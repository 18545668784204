define('jason-frontend/components/document-upload-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api', 'ember-uploader'], function (exports, Ember, InboundActions, API, EmberUploader) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    currentFile: null,
    uploading: false,
    active: false,
    loaded: false,
    selectedTab: null,
    selectedTags: null,
    tagsFlat: null,
    type: 'default',
    uploadedFiles: null,
    statusAvailable: false,
    myDropzone: null,
    actions: {
      selectTag: function selectTag(tag) {
        var _this = this;

        if (!tag.selected) {
          (function () {
            _this.get('selectedTags').pushObject(tag);
            Ember['default'].set(tag, 'selected', true);

            var flat = new Array();
            $.each(_this.get('selectedTags'), function () {
              flat.push($(this)[0].id);
            });
            _this.set('tagsFlat', flat.join(','));
          })();
        } else {
          (function () {
            _this.get('selectedTags').removeObject(tag);
            Ember['default'].set(tag, 'selected', false);
            var flat = new Array();
            $.each(_this.get('selectedTags'), function () {
              flat.push($(this)[0].id);
            });
            _this.set('tagsFlat', flat.join(','));
          })();
        }
      },
      selectMode: function selectMode(mode) {
        this.set('selectedTab', mode);
      },
      uploadInProgress: function uploadInProgress(e) {
        this.set('uploading', true);
      },
      storeExternal: function storeExternal() {
        var data = {
          name: this.get('name'),
          externalUrl: this.get('externalUrl'),
          articleId: this.get('ctxId')
        };

        var self = this;
        $.ajax({
          url: "/api/documents/external",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.sendAction('uploadDone');
          $.magnificPopup.close();
        }, function () {
          self.sendAction('uploadDone');
          $.magnificPopup.close();
        });
      },
      load: function load(ctx, ctxId, folderId, breads) {
        this.set('showUploadButton', false);
        this.set('currentFile', null);
        this.set('ctx', ctx);
        this.set('ctxId', ctxId);
        this.set('ctxId2', -1);
        this.set('userId', API['default'].getUserId());
        this.set('folderId', folderId);
        this.set('active', true);
        this.set('breads', breads);
        this.set('name', null);
        this.set('selectedTags', new Array());
        this.set('tagsFlat', null);
        this.set('selectedTab', 'single');
        this.set('description', null);
        this.set('uploadedFiles', new Array());
        this.set('statusAvailable', false);

        this.set('created', moment().format('DD. MM. YYYY'));
        this.set('createdTime', moment().format('HH:mm'));

        var self = this;

        var deferred = $.ajax({
          url: "/api/documentTags",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('tags', data.documentTag);
        }, function (data) {
          self.set('tags', data.documentTag);
        });

        if (!this.get('loaded')) {
          this.set('loaded', true);
          Dropzone.autoDiscover = false;
          this.myDropzone = new Dropzone("#uploadFormArgl", {
            maxFiles: 20,
            parallelUploads: 3,
            createImageThumbnails: false,
            url: '/api/documents/upload',
            params: {
              'mandantId': API['default'].getMandant(),
              'downloadToken': API['default'].getDownloadToken()
            },
            init: function init() {
              this.on("sending", function (file, xhr, formData) {
                formData.append("tags", self.get('tagsFlat'));
                formData.append("folderId", self.get('folderId'));
                formData.append("ctx", self.get('ctx'));
                formData.append("ctxId", self.get('ctxId'));
                formData.append("ctxId2", self.get('ctxId2'));
                formData.append("userId", API['default'].getUserId());
              });
            }
          });

          this.myDropzone.on("addedfile", function (file) {
            self.set('uploading', true);
          });
          this.myDropzone.on("complete", function (file) {
            self.set('statusAvailable', true);
            console.log(file);
            if (file.status === 'error') {
              jason.notifiction.error('Fehler', 'Datei: ' + file.name + ' konnte nicht hochgeladen werden');
              self.get('uploadedFiles').pushObject({
                name: file.name,
                status: 'success'
              });
            } else {
              var status = file.xhr.status;
              if (status === 200) {
                var id = file.xhr.responseText;
                self.sendAction('uploadDone');
                jason.notifiction.notifiy('Erfolgreich hochgeladen', 'Datei: ' + file.name);
                self.get('uploadedFiles').pushObject({
                  name: file.name,
                  status: 'success'
                });
              } else {
                jason.notifiction.error('Beim Hochladen ist ein Fehler aufgetreten', '');
                self.get('uploadedFiles').pushObject({
                  name: file.name,
                  status: 'error'
                });
              }
            }
            self.set('uploading', false);
          });
        } else {
          this.myDropzone.removeAllFiles(true);
          this.myDropzone.params = {
            'mandantId': API['default'].getMandant(),
            'downloadToken': API['default'].getDownloadToken(),
            'ctx': this.get('ctx'),
            'ctxId': this.get('ctxId'),
            'ctxId2': this.get('ctxId2')
          };
        }
      },
      uploadError: function uploadError() {
        this.set('uploading', false);
        this.set('active', false);
      },
      uploadDone: function uploadDone(data) {
        this.set('uploading', false);
        this.set('active', false);
        this.sendAction('uploadDone');

        $.magnificPopup.close();
      }
    }
  });

});