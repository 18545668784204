define('jason-frontend/routes/inventory/movements', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    perPage: 10,
    queryParams: {},
    setupController: function setupController(controller, model) {
      controller.set("model", model);

      this.store.find('article-tag', { type: 'service' }).then(function (items) {
        items.forEach(function (item) {
          Ember['default'].set(item, 'selected', false);
        });
        controller.set('articleTags', items);
      });
      this.applyFilter();
    },
    actions: {
      queryProductsPurchase: function queryProductsPurchase(query, deferred) {
        this.store.find('product', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      download: function download() {
        var controller = this.get('controller');
        var tags = new Array();
        if (controller.get('articleTags')) {
          $.each(controller.get('articleTags').content, function () {
            if ($(this)[0].record.get('selected')) {
              tags.push($(this)[0].record.id);
            }
          });
        }
        var code = controller.get('filterCode'),
            status = controller.get('filterStatus'),
            type = controller.get('filterType'),
            product = controller.get('filterProduct'),
            dateRange = controller.get('pickDate'),
            dateFromTo = dateRange.split('-');

        var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
            to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

        controller.set('to', to);
        controller.set('from', from);

        if (type) {
          type = type.id;
        }

        if (product) {
          product = product.id;
        }

        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: API['default'].getMandant()
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/inventoryStockMovements/download?mandantId=' + API['default'].getMandant() + '&token=' + data.responseText + '&filterTags=' + tags.toString() + '&filterType=' + type + '&from=' + from + '&to=' + to + '&filterCode=' + code + '&filterProduct=' + product + '&filterStatus=' + status);
        });
      },
      downloadDrugReport: function downloadDrugReport() {
        var controller = this.get('controller');
        var tags = new Array();
        if (controller.get('articleTags')) {
          $.each(controller.get('articleTags').content, function () {
            if ($(this)[0].record.get('selected')) {
              tags.push($(this)[0].record.id);
            }
          });
        }
        var code = controller.get('filterCode'),
            status = controller.get('filterStatus'),
            type = controller.get('filterType'),
            product = controller.get('filterProduct'),
            dateRange = controller.get('pickDate'),
            dateFromTo = dateRange.split('-');

        var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
            to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

        controller.set('to', to);
        controller.set('from', from);

        if (type) {
          type = type.id;
        }

        if (product) {
          product = product.id;
        }

        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: API['default'].getMandant()
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/inventoryStockMovements/downloadDrugReport?mandantId=' + API['default'].getMandant() + '&token=' + data.responseText + '&filterTags=' + tags.toString() + '&filterType=' + type + '&from=' + from + '&to=' + to + '&filterCode=' + code + '&filterProduct=' + product + '&filterStatus=' + status);
        });
      },
      selectTag: function selectTag(tag) {
        var currentValue = tag.get('selected');
        Ember['default'].set(tag, 'selected', !currentValue);
        this.applyFilter();
      },
      bookoutMovement: function bookoutMovement(id) {
        var controller = this.get('controller');

        var self = this;
        $.ajax({
          url: "/api/inventoryStockMovements/simple/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.applyFilter();
        }, function (data) {
          self.applyFilter();
        });
      },
      showStock: function showStock(id) {
        this.transitionTo('inventory.show-stock', id);
      },
      scanProduct: function scanProduct(id) {
        var controller = this.get('controller');

        controller.set('filterCode', id);
        this.applyFilter();
      },
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          code = controller.get('filterCode'),
          status = controller.get('filterStatus'),
          type = controller.get('filterType'),
          product = controller.get('filterProduct'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      if (type) {
        type = type.id;
      }
      if (product) {
        product = product.id;
      }

      controller.set('showLoading', true);

      var tags = new Array();
      if (controller.get('articleTags')) {
        $.each(controller.get('articleTags').content, function () {
          if ($(this)[0].record.get('selected')) {
            tags.push($(this)[0].record.id);
          }
        });
      }

      this.findPaged('inventory-stock-movement', {
        filterCode: code,
        filterStatus: status,
        filterType: type,
        filterProduct: product,
        to: to,
        from: from,
        filterTags: tags.toString(),
        pageSize: this.get('perPage')
      }).then(function (data) {
        var meta = data.meta;
        controller.set('model', data);
        controller.set('showLoading', false);
        controller.set('totalPlus', meta.total_plus);
        controller.set('totalIncoming', meta.total_incoming);
        controller.set('totalOutgoing', meta.total_outgoing);
        controller.set('totalMinus', meta.total_minus);
      });
    }
  });

});