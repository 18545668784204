define('jason-frontend/models/patient-article-history', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    date: DS['default'].attr('date'),
    invoiceId: DS['default'].attr('number'),
    invoiceCode: DS['default'].attr('string')
  });

});