define('jason-frontend/components/customer-group-panel', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    toggleVisible: false,
    customerGroups: [{ value: "1", id: "1" }, { value: "2", id: "2" }, { value: "3", id: "3" }, { value: "4", id: "4" }],
    actions: {
      confirm: function confirm(model) {

        var form = $('#customerGroupForm'),
            id = form.attr('data-customerGroup-id');

        var value = form.find('#groupId').val();
        value = value.replace(',', '.');

        if (isNaN(value) || value < 0) {
          jason.notifiction.systemError('Fehler', 'Bitte einen Wert >= 0 eingeben');
          return;
        }

        var fee = {
          id: id,
          mandantId: form.data('mandant-id'),
          group: value
        };

        if (form.find('#supplier')) {
          fee.supplier = {
            id: form.find('#customergroup-supplier').val()
          };
        }

        this.sendAction('confirm', fee);

        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    },
    becomeFocused: (function () {
      var self = this,
          form = $('#customerGroupForm'),
          id = form.attr('data-mandant-id');
    }).on('didInsertElement')
  });

});