define('jason-frontend/models/purchase-product-supplier-info', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    retailPrice: DS['default'].attr('number'),
    title: DS['default'].attr(''),
    cashback: DS['default'].attr('number'),
    cashbackActive: DS['default'].attr(''),
    reduction: DS['default'].attr('number'),
    jasonPrice: DS['default'].attr('number'),
    jasonRetailPrice: DS['default'].attr('number'),
    transactionFee: DS['default'].attr('number'),
    bestPriceGuaranty: DS['default'].attr('boolean'),
    promotion: DS['default'].attr('boolean'),
    partnerText: DS['default'].attr('string'),
    topPromotion: DS['default'].attr('boolean'),
    sellingAmount: DS['default'].attr('number'),
    jasonPriceDivided: DS['default'].attr('number'),
    iekAvailable: DS['default'].attr('boolean'),
    free: DS['default'].attr('boolean'),
    supplier: DS['default'].belongsTo('product-supplier'),
    promotionCategory: DS['default'].belongsTo('promotion-category'),
    deliveryStatus: DS['default'].belongsTo('purchase-delivery-status'),
    deliverableFrom: DS['default'].attr('string'),
    deliverableTo: DS['default'].attr('string'),
    deliveryInfo: DS['default'].attr('string'),
    deliveryPhone: DS['default'].attr('string'),
    promotionActive: DS['default'].attr('boolean'),
    promotionFrom: DS['default'].attr('string'),
    promotionTo: DS['default'].attr('string'),
    promotionPrice: DS['default'].attr('number'),
    savingInPercent: DS['default'].attr('number'),
    promotionInfo: DS['default'].attr('string'),
    promotionLabel: DS['default'].attr('string'),
    active: DS['default'].attr('boolean'),
    activeFrom: DS['default'].attr('string'),
    deliverableSoon: DS['default'].attr('boolean'),
    activeTo: DS['default'].attr('string'),
    minimalOrderAmount: DS['default'].attr('number'),
    richterInfo: DS['default'].attr(''),
    promotionRow: DS['default'].attr('number'),
    selectedQty: DS['default'].attr(),
    virtual: DS['default'].attr(),
    richterNav: DS['default'].attr()
  });

});