define('jason-frontend/controllers/transactions/financial', ['exports', 'ember', 'moment'], function (exports, Ember, moment) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Registrierkasse",
    breadCrumbPath: "transactions.cashdesk",
    filterType: "all",
    filterSumIn: false,
    filterSumOut: false,
    filterDayIn: false,
    filterDayOut: false,
    filterPaymentAll: true,
    filterPaymentCash: false,
    filterPaymentCashcard: false,
    filterPaymentCreditcard: false,
    filterPaymentVoucher: false,
    filterPaymentBanktransfer: false,
    classFilterTypeAll: "primary",
    classFilterTypeIn: "default",
    classFilterTypeOut: "default",
    classFilterSumIn: "default",
    classFilterSumOut: "default",
    classFilterDayIn: "default",
    classFilterDayOut: "default",
    classFilterPaymentAll: "primary",
    classFilterPaymentCash: "default",
    classFilterPaymentCashcard: "default",
    classFilterPaymentCreditcard: "default",
    classFilterPaymentVoucher: "default",
    classFilterPaymentBanktransfer: "default",
    pickDate: moment['default']().format("DD. MM. YYYY") + " - " + moment['default']().format("DD. MM. YYYY"),
    actions: {
      setFilterType: function setFilterType(type) {
        this.set('filterType', type);
        if (type === 'in') {
          this.set('classFilterTypeIn', "primary");
          this.set('classFilterTypeAll', "default");
          this.set('classFilterTypeOut', "default");
        }
        if (type === 'out') {
          this.set('classFilterTypeIn', "default");
          this.set('classFilterTypeAll', "default");
          this.set('classFilterTypeOut', "primary");
        }
        if (type === 'all') {
          this.set('classFilterTypeIn', "default");
          this.set('classFilterTypeAll', "primary");
          this.set('classFilterTypeOut', "default");
        }
      },
      setPaymentType: function setPaymentType(type) {
        if (type === 'all') {
          if (this.filterPaymentAll) {
            this.set('classFilterPaymentAll', "default");
            this.set('filterPaymentAll', false);
          } else {
            this.set('classFilterPaymentAll', "primary");
            this.set('classFilterPaymentCash', "default");
            this.set('classFilterPaymentCashcard', "default");
            this.set('classFilterPaymentCreditcard', "default");
            this.set('classFilterPaymentVoucher', "default");
            this.set('classFilterPaymentBanktransfer', "default");
            this.set('filterPaymentAll', true);
            this.set('filterPaymentCash', false);
            this.set('filterPaymentCashcard', false);
            this.set('filterPaymentCreditcard', false);
            this.set('filterPaymentVoucher', false);
            this.set('filterPaymentBanktransfer', false);
          }
        }
        if (type === 'cash') {
          if (this.filterPaymentCash) {
            this.set('classFilterPaymentCash', "default");
            this.set('filterPaymentAll', false);
            this.set('filterPaymentCash', false);
          } else {
            this.set('classFilterPaymentCash', "primary");
            this.set('classFilterPaymentAll', "default");
            this.set('filterPaymentCash', true);
            this.set('filterPaymentAll', false);
          }
        }
        if (type === 'cashcard') {
          if (this.filterPaymentCashcard) {
            this.set('classFilterPaymentCashcard', "default");
            this.set('filterPaymentAll', false);
            this.set('filterPaymentCashcard', false);
          } else {
            this.set('classFilterPaymentAll', "default");
            this.set('classFilterPaymentCashcard', "primary");
            this.set('filterPaymentCashcard', true);
            this.set('filterPaymentAll', false);
          }
        }
        if (type === 'creditcard') {
          if (this.filterPaymentCreditcard) {
            this.set('classFilterPaymentCreditcard', "default");
            this.set('filterPaymentAll', false);
            this.set('filterPaymentCreditcard', false);
          } else {
            this.set('classFilterPaymentAll', "default");
            this.set('classFilterPaymentCreditcard', "primary");
            this.set('filterPaymentCreditcard', true);
            this.set('filterPaymentAll', false);
          }
        }
        if (type === 'voucher') {
          if (this.filterPaymentVoucher) {
            this.set('classFilterPaymentVoucher', "default");
            this.set('filterPaymentAll', false);
            this.set('filterPaymentVoucher', false);
          } else {
            this.set('classFilterPaymentAll', "default");
            this.set('classFilterPaymentVoucher', "primary");
            this.set('filterPaymentVoucher', true);
            this.set('filterPaymentAll', false);
          }
        }
        if (type === 'banktransfer') {
          if (this.filterPaymentBanktransfer) {
            this.set('classFilterPaymentBanktransfer', "default");
            this.set('filterPaymentAll', false);
            this.set('filterPaymentBanktransfer', false);
          } else {
            this.set('classFilterPaymentAll', "default");
            this.set('classFilterPaymentBanktransfer', "primary");
            this.set('filterPaymentBanktransfer', true);
            this.set('filterPaymentAll', false);
          }
        }
      },
      setFilterSum: function setFilterSum(type) {
        if (type === 'in') {
          if (this.filterSumIn) {
            this.set('filterSumIn', false);
            this.set('classFilterSumIn', "default");
          } else {
            this.set('filterSumIn', true);
            this.set('classFilterSumIn', "primary");
          }
        }
        if (type === 'out') {
          if (this.filterSumOut) {
            this.set('filterSumOut', false);
            this.set('classFilterSumOut', "default");
          } else {
            this.set('filterSumOut', true);
            this.set('classFilterSumOut', "primary");
          }
        }
      },
      setFilterDay: function setFilterDay(type) {
        if (type === 'in') {
          if (this.filterDayIn) {
            this.set('filterDayIn', false);
            this.set('classFilterDayIn', "default");
          } else {
            this.set('filterDayIn', true);
            this.set('classFilterDayIn', "primary");
          }
        }
        if (type === 'out') {
          if (this.filterDayOut) {
            this.set('filterDayOut', false);
            this.set('classFilterDayOut', "default");
          } else {
            this.set('filterDayOut', true);
            this.set('classFilterDayOut', "primary");
          }
        }
      }
    }
  });

});