define('jason-frontend/helpers/is-essential', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isEssentialMandant = isEssentialMandant;

  function isEssentialMandant() {
    return API['default'].getMandantType() === 'essential';
  }

  exports['default'] = Ember['default'].Helper.helper(isEssentialMandant);

});