define('jason-frontend/components/task-list', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      sortable: function sortable() {
        this.sendAction('setSortDir');
      },
      changeOwner: function changeOwner(item) {
        this.sendAction('changeOwner', item);
      },
      "delete": function _delete(item) {
        this.sendAction('delete', item);
      },
      markAsRead: function markAsRead(msg) {
        this.sendAction('markAsRead', msg);
      },
      startTask: function startTask(msg) {
        this.sendAction('startTask', msg);
      },
      finishTask: function finishTask(msg) {
        this.sendAction('finishTask', msg);
      },
      createNotification: function createNotification(msg) {
        this.sendAction('createNotification', msg);
      },
      showTask: function showTask(msg) {
        this.sendAction('showTask', msg);
      },
      showNotification: function showNotification(msg) {
        this.sendAction('showNotification', msg);
      },
      markAsReadForAll: function markAsReadForAll(msg) {
        this.sendAction('markAsReadForAll', msg);
      }
    }
  });

});