define('jason-frontend/controllers/invoices/create', ['exports', 'ember', 'jason-frontend/api', 'moment'], function (exports, Ember, API, moment) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Ausgangsrechnung",
    breadCrumbPath: "invoices.create",
    customerType: "anon",
    currentInvoiceId: null,
    currentTransactionId: null,
    cashRegisterList: null,
    selectedCashRegister: null,
    filterDeadPatients: false,
    terminalActivated: false,
    externalCashRegister: false,
    selectedPaymentType: 'cash',
    invoiceDate: moment['default']().format("DD. MM. YYYY"),
    dueDate: moment['default']().format("DD. MM. YYYY"),
    notice: null,
    treatmentNotice: null,
    patientId: null,
    amount: 0,
    given: null,
    shownHeads: new Array(),
    changeMoney: null,
    selectedPatient: 0,
    slipNotice: "Lieferdatum = Rechnungsdatum",
    styleStepPositions: "",
    styleStepPayment: "hidden",
    styleStepConfirmation: "hidden",
    treatmentHistoryData: null,
    queryParams: ['patientId'],
    actions: {
      openRevenue: function openRevenue(id) {

        this.get('customerRevenuePanel').send('load', id);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-customer-revenue-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openCorrections: function openCorrections(id) {

        var self = this;

        $.ajax({
          url: "/api/inventoryStocks/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('inventoryCorrectionPanel').send('load', data.inventoryStock);
        }, function () {});

        setTimeout(function (e) {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-inventory-correction-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 500);
      },
      selectSlopingBarcode: function selectSlopingBarcode(patientId) {
        this.get('slopingSelectBarcodePanel').send('load', patientId, 'invoices');
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-sloping-select-barcode-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectLaboklinBarcode: function selectLaboklinBarcode(patientId) {
        this.get('laboklinSelectBarcodePanel').send('load', patientId);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-laboklin-select-barcode-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectBarcode: function selectBarcode(patientId) {
        this.get('patientSelectBarcodePanel').send('load', patientId);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-patient-select-barcode-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openSlopingInfos: function openSlopingInfos(entry) {

        var self = this;

        $.ajax({
          url: "/api/products/" + entry.get('article').id + "/slopingInfos",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('slopingInfoPanel').send('load', data.productSlopingInfo, entry.get('article'), entry);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-sloping-info-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      scan: function scan(patientId, directEhapo) {

        this.get('quickScanProductPanel').send('load', 'ehapo', directEhapo);
        this.get('quickScanProductPanel').send('setPatientId', patientId);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-quick-scan-product-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openInventoryStockPanel: function openInventoryStockPanel(entry, model) {

        if (model) {
          var id = entry.get('article').id,
              productName = entry.get('article').get('name'),
              necessary = entry.get('unitQuantity') * entry.get('quantity'),
              entryId = entry.id,
              connectedMovements = entry.get('movements'),
              fullyBooked = entry.get('fullyBooked'),
              alreadyBooked = entry.get('alreadyBooked'),
              unitName = entry.get('unit').name,
              unitId = entry.get('unit').id;
        } else {
          var id = entry.article.id,
              productName = entry.article.name,
              necessary = entry.unitQuantity * entry.quantity,
              entryId = entry.id,
              connectedMovements = entry.movements,
              fullyBooked = entry.fullyBooked,
              alreadyBooked = entry.alreadyBooked,
              unitName = entry.unit.name,
              unitId = entry.unit.id;
        }

        var self = this;

        $.ajax({
          url: "/api/inventoryStocks/product/" + id + "?desiredUnit=" + unitId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('selectInventoryPanel').send('load', data.inventoryStock, necessary, id, productName, -1, self.get('invoice').id, entryId, connectedMovements, fullyBooked, alreadyBooked, unitName, unitId);
        }, function () {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-select-inventory-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openCheckInventoryInput: function openCheckInventoryInput(nr, unitId) {
        var self = this;
        $.ajax({
          url: "/api/inventoryStocks/checkNumber/" + nr + "?desiredUnit=" + unitId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('selectInventoryPanel').send('selectStock', data.inventoryStock);
        }, function (error) {
          jason.notifiction.error('Fehler', 'Die eingegebene Nummer konnte keinem Warenbestand zugeordnet werden');
        });
      },
      addPatient: function addPatient(customerId, invoiceId) {
        $('#createPatientForm').attr('data-customer-id', customerId);
        $('#createPatientForm').attr('data-invoice-id', invoiceId);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-create-patient-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openEmailPanel: function openEmailPanel(invoiceId) {

        var self = this;

        var customer = this.get('invoice').get('customer');

        if (customer) {
          this.get('forwardEmailPanel').send('loadWithEmails', invoiceId, customer.get('emails'));
        } else {
          this.get('forwardEmailPanel').send('load', invoiceId);
        }

        $.ajax({
          url: "/api/textModules?filterType=email&per_page=50",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('forwardEmailPanel').send('loadTextmodules', data.textModule);
        }, function () {});

        $.ajax({
          url: "/api/editor/invoice/printTemplates",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('forwardEmailPanel').send('loadPrintTemplates', data.templateStyle);
        }, function () {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-email-invoice-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openAddPatientPanel: function openAddPatientPanel(invoiceId) {
        this.send('reload');
        $('#patientInvoiceForm').attr('data-invoice-id', invoiceId);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-patient-invoice-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      editTreatment: function editTreatment(patient, invoiceId) {
        this.get('getTreatmentInfoHistory').send('openEditPanel', patient, invoiceId);
      },
      openHistory: function openHistory(patientId) {

        var controller = this.get('controller'),
            self = this;

        $('#modal-form').attr('data-patient-id', patientId);

        self.get('getTreatmentInfoHistory').send('load', this.store.find('treatment-patient', { patientId: patientId }), patientId);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-form-history'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      setPaymentType: function setPaymentType(paymentType) {
        this.set('selectedPaymentType', paymentType);
      }
    }
  });

});