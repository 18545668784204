define('jason-frontend/controllers/invoices/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    queryParams: ["page", "perPage", "filterCode", "customerId"],
    filterStatus: "",
    filterCode: "",
    showFilters: true,
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),
    filterPaymentStatus: "",
    filterCustomer: null,
    sortBy: 'created',
    sortDir: 'desc',
    myStickyOptionsTableHead: {
      topSpacing: 160,
      zIndex: 9
    },
    actions: {
      editPaymentAgreement: function editPaymentAgreement(invoice) {
        var self = this;
        self.get('paymentAgreementPanel').send('load', invoice);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-payment-agreement-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: false
        });
      },
      downloadWarning: function downloadWarning(invoice) {
        var self = this;
        self.get('downloadInvoicePanel').send('load', invoice);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-download-invoice-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    },
    filterStatusList: [{ value: "Ausgestellt", id: "to_be_verified" }, { value: "1. Mahnung", id: "warning1" }, { value: "2. Mahnung", id: "warning2" }, { value: "Bereit für Inkasso", id: "ready_for_money_collection" }, { value: "An Rechtsanwalt übergeben", id: "lawyer" }, { value: "An Inkasso übergeben", id: "money_collection" }, { value: "Keine Mahnung", id: "no_warning" }, { value: "Storniert", id: "canceled" }, { value: "Ausgebucht", id: "written_off" }, { value: "Abgeschlossen", id: "closed" }],

    filterPaymentStatusList: [{ value: "nach Bezahlstatus filtern", id: "" }, { value: "Vollständig bezahlt", id: "paid" }, { value: "Teilweise bezahlt", id: "partpaid" }, { value: "Nicht bezahlt", id: "notpaid" }],
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",

    page: 1,
    perPage: 10,
    watchCustomer: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterCustomer'),
    watchPaymentStatus: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterPaymentStatus'),
    watchStatus: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterStatus'),
    watchDate: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('pickDate')
  });

});