define('jason-frontend/routes/practicemgmt/station', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {
      return this.findPaged('treatment', {
        state: 'in_station',
        perPage: 10
      });
    },
    setupController: function setupController(controller, model, params) {

      controller.set("gendersAll", this.store.find('gender'));
      controller.set('patientGenders', this.store.find('patient-gender'));
      controller.set('patientCategories', this.store.find('patient-category'));
      controller.set('doctors', this.store.find('user', { role: 'doctor' }));
      controller.set('rooms', this.store.find('treatment-room'));
      controller.set('stations', this.store.find('treatment-station'));
      controller.set("model", model);
    },
    updateState: function updateState(_updateState) {
      var self = this;

      $.ajax({
        url: "/api/treatments/updateState",
        method: "POST",
        data: JSON.stringify(_updateState),
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        if (_updateState.newState === 'in_treatment') {
          self.transitionTo('practicemgmt.treatment-details', _updateState.treatmentPatientId, { queryParams: { entry: "true" } });
        } else if (_updateState.close == true) {
          self.transitionTo('cash.choose');
        } else {
          self.refresh();
        }
      });
    },
    actions: {
      toggleAllStations: function toggleAllStations() {
        this.get('controller').set('allStationsSelected', true);
        this.get('controller').get('stations').forEach(function (item) {
          Ember['default'].set(item, 'selected', false);
        });
        this.get('controller').set('changeTrigger', new Date());
      },
      toggleStation: function toggleStation(station) {
        this.get('controller').set('allStationsSelected', false);
        if (station.get('selected')) {
          Ember['default'].set(station, 'selected', false);
        } else {
          Ember['default'].set(station, 'selected', true);
        }

        if (!this.get('controller').get('stations').some(function (item) {
          return item.get('selected') === true;
        })) {
          this.get('controller').set('allStationsSelected', true);
        }
        this.get('controller').set('changeTrigger', new Date());
      },
      saveEditTreatmentInfo: function saveEditTreatmentInfo(data) {
        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/quick",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (result) {
          self.refresh();
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      movePatientToWaitingRoom: function movePatientToWaitingRoom(infoId) {
        var self = this,
            updateState = {
          treatmentPatientId: infoId,
          newState: 'in_waitingroom'
        };
        this.updateState(updateState);
      },
      movePatientToTreatmentRoom: function movePatientToTreatmentRoom(infoId) {
        var self = this,
            updateState = {
          treatmentPatientId: infoId,
          newState: 'in_treatment'
        };
        this.updateState(updateState);
      },
      abort: function abort(id) {
        var self = this,
            updateState = {
          treatmentPatientId: id,
          newState: 'closed',
          hardAbort: true
        };
        this.updateState(updateState);
      },
      exportInvoice: function exportInvoice(id) {
        var self = this,
            deferred = $.ajax({
          url: "/api/treatmentOffers/" + id + "/checkout",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('invoices.create', data, { queryParams: { entry: "true" } });
          jason.notifiction.notifiy('In Ausgangsrechnung übertragen', '');
        }, function (error) {});
      }
    }
  });

});