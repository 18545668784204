define('jason-frontend/components/line-chart', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      show: function show() {}
    },
    show: function show() {

      var charts = $('div#highcharts'),
          barcharts = $('div#barcharts'),
          barcharts2 = $('div#barcharts2'),
          productId = charts.data('product-id');

      $.ajax({
        url: "/api/articleImportHistories/csvbarJson?productId=" + productId,
        method: "GET",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {

        var csv = data.priceHistoryBar.data,
            csvSale = data.priceHistoryBar.saleData,
            label = data.priceHistoryBar.label,
            saleLabel = data.priceHistoryBar.saleLabel;

        if (!csv) {
          $('div#price-history-bars').hide();
          return;
        }

        barcharts.highcharts({
          chart: {
            type: 'column'
          },
          data: {
            csv: csv
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['Ersparnis']
          },
          colors: ["#999999", "#ADCCD4"],
          labels: {
            items: [{
              html: label,
              style: {
                color: '#ADCCD4',
                top: '3px',
                left: '200px',
                fontSize: '18px'
              }
            }]
          },
          yAxis: {
            title: {
              text: 'EUR'
            }
          }
        });

        barcharts2.highcharts({
          chart: {
            type: 'column'
          },
          data: {
            csv: csvSale
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['Marge']
          },
          colors: ["#999999", "#ADCCD4"],
          labels: {
            items: [{
              html: saleLabel,
              style: {
                color: '#ADCCD4',
                top: '3px',
                left: '200px',
                fontSize: '18px'
              }
            }]
          },
          yAxis: {
            title: {
              text: 'EUR'
            }
          }
        });
      });

      $.ajax({
        url: "/api/articleImportHistories/csv?productId=" + productId,
        method: "GET",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function () {}, function (data) {

        var lines = data.responseText.split('\n').length;

        if (lines <= 2) {
          $('div#price-history').hide();
          return;
        }

        charts.highcharts({

          lang: {
            decimalPoint: ',',
            thousandsSep: '.',
            loading: 'Daten werden geladen...',
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
            exportButtonTitle: "Exportieren",
            printButtonTitle: "Drucken",
            rangeSelectorFrom: "Von",
            rangeSelectorTo: "Bis",
            rangeSelectorZoom: "Zeitraum",
            downloadPNG: 'Download als PNG-Bild',
            downloadJPEG: 'Download als JPEG-Bild',
            downloadPDF: 'Download als PDF-Dokument',
            downloadSVG: 'Download als SVG-Bild',
            resetZoom: "Zoom zurücksetzen",
            resetZoomTitle: "Zoom zurücksetzen"
          },

          colors: ["#7cb5ec", "#f7a35c", "#90ee7e", "#7798BF", "#aaeeee", "#ff0066", "#eeaaee", "#55BF3B", "#DF5353", "#7798BF", "#aaeeee"],

          data: {
            csv: data.responseText
          },

          title: {
            text: ''
          },
          xAxis: {
            tickWidth: 0,
            gridLineWidth: 1,
            labels: {
              align: 'left',
              x: 3,
              y: -3
            }
          },

          yAxis: [{
            title: {
              text: null
            },
            labels: {
              align: 'left',
              x: 3,
              y: 16,
              format: '{value:.,0f}'
            },
            showFirstLabel: false
          }, {
            linkedTo: 0,
            gridLineWidth: 0,
            opposite: true,
            title: {
              text: 'EUR'
            },
            labels: {
              align: 'right',
              x: -3,
              y: 16,
              format: '{value:.,0f}'
            },
            showFirstLabel: false
          }],

          legend: {
            align: 'left',
            verticalAlign: 'top',
            y: 0,
            floating: true,
            borderWidth: 0
          },

          tooltip: {
            shared: true,
            crosshairs: true
          },

          plotOptions: {
            series: {
              cursor: 'pointer',
              marker: {
                lineWidth: 1
              }
            }
          }
        });
      });
    },
    becomeFocused: (function () {
      this.show();
    }).on('didInsertElement')
  });

});