define('jason-frontend/helpers/is-at-least', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isAtLeastMandant = isAtLeastMandant;

  function isAtLeastMandant(params) {
    var desired = params[0];
    var currentState = API['default'].getMandantType();

    if (!currentState) {
      return false;
    }
    if (desired === 'basic' && (currentState === 'basic' || currentState === 'essential' || currentState === 'professional')) {
      return true;
    }
    if (desired === 'essential' && (currentState === 'essential' || currentState === 'professional')) {
      return true;
    }
    if (desired === 'professional' && currentState === 'professional') {
      return true;
    }
    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(isAtLeastMandant);

});