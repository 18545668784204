define('jason-frontend/components/treatment-report-wizzard', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    save: function save(showNotification, id) {

      var entries = new Array();

      var self = this;

      $('#sortableDiv div.sortable-item').each(function (index, val) {
        var id = $(this).attr('data-module-id');

        var entry = {
          position: index,
          textModuleId: id
        };
        entries.push(entry);
      });

      var data = {
        entries: entries
      };

      $.ajax({
        url: "/api/treatmentReports/" + this.get('treatmentPatientId') + "/" + id,
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        if (showNotification) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        }
        self.sendAction('saveFinished', self.get('treatmentPatientId'), self.get('model').id);
      }, function () {});
    },
    actions: {
      preview: function preview() {
        var self = this;
        this.save(false, this.get('model').id);
        setTimeout(function () {
          self.sendAction('openPreview');
        }, 1000);
        $.magnificPopup.close();
      },
      save: function save() {
        this.save(false, this.get('model').id);
      },
      load: function load(model, treatmentPatientId) {
        var self = this;
        $('#sortableDiv div.sortable-item').each(function (index, val) {
          $(this).remove();
        });

        setTimeout(function () {
          self.set('model', model);
          self.set('treatmentPatientId', treatmentPatientId);
        }, 500);

        $.ajax({
          url: "/api/textModules?filterType=report&per_page=30",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('allItems', data.textModule);
        });

        setTimeout(function () {
          $(".draggable").draggable({
            containment: "#sortableDiv",
            connectToSortable: "#sortableDiv",
            helper: "clone",
            revert: "invalid"
          });
        }, 500);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});