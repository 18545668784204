define('jason-frontend/models/purchase-order', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    code: DS['default'].attr('string'),
    created: DS['default'].attr('date'),
    label: DS['default'].attr('string'),
    inventoryStatus: DS['default'].attr('string'),
    orderStatus: DS['default'].attr('string'),
    subOrders: DS['default'].hasMany('purchaseSubOrder'),
    taxEntries: DS['default'].hasMany('purchaseTaxEntry'),
    user: DS['default'].belongsTo('user'),
    total: DS['default'].attr('number'),
    formMissing: DS['default'].attr('boolean'),
    entryCount: DS['default'].attr('number'),
    totalNet: DS['default'].attr('number'),
    originalTotalNet: DS['default'].attr('number'),
    totalCancellationNet: DS['default'].attr('number'),
    cancelCount: DS['default'].attr('number'),
    totalTransactionFee: DS['default'].attr('number'),
    totalTransactionFeeTax: DS['default'].attr('number'),
    totalInclusiveFee: DS['default'].attr('number'),
    totalSaving: DS['default'].attr('number'),
    totalCommission: DS['default'].attr('number'),
    totalCashback: DS['default'].attr('number'),
    totalShipping: DS['default'].attr('number'),
    totalShippingTax: DS['default'].attr('number'),
    cancelable: DS['default'].attr('boolean'),
    privateOrder: DS['default'].attr('boolean'),
    ehapoOrder: DS['default'].attr('boolean'),
    laboklinOrder: DS['default'].attr('boolean'),
    needsRichterWsCall: DS['default'].attr(''),
    richterCartValid: DS['default'].attr('boolean'),
    entryQuantity: DS['default'].attr(),
    deliveryCosts: DS['default'].attr(),
    freeHouseShippingNotReached: DS['default'].attr('boolean'),
    laboklinDatas: DS['default'].hasMany('purchaseOrderLaboklinForm')
  });

});