define('jason-frontend/controllers/purchases/cashback-thresholds-details', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Cashback",
    breadCrumbPath: "purchases.cashback-thresholds",
    supplier: null,
    selectTab: 'master',
    showLoading: false,
    actions: {
      openEdit: function openEdit(line) {

        this.get('editThresholdPanel').send('load', line);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-threshold-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openAdd: function openAdd() {

        this.get('editThresholdPanel').send('load', null);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-threshold-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    }
  });

});