define('jason-frontend/controllers/inventory/delivery-note-view', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Details",
    breadCrumbPath: "inventory.delivery-note-view",
    actions: {}
  });

});