define('jason-frontend/components/button-notification', ['exports', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 50,
    side: 'top',
    showStroke: true,
    classes: '',
    timer: 0,
    color: '#ADCCD4',
    content: '',
    theme: 'tooltipster-punk'
  });

});