define('jason-frontend/components/select-station-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    months: null,
    type: null,
    actions: {
      confirm: function confirm(id) {
        var data = {
          patientTreatmentId: id,
          stationId: $('label[for=stationId] input.form-control').val()
        };

        this.sendAction('movePatientToStation', id, data);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(id) {
        this.set('id', id);

        var self = this;
        $.ajax({
          url: "/api/treatmentStations",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('stations', data.treatmentStation);
        }, function (error) {});
      }
    }
  });

});