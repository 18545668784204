define('jason-frontend/templates/components/document-upload-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 16
            },
            "end": {
              "line": 18,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-multi");
          var el3 = dom.createTextNode("Mehrere Dokumente");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element5, 'class');
          morphs[1] = dom.createElementMorph(element5);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[16,86],[16,97]]]],"multi"],[],["loc",[null,[16,82],[16,106]]]],"is-active",""],[],["loc",[null,[16,61],[16,123]]]]]]],
          ["element","action",["selectMode","multi"],[],["loc",[null,[16,143],[16,174]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 16
              },
              "end": {
                "line": 22,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"href","#tab-external");
            var el3 = dom.createTextNode("Externes Dokument");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element4, 'class');
            morphs[1] = dom.createElementMorph(element4);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[20,88],[20,99]]]],"external"],[],["loc",[null,[20,84],[20,111]]]],"is-active",""],[],["loc",[null,[20,63],[20,128]]]]]]],
            ["element","action",["selectMode","external"],[],["loc",[null,[20,151],[20,185]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 16
            },
            "end": {
              "line": 22,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[18,30],[18,34]]]],"therapy"],[],["loc",[null,[18,26],[18,45]]]]],[],0,null,["loc",[null,[18,16],[22,16]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 20
            },
            "end": {
              "line": 54,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element3, 'class');
          morphs[1] = dom.createAttrMorph(element3, 'style');
          morphs[2] = dom.createElementMorph(element3);
          morphs[3] = dom.createMorphAt(element3,0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["mr5 mb5 info-badge cursor with-delete-button ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[53,102],[53,114]]]],"selected",""],[],["loc",[null,[53,81],[53,130]]]]]]],
          ["attribute","style",["concat",["background-color: ",["get","tag.color",["loc",[null,[53,159],[53,168]]]]]]],
          ["element","action",["selectTag",["get","tag",["loc",[null,[53,193],[53,196]]]]],[],["loc",[null,[53,172],[53,198]]]],
          ["content","tag.name",["loc",[null,[53,199],[53,211]]]]
        ],
        locals: ["tag"],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 77
            },
            "end": {
              "line": 59,
              "column": 161
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","crumb-trail folder");
          var el2 = dom.createElement("a");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 0]),0,0);
          return morphs;
        },
        statements: [
          ["content","bread.name",["loc",[null,[59,138],[59,152]]]]
        ],
        locals: ["bread"],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 16
            },
            "end": {
              "line": 64,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[63,18],[63,39]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 64,
              "column": 16
            },
            "end": {
              "line": 66,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button context");
          dom.setAttribute(el1,"id","triggerFileUploadDocs");
          var el2 = dom.createTextNode(" Datei auswählen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 68,
              "column": 10
            },
            "end": {
              "line": 70,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","document-upload-widget",[],["uploadDone","uploadDone","uploadInProgress","uploadInProgress","uploadError","uploadError"],["loc",[null,[69,12],[69,124]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 85,
              "column": 18
            },
            "end": {
              "line": 87,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createAttrMorph(element2, 'style');
          morphs[2] = dom.createElementMorph(element2);
          morphs[3] = dom.createMorphAt(element2,0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["mr5 mb5 info-badge cursor with-delete-button ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[86,100],[86,112]]]],"selected",""],[],["loc",[null,[86,79],[86,128]]]]]]],
          ["attribute","style",["concat",["background-color: ",["get","tag.color",["loc",[null,[86,157],[86,166]]]]]]],
          ["element","action",["selectTag",["get","tag",["loc",[null,[86,191],[86,194]]]]],[],["loc",[null,[86,170],[86,196]]]],
          ["content","tag.name",["loc",[null,[86,197],[86,209]]]]
        ],
        locals: ["tag"],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 91,
              "column": 75
            },
            "end": {
              "line": 91,
              "column": 159
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","crumb-trail folder");
          var el2 = dom.createElement("a");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 0]),0,0);
          return morphs;
        },
        statements: [
          ["content","bread.name",["loc",[null,[91,136],[91,150]]]]
        ],
        locals: ["bread"],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 94,
              "column": 18
            },
            "end": {
              "line": 96,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[95,20],[95,41]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 96,
              "column": 18
            },
            "end": {
              "line": 101,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","dz-message needsclick");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","dz-button");
          var el3 = dom.createTextNode("Datei hier hinziehen oder auswählen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 106,
                "column": 22
              },
              "end": {
                "line": 108,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1,"class","mb10");
            var el2 = dom.createTextNode("Datei: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element0,1,1);
            morphs[1] = dom.createAttrMorph(element1, 'class');
            morphs[2] = dom.createMorphAt(element1,0,0);
            return morphs;
          },
          statements: [
            ["content","file.name",["loc",[null,[107,48],[107,61]]]],
            ["attribute","class",["concat",["vertical-middle info-badge info-badge-very-small ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","file.status",["loc",[null,[107,150],[107,161]]]],"success"],[],["loc",[null,[107,146],[107,172]]]],"label-success","label-error"],[],["loc",[null,[107,125],[107,204]]]]]]],
            ["inline","css-bool-evaluator",[["subexpr","eq",[["get","file.status",["loc",[null,[107,231],[107,242]]]],"success"],[],["loc",[null,[107,227],[107,253]]]],"Erfolgreich","Fehler"],[],["loc",[null,[107,206],[107,278]]]]
          ],
          locals: ["file"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 103,
              "column": 18
            },
            "end": {
              "line": 110,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","title mt20");
          var el2 = dom.createTextNode("Status");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1,"class","file-status");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","uploadedFiles",["loc",[null,[106,30],[106,43]]]]],[],0,null,["loc",[null,[106,22],[108,31]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 185,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-document-upload-panel");
        dom.setAttribute(el1,"class","small--heading popup-basic popup-large admin-form mfp-with-anim mfp-hide uploadContainer");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n              ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("\n                Dokument hochladen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","box pb0");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","js-tabs tabs");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","tabs__head");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        dom.setAttribute(el6,"class","u-mb0 clearfix");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"href","#tab-single");
        var el9 = dom.createTextNode("Einzelnes Dokument");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5,"class","tabs__body");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        dom.setAttribute(el6,"data-for","#tab-single");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("form");
        dom.setAttribute(el7,"id","documentEditPanel");
        dom.setAttribute(el7,"class","form-grid new-style");
        var el8 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col l-24/24@desk l-24/24@phone gutter");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","name");
        dom.setAttribute(el9,"class","field-label");
        var el10 = dom.createTextNode("Name");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col l-24/24 gutter");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","description");
        dom.setAttribute(el9,"class","field-label");
        var el10 = dom.createTextNode("Beschreibung");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col l-24/24 gutter");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","pickDate");
        dom.setAttribute(el9,"class","date-picker input-element");
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","field-label");
        var el11 = dom.createTextNode("Datum");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","field-icon");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("i");
        dom.setAttribute(el11,"class","fa fa-calendar");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      Uhr\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col l-24/24@desk l-24/24@phone gutter mb30");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","name");
        dom.setAttribute(el9,"class","field-label");
        var el10 = dom.createTextNode("Tags");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col l-24/24@desk l-24/24@phone gutter mb30");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","name");
        dom.setAttribute(el9,"class","field-label");
        var el10 = dom.createTextNode("Speicherort");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("img");
        dom.setAttribute(el9,"src","assets/images/icons/folder.svg");
        dom.setAttribute(el9,"class","mr10");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("br");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Hinweise");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ul");
        dom.setAttribute(el7,"class","hints");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        dom.setAttribute(el8,"class","mb0");
        var el9 = dom.createTextNode("Speicherort muss vorab ausgewählt werden!");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        dom.setAttribute(el8,"class","mb0");
        var el9 = dom.createTextNode("Die max. Dateigröße beträgt 10 MByte!");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        dom.setAttribute(el6,"data-for","#tab-multi");
        var el7 = dom.createTextNode("\n\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24@desk l-24/24@phone gutter mb30");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","name");
        dom.setAttribute(el8,"class","field-label");
        var el9 = dom.createTextNode("Tags");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24@desk l-24/24@phone gutter mb30");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","name");
        dom.setAttribute(el8,"class","field-label");
        var el9 = dom.createTextNode("Speicherort");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("img");
        dom.setAttribute(el8,"src","assets/images/icons/folder.svg");
        dom.setAttribute(el8,"class","mr10");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("form");
        dom.setAttribute(el7,"class","dropzone needsclick dz-clickable ");
        dom.setAttribute(el7,"id","uploadFormArgl");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("br");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Hinweise");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ul");
        dom.setAttribute(el7,"class","hints");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        dom.setAttribute(el8,"class","mb0");
        var el9 = dom.createTextNode("Der Speicherort muss vorab ausgewählt werden!");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        dom.setAttribute(el8,"class","mb0");
        var el9 = dom.createTextNode("Es können mehrere Dateien gleichzeitig hochgeladen werden!");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        dom.setAttribute(el8,"class","mb0");
        var el9 = dom.createTextNode("Die max. Dateigröße je Datei beträgt 10 MByte!");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("form");
        dom.setAttribute(el6,"id","documentUploadExternalForm");
        dom.setAttribute(el6,"class","form-grid new-style");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-external");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col l-24/24@desk l-24/24@phone gutter");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","name");
        dom.setAttribute(el9,"class","field-label");
        var el10 = dom.createTextNode("Name");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col l-24/24@desk l-24/24@phone gutter");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","name");
        dom.setAttribute(el9,"class","field-label");
        var el10 = dom.createTextNode("Externe Url");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class"," text-right");
        dom.setAttribute(el8,"style","margin-top: 10px;margin-bottom: -40px;margin-right: -20px");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("button");
        dom.setAttribute(el9,"type","submit");
        dom.setAttribute(el9,"class","icon-button icon-button--success");
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n\n    $(\"#documentUploadExternalForm\").validate({\n      errorClass: \"state-error\",\n      validClass: \"state-success\",\n      errorElement: \"em\",\n      rules: {\n        'name': {\n          required: true\n        },\n        'externalUrl': {\n          required: true\n        }\n      },\n      highlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n      },\n      unhighlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n      },\n      errorPlacement: function(error, element) {\n        if (element.is(\":radio\") || element.is(\":checkbox\")) {\n          element.closest('.option-group').after(error);\n        } else {\n          error.insertAfter(element.parent());\n        }\n      }\n    });\n\n    $('body').delegate('#triggerFileUploadDocs', 'click', function(event){\n      if(!$(this).hasClass('init')) {\n        $('#modal-document-upload-panel input[type=file]').click();\n        setTimeout(function() {\n          $('#triggerFileUploadDocs').removeClass('init');\n        }, 500);\n      }\n      $(this).addClass('init');\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var element7 = dom.childAt(element6, [1, 3, 1]);
        var element8 = dom.childAt(element7, [1, 1]);
        var element9 = dom.childAt(element8, [1, 1]);
        var element10 = dom.childAt(element7, [3]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(element11, [1]);
        var element13 = dom.childAt(element12, [5, 1]);
        var element14 = dom.childAt(element10, [3]);
        var element15 = dom.childAt(element10, [5]);
        var element16 = dom.childAt(element15, [1]);
        var morphs = new Array(29);
        morphs[0] = dom.createAttrMorph(element6, 'data-tags');
        morphs[1] = dom.createAttrMorph(element6, 'data-folder-id');
        morphs[2] = dom.createAttrMorph(element6, 'data-ctx');
        morphs[3] = dom.createAttrMorph(element6, 'data-ctx-id');
        morphs[4] = dom.createAttrMorph(element6, 'data-ctx-id2');
        morphs[5] = dom.createAttrMorph(element6, 'data-user-id');
        morphs[6] = dom.createAttrMorph(element9, 'class');
        morphs[7] = dom.createElementMorph(element9);
        morphs[8] = dom.createMorphAt(element8,3,3);
        morphs[9] = dom.createAttrMorph(element11, 'class');
        morphs[10] = dom.createElementMorph(element12);
        morphs[11] = dom.createMorphAt(dom.childAt(element12, [1]),3,3);
        morphs[12] = dom.createMorphAt(dom.childAt(element12, [3]),3,3);
        morphs[13] = dom.createMorphAt(element13,3,3);
        morphs[14] = dom.createMorphAt(element13,7,7);
        morphs[15] = dom.createMorphAt(dom.childAt(element12, [7]),3,3);
        morphs[16] = dom.createMorphAt(dom.childAt(element12, [9]),5,5);
        morphs[17] = dom.createMorphAt(element12,11,11);
        morphs[18] = dom.createMorphAt(element12,13,13);
        morphs[19] = dom.createAttrMorph(element14, 'class');
        morphs[20] = dom.createMorphAt(dom.childAt(element14, [1]),3,3);
        morphs[21] = dom.createMorphAt(dom.childAt(element14, [3]),5,5);
        morphs[22] = dom.createMorphAt(dom.childAt(element14, [5]),1,1);
        morphs[23] = dom.createMorphAt(element14,7,7);
        morphs[24] = dom.createElementMorph(element15);
        morphs[25] = dom.createAttrMorph(element16, 'class');
        morphs[26] = dom.createMorphAt(dom.childAt(element16, [1]),3,3);
        morphs[27] = dom.createMorphAt(dom.childAt(element16, [3]),3,3);
        morphs[28] = dom.createMorphAt(dom.childAt(element16, [5, 1]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","data-tags",["concat",[["get","tagsFlat",["loc",[null,[1,148],[1,156]]]]]]],
        ["attribute","data-folder-id",["concat",[["get","folderId",["loc",[null,[1,178],[1,186]]]]]]],
        ["attribute","data-ctx",["concat",[["get","ctx",["loc",[null,[1,202],[1,205]]]]]]],
        ["attribute","data-ctx-id",["concat",[["get","ctxId",["loc",[null,[1,224],[1,229]]]]]]],
        ["attribute","data-ctx-id2",["concat",[["get","ctxId2",["loc",[null,[1,249],[1,255]]]]]]],
        ["attribute","data-user-id",["concat",[["get","userId",["loc",[null,[1,275],[1,281]]]]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[12,86],[12,97]]]],"single"],[],["loc",[null,[12,82],[12,107]]]],"is-active",""],[],["loc",[null,[12,61],[12,124]]]]]]],
        ["element","action",["selectMode","single"],[],["loc",[null,[12,145],[12,177]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[14,26],[14,30]]]],"default"],[],["loc",[null,[14,22],[14,41]]]]],[],0,1,["loc",[null,[14,16],[22,23]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 mt20 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[27,77],[27,88]]]],"single"],[],["loc",[null,[27,73],[27,98]]]],"is-active",""],[],["loc",[null,[27,52],[27,115]]]]]]],
        ["element","action",["save"],["on","submit"],["loc",[null,[28,73],[28,102]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","name",["loc",[null,[32,34],[32,38]]]]],[],[]],"autofocus",true,"class","gui-input new-style","title","Bitte geben Sie einen Namen an","name","name","id","uploadName"],["loc",[null,[32,20],[32,150]]]],
        ["inline","textarea",[],["rows","3","value",["subexpr","@mut",[["get","description",["loc",[null,[36,46],[36,57]]]]],[],[]],"class","full input textarea__input","name","description","autocomplete","off","id","uploadDescription"],["loc",[null,[36,20],[36,155]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","created",["loc",[null,[41,36],[41,43]]]]],[],[]],"class","input--date-picker small-style input datetimepicker2 ember-view ember-text-field","name","created","id","uploadDate"],["loc",[null,[41,22],[41,165]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","createdTime",["loc",[null,[45,36],[45,47]]]]],[],[]],"class","input date-picker__input new-style time fixed-timepicker-width","name","createdTime","placeholder","12:00","id","uploadTime"],["loc",[null,[45,22],[45,175]]]],
        ["block","each",[["get","tags",["loc",[null,[52,28],[52,32]]]]],["key","@index"],2,null,["loc",[null,[52,20],[54,29]]]],
        ["block","each",[["get","breads",["loc",[null,[59,85],[59,91]]]]],[],3,null,["loc",[null,[59,77],[59,170]]]],
        ["block","if",[["get","uploading",["loc",[null,[62,22],[62,31]]]]],[],4,5,["loc",[null,[62,16],[66,23]]]],
        ["block","if",[["get","active",["loc",[null,[68,16],[68,22]]]]],[],6,null,["loc",[null,[68,10],[70,17]]]],
        ["attribute","class",["concat",["tabs__content form-grid new-style ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[81,84],[81,95]]]],"multi"],[],["loc",[null,[81,80],[81,104]]]],"is-active",""],[],["loc",[null,[81,59],[81,121]]]]]]],
        ["block","each",[["get","tags",["loc",[null,[85,26],[85,30]]]]],["key","@index"],7,null,["loc",[null,[85,18],[87,27]]]],
        ["block","each",[["get","breads",["loc",[null,[91,83],[91,89]]]]],[],8,null,["loc",[null,[91,75],[91,168]]]],
        ["block","if",[["get","uploading",["loc",[null,[94,24],[94,33]]]]],[],9,10,["loc",[null,[94,18],[101,25]]]],
        ["block","if",[["get","statusAvailable",["loc",[null,[103,24],[103,39]]]]],[],11,null,["loc",[null,[103,18],[110,25]]]],
        ["element","action",["storeExternal"],["on","submit"],["loc",[null,[119,80],[119,118]]]],
        ["attribute","class",["concat",["tabs__content form-grid new-style ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[120,86],[120,97]]]],"external"],[],["loc",[null,[120,82],[120,109]]]],"is-active",""],[],["loc",[null,[120,61],[120,126]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","name",["loc",[null,[123,36],[123,40]]]]],[],[]],"autofocus",true,"class","gui-input new-style","title","Bitte geben Sie einen Namen an","name","name"],["loc",[null,[123,22],[123,136]]]],
        ["inline","input",[],["placeholder","https://","value",["subexpr","@mut",[["get","externalUrl",["loc",[null,[127,59],[127,70]]]]],[],[]],"autofocus",true,"class","gui-input new-style","name","externalUrl"],["loc",[null,[127,22],[127,134]]]],
        ["inline","button-save",[],["color","#ffffff","size","60","content",""],["loc",[null,[132,24],[132,76]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11]
    };
  }()));

});