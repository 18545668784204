define('jason-frontend/helpers/has-access', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.hasAccess = hasAccess;

  function hasAccess(params) {
    var access = params[0];
    var mandantId = API['default'].getMandant();
    if (access === 'calendar') {
      if (mandantId == 138 || mandantId == 10002) {
        return false;
      }
    }
    return true;
  }

  exports['default'] = Ember['default'].Helper.helper(hasAccess);

});