define('jason-frontend/routes/resourcemgmt/create', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return new Object();
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);

      Ember['default'].set(model, 'duration', '4');
      Ember['default'].set(model, 'start', '08:00');
    },
    actions: {
      create: function create() {
        var self = this;

        var controller = this.get('controller'),
            model = controller.get('model'),
            account = new Object();

        account.name = model.name;
        account.start = model.start;
        account.duration = new String(model.duration).replace(',', '.');

        if (!account.start || new String(account.start).indexOf(':') == -1) {
          jason.notifiction.error("Fehler", "Startzeit bitte im Format 08:00");
          return;
        }
        if (!account.duration || account.duration <= 0) {
          jason.notifiction.error("Fehler", "Bitte geben Sie eine Dauer > 0 Stunden ein");
          return;
        }

        $.ajax({
          url: "/api/appointmentLayerplans",
          method: "POST",
          data: JSON.stringify(account),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.transitionTo('layerplans');
        }, function () {
          self.transitionTo('layerplans');
        });
      }
    }
  });

});