define('jason-frontend/routes/purchases/confirmcancel', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('purchaseOrder', params.id);
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);

      var cancellationId = params.params['purchases.confirmcancel'].cancellationId;
      var deferred = $.ajax({
        url: "/api/purchaseOrders/" + model.id + "/" + cancellationId + "/cancelPreview",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function () {}, function () {
        controller.set('viewUrl', '/api/purchaseOrders/showCancellation/' + model.id + "/" + cancellationId);
        controller.set('renderPdf', true);
      });
    },
    actions: {
      download: function download(orderId) {
        var deferred = $.ajax({
          url: "/api/purchaseOrders/" + orderId + "/preview",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function () {
          window.open('/api/purchaseOrders/showCancellation/' + orderId);
        });
      }
    },
    show: function show() {
      var self = this;

      setTimeout(function () {
        self.get('controller').get('pdfPreview').send('show');
      }, 200);
    }
  });

});