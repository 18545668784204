define('jason-frontend/helpers/is-deposit-invoice', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isDepositInvoice = isDepositInvoice;

  function isDepositInvoice(params) {
    var invoice = params[0];

    if (invoice && invoice.get('deposit')) {
      return true;
    }
    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(isDepositInvoice);

});