define('jason-frontend/routes/servicecategories/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find('serviceCategory');
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model.sortBy('fullName'));
    },
    actions: {
      'delete': function _delete(id) {
        var controller = this.get('controller'),
            self = this;
        this.get('store').find('serviceCategory', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.store.find('serviceCategory').then(function (data) {
            controller.set('model', data.sortBy('fullName'));
          });
        }, 1000);
      }
    }
  });

});