define('jason-frontend/models/mandant-email-text', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    warning1subject: DS['default'].attr('string'),
    warning2subject: DS['default'].attr('string'),
    warning1headline: DS['default'].attr('string'),
    warning2headline: DS['default'].attr('string'),
    warning1text: DS['default'].attr('string'),
    warning2text: DS['default'].attr('string'),
    warning1closing: DS['default'].attr('string'),
    warning2closing: DS['default'].attr('string'),
    defaultReminderText: DS['default'].attr('string'),
    defaultReminderSubject: DS['default'].attr('string'),
    defaultReminderHeader: DS['default'].attr('string'),
    defaultReminderSalutation: DS['default'].attr('string'),
    defaultReminderClosing: DS['default'].attr('string'),
    invoiceSubject: DS['default'].attr('string'),
    invoiceHeadline: DS['default'].attr('string'),
    invoiceClosing: DS['default'].attr('string'),
    appReorderConfirm: DS['default'].attr('string'),
    appDefaultAppTitle: DS['default'].attr('string'),
    appDefaultAppDeclineTitle: DS['default'].attr('string'),
    mandantName: DS['default'].attr('')
  });

});