define('jason-frontend/models/purchase-transaction-fee', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    amount: DS['default'].attr('number'),
    booked: DS['default'].attr('date'),
    created: DS['default'].attr('date'),
    orderCode: DS['default'].attr('string'),
    orderId: DS['default'].attr('number'),
    orderTotal: DS['default'].attr('number')
  });

});