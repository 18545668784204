define('jason-frontend/components/notdeliverable-confirm', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      load: function load(product, info, orderId, modelName) {
        this.set('product', product);
        this.set('info', info);
        this.set('orderId', orderId);
        this.set('modelName', modelName);
      },
      orderAnyway: function orderAnyway() {
        this.sendAction('orderAnyway', this.get('product'), this.get('orderId'), this.get('info').id, this.get('modelName'));
        $.magnificPopup.close();
      }
    }
  });

});