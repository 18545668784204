define('jason-frontend/helpers/is-empty', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isEmpty = isEmpty;

  function isEmpty(params) {
    var value = params[0];
    if (value && value.length > 0) {
      return false;
    }
    return true;
  }

  exports['default'] = Ember['default'].Helper.helper(isEmpty);

});