define('jason-frontend/helpers/show-treatment-offer-entries', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.showTreatmentOfferEntries = showTreatmentOfferEntries;

  function showTreatmentOfferEntries(params) {
    var showServices = params[0],
        showArticles = params[1],
        showFeeding = params[2],
        showLabor = params[3],
        productCategory = params[4],
        serviceCategory = params[5],
        articleType = params[6],
        serviceCategories = ['be', 'div', 'fre', 'im', 'lab', 'op'];

    if (!showServices && !showArticles && !showFeeding && !showLabor) {
      return true;
    }

    if (showServices && articleType === 'service') {
      return true;
    }

    if (showArticles && productCategory !== 'ern' && articleType === 'product') {
      return true;
    }

    if (showFeeding && productCategory === 'ern') {
      return true;
    }

    if (showLabor && (productCategory === 'labor' || serviceCategory === 'lab')) {
      return true;
    }

    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(showTreatmentOfferEntries);

});