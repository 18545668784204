define('jason-frontend/components/edit-sloping-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    item: null,
    actions: {
      confirm: function confirm() {
        if (parseFloat(this.get('item').weightFrom) > parseFloat(this.get('item').weightTo)) {
          jason.notifiction.error('Fehler', 'Gewichtsklasse ab muss kleiner als Gewichtsklasse bis sein');
          return;
        }

        var cat = {
          id: this.get('item').patientCategory.id
        };

        var cat2;
        if (this.get('item').patientCategory2) {
          cat2 = {
            id: this.get('item').patientCategory2.id
          };
        }
        var cat3;
        if (this.get('item').patientCategory3) {
          cat3 = {
            id: this.get('item').patientCategory3.id
          };
        }

        var unit = {
          id: this.get('item').serviceunit.id
        };

        Ember['default'].set(this.get('item'), 'patientCategory', cat);
        Ember['default'].set(this.get('item'), 'patientCategory2', cat2);
        Ember['default'].set(this.get('item'), 'patientCategory3', cat3);
        // Ember.set(this.get('item'), 'patientCategory4', cat4);
        // Ember.set(this.get('item'), 'patientCategory5', cat5);

        Ember['default'].set(this.get('item'), 'serviceunit', unit);

        try {
          Ember['default'].set(this.get('item'), 'weightFrom', this.get('item').get('weightFrom').replace(",", "."));
        } catch (e) {
          Ember['default'].set(this.get('item'), 'weightFrom', String(this.get('item').weightFrom).replace(",", "."));
        }

        try {
          Ember['default'].set(this.get('item'), 'weightTo', this.get('item').get('weightTo').replace(",", "."));
        } catch (e) {
          Ember['default'].set(this.get('item'), 'weightTo', String(this.get('item').weightTo).replace(",", "."));
        }

        try {
          Ember['default'].set(this.get('item'), 'unitsPerDay', this.get('item').get('unitsPerDay').replace(",", "."));
        } catch (e) {
          Ember['default'].set(this.get('item'), 'unitsPerDay', String(this.get('item').unitsPerDay).replace(",", "."));
        }

        try {
          Ember['default'].set(this.get('item'), 'dosingsPerDay', this.get('item').get('dosingsPerDay').replace(",", "."));
        } catch (e) {
          Ember['default'].set(this.get('item'), 'dosingsPerDay', String(this.get('item').dosingsPerDay).replace(",", "."));
        }

        try {
          Ember['default'].set(this.get('item'), 'days', Math.floor(this.get('item').get('days').replace(",", ".")));
        } catch (e) {

          var days = String(this.get('item').days).replace(",", ".");

          Ember['default'].set(this.get('item'), 'days', parseInt(days));
        }

        this.sendAction('save', this.get('item'));
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(line, patientCategories, serviceUnits) {
        if (line) {
          this.set('item', line);
        } else {
          var line = {
            weightFrom: 0,
            weightTo: 0,
            days: 0,
            unitsPerDay: 0,
            dosingsPerDay: 0,
            patientCategory: {
              id: API['default'].getOperatingCountry().toLowerCase() === 'at' ? 'hu' : 'hund',
              name: 'Hund',
              laboklinCode: 'H'
            },
            serviceunit: {
              id: 1,
              name: 'Einheit'
            }
          };
          this.set('item', line);
        }
        this.set('patientCategories', patientCategories);
        this.set('serviceUnits', serviceUnits);
      }
    }
  });

});