define('jason-frontend/controllers/inventory/stocktaking-add', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Korrekturbuchung hinzufügen",
    breadCrumbPath: "inventory.stocktaking-add",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    pickDate: moment().format("DD. MM. YYYY") + " - " + moment().format("DD. MM. YYYY"),
    page: 1,
    showLoading: false,
    perPage: 20,
    filterStatus: 'created,used',
    myStickyOptions: {
      topSpacing: 80,
      zIndex: 9
    },
    myStickyOptionsTableHead: {
      topSpacing: 180,
      zIndex: 9
    },
    watchTime: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('pickDate'),
    actions: {
      scan: function scan(nr) {
        this.get('quickScanProductPanel').send('loadWithoutDirect');
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-quick-scan-product-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    }
  });

});