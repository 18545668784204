define('jason-frontend/components/purchase-entries', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      openLaboklin: function openLaboklin(order, entryId) {
        this.sendAction('openLaboklin', order, entryId);
      },
      'delete': function _delete(entryId, orderId, productName) {
        this.sendAction('deleted', entryId, orderId, productName);
      },
      statsLoaded: function statsLoaded() {
        this.sendAction('statsLoaded');
      },
      changeQuantity: function changeQuantity(entry, orderId, productName, oldQty, qty) {
        if (oldQty != qty) {
          qty = String(qty).replace(',', '.');
          if (qty > 0) {
            this.sendAction('quantityChanged', qty, entry.id, orderId, productName);
          } else {
            jason.notifiction.error('Fehleingabe', 'Menge muss größer/gleich 0 sein');
          }
        }
      },
      increaseQuantity: function increaseQuantity(entry, orderId, productName) {
        var qty = parseFloat(this.$().find("input[data-qty-entry-id='" + entry.id + "']").val());
        this.sendAction('quantityChanged', qty + 1, entry.id, orderId, productName);
      },
      decreaseQuantity: function decreaseQuantity(entry, orderId, productName) {
        var qty = parseFloat(this.$().find("input[data-qty-entry-id='" + entry.id + "']").val());
        this.sendAction('quantityChanged', qty - 1, entry.id, orderId, productName);
      },
      changeReturnQuantity: function changeReturnQuantity(entry, orderId, cancellationId, productName, oldQty) {
        var returnQty = this.$().find("input[data-return-qty-entry-id='" + entry.id + "']").val(),
            qty = parseFloat(this.$().find("input[data-qty-entry-id='" + entry.id + "']").val());

        if (oldQty != returnQty) {
          returnQty = String(returnQty).replace(',', '.');
          if (returnQty >= 0 && returnQty <= qty) {
            this.sendAction('returnQuantityChanged', returnQty, entry.id, orderId, cancellationId, productName);
          } else {
            jason.notifiction.error('Fehleingabe', 'Retournierte Menge muss größer oder gleich 0 sein');
            this.$().find("input[data-return-qty-entry-id='" + entry.id + "']").val(oldQty);
          }
        }
      },
      changeEntryLabel: function changeEntryLabel(entryId, label) {
        this.sendAction('entryLabelChanged', entryId, label);
      }
    }
  });

});