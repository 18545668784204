define('jason-frontend/models/future-action-blueprint', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    code: DS['default'].attr('string'),
    type: DS['default'].belongsTo('future-action-type'),
    triggerType: DS['default'].belongsTo('future-action-trigger-type'),
    articleId: DS['default'].attr('number'),
    timeUnit: DS['default'].belongsTo('time-unit'),
    timeUnitInterval: DS['default'].attr('number'),
    timeUnitIterations: DS['default'].attr('number'),
    reminderText: DS['default'].attr('string'),
    defaultReminderText: DS['default'].attr('string'),
    reminderSubject: DS['default'].attr('string'),
    defaultReminderSubject: DS['default'].attr('string'),
    reminderHeadline: DS['default'].attr('string'),
    defaultReminderHeadline: DS['default'].attr('string'),
    reminderSalutation: DS['default'].attr('string'),
    messageText: DS['default'].attr('string'),
    messageTitle: DS['default'].attr('string'),
    messageUsers: DS['default'].attr('string'),
    defaultReminderSalutation: DS['default'].attr('string'),
    reminderClosing: DS['default'].attr('string'),
    defaultReminderClosing: DS['default'].attr('string'),
    entries: DS['default'].hasMany('futureArticleEntryBlueprint'),
    templateEntries: DS['default'].attr(''),
    taskTitle: DS['default'].attr('string'),
    taskComment: DS['default'].attr('string'),
    taskResponsible: DS['default'].belongsTo('user'),
    appTitle: DS['default'].attr('string'),
    appDateTo: DS['default'].attr('string'),
    appDateFrom: DS['default'].attr('string'),
    appPersons: DS['default'].attr('string'),
    appLocation: DS['default'].attr('string'),
    appDescription: DS['default'].attr('string'),
    appMessageText: DS['default'].attr('string'),
    appMessageTitle: DS['default'].attr('string'),
    wholeDay: DS['default'].attr('boolean'),
    appCategory: DS['default'].attr(''),
    appRoom: DS['default'].attr(''),
    appStation: DS['default'].attr(''),
    appInternalUser1: DS['default'].attr(''),
    appInternalUser2: DS['default'].attr(''),
    appInternalUser3: DS['default'].attr(''),
    appInternalAssistant1: DS['default'].attr(''),
    appInternalAssistant2: DS['default'].attr(''),
    appInternalAssistant3: DS['default'].attr(''),
    treatmentCastrate: DS['default'].attr(''),
    treatmentDied: DS['default'].attr('')
  });

});