define('jason-frontend/templates/components/invoice-add-composite', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 8
            },
            "end": {
              "line": 9,
              "column": 72
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-add-composite.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["content","product.name",["loc",[null,[9,56],[9,72]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 98
            },
            "end": {
              "line": 11,
              "column": 171
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-add-composite.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
          return morphs;
        },
        statements: [
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","product.total",["loc",[null,[11,149],[11,162]]]]],[],[]]],["loc",[null,[11,125],[11,164]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 40
                },
                "end": {
                  "line": 31,
                  "column": 109
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-composite.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(":");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["content","entryProduct.gotNumberPrefix",["loc",[null,[31,76],[31,108]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 262
                },
                "end": {
                  "line": 31,
                  "column": 305
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-composite.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(", Zeit");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 12
              },
              "end": {
                "line": 32,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-composite.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","small block");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(", 1-fach netto ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(", Faktor: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("%");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(element0,0,0);
            morphs[1] = dom.createMorphAt(element0,2,2);
            morphs[2] = dom.createMorphAt(element0,4,4);
            morphs[3] = dom.createMorphAt(element0,6,6);
            morphs[4] = dom.createMorphAt(element0,8,8);
            return morphs;
          },
          statements: [
            ["block","if",[["get","entryProduct.gotNumberPrefix",["loc",[null,[31,46],[31,74]]]]],[],0,null,["loc",[null,[31,40],[31,116]]]],
            ["content","entryProduct.gotNumber",["loc",[null,[31,117],[31,143]]]],
            ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","entryProduct.priceNet",["loc",[null,[31,202],[31,223]]]]],[],[]]],["loc",[null,[31,158],[31,225]]]],
            ["content","entryProduct.gotFactor",["loc",[null,[31,235],[31,261]]]],
            ["block","if",[["subexpr","eq",[["get","entryProduct.unit",["loc",[null,[31,272],[31,289]]]],"Zeit"],[],["loc",[null,[31,268],[31,297]]]]],[],1,null,["loc",[null,[31,262],[31,312]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 68
                },
                "end": {
                  "line": 34,
                  "column": 172
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-composite.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge info-badge-very-small");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
              return morphs;
            },
            statements: [
              ["content","tag.name",["loc",[null,[34,152],[34,164]]]]
            ],
            locals: ["tag"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 12
              },
              "end": {
                "line": 35,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-composite.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","tags");
            dom.setAttribute(el1,"style","display: block !important;");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["block","each",[["get","entryProduct.tags",["loc",[null,[34,76],[34,93]]]]],[],0,null,["loc",[null,[34,68],[34,181]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 12
              },
              "end": {
                "line": 40,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-composite.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","price-line");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","is-important inline-block");
            dom.setAttribute(el2,"style","line-height:14px");
            var el3 = dom.createTextNode("Der betreffende Artikel wurden vom Lieferanten aktualisiert. Bitte überprüfen Sie die Möglichkeit eines alternativen Artikels!");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 6
            },
            "end": {
              "line": 50,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-add-composite.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-4/24 l-2/24@desk text-center permanent-caption");
          dom.setAttribute(el2,"data-label"," ");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"height","20");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-8/24@desk permanent-caption");
          dom.setAttribute(el2,"data-label","Name");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-7/24 l-3/24@desk text-center permanent-caption");
          dom.setAttribute(el2,"data-label","VPE");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","tax");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-4/24 l-3/24@desk text-center permanent-caption");
          dom.setAttribute(el2,"data-label","Mwst");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" %");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1, 1, 0]);
          var element3 = dom.childAt(element1, [3]);
          var element4 = dom.childAt(element1, [5]);
          var morphs = new Array(9);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createAttrMorph(element2, 'src');
          morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
          morphs[3] = dom.createMorphAt(element3,3,3);
          morphs[4] = dom.createMorphAt(element3,4,4);
          morphs[5] = dom.createMorphAt(element3,5,5);
          morphs[6] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
          morphs[7] = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
          morphs[8] = dom.createMorphAt(dom.childAt(element1, [7, 1]),0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["table__row ",["subexpr","css-bool-evaluator",[["get","entryProduct.first",["loc",[null,[24,52],[24,70]]]],"first"],[],["loc",[null,[24,31],[24,81]]]]]]],
          ["attribute","src",["concat",["assets/images/icons/",["get","entryProduct.type",["loc",[null,[26,62],[26,79]]]],"-dark.svg"]]],
          ["content","entryProduct.name",["loc",[null,[29,18],[29,39]]]],
          ["block","if",[["get","entryProduct.gotNumber",["loc",[null,[30,18],[30,40]]]]],[],0,null,["loc",[null,[30,12],[32,19]]]],
          ["block","if",[["get","entryProduct.tags",["loc",[null,[33,18],[33,35]]]]],[],1,null,["loc",[null,[33,12],[35,19]]]],
          ["block","if",[["get","entryProduct.deleted",["loc",[null,[36,18],[36,38]]]]],[],2,null,["loc",[null,[36,12],[40,19]]]],
          ["content","entryProduct.unitQuantity",["loc",[null,[43,18],[43,47]]]],
          ["content","entryProduct.unit",["loc",[null,[44,30],[44,51]]]],
          ["content","entryProduct.tax",["loc",[null,[47,18],[47,38]]]]
        ],
        locals: ["entryProduct"],
        templates: [child0, child1, child2]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 54,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/invoice-add-composite.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table-center table__cell--favs l-4/24 l-2/24@desk");
        dom.setAttribute(el1,"data-label","");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--name l-15/24 l-11/24@desk");
        dom.setAttribute(el1,"data-label","Bezeichnung");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell table__cell--right l-5/24 l-5/24@desk price-value");
        dom.setAttribute(el1,"data-label","VK brutto");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell spinner-button-left absolute-fix text-center l-24/24 l-3/24@desk table__cell--spinner");
        dom.setAttribute(el1,"data-label","Menge");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell spinner-button-right absolute-fix table__cell--action l-6/24 l-3/24@desk buttons-1");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button icon-button--success list-action-square exp");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode("Hinzufügen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table__cell l-24/24 pb50 pl0 no-caption");
        dom.setAttribute(el1,"data-label","");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table grid table--sub table--composite");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__body");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0, 1, 1]);
        var element6 = dom.childAt(fragment, [8, 1]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element5, 'class');
        morphs[1] = dom.createElementMorph(element5);
        morphs[2] = dom.createMorphAt(element5,1,1);
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [2, 1]),0,0);
        morphs[4] = dom.createMorphAt(dom.childAt(fragment, [4]),0,0);
        morphs[5] = dom.createMorphAt(dom.childAt(fragment, [6]),1,1);
        morphs[6] = dom.createElementMorph(element6);
        morphs[7] = dom.createMorphAt(element6,1,1);
        morphs[8] = dom.createMorphAt(dom.childAt(fragment, [10, 1, 1]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["selectable-item favs cursor ",["subexpr","css-bool-evaluator",[["get","product.favorite",["loc",[null,[3,134],[3,150]]]],"selected",""],[],["loc",[null,[3,113],[3,166]]]]]]],
        ["element","action",["toggleFavorite",["get","product",["loc",[null,[3,31],[3,38]]]],["subexpr","not",[["get","product.favorite",["loc",[null,[3,44],[3,60]]]]],[],["loc",[null,[3,39],[3,61]]]]],["bubbles",false],["loc",[null,[3,5],[3,77]]]],
        ["inline","button-favorite",[],["size","18","color","#999999","showStroke",false,"content",""],["loc",[null,[4,4],[4,77]]]],
        ["block","link-to",["compositeproducts.edit",["get","product.id",["loc",[null,[9,44],[9,54]]]]],[],0,null,["loc",[null,[9,8],[9,84]]]],
        ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[11,104],[11,117]]]]],[],1,null,["loc",[null,[11,98],[11,178]]]],
        ["inline","simple-number-spinner2",[],["value",1,"internalid",["subexpr","@mut",[["get","product.id",["loc",[null,[13,46],[13,56]]]]],[],[]]],["loc",[null,[13,2],[13,58]]]],
        ["element","action",["addProduct",["get","product",["loc",[null,[16,91],[16,98]]]],["get","typeId",["loc",[null,[16,99],[16,105]]]],0,["get","product.name",["loc",[null,[16,108],[16,120]]]]],[],["loc",[null,[16,69],[16,122]]]],
        ["inline","button-add-cart",[],["content","","showStroke",false,"color","#fff","size","32"],["loc",[null,[17,4],[17,74]]]],
        ["block","each",[["get","product.entries",["loc",[null,[23,14],[23,29]]]]],[],2,null,["loc",[null,[23,6],[50,15]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});