define('jason-frontend/controllers/purchases/promotions', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Angebote",
    breadCrumbPath: "purchases.promotions",
    queryParams: ["page", "perPage"],
    products: null,
    sortBy: null,
    sortDir: null,
    promotionActiveFilter: null,
    filterPromotionCategory: null,
    list: false,
    myStickyOptions: {
      topSpacing: 80,
      zIndex: 9
    },
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    perPage: 16,
    watchSupplier: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterSupplier'),
    watchPromotionCategory: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterPromotionCategory')
  });

});