define('jason-frontend/controllers/inventory/bookin-request', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    filterOrderStatus: "exported",
    breadCrumb: "Lagerbestand buchen",
    breadCrumbPath: "inventory.bookin-request",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    queryParams: ["type", "stocktakingId"],
    totalPagesBinding: "content.totalPages",
    setQuantity: function setQuantity(entryId, requestId, qty, vpe) {

      var self = this;

      $.ajax({
        url: "/api/inventoryRequests/" + requestId + "/setQuantity/" + entryId + "/" + qty + "/" + btoa(vpe.id),
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('model', data.inventoryRequestSupplier);
      }, function () {});
    },
    actions: {
      updateBarcode: function updateBarcode(value, entry) {

        var data = {
          code: value
        };

        $.ajax({
          url: "/api/inventoryStocks/convertDataMatrix",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data2) {
          if ('na' === data2.chargeNumber) {
            jason.notifiction.warning('Chargennummer nicht erkannt', '');
          } else {
            Ember['default'].set(entry, 'chargeNumber', data2.chargeNumber);
          }
          if (data2.expiryDate) {
            Ember['default'].set(entry, 'expiryDate', moment(data2.expiryDate).format('DD. MM. YYYY'));
          }
        }, function (data2) {});
      },
      openScan: function openScan(entry) {
        this.get('quickScanChargePanel').send('load', entry);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-quick-scan-charge-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      changeQuantity: function changeQuantity(entry, requestId) {
        var field = $("span[data-qty-entry-id='" + entry.id + "'] > input");
        var qty = parseFloat(field.val());
        var self = this;
        if (qty < 1) {
          qty = 1;
        }
        field.val(qty);
        this.setQuantity(entry.id, requestId, qty, entry.vpe);
      },
      increaseQuantity: function increaseQuantity(entry, requestId) {
        var field = $("span[data-qty-entry-id='" + entry.id + "'] > input");
        var qty = parseFloat(field.val());
        qty++;
        field.val(qty);

        this.setQuantity(entry.id, requestId, qty, entry.vpe);
      },
      decreaseQuantity: function decreaseQuantity(entry, requestId) {
        var field = $("span[data-qty-entry-id='" + entry.id + "'] > input");
        var qty = parseFloat(field.val());
        qty--;
        if (qty < 1) {
          qty = 1;
        } else {
          this.setQuantity(entry.id, requestId, qty, entry.vpe);
        }
        field.val(qty);
      }
    },
    watchFilterOrderStatus: (function () {
      this.send('applyFilter');
    }).observes('filterOrderStatus'),
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('pickDate'),
    watchWarehouse: (function () {
      var target = this.get('overrideWarehouse');
      if (target) {
        this.send('changeWarehouse', target);
      }
    }).observes('overrideWarehouse')
  });

});