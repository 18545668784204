define('jason-frontend/templates/components/button-save-confirm', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-save-confirm.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"class","btn-delete");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el2 = dom.createElement("svg");
        dom.setAttribute(el2,"class","button--delete");
        dom.setAttribute(el2,"viewBox","0 0 32 32");
        dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M11.8116,1.7016 L10.299,0.189 C10.242,0.132 10.1652,0.1002 10.0848,0.1002 L8.8746,0.1002 L3.1254,0.1002 L1.0074,0.1002 C0.507,0.1002 0.0996,0.5076 0.0996,1.008 L0.0996,10.9926 C0.0996,11.493 0.507,11.9004 1.0074,11.9004 L2.52,11.9004 L9.4788,11.9004 L10.9914,11.9004 C11.4918,11.9004 11.8992,11.493 11.8992,10.9926 L11.8992,1.9152 C11.8998,1.8354 11.868,1.758 11.8116,1.7016 Z M7.059,0.705 L8.5716,0.705 L8.5716,3.4278 C8.5716,3.5946 8.436,3.7302 8.2692,3.7302 L7.059,3.7302 L7.059,0.705 L7.059,0.705 Z M3.4284,0.705 L6.4542,0.705 L6.4542,3.7308 L3.7308,3.7308 C3.564,3.7308 3.4284,3.5952 3.4284,3.4284 L3.4284,0.705 Z M9.177,11.295 L2.823,11.295 L2.823,7.6644 L9.177,7.6644 L9.177,11.295 Z M11.295,10.9926 C11.295,11.1594 11.1594,11.295 10.9926,11.295 L9.7824,11.295 L9.7824,7.3614 C9.7824,7.1946 9.6468,7.059 9.48,7.059 L2.5206,7.059 C2.3538,7.059 2.2182,7.1946 2.2182,7.3614 L2.2182,11.295 L1.0074,11.295 C0.8406,11.295 0.705,11.1594 0.705,10.9926 L0.705,1.0074 C0.705,0.8406 0.8406,0.705 1.0074,0.705 L2.823,0.705 L2.823,3.4278 C2.823,3.9282 3.2304,4.3356 3.7308,4.3356 L8.2692,4.3356 C8.7696,4.3356 9.177,3.9282 9.177,3.4278 L9.177,0.705 L9.9594,0.705 L11.295,2.0406 L11.295,10.9926 Z");
        dom.setAttribute(el4,"transform","translate(10 10)");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        dom.setNamespace(null);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","delete-confirm medium-confirm");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","footer");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","button");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createTextNode("Nein");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","button");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createTextNode("Ja");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1, 1]);
        var element3 = dom.childAt(fragment, [2]);
        var element4 = dom.childAt(element3, [3]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element4, [3]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element0, 'data-id');
        morphs[1] = dom.createAttrMorph(element0, 'title');
        morphs[2] = dom.createElementMorph(element0);
        morphs[3] = dom.createAttrMorph(element1, 'width');
        morphs[4] = dom.createAttrMorph(element1, 'height');
        morphs[5] = dom.createAttrMorph(element2, 'fill');
        morphs[6] = dom.createMorphAt(element3,1,1);
        morphs[7] = dom.createElementMorph(element5);
        morphs[8] = dom.createElementMorph(element6);
        return morphs;
      },
      statements: [
        ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[1,54],[1,61]]]]]]],
        ["attribute","title",["concat",[["get","tooltip",["loc",[null,[1,74],[1,81]]]]]]],
        ["element","action",["confirm"],[],["loc",[null,[1,3],[1,23]]]],
        ["attribute","width",["concat",[["get","size",["loc",[null,[2,41],[2,45]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[2,59],[2,63]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[4,22],[4,27]]]]]]],
        ["content","text",["loc",[null,[9,2],[9,10]]]],
        ["element","action",["cancel"],[],["loc",[null,[11,11],[11,30]]]],
        ["element","action",["delete"],[],["loc",[null,[12,11],[12,30]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});