define('jason-frontend/components/invoice-nopayment', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      activateExternalPayment: function activateExternalPayment() {
        var given = $('#given1').val();
        if (given) {
          given = given.replace(",", ".");
        }
        if (given && given > 0) {
          $('#extern1').attr('checked', 'checked');
        }
      },
      registerPayments: function registerPayments() {
        var externalPayment = $('#extern1').is(':checked');
        var given = $('#given1').val();
        var open = this.get('open');
        if (given) {
          given = given.replace(",", ".");
        }

        if (given && given > 0 && !externalPayment) {
          jason.notifiction.warning('Achtung', 'Bitte Zahlungsmittel Extern kassieren auswählen');
          return;
        }

        if (externalPayment) {
          if (given && given > open) {
            jason.notifiction.warning('Achtung', 'Gegebener Betrag darf die Rechnungssumme nicht überschreiten');
            return;
          }

          this.sendAction('continueWithExternalCashRegister', given);
        } else {
          this.sendAction('continue');
        }
      },
      toSelectPositions: function toSelectPositions() {
        this.sendAction('toSelectPositions');
      }
    },
    roundToTwo: function roundToTwo(value) {
      var format = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      var value = Math.round(value * 100) / 100;
      if (format) {
        return ('' + value).replace('.', ',');
      }
      return value;
    },
    getLocalizedValue: function getLocalizedValue(apiValue) {
      return String(apiValue).replace('.', ',');
    }
  });

});