define('jason-frontend/routes/purchases/board', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      $.ajax({
        url: "/api/purchaseOrders/dashboard",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('data', data.purchaseOrderDashboard);
      });
    }
  });

});