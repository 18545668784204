define('jason-frontend/templates/partials/personal-settings', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 2
              },
              "end": {
                "line": 11,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/partials/personal-settings.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Benachrichtigungen\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-quickaction-notification",[],["size","20","color","#666666","showStroke",false,"classNames","inline-icon","content",""],["loc",[null,[10,4],[10,118]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/partials/personal-settings.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","https://portal.vetnative.com");
          dom.setAttribute(el1,"class","cursor");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/icon_portal.svg");
          dom.setAttribute(el2,"class","inline-icon");
          dom.setAttribute(el2,"style","width: 12px;margin-left:2px;margin-right: 5px;vertical-align: middle;");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" VetNative Portal\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"target","_blank");
          dom.setAttribute(el1,"href","https://identity.vetnative.com/oauth2/authorize?response_type=code&client_id=client-odoo&redirect_uri=https%3A%2F%2Fservice.vetnative.com%2Fauth_oauth%2Fsignin&scope=openid+profile+email&state=%7B%22d%22%3A+%22vetnative-odoo-master-9363469%22%2C+%22p%22%3A+4%2C+%22r%22%3A+%22https%253A%252F%252Fservice.vetnative.com%252Fmy%252Fhome%253F%22%7D&nonce=37OqzVRRG4UtsVT8dFnortTBAzyCODpUpU_5yMOWToQ&code_challenge=3soUbb0OV1P0U4tiLL4lxESQlPOOmw9XE2Y_fUjuNcg&code_challenge_method=S256");
          dom.setAttribute(el1,"class","cursor");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","fa fa-link pr5");
          dom.setAttribute(el2,"style","padding-left: 4px");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Service Center\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("hr");
          dom.setAttribute(el1,"style","margin-bottom: 5px;margin-top: 5px;");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,7,7,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["notifications"],[],0,null,["loc",[null,[9,2],[11,14]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 2
              },
              "end": {
                "line": 16,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/partials/personal-settings.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Aufgaben\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-checked",[],["size","20","color","#666666","showStroke",false,"classNames","inline-icon","content",""],["loc",[null,[15,4],[15,101]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 2
              },
              "end": {
                "line": 44,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/partials/personal-settings.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            dom.setNamespace("http://www.w3.org/2000/svg");
            var el1 = dom.createElement("svg");
            dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
            dom.setAttribute(el1,"width","20");
            dom.setAttribute(el1,"height","20");
            dom.setAttribute(el1,"viewBox","0 0 34 34");
            dom.setAttribute(el1,"class","inline-icon");
            dom.setAttribute(el1,"style","margin-right: 3px");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("g");
            dom.setAttribute(el2,"fill","none");
            dom.setAttribute(el2,"fill-rule","evenodd");
            dom.setAttribute(el2,"transform","translate(1 1)");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("g");
            dom.setAttribute(el3,"fill","#666666");
            dom.setAttribute(el3,"transform","translate(5,5) scale(1.2,1.2)");
            dom.setAttribute(el3,"fill-rule","evenodd");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("path");
            dom.setAttribute(el4,"d","M3.669,3.71h0.696c0.256,0,0.464-0.165,0.464-0.367V0.367C4.829,0.164,4.621,0,4.365,0H3.669\n        C3.414,0,3.206,0.164,3.206,0.367v2.976C3.205,3.545,3.413,3.71,3.669,3.71z");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("path");
            dom.setAttribute(el4,"d","M10.95,3.71h0.696c0.256,0,0.464-0.165,0.464-0.367V0.367C12.11,0.164,11.902,0,11.646,0H10.95\n        c-0.256,0-0.463,0.164-0.463,0.367v2.976C10.487,3.545,10.694,3.71,10.95,3.71z");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("path");
            dom.setAttribute(el4,"d","M14.512,1.42h-1.846v2.278c0,0.509-0.458,0.923-1.021,0.923h-0.696\n        c-0.563,0-1.021-0.414-1.021-0.923V1.42H5.384v2.278c0,0.509-0.458,0.923-1.021,0.923H3.669c-0.562,0-1.02-0.414-1.02-0.923V1.42\n        H0.803c-0.307,0-0.557,0.25-0.557,0.557V14.76c0,0.307,0.25,0.555,0.557,0.555h13.709c0.308,0,0.557-0.248,0.557-0.555V1.977\n        C15.069,1.67,14.82,1.42,14.512,1.42z M14.316,9.49v4.349c0,0.096-0.078,0.176-0.175,0.176H7.457H1.174\n        c-0.097,0-0.175-0.08-0.175-0.176V10.31V5.961c0-0.096,0.078-0.176,0.175-0.176h6.683h6.284l0,0c0.097,0,0.175,0.08,0.175,0.176\n        V9.49z");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("rect");
            dom.setAttribute(el4,"x","2.327");
            dom.setAttribute(el4,"y","8.93");
            dom.setAttribute(el4,"width","1.735");
            dom.setAttribute(el4,"height","1.736");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("rect");
            dom.setAttribute(el4,"x","5.28");
            dom.setAttribute(el4,"y","8.93");
            dom.setAttribute(el4,"width","1.735");
            dom.setAttribute(el4,"height","1.736");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("rect");
            dom.setAttribute(el4,"x","8.204");
            dom.setAttribute(el4,"y","8.93");
            dom.setAttribute(el4,"width","1.734");
            dom.setAttribute(el4,"height","1.736");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("rect");
            dom.setAttribute(el4,"x","11.156");
            dom.setAttribute(el4,"y","8.93");
            dom.setAttribute(el4,"width","1.736");
            dom.setAttribute(el4,"height","1.736");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("rect");
            dom.setAttribute(el4,"x","2.363");
            dom.setAttribute(el4,"y","11.432");
            dom.setAttribute(el4,"width","1.736");
            dom.setAttribute(el4,"height","1.736");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("rect");
            dom.setAttribute(el4,"x","5.317");
            dom.setAttribute(el4,"y","11.432");
            dom.setAttribute(el4,"width","1.735");
            dom.setAttribute(el4,"height","1.736");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("rect");
            dom.setAttribute(el4,"x","8.241");
            dom.setAttribute(el4,"y","11.432");
            dom.setAttribute(el4,"width","1.734");
            dom.setAttribute(el4,"height","1.736");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("rect");
            dom.setAttribute(el4,"x","11.194");
            dom.setAttribute(el4,"y","11.432");
            dom.setAttribute(el4,"width","1.735");
            dom.setAttribute(el4,"height","1.736");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("rect");
            dom.setAttribute(el4,"x","8.215");
            dom.setAttribute(el4,"y","6.47");
            dom.setAttribute(el4,"width","1.735");
            dom.setAttribute(el4,"height","1.735");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("rect");
            dom.setAttribute(el4,"x","11.17");
            dom.setAttribute(el4,"y","6.47");
            dom.setAttribute(el4,"width","1.734");
            dom.setAttribute(el4,"height","1.735");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("Termine\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1, 1]);
            var element1 = dom.childAt(element0, [1]);
            var element2 = dom.childAt(element0, [3]);
            var element3 = dom.childAt(element0, [5]);
            var element4 = dom.childAt(element0, [7]);
            var element5 = dom.childAt(element0, [9]);
            var element6 = dom.childAt(element0, [11]);
            var element7 = dom.childAt(element0, [13]);
            var element8 = dom.childAt(element0, [15]);
            var element9 = dom.childAt(element0, [17]);
            var element10 = dom.childAt(element0, [19]);
            var element11 = dom.childAt(element0, [21]);
            var element12 = dom.childAt(element0, [23]);
            var element13 = dom.childAt(element0, [25]);
            var morphs = new Array(13);
            morphs[0] = dom.createAttrMorph(element1, 'style');
            morphs[1] = dom.createAttrMorph(element2, 'style');
            morphs[2] = dom.createAttrMorph(element3, 'style');
            morphs[3] = dom.createAttrMorph(element4, 'style');
            morphs[4] = dom.createAttrMorph(element5, 'style');
            morphs[5] = dom.createAttrMorph(element6, 'style');
            morphs[6] = dom.createAttrMorph(element7, 'style');
            morphs[7] = dom.createAttrMorph(element8, 'style');
            morphs[8] = dom.createAttrMorph(element9, 'style');
            morphs[9] = dom.createAttrMorph(element10, 'style');
            morphs[10] = dom.createAttrMorph(element11, 'style');
            morphs[11] = dom.createAttrMorph(element12, 'style');
            morphs[12] = dom.createAttrMorph(element13, 'style');
            return morphs;
          },
          statements: [
            ["attribute","style",["concat",["fill:",["get","color",["loc",[null,[21,30],[21,35]]]],";"]]],
            ["attribute","style",["concat",["fill:",["get","color",["loc",[null,[23,30],[23,35]]]],";"]]],
            ["attribute","style",["concat",["fill:",["get","color",["loc",[null,[25,30],[25,35]]]],";"]]],
            ["attribute","style",["concat",["fill:",["get","color",["loc",[null,[31,49],[31,54]]]],";"]]],
            ["attribute","style",["concat",["fill:",["get","color",["loc",[null,[32,48],[32,53]]]],";"]]],
            ["attribute","style",["concat",["fill:",["get","color",["loc",[null,[33,49],[33,54]]]],";"]]],
            ["attribute","style",["concat",["fill:",["get","color",["loc",[null,[34,50],[34,55]]]],";"]]],
            ["attribute","style",["concat",["fill:",["get","color",["loc",[null,[35,51],[35,56]]]],";"]]],
            ["attribute","style",["concat",["fill:",["get","color",["loc",[null,[36,51],[36,56]]]],";"]]],
            ["attribute","style",["concat",["fill:",["get","color",["loc",[null,[37,51],[37,56]]]],";"]]],
            ["attribute","style",["concat",["fill:",["get","color",["loc",[null,[38,52],[38,57]]]],";"]]],
            ["attribute","style",["concat",["fill:",["get","color",["loc",[null,[39,49],[39,54]]]],";"]]],
            ["attribute","style",["concat",["fill:",["get","color",["loc",[null,[40,49],[40,54]]]],";"]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 0
            },
            "end": {
              "line": 45,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/partials/personal-settings.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["tasks"],[],0,null,["loc",[null,[14,2],[16,14]]]],
          ["block","link-to",["appointments",["subexpr","query-params",[],["filterMe",true],["loc",[null,[17,28],[17,56]]]]],[],1,null,["loc",[null,[17,2],[44,14]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 0
            },
            "end": {
              "line": 48,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/partials/personal-settings.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","fa fa-user pr5");
          dom.setAttribute(el1,"style","padding-left: 4px");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" Profil\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 0
            },
            "end": {
              "line": 57,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/partials/personal-settings.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","fa fa-power-off pr5");
          dom.setAttribute(el1,"style","padding-left: 4px");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" Abmelden\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 58,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/partials/personal-settings.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.setAttribute(el1,"style","margin-bottom: 5px;margin-top: 5px;");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"target","_blank");
        dom.setAttribute(el1,"href","https://www.vetnative.com/datenschutz/");
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","fa fa-link pr5");
        dom.setAttribute(el2,"style","padding-left: 4px");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" Datenschutzerklärung");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"target","_blank");
        dom.setAttribute(el1,"href","https://www.vetnative.com/nutzungsbedingungen/");
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","fa fa-link pr5");
        dom.setAttribute(el2,"style","padding-left: 4px");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" Nutzungsbedingungen");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.setAttribute(el1,"style","margin-bottom: 5px;margin-top: 5px;");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"target","_blank");
        dom.setAttribute(el1,"href","https://www.vetnative.com/impressum/");
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","fa fa-link pr5");
        dom.setAttribute(el2,"style","padding-left: 4px");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" Impressum");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.setAttribute(el1,"style","margin-bottom: 5px;margin-top: 5px;");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
        morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[3] = dom.createMorphAt(fragment,15,15,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[1,11],[1,24]]]]],[],["loc",[null,[1,6],[1,25]]]]],[],0,null,["loc",[null,[1,0],[12,7]]]],
        ["block","if",[["subexpr","is-at-least",["professional"],[],["loc",[null,[13,6],[13,34]]]]],[],1,null,["loc",[null,[13,0],[45,7]]]],
        ["block","link-to",["profile"],[],2,null,["loc",[null,[46,0],[48,12]]]],
        ["block","link-to",["logout"],[],3,null,["loc",[null,[55,0],[57,12]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});