define('jason-frontend/components/show-appointment-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    type: null,
    selectTab: 'show',
    item: null,
    filtersShown: false,
    actions: {
      goto: function goto(to) {
        this.sendAction('goto', to, this.get('item'));
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      toCustomer: function toCustomer(id) {
        this.sendAction('linkTo', 'customers.edit', id);
        $.magnificPopup.close();
      },
      toPatient: function toPatient(id) {
        this.sendAction('linkTo', 'patients.edit', id);
        $.magnificPopup.close();
      },
      load: function load(item) {

        this.set('item', item);
        this.set('message', null);
        this.set('history', null);
        this.set('selectTab', 'show');
        this.set('comment', null);
      }
    }
  });

});