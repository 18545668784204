define('jason-frontend/templates/components/treatment-info-form', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 12
              },
              "end": {
                "line": 31,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class"," col l-12/24");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","field col l-12/24");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"for","taskCustomer");
            dom.setAttribute(el3,"class","field-label title");
            var el4 = dom.createTextNode("Kunde/in");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","cursor is-highlighted");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1, 1, 3]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element12);
            morphs[1] = dom.createMorphAt(element12,0,0);
            morphs[2] = dom.createMorphAt(element12,2,2);
            return morphs;
          },
          statements: [
            ["element","action",["toCustomer",["get","taskData.customer.id",["loc",[null,[28,73],[28,93]]]]],[],["loc",[null,[28,51],[28,95]]]],
            ["content","taskData.customer.firstname",["loc",[null,[28,96],[28,127]]]],
            ["content","taskData.customer.lastname",["loc",[null,[28,128],[28,158]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 15
              },
              "end": {
                "line": 37,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-11/24");
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","field col l-12/24");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"for","taskPatient");
            dom.setAttribute(el3,"class","field-label title");
            var el4 = dom.createTextNode("Patient");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","cursor is-highlighted");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [0, 0, 3]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element11);
            morphs[1] = dom.createMorphAt(element11,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["toPatient",["get","taskData.patient.id",["loc",[null,[34,66],[34,85]]]]],[],["loc",[null,[34,45],[34,87]]]],
            ["content","taskData.patient.name",["loc",[null,[34,88],[34,113]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 16
              },
              "end": {
                "line": 43,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["content","taskData.user.firstname",["loc",[null,[42,18],[42,45]]]],
            ["content","taskData.user.lastname",["loc",[null,[42,46],[42,72]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 16
              },
              "end": {
                "line": 45,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  Alle\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 14
              },
              "end": {
                "line": 52,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["content","taskData.delegatedBy.firstname",["loc",[null,[51,16],[51,50]]]],
            ["content","taskData.delegatedBy.lastname",["loc",[null,[51,51],[51,84]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 8
            },
            "end": {
              "line": 57,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","panel-body p25 pb0");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class"," col l-12/24");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","new-not-title");
          dom.setAttribute(el4,"class","field-label title");
          var el5 = dom.createTextNode("Aufgabe");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class"," col l-12/24");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","taskComment");
          dom.setAttribute(el4,"class","field-label title");
          var el5 = dom.createTextNode("Notiz");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class"," col l-12/24");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","taskComment");
          dom.setAttribute(el4,"class","field-label title");
          var el5 = dom.createTextNode("Verantwortlich");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class"," col l-12/24");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","taskComment");
          dom.setAttribute(el4,"class","field-label title");
          var el5 = dom.createTextNode("Delegiert von");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element13 = dom.childAt(fragment, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(dom.childAt(element13, [1, 1]),3,3);
          morphs[1] = dom.createMorphAt(dom.childAt(element13, [3, 1]),3,3);
          morphs[2] = dom.createMorphAt(element13,5,5);
          morphs[3] = dom.createMorphAt(element13,7,7);
          morphs[4] = dom.createMorphAt(dom.childAt(element13, [9, 1]),3,3);
          morphs[5] = dom.createMorphAt(dom.childAt(element13, [11, 1]),3,3);
          return morphs;
        },
        statements: [
          ["content","taskData.title",["loc",[null,[15,16],[15,34]]]],
          ["content","taskData.notice",["loc",[null,[21,14],[21,33]]]],
          ["block","if",[["get","taskData.customer",["loc",[null,[24,18],[24,35]]]]],[],0,null,["loc",[null,[24,12],[31,19]]]],
          ["block","if",[["get","taskData.patient",["loc",[null,[32,21],[32,37]]]]],[],1,null,["loc",[null,[32,15],[37,17]]]],
          ["block","if",[["get","taskData.user",["loc",[null,[41,22],[41,35]]]]],[],2,3,["loc",[null,[41,16],[45,23]]]],
          ["block","if",[["get","taskData.delegatedBy",["loc",[null,[50,20],[50,40]]]]],[],4,null,["loc",[null,[50,14],[52,21]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 14
            },
            "end": {
              "line": 72,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","section");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field field-datepicker");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","date-picker__body");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      am ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      um ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Uhr\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [1, 1, 1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element10,1,1);
          morphs[1] = dom.createMorphAt(element10,3,3);
          morphs[2] = dom.createMorphAt(element10,5,5);
          return morphs;
        },
        statements: [
          ["inline","icon-calendar",[],["color","#ADCCD4","width","20","class","date-picker__icon"],["loc",[null,[66,22],[66,92]]]],
          ["inline","input",[],["id","treatmentDate","value",["subexpr","@mut",[["get","data.date",["loc",[null,[67,58],[67,67]]]]],[],[]],"class","fixed-datepicker-width js-date-picker input date-picker__input","name","activeFrom","placeholder","Datum"],["loc",[null,[67,25],[67,178]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","date.time",["loc",[null,[68,39],[68,48]]]]],[],[]],"value",["subexpr","@mut",[["get","data.time",["loc",[null,[68,55],[68,64]]]]],[],[]],"id","treatmentTime","name","taskDuetime","class","fixed-timepicker-width input date-picker__input time"],["loc",[null,[68,25],[68,165]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 78,
              "column": 20
            },
            "end": {
              "line": 85,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"for","overlay_medicalHistory");
          dom.setAttribute(el1,"class","field prepend-icon");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","overlay_medicalHistory");
          dom.setAttribute(el2,"class","field-icon");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","fa fa-comments");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","textarea",[],["id","overlay_medicalHistory","name","overlay_medicalHistory","value",["subexpr","@mut",[["get","data.medicalHistory",["loc",[null,[80,97],[80,116]]]]],[],[]],"class","gui-textarea"],["loc",[null,[80,22],[80,139]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 85,
                "column": 20
              },
              "end": {
                "line": 88,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","field");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","hidden");
            dom.setAttribute(el1,"id","overlay_medicalHistory");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            morphs[1] = dom.createAttrMorph(element9, 'value');
            return morphs;
          },
          statements: [
            ["content","data.medicalHistory",["loc",[null,[86,43],[86,66]]]],
            ["attribute","value",["concat",[["get","data.medicalHistory",["loc",[null,[87,80],[87,99]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 88,
                "column": 20
              },
              "end": {
                "line": 91,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","field");
            var el2 = dom.createTextNode("-");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","hidden");
            dom.setAttribute(el1,"id","overlay_medicalHistory");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                    ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [3]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element8, 'value');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","data.medicalHistory",["loc",[null,[90,80],[90,99]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 85,
              "column": 20
            },
            "end": {
              "line": 91,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","is-etiga-readable",["addAnam",["get","etigaPermissions",["loc",[null,[85,59],[85,75]]]],["get","patient",["loc",[null,[85,76],[85,83]]]]],[],["loc",[null,[85,30],[85,84]]]]],[],0,1,["loc",[null,[85,20],[91,20]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 97,
              "column": 16
            },
            "end": {
              "line": 104,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"for","overlay_symptom");
          dom.setAttribute(el1,"class","field prepend-icon");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","overlay_symptom");
          dom.setAttribute(el2,"class","field-icon");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","fa fa-comments");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","textarea",[],["id","overlay_symptom","name","overlay_symptom","value",["subexpr","@mut",[["get","data.symptom",["loc",[null,[99,83],[99,95]]]]],[],[]],"class","gui-textarea"],["loc",[null,[99,22],[99,118]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 104,
                "column": 16
              },
              "end": {
                "line": 107,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","field");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","hidden");
            dom.setAttribute(el1,"id","overlay_symptom");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            morphs[1] = dom.createAttrMorph(element7, 'value');
            return morphs;
          },
          statements: [
            ["content","data.symptom",["loc",[null,[105,39],[105,55]]]],
            ["attribute","value",["concat",[["get","data.symptom",["loc",[null,[106,69],[106,81]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 107,
                "column": 16
              },
              "end": {
                "line": 110,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","field");
            var el2 = dom.createTextNode("-");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","hidden");
            dom.setAttribute(el1,"id","overlay_symptom");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [3]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element6, 'value');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","data.symptom",["loc",[null,[109,69],[109,81]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 104,
              "column": 16
            },
            "end": {
              "line": 110,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","is-etiga-readable",["addSymp",["get","etigaPermissions",["loc",[null,[104,55],[104,71]]]],["get","patient",["loc",[null,[104,72],[104,79]]]]],[],["loc",[null,[104,26],[104,80]]]]],[],0,1,["loc",[null,[104,16],[110,16]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 116,
              "column": 16
            },
            "end": {
              "line": 123,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"for","overlay_diagnose");
          dom.setAttribute(el1,"class","field prepend-icon");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","overlay_diagnose");
          dom.setAttribute(el2,"class","field-icon");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","fa fa-comments");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","textarea",[],["id","overlay_diagnose","name","diagnose","value",["subexpr","@mut",[["get","data.diagnose",["loc",[null,[118,77],[118,90]]]]],[],[]],"class","gui-textarea"],["loc",[null,[118,22],[118,113]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 123,
                "column": 16
              },
              "end": {
                "line": 126,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","field");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","hidden");
            dom.setAttribute(el1,"id","overlay_diagnose");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            morphs[1] = dom.createAttrMorph(element5, 'value');
            return morphs;
          },
          statements: [
            ["content","data.diagnose",["loc",[null,[124,41],[124,58]]]],
            ["attribute","value",["concat",[["get","data.diagnose",["loc",[null,[125,72],[125,85]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 126,
                "column": 16
              },
              "end": {
                "line": 129,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","field");
            var el2 = dom.createTextNode("-");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","hidden");
            dom.setAttribute(el1,"id","overlay_diagnose");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [3]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element4, 'value');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","data.diagnose",["loc",[null,[128,70],[128,83]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 123,
              "column": 16
            },
            "end": {
              "line": 129,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","is-etiga-readable",["addDiag",["get","etigaPermissions",["loc",[null,[123,55],[123,71]]]],["get","patient",["loc",[null,[123,72],[123,79]]]]],[],["loc",[null,[123,26],[123,80]]]]],[],0,1,["loc",[null,[123,16],[129,16]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 135,
              "column": 16
            },
            "end": {
              "line": 142,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"for","overlay_therapy");
          dom.setAttribute(el1,"class","field prepend-icon");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","overlay_therapy");
          dom.setAttribute(el2,"class","field-icon");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","fa fa-comments");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","textarea",[],["id","overlay_therapy","name","overlay_therapy","value",["subexpr","@mut",[["get","data.therapy",["loc",[null,[137,83],[137,95]]]]],[],[]],"class","gui-textarea"],["loc",[null,[137,22],[137,118]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 142,
                "column": 16
              },
              "end": {
                "line": 145,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","field");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","hidden");
            dom.setAttribute(el1,"id","overlay_therapy");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            morphs[1] = dom.createAttrMorph(element3, 'value');
            return morphs;
          },
          statements: [
            ["content","data.therapy",["loc",[null,[143,39],[143,55]]]],
            ["attribute","value",["concat",[["get","data.therapy",["loc",[null,[144,69],[144,81]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 145,
                "column": 16
              },
              "end": {
                "line": 148,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","field");
            var el2 = dom.createTextNode("-");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","hidden");
            dom.setAttribute(el1,"id","overlay_therapy");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [3]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element2, 'value');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","data.therapy",["loc",[null,[147,69],[147,81]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 142,
              "column": 16
            },
            "end": {
              "line": 148,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","is-etiga-readable",["addThera",["get","etigaPermissions",["loc",[null,[142,56],[142,72]]]],["get","patient",["loc",[null,[142,73],[142,80]]]]],[],["loc",[null,[142,26],[142,81]]]]],[],0,1,["loc",[null,[142,16],[148,16]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 154,
              "column": 16
            },
            "end": {
              "line": 161,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"for","overlay_report");
          dom.setAttribute(el1,"class","field prepend-icon");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","overlay_report");
          dom.setAttribute(el2,"class","field-icon");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","fa fa-comments");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","textarea",[],["id","overlay_report","name","overlay_report","value",["subexpr","@mut",[["get","data.report",["loc",[null,[156,81],[156,92]]]]],[],[]],"class","gui-textarea"],["loc",[null,[156,22],[156,115]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 161,
                "column": 16
              },
              "end": {
                "line": 164,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","field");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","hidden");
            dom.setAttribute(el1,"id","overlay_therapy");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            morphs[1] = dom.createAttrMorph(element1, 'value');
            return morphs;
          },
          statements: [
            ["content","data.report",["loc",[null,[162,39],[162,54]]]],
            ["attribute","value",["concat",[["get","data.report",["loc",[null,[163,69],[163,80]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 164,
                "column": 16
              },
              "end": {
                "line": 167,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","field");
            var el2 = dom.createTextNode("-");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","hidden");
            dom.setAttribute(el1,"id","overlay_therapy");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [3]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'value');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","data.report",["loc",[null,[166,69],[166,80]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 161,
              "column": 16
            },
            "end": {
              "line": 167,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","is-etiga-readable",["addAnam",["get","etigaPermissions",["loc",[null,[161,55],[161,71]]]],["get","patient",["loc",[null,[161,72],[161,79]]]]],[],["loc",[null,[161,26],[161,80]]]]],[],0,1,["loc",[null,[161,16],[167,16]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 171,
              "column": 10
            },
            "end": {
              "line": 177,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","bar");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","submit");
          dom.setAttribute(el2,"class","icon-button icon-button--success");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","button-save",[],["color","#ffffff","size","52"],["loc",[null,[174,20],[174,61]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 181,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/treatment-info-form.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","treatment-edit-form");
        dom.setAttribute(el1,"class","popup-basic popup-very-large admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n              ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5,"class","fa fa-edit");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("Behandlungsinfos");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"method","post");
        dom.setAttribute(el3,"id","comment");
        var el4 = dom.createTextNode("\n\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-body p25");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","section");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","mb10");
        var el7 = dom.createTextNode("\n                        Anamnese\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","section");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","mb10");
        var el7 = dom.createTextNode("\n                        Symptome\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","section");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","mb10");
        var el7 = dom.createTextNode("\n                        Diagnose\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","section");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","mb10");
        var el7 = dom.createTextNode("\n                        Therapie\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","section");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","mb10");
        var el7 = dom.createTextNode("\n                        Beratung\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element14 = dom.childAt(fragment, [0, 1]);
        var element15 = dom.childAt(element14, [5]);
        var element16 = dom.childAt(element15, [1]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(element14,3,3);
        morphs[1] = dom.createElementMorph(element15);
        morphs[2] = dom.createMorphAt(element16,1,1);
        morphs[3] = dom.createMorphAt(dom.childAt(element16, [3]),3,3);
        morphs[4] = dom.createMorphAt(dom.childAt(element16, [5]),3,3);
        morphs[5] = dom.createMorphAt(dom.childAt(element16, [7]),3,3);
        morphs[6] = dom.createMorphAt(dom.childAt(element16, [9]),3,3);
        morphs[7] = dom.createMorphAt(dom.childAt(element16, [11]),3,3);
        morphs[8] = dom.createMorphAt(element15,3,3);
        return morphs;
      },
      statements: [
        ["block","if",[["get","taskData",["loc",[null,[9,14],[9,22]]]]],[],0,null,["loc",[null,[9,8],[57,15]]]],
        ["element","action",["save"],["on","submit"],["loc",[null,[59,41],[59,70]]]],
        ["block","if",[["get","editDate",["loc",[null,[62,20],[62,28]]]]],[],1,null,["loc",[null,[62,14],[72,21]]]],
        ["block","if",[["subexpr","or",[["get","owner",["loc",[null,[78,30],[78,35]]]],["subexpr","is-etiga-editable",["addAnam",["get","etigaPermissions",["loc",[null,[78,65],[78,81]]]],["get","patient",["loc",[null,[78,82],[78,89]]]]],[],["loc",[null,[78,36],[78,90]]]]],[],["loc",[null,[78,26],[78,91]]]]],[],2,3,["loc",[null,[78,20],[91,27]]]],
        ["block","if",[["subexpr","or",[["get","owner",["loc",[null,[97,26],[97,31]]]],["subexpr","is-etiga-editable",["addSymp",["get","etigaPermissions",["loc",[null,[97,61],[97,77]]]],["get","patient",["loc",[null,[97,78],[97,85]]]]],[],["loc",[null,[97,32],[97,86]]]]],[],["loc",[null,[97,22],[97,87]]]]],[],4,5,["loc",[null,[97,16],[110,23]]]],
        ["block","if",[["subexpr","or",[["get","owner",["loc",[null,[116,26],[116,31]]]],["subexpr","is-etiga-editable",["addDiag",["get","etigaPermissions",["loc",[null,[116,61],[116,77]]]],["get","patient",["loc",[null,[116,78],[116,85]]]]],[],["loc",[null,[116,32],[116,86]]]]],[],["loc",[null,[116,22],[116,87]]]]],[],6,7,["loc",[null,[116,16],[129,23]]]],
        ["block","if",[["subexpr","or",[["get","owner",["loc",[null,[135,26],[135,31]]]],["subexpr","is-etiga-editable",["addThera",["get","etigaPermissions",["loc",[null,[135,62],[135,78]]]],["get","patient",["loc",[null,[135,79],[135,86]]]]],[],["loc",[null,[135,32],[135,87]]]]],[],["loc",[null,[135,22],[135,88]]]]],[],8,9,["loc",[null,[135,16],[148,23]]]],
        ["block","if",[["subexpr","or",[["get","owner",["loc",[null,[154,26],[154,31]]]],["subexpr","is-etiga-editable",["addAnam",["get","etigaPermissions",["loc",[null,[154,61],[154,77]]]],["get","patient",["loc",[null,[154,78],[154,85]]]]],[],["loc",[null,[154,32],[154,86]]]]],[],["loc",[null,[154,22],[154,87]]]]],[],10,11,["loc",[null,[154,16],[167,23]]]],
        ["block","if",[["subexpr","or",[["get","owner",["loc",[null,[171,20],[171,25]]]],["subexpr","is-etiga-editable",["addThera",["get","etigaPermissions",["loc",[null,[171,56],[171,72]]]],["get","patient",["loc",[null,[171,73],[171,80]]]]],[],["loc",[null,[171,26],[171,81]]]],["subexpr","is-etiga-editable",["addDiag",["get","etigaPermissions",["loc",[null,[171,111],[171,127]]]],["get","patient",["loc",[null,[171,128],[171,135]]]]],[],["loc",[null,[171,82],[171,136]]]],["subexpr","is-etiga-editable",["addSymp",["get","etigaPermissions",["loc",[null,[171,166],[171,182]]]],["get","patient",["loc",[null,[171,183],[171,190]]]]],[],["loc",[null,[171,137],[171,191]]]],["subexpr","is-etiga-editable",["addAnam",["get","etigaPermissions",["loc",[null,[171,221],[171,237]]]],["get","patient",["loc",[null,[171,238],[171,245]]]]],[],["loc",[null,[171,192],[171,246]]]]],[],["loc",[null,[171,16],[171,247]]]]],[],12,null,["loc",[null,[171,10],[177,17]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12]
    };
  }()));

});