define('jason-frontend/helpers/get-weekday', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getWeekday = getWeekday;

  function getWeekday(params) {
    var day = params[0].getDay();

    if (day === 1) {
      return "Montag";
    } else if (day === 2) {
      return "Dienstag";
    } else if (day === 3) {
      return "Mittwoch";
    } else if (day === 4) {
      return "Donnerstag";
    } else if (day === 5) {
      return "Freitag";
    } else if (day === 6) {
      return "Samstag";
    } else if (day === 0) {
      return "Sonntag";
    }
  }

  exports['default'] = Ember['default'].Helper.helper(getWeekday);

});