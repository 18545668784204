define('jason-frontend/components/choose-etiga-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    masterId: null,
    actions: {
      load: function load(matches, masterId) {
        this.set('patients', matches);
        this.set('masterId', masterId);
      },
      select: function select(id) {
        this.sendAction('select', id, this.get('masterId'));
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});