define('jason-frontend/components/lab-result-chart-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      load: function load(patientId, paramId, formId) {
        this.set('patientId', patientId);
        this.set('paramId', paramId);
        this.set('formId', formId);

        var self = this;
        setTimeout(function () {
          self.show();
        }, 1000);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      back: function back() {
        this.sendAction('back', this.get('formId'));
      }
    },
    show: function show() {

      var charts = $('div#highcharts2'),
          patientId = this.get('patientId'),
          formId = this.get('formId'),
          paramId = this.get('paramId');

      var self = this;

      var url = "/api/labResults/" + patientId + "/chart/" + formId + "/" + btoa(paramId) + '?format=desktop';

      if (!formId) {
        url = "/api/labResults/" + patientId + "/allchart/" + btoa(paramId) + '?format=desktop';
      }

      $.ajax({
        url: url,
        method: "GET",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {

        self.set('name', data.labResultChartWrapper.name);
        var csv = data.labResultChartWrapper.bar;
        var unit = data.labResultChartWrapper.unit;
        self.set('unit', unit);
        self.set('items', data.labResultChartWrapper.items);
        var lines = csv.split('\n').length;

        if (lines <= 2) {
          $('div#highcharts').hide();
          return;
        }

        $('div#highcharts').show();

        charts.highcharts({

          lang: {
            decimalPoint: ',',
            thousandsSep: '.',
            loading: 'Daten werden geladen...',
            exportButtonTitle: "Exportieren",
            printButtonTitle: "Drucken",
            rangeSelectorFrom: "Von",
            rangeSelectorTo: "Bis",
            rangeSelectorZoom: "Zeitraum",
            downloadPNG: 'Download als PNG-Bild',
            downloadJPEG: 'Download als JPEG-Bild',
            downloadPDF: 'Download als PDF-Dokument',
            downloadSVG: 'Download als SVG-Bild',
            resetZoom: "Zoom zurücksetzen",
            resetZoomTitle: "Zoom zurücksetzen"
          },

          colors: ["#ff0000", "#ADCCD4", "#ff0000", "#7798BF", "#aaeeee", "#ff0066", "#eeaaee", "#55BF3B", "#DF5353", "#7798BF", "#aaeeee"],

          data: {
            csv: csv
          },

          title: {
            text: ''
          },
          xAxis: {
            tickWidth: 0,
            gridLineWidth: 1,
            labels: {
              align: 'left',
              x: 3,
              y: -3
            }
          },

          yAxis: [{
            title: {
              text: null
            },
            labels: {
              align: 'left',
              x: 3,
              y: 16,
              format: '{value:.,0f}'
            },
            showFirstLabel: false
          }, {
            linkedTo: 0,
            gridLineWidth: 0,
            opposite: true,
            title: {
              text: unit
            },
            labels: {
              align: 'right',
              x: -3,
              y: 16,
              format: '{value:.,0f}'
            },
            showFirstLabel: false
          }],

          legend: {
            align: 'left',
            verticalAlign: 'top',
            y: 0,
            floating: true,
            borderWidth: 0
          },

          tooltip: {
            shared: true,
            crosshairs: true
          },

          plotOptions: {
            series: {
              cursor: 'pointer',
              marker: {
                lineWidth: 1
              }
            }
          }
        });
      });
    }
  });

});