define('jason-frontend/controllers/cash/in', ['exports', 'ember', 'moment'], function (exports, Ember, moment) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Barzahlung",
    breadCrumbPath: "cash.in",
    queryParams: ["page", "perPage", "filterCode"],
    filterStatus: "",
    filterCode: "",
    filterPaymentStatus: "",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    perPage: 10,
    selectedPaymentType: "cash",
    terminalActivated: false,
    externalCashRegister: false,
    showInvoicePanel: true,
    showBankTransferPanel: false,
    selectedCashRegister: null,
    selectedType: "in",
    selectedInvoiceId: null,
    selectedInvoice: null,
    maxPaymentAmount: null,
    transaction: null,
    bankingAccount: null,
    documentDate: moment['default']().format("DD. MM. YYYY"),
    notice: null,
    paymentTypeList: [{ value: "Bar", id: "cash" }, { value: "EC-Karte", id: "cashcard" }, { value: "Kreditkarte", id: "creditcard" }, { value: "Überweisung", id: "banktransfer" }, { value: "Gutschein", id: "voucher" }],
    styleStepOne: "",
    styleStepTwo: "hidden",
    styleStepThree: "hidden",
    styleStepConfirmation: "hidden",
    actions: {
      toggleReference: function toggleReference(show) {
        this.set('showRefOutgoing', show);
      },
      setPaymentType: function setPaymentType(paymentType) {
        this.set('selectedPaymentType', paymentType);
        this.set('showBankTransferPanel', paymentType === 'banktransfer');
      },
      setType: function setType(type) {
        this.set('selectedType', type);
        this.set('showInvoicePanel', type === 'in');

        if (type === 'inlay') {
          this.set('selectedPaymentType', 'cash');
        }
      }
    },
    watchType: (function () {
      var self = this;
      var invoice = this.store.fetch('invoice', this.selectedInvoiceId).then(function (data) {
        self.set("selectedInvoice", data);
        var open = data.get('total') - data.get('payedTotal');
        self.set("maxPaymentAmount", open);
      });
    }).observes('selectedInvoiceId')
  });

});