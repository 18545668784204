define('jason-frontend/routes/practicemgmt/treatment', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {
      return this.findPaged('treatment', {
        state: 'in_treatment',
        perPage: 10
      });
    },
    setupController: function setupController(controller, model, params) {
      controller.set("model", model);

      controller.set("gendersAll", this.store.find('gender'));
      controller.set('patientGenders', this.store.find('patient-gender'));
      controller.set('patientCategories', this.store.find('patient-category'));
      controller.set('doctors', this.store.find('user', { role: 'doctor' }));
      controller.set('assistants', this.store.find('user', { role: 'assistant' }));
      controller.set('rooms', this.store.find('treatment-room'));

      this.findPaged('treatment', {
        state: 'in_waitingroom',
        perPage: 10
      }, function (data) {
        controller.set('waitings', data);
      });
    },
    updateState: function updateState(_updateState) {
      var self = this;

      $.ajax({
        url: "/api/treatments/updateState",
        method: "POST",
        data: JSON.stringify(_updateState),
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        if (_updateState.newState === 'in_treatment') {
          self.transitionTo('practicemgmt.treatment-details', _updateState.treatmentPatientId, { queryParams: { entry: "true" } });
        } else if (_updateState.close == true) {
          self.transitionTo('cash.choose');
        } else {
          self.refresh();
        }
      });
    },
    actions: {
      saveEditTreatmentInfo: function saveEditTreatmentInfo(data) {
        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/quick",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (result) {
          self.refresh();
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      selectCustomerForTreatment: function selectCustomerForTreatment() {
        this.transitionTo('practicemgmt.admission-select-customer');
      },
      movePatientToWaitingRoom: function movePatientToWaitingRoom(infoId) {
        var self = this,
            updateState = {
          treatmentPatientId: infoId,
          newState: 'in_waitingroom'
        };
        this.updateState(updateState);
      },
      movePatientToStation: function movePatientToStation(id, data) {
        var self = this,
            updateState = {
          treatmentPatientId: id,
          stationId: data.stationId,
          newState: 'in_station'
        };
        this.updateState(updateState);
      },
      movePatientToTreatmentRoom: function movePatientToTreatmentRoom(infoId) {
        var self = this,
            updateState = {
          treatmentPatientId: infoId,
          newState: 'in_treatment'
        };
        this.updateState(updateState);
      },
      abort: function abort(id) {
        var self = this,
            updateState = {
          treatmentPatientId: id,
          newState: 'closed',
          hardAbort: true
        };
        this.updateState(updateState);
      },
      exportInvoice: function exportInvoice(id) {
        var self = this,
            deferred = $.ajax({
          url: "/api/treatmentOffers/" + id + "/checkout",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('invoices.create', data, { queryParams: { entry: "true" } });
          jason.notifiction.notifiy('In Ausgangsrechnung übertragen', '');
        }, function (error) {});
      },
      clickPatient: function clickPatient(id) {
        this.transitionTo('practicemgmt.treatment-details', id.id);
      }
    }
  });

});