define('jason-frontend/components/button-quickaction-etiga', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 50,
    side: 'top',
    timer: 0,
    color: '#404040',
    showStroke: true,
    content: 'Kalender',
    theme: 'tooltipster-punk'
  });

});