define('jason-frontend/routes/cash/dsf', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      this.findPaged('closing-export', {
        filterFrom: from,
        filterTo: to
      }).then(function (entries) {
        controller.set('items', entries);
      });
    },
    setupController: function setupController(controller) {

      if (!API['default'].hasPermission('r_logbook')) {
        this.transitionTo('home');
      }

      controller.set('downloadToken', API['default'].getDownloadToken());
      controller.set('mandantId', API['default'].getMandant());

      this.applyFilter();
    },
    perPage: 100,
    queryParams: {
      filterRegisterId: {
        refreshModel: true
      }
    },
    actions: {
      showDetails: function showDetails(item) {

        this.get('controller').get('dsfPanel').send('load', item);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-dsf-details-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      refreshStatus: function refreshStatus(closing) {
        var self = this;
        $.ajax({
          url: "/api/closings/dsfRefresh/" + closing.id,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('DSFinV-K', 'aktualisiert');
          self.applyFilter();
        }, function (data) {
          jason.notifiction.notifiy('DSFinV-K', 'aktualisiert');
          self.applyFilter();
        });
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      }
    }
  });

});