define('jason-frontend/controllers/users/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.ArrayController.extend({
    queryParams: ["page", "perPage"],
    filterName: null,
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    myStickyOptionsTableHead: {
      topSpacing: 160,
      zIndex: 9
    },
    page: 1,
    perPage: 10
  });

});