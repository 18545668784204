define('jason-frontend/helpers/is-show-inactivity-label', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isShowInactivityLabel = isShowInactivityLabel;

  function isShowInactivityLabel() {
    return API['default'].isShowInactivityLabel();
  }

  exports['default'] = Ember['default'].Helper.helper(isShowInactivityLabel);

});