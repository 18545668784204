define('jason-frontend/components/change-warehouse-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    type: null,
    actions: {
      load: function load(items, stockId) {
        this.set('warehouses', items);
        this.set('stockId', stockId);
      },
      confirm: function confirm() {

        this.sendAction('changeWarehouse', this.get('warehouse'), this.get('stockId'));
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      } }
  });

});