define('jason-frontend/controllers/treatmentstations', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Meine Stationen",
    breadCrumbPath: "treatmentstations.index",
    filterFavorites: false
  });

});