define('jason-frontend/templates/components/button-chat', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 5,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/button-chat.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"class","circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","14");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[4,62],[4,67]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 63,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-chat.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 34 34");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(2 1)");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"fill","white");
        dom.setAttribute(el3,"transform","scale(0.04,0.04) translate(130,130)");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("g");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6,"d","M372.29,50.758H28.831C12.933,50.758,0,63.691,0,79.588v206.056c0,15.897,12.933,28.831,28.831,28.831h22.271v76.71\n			c0,4.884,2.942,9.289,7.456,11.159c1.494,0.62,3.064,0.92,4.62,0.92c3.144,0,6.232-1.228,8.543-3.538l85.251-85.25h17.104\n			c6.671,0,12.078-5.407,12.078-12.078c0-6.672-5.409-12.079-12.078-12.079c0,0-22.69,0.016-22.927,0.04\n			c-2.812,0.191-5.572,1.349-7.722,3.498l-68.169,68.169c0,0-0.021-60.392-0.06-60.705c-0.545-6.166-5.717-11.002-12.024-11.002\n			H28.831c-2.578,0-4.674-2.097-4.674-4.674V79.588c0-2.578,2.097-4.674,4.674-4.674H372.29c2.578,0,4.674,2.097,4.674,4.674v79.055\n			c0,6.671,5.409,12.078,12.078,12.078s12.078-5.407,12.078-12.078V79.588C401.12,63.691,388.187,50.758,372.29,50.758z");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("g");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6,"d","M483.169,198.492H242.754c-15.897,0-28.831,12.933-28.831,28.831v140.57c0,15.897,12.933,28.831,28.831,28.831h150.514\n			l60.98,60.98c2.311,2.311,5.4,3.538,8.543,3.538c1.556,0,3.126-0.301,4.62-0.92c4.512-1.87,7.456-6.273,7.456-11.159v-52.44h8.301\n			c15.897,0,28.831-12.933,28.831-28.831V227.322C512,211.425,499.067,198.492,483.169,198.492z M487.844,367.893\n			c0,2.577-2.097,4.674-4.674,4.674h-20.376c-6.356,0-11.554,4.912-12.031,11.147c-0.031,0.264-0.051,36.29-0.051,36.29\n			l-43.854-43.855c-0.046-0.046-0.094-0.089-0.14-0.135c-0.172-0.168-0.335-0.314-0.489-0.445c-2.126-1.864-4.903-3.003-7.951-3.003\n			H242.754c-2.578,0-4.674-2.097-4.674-4.674v-140.57c0-2.578,2.097-4.674,4.674-4.674h240.416c2.577,0,4.674,2.097,4.674,4.674\n			V367.893z");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("g");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6,"d","M362.964,285.53c-6.667,0-12.078,5.411-12.078,12.078c0,6.667,5.411,12.078,12.078,12.078\n			c6.668,0,12.078-5.411,12.078-12.078C375.042,290.941,369.631,285.53,362.964,285.53z");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("g");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6,"d","M310.472,130.611c0,0-219.822,0-219.822,0c-6.67,0-12.078,5.407-12.078,12.078s5.409,12.078,12.078,12.078h219.822\n			c6.67,0,12.078-5.407,12.078-12.078S317.142,130.611,310.472,130.611z");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("g");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6,"d","M174.075,210.465H90.65c-6.67,0-12.078,5.407-12.078,12.078c0,6.671,5.409,12.078,12.078,12.078h83.425\n			c6.671,0,12.078-5.407,12.078-12.078S180.745,210.465,174.075,210.465z");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("g");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6,"d","M306.837,285.53c-6.666,0-12.078,5.411-12.078,12.078c0,6.667,5.412,12.078,12.078,12.078\n			c6.668,0,12.078-5.411,12.078-12.078C318.915,290.941,313.505,285.53,306.837,285.53z");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("g");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6,"d","M419.079,285.53c-6.667,0-12.078,5.411-12.078,12.078c0,6.667,5.411,12.078,12.078,12.078\n			c6.668,0,12.078-5.411,12.078-12.078C431.157,290.941,425.746,285.53,419.079,285.53z");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,10],[3,20]]]]],[],0,null,["loc",[null,[3,4],[5,11]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});