define('jason-frontend/components/dsf-export-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    type: null,
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),
    actions: {
      confirm: function confirm() {
        this.sendAction('updateProgress', true);
        this.sendAction('confirm', this.get('pickDate'));
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      reset: function reset(msg) {
        this.set('msgId', msg.id);
        this.set('comment', null);
      }
    }
  });

});