define('jason-frontend/components/invoice-orderstatus-read', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    downloadWarning: true,
    subClass: 'small',
    actions: {
      downloadWarning: function downloadWarning(invoice) {
        this.sendAction('downloadWarning', invoice);
      }
    }
  });

});