define('jason-frontend/helpers/link', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.link = link;

  function link(params) {
    var url = Ember['default'].Handlebars.Utils.escapeExpression(params[0]).trim(),
        text = Ember['default'].Handlebars.Utils.escapeExpression(params[1]),
        supplier = params[2];

    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

    if (pattern.test(url)) {
      return new Ember['default'].Handlebars.SafeString("<a target='_blank' class='link2' href='" + url + "'>" + text + "</a>");
    }

    var patternRelative = new RegExp('\.pdf$', 'i');

    var patternUpload = new RegExp('^\\/media/*.', 'i');

    if (patternUpload.test(url)) {
      return new Ember['default'].Handlebars.SafeString("<a target='_blank' class='link2' href='" + url + "'>" + text + "</a>");
    }
    if (patternRelative.test(url)) {
      return new Ember['default'].Handlebars.SafeString("<a target='_blank' class='link2' href='/media/" + supplier + "/docs/" + url + "'>" + text + "</a>");
    }

    return url;
  }

  exports['default'] = Ember['default'].Helper.helper(link);

});