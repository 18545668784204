define('jason-frontend/controllers/purchases/confirm', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Bestätigung",
    breadCrumbPath: "purchases.confirm",
    sidebarActive: true,
    renderPdf: false
  });

});