define('jason-frontend/components/laboklin-historic-forms', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    small: false,
    showLoading: false,
    labItems: null,
    showEdit: false,
    showSendAll: true,
    reportType: null,
    size: 30,
    reportTypeList: [{ name: "Endbefund", id: "E" }, { name: "In Arbeit", id: "B" }, { name: "Teilbefund", id: "T" }, { name: "Vorläufig", id: "V" }, { name: "Nachtrag", id: "N" }, { name: "Archiv", id: "A" }, { name: "In Auftrag", id: "X" }],
    showSendItems: Ember['default'].computed('labItems', function () {

      var returnValue = false;
      $(this.get('labItems')).each(function (index, value) {
        if (value.status.id === 'created' && value.hasEntries && !value.privateLab) {
          returnValue = true;
        }
      });
      return returnValue;
    }),
    actions: {
      hideLabForApp: function hideLabForApp(id) {
        var self = this;
        $.ajax({
          url: "/api/labs/" + id + "/hideLab",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'ausgeblendet');
          self.sendAction('reload', self.get('filterReportType'));
        }, function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'ausgeblendet');
          self.sendAction('reload', self.get('filterReportType'));
        });
      },
      showLabForApp: function showLabForApp(id) {
        var self = this;
        $.ajax({
          url: "/api/labs/" + id + "/showLab",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'eingeblendet');
          self.sendAction('reload', self.get('filterReportType'));
        }, function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'eingeblendet');
          self.sendAction('reload', self.get('filterReportType'));
        });
      },
      openAniveriWebUrl: function openAniveriWebUrl(url) {
        window.open(url);
      },
      getIdexxViewUrl: function getIdexxViewUrl(id) {

        var deferred = $.ajax({
          url: "/api/vetconnect/webView/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (data) {
            window.open(data.responseText);
          } else {
            jason.notifiction.error("Fehler", "Idexx Ergebnislink konnte nicht gefunden werden");
          }
        }, function (data) {
          if (data) {
            window.open(data.responseText, "Idexx", "width=1024,height=768,status=no,scrollbars=yes,resizable=yes");
          } else {
            jason.notifiction.error("Fehler", "Idexx Ergebnislink konnte nicht gefunden werden");
          }
        });
      },
      forwardEmail: function forwardEmail(id) {
        this.sendAction('forwardEmail', id);
      },
      downloadResult: function downloadResult(items) {
        this.sendAction('downloadResult', items);
      },
      openResult: function openResult(items) {
        this.sendAction('openResult', items);
      },
      downloadVetconnectResult: function downloadVetconnectResult(id) {
        this.sendAction('downloadVetconnectResult', id);
      },
      disableLoading: function disableLoading() {
        this.set('showLoading', false);
      },
      editLaboklinForm: function editLaboklinForm(formId) {
        this.sendAction('editLaboklinForm', formId);
      },
      downloadBarcode: function downloadBarcode(id) {
        this.sendAction('downloadBarcode', id);
      },
      sortable: function sortable() {
        this.sendAction('setSort');
      },
      deleteReport: function deleteReport(id) {
        this.sendAction('deleteReport', id);
      },
      downloadMedia: function downloadMedia(id, format) {
        this.sendAction('downloadMedia', id, format);
      },
      downloadPdf: function downloadPdf(id) {
        this.sendAction('downloadPdf', id);
      },
      submitLaboklin: function submitLaboklin(formId) {
        this.sendAction('submitLaboklin', 0, formId);
        this.set('showLoading', true);
      },
      sendAll: function sendAll(forms) {
        var self = this;
        $(forms).each(function (index, value) {
          if (value.status.id === 'created' && value.hasEntries) {
            setTimeout(function () {
              self.sendAction('submitLaboklin', 0, value.id);
            }, 500);
          }
        });
      }
    },
    watchReportType: (function () {
      this.sendAction('reload', this.get('filterReportType'));
    }).observes('filterReportType')
  });

});