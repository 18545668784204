define('jason-frontend/helpers/format-number-german', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.formatNumberGerman = formatNumberGerman;

  function formatNumberGerman(params) {
    var value = String(params[0]).replace(',', '.');
    if (!isNaN(value)) {
      value = Math.round(value * 100) / 100;
      // return String(value).replace('.', ',');
      var splits = value.toString().split(".");
      var numSplit = splits[0];
      var decimalSplit = splits[1];
      var thousands = /\B(?=(\d{3})+(?!\d))/g;
      return numSplit.replace(thousands, ".") + (decimalSplit ? "," + decimalSplit : "");
    }
    return;
  }

  exports['default'] = Ember['default'].Helper.helper(formatNumberGerman);

});