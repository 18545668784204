define('jason-frontend/models/sale-dashboard', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    user: DS['default'].attr('string'),
    userId: DS['default'].attr('number'),
    assistant: DS['default'].attr('string'),
    assistantId: DS['default'].attr('number'),
    customerId: DS['default'].attr('number'),
    customer: DS['default'].attr('string'),
    created: DS['default'].attr('date'),
    referenceCode: DS['default'].attr('string'),
    referenceId: DS['default'].attr('number'),
    revenue20: DS['default'].attr('number'),
    revenue20net: DS['default'].attr('number'),
    revenue19: DS['default'].attr('number'),
    revenue19net: DS['default'].attr('number'),
    revenue13: DS['default'].attr('number'),
    revenue13net: DS['default'].attr('number'),
    revenue10: DS['default'].attr('number'),
    revenue10net: DS['default'].attr('number'),
    revenue7: DS['default'].attr('number'),
    revenue7net: DS['default'].attr('number'),
    revenue0: DS['default'].attr('number'),
    partPayed: DS['default'].attr(''),
    discounted: DS['default'].attr(''),
    revenue: DS['default'].attr('number')
  });

});