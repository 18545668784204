define('jason-frontend/components/customer-correspondance', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',
    actions: {
      downloadWarning: function downloadWarning(id, invoiceId) {
        window.open('/api/customers/downloadWarning/' + id);

        var deferred = Ember['default'].$.ajax({
          url: "/api/invoices/" + invoiceId + "/preview",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          window.location.href = window.location.origin + '/api/invoices/show/' + data.responseText;
        }, function (data) {
          window.location.href = window.location.origin + '/api/invoices/show/' + data.responseText;
        });
        return Ember['default'].RSVP.resolve(deferred);
      },
      downloadReminder: function downloadReminder(id, invoiceId) {
        window.open('/api/customers/downloadReminder/' + id);
      }
    }
  });

});