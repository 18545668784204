define('jason-frontend/helpers/get-default-small-picture', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.getDefaultPicture = getDefaultPicture;

  function getDefaultPicture(params) {
    var product = params[0];

    if (API['default'].isSupplier()) {
      return 'small_' + API['default'].getSupplierId() + '.png';
    }

    try {
      if (product && product.get('supplier')) {
        return 'small_' + product.get('supplier').get('id') + '.png';
      }
    } catch (e) {
      if (product && product.supplier) {
        return 'small_' + product.supplier.id + '.png';
      }
    }
    return 'nopicture.png';
  }

  exports['default'] = Ember['default'].Helper.helper(getDefaultPicture);

});