define('jason-frontend/templates/components/button-died', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 6
            },
            "end": {
              "line": 6,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/button-died.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"class","circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","16");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element4, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[5,62],[5,67]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 4
            },
            "end": {
              "line": 25,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/button-died.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("filter");
          dom.setAttribute(el1,"id","dropshadowDied");
          dom.setAttribute(el1,"height","130%");
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("feGaussianBlur");
          dom.setAttribute(el2,"in","SourceAlpha");
          dom.setAttribute(el2,"stdDeviation","10");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("feOffset");
          dom.setAttribute(el2,"dx","20");
          dom.setAttribute(el2,"dy","30");
          dom.setAttribute(el2,"result","offsetblur");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("feComponentTransfer");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("feFuncA");
          dom.setAttribute(el3,"type","linear");
          dom.setAttribute(el3,"slope","1.5");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("feMerge");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("feMergeNode");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("feMergeNode");
          dom.setAttribute(el3,"in","SourceGraphic");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 0
            },
            "end": {
              "line": 36,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/button-died.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","delete-confirm medium-confirm");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","footer");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","button");
          dom.setAttribute(el3,"href","#");
          var el4 = dom.createTextNode("Nein");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","button");
          dom.setAttribute(el3,"href","#");
          var el4 = dom.createTextNode("Ja");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element0,1,1);
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [
          ["content","text",["loc",[null,[30,4],[30,12]]]],
          ["element","action",["cancel"],[],["loc",[null,[32,9],[32,28]]]],
          ["element","action",["delete"],[],["loc",[null,[33,9],[33,28]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 38,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-died.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"class","btn-delete");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el2 = dom.createElement("svg");
        dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el2,"class","died-button cursor state-button");
        dom.setAttribute(el2,"viewBox","-1 -1 34 34");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"fill","none");
        dom.setAttribute(el3,"fill-rule","evenodd");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        dom.setAttribute(el4,"class","icon in__icon");
        dom.setAttribute(el4,"fill-rule","evenodd");
        dom.setAttribute(el4,"transform","translate(6,5) scale(0.04,0.04)");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M389.565,100.174h-83.072V16.696C306.493,7.479,298.608,0,289.391,0h-66.783c-9.217,0-16.289,7.479-16.289,16.696v83.478    h-83.885c-9.217,0-16.289,7.479-16.289,16.696v66.783c0,9.217,7.072,16.696,16.289,16.696h83.884v138.224    c11.13-2.935,32.833-4.659,50.087-4.659s38.957,1.724,50.087,4.659V200.348h83.072c9.217,0,17.102-7.479,17.102-16.696V116.87    C406.667,107.652,398.782,100.174,389.565,100.174z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M439.207,494.174C434.381,423.032,353.913,367.304,256,367.304S77.619,423.033,72.793,494.173    c-0.315,4.609,1.293,9.153,4.457,12.533c3.152,3.38,7.576,5.294,12.196,5.294h333.109c4.619,0,9.044-1.913,12.196-5.294    C437.914,503.326,439.522,498.783,439.207,494.174z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element7, [3]);
        var element9 = dom.childAt(element8, [1]);
        var element10 = dom.childAt(element8, [3]);
        var morphs = new Array(11);
        morphs[0] = dom.createAttrMorph(element5, 'data-id');
        morphs[1] = dom.createAttrMorph(element5, 'title');
        morphs[2] = dom.createElementMorph(element5);
        morphs[3] = dom.createAttrMorph(element6, 'width');
        morphs[4] = dom.createAttrMorph(element6, 'height');
        morphs[5] = dom.createMorphAt(element7,1,1);
        morphs[6] = dom.createAttrMorph(element8, 'fill');
        morphs[7] = dom.createAttrMorph(element9, 'style');
        morphs[8] = dom.createAttrMorph(element10, 'style');
        morphs[9] = dom.createMorphAt(element6,3,3);
        morphs[10] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[1,54],[1,61]]]]]]],
        ["attribute","title",["concat",[["get","tooltip",["loc",[null,[1,74],[1,81]]]]]]],
        ["element","action",["confirm"],[],["loc",[null,[1,3],[1,23]]]],
        ["attribute","width",["concat",[["get","size",["loc",[null,[2,51],[2,55]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[2,69],[2,73]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[4,12],[4,22]]]]],[],0,null,["loc",[null,[4,6],[6,13]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[7,39],[7,44]]]]]]],
        ["attribute","style",["concat",[["subexpr","css-bool-evaluator",[["get","useFilter",["loc",[null,[8,42],[8,51]]]],"filter:url(#dropshadowDied)",""],[],["loc",[null,[8,21],[8,86]]]]]]],
        ["attribute","style",["concat",[["subexpr","css-bool-evaluator",[["get","useFilter",["loc",[null,[9,42],[9,51]]]],"filter:url(#dropshadowDied)",""],[],["loc",[null,[9,21],[9,86]]]]]]],
        ["block","if",[["get","useFilter",["loc",[null,[12,10],[12,19]]]]],[],1,null,["loc",[null,[12,4],[25,11]]]],
        ["block","if",[["get","useConfirm",["loc",[null,[28,6],[28,16]]]]],[],2,null,["loc",[null,[28,0],[36,7]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});