define('jason-frontend/components/invoice-entries', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    treatmentData: null,
    actions: {
      toggle: function toggle(entry) {

        var tr = $("tr[data-id=" + entry.id + "]");
        if (tr.hasClass('hidden')) {
          tr.removeClass('hidden');
        } else {
          tr.addClass('hidden');
        }

        if (tr.find('select[name=entry-doctor]').hasClass('unchanged')) {
          tr.find('select[name=entry-doctor]').val(entry.get('doctorId'));
        }
        if (tr.find('select[name=entry-assistant]').hasClass('unchanged')) {
          tr.find('select[name=entry-assistant]').val(entry.get('assistantId'));
        }
      },
      saveTreatment: function saveTreatment(data) {
        this.sendAction('treatmentSaved', data);
      },
      open: function open(patientId, invoiceId) {

        var controller = this.get('controller'),
            self = this;

        $('#modal-form').attr('data-patient-id', patientId);
        $('#modal-form').attr('data-invoice-id', invoiceId);

        $.ajax({
          url: "/api/patientTreatmentInfos/" + patientId + "/" + invoiceId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('treatmentData', data);
        }, function (error) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-form'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openHistory: function openHistory(patientId) {
        this.sendAction('openHistory', patientId);
      },
      'delete': function _delete(entryId, invoiceId, productName) {
        this.sendAction('deleted', entryId, invoiceId, productName);
      },
      selectPatient: function selectPatient(patientId) {
        if (isNaN(patientId)) {
          patientId = 0;
        }
        this.sendAction('patientSelected', patientId);
      },
      changeDay: function changeDay(entry, invoiceId) {
        var day = this.$().find("input[data-day-entry-id='" + entry.id + "']").val();
        if (day !== entry.get('day')) {
          this.sendAction('changeDay', invoiceId, day, entry.id);
        }
      },
      changeUser: function changeUser(entry, invoiceId, typeId) {
        var userId = 0;
        if (typeId === 'doctor') {
          userId = this.$().find("input[data-doctor-entry-id='" + entry.id + "']").val();
        } else if (typeId === 'assistant') {
          userId = this.$().find("input[data-assistant-entry-id='" + entry.id + "']").val();
        }
        if (userId > 0 && typeId) {
          this.sendAction('changeUser', invoiceId, entry, userId, typeId);
        }
      },
      changeQuantity: function changeQuantity(entry, invoiceId, productName, oldQty) {
        var qty = this.$().find("input[data-qty-entry-id='" + entry.id + "']").val();
        if (oldQty != qty) {
          qty = String(qty).replace(',', '.');
          if (qty > 0) {
            this.sendAction('quantityChanged', qty, entry.id, invoiceId, productName);
          } else {
            jason.notifiction.error('Fehleingabe', 'Menge muss größer/gleich 0 sein!');
          }
        }
      },
      changeText: function changeText(entry, invoiceId, productName, oldText) {
        var text = this.$().find("input[data-article-text-entry-id='" + entry.id + "']").val();
        if (oldText !== text) {
          this.sendAction('textChanged', text, entry.id, invoiceId, productName);
        }
      },
      changeDescription: function changeDescription(entry, invoiceId, productName, oldText) {
        var text = this.$().find("input[data-article-description-entry-id='" + entry.id + "']").val();
        if (oldText !== text) {
          this.sendAction('descriptionChanged', text, entry.id, invoiceId, productName);
        }
      },
      increaseQuantity: function increaseQuantity(entry, invoiceId, productName) {
        var qty = parseFloat(this.$().find("input[data-qty-entry-id='" + entry.id + "']").val());
        this.sendAction('quantityChanged', qty + 1, entry.id, invoiceId, productName);
      },
      decreaseQuantity: function decreaseQuantity(entry, invoiceId, productName) {
        var qty = parseFloat(this.$().find("input[data-qty-entry-id='" + entry.id + "']").val());
        this.sendAction('quantityChanged', qty - 1, entry.id, invoiceId, productName);
      },
      changePrice: function changePrice(entry, invoiceId, productName) {
        var price = this.$().find("input[data-price-entry-id='" + entry.id + "']").val();
        price = price.replace(',', '.');
        if (price > 0) {
          this.sendAction('priceChanged', price, entry.id, invoiceId, productName);
        } else {
          jason.notifiction.error('Fehleingabe', 'Preis muss größer/gleich 0 sein');
        }
      },
      changeGrossPrice: function changeGrossPrice(entry, percentage, invoiceId, productName) {
        var grossPrice = String(this.$().find("input[data-gross-price-entry-id='" + entry.id + "']").val()).replace(',', '.');
        var netPrice = this.$().find("input[data-price-entry-id='" + entry.id + "']"),
            newNetPrice = Math.round(grossPrice / (100 + percentage) * 100 * 1000) / 1000;

        if (newNetPrice > 0) {
          netPrice.val(String(newNetPrice).replace('.', ','));
          this.sendAction('priceChanged', newNetPrice, entry.id, invoiceId, productName);
        } else {
          jason.notifiction.error('Fehleingabe', 'Preis muss größer/gleich 0 sein');
        }
      },
      changeApplied: function changeApplied(entry, invoiceId, productName) {
        var applied = this.$().find("input[data-applied-entry-id='" + entry.id + "']").val();
        if (applied === 'false') {
          applied = "true";
        } else {
          applied = "false";
        }
        this.sendAction('appliedChanged', applied, entry.id, invoiceId, productName);
      },
      changeDiscount: function changeDiscount(entry, invoiceId, absolute, productName, oldDiscount) {
        var discount = this.$().find("input[data-discount-entry-id='" + entry.id + "']").val();
        if (oldDiscount != discount) {
          if (discount.replace(',', '.') >= 0) {
            this.sendAction('discountChanged', discount, absolute, entry.id, invoiceId, productName);
          }
        }
      },
      changeBankadvice: function changeBankadvice(entry, invoiceId, productName) {
        var advice = this.$().find("input[data-bankadvice-entry-id='" + entry.id + "']").val();
        if (advice === 'false') {
          advice = "true";
        } else {
          advice = "false";
        }
        this.sendAction('bankadviceChanged', advice, entry.id, invoiceId, productName);
      },
      setRelativeDiscount: function setRelativeDiscount(entry, invoiceId, discount, absolute, productName) {
        if (absolute) {
          this.sendAction('relativeDiscountChanged', entry.id, discount, invoiceId, productName);
        }
      },
      setAbsoluteDiscount: function setAbsoluteDiscount(entry, invoiceId, discount, absolute, productName) {
        if (!absolute) {
          this.sendAction('absoluteDiscountChanged', entry.id, discount, invoiceId, productName);
        }
      }
    },
    initHeaderSelection: function initHeaderSelection() {},

    becomesLoaded: (function () {

      $(document).on("click", "table#invoices tr.head", function () {
        $('table#invoices tr.head').each(function () {
          $(this).removeClass('selected');
        });
        $(this).addClass('selected');
      });

      $(document).on("change", "table#invoices select", function () {
        var self = $(this);

        var invoiceId = $('table#invoices').data('invoice-id'),
            orderEntryId = self.closest('tr').data('id');

        var selected = self.context ? self.context.value : null;

        self.removeClass('unchanged');

        var data = {
          userId: selected,
          type: self.hasClass('doctor') ? 'doctor' : 'assistant'
        };

        $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeUser/" + orderEntryId,
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (error) {});
      });

      $(document).on("focus", "input.datepicker:not(.hasDatepicker)", function () {
        $(this).daterangepicker({
          singleDatePicker: true,
          autoUpdateInput: true,
          "locale": {
            "format": "DD.MM.YYYY",
            "daysOfWeek": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
            "monthNames": ["Jänner", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
            "firstDay": 1
          }
        });
        $(this).addClass('hasDatepicker');
      });
    }).on('didInsertElement'),
    maxObserver: (function () {
      this.set('shownHeads', new Array());
    }).on('didInsertElement').observes('invoice')
  });

});