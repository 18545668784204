define('jason-frontend/models/digital-diagnose', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    internalId: DS['default'].attr(''),
    fullId: DS['default'].attr(''),
    fullName: DS['default'].attr('string'),
    key: DS['default'].attr('string'),
    nameWithKey: DS['default'].attr(''),
    parentKey: DS['default'].attr('string'),
    parentName: DS['default'].attr('string'),
    locations: DS['default'].attr(''),
    kind: DS['default'].attr(''),
    details: DS['default'].attr(''),
    color: DS['default'].attr(''),
    category: DS['default'].attr(''),
    synonyms: DS['default'].attr(''),
    simpleName: DS['default'].attr(''),
    characteristics: DS['default'].attr(''),
    selectedCharacteristics: DS['default'].attr(''),
    selectedLocations: DS['default'].attr('')
  });

});