define('jason-frontend/routes/purchases/cashback', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var self = this;
      this.store.find('mandant', { filterModule: 'test' }).then(function (data) {
        controller.set('mandants', data);
        var found = false;
        var mandantId = API['default'].getMandant();
        data.forEach(function (item) {
          if (mandantId == 4) {
            if (!found) {
              controller.set('filterMandant', item);
              self.applyFilter();
              found = true;
            }
          } else {
            if (item.id == mandantId && !found) {
              controller.set('filterMandant', item);
              self.applyFilter();
              found = true;
            }
          }
        });
      });

      $.ajax({
        url: "/api/months",
        method: "GET",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('months', data);

        var currentMonth = moment().month() + 1;
        var found = false;

        data.forEach(function (item) {
          if (!found && item.id === currentMonth) {
            controller.set('filterMonth', item);
            found = true;
          }
        });
      });

      $.ajax({
        url: "/api/years",
        method: "GET",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('years', data);

        var currentYear = moment().year();
        var found = false;

        data.forEach(function (item) {
          if (!found && item.id === currentYear) {
            controller.set('filterYear', item);
            found = true;
          }
        });
      });
    },
    actions: {
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      },
      sortable: function sortable(attr) {
        if (this.get('controller').get('sortBy') === attr) {
          if (this.get('controller').get('sortDir') === 'asc') {
            this.get('controller').set('sortDir', 'desc');
          } else {
            this.get('controller').set('sortDir', 'asc');
          }
        } else {
          this.get('controller').set('sortBy', attr);
        }
        this.applyFilter();
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterMandant = controller.get('filterMandant'),
          filterMonth = controller.get('filterMonth'),
          filterYear = controller.get('filterYear');

      if (filterMandant && isNaN(filterMandant)) {
        filterMandant = filterMandant.id;
      } else if (filterMandant && !isNaN(filterMandant)) {
        filterMandant = filterMandant;
      } else {
        filterMandant = "";
      }

      if (filterYear) {
        filterYear = filterYear.id;
      }
      if (filterMonth) {
        filterMonth = filterMonth.id;
      }

      controller.set('showLoading', true);

      $.ajax({
        url: "/api/purchaseCashbacks?filterMandant=" + filterMandant + "&month=" + filterMonth + "&year=" + filterYear,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('showLoading', false);
        controller.set('model', data.purchaseCashback);
        controller.set('totalCashback', data.meta.total_cashback);
        controller.set('totalTariff', data.meta.totalTariff);
        controller.set('totalCashbackReduction', data.meta.total_cashback_reduction);
      });
    }
  });

});