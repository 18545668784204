define('jason-frontend/templates/components/consent-upload-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 16
            },
            "end": {
              "line": 28,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/consent-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[27,18],[27,39]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 16
            },
            "end": {
              "line": 30,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/consent-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button context");
          dom.setAttribute(el1,"id","triggerFileUploadConsent");
          var el2 = dom.createTextNode(" Datei auswählen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 88,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/consent-upload-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-consent-upload-panel");
        dom.setAttribute(el1,"class","small--heading popup-basic popup-large admin-form mfp-with-anim mfp-hide uploadContainer");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n              ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("\n                Einverständniserklärung hochladen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","box pb0");
        var el4 = dom.createTextNode("\n                ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","consentEditPanel");
        dom.setAttribute(el4,"class","form-grid new-style");
        var el5 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-24/24@desk l-24/24@phone gutter");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","name");
        dom.setAttribute(el6,"class","field-label");
        var el7 = dom.createTextNode("Titel");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-24/24 gutter");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","pickDate");
        dom.setAttribute(el6,"class","date-picker input-element");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","field-label");
        var el8 = dom.createTextNode("Unterzeichnet am");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","field-icon");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("i");
        dom.setAttribute(el8,"class","fa fa-calendar");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      Uhr\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","box");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Hinweise");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5,"class","hints");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        dom.setAttribute(el6,"class","mb0");
        var el7 = dom.createTextNode("Die max. Dateigröße beträgt 10 MByte!");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n\n    $(\"#consentEditPanel\").validate({\n      errorClass: \"state-error\",\n      validClass: \"state-success\",\n      errorElement: \"em\",\n      rules: {\n        'name': {\n          required: true\n        }\n      },\n      highlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n      },\n      unhighlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n      },\n      errorPlacement: function(error, element) {\n        if (element.is(\":radio\") || element.is(\":checkbox\")) {\n          element.closest('.option-group').after(error);\n        } else {\n          error.insertAfter(element.parent());\n        }\n      }\n    });\n\n    $('body').delegate('#triggerFileUploadConsent', 'click', function(event){\n      console.log(\"clock\")\n      if(!$(this).hasClass('init')) {\n        $('#modal-consent-upload-panel input[type=file]').click();\n        setTimeout(function() {\n          $('#triggerFileUploadConsent').removeClass('init');\n        }, 500);\n      }\n      $(this).addClass('init');\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1, 3, 1]);
        var element2 = dom.childAt(element1, [3, 1]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element0, 'data-customer-id');
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]),3,3);
        morphs[3] = dom.createMorphAt(element2,3,3);
        morphs[4] = dom.createMorphAt(element2,7,7);
        morphs[5] = dom.createMorphAt(element1,5,5);
        morphs[6] = dom.createMorphAt(element1,7,7);
        return morphs;
      },
      statements: [
        ["attribute","data-customer-id",["concat",[["get","customerId",["loc",[null,[1,154],[1,164]]]]]]],
        ["element","action",["save"],["on","submit"],["loc",[null,[8,72],[8,101]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","name",["loc",[null,[12,34],[12,38]]]]],[],[]],"autofocus",true,"class","gui-input new-style","title","Bitte geben Sie einen Titel an","name","name","id","uploadConsentName"],["loc",[null,[12,20],[12,157]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","created",["loc",[null,[17,36],[17,43]]]]],[],[]],"class","input--date-picker small-style input datetimepicker2 ember-view ember-text-field","name","created","id","uploadConsentDate"],["loc",[null,[17,22],[17,172]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","createdTime",["loc",[null,[21,36],[21,47]]]]],[],[]],"class","input date-picker__input new-style time fixed-timepicker-width","name","createdTime","placeholder","12:00","id","uploadConsentTime"],["loc",[null,[21,22],[21,182]]]],
        ["block","if",[["get","uploading",["loc",[null,[26,22],[26,31]]]]],[],0,1,["loc",[null,[26,16],[30,23]]]],
        ["inline","consent-upload-widget",[],["uploadDone","uploadDone","uploadInProgress","uploadInProgress","uploadError","uploadError"],["loc",[null,[33,12],[33,123]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});