define('jason-frontend/components/notification-center-calendar', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      collapse: function collapse(item) {
        item.set('collapsed', true);
      },
      openMessage: function openMessage(id) {
        // var notAllowed = $('li#message' + id + ' div.full-text').hasClass('hidden');
        // var markedAsRead = this.get('markedAsRead');
        // if (!notAllowed && !markedAsRead) {
        this.sendAction('openNotification');
        // }
      },
      toNotifications: function toNotifications() {
        $('#notificationPanelCalendar').addClass('hidden');
        this.sendAction('openNotification');
      },
      toCalendar: function toCalendar() {
        $('#notificationPanelCalendar').addClass('hidden');
        this.sendAction('openCalendar');
      },
      toEtigaNotifications: function toEtigaNotifications() {
        $('#notificationPanelCalendar').addClass('hidden');
        this.sendAction('openEtigaNotifications');
      },
      markAllAsRead: function markAllAsRead(msg) {
        this.sendAction('markAllAsRead', msg);
        this.set('markedAsRead', true);

        var self = this;

        Ember['default'].run.later(this, function () {

          $.ajax({
            url: "/api/msgs/unread?filter=cal",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.set('unreadMessages', data.msg);
            self.set('unreadMessagesCount', data.meta.total_records);
            self.set('undoneCount', data.meta.total_undone);
            self.set('markedAsRead', false);
          });
        }, 500);
      },
      markAsRead: function markAsRead(msg) {
        this.sendAction('markAsRead', msg);

        this.set('markedAsRead', true);

        var self = this;

        Ember['default'].run.later(this, function () {

          $.ajax({
            url: "/api/msgs/unread?filter=cal",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.set('unreadMessages', data.msg);
            self.set('unreadMessagesCount', data.meta.total_records);
            self.set('markedAsRead', false);
          });
        }, 500);
      },
      markAsReadForAll: function markAsReadForAll(msg) {
        this.sendAction('markAsReadForAll', msg);

        this.set('markedAsRead', true);

        var self = this;

        Ember['default'].run.later(this, function () {

          $.ajax({
            url: "/api/msgs/unread?filter=cal",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.set('unreadMessages', data.msg);
            self.set('unreadMessagesCount', data.meta.total_records);
            self.set('markedAsRead', false);
          });
        }, 500);
      }
    },
    _onReady: (function () {
      this.fetchMessages();
    }).on('didInsertElement'),
    fetchMessages: function fetchMessages() {
      var self = this;

      $.ajax({
        url: "/api/msgs/unread?filter=cal",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('unreadMessages', data.msg);
        self.set('unreadMessagesCount', data.meta.total_records);
      });
      Ember['default'].run.later(this, function () {
        self.fetchMessages();
      }, 55000);
    }
  });

});