define('jason-frontend/components/set-death-day-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    selectTab: 'msg',
    type: 'died',
    deathDay: moment().format('DD. MM. YYYY'),
    actions: {
      confirm: function confirm() {

        var day = this.get('deathDay');

        if (!day) {
          day = Date.now();
        }
        var taskData = {
          deathDay: moment(day, 'DD. MM. YYYY').format('YYYY-MM-DD'),
          id: this.get('id'),
          type: this.get('type'),
          notice: this.get('notice')
        };

        if (this.get('type') === 'dead' && this.get('dead') || this.get('type') === 'giveaway' && this.get('sold')) {
          this.sendAction('remove', taskData);
        } else {
          this.sendAction('confirm', taskData);
        }

        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(patient, type) {

        if (type === 'dead') {
          this.set('dead', patient.dead);
          this.set('sold', false);
          this.set('savedDeathDay', patient.deathday);
        } else {
          this.set('sold', patient.sold);
          this.set('dead', false);
          this.set('savedDeathDay', patient.soldDate);
        }

        this.set('id', patient.id);
        this.set('type', type);
      },
      loadDetails: function loadDetails(patient, type) {

        if (type === 'dead') {
          this.set('dead', patient.get('dead'));
          this.set('sold', false);
          this.set('savedDeathDay', patient.get('deathday'));
        } else {
          this.set('sold', patient.get('sold'));
          this.set('dead', false);
          this.set('savedDeathDay', patient.get('soldDate'));
        }

        this.set('id', patient.id);
        this.set('type', type);
      }
    }
  });

});