define('jason-frontend/routes/templates/index', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model() {},
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);
      var self = this;
      var selectedTab = params.queryParams.selectTab;

      $.ajax({
        url: "/api/mandants/" + API['default'].getMandant() + "/emailText",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('emailData', data.mandantEmailText);
      });

      if (API['default'].getMandant() == 10007) {
        this.get('controller').set('styles', this.get('controller').get('stylesAlternative'));
      }

      if (selectedTab) {
        this.selectTab(selectedTab);
      } else {
        setTimeout(function () {
          self.selectTab('templates');
        }, 1000);
      }
    },
    selectTab: function selectTab(selected) {
      var controller = this.get('controller');
      controller.set('selectTab', selected);

      if (selected === 'templates') {
        $.ajax({
          url: "/api/templates?per_page=100&filterName=" + controller.get('filterName'),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('templates', data.template);
        });
      } else if (selected === 'textmodules') {
        this.findPaged('textModule', {
          filterName: controller.get('filterName'),
          filterType: 'def',
          perPage: 10
        }).then(function (entries) {
          controller.set('textModules', entries);
          controller.set("showLoading", false);
        });
      } else if (selected === 'reports') {
        this.findPaged('textModule', {
          filterName: controller.get('filterName'),
          filterType: 'report',
          perPage: 10
        }).then(function (entries) {
          controller.set('textModules', entries);
          controller.set("showLoading", false);
        });
      } else if (selected === 'invoices') {
        this.findPaged('textModule', {
          filterName: controller.get('filterName'),
          filterType: 'invoice',
          perPage: 10
        }).then(function (entries) {
          controller.set('textModules', entries);
          controller.set("showLoading", false);
        });
      } else if (selected === 'emails') {
        this.findPaged('textModule', {
          filterName: controller.get('filterName'),
          filterType: 'email',
          perPage: 10
        }).then(function (entries) {
          controller.set('textModules', entries);
          controller.set("showLoading", false);
        });
      } else if (selected === 'connectedArticles') {
        this.findPaged('futureProduct', {
          filterName: controller.get('filterName'),
          perPage: 10
        }).then(function (entries) {
          controller.set('products', entries);
          controller.set("showLoading", false);
        });
      } else if (selected === 'settings') {

        Ember['default'].$.ajax({
          url: "/api/mandants/defaultTemplateStyle",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          controller.set('defaultTemplateStyle', data.responseText);
        });
      }
    },
    actions: {
      createTextmodule: function createTextmodule() {
        this.transitionTo('textmodules.edit', 0, 'def');
      },
      createTextmoduleForReport: function createTextmoduleForReport() {
        this.transitionTo('textmodules.edit', 0, 'report');
      },
      createTextmoduleForInvoice: function createTextmoduleForInvoice() {
        this.transitionTo('textmodules.edit', 0, 'invoice');
      },
      createTextmoduleForMail: function createTextmoduleForMail() {
        this.transitionTo('textmodules.edit', 0, 'email');
      },
      applyFilter: function applyFilter() {
        this.selectTab(this.get('controller').get('selectTab'));
      },
      gotoProductFuture: function gotoProductFuture(id, type) {
        if (type === 'product') {
          this.transitionTo('products.edit', id, { queryParams: { selectTab: "future" } });
        } else if (type === 'service') {
          this.transitionTo('services.edit', id, { queryParams: { selectTab: "future" } });
        } else if (type === 'package') {
          this.transitionTo('compositeproducts.edit', id, { queryParams: { selectTab: "future" } });
        }
      },
      sendTestmail: function sendTestmail(email, type) {
        if (type === 'warning') {
          var data = {
            email: email,
            mandantId: API['default'].getMandant()
          };

          setTimeout(function () {
            Ember['default'].$.ajax({
              url: "/api/mandants/sendTestmail",
              method: "POST",
              contentType: "application/json",
              data: JSON.stringify(data),
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function () {
              jason.notifiction.notifiy('Erfolgreich', 'Test-Email verschickt');
            }, function () {
              jason.notifiction.notifiy('Erfolgreich', 'Test-Email verschickt');
            });
          }, 1000);
        } else if (type === 'reminder') {
          var data = {
            email: $('#testemail').val(),
            mandantId: API['default'].getMandant()
          };

          setTimeout(function () {
            Ember['default'].$.ajax({
              url: "/api/mandants/sendTestmailReminder",
              method: "POST",
              contentType: "application/json",
              data: JSON.stringify(data),
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function () {
              jason.notifiction.notifiy('Erfolgreich', 'Test-Email verschickt');
            }, function () {
              jason.notifiction.notifiy('Erfolgreich', 'Test-Email verschickt');
            });
          }, 1000);
        }
      },
      save: function save() {
        var self = this,
            model = self.get('controller').get('emailData');

        if (self.get('controller').get('selectTab') === 'settings') {
          $.ajax({
            url: "/api/mandants/defaultTemplateStyle/" + self.get('controller').get('defaultTemplateStyle'),
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {
            jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          }, function (error) {});
          return;
        }

        var data = JSON.stringify(model);
        data = JSON.parse(data);

        data.appReorderConfirm = $('#appReorderConfirm').html();
        data.appDefaultAppTitle = $('#appDefaultAppTitle').html();
        data.appDefaultAppDeclineTitle = $('#appDefaultAppDeclineTitle').html();

        $.ajax({
          url: "/api/mandants/emailTexts/" + API['default'].getMandant(),
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {}, function (error) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        });
      },
      deleteTemplate: function deleteTemplate(id) {
        var self = this;
        $.ajax({
          url: "/api/templates/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          jason.notifiction.notifiy('Erfolgreich', 'entfernt');
          self.selectTab('templates');
        }, function (error) {});
      },
      deleteTextModule: function deleteTextModule(id, area) {
        var self = this;
        $.ajax({
          url: "/api/textModules/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          jason.notifiction.notifiy('Erfolgreich', 'entfernt');
          self.selectTab('textmodules');
        }, function (error) {});
      },
      deleteTextModuleForEmail: function deleteTextModuleForEmail(id, area) {
        var self = this;
        $.ajax({
          url: "/api/textModules/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          jason.notifiction.notifiy('Erfolgreich', 'entfernt');
          self.selectTab('emails');
        }, function (error) {});
      },
      selectTab: function selectTab(id) {
        this.selectTab(id);
      },
      createTemplate: function createTemplate() {
        this.transitionTo('editor.edit', 0, 'template', 0);
      },
      openTextModule: function openTextModule(id, type) {
        this.transitionTo('textmodules.edit', id, type);
      },
      openEditTemplate: function openEditTemplate(templateId) {
        this.transitionTo('editor.edit', templateId, 'template', 0);
      }
    }
  });

});