define('jason-frontend/templates/components/edit-todo-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 16
            },
            "end": {
              "line": 7,
              "column": 56
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-todo-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("TO-DO bearbeiten");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 56
            },
            "end": {
              "line": 7,
              "column": 75
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-todo-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Neues TO-DO");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 8
            },
            "end": {
              "line": 23,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-todo-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","field field-datepicker");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","nextExecution");
          dom.setAttribute(el2,"class","field-label title");
          var el3 = dom.createTextNode("Fällig am");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","date-picker__body like-h3 inline-block");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              um ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" Uhr\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1, 3]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element1,1,1);
          morphs[1] = dom.createMorphAt(element1,3,3);
          return morphs;
        },
        statements: [
          ["inline","input",[],["value",["subexpr","@mut",[["get","item.dueDateString",["loc",[null,[19,28],[19,46]]]]],[],[]],"class","js-date-picker input date-picker__input fixed-datepicker-width","name","dueDateString","placeholder","Datum"],["loc",[null,[19,14],[19,160]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","item.dueDateTime",["loc",[null,[20,31],[20,47]]]]],[],[]],"name","taskDuetime","class","input date-picker__input time fixed-timepicker-width"],["loc",[null,[20,17],[20,129]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 91
              },
              "end": {
                "line": 42,
                "column": 128
              }
            },
            "moduleName": "jason-frontend/templates/components/edit-todo-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("nach");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 128
              },
              "end": {
                "line": 42,
                "column": 140
              }
            },
            "moduleName": "jason-frontend/templates/components/edit-todo-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("alle");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 58,
                "column": 10
              },
              "end": {
                "line": 66,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/edit-todo-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("           ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","mt20");
            var el2 = dom.createTextNode("\n             wiederhole\n             ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","spinner");
            dom.setAttribute(el2,"style","vertical-align: middle;");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n             Mal\n           ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
            return morphs;
          },
          statements: [
            ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","item.dueTimeUnitIterations",["loc",[null,[62,44],[62,70]]]]],[],[]],"id","spinner-2"],["loc",[null,[62,14],[62,87]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 8
            },
            "end": {
              "line": 68,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-todo-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","field--narrow");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","new-not-title");
          dom.setAttribute(el2,"class","field-label title");
          var el3 = dom.createTextNode("Häufigkeit");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","field--narrow");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","new-not-title");
          dom.setAttribute(el2,"class","field-label title");
          var el3 = dom.createTextNode("Fälligkeit");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","like-h3 inline-block");
          dom.setAttribute(el2,"style","vertical-align: top;padding-top: 7px;");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","spinner");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","inline-block vertical-bottom");
          dom.setAttribute(el2,"style","vertical-align: top;margin-top: -3px");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","like-h3 inline-block");
          dom.setAttribute(el2,"style","vertical-align: top;padding-top: 8px;margin-left: 10px;");
          var el3 = dom.createTextNode("um ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" Uhr");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),5,5);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [7]),1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element0, [9]),1,1);
          morphs[5] = dom.createMorphAt(element0,11,11);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","repeatFilter",["loc",[null,[29,28],[29,40]]]]],[],[]],"value",["subexpr","@mut",[["get","item.repeat",["loc",[null,[30,26],[30,37]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","name","optionValuePath","id","allowClear",false,"name","repeat"],["loc",[null,[28,12],[36,14]]]],
          ["block","if",[["subexpr","eq",[["get","item.repeat",["loc",[null,[42,101],[42,112]]]],"single"],[],["loc",[null,[42,97],[42,122]]]]],[],0,1,["loc",[null,[42,91],[42,147]]]],
          ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","item.dueTimeUnitInterval",["loc",[null,[44,42],[44,66]]]]],[],[]],"id","spinner-1"],["loc",[null,[44,12],[44,83]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","timeUnits",["loc",[null,[48,28],[48,37]]]]],[],[]],"value",["subexpr","@mut",[["get","item.dueTimeUnit",["loc",[null,[49,26],[49,42]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","allowClear",true,"name","future-timeunit","placeholder","Bitte wählen"],["loc",[null,[47,12],[55,14]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","item.time",["loc",[null,[57,125],[57,134]]]]],[],[]],"name","activeFromTime","class","input date-picker__input time fixed-timepicker-width"],["loc",[null,[57,111],[57,219]]]],
          ["block","if",[["subexpr","eq",[["get","item.repeat",["loc",[null,[58,20],[58,31]]]],"repeat"],[],["loc",[null,[58,16],[58,41]]]]],[],2,null,["loc",[null,[58,10],[66,17]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 109,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/edit-todo-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-edit-todo-panel");
        dom.setAttribute(el1,"class","popup-basic popup-large admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"id","editTodoForm");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-heading");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","panel-title");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-body p25");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-24/24 mb20");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","new-not-title");
        dom.setAttribute(el6,"class","field-label title");
        var el7 = dom.createTextNode("Titel");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-footer text-right");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","submit");
        dom.setAttribute(el5,"class","icon-button icon-button--success");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function () {\n\n    $(\"#editTodoForm\").validate({\n      errorClass: \"state-error\",\n      validClass: \"state-success\",\n      errorElement: \"em\",\n      rules: {\n        'name': {\n          required: true\n        }\n      },\n      highlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n      },\n      unhighlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n      },\n      errorPlacement: function(error, element) {\n        if (element.is(\":radio\") || element.is(\":checkbox\")) {\n          element.closest('.option-group').after(error);\n        } else {\n          error.insertAfter(element.parent());\n        }\n      }\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 1, 1]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createElementMorph(element2);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [1, 1]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]),3,3);
        morphs[3] = dom.createMorphAt(element3,3,3);
        morphs[4] = dom.createMorphAt(element3,4,4);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [5, 1]),1,1);
        return morphs;
      },
      statements: [
        ["element","action",["confirm"],["on","submit"],["loc",[null,[3,28],[3,60]]]],
        ["block","if",[["subexpr","eq",[["get","mode",["loc",[null,[7,26],[7,30]]]],"edit"],[],["loc",[null,[7,22],[7,38]]]]],[],0,1,["loc",[null,[7,16],[7,82]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.name",["loc",[null,[13,24],[13,33]]]]],[],[]],"class","gui-input","name","new-not-title","autocomplete","off"],["loc",[null,[13,10],[13,93]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[15,18],[15,22]]]],"instance"],[],["loc",[null,[15,14],[15,34]]]]],[],2,null,["loc",[null,[15,8],[23,15]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[24,18],[24,22]]]],"template"],[],["loc",[null,[24,14],[24,34]]]]],[],3,null,["loc",[null,[24,8],[68,7]]]],
        ["inline","button-save",[],["size","52","color","#ffffff"],["loc",[null,[74,10],[74,51]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});