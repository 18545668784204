define('jason-frontend/models/etiga-campaign', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    title: DS['default'].attr('string'),
    editId: DS['default'].attr('number'),
    created: DS['default'].attr('date'),
    submitted: DS['default'].attr('date'),
    content: DS['default'].attr('string'),
    status: DS['default'].attr('string'),
    patientAge: DS['default'].attr(),
    patientAgeTo: DS['default'].attr(),
    patientCategory: DS['default'].attr(),
    patientGender: DS['default'].attr(),
    patients: DS['default'].attr()
  });

});