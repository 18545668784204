define('jason-frontend/templates/services/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 4
            },
            "end": {
              "line": 9,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/services/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createTextNode("Neue Leistung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 2
            },
            "end": {
              "line": 30,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/services/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","filter-box grid");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","u-mb0 field select full-width");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","title");
          var el5 = dom.createTextNode("Kategorie");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]),3,3);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","categories",["loc",[null,[20,20],[20,30]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedCategoryFilters",["loc",[null,[21,18],[21,41]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","allowClear",true,"placeholder","Alle"],["loc",[null,[19,10],[26,12]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 10
              },
              "end": {
                "line": 55,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/services/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","selectable-item has-icon");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createAttrMorph(element7, 'for');
            morphs[2] = dom.createElementMorph(element7);
            morphs[3] = dom.createMorphAt(element7,0,0);
            return morphs;
          },
          statements: [
            ["inline","input",[],["type","checkbox","id",["subexpr","concat",["tag_",["get","tag.internalId",["loc",[null,[53,54],[53,68]]]]],[],["loc",[null,[53,39],[53,69]]]],"checked",["subexpr","@mut",[["get","tag.selected",["loc",[null,[53,78],[53,90]]]]],[],[]]],["loc",[null,[53,12],[53,92]]]],
            ["attribute","for",["concat",[["subexpr","concat",["tag_",["get","tag.internalId",["loc",[null,[54,73],[54,87]]]]],[],["loc",[null,[54,57],[54,89]]]]]]],
            ["element","action",["selectTag",["get","tag",["loc",[null,[54,112],[54,115]]]]],[],["loc",[null,[54,91],[54,117]]]],
            ["content","tag.name",["loc",[null,[54,118],[54,130]]]]
          ],
          locals: ["tag"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 8
            },
            "end": {
              "line": 56,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/services/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","articleTags",["loc",[null,[52,18],[52,29]]]]],[],0,null,["loc",[null,[52,10],[55,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 66,
                "column": 66
              },
              "end": {
                "line": 67,
                "column": 59
              }
            },
            "moduleName": "jason-frontend/templates/services/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","table__title title");
            var el2 = dom.createTextNode("VK brutto");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 6
            },
            "end": {
              "line": 70,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/services/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__head sticker");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-2/24");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","table__title title");
          var el4 = dom.createTextNode("Bezeichnung");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-3/24");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","table__title title");
          var el4 = dom.createTextNode("Tags");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-4/24 table__cell--center");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","table__title title");
          var el4 = dom.createTextNode("Einheit");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right@desk l-3/24");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-4/24");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 9]),0,0);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[66,72],[66,85]]]]],[],0,null,["loc",[null,[66,66],[67,66]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 78,
                "column": 16
              },
              "end": {
                "line": 82,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/services/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            morphs[1] = dom.createElementMorph(element1);
            morphs[2] = dom.createMorphAt(element1,1,1);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["selectable-item favs cursor ",["subexpr","css-bool-evaluator",[["get","service.favorite",["loc",[null,[79,157],[79,173]]]],"selected",""],[],["loc",[null,[79,136],[79,189]]]]]]],
            ["element","action",["toggleServiceFavorite",["get","service",["loc",[null,[79,54],[79,61]]]],["subexpr","not",[["get","service.favorite",["loc",[null,[79,67],[79,83]]]]],[],["loc",[null,[79,62],[79,84]]]]],["bubbles",false],["loc",[null,[79,21],[79,100]]]],
            ["inline","button-favorite",[],["size","18","color","#999999","showStroke",false,"content",""],["loc",[null,[80,20],[80,93]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 86,
                "column": 20
              },
              "end": {
                "line": 86,
                "column": 75
              }
            },
            "moduleName": "jason-frontend/templates/services/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","service.name",["loc",[null,[86,59],[86,75]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 87,
                  "column": 213
                },
                "end": {
                  "line": 87,
                  "column": 260
                }
              },
              "moduleName": "jason-frontend/templates/services/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" | Zeit");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 87,
                "column": 32
              },
              "end": {
                "line": 87,
                "column": 267
              }
            },
            "moduleName": "jason-frontend/templates/services/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("GOT 1-fach netto ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" | Faktor: ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" %");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","service.singleGotPrice",["loc",[null,[87,158],[87,180]]]]],[],[]]],["loc",[null,[87,114],[87,182]]]],
            ["content","service.factor",["loc",[null,[87,193],[87,211]]]],
            ["block","if",[["subexpr","eq",[["get","service.unit.acronym",["loc",[null,[87,223],[87,243]]]],"zeit"],[],["loc",[null,[87,219],[87,251]]]]],[],0,null,["loc",[null,[87,213],[87,267]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 87,
                    "column": 331
                  },
                  "end": {
                    "line": 87,
                    "column": 377
                  }
                },
                "moduleName": "jason-frontend/templates/services/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("(Intern)");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 87,
                    "column": 377
                  },
                  "end": {
                    "line": 87,
                    "column": 400
                  }
                },
                "moduleName": "jason-frontend/templates/services/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("(Reference Lab)");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 87,
                  "column": 301
                },
                "end": {
                  "line": 87,
                  "column": 407
                }
              },
              "moduleName": "jason-frontend/templates/services/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","service.vetconnectTest.inHouse",["loc",[null,[87,337],[87,367]]]]],[],0,1,["loc",[null,[87,331],[87,407]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 87,
                "column": 267
              },
              "end": {
                "line": 87,
                "column": 414
              }
            },
            "moduleName": "jason-frontend/templates/services/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","service.category.name",["loc",[null,[87,275],[87,300]]]],
            ["block","if",[["get","service.vetconnectTest",["loc",[null,[87,307],[87,329]]]]],[],0,null,["loc",[null,[87,301],[87,414]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child4 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 90,
                  "column": 53
                },
                "end": {
                  "line": 90,
                  "column": 139
                }
              },
              "moduleName": "jason-frontend/templates/services/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge  mr5 mb5");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
              return morphs;
            },
            statements: [
              ["content","tag.name",["loc",[null,[90,119],[90,131]]]]
            ],
            locals: ["tag"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 90,
                "column": 14
              },
              "end": {
                "line": 90,
                "column": 155
              }
            },
            "moduleName": "jason-frontend/templates/services/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","tags");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
            return morphs;
          },
          statements: [
            ["block","each",[["get","service.tags",["loc",[null,[90,61],[90,73]]]]],[],0,null,["loc",[null,[90,53],[90,148]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 91,
                "column": 14
              },
              "end": {
                "line": 95,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/services/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("\n                 ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","info-badge");
            var el3 = dom.createTextNode("Untersuchung");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n               ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 95,
                  "column": 14
                },
                "end": {
                  "line": 99,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/services/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              var el2 = dom.createTextNode("\n                 ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2,"class","info-badge non-uppercase");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" - ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n               ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element0,0,0);
              morphs[1] = dom.createMorphAt(element0,2,2);
              return morphs;
            },
            statements: [
              ["content","service.gotNumberPrefix",["loc",[null,[97,57],[97,84]]]],
              ["content","service.gotNumber",["loc",[null,[97,87],[97,108]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 99,
                    "column": 14
                  },
                  "end": {
                    "line": 103,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/services/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                var el2 = dom.createTextNode("\n                 ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                dom.setAttribute(el2,"class","info-badge non-uppercase");
                var el3 = dom.createTextNode("Impfung");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n               ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 103,
                      "column": 14
                    },
                    "end": {
                      "line": 107,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/services/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  var el2 = dom.createTextNode("\n                 ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("label");
                  dom.setAttribute(el2,"class","info-badge label-danger");
                  var el3 = dom.createTextNode("(gültig BIS 22.11.2022)");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n               ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 108,
                        "column": 16
                      },
                      "end": {
                        "line": 108,
                        "column": 67
                      }
                    },
                    "moduleName": "jason-frontend/templates/services/index.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","none");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 107,
                      "column": 14
                    },
                    "end": {
                      "line": 109,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/services/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n              ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["block","unless",[["get","service.tags",["loc",[null,[108,26],[108,38]]]]],[],0,null,["loc",[null,[108,16],[108,78]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 103,
                    "column": 14
                  },
                  "end": {
                    "line": 109,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/services/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","service.gotDeprecated",["loc",[null,[103,24],[103,45]]]]],[],0,1,["loc",[null,[103,14],[109,14]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 99,
                  "column": 14
                },
                "end": {
                  "line": 109,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/services/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","service.type",["loc",[null,[99,28],[99,40]]]],"vacc"],[],["loc",[null,[99,24],[99,48]]]]],[],0,1,["loc",[null,[99,14],[109,14]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 95,
                "column": 14
              },
              "end": {
                "line": 109,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/services/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","service.type",["loc",[null,[95,28],[95,40]]]],"got"],[],["loc",[null,[95,24],[95,47]]]]],[],0,1,["loc",[null,[95,14],[109,14]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 112,
                "column": 128
              },
              "end": {
                "line": 113,
                "column": 73
              }
            },
            "moduleName": "jason-frontend/templates/services/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","tax");
            var el2 = dom.createTextNode("(");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("% Mwst)");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
            return morphs;
          },
          statements: [
            ["inline","format-currency",[],["value",["subexpr","get-inclusive-price",[["get","service.priceExVat",["loc",[null,[112,200],[112,218]]]],["get","service.tax.percentage",["loc",[null,[112,219],[112,241]]]]],[],["loc",[null,[112,179],[112,242]]]]],["loc",[null,[112,155],[112,244]]]],
            ["content","service.tax.percentage",["loc",[null,[113,33],[113,59]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child8 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 119,
                "column": 14
              },
              "end": {
                "line": 121,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/services/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("Bearbeiten");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[120,16],[120,82]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 73,
              "column": 8
            },
            "end": {
              "line": 124,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/services/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center table__cell--favs l-4/24 l-2/24@desk");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--name l-20/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Bezeichnung");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","sub");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-10/24 table__cell--center table__cell--high text-center l-3/24@desk");
          dom.setAttribute(el2,"data-label","Tags");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24 table__cell--high text-center l-4/24@desk");
          dom.setAttribute(el2,"data-label","Einheit");
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","tax");
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--high table__cell--right l-5/24 l-3/24@desk price-value");
          dom.setAttribute(el2,"data-label","VK brutto");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-4/24@desk buttons-2");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--delete list-action-square exp");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [3]);
          var element4 = dom.childAt(element2, [5]);
          var element5 = dom.childAt(element2, [11]);
          var element6 = dom.childAt(element5, [1]);
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(dom.childAt(element2, [1, 1]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
          morphs[3] = dom.createMorphAt(element4,1,1);
          morphs[4] = dom.createMorphAt(element4,3,3);
          morphs[5] = dom.createMorphAt(dom.childAt(element2, [7, 0]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element2, [9]),0,0);
          morphs[7] = dom.createMorphAt(element6,1,1);
          morphs[8] = dom.createMorphAt(dom.childAt(element6, [3]),0,0);
          morphs[9] = dom.createMorphAt(element5,3,3);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","filter-favorite",[["get","service.favorite",["loc",[null,[78,39],[78,55]]]],["get","filterFavorites",["loc",[null,[78,56],[78,71]]]]],[],["loc",[null,[78,22],[78,72]]]]],[],0,null,["loc",[null,[78,16],[82,23]]]],
          ["block","link-to",["services.edit",["get","service.id",["loc",[null,[86,47],[86,57]]]]],[],1,null,["loc",[null,[86,20],[86,87]]]],
          ["block","if",[["subexpr","and",[["subexpr","is-operating-country",["DE"],[],["loc",[null,[87,43],[87,70]]]],["subexpr","eq",[["get","service.type",["loc",[null,[87,75],[87,87]]]],"got"],[],["loc",[null,[87,71],[87,94]]]]],[],["loc",[null,[87,38],[87,95]]]]],[],2,3,["loc",[null,[87,32],[87,421]]]],
          ["block","if",[["get","service.tags",["loc",[null,[90,20],[90,32]]]]],[],4,null,["loc",[null,[90,14],[90,162]]]],
          ["block","if",[["subexpr","eq",[["get","service.type",["loc",[null,[91,24],[91,36]]]],"digital-form"],[],["loc",[null,[91,20],[91,52]]]]],[],5,6,["loc",[null,[91,14],[109,21]]]],
          ["content","service.unit.name",["loc",[null,[111,113],[111,134]]]],
          ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[112,134],[112,147]]]]],[],7,null,["loc",[null,[112,128],[113,80]]]],
          ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","service",["loc",[null,[116,94],[116,101]]]]],[],[]],"confirmed","delete","text","Wollen Sie diese Leistung wirklich löschen?"],["loc",[null,[116,16],[116,173]]]],
          ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","service",["loc",[null,[117,47],[117,54]]]]],[],[]],"confirmed","delete","textToShow","Löschen","text","Wollen Sie diese Leistung wirklich löschen?"],["loc",[null,[117,22],[117,147]]]],
          ["block","link-to",["services.edit",["get","service",["loc",[null,[119,41],[119,48]]]],["subexpr","query-params",[],["referer","sale"],["loc",[null,[119,49],[119,78]]]]],["class","exp icon-button icon-button--default list-action-square"],8,null,["loc",[null,[119,14],[121,26]]]]
        ],
        locals: ["service"],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 132,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/services/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-14/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","card site-content product-list add-product add-sale-product with-context-bar");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper box no-box@phone");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter box@phone");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-24/24 l-10/24@desk");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Schnellfilter");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","selectable-item has-icon");
        dom.setAttribute(el5,"for","filterAll");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","selectable-item has-icon favs");
        dom.setAttribute(el5,"for","filterFavs");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          Favoriten");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          -\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","selectable-item has-icon");
        dom.setAttribute(el5,"for","filterTiny");
        var el6 = dom.createTextNode("Kleintiere");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","selectable-item has-icon");
        dom.setAttribute(el5,"for","filterHorse");
        var el6 = dom.createTextNode("Pferde");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","selectable-item has-icon");
        dom.setAttribute(el5,"for","filterUse");
        var el6 = dom.createTextNode("Nutztiere");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-24/24 l-14/24@desk");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Tags");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table grid table--large");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__body");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0]);
        var element9 = dom.childAt(element8, [1]);
        var element10 = dom.childAt(element8, [3]);
        var element11 = dom.childAt(fragment, [2]);
        var element12 = dom.childAt(element11, [3]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element13, [1]);
        var element15 = dom.childAt(element12, [3]);
        var morphs = new Array(16);
        morphs[0] = dom.createAttrMorph(element8, 'class');
        morphs[1] = dom.createMorphAt(element9,1,1);
        morphs[2] = dom.createMorphAt(element9,3,3);
        morphs[3] = dom.createAttrMorph(element10, 'class');
        morphs[4] = dom.createMorphAt(element10,1,1);
        morphs[5] = dom.createMorphAt(element11,1,1);
        morphs[6] = dom.createMorphAt(element14,3,3);
        morphs[7] = dom.createMorphAt(element14,7,7);
        morphs[8] = dom.createMorphAt(dom.childAt(element14, [9]),0,0);
        morphs[9] = dom.createMorphAt(element14,11,11);
        morphs[10] = dom.createMorphAt(element14,15,15);
        morphs[11] = dom.createMorphAt(element14,19,19);
        morphs[12] = dom.createMorphAt(dom.childAt(element13, [3]),3,3);
        morphs[13] = dom.createMorphAt(element15,1,1);
        morphs[14] = dom.createMorphAt(dom.childAt(element15, [3]),1,1);
        morphs[15] = dom.createMorphAt(dom.childAt(element12, [5]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["context-bar grid ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,50],[1,63]]]],"sidebar",""],[],["loc",[null,[1,29],[1,78]]]]]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[3,28],[3,38]]]]],[],[]],"placeHolder","Leistung suchen","search","applyFilter"],["loc",[null,[3,4],[3,91]]]],
        ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[4,29],[4,40]]]]],[],[]]],["loc",[null,[4,4],[4,42]]]],
        ["attribute","class",["concat",["buttons-box col l-",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[6,53],[6,66]]]],"4","10"],[],["loc",[null,[6,32],[6,77]]]],"/24"]]],
        ["block","link-to",["services.create",["subexpr","query-params",[],["referer","sale"],["loc",[null,[7,33],[7,62]]]]],["class","with-icon button context primary"],0,null,["loc",[null,[7,4],[9,16]]]],
        ["block","if",[["get","showFilters",["loc",[null,[14,8],[14,19]]]]],[],1,null,["loc",[null,[14,2],[30,9]]]],
        ["inline","input",[],["type","checkbox","id","filterAll","name","filterAll","checked",["subexpr","@mut",[["get","filterAll",["loc",[null,[36,72],[36,81]]]]],[],[]]],["loc",[null,[36,8],[36,83]]]],
        ["inline","input",[],["type","checkbox","id","filterFavs","name","filterFavs","checked",["subexpr","@mut",[["get","filterFavs",["loc",[null,[38,74],[38,84]]]]],[],[]]],["loc",[null,[38,8],[38,86]]]],
        ["inline","button-favorite",[],["content","","size",18,"showStroke",false,"color","#999999","classNames",""],["loc",[null,[39,70],[39,155]]]],
        ["inline","input",[],["type","checkbox","id","filterTiny","name","filterTiny","checked",["subexpr","@mut",[["get","filterTiny",["loc",[null,[42,76],[42,86]]]]],[],[]]],["loc",[null,[42,10],[42,88]]]],
        ["inline","input",[],["type","checkbox","id","filterHorse","name","filterHorse","checked",["subexpr","@mut",[["get","filterHorse",["loc",[null,[44,78],[44,89]]]]],[],[]]],["loc",[null,[44,10],[44,91]]]],
        ["inline","input",[],["type","checkbox","id","filterUse","name","filterUse","checked",["subexpr","@mut",[["get","filterUse",["loc",[null,[46,74],[46,83]]]]],[],[]]],["loc",[null,[46,10],[46,85]]]],
        ["block","if",[["get","articleTags",["loc",[null,[51,14],[51,25]]]]],[],2,null,["loc",[null,[51,8],[56,15]]]],
        ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[60,34],[60,58]]]]],[],[]]],3,null,["loc",[null,[60,6],[70,27]]]],
        ["block","each",[["get","model",["loc",[null,[73,16],[73,21]]]]],[],4,null,["loc",[null,[73,8],[124,17]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[128,29],[128,36]]]]],[],[]],"numPagesToShow",7],["loc",[null,[128,6],[128,55]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }()));

});