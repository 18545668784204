define('jason-frontend/helpers/is-permission-selected', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isPermissionSelected = isPermissionSelected;

  function isPermissionSelected(params /*, hash*/) {
    var selectedList = params[0],
        category = params[1],
        returnValue = false;
    $.each(selectedList.arrayList, function (index, value) {
      if (value.id === category.id) {
        returnValue = true;
        return;
      }
    });
    return returnValue;
  }

  exports['default'] = Ember['default'].Helper.helper(isPermissionSelected);

});