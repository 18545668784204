define('jason-frontend/routes/patients/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {
      return this.findPaged('patient', params);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("genders", this.store.find('gender'));
      controller.set('rooms', this.store.find('treatment-room'));
      controller.set('doctors', this.store.find('user', { role: 'doctor' }));
      controller.set('assistants', this.store.find('user', { role: 'assistant' }));
      controller.set('filterEtiga', false);
      controller.set('showEtiga', false);

      controller.set('downloadToken', API['default'].getDownloadToken());
      controller.set('mandantId', API['default'].getMandant());
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy'),
          filterEtiga = controller.get('showEtiga');

      this.findPaged('patient', {
        filterName: filterName,
        sortDir: sortDir,
        sortBy: sortBy,
        filterEtiga: filterEtiga,
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    actions: {
      downloadCsv: function downloadCsv() {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: API['default'].getMandant()
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          $('#patientExportForm input[name=token]').val(data.responseText);
          $('#patientExportForm').submit();
        });
      },
      gotoEtigaMaster: function gotoEtigaMaster(id) {
        this.transitionTo('patients.etiga', id);
      },
      selectCustomerConfirm: function selectCustomerConfirm(customerId, data, appointment, time, directTreatment) {
        var self = this,
            treatment = {
          customer: {
            id: customerId
          }
        };

        treatment.selectedPatients = data;
        treatment.appointment = appointment;
        treatment.time = time;

        $.ajax({
          url: "/api/treatments",
          method: "PUT",
          data: JSON.stringify(treatment),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          if (directTreatment) {
            self.transitionTo('practicemgmt.treatment-details', id);
          } else {
            self.transitionTo('practicemgmt.admission');
          }
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      'delete': function _delete(id) {
        var controller = this.get('controller'),
            self = this;
        this.get('store').find('patient', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.applyFilter();
        }, 1000);
      }
    }
  });

});