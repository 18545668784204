define('jason-frontend/components/treatment-report-chooser', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      edit: function edit(id) {
        this.sendAction('chooseReport', id);
        $.magnificPopup.close();
      },
      'delete': function _delete(id) {
        this.sendAction('delete', id);
      },
      create: function create() {
        this.sendAction('create');
      },
      load: function load(allAvailable) {
        this.set('allAvailable', allAvailable.entries);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});