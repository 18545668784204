define('jason-frontend/components/tooltip-icon', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    side: 'top',
    timer: 0,
    content: 'anzeigen',
    theme: 'tooltipster-punk',
    tagName: 'span',
    classNames: ['jason-tooltip']
  });

});