define('jason-frontend/models/message-reply', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    comment: DS['default'].attr('string'),
    sender: DS['default'].belongsTo('user'),
    created: DS['default'].attr('date')
  });

});