define('jason-frontend/templates/components/button-lock-with-confirm', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 8
            },
            "end": {
              "line": 6,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/button-lock-with-confirm.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"class","circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","14");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[5,66],[5,71]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-lock-with-confirm.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"class","btn-delete");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el2 = dom.createElement("svg");
        dom.setAttribute(el2,"class","button--delete");
        dom.setAttribute(el2,"viewBox","0 0 32 32");
        dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"fill","none");
        dom.setAttribute(el3,"fill-rule","evenodd");
        dom.setAttribute(el3,"transform","translate(1 1)");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        dom.setAttribute(el4,"class","icon");
        dom.setAttribute(el4,"transform","scale(0.04, 0.04) translate(150,130)");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M81.5,488.8h325.8c19.7,0,35.8-16.1,35.8-35.8V258.3c0-19.7-16.1-35.8-35.8-35.8h-25.2v-84.8C382.1,61.8,320.3,0,244.4,0    S106.7,61.8,106.7,137.7v84.8H81.5c-19.7,0-35.8,16.1-35.8,35.8V453C45.7,472.7,61.8,488.8,81.5,488.8z M131.2,137.7    c0-62.4,50.8-113.2,113.2-113.2s113.2,50.8,113.2,113.2v84.8H131.2V137.7z M70.2,258.3c0-6.2,5.1-11.3,11.3-11.3h325.8    c6.2,0,11.3,5.1,11.3,11.3V453c0,6.2-5.1,11.3-11.3,11.3H81.5c-6.2,0-11.3-5.1-11.3-11.3C70.2,453,70.2,258.3,70.2,258.3z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M244.4,405.5c27.5,0,49.9-22.4,49.9-49.9s-22.4-49.9-49.9-49.9s-49.9,22.4-49.9,49.9C194.5,383.2,216.9,405.5,244.4,405.5    z M244.4,330.3c14,0,25.4,11.4,25.4,25.4s-11.4,25.4-25.4,25.4c-14,0-25.4-11.4-25.4-25.4S230.4,330.3,244.4,330.3z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        dom.setNamespace(null);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","delete-confirm medium-confirm");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","footer");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","button");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createTextNode("Nein");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","button");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createTextNode("Ja");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element3, [3]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element4, [3]);
        var element7 = dom.childAt(fragment, [2]);
        var element8 = dom.childAt(element7, [3]);
        var element9 = dom.childAt(element8, [1]);
        var element10 = dom.childAt(element8, [3]);
        var morphs = new Array(12);
        morphs[0] = dom.createAttrMorph(element1, 'data-id');
        morphs[1] = dom.createAttrMorph(element1, 'title');
        morphs[2] = dom.createElementMorph(element1);
        morphs[3] = dom.createAttrMorph(element2, 'width');
        morphs[4] = dom.createAttrMorph(element2, 'height');
        morphs[5] = dom.createMorphAt(element3,1,1);
        morphs[6] = dom.createAttrMorph(element4, 'fill');
        morphs[7] = dom.createAttrMorph(element5, 'fill');
        morphs[8] = dom.createAttrMorph(element6, 'fill');
        morphs[9] = dom.createMorphAt(element7,1,1);
        morphs[10] = dom.createElementMorph(element9);
        morphs[11] = dom.createElementMorph(element10);
        return morphs;
      },
      statements: [
        ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[1,54],[1,61]]]]]]],
        ["attribute","title",["concat",[["get","tooltip",["loc",[null,[1,74],[1,81]]]]]]],
        ["element","action",["confirm"],[],["loc",[null,[1,3],[1,23]]]],
        ["attribute","width",["concat",[["get","size",["loc",[null,[2,41],[2,45]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[2,59],[2,63]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[4,14],[4,24]]]]],[],0,null,["loc",[null,[4,8],[6,15]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[7,32],[7,37]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[8,24],[8,29]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[9,24],[9,29]]]]]]],
        ["content","text",["loc",[null,[15,2],[15,10]]]],
        ["element","action",["cancel"],[],["loc",[null,[17,11],[17,30]]]],
        ["element","action",["delete"],[],["loc",[null,[18,11],[18,30]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});