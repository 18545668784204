define('jason-frontend/templates/components/lab-result-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 42
            },
            "end": {
              "line": 6,
              "column": 202
            }
          },
          "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","animal-icon",[],["evil",["subexpr","@mut",[["get","patient.evil",["loc",[null,[6,85],[6,97]]]]],[],[]],"animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[6,108],[6,127]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[6,136],[6,160]]]]],[],[]],"size","50","content",["subexpr","@mut",[["get","patient.category.name",["loc",[null,[6,179],[6,200]]]]],[],[]]],["loc",[null,[6,66],[6,202]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 29
            },
            "end": {
              "line": 9,
              "column": 66
            }
          },
          "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(",");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["content","patient.race",["loc",[null,[9,49],[9,65]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 74
            },
            "end": {
              "line": 9,
              "column": 113
            }
          },
          "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(",");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["content","patient.color",["loc",[null,[9,95],[9,112]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 121
            },
            "end": {
              "line": 9,
              "column": 178
            }
          },
          "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-age",[["get","patient.birthday",["loc",[null,[9,160],[9,176]]]]],[],["loc",[null,[9,150],[9,178]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 62,
                          "column": 206
                        },
                        "end": {
                          "line": 62,
                          "column": 268
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","redBar");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 62,
                          "column": 268
                        },
                        "end": {
                          "line": 62,
                          "column": 304
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","greenBar");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 62,
                        "column": 157
                      },
                      "end": {
                        "line": 62,
                        "column": 315
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","unless",[["subexpr","eq",[["get","param.refStatus",["loc",[null,[62,220],[62,235]]]],"="],[],["loc",[null,[62,216],[62,240]]]]],[],0,1,["loc",[null,[62,206],[62,315]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 62,
                      "column": 123
                    },
                    "end": {
                      "line": 62,
                      "column": 322
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","and",[["subexpr","gt",[["get","param.value",["loc",[null,[62,172],[62,183]]]],0],[],["loc",[null,[62,168],[62,186]]]],["subexpr","gt",[["get","param.max",["loc",[null,[62,191],[62,200]]]],0],[],["loc",[null,[62,187],[62,203]]]]],[],["loc",[null,[62,163],[62,204]]]]],[],0,null,["loc",[null,[62,157],[62,322]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 63,
                      "column": 83
                    },
                    "end": {
                      "line": 63,
                      "column": 119
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("(");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(")");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","param.id",["loc",[null,[63,106],[63,118]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 64,
                      "column": 60
                    },
                    "end": {
                      "line": 64,
                      "column": 139
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","lab-reference-status",[],["size",26,"value",["subexpr","@mut",[["get","param.refStatus",["loc",[null,[64,122],[64,137]]]]],[],[]]],["loc",[null,[64,85],[64,139]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child3 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 66,
                      "column": 18
                    },
                    "end": {
                      "line": 68,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","param-bar",[],["param",["subexpr","@mut",[["get","param",["loc",[null,[67,38],[67,43]]]]],[],[]],"classNames","paramBarWrapper"],["loc",[null,[67,20],[67,74]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child4 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 73,
                        "column": 18
                      },
                      "end": {
                        "line": 74,
                        "column": 107
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","small block");
                    var el2 = dom.createTextNode("Original: ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                    return morphs;
                  },
                  statements: [
                    ["inline","format-number-german",[["get","param.originalValue",["loc",[null,[74,79],[74,98]]]]],[],["loc",[null,[74,56],[74,100]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 71,
                      "column": 16
                    },
                    "end": {
                      "line": 75,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("input");
                  dom.setAttribute(el1,"type","text");
                  dom.setAttribute(el1,"class","input input--editable input--full-width");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element2 = dom.childAt(fragment, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createAttrMorph(element2, 'id');
                  morphs[1] = dom.createAttrMorph(element2, 'value');
                  morphs[2] = dom.createElementMorph(element2);
                  morphs[3] = dom.createMorphAt(fragment,3,3,contextualElement);
                  return morphs;
                },
                statements: [
                  ["attribute","id",["concat",["param_",["get","param.id",["loc",[null,[72,49],[72,57]]]]]]],
                  ["attribute","value",["concat",[["get","param.value",["loc",[null,[72,118],[72,129]]]]]]],
                  ["element","action",["changeParamValue",["get","item.formId",["loc",[null,[72,161],[72,172]]]],["get","param",["loc",[null,[72,173],[72,178]]]]],["on","focusOut"],["loc",[null,[72,133],[72,194]]]],
                  ["block","if",[["subexpr","not",[["subexpr","eq",[["get","param.originalValue",["loc",[null,[73,33],[73,52]]]],["get","param.value",["loc",[null,[73,53],[73,64]]]]],[],["loc",[null,[73,29],[73,65]]]]],[],["loc",[null,[73,24],[73,66]]]]],[],0,null,["loc",[null,[73,18],[74,114]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child5 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 75,
                        "column": 16
                      },
                      "end": {
                        "line": 77,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","param.valueText",["loc",[null,[76,24],[76,43]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 75,
                      "column": 16
                    },
                    "end": {
                      "line": 77,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","param.type",["loc",[null,[75,30],[75,40]]]],"text"],[],["loc",[null,[75,26],[75,48]]]]],[],0,null,["loc",[null,[75,16],[77,16]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child6 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 80,
                      "column": 97
                    },
                    "end": {
                      "line": 80,
                      "column": 155
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","format-number-german",[["get","param.min",["loc",[null,[80,144],[80,153]]]]],[],["loc",[null,[80,121],[80,155]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child7 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 81,
                      "column": 96
                    },
                    "end": {
                      "line": 81,
                      "column": 154
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","format-number-german",[["get","param.max",["loc",[null,[81,143],[81,152]]]]],[],["loc",[null,[81,120],[81,154]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 60,
                    "column": 14
                  },
                  "end": {
                    "line": 88,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row ");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell   u-hide@phone u-hide@tablet l-2/24 l-1/24@desk pt10 pl0 pb0");
                dom.setAttribute(el2,"style","height: 80px");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell  l-24/24 l-4/24@desk");
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-2/24 l-2/24@desk");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-10/24 l-5/24@desk");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell text-center table__cell--lab-input l-5/24 l-3/24@desk");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-5/24 l-2/24@desk");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell  u-hide@phone u-hide@tablet table__cell--center l-3/24 ");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell  u-hide@phone u-hide@tablet table__cell--center l-3/24");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--center l-1/24 table__cell--action buttons-1");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","cursor icon-button icon-button--default list-action-square");
                var el4 = dom.createTextNode("\n                    ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                var el5 = dom.createTextNode("Verlauf");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var element4 = dom.childAt(element3, [3, 0]);
                var element5 = dom.childAt(element3, [17, 1]);
                var morphs = new Array(11);
                morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
                morphs[1] = dom.createMorphAt(element4,0,0);
                morphs[2] = dom.createMorphAt(element4,2,2);
                morphs[3] = dom.createMorphAt(dom.childAt(element3, [5]),0,0);
                morphs[4] = dom.createMorphAt(dom.childAt(element3, [7]),1,1);
                morphs[5] = dom.createMorphAt(dom.childAt(element3, [9]),1,1);
                morphs[6] = dom.createMorphAt(dom.childAt(element3, [11]),0,0);
                morphs[7] = dom.createMorphAt(dom.childAt(element3, [13]),0,0);
                morphs[8] = dom.createMorphAt(dom.childAt(element3, [15]),0,0);
                morphs[9] = dom.createElementMorph(element5);
                morphs[10] = dom.createMorphAt(element5,1,1);
                return morphs;
              },
              statements: [
                ["block","unless",[["subexpr","eq",[["get","param.type",["loc",[null,[62,137],[62,147]]]],"text"],[],["loc",[null,[62,133],[62,155]]]]],[],0,null,["loc",[null,[62,123],[62,333]]]],
                ["content","param.name",["loc",[null,[63,68],[63,82]]]],
                ["block","unless",[["get","param.name",["loc",[null,[63,93],[63,103]]]]],[],1,null,["loc",[null,[63,83],[63,130]]]],
                ["block","if",[["subexpr","gt",[["get","param.max",["loc",[null,[64,70],[64,79]]]],0],[],["loc",[null,[64,66],[64,83]]]]],[],2,null,["loc",[null,[64,60],[64,146]]]],
                ["block","unless",[["subexpr","eq",[["get","param.type",["loc",[null,[66,32],[66,42]]]],"text"],[],["loc",[null,[66,28],[66,50]]]]],[],3,null,["loc",[null,[66,18],[68,29]]]],
                ["block","if",[["get","param.value",["loc",[null,[71,22],[71,33]]]]],[],4,5,["loc",[null,[71,16],[77,23]]]],
                ["content","param.unit",["loc",[null,[79,60],[79,74]]]],
                ["block","if",[["subexpr","gt",[["get","param.max",["loc",[null,[80,107],[80,116]]]],0],[],["loc",[null,[80,103],[80,119]]]]],[],6,null,["loc",[null,[80,97],[80,162]]]],
                ["block","if",[["subexpr","gt",[["get","param.max",["loc",[null,[81,106],[81,115]]]],0],[],["loc",[null,[81,102],[81,118]]]]],[],7,null,["loc",[null,[81,96],[81,161]]]],
                ["element","action",["openChart",["get","patient.id",["loc",[null,[83,42],[83,52]]]],["get","param.id",["loc",[null,[83,53],[83,61]]]],["get","item.formId",["loc",[null,[83,62],[83,73]]]]],[],["loc",[null,[83,21],[83,75]]]],
                ["inline","button-chart2",[],["showStroke",false,"color","#fff","content","","size",36],["loc",[null,[84,20],[84,86]]]]
              ],
              locals: [],
              templates: [child0, child1, child2, child3, child4, child5, child6, child7]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 90,
                      "column": 72
                    },
                    "end": {
                      "line": 90,
                      "column": 108
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("(");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(")");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","param.id",["loc",[null,[90,95],[90,107]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 88,
                    "column": 14
                  },
                  "end": {
                    "line": 95,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row ");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell  l-6/24");
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--lab-input l-18/24");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var element1 = dom.childAt(element0, [1, 0]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(element1,0,0);
                morphs[1] = dom.createMorphAt(element1,2,2);
                morphs[2] = dom.createUnsafeMorphAt(dom.childAt(element0, [3, 1]),0,0);
                return morphs;
              },
              statements: [
                ["content","param.name",["loc",[null,[90,57],[90,71]]]],
                ["block","unless",[["get","param.name",["loc",[null,[90,82],[90,92]]]]],[],0,null,["loc",[null,[90,72],[90,119]]]],
                ["content","param.valueText",["loc",[null,[92,28],[92,49]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 12
                },
                "end": {
                  "line": 96,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["subexpr","eq",[["get","param.type",["loc",[null,[60,28],[60,38]]]],"text"],[],["loc",[null,[60,24],[60,46]]]]],[],0,1,["loc",[null,[60,14],[95,25]]]]
            ],
            locals: ["param"],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 6
              },
              "end": {
                "line": 99,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table grid table--large");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__head");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-1/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-4/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Test/Parameter");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-2/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-5/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell  table__cell--center  l-3/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Ergebnis");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-2/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell table__cell--center  l-3/24@desk");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Ref. Min");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell table__cell--center  l-3/24@desk");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Ref. Max");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell table__cell--center  l-1/24@desk");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__body");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","paramGroup.params",["loc",[null,[59,20],[59,37]]]]],[],0,null,["loc",[null,[59,12],[96,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 6
            },
            "end": {
              "line": 100,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","toggle-container");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","header-toggle");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","switch");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1, 1]);
          var element7 = dom.childAt(element6, [3]);
          var element8 = dom.childAt(element7, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
          morphs[1] = dom.createMorphAt(element7,1,1);
          morphs[2] = dom.createAttrMorph(element8, 'for');
          morphs[3] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["content","paramGroup.name",["loc",[null,[37,16],[37,35]]]],
          ["inline","input",[],["type","checkbox","id",["subexpr","@mut",[["get","paramGroup.code",["loc",[null,[39,41],[39,56]]]]],[],[]],"classNames","switch","name",["subexpr","@mut",[["get","paramGroup.code",["loc",[null,[39,82],[39,97]]]]],[],[]],"checked",["subexpr","@mut",[["get","paramGroup.expanded",["loc",[null,[39,106],[39,125]]]]],[],[]]],["loc",[null,[39,14],[39,127]]]],
          ["attribute","for",["concat",[["get","paramGroup.code",["loc",[null,[40,28],[40,43]]]]]]],
          ["block","if",[["get","paramGroup.expanded",["loc",[null,[45,12],[45,31]]]]],[],0,null,["loc",[null,[45,6],[99,13]]]]
        ],
        locals: ["paramGroup"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 122,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/lab-result-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-lab-result-panel");
        dom.setAttribute(el1,"class","popup-basic popup-very-large admin-form mfp-with-anim mfp-hide small--heading");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("Ergebnis ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-body p25");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-24/24 l-16/24@desk");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h1");
        dom.setAttribute(el5,"class","u-mb0 heading--inline");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","is-highlighted cursor");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","mt20 mb20");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("br");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          BesitzerIn: ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","is-highlighted cursor");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Laborgerät");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col mt10 l-12/24");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Profil");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col mt10 l-12/24");
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n    ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","mt10 col l-12/24");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Erstellt");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","mt10 col l-12/24");
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","cursor clickable");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","text--editable");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","mt20 field mb0");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","comment");
        dom.setAttribute(el5,"class","field-label title");
        var el6 = dom.createTextNode("Anmerkung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","bar");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"type","submit");
        dom.setAttribute(el4,"class","icon-button");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"type","submit");
        dom.setAttribute(el4,"class","icon-button icon-button--success");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0]);
        var element10 = dom.childAt(element9, [1]);
        var element11 = dom.childAt(element10, [3]);
        var element12 = dom.childAt(element11, [1]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element13, [2]);
        var element15 = dom.childAt(element13, [3]);
        var element16 = dom.childAt(element12, [3]);
        var element17 = dom.childAt(element16, [7]);
        var element18 = dom.childAt(element11, [3]);
        var element19 = dom.childAt(element18, [3, 1]);
        var element20 = dom.childAt(element18, [11, 1]);
        var element21 = dom.childAt(element9, [3]);
        var element22 = dom.childAt(element21, [1]);
        var element23 = dom.childAt(element21, [3]);
        var morphs = new Array(23);
        morphs[0] = dom.createMorphAt(dom.childAt(element10, [1, 0]),1,1);
        morphs[1] = dom.createMorphAt(element13,0,0);
        morphs[2] = dom.createElementMorph(element14);
        morphs[3] = dom.createMorphAt(element14,0,0);
        morphs[4] = dom.createElementMorph(element15);
        morphs[5] = dom.createMorphAt(element15,1,1);
        morphs[6] = dom.createMorphAt(element16,0,0);
        morphs[7] = dom.createMorphAt(element16,2,2);
        morphs[8] = dom.createMorphAt(element16,4,4);
        morphs[9] = dom.createElementMorph(element17);
        morphs[10] = dom.createMorphAt(element17,0,0);
        morphs[11] = dom.createAttrMorph(element18, 'class');
        morphs[12] = dom.createMorphAt(element19,0,0);
        morphs[13] = dom.createMorphAt(element19,2,2);
        morphs[14] = dom.createMorphAt(dom.childAt(element18, [7, 1]),0,0);
        morphs[15] = dom.createElementMorph(element20);
        morphs[16] = dom.createMorphAt(dom.childAt(element20, [0]),0,0);
        morphs[17] = dom.createMorphAt(element11,5,5);
        morphs[18] = dom.createMorphAt(dom.childAt(element11, [7]),3,3);
        morphs[19] = dom.createElementMorph(element22);
        morphs[20] = dom.createMorphAt(dom.childAt(element22, [1]),1,1);
        morphs[21] = dom.createElementMorph(element23);
        morphs[22] = dom.createMorphAt(dom.childAt(element23, [1]),1,1);
        return morphs;
      },
      statements: [
        ["inline","value-bool-evaluator",[["subexpr","eq",[["get","item.type",["loc",[null,[3,93],[3,102]]]],"aniveri"],[],["loc",[null,[3,89],[3,113]]]],"Laboranalyse","Labor"],[],["loc",[null,[3,66],[3,138]]]],
        ["block","if",[["get","patient.category",["loc",[null,[6,48],[6,64]]]]],[],0,null,["loc",[null,[6,42],[6,209]]]],
        ["element","action",["gotoPatient",["get","patient.id",["loc",[null,[6,267],[6,277]]]]],[],["loc",[null,[6,244],[6,279]]]],
        ["inline","button-text",[],["text",["subexpr","@mut",[["get","patient.name",["loc",[null,[6,299],[6,311]]]]],[],[]],"content",["subexpr","@mut",[["get","patient.id",["loc",[null,[6,320],[6,330]]]]],[],[]]],["loc",[null,[6,280],[6,332]]]],
        ["element","action",["selectBarcode",["get","model.id",["loc",[null,[6,373],[6,381]]]]],[],["loc",[null,[6,348],[6,383]]]],
        ["inline","button-barcode",[],["color","#4A4A4A","showStroke",true,"size",36,"classNames","mt9"],["loc",[null,[7,10],[7,86]]]],
        ["block","if",[["get","patient.race",["loc",[null,[9,35],[9,47]]]]],[],1,null,["loc",[null,[9,29],[9,73]]]],
        ["block","if",[["get","patient.color",["loc",[null,[9,80],[9,93]]]]],[],2,null,["loc",[null,[9,74],[9,120]]]],
        ["block","if",[["get","patient.birthday",["loc",[null,[9,127],[9,143]]]]],[],3,null,["loc",[null,[9,121],[9,185]]]],
        ["element","action",["gotoCustomer",["get","customer.id",["loc",[null,[10,79],[10,90]]]]],[],["loc",[null,[10,55],[10,92]]]],
        ["inline","button-text",[],["content",["subexpr","concat",["Telefon: ",["get","customer.phone",["loc",[null,[10,135],[10,149]]]]],[],["loc",[null,[10,115],[10,150]]]],"text",["subexpr","concat",[["get","customer.firstname",["loc",[null,[10,164],[10,182]]]]," ",["get","customer.lastname",["loc",[null,[10,187],[10,204]]]]],[],["loc",[null,[10,156],[10,205]]]]],["loc",[null,[10,93],[10,207]]]],
        ["attribute","class",["concat",["col  l-24/24 l-8/24@desk ",["subexpr","value-bool-evaluator",[["subexpr","eq",[["get","item.type",["loc",[null,[13,73],[13,82]]]],"aniveri"],[],["loc",[null,[13,69],[13,93]]]],"hidden",""],[],["loc",[null,[13,46],[13,107]]]]]]],
        ["content","item.labEquipment.manu",["loc",[null,[18,14],[18,40]]]],
        ["content","item.labEquipment.name",["loc",[null,[18,41],[18,67]]]],
        ["content","item.labProfile.name",["loc",[null,[24,12],[24,36]]]],
        ["element","action",["changeDate",["get","item",["loc",[null,[30,56],[30,60]]]]],[],["loc",[null,[30,34],[30,62]]]],
        ["inline","time-format",[["get","item.created",["loc",[null,[30,106],[30,118]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[30,92],[30,141]]]],
        ["block","each",[["get","item.paramGroups",["loc",[null,[33,14],[33,30]]]]],[],4,null,["loc",[null,[33,6],[100,15]]]],
        ["inline","textarea",[],["name","comment","value",["subexpr","@mut",[["get","item.comment",["loc",[null,[104,40],[104,52]]]]],[],[]],"class","newStyle gui-textarea"],["loc",[null,[104,8],[104,84]]]],
        ["element","action",["downloadResult",["get","item.formId",["loc",[null,[110,33],[110,44]]]]],[],["loc",[null,[110,7],[110,46]]]],
        ["inline","button-download-list",[],["size","52","showStroke",false,"content","Laborergebnis herunterladen","color","#ffffff"],["loc",[null,[112,8],[112,113]]]],
        ["element","action",["save",["get","item.formId",["loc",[null,[115,23],[115,34]]]]],[],["loc",[null,[115,7],[115,36]]]],
        ["inline","button-save",[],["size","52","color","#ffffff","content","Speichern"],["loc",[null,[117,8],[117,69]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }()));

});