define('jason-frontend/components/merge-patient-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    patient: null,
    applyFilter: function applyFilter(searchString) {
      var self = this;

      $.ajax({
        url: "/api/customers/" + this.patient.get('customer').get('id'),
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('model', data.customer);
      });
    },
    actions: {
      load: function load(patient) {
        this.set('patient', patient);
        this.applyFilter();
      },
      selectPatient: function selectPatient(selectedPatient) {
        var self = this;

        if (selectedPatient.id === this.patient.id) {
          jason.notifiction.errorLong('Fehler', 'Ziel und Quelle dürfen nicht übereinstimmen');
          return;
        }

        $.ajax({
          url: '/api/patients/' + this.patient.id + '/merge/' + selectedPatient.id,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.sendAction('gotoPatient', data);
          jason.notifiction.notifiy('Übertragung', 'Tiere wurden erfolgreich zusammengefügt');
          $.magnificPopup.close();
        });
      },
      select: function select(id) {
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }

    }
  });

});