define('jason-frontend/templates/templates/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 5,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[4,30],[4,40]]]]],[],[]],"placeHolder",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[4,77],[4,86]]]],"templates"],[],["loc",[null,[4,73],[4,99]]]],"Vorlage suchen",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[4,141],[4,150]]]],"connectedArticles"],[],["loc",[null,[4,137],[4,171]]]],"Erinnerung suchen",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[4,216],[4,225]]]],"textmodules"],[],["loc",[null,[4,212],[4,240]]]],"Textbaustein suchen",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[4,287],[4,296]]]],"reports"],[],["loc",[null,[4,283],[4,307]]]],"Bericht suchen",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[4,349],[4,358]]]],"invoices"],[],["loc",[null,[4,345],[4,370]]]],"Rechnungsvorlage suchen",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[4,421],[4,430]]]],"emails"],[],["loc",[null,[4,417],[4,440]]]],"Email-Vorlage suchen",""],[],["loc",[null,[4,397],[4,467]]]]],[],["loc",[null,[4,325],[4,468]]]]],[],["loc",[null,[4,263],[4,469]]]]],[],["loc",[null,[4,192],[4,470]]]]],[],["loc",[null,[4,117],[4,471]]]]],[],["loc",[null,[4,53],[4,472]]]],"search","applyFilter"],["loc",[null,[4,6],[4,495]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 2
            },
            "end": {
              "line": 10,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Test-Email verschicken");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element47 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element47);
          morphs[1] = dom.createMorphAt(element47,0,0);
          return morphs;
        },
        statements: [
          ["element","action",["openTestmailPanel","warning"],[],["loc",[null,[9,7],[9,47]]]],
          ["inline","button-send-mail",[],["size","28","classNames","","content","","color","#fff","showStroke",false],["loc",[null,[9,81],[9,166]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 2
            },
            "end": {
              "line": 13,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Test-Email verschicken");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element46 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element46);
          morphs[1] = dom.createMorphAt(element46,0,0);
          return morphs;
        },
        statements: [
          ["element","action",["openTestmailPanel","reminder"],[],["loc",[null,[12,7],[12,48]]]],
          ["inline","button-send-mail",[],["size","28","classNames","","content","","color","#fff","showStroke",false],["loc",[null,[12,82],[12,167]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 2
            },
            "end": {
              "line": 16,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context primary");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neue Textvorlage");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element45 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element45);
          return morphs;
        },
        statements: [
          ["element","action",["createTemplate"],[],["loc",[null,[15,7],[15,34]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 2
            },
            "end": {
              "line": 19,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context primary");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neuer Textbaustein");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element44 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element44);
          return morphs;
        },
        statements: [
          ["element","action",["createTextmodule"],[],["loc",[null,[18,7],[18,36]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 2
            },
            "end": {
              "line": 22,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context primary");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neue Email-Vorlage");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element43 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element43);
          return morphs;
        },
        statements: [
          ["element","action",["createTextmoduleForMail"],[],["loc",[null,[21,7],[21,43]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 2
            },
            "end": {
              "line": 25,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context primary");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neuer Bericht");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element42 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element42);
          return morphs;
        },
        statements: [
          ["element","action",["createTextmoduleForReport"],[],["loc",[null,[24,7],[24,45]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 2
            },
            "end": {
              "line": 28,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context primary");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neue Rechnungsvorlage");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element41 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element41);
          return morphs;
        },
        statements: [
          ["element","action",["createTextmoduleForInvoice"],[],["loc",[null,[27,7],[27,46]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 8
            },
            "end": {
              "line": 52,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-textmodules");
          var el3 = dom.createTextNode("Textbausteine");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-reports");
          var el3 = dom.createTextNode("Berichte");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-reminder");
          var el3 = dom.createTextNode("Erinnerungen allgemein");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-connectedArticles");
          var el3 = dom.createTextNode("Erinnerungen speziell");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element37 = dom.childAt(fragment, [1, 1]);
          var element38 = dom.childAt(fragment, [3, 1]);
          var element39 = dom.childAt(fragment, [5, 1]);
          var element40 = dom.childAt(fragment, [7, 1]);
          var morphs = new Array(8);
          morphs[0] = dom.createAttrMorph(element37, 'class');
          morphs[1] = dom.createElementMorph(element37);
          morphs[2] = dom.createAttrMorph(element38, 'class');
          morphs[3] = dom.createElementMorph(element38);
          morphs[4] = dom.createAttrMorph(element39, 'class');
          morphs[5] = dom.createElementMorph(element39);
          morphs[6] = dom.createAttrMorph(element40, 'class');
          morphs[7] = dom.createElementMorph(element40);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[41,78],[41,87]]]],"textmodules"],[],["loc",[null,[41,74],[41,102]]]],"is-active",""],[],["loc",[null,[41,53],[41,119]]]]]]],
          ["element","action",["selectTab","textmodules"],[],["loc",[null,[41,145],[41,181]]]],
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[44,78],[44,87]]]],"reports"],[],["loc",[null,[44,74],[44,98]]]],"is-active",""],[],["loc",[null,[44,53],[44,115]]]]]]],
          ["element","action",["selectTab","reports"],[],["loc",[null,[44,137],[44,169]]]],
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[47,80],[47,89]]]],"reminder"],[],["loc",[null,[47,76],[47,101]]]],"is-active",""],[],["loc",[null,[47,55],[47,118]]]]]]],
          ["element","action",["selectTab","reminder"],[],["loc",[null,[47,141],[47,174]]]],
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[50,80],[50,89]]]],"connectedArticles"],[],["loc",[null,[50,76],[50,110]]]],"is-active",""],[],["loc",[null,[50,55],[50,127]]]]]]],
          ["element","action",["selectTab","connectedArticles"],[],["loc",[null,[50,159],[50,201]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 10
              },
              "end": {
                "line": 58,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/templates/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"href","#tab-invoices");
            var el3 = dom.createTextNode("Rechnungen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element34 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element34, 'class');
            morphs[1] = dom.createElementMorph(element34);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[56,80],[56,89]]]],"invoices"],[],["loc",[null,[56,76],[56,101]]]],"is-active",""],[],["loc",[null,[56,55],[56,118]]]]]]],
            ["element","action",["selectTab","invoices"],[],["loc",[null,[56,141],[56,174]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 8
            },
            "end": {
              "line": 65,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-emails");
          var el3 = dom.createTextNode("Emails");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-warning");
          var el3 = dom.createTextNode("Mahnungen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element35 = dom.childAt(fragment, [2, 1]);
          var element36 = dom.childAt(fragment, [4, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createAttrMorph(element35, 'class');
          morphs[2] = dom.createElementMorph(element35);
          morphs[3] = dom.createAttrMorph(element36, 'class');
          morphs[4] = dom.createElementMorph(element36);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[54,16],[54,24]]]]],[],0,null,["loc",[null,[54,10],[58,17]]]],
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[60,80],[60,89]]]],"emails"],[],["loc",[null,[60,76],[60,99]]]],"is-active",""],[],["loc",[null,[60,55],[60,116]]]]]]],
          ["element","action",["selectTab","emails"],[],["loc",[null,[60,137],[60,168]]]],
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[63,80],[63,89]]]],"warning"],[],["loc",[null,[63,76],[63,100]]]],"is-active",""],[],["loc",[null,[63,55],[63,117]]]]]]],
          ["element","action",["selectTab","warning"],[],["loc",[null,[63,139],[63,171]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 66,
              "column": 8
            },
            "end": {
              "line": 73,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-app");
          var el3 = dom.createTextNode("App");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-settings");
          var el3 = dom.createTextNode("Einstellungen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element32 = dom.childAt(fragment, [1, 1]);
          var element33 = dom.childAt(fragment, [3, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element32, 'class');
          morphs[1] = dom.createElementMorph(element32);
          morphs[2] = dom.createAttrMorph(element33, 'class');
          morphs[3] = dom.createElementMorph(element33);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[68,80],[68,89]]]],"app"],[],["loc",[null,[68,76],[68,96]]]],"is-active",""],[],["loc",[null,[68,55],[68,113]]]]]]],
          ["element","action",["selectTab","app"],[],["loc",[null,[68,131],[68,159]]]],
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[71,80],[71,89]]]],"settings"],[],["loc",[null,[71,76],[71,101]]]],"is-active",""],[],["loc",[null,[71,55],[71,118]]]]]]],
          ["element","action",["selectTab","settings"],[],["loc",[null,[71,141],[71,174]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 136,
              "column": 0
            },
            "end": {
              "line": 160,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"data-for","#tab-emails");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2,"class","mt0 mb20");
          var el3 = dom.createTextNode("Weiterleitung per Email");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 gutter");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","mandant-emaildata-invoiceSubject");
          dom.setAttribute(el4,"class","field-label title");
          var el5 = dom.createTextNode("Email-Betreff");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 gutter");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","mandant-emaildata-invoiceHeadline");
          dom.setAttribute(el4,"class","field-label title");
          var el5 = dom.createTextNode("Überschrift");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 gutter");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","mandant-emaildata-invoiceClosing");
          dom.setAttribute(el4,"class","field-label title");
          var el5 = dom.createTextNode("Abschlusssatz");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element31 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element31, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element31, [3, 1]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(element31, [6, 1]),3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(element31, [8, 1]),3,3);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[137,58],[137,67]]]],"emails"],[],["loc",[null,[137,54],[137,77]]]],"is-active",""],[],["loc",[null,[137,33],[137,94]]]]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.invoiceSubject",["loc",[null,[143,26],[143,50]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-emaildata-invoiceSubject"],["loc",[null,[143,12],[143,143]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.invoiceHeadline",["loc",[null,[150,26],[150,51]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-emaildata-invoiceHeadline"],["loc",[null,[150,12],[150,145]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.invoiceClosing",["loc",[null,[156,26],[156,50]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-emaildata-invoiceClosing"],["loc",[null,[156,12],[156,143]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 297,
                  "column": 24
                },
                "end": {
                  "line": 299,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","is-highlighted cursor");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element28 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element28);
              morphs[1] = dom.createMorphAt(element28,0,0);
              return morphs;
            },
            statements: [
              ["element","action",["openTextModule",["get","module.id",["loc",[null,[298,85],[298,94]]]],"def"],[],["loc",[null,[298,59],[298,102]]]],
              ["content","module.title",["loc",[null,[298,103],[298,119]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 299,
                  "column": 24
                },
                "end": {
                  "line": 301,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","module.title",["loc",[null,[300,26],[300,42]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 306,
                  "column": 22
                },
                "end": {
                  "line": 314,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--delete list-action-square");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Bearbeiten");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element26 = dom.childAt(fragment, [1]);
              var element27 = dom.childAt(fragment, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(element26,1,1);
              morphs[1] = dom.createMorphAt(dom.childAt(element26, [3]),0,0);
              morphs[2] = dom.createElementMorph(element27);
              morphs[3] = dom.createMorphAt(element27,1,1);
              return morphs;
            },
            statements: [
              ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"size","36","item",["subexpr","@mut",[["get","module",["loc",[null,[308,100],[308,106]]]]],[],[]],"confirmed","deleteTextModule","content","","text","Wollen Sie diesen Textbaustein wirklich löschen?"],["loc",[null,[308,26],[308,204]]]],
              ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","module",["loc",[null,[309,57],[309,63]]]]],[],[]],"confirmed","deleteTextModule","textToShow","Löschen","text","Wollen Sie diesen Textbaustein wirklich löschen?"],["loc",[null,[309,32],[309,171]]]],
              ["element","action",["openTextModule",["get","module.id",["loc",[null,[311,117],[311,126]]]],"def"],[],["loc",[null,[311,91],[311,134]]]],
              ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[312,26],[312,92]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 290,
                "column": 16
              },
              "end": {
                "line": 317,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/templates/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--icon l-4/24@phone l-1/24");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/textmodule-dark.svg");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--name l-24/24@phone l-19/24");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","sub");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk buttons-2");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element29 = dom.childAt(fragment, [1]);
            var element30 = dom.childAt(element29, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element30, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element30, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element29, [5]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","module.editable",["loc",[null,[297,30],[297,45]]]]],[],0,1,["loc",[null,[297,24],[301,31]]]],
            ["content","module.desc",["loc",[null,[303,40],[303,55]]]],
            ["block","if",[["get","module.editable",["loc",[null,[306,28],[306,43]]]]],[],2,null,["loc",[null,[306,22],[314,29]]]]
          ],
          locals: ["module"],
          templates: [child0, child1, child2]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 289,
              "column": 14
            },
            "end": {
              "line": 318,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","textModules",["loc",[null,[290,24],[290,35]]]]],[],0,null,["loc",[null,[290,16],[317,25]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 341,
                  "column": 24
                },
                "end": {
                  "line": 343,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","is-highlighted cursor");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element23 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element23);
              morphs[1] = dom.createMorphAt(element23,0,0);
              return morphs;
            },
            statements: [
              ["element","action",["openTextModule",["get","module.id",["loc",[null,[342,85],[342,94]]]],"report"],[],["loc",[null,[342,59],[342,105]]]],
              ["content","module.title",["loc",[null,[342,106],[342,122]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 343,
                  "column": 24
                },
                "end": {
                  "line": 345,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","module.title",["loc",[null,[344,26],[344,42]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 350,
                  "column": 22
                },
                "end": {
                  "line": 358,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--delete list-action-square");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Bearbeiten");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element21 = dom.childAt(fragment, [1]);
              var element22 = dom.childAt(fragment, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(element21,1,1);
              morphs[1] = dom.createMorphAt(dom.childAt(element21, [3]),0,0);
              morphs[2] = dom.createElementMorph(element22);
              morphs[3] = dom.createMorphAt(element22,1,1);
              return morphs;
            },
            statements: [
              ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"size","36","item",["subexpr","@mut",[["get","module",["loc",[null,[352,100],[352,106]]]]],[],[]],"confirmed","deleteTextModule","content","","text","Wollen Sie diesen Textbaustein wirklich löschen?"],["loc",[null,[352,26],[352,204]]]],
              ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","module",["loc",[null,[353,57],[353,63]]]]],[],[]],"confirmed","deleteTextModule","textToShow","Löschen","text","Wollen Sie diesen Textbaustein wirklich löschen?"],["loc",[null,[353,32],[353,171]]]],
              ["element","action",["openTextModule",["get","module.id",["loc",[null,[355,117],[355,126]]]],"report"],[],["loc",[null,[355,91],[355,137]]]],
              ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[356,26],[356,92]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 334,
                "column": 16
              },
              "end": {
                "line": 361,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/templates/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--icon l-4/24@phone l-1/24");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/textmodule-dark.svg");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--name l-24/24@phone l-19/24");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","sub");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk buttons-2");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element24 = dom.childAt(fragment, [1]);
            var element25 = dom.childAt(element24, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element25, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element25, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element24, [5]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","module.editable",["loc",[null,[341,30],[341,45]]]]],[],0,1,["loc",[null,[341,24],[345,31]]]],
            ["content","module.desc",["loc",[null,[347,40],[347,55]]]],
            ["block","if",[["get","module.editable",["loc",[null,[350,28],[350,43]]]]],[],2,null,["loc",[null,[350,22],[358,29]]]]
          ],
          locals: ["module"],
          templates: [child0, child1, child2]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 333,
              "column": 14
            },
            "end": {
              "line": 362,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","textModules",["loc",[null,[334,24],[334,35]]]]],[],0,null,["loc",[null,[334,16],[361,25]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child14 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 386,
                  "column": 24
                },
                "end": {
                  "line": 388,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","is-highlighted cursor");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element18 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element18);
              morphs[1] = dom.createMorphAt(element18,0,0);
              return morphs;
            },
            statements: [
              ["element","action",["openTextModule",["get","module.id",["loc",[null,[387,85],[387,94]]]],"email"],[],["loc",[null,[387,59],[387,104]]]],
              ["content","module.title",["loc",[null,[387,105],[387,121]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 388,
                  "column": 24
                },
                "end": {
                  "line": 390,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","module.title",["loc",[null,[389,26],[389,42]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 395,
                  "column": 22
                },
                "end": {
                  "line": 403,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--delete list-action-square");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Bearbeiten");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element16 = dom.childAt(fragment, [1]);
              var element17 = dom.childAt(fragment, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(element16,1,1);
              morphs[1] = dom.createMorphAt(dom.childAt(element16, [3]),0,0);
              morphs[2] = dom.createElementMorph(element17);
              morphs[3] = dom.createMorphAt(element17,1,1);
              return morphs;
            },
            statements: [
              ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"size","36","item",["subexpr","@mut",[["get","module",["loc",[null,[397,100],[397,106]]]]],[],[]],"confirmed","deleteTextModuleForEmail","content","","text","Wollen Sie diese Email-Vorlage wirklich löschen?"],["loc",[null,[397,26],[397,212]]]],
              ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","module",["loc",[null,[398,57],[398,63]]]]],[],[]],"confirmed","deleteTextModuleForEmail","textToShow","Löschen","text","Wollen Sie diese Email-Vorlage wirklich löschen?"],["loc",[null,[398,32],[398,179]]]],
              ["element","action",["openTextModule",["get","module.id",["loc",[null,[400,117],[400,126]]]],"email"],[],["loc",[null,[400,91],[400,136]]]],
              ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[401,26],[401,92]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 379,
                "column": 16
              },
              "end": {
                "line": 406,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/templates/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--icon l-4/24@phone l-1/24");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/textmodule-dark.svg");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--name l-24/24@phone l-19/24");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","sub");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk buttons-2");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element19 = dom.childAt(fragment, [1]);
            var element20 = dom.childAt(element19, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element20, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element20, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element19, [5]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","module.editable",["loc",[null,[386,30],[386,45]]]]],[],0,1,["loc",[null,[386,24],[390,31]]]],
            ["content","module.desc",["loc",[null,[392,40],[392,55]]]],
            ["block","if",[["get","module.editable",["loc",[null,[395,28],[395,43]]]]],[],2,null,["loc",[null,[395,22],[403,29]]]]
          ],
          locals: ["module"],
          templates: [child0, child1, child2]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 378,
              "column": 14
            },
            "end": {
              "line": 407,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","textModules",["loc",[null,[379,24],[379,35]]]]],[],0,null,["loc",[null,[379,16],[406,25]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child15 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 430,
                  "column": 24
                },
                "end": {
                  "line": 432,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","is-highlighted cursor");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element13 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element13);
              morphs[1] = dom.createMorphAt(element13,0,0);
              return morphs;
            },
            statements: [
              ["element","action",["openTextModule",["get","module.id",["loc",[null,[431,85],[431,94]]]],"invoice"],[],["loc",[null,[431,59],[431,106]]]],
              ["content","module.title",["loc",[null,[431,107],[431,123]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 432,
                  "column": 24
                },
                "end": {
                  "line": 434,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","module.title",["loc",[null,[433,26],[433,42]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 439,
                  "column": 22
                },
                "end": {
                  "line": 447,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--delete list-action-square");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Bearbeiten");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [1]);
              var element12 = dom.childAt(fragment, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(element11,1,1);
              morphs[1] = dom.createMorphAt(dom.childAt(element11, [3]),0,0);
              morphs[2] = dom.createElementMorph(element12);
              morphs[3] = dom.createMorphAt(element12,1,1);
              return morphs;
            },
            statements: [
              ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"size","36","item",["subexpr","@mut",[["get","module",["loc",[null,[441,100],[441,106]]]]],[],[]],"confirmed","deleteTextModule","content","","text","Wollen Sie diesen Textbaustein wirklich löschen?"],["loc",[null,[441,26],[441,204]]]],
              ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","module",["loc",[null,[442,57],[442,63]]]]],[],[]],"confirmed","deleteTextModule","textToShow","Löschen","text","Wollen Sie diesen Textbaustein wirklich löschen?"],["loc",[null,[442,32],[442,171]]]],
              ["element","action",["openTextModule",["get","module.id",["loc",[null,[444,117],[444,126]]]],"invoice"],[],["loc",[null,[444,91],[444,138]]]],
              ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[445,26],[445,92]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 423,
                "column": 16
              },
              "end": {
                "line": 450,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/templates/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--icon l-4/24@phone l-1/24");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/textmodule-dark.svg");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--name l-24/24@phone l-19/24");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","sub");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk buttons-2");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            var element15 = dom.childAt(element14, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element15, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element15, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element14, [5]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","module.editable",["loc",[null,[430,30],[430,45]]]]],[],0,1,["loc",[null,[430,24],[434,31]]]],
            ["content","module.desc",["loc",[null,[436,40],[436,55]]]],
            ["block","if",[["get","module.editable",["loc",[null,[439,28],[439,43]]]]],[],2,null,["loc",[null,[439,22],[447,29]]]]
          ],
          locals: ["module"],
          templates: [child0, child1, child2]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 422,
              "column": 14
            },
            "end": {
              "line": 451,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","textModules",["loc",[null,[423,24],[423,35]]]]],[],0,null,["loc",[null,[423,16],[450,25]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child16 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 479,
                    "column": 22
                  },
                  "end": {
                    "line": 484,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/templates/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","exp icon-button icon-button--delete list-action-square");
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element5,1,1);
                morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
                return morphs;
              },
              statements: [
                ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"size","36","item",["subexpr","@mut",[["get","template",["loc",[null,[481,100],[481,108]]]]],[],[]],"confirmed","deleteTemplate","content","","text","Wollen Sie diese Textvorlage wirklich löschen?"],["loc",[null,[481,26],[481,202]]]],
                ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","template",["loc",[null,[482,57],[482,65]]]]],[],[]],"confirmed","deleteTemplate","textToShow","Löschen","text","Wollen Sie diese Textvorlage wirklich löschen?"],["loc",[null,[482,32],[482,169]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 484,
                    "column": 22
                  },
                  "end": {
                    "line": 487,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/templates/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","ml5 float-right icon-button--placeholder");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 469,
                  "column": 16
                },
                "end": {
                  "line": 493,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--icon l-4/24@phone l-1/24");
              dom.setAttribute(el2,"data-label","");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createElement("img");
              dom.setAttribute(el4,"src","assets/images/icons/template-dark.svg");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--name l-24/24@phone l-19/24");
              dom.setAttribute(el2,"data-label","Name");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createElement("a");
              dom.setAttribute(el4,"class","is-highlighted cursor");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","block");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk buttons-2");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","exp icon-button icon-button--default list-action-square");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              var el5 = dom.createTextNode("Bearbeiten");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var element7 = dom.childAt(element6, [3]);
              var element8 = dom.childAt(element7, [1, 0]);
              var element9 = dom.childAt(element6, [5]);
              var element10 = dom.childAt(element9, [3]);
              var morphs = new Array(6);
              morphs[0] = dom.createElementMorph(element8);
              morphs[1] = dom.createMorphAt(element8,0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element7, [3]),0,0);
              morphs[3] = dom.createMorphAt(element9,1,1);
              morphs[4] = dom.createElementMorph(element10);
              morphs[5] = dom.createMorphAt(element10,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["openEditTemplate",["get","template.id",["loc",[null,[475,89],[475,100]]]]],[],["loc",[null,[475,61],[475,102]]]],
              ["content","template.name",["loc",[null,[475,103],[475,120]]]],
              ["content","template.category.name",["loc",[null,[476,42],[476,68]]]],
              ["block","if",[["get","template.editable",["loc",[null,[479,28],[479,45]]]]],[],0,1,["loc",[null,[479,22],[487,29]]]],
              ["element","action",["openEditTemplate",["get","template.id",["loc",[null,[488,117],[488,128]]]]],[],["loc",[null,[488,89],[488,130]]]],
              ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[489,24],[489,90]]]]
            ],
            locals: ["template"],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 468,
                "column": 14
              },
              "end": {
                "line": 494,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/templates/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","templates",["loc",[null,[469,24],[469,33]]]]],[],0,null,["loc",[null,[469,16],[493,25]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 459,
              "column": 8
            },
            "end": {
              "line": 497,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table  table--large box no-box@phone grid");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__head");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-1/24");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-19/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Name");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-4/24");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__body");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["get","templates",["loc",[null,[468,20],[468,29]]]]],[],0,null,["loc",[null,[468,14],[494,21]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child17 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 510,
                  "column": 16
                },
                "end": {
                  "line": 524,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--icon l-4/24@phone l-1/24");
              dom.setAttribute(el2,"data-label","");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createElement("img");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-20/24@phone l-19/24 table__cell--name");
              dom.setAttribute(el2,"data-label","Name");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createElement("a");
              dom.setAttribute(el4,"class","cursor is-highlighted");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk buttons-1");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","exp icon-button icon-button--default list-action-square");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              var el5 = dom.createTextNode("Bearbeiten");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [1, 1, 0]);
              var element3 = dom.childAt(element1, [3, 1, 0]);
              var element4 = dom.childAt(element1, [5, 1]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element2, 'src');
              morphs[1] = dom.createElementMorph(element3);
              morphs[2] = dom.createMorphAt(element3,0,0);
              morphs[3] = dom.createElementMorph(element4);
              morphs[4] = dom.createMorphAt(element4,1,1);
              return morphs;
            },
            statements: [
              ["attribute","src",["concat",["assets/images/icons/",["get","product.type",["loc",[null,[513,60],[513,72]]]],"-dark.svg"]]],
              ["element","action",["gotoProductFuture",["get","product.id",["loc",[null,[516,90],[516,100]]]],["get","product.type",["loc",[null,[516,101],[516,113]]]]],[],["loc",[null,[516,61],[516,115]]]],
              ["content","product.name",["loc",[null,[516,116],[516,132]]]],
              ["element","action",["gotoProductFuture",["get","product.id",["loc",[null,[519,118],[519,128]]]],["get","product.type",["loc",[null,[519,129],[519,141]]]]],[],["loc",[null,[519,89],[519,143]]]],
              ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[520,24],[520,90]]]]
            ],
            locals: ["product"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 509,
                "column": 14
              },
              "end": {
                "line": 525,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/templates/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","products",["loc",[null,[510,24],[510,32]]]]],[],0,null,["loc",[null,[510,16],[524,25]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 500,
              "column": 8
            },
            "end": {
              "line": 531,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table box no-box@phone grid table--large");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__head");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-1/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-19/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Tierärztliche Leistung mit Erinnerungen");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-4/24");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__body");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","pagination-wrapper is-fixed");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["get","products",["loc",[null,[509,20],[509,28]]]]],[],0,null,["loc",[null,[509,14],[525,21]]]],
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","products",["loc",[null,[529,35],[529,43]]]]],[],[]],"numPagesToShow",7],["loc",[null,[529,12],[529,62]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 536,
              "column": 6
            },
            "end": {
              "line": 540,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["save"],[],["loc",[null,[537,9],[537,26]]]],
          ["inline","button-save",[],["color","#ffffff","size","60","content","Speichern"],["loc",[null,[538,8],[538,69]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 597,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/templates/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-14/24");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","card site-content fadeIn with-context-bar");
        dom.setAttribute(el1,"id","mandantTexts");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tabs__head");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"class","u-mb0 clearfix");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-templates");
        var el7 = dom.createTextNode("Textvorlagen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","tabs__body mt20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","userEdit");
        dom.setAttribute(el4,"class","admin-form");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-reminder");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mt0 mb20");
        var el7 = dom.createTextNode("Erinnerungen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","patient-category");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Übermittlungseinstellung");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","app-internal-person2");
        dom.setAttribute(el8,"class","field select");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-emaildata-reminderSubject");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Email-Betreff");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n                        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Vorschau");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","full--height");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" Wauzi: ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n                        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-emaildata-reminderHeader");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Überschrift");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n                        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Vorschau");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","full--height");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-default-reminderText");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Erinnerungstext");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-emaildata-reminderClosing");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("Abschlusssatz");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-settings");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mt0 mb20");
        var el7 = dom.createTextNode("Vorlagen & Berichte");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-11/24 field--narrow");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","u-mb0 field select full-width");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","title");
        var el10 = dom.createTextNode("Vorausgewähltes Briefpapier");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-warning");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mt0 mb20");
        var el7 = dom.createTextNode("1. Mahnung");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-emaildata-warning1subject");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("Email-Betreff");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-emaildata-warning1headline");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("Überschrift");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-emaildata-warning1text");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Text");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-emaildata-warning1closure");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Abschlusssatz");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("2. Mahnung");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-emaildata-warning2subject");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("Email-Betreff");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-emaildata-warning2headline");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("Überschrift");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-emaildata-warning2text");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Text");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-emaildata-warning2closure");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Abschlusssatz");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-app");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mt0 mb20");
        var el7 = dom.createTextNode("Standardtexte");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-18/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-emaildata-appReorderConfirm");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Bestellung abholbereit");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"id","appReorderConfirm");
        dom.setAttribute(el8,"class","droppable editable-input");
        dom.setAttribute(el8,"contenteditable","true");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-6/24 gutter placeholders");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Textelemente");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"data-id","patient");
        dom.setAttribute(el7,"data-label","Tiername");
        dom.setAttribute(el7,"class","selectable-item dragable");
        var el8 = dom.createTextNode("Tiername ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","block small");
        var el9 = dom.createTextNode("Flocki");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"data-id","mandantname");
        dom.setAttribute(el7,"data-label","Praxisname");
        dom.setAttribute(el7,"class","selectable-item dragable");
        var el8 = dom.createTextNode("Praxisname ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","block small");
        var el9 = dom.createTextNode("Tierarztpraxis Mustermann");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"data-id","operators");
        dom.setAttribute(el7,"data-label","Betreibername");
        dom.setAttribute(el7,"class","selectable-item dragable");
        var el8 = dom.createTextNode("Betreibername ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","block small");
        var el9 = dom.createTextNode("Dr. Max Mustermann");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-18/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-emaildata-appDefaultAppTitle");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Standardanmerkung für Termine");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"id","appDefaultAppTitle");
        dom.setAttribute(el8,"class","droppable editable-input");
        dom.setAttribute(el8,"contenteditable","true");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-6/24 gutter placeholders");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Textelemente");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"data-id","patient");
        dom.setAttribute(el7,"data-label","Tiername");
        dom.setAttribute(el7,"class","selectable-item dragable");
        var el8 = dom.createTextNode("Tiername ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","block small");
        var el9 = dom.createTextNode("Flocki");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"data-id","mandantname");
        dom.setAttribute(el7,"data-label","Praxisname");
        dom.setAttribute(el7,"class","selectable-item dragable");
        var el8 = dom.createTextNode("Praxisname ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","block small");
        var el9 = dom.createTextNode("Tierarztpraxis Mustermann");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"data-id","operators");
        dom.setAttribute(el7,"data-label","Betreibername");
        dom.setAttribute(el7,"class","selectable-item dragable");
        var el8 = dom.createTextNode("Betreibername ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","block small");
        var el9 = dom.createTextNode("Dr. Max Mustermann");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-18/24 gutter");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-emaildata-appDefaultAppDeclineTitle");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Standardtext für Terminabsage");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"id","appDefaultAppDeclineTitle");
        dom.setAttribute(el8,"class","droppable editable-input");
        dom.setAttribute(el8,"contenteditable","true");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-6/24 gutter placeholders");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Textelemente");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"data-id","patient");
        dom.setAttribute(el7,"data-label","Tiername");
        dom.setAttribute(el7,"class","selectable-item dragable");
        var el8 = dom.createTextNode("Tiername ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","block small");
        var el9 = dom.createTextNode("Flocki");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"data-id","mandantname");
        dom.setAttribute(el7,"data-label","Praxisname");
        dom.setAttribute(el7,"class","selectable-item dragable");
        var el8 = dom.createTextNode("Praxisname ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","block small");
        var el9 = dom.createTextNode("Tierarztpraxis Mustermann");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"data-id","operators");
        dom.setAttribute(el7,"data-label","Betreibername");
        dom.setAttribute(el7,"class","selectable-item dragable");
        var el8 = dom.createTextNode("Betreibername ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","block small");
        var el9 = dom.createTextNode("Dr. Max Mustermann");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-textmodules");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table  table--large box no-box@phone grid");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-1/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-19/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","pagination-wrapper is-fixed");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-reports");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table  table--large box no-box@phone grid");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-1/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-19/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","pagination-wrapper is-fixed");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-emails");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table  table--large box no-box@phone grid");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-1/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-19/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","pagination-wrapper is-fixed");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-invoices");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table  table--large box no-box@phone grid");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-1/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-19/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","pagination-wrapper is-fixed");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-templates");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-connectedArticles");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","action-icons");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $(\".dragable\").draggable({revert: true, helper: \"clone\"});\n\n    $(\".droppable\").droppable({\n      over: function(event, ui) {\n        $(event.target).focus();\n      },\n      out: function(event, ui) {\n      },\n      drop: function(event, ui) {\n        let html = \"<span data-id='\"+ui.draggable.attr('data-id')+\"' contenteditable='false' class='placeholder'>\"+ui.draggable.attr('data-label')+\"</span>\";\n        $(event.target).focus();\n        pasteHtmlAtCaret(html);\n      }\n    });\n\n    function pasteHtmlAtCaret(html) {\n      var sel, range;\n      if (window.getSelection) {\n        // IE9 and non-IE\n        sel = window.getSelection();\n        if (sel.getRangeAt && sel.rangeCount) {\n          range = sel.getRangeAt(0);\n          range.deleteContents();\n\n          // Range.createContextualFragment() would be useful here but is\n          // non-standard and not supported in all browsers (IE9, for one)\n          var el = document.createElement(\"div\");\n          el.innerHTML = html;\n          var frag = document.createDocumentFragment(), node, lastNode;\n          while ( (node = el.firstChild) ) {\n            lastNode = frag.appendChild(node);\n          }\n          range.insertNode(frag);\n\n          // Preserve the selection\n          if (lastNode) {\n            range = range.cloneRange();\n            range.setStartAfter(lastNode);\n            range.collapse(true);\n            sel.removeAllRanges();\n            sel.addRange(range);\n          }\n        }\n      }\n    }\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element48 = dom.childAt(fragment, [0]);
        var element49 = dom.childAt(element48, [3]);
        var element50 = dom.childAt(fragment, [2]);
        var element51 = dom.childAt(element50, [1]);
        var element52 = dom.childAt(element51, [1, 1]);
        var element53 = dom.childAt(element52, [1, 1]);
        var element54 = dom.childAt(element51, [3]);
        var element55 = dom.childAt(element54, [1]);
        var element56 = dom.childAt(element55, [1]);
        var element57 = dom.childAt(element56, [7, 1, 3]);
        var element58 = dom.childAt(element55, [5]);
        var element59 = dom.childAt(element55, [7]);
        var element60 = dom.childAt(element55, [9]);
        var element61 = dom.childAt(element54, [3]);
        var element62 = dom.childAt(element54, [5]);
        var element63 = dom.childAt(element54, [7]);
        var element64 = dom.childAt(element54, [9]);
        var element65 = dom.childAt(element54, [11]);
        var element66 = dom.childAt(element54, [13]);
        var morphs = new Array(60);
        morphs[0] = dom.createAttrMorph(element48, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element48, [1]),1,1);
        morphs[2] = dom.createAttrMorph(element49, 'class');
        morphs[3] = dom.createMorphAt(element49,1,1);
        morphs[4] = dom.createMorphAt(element49,2,2);
        morphs[5] = dom.createMorphAt(element49,3,3);
        morphs[6] = dom.createMorphAt(element49,4,4);
        morphs[7] = dom.createMorphAt(element49,5,5);
        morphs[8] = dom.createMorphAt(element49,6,6);
        morphs[9] = dom.createMorphAt(element49,7,7);
        morphs[10] = dom.createAttrMorph(element53, 'class');
        morphs[11] = dom.createElementMorph(element53);
        morphs[12] = dom.createMorphAt(element52,3,3);
        morphs[13] = dom.createMorphAt(element52,4,4);
        morphs[14] = dom.createMorphAt(element52,5,5);
        morphs[15] = dom.createElementMorph(element55);
        morphs[16] = dom.createAttrMorph(element56, 'class');
        morphs[17] = dom.createMorphAt(dom.childAt(element56, [3, 1, 3]),1,1);
        morphs[18] = dom.createMorphAt(dom.childAt(element56, [5, 1]),3,3);
        morphs[19] = dom.createMorphAt(element57,0,0);
        morphs[20] = dom.createMorphAt(element57,2,2);
        morphs[21] = dom.createMorphAt(dom.childAt(element56, [10, 1]),3,3);
        morphs[22] = dom.createMorphAt(dom.childAt(element56, [12, 1, 3]),0,0);
        morphs[23] = dom.createMorphAt(dom.childAt(element56, [14, 1]),3,3);
        morphs[24] = dom.createMorphAt(dom.childAt(element56, [16, 1]),3,3);
        morphs[25] = dom.createMorphAt(element55,3,3);
        morphs[26] = dom.createAttrMorph(element58, 'class');
        morphs[27] = dom.createMorphAt(dom.childAt(element58, [3, 1, 1]),3,3);
        morphs[28] = dom.createAttrMorph(element59, 'class');
        morphs[29] = dom.createMorphAt(dom.childAt(element59, [3, 1]),3,3);
        morphs[30] = dom.createMorphAt(dom.childAt(element59, [5, 1]),3,3);
        morphs[31] = dom.createMorphAt(dom.childAt(element59, [7, 1]),3,3);
        morphs[32] = dom.createMorphAt(dom.childAt(element59, [9, 1]),3,3);
        morphs[33] = dom.createMorphAt(dom.childAt(element59, [13, 1]),3,3);
        morphs[34] = dom.createMorphAt(dom.childAt(element59, [15, 1]),3,3);
        morphs[35] = dom.createMorphAt(dom.childAt(element59, [17, 1]),3,3);
        morphs[36] = dom.createMorphAt(dom.childAt(element59, [19, 1]),3,3);
        morphs[37] = dom.createAttrMorph(element60, 'class');
        morphs[38] = dom.createUnsafeMorphAt(dom.childAt(element60, [3, 1, 3]),0,0);
        morphs[39] = dom.createUnsafeMorphAt(dom.childAt(element60, [7, 1, 3]),0,0);
        morphs[40] = dom.createUnsafeMorphAt(dom.childAt(element60, [11, 1, 3]),0,0);
        morphs[41] = dom.createAttrMorph(element61, 'class');
        morphs[42] = dom.createMorphAt(dom.childAt(element61, [1, 3]),1,1);
        morphs[43] = dom.createMorphAt(dom.childAt(element61, [3]),1,1);
        morphs[44] = dom.createAttrMorph(element62, 'class');
        morphs[45] = dom.createMorphAt(dom.childAt(element62, [1, 3]),1,1);
        morphs[46] = dom.createMorphAt(dom.childAt(element62, [3]),1,1);
        morphs[47] = dom.createAttrMorph(element63, 'class');
        morphs[48] = dom.createMorphAt(dom.childAt(element63, [1, 3]),1,1);
        morphs[49] = dom.createMorphAt(dom.childAt(element63, [3]),1,1);
        morphs[50] = dom.createAttrMorph(element64, 'class');
        morphs[51] = dom.createMorphAt(dom.childAt(element64, [1, 3]),1,1);
        morphs[52] = dom.createMorphAt(dom.childAt(element64, [3]),1,1);
        morphs[53] = dom.createAttrMorph(element65, 'class');
        morphs[54] = dom.createMorphAt(element65,1,1);
        morphs[55] = dom.createAttrMorph(element66, 'class');
        morphs[56] = dom.createMorphAt(element66,1,1);
        morphs[57] = dom.createMorphAt(dom.childAt(element50, [3]),1,1);
        morphs[58] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[59] = dom.createMorphAt(fragment,8,8,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["context-bar grid ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,50],[1,63]]]],"sidebar",""],[],["loc",[null,[1,29],[1,78]]]]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectTab",["loc",[null,[3,18],[3,27]]]],"templates"],[],["loc",[null,[3,14],[3,40]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[3,45],[3,54]]]],"connectedArticles"],[],["loc",[null,[3,41],[3,75]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[3,80],[3,89]]]],"textmodules"],[],["loc",[null,[3,76],[3,104]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[3,109],[3,118]]]],"invoices"],[],["loc",[null,[3,105],[3,130]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[3,135],[3,144]]]],"emails"],[],["loc",[null,[3,131],[3,154]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[3,159],[3,168]]]],"reports"],[],["loc",[null,[3,155],[3,179]]]]],[],["loc",[null,[3,10],[3,180]]]]],[],0,null,["loc",[null,[3,4],[5,11]]]],
        ["attribute","class",["concat",["buttons-box col l-",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[7,53],[7,66]]]],"4","10"],[],["loc",[null,[7,32],[7,77]]]],"/24"]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[8,12],[8,21]]]],"warning"],[],["loc",[null,[8,8],[8,32]]]]],[],1,null,["loc",[null,[8,2],[10,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[11,12],[11,21]]]],"reminder"],[],["loc",[null,[11,8],[11,33]]]]],[],2,null,["loc",[null,[11,2],[13,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[14,12],[14,21]]]],"templates"],[],["loc",[null,[14,8],[14,34]]]]],[],3,null,["loc",[null,[14,2],[16,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[17,12],[17,21]]]],"textmodules"],[],["loc",[null,[17,8],[17,36]]]]],[],4,null,["loc",[null,[17,2],[19,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[20,12],[20,21]]]],"emails"],[],["loc",[null,[20,8],[20,31]]]]],[],5,null,["loc",[null,[20,2],[22,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[23,12],[23,21]]]],"reports"],[],["loc",[null,[23,8],[23,32]]]]],[],6,null,["loc",[null,[23,2],[25,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[26,12],[26,21]]]],"invoices"],[],["loc",[null,[26,8],[26,33]]]]],[],7,null,["loc",[null,[26,2],[28,9]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[37,78],[37,87]]]],"templates"],[],["loc",[null,[37,74],[37,100]]]],"is-active",""],[],["loc",[null,[37,53],[37,117]]]]]]],
        ["element","action",["selectTab","templates"],[],["loc",[null,[37,141],[37,175]]]],
        ["block","if",[["subexpr","is-at-least",["professional"],[],["loc",[null,[39,14],[39,42]]]]],[],8,null,["loc",[null,[39,8],[52,15]]]],
        ["block","if",[["subexpr","is-at-least",["essential"],[],["loc",[null,[53,14],[53,39]]]]],[],9,null,["loc",[null,[53,8],[65,15]]]],
        ["block","if",[["subexpr","is-at-least",["professional"],[],["loc",[null,[66,14],[66,42]]]]],[],10,null,["loc",[null,[66,8],[73,15]]]],
        ["element","action",["save"],["on","submit"],["loc",[null,[77,45],[77,74]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[78,58],[78,67]]]],"reminder"],[],["loc",[null,[78,54],[78,79]]]],"is-active",""],[],["loc",[null,[78,33],[78,96]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","deliveryOptions",["loc",[null,[86,26],[86,41]]]]],[],[]],"value",["subexpr","@mut",[["get","emailData.defaultReminderSetting",["loc",[null,[87,24],[87,56]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","value","optionValuePath","id","name","mandant.defaultReminderSetting"],["loc",[null,[85,16],[92,18]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.defaultReminderSubject",["loc",[null,[101,28],[101,60]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-emaildata-reminderSubject"],["loc",[null,[101,14],[101,154]]]],
        ["content","emailData.defaultReminderSubject",["loc",[null,[107,39],[107,75]]]],
        ["content","emailData.mandantName",["loc",[null,[107,83],[107,108]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.defaultReminderHeader",["loc",[null,[114,28],[114,59]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-emaildata-reminderHeader"],["loc",[null,[114,14],[114,152]]]],
        ["content","emailData.defaultReminderHeader",["loc",[null,[120,39],[120,74]]]],
        ["inline","textarea",[],["rows","5","value",["subexpr","@mut",[["get","emailData.defaultReminderText",["loc",[null,[126,40],[126,69]]]]],[],[]],"class","input textarea__input full","name","mandant-default-reminderText"],["loc",[null,[126,14],[126,142]]]],
        ["inline","textarea",[],["rows","5","value",["subexpr","@mut",[["get","emailData.defaultReminderClosing",["loc",[null,[132,40],[132,72]]]]],[],[]],"class","input textarea__input full","name","mandant-default-reminderClosing"],["loc",[null,[132,14],[132,148]]]],
        ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[136,6],[136,14]]]]],[],11,null,["loc",[null,[136,0],[160,7]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[161,58],[161,67]]]],"settings"],[],["loc",[null,[161,54],[161,79]]]],"is-active",""],[],["loc",[null,[161,33],[161,96]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","styles",["loc",[null,[169,32],[169,38]]]]],[],[]],"value",["subexpr","@mut",[["get","defaultTemplateStyle",["loc",[null,[170,30],[170,50]]]]],[],[]],"optionLabelPath","value","optionValuePath","id","allowClear",false,"cssClass","input--dropdown"],["loc",[null,[168,16],[175,18]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[180,58],[180,67]]]],"warning"],[],["loc",[null,[180,54],[180,78]]]],"is-active",""],[],["loc",[null,[180,33],[180,95]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.warning1subject",["loc",[null,[186,28],[186,53]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-emaildata-warning1subject"],["loc",[null,[186,14],[186,147]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.warning1headline",["loc",[null,[192,28],[192,54]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-emaildata-warning1headline"],["loc",[null,[192,14],[192,149]]]],
        ["inline","textarea",[],["rows","4","value",["subexpr","@mut",[["get","emailData.warning1text",["loc",[null,[199,40],[199,62]]]]],[],[]],"class","input textarea__input full","name","mandant-emaildata-warning1text"],["loc",[null,[199,14],[199,137]]]],
        ["inline","textarea",[],["rows","3","value",["subexpr","@mut",[["get","emailData.warning1closing",["loc",[null,[206,40],[206,65]]]]],[],[]],"class","input textarea__input full","name","mandant-emaildata-warning1closure"],["loc",[null,[206,14],[206,143]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.warning2subject",["loc",[null,[215,28],[215,53]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-emaildata-warning2subject"],["loc",[null,[215,14],[215,147]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.warning2headline",["loc",[null,[221,28],[221,54]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-emaildata-warning2headline"],["loc",[null,[221,14],[221,149]]]],
        ["inline","textarea",[],["rows","4","value",["subexpr","@mut",[["get","emailData.warning2text",["loc",[null,[228,40],[228,62]]]]],[],[]],"class","input textarea__input full","name","mandant-emaildata-warning2text"],["loc",[null,[228,14],[228,137]]]],
        ["inline","textarea",[],["rows","3","value",["subexpr","@mut",[["get","emailData.warning2closing",["loc",[null,[235,40],[235,65]]]]],[],[]],"class","input textarea__input full","name","mandant-emaildata-warning2closure"],["loc",[null,[235,14],[235,143]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[239,58],[239,67]]]],"app"],[],["loc",[null,[239,54],[239,74]]]],"is-active",""],[],["loc",[null,[239,33],[239,91]]]]]]],
        ["content","emailData.appReorderConfirm",["loc",[null,[244,98],[244,131]]]],
        ["content","emailData.appDefaultAppTitle",["loc",[null,[257,99],[257,133]]]],
        ["content","emailData.appDefaultAppDeclineTitle",["loc",[null,[270,106],[270,147]]]],
        ["attribute","class",["concat",["tabs__content pt0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[281,60],[281,69]]]],"textmodules"],[],["loc",[null,[281,56],[281,84]]]],"is-active",""],[],["loc",[null,[281,35],[281,101]]]]]]],
        ["block","if",[["get","textModules",["loc",[null,[289,20],[289,31]]]]],[],12,null,["loc",[null,[289,14],[318,21]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","textModules",["loc",[null,[322,33],[322,44]]]]],[],[]],"numPagesToShow",7],["loc",[null,[322,10],[322,63]]]],
        ["attribute","class",["concat",["tabs__content pt0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[325,60],[325,69]]]],"reports"],[],["loc",[null,[325,56],[325,80]]]],"is-active",""],[],["loc",[null,[325,35],[325,97]]]]]]],
        ["block","if",[["get","textModules",["loc",[null,[333,20],[333,31]]]]],[],13,null,["loc",[null,[333,14],[362,21]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","textModules",["loc",[null,[366,33],[366,44]]]]],[],[]],"numPagesToShow",7],["loc",[null,[366,10],[366,63]]]],
        ["attribute","class",["concat",["tabs__content pt0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[370,60],[370,69]]]],"emails"],[],["loc",[null,[370,56],[370,79]]]],"is-active",""],[],["loc",[null,[370,35],[370,96]]]]]]],
        ["block","if",[["get","textModules",["loc",[null,[378,20],[378,31]]]]],[],14,null,["loc",[null,[378,14],[407,21]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","textModules",["loc",[null,[411,33],[411,44]]]]],[],[]],"numPagesToShow",7],["loc",[null,[411,10],[411,63]]]],
        ["attribute","class",["concat",["tabs__content pt0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[414,60],[414,69]]]],"invoices"],[],["loc",[null,[414,56],[414,81]]]],"is-active",""],[],["loc",[null,[414,35],[414,98]]]]]]],
        ["block","if",[["get","textModules",["loc",[null,[422,20],[422,31]]]]],[],15,null,["loc",[null,[422,14],[451,21]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","textModules",["loc",[null,[455,33],[455,44]]]]],[],[]],"numPagesToShow",7],["loc",[null,[455,10],[455,63]]]],
        ["attribute","class",["concat",["tabs__content pt0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[458,60],[458,69]]]],"templates"],[],["loc",[null,[458,56],[458,82]]]],"is-active",""],[],["loc",[null,[458,35],[458,99]]]]]]],
        ["block","if",[["subexpr","is-at-least",["professional"],[],["loc",[null,[459,14],[459,42]]]]],[],16,null,["loc",[null,[459,8],[497,15]]]],
        ["attribute","class",["concat",["tabs__content pt0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[499,60],[499,69]]]],"connectedArticles"],[],["loc",[null,[499,56],[499,90]]]],"is-active",""],[],["loc",[null,[499,35],[499,107]]]]]]],
        ["block","if",[["subexpr","is-at-least",["professional"],[],["loc",[null,[500,14],[500,42]]]]],[],17,null,["loc",[null,[500,8],[531,15]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectTab",["loc",[null,[536,20],[536,29]]]],"invoice"],[],["loc",[null,[536,16],[536,40]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[536,45],[536,54]]]],"settings"],[],["loc",[null,[536,41],[536,66]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[536,71],[536,80]]]],"reminder"],[],["loc",[null,[536,67],[536,92]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[536,97],[536,106]]]],"app"],[],["loc",[null,[536,93],[536,113]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[536,118],[536,127]]]],"warning"],[],["loc",[null,[536,114],[536,138]]]]],[],["loc",[null,[536,12],[536,139]]]]],[],18,null,["loc",[null,[536,6],[540,13]]]],
        ["inline","template-edit-panel",[],["save","saveTemplate","actionReceiver",["subexpr","@mut",[["get","templateEditPanel",["loc",[null,[594,57],[594,74]]]]],[],[]]],["loc",[null,[594,0],[594,76]]]],
        ["inline","send-testmail-panel",[],["forwarded","sendTestmail","actionReceiver",["subexpr","@mut",[["get","sendTestmailPanel",["loc",[null,[595,63],[595,80]]]]],[],[]]],["loc",[null,[595,0],[595,82]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18]
    };
  }()));

});