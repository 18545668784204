define('jason-frontend/helpers/get-relative-discount-class', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getRelativeDiscountClass = getRelativeDiscountClass;

  function getRelativeDiscountClass(params) {
    if (params[0] != true) {
      return "hover";
    }
    return "";
  }

  exports['default'] = Ember['default'].Helper.helper(getRelativeDiscountClass);

});