define('jason-frontend/components/users-edit-form', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      imageChanged: function imageChanged(id) {
        this.set('tmpUserImage', id);
        this.sendAction('imageChanged', id);
      },
      showImagePanel: function showImagePanel() {
        var controller = this.get('controller');
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-image-upload-and-select-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
        controller.get('imageUploadAndSelectPanel').send('load', self, 'simple', 0);
      }
    }
  });

});