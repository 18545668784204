define('jason-frontend/routes/customers/show', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  var modelHookRun;

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    beforeModel: function beforeModel() {
      modelHookRun = false;
    },
    model: function model(params) {
      modelHookRun = true;
      return this.store.findRecord('customer', params.id, { reload: true });
    },
    afterModel: function afterModel(model) {
      if (!modelHookRun) {
        return model.reload();
      }
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);

      controller.set("banktransferEnabled", API['default'].hasPermission('c_banktransfer'));

      var deferred = $.ajax({
        url: "/api/customerDeposits/" + model.id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('deposits', data.customerDeposit);
        controller.set('depositSum', data.meta.depositSum);
        controller.set('balanceSum', data.meta.sum);
      });
    },
    applyCorrespondanceFilter: function applyCorrespondanceFilter() {
      var self = this;
      this.findPaged('customer-logbook-entry', {
        perPage: 10,
        paged: true,
        customerId: self.get('controller').get('model').get('id')
      }).then(function (entries) {
        self.get('controller').set('logbookEntries', entries);
      });
    },
    actions: {
      toggleDeadPatients: function toggleDeadPatients(enabled) {
        var controller = this.get('controller');
        controller.set('filterDeadPatients', enabled);
      },
      print: function print(customer) {
        window.open('/api/customers/balance/' + customer.id);
      },
      selectTab: function selectTab(selected) {
        var controller = this.get('controller');
        controller.set('selectTab', selected);

        if (selected === 'correspondance') {
          this.applyCorrespondanceFilter();
        }
      }
    }
  });

});