define('jason-frontend/routes/inventory/inventory-print', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    perPage: 10,
    queryParams: {},
    setupController: function setupController(controller, model) {
      controller.set("model", model);
      controller.set('warehouses', this.store.find('inventory-warehouse'));

      this.store.find('tax').then(function (items) {
        controller.set('taxes', items.sortBy('percentage'));
      });

      $.ajax({
        url: "/api/productCategories?suggest=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('productCategories', data.productCategory);
      });

      this.applyFilter();
    },
    actions: {
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          date = controller.get('pickDate'),
          warehouse = controller.get('filterWarehouse'),
          category = controller.get('filterProductCategory'),
          tax = controller.get('filterTax'),
          expired = controller.get('filterExpired'),
          name = controller.get('filterCode');

      if (warehouse) {
        warehouse = warehouse.id;
      }
      if (category) {
        category = category.id;
      }
      if (tax) {
        tax = tax.id;
      }
      if (expired) {
        expired = expired.id;
      }

      controller.set('filterWarehouseId', warehouse);
      controller.set('filterCategoryId', category);
      controller.set('filterTaxId', tax);
      controller.set('filterName', name);
      controller.set('filterExpiredId', expired);

      date = moment(date, 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('showLoading', true);
      controller.set('showLoadingTiles', true);

      controller.set('downloadToken', API['default'].getDownloadToken());
      controller.set('mandantId', API['default'].getMandant());

      this.findPaged('inventory-print', {
        filterDate: date,
        filterTax: tax,
        filterCode: name,
        filterCategory: category,
        filterExpired: expired,
        filterWarehouse: warehouse,
        pageSize: this.get('perPage')
      }).then(function (data) {
        var meta = data.meta;
        controller.set('model', data);
        controller.set('showLoading', false);
      });

      var filterString = '';

      if (tax) {
        filterString += '&filterTax=' + tax;
      }
      if (category) {
        filterString += '&filterCategory=' + category;
      }
      if (expired) {
        filterString += '&filterExpired=' + expired;
      }
      if (warehouse) {
        filterString += '&filterWarehouse=' + warehouse;
      }
      if (name) {
        filterString += '&filterCode=' + name;
      }

      $.ajax({
        url: "/api/inventoryPrints/total?filterDate=" + date + filterString,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('purchaseTotal', data.purchaseTotal);
        controller.set('purchaseTotalDetailed', data.purchaseTotalDetailed);
        controller.set('saleTotal', data.saleTotal);
        controller.set('saleTotalDetailed', data.saleTotalDetailed);
        controller.set('showLoadingTiles', false);
      }, function () {});
    }
  });

});