define('jason-frontend/models/cash-register', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    identificationNumber: DS['default'].attr('string'),
    rksvActive: DS['default'].attr('boolean'),
    rksvPossible: DS['default'].attr('boolean'),
    active: DS['default'].attr('')
  });

});