define('jason-frontend/components/icon-cash', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'bottom',
    color: "#3B6182",
    timer: 0,
    content: 'Umsatz',
    theme: 'tooltipster-punk'
  });

});