define('jason-frontend/controllers/textmodules/edit', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Bearbeiten",
    breadCrumbPath: "distributors.edit",
    sidebarActive: true,
    showTextelements: true,
    showTextmodules: true,
    selectedTab: 'treatment'
  });

});