define('jason-frontend/templates/purchases/summary', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 2
            },
            "end": {
              "line": 9,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/summary.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","purchase-entries",[],["order",["subexpr","@mut",[["get","model",["loc",[null,[8,29],[8,34]]]]],[],[]],"edit",false,"cancel",false,"statsLoaded","statsLoaded","entryLabelChanged","changeEntryLabel"],["loc",[null,[8,4],[8,123]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 6
              },
              "end": {
                "line": 18,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/purchases/summary.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge private");
            var el2 = dom.createTextNode("Privat");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 18,
                  "column": 6
                },
                "end": {
                  "line": 20,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/purchases/summary.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge lab");
              var el2 = dom.createTextNode("Labor");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 6
                },
                "end": {
                  "line": 22,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/purchases/summary.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge practice");
              var el2 = dom.createTextNode("Praxis");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 6
              },
              "end": {
                "line": 22,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/purchases/summary.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","model.laboklinOrder",["loc",[null,[18,16],[18,35]]]]],[],0,1,["loc",[null,[18,6],[22,6]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 4
              },
              "end": {
                "line": 36,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/purchases/summary.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-24/24");
            dom.setAttribute(el1,"id","toCartButton");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"class","button context primary");
            var el3 = dom.createTextNode("Bestellung bestätigen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [
            ["element","action",["openSupplierPanel",["get","model.id",["loc",[null,[34,45],[34,53]]]]],[],["loc",[null,[34,16],[34,55]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 3
            },
            "end": {
              "line": 42,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/purchases/summary.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar card col l-6/24@desk l-24/24@phone l-24/24@tablet");
          dom.setAttribute(el1,"id","minicartSidebar");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","mfp-close");
          var el3 = dom.createTextNode("X");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2,"class","cursor");
          var el3 = dom.createTextNode("Warenkorb");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","label-container");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","grid");
          dom.setAttribute(el2,"id","checkmark");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","l-2/24 checkbox-custom pl10 checkbox-primary col u-text--right mtm10");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","acceptAgbs");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-22/24 u-text--left pl20");
          var el4 = dom.createTextNode("Ich akzeptiere die AGB und Lieferbedingungen aller Lieferanten");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","basket-sums");
          dom.setAttribute(el2,"class","scrollable");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createElementMorph(element3);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [5]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element1, [7, 1]),1,1);
          morphs[4] = dom.createMorphAt(element1,9,9);
          morphs[5] = dom.createMorphAt(dom.childAt(element1, [11]),1,1);
          return morphs;
        },
        statements: [
          ["element","action",["toggleSidebar",true],[],["loc",[null,[13,44],[13,75]]]],
          ["element","action",["toCart",["get","order.id",["loc",[null,[14,41],[14,49]]]]],[],["loc",[null,[14,23],[14,51]]]],
          ["block","if",[["get","model.privateOrder",["loc",[null,[16,12],[16,30]]]]],[],0,1,["loc",[null,[16,6],[22,13]]]],
          ["inline","input",[],["type","checkbox","name","acceptAgbs","id","acceptAgbs","checked",["subexpr","@mut",[["get","acceptAgbs",["loc",[null,[27,74],[27,84]]]]],[],[]]],["loc",[null,[27,8],[27,86]]]],
          ["block","if",[["subexpr","and",[["get","acceptAgbs",["loc",[null,[32,15],[32,25]]]],["subexpr","order-entries-available",[["get","model",["loc",[null,[32,51],[32,56]]]]],[],["loc",[null,[32,26],[32,57]]]]],[],["loc",[null,[32,10],[32,58]]]]],[],2,null,["loc",[null,[32,4],[36,11]]]],
          ["inline","purchase-order-sum-new",[],["showButton",["subexpr","@mut",[["get","acceptAgbs",["loc",[null,[39,42],[39,52]]]]],[],[]],"buttonLabel","Bestellung abschließen","toCart","openSupplierPanel","order",["subexpr","@mut",[["get","model",["loc",[null,[39,123],[39,128]]]]],[],[]],"reductionSum",["subexpr","@mut",[["get","reductionSum",["loc",[null,[39,142],[39,154]]]]],[],[]],"currentOrderId",["subexpr","@mut",[["get","currentOrderId",["loc",[null,[39,170],[39,184]]]]],[],[]],"showTaxDetails",true,"showGross",true],["loc",[null,[39,6],[39,221]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 0
            },
            "end": {
              "line": 46,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/purchases/summary.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar-hidden");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"id","showSidebar");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[44,61],[44,196]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 71,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/purchases/summary.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","cart");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons");
        var el2 = dom.createTextNode("\n        ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button icon-button--small");
        var el3 = dom.createTextNode("\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $( window ).resize(function() {\n      $('aside.side-bar').css('min-height', $(window).height()-80);\n      $('aside.side-bar div.scrollable').css('max-height', $(window).height()-200);\n    });\n    $('aside.side-bar').css('min-height', $(window).height()-80);\n    $('aside.side-bar div.scrollable').css('max-height', $(window).height()-200);\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(fragment, [2]);
        var element7 = dom.childAt(fragment, [14, 1]);
        var morphs = new Array(12);
        morphs[0] = dom.createAttrMorph(element4, 'class');
        morphs[1] = dom.createAttrMorph(element5, 'class');
        morphs[2] = dom.createMorphAt(element5,1,1);
        morphs[3] = dom.createAttrMorph(element6, 'class');
        morphs[4] = dom.createMorphAt(element6,1,1);
        morphs[5] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[6] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[7] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[8] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[9] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[10] = dom.createElementMorph(element7);
        morphs[11] = dom.createMorphAt(element7,1,1);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["context-bar grid ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,50],[1,63]]]],"sidebar","sidebar-inactive"],[],["loc",[null,[1,29],[1,94]]]]]]],
        ["attribute","class",["concat",["col l-",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[2,41],[2,54]]]],"10","16"],[],["loc",[null,[2,20],[2,66]]]],"/24"]]],
        ["inline","checkout-progress",[],["active","3"],["loc",[null,[3,4],[3,36]]]],
        ["attribute","class",["concat",["with-context-bar  l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[6,62],[6,75]]]]],[],["loc",[null,[6,57],[6,76]]]],"24","18"],[],["loc",[null,[6,36],[6,88]]]],"/24@desk ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[6,118],[6,131]]]],"sidebar-active",""],[],["loc",[null,[6,97],[6,153]]]]]]],
        ["block","if",[["subexpr","gt",[["subexpr","get-size",[["get","model.subOrders",["loc",[null,[7,22],[7,37]]]]],[],["loc",[null,[7,12],[7,38]]]],0],[],["loc",[null,[7,8],[7,41]]]]],[],0,null,["loc",[null,[7,2],[9,9]]]],
        ["block","if",[["get","sidebarActive",["loc",[null,[11,9],[11,22]]]]],[],1,2,["loc",[null,[11,3],[46,7]]]],
        ["inline","purchase-order-supplier-panel",[],["data",["subexpr","@mut",[["get","model",["loc",[null,[49,39],[49,44]]]]],[],[]],"toMandant","toMandant","confirm","checkRichter","deleteProduct","deleteProduct","openLaboklin","openLaboklin","actionReceiver",["subexpr","@mut",[["get","supplierPanel",["loc",[null,[49,163],[49,176]]]]],[],[]]],["loc",[null,[49,2],[49,178]]]],
        ["inline","purchase-order-richter-async-panel",[],["confirmRichter","confirmRichter","actionReceiver",["subexpr","@mut",[["get","purchaseOrderRichterAsyncPanel",["loc",[null,[50,86],[50,116]]]]],[],[]]],["loc",[null,[50,2],[50,118]]]],
        ["inline","purchase-order-laboklin-panel",[],["data",["subexpr","@mut",[["get","model",["loc",[null,[51,39],[51,44]]]]],[],[]],"form",["subexpr","@mut",[["get","form",["loc",[null,[51,50],[51,54]]]]],[],[]],"confirm","confirmLaboklin"],["loc",[null,[51,2],[51,82]]]],
        ["inline","datacare-delivery-panel",[],["confirm","confirm","actionReceiver",["subexpr","@mut",[["get","datacareDeliveryPanel",["loc",[null,[52,61],[52,82]]]]],[],[]]],["loc",[null,[52,2],[52,84]]]],
        ["element","action",["toSelectOrder",["get","model.id",["loc",[null,[55,36],[55,44]]]]],[],["loc",[null,[55,11],[55,46]]]],
        ["inline","button-prev",[],["size","44"],["loc",[null,[56,10],[56,35]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});