define('jason-frontend/templates/components/button-closeout-with-confirm', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 4
            },
            "end": {
              "line": 19,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/button-closeout-with-confirm.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("g");
          dom.setAttribute(el1,"transform","translate(-1215 -275)");
          dom.setAttribute(el1,"fill-rule","evenodd");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2,"d","M1219.686 302.314c6.25 6.248 16.38 6.248 22.628 0 6.248-6.25 6.248-16.38 0-22.628-6.25-6.248-16.38-6.248-22.628 0-6.248 6.25-6.248 16.38 0 22.628zm1.078-1.078c-5.654-5.653-5.654-14.82 0-20.472 5.653-5.654 14.82-5.654 20.472 0 5.654 5.653 5.654 14.82 0 20.472-5.653 5.654-14.82 5.654-20.472 0z");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'fill');
          return morphs;
        },
        statements: [
          ["attribute","fill",["concat",[["get","color",["loc",[null,[16,49],[16,54]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-closeout-with-confirm.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"class","btn-delete");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el2 = dom.createElement("svg");
        dom.setAttribute(el2,"class","button--delete");
        dom.setAttribute(el2,"viewBox","0 0 32 32");
        dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("title");
        var el4 = dom.createTextNode("löschen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","translate(6,7),scale(0.035,0.035)");
        dom.setAttribute(el3,"fill-rule","evenodd");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M444.288,429.288c-5.4-13.2-10.9-26.4-16.9-39.4c-5.3-11.6-12.1-15-24.8-12.1c-16.1,3.7-31.9,8.6-48,11.9\n		c-31.1,6.5-62.3,7.1-93-2.6c-38.5-12.1-59-40-71.6-76h104.4c8.2,0,14.8-6.6,14.8-14.8v-32.9c0-8.2-6.6-14.8-14.8-14.8h-114.4\n		c0-9.2-0.1-18,0-26.8h114.4c8.2,0,14.8-6.6,14.8-14.8v-32.9c0-8.2-6.6-14.8-14.8-14.8h-100c0-0.4,0-0.8,0.2-1\n		c12-27.3,29.5-49.2,58.2-60.6c33.4-13.2,67.5-12.9,101.9-5.8c16.3,3.3,32.3,8.3,48.6,12c11.9,2.7,18.8-0.8,23.9-11.9\n		c5.9-12.8,11.3-25.8,16.7-38.9c5.1-12.3,2.1-21-9.5-27.8c-2.9-1.7-5.9-3.1-9-4.3c-48.2-18.8-97.9-25.8-149.2-17.6\n		c-36.1,5.8-69.8,18.2-98.9,40.8c-36.7,28.4-60.5,65.9-74.3,110l-1.7,5.1h-51.4c-8.2,0-14.8,6.6-14.8,14.8v32.9\n		c0,8.2,6.6,14.8,14.8,14.8h40.5c0,9,0,17.7,0,26.8h-40.5c-8.2,0-14.8,6.6-14.8,14.8v32.9c0,8.2,6.6,14.8,14.8,14.8h48.8\n		c3.7,12,6.8,24.2,11.5,35.7c24.7,59.6,66.1,102,128.4,122.2c51.5,16.7,103.4,16.2,155.3,1.9c13.5-3.7,26.9-8.5,39.7-14.4\n		C445.988,450.788,449.188,441.188,444.288,429.288z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        dom.setNamespace(null);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","delete-confirm medium-confirm");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","footer");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","button");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createTextNode("Nein");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","button");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createTextNode("Ja");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(fragment, [2]);
        var element5 = dom.childAt(element4, [3]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element5, [3]);
        var morphs = new Array(10);
        morphs[0] = dom.createAttrMorph(element1, 'data-id');
        morphs[1] = dom.createAttrMorph(element1, 'title');
        morphs[2] = dom.createElementMorph(element1);
        morphs[3] = dom.createAttrMorph(element2, 'width');
        morphs[4] = dom.createAttrMorph(element2, 'height');
        morphs[5] = dom.createAttrMorph(element3, 'fill');
        morphs[6] = dom.createMorphAt(element2,5,5);
        morphs[7] = dom.createMorphAt(element4,1,1);
        morphs[8] = dom.createElementMorph(element6);
        morphs[9] = dom.createElementMorph(element7);
        return morphs;
      },
      statements: [
        ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[1,54],[1,61]]]]]]],
        ["attribute","title",["concat",[["get","tooltip",["loc",[null,[1,74],[1,81]]]]]]],
        ["element","action",["confirm"],[],["loc",[null,[1,3],[1,23]]]],
        ["attribute","width",["concat",[["get","size",["loc",[null,[2,39],[2,43]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[2,57],[2,61]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[4,61],[4,66]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[15,10],[15,20]]]]],[],0,null,["loc",[null,[15,4],[19,11]]]],
        ["content","text",["loc",[null,[23,2],[23,10]]]],
        ["element","action",["cancel"],[],["loc",[null,[25,7],[25,26]]]],
        ["element","action",["delete"],[],["loc",[null,[26,7],[26,26]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});