define('jason-frontend/templates/components/toggle-dead-patients-button', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 157
            },
            "end": {
              "line": 1,
              "column": 204
            }
          },
          "moduleName": "jason-frontend/templates/components/toggle-dead-patients-button.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" Verstorbene Patienten anzeigen");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/toggle-dead-patients-button.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"title","Verstorbene Patienten anzeigen ja/nein");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'data-selected');
        morphs[2] = dom.createAttrMorph(element0, 'data-id');
        morphs[3] = dom.createElementMorph(element0);
        morphs[4] = dom.createMorphAt(element0,0,0);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",[["get","cssClass",["loc",[null,[1,32],[1,40]]]]," btn fav-button"]]],
        ["attribute","data-selected",["concat",[["get","selected",["loc",[null,[1,76],[1,84]]]]]]],
        ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[1,99],[1,106]]]]]]],
        ["element","action",["toggle"],[],["loc",[null,[1,3],[1,22]]]],
        ["block","if",[["get","showText",["loc",[null,[1,163],[1,171]]]]],[],0,null,["loc",[null,[1,157],[1,211]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});