define('jason-frontend/helpers/is-same-number', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isSameNumber = isSameNumber;

  function isSameNumber(params) {

    var first = params[0];
    var second = params[1];

    return first == second;
  }

  exports['default'] = Ember['default'].Helper.helper(isSameNumber);

});