define('jason-frontend/helpers/detail-grid', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.detailGrid = detailGrid;

  function detailGrid(param) {
    var info = param[0];
    return !info.get('active') || info.get('promotionActive');
  }

  exports['default'] = Ember['default'].Helper.helper(detailGrid);

});