define('jason-frontend/components/animal-icon', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';


  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'bottom',
    timer: 0,
    content: 'Rasse',
    useFilter: false,
    color: "#000000",
    theme: 'tooltipster-punk'
  });

});