define('jason-frontend/templates/components/button-include', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 4
              },
              "end": {
                "line": 6,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/button-include.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("circle");
            dom.setAttribute(el1,"class","circle");
            dom.setAttribute(el1,"cx","16");
            dom.setAttribute(el1,"cy","16");
            dom.setAttribute(el1,"r","15");
            dom.setAttribute(el1,"fill","white");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element1, 'stroke');
            return morphs;
          },
          statements: [
            ["attribute","stroke",["concat",[["get","color",["loc",[null,[5,62],[5,67]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 4
              },
              "end": {
                "line": 8,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/button-include.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("circle");
            dom.setAttribute(el1,"class","circle");
            dom.setAttribute(el1,"cx","16");
            dom.setAttribute(el1,"cy","16");
            dom.setAttribute(el1,"r","15");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'stroke');
            return morphs;
          },
          statements: [
            ["attribute","stroke",["concat",[["get","color",["loc",[null,[7,62],[7,67]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 9,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/button-include.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","fill",["loc",[null,[4,10],[4,14]]]]],[],0,1,["loc",[null,[4,4],[8,11]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 32,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-include.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"class","button--delete");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(0 0)");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","scale(0.035,0.035),translate(130,180)");
        dom.setAttribute(el3,"fill-rule","evenodd");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("g");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6,"d","M345.652,227.266l-94.356-94.348c-5.064-5.068-11.82-7.856-19.024-7.856c-7.208,0-13.964,2.788-19.028,7.856\n			l-16.12,16.116c-5.064,5.064-7.852,11.82-7.852,19.024c0,7.208,2.788,14.008,7.852,19.076l20.384,20.428H26.944\n			C12.108,207.562,0,219.542,0,234.378v0.092v22.708c0,14.832,12.108,27.26,26.944,27.26H218.68l-21.52,21.344\n			c-5.064,5.06-7.852,11.728-7.852,18.932c0,7.204,2.788,13.916,7.852,18.98l16.12,16.1c5.068,5.064,11.82,7.84,19.024,7.84\n			c7.212,0,13.964-2.796,19.032-7.856l94.316-94.328c5.08-5.08,7.868-11.864,7.844-19.084\n			C353.52,239.126,350.732,232.342,345.652,227.266z");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("g");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6,"d","M453.316,0.002H188.22c-21.196,0-38.312,17.464-38.312,38.66v34.404c0,14.728,12.316,26.876,27.04,26.876h22.796\n			c13.56,0,24.8-11.532,26.64-23.064h188.744v338.248H226.416c-1.756-13.148-13.056-23.064-26.672-23.064h-22.796\n			c-14.724,0-27.04,12.18-27.04,26.908v34.592c0,21.196,17.116,38.436,38.312,38.436h265.096c21.196,0,38.684-17.244,38.684-38.436\n			v-414.9C492,17.466,474.512,0.002,453.316,0.002z");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element3, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element2, 'width');
        morphs[1] = dom.createAttrMorph(element2, 'height');
        morphs[2] = dom.createMorphAt(element3,1,1);
        morphs[3] = dom.createAttrMorph(element4, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,37],[1,41]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,55],[1,59]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,8],[3,18]]]]],[],0,null,["loc",[null,[3,2],[9,9]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[10,63],[10,68]]]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});