define('jason-frontend/serializers/treatment-patient', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  exports['default'] = DS['default'].ActiveModelSerializer.extend(DS['default'].EmbeddedRecordsMixin, {
    attrs: {
      patient: { embedded: 'always' },
      info: { embedded: 'always' },
      patientOwner: { embedded: 'always' },
      patientTreatmentInfo: { embedded: 'always' },
      state: { embedded: 'always' },
      room: { embedded: 'always' },
      station: { embedded: 'always' },
      entries: { embedded: 'always' },
      doctor: { embedded: 'always' },
      wishDoctor: { embedded: 'always' },
      assistant: { embedded: 'always' }
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      var serialized = this.serialize(record, options);
      serialized.id = record.id ? record.id : 0;
      Ember['default'].merge(hash, serialized);
    },
    keyForAttribute: function keyForAttribute(attr) {
      return Ember['default'].String.camelize(attr);
    }
  });

});