define('jason-frontend/routes/invoices/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      controller.set("model", this.store.fetch('invoice', model.id));
    },
    actions: {
      selectCash: function selectCash(invoiceId) {
        var self = this;
        var invoice = new Object();
        var customer = new Object();
        invoice.id = invoiceId;
        invoice.customer = customer;
        var deferred = $.ajax({
          url: "/api/invoices/" + invoice.id + "/changecustomer",
          method: "PUT",
          data: JSON.stringify(invoice),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          jason.notifiction.notifiy('Rechnung wurde zu Barverkauft', '');
          return data;
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
        return Ember['default'].RSVP.resolve(deferred);
      },
      deleteProduct: function deleteProduct(entryId, invoiceId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeQuantity/" + entryId + "/0",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          jason.notifiction.notifiy('Produkt entfernt', productName);
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      closeInvoice: function closeInvoice(invoiceId) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/close",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('invoices.show', invoiceId);
          jason.notifiction.notifiy('Rechnung abgeschlossen', '');
        }, function (error) {
          self.transitionTo('invoices.show', invoiceId);
          jason.notifiction.notifiy('Rechnung abgeschlossen', '');
        });

        return Ember['default'].RSVP.resolve(deferred);
      },
      changeQuantity: function changeQuantity(quantity, entryId, invoiceId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeQuantity/" + entryId + "/" + quantity,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          jason.notifiction.notifiy('Menge aktualisiert', productName);
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      changePrice: function changePrice(price, entryId, invoiceId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changePrice/" + entryId + "/" + price,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          jason.notifiction.notifiy('Preis aktualisiert', productName);
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      changeApplied: function changeApplied(applied, entryId, invoiceId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/invoices/" + invoiceId + "/changeApplied/" + entryId + "/" + applied,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          jason.notifiction.notifiy('Verabreichung aktualisiert', productName);
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      }
    }
  });

});