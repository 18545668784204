define('jason-frontend/templates/components/button-delete-with-confirm', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 12
            },
            "end": {
              "line": 7,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/components/button-delete-with-confirm.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("path");
          dom.setAttribute(el1,"d","M1219.686 302.314c6.25 6.248 16.38 6.248 22.628 0 6.248-6.25 6.248-16.38 0-22.628-6.25-6.248-16.38-6.248-22.628 0-6.248 6.25-6.248 16.38 0 22.628zm1.078-1.078c-5.654-5.653-5.654-14.82 0-20.472 5.653-5.654 14.82-5.654 20.472 0 5.654 5.653 5.654 14.82 0 20.472-5.653 5.654-14.82 5.654-20.472 0z");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-delete-with-confirm.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"class","btn-delete");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el2 = dom.createElement("svg");
        dom.setAttribute(el2,"class","button--delete");
        dom.setAttribute(el2,"viewBox","0 0 32 32");
        dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","translate(-1215 -275)");
        dom.setAttribute(el3,"fill-rule","evenodd");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M1232.077 291l5.66-5.66c.297-.296.295-.777-.003-1.074-.3-.3-.78-.3-1.075-.003l-5.66 5.66-5.66-5.66c-.296-.297-.777-.295-1.074.003-.3.3-.3.78-.003 1.075l5.66 5.66-5.66 5.66c-.297.296-.295.777.003 1.074.3.3.78.3 1.075.003l5.66-5.66 5.66 5.66c.296.297.777.295 1.074-.003.3-.3.3-.78.003-1.075l-5.66-5.66z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        dom.setNamespace(null);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","delete-confirm medium-confirm");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","footer");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","button choice");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createTextNode("Nein");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","button choice");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createTextNode("Ja");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(fragment, [2]);
        var element4 = dom.childAt(element3, [3]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element4, [3]);
        var morphs = new Array(10);
        morphs[0] = dom.createAttrMorph(element0, 'data-id');
        morphs[1] = dom.createAttrMorph(element0, 'title');
        morphs[2] = dom.createElementMorph(element0);
        morphs[3] = dom.createAttrMorph(element1, 'width');
        morphs[4] = dom.createAttrMorph(element1, 'height');
        morphs[5] = dom.createAttrMorph(element2, 'fill');
        morphs[6] = dom.createMorphAt(element2,3,3);
        morphs[7] = dom.createMorphAt(element3,1,1);
        morphs[8] = dom.createElementMorph(element5);
        morphs[9] = dom.createElementMorph(element6);
        return morphs;
      },
      statements: [
        ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[1,54],[1,61]]]]]]],
        ["attribute","title",["concat",[["get","tooltip",["loc",[null,[1,74],[1,81]]]]]]],
        ["element","action",["confirm"],[],["loc",[null,[1,3],[1,23]]]],
        ["attribute","width",["concat",[["get","size",["loc",[null,[2,41],[2,45]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[2,59],[2,63]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[3,53],[3,58]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[5,18],[5,28]]]]],[],0,null,["loc",[null,[5,12],[7,19]]]],
        ["content","text",["loc",[null,[12,2],[12,10]]]],
        ["element","action",["cancel"],[],["loc",[null,[14,11],[14,30]]]],
        ["element","action",["delete"],[],["loc",[null,[15,11],[15,30]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});