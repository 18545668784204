define('jason-frontend/components/notifications-timeline', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      markAsRead: function markAsRead(msg) {
        this.sendAction('markAsRead', msg);
      },
      markAsReadForAll: function markAsReadForAll(msg) {
        this.sendAction('markAsReadForAll', msg);
      },
      startAdmissionFromReorder: function startAdmissionFromReorder(msg) {
        this.sendAction('startAdmissionFromReorder', msg);
      },
      startAdmissionFromMessage: function startAdmissionFromMessage(msg) {
        this.sendAction('startAdmissionFromMessage', msg);
      },
      startAdmission: function startAdmission(id, customerId) {
        this.sendAction('startAdmission', id, customerId);
      },
      activateEtiga: function activateEtiga(id, msgId) {
        this.sendAction('activateEtiga', id, msgId);
      },
      openAppRequestConfirmation: function openAppRequestConfirmation(item) {
        this.sendAction('openAppRequestConfirmation', item);
      },
      forward: function forward(msg) {
        this.sendAction('forward', msg);
      },
      gotoPatient: function gotoPatient(msg) {
        this.sendAction('gotoPatient', msg);
      },
      openDirectMessage: function openDirectMessage(id, type) {
        this.sendAction('openDirectMessage', id, type);
      },
      reply: function reply(msg) {
        this.sendAction('reply', msg);
      },
      gotoPracticeCart: function gotoPracticeCart() {
        this.sendAction('gotoPracticeCart');
      },
      gotoPurchaseProduct: function gotoPurchaseProduct(id) {
        this.sendAction('gotoPurchaseProduct', id);
      },
      "delete": function _delete(msg) {
        this.sendAction('delete', msg);
      },
      addTask: function addTask(msg) {
        this.sendAction('addTask', msg);
      },
      showTask: function showTask(msg) {
        this.sendAction('showTask', msg);
      }
    }
  });

});