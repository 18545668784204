define('jason-frontend/controllers/documents/index', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Meine Dokumente",
    breadCrumbPath: "documents.index",
    showFilters: true,
    queryParams: ["page", "perPage", "filterName"],
    filterName: "",
    sortBy: null,
    sortDir: null,
    tagsEnabled: false,
    searchEnabled: false,
    downloadFolderEnabled: false,
    searchPlaceholder: "Dokumente suchen",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",

    page: 1,
    perPage: 10,
    actions: {}
  });

});