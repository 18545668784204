define('jason-frontend/models/patient-weight-info', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    date: DS['default'].attr('date'),
    weight: DS['default'].attr('number'),
    shared: DS['default'].attr('boolean'),
    editable: DS['default'].attr('boolean'),
    notice: DS['default'].attr('string')
  });

});