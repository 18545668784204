define('jason-frontend/controllers/cash/add-article', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Position hinzufügen",
    queryParams: ["page", "perPage", "stocktakingId", "categoryRedirect"],
    invoice: null,
    products: null,
    filterName: null,
    ignoreCrumbs: true,
    filterSupplier: 'all',
    placeholder: 'Produkt suchen',
    selectedType: 'product',
    filterManufacturer: null,
    sidebarActive: false,
    sidebarVisible: false,
    filterAll: !API['default'].isPreselectFavorites(),
    filterFavs: API['default'].isPreselectFavorites(),
    filterDistributor: null,
    showFilters: true,
    sortBy: null,
    sortDir: null,
    categoryFilterClass: 'hidden',
    selectedCategoryFilters: null,
    showLoading: true,
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    perPage: 10,
    filterTiny: true,
    filterHorse: true,
    filterUse: true,
    myStickyOptionsTableHead: {
      topSpacing: 160,
      zIndex: 9
    },
    watchSmall: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterTiny'),
    watchHorse: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterHorse'),
    watchUse: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterUse'),
    watchFavs: (function () {
      if (this.get('filterFavs')) {
        this.set('filterAll', false);
      } else {
        this.set('filterAll', true);
      }
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterFavs'),
    watchfilterAll: (function () {
      if (this.get('filterAll')) {
        this.set('filterFavs', false);
      } else {
        this.set('filterFavs', true);
      }
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterAll'),
    watchName: (function () {
      this.set('page', 1);
    }).observes('filterName'),
    watchDistributor: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterDistributor'),
    watchCategory: (function () {
      this.set('page', 1);
      var self = this;
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('selectedCategoryFilters'),
    watchSupplier: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterSupplier'),
    watchManufacturer: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterManufacturer')
  });

});