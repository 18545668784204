define('jason-frontend/helpers/print-list-of-id', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.printListOfName = printListOfName;

  function printListOfName(params) {
    var list = params[0];
    var values = new Array();
    if (list) {
      $(list).each(function (idx, val) {
        values.push(val.id);
      });
    }
    return values.join(',');
  }

  exports['default'] = Ember['default'].Helper.helper(printListOfName);

});