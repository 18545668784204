define('jason-frontend/templates/components/purchase-article-price-line-info', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/purchase-article-price-line-info.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","priceInfo");
        dom.setAttribute(el1,"style","float:right");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        dom.setAttribute(el2,"class","price");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]),0,0);
        return morphs;
      },
      statements: [
        ["inline","format-currency",[],["value",["subexpr","multiply",[["get","entry.price",["loc",[null,[2,56],[2,67]]]],["get","entry.fixedQuantity",["loc",[null,[2,68],[2,87]]]]],[],["loc",[null,[2,46],[2,88]]]]],["loc",[null,[2,22],[2,90]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});