define('jason-frontend/helpers/is-tgd', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isTgd = isTgd;

  function isTgd(params) {

    var entry = params[0];

    try {
      var article = entry.get('article');

      if (article) {
        if (entry.get('tgd') == true) {
          return true;
        }
      }
    } catch (e) {
      var article = entry.article;

      if (article) {
        if (entry.tgd == true) {
          return true;
        }
      }
    }
    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(isTgd);

});