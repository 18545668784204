define('jason-frontend/components/document-tag-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    prefix: null,
    actions: {
      addTag: function addTag(tag) {
        if (!this.get('document').tags) {
          Ember['default'].set(this.get('document'), 'tags', new Array());
        }
        Ember['default'].set(tag, 'selected', true);
        this.get('document').tags.addObject(tag);
      },
      removeTag: function removeTag(tag) {
        this.get('document').tags.removeObject(tag);

        $.each(this.get('tags'), function () {
          var id = $(this)[0].id;
          if (id === tag.id) {
            Ember['default'].set($(this)[0], 'selected', false);
          }
        });
      },
      save: function save() {
        this.sendAction('saveTags', this.get('document'));
      },
      load: function load(document, tags) {
        this.set('document', document);
        this.set('tags', tags);

        var self = this;

        $.each(this.get('tags'), function () {
          var id = $(this)[0].id;
          var that = $(this)[0];
          $.each(self.get('document').tags, function () {
            var id2 = $(this)[0].id;
            if (id2 === id) {
              Ember['default'].set(that, 'selected', true);
            }
          });
        });
      }
    }
  });

});