define('jason-frontend/models/patient-appointment', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    date: DS['default'].attr('string'),
    dateFormatted: DS['default'].attr('string'),
    dateEnd: DS['default'].attr('string'),
    multiDay: DS['default'].attr('boolean'),
    dateFrom: DS['default'].attr('string'),
    dateTo: DS['default'].attr('string'),
    category: DS['default'].belongsTo('patient-appointment-category'),
    etigaCategory: DS['default'].belongsTo('patient-appointment-category'),
    bookingCategory: DS['default'].attr(''),
    preferredEmployee: DS['default'].attr(''),
    notice: DS['default'].attr(''),
    patient: DS['default'].attr(''),
    customer: DS['default'].attr(''),
    created: DS['default'].attr('date'),
    wholeDay: DS['default'].attr('boolean'),
    title: DS['default'].attr('string'),
    persons: DS['default'].attr('string'),
    location: DS['default'].attr('string'),
    description: DS['default'].attr('string'),
    user: DS['default'].belongsTo('user'),
    room: DS['default'].belongsTo('treatment-room'),
    station: DS['default'].belongsTo('treatment-station'),
    treatmentPatientId: DS['default'].attr(''),
    internalUser1: DS['default'].attr(''),
    resource: DS['default'].attr(''),
    editSeries: DS['default'].attr(''),
    seriesInterval: DS['default'].attr(''),
    seriesEnd: DS['default'].attr(''),
    appStatus: DS['default'].attr(''),
    internalUser2: DS['default'].attr(''),
    internalUser3: DS['default'].attr(''),
    internalAssistant1: DS['default'].attr(''),
    internalAssistant2: DS['default'].attr(''),
    internalAssistant3: DS['default'].attr(''),
    introductionReasons: DS['default'].attr('')
  });

});