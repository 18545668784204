define('jason-frontend/templates/components/merge-patient-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 31
            },
            "end": {
              "line": 17,
              "column": 97
            }
          },
          "moduleName": "jason-frontend/templates/components/merge-patient-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","animal-icon",[],["evil",["subexpr","@mut",[["get","patient.evil",["loc",[null,[15,74],[15,86]]]]],[],[]],"classNames","inline-icon","animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[16,66],[16,85]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[16,94],[16,118]]]]],[],[]],"size","32","content",["subexpr","@mut",[["get","patient.category.name",["loc",[null,[17,74],[17,95]]]]],[],[]]],["loc",[null,[15,55],[17,97]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 54,
                    "column": 26
                  },
                  "end": {
                    "line": 57,
                    "column": 105
                  }
                },
                "moduleName": "jason-frontend/templates/components/merge-patient-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","animal-icon",[],["evil",["subexpr","@mut",[["get","otherPatient.evil",["loc",[null,[54,74],[54,91]]]]],[],[]],"classNames","inline-icon","animal-id",["subexpr","@mut",[["get","otherPatient.category.id",["loc",[null,[55,79],[55,103]]]]],[],[]],"race-id",["subexpr","@mut",[["get","otherPatient.digitalRace.icon",["loc",[null,[56,77],[56,106]]]]],[],[]],"size","32","content",["subexpr","@mut",[["get","otherPatient.category.name",["loc",[null,[57,77],[57,103]]]]],[],[]]],["loc",[null,[54,55],[57,105]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 60,
                    "column": 78
                  },
                  "end": {
                    "line": 62,
                    "column": 28
                  }
                },
                "moduleName": "jason-frontend/templates/components/merge-patient-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                            ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","icon-etiga",[],["classNames","inline-icon","size","20","width",60,"color","00AAC6","connected",true],["loc",[null,[61,30],[61,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 62,
                    "column": 36
                  },
                  "end": {
                    "line": 64,
                    "column": 97
                  }
                },
                "moduleName": "jason-frontend/templates/components/merge-patient-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-died",[],["classNames","ml10","size","21","useConfirm",false,"showStroke",false,"content","verstorben"],["loc",[null,[62,61],[64,97]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 70,
                    "column": 20
                  },
                  "end": {
                    "line": 71,
                    "column": 81
                  }
                },
                "moduleName": "jason-frontend/templates/components/merge-patient-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","otherPatient.digitalRace.name",["loc",[null,[71,48],[71,81]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 71,
                    "column": 81
                  },
                  "end": {
                    "line": 71,
                    "column": 110
                  }
                },
                "moduleName": "jason-frontend/templates/components/merge-patient-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","otherPatient.race",["loc",[null,[71,89],[71,110]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 14
                },
                "end": {
                  "line": 83,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/merge-patient-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-1/24@desk");
              dom.setAttribute(el2,"data-label","Geschlecht");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-1/24@desk");
              dom.setAttribute(el2,"data-label","Art");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--name l-12/24 l-12/24@tablet l-5/24@desk");
              dom.setAttribute(el2,"data-label","Name");
              var el3 = dom.createTextNode("\n                            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","patient-view");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-4/24@desk");
              dom.setAttribute(el2,"data-label","Spezies");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-12/24 l-6/24@tablet l-5/24@desk");
              dom.setAttribute(el2,"data-label","Rasse");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-4/24@desk");
              dom.setAttribute(el2,"data-label","Farbe");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell  table__cell--action l-4/24@desk buttons-3");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","icon-button icon-button--success list-action-square");
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              var el5 = dom.createTextNode("Auswählen");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [5, 1]);
              var element2 = dom.childAt(element0, [13, 1]);
              var morphs = new Array(10);
              morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
              morphs[2] = dom.createMorphAt(element1,0,0);
              morphs[3] = dom.createMorphAt(element1,2,2);
              morphs[4] = dom.createMorphAt(element1,4,4);
              morphs[5] = dom.createMorphAt(dom.childAt(element0, [7]),1,1);
              morphs[6] = dom.createMorphAt(dom.childAt(element0, [9]),1,1);
              morphs[7] = dom.createMorphAt(dom.childAt(element0, [11]),1,1);
              morphs[8] = dom.createElementMorph(element2);
              morphs[9] = dom.createMorphAt(element2,1,1);
              return morphs;
            },
            statements: [
              ["inline","icon-gender",[],["gender",["subexpr","@mut",[["get","otherPatient.gender.id",["loc",[null,[50,47],[50,69]]]]],[],[]],"castrated",["subexpr","@mut",[["get","otherPatient.castrated",["loc",[null,[50,80],[50,102]]]]],[],[]],"content",["subexpr","@mut",[["get","otherPatient.genderDetails",["loc",[null,[51,48],[51,74]]]]],[],[]],"size","32","color","#4A4A4A"],["loc",[null,[50,26],[51,102]]]],
              ["block","if",[["get","otherPatient.category",["loc",[null,[54,32],[54,53]]]]],[],0,null,["loc",[null,[54,26],[57,112]]]],
              ["content","otherPatient.name",["loc",[null,[60,55],[60,76]]]],
              ["block","if",[["get","otherPatient.etigaActive",["loc",[null,[60,84],[60,108]]]]],[],1,null,["loc",[null,[60,78],[62,35]]]],
              ["block","if",[["get","otherPatient.dead",["loc",[null,[62,42],[62,59]]]]],[],2,null,["loc",[null,[62,36],[64,104]]]],
              ["content","otherPatient.category.name",["loc",[null,[67,20],[67,50]]]],
              ["block","if",[["get","otherPatient.digitalRace",["loc",[null,[71,22],[71,46]]]]],[],3,4,["loc",[null,[70,20],[71,117]]]],
              ["content","otherPatient.color",["loc",[null,[74,20],[74,42]]]],
              ["element","action",["selectPatient",["get","otherPatient",["loc",[null,[77,48],[77,60]]]]],[],["loc",[null,[77,23],[77,62]]]],
              ["inline","button-select",[],["color","#fff","showStroke",false,"classNames","mr10","content","Mit diesem Tier zusammenführen"],["loc",[null,[78,22],[79,80]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 12
              },
              "end": {
                "line": 84,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/merge-patient-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","or",[["subexpr","not",[["get","otherPatient.dead",["loc",[null,[47,29],[47,46]]]]],[],["loc",[null,[47,24],[47,47]]]],["subexpr","and",[["get","showDead",["loc",[null,[47,53],[47,61]]]],["get","otherPatient.dead",["loc",[null,[47,62],[47,79]]]]],[],["loc",[null,[47,48],[47,80]]]]],[],["loc",[null,[47,20],[47,81]]]]],[],0,null,["loc",[null,[47,14],[83,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 45,
              "column": 10
            },
            "end": {
              "line": 85,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/merge-patient-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","unless",[["subexpr","is-same-id",[["get","otherPatient.id",["loc",[null,[46,34],[46,49]]]],["get","patient.id",["loc",[null,[46,50],[46,60]]]]],[],["loc",[null,[46,22],[46,61]]]]],[],0,null,["loc",[null,[46,12],[84,23]]]]
        ],
        locals: ["otherPatient"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 93,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/merge-patient-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-merge-patient-panel");
        dom.setAttribute(el1,"class","small--heading popup-basic popup-extremely-large admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("Tierdaten zusammenführen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-body");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"style","width: 100%; margin: 0 auto 20px;padding: 0 20px;");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-10/24 customerBox");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Quelle");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","flex-center align-items-center");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ml10");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ml10");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"style","font-size: 1.1em;");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","block");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-4/24");
        dom.setAttribute(el5,"style","text-align: center;padding-top: 25px;");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("=>");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("br");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","small");
        var el7 = dom.createTextNode(" Gewicht, Behandlungen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-8/24 customerBox");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Ziel");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          Bitte Tier aus Liste wählen\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table grid table--large");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__head");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__cell l-2/24");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","table__title title");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__cell l-5/24");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","table__title title");
        var el8 = dom.createTextNode("Name");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__cell l-4/24");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","table__title title");
        var el8 = dom.createTextNode("Spezies");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__cell l-5/24");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","table__title title");
        var el8 = dom.createTextNode("Rasse");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__cell l-4/24");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","table__title title");
        var el8 = dom.createTextNode("Farbe");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__cell l-4/24");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__body");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0, 1, 3]);
        var element4 = dom.childAt(element3, [1, 1, 3]);
        var element5 = dom.childAt(element4, [5]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element4,1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
        morphs[2] = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
        morphs[3] = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
        morphs[4] = dom.createMorphAt(dom.childAt(element3, [3, 3]),1,1);
        return morphs;
      },
      statements: [
        ["inline","icon-gender",[],["gender",["subexpr","@mut",[["get","patient.gender.id",["loc",[null,[13,37],[13,54]]]]],[],[]],"castrated",["subexpr","@mut",[["get","patient.castrated",["loc",[null,[13,65],[13,82]]]]],[],[]],"content",["subexpr","@mut",[["get","patient.genderDetails",["loc",[null,[13,91],[13,112]]]]],[],[]],"size","32","color","#4A4A4A"],["loc",[null,[13,16],[14,57]]]],
        ["block","if",[["get","patient.category",["loc",[null,[15,37],[15,53]]]]],[],0,null,["loc",[null,[15,31],[17,104]]]],
        ["content","patient.name",["loc",[null,[19,50],[19,66]]]],
        ["content","patient.color",["loc",[null,[20,38],[20,55]]]],
        ["block","each",[["get","model.patients",["loc",[null,[45,18],[45,32]]]]],[],1,null,["loc",[null,[45,10],[85,19]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});