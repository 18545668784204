define('jason-frontend/components/icon-module-cash', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    classNames: 'licenceIcon',
    size: 90,
    viewport: 60,
    side: 'top',
    timer: 0,
    tagName: 'div',
    content: 'Kassa',
    theme: 'tooltipster-punk'
  });

});