define('jason-frontend/helpers/is-same-id', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isSameId = isSameId;

  function isSameId(params) {
    var id1 = params[0],
        id2 = params[1];

    return String(id1) === String(id2);
  }

  exports['default'] = Ember['default'].Helper.helper(isSameId);

});