define('jason-frontend/controllers/templates/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    selectTab: 'templates',
    queryParams: ['selectTab'],
    sidebarActive: false,
    filterName: null,
    deliveryOptions: [{ id: "all", value: "Smartphone App + Email" }, { id: "app", value: "Smartphone App" }, { id: "email", value: "Email" }],
    styles: [{ value: "Keine Vorauswahl", id: "none" }, { value: "Stil 1", id: "style1" }, { value: "Stil 2", id: "style2" }, { value: "Leeres Briefpapier", id: "styleempty" }],
    stylesAlternative: [{ value: "Keine Vorauswahl", id: "none" }, { value: "Stil 1", id: "style1" }, { value: "Stil 2", id: "style3" }, { value: "Leeres Briefpapier", id: "styleempty" }],
    actions: {
      openTestmailPanel: function openTestmailPanel(type) {

        this.send('save');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-send-testmail-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });

        this.get('sendTestmailPanel').send('init', type);
      }
    }
  });

});