define('jason-frontend/components/add-price-adaption', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    item: null,
    types: [{
      name: "Meine Produkte", id: "products"
    }, {
      name: "Meine Leistungen", id: "services"
    }],
    indexes: [{ name: "0,1%", id: "0.1" }, { name: "0,2%", id: "0.2" }, { name: "0,3%", id: "0.3" }, { name: "0,4%", id: "0.4" }, { name: "0,5%", id: "0.5" }, { name: "0,6%", id: "0.6" }, { name: "0,7%", id: "0.7" }, { name: "0,8%", id: "0.8" }, { name: "0,9%", id: "0.9" }, { name: "1,0%", id: "1.0" }, { name: "1,1%", id: "1.1" }, { name: "1,2%", id: "1.2" }, { name: "1,3%", id: "1.3" }, { name: "1,4%", id: "1.4" }, { name: "1,5%", id: "1.5" }, { name: "1,6%", id: "1.6" }, { name: "1,7%", id: "1.7" }, { name: "1,8%", id: "1.8" }, { name: "1,9%", id: "1.9" }, { name: "2,0%", id: "2.0" }, { name: "2,1%", id: "2.1" }, { name: "2,2%", id: "2.2" }, { name: "2,3%", id: "2.3" }, { name: "2,4%", id: "2.4" }, { name: "2,5%", id: "2.5" }, { name: "2,6%", id: "2.6" }, { name: "2,7%", id: "2.7" }, { name: "2,8%", id: "2.8" }, { name: "2,9%", id: "2.9" }, { name: "3,0%", id: "3.0" }, { name: "3,1%", id: "3.1" }, { name: "3,2%", id: "3.2" }, { name: "3,3%", id: "3.3" }, { name: "3,4%", id: "3.4" }, { name: "3,5%", id: "3.5" }, { name: "3,6%", id: "3.6" }, { name: "3,7%", id: "3.7" }, { name: "3,8%", id: "3.8" }, { name: "3,9%", id: "3.9" }, { name: "4,0%", id: "4.0" }, { name: "4,1%", id: "4.1" }, { name: "4,2%", id: "4.2" }, { name: "4,3%", id: "4.3" }, { name: "4,4%", id: "4.4" }, { name: "4,5%", id: "4.5" }, { name: "4,6%", id: "4.6" }, { name: "4,7%", id: "4.7" }, { name: "4,8%", id: "4.8" }, { name: "4,9%", id: "4.9" }, { name: "5,0%", id: "5.0" }, { name: "6,0%", id: "6.0" }, { name: "7,0%", id: "7.0" }, { name: "8,0%", id: "8.0" }, { name: "9,0%", id: "9.0" }, { name: "10,0%", id: "10.0" }, { name: "11,0%", id: "11.0" }, { name: "12,0%", id: "12.0" }, { name: "13,0%", id: "13.0" }, { name: "14,0%", id: "14.0" }, { name: "15,0%", id: "15.0" }],
    actions: {
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load() {

        this.set('type', null);
        this.set('category', null);
        this.set('index', null);
      },
      confirm: function confirm() {

        var data = {
          type: this.get('type').id,
          index: this.get('index').id
        };

        if (this.get('category')) {
          category: this.get('category').name, data.categoryId = this.get('category').id;
        }

        this.sendAction('confirm', data);
        $.magnificPopup.close();
      }
    },
    watchType: (function () {
      var self = this;
      if (this.get('type')) {
        if (this.get('type').id === 'products') {
          $.ajax({
            url: "/api/productCategories?suggest=true",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.set('categories', data.productCategory);
          });
        } else {
          $.ajax({
            url: "/api/serviceCategories?suggest=true",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.set('categories', data.serviceCategory);
          });
        }
      }
    }).observes('type')
  });

});