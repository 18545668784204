define('jason-frontend/routes/purchases/cancel', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  var modelHookRun;

  exports['default'] = Ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      modelHookRun = false;
    },
    model: function model(params) {
      return this.store.find('purchaseOrder', params.id);
    },
    afterModel: function afterModel(model) {
      if (!modelHookRun) {
        return model.reload();
      }
      return model;
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);

      var cancellationId = params.params['purchases.cancel'].cancellationId;
      controller.set('cancellationId', cancellationId);
      controller.set('reason', null);
      controller.set('accept', false);
    },
    actions: {
      cancelCancellation: function cancelCancellation() {
        this.transitionTo('purchases.choose');
      },
      changeReturnQuantity: function changeReturnQuantity(qty, entryId, orderId, cancellationId, productName) {
        var self = this;

        if (qty < 0) {
          return;
        }

        var deferred = $.ajax({
          url: "/api/purchaseOrders/" + orderId + "/" + cancellationId + "/changeReturnQuantity/" + entryId + "/" + qty,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.refresh();
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      confirmCancellation: function confirmCancellation(cancellationId, orderId) {
        var self = this,
            form = new Object();

        form.reason = this.get('controller').get('reason');

        $.ajax({
          url: "/api/purchaseOrders/commitCancel/" + cancellationId,
          method: "POST",
          data: JSON.stringify(form),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Stornierung/Retoure', 'erfolgreich erfasst');
          self.transitionTo('purchases.confirmcancel', orderId, cancellationId);
        });
      }
    }
  });

});