define('jason-frontend/components/invoice-discount-input', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      changeInvoiceDiscount: function changeInvoiceDiscount(invoiceId, absolute, oldDiscount) {
        var discount = this.$().find("input#global-discount").val();
        if (oldDiscount != discount) {
          this.sendAction('invoiceDiscountChanged', invoiceId, discount, absolute);
        }
      },
      setRelativeDiscountInvoice: function setRelativeDiscountInvoice(invoiceId, absolute) {
        if (absolute) {
          var discount = this.$().find("input#global-discount").val();
          this.sendAction('relativeInvoiceDiscountChanged', invoiceId, discount);
        }
      },
      setAbsoluteDiscountInvoice: function setAbsoluteDiscountInvoice(invoiceId, absolute) {
        if (!absolute) {
          var discount = this.$().find("input#global-discount").val();
          this.sendAction('absoluteInvoiceDiscountChanged', invoiceId, discount);
        }
      }
    }
  });

});