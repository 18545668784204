define('jason-frontend/templates/components/digital-form-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 14
              },
              "end": {
                "line": 24,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-24/24");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n                      ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h2");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                      ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","header-toggle");
            var el5 = dom.createTextNode("\n                        ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                        ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("label");
            dom.setAttribute(el5,"class","switch");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                      ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1, 1]);
            var element5 = dom.childAt(element4, [1]);
            var element6 = dom.childAt(element5, [3]);
            var element7 = dom.childAt(element6, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element4, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
            morphs[2] = dom.createMorphAt(element6,1,1);
            morphs[3] = dom.createAttrMorph(element7, 'for');
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["toggle-container ",["subexpr","css-bool-evaluator",[["get","item.show",["loc",[null,[14,68],[14,77]]]],"active",""],[],["loc",[null,[14,47],[14,91]]]]]]],
            ["content","item.name",["loc",[null,[16,26],[16,39]]]],
            ["inline","input",[],["type","checkbox","id",["subexpr","@mut",[["get","item.name",["loc",[null,[18,51],[18,60]]]]],[],[]],"classNames","switch","checked",["subexpr","@mut",[["get","item.show",["loc",[null,[18,89],[18,98]]]]],[],[]]],["loc",[null,[18,24],[18,100]]]],
            ["attribute","for",["concat",[["get","item.name",["loc",[null,[19,38],[19,47]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 14
              },
              "end": {
                "line": 27,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-8/24");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h3");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
            return morphs;
          },
          statements: [
            ["content","item.name",["loc",[null,[26,22],[26,35]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 30,
                  "column": 18
                },
                "end": {
                  "line": 34,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element3, 'data-id');
              morphs[1] = dom.createAttrMorph(element3, 'class');
              morphs[2] = dom.createElementMorph(element3);
              morphs[3] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","data-id",["concat",[["get","subItem.id",["loc",[null,[31,36],[31,46]]]]]]],
              ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["get","subItem.selected",["loc",[null,[31,94],[31,110]]]],"selected",""],[],["loc",[null,[31,73],[31,126]]]]]]],
              ["element","action",["select",["get","subItem",["loc",[null,[31,146],[31,153]]]],["get","item",["loc",[null,[31,154],[31,158]]]]],[],["loc",[null,[31,128],[31,160]]]],
              ["content","subItem.name",["loc",[null,[32,28],[32,44]]]]
            ],
            locals: ["subItem"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 16
              },
              "end": {
                "line": 35,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","item.values",["loc",[null,[30,26],[30,37]]]]],[],0,null,["loc",[null,[30,18],[34,27]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 36,
                    "column": 18
                  },
                  "end": {
                    "line": 40,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element2, 'data-id');
                morphs[1] = dom.createAttrMorph(element2, 'class');
                morphs[2] = dom.createElementMorph(element2);
                morphs[3] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
                return morphs;
              },
              statements: [
                ["attribute","data-id",["concat",[["get","subItem.id",["loc",[null,[37,36],[37,46]]]]]]],
                ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["get","subItem.selected",["loc",[null,[37,94],[37,110]]]],"selected",""],[],["loc",[null,[37,73],[37,126]]]]]]],
                ["element","action",["select",["get","subItem",["loc",[null,[37,146],[37,153]]]],["get","item",["loc",[null,[37,154],[37,158]]]]],[],["loc",[null,[37,128],[37,160]]]],
                ["content","subItem.name",["loc",[null,[38,28],[38,44]]]]
              ],
              locals: ["subItem"],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 16
                },
                "end": {
                  "line": 41,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.values",["loc",[null,[36,26],[36,37]]]]],[],0,null,["loc",[null,[36,18],[40,27]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 41,
                    "column": 16
                  },
                  "end": {
                    "line": 48,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Ja");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Nein");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var element1 = dom.childAt(fragment, [3]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element0, 'class');
                morphs[1] = dom.createElementMorph(element0);
                morphs[2] = dom.createAttrMorph(element1, 'class');
                morphs[3] = dom.createElementMorph(element1);
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","item.value",["loc",[null,[42,71],[42,81]]]],"true"],[],["loc",[null,[42,67],[42,89]]]],"selected",""],[],["loc",[null,[42,46],[42,105]]]]]]],
                ["element","action",["selectBoolean",["get","item",["loc",[null,[42,132],[42,136]]]],"true"],[],["loc",[null,[42,107],[42,145]]]],
                ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","item.value",["loc",[null,[45,71],[45,81]]]],"false"],[],["loc",[null,[45,67],[45,90]]]],"selected",""],[],["loc",[null,[45,46],[45,106]]]]]]],
                ["element","action",["selectBoolean",["get","item",["loc",[null,[45,133],[45,137]]]],"false"],[],["loc",[null,[45,108],[45,147]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 48,
                      "column": 16
                    },
                    "end": {
                      "line": 50,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","input",[],["value",["subexpr","@mut",[["get","item.value",["loc",[null,[49,32],[49,42]]]]],[],[]],"class","gui-input newStyle small-input","type","number"],["loc",[null,[49,18],[49,97]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 50,
                        "column": 16
                      },
                      "end": {
                        "line": 52,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","input",[],["value",["subexpr","@mut",[["get","item.value",["loc",[null,[51,32],[51,42]]]]],[],[]],"class","gui-input newStyle","type","text"],["loc",[null,[51,18],[51,84]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 52,
                          "column": 16
                        },
                        "end": {
                          "line": 54,
                          "column": 16
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","input",[],["value",["subexpr","@mut",[["get","item.value",["loc",[null,[53,32],[53,42]]]]],[],[]],"class","gui-input newStyle small-input","type","text"],["loc",[null,[53,18],[53,96]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 54,
                            "column": 16
                          },
                          "end": {
                            "line": 56,
                            "column": 16
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                  ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n                ");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        return morphs;
                      },
                      statements: [
                        ["inline","textarea",[],["rows","3","value",["subexpr","@mut",[["get","item.value",["loc",[null,[55,44],[55,54]]]]],[],[]],"class","input textarea__input full newStyle"],["loc",[null,[55,18],[55,101]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 54,
                          "column": 16
                        },
                        "end": {
                          "line": 56,
                          "column": 16
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["subexpr","eq",[["get","item.provider",["loc",[null,[54,30],[54,43]]]],"text_large"],[],["loc",[null,[54,26],[54,57]]]]],[],0,null,["loc",[null,[54,16],[56,16]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 52,
                        "column": 16
                      },
                      "end": {
                        "line": 56,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","item.provider",["loc",[null,[52,30],[52,43]]]],"text_small"],[],["loc",[null,[52,26],[52,57]]]]],[],0,1,["loc",[null,[52,16],[56,16]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 50,
                      "column": 16
                    },
                    "end": {
                      "line": 56,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","item.provider",["loc",[null,[50,30],[50,43]]]],"text"],[],["loc",[null,[50,26],[50,51]]]]],[],0,1,["loc",[null,[50,16],[56,16]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 48,
                    "column": 16
                  },
                  "end": {
                    "line": 56,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.provider",["loc",[null,[48,30],[48,43]]]],"number"],[],["loc",[null,[48,26],[48,53]]]]],[],0,1,["loc",[null,[48,16],[56,16]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 16
                },
                "end": {
                  "line": 56,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.provider",["loc",[null,[41,30],[41,43]]]],"boolean"],[],["loc",[null,[41,26],[41,54]]]]],[],0,1,["loc",[null,[41,16],[56,16]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 16
              },
              "end": {
                "line": 56,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","item.provider",["loc",[null,[35,30],[35,43]]]],"multi"],[],["loc",[null,[35,26],[35,52]]]]],[],0,1,["loc",[null,[35,16],[56,16]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 10
            },
            "end": {
              "line": 59,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-16/24 item-row");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element8, 'class');
          morphs[1] = dom.createAttrMorph(element8, 'data-parent-id');
          morphs[2] = dom.createAttrMorph(element8, 'data-headline-id');
          morphs[3] = dom.createMorphAt(element8,1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element8, [3]),1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["digital-field-row col l-24/24 ",["subexpr","css-bool-evaluator",[["get","item.headline",["loc",[null,[11,75],[11,88]]]],"headline",""],[],["loc",[null,[11,54],[11,104]]]]," ",["subexpr","css-bool-evaluator",[["get","item.parentId",["loc",[null,[11,126],[11,139]]]],"sub-item",""],[],["loc",[null,[11,105],[11,155]]]]," ",["subexpr","css-bool-evaluator",[["get","item.firstOfSection",["loc",[null,[11,177],[11,196]]]],"first",""],[],["loc",[null,[11,156],[11,209]]]]]]],
          ["attribute","data-parent-id",["concat",[["get","item.parentId",["loc",[null,[11,229],[11,242]]]]]]],
          ["attribute","data-headline-id",["concat",[["get","item.headlineId",["loc",[null,[11,266],[11,281]]]]]]],
          ["block","if",[["get","item.headline",["loc",[null,[12,20],[12,33]]]]],[],0,1,["loc",[null,[12,14],[27,29]]]],
          ["block","if",[["subexpr","eq",[["get","item.provider",["loc",[null,[29,26],[29,39]]]],"single"],[],["loc",[null,[29,22],[29,49]]]]],[],2,3,["loc",[null,[29,16],[56,23]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2, child3]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 79,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/digital-form-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-digital-form-panel");
        dom.setAttribute(el1,"class","popup-basic popup-very-large admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"id","digitalForm");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-heading");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","panel-title");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-body box grid");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","grid");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-footer text-right");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","icon-button icon-button--success");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $(\"body\").delegate(\"#digitalForm textarea\", \"keyup\", function() {\n      $(this).height(30);\n      $(this).height(this.scrollHeight + parseFloat($(this).css(\"borderTopWidth\")) + parseFloat($(this).css(\"borderBottomWidth\")));\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0, 1, 1]);
        var element10 = dom.childAt(element9, [5, 1]);
        var morphs = new Array(5);
        morphs[0] = dom.createElementMorph(element9);
        morphs[1] = dom.createMorphAt(dom.childAt(element9, [1, 1]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element9, [3, 1]),1,1);
        morphs[3] = dom.createElementMorph(element10);
        morphs[4] = dom.createMorphAt(element10,1,1);
        return morphs;
      },
      statements: [
        ["element","action",["confirm"],["on","submit"],["loc",[null,[3,27],[3,59]]]],
        ["content","item.title",["loc",[null,[6,10],[6,24]]]],
        ["block","each",[["get","item.entries",["loc",[null,[10,18],[10,30]]]]],[],0,null,["loc",[null,[10,10],[59,19]]]],
        ["element","action",["cancel"],[],["loc",[null,[63,52],[63,71]]]],
        ["inline","button-ok",[],["size","32","showStroke",false,"color","#ffffff","content",""],["loc",[null,[64,10],[64,77]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});