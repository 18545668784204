define('jason-frontend/helpers/is-cancelation-allowed', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isCancelationAllowed = isCancelationAllowed;

  function isCancelationAllowed(params) {
    return params[0].get('transactionType') != null && (params[0].get('transactionType').get('id') === 'cancel_in' || params[0].get('transactionType').get('id') === 'cancel_out' || params[0].get('canceledBy') > 0) ? false : true;
  }

  exports['default'] = Ember['default'].Helper.helper(isCancelationAllowed);

});