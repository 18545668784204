define('jason-frontend/routes/patientappointmentcategories/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('patient-appointment-category', params.id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },
    actions: {
      save: function save(id) {
        var self = this;

        var controller = this.get('controller'),
            model = controller.get('model'),
            data = new Object();

        data.id = id;
        data.name = model.get('name');
        data.color = model.get('color');
        data.active = model.get('active');

        $.ajax({
          url: "/api/patientAppointmentCategories",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          if (model.get('etiga')) {
            self.transitionTo('basicinfos', { queryParams: { selectTab: "etigaAppointmentcategories" } });
          } else {
            self.transitionTo('basicinfos', { queryParams: { selectTab: "appointmentcategories" } });
          }
        }, function () {
          if (model.get('etiga')) {
            self.transitionTo('basicinfos', { queryParams: { selectTab: "etigaAppointmentcategories" } });
          } else {
            self.transitionTo('basicinfos', { queryParams: { selectTab: "appointmentcategories" } });
          }
        });
      }
    }
  });

});