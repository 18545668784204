define('jason-frontend/helpers/is-bankadvice-available', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isBankadviceAvailable = isBankadviceAvailable;

  function isBankadviceAvailable(params) {
    var invoice = params[0];
    if (invoice && invoice.get('entries') && invoice.get('customer')) {
      return invoice.get('entries').length > 0 && invoice.get('customer').get('id') > 0;
    }
    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(isBankadviceAvailable);

});