define('jason-frontend/controllers/transactions/show', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Beleg anzeigen",
    breadCrumbPath: "transactions.show",
    actions: {
      openEmailPanelTest: function openEmailPanelTest(invoiceId) {
        var self = this,
            customerId = this.get('model').get('customerId');

        if (customerId && customerId > 0) {

          $.ajax({
            url: "/api/customers/" + customerId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.get('forwardTransactionPanel').send('load', invoiceId, data.customer.email, true, customerId);
          }, function () {});
        } else {
          self.get('forwardTransactionPanel').send('load', invoiceId, '', false, 0);
        }

        $.ajax({
          url: "/api/textModules?filterType=email&per_page=50",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('forwardTransactionPanel').send('loadTextmodules', data.textModule);
        }, function () {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-email-invoice-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    }
  });

});