define('jason-frontend/templates/cash/removal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 8
            },
            "end": {
              "line": 44,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/cash/removal.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"type","submit");
          dom.setAttribute(el1,"class","icon-button icon-button--success");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","button-next",[],["size","52","color","#ffffff"],["loc",[null,[43,75],[43,116]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 6
            },
            "end": {
              "line": 72,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/cash/removal.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(fragment, [3]);
          var element2 = dom.childAt(fragment, [5]);
          var morphs = new Array(6);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          morphs[2] = dom.createElementMorph(element1);
          morphs[3] = dom.createMorphAt(element1,1,1);
          morphs[4] = dom.createElementMorph(element2);
          morphs[5] = dom.createMorphAt(element2,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showSlip"],[],["loc",[null,[63,13],[63,34]]]],
          ["inline","button-view",[],["color","#ffffff","size","52","content","Bon anzeigen","showStroke",false],["loc",[null,[64,12],[64,93]]]],
          ["element","action",["download",["get","transactionDownloadUrl",["loc",[null,[66,33],[66,55]]]]],[],["loc",[null,[66,13],[66,57]]]],
          ["inline","button-download",[],["color","#ffffff","size","52","content","Bon herunterladen"],["loc",[null,[67,12],[67,85]]]],
          ["element","action",["print",["get","transactionGroup",["loc",[null,[69,30],[69,46]]]]],[],["loc",[null,[69,13],[69,48]]]],
          ["inline","button-print",[],["color","#ffffff","size","52","content","Bon Druck"],["loc",[null,[70,12],[70,74]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 121,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/cash/removal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("form");
        dom.setAttribute(el1,"id","cashExternalForm");
        dom.setAttribute(el1,"method","post");
        dom.setAttribute(el1,"class","");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("section");
        var el3 = dom.createTextNode("\n   ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","box");
        var el4 = dom.createTextNode("\n     ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","u-mb0");
        var el5 = dom.createTextNode("Auszahlung erfassen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n   ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","box col l-12/24@tablet");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","field field--payment");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Betrag");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","box col l-12/24@tablet");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","field");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","cashRegister");
        dom.setAttribute(el5,"class","select--inline input-element");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","title");
        var el7 = dom.createTextNode("Kassa");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","field field--big");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","textarea textarea--inline clearfix");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","title");
        var el7 = dom.createTextNode("Auszahlungsgrund");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","textarea__body");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","action-icons");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","box");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        dom.setAttribute(el3,"class","");
        var el4 = dom.createTextNode("Transaktion erfolgreich durchgeführt");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-24/24 box");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-24/24 box");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","action-icons");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function () {\n        jQuery.validator.addMethod(\"mynumber\", function (value, element) {\n            return this.optional(element) || /^\\d+([,][0-9]{1,2})?$/g.test(value);\n        }, \"Bitte einen gültigen Betrag eingeben\");\n\n        jQuery.validator.addMethod(\"notZero\", function (value, element) {\n            var valueSafe = value.length ? value.replace(',', '.') : 0;\n            return this.optional(element) || valueSafe > 0;\n        }, \"Bitte einen Betrag > 0,01 Euro eingeben\");\n        $(\"#cashExternalForm\").validate({\n            errorClass: \"state-error\",\n            validClass: \"state-success\",\n            errorElement: \"em\",\n            rules: {\n                'amount': {\n                    required: true,\n                    mynumber: true,\n                    notZero: true\n                },\n                'documentDate': {\n                    required: true\n                },\n                'reason': {\n                    required: true\n                }\n            },\n            highlight: function (element, errorClass, validClass) {\n                $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n            },\n            unhighlight: function (element, errorClass, validClass) {\n                $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n            },\n            errorPlacement: function (error, element) {\n                if (element.is(\":radio\") || element.is(\":checkbox\")) {\n                    element.closest('.option-group').after(error);\n                } else {\n                    error.insertAfter(element.parent());\n                }\n            }\n        });\n    });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [5]);
        var element6 = dom.childAt(fragment, [2]);
        var morphs = new Array(11);
        morphs[0] = dom.createElementMorph(element3);
        morphs[1] = dom.createAttrMorph(element4, 'class');
        morphs[2] = dom.createMorphAt(dom.childAt(element4, [3, 1]),3,3);
        morphs[3] = dom.createMorphAt(dom.childAt(element5, [1, 1]),3,3);
        morphs[4] = dom.createMorphAt(dom.childAt(element5, [3, 1, 3]),1,1);
        morphs[5] = dom.createMorphAt(dom.childAt(element4, [7]),1,1);
        morphs[6] = dom.createAttrMorph(element6, 'class');
        morphs[7] = dom.createMorphAt(dom.childAt(element6, [3]),1,1);
        morphs[8] = dom.createMorphAt(dom.childAt(element6, [5]),1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element6, [7]),1,1);
        morphs[10] = dom.createMorphAt(fragment,6,6,contextualElement);
        return morphs;
      },
      statements: [
        ["element","action",["doPayment"],["on","submit"],["loc",[null,[2,15],[2,49]]]],
        ["attribute","class",["concat",["site-content card ",["get","styleStepPayment",["loc",[null,[3,38],[3,54]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","amount",["loc",[null,[12,22],[12,28]]]]],[],[]],"class","input input--editable","name","amount","placeholder","Betrag"],["loc",[null,[12,8],[12,95]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","cashRegisterList",["loc",[null,[23,20],[23,36]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedCashRegister",["loc",[null,[24,18],[24,38]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","allowClear",false],["loc",[null,[22,12],[28,14]]]],
        ["inline","textarea",[],["rows","3","value",["subexpr","@mut",[["get","reason",["loc",[null,[36,38],[36,44]]]]],[],[]],"class","input textarea__input","name","reason","placeholder","Geben Sie den Grund für die Auszahlung an."],["loc",[null,[36,12],[36,147]]]],
        ["block","if",[["get","submitButtonEnabled",["loc",[null,[42,14],[42,33]]]]],[],0,null,["loc",[null,[42,8],[44,15]]]],
        ["attribute","class",["concat",["site-content card invoice ",["get","styleStepConfirmation",["loc",[null,[49,44],[49,65]]]]]]],
        ["inline","transaction-group-confirm",[],["transactionGroup",["subexpr","@mut",[["get","transactionGroup",["loc",[null,[55,51],[55,67]]]]],[],[]],"showLoading",["subexpr","@mut",[["get","showLoading",["loc",[null,[55,80],[55,91]]]]],[],[]]],["loc",[null,[55,6],[55,93]]]],
        ["inline","pdf-preview",[],["url",["subexpr","@mut",[["get","transactionDownloadUrl",["loc",[null,[58,20],[58,42]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","pdfPreview",["loc",[null,[58,58],[58,68]]]]],[],[]]],["loc",[null,[58,2],[58,70]]]],
        ["block","if",[["get","transactionGroup.cashdeskProcessed",["loc",[null,[62,12],[62,46]]]]],[],1,null,["loc",[null,[62,6],[72,13]]]],
        ["content","js-datepicker-script",["loc",[null,[120,0],[120,24]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});