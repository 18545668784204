define('jason-frontend/helpers/is-cat-selected', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isCatSelected = isCatSelected;

  function isCatSelected(params /*, hash*/) {
    var selectedList = params[0],
        categoryId = params[1],
        returnValue = false;

    if (selectedList) {
      $.each(selectedList, function (index, value) {
        if (value.id === categoryId) {
          returnValue = true;
          return;
        }
      });
    }
    return returnValue;
  }

  exports['default'] = Ember['default'].Helper.helper(isCatSelected);

});