define('jason-frontend/controllers/inventory/checks', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Arzneimittelkontrollen",
    breadCrumbPath: "inventory.checks",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    showFilters: true,
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),
    page: 1,
    showLoading: false,
    perPage: 20,
    filterStatus: 'created,used',
    myStickyOptionsTableHead: {
      topSpacing: 160,
      zIndex: 9
    },
    actions: {}
  });

});