define('jason-frontend/controllers/sale/products', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Produkte",
    breadCrumbPath: "sale.products",
    queryParams: ["page", "perPage", "filterName", "filterSupplier", "sortBy", "sortDir"],
    filterName: null,
    filterSupplier: 'all',
    filterManufacturer: null,
    filterDistributor: null,
    filterFavorites: false,
    sortBy: null,
    sortDir: null,
    categoryFilterClass: 'hidden',
    selectedCategoryFilters: null,
    filterAll: !API['default'].isPreselectFavorites(),
    filterFavs: API['default'].isPreselectFavorites(),
    page: 1,
    perPage: 10,
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    showFilters: true,
    myStickyOptionsTableHead: {
      topSpacing: 160,
      zIndex: 9
    },
    watchDistributor: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterDistributor'),
    watchFilterName: (function () {
      this.set('page', 1);
    }).observes('filterName'),
    watchCategory: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('selectedCategoryFilters'),
    watchFavs: (function () {
      if (this.get('filterFavs')) {
        this.set('filterAll', false);
      } else {
        this.set('filterAll', true);
      }
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterFavs'),
    watchfilterAll: (function () {
      if (this.get('filterAll')) {
        this.set('filterFavs', false);
      } else {
        this.set('filterFavs', true);
      }
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterAll'),
    watchSupplier: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterSupplier'),
    watchManufacturer: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterManufacturer')
  });

});