define('jason-frontend/routes/purchases/detail', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  var modelHookRun;

  exports['default'] = Ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      modelHookRun = false;
    },
    model: function model(params) {
      return this.store.find('purchaseProduct', params.id);
    },
    afterModel: function afterModel(model) {
      if (!modelHookRun && API['default'].isSupplier()) {
        return model.reload();
      }
      return model;
    },
    refreshModel: function refreshModel() {
      var controller = this.get('controller');
      var deferred = $.ajax({
        url: "/api/purchaseProducts/" + controller.get('productId'),
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('model', data.purchaseProduct);
      }, function (error) {});
    },
    selectTab: function selectTab(id) {

      this.get('controller').set('selectTab', id);

      if (id === 'history') {
        var self = this;
        $.ajax({
          url: "/api/purchaseProducts/" + this.get('controller').get('model').id + "/latestOrders",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').set('history', data.latestPurchaseOrder);
        });
      }
      if (id === 'inventory') {

        var self = this;
        $.ajax({
          url: "/api/inventoryStocks/product/" + this.get('controller').get('model').id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').set('stocks', data.inventoryStock);
        }, function () {});
      }
    },
    actions: {
      printQr: function printQr(id) {
        window.open('/api/products/' + id + '/qr');
      },
      toProduct: function toProduct(id, orderId) {
        this.transitionTo('purchases.detail', id, orderId);
      },
      deleteCartEntry: function deleteCartEntry(id, orderId) {
        var self = this;
        var deferred = $.ajax({
          url: "/api/purchaseOrders/" + orderId + "/changeQuantity/" + id + "/0",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.set("order", self.store.fetch('purchaseOrder', orderId));
        }, function (error) {
          self.set("order", self.store.fetch('purchaseOrder', orderId));
        });
      },
      selectSellingUnit: function selectSellingUnit(all, item) {
        all.forEach(function (supplier) {
          supplier.entries.forEach(function (value) {
            Ember['default'].set(value, 'selected', false);
          });
        });
        Ember['default'].set(item, 'selected', true);
      },
      toggleSidebar: function toggleSidebar() {
        var controller = this.get('controller');
        if (controller.get('sidebarActive') === true) {
          controller.set('sidebarActive', false);
        } else {
          controller.set('sidebarActive', true);
          setTimeout(function () {
            $('aside.side-bar .scrollable').css('max-height', $(window).height() - 100);
            $('aside.side-bar').css('min-height', $(window).height() - 100);
          }, 100);

          if ($(window).width() <= 767) {
            $("html, body").animate({ scrollTop: 0 }, 200);
          }
        }
      },
      toMandant: function toMandant() {
        this.transitionTo('mandants.edit', { queryParams: { selectTab: "purchases" } });
      },
      gotoSale: function gotoSale(id) {
        this.transitionTo('products.edit', id);
      },
      toggleFavorite: function toggleFavorite(product, add) {
        Ember['default'].set(product, 'favorite', add);
        var controller = this.get('controller');
        if (add) {
          $.ajax({
            url: "/api/purchaseProducts/" + product.id + "/addToFavs",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        } else {
          $.ajax({
            url: "/api/purchaseProducts/" + product.id + "/removeFromFavs",
            method: "DELETE",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        }
      },
      saveHistory: function saveHistory(product) {

        var data = {
          articleId: product.id,
          notice: product.get('historyNotice')
        };

        $.ajax({
          url: "/api/purchaseProducts/" + product.id + "/historyNotice",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        }, function (error) {});
      },
      selectTab: function selectTab(id) {
        this.selectTab(id);
      },
      gotoCart: function gotoCart(id) {
        this.transitionTo('purchases.edit', id, { queryParams: { referer: "product" } });
      },
      gotoCarts: function gotoCarts() {
        this.transitionTo('purchases.choose');
      },
      deletePriceRow: function deletePriceRow(id) {
        var self = this;
        $.ajax({
          url: "/api/purchaseProductSupplierAdminInfos/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
          self.refreshModel();
        }, function (error) {
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
          self.refreshModel();
        });
      },
      deleteArticle: function deleteArticle(id) {
        var self = this;
        $.ajax({
          url: "/api/purchaseProducts/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
          self.transitionTo('purchases.add_product', 0);
        });
      },
      setReorderActive: function setReorderActive(reorder) {
        if (reorder.active) {
          Ember['default'].set(reorder, "active", false);
        } else {
          Ember['default'].set(reorder, "active", true);
        }
      },
      saveReorder: function saveReorder(model) {
        var product = {};
        var self = this;

        product.reorderSettings = model.reorderSettings;
        product.id = model.id;
        product.orderNotice = model.orderNotice;
        product.autosendReorder = model.autosendReorder;

        $.ajax({
          url: "/api/purchaseProducts/reorderInfos",
          method: "POST",
          data: JSON.stringify(product),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          self.refresh();
        }, function (error) {});
      },
      saveProduct: function saveProduct(product) {
        var self = this;

        $.ajax({
          url: "/api/purchaseProducts",
          method: "POST",
          data: JSON.stringify(product),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          self.refreshModel();
        }, function (error) {});
      },
      addReorder: function addReorder(product) {
        var allSettings = product.get('reorderSettings');

        var id = Math.floor(Math.random() * 100000 + 1);;

        var newLine = {};
        newLine.reorderMinimalAmount = 0;
        newLine.reorderSpinnerId = 'reorder' + id;
        newLine.infoMinimalAmount = 0;
        newLine.infoSpinnerId = 'info' + id;
        newLine.reorderAmount = 0;
        newLine.amountSpinnerId = 'amount' + id;

        allSettings.pushObject(newLine);
      },
      deleteReorderSetting: function deleteReorderSetting(reorder, productId) {
        Ember['default'].set(reorder, "deleted", true);
      },
      savePrice: function savePrice(price, productId) {
        var self = this,
            controller = this.get('controller');

        $.ajax({
          url: "/api/purchaseProductSupplierAdminInfos",
          method: "POST",
          data: JSON.stringify(price),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          $.magnificPopup.close();
          var deferred = $.ajax({
            url: "/api/purchaseProducts/" + controller.get('productId'),
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set('model', data.purchaseProduct);
          }, function (error) {});
        }, function (error) {
          jason.notifiction.error('Achtung', error.responseJSON.message);
        });
      }
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);

      if (API['default'].isSupplier()) {
        controller.set('sidebarActive', false);
      }

      var orderId = params.params['purchases.detail'].orderId,
          self = this;

      var deferred = $.ajax({
        url: "/api/purchaseProducts/" + params.params['purchases.detail'].id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('model', data.purchaseProduct);
      }, function (error) {});

      if (orderId > 0) {
        controller.set('order', this.store.find('purchaseOrder', orderId));
      }
      controller.set('orderId', orderId);
      controller.set('productId', params.params['purchases.detail'].id);

      controller.set('warehouses', this.store.find('inventory-warehouse'));

      this.store.find('serviceunit', { paged: false }).then(function (items) {
        controller.set('allUnits', items.sortBy('name'));
      });

      this.store.find('serviceunit', { productId: model.id }).then(function (items) {
        controller.set('matchedUnits', items.sortBy('name'));
      });

      var mandant = this.store.find('mandant', API['default'].getMandant()).then(function (mandant) {
        controller.set('laboklinForm', self.store.createRecord('purchaseOrderLaboklinForm', {
          buyer: mandant.get('name') + ' ' + mandant.get('additionalName') + '\n' + mandant.get('operators') + '\n' + mandant.get('street') + '\n' + mandant.get('postalCode') + ' ' + mandant.get('town')
        }));
      });

      var selectedTab = params.queryParams.selectTab;
      if (selectedTab) {
        this.selectTab(selectedTab);
      } else {
        this.selectTab('purchase');
      }
    }
  });

});