define('jason-frontend/templates/expired', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 2
            },
            "end": {
              "line": 21,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/expired.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","innerWrapper");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("form");
          dom.setAttribute(el2,"class","card u-center login-form");
          dom.setAttribute(el2,"method","post");
          dom.setAttribute(el2,"id","registerForm");
          var el3 = dom.createTextNode("\n        Eine Bestätigungs-Email wurde gerade an Ihre E-Mail Adresse ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("b");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" verschickt. Bitte bestätigen Sie darin Ihre Passwortänderung.");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("Falls Sie kein Email erhalten überprüfen Sie bitte auch Ihren SPAM-Ordner.\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"href","#");
          dom.setAttribute(el3,"class","block u-text--center is-highlighted");
          var el4 = dom.createTextNode("zur Anmeldung zurückkehren");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1, 1]);
          var element3 = dom.childAt(element2, [9]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
          morphs[1] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [
          ["content","email",["loc",[null,[16,71],[16,80]]]],
          ["element","action",["toLogin"],[],["loc",[null,[18,11],[18,31]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 51,
                "column": 8
              },
              "end": {
                "line": 59,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/expired.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","pt0 box form-buttons");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","u-text--center col l-24/24");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"id","button-login");
            dom.setAttribute(el3,"type","submit");
            dom.setAttribute(el3,"class","button");
            var el4 = dom.createTextNode("Passwort ändern");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("br");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"href","#");
            dom.setAttribute(el1,"class","block u-text--center is-highlighted");
            var el2 = dom.createTextNode("zur Anmeldung zurückkehren");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [3]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [
            ["element","action",["toLogin"],[],["loc",[null,[58,13],[58,33]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 59,
                "column": 8
              },
              "end": {
                "line": 61,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/expired.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[60,10],[60,31]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 2
            },
            "end": {
              "line": 65,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/expired.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","innerWrapper");
          var el2 = dom.createTextNode("\n\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("form");
          dom.setAttribute(el2,"class","card u-center login-form");
          dom.setAttribute(el2,"method","post");
          dom.setAttribute(el2,"id","expiryForm");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          dom.setAttribute(el3,"class","ml20 mt20 is-highlighted mbm10");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","section box clear");
          var el4 = dom.createTextNode("\n          Ihr Passwort ist abgelaufen, bitte vergeben Sie ein neues:\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","section box");
          dom.setAttribute(el3,"id","fieldPassword");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field u-mb0");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","section box");
          dom.setAttribute(el3,"id","fieldPasswordConfirmaation");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field u-mb0");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","box");
          var el4 = dom.createTextNode("\n          Das neue Passwort muss die folgenden Kriterien erfüllen:\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          var el6 = dom.createTextNode("Mindestens 8 Zeichen lang");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          var el6 = dom.createTextNode("Mindestens 1 Klein- und Großbuchstabe");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          var el6 = dom.createTextNode("Mindestens 1 Zahl");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          var el6 = dom.createTextNode("Erlaubte Sonderzeichen: @ * _ - . ! #");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [5, 1]),1,1);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [7, 1]),1,1);
          morphs[3] = dom.createMorphAt(element1,11,11);
          return morphs;
        },
        statements: [
          ["element","action",["changeExpired"],["on","submit"],["loc",[null,[24,75],[24,113]]]],
          ["inline","input",[],["name","password","value",["subexpr","@mut",[["get","password",["loc",[null,[33,42],[33,50]]]]],[],[]],"type","password","id","password","class","input input--editable","placeholder","Neues Passwort"],["loc",[null,[33,12],[33,141]]]],
          ["inline","input",[],["name","passwordConfirmation","id","passwordConfirmation","type","password","value",["subexpr","@mut",[["get","passwordConfirmation",["loc",[null,[38,97],[38,117]]]]],[],[]],"class","input input--editable","placeholder","Neues Passwort wiederholen"],["loc",[null,[38,12],[38,190]]]],
          ["block","unless",[["get","inProgress",["loc",[null,[51,18],[51,28]]]]],[],0,1,["loc",[null,[51,8],[61,19]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 117,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/expired.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n  html, body {\n    height: 100%;\n  }\n  html, body > .ember-view {\n    height: 100%;\n  }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","register");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"id","impressum");
        dom.setAttribute(el2,"class","impressum-hide");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","impressum-text");
        dom.setAttribute(el3,"href","https://www.vetnative.com");
        var el4 = dom.createTextNode("© 2024 VetNative Digital GmbH | ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","impressum-text");
        dom.setAttribute(el3,"href","https://www.vetnative.com/impressum/");
        var el4 = dom.createTextNode("Impressum");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $(\"#expiryForm\").validate({\n      errorClass: \"state-error\",\n      validClass: \"state-success\",\n      errorElement: \"em\",\n      rules: {\n        'password': {\n          required: true,\n          pwcheck: true,\n        },\n        'passwordConfirmation': {\n          equalTo: '#password'\n        },\n      },\n      messages: {\n        password: {\n          pwcheck: \"Das Passwort entspricht nicht den Kriterien!\"\n        }\n      },\n      highlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n      },\n      unhighlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n      },\n      errorPlacement: function(error, element) {\n        if (element.is(\":radio\") || element.is(\":checkbox\")) {\n          element.closest('.option-group').after(error);\n        } else {\n          error.insertAfter(element.parent());\n        }\n      }\n    });\n\n    $.validator.addMethod(\"pwcheck\",\n      function(value, element) {\n        return /^[A-Za-z0-9\\d=!\\-@._*#]*$/.test(value) // consists of only these\n          && /[a-z]/.test(value) // has a lowercase letter\n          && /[A-Z]/.test(value) // has a lowercase letter\n          && /\\d/.test(value) // has a digit\n          && value.length >= 8  // at least 8\n      });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
        return morphs;
      },
      statements: [
        ["block","if",[["get","showConfirm",["loc",[null,[12,8],[12,19]]]]],[],0,1,["loc",[null,[12,2],[65,9]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});