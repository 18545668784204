define('jason-frontend/templates/components/simple-number-spinner2', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/simple-number-spinner2.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"class","quantityspinn btn minus");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el2 = dom.createElement("svg");
        dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el2,"width","15");
        dom.setAttribute(el2,"height","20");
        dom.setAttribute(el2,"viewBox","0 0 34 34");
        dom.setAttribute(el2,"class","cursor");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"class","spinner-icon");
        dom.setAttribute(el3,"transform","translate(-35,-40) scale(0.5,0.5)");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M77.955,109.975c-4.037,0-7.309,3.271-7.309,7.308c0,4.037,3.272,7.31,7.309,7.31h56.029c4.037,0,7.309-3.271,7.309-7.31    c0-4.036-3.271-7.308-7.309-7.308H77.955z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        dom.setNamespace(null);
        var el1 = dom.createElement("span");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"class","quantityspinn btn plus");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el2 = dom.createElement("svg");
        dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el2,"xmlns:xlink","http://www.w3.org/1999/xlink");
        dom.setAttribute(el2,"width","15");
        dom.setAttribute(el2,"height","20");
        dom.setAttribute(el2,"viewBox","0 0 34 34");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"class","spinner-icon");
        dom.setAttribute(el3,"transform","translate(0,-2) scale(0.5,0.5)");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M0,44.2c0,4.037,3.272,7.308,7.309,7.308h19.489v19.49c0,4.036,3.271,7.309,7.308,7.309c4.037,0,7.309-3.271,7.309-7.309    v-19.49H63.34c4.037,0,7.308-3.271,7.308-7.308c0-4.037-3.271-7.309-7.308-7.309H41.414V12.53c0-4.037-3.272-7.308-7.309-7.308    c-4.037,0-7.308,3.271-7.308,7.308v24.361H7.308C3.272,36.892,0,40.164,0,44.2z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(fragment, [4]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element0, 'data-qty-entry-id');
        morphs[1] = dom.createElementMorph(element0);
        morphs[2] = dom.createAttrMorph(element1, 'data-qty-entry-id');
        morphs[3] = dom.createAttrMorph(element1, 'class');
        morphs[4] = dom.createMorphAt(element1,1,1);
        morphs[5] = dom.createAttrMorph(element2, 'data-qty-entry-id');
        morphs[6] = dom.createElementMorph(element2);
        return morphs;
      },
      statements: [
        ["attribute","data-qty-entry-id",["concat",[["get","internalid",["loc",[null,[2,24],[2,34]]]]]]],
        ["element","action",["decreaseQuantitySpinner",["get","internalid",["loc",[null,[2,73],[2,83]]]]],[],["loc",[null,[2,38],[2,85]]]],
        ["attribute","data-qty-entry-id",["concat",[["get","internalid",["loc",[null,[9,27],[9,37]]]]]]],
        ["attribute","class",["concat",["spinn_",["get","internalid",["loc",[null,[9,56],[9,66]]]]]]],
        ["inline","input",[],["class","form-control ui-spinner-input","name","spinner","value",["subexpr","@mut",[["get","value",["loc",[null,[10,69],[10,74]]]]],[],[]]],["loc",[null,[10,2],[10,76]]]],
        ["attribute","data-qty-entry-id",["concat",[["get","internalid",["loc",[null,[13,24],[13,34]]]]]]],
        ["element","action",["increaseQuantitySpinner",["get","internalid",["loc",[null,[13,73],[13,83]]]]],[],["loc",[null,[13,38],[13,85]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});