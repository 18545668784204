define('jason-frontend/routes/services/create', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      var model = this.store.createRecord('service');
      model.set('priceExVat', 0);

      return model;
    },
    actions: {
      create: function create() {
        var self = this;
        var model = this.controller.get('model');

        model.set('priceExVat', new String(model.get('priceExVat')).replace(",", "."));
        model.save().then(function () {
          self.transitionTo('services.index');
        });
      }
    },
    setupController: function setupController(controller, model) {

      this._super(controller, model);

      this.store.find('serviceunit', { paged: false }).then(function (items) {
        controller.set('allUnits', items.sortBy('name'));
        items.forEach(function (item) {
          if (item.get('name') === 'Leistung') {
            model.set('unit', item);
          }
        });
      });

      model.set('factor', 100);
      model.set('gotUse', true);
      model.set('gotHorse', true);
      model.set('gotSmall', true);
      model.set('tags', []);
      model.tags = [];

      this.store.find('tax', { paged: false, onlyValid: true }).then(function (data) {
        var taxSet = false;
        data.forEach(function (item) {
          if (!taxSet) {
            model.set('tax', item);
          }
          taxSet = true;
        });
        controller.set('taxes', data);
      });

      controller.set('labProfiles', this.store.find('labProfile'));

      $.ajax({
        url: "/api/serviceCategories?suggest=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('categories', data.serviceCategory);
      });

      controller.set('patientCategories', this.store.find('patientCategory'));

      this.store.find('productDistributor', { onlyMy: true }).then(function (items) {
        controller.set('distributors', items.sortBy('name'));
        items.forEach(function (item) {
          if (item.get('id') === 'own') {
            model.set('distributor', item);
          }
        });
      });

      this.store.find('article-tag', { type: 'service' }).then(function (items) {
        controller.set('articleTags', items);
      });
    }
  });

});