define('jason-frontend/routes/sale/compositeproducts', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          selectedCategoryFilters = controller.get('selectedCategoryFilters'),
          filterSupplier = controller.get('filterSupplier'),
          filterManufacturer = controller.get('filterManufacturer'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy'),
          filterFavorites = controller.get('filterFavs'),
          manufacturerId = null,
          filterId = null;

      if (filterManufacturer) {
        manufacturerId = filterManufacturer.id;
      }

      if (filterSupplier === 'all') {
        filterSupplier = null;
      }

      if (selectedCategoryFilters) {
        filterId = selectedCategoryFilters.id;
      }

      this.findPaged('compositeArticle', {
        filterName: filterName,
        selectedCategoryFilters: filterId,
        filterSupplier: filterSupplier,
        filterManufacturer: manufacturerId,
        filterFavorites: filterFavorites,
        filterMandant: "all",
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    toggleFavoriteIntern: function toggleFavoriteIntern(id, add) {
      var controller = this.get('controller');

      if (add) {
        $.ajax({
          url: "/api/compositeArticles/" + id + "/addToFavs",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {}, function (error) {});
      } else {
        $.ajax({
          url: "/api/compositeArticles/" + id + "/removeFromFavs",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {}, function (error) {});
      }
    },
    actions: {
      'delete': function _delete(id) {
        this.get('store').find('compositeArticle', id).then(function (rec) {
          rec.destroyRecord();
        });
      },
      toggleFavorite: function toggleFavorite(product, add) {
        Ember['default'].set(product, 'favorite', add);
        this.toggleFavoriteIntern(product.id, add);
      },
      copy: function copy(product) {
        var self = this;
        $.ajax({
          url: "/api/compositeArticles/copy/" + product,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Paket kopiert', '');
          self.applyFilter();
        }, function () {
          jason.notifiction.systemError('Fehlercode 102', 'Paket konnte nicht kopiert werden');
        });
      },
      filterFavs: function filterFavs() {
        if (this.controller.get('filterFavs')) {
          this.controller.set('filterFavs', false);
        } else {
          this.controller.set('filterFavs', true);
        }
        this.applyFilter();
      },
      queryManufacturer: function queryManufacturer(query, deferred) {
        this.store.find('productManufacturer', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      setSort: function setSort(attr) {
        var controller = this.get('controller');
        controller.set('sortBy', attr);
        if (controller.get('sortDir') === 'asc') {
          controller.set('sortDir', 'desc');
        } else {
          controller.set('sortDir', 'asc');
        }
        this.applyFilter();
      }
    },
    setupController: function setupController(controller, model) {

      controller.set('filterFavorites', API['default'].isPreselectFavorites());
      controller.set('model', model);

      controller.set('suppliers', this.store.find('product-supplier'));

      this.store.unloadAll('productSupplier');

      this.store.find('productSupplier').then(function (items) {
        controller.set('suppliers', items.sortBy('name'));
      });

      this.applyFilter();
    }
  });

});