define('jason-frontend/helpers/invert-number', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.invertNumber = invertNumber;

  function invertNumber(params) {
    var number = params[0];
    return number * -1;
  }

  exports['default'] = Ember['default'].Helper.helper(invertNumber);

});