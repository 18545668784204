define('jason-frontend/components/show-task-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    type: null,
    selectTab: 'show',
    item: null,
    actions: {
      goto: function goto(to) {
        this.sendAction('goto', to, this.get('item'));
        $.magnificPopup.close();
      },
      selectTab: function selectTab(selected) {
        var controller = this.get('controller'),
            self = this;
        controller.set('selectTab', selected);

        if (selected === 'chat') {
          try {
            if (this.get('item').get('message')) {
              this.set('showLoading', true);
              Ember['default'].run.later(this, function () {

                $.ajax({
                  url: "/api/msgs/" + this.get('item').get('message').id,
                  method: "GET",
                  contentType: "application/json",
                  dataType: "json",
                  headers: {
                    'X-AUTH-TOKEN': API['default'].getToken()
                  }
                }).then(function (data) {
                  self.set('showLoading', false);
                  self.set('message', data.msg);
                });
              }, 500);
            }
          } catch (err) {
            if (this.get('item').message) {
              Ember['default'].run.later(this, function () {
                this.set('showLoading', true);

                $.ajax({
                  url: "/api/msgs/" + this.get('item').message.id,
                  method: "GET",
                  contentType: "application/json",
                  dataType: "json",
                  headers: {
                    'X-AUTH-TOKEN': API['default'].getToken()
                  }
                }).then(function (data) {
                  self.set('showLoading', false);
                  self.set('message', data.msg);
                });
              }, 500);
            }
          }
        }
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      toCustomer: function toCustomer(id) {
        this.sendAction('linkTo', 'customers.edit', id);
        $.magnificPopup.close();
      },
      toPatient: function toPatient(id) {
        this.sendAction('linkTo', 'patients.edit', id);
        $.magnificPopup.close();
      },
      load: function load(item) {

        this.set('item', item);
        this.set('message', null);
        this.set('history', null);
        this.set('selectTab', 'show');
        this.set('comment', null);
      },
      loadHistory: function loadHistory(item) {
        this.set('history', item);
      },
      finishTask: function finishTask(item) {
        $.magnificPopup.close();
        var self = this;
        setTimeout(function () {
          self.sendAction('finishTask', item);
        }, 500);
      },
      startTask: function startTask(item) {
        $.magnificPopup.close();
        var self = this;
        setTimeout(function () {
          self.sendAction('startTask', item);
        }, 500);
      },
      postReply: function postReply(item) {

        var messageId;

        try {
          messageId = this.get('item').get('message').id;
        } catch (err) {
          messageId = this.get('item').message.id;
        }

        var data = {
          comment: this.get('comment'),
          taskId: this.get('item').id,
          messageId: messageId
        };

        var self = this;

        self.sendAction('addReply', data);

        setTimeout(function () {

          $.ajax({
            url: "/api/msgs/" + messageId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.set('message', data.msg);
            self.set('comment', null);
          });
        }, 500);
      },
      addMessage: function addMessage(item) {

        var userIds = new Array();

        try {
          if (item.get('user')) {
            userIds.push(item.get('user').id);
          }
        } catch (err) {
          if (item.user) {
            userIds.push(item.user.id);
          }
        }

        try {
          if (item.get('delegatedBy')) {
            userIds.push(item.get('delegatedBy').id);
          }
        } catch (err) {
          if (item.delegatedBy) {
            userIds.push(item.delegatedBy.id);
          }
        }

        var title;
        try {
          title = item.get('title');
        } catch (err) {
          title = item.title;
        }

        var messageData = {
          title: 'Aufgabe: ' + title,
          message: this.get('comment'),
          receivers: userIds,
          originalMsgId: this.get('originalMsgId'),
          relatedTaskId: item.id
        };

        $.magnificPopup.close();
        this.sendAction('create', messageData);
      },
      changeOwner: function changeOwner(item) {
        $.magnificPopup.close();
        var self = this;
        setTimeout(function () {
          self.sendAction('changeOwner', item);
        }, 500);
      }
    }
  });

});