define('jason-frontend/components/send-testmail-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    type: 'warning',
    actions: {
      forward: function forward() {
        if (!this.get('email')) {
          jason.notifiction.error('Bitte geben Sie eine gültige Email-Adresse an', '');
          return;
        }
        this.sendAction('forwarded', this.get('email'), this.get('type'));
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      init: function init(type) {
        this.set('type', type);

        $('#testemail').focus();
      }
    }
  });

});