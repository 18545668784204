define('jason-frontend/models/customer-logbook-entry', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    user: DS['default'].belongsTo('user'),
    created: DS['default'].attr('date'),
    text: DS['default'].attr('string'),
    category: DS['default'].attr('string'),
    referenceType: DS['default'].attr('string'),
    referenceId: DS['default'].attr('number'),
    referenceCode: DS['default'].attr('string'),
    subCategory: DS['default'].attr('string')
  });

});