define('jason-frontend/templates/practicemgmt/admission', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 25,
                    "column": 12
                  },
                  "end": {
                    "line": 30,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","symbol-label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element53 = dom.childAt(fragment, [1]);
                var morphs = new Array(5);
                morphs[0] = dom.createAttrMorph(element53, 'title');
                morphs[1] = dom.createAttrMorph(element53, 'class');
                morphs[2] = dom.createAttrMorph(element53, 'style');
                morphs[3] = dom.createElementMorph(element53);
                morphs[4] = dom.createMorphAt(dom.childAt(element53, [1]),0,0);
                return morphs;
              },
              statements: [
                ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[26,28],[26,42]]]]]]],
                ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[26,121],[26,134]]]],"selected",""],[],["loc",[null,[26,100],[26,150]]]]," symbol symbol-circle symbol-40px"]]],
                ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[26,212],[26,222]]]]," !important;"]]],
                ["element","action",["toggleResource",["get","user",["loc",[null,[26,72],[26,76]]]]],[],["loc",[null,[26,46],[26,78]]]],
                ["content","user.capitals",["loc",[null,[27,42],[27,59]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 30,
                    "column": 12
                  },
                  "end": {
                    "line": 35,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","symbol-label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element52 = dom.childAt(fragment, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element52, 'title');
                morphs[1] = dom.createAttrMorph(element52, 'class');
                morphs[2] = dom.createElementMorph(element52);
                morphs[3] = dom.createMorphAt(dom.childAt(element52, [1]),0,0);
                return morphs;
              },
              statements: [
                ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[31,28],[31,42]]]]]]],
                ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[31,121],[31,134]]]],"selected",""],[],["loc",[null,[31,100],[31,150]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[31,186],[31,196]]]]]]],
                ["element","action",["toggleResource",["get","user",["loc",[null,[31,72],[31,76]]]]],[],["loc",[null,[31,46],[31,78]]]],
                ["content","user.capitals",["loc",[null,[32,42],[32,59]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 10
                },
                "end": {
                  "line": 36,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","user.colorHex",["loc",[null,[25,18],[25,31]]]]],[],0,1,["loc",[null,[25,12],[35,19]]]]
            ],
            locals: ["user"],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 4
              },
              "end": {
                "line": 39,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","quick-filter mb10 mtm10 col l-24/24");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","title");
            var el3 = dom.createTextNode("Mitarbeiter:innen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","symbol-group symbol-hover flex-shrink-0 me-2");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","users",["loc",[null,[24,18],[24,23]]]]],[],0,null,["loc",[null,[24,10],[36,19]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 61,
                    "column": 24
                  },
                  "end": {
                    "line": 62,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[62,64],[62,91]]]],
                ["content","item.customer.lastname",["loc",[null,[62,92],[62,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 65,
                      "column": 24
                    },
                    "end": {
                      "line": 65,
                      "column": 256
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1,"class","animal-icon");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[65,115],[65,146]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[65,155],[65,191]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[65,200],[65,233]]]]],[],[]],"classNames","mr5"],["loc",[null,[65,83],[65,252]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 66,
                      "column": 22
                    },
                    "end": {
                      "line": 66,
                      "column": 149
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[66,92],[66,116]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[66,125],[66,147]]]]],[],[]]],["loc",[null,[66,73],[66,149]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 67,
                      "column": 22
                    },
                    "end": {
                      "line": 67,
                      "column": 190
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[67,61],[67,190]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child3 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 73,
                          "column": 28
                        },
                        "end": {
                          "line": 78,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-label");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element40 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element40, 'title');
                      morphs[1] = dom.createAttrMorph(element40, 'style');
                      morphs[2] = dom.createMorphAt(dom.childAt(element40, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[74,44],[74,72]]]]]]],
                      ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[74,162],[74,186]]]]," !important;"]]],
                      ["content","patientInfo.doctor.capitals",["loc",[null,[75,60],[75,91]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 78,
                          "column": 28
                        },
                        "end": {
                          "line": 83,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-label");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element39 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element39, 'title');
                      morphs[1] = dom.createAttrMorph(element39, 'class');
                      morphs[2] = dom.createMorphAt(dom.childAt(element39, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[79,44],[79,72]]]]]]],
                      ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[79,122],[79,149]]]],"selected",""],[],["loc",[null,[79,101],[79,165]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[79,201],[79,225]]]]]]],
                      ["content","patientInfo.doctor.capitals",["loc",[null,[80,60],[80,91]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 72,
                        "column": 26
                      },
                      "end": {
                        "line": 84,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[73,34],[73,61]]]]],[],0,1,["loc",[null,[73,28],[83,35]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 86,
                          "column": 28
                        },
                        "end": {
                          "line": 91,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                      var el2 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-label");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element38 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element38, 'title');
                      morphs[1] = dom.createAttrMorph(element38, 'style');
                      morphs[2] = dom.createMorphAt(dom.childAt(element38, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[87,44],[87,75]]]]]]],
                      ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[87,165],[87,192]]]]," !important;"]]],
                      ["content","patientInfo.assistant.capitals",["loc",[null,[88,62],[88,96]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 91,
                          "column": 28
                        },
                        "end": {
                          "line": 96,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      var el2 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-label");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element37 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element37, 'title');
                      morphs[1] = dom.createAttrMorph(element37, 'class');
                      morphs[2] = dom.createMorphAt(dom.childAt(element37, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[92,44],[92,75]]]]]]],
                      ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[92,125],[92,155]]]],"selected",""],[],["loc",[null,[92,104],[92,171]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[92,207],[92,234]]]]]]],
                      ["content","patientInfo.assistant.capitals",["loc",[null,[93,62],[93,96]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 85,
                        "column": 26
                      },
                      "end": {
                        "line": 97,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[86,34],[86,64]]]]],[],0,1,["loc",[null,[86,28],[96,35]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 70,
                      "column": 24
                    },
                    "end": {
                      "line": 98,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","patientInfo.doctor",["loc",[null,[72,32],[72,50]]]]],[],0,null,["loc",[null,[72,26],[84,33]]]],
                  ["block","if",[["get","patientInfo.assistant",["loc",[null,[85,32],[85,53]]]]],[],1,null,["loc",[null,[85,26],[97,33]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 63,
                    "column": 18
                  },
                  "end": {
                    "line": 105,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","flex-center mb5 mt5");
                dom.setAttribute(el1,"style","flex-direction: row");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","symbol-group");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","ember-view icon-button icon-button--success list-action-square mr0 ml10");
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element41 = dom.childAt(fragment, [1]);
                var element42 = dom.childAt(element41, [9]);
                var morphs = new Array(6);
                morphs[0] = dom.createMorphAt(element41,1,1);
                morphs[1] = dom.createMorphAt(element41,3,3);
                morphs[2] = dom.createMorphAt(element41,5,5);
                morphs[3] = dom.createMorphAt(dom.childAt(element41, [7]),1,1);
                morphs[4] = dom.createElementMorph(element42);
                morphs[5] = dom.createMorphAt(element42,1,1);
                return morphs;
              },
              statements: [
                ["block","if",[["get","patientInfo.patient.category",["loc",[null,[65,30],[65,58]]]]],[],0,null,["loc",[null,[65,24],[65,263]]]],
                ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[66,49],[66,71]]]]],[],1,null,["loc",[null,[66,22],[66,161]]]],
                ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[67,28],[67,59]]]]],[],2,null,["loc",[null,[67,22],[67,197]]]],
                ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[70,34],[70,55]]]],["get","patientInfo.doctor",["loc",[null,[70,56],[70,74]]]]],[],["loc",[null,[70,30],[70,75]]]]],[],3,null,["loc",[null,[70,24],[98,31]]]],
                ["element","action",["admissionPatient",["get","item.customer.id",["loc",[null,[101,133],[101,149]]]],["get","patientInfo.patient.id",["loc",[null,[101,150],[101,172]]]],["get","item.appointmentId",["loc",[null,[101,173],[101,191]]]]],[],["loc",[null,[101,105],[101,193]]]],
                ["inline","button-admission",[],["size","32","color","#fff","showStroke",false,"content","Check-In"],["loc",[null,[102,24],[102,103]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0, child1, child2, child3]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 54,
                  "column": 12
                },
                "end": {
                  "line": 108,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
              dom.setAttribute(el2,"data-label","Termin");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell  l-16/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element43 = dom.childAt(fragment, [1]);
              var element44 = dom.childAt(element43, [1]);
              var element45 = dom.childAt(element43, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element44, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element44, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element45, [1]),0,0);
              morphs[3] = dom.createMorphAt(element45,3,3);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[57,97],[57,109]]]],"DD. MM. YYYY"],[],["loc",[null,[57,83],[57,126]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[58,50],[58,62]]]],"HH:mm"],[],["loc",[null,[58,36],[58,72]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[61,52],[61,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[61,24],[62,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[63,26],[63,48]]]]],[],1,null,["loc",[null,[63,18],[105,27]]]]
            ],
            locals: ["item"],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 131,
                      "column": 22
                    },
                    "end": {
                      "line": 138,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","sub block mt5");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","sub");
                  dom.setAttribute(el2,"style","display: inline");
                  var el3 = dom.createTextNode("Termin: ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("label");
                  dom.setAttribute(el2,"for","time");
                  dom.setAttribute(el2,"class","input-element");
                  var el3 = dom.createTextNode("\n                             ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("input");
                  dom.setAttribute(el3,"style","font-size: 14px");
                  dom.setAttribute(el3,"autocomplete","false");
                  dom.setAttribute(el3,"type","text");
                  dom.setAttribute(el3,"placeholder","");
                  dom.setAttribute(el3,"class","input date-picker__input time time-small");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element33 = dom.childAt(fragment, [1, 3, 1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element33, 'id');
                  morphs[1] = dom.createAttrMorph(element33, 'value');
                  morphs[2] = dom.createAttrMorph(element33, 'onclick');
                  return morphs;
                },
                statements: [
                  ["attribute","id",["concat",["timeTile_",["get","item.id",["loc",[null,[135,108],[135,115]]]]]]],
                  ["attribute","value",["concat",[["get","item.time",["loc",[null,[135,143],[135,152]]]]]]],
                  ["attribute","onclick",["subexpr","action",["openTime",["get","item",["loc",[null,[135,233],[135,237]]]]],[],["loc",[null,[135,213],[135,239]]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 141,
                      "column": 28
                    },
                    "end": {
                      "line": 142,
                      "column": 118
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.customer.firstname",["loc",[null,[142,64],[142,91]]]],
                  ["content","item.customer.lastname",["loc",[null,[142,92],[142,118]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 146,
                          "column": 24
                        },
                        "end": {
                          "line": 150,
                          "column": 85
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1,"class","animal-icon");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[147,81],[147,112]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[148,71],[148,107]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[149,71],[149,104]]]]],[],[]],"classNames","mr5"],["loc",[null,[147,49],[150,81]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 151,
                          "column": 28
                        },
                        "end": {
                          "line": 152,
                          "column": 92
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[152,35],[152,59]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[152,68],[152,90]]]]],[],[]]],["loc",[null,[151,79],[152,92]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 153,
                          "column": 28
                        },
                        "end": {
                          "line": 155,
                          "column": 63
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[153,67],[155,63]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child3 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 160,
                              "column": 28
                            },
                            "end": {
                              "line": 165,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element30 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element30, 'title');
                          morphs[1] = dom.createAttrMorph(element30, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element30, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[161,44],[161,72]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[161,162],[161,186]]]]," !important;"]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[162,60],[162,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 165,
                              "column": 28
                            },
                            "end": {
                              "line": 170,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element29 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element29, 'title');
                          morphs[1] = dom.createAttrMorph(element29, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element29, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[166,44],[166,72]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[166,122],[166,149]]]],"selected",""],[],["loc",[null,[166,101],[166,165]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[166,201],[166,225]]]]]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[167,60],[167,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 159,
                            "column": 26
                          },
                          "end": {
                            "line": 171,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[160,34],[160,61]]]]],[],0,1,["loc",[null,[160,28],[170,35]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 173,
                              "column": 34
                            },
                            "end": {
                              "line": 178,
                              "column": 34
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                    ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element28 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element28, 'title');
                          morphs[1] = dom.createAttrMorph(element28, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element28, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[174,50],[174,81]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[174,171],[174,198]]]]," !important;"]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[175,62],[175,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 178,
                              "column": 34
                            },
                            "end": {
                              "line": 183,
                              "column": 34
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                    ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element27 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element27, 'title');
                          morphs[1] = dom.createAttrMorph(element27, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element27, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[179,50],[179,81]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[179,131],[179,161]]]],"selected",""],[],["loc",[null,[179,110],[179,177]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[179,213],[179,240]]]]]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[180,62],[180,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 172,
                            "column": 32
                          },
                          "end": {
                            "line": 184,
                            "column": 32
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[173,40],[173,70]]]]],[],0,1,["loc",[null,[173,34],[183,41]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 157,
                          "column": 28
                        },
                        "end": {
                          "line": 185,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.doctor",["loc",[null,[159,32],[159,50]]]]],[],0,null,["loc",[null,[159,26],[171,33]]]],
                      ["block","if",[["get","patientInfo.assistant",["loc",[null,[172,38],[172,59]]]]],[],1,null,["loc",[null,[172,32],[184,39]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 144,
                        "column": 24
                      },
                      "end": {
                        "line": 192,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","flex-center mb5 mt5");
                    dom.setAttribute(el1,"style","flex-direction: row");
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-group");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                          ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","ember-view icon-button icon-button--success list-action-square mr0 ml10");
                    var el3 = dom.createTextNode("\n                              ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                            ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element31 = dom.childAt(fragment, [1]);
                    var element32 = dom.childAt(element31, [9]);
                    var morphs = new Array(6);
                    morphs[0] = dom.createMorphAt(element31,1,1);
                    morphs[1] = dom.createMorphAt(element31,3,3);
                    morphs[2] = dom.createMorphAt(element31,5,5);
                    morphs[3] = dom.createMorphAt(dom.childAt(element31, [7]),1,1);
                    morphs[4] = dom.createElementMorph(element32);
                    morphs[5] = dom.createMorphAt(element32,1,1);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.patient.category",["loc",[null,[146,30],[146,58]]]]],[],0,null,["loc",[null,[146,24],[150,92]]]],
                    ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[151,55],[151,77]]]]],[],1,null,["loc",[null,[151,28],[152,104]]]],
                    ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[153,34],[153,65]]]]],[],2,null,["loc",[null,[153,28],[155,70]]]],
                    ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[157,38],[157,59]]]],["get","patientInfo.doctor",["loc",[null,[157,60],[157,78]]]]],[],["loc",[null,[157,34],[157,79]]]]],[],3,null,["loc",[null,[157,28],[185,35]]]],
                    ["element","action",["movePatientToTreatmentRoom",["get","patientInfo.id",["loc",[null,[188,149],[188,163]]]]],[],["loc",[null,[188,111],[188,165]]]],
                    ["inline","button-in-treatment",[],["size","32","color","#fff","showStroke",false,"content","Behandeln"],["loc",[null,[189,30],[189,113]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 143,
                      "column": 22
                    },
                    "end": {
                      "line": 193,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[144,59],[144,70]]]],["get","selectedUsers",["loc",[null,[144,71],[144,84]]]],["get","changeTrigger",["loc",[null,[144,85],[144,98]]]]],[],["loc",[null,[144,30],[144,99]]]]],[],0,null,["loc",[null,[144,24],[192,31]]]]
                ],
                locals: ["patientInfo"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 126,
                    "column": 14
                  },
                  "end": {
                    "line": 197,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
                dom.setAttribute(el2,"data-label","Check-In");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" Uhr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell  l-16/24");
                dom.setAttribute(el2,"data-label","Kund:in");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element34 = dom.childAt(fragment, [1]);
                var element35 = dom.childAt(element34, [1]);
                var element36 = dom.childAt(element34, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(dom.childAt(element35, [1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element35, [3]),0,0);
                morphs[2] = dom.createMorphAt(element35,5,5);
                morphs[3] = dom.createMorphAt(dom.childAt(element36, [1]),0,0);
                morphs[4] = dom.createMorphAt(element36,3,3);
                return morphs;
              },
              statements: [
                ["inline","time-format",[["get","item.created",["loc",[null,[129,101],[129,113]]]],"DD. MM. YYYY"],[],["loc",[null,[129,87],[129,130]]]],
                ["inline","time-format",[["get","item.created",["loc",[null,[130,54],[130,66]]]],"HH:mm"],[],["loc",[null,[130,40],[130,76]]]],
                ["block","if",[["get","item.time",["loc",[null,[131,28],[131,37]]]]],[],0,null,["loc",[null,[131,22],[138,29]]]],
                ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[141,56],[141,72]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[141,28],[142,130]]]],
                ["block","each",[["get","item.treatmentPatients",["loc",[null,[143,30],[143,52]]]]],[],2,null,["loc",[null,[143,22],[193,31]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 125,
                  "column": 12
                },
                "end": {
                  "line": 198,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[126,41],[126,45]]]],["get","selectedUsers",["loc",[null,[126,46],[126,59]]]],["get","changeTrigger",["loc",[null,[126,60],[126,73]]]]],[],["loc",[null,[126,20],[126,74]]]]],[],0,null,["loc",[null,[126,14],[197,21]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 221,
                      "column": 18
                    },
                    "end": {
                      "line": 228,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","sub block mt5");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","sub");
                  dom.setAttribute(el2,"style","display: inline");
                  var el3 = dom.createTextNode("Termin: ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("label");
                  dom.setAttribute(el2,"for","time");
                  dom.setAttribute(el2,"class","input-element");
                  var el3 = dom.createTextNode("\n                             ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("input");
                  dom.setAttribute(el3,"style","font-size: 14px");
                  dom.setAttribute(el3,"autocomplete","false");
                  dom.setAttribute(el3,"type","text");
                  dom.setAttribute(el3,"placeholder","");
                  dom.setAttribute(el3,"class","input date-picker__input time time-small");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element23 = dom.childAt(fragment, [1, 3, 1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element23, 'id');
                  morphs[1] = dom.createAttrMorph(element23, 'value');
                  morphs[2] = dom.createAttrMorph(element23, 'onclick');
                  return morphs;
                },
                statements: [
                  ["attribute","id",["concat",["timeTile_",["get","item.id",["loc",[null,[225,108],[225,115]]]]]]],
                  ["attribute","value",["concat",[["get","item.time",["loc",[null,[225,143],[225,152]]]]]]],
                  ["attribute","onclick",["subexpr","action",["openTime",["get","item",["loc",[null,[225,233],[225,237]]]]],[],["loc",[null,[225,213],[225,239]]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 231,
                      "column": 24
                    },
                    "end": {
                      "line": 232,
                      "column": 118
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.customer.firstname",["loc",[null,[232,64],[232,91]]]],
                  ["content","item.customer.lastname",["loc",[null,[232,92],[232,118]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 236,
                          "column": 24
                        },
                        "end": {
                          "line": 236,
                          "column": 256
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1,"class","animal-icon");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[236,115],[236,146]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[236,155],[236,191]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[236,200],[236,233]]]]],[],[]],"classNames","mr5"],["loc",[null,[236,83],[236,252]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 237,
                          "column": 24
                        },
                        "end": {
                          "line": 237,
                          "column": 151
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[237,94],[237,118]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[237,127],[237,149]]]]],[],[]]],["loc",[null,[237,75],[237,151]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 238,
                          "column": 24
                        },
                        "end": {
                          "line": 238,
                          "column": 192
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[238,63],[238,192]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child3 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 243,
                              "column": 30
                            },
                            "end": {
                              "line": 248,
                              "column": 44
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element21 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element21, 'title');
                          morphs[1] = dom.createAttrMorph(element21, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element21, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[244,46],[244,74]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[244,164],[244,188]]]]," !important;"]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[245,60],[245,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 248,
                              "column": 44
                            },
                            "end": {
                              "line": 253,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element20 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element20, 'title');
                          morphs[1] = dom.createAttrMorph(element20, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element20, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[249,60],[249,88]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[249,138],[249,165]]]],"selected",""],[],["loc",[null,[249,117],[249,181]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[249,217],[249,241]]]]]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[250,60],[250,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 242,
                            "column": 26
                          },
                          "end": {
                            "line": 254,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[243,36],[243,63]]]]],[],0,1,["loc",[null,[243,30],[253,37]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 256,
                              "column": 30
                            },
                            "end": {
                              "line": 261,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element19 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element19, 'title');
                          morphs[1] = dom.createAttrMorph(element19, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element19, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[257,46],[257,77]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[257,167],[257,194]]]]," !important;"]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[258,62],[258,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 261,
                              "column": 30
                            },
                            "end": {
                              "line": 266,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element18 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element18, 'title');
                          morphs[1] = dom.createAttrMorph(element18, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element18, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[262,46],[262,77]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[262,127],[262,157]]]],"selected",""],[],["loc",[null,[262,106],[262,173]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[262,209],[262,236]]]]]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[263,62],[263,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 255,
                            "column": 28
                          },
                          "end": {
                            "line": 267,
                            "column": 28
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[256,36],[256,66]]]]],[],0,1,["loc",[null,[256,30],[266,37]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 241,
                          "column": 24
                        },
                        "end": {
                          "line": 268,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.doctor",["loc",[null,[242,32],[242,50]]]]],[],0,null,["loc",[null,[242,26],[254,33]]]],
                      ["block","if",[["get","patientInfo.assistant",["loc",[null,[255,34],[255,55]]]]],[],1,null,["loc",[null,[255,28],[267,35]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                var child4 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 271,
                          "column": 24
                        },
                        "end": {
                          "line": 273,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-in-treatment",[],["size","32","color","#fff","showStroke",false,"content","Behandlung öffnen"],["loc",[null,[272,26],[272,117]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 234,
                        "column": 22
                      },
                      "end": {
                        "line": 275,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","flex-center mb5 mt5");
                    dom.setAttribute(el1,"style","flex-direction: row");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-group");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                          ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element22 = dom.childAt(fragment, [1]);
                    var morphs = new Array(5);
                    morphs[0] = dom.createMorphAt(element22,1,1);
                    morphs[1] = dom.createMorphAt(element22,3,3);
                    morphs[2] = dom.createMorphAt(element22,5,5);
                    morphs[3] = dom.createMorphAt(dom.childAt(element22, [7]),1,1);
                    morphs[4] = dom.createMorphAt(element22,9,9);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.patient.category",["loc",[null,[236,30],[236,58]]]]],[],0,null,["loc",[null,[236,24],[236,263]]]],
                    ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[237,51],[237,73]]]]],[],1,null,["loc",[null,[237,24],[237,163]]]],
                    ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[238,30],[238,61]]]]],[],2,null,["loc",[null,[238,24],[238,199]]]],
                    ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[241,34],[241,55]]]],["get","patientInfo.doctor",["loc",[null,[241,56],[241,74]]]]],[],["loc",[null,[241,30],[241,75]]]]],[],3,null,["loc",[null,[241,24],[268,31]]]],
                    ["block","link-to",["practicemgmt.treatment-details",["get","patientInfo.id",["loc",[null,[271,68],[271,82]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[271,83],[271,110]]]]],["class","mr0 ml10 icon-button icon-button--default list-action-square"],4,null,["loc",[null,[271,24],[273,36]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3, child4]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 233,
                      "column": 20
                    },
                    "end": {
                      "line": 276,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[234,57],[234,68]]]],["get","selectedUsers",["loc",[null,[234,69],[234,82]]]],["get","changeTrigger",["loc",[null,[234,83],[234,96]]]]],[],["loc",[null,[234,28],[234,97]]]]],[],0,null,["loc",[null,[234,22],[275,29]]]]
                ],
                locals: ["patientInfo"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 216,
                    "column": 14
                  },
                  "end": {
                    "line": 279,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
                dom.setAttribute(el2,"data-label","Check-In");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" Uhr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-16/24");
                dom.setAttribute(el2,"data-label","Kund:in");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element24 = dom.childAt(fragment, [1]);
                var element25 = dom.childAt(element24, [1]);
                var element26 = dom.childAt(element24, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(dom.childAt(element25, [1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element25, [3]),0,0);
                morphs[2] = dom.createMorphAt(element25,5,5);
                morphs[3] = dom.createMorphAt(dom.childAt(element26, [1]),0,0);
                morphs[4] = dom.createMorphAt(element26,3,3);
                return morphs;
              },
              statements: [
                ["inline","time-format",[["get","item.created",["loc",[null,[219,97],[219,109]]]],"DD. MM. YYYY"],[],["loc",[null,[219,83],[219,126]]]],
                ["inline","time-format",[["get","item.created",["loc",[null,[220,50],[220,62]]]],"HH:mm"],[],["loc",[null,[220,36],[220,72]]]],
                ["block","if",[["get","item.time",["loc",[null,[221,24],[221,33]]]]],[],0,null,["loc",[null,[221,18],[228,25]]]],
                ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[231,52],[231,68]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[231,24],[232,130]]]],
                ["block","each",[["get","item.treatmentPatients",["loc",[null,[233,28],[233,50]]]]],[],2,null,["loc",[null,[233,20],[276,29]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 215,
                  "column": 12
                },
                "end": {
                  "line": 280,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[216,41],[216,45]]]],["get","selectedUsers",["loc",[null,[216,46],[216,59]]]],["get","changeTrigger",["loc",[null,[216,60],[216,73]]]]],[],["loc",[null,[216,20],[216,74]]]]],[],0,null,["loc",[null,[216,14],[279,21]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 303,
                      "column": 24
                    },
                    "end": {
                      "line": 304,
                      "column": 118
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.customer.firstname",["loc",[null,[304,64],[304,91]]]],
                  ["content","item.customer.lastname",["loc",[null,[304,92],[304,118]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 308,
                          "column": 24
                        },
                        "end": {
                          "line": 308,
                          "column": 256
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1,"class","animal-icon");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[308,115],[308,146]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[308,155],[308,191]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[308,200],[308,233]]]]],[],[]],"classNames","mr5"],["loc",[null,[308,83],[308,252]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 310,
                          "column": 0
                        },
                        "end": {
                          "line": 310,
                          "column": 127
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[310,70],[310,94]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[310,103],[310,125]]]]],[],[]]],["loc",[null,[310,51],[310,127]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 311,
                          "column": 24
                        },
                        "end": {
                          "line": 313,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","block small");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["content","patientInfo.room.name",["loc",[null,[312,52],[312,77]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child3 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 315,
                          "column": 22
                        },
                        "end": {
                          "line": 315,
                          "column": 190
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[315,61],[315,190]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child4 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 320,
                              "column": 28
                            },
                            "end": {
                              "line": 325,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element11 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element11, 'title');
                          morphs[1] = dom.createAttrMorph(element11, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element11, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[321,44],[321,72]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[321,162],[321,186]]]]," !important;"]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[322,60],[322,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 325,
                              "column": 28
                            },
                            "end": {
                              "line": 330,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element10 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element10, 'title');
                          morphs[1] = dom.createAttrMorph(element10, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[326,44],[326,72]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[326,122],[326,149]]]],"selected",""],[],["loc",[null,[326,101],[326,165]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[326,201],[326,225]]]]]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[327,60],[327,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 319,
                            "column": 26
                          },
                          "end": {
                            "line": 331,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[320,34],[320,61]]]]],[],0,1,["loc",[null,[320,28],[330,35]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 333,
                              "column": 28
                            },
                            "end": {
                              "line": 338,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element9 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element9, 'title');
                          morphs[1] = dom.createAttrMorph(element9, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[334,44],[334,75]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[334,165],[334,192]]]]," !important;"]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[335,62],[335,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 338,
                              "column": 28
                            },
                            "end": {
                              "line": 343,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element8 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element8, 'title');
                          morphs[1] = dom.createAttrMorph(element8, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[339,44],[339,75]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[339,125],[339,155]]]],"selected",""],[],["loc",[null,[339,104],[339,171]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[339,207],[339,234]]]]]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[340,62],[340,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 332,
                            "column": 26
                          },
                          "end": {
                            "line": 344,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[333,34],[333,64]]]]],[],0,1,["loc",[null,[333,28],[343,35]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 317,
                          "column": 22
                        },
                        "end": {
                          "line": 345,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.doctor",["loc",[null,[319,32],[319,50]]]]],[],0,null,["loc",[null,[319,26],[331,33]]]],
                      ["block","if",[["get","patientInfo.assistant",["loc",[null,[332,32],[332,53]]]]],[],1,null,["loc",[null,[332,26],[344,33]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 306,
                        "column": 20
                      },
                      "end": {
                        "line": 352,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","flex-center mb5 mt5");
                    dom.setAttribute(el1,"style","flex-direction: row");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                      ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-group");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                    ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","ml5 exp icon-button icon-button--default list-action-square");
                    var el3 = dom.createTextNode("\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("span");
                    var el4 = dom.createTextNode("Behandeln");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element12 = dom.childAt(fragment, [1]);
                    var element13 = dom.childAt(element12, [3]);
                    var element14 = dom.childAt(element12, [9]);
                    var morphs = new Array(7);
                    morphs[0] = dom.createMorphAt(element12,1,1);
                    morphs[1] = dom.createMorphAt(element13,1,1);
                    morphs[2] = dom.createMorphAt(element13,3,3);
                    morphs[3] = dom.createMorphAt(element12,5,5);
                    morphs[4] = dom.createMorphAt(dom.childAt(element12, [7]),1,1);
                    morphs[5] = dom.createElementMorph(element14);
                    morphs[6] = dom.createMorphAt(element14,1,1);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.patient.category",["loc",[null,[308,30],[308,58]]]]],[],0,null,["loc",[null,[308,24],[308,263]]]],
                    ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[310,27],[310,49]]]]],[],1,null,["loc",[null,[310,0],[310,139]]]],
                    ["block","if",[["get","patientInfo.room",["loc",[null,[311,30],[311,46]]]]],[],2,null,["loc",[null,[311,24],[313,31]]]],
                    ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[315,28],[315,59]]]]],[],3,null,["loc",[null,[315,22],[315,197]]]],
                    ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[317,32],[317,53]]]],["get","patientInfo.doctor",["loc",[null,[317,54],[317,72]]]]],[],["loc",[null,[317,28],[317,73]]]]],[],4,null,["loc",[null,[317,22],[345,29]]]],
                    ["element","action",["movePatientToTreatmentRoom",["get","patientInfo.id",["loc",[null,[348,131],[348,145]]]]],[],["loc",[null,[348,93],[348,147]]]],
                    ["inline","button-in-treatment",[],["size","40","color","#fff","showStroke",false,"content","In eine Behandlungs-Lokation setzen"],["loc",[null,[349,26],[349,135]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3, child4]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 305,
                      "column": 18
                    },
                    "end": {
                      "line": 353,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[306,55],[306,66]]]],["get","selectedUsers",["loc",[null,[306,67],[306,80]]]],["get","changeTrigger",["loc",[null,[306,81],[306,94]]]]],[],["loc",[null,[306,26],[306,95]]]]],[],0,null,["loc",[null,[306,20],[352,27]]]]
                ],
                locals: ["patientInfo"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 296,
                    "column": 14
                  },
                  "end": {
                    "line": 356,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
                dom.setAttribute(el2,"data-label","Check-In");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" Uhr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-16/24");
                dom.setAttribute(el2,"data-label","Kund:in");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element15 = dom.childAt(fragment, [1]);
                var element16 = dom.childAt(element15, [1]);
                var element17 = dom.childAt(element15, [3]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(dom.childAt(element16, [1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element16, [3]),0,0);
                morphs[2] = dom.createMorphAt(dom.childAt(element17, [1]),0,0);
                morphs[3] = dom.createMorphAt(element17,3,3);
                return morphs;
              },
              statements: [
                ["inline","time-format",[["get","item.created",["loc",[null,[299,97],[299,109]]]],"DD. MM. YYYY"],[],["loc",[null,[299,83],[299,126]]]],
                ["inline","time-format",[["get","item.created",["loc",[null,[300,50],[300,62]]]],"HH:mm"],[],["loc",[null,[300,36],[300,72]]]],
                ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[303,52],[303,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[303,24],[304,130]]]],
                ["block","each",[["get","item.treatmentPatients",["loc",[null,[305,26],[305,48]]]]],[],1,null,["loc",[null,[305,18],[353,27]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 295,
                  "column": 12
                },
                "end": {
                  "line": 357,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[296,41],[296,45]]]],["get","selectedUsers",["loc",[null,[296,46],[296,59]]]],["get","changeTrigger",["loc",[null,[296,60],[296,73]]]]],[],["loc",[null,[296,20],[296,74]]]]],[],0,null,["loc",[null,[296,14],[356,21]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 382,
                    "column": 24
                  },
                  "end": {
                    "line": 383,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[383,64],[383,91]]]],
                ["content","item.customer.lastname",["loc",[null,[383,92],[383,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 386,
                      "column": 24
                    },
                    "end": {
                      "line": 390,
                      "column": 85
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1,"class","animal-icon");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[387,81],[387,112]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[388,71],[388,107]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[389,71],[389,104]]]]],[],[]],"classNames","mr5"],["loc",[null,[387,49],[390,81]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 391,
                      "column": 22
                    },
                    "end": {
                      "line": 392,
                      "column": 119
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[391,92],[391,116]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[392,95],[392,117]]]]],[],[]]],["loc",[null,[391,73],[392,119]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 393,
                      "column": 22
                    },
                    "end": {
                      "line": 395,
                      "column": 107
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[393,61],[395,107]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 384,
                    "column": 18
                  },
                  "end": {
                    "line": 397,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","flex-center");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(element4,1,1);
                morphs[1] = dom.createMorphAt(element4,3,3);
                morphs[2] = dom.createMorphAt(element4,5,5);
                return morphs;
              },
              statements: [
                ["block","if",[["get","patientInfo.patient.category",["loc",[null,[386,30],[386,58]]]]],[],0,null,["loc",[null,[386,24],[390,92]]]],
                ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[391,49],[391,71]]]]],[],1,null,["loc",[null,[391,22],[392,131]]]],
                ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[393,28],[393,59]]]]],[],2,null,["loc",[null,[393,22],[395,114]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0, child1, child2]
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 401,
                      "column": 20
                    },
                    "end": {
                      "line": 406,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square mr0 mb5 ml0 ");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","button-ready4payment",[],["size","32","color","#fff","showStroke",false,"content","Übergabe an Ausgangsrechnung"],["loc",[null,[403,24],[404,87]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 413,
                        "column": 22
                      },
                      "end": {
                        "line": 415,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-in-treatment",[],["size","32","color","#fff","showStroke",false,"content","Behandlung öffnen"],["loc",[null,[414,24],[414,115]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 406,
                      "column": 20
                    },
                    "end": {
                      "line": 416,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","ember-view icon-button icon-button--success list-action-square mr0 mb5 ml5 ");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createElementMorph(element3);
                  morphs[1] = dom.createMorphAt(element3,1,1);
                  morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["element","action",["openExportInvoicePanel",["get","patientInfo.id",["loc",[null,[408,94],[408,108]]]],["get","patientInfo.info.invoiceId",["loc",[null,[409,94],[409,120]]]]],[],["loc",[null,[407,109],[409,122]]]],
                  ["inline","button-ready4payment",[],["size","32","color","#fff","showStroke",false,"content","Übergabe an Ausgangsrechnung"],["loc",[null,[410,24],[411,87]]]],
                  ["block","link-to",["practicemgmt.treatment-details",["get","patientInfo.id",["loc",[null,[413,66],[413,80]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[413,81],[413,108]]]]],["class","mr0 icon-button icon-button--default list-action-square"],0,null,["loc",[null,[413,22],[415,34]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 400,
                    "column": 18
                  },
                  "end": {
                    "line": 417,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","inProgress",["loc",[null,[401,26],[401,36]]]]],[],0,1,["loc",[null,[401,20],[416,27]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 374,
                  "column": 12
                },
                "end": {
                  "line": 420,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
              dom.setAttribute(el2,"data-label","Check-In");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell  l-11/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--action l-5/24");
              dom.setAttribute(el2,"data-label","");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var element6 = dom.childAt(element5, [1]);
              var element7 = dom.childAt(element5, [3]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
              morphs[3] = dom.createMorphAt(element7,3,3);
              morphs[4] = dom.createMorphAt(dom.childAt(element5, [5]),1,1);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[377,97],[377,109]]]],"DD. MM. YYYY"],[],["loc",[null,[377,83],[378,113]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[379,50],[379,62]]]],"HH:mm"],[],["loc",[null,[379,36],[379,72]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[382,52],[382,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[382,24],[383,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[384,26],[384,48]]]]],[],1,null,["loc",[null,[384,18],[397,27]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[400,26],[400,48]]]]],[],2,null,["loc",[null,[400,18],[417,27]]]]
            ],
            locals: ["item"],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 0
              },
              "end": {
                "line": 425,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Demnächst");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table grid table--small");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Termin");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Wartezimmer");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table grid table--small");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("In Behandlung");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table table--small grid");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            dom.setAttribute(el2,"id","stationsCard");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Auf Station");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table table--small grid");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Bezahlung");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table table--small grid");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-14/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element46 = dom.childAt(fragment, [1, 1]);
            var element47 = dom.childAt(fragment, [3, 1]);
            var element48 = dom.childAt(fragment, [5]);
            var element49 = dom.childAt(element48, [1]);
            var element50 = dom.childAt(element48, [3]);
            var element51 = dom.childAt(fragment, [7, 1]);
            var morphs = new Array(10);
            morphs[0] = dom.createMorphAt(dom.childAt(element46, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element46, [3, 3]),1,1);
            morphs[2] = dom.createMorphAt(dom.childAt(element47, [1, 1]),0,0);
            morphs[3] = dom.createMorphAt(dom.childAt(element47, [3, 3]),1,1);
            morphs[4] = dom.createMorphAt(dom.childAt(element49, [1, 1]),0,0);
            morphs[5] = dom.createMorphAt(dom.childAt(element49, [3, 3]),1,1);
            morphs[6] = dom.createMorphAt(dom.childAt(element50, [1, 1]),0,0);
            morphs[7] = dom.createMorphAt(dom.childAt(element50, [3, 3]),1,1);
            morphs[8] = dom.createMorphAt(dom.childAt(element51, [1, 1]),0,0);
            morphs[9] = dom.createMorphAt(dom.childAt(element51, [3, 3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","get-size",[["get","upcomingItems",["loc",[null,[45,92],[45,105]]]]],[],["loc",[null,[45,81],[45,107]]]],
            ["block","each",[["get","upcomingItems",["loc",[null,[54,20],[54,33]]]]],[],0,null,["loc",[null,[54,12],[108,21]]]],
            ["inline","get-size",[["get","waitingroomItems",["loc",[null,[116,92],[116,108]]]]],[],["loc",[null,[116,81],[116,110]]]],
            ["block","each",[["get","waitingroomItems",["loc",[null,[125,20],[125,36]]]]],[],1,null,["loc",[null,[125,12],[198,21]]]],
            ["inline","get-size",[["get","treatmentItems",["loc",[null,[206,92],[206,106]]]]],[],["loc",[null,[206,81],[206,108]]]],
            ["block","each",[["get","treatmentItems",["loc",[null,[215,20],[215,34]]]]],[],2,null,["loc",[null,[215,12],[280,21]]]],
            ["inline","get-size",[["get","stationItems",["loc",[null,[286,92],[286,104]]]]],[],["loc",[null,[286,81],[286,106]]]],
            ["block","each",[["get","stationItems",["loc",[null,[295,20],[295,32]]]]],[],3,null,["loc",[null,[295,12],[357,21]]]],
            ["inline","get-size",[["get","paymentItems",["loc",[null,[365,92],[365,104]]]]],[],["loc",[null,[365,81],[365,106]]]],
            ["block","each",[["get","paymentItems",["loc",[null,[374,20],[374,32]]]]],[],4,null,["loc",[null,[374,12],[420,21]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 0
            },
            "end": {
              "line": 427,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","with-context-bar grid");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element54 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element54,1,1);
          morphs[1] = dom.createMorphAt(element54,3,3);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","mainView",["loc",[null,[20,14],[20,22]]]],"tiles"],[],["loc",[null,[20,10],[20,31]]]]],[],0,null,["loc",[null,[20,4],[39,11]]]],
          ["block","if",[["get","users",["loc",[null,[41,6],[41,11]]]]],[],1,null,["loc",[null,[41,0],[425,7]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 443,
                "column": 10
              },
              "end": {
                "line": 445,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createElementMorph(element2);
            morphs[2] = dom.createMorphAt(element2,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","station.selected",["loc",[null,[444,65],[444,81]]]],"selected",""],[],["loc",[null,[444,44],[444,97]]]]]]],
            ["element","action",["toggleStation",["get","station",["loc",[null,[444,124],[444,131]]]]],[],["loc",[null,[444,99],[444,133]]]],
            ["content","station.name",["loc",[null,[444,134],[444,150]]]]
          ],
          locals: ["station"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 440,
              "column": 6
            },
            "end": {
              "line": 447,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","quick-filter mb0 col l-12/24");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Stationen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","stations",["loc",[null,[443,18],[443,26]]]]],[],0,null,["loc",[null,[443,10],[445,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 454,
                  "column": 16
                },
                "end": {
                  "line": 462,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","symbol-label");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element1, 'title');
              morphs[1] = dom.createAttrMorph(element1, 'class');
              morphs[2] = dom.createAttrMorph(element1, 'style');
              morphs[3] = dom.createElementMorph(element1);
              morphs[4] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[455,32],[455,46]]]]]]],
              ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[456,65],[456,78]]]],"selected",""],[],["loc",[null,[456,44],[457,69]]]]," symbol symbol-circle symbol-40px"]]],
              ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[458,50],[458,60]]]]," !important;"]]],
              ["element","action",["toggleResource",["get","user",["loc",[null,[455,76],[455,80]]]]],[],["loc",[null,[455,50],[455,82]]]],
              ["content","user.capitals",["loc",[null,[459,46],[459,63]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 462,
                  "column": 16
                },
                "end": {
                  "line": 469,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","symbol-label");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element0, 'title');
              morphs[1] = dom.createAttrMorph(element0, 'class');
              morphs[2] = dom.createElementMorph(element0);
              morphs[3] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[463,32],[463,46]]]]]]],
              ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[464,65],[464,78]]]],"selected",""],[],["loc",[null,[464,44],[465,69]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[465,105],[465,115]]]]]]],
              ["element","action",["toggleResource",["get","user",["loc",[null,[463,76],[463,80]]]]],[],["loc",[null,[463,50],[463,82]]]],
              ["content","user.capitals",["loc",[null,[466,46],[466,63]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 453,
                "column": 14
              },
              "end": {
                "line": 470,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","user.colorHex",["loc",[null,[454,22],[454,35]]]]],[],0,1,["loc",[null,[454,16],[469,23]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 452,
              "column": 12
            },
            "end": {
              "line": 471,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","user.active",["loc",[null,[453,20],[453,31]]]]],[],0,null,["loc",[null,[453,14],[470,21]]]]
        ],
        locals: ["user"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 516,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","context-bar grid");
        dom.setAttribute(el1,"id","treatmentOverviewNav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-14/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","btn-group");
        dom.setAttribute(el3,"role","group");
        dom.setAttribute(el3,"aria-label","Basic example");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"type","button");
        var el5 = dom.createTextNode("Kombinierte Ansicht");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"type","button");
        var el5 = dom.createTextNode(" Nur Kacheln");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"type","button");
        var el5 = dom.createTextNode("Nur Liste");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","buttons-box col l-10/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","with-icon button context primary");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/add-entry.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Neue Behandlung");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","treatmentOverview");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("section");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table-wrapper mb30 mt20 grid");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","quick-filter mb0 col l-12/24");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Filter");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Wartezimmer");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Behandlung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Bereit zum Bezahlen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Station");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","quick-filter mb0 mt10 col l-24/24");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Mitarbeiter:innen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","symbol-group symbol-hover flex-shrink-0 me-2");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element55 = dom.childAt(fragment, [0]);
        var element56 = dom.childAt(element55, [1, 1]);
        var element57 = dom.childAt(element56, [1]);
        var element58 = dom.childAt(element56, [3]);
        var element59 = dom.childAt(element56, [5]);
        var element60 = dom.childAt(element55, [3, 1]);
        var element61 = dom.childAt(fragment, [2]);
        var element62 = dom.childAt(element61, [3]);
        var element63 = dom.childAt(element62, [1]);
        var element64 = dom.childAt(element63, [1]);
        var element65 = dom.childAt(element64, [3]);
        var element66 = dom.childAt(element64, [5]);
        var element67 = dom.childAt(element64, [7]);
        var element68 = dom.childAt(element64, [9]);
        var element69 = dom.childAt(element64, [11]);
        var morphs = new Array(27);
        morphs[0] = dom.createAttrMorph(element57, 'class');
        morphs[1] = dom.createElementMorph(element57);
        morphs[2] = dom.createAttrMorph(element58, 'class');
        morphs[3] = dom.createElementMorph(element58);
        morphs[4] = dom.createAttrMorph(element59, 'class');
        morphs[5] = dom.createElementMorph(element59);
        morphs[6] = dom.createElementMorph(element60);
        morphs[7] = dom.createAttrMorph(element61, 'class');
        morphs[8] = dom.createMorphAt(element61,1,1);
        morphs[9] = dom.createAttrMorph(element62, 'class');
        morphs[10] = dom.createAttrMorph(element65, 'class');
        morphs[11] = dom.createElementMorph(element65);
        morphs[12] = dom.createAttrMorph(element66, 'class');
        morphs[13] = dom.createElementMorph(element66);
        morphs[14] = dom.createAttrMorph(element67, 'class');
        morphs[15] = dom.createElementMorph(element67);
        morphs[16] = dom.createAttrMorph(element68, 'class');
        morphs[17] = dom.createElementMorph(element68);
        morphs[18] = dom.createAttrMorph(element69, 'class');
        morphs[19] = dom.createElementMorph(element69);
        morphs[20] = dom.createMorphAt(element63,3,3);
        morphs[21] = dom.createMorphAt(dom.childAt(element63, [5, 3]),1,1);
        morphs[22] = dom.createMorphAt(element62,3,3);
        morphs[23] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[24] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[25] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[26] = dom.createMorphAt(fragment,10,10,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[4,106],[4,114]]]],"splitted"],[],["loc",[null,[4,102],[4,126]]]],"selected",""],[],["loc",[null,[4,81],[4,142]]]]]]],
        ["element","action",["changeView","splitted"],[],["loc",[null,[4,14],[4,48]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[5,103],[5,111]]]],"tiles"],[],["loc",[null,[5,99],[5,120]]]],"selected",""],[],["loc",[null,[5,78],[5,136]]]]]]],
        ["element","action",["changeView","tiles"],[],["loc",[null,[5,14],[5,45]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[6,102],[6,110]]]],"list"],[],["loc",[null,[6,98],[6,118]]]],"selected",""],[],["loc",[null,[6,77],[6,134]]]]]]],
        ["element","action",["changeView","list"],[],["loc",[null,[6,14],[6,44]]]],
        ["element","action",["selectCustomerForTreatment"],[],["loc",[null,[10,7],[10,47]]]],
        ["attribute","class",["concat",[["get","mainView",["loc",[null,[16,37],[16,45]]]]]]],
        ["block","if",[["get","showTopTiles",["loc",[null,[17,6],[17,18]]]]],[],0,null,["loc",[null,[17,0],[427,9]]]],
        ["attribute","class",["concat",["site-content card transaction-filter  ",["subexpr","css-bool-evaluator",[["get","showTopTiles",["loc",[null,[429,77],[429,89]]]],"","with-context-bar"],[],["loc",[null,[429,56],[429,113]]]]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showAll",["loc",[null,[434,61],[434,68]]]],"selected",""],[],["loc",[null,[434,40],[434,85]]]]]]],
        ["element","action",["toggleQuickfilter","all"],[],["loc",[null,[434,87],[434,123]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showWaiting",["loc",[null,[435,61],[435,72]]]],"selected",""],[],["loc",[null,[435,40],[435,88]]]]]]],
        ["element","action",["toggleQuickfilter","waiting"],[],["loc",[null,[435,90],[435,130]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showTreatment",["loc",[null,[436,61],[436,74]]]],"selected",""],[],["loc",[null,[436,40],[436,90]]]]]]],
        ["element","action",["toggleQuickfilter","treatment"],[],["loc",[null,[436,92],[436,134]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showPayment",["loc",[null,[437,61],[437,72]]]],"selected",""],[],["loc",[null,[437,40],[437,88]]]]]]],
        ["element","action",["toggleQuickfilter","payment"],[],["loc",[null,[437,90],[437,130]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showStation",["loc",[null,[438,61],[438,72]]]],"selected",""],[],["loc",[null,[438,40],[438,88]]]]]]],
        ["element","action",["toggleQuickfilter","station"],[],["loc",[null,[438,90],[438,130]]]],
        ["block","if",[["get","showStation",["loc",[null,[440,12],[440,23]]]]],[],1,null,["loc",[null,[440,6],[447,13]]]],
        ["block","each",[["get","users",["loc",[null,[452,20],[452,25]]]]],[],2,null,["loc",[null,[452,12],[471,21]]]],
        ["inline","treatment-patient-list",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[477,14],[477,19]]]]],[],[]],"type",["subexpr","@mut",[["get","type",["loc",[null,[478,13],[478,17]]]]],[],[]],"selectedUsers",["subexpr","@mut",[["get","selectedUsers",["loc",[null,[479,22],[479,35]]]]],[],[]],"filteredStations",["subexpr","@mut",[["get","stations",["loc",[null,[480,25],[480,33]]]]],[],[]],"changeTrigger",["subexpr","@mut",[["get","changeTrigger",["loc",[null,[481,22],[481,35]]]]],[],[]],"showAll",["subexpr","@mut",[["get","showAll",["loc",[null,[482,16],[482,23]]]]],[],[]],"showAdmission",["subexpr","@mut",[["get","showAdmission",["loc",[null,[483,22],[483,35]]]]],[],[]],"showWaiting",["subexpr","@mut",[["get","showWaiting",["loc",[null,[484,20],[484,31]]]]],[],[]],"showTreatment",["subexpr","@mut",[["get","showTreatment",["loc",[null,[485,22],[485,35]]]]],[],[]],"showPayment",["subexpr","@mut",[["get","showPayment",["loc",[null,[486,20],[486,31]]]]],[],[]],"showStation",["subexpr","@mut",[["get","showStation",["loc",[null,[487,20],[487,31]]]]],[],[]],"showStationOverride",["subexpr","is-test-mandant",[],[],["loc",[null,[488,28],[488,45]]]],"abort","abort","openTime","openTime","movePatientToWaitingRoom","movePatientToWaitingRoom","movePatientToTreatmentRoom","movePatientToTreatmentRoom","movePatientToStation","movePatientToStation","exportInvoice","openExportInvoicePanel","editTreatmentInfo","editTreatmentInfo"],["loc",[null,[476,6],[496,8]]]],
        ["inline","edit-treatment-infos-panel",[],["confirm","saveEditTreatmentInfo","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[499,67],[499,72]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[499,84],[499,94]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[499,103],[499,110]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","infoTreatmentPanel",["loc",[null,[499,126],[499,144]]]]],[],[]]],["loc",[null,[499,0],[499,146]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[505,8],[505,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[506,10],[506,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[507,13],[507,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[508,17],[508,36]]]]],[],[]]],["loc",[null,[501,0],[508,38]]]],
        ["inline","export-invoice-panel",[],["exportInvoice","openExportInvoicePanel","confirm","exportInvoice","actionReceiver",["subexpr","@mut",[["get","exportInvoicePanel",["loc",[null,[513,17],[513,35]]]]],[],[]]],["loc",[null,[510,0],[513,37]]]],
        ["inline","select-app-time",[],["save","saveDate","actionReceiver",["subexpr","@mut",[["get","selectAppTime",["loc",[null,[514,49],[514,62]]]]],[],[]]],["loc",[null,[514,0],[514,64]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});