define('jason-frontend/routes/purchases/impex', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    perPage: 20,
    queryParams: {},
    setupController: function setupController(controller, model) {

      controller.set("model", model);

      this.applyFilter();
    },
    actions: {
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      },
      createCsv: function createCsv() {
        window.open('/api/purchaseImportRuns/download?supplierId=' + API['default'].getSupplierId() + '&mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      uploadDone: function uploadDone() {
        this.applyFilter();
      },
      downloadFile: function downloadFile(id) {
        window.open('/api/purchaseImportRuns/download?supplierId=' + API['default'].getSupplierId() + '&mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken() + '&id=' + id);
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller');

      controller.set('showLoading', true);

      this.findPaged('purchaseImportRun', {
        supplierId: API['default'].getSupplierId()
      }).then(function (data) {
        controller.set('model', data);
        controller.set('showLoading', false);
      });
    }
  });

});