define('jason-frontend/components/customer-payout-deposit-panel', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      save: function save(invoice) {
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      payout: function payout(totalDeposit, customerId) {

        if (!this.get('selectedCashRegister')) {
          jason.notifiction.error('Fehler', 'Bitte wählen Sie eine Kassa');
          return;
        }

        if (this.get('payoutValue') > totalDeposit) {
          jason.notifiction.error('Fehler', 'Auszahlungsbetrag darf max. dem Guthaben entsprechen');
          return;
        }

        var data = {
          value: String(this.get('payoutValue')).replace(',', '.'),
          cashRegisterId: this.get('selectedCashRegister').get('id'),
          customerId: customerId
        };

        this.sendAction('payout', data);
        $.magnificPopup.close();
      }
    }
  });

});