define('jason-frontend/components/dsf-details-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      load: function load(item) {
        this.set('details', item.get('details'));
        this.set('request', item.get('request'));
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});