define('jason-frontend/helpers/is-content-mandant', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isContentMandant = isContentMandant;

  function isContentMandant() {
    return API['default'].getMandant() == 141 || API['default'].getMandant() == 10006 || API['default'].getMandant() == 673 || API['default'].getMandant() == 674 || API['default'].getMandant() == 675;
  }

  exports['default'] = Ember['default'].Helper.helper(isContentMandant);

});