define('jason-frontend/controllers/patientappointmentcategories/create', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Erstellen",
    queryParams: ["etiga"],
    breadCrumbPath: "patientappointmentcategories.create"
  });

});