define('jason-frontend/routes/products/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",

    model: function model(params) {
      params.filterMandant = true;

      return this.findPaged('product', params);
    },
    setupController: function setupController(controller, model) {
      controller.set("model", model);

      this.store.find('productCategory').then(function (items) {
        controller.set('categories', items.sortBy('fullName'));
      });

      this.store.unloadAll('productSupplier');

      this.store.find('productSupplier').then(function (items) {
        controller.set('suppliers', items.sortBy('name'));
      });

      this.store.find('productDistributor').then(function (items) {
        controller.set('distributors', items.sortBy('name'));
      });

      this.store.find('article-tag', { type: 'service' }).then(function (items) {
        items.forEach(function (item) {
          Ember['default'].set(item, 'selected', false);
        });
        controller.set('articleTags', items);
      });

      $.ajax({
        url: "/api/productCategories?suggest=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('categories', data.productCategory);
        controller.set('selectedCategoryFilters', null);
      });
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          selectedCategoryFilters = controller.get('selectedCategoryFilters'),
          filterSupplier = controller.get('filterSupplier'),
          filterManufacturer = controller.get('filterManufacturer'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy'),
          manufacturerId = null,
          filterFavorites = controller.get('filterFavs'),
          filterId = null;

      if (filterManufacturer) {
        manufacturerId = filterManufacturer.id;
      }

      if (filterSupplier === 'all') {
        filterSupplier = null;
      }

      if (selectedCategoryFilters) {
        filterId = selectedCategoryFilters.id;
      }
      var tags = new Array();
      if (controller.get('articleTags')) {
        $.each(controller.get('articleTags').content, function () {
          if ($(this)[0].record.get('selected')) {
            tags.push($(this)[0].record.id);
          }
        });
      }

      this.findPaged('product', {
        filterName: filterName,
        filterMandant: true,
        selectedCategoryFilters: filterId,
        filterSupplier: filterSupplier,
        filterManufacturer: manufacturerId,
        filterFavorites: filterFavorites,
        sortDir: sortDir,
        filterTags: tags.toString(),
        sortBy: sortBy,
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    toggleProductFavoriteIntern: function toggleProductFavoriteIntern(id, add) {
      var controller = this.get('controller');

      if (add) {
        $.ajax({
          url: "/api/products/" + id + "/addToFavs",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {}, function (error) {});
      } else {
        $.ajax({
          url: "/api/products/" + id + "/removeFromFavs",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {}, function (error) {});
      }
    },
    togglePriceFavoriteIntern: function togglePriceFavoriteIntern(id, priceId, add) {
      var controller = this.get('controller');

      if (add) {
        $.ajax({
          url: "/api/products/" + id + "/price/" + priceId + "/addToFavs",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {}, function (error) {});
      } else {
        $.ajax({
          url: "/api/products/" + id + "/price/" + priceId + "/removeFromFavs",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {}, function (error) {});
      }
    },
    actions: {
      selectTag: function selectTag(tag) {
        var currentValue = tag.get('selected');
        Ember['default'].set(tag, 'selected', !currentValue);
        this.applyFilter();
      },
      queryManufacturer: function queryManufacturer(query, deferred) {
        this.store.find('productManufacturer', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      toggleProductFavorite: function toggleProductFavorite(product, add) {
        Ember['default'].set(product, 'favorite', add);
        this.toggleProductFavoriteIntern(product.id, add);
      },
      togglePriceFavorite: function togglePriceFavorite(product, price, add) {
        Ember['default'].set(price, 'favorite', add);
        this.togglePriceFavoriteIntern(product.id, price.id, add);
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      toggleFavorites: function toggleFavorites(enabled) {
        var controller = this.get('controller');
        // controller.set('filterFavorites', enabled);
        this.applyFilter();
      },
      setSort: function setSort(attr) {
        var controller = this.get('controller');
        controller.set('sortBy', attr);
        if (controller.get('sortDir') === 'asc') {
          controller.set('sortDir', 'desc');
        } else {
          controller.set('sortDir', 'asc');
        }
      },
      'delete': function _delete(id) {
        this.get('store').find('product', id).then(function (rec) {
          rec.destroyRecord();
        });
      }
    }
  });

});