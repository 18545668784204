define('jason-frontend/components/inlay-payment-chooser', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      registerPayments: function registerPayments() {

        var all = new Array();

        $('table#transaction-input tbody tr').each(function (index) {
          var given = $(this).find('td.given input').val();
          given = given.replace(",", ".");
          var change = $(this).find('td.change').data('change');
          if (given > 0) {
            all[index] = {
              given: given,
              change: change,
              paymentType: $(this).find('td.payment-types input[type=radio]:checked').val()
            };
          }
        });

        this.sendAction('continue', all);
      },
      toSelectPositions: function toSelectPositions() {
        this.sendAction('toSelectPositions');
      }
    },
    becomeFocused: (function () {
      function roundToTwo(value) {
        var format = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

        var value = Math.round(value * 100) / 100;
        if (format) {
          return ('' + value).replace('.', ',');
        }
        return value;
      }

      $(document).on("click", "td.remove > span", function () {
        $(this).closest('tr').remove();
      });

      $.validator.addMethod("mynumber", function (value, element) {
        return this.optional(element) || /^\d+([,][0-9]{1,2})?$/g.test(value);
      }, "Bitte einen gültigen Betrag eingeben");

      $.validator.addMethod("notZero", function (value, element) {
        var valueSafe = value.length ? value.replace(',', '.') : 0;
        return this.optional(element) || valueSafe > 0;
      }, "Bitte einen Betrag > 0,01 Euro eingeben");

      $("#payment-Chooser-Form").validate({
        errorClass: "state-error",
        validClass: "state-success",
        errorElement: "em",
        rules: {
          'given1': {
            mynumber: true,
            notZero: true
          }
        },
        highlight: function highlight(element, errorClass, validClass) {
          $(element).closest('.field').addClass(errorClass).removeClass(validClass);
        },
        unhighlight: function unhighlight(element, errorClass, validClass) {
          $(element).closest('.field').removeClass(errorClass).addClass(validClass);
        },
        errorPlacement: function errorPlacement(error, element) {
          if (element.is(":radio") || element.is(":checkbox")) {
            element.closest('.option-group').after(error);
          } else {
            error.insertAfter(element.parent());
          }
        }
      });
    }).on('didInsertElement')
  });

});