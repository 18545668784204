define('jason-frontend/components/invoice-payment-chooser', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      registerPayments: function registerPayments() {

        var all = new Array();

        var paymentRowIndex = 0;
        $('#transaction-input .js-table__row').each(function (index) {
          var given = $(this).find('.given input').val();
          given = given.replace(",", ".");
          var change = $(this).find('.change').data('change');

          var paymentType;

          if ($(this).hasClass('deposit')) {
            paymentType = 'deposit';
          } else {
            paymentType = $(this).find('.payment-types input[type=radio]:checked').val();
          }

          if (given > 0) {
            all[paymentRowIndex] = {
              given: given,
              change: change,
              paymentType: paymentType
            };
            paymentRowIndex++;
          }
        });

        this.sendAction('continue', all);
      },
      toSelectPositions: function toSelectPositions() {
        this.sendAction('toSelectPositions');
      }
    },
    reset: function reset() {
      var row1 = $('#transaction-input #row1');
      $('.given-input', row1).val('');
      $('.change', row1).html('');
      $('.change', row1).data('change', '0');
      $('.open').data('open', $('#init-open').html());

      $('#transaction-input .rows.cloned').each(function () {
        $(this).remove();
      });
    },
    roundToTwo: function roundToTwo(value) {
      var format = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      var value = Math.round(value * 100) / 100;
      if (format) {
        return ('' + value).replace('.', ',');
      }
      return value;
    },
    getLocalizedValue: function getLocalizedValue(apiValue) {
      return String(apiValue).replace('.', ',');
    },
    becomeFocused: (function () {

      var self = this;

      this.reset();

      $(document).off("click", ".dropdown-item.terminal");
      $(document).on("click", ".dropdown-item.terminal", function () {
        var currentRow = $(this).closest('.js-table__row'),
            given = currentRow.find('.given input').val().replace(',', '.');

        $.ajax({
          url: "/api/cashdesk/terminalpayment?amount=" + given,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {});
      });

      $(document).off("click", ".remove > span");
      $(document).on("click", ".remove > span", function () {
        $(this).closest('.js-table__row').remove();
      });

      $(document).off("click", ".rows input[type=radio]");
      $(document).on("click", ".rows input[type=radio]", function () {
        var currentRow = $(this).closest('.js-table__row'),
            paymentMode = currentRow.find('.payment-types input[type=radio]:checked').val(),
            given = currentRow.find('.given input'),
            open = currentRow.find('.open').attr('data-open');

        if (paymentMode !== 'cash') {
          if (given.val().length === 0) {
            given.val(String(open).replace('.', ','));
          }
          if (parseFloat(given.val().replace(',', '.')) > open) {

            given.val(String(open).replace('.', ','));

            currentRow.find('.change').html(accounting.formatMoney(0, "€ ", 2, ".", ","));
            currentRow.find('.change').attr('data-change', 0);
          }
        }

        if (paymentMode === 'creditcard' || paymentMode === 'cashcard') {
          currentRow.find('.dropdown').removeClass('terminalDisabled');
        } else {
          currentRow.find('.dropdown').addClass('terminalDisabled');
        }
        $('.rows input').trigger('blur');
      });

      $(document).off("blur", ".rows input[type=text]");
      $(document).on("blur", ".rows input[type=text]", function () {

        var tableBody = $('#transaction-input'),
            currentRow = $(this).closest('.js-table__row'),
            open = parseFloat(currentRow.find('.open').attr('data-open')),
            given = $(this).val().replace(",", "."),
            currentId = currentRow.data('id'),
            paymentMode = currentRow.find('.payment-types input[type=radio]:checked').val(),
            isDepositRow = currentRow.hasClass('deposit');

        if (!isDepositRow && given.length == 0 || given == 0) {}

        if (!$(this).val().length) {
          return;
        }

        if (isDepositRow) {
          var deposit = currentRow.find('.open').data('deposit');
          if (paymentMode !== 'cash' && given > open) {
            given = open;
            currentRow.find('.given-input').val(self.getLocalizedValue(open));
          }
          if (open > deposit && given > deposit) {
            given = deposit;
            currentRow.find('.given-input').val(self.getLocalizedValue(deposit));
          }
          if (deposit > open && given > open) {
            given = open;
            currentRow.find('.given-input').val(self.getLocalizedValue(given));
          }
          if (deposit >= open && given >= open) {
            given = open;
            currentRow.find('.given-input').val(self.getLocalizedValue(given));
          }
        }

        if (given < open || given <= open && currentRow.hasClass('deposit')) {
          var next = tableBody.find('div#row' + (currentId + 1));
          var newRow = null;
          if (next.length) {
            newRow = next;
          } else {
            newRow = $(this).closest('.js-table__row').clone();
            newRow.addClass('cloned');
            newRow.find('.remove').html('<span><a class="input input--action icon-button icon-button--delete list-action-square">' + '<img src="/assets/images/icons/icon-delete.svg"/> </a></span>');
            newRow.attr('data-id', currentId + 1);
            newRow.prop('id', 'row' + (currentId + 1));

            newRow.find('.given .given-input').attr('name', 'given' + (currentId + 1));
            newRow.find('.payment-types .cash').prop('id', 'cash' + (currentId + 1)).prop('name', 'paymentType' + (currentId + 1));
            newRow.find('.payment-types label.cash').prop('for', 'cash' + (currentId + 1));
            newRow.find('.payment-types input.cashcard').prop('id', 'cashcard' + (currentId + 1)).prop('name', 'paymentType' + (currentId + 1));
            newRow.find('.payment-types .cashcard').prop('for', 'cashcard' + (currentId + 1));
            newRow.find('.payment-types .creditcard').prop('id', 'creditcard' + (currentId + 1)).prop('name', 'paymentType' + (currentId + 1));
            newRow.find('.payment-types .creditcard').prop('for', 'creditcard' + (currentId + 1));
            newRow.find('.payment-types .voucher').prop('id', 'voucher' + (currentId + 1)).prop('name', 'paymentType' + (currentId + 1));
            newRow.find('.payment-types label.voucher').prop('for', 'voucher' + (currentId + 1));
            newRow.appendTo('#transaction-input');
          }
          var newOpen = open - given;

          newRow.find('.open').html(accounting.formatMoney(self.roundToTwo(newOpen), "€ ", 2, ".", ","));
          newRow.find('.given input').val('');
          newRow.find('.change').html('');
          newRow.find('.change').attr('data-change', '0');
          newRow.find('.open').attr('data-open', '' + self.roundToTwo(open - given));

          currentRow.find('.change').html('');
        } else {
          if (paymentMode === 'cash') {
            currentRow.find('.change').html(accounting.formatMoney(self.roundToTwo(given - open), "€ ", 2, ".", ","));
            currentRow.find('.change').attr('data-change', given - open);
          } else {
            if (given.length === 0) {
              currentRow.find('.given input').val(self.getLocalizedValue(open));
            }
            if (parseFloat(given.replace(',', '.')) > open) {
              currentRow.find('.given input').val(self.getLocalizedValue(open));
            }
          }
        }
      });

      $.validator.addMethod("mynumber", function (value, element) {
        return this.optional(element) || /^\d+([,][0-9]{1,2})?$/g.test(value);
      }, "Bitte einen gültigen Betrag eingeben");

      $.validator.addMethod("notZero", function (value, element) {
        var valueSafe = value.length ? value.replace(',', '.') : 0;
        return this.optional(element) || valueSafe > 0;
      }, "Bitte einen Betrag > 0,01 Euro eingeben");
    }).on('didInsertElement').observes('invoice')
  });

});