define('jason-frontend/components/search-box', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    tagName: '',
    filterName: null,
    placeHolder: 'Suchen',
    actions: {
      search: function search() {
        this.sendAction('search', this.get('filterName'));
      }
    },
    becomeFocused: (function () {

      try {
        if (/Mobi|Tablet|iPad|iPhone|Android/.test(navigator.userAgent)) {} else {
          $('input.input--search').focus();
        }
      } catch (err) {}
    }).on('didInsertElement')
  });

});