define('jason-frontend/routes/logout', ['exports', 'ember', 'jason-frontend/api', 'jason-frontend/config/environment'], function (exports, Ember, API, config) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController() {
      API['default'].logout();
      this.session.set('user', null);
      API['default'].resetToken();
      if (config['default'].idpEnabled) {
        var url = config['default'].ssoUrl + '/logout?redirect=' + btoa(config['default'].baseUrl);
        window.location.href = url;
      } else {
        window.location.href = '/login';
      }
    }
  });

});