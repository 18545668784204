define('jason-frontend/components/patient-toggle', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    patient: null,
    tagName: 'div',
    selected: true,
    invoiceId: null,
    classNames: ['patient-selector'],
    classNameBindings: ['selected'],
    actions: {
      confirmDeselection: function confirmDeselection() {
        this.set('selected', false);
        this.sendAction('changePatient', this.get('invoiceId'), this.get('patient').id, false);
      },
      click: function click(patientId, event) {
        if (this.get('selected') === true) {
          var $btn = Ember['default'].$(event.target),
              offset = $btn.position(),
              $confirm = $('.confirm-panel', $btn),
              height = $confirm.outerHeight(),
              width = $confirm.outerWidth();

          $confirm.css('top', offset.top - height - 20);
          $confirm.css('left', -110);
          $confirm.fadeIn();
        } else {
          this.set('selected', true);
          this.sendAction('changePatient', this.get('invoiceId'), patientId, true);
        }
      }
    }
  });

});