define('jason-frontend/templates/appointments/calendar', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 0
                },
                "end": {
                  "line": 12,
                  "column": 0
                }
              },
              "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("  background-color: ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(";\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","user.color",["loc",[null,[11,20],[11,34]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 0
                },
                "end": {
                  "line": 14,
                  "column": 0
                }
              },
              "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("  background-color: #b5b5c3;\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 2
                },
                "end": {
                  "line": 37,
                  "column": 2
                }
              },
              "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    background-color: ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(";\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","user.color",["loc",[null,[36,22],[36,36]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 2
                },
                "end": {
                  "line": 39,
                  "column": 2
                }
              },
              "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    background-color: #b5b5c3;\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 0
              },
              "end": {
                "line": 49,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("th.fc-resource-cell[data-resource-id=\"");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\"]::after {\n  content: '");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("';\n  transition: all 0.3s ease;\n  display: inline-block;\n  border-radius: 50%;\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("  color: white;\n  width: 25px;\n  height: 25px;\n  font-size: 12px;\n  line-height: 25px;\n  text-align: center;\n  font-weight: bold;\n  position: absolute;\n  left:50%;\n  top:50%;\n  transform: translate(-50%, -50%);\n}\n.showUserBadges .fc-event.user-");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("::after {\n  content: '");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("';\n  bottom: -4px;\n  right: -4px;\n  position: absolute;\n  margin-left: -10px;\n  display: inline-block;\n  border-radius: 50%;\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("  color: white;\n  width: 22px;\n  height: 22px;\n  font-size: 10px;\n  text-align: center;\n  font-weight: bold;\n  line-height: 22px;\n  z-index: 99;\n}\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
            morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
            morphs[4] = dom.createMorphAt(fragment,9,9,contextualElement);
            morphs[5] = dom.createMorphAt(fragment,11,11,contextualElement);
            return morphs;
          },
          statements: [
            ["content","user.id",["loc",[null,[5,38],[5,49]]]],
            ["content","user.capitals",["loc",[null,[6,12],[6,29]]]],
            ["block","if",[["get","user.colorHex",["loc",[null,[10,6],[10,19]]]]],[],0,1,["loc",[null,[10,0],[14,7]]]],
            ["content","user.id",["loc",[null,[27,31],[27,42]]]],
            ["content","user.capitals",["loc",[null,[28,12],[28,29]]]],
            ["block","if",[["get","user.colorHex",["loc",[null,[35,8],[35,21]]]]],[],2,3,["loc",[null,[35,2],[39,9]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 50,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","unless",[["subexpr","eq",[["get","user.id",["loc",[null,[4,14],[4,21]]]],"9998"],[],["loc",[null,[4,10],[4,29]]]]],[],0,null,["loc",[null,[4,0],[49,11]]]]
        ],
        locals: ["user"],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 109,
                "column": 16
              },
              "end": {
                "line": 117,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","symbol-label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element24 = dom.childAt(fragment, [1]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element24, 'title');
            morphs[1] = dom.createAttrMorph(element24, 'class');
            morphs[2] = dom.createAttrMorph(element24, 'style');
            morphs[3] = dom.createElementMorph(element24);
            morphs[4] = dom.createMorphAt(dom.childAt(element24, [1]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[110,32],[110,46]]]]]]],
            ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[111,65],[111,78]]]],"selected",""],[],["loc",[null,[111,44],[112,69]]]]," symbol symbol-circle symbol-40px"]]],
            ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[113,50],[113,60]]]]," !important;"]]],
            ["element","action",["toggleResource",["get","user",["loc",[null,[110,76],[110,80]]]]],[],["loc",[null,[110,50],[110,82]]]],
            ["content","user.capitals",["loc",[null,[114,46],[114,63]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 121,
                  "column": 20
                },
                "end": {
                  "line": 125,
                  "column": 19
                }
              },
              "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","symbol-label");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/notassigned.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 125,
                  "column": 19
                },
                "end": {
                  "line": 128,
                  "column": 19
                }
              },
              "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                     ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","symbol-label");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                     ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              return morphs;
            },
            statements: [
              ["content","user.capitals",["loc",[null,[126,47],[126,64]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 117,
                "column": 16
              },
              "end": {
                "line": 130,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element23 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element23, 'title');
            morphs[1] = dom.createAttrMorph(element23, 'class');
            morphs[2] = dom.createElementMorph(element23);
            morphs[3] = dom.createMorphAt(element23,1,1);
            return morphs;
          },
          statements: [
            ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[118,32],[118,46]]]]]]],
            ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[119,65],[119,78]]]],"selected",""],[],["loc",[null,[119,44],[120,69]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[120,105],[120,115]]]]]]],
            ["element","action",["toggleResource",["get","user",["loc",[null,[118,76],[118,80]]]]],[],["loc",[null,[118,50],[118,82]]]],
            ["block","if",[["subexpr","eq",[["get","user.id",["loc",[null,[121,30],[121,37]]]],"9998"],[],["loc",[null,[121,26],[121,45]]]]],[],0,1,["loc",[null,[121,20],[128,26]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 107,
              "column": 12
            },
            "end": {
              "line": 132,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","user.colorHex",["loc",[null,[109,22],[109,35]]]]],[],0,1,["loc",[null,[109,16],[130,23]]]]
        ],
        locals: ["user"],
        templates: [child0, child1]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 159,
              "column": 8
            },
            "end": {
              "line": 163,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","app");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-app");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 163,
              "column": 8
            },
            "end": {
              "line": 167,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","app");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-app");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 171,
              "column": 8
            },
            "end": {
              "line": 175,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","user");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-appUser");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 175,
              "column": 8
            },
            "end": {
              "line": 179,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","user");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-appUser");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 184,
              "column": 10
            },
            "end": {
              "line": 188,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","etiga");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-appEtiga");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 188,
              "column": 10
            },
            "end": {
              "line": 192,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","etiga");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-appEtiga");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 197,
              "column": 8
            },
            "end": {
              "line": 201,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","task");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-task");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 201,
              "column": 8
            },
            "end": {
              "line": 205,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","task");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-task");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 217,
                "column": 10
              },
              "end": {
                "line": 223,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("style");
            var el2 = dom.createTextNode("\n              label[for=cat-");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("]::before {\n                background-color: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(";\n             }\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element20 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element20,1,1);
            morphs[1] = dom.createMorphAt(element20,3,3);
            return morphs;
          },
          statements: [
            ["content","cat.id",["loc",[null,[219,28],[219,38]]]],
            ["content","cat.color",["loc",[null,[220,34],[220,47]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 225,
                "column": 12
              },
              "end": {
                "line": 229,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxCat");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element19 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element19,[],true); }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element19, 'value');
            morphs[1] = dom.createAttrMorph(element19, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","cat.id",["loc",[null,[226,30],[226,36]]]]]]],
            ["attribute","id",["concat",["cat-",["get","cat.id",["loc",[null,[227,31],[227,37]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 229,
                "column": 12
              },
              "end": {
                "line": 233,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxCat");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element18, 'value');
            morphs[1] = dom.createAttrMorph(element18, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","cat.id",["loc",[null,[230,30],[230,36]]]]]]],
            ["attribute","id",["concat",["cat-",["get","cat.id",["loc",[null,[231,31],[231,37]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 234,
                "column": 41
              },
              "end": {
                "line": 234,
                "column": 173
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" App Termin");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["inline","button-etiga-small",[],["content","","classNames","pt2 inline-icon","size","16","width",28],["loc",[null,[234,81],[234,162]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 234,
                "column": 173
              },
              "end": {
                "line": 234,
                "column": 193
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","cat.name",["loc",[null,[234,181],[234,193]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 216,
              "column": 8
            },
            "end": {
              "line": 236,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [2]);
          var element22 = dom.childAt(element21, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createAttrMorph(element21, 'class');
          morphs[2] = dom.createElementMorph(element21);
          morphs[3] = dom.createMorphAt(element21,1,1);
          morphs[4] = dom.createAttrMorph(element22, 'for');
          morphs[5] = dom.createMorphAt(element22,1,1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["get","cat.colorHex",["loc",[null,[217,16],[217,28]]]]],[],0,null,["loc",[null,[217,10],[223,17]]]],
          ["attribute","class",["concat",["upper checkbox-custom checkbox-primary mb5 color-pick ",["get","cat.color",["loc",[null,[224,78],[224,87]]]]]]],
          ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[224,91],[224,151]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","profile.cats",["loc",[null,[225,36],[225,48]]]],["get","cat.fullId",["loc",[null,[225,49],[225,59]]]]],[],["loc",[null,[225,18],[225,60]]]]],[],1,2,["loc",[null,[225,12],[233,19]]]],
          ["attribute","for",["concat",["cat-",["get","cat.id",["loc",[null,[234,30],[234,36]]]]]]],
          ["block","if",[["subexpr","eq",[["get","cat.name",["loc",[null,[234,51],[234,59]]]],"etiga App Termin"],[],["loc",[null,[234,47],[234,79]]]]],[],3,4,["loc",[null,[234,41],[234,200]]]]
        ],
        locals: ["cat"],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 247,
              "column": 10
            },
            "end": {
              "line": 251,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxUser");
          dom.setAttribute(el1,"id","user-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 251,
              "column": 10
            },
            "end": {
              "line": 255,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxUser");
          dom.setAttribute(el1,"id","user-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 260,
                  "column": 10
                },
                "end": {
                  "line": 266,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("style");
              var el2 = dom.createTextNode("\n                label[for=user-");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("]::before {\n                background-color: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(";\n              }\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element15 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element15,1,1);
              morphs[1] = dom.createMorphAt(element15,3,3);
              return morphs;
            },
            statements: [
              ["content","user.id",["loc",[null,[262,31],[262,42]]]],
              ["content","user.color",["loc",[null,[263,34],[263,48]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 268,
                  "column": 12
                },
                "end": {
                  "line": 272,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"class","checkboxUser");
              dom.setAttribute(el1,"type","checkbox");
              dom.setAttribute(el1,"checked","");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element14 = dom.childAt(fragment, [1]);
              if (this.cachedFragment) { dom.repairClonedNode(element14,[],true); }
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element14, 'value');
              morphs[1] = dom.createAttrMorph(element14, 'id');
              return morphs;
            },
            statements: [
              ["attribute","value",["concat",[["get","user.id",["loc",[null,[269,30],[269,37]]]]]]],
              ["attribute","id",["concat",["user-",["get","user.id",["loc",[null,[270,32],[270,39]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 272,
                  "column": 12
                },
                "end": {
                  "line": 276,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"class","checkboxUser");
              dom.setAttribute(el1,"type","checkbox");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element13 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element13, 'value');
              morphs[1] = dom.createAttrMorph(element13, 'id');
              return morphs;
            },
            statements: [
              ["attribute","value",["concat",[["get","user.id",["loc",[null,[273,30],[273,37]]]]]]],
              ["attribute","id",["concat",["user-",["get","user.id",["loc",[null,[274,32],[274,39]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 259,
                "column": 10
              },
              "end": {
                "line": 279,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [2]);
            var element17 = dom.childAt(element16, [3]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createAttrMorph(element16, 'class');
            morphs[2] = dom.createElementMorph(element16);
            morphs[3] = dom.createMorphAt(element16,1,1);
            morphs[4] = dom.createAttrMorph(element17, 'for');
            morphs[5] = dom.createMorphAt(element17,0,0);
            morphs[6] = dom.createMorphAt(element17,2,2);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["block","if",[["get","user.colorHex",["loc",[null,[260,16],[260,29]]]]],[],0,null,["loc",[null,[260,10],[266,17]]]],
            ["attribute","class",["concat",["upper checkbox-custom checkbox-primary mb5 color-pick ",["get","user.color",["loc",[null,[267,78],[267,88]]]]]]],
            ["element","action",["triggerRefreshUser",""],["on","change","preventDefault",false],["loc",[null,[267,92],[267,159]]]],
            ["block","if",[["subexpr","is-cat-selected2",[["get","profile.users",["loc",[null,[268,36],[268,49]]]],["get","user.fullId",["loc",[null,[268,50],[268,61]]]]],[],["loc",[null,[268,18],[268,62]]]]],[],1,2,["loc",[null,[268,12],[276,19]]]],
            ["attribute","for",["concat",["user-",["get","user.id",["loc",[null,[277,31],[277,38]]]]]]],
            ["content","user.firstname",["loc",[null,[277,43],[277,61]]]],
            ["content","user.lastname",["loc",[null,[277,62],[277,79]]]]
          ],
          locals: [],
          templates: [child0, child1, child2]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 258,
              "column": 8
            },
            "end": {
              "line": 280,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","unless",[["subexpr","eq",[["get","user.id",["loc",[null,[259,24],[259,31]]]],"9998"],[],["loc",[null,[259,20],[259,39]]]]],[],0,null,["loc",[null,[259,10],[279,21]]]]
        ],
        locals: ["user"],
        templates: [child0]
      };
    }());
    var child14 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 296,
                "column": 10
              },
              "end": {
                "line": 303,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","upper checkbox-custom checkbox-primary mb5");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"class","checkResource");
            dom.setAttribute(el2,"type","checkbox");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var element9 = dom.childAt(element8, [1]);
            var element10 = dom.childAt(element8, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createElementMorph(element8);
            morphs[1] = dom.createAttrMorph(element9, 'value');
            morphs[2] = dom.createAttrMorph(element9, 'id');
            morphs[3] = dom.createAttrMorph(element10, 'for');
            morphs[4] = dom.createMorphAt(element10,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["triggerRefreshResource",""],["on","change","preventDefault",false],["loc",[null,[297,68],[297,139]]]],
            ["attribute","value",["concat",[["get","resource.id",["loc",[null,[298,30],[298,41]]]]]]],
            ["attribute","id",["concat",["resource-",["get","resource.id",["loc",[null,[299,36],[299,47]]]]]]],
            ["attribute","for",["concat",["resource-",["get","resource.id",["loc",[null,[301,37],[301,48]]]]]]],
            ["content","resource.name",["loc",[null,[301,52],[301,69]]]]
          ],
          locals: ["resource"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 283,
              "column": 6
            },
            "end": {
              "line": 305,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","header");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h3");
          dom.setAttribute(el2,"class","mb20 float-left mt20 ml20");
          var el3 = dom.createTextNode("Resourcen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","upper checkbox-custom checkbox-primary mb5");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("input");
          dom.setAttribute(el3,"value","9999");
          dom.setAttribute(el3,"class","checkResource");
          dom.setAttribute(el3,"id","resource-9999");
          dom.setAttribute(el3,"type","checkbox");
          dom.setAttribute(el3,"checked","");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","resource-9999");
          var el4 = dom.createTextNode("Alle");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [3]);
          var element12 = dom.childAt(element11, [1]);
          if (this.cachedFragment) { dom.repairClonedNode(dom.childAt(element12, [1]),[],true); }
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          morphs[1] = dom.createAttrMorph(element11, 'class');
          morphs[2] = dom.createElementMorph(element12);
          morphs[3] = dom.createMorphAt(element11,3,3);
          return morphs;
        },
        statements: [
          ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames"," mt20 float-right topToggle","active",["subexpr","@mut",[["get","resourcesExpanded",["loc",[null,[287,107],[287,124]]]]],[],[]],"toggleAll","toggleResources","content","ein/ausblenden"],["loc",[null,[287,10],[287,179]]]],
          ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","resourcesExpanded",["loc",[null,[289,58],[289,75]]]],"","hidden"],[],["loc",[null,[289,37],[289,89]]]]]]],
          ["element","action",["triggerRefreshResource","all"],["on","change","preventDefault",false],["loc",[null,[290,66],[290,140]]]],
          ["block","each",[["get","resources",["loc",[null,[296,18],[296,27]]]]],[],0,null,["loc",[null,[296,10],[303,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 312,
              "column": 10
            },
            "end": {
              "line": 316,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxLocation");
          dom.setAttribute(el1,"id","room-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 316,
              "column": 10
            },
            "end": {
              "line": 320,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxLocation");
          dom.setAttribute(el1,"id","room-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child17 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 325,
                "column": 12
              },
              "end": {
                "line": 329,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxLocation");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element5,[],true); }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element5, 'value');
            morphs[1] = dom.createAttrMorph(element5, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","room.id",["loc",[null,[326,30],[326,37]]]]]]],
            ["attribute","id",["concat",["room-",["get","room.id",["loc",[null,[327,32],[327,39]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 329,
                "column": 12
              },
              "end": {
                "line": 333,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxLocation");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element4, 'value');
            morphs[1] = dom.createAttrMorph(element4, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","room.id",["loc",[null,[330,30],[330,37]]]]]]],
            ["attribute","id",["concat",["room-",["get","room.id",["loc",[null,[331,32],[331,39]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 323,
              "column": 8
            },
            "end": {
              "line": 336,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","upper checkbox-custom checkbox-primary mb5");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(element6, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createMorphAt(element6,1,1);
          morphs[2] = dom.createAttrMorph(element7, 'for');
          morphs[3] = dom.createMorphAt(element7,0,0);
          return morphs;
        },
        statements: [
          ["element","action",["triggerRefreshLocation",""],["on","change","preventDefault",false],["loc",[null,[324,66],[324,137]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","profile.rooms",["loc",[null,[325,36],[325,49]]]],["get","room.fullId",["loc",[null,[325,50],[325,61]]]]],[],["loc",[null,[325,18],[325,62]]]]],[],0,1,["loc",[null,[325,12],[333,19]]]],
          ["attribute","for",["concat",["room-",["get","room.id",["loc",[null,[334,31],[334,38]]]]]]],
          ["content","room.name",["loc",[null,[334,42],[334,55]]]]
        ],
        locals: ["room"],
        templates: [child0, child1]
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 345,
              "column": 10
            },
            "end": {
              "line": 349,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxStation");
          dom.setAttribute(el1,"id","station-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 349,
              "column": 10
            },
            "end": {
              "line": 353,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxStation");
          dom.setAttribute(el1,"id","station-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child20 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 359,
                "column": 12
              },
              "end": {
                "line": 363,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxStation");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element1,[],true); }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element1, 'value');
            morphs[1] = dom.createAttrMorph(element1, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","station.id",["loc",[null,[360,30],[360,40]]]]]]],
            ["attribute","id",["concat",["station-",["get","station.id",["loc",[null,[361,35],[361,45]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 363,
                "column": 12
              },
              "end": {
                "line": 367,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxStation");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'value');
            morphs[1] = dom.createAttrMorph(element0, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","station.id",["loc",[null,[364,30],[364,40]]]]]]],
            ["attribute","id",["concat",["station-",["get","station.id",["loc",[null,[365,35],[365,45]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 357,
              "column": 8
            },
            "end": {
              "line": 370,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","upper checkbox-custom checkbox-primary mb5");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createMorphAt(element2,1,1);
          morphs[2] = dom.createAttrMorph(element3, 'for');
          morphs[3] = dom.createMorphAt(element3,0,0);
          return morphs;
        },
        statements: [
          ["element","action",["triggerRefreshStation",""],["on","change","preventDefault",false],["loc",[null,[358,66],[358,136]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","profile.stations",["loc",[null,[359,36],[359,52]]]],["get","station.fullId",["loc",[null,[359,53],[359,67]]]]],[],["loc",[null,[359,18],[359,68]]]]],[],0,1,["loc",[null,[359,12],[367,19]]]],
          ["attribute","for",["concat",["station-",["get","station.id",["loc",[null,[368,34],[368,44]]]]]]],
          ["content","station.name",["loc",[null,[368,48],[368,64]]]]
        ],
        locals: ["station"],
        templates: [child0, child1]
      };
    }());
    var child21 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 375,
              "column": 2
            },
            "end": {
              "line": 378,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar-hidden");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"id","showSidebar");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[376,61],[376,196]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 489,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","calendarWrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","fc fc-ltr fc-unthemed");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","calendarStickyArea");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","d-flex align-items-center mb20");
        dom.setAttribute(el4,"id","calendarDateToolbar");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"type","button");
        dom.setAttribute(el6,"class","mr10 btn btn-light text-center");
        var el7 = dom.createTextNode("Heute\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"style","position: relative");
        dom.setAttribute(el6,"type","button");
        dom.setAttribute(el6,"class","mr10 btn btn-light text-center");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","assets/images/icons/icon-calendar.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","btn-group");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"type","button");
        dom.setAttribute(el7,"class","btn btn-icon btn-light text-center");
        var el8 = dom.createElement("img");
        dom.setAttribute(el8,"src","assets/images/icons/icon-prev-dark.svg");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"type","button");
        dom.setAttribute(el7,"class","btn btn-icon ml5 btn-light text-center");
        var el8 = dom.createElement("img");
        dom.setAttribute(el8,"src","assets/images/icons/icon-next-dark.svg");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","text-center");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","current-date fs-2 fw-bold");
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ml5 fs-7 text-gray-500 fw-bold");
        var el8 = dom.createTextNode("KW ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n         ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","d-flex justify-content-end");
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","flex-center mr20");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","mr5 text-center");
        dom.setAttribute(el7,"style","font-size: 13px;line-height: 13px");
        var el8 = dom.createTextNode("Farbe");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Terminart");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","header-toggle");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","colorMode");
        dom.setAttribute(el8,"class","switch neutral");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ml5 text-center");
        dom.setAttribute(el7,"style","font-size: 13px;line-height: 13px");
        var el8 = dom.createTextNode("Farbe");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Mitarbeiter:in");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","u-mb0 field select mr10");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"type","button");
        dom.setAttribute(el6,"class","btn btn-icon btn-light text-center");
        var el7 = dom.createTextNode("Ansicht\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","calendarFilterToolbar");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","headline");
        var el7 = dom.createTextNode("Geteilte Ansicht je Mitarbeiter:in");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","symbol-group symbol-hover flex-shrink-0 me-2");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","calendar");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"id","tooltipTest");
        dom.setAttribute(el2,"class","tooltip hidden");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","head");
        var el4 = dom.createTextNode("Details");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line");
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/clock.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","time");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line category");
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/empty.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","category");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line customer");
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/customer-info.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line");
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/info.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","info");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line appStatus");
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/empty.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","appStatus");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("aside");
        dom.setAttribute(el1,"id","calendarSidebar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2,"type","button");
        dom.setAttribute(el2,"class","mfp-close");
        var el3 = dom.createTextNode("X");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","box scrollable");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 mt20");
        var el5 = dom.createTextNode("Typ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","upper checkbox-custom checkbox-primary mb5 type-pick calApp");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","type-app");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","calApp");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Kund:innen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","upper checkbox-custom checkbox-primary mb5 type-pick calAppMa");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","type-appUser");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","calAppMa");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Mitarbeiter:innen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","upper checkbox-custom checkbox-primary mb5 type-pick calAppEtiga");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","type-appEtiga");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","calAppEtiga mr0");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Terminanfragen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","upper checkbox-custom checkbox-primary mb5 type-pick calTask");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","type-task");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","calTask");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Aufgaben");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 float-left ml20 mt20");
        var el5 = dom.createTextNode("Terminarten");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 float-left mt20 ml20");
        var el5 = dom.createTextNode("Personen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","upper checkbox-custom checkbox-primary mb5");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","user-9999");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 float-left mt20 ml20");
        var el5 = dom.createTextNode("Lokationen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","upper checkbox-custom checkbox-primary mb5");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","room-9999");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 float-left mt20 ml20");
        var el5 = dom.createTextNode("Stationen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","upper checkbox-custom checkbox-primary mb5");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","station-9999");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $( window ).resize(function() {\n      $('aside.side-bar ').css('min-height', $(window).height()-100);\n      $('aside.side-bar div.scrollable').css('max-height', $(window).height()-100);\n      $('#calendarWrapper thead.fc-head').css('width', $('#calendar').width());\n      $('#calendarWrapper .fc-widget-content .fc-day-grid').css('width', $('#calendar').width()-3);\n    });\n    $('aside.side-bar').css('min-height', $(window).height() - 150);\n    $('aside.side-bar div.scrollable').css('max-height', $(window).height() - 150);\n    $('#calendarWrapper .fc-widget-content .fc-day-grid').css('width', $('#calendar').width()-3);\n\n    $('#miniCalendarTrigger').daterangepicker({\n      \"singleDatePicker\": true,\n      \"showISOWeekNumbers\": true,\n      \"locale\": {\n        \"separator\": \" - \",\n        \"applyLabel\": \"Anwenden\",\n        \"cancelLabel\": \"Abbrechen\",\n        \"fromLabel\": \"Von\",\n        \"toLabel\": \"Bis\",\n        \"customRangeLabel\": \"Benutzerdefiniert\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n      // \"autoApply\": true,\n    });\n    $('#miniCalendarTrigger').on('apply.daterangepicker', function(ev, picker) {\n     picker.show();\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element25 = dom.childAt(fragment, [2]);
        var element26 = dom.childAt(element25, [1, 1]);
        var element27 = dom.childAt(element26, [1]);
        var element28 = dom.childAt(element27, [1]);
        var element29 = dom.childAt(element28, [1]);
        var element30 = dom.childAt(element28, [3]);
        var element31 = dom.childAt(element28, [7]);
        var element32 = dom.childAt(element31, [1]);
        var element33 = dom.childAt(element31, [3]);
        var element34 = dom.childAt(element27, [3, 1]);
        var element35 = dom.childAt(element27, [5]);
        var element36 = dom.childAt(element35, [1, 3]);
        var element37 = dom.childAt(element35, [5]);
        var element38 = dom.childAt(element26, [3]);
        var element39 = dom.childAt(fragment, [4]);
        var element40 = dom.childAt(element39, [1]);
        var element41 = dom.childAt(element39, [3]);
        var element42 = dom.childAt(element41, [1]);
        var element43 = dom.childAt(element41, [3]);
        var element44 = dom.childAt(element41, [7]);
        var element45 = dom.childAt(element41, [9]);
        var element46 = dom.childAt(element41, [12]);
        var element47 = dom.childAt(element41, [15]);
        var element48 = dom.childAt(element41, [19]);
        var element49 = dom.childAt(element41, [23]);
        var element50 = dom.childAt(element49, [1]);
        var element51 = dom.childAt(element41, [29]);
        var element52 = dom.childAt(element51, [1]);
        var element53 = dom.childAt(element41, [33]);
        var element54 = dom.childAt(element53, [1]);
        var morphs = new Array(58);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
        morphs[1] = dom.createAttrMorph(element25, 'class');
        morphs[2] = dom.createAttrMorph(element26, 'class');
        morphs[3] = dom.createElementMorph(element29);
        morphs[4] = dom.createElementMorph(element30);
        morphs[5] = dom.createMorphAt(element28,5,5);
        morphs[6] = dom.createElementMorph(element32);
        morphs[7] = dom.createElementMorph(element33);
        morphs[8] = dom.createMorphAt(element34,1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element34, [2]),1,1);
        morphs[10] = dom.createElementMorph(element36);
        morphs[11] = dom.createMorphAt(element36,1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element35, [3]),1,1);
        morphs[13] = dom.createElementMorph(element37);
        morphs[14] = dom.createAttrMorph(element38, 'class');
        morphs[15] = dom.createMorphAt(dom.childAt(element38, [1, 3]),1,1);
        morphs[16] = dom.createAttrMorph(element39, 'class');
        morphs[17] = dom.createElementMorph(element40);
        morphs[18] = dom.createElementMorph(element42);
        morphs[19] = dom.createMorphAt(element42,0,0);
        morphs[20] = dom.createElementMorph(element43);
        morphs[21] = dom.createMorphAt(element43,0,0);
        morphs[22] = dom.createElementMorph(element44);
        morphs[23] = dom.createMorphAt(element44,1,1);
        morphs[24] = dom.createElementMorph(element45);
        morphs[25] = dom.createMorphAt(element45,1,1);
        morphs[26] = dom.createElementMorph(element46);
        morphs[27] = dom.createMorphAt(element46,1,1);
        morphs[28] = dom.createMorphAt(dom.childAt(element46, [3]),2,2);
        morphs[29] = dom.createElementMorph(element47);
        morphs[30] = dom.createMorphAt(element47,1,1);
        morphs[31] = dom.createMorphAt(dom.childAt(element41, [17]),3,3);
        morphs[32] = dom.createAttrMorph(element48, 'class');
        morphs[33] = dom.createMorphAt(element48,1,1);
        morphs[34] = dom.createMorphAt(dom.childAt(element41, [21]),3,3);
        morphs[35] = dom.createAttrMorph(element49, 'class');
        morphs[36] = dom.createElementMorph(element50);
        morphs[37] = dom.createMorphAt(element50,1,1);
        morphs[38] = dom.createMorphAt(element49,3,3);
        morphs[39] = dom.createMorphAt(element41,25,25);
        morphs[40] = dom.createMorphAt(dom.childAt(element41, [27]),3,3);
        morphs[41] = dom.createAttrMorph(element51, 'class');
        morphs[42] = dom.createElementMorph(element52);
        morphs[43] = dom.createMorphAt(element52,1,1);
        morphs[44] = dom.createMorphAt(element51,3,3);
        morphs[45] = dom.createMorphAt(dom.childAt(element41, [31]),3,3);
        morphs[46] = dom.createAttrMorph(element53, 'class');
        morphs[47] = dom.createElementMorph(element54);
        morphs[48] = dom.createMorphAt(element54,1,1);
        morphs[49] = dom.createMorphAt(element53,3,3);
        morphs[50] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[51] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[52] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[53] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[54] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[55] = dom.createMorphAt(fragment,16,16,contextualElement);
        morphs[56] = dom.createMorphAt(fragment,18,18,contextualElement);
        morphs[57] = dom.createMorphAt(fragment,20,20,contextualElement);
        return morphs;
      },
      statements: [
        ["block","each",[["get","users",["loc",[null,[3,8],[3,13]]]]],[],0,null,["loc",[null,[3,0],[50,9]]]],
        ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","hideBgEvents",["loc",[null,[53,58],[53,70]]]],"hideBgEvents",""],[],["loc",[null,[53,37],[53,90]]]]," ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","showUserBadges",["loc",[null,[53,116],[53,130]]]],"true"],[],["loc",[null,[53,112],[53,138]]]],"showUserBadges",""],[],["loc",[null,[53,91],[53,160]]]]," ",["subexpr","css-bool-evaluator",[["get","showFilter",["loc",[null,[53,182],[53,192]]]],"withFilter",""],[],["loc",[null,[53,161],[53,210]]]]," col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[53,243],[53,256]]]]],[],["loc",[null,[53,238],[53,257]]]],"24","19"],[],["loc",[null,[53,217],[53,269]]]],"/24@desk l-24/24@phone l-24/24@tablet ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[53,328],[53,341]]]],"sidebar-active",""],[],["loc",[null,[53,307],[53,363]]]]," card site-content fadeIn"]]],
        ["attribute","class",["concat",["col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[55,72],[55,85]]]]],[],["loc",[null,[55,67],[55,86]]]],"24","20"],[],["loc",[null,[55,46],[55,98]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["element","action",["today"],[],["loc",[null,[58,18],[58,36]]]],
        ["element","action",["openMiniCalendar"],[],["loc",[null,[60,98],[60,127]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","switchToDate",["loc",[null,[63,36],[63,48]]]]],[],[]],"id","miniCalendarTrigger"],["loc",[null,[63,10],[63,75]]]],
        ["element","action",["prev"],[],["loc",[null,[65,34],[65,51]]]],
        ["element","action",["next"],[],["loc",[null,[67,34],[67,51]]]],
        ["content","currentDateFormatted",["loc",[null,[72,51],[72,75]]]],
        ["content","weekNumber",["loc",[null,[73,54],[73,68]]]],
        ["element","action",["toggleColorMode"],[],["loc",[null,[80,39],[80,67]]]],
        ["inline","input",[],["type","checkbox","id","colorMode","classNames","switch","name","colorMode","checked",["subexpr","@mut",[["get","colorModeUserEnabled",["loc",[null,[81,98],[81,118]]]]],[],[]]],["loc",[null,[81,14],[81,120]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","views",["loc",[null,[89,22],[89,27]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedView",["loc",[null,[90,20],[90,32]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","searchEnabled",false,"allowClear",false,"cssClass","input--dropdown custom-calendar"],["loc",[null,[88,12],[96,14]]]],
        ["element","action",["toggleFilter"],[],["loc",[null,[99,32],[99,57]]]],
        ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","showFilter",["loc",[null,[103,39],[103,49]]]],"","hidden"],[],["loc",[null,[103,18],[103,63]]]]," mb10"]]],
        ["block","each",[["get","users",["loc",[null,[107,20],[107,25]]]]],[],1,null,["loc",[null,[107,12],[132,21]]]],
        ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[149,38],[149,51]]]],"","hidden"],[],["loc",[null,[149,17],[149,65]]]]," side-bar card col l-5/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["element","action",["toggleSidebar",true],[],["loc",[null,[150,42],[150,73]]]],
        ["element","action",["toggleMe"],[],["loc",[null,[152,18],[152,39]]]],
        ["inline","button-toggle-me",[],["size",40,"strokeWidth",1,"classNames","toggleMe","active",["subexpr","@mut",[["get","onlyMine",["loc",[null,[152,111],[152,119]]]]],[],[]],"content",["subexpr","css-bool-evaluator",[["get","onlyMine",["loc",[null,[152,148],[152,156]]]],"Persönliche Ansicht","Praxis Ansicht"],[],["loc",[null,[152,128],[152,196]]]]],["loc",[null,[152,41],[152,198]]]],
        ["element","action",["ical"],[],["loc",[null,[153,18],[153,35]]]],
        ["inline","button-ical",[],["size",32,"classNames","ical"],["loc",[null,[153,37],[153,78]]]],
        ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[158,79],[158,139]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.types",["loc",[null,[159,32],[159,45]]]],"type-app"],[],["loc",[null,[159,14],[159,57]]]]],[],2,3,["loc",[null,[159,8],[167,15]]]],
        ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[170,81],[170,141]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.types",["loc",[null,[171,32],[171,45]]]],"type-appUser"],[],["loc",[null,[171,14],[171,61]]]]],[],4,5,["loc",[null,[171,8],[179,15]]]],
        ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[183,86],[183,146]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.types",["loc",[null,[184,34],[184,47]]]],"type-appEtiga"],[],["loc",[null,[184,16],[184,64]]]]],[],6,7,["loc",[null,[184,10],[192,17]]]],
        ["inline","button-etiga-small",[],["content","","classNames","inline-icon","size","16","width",28],["loc",[null,[193,75],[193,152]]]],
        ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[196,80],[196,140]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.types",["loc",[null,[197,32],[197,45]]]],"type-task"],[],["loc",[null,[197,14],[197,58]]]]],[],8,9,["loc",[null,[197,8],[205,15]]]],
        ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames","mt20 float-right topToggle","active",["subexpr","@mut",[["get","catsExpanded",["loc",[null,[212,104],[212,116]]]]],[],[]],"toggleAll","toggleCats","content","ein/ausblenden"],["loc",[null,[212,8],[212,166]]]],
        ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","catsExpanded",["loc",[null,[215,56],[215,68]]]],"","hidden"],[],["loc",[null,[215,35],[215,82]]]]]]],
        ["block","each",[["get","categories",["loc",[null,[216,16],[216,26]]]]],[],10,null,["loc",[null,[216,8],[236,17]]]],
        ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames","mt20 float-right topToggle","active",["subexpr","@mut",[["get","usersExpanded",["loc",[null,[241,104],[241,117]]]]],[],[]],"toggleAll","toggleUsers","content","ein/ausblenden"],["loc",[null,[241,8],[241,168]]]],
        ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","usersExpanded",["loc",[null,[244,56],[244,69]]]],"","hidden"],[],["loc",[null,[244,35],[244,83]]]]]]],
        ["element","action",["triggerRefreshUser","all"],["on","change","preventDefault",false],["loc",[null,[246,64],[246,134]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.users",["loc",[null,[247,34],[247,47]]]],"user-9999"],[],["loc",[null,[247,16],[247,60]]]]],[],11,12,["loc",[null,[247,10],[255,17]]]],
        ["block","each",[["get","users",["loc",[null,[258,16],[258,21]]]]],[],13,null,["loc",[null,[258,8],[280,17]]]],
        ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[283,12],[283,20]]]]],[],14,null,["loc",[null,[283,6],[305,13]]]],
        ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames"," mt20 float-right topToggle","active",["subexpr","@mut",[["get","roomsExpanded",["loc",[null,[308,105],[308,118]]]]],[],[]],"toggleAll","toggleRooms","content","ein/ausblenden"],["loc",[null,[308,8],[308,169]]]],
        ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","roomsExpanded",["loc",[null,[310,56],[310,69]]]],"","hidden"],[],["loc",[null,[310,35],[310,83]]]]]]],
        ["element","action",["triggerRefreshLocation","all"],["on","change","preventDefault",false],["loc",[null,[311,64],[311,138]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.rooms",["loc",[null,[312,34],[312,47]]]],"room-9999"],[],["loc",[null,[312,16],[312,60]]]]],[],15,16,["loc",[null,[312,10],[320,17]]]],
        ["block","each",[["get","rooms",["loc",[null,[323,16],[323,21]]]]],[],17,null,["loc",[null,[323,8],[336,17]]]],
        ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames"," mt20 float-right topToggle","active",["subexpr","@mut",[["get","stationsExpanded",["loc",[null,[341,105],[341,121]]]]],[],[]],"toggleAll","toggleStations","content","ein/ausblenden"],["loc",[null,[341,8],[341,175]]]],
        ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","stationsExpanded",["loc",[null,[343,56],[343,72]]]],"","hidden"],[],["loc",[null,[343,35],[343,86]]]]]]],
        ["element","action",["triggerRefreshStation","all"],["on","change","preventDefault",false],["loc",[null,[344,64],[344,137]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.stations",["loc",[null,[345,34],[345,50]]]],"station-9999"],[],["loc",[null,[345,16],[345,66]]]]],[],18,19,["loc",[null,[345,10],[353,17]]]],
        ["block","each",[["get","stations",["loc",[null,[357,16],[357,24]]]]],[],20,null,["loc",[null,[357,8],[370,17]]]],
        ["block","unless",[["get","sidebarActive",["loc",[null,[375,12],[375,25]]]]],[],21,null,["loc",[null,[375,2],[378,13]]]],
        ["inline","show-task-panel",[],["actionReceiver",["subexpr","@mut",[["get","showTaskPanel",["loc",[null,[380,33],[380,46]]]]],[],[]],"goto","goto","startTask","startTask","linkTo","linkTo","create","createNotification","addReply","addReply","finishTask","finishTaskSend","addMessage","addMessage","changeOwner","changeOwner"],["loc",[null,[380,0],[388,45]]]],
        ["inline","add-appointment-panel",[],["categories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[390,35],[390,52]]]]],[],[]],"etigaCategories",["subexpr","@mut",[["get","etigaCategories",["loc",[null,[391,40],[391,55]]]]],[],[]],"users",["subexpr","@mut",[["get","users",["loc",[null,[392,30],[392,35]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[393,32],[393,39]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[394,35],[394,45]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[395,34],[395,43]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[396,30],[396,35]]]]],[],[]],"users",["subexpr","@mut",[["get","users",["loc",[null,[397,30],[397,35]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[398,33],[398,41]]]]],[],[]],"showDelete",true,"showSeries",true,"supportUserApps",true,"showAdmission",true,"gotoPatient","gotoPatient","gotoEtigaMaster","gotoPatientEtiga","reloadPatients","reloadPatients","startAdmission","admissionPatient","gotoTreatment","gotoTreatment","create","createAppointment","delete","deleteAppointmentCheckEtiga","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[410,39],[410,58]]]]],[],[]]],["loc",[null,[390,0],[410,60]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[416,8],[416,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[417,10],[417,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[418,13],[418,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[419,17],[419,36]]]]],[],[]]],["loc",[null,[412,0],[419,38]]]],
        ["inline","app-request-confirm-panel",[],["confirm","confirmAppRequest","gotoPatient","gotoPatient","gotoPatientEtiga","gotoPatientEtiga","gotoCustomer","gotoCustomer","abort","abortAppRequest","actionReceiver",["subexpr","@mut",[["get","appRequestConfirmPanel",["loc",[null,[424,95],[424,117]]]]],[],[]]],["loc",[null,[421,0],[424,119]]]],
        ["inline","app-denial-panel",[],["confirm","deleteAppointment","gotoPatient","gotoPatient","gotoPatientEtiga","gotoPatientEtiga","gotoCustomer","gotoCustomer","actionReceiver",["subexpr","@mut",[["get","appDenialPanel",["loc",[null,[430,43],[430,57]]]]],[],[]]],["loc",[null,[426,0],[430,59]]]],
        ["inline","ical-link-panel",[],["actionReceiver",["subexpr","@mut",[["get","icalLinkPanel",["loc",[null,[432,33],[432,46]]]]],[],[]]],["loc",[null,[432,0],[432,48]]]],
        ["inline","choose-appointment-edit-type-panel",[],["editEvent","editEvent","actionReceiver",["subexpr","@mut",[["get","chooseAppointmentEditTypePanel",["loc",[null,[433,74],[433,104]]]]],[],[]]],["loc",[null,[433,0],[433,106]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21]
    };
  }()));

});