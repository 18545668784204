define('jason-frontend/templates/components/customer-etiga', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 41
              },
              "end": {
                "line": 7,
                "column": 138
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","label label-unselected");
            var el2 = dom.createTextNode("Nicht mit eltiga verknüpft");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 146
                },
                "end": {
                  "line": 7,
                  "column": 234
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","label label-success");
              var el2 = dom.createTextNode("Mit eltiga verknüpft");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 234
                },
                "end": {
                  "line": 7,
                  "column": 312
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","label label-unselected");
              var el2 = dom.createTextNode("Nicht mit eltiga verknüpft");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 138
              },
              "end": {
                "line": 7,
                "column": 319
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","patient.etigaActive",["loc",[null,[7,152],[7,171]]]]],[],0,1,["loc",[null,[7,146],[7,319]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 42
              },
              "end": {
                "line": 10,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-disconnect-user",[],["item",["subexpr","@mut",[["get","patient",["loc",[null,[9,36],[9,43]]]]],[],[]],"confirmed","disconnectEtiga","color","#3B6182","size","32","showStroke",false,"content","","text","Wollen Sie Ihre eigenen Stammdaten wirklich von eltiga trennen?"],["loc",[null,[9,6],[9,198]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 4
                },
                "end": {
                  "line": 14,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","ml10 label inline-block button verticaltop button purple-style");
              var el2 = dom.createTextNode("Mit eltiga verknüpfen\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element21 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element21);
              return morphs;
            },
            statements: [
              ["element","action",["chooseEtigaPanel",["get","patient.id",["loc",[null,[12,108],[12,118]]]]],[],["loc",[null,[12,80],[12,120]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 15,
                    "column": 6
                  },
                  "end": {
                    "line": 18,
                    "column": 6
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","ml10 label inline-block purple-style button verticaltop");
                var el2 = dom.createTextNode("Mit eltiga verknüpfen\n      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element20 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element20);
                return morphs;
              },
              statements: [
                ["element","action",["activateEtiga",["get","patient",["loc",[null,[16,98],[16,105]]]]],[],["loc",[null,[16,73],[16,107]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 4
                },
                "end": {
                  "line": 19,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","has-permission",["etiga"],[],["loc",[null,[15,12],[15,36]]]]],[],0,null,["loc",[null,[15,6],[18,13]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 2
              },
              "end": {
                "line": 21,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n  ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["block","if",[["get","patient.etigaMaster",["loc",[null,[11,10],[11,29]]]]],[],0,1,["loc",[null,[11,4],[19,11]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 41
              },
              "end": {
                "line": 23,
                "column": 212
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","label label-success");
            var el2 = dom.createTextNode("Eigene Daten freigegeben");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 212
              },
              "end": {
                "line": 23,
                "column": 295
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","label label-unselected");
            var el2 = dom.createTextNode("Keine eigenen Daten freigegeben");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 4
              },
              "end": {
                "line": 29,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","ml10 label inline-block button with-icon verticaltop");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" Daten freigeben\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element19 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element19);
            morphs[1] = dom.createMorphAt(element19,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["addEtigaShare"],[],["loc",[null,[26,68],[26,94]]]],
            ["inline","button-locked",[],["color","#3B6182","size","32","classNames","pt2","showStroke",false,"content","Neue Freigabe hinzufügen"],["loc",[null,[27,6],[27,118]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 41
              },
              "end": {
                "line": 32,
                "column": 189
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","label label-success");
            var el2 = dom.createTextNode("Daten für mich freigegeben");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child8 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 189
              },
              "end": {
                "line": 32,
                "column": 273
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","label label-unselected");
            var el2 = dom.createTextNode("Keine Daten für mich freigegeben");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child9 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 6
                },
                "end": {
                  "line": 47,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"id","mediaCategory");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("input");
              dom.setAttribute(el2,"class","input-md");
              dom.setAttribute(el2,"type","hidden");
              dom.setAttribute(el2,"value","declaration");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","is-highlighted");
              var el2 = dom.createTextNode("Willenserklärung ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"for","pickFile");
              dom.setAttribute(el1,"class","pl10 input-element");
              dom.setAttribute(el1,"id","pickFile");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [7]),1,1);
              return morphs;
            },
            statements: [
              ["inline","button-download",[],["color","#ADCCD4","classNames","inline-icon mtm4","content",""],["loc",[null,[42,8],[42,84]]]],
              ["inline","file-upload",[],["uploadDone","uploadDone"],["loc",[null,[45,10],[45,49]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 48,
                  "column": 4
                },
                "end": {
                  "line": 51,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","cursor is-highlighted");
              var el2 = dom.createTextNode("Zugangsdaten für APP-BenutzerIn");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","cursor is-highlighted");
              var el2 = dom.createTextNode(" App Zugangsdaten herunterladen");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element15 = dom.childAt(fragment, [4]);
              var element16 = dom.childAt(fragment, [9]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
              morphs[1] = dom.createElementMorph(element15);
              morphs[2] = dom.createMorphAt(fragment,7,7,contextualElement);
              morphs[3] = dom.createElementMorph(element16);
              return morphs;
            },
            statements: [
              ["inline","button-download",[],["color","#ADCCD4","classNames","inline-icon mtm4","content",""],["loc",[null,[49,11],[49,87]]]],
              ["element","action",["showAppCredentials",["get","patient.id",["loc",[null,[49,121],[49,131]]]],["get","patient",["loc",[null,[49,132],[49,139]]]]],[],["loc",[null,[49,91],[49,141]]]],
              ["inline","button-qr",[],["color","#ADCCD4","classNames","inline-icon mtm4","content","","showStroke",false],["loc",[null,[50,11],[50,98]]]],
              ["element","action",["downloadEtigaQr"],[],["loc",[null,[50,102],[50,130]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 56,
                  "column": 160
                },
                "end": {
                  "line": 56,
                  "column": 214
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Für mich freigegebene Daten");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 56,
                  "column": 214
                },
                "end": {
                  "line": 56,
                  "column": 248
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Von mir freigegebene Daten");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 64,
                    "column": 88
                  },
                  "end": {
                    "line": 64,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("von");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 64,
                    "column": 118
                  },
                  "end": {
                    "line": 64,
                    "column": 129
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("für");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 75,
                      "column": 16
                    },
                    "end": {
                      "line": 79,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","line");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["content","entry.mandantName",["loc",[null,[77,18],[77,39]]]]
                ],
                locals: ["entry"],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 83,
                        "column": 18
                      },
                      "end": {
                        "line": 98,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","line");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("label");
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("span");
                    dom.setAttribute(el3,"class","title");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                      ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element12 = dom.childAt(fragment, [1, 1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element12, 'class');
                    morphs[1] = dom.createMorphAt(element12,3,3);
                    return morphs;
                  },
                  statements: [
                    ["attribute","class",["concat",["u-mb0 field select etiga ",["get","entry.permissionOwner",["loc",[null,[85,63],[85,84]]]]]]],
                    ["inline","select-2",[],["content",["subexpr","@mut",[["get","permissionTypesListReadWriteOnly",["loc",[null,[88,34],[88,66]]]]],[],[]],"value",["subexpr","@mut",[["get","entry.permissionOwner",["loc",[null,[89,32],[89,53]]]]],[],[]],"searchEnabled",false,"optionLabelPath","value","optionValuePath","id","allowClear",false,"cssClass","input--dropdown"],["loc",[null,[87,24],[95,26]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 99,
                            "column": 20
                          },
                          "end": {
                            "line": 99,
                            "column": 119
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1,"class","label-etiga-permission label");
                        var el2 = dom.createTextNode("Keine");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 100,
                            "column": 20
                          },
                          "end": {
                            "line": 100,
                            "column": 124
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1,"class","label-etiga-permission read label");
                        var el2 = dom.createTextNode("Lesen");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child2 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 101,
                            "column": 20
                          },
                          "end": {
                            "line": 101,
                            "column": 131
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1,"class","label-etiga-permission write label");
                        var el2 = dom.createTextNode("Bearbeiten");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 98,
                          "column": 18
                        },
                        "end": {
                          "line": 102,
                          "column": 18
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(3);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                      morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[99,30],[99,54]]]],"none"],[],["loc",[null,[99,26],[99,62]]]]],[],0,null,["loc",[null,[99,20],[99,126]]]],
                      ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[100,30],[100,54]]]],"read"],[],["loc",[null,[100,26],[100,62]]]]],[],1,null,["loc",[null,[100,20],[100,131]]]],
                      ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[101,30],[101,54]]]],"write"],[],["loc",[null,[101,26],[101,63]]]]],[],2,null,["loc",[null,[101,20],[101,138]]]]
                    ],
                    locals: [],
                    templates: [child0, child1, child2]
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 102,
                          "column": 18
                        },
                        "end": {
                          "line": 117,
                          "column": 18
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","line");
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("label");
                      var el3 = dom.createTextNode("\n                        ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("span");
                      dom.setAttribute(el3,"class","title");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                        ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                      ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                  ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element11 = dom.childAt(fragment, [1, 1]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createAttrMorph(element11, 'class');
                      morphs[1] = dom.createMorphAt(element11,3,3);
                      return morphs;
                    },
                    statements: [
                      ["attribute","class",["concat",["u-mb0 field select etiga ",["get","entry.permissionPractice",["loc",[null,[104,63],[104,87]]]]]]],
                      ["inline","select-2",[],["content",["subexpr","@mut",[["get","permissionTypesList",["loc",[null,[107,34],[107,53]]]]],[],[]],"value",["subexpr","@mut",[["get","entry.permissionPractice",["loc",[null,[108,32],[108,56]]]]],[],[]],"searchEnabled",false,"optionLabelPath","value","optionValuePath","id","allowClear",false,"cssClass","input--dropdown"],["loc",[null,[106,24],[114,26]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 98,
                        "column": 18
                      },
                      "end": {
                        "line": 117,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","or",[["subexpr","eq",[["get","entry.mandantId",["loc",[null,[98,36],[98,51]]]],4],[],["loc",[null,[98,32],[98,54]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[98,59],[98,74]]]],"masterData"],[],["loc",[null,[98,55],[98,88]]]]],[],["loc",[null,[98,28],[98,89]]]]],[],0,1,["loc",[null,[98,18],[117,18]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 82,
                      "column": 16
                    },
                    "end": {
                      "line": 118,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","item.permission",["loc",[null,[83,28],[83,43]]]],"masterData"],[],["loc",[null,[83,24],[83,57]]]]],[],0,1,["loc",[null,[83,18],[117,25]]]]
                ],
                locals: ["entry"],
                templates: [child0, child1]
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 123,
                        "column": 18
                      },
                      "end": {
                        "line": 125,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["content","entry.date",["loc",[null,[124,20],[124,34]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 128,
                          "column": 20
                        },
                        "end": {
                          "line": 130,
                          "column": 20
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-copy-permissions",[],["reference",["subexpr","@mut",[["get","entry.mandantId",["loc",[null,[129,58],[129,73]]]]],[],[]],"type","prim","content","Datum für alle Berechtigungen übernehmen","color","#ADCCD4","size",26,"copyPermissions","expandDate","classNames","ml30 pt2 inline-icon"],["loc",[null,[129,22],[129,225]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 125,
                        "column": 18
                      },
                      "end": {
                        "line": 131,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element10 = dom.childAt(fragment, [3]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    morphs[1] = dom.createElementMorph(element10);
                    morphs[2] = dom.createMorphAt(element10,0,0);
                    morphs[3] = dom.createMorphAt(fragment,5,5,contextualElement);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","input",[],["value",["subexpr","@mut",[["get","entry.date",["loc",[null,[126,34],[126,44]]]]],[],[]],"class",["subexpr","if",[["subexpr","eq",[["get","item.permission",["loc",[null,[126,60],[126,75]]]],"customerData"],[],["loc",[null,[126,56],[126,91]]]],["subexpr","concat",[["get","entry.title",["loc",[null,[126,100],[126,111]]]]," mandant",["get","entry.mandantId",["loc",[null,[126,122],[126,137]]]]," prim js-date-picker input date-picker__input fixed-datepicker-width"],[],["loc",[null,[126,92],[126,209]]]],["subexpr","concat",[["get","entry.title",["loc",[null,[126,218],[126,229]]]]," mandant",["get","entry.mandantId",["loc",[null,[126,240],[126,255]]]]," js-date-picker input date-picker__input fixed-datepicker-width"],[],["loc",[null,[126,210],[126,322]]]]],[],["loc",[null,[126,52],[126,323]]]],"name","entry-date"],["loc",[null,[126,20],[126,343]]]],
                    ["element","action",["deleteDate",["get","entry",["loc",[null,[127,45],[127,50]]]]],[],["loc",[null,[127,23],[127,52]]]],
                    ["inline","button-delete-list",[],["size","26","classNames","cursor inline-block delete-etiga-permission"],["loc",[null,[127,53],[127,142]]]],
                    ["block","if",[["subexpr","eq",[["get","item.permission",["loc",[null,[128,30],[128,45]]]],"customerData"],[],["loc",[null,[128,26],[128,61]]]]],[],0,null,["loc",[null,[128,20],[130,27]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 121,
                      "column": 16
                    },
                    "end": {
                      "line": 133,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","line");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","readOnly",["loc",[null,[123,24],[123,32]]]]],[],0,1,["loc",[null,[123,18],[131,25]]]]
                ],
                locals: ["entry"],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 69,
                    "column": 12
                  },
                  "end": {
                    "line": 136,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createComment("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-6/24@desk");
                dom.setAttribute(el2,"data-label","Datentyp");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24@desk");
                dom.setAttribute(el2,"data-label","Freigegeben für");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-4/24@desk");
                dom.setAttribute(el2,"data-label","Berechtigung");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-6/24@desk");
                dom.setAttribute(el2,"data-label","Daten seit");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element13 = dom.childAt(fragment, [1]);
                var morphs = new Array(5);
                morphs[0] = dom.createAttrMorph(element13, 'class');
                morphs[1] = dom.createMorphAt(dom.childAt(element13, [1, 1]),0,0);
                morphs[2] = dom.createMorphAt(dom.childAt(element13, [3]),1,1);
                morphs[3] = dom.createMorphAt(dom.childAt(element13, [5]),1,1);
                morphs[4] = dom.createMorphAt(dom.childAt(element13, [7]),1,1);
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["table__row ",["get","item.permission",["loc",[null,[70,39],[70,54]]]]]]],
                ["inline","get-permission-title",[["get","item.permission",["loc",[null,[72,45],[72,60]]]]],[],["loc",[null,[72,22],[72,62]]]],
                ["block","each",[["get","item.entries",["loc",[null,[75,24],[75,36]]]]],[],0,null,["loc",[null,[75,16],[79,25]]]],
                ["block","each",[["get","item.entries",["loc",[null,[82,24],[82,36]]]]],[],1,null,["loc",[null,[82,16],[118,25]]]],
                ["block","each",[["get","item.entries",["loc",[null,[121,24],[121,36]]]]],[],2,null,["loc",[null,[121,16],[133,25]]]]
              ],
              locals: ["item"],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 60,
                  "column": 6
                },
                "end": {
                  "line": 139,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table box no-box@phone mt20 pb0 ");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__head");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell l-6/24");
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","table__title title");
              var el5 = dom.createTextNode("Datentyp");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell l-8/24");
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","table__title title");
              var el5 = dom.createTextNode("Freigegeben ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell l-4/24");
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","table__title title");
              var el5 = dom.createTextNode("Berechtigung");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell l-6/24");
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","table__title title");
              var el5 = dom.createTextNode("Daten seit");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__body");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element14 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element14, [1, 3, 0]),1,1);
              morphs[1] = dom.createMorphAt(dom.childAt(element14, [3]),1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["get","patient.etigaMaster",["loc",[null,[64,94],[64,113]]]]],[],0,1,["loc",[null,[64,88],[64,136]]]],
              ["block","each",[["get","permissions.masterEntries",["loc",[null,[69,20],[69,45]]]]],[],2,null,["loc",[null,[69,12],[136,21]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child5 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 142,
                    "column": 8
                  },
                  "end": {
                    "line": 149,
                    "column": 8
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__head");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-6/24");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","table__title title");
                var el4 = dom.createTextNode("Datentyp");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","table__title title");
                var el4 = dom.createTextNode("Freigegeben für");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-4/24");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","table__title title");
                var el4 = dom.createTextNode("Berechtigung");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-6/24");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","table__title title");
                var el4 = dom.createTextNode("Daten seit");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 157,
                      "column": 14
                    },
                    "end": {
                      "line": 161,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","line");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["content","entry.mandantName",["loc",[null,[159,18],[159,39]]]]
                ],
                locals: ["entry"],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 166,
                        "column": 16
                      },
                      "end": {
                        "line": 168,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","label-etiga-permission label");
                    var el2 = dom.createTextNode("Keine");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 168,
                          "column": 16
                        },
                        "end": {
                          "line": 170,
                          "column": 16
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","label-etiga-permission read label");
                      var el2 = dom.createTextNode("Lesen");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 170,
                            "column": 16
                          },
                          "end": {
                            "line": 172,
                            "column": 16
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                  ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1,"class","label-etiga-permission read label");
                        var el2 = dom.createTextNode("Lesen");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 172,
                              "column": 16
                            },
                            "end": {
                              "line": 187,
                              "column": 16
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                    ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","line");
                          var el2 = dom.createTextNode("\n                      ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("label");
                          var el3 = dom.createTextNode("\n                        ");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createElement("span");
                          dom.setAttribute(el3,"class","title");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                        ");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                      ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                    ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element7 = dom.childAt(fragment, [1, 1]);
                          var morphs = new Array(2);
                          morphs[0] = dom.createAttrMorph(element7, 'class');
                          morphs[1] = dom.createMorphAt(element7,3,3);
                          return morphs;
                        },
                        statements: [
                          ["attribute","class",["concat",["u-mb0 field select etiga ",["get","entry.permissionOwner",["loc",[null,[174,63],[174,84]]]]]]],
                          ["inline","select-2",[],["content",["subexpr","@mut",[["get","permissionTypesListReadWriteOnly",["loc",[null,[177,34],[177,66]]]]],[],[]],"value",["subexpr","@mut",[["get","entry.permissionOwner",["loc",[null,[178,32],[178,53]]]]],[],[]],"searchEnabled",false,"optionLabelPath","value","optionValuePath","id","allowClear",false,"cssClass","input--dropdown"],["loc",[null,[176,24],[184,26]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      var child0 = (function() {
                        var child0 = (function() {
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 188,
                                  "column": 18
                                },
                                "end": {
                                  "line": 188,
                                  "column": 117
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createElement("span");
                              dom.setAttribute(el1,"class","label-etiga-permission label");
                              var el2 = dom.createTextNode("Keine");
                              dom.appendChild(el1, el2);
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes() { return []; },
                            statements: [

                            ],
                            locals: [],
                            templates: []
                          };
                        }());
                        var child1 = (function() {
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 189,
                                  "column": 18
                                },
                                "end": {
                                  "line": 189,
                                  "column": 122
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createElement("span");
                              dom.setAttribute(el1,"class","label-etiga-permission read label");
                              var el2 = dom.createTextNode("Lesen");
                              dom.appendChild(el1, el2);
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes() { return []; },
                            statements: [

                            ],
                            locals: [],
                            templates: []
                          };
                        }());
                        var child2 = (function() {
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 190,
                                  "column": 18
                                },
                                "end": {
                                  "line": 190,
                                  "column": 129
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createElement("span");
                              dom.setAttribute(el1,"class","label-etiga-permission write label");
                              var el2 = dom.createTextNode("Bearbeiten");
                              dom.appendChild(el1, el2);
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes() { return []; },
                            statements: [

                            ],
                            locals: [],
                            templates: []
                          };
                        }());
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 187,
                                "column": 16
                              },
                              "end": {
                                "line": 191,
                                "column": 16
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                  ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n                  ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n                  ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(3);
                            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                            morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                            return morphs;
                          },
                          statements: [
                            ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[188,28],[188,52]]]],"none"],[],["loc",[null,[188,24],[188,60]]]]],[],0,null,["loc",[null,[188,18],[188,124]]]],
                            ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[189,28],[189,52]]]],"read"],[],["loc",[null,[189,24],[189,60]]]]],[],1,null,["loc",[null,[189,18],[189,129]]]],
                            ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[190,28],[190,52]]]],"write"],[],["loc",[null,[190,24],[190,61]]]]],[],2,null,["loc",[null,[190,18],[190,136]]]]
                          ],
                          locals: [],
                          templates: [child0, child1, child2]
                        };
                      }());
                      var child1 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 191,
                                "column": 16
                              },
                              "end": {
                                "line": 206,
                                "column": 16
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            dom.setAttribute(el1,"class","line");
                            var el2 = dom.createTextNode("\n                  ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("label");
                            var el3 = dom.createTextNode("\n                    ");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createElement("span");
                            dom.setAttribute(el3,"class","title");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                    ");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                  ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n                ");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element6 = dom.childAt(fragment, [1, 1]);
                            var morphs = new Array(2);
                            morphs[0] = dom.createAttrMorph(element6, 'class');
                            morphs[1] = dom.createMorphAt(element6,3,3);
                            return morphs;
                          },
                          statements: [
                            ["attribute","class",["concat",["u-mb0 field select etiga ",["get","entry.permissionPractice",["loc",[null,[193,59],[193,83]]]]]]],
                            ["inline","select-2",[],["content",["subexpr","@mut",[["get","permissionTypesList",["loc",[null,[196,30],[196,49]]]]],[],[]],"value",["subexpr","@mut",[["get","entry.permissionPractice",["loc",[null,[197,28],[197,52]]]]],[],[]],"searchEnabled",false,"optionLabelPath","value","optionValuePath","id","allowClear",false,"cssClass","input--dropdown"],["loc",[null,[195,20],[203,22]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 187,
                              "column": 16
                            },
                            "end": {
                              "line": 206,
                              "column": 16
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["subexpr","eq",[["get","entry.mandantId",["loc",[null,[187,30],[187,45]]]],4],[],["loc",[null,[187,26],[187,48]]]]],[],0,1,["loc",[null,[187,16],[206,16]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 172,
                            "column": 16
                          },
                          "end": {
                            "line": 206,
                            "column": 16
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.mandantId",["loc",[null,[172,35],[172,50]]]],4],[],["loc",[null,[172,31],[172,53]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[172,58],[172,73]]]],"addWeight"],[],["loc",[null,[172,54],[172,86]]]]],[],["loc",[null,[172,26],[172,87]]]]],[],0,1,["loc",[null,[172,16],[206,16]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 170,
                          "column": 16
                        },
                        "end": {
                          "line": 206,
                          "column": 16
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["subexpr","eq",[["get","item.permission",["loc",[null,[170,30],[170,45]]]],"addDocs"],[],["loc",[null,[170,26],[170,56]]]]],[],0,1,["loc",[null,[170,16],[206,16]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 168,
                        "column": 16
                      },
                      "end": {
                        "line": 206,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","item.permission",["loc",[null,[168,30],[168,45]]]],"addCorr"],[],["loc",[null,[168,26],[168,56]]]]],[],0,1,["loc",[null,[168,16],[206,16]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 164,
                      "column": 14
                    },
                    "end": {
                      "line": 207,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.mandantId",["loc",[null,[166,31],[166,46]]]],4],[],["loc",[null,[166,27],[166,49]]]],["subexpr","or",[["subexpr","eq",[["get","item.permission",["loc",[null,[166,58],[166,73]]]],"addAnam"],[],["loc",[null,[166,54],[166,84]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[166,89],[166,104]]]],"addSymp"],[],["loc",[null,[166,85],[166,115]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[166,120],[166,135]]]],"addThera"],[],["loc",[null,[166,116],[166,147]]]]],[],["loc",[null,[166,50],[166,148]]]]],[],["loc",[null,[166,22],[166,149]]]]],[],0,1,["loc",[null,[166,16],[206,23]]]]
                ],
                locals: ["entry"],
                templates: [child0, child1]
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 212,
                        "column": 16
                      },
                      "end": {
                        "line": 214,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 214,
                          "column": 16
                        },
                        "end": {
                          "line": 216,
                          "column": 16
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 217,
                            "column": 18
                          },
                          "end": {
                            "line": 219,
                            "column": 18
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                    ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        return morphs;
                      },
                      statements: [
                        ["content","entry.date",["loc",[null,[218,20],[218,34]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 222,
                              "column": 20
                            },
                            "end": {
                              "line": 224,
                              "column": 20
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                      ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                          return morphs;
                        },
                        statements: [
                          ["inline","button-copy-permissions",[],["reference",["subexpr","@mut",[["get","entry.mandantId",["loc",[null,[223,58],[223,73]]]]],[],[]],"type","prim2","content","Datum für alle Berechtigungen übernehmen","color","#ADCCD4","size",26,"copyPermissions","expandDate","classNames","ml30 pt2 inline-icon"],["loc",[null,[223,22],[223,226]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 219,
                            "column": 18
                          },
                          "end": {
                            "line": 225,
                            "column": 18
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                    ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n                    ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("a");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element5 = dom.childAt(fragment, [3]);
                        var morphs = new Array(4);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        morphs[1] = dom.createElementMorph(element5);
                        morphs[2] = dom.createMorphAt(element5,0,0);
                        morphs[3] = dom.createMorphAt(fragment,5,5,contextualElement);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["inline","input",[],["value",["subexpr","@mut",[["get","entry.date",["loc",[null,[220,34],[220,44]]]]],[],[]],"class",["subexpr","if",[["subexpr","eq",[["get","item.permission",["loc",[null,[220,59],[220,74]]]],"addDiag"],[],["loc",[null,[220,55],[220,85]]]],["subexpr","concat",[["get","entry.title",["loc",[null,[220,94],[220,105]]]]," mandant",["get","entry.mandantId",["loc",[null,[220,116],[220,131]]]]," prim2 js-date-picker input date-picker__input fixed-datepicker-width"],[],["loc",[null,[220,86],[220,204]]]],["subexpr","concat",[["get","entry.title",["loc",[null,[220,214],[220,225]]]]," mandant",["get","entry.mandantId",["loc",[null,[220,236],[220,251]]]]," js-date-picker input date-picker__input fixed-datepicker-width"],[],["loc",[null,[220,205],[220,318]]]]],[],["loc",[null,[220,51],[220,319]]]],"name","entry-date"],["loc",[null,[220,20],[220,339]]]],
                        ["element","action",["deleteDate",["get","entry",["loc",[null,[221,45],[221,50]]]]],[],["loc",[null,[221,23],[221,52]]]],
                        ["inline","button-delete-list",[],["size","26","classNames","cursor inline-block delete-etiga-permission"],["loc",[null,[221,53],[221,142]]]],
                        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.mandantId",["loc",[null,[222,35],[222,50]]]],4],[],["loc",[null,[222,31],[222,53]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[222,58],[222,73]]]],"addDiag"],[],["loc",[null,[222,54],[222,84]]]]],[],["loc",[null,[222,26],[222,85]]]]],[],0,null,["loc",[null,[222,20],[224,27]]]]
                      ],
                      locals: [],
                      templates: [child0]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 216,
                          "column": 16
                        },
                        "end": {
                          "line": 226,
                          "column": 18
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","readOnly",["loc",[null,[217,24],[217,32]]]]],[],0,1,["loc",[null,[217,18],[225,25]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 214,
                        "column": 16
                      },
                      "end": {
                        "line": 226,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.mandantId",["loc",[null,[214,35],[214,50]]]],4],[],["loc",[null,[214,31],[214,53]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[214,58],[214,73]]]],"addWeight"],[],["loc",[null,[214,54],[214,86]]]]],[],["loc",[null,[214,26],[214,87]]]]],[],0,1,["loc",[null,[214,16],[226,18]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 210,
                      "column": 14
                    },
                    "end": {
                      "line": 228,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","line");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.mandantId",["loc",[null,[212,31],[212,46]]]],4],[],["loc",[null,[212,27],[212,49]]]],["subexpr","or",[["subexpr","eq",[["get","item.permission",["loc",[null,[212,58],[212,73]]]],"addAnam"],[],["loc",[null,[212,54],[212,84]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[212,89],[212,104]]]],"addSymp"],[],["loc",[null,[212,85],[212,115]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[212,120],[212,135]]]],"addCorr"],[],["loc",[null,[212,116],[212,146]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[212,151],[212,166]]]],"addThera"],[],["loc",[null,[212,147],[212,178]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[212,183],[212,198]]]],"addLabor"],[],["loc",[null,[212,179],[212,210]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[212,215],[212,230]]]],"addDocs"],[],["loc",[null,[212,211],[212,241]]]]],[],["loc",[null,[212,50],[212,242]]]]],[],["loc",[null,[212,22],[212,243]]]]],[],0,1,["loc",[null,[212,16],[226,25]]]]
                ],
                locals: ["entry"],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 151,
                    "column": 10
                  },
                  "end": {
                    "line": 231,
                    "column": 10
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createComment("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-6/24@desk");
                dom.setAttribute(el2,"data-label","Datentyp");
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24@desk");
                dom.setAttribute(el2,"data-label","Freigegeben für");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-4/24@desk");
                dom.setAttribute(el2,"data-label","Berechtigung");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-6/24@desk");
                dom.setAttribute(el2,"data-label","Daten seit");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element8 = dom.childAt(fragment, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(dom.childAt(element8, [1, 1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element8, [3]),1,1);
                morphs[2] = dom.createMorphAt(dom.childAt(element8, [5]),1,1);
                morphs[3] = dom.createMorphAt(dom.childAt(element8, [7]),1,1);
                return morphs;
              },
              statements: [
                ["inline","get-permission-title",[["get","item.permission",["loc",[null,[154,43],[154,58]]]]],[],["loc",[null,[154,20],[154,60]]]],
                ["block","each",[["get","item.entries",["loc",[null,[157,22],[157,34]]]]],[],0,null,["loc",[null,[157,14],[161,23]]]],
                ["block","each",[["get","item.entries",["loc",[null,[164,22],[164,34]]]]],[],1,null,["loc",[null,[164,14],[207,23]]]],
                ["block","each",[["get","item.entries",["loc",[null,[210,22],[210,34]]]]],[],2,null,["loc",[null,[210,14],[228,23]]]]
              ],
              locals: ["item"],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 140,
                  "column": 6
                },
                "end": {
                  "line": 235,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table box no-box@phone pt20");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__body");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element9,1,1);
              morphs[1] = dom.createMorphAt(dom.childAt(element9, [3]),1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","not",[["get","patient.etigaOwner",["loc",[null,[142,19],[142,37]]]]],[],["loc",[null,[142,14],[142,38]]]]],[],0,null,["loc",[null,[142,8],[149,15]]]],
              ["block","each",[["get","permissions.additionalEntries",["loc",[null,[151,18],[151,47]]]]],[],1,null,["loc",[null,[151,10],[231,19]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child6 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 235,
                  "column": 6
                },
                "end": {
                  "line": 237,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","pt20 pl20");
              var el2 = dom.createTextNode("Derzeit keine Freigabe eingerichtet");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child7 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 261,
                        "column": 16
                      },
                      "end": {
                        "line": 265,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","line");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                    return morphs;
                  },
                  statements: [
                    ["content","entry.mandantName",["loc",[null,[263,20],[263,41]]]]
                  ],
                  locals: ["entry"],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 270,
                          "column": 20
                        },
                        "end": {
                          "line": 270,
                          "column": 119
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","label-etiga-permission label");
                      var el2 = dom.createTextNode("Keine");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 271,
                          "column": 20
                        },
                        "end": {
                          "line": 271,
                          "column": 124
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","label-etiga-permission read label");
                      var el2 = dom.createTextNode("Lesen");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 272,
                          "column": 20
                        },
                        "end": {
                          "line": 272,
                          "column": 131
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","label-etiga-permission write label");
                      var el2 = dom.createTextNode("Bearbeiten");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 268,
                        "column": 16
                      },
                      "end": {
                        "line": 274,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","line");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element2 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createMorphAt(element2,1,1);
                    morphs[1] = dom.createMorphAt(element2,3,3);
                    morphs[2] = dom.createMorphAt(element2,5,5);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[270,30],[270,54]]]],"none"],[],["loc",[null,[270,26],[270,62]]]]],[],0,null,["loc",[null,[270,20],[270,126]]]],
                    ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[271,30],[271,54]]]],"read"],[],["loc",[null,[271,26],[271,62]]]]],[],1,null,["loc",[null,[271,20],[271,131]]]],
                    ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[272,30],[272,54]]]],"write"],[],["loc",[null,[272,26],[272,63]]]]],[],2,null,["loc",[null,[272,20],[272,138]]]]
                  ],
                  locals: ["entry"],
                  templates: [child0, child1, child2]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 277,
                        "column": 16
                      },
                      "end": {
                        "line": 279,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","line");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","item.date",["loc",[null,[278,36],[278,49]]]]
                  ],
                  locals: ["entry"],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 255,
                      "column": 12
                    },
                    "end": {
                      "line": 282,
                      "column": 12
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createComment("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","table__cell l-6/24@desk");
                  dom.setAttribute(el2,"data-label","Datentyp");
                  var el3 = dom.createTextNode("\n                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","table__cell l-8/24@desk");
                  dom.setAttribute(el2,"data-label","Freigegeben für");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","table__cell l-4/24@desk");
                  dom.setAttribute(el2,"data-label","Berechtigung");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","table__cell l-4/24@desk");
                  dom.setAttribute(el2,"data-label","Daten seit");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [1]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createAttrMorph(element3, 'class');
                  morphs[1] = dom.createMorphAt(dom.childAt(element3, [1, 1]),0,0);
                  morphs[2] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
                  morphs[3] = dom.createMorphAt(dom.childAt(element3, [5]),1,1);
                  morphs[4] = dom.createMorphAt(dom.childAt(element3, [7]),1,1);
                  return morphs;
                },
                statements: [
                  ["attribute","class",["concat",["table__row ",["get","item.permission",["loc",[null,[256,39],[256,54]]]]]]],
                  ["inline","get-permission-title",[["get","item.permission",["loc",[null,[258,45],[258,60]]]]],[],["loc",[null,[258,22],[258,62]]]],
                  ["block","each",[["get","item.entries",["loc",[null,[261,24],[261,36]]]]],[],0,null,["loc",[null,[261,16],[265,25]]]],
                  ["block","each",[["get","item.entries",["loc",[null,[268,24],[268,36]]]]],[],1,null,["loc",[null,[268,16],[274,25]]]],
                  ["block","each",[["get","item.entries",["loc",[null,[277,24],[277,36]]]]],[],2,null,["loc",[null,[277,16],[279,25]]]]
                ],
                locals: ["item"],
                templates: [child0, child1, child2]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 245,
                    "column": 6
                  },
                  "end": {
                    "line": 285,
                    "column": 6
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","pt20 pl20");
                var el2 = dom.createTextNode("Folgende Daten des ausgewählten Patienten wurden von einer anderen Praxis für Sie freigegeben:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table box no-box@phone mt20 pb0 hidden");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__head");
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","table__cell l-6/24");
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","table__title title");
                var el5 = dom.createTextNode("Datentyp");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("\n        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","table__cell l-8/24");
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","table__title title");
                var el5 = dom.createTextNode("Freigegeben von");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("\n        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","table__cell l-4/24");
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","table__title title");
                var el5 = dom.createTextNode("Berechtigung");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("\n        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","table__cell l-4/24");
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","table__title title");
                var el5 = dom.createTextNode("Daten seit");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("\n      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__body");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 3]),1,1);
                return morphs;
              },
              statements: [
                ["block","each",[["get","myPermissions.masterEntries",["loc",[null,[255,20],[255,47]]]]],[],0,null,["loc",[null,[255,12],[282,21]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 285,
                    "column": 6
                  },
                  "end": {
                    "line": 287,
                    "column": 6
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","pt20 pl20");
                var el2 = dom.createTextNode("Derzeit wurden keine eltiga Daten zu diesem Patienten für Sie freigegeben");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 303,
                        "column": 16
                      },
                      "end": {
                        "line": 307,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","line");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                    return morphs;
                  },
                  statements: [
                    ["content","entry.mandantName",["loc",[null,[305,20],[305,41]]]]
                  ],
                  locals: ["entry"],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 312,
                          "column": 20
                        },
                        "end": {
                          "line": 312,
                          "column": 119
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","label-etiga-permission label");
                      var el2 = dom.createTextNode("Keine");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 313,
                          "column": 20
                        },
                        "end": {
                          "line": 313,
                          "column": 124
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","label-etiga-permission read label");
                      var el2 = dom.createTextNode("Lesen");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 314,
                          "column": 20
                        },
                        "end": {
                          "line": 314,
                          "column": 131
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","label-etiga-permission write label");
                      var el2 = dom.createTextNode("Bearbeiten");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 310,
                        "column": 16
                      },
                      "end": {
                        "line": 316,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","line");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createMorphAt(element0,1,1);
                    morphs[1] = dom.createMorphAt(element0,3,3);
                    morphs[2] = dom.createMorphAt(element0,5,5);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[312,30],[312,54]]]],"none"],[],["loc",[null,[312,26],[312,62]]]]],[],0,null,["loc",[null,[312,20],[312,126]]]],
                    ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[313,30],[313,54]]]],"read"],[],["loc",[null,[313,26],[313,62]]]]],[],1,null,["loc",[null,[313,20],[313,131]]]],
                    ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[314,30],[314,54]]]],"write"],[],["loc",[null,[314,26],[314,63]]]]],[],2,null,["loc",[null,[314,20],[314,138]]]]
                  ],
                  locals: ["entry"],
                  templates: [child0, child1, child2]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 319,
                        "column": 16
                      },
                      "end": {
                        "line": 321,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","line");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","item.date",["loc",[null,[320,36],[320,49]]]]
                  ],
                  locals: ["entry"],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 297,
                      "column": 12
                    },
                    "end": {
                      "line": 324,
                      "column": 12
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","table__row");
                  var el2 = dom.createComment("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","table__cell l-6/24@desk");
                  dom.setAttribute(el2,"data-label","Datentyp");
                  var el3 = dom.createTextNode("\n                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","table__cell l-8/24@desk");
                  dom.setAttribute(el2,"data-label","Freigegeben für");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","table__cell l-4/24@desk");
                  dom.setAttribute(el2,"data-label","Berechtigung");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","table__cell l-4/24@desk");
                  dom.setAttribute(el2,"data-label","Daten seit");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(dom.childAt(element1, [1, 1]),0,0);
                  morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]),1,1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element1, [5]),1,1);
                  morphs[3] = dom.createMorphAt(dom.childAt(element1, [7]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","get-permission-title",[["get","item.permission",["loc",[null,[300,45],[300,60]]]]],[],["loc",[null,[300,22],[300,62]]]],
                  ["block","each",[["get","item.entries",["loc",[null,[303,24],[303,36]]]]],[],0,null,["loc",[null,[303,16],[307,25]]]],
                  ["block","each",[["get","item.entries",["loc",[null,[310,24],[310,36]]]]],[],1,null,["loc",[null,[310,16],[316,25]]]],
                  ["block","each",[["get","item.entries",["loc",[null,[319,24],[319,36]]]]],[],2,null,["loc",[null,[319,16],[321,25]]]]
                ],
                locals: ["item"],
                templates: [child0, child1, child2]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 288,
                    "column": 6
                  },
                  "end": {
                    "line": 327,
                    "column": 6
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table box no-box@phone pt0");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__head");
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","table__cell l-6/24");
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","table__title title");
                var el5 = dom.createTextNode("Datentyp");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("\n        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","table__cell l-8/24");
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","table__title title");
                var el5 = dom.createTextNode("Freigegeben von");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("\n        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","table__cell l-4/24");
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","table__title title");
                var el5 = dom.createTextNode("Berechtigung");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("\n        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","table__cell l-4/24");
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","table__title title");
                var el5 = dom.createTextNode("Daten seit");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("\n      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__body");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
                return morphs;
              },
              statements: [
                ["block","each",[["get","myPermissions.additionalEntries",["loc",[null,[297,20],[297,51]]]]],[],0,null,["loc",[null,[297,12],[324,21]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 239,
                  "column": 4
                },
                "end": {
                  "line": 329,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","topToggle");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Für mich freigegebene Daten");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"id","secondShare");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [6]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [4]),1,1);
              morphs[1] = dom.createAttrMorph(element4, 'class');
              morphs[2] = dom.createMorphAt(element4,1,1);
              morphs[3] = dom.createMorphAt(element4,2,2);
              return morphs;
            },
            statements: [
              ["inline","button-toggle-all",[],["size",40,"strokeWidth",1,"classNames","topToggle","active",["subexpr","@mut",[["get","secondShareActive",["loc",[null,[242,78],[242,95]]]]],[],[]],"toggleAll","toggleSecond","content","Freigaben ein/ausblenden"],["loc",[null,[242,6],[242,157]]]],
              ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","secondShareShown",["loc",[null,[244,37],[244,53]]]],"","hidden"],[],["loc",[null,[244,16],[244,67]]]]]]],
              ["block","if",[["subexpr","list-not-empty",[["get","myPermissions.masterEntries",["loc",[null,[245,28],[245,55]]]]],[],["loc",[null,[245,12],[245,56]]]]],[],0,1,["loc",[null,[245,6],[287,13]]]],
              ["block","if",[["subexpr","list-not-empty",[["get","myPermissions.additionalEntries",["loc",[null,[288,28],[288,59]]]]],[],["loc",[null,[288,12],[288,60]]]]],[],2,null,["loc",[null,[288,6],[327,13]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child8 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 330,
                  "column": 4
                },
                "end": {
                  "line": 332,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","js-datepicker-script",["loc",[null,[331,6],[331,30]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 2
              },
              "end": {
                "line": 333,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","topToggle");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","firstShare");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element17 = dom.childAt(fragment, [7]);
            var element18 = dom.childAt(fragment, [9]);
            var morphs = new Array(9);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[2] = dom.createMorphAt(element17,1,1);
            morphs[3] = dom.createMorphAt(dom.childAt(element17, [3]),0,0);
            morphs[4] = dom.createAttrMorph(element18, 'class');
            morphs[5] = dom.createMorphAt(element18,1,1);
            morphs[6] = dom.createMorphAt(element18,2,2);
            morphs[7] = dom.createMorphAt(fragment,11,11,contextualElement);
            morphs[8] = dom.createMorphAt(fragment,12,12,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[38,12],[38,20]]]]],[],0,null,["loc",[null,[38,6],[47,13]]]],
            ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[48,10],[48,18]]]]],[],1,null,["loc",[null,[48,4],[51,11]]]],
            ["inline","button-toggle-all",[],["size",40,"strokeWidth",1,"classNames","topToggle","active",["subexpr","@mut",[["get","firstShareActive",["loc",[null,[56,76],[56,92]]]]],[],[]],"toggleAll","toggleFirst","content","Freigaben ein/ausblenden"],["loc",[null,[56,4],[56,153]]]],
            ["block","if",[["get","patient.etigaMaster",["loc",[null,[56,166],[56,185]]]]],[],2,3,["loc",[null,[56,160],[56,255]]]],
            ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","firstShareShown",["loc",[null,[59,39],[59,54]]]],"","hidden"],[],["loc",[null,[59,18],[59,68]]]]]]],
            ["block","if",[["subexpr","and",[["subexpr","list-not-empty",[["get","permissions.masterEntries",["loc",[null,[60,33],[60,58]]]]],[],["loc",[null,[60,17],[60,59]]]]],[],["loc",[null,[60,12],[60,60]]]]],[],4,null,["loc",[null,[60,6],[139,13]]]],
            ["block","if",[["subexpr","list-not-empty",[["get","permissions.additionalEntries",["loc",[null,[140,28],[140,57]]]]],[],["loc",[null,[140,12],[140,58]]]]],[],5,6,["loc",[null,[140,6],[237,13]]]],
            ["block","if",[["subexpr","not",[["get","patient.etigaMaster",["loc",[null,[239,15],[239,34]]]]],[],["loc",[null,[239,10],[239,35]]]]],[],7,null,["loc",[null,[239,4],[329,11]]]],
            ["block","if",[["subexpr","and",[["get","permissions.master",["loc",[null,[330,15],[330,33]]]],["get","permissions.additional",["loc",[null,[330,34],[330,56]]]]],[],["loc",[null,[330,10],[330,57]]]]],[],8,null,["loc",[null,[330,4],[332,11]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
        };
      }());
      var child10 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 335,
                  "column": 4
                },
                "end": {
                  "line": 337,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("<p>Die Freigabe der Daten des ausgewählten Patienten für die elektronische Tiergesundheitsakte wurde nicht aktiviert.</p>");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 333,
                "column": 2
              },
              "end": {
                "line": 338,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","not",[["get","patient.etigaActive",["loc",[null,[335,15],[335,34]]]]],[],["loc",[null,[335,10],[335,35]]]]],[],0,null,["loc",[null,[335,4],[337,11]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 345,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          dom.setAttribute(el1,"class","flex-center");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("- elektronische Tiergesundheitsakte");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","pl30 l-24/24");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col mb30 pt10 l-8/24");
          var el3 = dom.createElement("b");
          var el4 = dom.createTextNode("Verknüpfung mit eigenen Stammdaten");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col mb30 pt10 l-8/24");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col mb30  l-8/24 pt2");
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col mb10 pt10 l-8/24");
          var el3 = dom.createElement("b");
          var el4 = dom.createTextNode("Freigabe der eigenen Daten");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col mb10 pt10 l-8/24");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col mb30  l-8/24");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col mb10 pt10 l-8/24");
          var el3 = dom.createElement("b");
          var el4 = dom.createTextNode("Freigabe von Daten für mich");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col mb10 pt10 l-8/24");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col mb30  l-8/24");
          var el3 = dom.createTextNode("\n\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element22 = dom.childAt(fragment, [3]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element22, [3]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element22, [5]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element22, [9]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element22, [11]),1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element22, [15]),0,0);
          morphs[6] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[7] = dom.createMorphAt(fragment,7,7,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","icon-etiga",[],["content","","classNames","inline-icon mr10","size","32","width",70,"color","00AAC6"],["loc",[null,[3,26],[3,115]]]],
          ["block","if",[["get","patient.etigaMaster",["loc",[null,[7,47],[7,66]]]]],[],0,1,["loc",[null,[7,41],[7,326]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["get","patient.etigaMaster",["loc",[null,[8,58],[8,77]]]]],[],["loc",[null,[8,53],[8,78]]]],["get","patient.etigaActive",["loc",[null,[8,79],[8,98]]]]],[],["loc",[null,[8,48],[8,99]]]]],[],2,3,["loc",[null,[8,42],[21,9]]]],
          ["block","if",[["subexpr","and",[["get","patient.etigaActive",["loc",[null,[23,52],[23,71]]]],["subexpr","not",[["get","patient.etigaMaster",["loc",[null,[23,77],[23,96]]]]],[],["loc",[null,[23,72],[23,97]]]],["subexpr","list-not-empty",[["get","permissions.additionalEntries",["loc",[null,[23,114],[23,143]]]]],[],["loc",[null,[23,98],[23,144]]]]],[],["loc",[null,[23,47],[23,145]]]]],[],4,5,["loc",[null,[23,41],[23,302]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["get","patient.etigaMaster",["loc",[null,[25,20],[25,39]]]]],[],["loc",[null,[25,15],[25,40]]]],["get","patient.etigaActive",["loc",[null,[25,41],[25,60]]]]],[],["loc",[null,[25,10],[25,61]]]]],[],6,null,["loc",[null,[25,4],[29,9]]]],
          ["block","if",[["subexpr","or",[["get","patient.etigaMaster",["loc",[null,[32,51],[32,70]]]],["subexpr","list-not-empty",[["get","myPermissions.additionalEntries",["loc",[null,[32,87],[32,118]]]]],[],["loc",[null,[32,71],[32,119]]]]],[],["loc",[null,[32,47],[32,120]]]]],[],7,8,["loc",[null,[32,41],[32,280]]]],
          ["block","if",[["get","patient.etigaActive",["loc",[null,[37,8],[37,27]]]]],[],9,10,["loc",[null,[37,2],[338,9]]]],
          ["inline","etiga-app-permission-panel",[],["submitCredentials","submitCredentials","downloadEtigaQr","downloadEtigaQr","actionReceiver",["subexpr","@mut",[["get","etigaAppPermissionPanel",["loc",[null,[343,19],[343,42]]]]],[],[]]],["loc",[null,[340,2],[343,44]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 345,
              "column": 0
            },
            "end": {
              "line": 347,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  Derzeit in Arbeit\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 348,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","eq",[1,1],[],["loc",[null,[1,6],[1,14]]]]],[],0,1,["loc",[null,[1,0],[347,7]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});