define('jason-frontend/routes/labequipments/index', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find('lab-equipment');
    },
    actions: {
      selectTab: function selectTab(selected) {
        var controller = this.get('controller');
        controller.set('selectTab', selected);

        if (selected === 'profiles') {
          controller.set('profiles', this.store.find('lab-profile'));
        }
      },
      addNew: function addNew() {
        this.transitionTo('labprofiles.edit');
      }
    }
  });

});