define('jason-frontend/components/document-upload-widget', ['exports', 'ember', 'ember-uploader', 'jason-frontend/api'], function (exports, Ember, EmberUploader, API) {

  'use strict';

  exports['default'] = EmberUploader['default'].FileField.extend({
    filesDidChange: function filesDidChange(files) {
      var _this = this;

      var panel = $('div.uploadContainer');
      var time = moment(panel.find('#uploadDate').val() + ' ' + panel.find('#uploadTime').val() + moment().format('ss'), 'DD. MM. YYYY HH:mm:ss').unix();

      var data = {
        mandantId: API['default'].getMandant(),
        downloadToken: API['default'].getDownloadToken(),
        folderId: panel.attr('data-folder-id'),
        ctx: panel.attr('data-ctx'),
        ctxId: panel.attr('data-ctx-id'),
        ctxId2: panel.attr('data-ctx-id2'),
        userId: panel.attr('data-user-id'),
        uploadName: panel.find('#uploadName').val(),
        tags: panel.attr('data-tags'),
        uploadDescription: panel.find('#uploadDescription').val(),
        uploadTime: time
      };

      var uploader = EmberUploader['default'].Uploader.create({
        url: '/api/documents/upload'
      });

      if (!Ember['default'].isEmpty(files)) {
        uploader.upload(files[0], data);
      }

      uploader.on('progress', function (e) {
        _this.sendAction('uploadInProgress', e);
      });

      uploader.on('didUpload', function (e) {
        _this.sendAction('uploadDone', e);
      });

      uploader.on('didError', function (jqXHR, textStatus, errorThrown) {
        jason.notifiction.errorLong('Dokument konnte nicht hochgeladen werden', errorThrown);
        _this.sendAction('uploadError');
      });
    }
  });

});