define('jason-frontend/components/mandant-invoice-text-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      load: function load(data, month, year, mandantdId) {
        this.set('month', month);
        this.set('year', year);
        this.set('model', data);
        this.set('mandantId', mandantdId);
      },
      confirm: function confirm() {

        var net1 = new String(this.get('model').net1).replace(',', '.'),
            net2 = new String(this.get('model').net2).replace(',', '.'),
            tax1 = this.get('model').tax1,
            tax2 = this.get('model').tax2,
            text1 = this.get('model').text1,
            text2 = this.get('model').text2;

        if (isNaN(net1)) {
          jason.notifiction.systemError('Fehler', 'Bitte eine Zahl als Betrag eingeben');
          return;
        }
        if (isNaN(net2)) {
          jason.notifiction.systemError('Fehler', 'Bitte eine Zahl als Betrag eingeben');
          return;
        }

        var data = {
          id: this.get('model').id,
          month: this.get('month'),
          year: this.get('year'),
          net1: net1,
          tax1: tax1,
          tax2: tax2,
          net2: net2,
          text1: text1,
          text2: text2,
          mandantId: this.get('mandantId')
        };

        this.sendAction('confirm', data);

        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});