define('jason-frontend/models/text-module', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    title: DS['default'].attr('string'),
    text: DS['default'].attr('string'),
    subject: DS['default'].attr('string'),
    desc: DS['default'].attr('string'),
    editable: DS['default'].attr('boolean')
  });

});