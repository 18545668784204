define('jason-frontend/templates/components/button-report', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 5,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/button-report.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"class","circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","16");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[4,62],[4,67]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-report.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 34 34");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(1 1)");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","translate(-12,-12) scale(0.65,0.65)");
        dom.setAttribute(el3,"fill-rule","evenodd");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M32.746 27.664c-.412 0-.746.305-.746.682v27.987c0 .774.744 1.354 1.55 1.354h20.782c.806 0 1.55-.58 1.55-1.354V33.688c.002-.177-.075-.353-.21-.48l-5.597-5.342c-.14-.13-.337-.204-.536-.202H32.745zm.747 1.364h15.3v4.777c0 .357.355.682.746.682h4.85V56.323c-.004.002-.016 0-.058 0h-20.78c-.044 0-.055.002-.06 0V29.028zm16.792 1.013l3.23 3.083h-3.23V30.04zm-13.06 4.447c-.414 0-.747.305-.747.683 0 .378.333.682.746.682h6.717c.415 0 .748-.304.748-.682 0-.378-.333-.683-.747-.683h-6.716zm0 4.094c-.414 0-.747.306-.747.684 0 .378.333.682.746.682h13.434c.414 0 .747-.304.747-.682 0-.378-.333-.683-.747-.683H37.224zm0 4.096c-.414 0-.747.304-.747.682 0 .378.333.682.746.682h13.434c.414 0 .747-.304.747-.682 0-.378-.333-.682-.747-.682H37.224zm0 4.094c-.414 0-.747.304-.747.682 0 .378.333.682.746.682h13.434c.414 0 .747-.304.747-.682 0-.378-.333-.682-.747-.682H37.224zm0 4.094c-.414 0-.747.304-.747.682 0 .378.333.683.746.683h13.434c.414 0 .747-.306.747-.684 0-.378-.333-.682-.747-.682H37.224z");
        dom.setAttribute(el4,"fill-rule","evenodd");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createAttrMorph(element1, 'class');
        morphs[3] = dom.createMorphAt(element2,1,1);
        morphs[4] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","class",["concat",["laboklin-status-",["get","cssClasses",["loc",[null,[1,120],[1,130]]]]," state-button cursor ",["subexpr","css-bool-evaluator",[["get","selected",["loc",[null,[1,174],[1,182]]]],"active",""],[],["loc",[null,[1,153],[1,196]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,10],[3,20]]]]],[],0,null,["loc",[null,[3,4],[5,11]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[6,15],[6,20]]]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});