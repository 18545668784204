define('jason-frontend/routes/transactions/show', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.findRecord('transaction-group', params.id, { reload: true });
    },
    setupController: function setupController(controller, model, params) {
      var transactionId = params.params['transactions.show'].id;
      this.store.fetch('transaction-group', transactionId).then(function (data) {
        controller.set('model', data);

        var hostAppendix = '';
        if (location.hostname === "localhost") {
          hostAppendix = 'http://localhost:8080/';
        }

        controller.set('viewUrl', hostAppendix + '/api/salesSlip/' + data.get('token'));
      });
      controller.set('printers', this.store.find('printer'));
    },
    actions: {
      download: function download(url) {
        window.open(url);
      },
      show: function show() {
        var self = this;

        setTimeout(function () {
          self.get('controller').get('pdfPreview').send('show');
        }, 200);
      },
      print: function print(transactionGroup) {
        var deferred = $.ajax({
          url: "/api/cashdesk/print/" + transactionGroup.get('id'),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Belegdruck durchgeführt', '');
        }, function () {
          jason.notifiction.systemError('Fehlercode 101', 'Belegdruck konnte nicht durchgeführt werden');
        });
      },
      sendEmail: function sendEmail(transactionId, data) {

        var id = window.location.pathname.split('/')[3];
        if (id !== transactionId) {
          jason.notifiction.notifiy('Fehler', 'Es ist ein interner Fehler aufgetreten');
          return;
        }

        Ember['default'].$.ajax({
          url: "/api/transactionGroups/mailto/" + transactionId,
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'Beleg wurde übermittelt');
        }, function () {
          jason.notifiction.notifiy('Erfolgreich', 'Beleg wurde übermittelt');
        });
      }
    }
  });

});