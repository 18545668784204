define('jason-frontend/helpers/create-id', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.createId = createId;

  function createId(params) {
    var id = params[0],
        prefix = params[1];

    return prefix + id;
  }

  exports['default'] = Ember['default'].Helper.helper(createId);

});