define('jason-frontend/routes/inventory/delivery-note-view', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {

      var id = params.params['inventory.delivery-note-view'].id;

      $.ajax({
        url: "/api/deliveryNotes/" + id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('model', data.deliveryNote);
      });

      controller.set("model", model);
    },
    actions: {}
  });

});