define('jason-frontend/models/customer-contact-info', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    value: DS['default'].attr('string'),
    comment: DS['default'].attr('string'),
    type: DS['default'].attr('string')
  });

});