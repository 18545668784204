define('jason-frontend/controllers/invoices/financialbook', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Finanzbuch",
    breadCrumbPath: "transactions.cashbook",
    filterIn: "day",
    filterOut: "single",
    classFilterTypeInAll: "default",
    classFilterTypeInSingle: "default",
    classFilterTypeInDay: "primary",
    classFilterTypeOutAll: "default",
    classFilterTypeOutSingle: "primary",
    classFilterTypeOutDay: "default",

    filterPaymentCash: true,
    filterPaymentCashcard: true,
    filterPaymentCreditcard: true,
    filterPaymentVoucher: true,
    filterPaymentDeposit: true,
    filterPaymentBanktransfer: true,
    filterPaymentWithdrawal: true,
    classFilterPaymentCash: "primary",
    classFilterPaymentCashcard: "primary",
    classFilterPaymentCreditcard: "primary",
    classFilterPaymentVoucher: "primary",
    classFilterPaymentBanktransfer: "primary",
    classFilterPaymentDeposit: "primary",
    classFilterPaymentWithdrawal: "primary",

    selectedRegister: null,
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),

    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    perPage: 20,
    to: null,
    from: null,

    actions: {
      setFilterType: function setFilterType(type, setting) {
        if (type === 'in') {
          if (setting === 'all') {
            this.set('classFilterTypeInAll', "primary");
            this.set('classFilterTypeInSingle', "default");
            this.set('classFilterTypeInDay', "default");
          }
          if (setting === 'single') {
            this.set('classFilterTypeInAll', "default");
            this.set('classFilterTypeInSingle', "primary");
            this.set('classFilterTypeInDay', "default");
          }
          if (setting === 'day') {
            this.set('classFilterTypeInAll', "default");
            this.set('classFilterTypeInSingle', "default");
            this.set('classFilterTypeInDay', "primary");
          }
        }
        if (type === 'out') {
          if (setting === 'all') {
            this.set('classFilterTypeOutAll', "primary");
            this.set('classFilterTypeOutSingle', "default");
            this.set('classFilterTypeOutDay', "default");
          }
          if (setting === 'single') {
            this.set('classFilterTypeOutAll', "default");
            this.set('classFilterTypeOutSingle', "primary");
            this.set('classFilterTypeOutDay', "default");
          }
          if (setting === 'day') {
            this.set('classFilterTypeOutAll', "default");
            this.set('classFilterTypeOutSingle', "default");
            this.set('classFilterTypeOutDay', "primary");
          }
        }
        this.send('applyFilter');
      }
    },
    watchCashRegister: (function () {
      this.send('applyFilter');
    }).observes('selectedRegister'),
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('pickDate')
  });

});