define('jason-frontend/controllers/purchases/dashboard', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Meine Einkäufe",
    breadCrumbPath: "sale.dashboard",
    filterType: 'months',
    sortBy: 'created',
    sortDir: 'asc',
    filterTypeList: [{ value: "Monatsweise Auswertung", id: "months" }, { value: "Quartalsweise Auswertung", id: "quarter" }, { value: "Jahresweise Auswertung", id: "years" }],
    actions: {
      sortBy: function sortBy(_sortBy) {
        if (_sortBy === this.get('sortBy')) {
          if (this.get('sortDir') === 'desc') {
            this.set('sortDir', 'asc');
          } else {
            this.set('sortDir', 'desc');
          }
        }
        this.set('sortBy', _sortBy);
        this.send('applyFilter');
      }
    },
    watchFilterType: (function () {
      var self = this;
      this.set('page', 1);

      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterType'),
    watchFilterSupplier: (function () {
      var self = this;
      this.set('page', 1);

      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterSupplier'),
    watchFilterMandant: (function () {
      var self = this;
      this.set('page', 1);

      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterMandant')
  });

});