define('jason-frontend/controllers/inventory/check-detail', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Kontrolldetails",
    breadCrumbPath: "inventory.check-detail",
    resultList: [{ value: "Ok", id: "ok" }, { value: "In Arbeit", id: "inprogress" }, { value: "Fehlerhaft", id: "notok" }],
    actions: {
      openInventorySelect: function openInventorySelect(id) {

        var self = this;

        id = id.replace("product", "");
        id = id.replace("service", "");
        id = id.replace("package", "");

        $.ajax({
          url: "/api/products/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (product) {

          $.ajax({
            url: "/api/inventoryStocks/product/" + id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.get('selectInventoryPanel').send('load', data.inventoryStock, product.product.name);
          }, function () {});
        });

        setTimeout(function () {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-select-inventory-panel-simple'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 1000);
      },
      openScan: function openScan() {

        this.get('quickScanProductPanel').send('loadWithoutDirect');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-quick-scan-product-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    }
  });

});