define('jason-frontend/controllers/transactions/closings', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Abschlüsse",
    breadCrumbPath: "transactions.closings",
    queryParams: ["type", "page", "perPage", "filterRegisterId"],
    filterRegisterId: "",
    selectedRegister: null,
    type: 'z',
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    showClosingButton: false,
    hideClosingButton: false,
    showCashRegisters: false,

    page: 1,
    perPage: 10,
    watchCashRegister: (function () {
      this.send("retrieve", this.get('type'));
    }).observes('selectedRegister')
  });

});