define('jason-frontend/templates/compositeproducts/select-product', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 78,
              "column": 30
            },
            "end": {
              "line": 82,
              "column": 30
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/select-product.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col-md-3 mb5");
          var el2 = dom.createTextNode("\n                                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","patient-category-filter-button",[],["category",["subexpr","@mut",[["get","category",["loc",[null,[80,78],[80,86]]]]],[],[]],"categorySelected","selectCategoryFilterOption"],["loc",[null,[80,36],[80,134]]]]
        ],
        locals: ["category"],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 112,
              "column": 24
            },
            "end": {
              "line": 114,
              "column": 24
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/select-product.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","invoice-add-product",[],["linkToEditEnabled",["subexpr","@mut",[["get","linkToEditEnabled",["loc",[null,[113,66],[113,83]]]]],[],[]],"patientId",["subexpr","@mut",[["get","selectedPatient",["loc",[null,[113,94],[113,109]]]]],[],[]],"filterFavorites",["subexpr","@mut",[["get","filterFavorites",["loc",[null,[113,126],[113,141]]]]],[],[]],"product",["subexpr","@mut",[["get","product",["loc",[null,[113,150],[113,157]]]]],[],[]],"invoice",["subexpr","@mut",[["get","invoice",["loc",[null,[113,166],[113,173]]]]],[],[]],"addProduct","addProduct"],["loc",[null,[113,26],[113,199]]]]
        ],
        locals: ["product"],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 127,
              "column": 2
            },
            "end": {
              "line": 127,
              "column": 103
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/select-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["content","button-prev",["loc",[null,[127,88],[127,103]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 129,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/compositeproducts/select-product.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","content");
        dom.setAttribute(el1,"class","table-layout add-product");
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","tray tray-center");
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel mb25 mt5 product-search");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-heading");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","panel-title hidden-xs");
        var el6 = dom.createTextNode(" Suche");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-body p20 pb10");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","tab-content pn br-n admin-form");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"id","tab1_2");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("form");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","section row");
        var el9 = dom.createTextNode("\n\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col-md-7");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","search");
        dom.setAttribute(el10,"class","field");
        dom.setAttribute(el10,"style","width: 85%; float: left;");
        var el11 = dom.createTextNode("\n                                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("button");
        dom.setAttribute(el10,"style","width: 10%; float: right");
        dom.setAttribute(el10,"class","btn-primary btn icon-btn");
        dom.setAttribute(el10,"type","submit");
        dom.setAttribute(el10,"title","Suchen");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","fa fa-search");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("<div class=\"col-md-2\">");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("<button type=\"button\" class=\"btn btn-primary btn-gradient dark btn-block\" >Kategoriefilter einblenden</button>");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("</div>");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","section row");
        var el9 = dom.createTextNode("\n\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col-md-2");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","field select");
        var el11 = dom.createTextNode("\n                                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("i");
        dom.setAttribute(el11,"class","arrow double");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col-md-2");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","field select");
        var el11 = dom.createTextNode("\n                                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("i");
        dom.setAttribute(el11,"class","arrow double");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col-md-3");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","field select");
        var el11 = dom.createTextNode("\n                                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("i");
        dom.setAttribute(el11,"class","arrow double");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col-md-5 text-right right-actions");
        var el10 = dom.createTextNode("\n                                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","section row");
        dom.setAttribute(el8,"id","categoryFilter");
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-body pn");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table-responsive");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("table");
        dom.setAttribute(el6,"class","table admin-form theme-warning tc-checkbox-1 fs13");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("thead");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("tr");
        dom.setAttribute(el8,"class","bg-light");
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("th");
        dom.setAttribute(el9,"class","sortable");
        var el10 = dom.createTextNode("Bezeichnung");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("th");
        var el10 = dom.createTextNode("Hersteller");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("th");
        dom.setAttribute(el9,"class","hide-1200");
        var el10 = dom.createTextNode("Quelle");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("th");
        dom.setAttribute(el9,"class","hide-1200 sortable");
        var el10 = dom.createTextNode("Kategorie");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("th");
        dom.setAttribute(el9,"class","text-center");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("th");
        dom.setAttribute(el9,"class","text-center");
        var el10 = dom.createTextNode("VPE-Menge");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("th");
        dom.setAttribute(el9,"class","text-center");
        var el10 = dom.createTextNode("VPE-Einheit");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("th");
        dom.setAttribute(el9,"class","text-right");
        var el10 = dom.createTextNode("Mwst.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("th");
        dom.setAttribute(el9,"class","hide-1300 text-right");
        dom.setAttribute(el9,"style","min-width: 100px;");
        var el10 = dom.createTextNode("VK Netto");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("th");
        dom.setAttribute(el9,"class","text-right");
        dom.setAttribute(el9,"style","min-width: 100px;");
        var el10 = dom.createTextNode("VK Brutto");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("th");
        dom.setAttribute(el9,"class","text-right");
        dom.setAttribute(el9,"style","min-width: 80px;");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("th");
        dom.setAttribute(el9,"class","text-right");
        var el10 = dom.createTextNode("Menge");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("th");
        dom.setAttribute(el9,"class","text-right");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tbody");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","buttons action-icons clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(element0, [1, 3, 1, 1, 1]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element1, [5]);
        var element4 = dom.childAt(element0, [3, 1, 1]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element5, [1, 1]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element6, [7]);
        var morphs = new Array(13);
        morphs[0] = dom.createElementMorph(element1);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1, 1, 1]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [1, 1]),1,1);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [3, 1]),1,1);
        morphs[4] = dom.createMorphAt(dom.childAt(element2, [5, 1]),1,1);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [7]),1,1);
        morphs[6] = dom.createAttrMorph(element3, 'class');
        morphs[7] = dom.createMorphAt(element3,1,1);
        morphs[8] = dom.createElementMorph(element7);
        morphs[9] = dom.createElementMorph(element8);
        morphs[10] = dom.createMorphAt(dom.childAt(element5, [3]),1,1);
        morphs[11] = dom.createMorphAt(element4,3,3);
        morphs[12] = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
        return morphs;
      },
      statements: [
        ["element","action",["applyFilter"],["on","submit"],["loc",[null,[12,30],[12,66]]]],
        ["inline","input",[],["name","search","value",["subexpr","@mut",[["get","filterName",["loc",[null,[17,66],[17,76]]]]],[],[]],"class","gui-input","placeholder","Bezeichnung","autocomplete","off"],["loc",[null,[17,38],[17,141]]]],
        ["inline","select-2",[],["value",["subexpr","@mut",[["get","filterManufacturer",["loc",[null,[31,44],[31,62]]]]],[],[]],"optionLabelPath","name","placeholder","Alle Hersteller","allowClear",true,"typeaheadSearchingText","Suche Hersteller","typeaheadNoMatchesText","Keine Hersteller gefunden für '%@'","typeaheadErrorText","Ladevorgang fehlerhaft: %@","minimumInputLength",3,"maximumInputLength",15,"cssClass","custom-select2","query","queryManufacturer"],["loc",[null,[30,38],[42,40]]]],
        ["inline","view",["select"],["content",["subexpr","@mut",[["get","suppliers",["loc",[null,[50,46],[50,55]]]]],[],[]],"value",["subexpr","@mut",[["get","filterSupplier",["loc",[null,[51,44],[51,58]]]]],[],[]],"optionLabelPath","content.name","optionValuePath","content.id"],["loc",[null,[49,38],[53,68]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","categories",["loc",[null,[61,46],[61,56]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedCategoryFilters",["loc",[null,[62,44],[62,67]]]]],[],[]],"cssClass","custom-select2","optionLabelPath","name","allowClear",true,"placeholder","Alle Kategorien"],["loc",[null,[60,38],[67,40]]]],
        ["inline","toggle-favorite-button",[],["showText",true,"fireAction",true,"toggle","toggleFavorites"],["loc",[null,[73,34],[73,115]]]],
        ["attribute","class",["concat",[["get","categoryFilterClass",["loc",[null,[77,82],[77,101]]]]]]],
        ["block","each",[["get","categories",["loc",[null,[78,38],[78,48]]]]],[],0,null,["loc",[null,[78,30],[82,39]]]],
        ["element","action",["setSort","name",["get","click",["loc",[null,[96,75],[96,80]]]]],[],["loc",[null,[96,49],[96,82]]]],
        ["element","action",["setSort","category",["get","click",["loc",[null,[99,89],[99,94]]]]],[],["loc",[null,[99,59],[99,96]]]],
        ["block","each",[["get","model",["loc",[null,[112,32],[112,37]]]]],[],1,null,["loc",[null,[112,24],[114,33]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[117,41],[117,48]]]]],[],[]]],["loc",[null,[117,18],[117,50]]]],
        ["block","link-to",["compositeproducts.edit",["get","productId",["loc",[null,[127,38],[127,47]]]]],["class","icon-button icon-button--small"],2,null,["loc",[null,[127,2],[127,115]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});