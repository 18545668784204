define('jason-frontend/helpers/show-ehapo-stock', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.showEhapoStock = showEhapoStock;

  function showEhapoStock(params) {

    var article = params[0];

    if (article) {
      try {
        if (article.get('type') === "service") {
          return false;
        }

        if (article && article.get('category')) {
          var category = article.get('category').id;
          var categoryName = article.get('category').fullName;
          if (category === 'labor' || categoryName === 'Labor') {
            return false;
          }
        }
        if (article && article.get('hideEhapo')) {
          return false;
        }
      } catch (e) {
        return false;
      }
    }

    return API['default'].isInventoryActive() && API['default'].isShowInactivityLabel();
  }

  exports['default'] = Ember['default'].Helper.helper(showEhapoStock);

});