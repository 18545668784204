define('jason-frontend/controllers/purchases/labresults', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Laboranalysen bereitstellen",
    breadCrumbPath: "purchases.labresults",
    queryParams: ["page", "perPage", "filterName"],
    filterName: "",
    filterSource: null,
    sortBy: null,
    sortDir: null,

    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",

    page: 1,
    perPage: 10,

    watchSource: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterSource')
  });

});