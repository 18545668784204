define('jason-frontend/models/media', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    path: DS['default'].attr('string'),
    name: DS['default'].attr('string'),
    realfilename: DS['default'].attr('string'),
    created: DS['default'].attr('date'),
    size: DS['default'].attr('number'),
    cdnId: DS['default'].attr('string'),
    cdnAcc: DS['default'].attr('string'),
    editable: DS['default'].attr('boolean'),
    shared: DS['default'].attr('boolean'),
    category: DS['default'].belongsTo('media-category')
  });

});