define('jason-frontend/components/treatment-history-employees', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    patientInfo: null,
    actions: {
      save: function save() {
        var data = {
          id: this.get('patientInfo').id
        };

        if (this.get('patientInfo').get('doctor')) {
          data.doctor = {
            id: this.get('patientInfo').get('doctor').id
          };
        }

        if (this.get('patientInfo').get('assistant')) {
          data.assistant = {
            id: this.get('patientInfo').get('assistant').id
          };
        }

        this.sendAction('employeesSaved', data);
        $.magnificPopup.close();
      },
      load: function load(patientInfo) {
        this.set('patientInfo', patientInfo);
      }
    }
  });

});