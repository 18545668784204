define('jason-frontend/templates/components/button-money-collection', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 6
            },
            "end": {
              "line": 6,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/button-money-collection.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"class","circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","16");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element4, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[5,64],[5,69]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 0
            },
            "end": {
              "line": 29,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/button-money-collection.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","delete-confirm medium-confirm");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","footer");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","button");
          dom.setAttribute(el3,"href","#");
          var el4 = dom.createTextNode("Nein");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","button");
          dom.setAttribute(el3,"href","#");
          var el4 = dom.createTextNode("Ja");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element0,1,1);
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [
          ["content","text",["loc",[null,[23,4],[23,12]]]],
          ["element","action",["cancel"],[],["loc",[null,[25,9],[25,28]]]],
          ["element","action",["delete"],[],["loc",[null,[26,9],[26,28]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 31,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-money-collection.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"class","btn-delete");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el2 = dom.createElement("svg");
        dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el2,"class","cursor");
        dom.setAttribute(el2,"viewBox","-1 -1 34 34");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"fill","none");
        dom.setAttribute(el3,"fill-rule","evenodd");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        dom.setAttribute(el4,"fill-rule","evenodd");
        dom.setAttribute(el4,"transform","translate(5,5) scale(0.045,0.045)");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M96.701,148.775c-2.826-5.265-6.854-9.759-11.663-13.326c-5.681-4.215-12.458-7.089-20.002-7.838   c-0.064-0.006-0.122-0.031-0.187-0.037c-1.885-0.194-3.715-0.071-5.553-0.004c10.867,3.044,20.445,10.424,25.667,21.414   l15.442,32.474l10.362,21.79l31.86-3.004l6.982-11.334l-14.651,3.842L96.701,148.775z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M222.552,175.65l-24.998-15.404c-1.526-0.94-3.272-1.43-5.044-1.43c-0.735,0-1.477,0.087-2.212,0.26   c-2.474,0.593-4.615,2.142-5.95,4.307l-22.897,37.169c16.68,3.655,29.927,17.637,31.622,35.573   c0.184,1.945,0.054,3.836-0.036,5.733l32.653-53.002C228.471,184.343,227.064,178.431,222.552,175.65z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M78.328,316.453c6.371-6.415,10.739-14.896,11.654-24.602l0.51-5.403l-0.305,0.028c-1.28,0.127-2.553,0.182-3.817,0.182   c-15.491,0-29.78-8.891-36.529-23.085l-32.1-67.494l-8.325,88.168c-0.936,9.913,1.937,19.221,7.212,26.798l26.25,76.933l-9.5,69.92   c-2.11,15.506,8.749,29.779,24.247,31.881c1.296,0.182,2.584,0.261,3.857,0.261c13.949,0,26.096-10.306,28.024-24.508   l10.401-76.551c0.593-4.363,0.166-8.804-1.256-12.961L78.328,316.453z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M99.016,117.447c26.76-6.971,42.804-34.324,35.832-61.085c-6.969-26.76-34.314-42.804-61.075-35.832   c-26.76,6.97-42.804,34.324-35.834,61.084C44.91,108.373,72.257,124.416,99.016,117.447z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M150.506,215.759l-49.49,4.671l-30.672-64.49c-5.762-12.116-20.272-17.261-32.348-11.5   c-12.116,5.754-17.261,20.24-11.499,32.348l37.967,79.83c4.046,8.52,12.621,13.854,21.916,13.854c0.759,0,1.525-0.031,2.292-0.11   l66.403-6.268c13.349-1.256,23.148-13.104,21.884-26.452C175.701,224.286,163.759,214.36,150.506,215.759z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M480.624,284.248l-11.349-120.192c-2.103-22.255-21.995-38.75-44.084-36.481c-15.57,1.471-28.083,11.66-33.567,25.222   l3.551-3.243c16.502-15.056,42.101-13.918,57.164,2.584c15.071,16.502,13.918,42.093-2.585,57.164l-52.925,48.343l3.229,34.207   c0.915,9.706,5.284,18.187,11.655,24.602l-20.324,59.567c-1.423,4.157-1.85,8.599-1.257,12.961l10.401,76.551   c1.928,14.202,14.076,24.508,28.025,24.508c1.272,0,2.561-0.079,3.856-0.261c15.499-2.102,26.357-16.375,24.248-31.881l-9.5-69.92   l26.251-76.933C478.688,303.469,481.56,294.161,480.624,284.248z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M391.025,117.447c26.76,6.97,54.106-9.074,61.075-35.834c6.972-26.76-9.072-54.114-35.831-61.084   c-26.763-6.972-54.106,9.072-61.078,35.832C348.222,83.123,364.264,110.476,391.025,117.447z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M440.388,163.052c-9.042-9.887-24.413-10.598-34.3-1.549l-51.829,47.341h-65.55c-13.412,0-24.278,10.866-24.278,24.278   s10.867,24.279,24.278,24.279h74.97c6.062,0,11.902-2.268,16.375-6.354l58.784-53.695   C448.742,188.311,449.43,172.955,440.388,163.052z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M283.478,89.441c0-15.223-8.118-24.509-28.248-31.612c-14.366-5.415-20.287-8.964-20.287-14.544   c0-4.742,3.549-9.467,14.551-9.467c6.139,0,11.166,0.988,15.205,2.205c2.434,0.743,5.074,0.441,7.287-0.807   c2.213-1.25,3.824-3.35,4.458-5.816l0.159-0.608c1.303-5.09-1.732-10.282-6.807-11.649c-4.25-1.146-9.349-1.985-15.577-2.262V8.282   c0-4.575-3.707-8.282-8.283-8.282c-4.583,0-8.29,3.707-8.29,8.282v7.777c-18.092,3.548-28.57,15.221-28.57,30.096   c0,16.398,12.337,24.855,30.428,30.949c12.519,4.22,17.932,8.282,17.932,14.7c0,6.764-6.598,10.496-16.234,10.496   c-6.843,0-13.291-1.383-18.903-3.359c-2.404-0.854-5.058-0.641-7.303,0.561c-2.253,1.201-3.896,3.288-4.529,5.753l-0.157,0.625   c-1.392,5.437,1.722,11.009,7.082,12.685c5.697,1.778,12.494,3.035,19.409,3.342v7.786c0,4.576,3.706,8.283,8.282,8.283   c4.584,0,8.291-3.707,8.291-8.283v-8.97C272.816,117.348,283.478,104.489,283.478,89.441z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element7, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element5, 'data-id');
        morphs[1] = dom.createAttrMorph(element5, 'title');
        morphs[2] = dom.createElementMorph(element5);
        morphs[3] = dom.createAttrMorph(element6, 'width');
        morphs[4] = dom.createAttrMorph(element6, 'height');
        morphs[5] = dom.createMorphAt(element7,1,1);
        morphs[6] = dom.createAttrMorph(element8, 'fill');
        morphs[7] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[1,54],[1,61]]]]]]],
        ["attribute","title",["concat",[["get","tooltip",["loc",[null,[1,74],[1,81]]]]]]],
        ["element","action",["confirm"],[],["loc",[null,[1,3],[1,23]]]],
        ["attribute","width",["concat",[["get","size",["loc",[null,[2,51],[2,55]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[2,69],[2,73]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[4,12],[4,22]]]]],[],0,null,["loc",[null,[4,6],[6,13]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[7,17],[7,22]]]]]]],
        ["block","if",[["get","useConfirm",["loc",[null,[21,6],[21,16]]]]],[],1,null,["loc",[null,[21,0],[29,7]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});