define('jason-frontend/routes/patientappointmentcategories/create', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return new Object();
    },
    setupController: function setupController(controller, model, params) {

      var etiga = params.queryParams.etiga;
      controller.set('etiga', etiga == 'true');
      Ember['default'].set(model, 'etiga', etiga == 'true');
      Ember['default'].set(model, 'active', true);
      controller.set('model', model);
    },
    actions: {
      create: function create() {
        var self = this;

        var controller = this.get('controller'),
            model = controller.get('model'),
            account = new Object();

        account.name = model.name;
        account.color = model.color;
        account.active = model.active;
        account.etiga = model.etiga;

        $.ajax({
          url: "/api/patientAppointmentCategories",
          method: "POST",
          data: JSON.stringify(account),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          if (model.etiga) {
            self.transitionTo('basicinfos', { queryParams: { selectTab: "etigaAppointmentcategories" } });
          } else {
            self.transitionTo('basicinfos', { queryParams: { selectTab: "appointmentcategories" } });
          }
          controller.set('number', '');
        }, function () {
          if (model.etiga) {
            self.transitionTo('basicinfos', { queryParams: { selectTab: "etigaAppointmentcategories" } });
          } else {
            self.transitionTo('basicinfos', { queryParams: { selectTab: "appointmentcategories" } });
          }
          controller.set('number', '');
        });
      }
    }
  });

});