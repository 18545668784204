define('jason-frontend/helpers/get-inclusive-price', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.getInclusivePrice = getInclusivePrice;

  function getInclusivePrice(params) {
    var price = params[0],
        percentage = params[1],
        applied = params[2];

    if (price) {
      price = parseFloat((price + "").replace(",", "."));
    }

    if (!isNaN(price) && !isNaN(percentage)) {

      if (API['default'].getOperatingCountry().toLowerCase() === 'at' && applied) {
        percentage = 20;
      }
      return parseFloat(price) / 100 * (100 + percentage);
    }
    return 0;
  }

  exports['default'] = Ember['default'].Helper.helper(getInclusivePrice);

});