define('jason-frontend/routes/inventory/stocktaking-detail', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {

      var id = params.params['inventory.stocktaking-detail'].id;
      this.set('id', id);
      this._super(controller);
      this.loadData(id);
      controller.set('users', this.store.find('user', { per_page: 100 }));

      this.applyFilter();
    },
    scanProduct: function scanProduct(id) {
      var self = this,
          controller = this.get('controller');
      if (new String(id).startsWith('product')) {
        $.ajax({
          url: "/api/products/" + id.replace('product', '').replace('qr', ''),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (product) {
          $.ajax({
            url: "/api/inventoryStocks/product/" + product.product.id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.get('stockSelectPanel').send('load', data, product.product);
          }, function () {});

          setTimeout(function () {
            $.magnificPopup.open({
              removalDelay: 500,
              closeOnBgClick: false,
              items: {
                src: '#modal-select-stock-panel'
              },
              callbacks: {
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: true
            });
          }, 1000);
        }, function () {});
      } else {
        setTimeout(function () {
          controller.send('openCorrectionPanel', id, controller.get('model').id);
        }, 1000);
      }
    },
    loadData: function loadData(id) {
      var self = this,
          controller = this.get('controller');

      if (!id) {
        id = controller.get('model').id;
      }

      $.ajax({
        url: "/api/inventoryStocktakings/" + id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('model', data.inventoryStocktaking);
      }, function () {
        controller.set('model', data.inventoryStocktaking);
      });
    },
    save: function save() {
      var data = this.get('controller').get('model'),
          controller = this.get('controller');

      var user = {
        id: data.user.id
      };

      Ember['default'].set(data, "user", user);

      $.ajax({
        url: "/api/inventoryStocktakings/" + this.get('controller').get('model').id,
        method: "POST",
        contentType: "application/json",
        data: JSON.stringify(data),
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('model', data.inventoryStocktaking);
        jason.notifiction.notifiy('Inventur', 'gespeichert');
      }, function () {
        jason.notifiction.notifiy('Inventur', 'gespeichert');
      });
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller');

      this.findPaged('inventoryStocktakingEntry', {
        stocktakingId: this.get('id'),
        pageSize: this.get('perPage'),
        filterCode: controller.get('filterCode'),
        per_page: 10
      }).then(function (data) {
        controller.set('entries', data);
      });
    },
    openDiffPanel: function openDiffPanel(id) {

      var self = this;
      this.get('controller').get('stocktakingCheckPanel').send('load', id, this);

      $.magnificPopup.open({
        removalDelay: 500,
        closeOnBgClick: false,
        items: {
          src: '#modal-stocktaking-check-panel'
        },
        callbacks: {
          beforeOpen: function beforeOpen() {
            var Animation = "mfp-with-fade";
            this.st.mainClass = Animation;
          }
        },
        midClick: true
      });
    },
    actions: {
      gotoProduct: function gotoProduct(id) {
        this.transitionTo('products.edit', id);
      },
      openDiffPanel: function openDiffPanel(id) {
        this.openDiffPanel(id);
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      saveCorrection: function saveCorrection(data, stockTakingId, keepOpen, reopen) {
        var self = this;
        var controller = this.get('controller');
        $.ajax({
          url: "/api/inventoryStocks/" + data.id + "/correction",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data2) {
          jason.notifiction.notifiy('Korrekturbuchung', 'gespeichert');
          self.loadData();
          self.applyFilter();
          if (keepOpen) {
            setTimeout(function () {
              self.openDiffPanel(stockTakingId);
            }, 500);
          }
          if (reopen) {
            setTimeout(function () {
              controller.get('quickScanProductPanel').send('loadWithoutDirect');

              $.magnificPopup.open({
                removalDelay: 500,
                closeOnBgClick: false,
                items: {
                  src: '#modal-quick-scan-product-panel'
                },
                callbacks: {
                  beforeOpen: function beforeOpen() {
                    var Animation = "mfp-with-fade";
                    this.st.mainClass = Animation;
                  }
                },
                midClick: true
              });
            }, 500);
          }
        }, function (data2) {
          jason.notifiction.notifiy('Korrekturbuchung', 'gespeichert');
          self.loadData();
          self.applyFilter();
          if (reopen) {
            setTimeout(function () {
              self.scanProduct('product' + data.product.id + 'qr');
            }, 500);
          } else {
            setTimeout(function () {
              self.send('scan');
            }, 500);
          }
        });
      },
      createRequest: function createRequest(id) {
        var self = this;
        $.ajax({
          url: "/api/inventoryRequests?referer=corrections",
          method: "PUT",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('cash.add_article', 'inventoryRequests', data.inventoryRequestSupplier.id, 0, { queryParams: { type: 'inventoryCorrections', stocktakingId: id } });
        }, function () {});
      },
      lock: function lock(id) {
        var self = this;
        var controller = this.get('controller');
        $.ajax({
          url: "/api/inventoryStocktakings/" + id + "/lock",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('model', data.inventoryStocktaking);
        }, function () {});
      },
      deleteCorrection: function deleteCorrection(correctionId) {
        var controller = this.get('controller');
        var id = this.get('controller').get('model').id;
        var self = this;
        $.ajax({
          url: "/api/inventoryStocktakings/" + id + "/deleteCorrection/" + correctionId,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('model', data.inventoryStocktaking);
          self.applyFilter();
          jason.notifiction.notifiy('Korrekturbuchung', 'entfernt');
        }, function (data) {
          controller.set('model', data.inventoryStocktaking);
          self.applyFilter();
          jason.notifiction.notifiy('Korrekturbuchung', 'entfernt');
        });
      },
      print: function print(id) {
        window.open('/api/inventoryStocktakings/download/' + id);
      },
      save: function save() {
        this.save();
      },
      add: function add() {
        var id = this.get('controller').get('model').id;
        this.transitionTo('inventory.stocktaking-add', id);
      },
      deleteEntry: function deleteEntry(id) {
        var self = this,
            controller = this.get('controller');

        $.ajax({
          url: "/api/inventoryChecks/" + this.get('controller').get('model').id + "/entry/" + id,
          method: "DELETE",
          contentType: "application/json",

          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('model', data.inventoryCheck);
          jason.notifiction.notifiy('Kontrolle', 'Eintrag entfernt');
        }, function () {
          controller.set('model', data.inventoryCheck);
          jason.notifiction.notifiy('Kontrolle', 'Eintrag entfernt');
        });
      },
      scan: function scan() {
        this.save();
        this.get('controller').send('scan');
      },
      manualStockSelected: function manualStockSelected(item, product) {
        var controller = this.get('controller');
        setTimeout(function () {
          controller.send('openCorrectionPanel', item, controller.get('model').id, false, true);
        }, 1000);
      },
      scanProduct: function scanProduct(id) {
        this.scanProduct(id);
      }
    }
  });

});