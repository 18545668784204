define('jason-frontend/components/document-preview-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    url: null,
    prefix: null,
    actions: {
      download: function download(id) {
        this.sendAction('download', id);
      },
      load: function load(document) {
        this.set('id', document.get('internalId'));
        this.set('document', document);
        this.set('url', '/api/media/' + document.get('internalId') + '/download?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      }
    }
  });

});