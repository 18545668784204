define('jason-frontend/components/media-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    showUploadForm: false,
    currentFile: null,
    showUploadButton: false,
    sortDir: 'desc',
    actions: {
      'delete': function _delete(id) {
        this.sendAction('deleteMedia', this.get('model').id, id);
      },
      forwardEmail: function forwardEmail(id) {
        this.sendAction('forwardEmail', id);
      },
      load: function load(data) {
        this.set('items', data);
        this.set('showUploadForm', false);
        this.set('showUploadButton', false);
        this.set('mediaCategory', null);
        this.set('currentFile', null);
      },
      showUploadForm: function showUploadForm() {
        this.set('showUploadForm', true);
      },
      showList: function showList() {
        this.set('showUploadForm', false);
      },
      uploadDone: function uploadDone(data) {
        this.set('data', data);
        this.set('showUploadForm', false);
        this.sendAction('reload', this.get('model').id, this.get('sortDir'));
      },
      downloadFile: function downloadFile(id) {
        window.open('/api/media/' + id + '/download?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      sortable: function sortable(patientId) {
        this.set('sortDir', this.get('sortDir') === "asc" ? "desc" : "asc");
        this.sendAction('reload', this.get('model').id, this.get('sortDir'));
      }
    },
    watchMediaCategory: (function () {
      if (this.get('mediaCategory')) {
        this.set('showUploadButton', true);
      }
    }).observes('mediaCategory')
  });

});