define('jason-frontend/routes/inventory/stock-details', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 20,
    queryParams: {},
    model: function model() {},
    setupController: function setupController(controller, model, params) {
      var id = params.params['inventory.stock-details'].id;

      var self = this;

      this.loadData(id);
    },
    loadData: function loadData(id) {

      if (!id) {
        id = this.get('controller').get('item').id;
      }

      var self = this;
      $.ajax({
        url: "/api/inventoryStocks/checkNumber/" + id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.get('controller').set('item', data.inventoryStock);
      }, function (data) {
        jason.notifiction.error('Fehler', 'QR Code konnte nicht zugeordnet werden');
      });
    },
    actions: {
      saveCorrection: function saveCorrection(data) {
        var self = this;
        $.ajax({
          url: "/api/inventoryStocks/" + data.id + "/correction",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Korrekturbuchung', 'gespeichert');
          self.loadData();
        }, function (data) {
          jason.notifiction.notifiy('Korrekturbuchung', 'gespeichert');
          self.loadData();
        });
      },
      bookout: function bookout(id, productId, productName) {

        var self = this;

        $.ajax({
          url: "/api/inventoryStocks/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.loadData(id);
          jason.notifiction.notifiy('Lagerbestand', 'storniert');
        });
      },
      printBarcode: function printBarcode(id) {
        window.open('/api/inventoryRequests/barcodes/' + id + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      bookoutMovement: function bookoutMovement(id, productId, productName) {
        var controller = this.get('controller');

        var self = this;
        $.ajax({
          url: "/api/inventoryStockMovements/simple/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.loadData();
        }, function (data) {
          self.loadData();
        });
      },
      confirm: function confirm() {
        var id = this.get('controller').get('qrProductInput');
        id = id.replace('ehapo', '');
        id = id.replace('QR', '');
        this.loadData(id);
      },
      changeWarehouse: function changeWarehouse(warehouse, stockId) {
        var self = this;

        var data = {
          warehouseId: warehouse.id,
          stockId: stockId
        };

        $.ajax({
          url: "/api/inventoryStocks/" + stockId + "/changeWarehouse",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Produkt', 'umgebucht');
          self.loadData();
        }, function () {
          jason.notifiction.notifiy('Produkt', 'umgebucht');
          self.loadData();
        });
      }
    }
  });

});