define('jason-frontend/templates/components/button-quickaction-promotions', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 4,
              "column": 72
            }
          },
          "moduleName": "jason-frontend/templates/components/button-quickaction-promotions.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","16");
          dom.setAttribute(el1,"class","circle");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[4,47],[4,52]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 35,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-quickaction-promotions.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 37 42");
        dom.setAttribute(el1,"class","quickaction-icon");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(1 1)");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","translate(5,5) scale(1.3,1.3)");
        dom.setAttribute(el3,"class","icon");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M16.677,7.689L15.828,6.84l0.398-1.131c0.211-0.595-0.102-1.252-0.699-1.465l-0.67-0.236V3.297\n			c0-0.633-0.515-1.147-1.146-1.147H12.51l-0.517-1.082c-0.264-0.553-0.975-0.807-1.531-0.542L9.819,0.834L9.318,0.332\n			C8.886-0.102,8.131-0.104,7.696,0.331L6.846,1.18l-1.13-0.398C5.134,0.572,4.457,0.898,4.25,1.479L4.014,2.15H3.303\n			c-0.632,0-1.147,0.515-1.147,1.147v1.2L1.071,5.015C0.502,5.288,0.26,5.974,0.533,6.545L0.84,7.186L0.337,7.689\n			C0.119,7.906,0,8.194,0,8.502c0,0.307,0.12,0.594,0.336,0.809l0.849,0.85l-0.4,1.133c-0.102,0.287-0.085,0.598,0.047,0.875\n			s0.364,0.486,0.653,0.588l0.67,0.236v0.711c0,0.633,0.515,1.146,1.147,1.146h1.201l0.518,1.084\n			c0.264,0.553,0.976,0.805,1.529,0.541l0.642-0.309l0.503,0.504c0.217,0.217,0.505,0.336,0.812,0.336\n			c0.307,0,0.595-0.119,0.81-0.336l0.85-0.848L11.3,16.22c0.583,0.207,1.259-0.119,1.463-0.699L13,14.849h0.712\n			c0.632,0,1.146-0.514,1.146-1.146v-1.199l1.083-0.518c0.569-0.273,0.813-0.959,0.54-1.531l-0.307-0.641l0.503-0.504\n			C17.123,8.865,17.123,8.137,16.677,7.689z M15.336,10.912l-1.708,0.816v1.893H12.13l-0.5,1.414l-1.784-0.629l-1.339,1.336\n			l-1.062-1.059l-1.348,0.652L5.278,13.62H3.385v-1.498l-1.413-0.5l0.63-1.783l-1.338-1.34l1.06-1.06L1.677,6.087l1.708-0.816V3.379\n			h1.499l0.5-1.413l1.785,0.63l1.338-1.338l1.061,1.059l1.349-0.653l0.82,1.715h1.893v1.499l1.413,0.499l-0.63,1.786l1.339,1.338\n			l-1.063,1.06L15.336,10.912z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M11.064,7.583c-1.156,0-1.896,0.917-1.896,2.126c0.01,1.271,0.823,2.041,1.845,2.041\n			c0.979,0,1.875-0.738,1.875-2.156C12.888,8.438,12.231,7.583,11.064,7.583z M11.044,10.887c-0.459,0-0.698-0.512-0.688-1.219\n			c0-0.678,0.209-1.22,0.688-1.22c0.489,0,0.666,0.531,0.666,1.22C11.71,10.428,11.491,10.887,11.044,10.887z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("polygon");
        dom.setAttribute(el5,"points","10.105,4.77 6.219,11.772 7.062,11.772 10.96,4.77 		");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M8.012,6.781c0-1.157-0.657-2.011-1.824-2.011c-1.156,0-1.896,0.917-1.896,2.126\n			c0,1.271,0.813,2.042,1.845,2.042C7.115,8.938,8.012,8.198,8.012,6.781z M6.167,8.073c-0.459,0-0.698-0.51-0.698-1.219\n			c0-0.678,0.219-1.219,0.688-1.219c0.49,0,0.678,0.531,0.678,1.219C6.834,7.614,6.615,8.073,6.167,8.073z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createMorphAt(element2,1,1);
        morphs[3] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,10],[3,20]]]]],[],0,null,["loc",[null,[3,4],[4,79]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[6,57],[6,62]]]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});