define('jason-frontend/components/treatment-weight-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    months: null,
    type: null,
    patient: null,
    sortDir: 'desc',
    changeBoundaries: function changeBoundaries() {
      var lower = undefined;
      var upper = undefined;
      try {
        lower = parseFloat(new String(this.get('patient').get('normalWeightLower')).replace(",", "."));
        upper = parseFloat(new String(this.get('patient').get('normalWeightUpper')).replace(",", "."));
      } catch (e) {
        lower = parseFloat(new String(this.get('patient').normalWeightLower).replace(",", "."));
        upper = parseFloat(new String(this.get('patient').normalWeightUpper).replace(",", "."));
      }

      var self = this;
      var data = {
        lower: isNaN(lower) ? null : lower,
        upper: isNaN(upper) ? null : upper
      };
      $.ajax({
        url: "/api/patients/" + this.get('patient').id + "/changeWeightBoundaries",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function () {
        self.reloadChart();
      }, function () {
        self.reloadChart();
      });
    },
    reloadChart: function reloadChart() {
      var controller = this;
      controller.get('weightChart').send('reloadChart');
    },
    actions: {
      save: function save(invoice) {
        $.magnificPopup.close();
      },
      reloadChart: function reloadChart() {
        this.reloadChart();
      },
      openWeight: function openWeight(id, patientId) {

        var self = this;

        $('#weight-form').attr('data-id', id);
        $('#weight-form').attr('data-patient-id', patientId);

        if (id > 0) {
          $.ajax({
            url: "/api/patientWeightInfos/" + id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            if (data.patientWeightInfo.date) {
              data.patientWeightInfo.dateInput = moment(data.patientWeightInfo.date).format('DD. MM. YYYY');
            }
            if (data.patientWeightInfo.weight) {
              data.patientWeightInfo.weight = new String(data.patientWeightInfo.weight).replace('.', ',');
            }
            self.set('weightData', data);
          }, function (error) {});
        } else {

          var data = {
            dateInput: moment().format('DD. MM. YYYY')
          };

          self.set('weightData', data);
          $('#weight-form').find('#weight').val('');
          $('#weight-form').find('#notice').val('');
        }

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#weight-form'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      deleteWeight: function deleteWeight(weight, patientId) {
        var controller = this;
        var self = this;

        controller.get('weightChart').send('reloadChart');

        weight.destroyRecord();
        Ember['default'].run.later(this, function () {
          this.sendAction('reloadWeightData', patientId, self.get('sortDir'));
        }, 1000);
      },
      saveWeight: function saveWeight(data) {
        var controller = this,
            self = this;

        $.ajax({
          url: "/api/patientWeightInfos",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert!');
          setTimeout(function () {
            self.sendAction('reloadPatient');
          }, 500);
          setTimeout(function () {
            self.sendAction('reopen');
          }, 1000);
        }, function () {
          jason.notifiction.systemError('Fehlercode 109', 'Eingaben konnte nicht gespeichert werden');
        });
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      sortDir: function sortDir(patientId) {
        this.set('sortDir', this.get('sortDir') === "asc" ? 'desc' : 'asc');
        this.sendAction('reloadWeightData', patientId, this.get('sortDir'));
      }
    },
    watchLower: (function () {
      var self = this;
      this.changeBoundaries();
    }).observes('patient.normalWeightLower'),
    watchUpper: (function () {
      var self = this;
      this.changeBoundaries();
    }).observes('patient.normalWeightUpper')
  });

});