define('jason-frontend/routes/inventory/stocktaking', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    perPage: 10,
    queryParams: {},
    setupController: function setupController(controller, model) {
      controller.set("model", model);
      this.applyFilter();
    },
    actions: {
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      },
      print: function print(id) {
        window.open('/api/inventoryStocktakings/download/' + id);
      },
      remove: function remove(id) {

        var self = this;

        $.ajax({
          url: "/api/inventoryStocktakings/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Inventur', 'entfernt!');
          self.applyFilter();
        }, function () {});
      },
      addStocktaking: function addStocktaking() {

        var self = this;

        $.ajax({
          url: "/api/inventoryStocktakings",
          method: "PUT",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('inventory.stocktaking-detail', data);
        }, function () {});
      },
      lock: function lock(id) {
        var self = this;
        $.ajax({
          url: "/api/inventoryStocktakings/" + id + "/lock",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.applyFilter();
        }, function () {});
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          code = controller.get('filterCode'),
          status = controller.get('filterStatus'),
          warehouse = controller.get('filterWarehouse');

      if (warehouse) {
        warehouse = warehouse.id;
      }

      controller.set('showLoading', true);

      this.findPaged('inventoryStocktaking', {
        filterCode: code,
        filterStatus: status,
        filterWarehouse: warehouse,
        pageSize: this.get('perPage')
      }).then(function (data) {
        var meta = data.meta;
        controller.set('model', data);
        controller.set('showLoading', false);
      });
    }
  });

});