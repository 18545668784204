define('jason-frontend/components/format-currency', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    value: 0,
    forceNegative: false,
    onlyFull: false,
    formattedValue: (function () {

      if (this.get('onlyFull')) {
        return accounting.formatMoney(this.get('value'), "€ ", 0, ".", ",");
      }

      if (this.get('forceNegative')) {
        return accounting.formatMoney(this.get('value'), "- € ", 2, ".", ",");
      } else {
        return accounting.formatMoney(this.get('value'), "€ ", 2, ".", ",");
      }
    }).property('value')
  });

});