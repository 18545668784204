define('jason-frontend/templates/components/button-in-treatment-confirm', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 4
            },
            "end": {
              "line": 8,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/button-in-treatment-confirm.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("path");
          dom.setAttribute(el1,"d","M15,30 C23.2842713,30 30,23.2842713 30,15 C30,6.71572875 23.2842713,0 15,0 C6.71572875,0 0,6.71572875 0,15 C0,23.2842713 6.71572875,30 15,30 L15,30 L15,30 Z M15,28.5714286 C7.50470696,28.5714286 1.42857143,22.495293 1.42857143,15 C1.42857143,7.50470696 7.50470696,1.42857143 15,1.42857143 C22.495293,1.42857143 28.5714286,7.50470696 28.5714286,15 C28.5714286,22.495293 22.495293,28.5714286 15,28.5714286 L15,28.5714286 L15,28.5714286 Z");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'fill');
          return morphs;
        },
        statements: [
          ["attribute","fill",["concat",[["get","color",["loc",[null,[7,20],[7,25]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-in-treatment-confirm.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"class","btn-delete");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el2 = dom.createElement("svg");
        dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el2,"viewBox","0 0 32 32");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"fill","none");
        dom.setAttribute(el3,"fill-rule","evenodd");
        dom.setAttribute(el3,"transform","translate(-10,-10) scale(1.6, 1.6)");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"class","icon");
        dom.setAttribute(el4,"d","M19.538 8.005c.07.012.132.047.18.1l3.605 3.605c.015.012.028.026.04.04l.877.857c.083.08.116.2.086.314-.03.113-.118.2-.23.23-.113.03-.233-.002-.314-.085l-.678-.677-1.793 1.793.18.18.5.498c.023.023.043.05.058.08l.837.837c.093.078.133.203.103.322-.03.118-.125.208-.244.233-.12.025-.243-.02-.317-.117l-.658-.677-7.91 7.89c-.087.09-.22.122-.34.08l-2.967-1.276L8.9 23.884c-.08.093-.205.132-.323.102-.118-.03-.21-.124-.234-.244-.024-.12.02-.242.118-.317l1.654-1.653-1.275-2.95c-.058-.12-.034-.262.06-.358l7.908-7.89-.697-.677c-.102-.1-.125-.257-.055-.383s.215-.19.355-.155c.062.016.117.05.16.1l.896.895.697.678 1.793-1.793-.677-.678c-.11-.092-.145-.247-.084-.377.06-.13.2-.205.342-.18zm.857 1.673l-1.793 1.813 2.25 2.252 1.794-1.793-2.25-2.272zm-3.148 1.355l-.917.916 1.136 1.115-.438.438-1.136-1.115-.916.916 1.135 1.116-.437.438-1.136-1.116-.896.897 1.116 1.135-.438.438-1.136-1.116-.896.897 1.135 1.116-.457.458-1.135-1.116-.877.877 1.117 1.134-.46.46-1.134-1.117-.976.976 1.215 2.81 2.87 1.235 7.73-7.73-.6-.618-.038-.02-2.75-2.75c-.033-.027-.06-.06-.08-.1l-.597-.577z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        dom.setNamespace(null);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","delete-confirm medium-confirm");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","footer");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","button");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createTextNode("Nein");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","button");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createTextNode("Ja");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [1, 1]);
        var element4 = dom.childAt(fragment, [2]);
        var element5 = dom.childAt(element4, [3]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element5, [3]);
        var morphs = new Array(10);
        morphs[0] = dom.createAttrMorph(element1, 'data-id');
        morphs[1] = dom.createAttrMorph(element1, 'title');
        morphs[2] = dom.createElementMorph(element1);
        morphs[3] = dom.createAttrMorph(element2, 'width');
        morphs[4] = dom.createAttrMorph(element2, 'height');
        morphs[5] = dom.createAttrMorph(element3, 'fill');
        morphs[6] = dom.createMorphAt(element2,3,3);
        morphs[7] = dom.createMorphAt(element4,1,1);
        morphs[8] = dom.createElementMorph(element6);
        morphs[9] = dom.createElementMorph(element7);
        return morphs;
      },
      statements: [
        ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[1,54],[1,61]]]]]]],
        ["attribute","title",["concat",[["get","tooltip",["loc",[null,[1,74],[1,81]]]]]]],
        ["element","action",["confirm"],[],["loc",[null,[1,3],[1,23]]]],
        ["attribute","width",["concat",[["get","size",["loc",[null,[2,51],[2,55]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[2,69],[2,73]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[4,33],[4,38]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[6,10],[6,20]]]]],[],0,null,["loc",[null,[6,4],[8,11]]]],
        ["content","text",["loc",[null,[12,2],[12,10]]]],
        ["element","action",["cancel"],[],["loc",[null,[14,11],[14,30]]]],
        ["element","action",["delete"],[],["loc",[null,[15,11],[15,30]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});