define('jason-frontend/components/quick-scan-product-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    referer: null,
    ehapoAware: false,
    directEhapo: null,
    useFavorites: true,
    useDosing: true,
    ehapoOptions: [{ name: "Direkt aus Lager ausbuchen", id: "ehapo" }, { name: "Korrekturbuchung", id: "correction" }, { name: "Keine", id: "none" }],

    actions: {
      confirm: function confirm() {
        var id = new String(this.get('qrProductInput')).toLowerCase();
        id = id.replace('ehapo', '');

        if (id.indexOf("qr") == -1) {
          return;
        }

        id = id.replace('qr', '');

        var directEhapo = this.get('directEhapo'),
            serviceOrproduct = id.startsWith('service') || id.startsWith('product') || id.startsWith('package');

        if (id.length == 0) {
          jason.notifiction.error('Eingabe nicht korrekt', '');
          this.set('qrProductInput', '');
          $('#qrProductInput').prop('disabled', false);
          $('#qrProductInput').focus();
          $('#qrProductInput').click();
          $('#qrProductInput').focus();
          return;
        }

        try {
          if (directEhapo) {
            if (this.get('useFavorites') && directEhapo.id === 'ehapo') {
              directEhapo.useFavorites = true;
            } else {
              directEhapo.useFavorites = false;
            }

            if (this.get('useDosing') && directEhapo.id === 'ehapo') {
              directEhapo.useDosing = true;
            } else {
              directEhapo.useDosing = false;
            }

            if (directEhapo.id === 'correction') {
              this.sendAction('openCorrections', id, this.get('patientId'));
              return;
            }
          }
        } catch (e) {}

        if (!serviceOrproduct && (this.get('qrProductInput').startsWith('ehapo') || this.get('referer') === 'ehapo')) {
          this.sendAction('confirm', id, this.get('patientId'), directEhapo);
        } else {
          this.sendAction('addArticle', id, this.get('patientId'), this.get('ctx'));
        }

        $.magnificPopup.close();
      },
      loadWithoutDirect: function loadWithoutDirect(ctx) {
        this.set('qrProductInput', '');
        this.set('referer', '');
        this.set('ctx', ctx);
        this.set('showDirectEhapo', false);

        setTimeout(function () {
          $('#qrProductInput').prop('disabled', false);
          $('#qrProductInput').focus();
          $('#qrProductInput').click();
          $('#qrProductInput').focus();
        }, 500);

        var self = this;
        Ember['default'].run.later(this, function () {
          self.autoSubmitForm();
        }, 500);
      },
      load: function load(referer, directEhapo) {

        var self = this;
        this.set('qrProductInput', '');
        this.set('referer', referer);
        this.set('showDirectEhapo', true);

        setTimeout(function () {
          $('#qrProductInput').prop('disabled', false);
          $('#qrProductInput').focus();
          $('#qrProductInput').click();
          $('#qrProductInput').focus();
        }, 500);

        if (!directEhapo) {
          directEhapo = {
            id: 'ehapo',
            useFavorites: API['default'].isUseFavorites(),
            useDosing: API['default'].isUseAutodosing()
          };
          this.set('useFavorites', API['default'].isUseFavorites());
          this.set('useDosing', API['default'].isUseAutodosing());
        } else {
          this.set('useFavorites', directEhapo.useFavorites);
          this.set('useDosing', directEhapo.useDosing);
        }

        this.set('directEhapo', directEhapo);

        var self = this;
        Ember['default'].run.later(this, function () {
          self.autoSubmitForm();
        }, 500);
      },
      setPatientId: function setPatientId(patientId) {
        this.set('patientId', patientId);
      }
    },
    autoSubmitForm: function autoSubmitForm() {
      var self = this;
      Ember['default'].run.later(this, function () {
        var form = $('#productScanForm');
        if (form && !$('#qrProductInput').prop('disabled')) {
          form.submit();
        }
        if ($(document).find('#modal-quick-scan-product-panel').length != 0 && !$(document).find('#modal-quick-scan-product-panel').hasClass('mfp-hide')) {
          self.autoSubmitForm();
        }
      }, 500);
    },
    watchUseDosing: (function () {
      $('#qrProductInput').focus();
    }).observes('useDosing'),
    watchUseFavorites: (function () {
      $('#qrProductInput').focus();
    }).observes('useFavorites'),
    watchDirectEhapo: (function () {
      $('#qrProductInput').focus();
    }).observes('directEhapo')
  });

});