define('jason-frontend/controllers/inventory/movements', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Lagerjournal",
    breadCrumbPath: "inventory.agg-stock",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    showFilters: true,
    page: 1,
    showLoading: false,
    filterCode: null,
    pickDate: moment().format("DD. MM. YYYY") + " - " + moment().format("DD. MM. YYYY"),
    perPage: 20,
    types: [{ name: "Ausbuchung", id: "outgoing" }, { name: "Einkauf", id: "purchase" }, { name: "Korrektur", id: "correction" }, { name: "Lagerwechsel", id: "change" }, { name: "Verkauf", id: "sale" }],
    actions: {
      scan: function scan(nr) {

        this.get('quickScanProductPanel').send('loadWithoutDirect');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-quick-scan-product-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    },
    myStickyOptionsTableHead: {
      topSpacing: 160,
      zIndex: 9
    },
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('pickDate'),
    watchType: (function () {
      this.send('applyFilter');
    }).observes('filterType'),
    watchProduct: (function () {
      this.send('applyFilter');
    }).observes('filterProduct')
  });

});