define('jason-frontend/models/cashdesk', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    created: DS['default'].attr('date'),
    recieptDate: DS['default'].attr('date'),
    formattedCreated: DS['default'].attr('string'),
    transactionId: DS['default'].attr('number'),
    invoiceId: DS['default'].attr('number'),
    invoiceCode: DS['default'].attr('string'),
    customerFirstname: DS['default'].attr('string'),
    customerLastname: DS['default'].attr('string'),
    customerId: DS['default'].attr('number'),
    cancellation: DS['default'].attr('boolean'),
    banktransfer: DS['default'].attr('boolean'),
    extern: DS['default'].attr('boolean'),
    total: DS['default'].attr('number'),
    transactions: DS['default'].hasMany('cashdeskEntry'),
    transactionType: DS['default'].belongsTo('transactionType'),
    transactionSubType: DS['default'].belongsTo('transactionSubType')
  });

});