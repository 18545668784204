define('jason-frontend/models/invoice-patient', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    entries: DS['default'].hasMany('orderEntry'),
    patient: DS['default'].belongsTo('patient'),
    customer: DS['default'].belongsTo('customer')
  });

});