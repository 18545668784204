define('jason-frontend/components/edit-labreference-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      confirm: function confirm() {
        var model = this.get('item');
        var data = {
          id: model.id,
          minReference: String(model.minReference).replace(",", "."),
          maxReference: String(model.maxReference).replace(",", "."),
          unit: model.unit,
          labParamId: this.get('labParamId')
        };

        if (model.patientCategory) {
          data.patientCategory = {
            id: model.patientCategory.id
          };
        }

        this.sendAction('confirm', data);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(invoice, labParamId) {
        this.set('item', invoice);
        this.set('labParamId', labParamId);

        if (invoice == null) {
          this.set('item', {});
        }
      }
    }
  });

});