define('jason-frontend/components/treatment-future-action-form', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    selectTab: 'masterdata',
    showArticles: false,
    actions: {
      selectTab: function selectTab(selected) {
        var controller = this.get('controller');
        controller.set('selectTab', selected);
      },

      save: function save(model, refererId) {

        var userIds = "";
        $('input.messageUser:checked').each(function (index, field) {
          userIds += "," + $(this).val();
        });
        model.set('messageUsers', userIds);

        this.sendAction('save', model, refererId);
      },
      addArticle: function addArticle(id) {
        this.sendAction('addArticle', id);
      },
      addTemplate: function addTemplate(id) {
        this.sendAction('addTemplate', id);
      },
      changeQuantity: function changeQuantity(entry, id, productName) {
        this.sendAction('changeQuantity', entry, id, productName);
      },
      changeGrossPrice: function changeGrossPrice(price, entryId, id, productName) {
        this.sendAction('changeGrossPrice', price, entryId, id, productName);
      },
      changeApplied: function changeApplied(applied, entryId, id, productName) {
        this.sendAction('changeApplied', applied, entryId, id, productName);
      },
      deleteProduct: function deleteProduct(entryId, offerId, productName) {
        this.sendAction('deleteProduct', entryId, offerId, productName);
      },
      clickBack: function clickBack(id) {
        this.sendAction('clickBack', id);
      },
      increaseSpinner: function increaseSpinner(model, attribute) {
        model.set(attribute, model.get(attribute) + 1);
      },
      decreaseSpinner: function decreaseSpinner(model, attribute) {
        if (model.get(attribute) > 1) {
          model.set(attribute, model.get(attribute) - 1);
        }
      }
    },
    watchType: (function () {
      if (this.get('future') && this.get('future').get('type')) {
        this.sendAction('setFutureType', this.get('future').get('type').id, this.get('future').id);
      }
      if (this.get('future') && this.get('future').get('type') && this.get('future').get('type').id === 'add_article') {
        this.set('showArticles', true);
      } else {
        this.set('showArticles', false);
      }
      if (this.get('future') && this.get('future').get('type') && this.get('future').get('type').id === 'add_template') {
        this.set('showTemplates', true);
      } else {
        this.set('showTemplates', false);
      }
      if (this.get('future') && this.get('future').get('type') && this.get('future').get('type').id === 'message') {
        this.set('showMessage', true);
      } else {
        this.set('showMessage', false);
      }
      if (this.get('future') && this.get('future').get('type') && this.get('future').get('type').id === 'add_appointment') {
        this.set('showAppointment', true);
      } else {
        this.set('showAppointment', false);
      }
      if (this.get('future') && this.get('future').get('type') && this.get('future').get('type').id === 'add_task') {
        this.set('showTask', true);
      } else {
        this.set('showTask', false);
      }
      if (this.get('future') && this.get('future').get('type') && this.get('future').get('type').id === 'app_message') {
        this.set('showAppMessage', true);
      } else {
        this.set('showAppMessage', false);
      }
      if (this.get('future') && this.get('future').get('type') && this.get('future').get('type').id === 'treatment') {
        this.set('showTreatment', true);
      } else {
        this.set('showTreatment', false);
      }

      if (this.get('future') && this.get('future').get('type') && this.get('future').get('type').id === 'add_reminder') {
        this.set('showReminder', true);
        if (!this.get('future').get('reminderText')) {
          this.get('future').set('reminderText', this.get('future').get('defaultReminderText'));
        }
        if (!this.get('future').get('reminderSubject')) {
          this.get('future').set('reminderSubject', this.get('future').get('defaultReminderSubject'));
        }
        if (!this.get('future').get('reminderClosing')) {
          this.get('future').set('reminderClosing', this.get('future').get('defaultReminderClosing'));
        }
        if (!this.get('future').get('reminderHeadline')) {
          this.get('future').set('reminderHeadline', this.get('future').get('defaultReminderHeadline'));
        }
        if (!this.get('future').get('reminderSalutation')) {
          this.get('future').set('reminderSalutation', this.get('future').get('defaultReminderSalutation'));
        }
      } else {
        this.set('showReminder', false);
      }
    }).observes('future.type'),
    watchTriggerType: (function () {
      if (this.get('future') && this.get('future').get('triggerType') && this.get('future').get('triggerType').id === 'onetime') {
        this.set('showOneTimeTrigger', true);
      } else {
        this.set('showOneTimeTrigger', false);
      }

      if (this.get('future') && this.get('future').get('triggerType') && this.get('future').get('triggerType').id === 'multitime') {
        this.set('showMultiTimeTrigger', true);
      } else {
        this.set('showMultiTimeTrigger', false);
      }
    }).observes('future.triggerType')
  });

});