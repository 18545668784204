define('jason-frontend/models/stocktaking-diff-entry', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    number: DS['default'].attr('number'),
    product: DS['default'].attr(),
    quantity: DS['default'].attr(),
    warehouse: DS['default'].attr(),
    packageQuantity: DS['default'].attr(),
    unitName: DS['default'].attr()
  });

});