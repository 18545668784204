define('jason-frontend/controllers/treatmentrooms', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Meine Behandlungs-Lokationen",
    breadCrumbPath: "treatmentrooms.index",
    filterFavorites: false
  });

});