define('jason-frontend/components/edit-lab-date-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      confirm: function confirm() {

        var data = {
          id: this.get('item').formId,
          date: this.get('item').createdDate,
          time: this.get('item').createdTime
        };

        this.sendAction('confirm', data);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(item) {
        console.log(item);
        this.set('item', item);
      }
    }
  });

});