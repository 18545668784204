define('jason-frontend/templates/components/button-user', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-user.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(11 8)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M11.9026667,10.3984681 C11.3201667,9.80374468 10.5521667,9.46910638 9.73216667,9.44340426 C9.20583333,8.19148936 8.00183333,7.36817021 6.65566667,7.36817021 L3.50766667,7.36817021 C1.66033333,7.36817021 0.157333333,8.90314894 0.157333333,10.7897872 L0.157333333,12.9434894 C0.157333333,13.1537021 0.324333333,13.3244255 0.530333333,13.3244255 L6.4745,13.3244255 C6.592,13.9702128 6.895,14.5645957 7.35966667,15.0389787 C7.96616667,15.6585532 8.773,15.9998298 9.6315,15.9998298 C10.4895,15.9998298 11.2966667,15.6587234 11.9041667,15.0389787 C13.1561667,13.7594894 13.1555,11.6777872 11.9026667,10.3984681 Z M0.903333333,10.789617 C0.903333333,9.32323404 2.0715,8.13004255 3.50766667,8.13004255 L6.65566667,8.13004255 C7.61766667,8.13004255 8.4855,8.67251064 8.93716667,9.51710638 C8.34433333,9.64987234 7.79916667,9.95012766 7.35983333,10.3986383 C6.78916667,10.9812766 6.4635,11.7448511 6.426,12.562383 L0.903333333,12.562383 L0.903333333,10.789617 Z M11.3766667,14.5000851 C10.91,14.976 10.2901667,15.2377872 9.63133333,15.2377872 C8.972,15.2377872 8.35283333,14.976 7.88733333,14.5000851 C7.421,14.0245106 7.1645,13.3918298 7.1645,12.7189787 C7.1645,12.0461277 7.421,11.4132766 7.88733333,10.9373617 C8.35283333,10.4619574 8.972,10.2003404 9.63133333,10.2003404 C10.2901667,10.2003404 10.9096667,10.4619574 11.3753333,10.9373617 C12.3371667,11.9196596 12.3375,13.5177872 11.3766667,14.5000851 Z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M10.8325 12.3375319L10.0041667 12.3375319 10.0041667 11.4929362C10.0041667 11.2827234 9.83716667 11.112 9.63133333 11.112 9.42533333 11.112 9.25833333 11.2827234 9.25833333 11.4929362L9.25833333 12.3375319 8.43033333 12.3375319C8.2245 12.3375319 8.05733333 12.5082553 8.05733333 12.7184681 8.05733333 12.9286809 8.2245 13.0994043 8.43033333 13.0994043L9.25833333 13.0994043 9.25833333 13.9451915C9.25833333 14.1554043 9.42533333 14.3261277 9.63133333 14.3261277 9.83716667 14.3261277 10.0041667 14.1554043 10.0041667 13.9451915L10.0041667 13.0995745 10.8325 13.0995745C11.0385 13.0995745 11.2055 12.9288511 11.2055 12.7186383 11.2055 12.5084255 11.0385 12.3375319 10.8325 12.3375319zM5.08133333 6.70638298C6.89233333 6.70638298 8.36566667 5.20255319 8.36566667 3.35404255 8.36566667 1.50485106 6.89233333.000340425532 5.08133333.000340425532 3.27133333.000340425532 1.79866667 1.50485106 1.79866667 3.35404255 1.79866667 5.20238298 3.27116667 6.70638298 5.08133333 6.70638298zM5.08133333.762212766C6.48083333.762212766 7.6195 1.92493617 7.6195 3.35404255 7.6195 4.78246809 6.48083333 5.94451064 5.08133333 5.94451064 3.6825 5.94451064 2.54466667 4.78246809 2.54466667 3.35404255 2.54466667 1.92493617 3.6825.762212766 5.08133333.762212766z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element1, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[2,13],[2,18]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});