define('jason-frontend/models/serviceunit', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    internalId: DS['default'].attr(''),
    internalId2: DS['default'].attr(''),
    name: DS['default'].attr('string'),
    acronym: DS['default'].attr('string'),
    conversionFactor: DS['default'].attr(''),
    editable: DS['default'].attr('boolean')
  });

});