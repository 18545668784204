define('jason-frontend/helpers/minus', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.minus = minus;

  function minus(params) {
    var first = isNaN(params[0]) ? String(params[0]).replace(',', '.') : params[0];
    var second = isNaN(params[1]) ? String(params[1]).replace(',', '.') : params[1];
    return Math.round((first - second) * 100) / 100;
  }

  exports['default'] = Ember['default'].Helper.helper(minus);

});