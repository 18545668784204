define('jason-frontend/initializers/cloudinary', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = {
    name: 'cloudinary',

    initialize: function initialize() {
      Ember['default'].$.cloudinary.config({
        cloud_name: 'myjason',
        api_key: '143434877491364'
      });
    }
  };

});