define('jason-frontend/routes/purchases/ws-logs', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {
      return this.findPaged('ws-log', params);
    },
    setupController: function setupController(controller, model, params) {
      controller.set("model", model);
      controller.set('mandants', this.store.find('mandant', { filterModule: 'test' }));
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterCode = controller.get('filterCode'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy'),
          dateRange = controller.get('pickDate'),
          filterMandant = controller.get('filterMandant'),
          dateFromTo = dateRange.split('-');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      this.findPaged('ws-log', {
        filterCode: filterCode,
        filterFrom: from,
        filterMandant: filterMandant,
        filterTo: to,
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    actions: {
      applyFilter: function applyFilter() {
        this.applyFilter();
      }
    }
  });

});