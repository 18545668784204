define('jason-frontend/controllers/etigafeed/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Bearbeiten",
    breadCrumbPath: "document-tags.edit",
    showVisibility: true,
    showFilters: false,
    showLayout: true,
    watchCategory: (function () {
      var self = this;

      if (self.get('model').get('patientCategory')) {
        try {
          $.ajax({
            url: "/api/digitalRaces/" + self.get('model').get('patientCategory').id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.set('digitalRaces', data.digitalRace);
          });
        } catch (e) {}
      }
    }).observes('model.patientCategory')
  });

});