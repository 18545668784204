define('jason-frontend/controllers/sale/services', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Leistungen",
    breadCrumbPath: "sale.services",
    queryParams: ["page", "perPage", "filterName", "filterCategory", "sortBy", "sortDir"],
    filterName: null,
    switchFilters: true,
    filterCategory: null,
    filterSupplier: 'all',
    filterDistributor: null,
    filterAll: !API['default'].isPreselectFavorites(),
    filterFavs: API['default'].isPreselectFavorites(),
    articleTags: null,
    showFilters: true,
    filterTiny: true,
    filterHorse: true,
    filterUse: true,
    myStickyOptionsTableHead: {
      topSpacing: 160,
      zIndex: 9
    },
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    sortBy: null,
    sortDir: null,
    watchName: (function () {
      this.set('page', 1);
    }).observes('filterName'),
    watchCategory: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('selectedCategoryFilters'),
    watchSupplier: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterSupplier'),
    watchDistributor: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterDistributor'),
    watchFavs: (function () {
      if (this.get('filterFavs')) {
        this.set('filterAll', false);
      } else {
        this.set('filterAll', true);
      }
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterFavs'),
    watchSmall: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterTiny'),
    watchHorse: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterHorse'),
    watchUse: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterUse'),
    watchfilterAll: (function () {
      if (this.get('filterAll')) {
        this.set('filterFavs', false);
      } else {
        this.set('filterFavs', true);
      }
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterAll'),
    page: 1,
    perPage: 10
  });

});