define('jason-frontend/components/digital-form-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    loading: true,
    actions: {
      save: function save() {
        this.sendAction('confirm', this.get('item'), this.get('entryId'));
      },
      selectBoolean: function selectBoolean(item, value) {
        Ember['default'].set(item, 'value', value);

        var id = item.id;
        if (value === 'true') {
          $('div[data-parent-id=' + id + ']').each(function () {
            $(this).removeClass('hidden2');
          });
        } else {
          $('div[data-parent-id=' + id + ']').each(function () {
            $(this).addClass('hidden2');
          });
        }
      },
      select: function select(item, root) {

        var selected = !item.selected;
        var self = this;
        if (root.provider === 'single') {
          root.values.forEach(function (elem) {
            Ember['default'].set(elem, 'selected', false);
            self.get('selectedValues').removeObject(elem);
          });
        }

        if (selected) {
          this.get('selectedValues').pushObject(item);
          Ember['default'].set(item, 'selected', true);
        } else {
          if (root.provider === 'multi') {
            this.get('selectedValues').removeObject(item);
            Ember['default'].set(item, 'selected', false);
          }
        }
      },
      cancel: function cancel() {
        this.sendAction('confirm', this.get('item'), this.get('entryId'));
        $.magnificPopup.close();
      },
      load: function load(item, entryId) {
        this.set('selectedValues', new Array());
        this.set('item', item);
        this.set('entryId', entryId);

        var self = this;
        setTimeout(function () {

          if (self.get('item')) {
            $(self.get('item').entries).each(function () {
              var current = $(this)[0];
              if (current.provider === 'boolean') {
                var id = current.id;
                if (current.value === 'true') {
                  $('div[data-parent-id=' + id + ']').each(function () {
                    $(this).removeClass('hidden2');
                  });
                } else {
                  $('div[data-parent-id=' + id + ']').each(function () {
                    $(this).addClass('hidden2');
                  });
                }
              }
            });
            $("#digitalForm textarea").each(function () {
              $(this).height(this.scrollHeight + parseFloat($(this).css("borderTopWidth")) + parseFloat($(this).css("borderBottomWidth")));
            });
          }
        }, 300);
      }
    },
    watchHeadlines: (function () {

      if (this.get('item')) {
        $(this.get('item').entries).each(function () {
          var current = $(this)[0];
          if (current.headline) {
            if (current.show) {
              $('div[data-headline-id="' + current.headlineId + '"]').not('.headline').each(function () {
                $(this).removeClass('hidden');
              });
            } else {
              $('div[data-headline-id="' + current.headlineId + '"]').not('.headline').each(function () {
                $(this).addClass('hidden');
              });
            }
          }
        });
      }
    }).observes('item.entries.@each.show')
  });

});