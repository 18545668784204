define('jason-frontend/helpers/multiply-percentage', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.multiplyPercentage = multiplyPercentage;

  function multiplyPercentage(params) {
    var net = params[0];
    var factor = params[1];

    if (isNaN(factor)) {
      factor = parseFloat(String(factor).replace(',', '.'));
    }
    if (factor == null) {
      factor = 100;
    }

    return net / 100 * factor;
  }

  exports['default'] = Ember['default'].Helper.helper(multiplyPercentage);

});