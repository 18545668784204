define('jason-frontend/models/patient-journal', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    date: DS['default'].attr('date'),
    title: DS['default'].attr('string'),
    type: DS['default'].attr('string'),
    tags: DS['default'].attr(''),
    payload: DS['default'].attr(''),
    referenceId: DS['default'].attr(''),
    eltigaRecord: DS['default'].attr(''),
    eltigaMandant: DS['default'].attr('')
  });

});