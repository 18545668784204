define('jason-frontend/controllers/resources/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    queryParams: ["page", "perPage", "filterName"],
    filterName: "",
    filterSource: null,
    sortBy: null,
    sortDir: null,
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",

    sources: [{ name: "Alle Quellen", id: "all" }, { name: "Jason Network", id: "jason" }, { name: "Meine Einheiten", id: "my" }],

    page: 1,
    perPage: 10
  });

});