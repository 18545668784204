define('jason-frontend/controllers/compositeproducts/select-service', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Leistung hinzufügen",
    breadCrumbPath: "compositeproducts.select-service",
    invoice: null,
    products: null,
    selectedPatient: 0,
    queryParams: ["page", "perPage"],
    filterName: null,
    filterSupplier: null,
    selectedCategoryFilters: null,
    productId: null,
    sortBy: null,
    sortDir: null,

    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    perPage: 10,
    watchName: (function () {
      this.set('page', 1);
    }).observes('filterName'),
    watchCategory: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('selectedCategoryFilters'),
    watchSupplier: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterSupplier')
  });

});