define('jason-frontend/helpers/is-quantity-editable', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isQuantityEditable = isQuantityEditable;

  function isQuantityEditable(params /*, hash*/) {
    var article = params[0];
    if (article && article.get('category')) {
      var category = article.get('category').get('id');
      if (category === 'anz') {
        return false;
      }
    }
    return true;
  }

  exports['default'] = Ember['default'].Helper.helper(isQuantityEditable);

});