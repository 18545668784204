define('jason-frontend/routes/serviceunits/create', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      var unit = this.store.createRecord('serviceunit');
      return unit;
    },
    actions: {
      create: function create() {
        var self = this;

        var controller = this.get('controller');
        var model = controller.get('model');
        var unit = new Object();
        unit.name = model.get('name');
        unit.acronym = model.get('acronym');

        $.ajax({
          url: "/api/serviceunits",
          method: "POST",
          data: JSON.stringify(unit),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('basicinfos', { queryParams: { selectTab: "serviceunits" } });
        });
      }
    }
  });

});