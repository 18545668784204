define('jason-frontend/controllers/products/create', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Anlegen",
    breadCrumbPath: "products.create",
    queryParams: ['referer'],
    referer: null,
    actions: {
      triggerSubmit: function triggerSubmit() {
        $('form#productEdit').submit();
      },
      create: function create(referer) {

        var allPrices = new Array(),
            valid = true,
            validUnit = true,
            unitsFound = new Array(this.get('model').get('unit').get('id')),
            refLine = $('div#default-row');

        $('div.price-line.cloned').each(function (index) {
          var line = $(this),
              price = line.find('input.product-price').val().replace(",", "."),
              unit = line.find('select.product-unit').val(),
              quantity = line.find('input.product-quantity-input').val().replace(",", "."),
              applied = line.find('input.product-applied-input').is(':checked'),
              tgd = line.find('input.product-tgd-input').is(':checked'),
              priceAddition = line.find('input.product-price-addition-input').val().replace(",", "."),
              id = line.data('id'),
              favButton = line.find('a.fav-button'),
              isFav = false;

          if (unitsFound.contains(unit)) {
            validUnit = false;
          }

          if (favButton.length) {
            isFav = favButton.data('selected');
          }

          unitsFound.push(unit);

          if (parseFloat(price) < 0.01) {}
          allPrices[index] = {
            id: id,
            price: price,
            favorite: isFav,
            applied: applied,
            tgd: tgd,
            quantity: quantity,
            priceAddition: priceAddition,
            unit: { id: unit, name: '' }
          };
        });

        if (!valid) {
          jason.notifiction.error('Eingaben prüfen', 'Bitte überprüfen Sie Ihre Preiseingabe');
          return;
        }

        if (!validUnit) {}

        var self = this;

        setTimeout(function () {
          var model = self.get('model');
          var price = model.get('priceExVat');
          if (price) {
            model.set('priceExVat', (price + '').replace(",", "."));
          }

          var patientCategory = new Array();
          $('form#productEdit #patient-category-panel input[type=checkbox].patientCategory:checked').each(function () {
            patientCategory.push({
              id: $(this).val()
            });
          });

          var product = new Object();
          product.name = model.get('name');
          product.priceExVat = $('input.product-price', refLine).val().replace(",", ".");
          product.individualName = model.get('individualName');
          product.doseMethod = model.get('doseMethod');
          product.doseInfoSpecial = model.get('doseInfoSpecial');
          product.individualSlopingInfo = model.get('individualSlopingInfo');
          product.individualDescription = model.get('individualDescription');

          if ($('input.product-purchase-price', refLine).val()) {
            product.purchasePrice = $('input.product-purchase-price', refLine).val().replace(",", ".");
          }

          product.quantity = $('input.product-quantity-input', refLine).val().replace(",", ".");
          product.description = model.get('description');

          if (model.get('category')) {
            product.category = { id: model.get('category').id, name: model.get('category').name };
          }

          if (model.get('manufacturer')) {
            product.manufacturer = { id: model.get('manufacturer').id, name: model.get('manufacturer').get('name') };
          }
          if (model.get('distributor')) {
            product.distributor = { id: model.get('distributor').id, name: model.get('distributor').get('name') };
          }
          if ($('a.btn-blub', refLine)) {
            product.favorite = $('a.btn-blub', refLine).data('selected') === true;
          }
          if (model.get('tax')) {
            product.tax = {
              id: model.get('tax').id,
              name: model.get('tax').get('name')
            };
          }

          if (!product.tax) {
            jason.notifiction.error('Eingaben prüfen', 'Bitte wählen Sie einen MWST-Satz aus');
            return;
          }

          product.unit = { id: $('select.product-unit', refLine).val() };
          product.prices = allPrices;
          product.referenceNumber = model.get('referenceNumber');
          product.patientCategories = patientCategory;

          product.applied = $('input.product-applied-input', refLine).is(':checked');
          product.tgd = $('input.product-tgd-input', refLine).is(':checked');

          if (model.get('labProfile')) {
            product.labProfile = { id: model.get('labProfile').id };
          }

          $.ajax({
            url: "/api/products",
            method: "POST",
            data: JSON.stringify(product),
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.transitionTo('products.edit', data.product.id);
          }, function (error) {});
        }, 1000);
      }
    }
  });

});