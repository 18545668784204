define('jason-frontend/components/document-edit-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    prefix: null,
    rootFolder: null,
    previousFolder: null,
    showFolderChooser: false,
    applyFilter: function applyFilter() {
      var self = this;
      this.externalContext.findPaged('document', {
        rootFolder: this.get('rootFolder'),
        patientId: this.get('patientId'),
        customerId: this.get('customerId'),
        onlyFolders: true,
        ctx: this.ctx
      }).then(function (entries) {
        self.set('folders', entries);
        self.set('breads', entries.meta.breads);
      });
    },
    actions: {
      selectFolder: function selectFolder(id) {
        var self = this;

        var data = {
          targetFolderId: id,
          patientId: this.get('patientId'),
          customerId: this.get('customerId')
        };

        var deferred = $.ajax({
          url: "/api/documents/" + this.get('document').internalId + "/movefolder",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('showFolderChooser', false);
          self.sendAction('reload', self.get('document').internalId);
          self.sendAction('refreshView');
        }, function (data) {
          self.set('showFolderChooser', false);
          self.sendAction('reload', self.get('document').internalId);
          self.sendAction('refreshView');
        });
      },
      gotoFolder: function gotoFolder(folder) {
        var folderId = folder.get('internalId');

        if (folder.get('rootFolderId')) {
          folderId = folder.get('rootFolderId');
        }

        if (folder.get('customerId') !== 'null') {
          this.customerId = folder.get('customerId');
        } else {
          this.customerId = null;
        }
        if (folder.get('patientId') !== 'null') {
          this.patientId = folder.get('patientId');
        } else {
          this.patientId = null;
        }

        if (folderId !== 'null') {
          this.previousFolder = this.rootFolder;
          this.set('rootFolder', folderId);
        } else {
          this.set('rootFolder', null);
        }
        this.applyFilter();
      },
      enableFolderChooser: function enableFolderChooser(media) {
        this.set('rootFolder', media.rootFolderId);
        this.set('patientId', media.patientId);
        this.set('customerId', media.customerId);
        this.set('showFolderChooser', true);
        this.applyFilter();
      },
      back: function back() {
        this.set('showFolderChooser', false);
      },
      'delete': function _delete(document) {
        this.sendAction('delete', this.get('document').internalId);
        $.magnificPopup.close();
      },
      save: function save() {
        this.sendAction('save', this.get('document'));
        $.magnificPopup.close();
      },
      load: function load(item, externalContext, prefix) {
        this.set('externalContext', externalContext);
        this.set('showFolderChooser', false);
        this.set('prefix', 'folder' + prefix);
        var original = item.created;
        Ember['default'].set(item, 'created', moment(original).format('DD. MM. YYYY'));
        Ember['default'].set(item, 'createdTime', moment(original).format('HH:mm'));
        this.set('document', item);
      }
    }
  });

});