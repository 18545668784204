define('jason-frontend/helpers/get-mandant-type', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.getMandantType = getMandantType;

  function getMandantType() {
    return API['default'].getMandantType().toUpperCase();
  }

  exports['default'] = Ember['default'].Helper.helper(getMandantType);

});