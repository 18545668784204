define('jason-frontend/routes/cashregisters/index', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find('cash-register');
    },
    actions: {
      startRksv: function startRksv(id) {
        var register = new Object();
        var self = this;
        register.id = id;

        $.ajax({
          url: "/api/cashRegisters/rksv",
          method: "POST",
          data: JSON.stringify(register),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Aktiviert', 'Elektronische Sperrvorrichtung erfolgreich aktiviert');
          self.refresh();
        }, function (data) {
          jason.notifiction.error('Fehler', 'Elektronische Sperrvorrichtung konnte nicht aktiviert werden - wenden Sie sich bitte an myJason.at');
        });
      },
      printNullReciept: function printNullReciept(id) {
        var register = new Object();
        var self = this;
        register.id = id;

        $.ajax({
          url: "/api/cashRegisters/printNullReciept",
          method: "POST",
          data: JSON.stringify(register),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'Nullbeleg erfolgreich erfasst');
        }, function (data) {
          jason.notifiction.error('Fehler', 'Nullbeleg konnte nicht erfasst werden');
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('mandant', this.store.find('mandant', API['default'].getMandant()));
    }
  });

});