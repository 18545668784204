define('jason-frontend/routes/cash/add-composite', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",

    model: function model(params) {
      params.filterMandant = "all";
      return this.findPaged('compositeArticle', params);
    },
    setupController: function setupController(controller, model, params) {
      controller.set("model", model);
      var invoiceId = params.params['cash.add_composite'].id,
          patientId = params.params['cash.add_composite'].patientId;

      controller.set("invoice", this.store.fetch('invoice', invoiceId));

      controller.set('suppliers', this.store.find('product-supplier'));

      controller.set("selectedPatient", patientId);
      controller.set("linkToEditEnabled", API['default'].hasPermission('crud_composite_products'));
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          selectedCategoryFilters = controller.get('selectedCategoryFilters'),
          filterSupplier = controller.get('filterSupplier'),
          filterManufacturer = controller.get('filterManufacturer'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy'),
          manufacturerId = null,
          filterFavorites = controller.get('filterFavorites'),
          filterId = null;

      if (filterSupplier === 'all') {
        filterSupplier = null;
      }

      if (selectedCategoryFilters) {
        filterId = selectedCategoryFilters.id;
      }

      this.findPaged('compositeArticle', {
        filterName: filterName,
        selectedCategoryFilters: filterId,
        filterSupplier: filterSupplier,
        filterManufacturer: manufacturerId,
        filterFavorites: filterFavorites,
        filterMandant: "all",
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    actions: {
      toggleFavorites: function toggleFavorites(enabled) {
        var controller = this.get('controller');
        controller.set('filterFavorites', enabled);
        this.applyFilter();
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      setSort: function setSort(attr) {
        var controller = this.get('controller');
        controller.set('sortBy', attr);
        if (controller.get('sortDir') === 'asc') {
          controller.set('sortDir', 'desc');
        } else {
          controller.set('sortDir', 'asc');
        }
        this.applyFilter();
      },
      addProduct: function addProduct(quantity, productId, invoiceId, priceId, productName) {

        var self = this,
            patientId = this.get('controller').get('selectedPatient');

        if (isNaN(patientId)) {
          patientId = 0;
        }

        if (quantity <= 0) {
          jason.notifiction.error('Fehler', 'Menge muss größer 0 sein');
          return;
        }

        var deferred = jQuery.ajax({
          url: "/api/invoices/" + invoiceId + "/add/composite/" + productId + "/" + quantity + "/" + patientId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.get('controller').set("invoice", self.store.fetch('invoice', invoiceId));
          jason.notifiction.notifiy('Paket hinzugefügt', productName);
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
        return Ember['default'].RSVP.resolve(deferred);
      }
    }
  });

});