define('jason-frontend/controllers/inventory/delivery-note-details', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Lieferschein einbuchen",
    breadCrumbPath: "inventory.delivery-note-details",
    page: 1,
    actions: {
      updateBarcode: function updateBarcode(value, entry) {
        var data = {
          code: value
        };

        $.ajax({
          url: "/api/inventoryStocks/convertDataMatrix",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data2) {
          if ('na' === data2.chargeNumber) {
            jason.notifiction.warning('Chargennummer nicht erkannt', '');
          } else {
            Ember['default'].set(entry, 'chargeNumber', data2.chargeNumber);
          }
          if (data2.expiryDate) {
            Ember['default'].set(entry, 'expiryDate', moment(data2.expiryDate).format('DD. MM. YYYY'));
          }
        }, function (data2) {});
      },
      openScan: function openScan(entry) {
        this.get('quickScanChargePanel').send('load', entry);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-quick-scan-charge-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    },
    watchPage: (function () {
      if (this.page !== undefined) {
        this.send('updatePage', this.page);
      }
    }).observes('page')
  });

});