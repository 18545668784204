define('jason-frontend/helpers/is-ehapo-available', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isEhapoAvailable = isEhapoAvailable;

  function isEhapoAvailable(params) {
    var entry = params[0],
        article = entry.get('article');

    if (!API['default'].isInventoryActive()) {
      return false;
    }

    if (!article) {
      return false;
    }

    if (article.get('type') === "service") {
      return false;
    }
    if (entry.get('laboklin') == true) {
      return false;
    }
    if (entry.get('hideEhapo') == true) {
      return false;
    }

    return true;
  }

  exports['default'] = Ember['default'].Helper.helper(isEhapoAvailable);

});