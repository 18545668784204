define('jason-frontend/components/select-customer-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';


  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    applyFilter: function applyFilter(first) {
      var self = this;

      var term = this.get('filterName');

      var url = "/api/customers?filterName=" + term;

      $.ajax({
        url: url,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('customers', data.customer);
      });
    },
    actions: {
      load: function load() {
        this.set('filterName', ' ');
        this.applyFilter(true);
      },
      applyFilter: function applyFilter(first) {
        this.applyFilter(first);
      },
      select: function select(customerId) {
        this.sendAction('select', customerId);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});