define('jason-frontend/templates/inventory/check-detail', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 24
              },
              "end": {
                "line": 56,
                "column": 156
              }
            },
            "moduleName": "jason-frontend/templates/inventory/check-detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","entry.productName",["loc",[null,[56,135],[56,156]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 12
            },
            "end": {
              "line": 88,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/inventory/check-detail.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-24/24@phone l-24/24@tablet l-6/24@desk");
          dom.setAttribute(el2,"data-label","Bezeichnung des Arzneimittels");
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-12/24@phone l-12/24@tablet l-3/24@desk");
          dom.setAttribute(el2,"data-label","Inventar-Nr");
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-23/24@phone l-23/24@tablet l-3/24@desk");
          dom.setAttribute(el2,"data-label","Chargen-Nr");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-1/24@phone l-1/24@tablet l-4/24@desk table__cell--center--desktop");
          dom.setAttribute(el2,"data-label","Lagerplatz");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-24/24@phone l-24/24@tablet l-4/24@desk table__cell--center--desktop");
          dom.setAttribute(el2,"data-label","Ergebnis der Kontrolle");
          var el3 = dom.createTextNode("\n\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","title");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-4/24 table__cell--action buttons-3");
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","exp icon-button icon-button--delete list-action-square");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [9, 1]);
          var element2 = dom.childAt(element0, [11, 1]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [5, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [7, 1]),0,0);
          morphs[4] = dom.createAttrMorph(element1, 'class');
          morphs[5] = dom.createMorphAt(element1,3,3);
          morphs[6] = dom.createMorphAt(element2,1,1);
          morphs[7] = dom.createMorphAt(dom.childAt(element2, [2]),0,0);
          return morphs;
        },
        statements: [
          ["block","link-to",["products.edit",["get","entry.productId",["loc",[null,[56,51],[56,66]]]],["subexpr","query-params",[],["selectTab","inventory"],["loc",[null,[56,67],[56,103]]]]],["class","cursor is-highlighted"],0,null,["loc",[null,[56,24],[56,168]]]],
          ["content","entry.stockId",["loc",[null,[59,24],[59,41]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","entry.chargeNumber",["loc",[null,[62,36],[62,54]]]]],[],[]],"class","gui-input","name","chargeNumber"],["loc",[null,[62,22],[62,94]]]],
          ["content","entry.locationInfo",["loc",[null,[65,22],[65,44]]]],
          ["attribute","class",["concat",["u-mb0 field select etiga ",["get","entry.result",["loc",[null,[69,57],[69,69]]]]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","resultList",["loc",[null,[72,28],[72,38]]]]],[],[]],"value",["subexpr","@mut",[["get","entry.result",["loc",[null,[73,26],[73,38]]]]],[],[]],"searchEnabled",false,"optionLabelPath","value","optionValuePath","id","allowClear",false,"cssClass","input--dropdown"],["loc",[null,[71,18],[79,20]]]],
          ["inline","button-delete-with-confirm",[],["showStroke",false,"color","#fff","item",["subexpr","@mut",[["get","entry",["loc",[null,[84,84],[84,89]]]]],[],[]],"confirmed","deleteEntry","text","Wollen Sie diese Kontrollposition wirklich stornieren?","content","Kontrollposition entfernen"],["loc",[null,[84,20],[84,214]]]],
          ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","entry",["loc",[null,[84,245],[84,250]]]]],[],[]],"confirmed","deleteEntry","textToShow","Löschen","text","Wollen Sie diese Kontrollposition wirklich stornieren?"],["loc",[null,[84,220],[84,359]]]]
        ],
        locals: ["entry"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 112,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/inventory/check-detail.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-14/24");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","with-icon button context primary");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/add-entry.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Position scannen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","site-content card with-context-bar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        dom.setAttribute(el2,"class","mb10");
        var el3 = dom.createTextNode("Kontrolle");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","tabs__body mt20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","userEdit");
        dom.setAttribute(el4,"class","admin-form");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 field field-datepicker");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","nextExecution");
        dom.setAttribute(el6,"class","field-label title");
        var el7 = dom.createTextNode("Kontrolldatum");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","date-picker__body");
        var el7 = dom.createTextNode("\n            am ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            um ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" Uhr\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-11/24 field--narrow");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","task-user");
        dom.setAttribute(el6,"class","select--inline input-element");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Durchgeführt von");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","field");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","taskComment");
        dom.setAttribute(el6,"class","field-label title");
        var el7 = dom.createTextNode("Notiz");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h1");
        var el6 = dom.createTextNode("Kontrollierte Arzneimittel ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("/10");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table box table--large no-box@phone");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-6/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Bezeichnung des Arzneimittels");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-3/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Inventar-Nr");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-3/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Chargen-Nr");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24 table__cell--center");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Lagerplatz");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24 table__cell--center");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Ergebnis der Kontrolle");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","action-icons");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","icon-button icon-button--small");
        dom.setAttribute(el3,"onclick","history.back()");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","icon-button");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","icon-button--success icon-button");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [3]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(fragment, [2]);
        var element7 = dom.childAt(element6, [3, 1, 1]);
        var element8 = dom.childAt(element7, [1, 3]);
        var element9 = dom.childAt(element6, [5]);
        var element10 = dom.childAt(element9, [3]);
        var element11 = dom.childAt(element9, [5]);
        var morphs = new Array(19);
        morphs[0] = dom.createAttrMorph(element3, 'class');
        morphs[1] = dom.createAttrMorph(element4, 'class');
        morphs[2] = dom.createElementMorph(element5);
        morphs[3] = dom.createElementMorph(element7);
        morphs[4] = dom.createMorphAt(element8,1,1);
        morphs[5] = dom.createMorphAt(element8,3,3);
        morphs[6] = dom.createMorphAt(dom.childAt(element7, [3, 1]),3,3);
        morphs[7] = dom.createMorphAt(dom.childAt(element7, [5]),3,3);
        morphs[8] = dom.createMorphAt(dom.childAt(element7, [7]),1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element7, [9, 3]),1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element9, [1]),1,1);
        morphs[11] = dom.createElementMorph(element10);
        morphs[12] = dom.createMorphAt(element10,1,1);
        morphs[13] = dom.createElementMorph(element11);
        morphs[14] = dom.createMorphAt(element11,1,1);
        morphs[15] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[16] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[17] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[18] = dom.createMorphAt(fragment,10,10,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["context-bar grid ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,50],[1,63]]]],"sidebar",""],[],["loc",[null,[1,29],[1,78]]]]]]],
        ["attribute","class",["concat",["buttons-box col l-",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[4,53],[4,66]]]],"4","10"],[],["loc",[null,[4,32],[4,77]]]],"/24"]]],
        ["element","action",["scan"],[],["loc",[null,[5,7],[5,24]]]],
        ["element","action",["save"],["on","submit"],["loc",[null,[15,45],[15,74]]]],
        ["inline","input",[],["id","addtaskDue","value",["subexpr","@mut",[["get","model.date",["loc",[null,[19,45],[19,55]]]]],[],[]],"class","js-date-picker input date-picker__input","name","activeFrom","placeholder","Datum"],["loc",[null,[19,15],[19,143]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.time",["loc",[null,[20,29],[20,39]]]]],[],[]],"id","addTaskDuetime","name","taskDuetime","class","input date-picker__input time"],["loc",[null,[20,15],[20,118]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","users",["loc",[null,[27,22],[27,27]]]]],[],[]],"value",["subexpr","@mut",[["get","model.user",["loc",[null,[28,20],[28,30]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","shortName","allowClear",false,"name","user"],["loc",[null,[26,12],[33,14]]]],
        ["inline","textarea",[],["rows",5,"value",["subexpr","@mut",[["get","model.notice",["loc",[null,[38,34],[38,46]]]]],[],[]],"class","input textarea__input full","name","taskComment","autocomplete","off"],["loc",[null,[38,10],[38,121]]]],
        ["inline","get-size",[["get","model.entries",["loc",[null,[41,50],[41,63]]]]],[],["loc",[null,[41,39],[41,65]]]],
        ["block","each",[["get","model.entries",["loc",[null,[53,20],[53,33]]]]],[],0,null,["loc",[null,[53,12],[88,21]]]],
        ["content","button-prev",["loc",[null,[96,6],[96,21]]]],
        ["element","action",["print",["get","model.id",["loc",[null,[98,24],[98,32]]]]],[],["loc",[null,[98,7],[98,34]]]],
        ["inline","button-print",[],["color","#ffffff","size","52","content","Kontrollbericht herunterladen"],["loc",[null,[99,6],[99,88]]]],
        ["element","action",["save"],[],["loc",[null,[101,9],[101,26]]]],
        ["inline","button-save",[],["color","#ffffff","size","52","content","Speichern"],["loc",[null,[102,8],[102,69]]]],
        ["inline","quick-scan-product-panel",[],["confirm","scanProduct","ehapoAware",true,"addArticle","scanProduct","actionReceiver",["subexpr","@mut",[["get","quickScanProductPanel",["loc",[null,[107,105],[107,126]]]]],[],[]]],["loc",[null,[107,0],[107,128]]]],
        ["inline","select-inventory-panel-simple",[],["use","scanProduct","actionReceiver",["subexpr","@mut",[["get","selectInventoryPanel",["loc",[null,[108,65],[108,85]]]]],[],[]]],["loc",[null,[108,0],[108,87]]]],
        ["inline","select-stock-panel",[],["select","manualStockSelected","actionReceiver",["subexpr","@mut",[["get","stockSelectPanel",["loc",[null,[109,65],[109,81]]]]],[],[]]],["loc",[null,[109,0],[109,83]]]],
        ["content","js-datepicker-script",["loc",[null,[111,0],[111,24]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});