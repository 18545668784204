define('jason-frontend/templates/purchases/dashboard', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 6
            },
            "end": {
              "line": 58,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/purchases/dashboard.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24@desk l-6/24@desk gutter--small");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","filterMandant");
          dom.setAttribute(el2,"class","input-element");
          dom.setAttribute(el2,"style","width: 100%");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","title");
          var el4 = dom.createTextNode("Mandant");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","mandants",["loc",[null,[48,22],[48,30]]]]],[],[]],"value",["subexpr","@mut",[["get","filterMandant",["loc",[null,[49,20],[49,33]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","shortName","optionValuePath","id","placeholder","Alle","allowClear",true],["loc",[null,[47,12],[55,14]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 74,
              "column": 6
            },
            "end": {
              "line": 76,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/purchases/dashboard.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[75,8],[75,29]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 83,
                  "column": 22
                },
                "end": {
                  "line": 87,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/purchases/dashboard.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__cell table__cell--right l-4/24@desk mrm5");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2,"class","table__title title");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
              return morphs;
            },
            statements: [
              ["content","head",["loc",[null,[85,59],[85,67]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 87,
                    "column": 22
                  },
                  "end": {
                    "line": 91,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/dashboard.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__cell table__cell--right l-4/24@tablet l-2/24@desk mrm5");
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","table__title title");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
                return morphs;
              },
              statements: [
                ["content","head",["loc",[null,[89,59],[89,67]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 91,
                      "column": 22
                    },
                    "end": {
                      "line": 95,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/dashboard.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","table__cell table__cell--right l-4/24@desk mrm5");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","table__title title");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                      ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","head",["loc",[null,[93,59],[93,67]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 91,
                    "column": 22
                  },
                  "end": {
                    "line": 95,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/dashboard.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","filterType",["loc",[null,[91,36],[91,46]]]],"years"],[],["loc",[null,[91,32],[91,55]]]]],[],0,null,["loc",[null,[91,22],[95,22]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 87,
                  "column": 22
                },
                "end": {
                  "line": 95,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/purchases/dashboard.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","filterType",["loc",[null,[87,36],[87,46]]]],"months"],[],["loc",[null,[87,32],[87,56]]]]],[],0,1,["loc",[null,[87,22],[95,22]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 82,
                "column": 20
              },
              "end": {
                "line": 96,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/purchases/dashboard.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","filterType",["loc",[null,[83,32],[83,42]]]],"quarter"],[],["loc",[null,[83,28],[83,53]]]]],[],0,1,["loc",[null,[83,22],[95,29]]]]
          ],
          locals: ["head"],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 108,
                      "column": 229
                    },
                    "end": {
                      "line": 108,
                      "column": 258
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/dashboard.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("+");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 108,
                    "column": 208
                  },
                  "end": {
                    "line": 108,
                    "column": 305
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/dashboard.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("(");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" %)");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","gte",[["get","entry.change",["loc",[null,[108,240],[108,252]]]],0],[],["loc",[null,[108,235],[108,255]]]]],[],0,null,["loc",[null,[108,229],[108,265]]]],
                ["inline","format-number-german",[["get","entry.change",["loc",[null,[108,288],[108,300]]]]],[],["loc",[null,[108,265],[108,302]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 108,
                    "column": 305
                  },
                  "end": {
                    "line": 108,
                    "column": 319
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/dashboard.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 106,
                  "column": 24
                },
                "end": {
                  "line": 111,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/purchases/dashboard.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","small is-highlighted u-text--right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [2]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element0, 'class');
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
              morphs[2] = dom.createAttrMorph(element1, 'class');
              morphs[3] = dom.createMorphAt(element1,0,0);
              morphs[4] = dom.createMorphAt(dom.childAt(element0, [4]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["table__cell table__cell--right l-",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","filterType",["loc",[null,[107,96],[107,106]]]],"months"],[],["loc",[null,[107,92],[107,116]]]],"6","6"],[],["loc",[null,[107,71],[107,126]]]],"/24@tablet l-",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","filterType",["loc",[null,[107,164],[107,174]]]],"months"],[],["loc",[null,[107,160],[107,184]]]],"2","4"],[],["loc",[null,[107,139],[107,194]]]],"/24@desk"]]],
              ["inline","format-currency",[],["classNames","mbm10","value",["subexpr","@mut",[["get","entry.sum",["loc",[null,[108,77],[108,86]]]]],[],[]]],["loc",[null,[108,34],[108,88]]]],
              ["attribute","class",["concat",["small u-text--right inline ",["subexpr","css-bool-evaluator",[["subexpr","gte",[["get","entry.change",["loc",[null,[108,161],[108,173]]]],0],[],["loc",[null,[108,156],[108,176]]]],"is-positive","is-negative"],[],["loc",[null,[108,135],[108,206]]]]]]],
              ["block","if",[["get","entry.change",["loc",[null,[108,214],[108,226]]]]],[],0,1,["loc",[null,[108,208],[108,326]]]],
              ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","entry.advantage",["loc",[null,[109,121],[109,136]]]]],[],[]]],["loc",[null,[109,76],[109,138]]]]
            ],
            locals: ["entry"],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 99,
                "column": 20
              },
              "end": {
                "line": 113,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/purchases/dashboard.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-4/24@desk");
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","small inline");
            var el4 = dom.createTextNode("Differenz");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","small is-highlighted");
            var el4 = dom.createTextNode("Gesamtvorteil");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element2, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(element2,3,3);
            return morphs;
          },
          statements: [
            ["content","item.productSupplier.acronym",["loc",[null,[102,37],[102,69]]]],
            ["block","each",[["get","item.entries",["loc",[null,[106,32],[106,44]]]]],[],0,null,["loc",[null,[106,24],[111,33]]]]
          ],
          locals: ["item"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 76,
              "column": 6
            },
            "end": {
              "line": 117,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/purchases/dashboard.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table-wrapper mt20");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table");
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__head");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-4/24@desk cursor");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Lieferant");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__body");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]),3,3);
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","headers",["loc",[null,[82,28],[82,35]]]]],[],0,null,["loc",[null,[82,20],[96,29]]]],
          ["block","each",[["get","model",["loc",[null,[99,28],[99,33]]]]],[],1,null,["loc",[null,[99,20],[113,29]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 123,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/purchases/dashboard.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","content");
        dom.setAttribute(el1,"class","animated fadeIn dashboard");
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24 l-6/24@desk floating-card");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","card u-text--center");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Bestellvolumen");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("netto");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24 l-6/24@desk gutter floating-card");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","card u-text--center");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Ersparnis");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("netto");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24 l-6/24@desk gutter floating-card");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","card u-text--center");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Cashback Rabatt");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("netto");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24 l-6/24@desk gutter floating-card");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","card u-text--center");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Gesamtvorteil");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("netto");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","site-content card clear-left");
        var el3 = dom.createComment("\n   ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-6/24@desk l-6/24@desk gutter--small");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","input-element");
        dom.setAttribute(el4,"style","width: 100%");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Art der Auswertung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-6/24@desk l-6/24@desk gutter--small");
        dom.setAttribute(el3,"style","margin-left: -14px;");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","input-element input--full-width");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Lieferanten");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0]);
        var element5 = dom.childAt(element4, [9]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 1, 1]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element4, [3, 1, 1]),0,0);
        morphs[2] = dom.createMorphAt(dom.childAt(element4, [5, 1, 1]),0,0);
        morphs[3] = dom.createMorphAt(dom.childAt(element4, [7, 1, 1]),0,0);
        morphs[4] = dom.createMorphAt(dom.childAt(element5, [1, 1]),3,3);
        morphs[5] = dom.createMorphAt(element5,3,3);
        morphs[6] = dom.createMorphAt(dom.childAt(element5, [5, 1]),3,3);
        morphs[7] = dom.createMorphAt(element5,7,7);
        return morphs;
      },
      statements: [
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","totalRevenue",["loc",[null,[5,60],[5,72]]]]],[],[]]],["loc",[null,[5,36],[5,74]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","totalSaving",["loc",[null,[11,56],[11,67]]]]],[],[]]],["loc",[null,[11,32],[11,69]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","totalCashback",["loc",[null,[17,60],[17,73]]]]],[],[]]],["loc",[null,[17,36],[17,75]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","totalAdvantage",["loc",[null,[23,60],[23,74]]]]],[],[]]],["loc",[null,[23,36],[23,76]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","filterTypeList",["loc",[null,[33,20],[33,34]]]]],[],[]],"value",["subexpr","@mut",[["get","filterType",["loc",[null,[34,18],[34,28]]]]],[],[]],"optionLabelPath","value","optionValuePath","id","allowClear",false,"cssClass","input--dropdown"],["loc",[null,[32,10],[39,12]]]],
        ["block","if",[["subexpr","is-jason-network",[],[],["loc",[null,[43,12],[43,30]]]]],[],0,null,["loc",[null,[43,6],[58,13]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","suppliers",["loc",[null,[64,20],[64,29]]]]],[],[]],"value",["subexpr","@mut",[["get","filterSupplier",["loc",[null,[65,18],[65,32]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","allowClear",true,"placeholder","Alle"],["loc",[null,[63,10],[70,12]]]],
        ["block","if",[["get","showLoading",["loc",[null,[74,12],[74,23]]]]],[],1,2,["loc",[null,[74,6],[117,13]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});