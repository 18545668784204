define('jason-frontend/templates/components/button-delete-square', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-delete-square.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        dom.setAttribute(el1,"fill","none");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"clip-path","url(#clip0)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("rect");
        dom.setAttribute(el3,"width","32");
        dom.setAttribute(el3,"height","32");
        dom.setAttribute(el3,"fill","white");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("rect");
        dom.setAttribute(el3,"x","1");
        dom.setAttribute(el3,"y","1");
        dom.setAttribute(el3,"width","30");
        dom.setAttribute(el3,"height","30");
        dom.setAttribute(el3,"fill","#F16851");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"fill-rule","evenodd");
        dom.setAttribute(el3,"clip-rule","evenodd");
        dom.setAttribute(el3,"d","M17.2234 16.2132L22.529 10.9076C22.8072 10.6294 22.8056 10.1787 22.5267 9.89976C22.2458 9.61887 21.7965 9.61978 21.5188 9.89746L16.2132 15.2031L10.9076 9.89746C10.6293 9.6192 10.1787 9.62081 9.89975 9.89976C9.61886 10.1806 9.61978 10.6299 9.89746 10.9076L15.2031 16.2132L9.89746 21.5188C9.6192 21.7971 9.6208 22.2477 9.89975 22.5267C10.1806 22.8076 10.6299 22.8066 10.9076 22.529L16.2132 17.2234L21.5188 22.529C21.7971 22.8072 22.2477 22.8056 22.5267 22.5267C22.8075 22.2458 22.8066 21.7965 22.529 21.5188L17.2234 16.2132V16.2132Z");
        dom.setAttribute(el3,"fill","url(#paint0_linear)");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("defs");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("linearGradient");
        dom.setAttribute(el3,"id","paint0_linear");
        dom.setAttribute(el3,"x1","22.6843");
        dom.setAttribute(el3,"y1","3.56915");
        dom.setAttribute(el3,"x2","3.56914");
        dom.setAttribute(el3,"y2","9.7078");
        dom.setAttribute(el3,"gradientUnits","userSpaceOnUse");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4,"stop-color","white");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4,"offset","1");
        dom.setAttribute(el4,"stop-color","white");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("clipPath");
        dom.setAttribute(el3,"id","clip0");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("rect");
        dom.setAttribute(el4,"width","32");
        dom.setAttribute(el4,"height","32");
        dom.setAttribute(el4,"fill","white");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,14],[1,18]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,32],[1,36]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});