define('jason-frontend/routes/serviceunits/edit', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('serviceunit', params.id);
    },
    actions: {
      save: function save() {
        var self = this;
        self.controller.get('model').save().then(function () {
          self.transitionTo('basicinfos', { queryParams: { selectTab: "serviceunits" } });
        });
      }
    }
  });

});