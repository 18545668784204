define('jason-frontend/components/patient-weight-info-form', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      save: function save() {

        var container = $('#weight-form');

        var data = {
          weight: $('#weight').val().replace(',', '.'),
          notice: $('#notice').val(),
          patientId: container.attr('data-patient-id'),
          id: container.attr('data-id')
        };

        var dateInput = $('#dateInput').val();

        if (dateInput) {
          data.date = moment(dateInput, 'DD. MM. YYYY').toDate();
        } else {
          data.date = null;
        }

        this.sendAction('weightSaved', data);
        $.magnificPopup.close();
      }
    }
  });

});