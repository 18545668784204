define('jason-frontend/controllers/appointments/index', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Termine",
    breadCrumbPath: "appointments.index",
    treatmentSortDir: 'asc',
    pickDate: moment().format("DD. MM. YYYY") + " - " + moment().format("DD. MM. YYYY"),
    page: 1,
    queryParams: ["filterMe", "openNew"],
    showLoading: false,
    filtersShown: true,
    filterResource: null,
    fromNow: true,
    perPage: 20,
    showFilters: true,
    filterDoctor: null,
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    searchType: 'products',
    watchFuture: (function () {
      if (this.get('fromNow')) {
        this.set('filterAll', false);
      } else {
        this.set('filterAll', true);
      }
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('fromNow'),
    watchfilterAll: (function () {
      if (this.get('filterAll')) {
        this.set('fromNow', false);
      } else {
        this.set('fromNow', true);
      }
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterAll'),
    actions: {
      startTreatment: function startTreatment(customerId, patientId) {

        var patients = [patientId];

        this.get('startTreatmentPanel').send('load', customerId, patients);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-start-treatment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      toggleFilters: function toggleFilters(value) {
        this.set('filtersShown', value);
      },
      openNewAppointment: function openNewAppointment() {
        var self = this;
        this.get('addAppointmentPanel').send('load', null, this.get('patientAppointmentCategories'), true);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-appointment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openEditAppointment: function openEditAppointment(itemId) {
        var self = this;

        $.ajax({
          url: "/api/patientAppointments/" + itemId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('addAppointmentPanel').send('loadEdit', data.patientAppointment, self.store.find('patient-appointment-category'));
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-appointment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      showAppointment: function showAppointment(item) {
        this.get('showAppointmentPanel').send('load', item);
        var self = this;

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-show-appointment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    },
    watchDateChange: (function () {
      this.send('applyFilter');
    }).observes('pickDate'),
    watchDoctor: (function () {
      try {
        this.send('applyFilter');
      } catch (e) {}
    }).observes('filterDoctor'),
    watchAssistant: (function () {
      this.send('applyFilter');
    }).observes('filterAssistant'),
    watchCustomerSelection: (function () {
      var self = this;
      this.set('page', 1);

      if (this.get('filterCustomer')) {

        $.ajax({
          url: "/api/patients/forcustomer/" + this.get('filterCustomer').id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('patients', data.patient);
        });
      } else {
        this.set('filterPatient', null);
        this.set('patients', null);
      }
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterCustomer'),
    watchPatientSelection: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterPatient'),
    watchSearchType: (function () {
      var self = this;
      this.set('page', 1);

      this.set('filterServiceCategory', null);
      this.set('filterProduct', null);
      this.set('filterService', null);
      this.set('filterProductCategory', null);
      this.set('filterSupplier', null);
      this.set('filterPartnerCategory', null);

      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('searchType'),
    watchProduct: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterProduct'),
    watchService: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterService'),
    watchCategory: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterCategory'),
    watchRoom: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterRoom'),
    watchStation: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterStation'),
    watchResource: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterResource'),
    watchSupplier: (function () {
      var self = this;
      this.set('page', 1);

      if (this.get('filterSupplier') == null) {
        this.set('partnerCategories', null);
        this.set('filterPartnerCategory', null);
      } else {
        this.send('updatePartnerCategories');
      }

      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterSupplier'),
    watchPartnerCategory: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterPartnerCategory'),
    watchFromNow: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('fromNow')
  });

});