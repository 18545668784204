define('jason-frontend/templates/customers/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 2
            },
            "end": {
              "line": 72,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/customers/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","filter-box grid");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter searchInput");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","search-patient");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Tier");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","input search__button");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/search-icon-white.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter searchInput");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","filterChip");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Chip-Nr");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","input search__button");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/search-icon-white.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter searchInput");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","filterAddress");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Adresse");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","input search__button");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/search-icon-white.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter searchInput");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","filterPhone");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Telefon");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","input search__button");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/search-icon-white.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [5]);
          var element3 = dom.childAt(element0, [3]);
          var element4 = dom.childAt(element3, [5]);
          var element5 = dom.childAt(element0, [5]);
          var element6 = dom.childAt(element5, [5]);
          var element7 = dom.childAt(element0, [7]);
          var element8 = dom.childAt(element7, [5]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(element1,3,3);
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createMorphAt(element3,3,3);
          morphs[3] = dom.createElementMorph(element4);
          morphs[4] = dom.createMorphAt(element5,3,3);
          morphs[5] = dom.createElementMorph(element6);
          morphs[6] = dom.createMorphAt(element7,3,3);
          morphs[7] = dom.createElementMorph(element8);
          return morphs;
        },
        statements: [
          ["inline","input",[],["enter","triggerSearch","value",["subexpr","@mut",[["get","filterPatient",["loc",[null,[41,44],[41,57]]]]],[],[]],"class","gui-input newStyle input input--search search__input","name","search-patient","placeholder","Tiername","autocomplete","off"],["loc",[null,[41,8],[42,36]]]],
          ["element","action",["triggerSearch"],[],["loc",[null,[43,45],[43,71]]]],
          ["inline","input",[],["enter","triggerSearch","value",["subexpr","@mut",[["get","filterChip",["loc",[null,[49,44],[49,54]]]]],[],[]],"class","gui-input newStyle input input--search search__input","name","filterChip","placeholder","Chip-Nr / Heimtiernummer","autocomplete","off"],["loc",[null,[49,8],[50,36]]]],
          ["element","action",["triggerSearch"],[],["loc",[null,[51,45],[51,71]]]],
          ["inline","input",[],["enter","triggerSearch","value",["subexpr","@mut",[["get","filterAddress",["loc",[null,[57,44],[57,57]]]]],[],[]],"class","gui-input newStyle input input--search search__input","name","filterAddress","placeholder","Adresse, PLZ, Ort","autocomplete","off"],["loc",[null,[57,8],[58,36]]]],
          ["element","action",["triggerSearch"],[],["loc",[null,[59,45],[59,71]]]],
          ["inline","input",[],["enter","triggerSearch","value",["subexpr","@mut",[["get","filterPhone",["loc",[null,[65,44],[65,55]]]]],[],[]],"class","gui-input newStyle input input--search search__input","name","filterPhone","placeholder","Telefon, Mobil","autocomplete","off"],["loc",[null,[65,8],[66,36]]]],
          ["element","action",["triggerSearch"],[],["loc",[null,[67,45],[67,71]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 142,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/customers/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","dropdown");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","with-icon button context dropdown");
        dom.setAttribute(el4,"data-toggle","dropdown");
        dom.setAttribute(el4,"aria-haspopup","true");
        dom.setAttribute(el4,"aria-expanded","false");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Export");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","dropdown-menu");
        dom.setAttribute(el4,"aria-labelledby","dropdownMenuButton");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        dom.setAttribute(el5,"id","customersVcardExportForm");
        dom.setAttribute(el5,"method","post");
        dom.setAttribute(el5,"action","api/customers/exportVcard");
        dom.setAttribute(el5,"target","_blank");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("input");
        dom.setAttribute(el6,"type","hidden");
        dom.setAttribute(el6,"name","mandant_id");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("input");
        dom.setAttribute(el6,"type","hidden");
        dom.setAttribute(el6,"name","token");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"class","dropdown-item");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        var el8 = dom.createTextNode("vCard herunterladen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        dom.setAttribute(el5,"id","customersExportForm");
        dom.setAttribute(el5,"method","post");
        dom.setAttribute(el5,"action","api/customers/export");
        dom.setAttribute(el5,"target","_blank");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("input");
        dom.setAttribute(el6,"type","hidden");
        dom.setAttribute(el6,"name","mandant_id");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("input");
        dom.setAttribute(el6,"type","hidden");
        dom.setAttribute(el6,"name","token");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"class","dropdown-item");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        var el8 = dom.createTextNode("CSV herunterladen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","with-icon button context primary");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/add-entry.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Neuer Kunde");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","site-content card transaction-filter with-context-bar");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons ");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button icon-button--small");
        dom.setAttribute(el2,"onclick","history.back()");
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function () {\n    $(\"#addCustomerForm\").validate({\n      errorClass: \"state-error\",\n      validClass: \"state-success\",\n      errorElement: \"em\",\n      rules: {\n        'firstname': {\n          required: true\n        },\n        'lastname': {\n          required: true\n        },\n        'email': {\n          email: true\n        },\n        'phone': {\n          pattern: /^\\+?[\\s\\d]+$/\n        }\n      },\n      highlight: function (element, errorClass, validClass) {\n        $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n      },\n      unhighlight: function (element, errorClass, validClass) {\n        $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n      },\n      errorPlacement: function (error, element) {\n        if (element.is(\":radio\") || element.is(\":checkbox\")) {\n          element.closest('.option-group').after(error);\n        } else {\n          error.insertAfter(element.parent());\n        }\n      }\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0]);
        var element10 = dom.childAt(element9, [1]);
        var element11 = dom.childAt(element9, [3]);
        var element12 = dom.childAt(element11, [1]);
        var element13 = dom.childAt(element12, [3]);
        var element14 = dom.childAt(element13, [1]);
        var element15 = dom.childAt(element14, [1]);
        var element16 = dom.childAt(element14, [5]);
        var element17 = dom.childAt(element13, [3]);
        var element18 = dom.childAt(element17, [1]);
        var element19 = dom.childAt(element17, [5]);
        var element20 = dom.childAt(element11, [3]);
        var element21 = dom.childAt(fragment, [2]);
        var morphs = new Array(16);
        morphs[0] = dom.createAttrMorph(element9, 'class');
        morphs[1] = dom.createMorphAt(element10,1,1);
        morphs[2] = dom.createMorphAt(element10,3,3);
        morphs[3] = dom.createAttrMorph(element11, 'class');
        morphs[4] = dom.createMorphAt(dom.childAt(element12, [1]),1,1);
        morphs[5] = dom.createAttrMorph(element15, 'value');
        morphs[6] = dom.createElementMorph(element16);
        morphs[7] = dom.createAttrMorph(element18, 'value');
        morphs[8] = dom.createElementMorph(element19);
        morphs[9] = dom.createElementMorph(element20);
        morphs[10] = dom.createMorphAt(element21,1,1);
        morphs[11] = dom.createMorphAt(element21,3,3);
        morphs[12] = dom.createMorphAt(dom.childAt(fragment, [4, 1]),1,1);
        morphs[13] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[14] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[15] = dom.createMorphAt(fragment,10,10,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["context-bar grid ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,50],[1,63]]]],"sidebar",""],[],["loc",[null,[1,29],[1,78]]]]]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[3,28],[3,38]]]]],[],[]],"placeHolder","Kunde suchen","search","applyFilter"],["loc",[null,[3,4],[3,88]]]],
        ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[4,29],[4,40]]]]],[],[]]],["loc",[null,[4,4],[4,42]]]],
        ["attribute","class",["concat",["buttons-box col l-",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[6,53],[6,66]]]],"4","12"],[],["loc",[null,[6,32],[6,77]]]],"/24"]]],
        ["inline","button-download2",[],["showStroke",false,"color","#fff","size",16,"content",""],["loc",[null,[9,8],[9,77]]]],
        ["attribute","value",["concat",[["get","mandantId",["loc",[null,[13,58],[13,67]]]]]]],
        ["element","action",["checkForm","customersVcardExportForm"],[],["loc",[null,[15,40],[15,89]]]],
        ["attribute","value",["concat",[["get","mandantId",["loc",[null,[20,58],[20,67]]]]]]],
        ["element","action",["checkForm","customersExportForm"],[],["loc",[null,[22,40],[22,84]]]],
        ["element","action",["openCreateCustomer",["get","invoiceId",["loc",[null,[29,37],[29,46]]]]],[],["loc",[null,[29,7],[29,48]]]],
        ["block","if",[["get","showFilters",["loc",[null,[37,8],[37,19]]]]],[],0,null,["loc",[null,[37,2],[72,9]]]],
        ["inline","customer-searchform",[],["showSearch",false,"model",["subexpr","@mut",[["get","model",["loc",[null,[74,47],[74,52]]]]],[],[]],"filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[74,64],[74,74]]]]],[],[]],"reference","admission","content",["subexpr","@mut",[["get","content",["loc",[null,[74,105],[74,112]]]]],[],[]],"showCashSaleButton",false,"showStatus",true,"showCrud",true,"applyFilter","applyFilter","openScanEtigaQr","openScanEtigaQr","gotoPatient","gotoPatient","delete","delete","addPatient","addPatient","selectCustomer","selectCustomer"],["loc",[null,[74,2],[77,97]]]],
        ["inline","button-prev",[],["color","#ffffff","size","44"],["loc",[null,[81,70],[81,111]]]],
        ["inline","create-customer-panel",[],["gendersAll",["subexpr","@mut",[["get","gendersAll",["loc",[null,[84,35],[84,45]]]]],[],[]],"patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[84,64],[84,81]]]]],[],[]],"patientGenders",["subexpr","@mut",[["get","patientGenders",["loc",[null,[84,97],[84,111]]]]],[],[]],"toTreatment","toTreatment","gotoDetails","gotoDetails","gotoPatient","gotoPatient","toWaitgotoPatientingRoom","toWaitingRoom","create","create","refresh","applyFilter","actionReceiver",["subexpr","@mut",[["get","createCustomerPanel",["loc",[null,[91,39],[91,58]]]]],[],[]]],["loc",[null,[84,0],[91,60]]]],
        ["inline","create-patient-panel",[],["patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[93,41],[93,58]]]]],[],[]],"patientGenders",["subexpr","@mut",[["get","patientGenders",["loc",[null,[93,74],[93,88]]]]],[],[]],"createPatient","createPatient","actionReceiver",["subexpr","@mut",[["get","createPatientPanel",["loc",[null,[93,134],[93,152]]]]],[],[]]],["loc",[null,[93,0],[93,154]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[98,8],[98,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[99,10],[99,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[100,13],[100,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[101,17],[101,36]]]]],[],[]]],["loc",[null,[94,0],[101,38]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});