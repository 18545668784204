define('jason-frontend/helpers/if-is-nth-item', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.ifIsNthItem = ifIsNthItem;

  function ifIsNthItem(params) {
    var index = params[0],
        modulo = params[1];

    if (index % modulo === 0) {
      return true;
    } else {
      return false;
    }
  }

  exports['default'] = Ember['default'].Helper.helper(ifIsNthItem);

});