define('jason-frontend/controllers/purchases/cashback-thresholds', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Lieferanten",
    breadCrumbPath: "purchases.cashback-thresholds",
    showLoading: false
  });

});