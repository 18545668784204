define('jason-frontend/helpers/is-external-cashregister-enabled', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isExternalCashregisterEnabled = isExternalCashregisterEnabled;

  function isExternalCashregisterEnabled(params) {
    return API['default'].isExternalCashregister();
  }

  exports['default'] = Ember['default'].Helper.helper(isExternalCashregisterEnabled);

});