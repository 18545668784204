define('jason-frontend/helpers/current-user', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.currentUser = currentUser;

  function currentUser() {
    return API['default'].getUser();
  }

  exports['default'] = Ember['default'].Helper.helper(currentUser);

});