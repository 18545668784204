define('jason-frontend/components/mini-cart', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      toCart: function toCart(id) {
        this.sendAction('toCart', id);
      },
      toProduct: function toProduct(id, orderId) {
        this.sendAction('toProduct', id, orderId);
      },
      'delete': function _delete(id, orderId) {
        this.sendAction('delete', id, orderId);
      }
    }
  });

});