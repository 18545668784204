define('jason-frontend/components/treatment-history-report-chooser', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    allAvailable: null,
    tpId: null,
    prefix: "",
    actions: {
      edit: function edit(id) {
        this.sendAction('chooseReport', this.get('tpId'), id);
        $.magnificPopup.close();
      },
      create: function create() {
        $.magnificPopup.close();
        var self = this;
        setTimeout(function () {
          self.sendAction('openWizard', self.get('tpId'));
        }, 500);
      },
      load: function load(allAvailable, tpId) {
        this.set('allAvailable', allAvailable);
        this.set('tpId', tpId);
      },
      'delete': function _delete(id) {
        this.sendAction('delete', id, this.get('tpId'));
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});