define('jason-frontend/components/button-toggle-me', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'top',
    timer: 0,
    strokeWidth: 2,
    active: false,
    color: '#4A4A4A',
    content: '',
    theme: 'tooltipster-punk',
    actions: {
      toggle: function toggle() {
        if (this.get('active')) {
          this.sendAction('toggleAll', false);
        } else {
          this.sendAction('toggleAll', true);
        }
      }
    }
  });

});