define('jason-frontend/templates/partner', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 6
            },
            "end": {
              "line": 34,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/partner.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","global state-error");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["content","message",["loc",[null,[33,41],[33,52]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 120,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/partner.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n    html, body {\n      height: 100%;\n    }\n    html, body > .ember-view {\n      height: 100%;\n    }\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","content");
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        dom.setAttribute(el2,"class","card u-center login-form");
        dom.setAttribute(el2,"method","post");
        dom.setAttribute(el2,"action","/");
        dom.setAttribute(el2,"id","loginForm");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","login__head box");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/elovet-logo.png");
        dom.setAttribute(el4,"class","mb10");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        dom.setAttribute(el4,"style","font-size: 0.9em; color: #404040");
        var el5 = dom.createTextNode("Praxismanagement");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","mt20 section box");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","field u-mb0 moveable-input");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Email-Adresse");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","section box");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","field u-mb0 moveable-input");
        dom.setAttribute(el4,"id","password-field");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Passwort");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","box form-buttons");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","u-text--center col l-24/24");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"id","button-login");
        dom.setAttribute(el5,"type","submit");
        dom.setAttribute(el5,"class","button icon-button--success");
        var el6 = dom.createTextNode("Anmelden");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("br");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","u-text--center col l-24/24");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"id","resetPasswordLink");
        dom.setAttribute(el5,"class","is-highlighted");
        var el6 = dom.createTextNode("Passwort vergessen?");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"id","info");
        dom.setAttribute(el2,"class","credits no-box@phone");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"id","showCredits");
        dom.setAttribute(el2,"class","credits");
        dom.setAttribute(el2,"hidden","true");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"id","textCredits");
        var el4 = dom.createTextNode("here comes the text");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"id","credits");
        var el4 = dom.createTextNode("here come the credits");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"id","impressum");
        dom.setAttribute(el2,"class","impressum-hide");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","impressum-text");
        dom.setAttribute(el3,"href","https://www.vetnative.com");
        var el4 = dom.createTextNode("© 2024 VetNative Digital GmbH | ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","impressum-text");
        dom.setAttribute(el3,"href","https://www.vetnative.com/impressum/");
        var el4 = dom.createTextNode("Impressum");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function() {\n      $(\"#loginForm\").validate({\n        errorClass: \"state-error\",\n        validClass: \"state-success\",\n        errorElement: \"em\",\n        rules: {\n          'username': {\n            required: true,\n            email: true\n          },\n          'password': {\n            required: true,\n          }\n        },\n        highlight: function(element, errorClass, validClass) {\n          $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n        },\n        unhighlight: function(element, errorClass, validClass) {\n          $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n        },\n        errorPlacement: function(error, element) {\n          if (element.is(\":radio\") || element.is(\":checkbox\")) {\n            element.closest('.option-group').after(error);\n          } else {\n            error.insertAfter(element.parent());\n          }\n        }\n      });\n\n      $(\"#resetForm\").validate({\n        errorClass: \"state-error\",\n        validClass: \"state-success\",\n        errorElement: \"em\",\n        rules: {\n          'resetEmail': {\n            required: true,\n            email: true\n          }\n        },\n        highlight: function(element, errorClass, validClass) {\n          $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n        },\n        unhighlight: function(element, errorClass, validClass) {\n          $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n        },\n        errorPlacement: function(error, element) {\n          if (element.is(\":radio\") || element.is(\":checkbox\")) {\n            element.closest('.option-group').after(error);\n          } else {\n            error.insertAfter(element.parent());\n          }\n        }\n      });\n    });\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [3]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [9, 5, 1]);
        var element3 = dom.childAt(element0, [3]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element0, 'style');
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [3, 1]),1,1);
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [5, 1]),1,1);
        morphs[4] = dom.createMorphAt(element1,7,7);
        morphs[5] = dom.createElementMorph(element2);
        morphs[6] = dom.createElementMorph(element3);
        morphs[7] = dom.createElementMorph(element3);
        morphs[8] = dom.createMorphAt(fragment,5,5,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","style",["get","style",["loc",[null,[10,42],[10,47]]]]],
        ["element","action",["login"],["on","submit"],["loc",[null,[12,83],[12,113]]]],
        ["inline","input",[],["name","username","value",["subexpr","@mut",[["get","username",["loc",[null,[20,40],[20,48]]]]],[],[]],"class","input input--editable","placeholder"," ","id","username"],["loc",[null,[20,10],[20,110]]]],
        ["inline","input",[],["type","password","name","password","value",["subexpr","@mut",[["get","password",["loc",[null,[27,56],[27,64]]]]],[],[]],"class","input input--editable","id","password","placeholder"," "],["loc",[null,[27,10],[27,126]]]],
        ["block","if",[["get","message",["loc",[null,[32,12],[32,19]]]]],[],0,null,["loc",[null,[32,6],[34,13]]]],
        ["element","action",["openResetPasswordPanel"],[],["loc",[null,[42,36],[42,71]]]],
        ["element","action",["openInfo"],["on","mouseEnter"],["loc",[null,[48,9],[48,46]]]],
        ["element","action",["closeInfo"],["on","mouseLeave"],["loc",[null,[48,47],[48,85]]]],
        ["inline","reset-password-panel",[],["reset","reset"],["loc",[null,[62,2],[62,40]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});