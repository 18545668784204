define('jason-frontend/templates/patients/etiga', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 37
            },
            "end": {
              "line": 2,
              "column": 187
            }
          },
          "moduleName": "jason-frontend/templates/patients/etiga.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","animal-icon",[],["evil",["subexpr","@mut",[["get","model.evil",["loc",[null,[2,78],[2,88]]]]],[],[]],"animal-id",["subexpr","@mut",[["get","model.category.id",["loc",[null,[2,99],[2,116]]]]],[],[]],"race-id",["subexpr","@mut",[["get","model.digitalRace.icon",["loc",[null,[2,125],[2,147]]]]],[],[]],"size","50","content",["subexpr","@mut",[["get","model.category.name",["loc",[null,[2,166],[2,185]]]]],[],[]]],["loc",[null,[2,59],[2,187]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 46
            },
            "end": {
              "line": 6,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/etiga.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","ml20 mb20 vertical-middle label label-unselected");
          var el2 = dom.createTextNode("Nicht mit eltiga verknüpft");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 65,
              "column": 6
            },
            "end": {
              "line": 69,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/etiga.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["sendEtigaSave"],[],["loc",[null,[66,11],[66,37]]]],
          ["inline","button-save",[],["color","#ffffff","size","52","content","Speichern"],["loc",[null,[67,10],[67,71]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 79,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/patients/etiga.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","card site-content fadeIn");
        dom.setAttribute(el1,"id","etigaPage");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        dom.setAttribute(el2,"class","mb10 heading--inline");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2,"class","inline-block");
        var el3 = dom.createTextNode("\n      BesitzerIn: ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tabs__head");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"class","u-mb0 clearfix");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label inactive tabs__label title ");
        var el7 = dom.createTextNode("Stammdaten");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label inactive tabs__label title ");
        var el7 = dom.createTextNode("Zusatzinformationen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label inactive tabs__label title ");
        var el7 = dom.createTextNode("Gewicht");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label inactive tabs__label title ");
        var el7 = dom.createTextNode("Behandlungshistorie");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label inactive tabs__label title ");
        var el7 = dom.createTextNode("Labor");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label inactive tabs__label title ");
        var el7 = dom.createTextNode("Dokumente");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label inactive tabs__label title");
        var el7 = dom.createTextNode("Korrespondenz");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label inactive tabs__label title");
        var el7 = dom.createTextNode("Aufgaben");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label inactive tabs__label title");
        var el7 = dom.createTextNode("Termine");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title is-active permanently");
        var el7 = dom.createTextNode("eltiga");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","tabs__body mt20");
        var el4 = dom.createTextNode("\n              ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","action-icons");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","icon-button icon-button--small");
        dom.setAttribute(el3,"onclick","history.back()");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [10]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(element2,0,0);
        morphs[1] = dom.createMorphAt(element2,2,2);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [4]),1,1);
        morphs[3] = dom.createMorphAt(element1,6,6);
        morphs[4] = dom.createMorphAt(dom.childAt(element1, [8, 3]),1,1);
        morphs[5] = dom.createMorphAt(dom.childAt(element3, [1]),1,1);
        morphs[6] = dom.createMorphAt(element3,3,3);
        morphs[7] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[8] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[9] = dom.createMorphAt(fragment,6,6,contextualElement);
        return morphs;
      },
      statements: [
        ["block","if",[["get","model.category",["loc",[null,[2,43],[2,57]]]]],[],0,null,["loc",[null,[2,37],[2,194]]]],
        ["content","model.name",["loc",[null,[2,195],[2,209]]]],
        ["content","model.customerName",["loc",[null,[4,18],[4,40]]]],
        ["block","if",[["get","model.etigaMaster",["loc",[null,[4,52],[4,69]]]]],[],1,null,["loc",[null,[4,46],[6,13]]]],
        ["inline","customer-etiga",[],["patient",["subexpr","@mut",[["get","model",["loc",[null,[43,39],[43,44]]]]],[],[]],"etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[44,48],[44,64]]]]],[],[]],"activateEtiga","activateEtiga","uploadDeclaration","uploadDeclaration","removeShare","removeEtigaShare","hasEtigaPermissions",true,"downloadEtigaQr","downloadEtigaQr","selectDocs","selectDocs","submitCredentials","sendCredentials","savePermission","saveEtigaPermission","chooseEtigaPanel","chooseEtigaPanel","editEtigaPermissions","editEtigaPermissions","readOnly",true,"classNames","etigaPermissionPanel","actionReceiver",["subexpr","@mut",[["get","etigaPanel",["loc",[null,[57,46],[57,56]]]]],[],[]]],["loc",[null,[43,14],[57,58]]]],
        ["content","button-prev",["loc",[null,[63,10],[63,25]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[65,16],[65,25]]]],"etiga"],[],["loc",[null,[65,12],[65,34]]]]],[],2,null,["loc",[null,[65,6],[69,13]]]],
        ["inline","patient-select-barcode-panel",[],["download","downloadPatientBarcode","actionReceiver",["subexpr","@mut",[["get","patientSelectBarcodePanel",["loc",[null,[74,80],[74,105]]]]],[],[]]],["loc",[null,[74,0],[74,107]]]],
        ["inline","choose-etiga-panel",[],["select","selectPatientForEtigaActivation","actionReceiver",["subexpr","@mut",[["get","chooseEtigaPanel",["loc",[null,[76,77],[76,93]]]]],[],[]]],["loc",[null,[76,0],[76,95]]]],
        ["inline","choose-etiga-customer-panel",[],["select","selectCustomerForEtigaActivation","createCustomerForEtigaActivation","createCustomerForEtigaActivation","genders",["subexpr","@mut",[["get","genders",["loc",[null,[77,148],[77,155]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","chooseEtigaCustomerPanel",["loc",[null,[77,171],[77,195]]]]],[],[]]],["loc",[null,[77,0],[77,197]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});