define('jason-frontend/models/inventory-print', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    prodId: DS['default'].attr('number'),
    productId: DS['default'].attr('number'),
    productName: DS['default'].attr('string'),
    productCategory: DS['default'].attr('string'),
    unit: DS['default'].belongsTo('serviceunit'),
    packageQuantity: DS['default'].attr('number'),
    totalAmount: DS['default'].attr('number'),
    singlePrice: DS['default'].attr('number'),
    salePrice: DS['default'].attr('number'),
    totalPrice: DS['default'].attr('number'),
    totalPriceDetailed: DS['default'].attr('number'),
    salePriceDetailed: DS['default'].attr('number'),
    tax: DS['default'].attr('number'),
    subItems: DS['default'].attr(''),
    moreAvailable: DS['default'].attr('boolean'),
    detailledAmount: DS['default'].attr('number'),
    warehouseName: DS['default'].attr('string'),
    supplier: DS['default'].attr('string')
  });

});