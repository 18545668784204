define('jason-frontend/helpers/print-list-of-name', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.printListOfId = printListOfId;

  function printListOfId(params) {
    var list = params[0];
    var joiner = params[1] || '<br/>';
    var values = new Array();
    if (list) {
      $(list).each(function (idx, val) {
        values.push(val.name);
      });
    }
    return values.join(joiner);
  }

  exports['default'] = Ember['default'].Helper.helper(printListOfId);

});