define('jason-frontend/templates/login', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 37,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/login.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"style","margin: 100px auto");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.setNamespace("http://www.w3.org/2000/svg");
          var el2 = dom.createElement("svg");
          dom.setAttribute(el2,"width","38");
          dom.setAttribute(el2,"height","38");
          dom.setAttribute(el2,"viewBox","0 0 38 38");
          dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
          dom.setAttribute(el2,"fill","#404040");
          dom.setAttribute(el2,"class","loading-animation-new");
          dom.setAttribute(el2,"style","margin: auto; display:block");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("defs");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("linearGradient");
          dom.setAttribute(el4,"x1","8.042%");
          dom.setAttribute(el4,"y1","0%");
          dom.setAttribute(el4,"x2","65.682%");
          dom.setAttribute(el4,"y2","23.865%");
          dom.setAttribute(el4,"id","a");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("stop");
          dom.setAttribute(el5,"stop-color","#404040");
          dom.setAttribute(el5,"stop-opacity","0");
          dom.setAttribute(el5,"offset","0%");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("stop");
          dom.setAttribute(el5,"stop-color","#404040");
          dom.setAttribute(el5,"stop-opacity",".631");
          dom.setAttribute(el5,"offset","63.146%");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("stop");
          dom.setAttribute(el5,"stop-color","#404040");
          dom.setAttribute(el5,"offset","100%");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("g");
          dom.setAttribute(el3,"fill","none");
          dom.setAttribute(el3,"fill-rule","evenodd");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("g");
          dom.setAttribute(el4,"transform","translate(1 1)");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("path");
          dom.setAttribute(el5,"d","M36 18c0-9.94-8.06-18-18-18");
          dom.setAttribute(el5,"id","Oval-2");
          dom.setAttribute(el5,"stroke","url(#a)");
          dom.setAttribute(el5,"stroke-width","2");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("animateTransform");
          dom.setAttribute(el6,"attributeName","transform");
          dom.setAttribute(el6,"type","rotate");
          dom.setAttribute(el6,"from","0 18 18");
          dom.setAttribute(el6,"to","360 18 18");
          dom.setAttribute(el6,"dur","0.9s");
          dom.setAttribute(el6,"repeatCount","indefinite");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("circle");
          dom.setAttribute(el5,"fill","#404040");
          dom.setAttribute(el5,"cx","36");
          dom.setAttribute(el5,"cy","18");
          dom.setAttribute(el5,"r","1");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("animateTransform");
          dom.setAttribute(el6,"attributeName","transform");
          dom.setAttribute(el6,"type","rotate");
          dom.setAttribute(el6,"from","0 18 18");
          dom.setAttribute(el6,"to","360 18 18");
          dom.setAttribute(el6,"dur","0.9s");
          dom.setAttribute(el6,"repeatCount","indefinite");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.setNamespace(null);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2,"class","text-center mt10");
          var el3 = dom.createTextNode("Bitte warten...");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 6
              },
              "end": {
                "line": 71,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/login.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","global state-error");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","message",["loc",[null,[70,41],[70,52]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 0
            },
            "end": {
              "line": 157,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/login.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("style");
          var el2 = dom.createTextNode("\n    html, body {\n      height: 100%;\n    }\n    html, body > .ember-view {\n      height: 100%;\n    }\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          dom.setAttribute(el1,"id","content");
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("form");
          dom.setAttribute(el2,"class","card u-center login-form");
          dom.setAttribute(el2,"method","post");
          dom.setAttribute(el2,"action","/");
          dom.setAttribute(el2,"id","loginForm");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","login__head box");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/elovet-logo.png");
          dom.setAttribute(el4,"class","mb10");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","title");
          dom.setAttribute(el4,"style","font-size: 0.9em; color: #404040");
          var el5 = dom.createTextNode("Praxismanagement");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","mt20 section box");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field u-mb0 moveable-input");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Email-Adresse");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","section box");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field u-mb0 moveable-input");
          dom.setAttribute(el4,"id","password-field");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Passwort");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","box form-buttons");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","u-text--center col l-24/24");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("button");
          dom.setAttribute(el5,"id","button-login");
          dom.setAttribute(el5,"type","submit");
          dom.setAttribute(el5,"class","button icon-button--success");
          var el6 = dom.createTextNode("Anmelden");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("br");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","u-text--center col l-24/24");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("a");
          dom.setAttribute(el5,"id","resetPasswordLink");
          dom.setAttribute(el5,"class","is-highlighted");
          var el6 = dom.createTextNode("Passwort vergessen?");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","info");
          dom.setAttribute(el2,"class","credits no-box@phone");
          var el3 = dom.createTextNode("\n\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","showCredits");
          dom.setAttribute(el2,"class","credits");
          dom.setAttribute(el2,"hidden","true");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"id","textCredits");
          var el4 = dom.createTextNode("here comes the text");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"id","credits");
          var el4 = dom.createTextNode("here come the credits");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","impressum");
          dom.setAttribute(el2,"class","impressum-hide");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","impressum-text");
          dom.setAttribute(el3,"href","https://www.vetnative.com");
          var el4 = dom.createTextNode("© 2024 VetNative Digital GmbH | ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","impressum-text");
          dom.setAttribute(el3,"href","https://www.vetnative.com/impressum/");
          var el4 = dom.createTextNode("Impressum");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("script");
          var el2 = dom.createTextNode("\n    $(function() {\n      $(\"#loginForm\").validate({\n        errorClass: \"state-error\",\n        validClass: \"state-success\",\n        errorElement: \"em\",\n        rules: {\n          'username': {\n            required: true,\n            email: true\n          },\n          'password': {\n            required: true,\n          }\n        },\n        highlight: function(element, errorClass, validClass) {\n          $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n        },\n        unhighlight: function(element, errorClass, validClass) {\n          $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n        },\n        errorPlacement: function(error, element) {\n          if (element.is(\":radio\") || element.is(\":checkbox\")) {\n            element.closest('.option-group').after(error);\n          } else {\n            error.insertAfter(element.parent());\n          }\n        }\n      });\n\n      $(\"#resetForm\").validate({\n        errorClass: \"state-error\",\n        validClass: \"state-success\",\n        errorElement: \"em\",\n        rules: {\n          'resetEmail': {\n            required: true,\n            email: true\n          }\n        },\n        highlight: function(element, errorClass, validClass) {\n          $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n        },\n        unhighlight: function(element, errorClass, validClass) {\n          $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n        },\n        errorPlacement: function(error, element) {\n          if (element.is(\":radio\") || element.is(\":checkbox\")) {\n            element.closest('.option-group').after(error);\n          } else {\n            error.insertAfter(element.parent());\n          }\n        }\n      });\n    });\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [9, 5, 1]);
          var element3 = dom.childAt(element0, [3]);
          var morphs = new Array(9);
          morphs[0] = dom.createAttrMorph(element0, 'style');
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [3, 1]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element1, [5, 1]),1,1);
          morphs[4] = dom.createMorphAt(element1,7,7);
          morphs[5] = dom.createElementMorph(element2);
          morphs[6] = dom.createElementMorph(element3);
          morphs[7] = dom.createElementMorph(element3);
          morphs[8] = dom.createMorphAt(fragment,5,5,contextualElement);
          return morphs;
        },
        statements: [
          ["attribute","style",["get","style",["loc",[null,[47,42],[47,47]]]]],
          ["element","action",["login"],["on","submit"],["loc",[null,[49,83],[49,113]]]],
          ["inline","input",[],["name","username","value",["subexpr","@mut",[["get","username",["loc",[null,[57,40],[57,48]]]]],[],[]],"class","input input--editable","placeholder"," ","id","username"],["loc",[null,[57,10],[57,110]]]],
          ["inline","input",[],["type","password","name","password","value",["subexpr","@mut",[["get","password",["loc",[null,[64,56],[64,64]]]]],[],[]],"class","input input--editable","id","password","placeholder"," "],["loc",[null,[64,10],[64,126]]]],
          ["block","if",[["get","message",["loc",[null,[69,12],[69,19]]]]],[],0,null,["loc",[null,[69,6],[71,13]]]],
          ["element","action",["openResetPasswordPanel"],[],["loc",[null,[79,36],[79,71]]]],
          ["element","action",["openInfo"],["on","mouseEnter"],["loc",[null,[85,9],[85,46]]]],
          ["element","action",["closeInfo"],["on","mouseLeave"],["loc",[null,[85,47],[85,85]]]],
          ["inline","reset-password-panel",[],["reset","reset"],["loc",[null,[99,2],[99,40]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 158,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/login.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","if",[["get","idpEnabled",["loc",[null,[1,6],[1,16]]]]],[],0,1,["loc",[null,[1,0],[157,7]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});