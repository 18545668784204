define('jason-frontend/components/add-etiga-share', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    permissionTypesList: [{ value: "Nicht freigegeben", id: "none" }, { value: "Freigegeben", id: "write" }],
    actions: {
      select: function select(id) {
        this.sendAction('select', id);
        $.magnificPopup.close();
      },
      remove: function remove(id) {
        this.sendAction('remove', id);
        $.magnificPopup.close();
      }
    },
    valueObserver: (function () {}).observes('mandants.@each.shareStatus')
  });

});