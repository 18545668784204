define('jason-frontend/controllers/users/permission', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Berechtigungen",
    breadCrumbPath: "users.permission",
    actions: {
      save: function save(id) {

        var data = new Object(),
            self = this,
            permissions = new Array();

        $('form#permissionEdit input[type=checkbox]:checked').each(function () {
          permissions.push($(this).attr('id'));
        });

        data.permissions = permissions;
        data.userId = id;

        $.ajax({
          url: "/api/users/changePermission/" + id,
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Berechtigungen geändert', '');
          self.transitionTo('users.index');
        }, function (error) {
          jason.notifiction.notifiy('Berechtigungen geändert', '');
          self.transitionTo('users.index');
        });
      }
    }
  });

});