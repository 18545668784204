define('jason-frontend/models/article-price', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    price: DS['default'].attr('number'),
    purchasePrice: DS['default'].attr('number'),
    priceAddition: DS['default'].attr('number'),
    priceAdditional: DS['default'].attr('number'),
    jasonRecommendedPrice: DS['default'].attr('number'),
    jasonRecommendedAddition: DS['default'].attr('number'),
    jasonPrice: DS['default'].attr('boolean'),
    outdated: DS['default'].attr(''),
    jasonPriceAuto: DS['default'].attr('boolean'),
    quantity: DS['default'].attr('number'),
    quantitySelection: DS['default'].attr(),
    equivalentAmount: DS['default'].attr('number'),
    equivalentDivident: DS['default'].attr('number'),
    editable: DS['default'].attr('boolean'),
    applied: DS['default'].attr('boolean'),
    jason: DS['default'].attr('boolean'),
    tgd: DS['default'].attr('boolean'),
    favorite: DS['default'].attr('boolean'),
    unit: DS['default'].belongsTo('serviceunit')
  });

});