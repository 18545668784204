define('jason-frontend/routes/servicecategories/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('serviceCategory', params.id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      $.ajax({
        url: "/api/serviceCategories?onlyParent=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('parentCategories', data.serviceCategory);
      });
    },
    actions: {
      save: function save(id) {
        var self = this;

        var controller = this.get('controller'),
            model = controller.get('model'),
            category = new Object();

        category.name = model.get('name');
        category.id = id;

        if (model.parentCategory) {
          category.parentCategory = {
            id: model.parentCategory.id
          };
        }
        $.ajax({
          url: "/api/serviceCategories/" + id,
          method: "PUT",
          data: JSON.stringify(category),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('basicinfos', { queryParams: { selectTab: "servicecategories" } });
          controller.set('name', '');
        }, function (data) {
          self.transitionTo('basicinfos', { queryParams: { selectTab: "servicecategories" } });
          controller.set('name', '');
        });
      }
    }
  });

});