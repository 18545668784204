define('jason-frontend/components/mandant-fee-panel', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    toggleVisible: false,
    months: null,
    type: null,
    years: [{ name: "2016", id: "2016" }, { name: "2017", id: "2017" }, { name: "2018", id: "2018" }, { name: "2019", id: "2019" }, { name: "2020", id: "2020" }, { name: "2021", id: "2021" }, { name: "2022", id: "2022" }, { name: "2023", id: "2023" }],
    actions: {
      confirm: function confirm() {

        var form = $('#mandantFeeForm'),
            id = form.attr('data-fee-id'),
            type = form.attr('data-type-id');

        var value = form.find('input[name=fee-value]').val();
        value = value.replace(',', '.');

        if (isNaN(value)) {
          //|| value < 0
          jason.notifiction.systemError('Fehler', 'Bitte einen Betrag eingeben');
          return;
        }

        var fee = {
          id: id,
          month: form.find('#fee-month').val(),
          mandantId: form.data('mandant-id'),
          year: form.find('#fee-year').val(),
          type: type,
          value: value,
          comment: form.find('input[name=fee-comment]').val(),
          quantity: form.find('input[name=quantity]').val(),
          subType: form.find('input[name=subType]').val()
        };

        if (id <= 0) {
          if (!fee.year) {
            jason.notifiction.systemError('Fehler', 'Bitte wählen Sie ein Jahr aus');
            return;
          }
          if (!fee.month) {
            jason.notifiction.systemError('Fehler', 'Bitte wählen Sie ein Monat aus');
            return;
          }
        }

        this.sendAction('confirm', fee);

        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    },
    becomeFocused: (function () {

      var self = this,
          form = $('#mandantFeeForm'),
          id = form.attr('data-mandant-id');

      $.ajax({
        url: "/api/months",
        method: "GET",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('months', data);
      });
    }).on('didInsertElement')
  });

});