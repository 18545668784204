define('jason-frontend/components/invoice-add-composite', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    // tagName: 'tr',
    classNames: ['table__row'],
    actions: {
      toggleFavorite: function toggleFavorite(product, add) {
        Ember['default'].set(product, 'favorite', add);
        this.sendAction('toggleCompositeFavorite', product.id, add);
      },
      addProduct: function addProduct(model, invoiceId, priceId, productName) {
        var qty = this.$().find("input.spinner-" + model.id + "-" + priceId).val();

        if (!qty) {
          qty = this.$().find("span.spinn_" + model.id + " > input").val();
        }

        qty = String(qty).replace(',', '.');

        this.sendAction('addProduct', qty, model.id, invoiceId, priceId, productName);
      }
    }
  });

});