define('jason-frontend/templates/components/button-download2', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-download2.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","scale(2.5,2.5) translate(0,0)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M8.70845377,5.88 C8.47797709,5.65844366 8.10574863,5.65844366 7.87527195,5.88 L6.51618104,7.16571429 L6.51618104,0.571428571 C6.51618104,0.255837286 6.25162203,1.93243929e-17 5.92527195,0 C5.59892187,-1.93243929e-17 5.33436286,0.255837286 5.33436286,0.571428571 L5.33436286,7.22285714 L3.97527195,5.88571429 C3.74082269,5.69155612 3.39133903,5.70461013 3.1730755,5.91567816 C2.95481197,6.12674619 2.94131293,6.46470841 3.14209013,6.69142857 L5.5057265,8.97714286 C5.61724217,9.08410658 5.76820655,9.14373529 5.92527195,9.14285714 L5.9548174,9.14285714 C6.14828012,9.13810803 6.32707732,9.04205093 6.43345377,8.88571429 L8.70254468,6.69142857 C8.81519622,6.5848971 8.87918572,6.43927014 8.8802953,6.28690466 C8.88140487,6.13453918 8.81954263,5.98805517 8.70845377,5.88 Z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("rect");
        dom.setAttribute(el3,"width","12");
        dom.setAttribute(el3,"height","1.143");
        dom.setAttribute(el3,"y","11");
        dom.setAttribute(el3,"transform","rotate(180 6 11.571)");
        dom.setAttribute(el3,"rx",".571");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element1, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[2,13],[2,18]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});