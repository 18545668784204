define('jason-frontend/routes/mandants/create', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('genders', this.store.find('gender'));
      controller.set('titles', this.store.find('title'));

      controller.set('showSubmit', true);
    }
  });

});