define('jason-frontend/routes/users/permission', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model, params) {
      var self = this;

      var userId = params.params['users.permission'].id;

      controller.set('userId', userId);
      $.ajax({
        url: "/api/users/permissions/" + userId,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set("given", data);
        controller.set('groups', self.store.find('permissionGroup'));
        controller.set('user', self.store.find('user', userId));
      }, function (error) {});
    }
  });

});