define('jason-frontend/components/button-unhide', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'top',
    timer: 0,
    showStroke: true,
    content: 'einblenden',
    theme: 'tooltipster-punk'
  });

});