define('jason-frontend/controllers/invoices/banktransfers', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Überweisungen",
    breadCrumbPath: "invoices.banktransfers",
    queryParams: ["page", "perPage"],
    filterStatus: "",
    submitButtonEnabled: true,
    filterCode: "",
    myStickyOptionsTableHead: {
      topSpacing: 160,
      zIndex: 9
    },
    showFilters: true,
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),
    filterPaymentStatus: "",
    filterCustomer: null,
    sortBy: 'created',
    sortDir: 'desc',

    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    perPage: 20,

    watchDate: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('pickDate')
  });

});