define('jason-frontend/routes/purchases/cashback-thresholds-details', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {

      this._super(controller, model);

      var supId = params.params['purchases.cashback-thresholds-details'].id;

      controller.set('supplier', this.store.fetch('product-supplier', supId));
      controller.set('supplierId', supId);

      $.ajax({
        url: "/api/years",
        method: "GET",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('years', data);
      });
      $.ajax({
        url: "/api/months",
        method: "GET",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('months', data);
      });

      this.applyFilter(supId);
    },
    applyFilterInvoices: function applyFilterInvoices(id) {
      var controller = this.get('controller');
      Ember['default'].$.ajax({
        url: "/api/productSuppliers/invoices/" + id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set("invoices", data.mandantInvoice);
      });
    },
    applyFilter: function applyFilter(supId) {
      var controller = this.get('controller');
      $.ajax({
        url: "/api/productSupplierCashbackThresholds/all/" + supId,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('items', data.productSupplierCashbackThreshold);
      });
    },
    actions: {
      saveInvoiceSettings: function saveInvoiceSettings() {
        var self = this,
            controller = this.get('controller');

        var data = {
          autosendInvoice: controller.get('supplier').get('autosendInvoice'),
          availableTopPromotions: controller.get('supplier').get('availableTopPromotions'),
          availablePromotions: controller.get('supplier').get('availablePromotions')
        };

        var deferred = $.ajax({
          url: "/api/productSuppliers/" + controller.get('supplier').get('id') + "/saveInvoiceSettings",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        }, function (error) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        });
      },
      downloadInvoice: function downloadInvoice(mandantId, month, year) {
        var deferred = $.ajax({
          url: "/api/productSuppliers/invoices/" + mandantId + "/" + month + "/" + year,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/productSuppliers/invoices/show/' + mandantId + "/" + month + "/" + year);
        });
      },
      sendInvoice: function sendInvoice(mandantId, month, year) {
        var self = this,
            controller = this.get('controller');

        controller.set('showLoading', true);
        var deferred = $.ajax({
          url: "/api/productSuppliers/invoices/email/" + mandantId + "/" + month + "/" + year,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Email', 'erfolgreich verschickt');
          self.applyFilterInvoices(controller.get('supplierId'));
          controller.set('showLoading', false);
        }, function (data) {
          jason.notifiction.notifiy('Email', 'erfolgreich verschickt');
          self.applyFilterInvoices(controller.get('supplierId'));
          controller.set('showLoading', false);
        });
      },
      selectTab: function selectTab(selected) {
        var controller = this.get('controller');
        controller.set('selectTab', selected);

        if (selected === 'cashback') {
          this.applyFilter(controller.get('supplierId'));
        } else if (selected === 'invoices') {
          this.applyFilterInvoices(controller.get('supplierId'));
        }
      },
      saveThreshold: function saveThreshold(data) {

        var controller = this.get('controller');

        data.supplier = {
          id: controller.get('supplierId')
        };

        var self = this;
        $.ajax({
          url: "/api/productSupplierCashbackThresholds",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (responseData) {
          self.applyFilter(controller.get('supplierId'));
        });
      },
      applyFilter: function applyFilter(id) {
        this.applyFilter(id);
      },
      'delete': function _delete(id) {

        var controller = this.get('controller');

        var self = this;
        $.ajax({
          url: "/api/productSupplierCashbackThresholds/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.applyFilter(controller.get('supplierId'));
        });
      }
    }
  });

});