define('jason-frontend/helpers/get-country-name', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getCountryName = getCountryName;

  function getCountryName(params) {
    var id = params[0];

    if (id === 'AT') {
      return "Österreich";
    }
    if (id === 'DE') {
      return "Deutschland";
    }
    if (id === 'CH') {
      return "Schweiz";
    }

    return '';
  }

  exports['default'] = Ember['default'].Helper.helper(getCountryName);

});