define('jason-frontend/components/upgrade-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    prefix: null,
    active: true,
    actions: {
      load: function load() {
        this.set('active', true);
      },
      upgrade: function upgrade(type) {
        this.set('active', false);
        var self = this;
        $.ajax({
          url: "/api/mandants/upgrade/" + type,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Benutzer-Paket', 'erfolgreich hinzugefügt');
          self.sendAction('refresh');
          self.set('active', true);
          $.magnificPopup.close();
        }, function (error) {
          jason.notifiction.notifiy('Benutzer-Paket', 'erfolgreich hinzugefügt');
          self.sendAction('refresh');
          self.set('active', true);
          $.magnificPopup.close();
        });
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});