define('jason-frontend/helpers/has-permission', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.hasPermission = hasPermission;

  function hasPermission(params) {
    return API['default'].hasPermission(params[0]);
  }

  exports['default'] = Ember['default'].Helper.helper(hasPermission);

});