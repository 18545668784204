define('jason-frontend/routes/products/create', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      var product = this.store.createRecord('product');
      product.editable = true;
      return product;
    },
    setupController: function setupController(controller, model) {

      $.ajax({
        url: "/api/productCategories?suggest=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('productCategories', data.productCategory);
        model.set('category', data.productCategory[0]);
      });

      controller.set('labProfiles', this.store.find('labProfile'));

      this._super(controller, model);
      controller.set('taxes', this.store.find('tax', { paged: false, onlyValid: true }));

      this.store.find('serviceunit', { paged: false }).then(function (items) {
        controller.set('allUnits', items.sortBy('name'));
      });

      this.store.find('productCategory').then(function (items) {
        controller.set('categories', items.sortBy('fullName'));
      });

      this.store.find('productManufacturer').then(function (items) {
        controller.set('manufacturers', items.sortBy('name'));
      });

      this.store.find('productDistributor').then(function (items) {
        controller.set('distributors', items.sortBy('name'));
      });

      controller.set('patientCategories', this.store.find('patientCategory'));
    }
  });

});