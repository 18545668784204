define('jason-frontend/helpers/get-days-between', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getDaysBetween = getDaysBetween;

  function getDaysBetween(params /*, hash*/) {
    var from = params[0],
        to = params[1];

    if (!to) {
      to = new Date();
    }

    var difference = moment(from).diff(moment(to), "days");

    if (difference < 0) {
      difference = difference * -1;
    }

    return difference;
  }

  exports['default'] = Ember['default'].Helper.helper(getDaysBetween);

});