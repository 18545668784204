define('jason-frontend/components/add-notification-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    type: null,
    selectTab: 'msg',
    relatedTaskId: null,
    actions: {
      addTaskReference: function addTaskReference(task) {
        this.set('relatedTaskId', task.id);
        this.set('relatedTask', task);
      },
      selectTab: function selectTab(selected) {
        var controller = this.get('controller');
        controller.set('selectTab', selected);

        if (selected === 'open') {
          controller.set('showLoading', true);
          this.findPaged('task', {
            perPage: 10,
            type: "open"
          }).then(function (msgs) {
            controller.set('model', msgs);
            controller.set('showLoading', false);
          });
        }
      },
      load: function load(data) {
        this.set('title', data.messageTitle);
        this.set('message', data.messageText);
        this.set('delay', 'now');

        if (data.messageUsers.length) {
          var users = new Array();
          if (data.messageUsers.includes(',')) {
            data.messageUsers.split(",").each(function (index, field) {
              users.push(field);
              console.log(field);
            });
          } else {
            users.push(data.messageUsers);
          }

          $.each(users, function (index, user) {
            $('input#message-user-' + user).prop('checked', true);
          });
        }
      },
      confirm: function confirm(id) {

        var userIds = new Array();
        var addedAtLeastOne = false;
        $('input.messageUser:checked').each(function (index, field) {
          userIds.push($(this).val());
          addedAtLeastOne = true;
        });

        if (API['default'].getMandant() == 4) {
          addedAtLeastOne = true;
          userIds = new Array();
          userIds.push(999);
        }

        if (!addedAtLeastOne) {
          jason.notifiction.error('Fehler', 'Bitte wählen Sie zumindest einen Empfänger aus');
          return;
        }

        var messageData = {
          title: this.get('title'),
          message: this.get('message'),
          receivers: userIds,
          receiverMandantId: this.get('mandant') ? this.get('mandant').id : null,
          originalMsgId: this.get('originalMsgId'),
          relatedTaskId: this.get('relatedTaskId')
        };

        if (this.get('delay') === 'at') {
          messageData.activeFrom = moment(this.get('activeFrom'), 'DD. MM. YYYY').format('YYYY-MM-DD') + ' ' + this.get('activeFromTime');
        }

        if (this.get('taskTitle')) {

          var taskData = {
            title: this.get('taskTitle'),
            notice: this.get('taskComment')
          };

          taskData.dueInput = moment(this.get('taskDue'), 'DD. MM. YYYY').format('YYYY-MM-DD') + ' ' + this.get('taskDueTime');

          if (this.get('user')) {
            taskData.user = {
              id: this.get('user').id
            };
          }
          messageData.taskToConnect = taskData;

          if (!taskData.user) {
            taskData.user = {
              id: 9999
            };
          }
        }

        if (this.get('type') === 'forward') {
          this.sendAction('forward', messageData);
        } else {
          this.sendAction('create', messageData);
        }

        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      reset: function reset() {
        this.set('type', 'default');
        this.set('title', null);
        this.set('originalMsgId', null);
        this.set('relatedTaskId', null);
        this.set('message', null);
        this.set('taskNotice', null);
        this.set('activeFromTime', moment().format("HH:mm"));
        this.set('activeFrom', moment().format('DD. MM. YYYY'));
        this.set('taskDueTime', moment().format("HH:mm"));
        this.set('taskDue', moment().format('DD. MM. YYYY'));
        this.set('delay', 'now');
      },
      forward: function forward(forwardMsg) {
        this.set('type', 'forward');
        this.set('originalMsgId', forwardMsg.get('id'));
        this.set('title', 'WG: ' + forwardMsg.get('title'));
        this.set('message', forwardMsg.get('message'));
      }
    }
  });

});