define('jason-frontend/components/button-cancel', ['exports', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'top',
    timer: 0,
    color: '#fff',
    content: 'Abbrechen',
    theme: 'tooltipster-punk'
  });

});