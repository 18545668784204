define('jason-frontend/models/inventory-stocktaking', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    date: DS['default'].attr('string'),
    notice: DS['default'].attr('string'),
    time: DS['default'].attr('string'),
    locked: DS['default'].attr('boolean'),
    user: DS['default'].belongsTo('user'),
    entrySize: DS['default'].attr('number')
  });

});