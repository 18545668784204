define('jason-frontend/routes/purchases/show', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {

      var id = params.params['purchases.show'].id;

      this._super(controller, model);

      $.ajax({
        url: "/api/purchaseOrders/" + id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('model', data.purchaseOrder);
      }, function (data) {
        controller.set('model', data);
      });
    },
    actions: {
      toggleSidebar: function toggleSidebar() {
        var controller = this.get('controller');
        if (controller.get('sidebarActive') === true) {
          controller.set('sidebarActive', false);
        } else {
          controller.set('sidebarActive', true);
          setTimeout(function () {
            $('aside.side-bar .scrollable').css('max-height', $(window).height() - 100);
            $('aside.side-bar').css('min-height', $(window).height() - 100);
          }, 100);

          if ($(window).width() <= 767) {
            $("html, body").animate({ scrollTop: 0 }, 200);
          }
        }
      },
      downloadBarcode: function downloadBarcode(formId) {
        window.open('/api/treatmentPatients/downloadBarcode/' + formId + '/hor?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      downloadPdf: function downloadPdf(id) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/treatmentPatients/downloadLaboklinPdf/' + API['default'].getMandant() + '/' + data.responseText);
        });
      },
      downloadMedia: function downloadMedia(id, media) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/treatmentPatients/downloadLaboklin/' + API['default'].getMandant() + '/' + media + '/' + data.responseText);
        });
      },
      download: function download(orderId) {
        var deferred = $.ajax({
          url: "/api/purchaseOrders/" + orderId + "/preview",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/purchaseOrders/show/' + orderId);
        });
      }
    }
  });

});