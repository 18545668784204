define('jason-frontend/models/todo', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    comment: DS['default'].attr('string'),
    dueDate: DS['default'].attr('date'),
    dueDateString: DS['default'].attr(''),
    dueDateRaw: DS['default'].attr(''),
    dueDateTime: DS['default'].attr(''),
    checkedDate: DS['default'].attr('date')
  });

});