define('jason-frontend/models/product-category', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    hierarchy: DS['default'].attr('string'),
    fullName: DS['default'].attr('string'),
    editable: DS['default'].attr('boolean'),
    parentCategory: DS['default'].belongsTo('product-category', { inverse: 'children' }),
    children: DS['default'].hasMany('product-category', { inverse: 'parentCategory' })
  });

});