define('jason-frontend/components/select-treatment-room', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    room: null,
    roomObserve: (function () {
      this.sendAction('changed');
    }).observes('room')
  });

});