define('jason-frontend/components/sloping-select-barcode-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    openPrint: function openPrint(entryId, format) {

      if (!this.get('referer')) {
        this.set('referer', 'treatmentOffers');
      }

      var viewUrl = '/api/' + this.get('referer') + '/downloadSloping/' + entryId + '/' + format + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken();

      var myWindow = window.open(viewUrl, 'Drucken', 'width=600,height=600');

      var is_chrome = Boolean(window.chrome);
      if (is_chrome) {
        myWindow.onload = function () {

          myWindow.moveTo(0, 0);
          myWindow.resizeTo(640, 480);
          myWindow.print();
        };
      } else {
        myWindow.print();
        myWindow.close();
      }
    },
    actions: {
      download: function download() {
        this.openPrint(this.get('id'), 'default'); //_default _dosage//without dosage _defauilt
      },
      downloadWithImage: function downloadWithImage() {
        this.openPrint(this.get('id'), '_logo'); //_dosage //without dosage
      },
      load: function load(id, referer) {
        this.set('id', id);
        this.set('referer', referer);
      }
    }
  });

});