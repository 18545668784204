define('jason-frontend/routes/purchases/choose', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    perPage: 20,
    queryParams: {},
    setupController: function setupController(controller, model) {

      if (!API['default'].hasPermission('crud_purchases')) {
        jason.notifiction.error('Keine Berechtigung', '');
        this.transitionTo('home');
      }

      controller.set("model", model);

      Ember['default'].$.ajax({
        url: "/api/purchaseOrders/open",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set("open", data.purchaseOrder);
      });

      this.applyFilter();
    },
    actions: {
      selectTab: function selectTab(selected) {
        var self = this;
        var controller = this.get('controller');
        controller.set('selectTab', selected);

        if (selected === 'current') {
          setTimeout(function () {
            self.applyFilter();
          }, 500);
        }
      },
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      },
      addOrder: function addOrder() {
        var order = new Object(),
            self = this;

        $.ajax({
          url: "/api/purchaseOrders",
          method: "POST",
          data: JSON.stringify(order),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (orderId) {
          self.transitionTo('purchases.add_product', orderId);
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      closeOrder: function closeOrder(id) {
        var self = this,
            currentOrderId = this.get('controller').get('currentOrderId'),
            controller = this.get('controller');

        $.ajax({
          url: "/api/purchaseOrders/" + id + "/close",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Bestellung', 'geschlossen');

          Ember['default'].$.ajax({
            url: "/api/purchaseOrders/open",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set("open", data.purchaseOrder);
          });
        }, function () {
          self.refresh();
          jason.notifiction.notifiy('Bestellung', 'geschlossen');
        });
      },
      finalize: function finalize(order) {
        var self = this;

        $.ajax({
          url: "/api/purchaseOrders/finalize/" + order.id,
          method: "POST",
          data: JSON.stringify(new Object()),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
        });
      },
      cancel: function cancel(id) {
        var self = this;
        $.ajax({
          url: "/api/purchaseOrders/cancel/" + id,
          method: "POST",
          data: JSON.stringify(new Object()),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('purchases.cancel', id, data);
        });
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      controller.set('showLoading', true);

      this.findPaged('purchaseOrder', {
        filterFrom: from,
        filterTo: to,
        filterStatus: controller.get('filterOrderStatus')
      }).then(function (transactions) {
        var meta = transactions.meta;
        controller.set('sumIndividualCashback', meta.sumIndividualCashback);
        controller.set('sumJasonCashback', meta.sumJasonCashback);
        controller.set('model', transactions);
        controller.set('showLoading', false);
      });
    }
  });

});