define('jason-frontend/controllers/practicemgmt/waitingroom', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Wartezimmer",
    breadCrumbPath: "practicemgmt.admission",
    actions: {
      editTreatmentInfo: function editTreatmentInfo(info) {
        this.get('infoTreatmentPanel').send('load', info);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-treatment-infos-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    }
  });

});