define('jason-frontend/router', ['exports', 'ember', 'jason-frontend/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.map(function () {
    this.resource('login', { path: '/' }, function () {
      this.route('login');
    });
    this.resource('partner', { path: '/partner' }, function () {
      this.route('partner');
    });
    this.resource('expired', { path: '/expired' }, function () {
      this.route('expired');
    });
    this.resource('register', { path: '/register' }, function () {
      this.route('register');
    });
    this.resource('rlogin', { path: '/rlogin' }, function () {
      this.route('rlogin');
    });
    this.resource('admission', { path: '/admission' }, function () {
      this.route('admission');
    });
    this.resource('digitalhelper', { path: '/digitalhelper' }, function () {
      this.route('digitalhelper');
    });
    this.resource('etigaconfirm', { path: '/etigaconfirm' }, function () {
      this.route('etigaconfirm');
    });
    this.resource('confirm', { path: '/confirm' }, function () {
      this.route('confirm');
    });
    this.resource('logout', { path: '/logout' }, function () {
      this.route('logout');
    });
    this.resource('dashboard', function () {
      this.resource('home');
      this.resource('profile');
      this.resource('notifications');
      this.resource('tasks');
      this.resource('welcome', function () {});
      this.resource('reminders', function () {});
      this.resource('documents', function () {});
      this.resource('templates', function () {});
      this.resource('basicinfos', function () {});
      this.resource('practice', function () {});
      this.resource('editor', function () {
        this.route('edit', { path: '/:id/:referer/:refererId' });
      });
      this.resource('invoices', function () {
        this.route('show', { path: '/:id' });
        this.route('preview', { path: '/:id/preview' });
        this.route('create', { path: '/:id/create' });
        this.route('select_customer', { path: '/:id/customer' });
        this.route('open');
        this.route('banktransfer', { path: '/:id/banktransfer' });
        this.route('banktransfers');
        this.route('showbanktransfer', { path: '/:id/showbanktransfer' });
        this.route('editbanktransfer', { path: '/:id/editbanktransfer' });
        this.route('financialbook');
        this.route('financejournal');
      });
      this.resource('users', function () {
        this.route('show', { path: '/:id' });
        this.route('edit', { path: '/:id/edit' });
        this.route('permission', { path: '/:id/permission' });
        this.route('create');
      });
      this.resource('mandants', function () {
        this.route('editadmin', { path: '/:id/editadmin' });
        this.route('create');
        this.route('show');
        this.route('edit');
        this.route('dashboard');
        this.route('referer');
      });
      this.resource('appointments', function () {
        this.route('calendar');
      });
      this.resource('customers', function () {
        this.route('show', { path: '/:id' });
        this.route('edit', { path: '/:id/edit', queryParams: ['selectTab'] });
      });
      this.resource('services', function () {
        this.route('show', { path: '/:id' });
        this.route('edit', { path: '/:id/edit', queryParams: ['selectTab'] });
        this.route('create');
      });
      this.resource('products', function () {
        this.route('edit', { path: '/:id/edit', queryParams: ['selectTab'] });
        this.route('create');
      });
      this.resource('compositeproducts', function () {
        this.route('edit', { path: '/:id/edit', queryParams: ['selectTab'] });
        this.route('select-product', { path: '/:id/select-product' });
        this.route('select-service', { path: '/:id/select-service' });
        this.route('create');
      });
      this.resource('serviceunits', function () {
        this.route('show', { path: '/:id' });
        this.route('edit', { path: '/:id/edit' });
        this.route('create');
      });
      this.resource('agents', function () {
        this.route('show', { path: '/:id' });
      });
      this.resource('cashregisters', function () {});
      this.resource('transactions', function () {
        this.route('cashdesk');
        this.route('cashbook');
        this.route('financial');
        this.route('sales-slip-preview', { path: '/:id/preview' });
        this.route('show', { path: '/:id' });
        this.route('closings');
        this.route('showclosing', { path: '/closing/:id' });
      });
      this.resource('cash', function () {
        this.route('removal');
        this.route('reciept');
        this.route('in', { path: '/:id' });
        this.route('inlay');
        this.route('composite');
        this.route('cancel');
        this.route('choose');
        this.route('logbook');
        this.route('dsf');
        this.route('dsf-export');
        this.route('tar-export');
        this.route('logbook-detailed');
        this.route('add_article', { path: '/:type/:id/addarticle/:patientId', queryParams: ['stocktakingId'] });
        this.route('add_product', { path: '/:id/addproduct/:patientId' });
        this.route('add_service', { path: '/:id/addservice/:patientId' });
        this.route('add_composite', { path: '/:id/addcomposite/:patientId' });
      });
      this.resource('patients', function () {
        this.route('appointment-categories');
        this.route('edit', { path: '/:id/edit', queryParams: ['selectTab'] });
        this.route('etiga', { path: '/:masterId/etiga' });
        this.route('create', { path: '/:customerId/create' });
      });
      this.resource('categories', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('create');
      });
      this.resource('document-tags', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('create');
      });
      this.resource('servicecategories', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('create');
      });
      this.resource('etigafeed', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('create');
      });
      this.resource('sale', function () {
        this.route('products');
        this.route('services');
        this.route('compositeproducts');
        this.route('etigacampaigns');
        this.route('dashboard');
      });
      this.resource('manufacturers', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('create');
      });
      this.resource('distributors', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('create');
      });
      this.resource('bankingaccounts', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('create');
      });
      this.resource('purchases', function () {
        this.route('show', { path: '/:id' });
        this.route('edit', { path: '/:id/edit', queryParams: ['redirectToListing'] });
        this.route('summary', { path: '/:id/summary' });
        this.route('confirm', { path: '/:id/confirm' });
        this.route('confirmcancel', { path: '/:id/:cancellationId/confirmcancel' });
        this.route('detail', { path: '/:id/detail/:orderId' });
        this.route('add_product', { path: '/:id/addproduct' });
        this.route('cancel');
        this.route('cancel', { path: '/:id/:cancellationId/cancel' });
        this.route('supplierdetails', { path: '/:id/supplier' });
        this.route('create');
        this.route('choose');
        this.route('board');
        this.route('savings');
        this.route('masterdata');
        this.route('ws-logs');
        this.route('transactionFees');
        this.route('cashback');
        this.route('cashback-thresholds');
        this.route('cashback-thresholds-details', { path: '/:id/thresholds' });
        this.route('commissions');
        this.route('labresults');
        this.route('suppliers');
        this.route('purchase-commissions');
        this.route('promotions');
        this.route('impex');
        this.route('dashboard');
      });
      this.resource('treatmentstations', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('create');
      });
      this.resource('textmodules', function () {
        this.route('edit', { path: '/:id/edit/:type' });
      });
      this.resource('treatmentrooms', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('create');
      });
      this.resource('inventorywarehouses', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('create');
      });
      this.resource('layerplans', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('create');
      });
      this.resource('resourcemgmt', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('create');
      });
      this.resource('patientappointmentcategories', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('create');
      });
      this.resource('labprofiles', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('show', { path: '/:id/show' });
        // this.route('create');
      });
      this.resource('xrayprofiles', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('show', { path: '/:id/show' });
      });
      this.resource('resources', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('show', { path: '/:id/show' });
        this.route('create', { path: '/create' });
      });
      this.resource('labequipments', function () {
        // this.route('edit', {path: '/:id/edit'});
        // this.route('create');
      });
      this.resource('inventory', function () {
        this.route('select-order');
        this.route('quick-scan');
        this.route('inventory-print');
        this.route('agg-stock');
        this.route('delivery-note');
        this.route('delivery-note-details', { path: '/:id/deliverynote' });
        this.route('delivery-note-view', { path: '/:id/deliverynoteview' });
        this.route('movements');
        this.route('checks');
        this.route('stocktaking');
        this.route('corrections');
        this.route('warehouses');
        this.route('bookin-request', { path: '/:id/bookin', queryParams: ['type', 'stocktakingId'] });
        this.route('stock-details', { path: '/:id/details' });
        this.route('check-detail', { path: '/:id/check' });
        this.route('stocktaking-detail', { path: '/:id/stocktaking' });
        this.route('massmove-detail', { path: '/:id/massmove' });
        this.route('stocktaking-add', { path: '/:id/add' });
      });
      this.resource('practicemgmt', function () {
        this.route('admission');
        this.route('admission-select-customer');
        this.route('waitingroom');
        this.route('station');
        this.route('payment');
        this.route('xray-journal');
        this.route('therapyplan');
        this.route('treatment-journal');
        this.route('treatment-future-action', { path: '/:type/:refererId/treatmentFuture/:id' });
        this.route('treatment');
        this.route('treatment-details', { path: '/:id/treatment', queryParams: ['selectTab'] });
      });
    });
  });

  exports['default'] = Router;

});