define('jason-frontend/components/treatment-fever-chart', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    tagName: '',
    actions: {
      reloadChart: function reloadChart(id) {
        var self = this;
        this.set('id', id);
        setTimeout(function () {
          self.show();
        }, 1000);
      }
    },
    show: function show() {

      var charts = $('div#highchartsFever');

      $.ajax({
        url: "/api/treatmentPatients/" + this.get('id') + "/feverChartCsv",
        method: "GET",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {

        var csv = data.patientWeightBar.data;

        var lines = csv.split('\n').length;

        if (lines <= 2) {
          $('div#highchartsFever').hide();
          return;
        }

        $('div#highchartsFever').show();

        charts.highcharts({
          lang: {
            decimalPoint: ',',
            thousandsSep: '.',
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
            loading: 'Daten werden geladen...',
            exportButtonTitle: "Exportieren",
            printButtonTitle: "Drucken",
            rangeSelectorFrom: "Von",
            rangeSelectorTo: "Bis",
            rangeSelectorZoom: "Zeitraum",
            downloadPNG: 'Download als PNG-Bild',
            downloadJPEG: 'Download als JPEG-Bild',
            downloadPDF: 'Download als PDF-Dokument',
            downloadSVG: 'Download als SVG-Bild',
            resetZoom: "Zoom zurücksetzen",
            resetZoomTitle: "Zoom zurücksetzen"
          },

          colors: ["#7cb5ec", "#f7a35c", "#90ee7e", "#7798BF", "#aaeeee", "#ff0066", "#eeaaee", "#55BF3B", "#DF5353", "#7798BF", "#aaeeee"],

          data: {
            csv: csv
          },

          title: {
            text: ''
          },
          xAxis: {
            tickWidth: 0,
            gridLineWidth: 1,
            labels: {
              align: 'left',
              x: 3,
              y: -3
            }
          },

          yAxis: [{
            title: {
              text: null
            },
            labels: {
              align: 'left',
              x: 3,
              y: 16,
              format: '{value:.,0f}'
            },
            showFirstLabel: false
          }, {
            linkedTo: 0,
            gridLineWidth: 0,
            opposite: true,
            title: {
              text: 'Grad'
            },
            labels: {
              align: 'right',
              x: -3,
              y: 16,
              format: '{value:.,0f}'
            },
            showFirstLabel: false
          }],

          legend: {
            align: 'left',
            verticalAlign: 'top',
            y: 0,
            floating: true,
            borderWidth: 0
          },

          tooltip: {
            shared: true,
            crosshairs: true
          },

          plotOptions: {
            series: {
              cursor: 'pointer',
              marker: {
                lineWidth: 1
              }
            }
          }
        });
      });
    }
  });

});