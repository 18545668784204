define('jason-frontend/controllers/customers/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Bearbeiten",
    breadCrumbPath: "customers.edit",
    queryParams: ['referer', 'refererInvoiceId', 'accounting'],
    referer: null,
    quickFilter: 'all',
    showFilters: true,
    refererInvoiceId: null,
    selectedPaymentMoral: null,
    selectTab: 'masterdata',
    newMode: API['default'].getMandant() === '3',
    filterDeadPatients: false,
    showDead: false,
    inputDirty: false,
    deliveryOptions: [{ id: "all", value: "Smartphone-App + Email" }, { id: "app", value: "Smartphone-App" }, { id: "email", value: "Email" }],
    openDataOutdatedPanel: function openDataOutdatedPanel(user) {
      // if(!$('#data-outdated-panel').length) {
      //   console.log('overlay not present - abort');
      //   return true;
      // }
      //
      // jason.notifiction.notifiy('Daten veraltet', 'Kunden');
      //
      // this.get('dataOutdatedPanel').send('load', user);
      //
      // $.magnificPopup.open({
      //   removalDelay: 500,
      //   closeOnBgClick: false,
      //   items: {
      //     src: '#data-outdated-panel'
      //   },
      //   callbacks: {
      //     beforeOpen: function() {
      //       var Animation = "mfp-with-fade";
      //       this.st.mainClass = Animation;
      //     }
      //   },
      //   midClick: true
      // });
    },
    actions: {
      openEmailForwardPanel: function openEmailForwardPanel(id) {

        var customer = this.get('model');

        if (customer) {
          this.get('forwardEmailPanel').send('load', id, customer.get('email'));
        } else {
          this.get('forwardEmailPanel').send('load', id);
        }

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-email-invoice-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openRevenue: function openRevenue(id) {

        var customer = this.get('model');

        if (customer) {
          this.get('customerRevenuePanel').send('load', id);
        }

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-customer-revenue-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openDocumentForwardPanel: function openDocumentForwardPanel(id) {

        var customer = this.get('model');

        if (customer) {
          this.get('forwardDocumentPanel').send('load', id, customer.get('email'));
        }

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-document-forward-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      showUploadForm: function showUploadForm(id) {
        var self = this;

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-media-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openNewAppointment: function openNewAppointment() {
        var self = this;

        $.ajax({
          url: "/api/patients/forcustomer/" + this.get('model').id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('addAppointmentPanel').send('loadCustomer', self.get('model'), self.store.find('patient-appointment-category'), data.patient);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-appointment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      showAppointment: function showAppointment(item) {
        this.get('showAppointmentPanel').send('load', item);
        var self = this;

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-show-appointment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openEditAppointment: function openEditAppointment(itemId) {
        var self = this;

        $.ajax({
          url: "/api/patientAppointments/" + itemId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('addAppointmentPanel').send('loadEdit', data.patientAppointment, self.store.find('patient-appointment-category'));
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-appointment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectBarcode: function selectBarcode(patientId) {
        this.get('patientSelectBarcodePanel').send('load', patientId);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-patient-select-barcode-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      downloadBarcode: function downloadBarcode(patientId, format) {
        window.open('/api/patients/downloadBarcode/' + patientId + '/' + format + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      payoutDeposit: function payoutDeposit(id) {
        var self = this;
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-customer-payout-deposit-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      triggerSave: function triggerSave() {
        jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        $('form#customerEdit').submit();
      },
      openDataOutdatedPanel: function openDataOutdatedPanel(user) {
        this.openDataOutdatedPanel(user);
      },
      save: function save(referer, refererInvoiceId) {
        var self = this,
            model = this.get('model'),
            distanceKm = model.get('distanceKm'),
            distanceMinutes = model.get('distanceMinutes');

        if (distanceKm) {
          model.set('distanceKm', String(distanceKm).replace(",", "."));
        }

        if (distanceMinutes) {
          model.set('distanceMinutes', String(distanceMinutes).replace(",", "."));
        }

        var customer = new Object();
        customer.id = model.get('id');
        customer.firstname = model.get('firstname');
        customer.lastname = model.get('lastname');
        customer.street = model.get('street');
        customer.town = model.get('town');
        customer.reminderSetting = model.get('reminderSetting');
        customer.reductionToPurchasePrice = model.get('reductionToPurchasePrice');
        customer.postalCode = model.get('postalCode');
        customer.driveStreet = model.get('driveStreet');
        customer.drivePostalcode = model.get('drivePostalcode');
        customer.driveTown = model.get('driveTown');
        customer.driveNotice = model.get('driveNotice');
        customer.distanceMinutes = model.get('distanceMinutes');
        customer.distanceKm = model.get('distanceKm');
        customer.sued = model.get('sued');
        customer.foreignId = model.get('foreignId');
        customer.version = model.get('version');
        customer.uid = model.get('uid');

        customer.profession = model.get('profession');

        customer.company = model.get('company');
        customer.notice = model.get('notice');
        customer.tags = model.get('tags');
        customer.homepage = model.get('homepage');

        if (!customer.company && !customer.firstname && !customer.lastname) {
          jason.notifiction.error('Fehler', 'Vorname, Nachname oder Firma müssen ausgefüllt sein');
          return;
        }

        if (model.get('birthdayInput') && model.get('birthdayInput').length > 0) {
          customer.birthday = moment(model.get('birthdayInput'), 'DD. MM. YYYY').toDate();
        } else {
          customer.birthday = null;
        }

        customer.email = model.get('email');
        customer.emailDescription = model.get('emailDescription');
        customer.email2 = model.get('email2');
        customer.emailDescription2 = model.get('emailDescription2');
        customer.email3 = model.get('email3');
        customer.emailDescription3 = model.get('emailDescription3');
        customer.email4 = model.get('email4');
        customer.emailDescription4 = model.get('emailDescription4');

        customer.phone1 = model.get('phone1');
        customer.phoneDescription1 = model.get('phoneDescription1');
        customer.phone2 = model.get('phone2');
        customer.phoneDescription2 = model.get('phoneDescription2');
        customer.phone3 = model.get('phone3');
        customer.phoneDescription3 = model.get('phoneDescription3');
        customer.phone4 = model.get('phone4');
        customer.phoneDescription4 = model.get('phoneDescription4');

        customer.fax = model.get('fax');
        customer.faxDescription = model.get('faxDescription');
        customer.fax2 = model.get('fax2');
        customer.faxDescription2 = model.get('faxDescription2');
        customer.fax3 = model.get('fax3');
        customer.faxDescription3 = model.get('faxDescription3');
        customer.fax4 = model.get('fax4');
        customer.faxDescription4 = model.get('faxDescription4');

        if (model.get('reductions')) {
          var reductions = [],
              indexCount = 0;

          model.get('reductions').forEach(function (item) {
            if (item) {
              reductions[indexCount] = {
                id: item.get('id'),
                reduction: item.get('reduction')
              };

              if (item.get('patient')) {
                reductions[indexCount].patient = {
                  id: item.get('patient').id
                };
              }
              if (item.get('type')) {
                reductions[indexCount].type = {
                  id: item.get('type').id
                };
              }
              if (item.get('productCategory')) {
                reductions[indexCount].productCategory = {
                  id: item.get('productCategory').id
                };
              }
              if (item.get('serviceCategory')) {
                reductions[indexCount].serviceCategory = {
                  id: item.get('serviceCategory').id
                };
              }

              indexCount++;

              if (item.get('id') > 0) {} else {
                self.store.deleteRecord(item);
              }
            }
          });
          customer.reductions = reductions;
        }

        if (model.get('title') && model.get('title').get('id')) {
          customer.title = {
            id: model.get('title').get('id')
          };
        }
        if (model.get('salutation') && model.get('salutation').get('id')) {
          customer.salutation = {
            id: model.get('salutation').get('id')
          };
        }
        if (model.get('country') && model.get('country').get('id')) {
          customer.country = {
            id: model.get('country').get('id')
          };
        }
        if (model.get('gender') && model.get('gender').get('id')) {
          customer.gender = {
            id: model.get('gender').get('id')
          };
        }
        if (model.get('invoiceTitle') && model.get('invoiceTitle').get('id')) {
          customer.invoiceTitle = {
            id: model.get('invoiceTitle').get('id')
          };
        }
        if (model.get('invoiceGender') && model.get('invoiceGender').get('id')) {
          customer.invoiceGender = {
            id: model.get('invoiceGender').get('id')
          };
        }

        customer.paymentMoral = this.get('selectedPaymentMoral');

        customer.invoiceFirstname = model.get('invoiceFirstname');
        customer.invoiceLastname = model.get('invoiceLastname');
        customer.invoiceStreet = model.get('invoiceStreet');
        customer.invoiceTown = model.get('invoiceTown');
        customer.invoicePostalcode = model.get('invoicePostalcode');
        customer.invoiceUid = model.get('invoiceUid');
        customer.iban = model.get('iban');
        customer.bic = model.get('bic');
        customer.invoiceAddition = model.get('invoiceAddition');
        customer.differentInvoice = model.get('differentInvoice');
        customer.invoiceConditions = model.get('invoiceConditions');
        customer.daysTillWarning1 = model.get('daysTillWarning1');
        customer.daysTillWarning2 = model.get('daysTillWarning2');
        customer.daysTillNopayer = model.get('daysTillNopayer');

        $.ajax({
          url: "/api/customers/" + model.id,
          method: "PUT",
          data: JSON.stringify(customer),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          if (referer === 'invoice') {
            self.transitionTo('invoices.create', refererInvoiceId);
          } else {
            self.get('target.router').refresh();
          }
        }, function (error) {
          self.openDataOutdatedPanel('einem anderen Benutzer');
        });
      }
    },
    watchCategory: (function () {
      this.send('selectTab', 'appointments');
    }).observes('filterCategory')
  });

});