define('jason-frontend/controllers/document-tags/edit', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Bearbeiten",
    breadCrumbPath: "etigafeed.edit",
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY")
  });

});