define('jason-frontend/components/composite-products-edit-form', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      openMedia: function openMedia(item) {
        if (item.get('media').externalUrl) {
          window.open(item.get('media').externalUrl);
        } else {
          window.open('/api/media/' + item.get('media').id + '/download?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
        }
      },
      toAddProduct: function toAddProduct(id) {
        this.sendAction('toAddProduct', id);
      },
      moveUp: function moveUp(entry, model) {
        this.sendAction('moveUp', entry, model);
      },
      moveDown: function moveDown(entry, model) {
        this.sendAction('moveDown', entry, model);
      },
      toAddService: function toAddService(id) {
        this.sendAction('toAddService', id);
      },
      toAddTemplate: function toAddTemplate(id) {
        this.sendAction('toAddTemplate', id);
      },
      toggleFavorite: function toggleFavorite(model) {
        if (model.get('favorite')) {
          Ember['default'].set(model, 'favorite', false);
        } else {
          Ember['default'].set(model, 'favorite', true);
        }
      }
    }
  });

});