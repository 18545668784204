define('jason-frontend/controllers/mandants/referer-code', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Referer-Codes",
    breadCrumbPath: "mandant.referer-code",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    showLoading: false,
    perPage: 20
  });

});