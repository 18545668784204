define('jason-frontend/components/patient-tasks', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',
    actions: {
      changeOwner: function changeOwner(item) {
        this.sendAction('changeOwner', item);
      },
      markAsRead: function markAsRead(msg) {
        this.sendAction('markAsRead', msg);
      },
      startTask: function startTask(msg) {
        this.sendAction('startTask', msg);
      },
      finishTask: function finishTask(msg) {
        this.sendAction('finishTask', msg);
      },
      createNotification: function createNotification(msg) {
        this.sendAction('createNotification', msg);
      },
      showTask: function showTask(msg) {
        this.sendAction('showTask', msg);
      },
      showNotification: function showNotification(msg) {
        this.sendAction('showNotification', msg);
      },
      markAsReadForAll: function markAsReadForAll(msg) {
        this.sendAction('markAsReadForAll', msg);
      }
    }
  });

});