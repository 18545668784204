define('jason-frontend/models/digital-therapy', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    fullName: DS['default'].attr('string'),
    parentName: DS['default'].attr('string')
  });

});