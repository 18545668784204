define('jason-frontend/controllers/invoices/show', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    selectedPrinter: null,
    actions: {
      downloadWarning: function downloadWarning(invoice) {
        var self = this;
        self.get('downloadInvoicePanel').send('load', invoice);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-download-invoice-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      print: function print(transactionGroup) {
        var deferred = $.ajax({
          url: "/api/cashdesk/print/" + transactionGroup.get('id'),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Belegdruck durchgeführt', '');
        }, function () {
          jason.notifiction.systemError('Fehlercode 101', 'Belegdruck konnte nicht durchgeführt werden');
        });
      },
      openEmailPanel: function openEmailPanel(invoiceId) {

        var customer = this.get('model').get('customer');
        var self = this;
        if (customer) {
          this.get('forwardEmailPanel').send('loadWithEmails', invoiceId, customer.get('emails'));
        } else {
          this.get('forwardEmailPanel').send('load', invoiceId);
        }

        $.ajax({
          url: "/api/textModules?filterType=email&per_page=50",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('forwardEmailPanel').send('loadTextmodules', data.textModule);
        }, function () {});

        $.ajax({
          url: "/api/editor/invoice/printTemplates",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('forwardEmailPanel').send('loadPrintTemplates', data.templateStyle);
        }, function () {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-email-invoice-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    }
  });

});