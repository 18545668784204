define('jason-frontend/helpers/list-not-empty', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.listNotEmpty = listNotEmpty;

  function listNotEmpty(params) {
    var list = params[0];
    if (list && list.length > 0) {
      return true;
    }
    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(listNotEmpty);

});