define('jason-frontend/models/banking-account', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    number: DS['default'].attr('string'),
    bankName: DS['default'].attr('string'),
    name: DS['default'].attr('string'),
    iban: DS['default'].attr('string')
  });

});