define('jason-frontend/templates/components/invoice-payment-chooser', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 6
            },
            "end": {
              "line": 9,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-payment-chooser.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-3/24");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","table__title title");
          var el3 = dom.createTextNode("EC - Terminal");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 4
            },
            "end": {
              "line": 28,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-payment-chooser.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","row0");
          dom.setAttribute(el1,"data-id","0");
          dom.setAttribute(el1,"class","js-table__row table__row rows deposit");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24@tablet l-3/24@desk open payment-label");
          dom.setAttribute(el2,"data-label","Offen");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24@tablet l-3/24@desk given payment-label");
          dom.setAttribute(el2,"data-label","Gegeben");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("input");
          dom.setAttribute(el3,"type","text");
          dom.setAttribute(el3,"value","0");
          dom.setAttribute(el3,"class","input input--editable given-input");
          dom.setAttribute(el3,"name","given0");
          dom.setAttribute(el3,"autocomplete","off");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24@tablet l-3/24@desk change payment-label");
          dom.setAttribute(el2,"data-label","Wechselgeld");
          dom.setAttribute(el2,"data-change","0");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-14/24@desk payment-types payment-label");
          dom.setAttribute(el2,"data-label","Zahlungsmittel");
          dom.setAttribute(el2,"id","deposit-label");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","cash hidden");
          dom.setAttribute(el3,"for","cash0");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("input");
          dom.setAttribute(el4,"class","cash");
          dom.setAttribute(el4,"name","paymentType0");
          dom.setAttribute(el4,"type","radio");
          dom.setAttribute(el4,"id","cash0");
          dom.setAttribute(el4,"value","cash");
          dom.setAttribute(el4,"checked","");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            Gesamtguthaben ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","title");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(", Saldo: ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [7]);
          if (this.cachedFragment) { dom.repairClonedNode(dom.childAt(element3, [1, 1]),[],true); }
          var element4 = dom.childAt(element3, [8]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element2, 'data-open');
          morphs[1] = dom.createAttrMorph(element2, 'data-deposit');
          morphs[2] = dom.createMorphAt(element2,0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element3, [6]),0,0);
          morphs[4] = dom.createAttrMorph(element4, 'class');
          morphs[5] = dom.createMorphAt(element4,0,0);
          return morphs;
        },
        statements: [
          ["attribute","data-open",["concat",[["get","open",["loc",[null,[15,110],[15,114]]]]]]],
          ["attribute","data-deposit",["concat",[["get","depositSum",["loc",[null,[15,134],[15,144]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","open",["loc",[null,[15,172],[15,176]]]]],[],[]]],["loc",[null,[15,148],[15,178]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","depositSum",["loc",[null,[25,165],[25,175]]]]],[],[]]],["loc",[null,[25,141],[25,177]]]],
          ["attribute","class",["concat",["title ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","balanceSum",["loc",[null,[25,237],[25,247]]]],0],[],["loc",[null,[25,233],[25,250]]]],"is-negative",""],[],["loc",[null,[25,212],[25,269]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","balanceSum",["loc",[null,[25,295],[25,305]]]]],[],[]]],["loc",[null,[25,271],[25,307]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 70,
              "column": 9
            },
            "end": {
              "line": 84,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-payment-chooser.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-8/24@tablet l-3/24@desk terminal-label mt5");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","dropdown terminalDisabled");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--success list-action-square dropdown");
          dom.setAttribute(el3,"data-toggle","dropdown");
          dom.setAttribute(el3,"aria-haspopup","true");
          dom.setAttribute(el3,"aria-expanded","false");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"style","width: 40px");
          dom.setAttribute(el4,"src","/assets/images/icons/icon-terminal.svg");
          dom.setAttribute(el4,"class","pr15");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","dropdown-menu");
          dom.setAttribute(el3,"aria-labelledby","dropdownMenuButton");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","dropdown-item terminal");
          var el5 = dom.createTextNode("\n              Betrag senden\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 94,
              "column": 2
            },
            "end": {
              "line": 98,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-payment-chooser.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"type","submit");
          dom.setAttribute(el1,"class","icon-button icon-button--success");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["registerPayments"],[],["loc",[null,[95,69],[95,98]]]],
          ["inline","button-next",[],["size","52","color","#ffffff","content","Kassieren"],["loc",[null,[96,8],[96,69]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 100,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/invoice-payment-chooser.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table table--payment box no-box@phone");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table__head");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell l-3/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Offen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell l-3/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Gegeben");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell l-3/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Wechselgeld");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell l-11/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Zahlungsmittel");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table__body");
        dom.setAttribute(el2,"id","transaction-input");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"id","init-open");
        dom.setAttribute(el3,"style","visibility:hidden;position:absolute;left:-9999%;");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","row1");
        dom.setAttribute(el3,"data-id","1");
        dom.setAttribute(el3,"class","js-table__row table__row rows");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell l-8/24@tablet l-3/24@desk open payment-label");
        dom.setAttribute(el4,"data-label","Offen");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell l-8/24@tablet l-3/24@desk given payment-label");
        dom.setAttribute(el4,"data-label","Gegeben");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"type","text");
        dom.setAttribute(el5,"class","input input--editable given-input");
        dom.setAttribute(el5,"name","given1");
        dom.setAttribute(el5,"autocomplete","off");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell l-8/24@tablet l-3/24@desk change payment-label");
        dom.setAttribute(el4,"data-change","0");
        dom.setAttribute(el4,"data-label","Wechselgeld");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","payment-types table__cell l-11/24@desk");
        dom.setAttribute(el4,"data-label","Zahlungsmittel");
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"class","button--toggle__input cash");
        dom.setAttribute(el5,"name","paymentType1");
        dom.setAttribute(el5,"type","radio");
        dom.setAttribute(el5,"id","cash1");
        dom.setAttribute(el5,"value","cash");
        dom.setAttribute(el5,"checked","");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","cash button button--toggle");
        dom.setAttribute(el5,"for","cash1");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","jason-icon");
        var el7 = dom.createTextNode("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Bargeld");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"class","button--toggle__input cashcard");
        dom.setAttribute(el5,"name","paymentType1");
        dom.setAttribute(el5,"id","cashcard1");
        dom.setAttribute(el5,"value","cashcard");
        dom.setAttribute(el5,"type","radio");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","cashcard button button--toggle");
        dom.setAttribute(el5,"for","cashcard1");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","jason-icon");
        var el7 = dom.createTextNode("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("EC-Karte");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment(" #00749c ");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"class","button--toggle__input creditcard");
        dom.setAttribute(el5,"name","paymentType1");
        dom.setAttribute(el5,"id","creditcard1");
        dom.setAttribute(el5,"value","creditcard");
        dom.setAttribute(el5,"type","radio");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","creditcard button button--toggle");
        dom.setAttribute(el5,"for","creditcard1");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","jason-icon");
        var el7 = dom.createTextNode("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Kreditkarte");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment(" #cashcard ");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"class","button--toggle__input voucher");
        dom.setAttribute(el5,"name","paymentType1");
        dom.setAttribute(el5,"id","voucher1");
        dom.setAttribute(el5,"value","voucher");
        dom.setAttribute(el5,"type","radio");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","voucher button button--toggle");
        dom.setAttribute(el5,"for","voucher1");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","jason-icon");
        var el7 = dom.createTextNode("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Gutschein");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment(" #ec1561 ");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell l-1/24 remove mt5");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons ");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button icon-button--small");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var element6 = dom.childAt(element5, [3]);
        var element7 = dom.childAt(element6, [5]);
        var element8 = dom.childAt(element7, [1]);
        if (this.cachedFragment) { dom.repairClonedNode(dom.childAt(element7, [7, 1]),[],true); }
        var element9 = dom.childAt(fragment, [2]);
        var element10 = dom.childAt(element9, [1]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]),9,9);
        morphs[1] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
        morphs[2] = dom.createMorphAt(element6,3,3);
        morphs[3] = dom.createAttrMorph(element8, 'data-open');
        morphs[4] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
        morphs[5] = dom.createMorphAt(element7,9,9);
        morphs[6] = dom.createElementMorph(element10);
        morphs[7] = dom.createMorphAt(element10,1,1);
        morphs[8] = dom.createMorphAt(element9,3,3);
        return morphs;
      },
      statements: [
        ["block","if",[["get","terminalActivated",["loc",[null,[7,12],[7,29]]]]],[],0,null,["loc",[null,[7,6],[9,13]]]],
        ["content","open",["loc",[null,[12,82],[12,90]]]],
        ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[13,14],[13,24]]]],0],[],["loc",[null,[13,10],[13,27]]]]],[],1,null,["loc",[null,[13,4],[28,11]]]],
        ["attribute","data-open",["concat",[["get","open",["loc",[null,[32,108],[32,112]]]]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","open",["loc",[null,[33,47],[33,51]]]]],[],[]]],["loc",[null,[33,23],[33,53]]]],
        ["block","if",[["get","terminalActivated",["loc",[null,[70,15],[70,32]]]]],[],2,null,["loc",[null,[70,9],[84,11]]]],
        ["element","action",["toSelectPositions"],[],["loc",[null,[91,7],[91,37]]]],
        ["content","button-prev",["loc",[null,[92,6],[92,21]]]],
        ["block","if",[["get","submitButtonEnabled",["loc",[null,[94,8],[94,27]]]]],[],3,null,["loc",[null,[94,2],[98,9]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});