define('jason-frontend/components/extract-invoice-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    selectedMode: 'new',
    isPatient: true,
    actions: {
      confirm: function confirm() {
        if (this.get('selectedMode') === 'composite' && !this.get('selectedInvoice')) {
          jason.notifiction.error('Fehler', 'Bitte Sammelrechnung auswählen');
          return;
        }
        this.sendAction('confirm', this.get('tpId'), this.get('patientId'), this.get('selectedMode'), this.get('selectedInvoice'), this.get('selectedPatient'));
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      selectMode: function selectMode(mode) {
        if (mode === 'new' || mode === 'moveIntern' || mode === 'composite' && this.get('selectedInvoice')) {
          this.set('selectedMode', mode);
        }
      },
      load: function load(tpId, patientId, invoices) {
        var _this = this;

        var self = this;
        this.set('tpId', tpId);
        this.set('patientId', patientId);
        this.set('selectedMode', 'composite');
        this.set('invoices', invoices);
        this.set('selectedPatient', null);
        if (invoices && invoices.invoices && invoices.invoices.length > 0) {
          self.set('selectedInvoice', invoices.invoices[0].id);
        }
        this.set('patients', null);
        setTimeout(function () {
          _this.set('patients', invoices.patients);
        }, 500);
      }
    },
    watchMode: (function () {
      this.set('selectedMode', 'moveIntern');
    }).observes('selectedPatient'),
    watchComposite: (function () {
      this.set('selectedMode', 'composite');
    }).observes('selectedInvoice')
  });

});