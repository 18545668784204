define('jason-frontend/routes/invoices/financialbook', ['exports', 'ember', 'moment', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, moment, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {
      params.filterMandant = "all";
      params.filterFrom = moment['default']().startOf('month').format('YYYY-MM-DD');
      params.filterTo = moment['default']().endOf('month').format('YYYY-MM-DD');
      return this.findPaged('cashbook', params);
    },
    perPage: 20,
    queryParams: {},
    setupController: function setupController(controller) {

      if (!API['default'].hasPermission('r_financialbook')) {
        this.transitionTo('home');
      }

      this.applyFilter();
      controller.set('cashRegisters', this.store.find('cashRegister'));
      controller.set('downloadToken', API['default'].getDownloadToken());
      controller.set('mandantId', API['default'].getMandant());
    },
    actions: {
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      },
      setPaymentType: function setPaymentType(type) {
        var controller = this.get('controller');
        if (type === 'cash') {
          if (controller.filterPaymentCash) {
            controller.set('classFilterPaymentCash', "default");
            controller.set('filterPaymentAll', false);
            controller.set('filterPaymentCash', false);
          } else {
            controller.set('classFilterPaymentCash', "primary");
            controller.set('classFilterPaymentAll', "default");
            controller.set('filterPaymentCash', true);
            controller.set('filterPaymentAll', false);
          }
        }
        if (type === 'cashcard') {
          if (controller.filterPaymentCashcard) {
            controller.set('classFilterPaymentCashcard', "default");
            controller.set('filterPaymentAll', false);
            controller.set('filterPaymentCashcard', false);
          } else {
            controller.set('classFilterPaymentAll', "default");
            controller.set('classFilterPaymentCashcard', "primary");
            controller.set('filterPaymentCashcard', true);
            controller.set('filterPaymentAll', false);
          }
        }
        if (type === 'creditcard') {
          if (controller.filterPaymentCreditcard) {
            controller.set('classFilterPaymentCreditcard', "default");
            controller.set('filterPaymentAll', false);
            controller.set('filterPaymentCreditcard', false);
          } else {
            controller.set('classFilterPaymentAll', "default");
            controller.set('classFilterPaymentCreditcard', "primary");
            controller.set('filterPaymentCreditcard', true);
            controller.set('filterPaymentAll', false);
          }
        }
        if (type === 'voucher') {
          if (controller.filterPaymentVoucher) {
            controller.set('classFilterPaymentVoucher', "default");
            controller.set('filterPaymentAll', false);
            controller.set('filterPaymentVoucher', false);
          } else {
            controller.set('classFilterPaymentAll', "default");
            controller.set('classFilterPaymentVoucher', "primary");
            controller.set('filterPaymentVoucher', true);
            controller.set('filterPaymentAll', false);
          }
        }
        if (type === 'deposit') {
          if (controller.filterPaymentDeposit) {
            controller.set('classFilterPaymentDeposit', "default");
            controller.set('filterPaymentAll', false);
            controller.set('filterPaymentDeposit', false);
          } else {
            controller.set('classFilterPaymentAll', "default");
            controller.set('classFilterPaymentDeposit', "primary");
            controller.set('filterPaymentDeposit', true);
            controller.set('filterPaymentAll', false);
          }
        }
        if (type === 'withdrawal') {
          if (controller.filterPaymentWithdrawal) {
            controller.set('classFilterPaymentWithdrawal', "default");
            controller.set('filterPaymentAll', false);
            controller.set('filterPaymentWithdrawal', false);
          } else {
            controller.set('classFilterPaymentAll', "default");
            controller.set('classFilterPaymentWithdrawal', "primary");
            controller.set('filterPaymentWithdrawal', true);
            controller.set('filterPaymentAll', false);
          }
        }
        this.applyFilter();
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-'),
          selectedRegister = controller.get('selectedRegister');

      var from = moment['default'](dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment['default'](dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          filterIn = 'day',
          filterOut = 'day',
          filterPaymentCash = controller.get('filterPaymentCash'),
          filterPaymentCreditcard = controller.get('filterPaymentCreditcard'),
          filterPaymentCashcard = controller.get('filterPaymentCashcard'),
          filterPaymentVoucher = controller.get('filterPaymentVoucher'),
          filterPaymentDeposit = controller.get('filterPaymentDeposit'),
          filterPaymentWithdrawal = controller.get('filterPaymentWithdrawal');

      if (controller.get('classFilterTypeInSingle') === 'primary') {
        filterIn = 'single';
      }
      if (controller.get('classFilterTypeInAll') === 'primary') {
        filterIn = 'all';
      }

      if (controller.get('classFilterTypeOutSingle') === 'primary') {
        filterOut = 'single';
      }
      if (controller.get('classFilterTypeOutAll') === 'primary') {
        filterOut = 'all';
      }

      controller.set('to', to);
      controller.set('from', from);
      controller.set('filterIn', filterIn);
      controller.set('filterOut', filterOut);

      this.findPaged('cashbook', {
        filterFrom: from,
        filterTo: to,
        filterIn: filterIn,
        filterOut: filterOut,
        financialFilter: true,
        filterPaymentCash: filterPaymentCash,
        filterPaymentCreditcard: filterPaymentCreditcard,
        filterPaymentCashcard: filterPaymentCashcard,
        filterPaymentVoucher: filterPaymentVoucher,
        filterPaymentDeposit: filterPaymentDeposit,
        filterPaymentWithdrawal: filterPaymentWithdrawal,
        selectedRegister: selectedRegister
      }).then(function (transactions) {
        var meta = transactions.meta;
        controller.set('sumIn', meta.sumIn);
        controller.set('sumOut', meta.sumOut);
        controller.set('model', transactions);
      });
    }
  });

});