define('jason-frontend/models/inventory-stock', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    product: DS['default'].attr(''),
    status: DS['default'].attr(''),
    flatStock: DS['default'].attr(''),
    packageQuantity: DS['default'].attr(''),
    unit: DS['default'].attr(''),
    warehouseName: DS['default'].attr(''),
    created: DS['default'].attr('date'),
    expiryDate: DS['default'].attr('date')
  });

});