define('jason-frontend/routes/patients/edit', ['exports', 'ember', 'moment', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api', 'jason-frontend/helpers/is-journal-enabled', 'jason-frontend/helpers/is-test-mandant'], function (exports, Ember, moment, RouteMixin, API, is_journal_enabled, is_test_mandant) {

  'use strict';

  var modelHookRun;

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 20,
    beforeModel: function beforeModel() {
      modelHookRun = false;
    },
    model: function model(params) {
      return this.store.findRecord('patient', params.id, { reload: true });
    },
    afterModel: function afterModel(model) {
      if (!modelHookRun) {
        return model.reload();
      }
      return model;
    },
    downloadPdf: function downloadPdf(id) {
      $.ajax({
        url: "/api/users/rat",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify({
          id: id
        }),
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {}, function (data) {
        window.open('/api/treatmentPatients/downloadLaboklinPdf/' + API['default'].getMandant() + '/' + data.responseText);
      });
    },
    reloadJournal: function reloadJournal() {
      var controller = this.get('controller');
      if (controller.get('selectTab') === 'activities') {
        controller.get('patientActivities').send('reload');
      }
    },
    selectHealthTab: function selectHealthTab(selected) {
      var controller = this.get('controller');

      if (controller.get('healthTab') !== selected) {
        controller.set('healthTab', selected);
      } else {
        controller.set('healthTab', '');
      }
      if (selected === 'vacc') {
        var self = this;
        this.findPaged('patient-vaccination', {
          perPage: 10,
          paged: true,
          patientId: self.get('controller').get('model').get('id')
        }).then(function (entries) {
          self.get('controller').set('vaccinations', entries);
        });
      }
      if (selected === 'memos') {
        var self = this;
        this.findPaged('patient-memo', {
          perPage: 10,
          paged: true,
          patientId: self.get('controller').get('model').get('id')
        }).then(function (entries) {
          self.get('controller').set('memos', entries);
        });
      }
      var self = this;
    },
    applyCorrespondanceFilter: function applyCorrespondanceFilter() {
      var self = this;
      this.findPaged('customer-logbook-entry', {
        perPage: 10,
        paged: true,
        patientId: self.get('controller').get('model').get('id')
      }).then(function (entries) {
        self.get('controller').set('logbookEntries', entries);
      });
    },
    selectTab: function selectTab(selected) {
      var rootFolderId = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      var controller = this.get('controller'),
          self = this;
      controller.set('selectTab', selected);
      if (selected === 'correspondance') {
        this.applyCorrespondanceFilter();
      }
      if (selected === 'future') {
        try {
          controller.set('futureItems', this.store.find('treatment-future-action', { patientId: controller.get('model').id, filterTime: 'future' }));
        } catch (e) {}
      }

      if (selected === 'history') {
        controller.set('users', this.store.find('user', { per_page: 100 }));

        var filterHide = controller.get('quickFilter') === 'all';

        this.findPaged('treatment-patient', {
          patientId: controller.get('model').get('id'),
          filterLab: controller.get('filterLab'),
          filterProduct: controller.get('filterProduct'),
          filterService: controller.get('filterService'),
          filterFood: controller.get('filterFood'),
          filterHide: filterHide,
          filterDeleted: controller.get('quickFilter') === 'deleted',
          perPage: 10
        }).then(function (entries) {
          controller.set('historicTreatments', entries);
        });
      }

      if (selected === 'weight' && controller.get('weightChart')) {
        controller.get('weightChart').send('reloadChart');
      }

      if (selected === 'activities') {
        controller.get('patientActivities').send('load', this, controller.get('model').id);
        var url = new URL(window.location.href);
        url.searchParams.set('selectTab', 'activities');
        window.history.replaceState(null, null, url);
      } else {
        var url = new URL(window.location.href);
        url.searchParams['delete']('selectTab');
        window.history.replaceState(null, null, url);
      }

      if (selected === 'lab') {
        $.ajax({
          url: "/api/patients/" + controller.get('model').get('id') + "/laboklinForms",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('labItems', data.purchaseOrderLaboklinForm);
        });
      }
      if (selected === 'todo') {
        this.findPaged('todo', {
          patientId: controller.get('model').get('id'),
          filterStatus: controller.get('filterTodoStatus'),
          perPage: 10
        }).then(function (entries) {
          controller.set('todos', entries);
        });
      }
      if (selected === 'etiga') {

        if (controller.get('model').get('etigaActive')) {

          $.ajax({
            url: "/api/patients/" + controller.get('model').get('id') + "/etigaPermissionsMatrix",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.get('etigaPanel').send('loadPermissions', data);
          });
          $.ajax({
            url: "/api/patients/" + controller.get('model').get('id') + "/myEtigaPermissionsMatrix",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.get('etigaPanel').send('loadMyPermissions', data);
          });
        }
      }

      if (selected === 'tasks') {

        $.ajax({
          url: "/api/tasks?patientId=" + controller.get('model').get('id') + "&per_page=30",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('tasks', data.task);
        });
      }

      if (selected === 'appointments') {
        this.findPaged('patient-appointment', {
          filterPatient: controller.get('model').get('id'),
          filterCategory: controller.get('filterCategory'),
          sorting: 'p.date desc',
          perPage: 10
        }).then(function (entries) {
          controller.set('appointments', entries);
        });
      }

      if (selected === 'medias') {
        this.reloadMedias();
      }
      if (selected === 'documents') {

        var deferred = $.ajax({
          url: "/api/documentTags",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('tags', data.documentTag);
        }, function (data) {
          controller.set('tags', data.documentTag);
        });
        setTimeout(function () {
          if (controller.get('documentsPanel')) {
            if (rootFolderId === 'null') {
              rootFolderId = null;
            }
            controller.get('documentsPanel').send('loadForPatient', self, controller.get('model').get('id'), controller.get('model').get('customer').get('id'), controller.get('model').get('customer').get('emails'), rootFolderId);
          }
        }, 1000);
      }
    },
    openDataOutdatedPanel: function openDataOutdatedPanel(user) {

      // if (!$('#data-outdated-panel').length) {
      //   console.log('overlay not present - abort');
      //   return true;
      // }
      //
      // this.get('controller').get('dataOutdatedPanel').send('load');
      //
      // jason.notifiction.notifiy('Daten veraltet', 'Patient');
      //
      // $.magnificPopup.open({
      //   removalDelay: 500,
      //   closeOnBgClick: false,
      //   items: {
      //     src: '#data-outdated-panel'
      //   },
      //   callbacks: {
      //     beforeOpen: function () {
      //       var Animation = "mfp-with-fade";
      //       this.st.mainClass = Animation;
      //     }
      //   },
      //   midClick: true
      // });
    },
    updateFuture: function updateFuture() {
      this.get('controller').set('futureItems', this.store.find('treatment-future-action', { patientId: this.get('controller').get('model').id, filterTime: 'future' }));
    },
    actions: {
      back: function back() {
        var url = new URL(window.location.href);
        var referer = url.searchParams.get('referer');
        if (referer && referer === 'journal') {
          url.searchParams['delete']('referer');
          window.history.replaceState(null, null, url);
          this.selectTab('activities');
        } else {
          window.history.back();
        }
      },
      openDocument: function openDocument(id) {
        this.selectTab('documents', id);
        var url = new URL(window.location.href);
        url.searchParams.set('selectTab', 'document');
        url.searchParams.set('referer', 'journal');
        window.history.pushState(null, null, url);
      },
      openLab: function openLab(id) {
        this.selectTab('lab');
        var url = new URL(window.location.href);
        url.searchParams.set('selectTab', 'lab');
        url.searchParams.set('referer', 'journal');
        window.history.pushState(null, null, url);
      },
      editFullTreatment: function editFullTreatment(treatmentId) {
        this.transitionTo('practicemgmt.treatment-details', treatmentId, { queryParams: { entry: "true", selectTab: 'now' } });
      },
      editMemo: function editMemo(id) {
        var self = this;
        $.ajax({
          url: "/api/patientMemos/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('patientMemoPanel').send('openEditMemo', data.patientMemo, self.get('controller').get('model').id);
        }, function (error) {});
      },
      reloadMemos: function reloadMemos() {
        var self = this;
        this.findPaged('patient-memo', {
          perPage: 10,
          paged: true,
          patientId: self.get('controller').get('model').get('id')
        }).then(function (entries) {
          self.get('controller').set('memos', entries);
        });
        this.reloadJournal();
      },
      addNewMemo: function addNewMemo() {
        this.get('controller').get('patientMemoPanel').send('openNewMemo', this.get('controller').get('model').id);
      },
      openMergePatient: function openMergePatient() {

        this.get('controller').get('mergePatientPanel').send('load', this.get('controller').get('model'));

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-merge-patient-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      toggleTabFilter: function toggleTabFilter(filter) {
        var controller = this.get('controller');
        if (controller.get(filter) === true) {
          controller.set(filter, false);
        } else {
          controller.set(filter, true);
        }
        this.selectTab('history');
      },
      toggleQuickFilter: function toggleQuickFilter(filter) {
        var controller = this.get('controller');
        if (filter === 'all') {
          controller.set('quickFilter', 'all');
        } else if (filter === 'visible') {
          controller.set('quickFilter', 'visible');
        } else if (filter === 'deleted') {
          controller.set('quickFilter', 'deleted');
        }
        this.selectTab('history');
      },
      openDigitalHelperPanel: function openDigitalHelperPanel(id) {
        var self = this;
        self.get('controller').get('digitalHelperPanel').send('load', id);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-digital-helper-qr-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectCustomerTab: function selectCustomerTab(selected) {
        var controller = this.get('controller');

        if (controller.get('customerTab') !== selected) {
          controller.set('customerTab', selected);
        } else {
          controller.set('customerTab', '');
        }

        controller.set('loading', true);

        var self = this;

        if (selected === 'documents') {
          if (controller.get('customerDocumentsPanel')) {
            controller.get('customerDocumentsPanel').send('loadForCustomer', self, controller.get('model').get('customer').id, controller.get('model').get('customer').emails);
          }
        } else if (selected === 'appointments') {
          this.findPaged('patient-appointment', {
            filterCustomer: controller.get('model').get('customerId'),
            sorting: 'p.date desc',
            maxResults: 5
          }).then(function (entries) {
            controller.set('loading', false);
            controller.set('customerAppointments', entries);
          });
        } else if (selected === 'patients') {
          $.ajax({
            url: "/api/patients/forcustomer/" + controller.get('model').get('customerId'),
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set('loading', false);
            self.get('controller').set('customerPatients', data.patient);
          });
        }
      },
      selectPatientTab: function selectPatientTab(selected) {
        var controller = this.get('controller');

        if (controller.get('patientTab') !== selected) {
          controller.set('patientTab', selected);
        } else {
          controller.set('patientTab', '');
        }

        if (selected === 'xray') {
          this.selectTab('documents', 12);
        } else {
          this.selectTab(selected);
        }
      },
      selectHealthTab: function selectHealthTab(selected) {
        this.selectHealthTab(selected);
      },
      updateDeathDate: function updateDeathDate(data) {
        var self = this;
        $.ajax({
          url: "/api/patients/" + data.id + "/updateDeathDay",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (data.type === 'deleted') {
            self.transitionTo('patients');
          } else {
            self.refresh();
          }
        }, function (error) {
          if (data.type === 'deleted') {
            self.transitionTo('patients');
          } else {
            self.refresh();
          }
        });
      },
      removeDeathDate: function removeDeathDate(data) {
        var self = this;
        $.ajax({
          url: "/api/patients/" + data.id + "/removeDeathDate",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
        }, function (error) {
          self.refresh();
        });
      },
      setDeathDate: function setDeathDate(type) {
        this.get('controller').get('deathDayPanel').send('loadDetails', this.get('controller').get('model'), type);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-set-death-day-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      deleteTodo: function deleteTodo(id) {
        var controller = this.get('controller');
        var self = this;
        $.ajax({
          url: "/api/todos/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (responseData) {
          self.selectTab('todo');
        }, function (error) {
          self.selectTab('todo');
        });
      },
      saveTodoInstance: function saveTodoInstance(data) {
        var self = this;
        var controller = this.get('controller');

        var payload = {
          id: data.id,
          name: data.get('name'),
          dueDateString: data.get('dueDateString'),
          dueDateTime: data.get('dueDateTime')
        };

        $.ajax({
          url: "/api/todos/" + data.id,
          method: "POST",
          data: JSON.stringify(payload),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (responseData) {
          self.selectTab(controller.get('selectTab'));
        });
      },
      editTodo: function editTodo(item) {

        var controller = this.get('controller');

        controller.get('editTodoPanel').send('loadInstance', item);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-todo-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            },
            close: function close() {}
          },
          midClick: true
        });
      },
      selectSearchTag: function selectSearchTag(tag) {
        if (tag.selected === true) {
          Ember['default'].set(tag, 'selected', false);
        } else {
          Ember['default'].set(tag, 'selected', true);
        }
        var self = this;
        setTimeout(function () {
          var tags = new Array();
          $('#tagFilters label.info-badge.selected').each(function () {
            tags.push($(this).attr('data-id'));
          });
          self.get('controller').get('documentsPanel').send('applyFilterWithTags', tags.join(","));
        }, 300);
      },
      showImagePanel: function showImagePanel() {
        this.controller.get('patientEditForm').send('showImagePanel');
      },
      openDigitalForm: function openDigitalForm(entry) {

        var controller = this.get('controller');

        $.ajax({
          url: "/api/treatmentPatients/" + controller.get('model').id + "/digitalForm/" + entry.id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.get('digitalFormPanel').send('load', data.digitalForm, entry.id);
        }, function (error) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-digital-form-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            },
            close: function close() {
              controller.get('digitalFormPanel').send('save');
            }
          },
          midClick: true
        });
      },
      updateDigitalForm: function updateDigitalForm(form, entryId) {},
      gotoReport: function gotoReport(tpId, id) {
        this.transitionTo('editor.edit', tpId, 'treatmentreport', id);
      },
      gotoTemplate: function gotoTemplate(tpId, id) {
        this.transitionTo('editor.edit', id, 'templateinstance', tpId);
      },
      downloadFolder: function downloadFolder() {
        this.get('controller').get('documentsPanel').send('downloadFolder');
      },
      searchDocument: function searchDocument() {
        this.get('controller').get('documentsPanel').send('externalSearch', this.get('controller').get('filterName'));
      },
      savePastAction: function savePastAction(data) {

        var self = this;

        $.ajax({
          url: "/api/patients/pastDosing",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (error) {});
        setTimeout(function () {
          self.get('controller').send('openMedicalProblemsPanel', self.get('controller').get('model').id);
        }, 700);
      },
      openReminderPanel: function openReminderPanel() {
        this.get('controller').get('treatmentFuturePanel').send('openReminderPanel', 'patient');
      },
      openAppointmentPanel: function openAppointmentPanel() {
        if (is_test_mandant.isTestMandant()) {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-add-appointment-panel-new'
            },
            callbacks: {
              close: function close() {}
            }
          });
          this.get('controller').get('addAppointmentPanelNew').send('loadWithCalendar');
        } else {
          this.transitionTo('appointments.calendar');
        }
      },
      refreshPastDosings: function refreshPastDosings(filter, filterDiscontinued, filterApplied) {
        var self = this;

        $.ajax({
          url: "/api/patients/pastDosings/" + self.get('controller').get('model').id + "?filterInfinity=" + filter + "&filterDiscontinued=" + filterDiscontinued + "&filterApplied=" + filterApplied,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('medicalProblemsPanel').send('loadDosings', data.sloping, filter);
        }, function (error) {});
      },
      saveMedicalProblemsInfo: function saveMedicalProblemsInfo(data) {
        $.ajax({
          url: "/api/patients/updateMedicalInfos",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {});
      },
      openRevenue: function openRevenue(id) {

        var customer = this.get('model');

        if (customer) {
          this.get('controller').get('customerRevenuePanel').send('load', id);
        }

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-customer-revenue-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openCustomerReductions: function openCustomerReductions(id) {
        var self = this;
        this.store.fetch('customer', id).then(function (data) {
          self.get('controller').get('setCustomer').send('load', data);
        });
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-customer-reductions-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      reloadWeightData: function reloadWeightData(id, sortDir) {
        this.get('controller').set('weightInfos', this.store.find('patientWeightInfo', { patientId: id, sortDir: sortDir }));
        this.refresh();
      },
      toggleSidebar: function toggleSidebar() {
        var controller = this.get('controller');
        if (controller.get('sidebarActive') === true) {
          controller.set('sidebarActive', false);
        } else {
          controller.set('sidebarActive', true);
          setTimeout(function () {
            $('aside.side-bar div.scrollable').css('max-height', $(window).height() - 375);
          }, 100);
          if ($(window).width() <= 767) {
            $("html, body").animate({ scrollTop: 0 }, 200);
          }
        }
      },
      openLabResult: function openLabResult(id) {

        var self = this;

        $.ajax({
          url: "/api/labResults/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('labResultPanel').send('load', data.labResultFrontend, self.get('controller').get('model'), self.get('controller').get('model').get('customer'));
        }, function (error) {});

        setTimeout(function () {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-lab-result-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 1000);
      },
      reloadTemplate: function reloadTemplate(instanceId, readOnly) {
        var self = this;
        $.ajax({
          url: "/api/templateInstances/" + instanceId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('templateEditPanel').send('load', data.templateInstance, "templateInstances", readOnly, self.get('controller').get('model').id);
        });
      },
      openTemplateEdit: function openTemplateEdit(entryId, instanceId, readOnly) {
        this.transitionTo('editor.edit', instanceId, 'templateinstance', entryId);
      },
      downloadTemplate: function downloadTemplate(id) {
        window.open('/api/templates/' + id + '/download?patientId=' + this.get('controller').get('model').id + '&mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      openDocumentUpload: function openDocumentUpload() {
        this.get('controller').get('documentsPanel').send('openUploadPanel', 'patients', this.get('controller').get('model').id);
      },
      editFuture: function editFuture(treatmentPatientId, id) {
        this.transitionTo('practicemgmt.treatment-future-action', 'patient', treatmentPatientId, id);
      },
      deleteFuture: function deleteFuture(id, entryId) {
        var self = this;
        var deferred = $.ajax({
          url: "/api/treatmentFutureActions/" + entryId,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Maßnahme entfernt', '');
          self.updateFuture();
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      sendEtigaMail: function sendEtigaMail(patient, form) {
        $.ajax({
          url: "/api/patients/" + patient.id + "/sendEtigaCredentials",
          method: "POST",
          data: JSON.stringify(form),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (responseData) {
          jason.notifiction.notifiy('eltiga Tier QR Code', 'erfolgreich verschickt');
        }, function (responseData) {
          jason.notifiction.notifiy('eltiga Tier QR Code', 'erfolgreich verschickt');
        });
      },
      addFutureAction: function addFutureAction(id) {
        var self = this,
            data = {
          referer: "patient"
        };
        $.ajax({
          url: "/api/treatmentFutureActions/" + id,
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (responseData) {
          self.transitionTo('practicemgmt.treatment-future-action', 'patient', id, responseData);
        });
      },
      addDirectAppNotification: function addDirectAppNotification(data) {

        var controller = this.get('controller'),
            self = this;

        $.ajax({
          url: "/api/treatmentPatients/" + controller.get('model').id + "/futureDirectNotification",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('eltiga App Benachrichtigung', 'erfolgreich verschickt');
          if (controller.get('selectTab') === 'activities') {
            controller.get('patientActivities').send('reload');
          }
        }, function () {
          jason.notifiction.systemError('Fehlercode 199', 'Eingaben konnte nicht gespeichert werden');
        });
      },
      selectCustomerConfirm: function selectCustomerConfirm(customerId, data, appointment, time, directTreatment) {
        var self = this,
            treatment = {
          customer: {
            id: customerId
          }
        };

        treatment.selectedPatients = data;
        treatment.appointment = appointment;
        treatment.time = time;

        $.ajax({
          url: "/api/treatments",
          method: "PUT",
          data: JSON.stringify(treatment),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          if (directTreatment) {
            self.transitionTo('practicemgmt.treatment-details', id, { queryParams: { entry: "true" } });
          } else {
            self.transitionTo('practicemgmt.admission');
          }
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      gotoCustomer: function gotoCustomer(id, tab) {
        var self = this;
        if (tab) {
          self.transitionTo('customers.edit', id, { queryParams: { selectTab: tab } });
        } else {
          self.transitionTo('customers.edit', id);
        }
      },
      gotoPatient: function gotoPatient(id) {
        this.transitionTo('patients.edit', id);
      },
      changeLabDate: function changeLabDate(data) {
        var self = this;

        $.ajax({
          url: "/api/labResults/updateDate",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Gespeichert', '');
        }, function (error) {});
      },
      gotoProduct: function gotoProduct(id) {
        this.transitionTo('products.edit', id, { queryParams: { selectTab: "sloping" } });
      },
      gotoService: function gotoService(id) {
        this.transitionTo('services.edit', id);
      },
      saveSlopingInfo: function saveSlopingInfo(data, entryId) {
        var self = this,
            controller = this.get('controller'),
            deferred = $.ajax({
          url: "/api/treatmentOffers/" + controller.get('model').id + "/changeSlopingInfo/" + entryId,
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (error) {});
      },
      reloadHistory: function reloadHistory(showLab, showProduct, showService, showFood) {
        var controller = this.get('controller');
        controller.set('filterLab', showLab);
        controller.set('filterProduct', showProduct);
        controller.set('filterService', showService);
        controller.set('filterFood', showFood);
        this.selectTab('history');
      },
      reloadFuture: function reloadFuture(target) {
        var controller = this.get('controller');
        this.selectTab('future');
      },
      connectToExistingEtigaCustomer: function connectToExistingEtigaCustomer(customerId, patientId) {
        var self = this;
        $.ajax({
          url: "/api/patients/" + this.get('controller').get('model').id + "/connectToEtigaMaster/" + patientId,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Verbunden', '');
          self.refresh();
        }, function (error) {
          jason.notifiction.notifiy('Verbunden', '');
          self.refresh();
        });
      },
      saveLabResult: function saveLabResult(id, comment) {
        var self = this;
        var data = {
          id: id,
          comment: comment
        };

        $.ajax({
          url: "/api/labResults/updateComment",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Gespeichert', '');
        }, function (error) {});
      },
      sendLabResultEmail: function sendLabResultEmail(id, data) {
        var self = this,
            controller = this.get('controller');

        var patientId = controller.get('model').id;
        data.customerId = controller.get('model').get('customerId');

        $.ajax({
          url: "/api/patients/mailto/" + id,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Laborergebnis per Email verschickt', '');
        }, function (error) {});
      },
      changePatientOwner: function changePatientOwner(customer) {
        var controller = this.get('controller');
        var patient = controller.get('model');
        var self = this;

        $.ajax({
          url: "/api/patients/changeOwner/" + patient.id + "/" + customer.id,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (error) {
          var name = customer.firstname;
          if (customer.lastname) {
            name += ' ' + customer.lastname;
          }
          controller.set('customerOverride', name);
          controller.set('customerOverrideId', customer.id);
        });
      },
      changeParamValue: function changeParamValue(id, param, value) {
        var self = this;
        var data = {
          id: param.id,
          formId: id,
          value: String(value).replace(',', '.')
        };

        $.ajax({
          url: "/api/labResults/updateParam",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('labResultPanel').send('update', data.labResultFrontend);
        }, function (error) {});
      },
      disconnectEtiga: function disconnectEtiga(id) {
        var self = this,
            controller = this.get('controller');

        $.ajax({
          url: "/api/patients/" + controller.get('model').get('id') + "/disconnectEtiga",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Etiga', 'erfolgreich getrennt');
          self.refresh();
        }, function () {
          jason.notifiction.notifiy('Etiga', 'erfolgreich getrennt');
          self.refresh();
        });
      },
      downloadResult: function downloadResult(id) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/labResults/downloadSecure/' + API['default'].getMandant() + '/' + data.responseText);
        });
      },
      selectSlopingBarcodeForInvoice: function selectSlopingBarcodeForInvoice(entryId, format) {
        window.open('/api/invoices/downloadSloping/' + entryId + '/default?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      downloadSlopingBarcode: function downloadSlopingBarcode(entryId, format) {
        window.open('/api/treatmentOffers/downloadSloping/' + entryId + '/_logo?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      goto: function goto(to, task) {
        if (to === 'admission') {
          this.transitionTo('practicemgmt.admission');
        } else if (to === 'patienttreatments') {
          this.selectTab('history');
        }
      },

      deleteAppointment: function deleteAppointment(id) {
        var controller = this.get('controller'),
            self = this;

        var deferred = $.ajax({
          url: "/api/patientAppointments/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          self.selectTab('appointments');
        }, function (error) {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          self.selectTab('appointments');
        });
        return Ember['default'].RSVP.resolve(deferred);
      },
      createAppointment: function createAppointment(data) {
        var self = this;
        $.ajax({
          url: "/api/patientAppointments",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          self.selectTab('appointments');
        }, function () {});
      },
      uploadDeclaration: function uploadDeclaration(data) {
        this.refresh();
      },
      selectDocs: function selectDocs() {
        this.selectTab('medias');
      },
      addEtigaPermisison: function addEtigaPermisison(id) {
        var self = this,
            controller = this.get('controller');

        var deferred = $.ajax({
          url: "/api/patients/" + controller.get('model').get('id') + "/" + id + "/addEtigaPermissions",
          method: "PUT",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (error) {
          self.selectTab('etiga');
        });
      },
      removeEtigaShare: function removeEtigaShare(id) {
        var self = this,
            controller = this.get('controller');

        var deferred = $.ajax({
          url: "/api/patients/" + controller.get('model').get('id') + "/" + id + "/etigaPermission",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (error) {
          self.selectTab('etiga');
          jason.notifiction.notifiy('Freigabeeinstellungen', 'entfernt');
        });
      },
      downloadEtigaQr: function downloadEtigaQr() {
        var controller = this.get('controller');
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: controller.get('model').id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/patients/downloadEtigaQr/' + controller.get('model').get('id') + '?mandantId=' + API['default'].getMandant() + '&token=' + data.responseText);
        });
      },
      sendEtigaSave: function sendEtigaSave() {
        jason.notifiction.notifiy('Freigabeeinstellungen', 'gespeichert');
        this.get('controller').get('etigaPanel').send('externalSave');
      },
      sendEtigaBack: function sendEtigaBack() {
        this.get('controller').get('etigaPanel').send('sendBack');
      },
      createNew: function createNew() {
        var self = this,
            deferred = $.ajax({
          url: "/api/patients/" + this.get('controller').get('model').get('id') + "/activateEtiga",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          setTimeout(function () {
            self.selectTab('etiga');
          }, 1000);
          jason.notifiction.notifiy('Datensatz für eltiga aktiviert', '');
        }, function (error) {});
      },
      sendCredentials: function sendCredentials(email) {

        var data = {
          email: email
        };

        var self = this,
            deferred = $.ajax({
          url: "/api/patients/" + this.get('controller').get('model').get('id') + "/sendEtigaCredentials",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          jason.notifiction.notifiy('Zugangsdaten', 'wurden übermittelt');
        }, function (error) {});
      },
      editEtigaPermissions: function editEtigaPermissions(mandantId) {
        var controller = this.get('controller');
        var self = this,
            deferred = $.ajax({
          url: "/api/patients/" + controller.get('model').get('id') + "/" + mandantId + "/etigaPermissions",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.get('etigaPanel').send('load', mandantId, data);
        }, function (error) {});
      },
      forwardToBilling: function forwardToBilling(id) {
        var self = this,
            deferred = $.ajax({
          url: "/api/treatmentOffers/" + id + "/checkout",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.selectTab(self.get('controller').get('selectTab'));
          jason.notifiction.notifiy('An Ausgangsrechnung übergeben', '');
        }, function (error) {});
      },
      sendEmail: function sendEmail(id, data) {
        Ember['default'].$.ajax({
          url: "/api/patients/mailto/" + id,
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'Laborbericht wurde übermittelt');
        }, function () {
          jason.notifiction.notifiy('Erfolgreich', 'Laborbericht wurde übermittelt');
        });
      },
      sendDocumentMail: function sendDocumentMail(id, data) {
        Ember['default'].$.ajax({
          url: "/api/patients/mailtodocument/" + id,
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'Dokument wurde übermittelt');
        }, function () {
          jason.notifiction.notifiy('Erfolgreich', 'Dokument wurde übermittelt');
        });
      },
      reloadLaboklin: function reloadLaboklin(type) {
        var controller = this.get('controller');
        $.ajax({
          url: "/api/patients/" + controller.get('model').get('id') + "/laboklinForms?type=" + type,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('labItems', data.purchaseOrderLaboklinForm);
        });
      },
      roomSaved: function roomSaved(data) {
        $.ajax({
          url: "/api/treatmentPatients/updateRoom",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (result) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      employeesSaved: function employeesSaved(data) {
        $.ajax({
          url: "/api/treatmentPatients/changeEmployees",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      editLab: function editLab(laboklinFormId) {

        var self = this;

        $.ajax({
          url: "/api/treatmentPatients/1/laboklinForms/" + laboklinFormId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('laboklinTreatmentPanel').send('load', self.get('model').id, 0, data, null, null, self.store.find('patient-category'), null, null);
        });

        Ember['default'].run.later(function () {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-form-laboklin-treatment-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 1000);
      },
      submitLaboklin: function submitLaboklin(id, formId) {
        var controller = this.get('controller'),
            self = this;

        var data = {
          formId: formId
        };

        $.ajax({
          url: "/api/treatmentPatients/submitLaboklin",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolg', 'Untersuchungsauftrag wurde erfolgreich übermittelt');

          $.ajax({
            url: "/api/patients/" + controller.get('model').get('id') + "/laboklinForms",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set('labItems', data.purchaseOrderLaboklinForm);
            controller.get('historicItems').send('disableLoading');
            self.downloadPdf(formId);
          });
        }, function () {
          jason.notifiction.systemError('Fehlercode 113', 'Untersuchungsauftrag konnte nicht übermittelt werden');
        });
      },
      downloadLaboklinBarcode: function downloadLaboklinBarcode(formId, format) {
        window.open('/api/treatmentPatients/downloadBarcode/' + formId + '/' + format + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      downloadCsv: function downloadCsv(id) {
        window.open('/api/patients/downloadMasterdata/' + id + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      downloadWeightCsv: function downloadWeightCsv(id) {
        window.open('/api/patients/downloadWeightData/' + id + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      downloadAppointmentCsv: function downloadAppointmentCsv(id) {
        window.open('/api/patients/downloadAppointmentData/' + id + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      downloadHistoryCsv: function downloadHistoryCsv(id) {
        window.open('/api/patients/downloadHistoryData/' + id + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      openWeightPanel: function openWeightPanel() {

        var self = this,
            patientId = this.get('controller').get('model').id;

        this.get('controller').set('weightInfos', this.store.find('patientWeightInfo', { patientId: patientId }));

        $('#highcharts').data('patient-id', patientId);

        this.get('controller').get('weightChart').send('reloadChart');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-weight-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      deleteReport: function deleteReport(formId) {
        var controller = this.get('controller');

        $.ajax({
          url: "/api/treatmentPatients/deleteLaboklin/" + formId,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolg', 'Untersuchungsauftrag wurde entfernt');

          $.ajax({
            url: "/api/patients/" + controller.get('model').get('id') + "/laboklinForms",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set('labItems', data.purchaseOrderLaboklinForm);
          });
        }, function () {
          jason.notifiction.systemError('Fehlercode 111', 'Eingaben konnte nicht gespeichert werden');
        });
      },
      openDataOutdatedPanel: function openDataOutdatedPanel(user) {
        this.openDataOutdatedPanel(user);
      },
      reload: function reload() {
        window.location.reload();
      },
      downloadPdf: function downloadPdf(id) {
        this.downloadPdf(id);
      },
      downloadBarcode: function downloadBarcode(formId) {
        window.open('/api/treatmentPatients/downloadBarcode/' + formId + '/hor?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      downloadMedia: function downloadMedia(id, media) {

        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/treatmentPatients/downloadLaboklin/' + API['default'].getMandant() + '/' + media + '/' + data.responseText);
        });
      },
      downloadVetconnectResult: function downloadVetconnectResult(id) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/labResults/downloadOriginal/' + API['default'].getMandant() + '/' + data.responseText);
        });
      },
      deleteTreatment: function deleteTreatment(id) {
        var controller = this.get('controller'),
            self = this;

        var deferred = $.ajax({
          url: "/api/treatmentPatients/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          controller.set('historicTreatments', self.store.find('treatment-patient', { patientId: controller.get('model').get('id') }));
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      deleteMedia: function deleteMedia(id) {
        var self = this;

        $.ajax({
          url: "/api/media/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.refresh();
          self.reloadMedias();
        });
      },
      changeImage: function changeImage(id) {
        this.controller.set('uploadedAssets', id);
        this.controller.set('patientImage', id);
        $('form#patientEdit').submit();
      },
      saveTreatment: function saveTreatment(data) {
        var controller = this.controller,
            self = this;

        $.ajax({
          url: "/api/patientTreatmentInfos",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          self.selectTab('history');
        }, function () {
          jason.notifiction.systemError('Fehlercode 109', 'Eingaben konnte nicht gespeichert werden');
        });
      },
      deleteWeight: function deleteWeight(weight) {
        var controller = this.controller;
        weight.destroyRecord();
        controller.get('weightChart').send('reloadChart');
      },
      reloadPatient: function reloadPatient() {
        var controller = this.controller;
        controller.get('model').reload();
      },
      saveWeight: function saveWeight(data) {
        var controller = this.controller,
            self = this;
        $.ajax({
          url: "/api/patientWeightInfos",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          controller.set('weightInfos', self.store.find('patientWeightInfo', { patientId: data.patientId }));
          controller.get('weightChart').send('reloadChart');
          controller.get('model').reload();

          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-treatment-weight-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, function () {
          jason.notifiction.systemError('Fehlercode 109', 'Eingaben konnte nicht gespeichert werden');
        });
      }, save: function save() {

        var self = this,
            model = self.controller.get('model');

        var patient = new Object();

        if (model.get('birthdayInput')) {
          patient.birthday = moment['default'](model.get('birthdayInput'), 'DD. MM. YYYY').toDate();
        }
        if (model.get('deathdayInput') && model.get('deathdayInput').length > 0) {
          patient.deathday = moment['default'](model.get('deathdayInput'), 'DD. MM. YYYY').toDate();
        } else {
          patient.deathday = null;
        }

        if (model.get('chipDateInput') && model.get('chipDateInput').length > 0) {
          patient.chipDate = moment['default'](model.get('chipDateInput'), 'DD. MM. YYYY').toDate();
        } else {
          patient.chipDate = null;
        }
        if (model.get('homeanimalNumberDateInput') && model.get('homeanimalNumberDateInput').length > 0) {
          patient.homeanimalNumberDate = moment['default'](model.get('homeanimalNumberDateInput'), 'DD. MM. YYYY').toDate();
        } else {
          patient.homeanimalNumberDate = null;
        }
        if (model.get('soldDateInput') && model.get('soldDateInput').length > 0) {
          patient.soldDate = moment['default'](model.get('soldDateInput'), 'DD. MM. YYYY').toDate();
        } else {
          patient.soldDate = null;
        }

        var image = self.controller.get('uploadedAssets');

        if (image) {
          patient.uploadedPicture = image;
        }

        patient.id = model.get('id');
        patient.giveawayNotice = model.get('giveawayNotice');
        patient.name = model.get('name');
        patient.color = model.get('color');
        patient.race = model.get('race');
        patient.chipId = model.get('chipId');
        patient.foreignId = model.get('foreignId');
        patient.castrated = model.get('castrated');
        patient.transponder = model.get('transponder');
        patient.bornCountry = model.get('bornCountry');
        patient.chipRegistered = model.get('chipRegistered');
        patient.evil = model.get('evil');
        patient.slaughter = model.get('slaughter');
        patient.handoverDocument = model.get('handoverDocument');
        patient.chipLocation = model.get('chipLocation');
        patient.allergies = model.get('allergies');
        patient.horsePassNumber = model.get('horsePassNumber');
        patient.homeanimalNumber = model.get('homeanimalNumber');
        patient.notice = model.get('notice');
        patient.insured = model.get('insured');
        patient.insuranceProtection = model.get('insuranceProtection');
        patient.insuranceNumber = model.get('insuranceNumber');
        patient.horsePass = model.get('horsePass');
        patient.horseBurningMark = model.get('horseBurningMark');
        patient.horseTatoo = model.get('horseTatoo');
        patient.horseLivenumber = model.get('horseLivenumber');
        patient.breedNumber = model.get('breedNumber');
        patient.horseBurningMarkLocation = model.get('horseBurningMarkLocation');
        patient.horseTatooLocation = model.get('horseTatooLocation');
        patient.stableAddress = model.get('stableAddress');
        patient.medicalIncompatibility = model.get('medicalIncompatibility');
        patient.medicalIncompatibilityNotice = model.get('medicalIncompatibilityNotice');
        patient.version = model.get('version');

        if (model.get('category') && model.get('category').get('id')) {
          patient.category = {
            id: model.get('category').get('id')
          };
        }
        if (model.get('insurer') && model.get('insurer').id) {
          patient.insurer = {
            id: model.get('insurer').id
          };
        }
        if (model.get('digitalRace')) {
          patient.digitalRace = {
            id: model.get('digitalRace').id
          };
        }

        if (model.get('customer')) {
          patient.customerId = model.get('customer').get('id');
        }

        if (model.get('gender') && model.get('gender').get('id')) {
          patient.gender = {
            id: model.get('gender').get('id')
          };
        }

        $.ajax({
          url: "/api/patients/" + patient.id,
          method: "PUT",
          data: JSON.stringify(patient),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
        }, function (error) {
          self.openDataOutdatedPanel('einem anderen Benutzer');
        });
      },
      'delete': function _delete() {
        var self = this;

        $.ajax({
          url: "/api/patients/" + self.controller.get('model').id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Patient', 'erfolgreich gelöscht');
          self.transitionTo('patients');
        }, function (error) {
          jason.notifiction.notifiy('Patient', 'erfolgreich gelöscht');
          self.transitionTo('patients');
        });
      },
      selectTab: function selectTab(selected) {
        this.selectTab(selected);
      },
      reloadMedias: function reloadMedias() {
        this.reloadMedias();
      },
      startTask: function startTask(taskData) {
        var self = this,
            controller = this.get('controller');

        var task = {
          id: taskData.id
        };

        $.ajax({
          url: "/api/tasks/start",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(task),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Aufgabe', 'gestartet');
          self.selectTab(controller.get('selectTab'));
        });
      },
      finishTaskSend: function finishTaskSend(taskData) {
        var self = this,
            controller = this.get('controller');

        var action = taskData.finishTaskAction;

        $.ajax({
          url: "/api/tasks/finish",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(taskData),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Aufgabe', 'erledigt');
          self.selectTab(controller.get('selectTab'));
          if (action === 'notbillable') {
            setTimeout(function () {
              controller.send('editTreatment', data, taskData.patient, taskData.task);
            }, 500);
          }
          if (action === 'billable') {
            self.transitionTo('practicemgmt.treatment-details', data, { queryParams: { entry: "true" } });
          }
        }, function (data) {});
      },
      changedTaskOwner: function changedTaskOwner(taskData) {
        var self = this,
            controller = this.get('controller');

        $.ajax({
          url: "/api/tasks/changedTaskOwner",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(taskData),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Aufgabe', 'Verantwortliche(n) geändert');
          self.selectTab(controller.get('selectTab'));
        });
      },
      addReply: function addReply(data) {
        var controller = this.get('controller');
        var self = this;
        $.ajax({
          url: "/api/messageReplies",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Antwort', 'verschickt');
        }, function () {
          jason.notifiction.notifiy('Antwort', 'verschickt');
          $.ajax({
            url: "/api/tasks/" + data.taskId + "/history",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.get('showTaskPanel').send('loadHistory', data.taskHistory);
          });
        });
      },
      openTask: function openTask(taskData) {
        var self = this,
            controller = this.get('controller');

        var task = {
          id: taskData.id
        };

        $.ajax({
          url: "/api/tasks/open",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(task),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Aufgabe', 'geöffnet');
          self.selectTab(controller.get('selectTab'));
        });
      },
      createNotification: function createNotification(data) {
        var self = this,
            controller = this.get('controller');
        $.ajax({
          url: "/api/msgs",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.selectTab(controller.get('selectTab'));
          jason.notifiction.notifiy('Nachricht', 'verschickt');
        });
      },
      create: function create(data) {
        var controller = this.get('controller');
        var self = this;
        $.ajax({
          url: "/api/tasks",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.selectTab(controller.get('selectTab'));
          jason.notifiction.notifiy('Aufgabe', 'erstellt');
        });
      },
      reloadPatients: function reloadPatients(customerId) {
        var self = this;
        $.ajax({
          url: "/api/patients/forcustomer/" + customerId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('addTaskPanel').send('loadPatients', data.patient);
        });
      }
    },
    reloadMedias: function reloadMedias() {
      this.get('controller').set('medias', this.store.find('media', { patientId: this.get('controller').get('model').id }));
    },
    showChart: function showChart() {

      var charts = $('div#highcharts2'),
          patientId = 46429,
          formId = 8401,
          paramId = 'Q3Jl';

      var self = this;

      $.ajax({
        url: "/api/labResults/" + patientId + "/chart/" + formId + "/" + paramId + '?format=desktop',
        method: "GET",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {

        self.set('name', data.labResultChartWrapper.name);

        var csv = data.labResultChartWrapper.bar;

        var lines = csv.split('\n').length;

        $('div#highcharts').show();

        charts.highcharts({

          lang: {
            decimalPoint: ',',
            thousandsSep: '.',
            loading: 'Daten werden geladen...',
            exportButtonTitle: "Exportieren",
            printButtonTitle: "Drucken",
            rangeSelectorFrom: "Von",
            rangeSelectorTo: "Bis",
            rangeSelectorZoom: "Zeitraum",
            downloadPNG: 'Download als PNG-Bild',
            downloadJPEG: 'Download als JPEG-Bild',
            downloadPDF: 'Download als PDF-Dokument',
            downloadSVG: 'Download als SVG-Bild',
            resetZoom: "Zoom zurücksetzen",
            resetZoomTitle: "Zoom zurücksetzen"
          },

          colors: ["#7cb5ec", "#f7a35c", "#90ee7e", "#7798BF", "#aaeeee", "#ff0066", "#eeaaee", "#55BF3B", "#DF5353", "#7798BF", "#aaeeee"],

          data: {
            csv: csv
          },

          title: {
            text: ''
          },
          xAxis: {
            tickWidth: 0,
            gridLineWidth: 1,
            labels: {
              align: 'left',
              x: 3,
              y: -3
            }
          },

          yAxis: [{
            title: {
              text: null
            },
            labels: {
              align: 'left',
              x: 3,
              y: 16,
              format: '{value:.,0f}'
            },
            showFirstLabel: false
          }, {
            linkedTo: 0,
            gridLineWidth: 0,
            opposite: true,
            title: {
              text: 'kg'
            },
            labels: {
              align: 'right',
              x: -3,
              y: 16,
              format: '{value:.,0f}'
            },
            showFirstLabel: false
          }],

          legend: {
            align: 'left',
            verticalAlign: 'top',
            y: 0,
            floating: true,
            borderWidth: 0
          },

          tooltip: {
            shared: true,
            crosshairs: true
          },

          plotOptions: {
            series: {
              cursor: 'pointer',
              marker: {
                lineWidth: 1
              }
            }
          }
        });
      });
    },
    setupController: function setupController(controller, model, params) {

      var self = this;

      controller.set('customerOverride', null);
      controller.set('customerOverrideId', null);
      controller.set('uploadedAssets', null);

      controller.set('filterLab', false);
      controller.set('filterProduct', false);
      controller.set('filterService', false);
      controller.set('filterFood', false);
      controller.set('quickFilter', 'visible');

      controller.set('resources', this.store.find('appointment-resource'));
      controller.set('insurers', this.store.find('insurer'));

      controller.set('patientAppointmentCategories', this.store.find('patient-appointment-category'));

      if (model.get('birthday')) {
        model.set('birthdayInput', moment['default'](model.get('birthday')).format('DD. MM. YYYY'));
      }
      if (model.get('deathday')) {
        model.set('deathdayInput', moment['default'](model.get('deathday')).format('DD. MM. YYYY'));
      }
      if (model.get('chipDate')) {
        model.set('chipDateInput', moment['default'](model.get('chipDate')).format('DD. MM. YYYY'));
      }
      if (model.get('homeanimalNumberDate')) {
        model.set('homeanimalNumberDateInput', moment['default'](model.get('homeanimalNumberDate')).format('DD. MM. YYYY'));
      }
      if (model.get('soldDate')) {
        model.set('soldDateInput', moment['default'](model.get('soldDate')).format('DD. MM. YYYY'));
      }
      this._super(controller, model);
      controller.set('genders', this.store.find('patient-gender'));
      controller.set('categories', this.store.find('patient-category', { includeNull: true }));
      controller.set('customer', model.get('customerName'));
      controller.set('selectedCustomerId', model.get('customerId'));

      controller.set('treatmentInfos', this.findPaged('patientTreatmentInfo', { patientId: model.id }));
      controller.set('weightInfos', this.store.find('patientWeightInfo', { patientId: model.id }));

      controller.set('rooms', this.store.find('treatment-room'));
      controller.set('stations', this.store.find('treatment-station'));

      if (model.get('picture')) {
        controller.set('patientImage', model.get('picture').get('cdnId'));
      } else {
        controller.set('patientImage', null);
      }

      controller.set('doctors', this.store.find('user', { role: 'doctor', per_page: 100 }));
      controller.set('assistants', this.store.find('user', { role: 'assistant', per_page: 100 }));
      controller.set('rooms', this.store.find('treatment-room'));

      var selectedTab = params.queryParams.selectTab;
      var rootFolderId = params.queryParams.rootFolderId;
      if (selectedTab) {

        if (is_journal_enabled.isJournalEnabled() && selectedTab === 'history') {
          selectedTab = 'activities';
        }

        setTimeout(function () {
          self.selectTab(selectedTab, rootFolderId);
        }, 500);
      } else {
        if (is_journal_enabled.isJournalEnabled()) {
          setTimeout(function () {
            self.selectTab('activities', rootFolderId);
          }, 500);
        } else {
          this.selectTab('masterdata', rootFolderId);
        }
      }

      controller.set('customerTab', 'masterdata');
      controller.set('healthTab', 'health');
      controller.set('patientTab', 'masterdata');

      var action = params.queryParams.action;

      controller.set('mediaCategories', this.store.find('media-category'));
      controller.set('users', this.store.find('user', { per_page: 100 }));

      if (model.get('etigaActive')) {
        $.ajax({
          url: "/api/patients/" + controller.get('model').get('id') + "/etigaPermissions",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('etigaPermissions', data);
        });
      }

      $.ajax({
        url: "/api/customerDeposits/" + controller.get('model').get('customer').get('id'),
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('balanceSum', data.meta.sum);
        controller.set('depositSum', data.meta.depositSum);
        controller.set('status', data.meta.status);
        controller.set('depositAvailable', true);
      });

      if (action === 'admission') {
        setTimeout(function () {
          controller.send('admissionPatient', controller.get('model').get('customerId'), controller.get('model').get('id'));
        }, 1000);
      }
    }
  });

});