define('jason-frontend/routes/resources/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('appointment-resource', params.id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('stations', this.store.find('treatment-station'));
      controller.set('rooms', this.store.find('treatment-room'));
      controller.set('equipments', this.store.find('lab-equipment'));
      controller.set('categories', this.store.find('serviceCategory', { suggest: true }));
    },
    actions: {
      save: function save() {
        var self = this,
            model = this.get('controller').get('model');

        var data = {
          id: model.get('id'),
          name: model.get('name')
        };

        if (model.get('treatmentRoom')) {
          data.treatmentRoom = {
            id: model.get('treatmentRoom').id
          };
        }
        if (model.get('treatmentStation')) {
          data.treatmentStation = {
            id: model.get('treatmentStation').id
          };
        }

        var cats = new Array();
        $('form#resourceEditForm input[type=checkbox]:checked').each(function () {
          var cat = {
            id: $(this)[0].id
          };
          cats.push(cat);
        });

        Ember['default'].set(data, 'categories', cats);

        $.ajax({
          url: "/api/appointmentResources",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          self.transitionTo('practice', { queryParams: { selectTab: "resources" } });
        }, function () {});
      }
    }
  });

});