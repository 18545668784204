define('jason-frontend/components/edit-xray-logbook-entry-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    months: null,
    type: null,
    actions: {
      refresh: function refresh() {
        $.magnificPopup.close();
        this.sendAction('refresh');
      },
      confirm: function confirm(id) {
        var data = {
          entryId: this.get('entryId'),
          kv: this.get('item').kv,
          mAmpSec: this.get('item').mAmpSec
        };
        this.sendAction('confirm', data);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(entryId, data, product) {
        this.set('entryId', entryId);
        this.set('item', data);
        this.set('product', product);
      },
      loadNew: function loadNew(entryId, product) {
        this.set('entryId', entryId);
        this.set('product', product);
      }
    }
  });

});