define('jason-frontend/components/button-jason', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    color: '#3B6182',
    strokeColor: '3B6182',
    showStroke: true,
    size: "32",
    side: 'top',
    timer: 0,
    content: '',
    contentAsHTML: true,
    theme: 'tooltipster-punk'
  });

});