define('jason-frontend/models/purchase-dashboard-entry', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    sum: DS['default'].attr('number'),
    saving: DS['default'].attr('number'),
    cashback: DS['default'].attr('number'),
    advantage: DS['default'].attr('number'),
    change: DS['default'].attr('number')
  });

});