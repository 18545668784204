define('jason-frontend/routes/resources/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find('appointment-resource');
    },
    actions: {
      addNew: function addNew() {
        this.transitionTo('resources.create');
      }
    }
  });

});