define('jason-frontend/templates/cash/add-product', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 104,
              "column": 8
            },
            "end": {
              "line": 106,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-product.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","invoice-add-product",[],["linkToEditEnabled",["subexpr","@mut",[["get","linkToEditEnabled",["loc",[null,[105,50],[105,67]]]]],[],[]],"patientId",["subexpr","@mut",[["get","selectedPatient",["loc",[null,[105,78],[105,93]]]]],[],[]],"filterFavorites",["subexpr","@mut",[["get","filterFavorites",["loc",[null,[105,110],[105,125]]]]],[],[]],"product",["subexpr","@mut",[["get","product",["loc",[null,[105,134],[105,141]]]]],[],[]],"invoice",["subexpr","@mut",[["get","invoice",["loc",[null,[105,150],[105,157]]]]],[],[]],"addProduct","addProduct"],["loc",[null,[105,10],[105,183]]]]
        ],
        locals: ["product"],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 116,
              "column": 4
            },
            "end": {
              "line": 118,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","button-prev",[],["content","Zurück zur Ausgangsrechnung"],["loc",[null,[117,6],[117,59]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 142,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/cash/add-product.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","content");
        dom.setAttribute(el1,"class","site-content card add-product add-purchase-product");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","box col l-20/24@tablet l-14/24@desk");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","col col--auto search-box-heading");
        var el5 = dom.createTextNode("Produkt suchen \n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","search");
        dom.setAttribute(el5,"class","search-box");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"class","input search__button");
        dom.setAttribute(el6,"type","submit");
        dom.setAttribute(el6,"title","Suchen");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el7 = dom.createElement("svg");
        dom.setAttribute(el7,"width","26");
        dom.setAttribute(el7,"height","26");
        dom.setAttribute(el7,"viewBox","0 0 26 26");
        dom.setAttribute(el7,"xmlns","http://www.w3.org/2000/svg");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("path");
        dom.setAttribute(el8,"d","M24.53 24.53c-.208.21-.52.21-.73 0l-4.59-4.59c.26-.262.52-.47.73-.73l4.59 4.59c.21.157.21.522 0 .73zm-13.095-2.66C5.695 21.87 1 17.174 1 11.435 1 5.695 5.696 1 11.435 1c5.74 0 10.435 4.696 10.435 10.435 0 5.74-4.696 10.435-10.435 10.435zm0-19.827c-5.165 0-9.392 4.227-9.392 9.392 0 5.165 4.227 9.39 9.392 9.39 5.165 0 9.39-4.225 9.39-9.39 0-5.165-4.225-9.392-9.39-9.392z");
        dom.setAttribute(el8,"stroke","#4A4A4A");
        dom.setAttribute(el8,"fill","#4A4A4A");
        dom.setAttribute(el8,"fill-rule","evenodd");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n\n      ");
        dom.appendChild(el2, el3);
        dom.setNamespace(null);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","box col l-4/24@tablet l-10/24@desk");
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","js-tabs tabs tabs--mobile-block");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","tabs__head");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        dom.setAttribute(el6,"class","u-mb0 clearfix");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"href","#all");
        var el9 = dom.createTextNode("Produkte");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"href","#all");
        var el9 = dom.createTextNode("Leistung");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"href","#all");
        var el9 = dom.createTextNode("Paket");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n               ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"href","#all");
        var el9 = dom.createTextNode("Guthaben");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","filter box");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-3/24@desk");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title title--button");
        var el6 = dom.createTextNode("Filtern nach:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-12/24@tablet l-6/24@desk gutter--small");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-12/24@tablet l-6/24@desk gutter--small");
        var el5 = dom.createTextNode("\n\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n{{#if partnerCategories}}\n            --><div class=\"col l-12/24 l-6/24@desk gutter--small\">\n                 {{select-2\n                    content=categories\n                    value=selectedCategoryFilters\n                    cssClass=\"input--dropdown\"\n                    optionLabelPath=\"name\"\n                    allowClear=true\n                    placeholder=\"Alle Kategorien\"\n                  }}\n            </div><!--\n            {{/if}}            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-12/24 l-6/24@desk gutter--small");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper box no-box@phone ");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table table--small no-shadow");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__head");
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell  l-1/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-4/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell  l-8/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Bezeichnung");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-2/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("VPE");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell  table__cell--center  l-1/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Mwst.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell  table__cell--right  l-2/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("VK Brutto");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-2/24");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-3/24 table__cell--center");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Menge");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-1/24");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__body product-list");
        dom.setAttribute(el4,"id","product-list");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","pagination-wrapper is-fixed");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","action-icons ");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function() {\n        $(document).off('click','.spinner__minus');\n        $(document).on('click','.spinner__minus', function() {\n            var targetId = $(this).data('target'),\n                    target = $('#'+targetId),\n                    oldValue = parseFloat(target.val());\n            target.val(oldValue-1);\n        });\n        $(document).off('click','.spinner__plus');\n        $(document).on('click','.spinner__plus', function() {\n            var targetId = $(this).data('target'),\n                    target = $('#'+targetId),\n                    oldValue = parseFloat(target.val());\n            target.val(oldValue+1);\n        });\n    })\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [3, 1, 1, 1]);
        var element3 = dom.childAt(element2, [1, 1]);
        var element4 = dom.childAt(element2, [3, 1]);
        var element5 = dom.childAt(element2, [5, 1]);
        var element6 = dom.childAt(element2, [7, 1]);
        var element7 = dom.childAt(element1, [5]);
        var morphs = new Array(16);
        morphs[0] = dom.createElementMorph(element1);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1, 1, 1]),3,3);
        morphs[2] = dom.createAttrMorph(element3, 'class');
        morphs[3] = dom.createElementMorph(element3);
        morphs[4] = dom.createAttrMorph(element4, 'class');
        morphs[5] = dom.createElementMorph(element4);
        morphs[6] = dom.createAttrMorph(element5, 'class');
        morphs[7] = dom.createElementMorph(element5);
        morphs[8] = dom.createAttrMorph(element6, 'class');
        morphs[9] = dom.createElementMorph(element6);
        morphs[10] = dom.createMorphAt(dom.childAt(element7, [3]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element7, [5]),1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element7, [7]),1,1);
        morphs[13] = dom.createMorphAt(dom.childAt(element0, [3, 1, 3]),1,1);
        morphs[14] = dom.createMorphAt(dom.childAt(element0, [5]),1,1);
        morphs[15] = dom.createMorphAt(dom.childAt(element0, [7]),1,1);
        return morphs;
      },
      statements: [
        ["element","action",["applyFilter"],["on","submit"],["loc",[null,[2,8],[2,44]]]],
        ["inline","input",[],["name","search","value",["subexpr","@mut",[["get","filterName",["loc",[null,[11,38],[11,48]]]]],[],[]],"class","gui-input input input--search search__input","placeholder","Bezeichnung","autocomplete","off"],["loc",[null,[11,10],[11,147]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[22,84],[22,96]]]],"in"],[],["loc",[null,[22,80],[22,102]]]],"is-active",""],[],["loc",[null,[22,59],[22,119]]]]]]],
        ["element","action",["setType","in"],[],["loc",[null,[22,134],[22,159]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[25,84],[25,96]]]],"out"],[],["loc",[null,[25,80],[25,103]]]],"is-active",""],[],["loc",[null,[25,59],[25,120]]]]]]],
        ["element","action",["setType","out"],[],["loc",[null,[25,136],[25,162]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[28,84],[28,96]]]],"banktransfer"],[],["loc",[null,[28,80],[28,112]]]],"is-active",""],[],["loc",[null,[28,59],[28,129]]]]]]],
        ["element","action",["setType","banktransfer"],[],["loc",[null,[28,144],[28,179]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[31,84],[31,96]]]],"banktransfer"],[],["loc",[null,[31,80],[31,112]]]],"is-active",""],[],["loc",[null,[31,59],[31,129]]]]]]],
        ["element","action",["setType","banktransfer"],[],["loc",[null,[31,144],[31,179]]]],
        ["inline","select-2",[],["value",["subexpr","@mut",[["get","filterManufacturer",["loc",[null,[43,22],[43,40]]]]],[],[]],"optionLabelPath","name","placeholder","Alle Hersteller","allowClear",true,"typeaheadSearchingText","Suche Hersteller","typeaheadNoMatchesText","Keine Hersteller gefunden für '%@'","typeaheadErrorText","Ladevorgang fehlerhaft: %@","minimumInputLength",3,"maximumInputLength",15,"cssClass","input--dropdown","query","queryManufacturer"],["loc",[null,[42,14],[54,16]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","suppliers",["loc",[null,[59,18],[59,27]]]]],[],[]],"value",["subexpr","@mut",[["get","filterSupplier",["loc",[null,[60,16],[60,30]]]]],[],[]],"cssClass","input--dropdown","optionValuePath","id","optionLabelPath","name","allowClear",false,"searchEnabled",false],["loc",[null,[58,10],[66,12]]]],
        ["inline","toggle-favorite-button",[],["showText",true,"fireAction",true,"toggle","toggleFavorites","cssClass","button"],["loc",[null,[82,14],[82,113]]]],
        ["block","each",[["get","model",["loc",[null,[104,16],[104,21]]]]],[],0,null,["loc",[null,[104,8],[106,17]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[112,27],[112,34]]]]],[],[]],"numPagesToShow",7],["loc",[null,[112,4],[112,53]]]],
        ["block","link-to",["invoices.create",["get","invoice.id",["loc",[null,[116,33],[116,43]]]]],["class","icon-button icon-button--success"],1,null,["loc",[null,[116,4],[118,16]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});