define('jason-frontend/templates/mandants/editadmin', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 16
            },
            "end": {
              "line": 47,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","js-tabs__label tabs__label title");
          dom.setAttribute(el2,"href","#tab-sale");
          var el3 = dom.createTextNode("Verkauf");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element33 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element33);
          return morphs;
        },
        statements: [
          ["element","action",["selectTab","sale"],[],["loc",[null,[45,81],[45,110]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 76,
              "column": 22
            },
            "end": {
              "line": 103,
              "column": 22
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-5/24 l-4/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("/");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center  l-4/24 l-4/24@desk");
          dom.setAttribute(el2,"data-label","Typ");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center  l-6/24 l-3/24@desk");
          dom.setAttribute(el2,"data-label","Menge");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center  l-6/24 l-3/24@desk");
          dom.setAttribute(el2,"data-label","Einzelpreis netto");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" EUR");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-9/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Anmerkung");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-2/24@desk buttons-2");
          var el3 = dom.createTextNode("\n                                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--delete list-action-square exp");
          var el4 = dom.createTextNode("\n                                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--default list-action-square exp");
          var el4 = dom.createTextNode("\n                                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createTextNode("Bearbeiten");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element28 = dom.childAt(fragment, [1]);
          var element29 = dom.childAt(element28, [1, 1]);
          var element30 = dom.childAt(element28, [11]);
          var element31 = dom.childAt(element30, [1]);
          var element32 = dom.childAt(element30, [3]);
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(element29,0,0);
          morphs[1] = dom.createMorphAt(element29,2,2);
          morphs[2] = dom.createMorphAt(dom.childAt(element28, [3, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element28, [5, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element28, [7, 1]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element28, [9, 1]),0,0);
          morphs[6] = dom.createMorphAt(element31,1,1);
          morphs[7] = dom.createMorphAt(dom.childAt(element31, [3]),0,0);
          morphs[8] = dom.createElementMorph(element32);
          morphs[9] = dom.createMorphAt(element32,1,1);
          return morphs;
        },
        statements: [
          ["content","fee.month",["loc",[null,[79,40],[79,53]]]],
          ["content","fee.year",["loc",[null,[79,54],[79,66]]]],
          ["content","fee.subType",["loc",[null,[82,40],[82,55]]]],
          ["content","fee.quantity",["loc",[null,[85,40],[85,56]]]],
          ["content","fee.value",["loc",[null,[88,40],[88,53]]]],
          ["content","fee.comment",["loc",[null,[91,40],[91,55]]]],
          ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","fee",["loc",[null,[95,112],[95,115]]]]],[],[]],"confirmed","deleteInvoiceRow","text","Wollen Sie diesen Eintrag wirklich löschen?"],["loc",[null,[95,34],[95,197]]]],
          ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","fee",["loc",[null,[96,65],[96,68]]]]],[],[]],"confirmed","deleteInvoiceRow","textToShow","Löschen","text","Wollen Sie diesen Eintrag wirklich löschen?"],["loc",[null,[96,40],[96,171]]]],
          ["element","action",["openFeePanel",["get","model.id",["loc",[null,[98,61],[98,69]]]],["get","fee.id",["loc",[null,[98,70],[98,76]]]],"invoicerows"],[],["loc",[null,[98,37],[98,92]]]],
          ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[99,36],[99,102]]]]
        ],
        locals: ["fee"],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 123,
              "column": 22
            },
            "end": {
              "line": 140,
              "column": 22
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("/");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Höhe");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" %");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Anmerkung");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-2/24@desk");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","cursor");
          var el4 = dom.createTextNode("\n                                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element25 = dom.childAt(fragment, [1]);
          var element26 = dom.childAt(element25, [1, 1]);
          var element27 = dom.childAt(element25, [7, 1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(element26,0,0);
          morphs[1] = dom.createMorphAt(element26,2,2);
          morphs[2] = dom.createMorphAt(dom.childAt(element25, [3, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element25, [5, 1]),0,0);
          morphs[4] = dom.createElementMorph(element27);
          morphs[5] = dom.createMorphAt(element27,1,1);
          return morphs;
        },
        statements: [
          ["content","fee.month",["loc",[null,[126,40],[126,53]]]],
          ["content","fee.year",["loc",[null,[126,54],[126,66]]]],
          ["content","fee.value",["loc",[null,[129,40],[129,53]]]],
          ["content","fee.comment",["loc",[null,[132,40],[132,55]]]],
          ["element","action",["openFeePanel",["get","model.id",["loc",[null,[135,61],[135,69]]]],["get","fee.id",["loc",[null,[135,70],[135,76]]]],"jasonNetworkFee"],[],["loc",[null,[135,37],[135,96]]]],
          ["inline","button-edit",[],["size","32","color","#3B6182"],["loc",[null,[136,36],[136,77]]]]
        ],
        locals: ["fee"],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 159,
              "column": 22
            },
            "end": {
              "line": 173,
              "column": 22
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Lieferant");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-8/24@desk u-text--right");
          dom.setAttribute(el2,"data-label","Kundengruppe");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-7/24@desk");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","cursor");
          var el4 = dom.createTextNode("\n                                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element23 = dom.childAt(fragment, [1]);
          var element24 = dom.childAt(element23, [5, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element23, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element23, [3, 1]),0,0);
          morphs[2] = dom.createElementMorph(element24);
          morphs[3] = dom.createMorphAt(element24,1,1);
          return morphs;
        },
        statements: [
          ["content","customerGroup.supplier.name",["loc",[null,[162,40],[162,71]]]],
          ["content","customerGroup.group",["loc",[null,[165,40],[165,63]]]],
          ["element","action",["openCustomerGroupsPanel",["get","model.id",["loc",[null,[168,72],[168,80]]]],["get","customerGroup.id",["loc",[null,[168,81],[168,97]]]]],[],["loc",[null,[168,37],[168,99]]]],
          ["inline","button-edit",[],["size","32","color","#3B6182"],["loc",[null,[169,36],[169,77]]]]
        ],
        locals: ["customerGroup"],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 189,
              "column": 22
            },
            "end": {
              "line": 203,
              "column": 22
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Lieferant");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-8/24@desk u-text--right");
          dom.setAttribute(el2,"data-label","Cashback in %");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" %");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-7/24@desk");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","cursor");
          var el4 = dom.createTextNode("\n                                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [1]);
          var element22 = dom.childAt(element21, [5, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element21, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element21, [3, 1]),0,0);
          morphs[2] = dom.createElementMorph(element22);
          morphs[3] = dom.createMorphAt(element22,1,1);
          return morphs;
        },
        statements: [
          ["content","cashback.supplier.name",["loc",[null,[192,40],[192,66]]]],
          ["content","cashback.cashback",["loc",[null,[195,40],[195,61]]]],
          ["element","action",["openCashbackPanel",["get","model.id",["loc",[null,[198,66],[198,74]]]],["get","cashback.id",["loc",[null,[198,75],[198,86]]]]],[],["loc",[null,[198,37],[198,88]]]],
          ["inline","button-edit",[],["size","32","color","#3B6182"],["loc",[null,[199,36],[199,77]]]]
        ],
        locals: ["cashback"],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 219,
              "column": 22
            },
            "end": {
              "line": 236,
              "column": 22
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("/");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-2/24@desk");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","cursor");
          var el4 = dom.createTextNode("\n                                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element18 = dom.childAt(fragment, [1]);
          var element19 = dom.childAt(element18, [1, 1]);
          var element20 = dom.childAt(element18, [7, 1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(element19,0,0);
          morphs[1] = dom.createMorphAt(element19,2,2);
          morphs[2] = dom.createMorphAt(dom.childAt(element18, [3, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element18, [5, 1]),0,0);
          morphs[4] = dom.createElementMorph(element20);
          morphs[5] = dom.createMorphAt(element20,1,1);
          return morphs;
        },
        statements: [
          ["content","fee.month",["loc",[null,[222,40],[222,53]]]],
          ["content","fee.year",["loc",[null,[222,54],[222,66]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","fee.value",["loc",[null,[225,64],[225,73]]]]],[],[]]],["loc",[null,[225,40],[225,75]]]],
          ["content","fee.comment",["loc",[null,[228,40],[228,55]]]],
          ["element","action",["openFeePanel",["get","model.id",["loc",[null,[231,61],[231,69]]]],["get","fee.id",["loc",[null,[231,70],[231,76]]]],"fee"],[],["loc",[null,[231,37],[231,84]]]],
          ["inline","button-edit",[],["size","32","color","#3B6182"],["loc",[null,[232,36],[232,77]]]]
        ],
        locals: ["fee"],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 256,
              "column": 22
            },
            "end": {
              "line": 273,
              "column": 22
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("/");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-2/24@desk");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","cursor");
          var el4 = dom.createTextNode("\n                                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element15 = dom.childAt(fragment, [1]);
          var element16 = dom.childAt(element15, [1, 1]);
          var element17 = dom.childAt(element15, [7, 1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(element16,0,0);
          morphs[1] = dom.createMorphAt(element16,2,2);
          morphs[2] = dom.createMorphAt(dom.childAt(element15, [3, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element15, [5, 1]),0,0);
          morphs[4] = dom.createElementMorph(element17);
          morphs[5] = dom.createMorphAt(element17,1,1);
          return morphs;
        },
        statements: [
          ["content","fee.month",["loc",[null,[259,40],[259,53]]]],
          ["content","fee.year",["loc",[null,[259,54],[259,66]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","fee.value",["loc",[null,[262,64],[262,73]]]]],[],[]]],["loc",[null,[262,40],[262,75]]]],
          ["content","fee.comment",["loc",[null,[265,40],[265,55]]]],
          ["element","action",["openFeePanel",["get","model.id",["loc",[null,[268,61],[268,69]]]],["get","fee.id",["loc",[null,[268,70],[268,76]]]],"simFee"],[],["loc",[null,[268,37],[268,87]]]],
          ["inline","button-edit",[],["size","32","color","#3B6182"],["loc",[null,[269,36],[269,77]]]]
        ],
        locals: ["fee"],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 436,
              "column": 14
            },
            "end": {
              "line": 438,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[437,16],[437,37]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 454,
                "column": 36
              },
              "end": {
                "line": 454,
                "column": 186
              }
            },
            "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","label label-success");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" am ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","invoice.submitStatus",["loc",[null,[454,95],[454,119]]]],
            ["inline","time-format",[["get","invoice.submittedAt",["loc",[null,[454,144],[454,163]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[454,130],[454,186]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 454,
                  "column": 194
                },
                "end": {
                  "line": 454,
                  "column": 286
                }
              },
              "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","label label-danger");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
              return morphs;
            },
            statements: [
              ["content","invoice.submitStatus",["loc",[null,[454,255],[454,279]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 454,
                  "column": 286
                },
                "end": {
                  "line": 454,
                  "column": 356
                }
              },
              "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","label label-warning");
              var el2 = dom.createTextNode("Noch nicht verschickt");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 454,
                "column": 186
              },
              "end": {
                "line": 454,
                "column": 363
              }
            },
            "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","invoice.submitStatus",["loc",[null,[454,200],[454,220]]]]],[],0,1,["loc",[null,[454,194],[454,363]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 457,
                "column": 28
              },
              "end": {
                "line": 461,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","input input--action");
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element11);
            morphs[1] = dom.createMorphAt(element11,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["sendInvoice",["get","model.id",["loc",[null,[458,84],[458,92]]]],["get","invoice.month",["loc",[null,[458,93],[458,106]]]],["get","invoice.year",["loc",[null,[458,107],[458,119]]]]],[],["loc",[null,[458,61],[458,121]]]],
            ["inline","button-mail",[],["size","50","color","#3B6182","showStroke",true,"content","Rechnung per Email an Mandant verschicken"],["loc",[null,[459,32],[459,141]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 462,
                "column": 28
              },
              "end": {
                "line": 466,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","input input--action");
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element10);
            morphs[1] = dom.createMorphAt(element10,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["downloadInvoice",["get","model.id",["loc",[null,[463,92],[463,100]]]],["get","invoice.month",["loc",[null,[463,101],[463,114]]]],["get","invoice.year",["loc",[null,[463,115],[463,127]]]]],[],["loc",[null,[463,65],[463,129]]]],
            ["inline","button-download-list",[],["size","50","color","#3B6182","content","Rechnung herunterladen"],["loc",[null,[464,36],[464,119]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 466,
                "column": 28
              },
              "end": {
                "line": 470,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","input input--action");
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element9);
            morphs[1] = dom.createMorphAt(element9,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["openInvoiceTextPanel",["get","model.id",["loc",[null,[467,93],[467,101]]]],["get","invoice.month",["loc",[null,[467,102],[467,115]]]],["get","invoice.year",["loc",[null,[467,116],[467,128]]]]],[],["loc",[null,[467,61],[467,130]]]],
            ["inline","button-edit",[],["size","48","color","#3B6182","content","Rechnungstext bearbeiten"],["loc",[null,[468,32],[468,108]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 448,
              "column": 22
            },
            "end": {
              "line": 473,
              "column": 22
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-10/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("/");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                               ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-9/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-5/24@desk table__cell--right");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element12 = dom.childAt(fragment, [1]);
          var element13 = dom.childAt(element12, [1, 1]);
          var element14 = dom.childAt(element12, [5]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(element13,0,0);
          morphs[1] = dom.createMorphAt(element13,2,2);
          morphs[2] = dom.createMorphAt(dom.childAt(element12, [3, 1]),0,0);
          morphs[3] = dom.createMorphAt(element14,1,1);
          morphs[4] = dom.createMorphAt(element14,2,2);
          return morphs;
        },
        statements: [
          ["content","invoice.publicMonth",["loc",[null,[451,40],[451,63]]]],
          ["content","invoice.publicYear",["loc",[null,[451,64],[451,86]]]],
          ["block","if",[["get","invoice.submitted",["loc",[null,[454,42],[454,59]]]]],[],0,1,["loc",[null,[454,36],[454,370]]]],
          ["block","if",[["subexpr","and",[["get","invoice.available",["loc",[null,[457,39],[457,56]]]],["subexpr","not",[["get","showLoading",["loc",[null,[457,62],[457,73]]]]],[],["loc",[null,[457,57],[457,74]]]]],[],["loc",[null,[457,34],[457,75]]]]],[],2,null,["loc",[null,[457,28],[461,35]]]],
          ["block","if",[["get","invoice.available",["loc",[null,[462,34],[462,51]]]]],[],3,4,["loc",[null,[462,28],[470,35]]]]
        ],
        locals: ["invoice"],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 489,
              "column": 22
            },
            "end": {
              "line": 501,
              "column": 22
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Benutzer");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Email");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24 l-7/24@desk u-text--right");
          dom.setAttribute(el2,"data-label","Letzter Login");
          var el3 = dom.createTextNode("\n                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var element8 = dom.childAt(element7, [1, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element8,0,0);
          morphs[1] = dom.createMorphAt(element8,2,2);
          morphs[2] = dom.createMorphAt(dom.childAt(element7, [3, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element7, [5, 1]),0,0);
          return morphs;
        },
        statements: [
          ["content","user.firstname",["loc",[null,[492,40],[492,58]]]],
          ["content","user.lastname",["loc",[null,[492,59],[492,76]]]],
          ["content","user.email",["loc",[null,[495,40],[495,54]]]],
          ["inline","time-format",[["get","user.lastLogin",["loc",[null,[498,54],[498,68]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[498,40],[498,91]]]]
        ],
        locals: ["user"],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 528,
              "column": 6
            },
            "end": {
              "line": 532,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createMorphAt(element6,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["saveInvoiceSettings"],[],["loc",[null,[529,11],[529,43]]]],
          ["inline","button-save",[],["color","#ffffff","size","50","content","Speichern"],["loc",[null,[530,10],[530,71]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 533,
              "column": 6
            },
            "end": {
              "line": 537,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element5);
          morphs[1] = dom.createMorphAt(element5,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["saveUsers"],[],["loc",[null,[534,11],[534,33]]]],
          ["inline","button-save",[],["color","#ffffff","size","50","content","Speichern"],["loc",[null,[535,10],[535,71]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 538,
              "column": 6
            },
            "end": {
              "line": 542,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element4);
          morphs[1] = dom.createMorphAt(element4,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["savePurchaseSettings"],[],["loc",[null,[539,11],[539,44]]]],
          ["inline","button-save",[],["color","#ffffff","size","50","content","Speichern"],["loc",[null,[540,10],[540,71]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 543,
              "column": 6
            },
            "end": {
              "line": 547,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element3);
          morphs[1] = dom.createMorphAt(element3,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["saveSaleSettings"],[],["loc",[null,[544,11],[544,40]]]],
          ["inline","button-save",[],["color","#ffffff","size","50","content","Speichern"],["loc",[null,[545,10],[545,71]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 548,
              "column": 6
            },
            "end": {
              "line": 552,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createMorphAt(element2,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openFeePanel",["get","model.id",["loc",[null,[549,37],[549,45]]]],0,["get","selectTab",["loc",[null,[549,48],[549,57]]]]],[],["loc",[null,[549,13],[549,59]]]],
          ["inline","button-add-entry",[],["content","Neuen Eintrag erstellen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[550,12],[550,109]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 553,
              "column": 6
            },
            "end": {
              "line": 557,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openCashbackPanel",["get","model.id",["loc",[null,[554,42],[554,50]]]],0],[],["loc",[null,[554,13],[554,54]]]],
          ["inline","button-add-entry",[],["content","Neuen Eintrag erstellen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[555,12],[555,109]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 558,
              "column": 6
            },
            "end": {
              "line": 562,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openCustomerGroupsPanel",["get","model.id",["loc",[null,[559,48],[559,56]]]],0],[],["loc",[null,[559,13],[559,60]]]],
          ["inline","button-add-entry",[],["content","Neuen Eintrag erstellen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[560,12],[560,109]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 570,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/mandants/editadmin.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","card site-content animated fadeIn");
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        dom.setAttribute(el2,"class","mb10");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tabs__head");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"class","u-mb0 clearfix");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title is-active");
        dom.setAttribute(el6,"href","#tab-licences");
        var el7 = dom.createTextNode("Tarif");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-networkfee");
        var el7 = dom.createTextNode("Jason Network Fee");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-customergroups");
        var el7 = dom.createTextNode("Kundengruppen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-cashback");
        var el7 = dom.createTextNode("Cashback");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-fee");
        var el7 = dom.createTextNode("Mitgliedsgebühren");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-users");
        var el7 = dom.createTextNode("Benutzer");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-sim-fee");
        var el7 = dom.createTextNode("Sim-Karten\n                        Gebühren");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-invoices");
        var el7 = dom.createTextNode("Abrechnungen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-invoicesettings");
        var el7 = dom.createTextNode("Abrechnung-Einstellungen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-invoicerows");
        var el7 = dom.createTextNode("Dauerzeilen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-purchase");
        var el7 = dom.createTextNode("Einkauf");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-system");
        var el7 = dom.createTextNode("System");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","tabs__body mt20");
        var el4 = dom.createTextNode("\n\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","tabs__content is-active");
        dom.setAttribute(el4,"data-for","#tab-licences");
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb20");
        var el6 = dom.createTextNode("Aktueller Tarif");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","label label-jason");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","tabs__content");
        dom.setAttribute(el4,"data-for","#tab-invoicerows");
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb20");
        var el6 = dom.createTextNode("Rechnungs-Dauerzeilen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table grid table--large");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Monat/Jahr");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell table__cell--center  l-4/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Typ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell table__cell--center  l-3/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Menge");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell table__cell--center l-3/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Einzelpreis netto");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell table__cell--center l-8/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Anmerkung");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-1/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","mt10 is-highlighted");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Hinweis:");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" Jede Dauerzeile wird automatisch verlängert, wenn es im Vormonat einen entsprechenden Eintrag gibt\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","tabs__content");
        dom.setAttribute(el4,"data-for","#tab-networkfee");
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb20");
        var el6 = dom.createTextNode("Jason Network Fee");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-8/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Monat/Jahr");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-6/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Jason Network Fee in %");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-8/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Anmerkung");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-1/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","mt10 is-highlighted");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Hinweis:");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" Die Höhe der Jason Network Fee wird monatlich automatisch um die Höhe des Vormonats\n                    verlängert\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","tabs__content");
        dom.setAttribute(el4,"data-for","#tab-customergroups");
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb20");
        var el6 = dom.createTextNode("Kundengruppen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-8/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Lieferant");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-8/24 u-text--right");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Kundengruppe");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-7/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","tabs__content");
        dom.setAttribute(el4,"data-for","#tab-cashback");
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb20");
        var el6 = dom.createTextNode("Cashback");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-8/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Lieferant");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-8/24 u-text--right");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Cashback in %");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-7/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","tabs__content");
        dom.setAttribute(el4,"data-for","#tab-fee");
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb20");
        var el6 = dom.createTextNode("Mitgliedsgebühren");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-8/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Monat/Jahr");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-6/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Betrag");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-8/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Anmerkung");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-1/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","mt30 is-highlighted");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Hinweis:");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" Die Mitgliedsgebühr wird monatlich automatisch um die Höhe des Mitgliedsbeitrages\n                    des Vormonats verlängert\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","tabs__content");
        dom.setAttribute(el4,"data-for","#tab-sim-fee");
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb20");
        var el6 = dom.createTextNode("SIM-Karten Gebühren");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-8/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Monat/Jahr");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-6/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Betrag");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-8/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Anmerkung");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-1/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","mt30 is-highlighted");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Hinweis:");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" Die SIM-Karten Gebühr wird monatlich automatisch um die Höhe der Vormonatsgebühr\n                    verlängert\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","tabs__content admin-form");
        dom.setAttribute(el4,"data-for","#tab-users");
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb20");
        var el6 = dom.createTextNode("Benutzer Einstellungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","grid");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-maxUsers");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Max Anzahl Benutzer");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","tabs__content admin-form");
        dom.setAttribute(el4,"data-for","#tab-invoicesettings");
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb20");
        var el6 = dom.createTextNode("Abrechnung Einstellungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","grid");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-invoiceCashbackReduction");
        dom.setAttribute(el8,"class","field-label title");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","mandant-invoiceCashbackReduction");
        var el10 = dom.createTextNode("Cashback Rabatt auf Rechnung ausweisen");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-invoiceCashback");
        dom.setAttribute(el8,"class","field-label title");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","mandant-invoiceCashback");
        var el10 = dom.createTextNode("Cashback Prämie auf Rechnung ausweisen");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-invoiceTransactionFee");
        dom.setAttribute(el8,"class","field-label title");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","mandant-invoiceTransactionFee");
        var el10 = dom.createTextNode("Jason Network Fee auf Rechnung ausweisen");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-invoiceSimFee");
        dom.setAttribute(el8,"class","field-label title");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","mandant-invoiceSimFee");
        var el10 = dom.createTextNode("Sim Fee auf Rechnung ausweisen");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-invoiceCashbackDetails");
        dom.setAttribute(el8,"class","field-label title");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","mandant-invoiceCashbackDetails");
        var el10 = dom.createTextNode("Cashback Abrechnung auf Rechnung ausweisen");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-showUid");
        dom.setAttribute(el8,"class","field-label title");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","mandant-showUid");
        var el10 = dom.createTextNode("Umsatzsteuer auf Rechnung ausweisen");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-autosend");
        dom.setAttribute(el8,"class","field-label title");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","mandant-autosend");
        var el10 = dom.createTextNode("Rechnungen automatisch per E-Mail versenden");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-invoiceEmail");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Empfänger-Email Adresse");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-taxNumber");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("UID");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-taxCode");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Steuernummer");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","tabs__content admin-form");
        dom.setAttribute(el4,"data-for","#tab-sale");
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb20");
        var el6 = dom.createTextNode("Einkauf Einstellungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 gutter");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","mandant-barsoi");
        dom.setAttribute(el6,"class","field-label title");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","checkbox-custom checkbox-primary field-checkbox");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","mandant-barsoi");
        var el8 = dom.createTextNode("BARSOI Liste aktiv");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","tabs__content admin-form");
        dom.setAttribute(el4,"data-for","#tab-purchase");
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb20");
        var el6 = dom.createTextNode("Datacare Einstellungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 gutter");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","mandant-idfNumber");
        dom.setAttribute(el7,"class","field-label title");
        var el8 = dom.createTextNode("IDF Hausapotheke");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 gutter pt30");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 gutter");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","mandant-idfNumberPrivate");
        dom.setAttribute(el7,"class","field-label title");
        var el8 = dom.createTextNode("IDF Privatbestellungen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 gutter pt30");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 gutter");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","mandant-idfNumberDrug");
        dom.setAttribute(el7,"class","field-label title");
        var el8 = dom.createTextNode("IDF Suchtgiftmittelbestellungen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 gutter pt30");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","tabs__content");
        dom.setAttribute(el4,"data-for","#tab-invoices");
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb20");
        var el6 = dom.createTextNode("Abrechnungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-10/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Monat/Jahr");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-9/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Status");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n                              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-5/24 table__cell--right");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"class","tabs__content");
        dom.setAttribute(el4,"data-for","#tab-system");
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb20");
        var el6 = dom.createTextNode("Benutzerstatistik");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-8/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Benutzer");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-8/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Email");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-7/24 u-text--right");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Letzter Login");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb20");
        var el6 = dom.createTextNode("Einkäufe");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        dom.setAttribute(el5,"id","purchaseEmailForm");
        dom.setAttribute(el5,"class","mt10");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","purchase-email");
        dom.setAttribute(el7,"class","field-label title required");
        var el8 = dom.createTextNode("Email-Adresse für\n                            Bestellungen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","action-icons");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","icon-button icon-button--small");
        dom.setAttribute(el3,"onclick","history.back()");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element34 = dom.childAt(fragment, [0]);
        var element35 = dom.childAt(element34, [5]);
        var element36 = dom.childAt(element35, [1, 1]);
        var element37 = dom.childAt(element36, [1, 1]);
        var element38 = dom.childAt(element36, [3, 1]);
        var element39 = dom.childAt(element36, [5, 1]);
        var element40 = dom.childAt(element36, [7, 1]);
        var element41 = dom.childAt(element36, [9, 1]);
        var element42 = dom.childAt(element36, [11, 1]);
        var element43 = dom.childAt(element36, [13, 1]);
        var element44 = dom.childAt(element36, [15, 1]);
        var element45 = dom.childAt(element36, [17, 1]);
        var element46 = dom.childAt(element36, [19, 1]);
        var element47 = dom.childAt(element36, [21, 1]);
        var element48 = dom.childAt(element36, [25, 1]);
        var element49 = dom.childAt(element35, [3]);
        var element50 = dom.childAt(element49, [17, 3]);
        var element51 = dom.childAt(element49, [21]);
        var element52 = dom.childAt(element49, [23]);
        var element53 = dom.childAt(element49, [25]);
        var element54 = dom.childAt(element34, [15]);
        var morphs = new Array(56);
        morphs[0] = dom.createMorphAt(dom.childAt(element34, [1]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element34, [3]),0,0);
        morphs[2] = dom.createElementMorph(element37);
        morphs[3] = dom.createElementMorph(element38);
        morphs[4] = dom.createElementMorph(element39);
        morphs[5] = dom.createElementMorph(element40);
        morphs[6] = dom.createElementMorph(element41);
        morphs[7] = dom.createElementMorph(element42);
        morphs[8] = dom.createElementMorph(element43);
        morphs[9] = dom.createElementMorph(element44);
        morphs[10] = dom.createElementMorph(element45);
        morphs[11] = dom.createElementMorph(element46);
        morphs[12] = dom.createElementMorph(element47);
        morphs[13] = dom.createMorphAt(element36,23,23);
        morphs[14] = dom.createElementMorph(element48);
        morphs[15] = dom.createMorphAt(dom.childAt(element49, [1, 3, 1]),0,0);
        morphs[16] = dom.createMorphAt(dom.childAt(element49, [3, 3, 3]),1,1);
        morphs[17] = dom.createMorphAt(dom.childAt(element49, [5, 3, 3]),1,1);
        morphs[18] = dom.createMorphAt(dom.childAt(element49, [7, 3, 3]),1,1);
        morphs[19] = dom.createMorphAt(dom.childAt(element49, [9, 3, 3]),1,1);
        morphs[20] = dom.createMorphAt(dom.childAt(element49, [11, 3, 3]),1,1);
        morphs[21] = dom.createMorphAt(dom.childAt(element49, [13, 3, 3]),1,1);
        morphs[22] = dom.createMorphAt(dom.childAt(element49, [15, 3, 1, 1]),3,3);
        morphs[23] = dom.createMorphAt(dom.childAt(element50, [1, 1, 3]),1,1);
        morphs[24] = dom.createMorphAt(dom.childAt(element50, [3, 1, 3]),1,1);
        morphs[25] = dom.createMorphAt(dom.childAt(element50, [5, 1, 3]),1,1);
        morphs[26] = dom.createMorphAt(dom.childAt(element50, [7, 1, 3]),1,1);
        morphs[27] = dom.createMorphAt(dom.childAt(element50, [9, 1, 3]),1,1);
        morphs[28] = dom.createMorphAt(dom.childAt(element50, [11, 1, 3]),1,1);
        morphs[29] = dom.createMorphAt(dom.childAt(element50, [13, 1, 3]),1,1);
        morphs[30] = dom.createMorphAt(dom.childAt(element50, [15, 1]),3,3);
        morphs[31] = dom.createMorphAt(dom.childAt(element50, [17, 1]),3,3);
        morphs[32] = dom.createMorphAt(dom.childAt(element50, [19, 1]),3,3);
        morphs[33] = dom.createMorphAt(dom.childAt(element49, [19, 3, 3]),1,1);
        morphs[34] = dom.createMorphAt(dom.childAt(element51, [3, 1]),3,3);
        morphs[35] = dom.createMorphAt(dom.childAt(element51, [5, 1]),1,1);
        morphs[36] = dom.createMorphAt(dom.childAt(element51, [7, 1]),3,3);
        morphs[37] = dom.createMorphAt(dom.childAt(element51, [9, 1]),1,1);
        morphs[38] = dom.createMorphAt(dom.childAt(element51, [11, 1]),3,3);
        morphs[39] = dom.createMorphAt(dom.childAt(element51, [13, 1]),1,1);
        morphs[40] = dom.createMorphAt(element52,3,3);
        morphs[41] = dom.createMorphAt(dom.childAt(element52, [5, 3]),1,1);
        morphs[42] = dom.createMorphAt(dom.childAt(element53, [3, 3]),1,1);
        morphs[43] = dom.createMorphAt(dom.childAt(element53, [7, 1]),3,3);
        morphs[44] = dom.createMorphAt(element34,7,7);
        morphs[45] = dom.createMorphAt(element34,9,9);
        morphs[46] = dom.createMorphAt(element34,11,11);
        morphs[47] = dom.createMorphAt(element34,13,13);
        morphs[48] = dom.createMorphAt(dom.childAt(element54, [1]),1,1);
        morphs[49] = dom.createMorphAt(element54,3,3);
        morphs[50] = dom.createMorphAt(element54,4,4);
        morphs[51] = dom.createMorphAt(element54,5,5);
        morphs[52] = dom.createMorphAt(element54,6,6);
        morphs[53] = dom.createMorphAt(element54,7,7);
        morphs[54] = dom.createMorphAt(element54,8,8);
        morphs[55] = dom.createMorphAt(element54,9,9);
        return morphs;
      },
      statements: [
        ["content","model.name",["loc",[null,[3,21],[3,35]]]],
        ["content","model.operators",["loc",[null,[4,8],[4,27]]]],
        ["element","action",["selectTab","licences"],[],["loc",[null,[10,95],[10,128]]]],
        ["element","action",["selectTab","jasonNetworkFee"],[],["loc",[null,[13,87],[13,127]]]],
        ["element","action",["selectTab","customerGroups"],[],["loc",[null,[16,91],[16,130]]]],
        ["element","action",["selectTab","cashback"],[],["loc",[null,[19,85],[19,118]]]],
        ["element","action",["selectTab","fee"],[],["loc",[null,[22,80],[22,108]]]],
        ["element","action",["selectTab","users"],[],["loc",[null,[25,82],[25,112]]]],
        ["element","action",["selectTab","simFee"],[],["loc",[null,[28,84],[28,115]]]],
        ["element","action",["selectTab","invoices"],[],["loc",[null,[32,85],[32,118]]]],
        ["element","action",["selectTab","invoicesettings"],[],["loc",[null,[35,88],[35,128]]]],
        ["element","action",["selectTab","invoicerows"],[],["loc",[null,[38,86],[38,122]]]],
        ["element","action",["selectTab","purchase"],[],["loc",[null,[41,83],[41,116]]]],
        ["block","if",[["subexpr","is-operating-country",["DE"],[],["loc",[null,[43,22],[43,49]]]]],[],0,null,["loc",[null,[43,16],[47,23]]]],
        ["element","action",["selectTab","system",["get","model.id",["loc",[null,[49,113],[49,121]]]]],[],["loc",[null,[49,83],[49,123]]]],
        ["content","model.mandantType",["loc",[null,[59,50],[59,71]]]],
        ["block","each",[["get","invoiceRows",["loc",[null,[76,30],[76,41]]]]],[],1,null,["loc",[null,[76,22],[103,31]]]],
        ["block","each",[["get","jasonNetworkFees",["loc",[null,[123,30],[123,46]]]]],[],2,null,["loc",[null,[123,22],[140,31]]]],
        ["block","each",[["get","customerGroups",["loc",[null,[159,30],[159,44]]]]],[],3,null,["loc",[null,[159,22],[173,31]]]],
        ["block","each",[["get","cashbacks",["loc",[null,[189,30],[189,39]]]]],[],4,null,["loc",[null,[189,22],[203,31]]]],
        ["block","each",[["get","fees",["loc",[null,[219,30],[219,34]]]]],[],5,null,["loc",[null,[219,22],[236,31]]]],
        ["block","each",[["get","simFees",["loc",[null,[256,30],[256,37]]]]],[],6,null,["loc",[null,[256,22],[273,31]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.maxUsers",["loc",[null,[289,32],[289,46]]]]],[],[]],"class","gui-input newStyle","name","mandant-maxUsers"],["loc",[null,[289,18],[289,99]]]],
        ["inline","input",[],["type","checkbox","name","mandant.invoiceCashbackReduction","id","mandant-invoiceCashbackReduction","checked",["subexpr","@mut",[["get","model.invoiceCashbackReduction",["loc",[null,[303,132],[303,162]]]]],[],[]]],["loc",[null,[303,22],[303,164]]]],
        ["inline","input",[],["type","checkbox","name","mandant.invoiceCashback","id","mandant-invoiceCashback","checked",["subexpr","@mut",[["get","model.invoiceCashback",["loc",[null,[312,114],[312,135]]]]],[],[]]],["loc",[null,[312,22],[312,137]]]],
        ["inline","input",[],["type","checkbox","name","mandant.invoiceTransactionFee","id","mandant-invoiceTransactionFee","checked",["subexpr","@mut",[["get","model.invoiceTransactionFee",["loc",[null,[321,126],[321,153]]]]],[],[]]],["loc",[null,[321,22],[321,155]]]],
        ["inline","input",[],["type","checkbox","name","mandant.invoiceSimFee","id","mandant-invoiceSimFee","checked",["subexpr","@mut",[["get","model.invoiceSimFee",["loc",[null,[330,110],[330,129]]]]],[],[]]],["loc",[null,[330,22],[330,131]]]],
        ["inline","input",[],["type","checkbox","name","mandant.invoiceCashbackDetails","id","mandant-invoiceCashbackDetails","checked",["subexpr","@mut",[["get","model.invoiceCashbackDetails",["loc",[null,[339,128],[339,156]]]]],[],[]]],["loc",[null,[339,22],[339,158]]]],
        ["inline","input",[],["type","checkbox","name","mandant.showUid","id","mandant-showUid","checked",["subexpr","@mut",[["get","model.showUid",["loc",[null,[348,98],[348,111]]]]],[],[]]],["loc",[null,[348,22],[348,113]]]],
        ["inline","input",[],["type","checkbox","name","mandant.autosend","id","mandant-autosend","checked",["subexpr","@mut",[["get","model.autosendInvoice",["loc",[null,[357,100],[357,121]]]]],[],[]]],["loc",[null,[357,22],[357,123]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceEmail",["loc",[null,[365,34],[365,52]]]]],[],[]],"class","gui-input newStyle","name","mandant-operators"],["loc",[null,[365,20],[365,106]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.taxNumber",["loc",[null,[371,34],[371,49]]]]],[],[]],"class","gui-input newStyle","name","mandant-taxNumber"],["loc",[null,[371,20],[371,103]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.taxCode",["loc",[null,[377,34],[377,47]]]]],[],[]],"class","gui-input newStyle","name","mandant-taxCode"],["loc",[null,[377,20],[377,99]]]],
        ["inline","input",[],["type","checkbox","name","mandant.barsoi","id","mandant-barsoi","checked",["subexpr","@mut",[["get","model.barsoi",["loc",[null,[388,94],[388,106]]]]],[],[]]],["loc",[null,[388,20],[388,108]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.idfNumber",["loc",[null,[400,32],[400,47]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-idfNumber"],["loc",[null,[400,18],[400,125]]]],
        ["inline","connection-status",[],["status",["subexpr","@mut",[["get","model.idfValid",["loc",[null,[405,43],[405,57]]]]],[],[]]],["loc",[null,[405,16],[405,59]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.idfNumberPrivate",["loc",[null,[411,30],[411,52]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-idfNumberPrivate"],["loc",[null,[411,16],[411,137]]]],
        ["inline","connection-status",[],["status",["subexpr","@mut",[["get","model.idfPrivateValid",["loc",[null,[416,43],[416,64]]]]],[],[]]],["loc",[null,[416,16],[416,66]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.idfNumberDrug",["loc",[null,[422,30],[422,49]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-idfNumberDrug"],["loc",[null,[422,16],[422,131]]]],
        ["inline","connection-status",[],["status",["subexpr","@mut",[["get","model.idfDrugValid",["loc",[null,[427,43],[427,61]]]]],[],[]]],["loc",[null,[427,16],[427,63]]]],
        ["block","if",[["get","showLoading",["loc",[null,[436,20],[436,31]]]]],[],7,null,["loc",[null,[436,14],[438,21]]]],
        ["block","each",[["get","invoices",["loc",[null,[448,30],[448,38]]]]],[],8,null,["loc",[null,[448,22],[473,31]]]],
        ["block","each",[["get","users",["loc",[null,[489,30],[489,35]]]]],[],9,null,["loc",[null,[489,22],[501,31]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.purchaseEmail",["loc",[null,[511,36],[511,55]]]]],[],[]],"class","ml0 input input--order-entry col col--auto","name","purchase-email","autocomplete","off","focus-out",["subexpr","action",["changePurchaseEmail",["get","model",["loc",[null,[511,188],[511,193]]]]],[],["loc",[null,[511,158],[511,194]]]]],["loc",[null,[511,22],[511,196]]]],
        ["inline","mandant-fee-panel",[],["mandant",["subexpr","@mut",[["get","model",["loc",[null,[519,30],[519,35]]]]],[],[]],"model",["subexpr","@mut",[["get","editFee",["loc",[null,[519,42],[519,49]]]]],[],[]],"type",["subexpr","@mut",[["get","selectTab",["loc",[null,[519,55],[519,64]]]]],[],[]],"confirm","addFee"],["loc",[null,[519,2],[519,83]]]],
        ["inline","mandant-invoice-text-panel",[],["taxes",["subexpr","@mut",[["get","taxes",["loc",[null,[520,37],[520,42]]]]],[],[]],"mandant",["subexpr","@mut",[["get","model",["loc",[null,[520,51],[520,56]]]]],[],[]],"confirm","editText","actionReceiver",["subexpr","@mut",[["get","invoiceTextPanel",["loc",[null,[520,91],[520,107]]]]],[],[]]],["loc",[null,[520,2],[520,109]]]],
        ["inline","cashback-panel",[],["suppliers",["subexpr","@mut",[["get","suppliers",["loc",[null,[521,29],[521,38]]]]],[],[]],"mandant",["subexpr","@mut",[["get","model",["loc",[null,[521,47],[521,52]]]]],[],[]],"model",["subexpr","@mut",[["get","editCashback",["loc",[null,[521,59],[521,71]]]]],[],[]],"type",["subexpr","@mut",[["get","selectTab",["loc",[null,[521,77],[521,86]]]]],[],[]],"confirm","addCashback"],["loc",[null,[521,2],[521,110]]]],
        ["inline","customerGroup-panel",[],["suppliers",["subexpr","@mut",[["get","suppliers",["loc",[null,[522,34],[522,43]]]]],[],[]],"mandant",["subexpr","@mut",[["get","model",["loc",[null,[522,52],[522,57]]]]],[],[]],"model",["subexpr","@mut",[["get","editCustomerGroup",["loc",[null,[522,64],[522,81]]]]],[],[]],"type",["subexpr","@mut",[["get","selectTab",["loc",[null,[522,87],[522,96]]]]],[],[]],"confirm","addCustomerGroup"],["loc",[null,[522,2],[522,125]]]],
        ["content","button-prev",["loc",[null,[526,10],[526,25]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[528,16],[528,25]]]],"invoicesettings"],[],["loc",[null,[528,12],[528,44]]]]],[],10,null,["loc",[null,[528,6],[532,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[533,16],[533,25]]]],"users"],[],["loc",[null,[533,12],[533,34]]]]],[],11,null,["loc",[null,[533,6],[537,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[538,16],[538,25]]]],"purchase"],[],["loc",[null,[538,12],[538,37]]]]],[],12,null,["loc",[null,[538,6],[542,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[543,16],[543,25]]]],"sale"],[],["loc",[null,[543,12],[543,33]]]]],[],13,null,["loc",[null,[543,6],[547,13]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectTab",["loc",[null,[548,20],[548,29]]]],"invoicerows"],[],["loc",[null,[548,16],[548,44]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[548,49],[548,58]]]],"fee"],[],["loc",[null,[548,45],[548,65]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[548,70],[548,79]]]],"simFee"],[],["loc",[null,[548,66],[548,89]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[548,94],[548,103]]]],"jasonNetworkFee"],[],["loc",[null,[548,90],[548,122]]]]],[],["loc",[null,[548,12],[548,123]]]]],[],14,null,["loc",[null,[548,6],[552,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[553,16],[553,25]]]],"cashback"],[],["loc",[null,[553,12],[553,37]]]]],[],15,null,["loc",[null,[553,6],[557,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[558,16],[558,25]]]],"customerGroups"],[],["loc",[null,[558,12],[558,43]]]]],[],16,null,["loc",[null,[558,6],[562,13]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16]
    };
  }()));

});