define('jason-frontend/routes/secret', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      if (!this.session.get('user')) {
        return Ember['default'].RSVP.reject({ status: 'Unauthorized', message: 'Please login to access this page' });
      } else if (this.session.get('user.role') !== 'admin') {
        return Ember['default'].RSVP.reject({ status: 'Forbidden', message: 'You are not allowed to access this page' });
      }
    },

    model: function model() {
      return API['default'].get('secret');
    }
  });

});