define('jason-frontend/controllers/patients/appointment-categories', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Meine Terminkategorien",
    breadCrumbPath: "patients.appointment-categories",
    showLoading: false
  });

});