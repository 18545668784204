define('jason-frontend/components/future-blueprint-panel', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      edit: function edit(modelId, itemId) {
        this.sendAction('edit', modelId, itemId);
      },
      'delete': function _delete(modelId, itemId) {
        this.sendAction('delete', modelId, itemId);
      }
    }
  });

});