define('jason-frontend/components/future-add-reminder-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    store: Ember['default'].inject.service(),
    intervals: [{ id: "misc", value: "Benutzerdefiniert" }, { id: "1week", value: "1 Woche" }, { id: "2weeks", value: "2 Wochen" }, { id: "3weeks", value: "3 Wochen" }, { id: "1month", value: "1 Monat" }, { id: "2months", value: "2 Monaten" }, { id: "3months", value: "3 Monaten" }, { id: "1year", value: "1 Jahr" }],
    confirm: function confirm() {

      var nextChange = moment(this.get('data').nextExecutionCustom, 'DD. MM. YYYY');

      if (this.get('data').nextExecution === 'misc' && nextChange.isBefore(moment())) {
        jason.notifiction.error('Fehler', 'Das Auslösedatum muss in der Zukunft liegen');
        return;
      }

      this.sendAction('create', this.get('data'));
      $.magnificPopup.close();
    },
    actions: {
      load: function load(text, referer) {
        var data = {
          reminderHeadline: text.headline,
          reminderText: text.text,
          reminderClosing: text.closing,
          nextExecution: 'misc',
          referer: referer,
          nextExecutionCustom: moment().add(1, 'days').format("DD. MM. YYYY")
        };
        this.set('data', data);
      },
      confirm: function confirm() {
        this.confirm();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});