define('jason-frontend/templates/components/inventory-warehouse-edit-form', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 28,
                  "column": 14
                },
                "end": {
                  "line": 33,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/inventory-warehouse-edit-form.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"class","messageUser");
              dom.setAttribute(el1,"checked","");
              dom.setAttribute(el1,"type","checkbox");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              if (this.cachedFragment) { dom.repairClonedNode(element2,[],true); }
              var element3 = dom.childAt(fragment, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element2, 'value');
              morphs[1] = dom.createAttrMorph(element2, 'id');
              morphs[2] = dom.createAttrMorph(element3, 'for');
              return morphs;
            },
            statements: [
              ["attribute","value",["concat",[["get","user.id",["loc",[null,[29,32],[29,39]]]]]]],
              ["attribute","id",["concat",["message-user-",["get","user.id",["loc",[null,[30,42],[30,49]]]]]]],
              ["attribute","for",["concat",["message-user-",["get","user.id",["loc",[null,[32,43],[32,50]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 33,
                  "column": 14
                },
                "end": {
                  "line": 38,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/inventory-warehouse-edit-form.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"class","messageUser");
              dom.setAttribute(el1,"type","checkbox");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(fragment, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element0, 'value');
              morphs[1] = dom.createAttrMorph(element0, 'id');
              morphs[2] = dom.createAttrMorph(element1, 'for');
              return morphs;
            },
            statements: [
              ["attribute","value",["concat",[["get","user.id",["loc",[null,[34,32],[34,39]]]]]]],
              ["attribute","id",["concat",["message-user-",["get","user.id",["loc",[null,[35,42],[35,49]]]]]]],
              ["attribute","for",["concat",["message-user-",["get","user.id",["loc",[null,[37,43],[37,50]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 8
              },
              "end": {
                "line": 44,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/inventory-warehouse-edit-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-2/24 mb20");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","upper checkbox-custom checkbox-primary mb5");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-9/24 mb20");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
            morphs[1] = dom.createMorphAt(element4,1,1);
            morphs[2] = dom.createMorphAt(element4,3,3);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","is-user-in-list",[["get","model.visibleFor",["loc",[null,[28,37],[28,53]]]],["get","user.id",["loc",[null,[28,54],[28,61]]]]],[],["loc",[null,[28,20],[28,62]]]]],[],0,1,["loc",[null,[28,14],[38,21]]]],
            ["content","user.lastname",["loc",[null,[42,10],[42,27]]]],
            ["content","user.firstname",["loc",[null,[42,28],[42,46]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 6
            },
            "end": {
              "line": 45,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/inventory-warehouse-edit-form.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","not",[["subexpr","eq",[["get","user.id",["loc",[null,[25,23],[25,30]]]],"9999"],[],["loc",[null,[25,19],[25,38]]]]],[],["loc",[null,[25,14],[25,39]]]]],[],0,null,["loc",[null,[25,8],[44,15]]]]
        ],
        locals: ["user"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 94,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/inventory-warehouse-edit-form.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h2");
        dom.setAttribute(el1,"class","mb20");
        var el2 = dom.createTextNode("Mein Lager");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","col l-24/24 gutter");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","field");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"for","name");
        dom.setAttribute(el3,"class","field-label title required");
        var el4 = dom.createTextNode("Name");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","col l-24/24 gutter");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","field");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"for","inventory-hide-stock");
        dom.setAttribute(el3,"class","field-label title");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","checkbox-custom checkbox-primary field-checkbox");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","inventory-hide-stock");
        var el5 = dom.createTextNode("Lagerstand für dieses Lager anzeigen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","field");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"for","message-text");
        dom.setAttribute(el3,"class","mb20 field-label title");
        var el4 = dom.createTextNode("Lager in Behandlung/AR sichtbar für");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"id","selectAll");
        dom.setAttribute(el1,"class","cursor");
        var el2 = dom.createTextNode("Alle auswählen");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode(" |  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"id","deselectAll");
        dom.setAttribute(el1,"class","cursor");
        var el2 = dom.createTextNode("Alle abwählen");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button icon-button--small");
        dom.setAttribute(el2,"onclick","history.back()");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button--success icon-button");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $('body').delegate(\"#selectAll\", \"click\", function() {\n        $('input.messageUser').prop('checked', true);\n    });\n    $('body').delegate(\"#deselectAll\", \"click\", function() {\n        $('input.messageUser').prop('checked', false);\n    });\n    $(\"#inventoryWarehouseEdit\").validate({\n      errorClass: \"state-error\",\n      validClass: \"state-success\",\n      errorElement: \"em\",\n      rules: {\n        'name': {\n          required: true\n        }\n      },\n      highlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n      },\n      unhighlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n      },\n      errorPlacement: function(error, element) {\n        if (element.is(\":radio\") || element.is(\":checkbox\")) {\n          element.closest('.option-group').after(error);\n        } else {\n          error.insertAfter(element.parent());\n        }\n      }\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [6]);
        var element6 = dom.childAt(fragment, [12]);
        var element7 = dom.childAt(element6, [3]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2, 1]),3,3);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [4, 1, 3]),1,1);
        morphs[2] = dom.createAttrMorph(element5, 'class');
        morphs[3] = dom.createMorphAt(dom.childAt(element5, [1, 3]),1,1);
        morphs[4] = dom.createMorphAt(dom.childAt(element6, [1]),1,1);
        morphs[5] = dom.createElementMorph(element7);
        morphs[6] = dom.createMorphAt(element7,1,1);
        return morphs;
      },
      statements: [
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.name",["loc",[null,[6,18],[6,28]]]]],[],[]],"autofocus",true,"class","gui-input","title","Bitte geben Sie einen Namen an","name","name"],["loc",[null,[6,4],[6,114]]]],
        ["inline","input",[],["type","checkbox","name","inventory-hide-stock","id","inventory-hide-stock","checked",["subexpr","@mut",[["get","model.showStock",["loc",[null,[14,92],[14,107]]]]],[],[]]],["loc",[null,[14,6],[14,109]]]],
        ["attribute","class",["concat",["col l-24/24 gutter ",["subexpr","css-bool-evaluator",[["subexpr","is-at-least",["essential"],[],["loc",[null,[20,52],[20,77]]]],"","hidden"],[],["loc",[null,[20,31],[20,91]]]]]]],
        ["block","each",[["get","users",["loc",[null,[24,14],[24,19]]]]],[],0,null,["loc",[null,[24,6],[45,15]]]],
        ["content","button-prev",["loc",[null,[53,4],[53,19]]]],
        ["element","action",["save",["get","model.id",["loc",[null,[55,21],[55,29]]]]],[],["loc",[null,[55,5],[55,31]]]],
        ["inline","button-save",[],["color","#ffffff","size","50","content","Speichern"],["loc",[null,[56,4],[56,65]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});