define('jason-frontend/components/param-bar', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    showLegend: false,
    cssClass: '',
    hidden: 'hidden'
  });

});