define('jason-frontend/models/purchase-import-run', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    start: DS['default'].attr('date'),
    end: DS['default'].attr('date'),
    totalItems: DS['default'].attr('number'),
    itemsSuccess: DS['default'].attr('number'),
    itemsSkipped: DS['default'].attr('number'),
    supplierId: DS['default'].attr('string'),
    fileName: DS['default'].attr('string')
  });

});