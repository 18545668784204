define('jason-frontend/components/show-etiga-campaign-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      remove: function remove(id) {
        var campaignId = this.get('item').id;
        var self = this;
        $.ajax({
          url: "/api/etigaCampaigns/" + campaignId + "/patient/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.sendAction('reload', campaignId);
        }, function () {
          self.sendAction('reload', campaignId);
        });
      },
      load: function load(data) {
        this.set('item', data);
      },
      submit: function submit(id) {
        this.sendAction('submit', id);
        $.magnificPopup.close();
      }
    }
  });

});