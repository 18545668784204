define('jason-frontend/components/treatment-fever-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      reloadChart: function reloadChart() {
        this.get('feverChart').send('reloadChart', this.get('treatmentId'));
      },
      load: function load(items, id) {
        this.set('items', items);
        this.set('treatmentId', id);
        this.get('feverChart').send('reloadChart', this.get('treatmentId'));
      },
      openEdit: function openEdit(id) {
        this.sendAction('openEditFeverPanel', id);
      },
      'delete': function _delete(id) {
        this.sendAction('delete', id);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});