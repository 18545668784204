define('jason-frontend/components/edit-treatment-infos-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    months: null,
    type: null,
    actions: {
      refresh: function refresh() {
        $.magnificPopup.close();
        this.sendAction('refresh');
      },
      save: function save(info) {
        var patientInfo = {
          id: this.get('patientInfo').id,
          medicalHistory: this.get('patientInfo').get('info').get('medicalHistory'),
          introductionReasons: $('#update_introReasons').attr('data-ids'),
          adminTasks: $('#update_adminTasks').attr('data-ids'),
          doctorId: $('#update_wishDoctor').find('input.input-md').val(),
          assistentId: $('#update_wishAssistant').find('input.input-md').val(),
          roomId: $('#update_room').find('input.input-md').val()
          // treatmentInfoText: $('label#' + info.id + '_treatmentInfo').find('textarea').val()
        };
        this.sendAction('confirm', patientInfo);
        $.magnificPopup.close();
      },
      updateSelectsUpdatePanel: function updateSelectsUpdatePanel(values, patient, provider) {
        Ember['default'].set(patient, provider, values);

        setTimeout(function () {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-edit-treatment-infos-panel'
            },
            callbacks: {
              close: function close() {}
            }
          });
        }, 1000);
      },
      selectTreatmentDetailInfoMulti: function selectTreatmentDetailInfoMulti(provider, selected, title, reference) {

        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/lookups?type=" + provider,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('treatmentSelectPanelUpdate').send('loadMultiselect', data.treatmentLookup, selected, title, reference, provider);
        }, function (error) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#updatemodal-treatment-select-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      addPatient: function addPatient(customerId, invoiceId) {
        this.sendAction('addPatient', customerId, invoiceId);
      },
      load: function load(patient) {
        this.set('patientInfo', patient);

        setTimeout(function () {
          $("#editTreatmentInfosForm textarea").each(function () {
            $(this).height(this.scrollHeight + parseFloat($(this).css("borderTopWidth")) + parseFloat($(this).css("borderBottomWidth")));
          });
        }, 500);
      }
    }
  });

});