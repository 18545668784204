define('jason-frontend/controllers/purchases/cashback', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Mein Cashback",
    breadCrumbPath: "purchases.cashback",
    sortBy: 'created',
    sortDir: 'asc',
    years: [{ name: "2018", id: "2018" }, { name: "2019", id: "2019" }, { name: "2020", id: "2020" }],
    actions: {
      sortBy: function sortBy(_sortBy) {
        if (_sortBy === this.get('sortBy')) {
          if (this.get('sortDir') === 'desc') {
            this.set('sortDir', 'asc');
          } else {
            this.set('sortDir', 'desc');
          }
        }
        this.set('sortBy', _sortBy);
        this.send('applyFilter');
      }
    },
    watchFilterMandant: (function () {
      var self = this;
      this.set('page', 1);

      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterMandant'),
    watchMonths: (function () {
      var self = this;

      setTimeout(function () {
        self.send('applyFilter');
      }, 350);
    }).observes('filterMonth'),
    watchYears: (function () {
      var self = this;

      setTimeout(function () {
        self.send('applyFilter');
      }, 350);
    }).observes('filterYear')
  });

});