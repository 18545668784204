define('jason-frontend/helpers/is-true', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isTrue = isTrue;

  function isTrue(params) {
    var condition = params[0];

    if (condition && (condition === "true" || condition === true)) {
      return true;
    }
    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(isTrue);

});