define('jason-frontend/components/purchase-order-laboklin-panel', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    toggleVisible: false,
    data: null,
    form: null,
    acceptData: false,
    actions: {
      confirm: function confirm(product, orderId, productName) {
        var self = this;

        var form = $('#laboklinForm');

        var priceInfoId = form.attr('price-info-id'),
            overrideProduct = form.attr('product-id');

        if (overrideProduct) {
          product = overrideProduct;
        }

        var data = {
          id: form.find('input[name=laboklin-id]').val(),
          buyer: form.find('textarea[name=laboklin-buyer]').val(),
          buyerEmail: form.find('input[name=laboklin-email]').val(),
          buyerFax: form.find('input[name=laboklin-fax]').val(),
          firstname: form.find('input[name=laboklin-owner-firstname]').val(),
          lastname: form.find('input[name=laboklin-owner-lastname]').val(),
          town: form.find('input[name=laboklin-owner-town]').val(),
          street: form.find('input[name=laboklin-owner-street]').val(),
          takingPlace: form.find('input[name=laboklin-taking-place]').val(),
          fax: form.find('input[name=laboklin-owner-fax]').val(),
          email: form.find('input[name=laboklin-owner-email]').val(),
          phone: form.find('input[name=laboklin-owner-phone]').val(),
          animalBirthday: moment(form.find('input[name=laboklin-owner-birthday]').val(), 'DD. MM. YYYY').format('YYYY-MM-DD'),
          probeTaking: moment(form.find('input[name=probeTaking]').val(), 'DD. MM. YYYY').format('YYYY-MM-DD'),
          probeReport: form.find('input[name=probeReport]').val(),
          probe: form.find('input[name=checkType]:checked').val(),
          animalType: form.find('input[name=animalType]:checked').val(),
          animalTypeMisc: form.find('input[name=patientTypeMisc]').val(),
          animalName: form.find('input[name=patientName]').val(),
          animalRace: form.find('input[name=patientRace]').val(),
          animalAge: form.find('input[name=patientAge]').val(),
          animalGender: form.find('input[name=patientGender]:checked').val(),
          qualityOfProbe: form.find('input[name=qualityOfProbe]:checked').val(),
          edvNumber: form.find('input[name=patientEdv]').val(),
          wish: form.find('input[name=laboklin-wish]').val()
        };

        var deferred = $.ajax({
          url: "/api/purchaseOrders/" + orderId + "/laboklinForm",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (formId) {
          self.sendAction('confirm', product, orderId, priceInfoId, productName, formId, 1);
        }, function (error) {});

        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});