define('jason-frontend/models/composite-article', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    individualName: DS['default'].attr('string'),
    description: DS['default'].attr('string'),
    editable: DS['default'].attr('boolean'),
    jason: DS['default'].attr('boolean'),
    total: DS['default'].attr('number'),
    type: DS['default'].attr(''),
    favorite: DS['default'].attr(''),
    entries: DS['default'].hasMany('compositeArticleEntry'),
    products: DS['default'].hasMany('compositeArticleEntry'),
    services: DS['default'].hasMany('compositeArticleEntry'),
    templates: DS['default'].hasMany('compositeArticleEntry'),
    medias: DS['default'].hasMany('compositeArticleEntry'),
    patientCategories: DS['default'].attr(),
    introductionReasons: DS['default'].attr(),
    symptoms: DS['default'].attr(),
    diagnosis: DS['default'].attr(),
    weightFrom: DS['default'].attr('number'),
    weightTo: DS['default'].attr('number'),
    ageFrom: DS['default'].attr('number'),
    ageTo: DS['default'].attr('number')
  });

});