define('jason-frontend/templates/components/button-return', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 5,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/button-return.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","14");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[4,24],[4,29]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-return.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createElement("title");
        var el3 = dom.createTextNode("view");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"fill-rule","evenodd");
        dom.setAttribute(el3,"d","M12.512 7.457c.2-.257.57-.303.828-.103s.304.57.103.828l-2.48 2.9h8.122c.97 0 1.853.398 2.493 1.037.64.64 1.036 1.52 1.036 2.493v3.844c0 .97-.397 1.854-1.036 2.493-.64.64-1.522 1.037-2.493 1.037H11.62c-.302 0-.55-.248-.55-.55v-.08c0-.303.248-.55.55-.55h7.465c.645 0 1.233-.265 1.66-.69.425-.427.69-1.014.69-1.66v-3.844c0-.646-.265-1.233-.69-1.66-.427-.426-1.015-.69-1.66-.69H10.97l2.464 2.88c.2.258.154.63-.103.83-.107.083-.234.123-.36.123-.177 0-.35-.078-.467-.227l-3.21-3.838c-.165-.214-.165-.512 0-.725l3.22-3.85z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [2]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createMorphAt(element2,1,1);
        morphs[3] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,10],[3,20]]]]],[],0,null,["loc",[null,[3,4],[5,11]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[6,18],[6,23]]]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});