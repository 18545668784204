define('jason-frontend/templates/components/button-sloping', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-sloping.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("circle");
        dom.setAttribute(el2,"class","circle");
        dom.setAttribute(el2,"cx","16");
        dom.setAttribute(el2,"cy","16");
        dom.setAttribute(el2,"r","13");
        dom.setAttribute(el2,"stroke","#3B6182");
        dom.setAttribute(el2,"fill","#fff");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","#3B6182");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(7,7) scale(0.035,0.035)");
        dom.setAttribute(el2,"class","icon");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M183.375,334.312l4.479-22.838c8.666-44.302,31.342-104.618,90.135-140.424l22.65-13.776L161.442,72.126\n			c-16.659-10.211-35.784-15.605-55.274-15.605c-37.297,0-71.172,18.975-90.61,50.736c-30.521,49.913-14.749,115.354,35.155,145.893\n			L183.375,334.312z M43.177,124.15c13.495-22.083,37.063-35.273,62.991-35.273c13.519,0,26.794,3.753,38.403,10.836l95.509,58.438\n			c-45.963,36.638-68.086,86.614-78.7,124.749l-93.798-57.354C32.9,204.319,21.948,158.833,43.177,124.15z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M449.318,253.258l-124.435-76.133l-2.305,0.982c-14.507,6.209-26.978,13.95-37.986,22.562l119.838,73.156\n			c4.532,2.785,5.972,8.703,3.183,13.24c-1.8,2.974-4.974,4.616-8.212,4.616c-1.707,0-3.435-0.444-5.021-1.41\n			c0,0-122.915-75.895-123.697-77.528c-43.949,42.908-56.228,101.4-59.578,129.392l-0.375,3.089l132.692,81.139\n			c15.945,9.767,34.224,14.912,52.846,14.912c35.67,0,68.046-18.146,86.637-48.522C512.106,345.031,497.026,282.451,449.318,253.258\n			z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element0, 'class');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","class",["concat",["pill ",["get","cssClasses",["loc",[null,[1,109],[1,119]]]]," ",["subexpr","css-bool-evaluator",[["get","showCursor",["loc",[null,[1,143],[1,153]]]],"cursor",""],[],["loc",[null,[1,122],[1,167]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});