define('jason-frontend/routes/categories/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  var modelHookRun;
  exports['default'] = Ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      modelHookRun = false;
    },
    model: function model(params) {
      return this.store.findRecord('productCategory', params.id, { reload: true });
    },
    afterModel: function afterModel(model) {
      if (!modelHookRun) {
        return model.reload();
      }
      return model;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      $.ajax({
        url: "/api/productCategories?onlyParent=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('parentCategories', data.productCategory);
      });
    },
    actions: {
      save: function save(id) {
        var self = this;

        var controller = this.get('controller'),
            model = controller.get('model'),
            category = new Object();

        category.name = model.get('name');
        category.id = id;

        if (model.parentCategory) {
          category.parentCategory = {
            id: model.parentCategory.id
          };
        }
        $.ajax({
          url: "/api/productCategories/" + id,
          method: "PUT",
          data: JSON.stringify(category),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('basicinfos', { queryParams: { selectTab: "productcategories" } });
          controller.set('name', '');
        }, function (data) {
          self.transitionTo('basicinfos', { queryParams: { selectTab: "productcategories" } });
          controller.set('name', '');
        });
      }
    }
  });

});