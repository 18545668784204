define('jason-frontend/components/number-spinner', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ['input-group', 'spinner2'],
    actions: {
      decreaseQuantitySpinner: function decreaseQuantitySpinner(entry, offerId, productName) {
        var qty = parseFloat(this.$().find("input[data-qty-entry-id='" + entry.id + "']").val());
        if (qty > 1) {
          this.sendAction('quantityChanged', qty - 1, entry.id, offerId, productName);
        }
      },
      increaseQuantitySpinner: function increaseQuantitySpinner(entry, offerId, productName) {
        var qty = parseFloat(this.$().find("input[data-qty-entry-id='" + entry.id + "']").val());
        this.sendAction('quantityChanged', qty + 1, entry.id, offerId, productName);
      },
      changeQuantitySpinner: function changeQuantitySpinner(entry, offerId, productName, oldQty) {
        var qty = this.$().find("input[data-qty-entry-id='" + entry.id + "']").val();
        if (oldQty != qty) {
          qty = String(qty).replace(',', '.');
          if (qty > 0) {
            this.sendAction('quantityChanged', qty, entry.id, offerId, productName);
          } else {
            jason.notifiction.error('Fehleingabe', 'Menge muss größer/gleich 0 sein');
          }
        }
      }
    }
  });

});