define('jason-frontend/helpers/format-number-german-with-comma', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.formatNumberGerman = formatNumberGerman;

  function formatNumberGerman(params) {
    var value = String(params[0]).replace(',', '.');
    if (!isNaN(value)) {
      value = Math.round(value * 100) / 100;
      value = String(value).replace('.', ',');

      if (value % 1 == 0) {
        value += ".0";
      }

      return value;
    }
    return;
  }

  exports['default'] = Ember['default'].Helper.helper(formatNumberGerman);

});