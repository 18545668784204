define('jason-frontend/helpers/show-cashdesk-action', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.showCashdeskActions = showCashdeskActions;

  function showCashdeskActions() {
    return API['default'].getMandant() != 10001 && API['default'].getMandant() != 10003;
  }

  exports['default'] = Ember['default'].Helper.helper(showCashdeskActions);

});