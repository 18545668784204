define('jason-frontend/components/laboklin-historic-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      load: function load(items) {
        this.set('labItems', items);
        this.get('historicItems').send('disableLoading');
      },
      disableLoading: function disableLoading(items) {
        this.get('historicItems').send('disableLoading');
      },
      downloadResult: function downloadResult(items) {
        this.sendAction('downloadResult', items);
      },
      openResult: function openResult(items) {
        this.sendAction('openResult', items);
      },
      downloadMedia: function downloadMedia(formId, format) {
        this.sendAction('downloadMedia', formId, format);
      },
      downloadBarcode: function downloadBarcode(formId) {
        this.sendAction('downloadBarcode', formId);
      },
      forwardEmail: function forwardEmail(id) {
        this.sendAction('forwardEmail', id);
      },
      downloadPdf: function downloadPdf(formId) {
        this.sendAction('downloadPdf', formId);
      },
      deleteReport: function deleteReport(formId) {
        this.sendAction('deleteReport', formId);
      },
      submitLaboklin: function submitLaboklin(id, formId) {
        this.sendAction('submitLaboklin', id, formId);
      },
      setSort: function setSort() {
        this.sendAction('sortLabItems');
      },
      reload: function reload(type) {
        this.sendAction('reload', type);
      },
      editLaboklinForm: function editLaboklinForm(formId) {
        this.sendAction('editLabWrapper', formId);
      }
    }
  });

});