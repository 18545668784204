define('jason-frontend/helpers/is-patient-category-selected', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isPatientCategorySelected = isPatientCategorySelected;

  function isPatientCategorySelected(params) {
    var selectedList = params[0],
        category = params[1],
        returnValue = false;

    if (!selectedList) {
      return false;
    }

    try {
      $.each(selectedList.currentState, function (index, value) {
        if (value.id === category.id) {
          returnValue = true;
          return;
        }
      });
    } catch (e) {}
    return returnValue;
  }

  exports['default'] = Ember['default'].Helper.helper(isPatientCategorySelected);

});