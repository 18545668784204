define('jason-frontend/controllers/bankingaccounts', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Meine Konten",
    breadCrumbPath: "bankingaccounts",
    queryParams: ["page", "perPage"],
    sortBy: null,
    sortDir: null,

    sources: [{ name: "Alle Quellen", id: "all" }, { name: "Jason Network", id: "jason" }, { name: "Meine Lieferanten", id: "my" }],

    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    perPage: 10,
    actions: {
      handleSelect: function handleSelect(selected) {
        this.set('filterSource', selected);
        this.send('applyFilter');
      }
    },
    watchName: (function () {
      this.set('page', 1);
    }).observes('filterName'),
    watchSource: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterSource')
  });

});