define('jason-frontend/components/stock-details', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    showHeader: true,
    showActionButtons: true,
    showWarehouseInLine: true,
    showInventoryDetailsButton: false,
    hasScanned: function hasScanned(item) {
      var self = this;
      var data = {
        code: item.batchNumber
      };

      $.ajax({
        url: "/api/inventoryStocks/convertDataMatrix",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data2) {
        $('#batch' + item.id).prop('disabled', false);
        if ('na' === data2.chargeNumber) {
          jason.notifiction.warning('Chargennummer nicht erkannt', '');
        } else {
          Ember['default'].set(item, 'batchNumber', data2.chargeNumber);
          self.sendAction('updateBatchNumber', item);
        }
        if (data2.expiryDate) {
          var exp = moment(data2.expiryDate).format('DD. MM. YYYY');
          Ember['default'].set(item, 'expiryDate', exp);
          setTimeout(function () {
            self.sendAction('updateExpiry', item);
          }, 500);
        }
      }, function (data2) {
        $('#batch' + item.id).prop('disabled', false);
      });
    },
    actions: {
      updateLocation: function updateLocation(item) {
        this.sendAction('updateLocation', item);
      },
      openScan: function openScan(item) {
        Ember['default'].set(item, 'batchNumber', null);
        $('#batch' + item.id).focus();

        $('#batch' + item.id).scannerDetection({
          timeBeforeScanTest: 200, // wait for the next character for upto 200ms
          onComplete: function onComplete(barcode, qty) {
            this.hasScanned(item);
          },
          scanButtonKeyCode: 116, // the hardware scan button acts as key 116 (F5)
          scanButtonLongPressThreshold: 5, // assume a long press if 5 or more events come in sequence
          onError: function onError(string) {
            console.log('error scanning');
          }
        });

        var self = this;

        $(document).off("change paste keyup select", '#batch' + item.id);
        $(document).on("change paste keyup select", '#batch' + item.id, function () {
          var value = $(this).val();
          if (value.toLowerCase().endsWith("qr")) {
            $(this).prop('disabled', true);
            setTimeout(function () {
              if (!$(this).prop('disabled')) {
                self.hasScanned(item);
              }
            }, 300);
          }
        });
      },
      updateBatchNumber: function updateBatchNumber(item) {
        this.sendAction('updateBatchNumber', item);
      },
      updateExpiry: function updateExpiry(item) {
        this.sendAction('updateExpiry', item);
      },
      load: function load(items, productName, productStockId, data) {
        this.set('items', items);
        this.set('model', data);

        items.forEach(function (item) {
          if (item.expiryDate) {
            Ember['default'].set(item, 'expiryDate', moment(item.expiryDate).format('DD. MM. YYYY'));
          }
        });

        this.set('productName', productName);
        this.set('productStockId', productStockId);
      },
      showInvoice: function showInvoice(id) {
        this.sendAction('showInvoice', id);
      },
      openOutgoing: function openOutgoing(id) {
        this.sendAction('openOutgoing', id);
      },
      showPatient: function showPatient(id) {
        this.sendAction('showPatient', id);
      },
      openCorrection: function openCorrection(id) {
        this.sendAction('openCorrection', id);
      },
      bookout: function bookout(id) {
        this.sendAction('bookout', id, this.get('productStockId'), this.get('productName'));
      },
      transfer: function transfer(id) {
        this.sendAction('transfer', id);
      },
      bookoutMovement: function bookoutMovement(id) {
        this.sendAction('bookoutMovement', id, this.get('productStockId'), this.get('productName'));
      },
      printBarcode: function printBarcode(id) {
        this.sendAction('printBarcode', id);
      },
      showStock: function showStock(id) {
        this.sendAction('showStock', id);
      },
      toggle: function toggle(entry) {
        var secondRow = $("div.table__cell[data-id=" + entry.id + "]");
        if (secondRow.hasClass('hidden')) {
          secondRow.removeClass('hidden');
        } else {
          secondRow.addClass('hidden');
        }
      }
    }
  });

});