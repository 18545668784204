define('jason-frontend/templates/components/loading-animation-new', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 32,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/loading-animation-new.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"width","38");
        dom.setAttribute(el1,"height","38");
        dom.setAttribute(el1,"viewBox","0 0 38 38");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"class","loading-animation-new");
        dom.setAttribute(el1,"style","margin: auto; display:block");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("defs");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("linearGradient");
        dom.setAttribute(el3,"x1","8.042%");
        dom.setAttribute(el3,"y1","0%");
        dom.setAttribute(el3,"x2","65.682%");
        dom.setAttribute(el3,"y2","23.865%");
        dom.setAttribute(el3,"id","a");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4,"stop-opacity","0");
        dom.setAttribute(el4,"offset","0%");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4,"stop-opacity",".631");
        dom.setAttribute(el4,"offset","63.146%");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4,"offset","100%");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","translate(1 1)");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M36 18c0-9.94-8.06-18-18-18");
        dom.setAttribute(el4,"id","Oval-2");
        dom.setAttribute(el4,"stroke","url(#a)");
        dom.setAttribute(el4,"stroke-width","2");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("animateTransform");
        dom.setAttribute(el5,"attributeName","transform");
        dom.setAttribute(el5,"type","rotate");
        dom.setAttribute(el5,"from","0 18 18");
        dom.setAttribute(el5,"to","360 18 18");
        dom.setAttribute(el5,"dur","0.9s");
        dom.setAttribute(el5,"repeatCount","indefinite");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("circle");
        dom.setAttribute(el4,"cx","36");
        dom.setAttribute(el4,"cy","18");
        dom.setAttribute(el4,"r","1");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("animateTransform");
        dom.setAttribute(el5,"attributeName","transform");
        dom.setAttribute(el5,"type","rotate");
        dom.setAttribute(el5,"from","0 18 18");
        dom.setAttribute(el5,"to","360 18 18");
        dom.setAttribute(el5,"dur","0.9s");
        dom.setAttribute(el5,"repeatCount","indefinite");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1, 1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(element1, [5]);
        var element5 = dom.childAt(element0, [3, 1, 3]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element0, 'fill');
        morphs[1] = dom.createAttrMorph(element2, 'stop-color');
        morphs[2] = dom.createAttrMorph(element3, 'stop-color');
        morphs[3] = dom.createAttrMorph(element4, 'stop-color');
        morphs[4] = dom.createAttrMorph(element5, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","fill",["concat",[["get","color",["loc",[null,[1,91],[1,96]]]]]]],
        ["attribute","stop-color",["concat",[["get","color",["loc",[null,[4,26],[4,31]]]]]]],
        ["attribute","stop-color",["concat",[["get","color",["loc",[null,[5,26],[5,31]]]]]]],
        ["attribute","stop-color",["concat",[["get","color",["loc",[null,[6,26],[6,31]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[20,22],[20,27]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});