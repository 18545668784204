define('jason-frontend/components/file-upload', ['exports', 'ember', 'ember-uploader', 'jason-frontend/api'], function (exports, Ember, EmberUploader, API) {

  'use strict';

  exports['default'] = EmberUploader['default'].FileField.extend({
    filesDidChange: function filesDidChange(files) {
      var _this = this;

      var data = {
        mandantId: API['default'].getMandant(),
        downloadToken: API['default'].getDownloadToken(),
        category: $('#mediaCategory').find('input.input-md').val(),
        patientTreatmentId: $('#modal-media-panel').attr('data-id'),
        patientId: $('#modal-media-panel').attr('data-patient-id'),
        customerId: $('#modal-media-panel').attr('data-customer-id'),
        name: $('#mediaName').find('input').val()
      };

      if (!data.customerId) {
        data.customerId = 0;
      }

      var uploader = EmberUploader['default'].Uploader.create({
        url: '/api/mediaUploads'
      });

      if (!Ember['default'].isEmpty(files)) {
        uploader.upload(files[0], data);
      }

      uploader.on('didUpload', function (e) {
        _this.sendAction('uploadDone', e);
      });

      uploader.on('didError', function (jqXHR, textStatus, errorThrown) {
        jason.notifiction.error('Dokument konnte nicht hochgeladen werden', 'max. 10 Mbyte erlaubt');
      });
    }
  });

});