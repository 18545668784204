define('jason-frontend/routes/inventory/select-order', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    perPage: 20,
    queryParams: {},
    setupController: function setupController(controller, model, params) {
      controller.set("model", model);
      controller.set('selectedType', 'default');
      controller.set('warehouses', this.store.find('inventory-warehouse'));

      var selectedTab = params.queryParams.selectTab;

      if (selectedTab === 'past') {
        selectedTab = 'now';
      }
      if (selectedTab) {
        this.setType(selectedTab);
      } else {
        this.applyFilter();
      }
    },
    setType: function setType(type) {
      var self = this;
      this.get('controller').set('selectedType', type);
      this.get('controller').set('model', null);

      this.get('controller').set('selectTab', type);

      if (this.get('controller').get('selectedType') === 'default') {
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      } else if (this.get('controller').get('selectedType') === 'outgoing') {
        setTimeout(function () {
          self.applyFilterOutgoing();
        }, 500);
      } else if (this.get('controller').get('selectedType') === 'correction') {
        setTimeout(function () {
          self.applyFilterCorrections();
        }, 500);
      } else {
        setTimeout(function () {
          self.applyFilterChange();
        }, 500);
      }
    },
    actions: {
      manualStockSelected: function manualStockSelected(item, product) {
        var self = this;
        $.ajax({
          url: "/api/inventoryStocks/" + item,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (self.get('controller').get('selectedType') === 'correction') {
            self.get('controller').get('inventoryCorrectionPanel').send('loadReopen', data.inventoryStock, product);
          } else if (self.get('controller').get('selectedType') === 'outgoing') {
            self.get('controller').get('inventoryOutgoingPanel').send('loadReopen', data.inventoryStock, product);
          }
        }, function () {});

        var src = '#modal-inventory-correction-panel';

        if (self.get('controller').get('selectedType') === 'outgoing') {
          src = '#modal-inventory-outgoing-panel';
        }
        setTimeout(function () {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: src
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 1000);
      },
      setType: function setType(type) {
        this.setType(type);
      },
      applyFilter: function applyFilter() {
        var self = this;

        if (this.get('controller').get('selectedType') === 'default') {
          setTimeout(function () {
            self.applyFilter();
          }, 500);
        } else if (this.get('controller').get('selectedType') === 'outgoing') {
          setTimeout(function () {
            self.applyFilterOutgoing();
          }, 500);
        } else {
          setTimeout(function () {
            self.applyFilterCorrections();
          }, 500);
        }
      },
      applyFilterCorrection: function applyFilterCorrection() {
        var self = this;
        setTimeout(function () {
          self.applyFilterCorrection();
        }, 500);
      },
      applyFilterChange: function applyFilterChange() {
        var self = this;
        setTimeout(function () {
          self.applyFilterChange();
        }, 500);
      },
      applyFilterOutgoing: function applyFilterOutgoing() {
        var self = this;
        setTimeout(function () {
          self.applyFilterOutgoing();
        }, 500);
      },
      saveCorrection: function saveCorrection(data, product, keepOpen, reopen) {
        var self = this;
        $.ajax({
          url: "/api/inventoryStocks/" + data.id + "/correction",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Korrekturbuchung', 'gespeichert');
          self.applyFilter();
          setTimeout(function () {
            self.get('controller').send('scan');
          }, 500);
        }, function (data) {
          jason.notifiction.notifiy('Korrekturbuchung', 'gespeichert');
          self.applyFilter();
          if (reopen == true) {

            $.ajax({
              url: "/api/inventoryStocks/product/" + product.id,
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              self.get('controller').get('stockSelectPanel').send('load', data, product);
            }, function () {});

            setTimeout(function () {
              $.magnificPopup.open({
                removalDelay: 500,
                closeOnBgClick: false,
                items: {
                  src: '#modal-select-stock-panel'
                },
                callbacks: {
                  beforeOpen: function beforeOpen() {
                    var Animation = "mfp-with-fade";
                    this.st.mainClass = Animation;
                  }
                },
                midClick: true
              });
            }, 1000);
          } else {
            setTimeout(function () {
              self.get('controller').send('scan');
            }, 500);
          }
        });
      },
      saveOutgoing: function saveOutgoing(data, product, reopen) {
        var self = this;
        $.ajax({
          url: "/api/inventoryStocks/" + data.id + "/outgoing",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Ausgangsbuchung', 'gespeichert');
          self.applyFilterOutgoing();
        }, function (data) {
          jason.notifiction.notifiy('Ausgangsbuchung', 'gespeichert');
          self.applyFilterOutgoing();
          if (reopen == true) {
            $.ajax({
              url: "/api/inventoryStocks/product/" + product.id,
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              self.get('controller').get('stockSelectPanel').send('load', data, product);
            }, function () {});

            setTimeout(function () {
              $.magnificPopup.open({
                removalDelay: 500,
                closeOnBgClick: false,
                items: {
                  src: '#modal-select-stock-panel'
                },
                callbacks: {
                  beforeOpen: function beforeOpen() {
                    var Animation = "mfp-with-fade";
                    this.st.mainClass = Animation;
                  }
                },
                midClick: true
              });
            }, 1000);
          }
        });
      },
      showStock: function showStock(id) {
        this.transitionTo('inventory.stock-details', id);
      },
      scanProduct: function scanProduct(id) {
        var controller = this.get('controller');

        controller.set('filterCode', id);
        this.applyFilter();
      },
      createRequest: function createRequest() {
        var self = this;
        $.ajax({
          url: "/api/inventoryRequests?referer=corrections",
          method: "PUT",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('cash.add_article', 'inventoryRequests', data.inventoryRequestSupplier.id, 0);
        }, function () {});
      },
      addChange: function addChange() {
        var self = this;
        $.ajax({
          url: "/api/inventoryMassMove",
          method: "PUT",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('inventory.massmove-detail', data);
        }, function () {});
      },
      bookinOrder: function bookinOrder(item) {
        var self = this;
        $.ajax({
          url: "/api/inventoryRequests/bookinPurchase/" + item.id,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('inventory.bookin-request', data);
        }, function () {});
      }
    },
    applyFilterChange: function applyFilterChange() {
      var controller = this.get('controller'),
          code = controller.get('filterCode'),
          status = controller.get('filterStatus'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      controller.set('showLoading', true);

      this.findPaged('inventory-stock-movement', {
        filterCode: code,
        filterStatus: status,
        filterType: 'stockchange',
        to: to,
        from: from,
        pageSize: this.get('perPage')
      }).then(function (data) {
        var meta = data.meta;
        controller.set('model', data);
        controller.set('showLoading', false);
        controller.set('totalPlus', meta.total_plus);
        controller.set('totalMinus', meta.total_minus);
      });
    },
    applyFilterCorrections: function applyFilterCorrections() {
      var controller = this.get('controller'),
          code = controller.get('filterCode'),
          status = controller.get('filterStatus'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-');

      controller.set('showLoading', true);

      this.findPaged('inventory-stock', {
        filterCode: code,
        filterStatus: status,
        pageSize: this.get('perPage')
      }).then(function (data) {
        var meta = data.meta;
        controller.set('model', data);
        controller.set('showLoading', false);
        controller.set('totalPlus', meta.total_plus);
        controller.set('totalMinus', meta.total_minus);
      });
    },
    applyFilterOutgoing: function applyFilterOutgoing() {
      var controller = this.get('controller'),
          code = controller.get('filterCode'),
          status = controller.get('filterStatus'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-');

      controller.set('showLoading', true);

      this.findPaged('inventory-stock', {
        filterCode: code,
        filterStatus: status,
        pageSize: this.get('perPage')
      }).then(function (data) {
        var meta = data.meta;
        controller.set('model', data);
        controller.set('showLoading', false);
        controller.set('totalPlus', meta.total_plus);
        controller.set('totalMinus', meta.total_minus);
      });
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          code = controller.get('filterCode'),
          dateFromTo = dateRange.split('-');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      controller.set('showLoading', true);

      this.findPaged('purchaseOrder', {
        filterFrom: from,
        filterTo: to,
        filterCode: code,
        filterProductsOnly: true,
        filterStatus: controller.get('filterOrderStatus')
      }).then(function (transactions) {
        var meta = transactions.meta;
        controller.set('sumIndividualCashback', meta.sumIndividualCashback);
        controller.set('sumJasonCashback', meta.sumJasonCashback);
        controller.set('model', transactions);
        controller.set('showLoading', false);
      });
    }
  });

});