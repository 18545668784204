define('jason-frontend/helpers/is-user-in-list', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isUserInList = isUserInList;

  function isUserInList(params) {
    var list = params[0],
        idToCheck = params[1];

    if (list) {
      return list.indexOf(parseInt(idToCheck)) >= 0;
    }
    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(isUserInList);

});