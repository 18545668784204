define('jason-frontend/components/create-product-pzn-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    pzn: null,
    state: 'start',
    store: null,
    id: 0,
    actions: {
      reset: function reset() {
        this.set('pzn', null);
        this.set('name', null);
        this.set('state', 'start');
        this.set('id', 0);
      },
      select: function select(id) {
        alert('selected');
      },
      confirm: function confirm() {
        var self = this;

        var data = {};
        data.pzn = this.get('pzn');
        data.name = this.get('name');

        $.ajax({
          url: "/api/purchaseProducts/checkIdentifier",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          self.set('state', 'showSuggestions');
          self.set('foundProducts', data.productSearchResult);
        });
      },
      toCreateProduct: function toCreateProduct() {
        this.sendAction('toCreateProduct', this.get('pzn'), this.get('name'));
      },
      toProduct: function toProduct(id) {
        this.sendAction('save', id);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(store) {
        this.set('store', store);
      }
    }
  });

});