define('jason-frontend/models/purchase-commission', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    created: DS['default'].attr('date'),
    orderCode: DS['default'].attr('string'),
    mandant: DS['default'].attr('string'),
    orderId: DS['default'].attr('number'),
    orderTotal: DS['default'].attr('number'),
    orderTotalGross: DS['default'].attr('number'),
    commission: DS['default'].attr('number'),
    cashback: DS['default'].attr('number'),
    saving: DS['default'].attr('number'),
    transactionFee: DS['default'].attr('number')
  });

});