define('jason-frontend/templates/components/button-inventory2', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 5,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/button-inventory2.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"class","circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","16");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[4,60],[4,65]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 37,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-inventory2.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 34 34");
        dom.setAttribute(el1,"style","margin: 2px auto");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(1 1)");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","scale(0.9,0.9) translate(2,2)");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        dom.setAttribute(el4,"transform","scale(0.05,0.05) translate(80,80)");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M68.571,189.286h0.104c-0.028-1.186-0.104-2.4-0.104-3.572C68.571,186.872,68.571,188.114,68.571,189.286z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M68.571,370.34c0,0.349,0,0.739,0,1.088c0.181-0.363,0.293-0.725,0.474-1.088H68.571z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M437.726,371.428h-12.012c0,3.572,0,7.143,0,14.286c0-7.143,0-10.714,0-14.286c0-0.363,0-0.725,0-1.088H407.02V250.565\n                          H252.249v39.927H102.514v79.848H69.045c-0.181,0.363-0.293,0.725-0.474,1.088H57.864l0.021,17.411l379.911-0.446L437.726,371.428z\n                           M252.249,370.34h-134.73v-64.844h134.73V370.34z M317.176,265.562h24.916v44.901h-24.916V265.562z M392.009,370.34H267.26V265.562\n                          h34.905v59.884h54.938v-59.884h34.905V370.34z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M425.714,185.714h11.272l-0.056-15.011h-39.899V90.841H247.282V50.907H92.532v119.796H57.62l0.021,15.011h10.931\n                          c0,1.172,0.077,2.386,0.104,3.572H437l-0.014-3.572H425.714z M157.445,65.911h24.93v44.894h-24.93V65.911z M232.299,170.703\n                          H107.536V65.911h34.912v59.891h54.932V65.911h34.919V170.703z M382.02,170.703H247.282v-64.865H382.02V170.703z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M68.571,370.34H57.864v1.088h10.708C68.571,371.08,68.571,370.689,68.571,370.34z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("rect");
        dom.setAttribute(el5,"x","313.103");
        dom.setAttribute(el5,"y","130.768");
        dom.setAttribute(el5,"width","29.967");
        dom.setAttribute(el5,"height","15.004");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("rect");
        dom.setAttribute(el5,"x","253.225");
        dom.setAttribute(el5,"y","130.768");
        dom.setAttribute(el5,"width","49.888");
        dom.setAttribute(el5,"height","15.004");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("rect");
        dom.setAttribute(el5,"x","183.338");
        dom.setAttribute(el5,"y","330.413");
        dom.setAttribute(el5,"width","29.946");
        dom.setAttribute(el5,"height","15.011");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("rect");
        dom.setAttribute(el5,"x","123.44");
        dom.setAttribute(el5,"y","330.413");
        dom.setAttribute(el5,"width","49.916");
        dom.setAttribute(el5,"height","15.011");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        dom.setAttribute(el4,"transform","scale(0.6,0.6) translate(-19,-16)");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("line");
        dom.setAttribute(el5,"x1","28.583");
        dom.setAttribute(el5,"y1","25.288");
        dom.setAttribute(el5,"x2","28.583");
        dom.setAttribute(el5,"y2","59.18");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("rect");
        dom.setAttribute(el5,"x","29.25");
        dom.setAttribute(el5,"y","25.967");
        dom.setAttribute(el5,"width","1.5");
        dom.setAttribute(el5,"height","33.893");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("rect");
        dom.setAttribute(el5,"x","61.29");
        dom.setAttribute(el5,"y","25.967");
        dom.setAttribute(el5,"width","1.5");
        dom.setAttribute(el5,"height","33.893");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("rect");
        dom.setAttribute(el5,"x","59.29");
        dom.setAttribute(el5,"y","36.437");
        dom.setAttribute(el5,"width","2");
        dom.setAttribute(el5,"height","1.313");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("rect");
        dom.setAttribute(el5,"x","30.75");
        dom.setAttribute(el5,"y","36.437");
        dom.setAttribute(el5,"width","2");
        dom.setAttribute(el5,"height","1.313");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("rect");
        dom.setAttribute(el5,"x","30.75");
        dom.setAttribute(el5,"y","50.424");
        dom.setAttribute(el5,"width","2");
        dom.setAttribute(el5,"height","1.295");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("rect");
        dom.setAttribute(el5,"x","59.29");
        dom.setAttribute(el5,"y","50.424");
        dom.setAttribute(el5,"width","2");
        dom.setAttribute(el5,"height","1.264");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("rect");
        dom.setAttribute(el5,"x","32.417");
        dom.setAttribute(el5,"y","37.094");
        dom.setAttribute(el5,"width","1.583");
        dom.setAttribute(el5,"height","0.656");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("rect");
        dom.setAttribute(el5,"x","57.917");
        dom.setAttribute(el5,"y","50.424");
        dom.setAttribute(el5,"width","1.791");
        dom.setAttribute(el5,"height","0.639");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element5, [3]);
        var element8 = dom.childAt(element5, [5]);
        var element9 = dom.childAt(element5, [7]);
        var element10 = dom.childAt(element5, [9]);
        var element11 = dom.childAt(element5, [11]);
        var element12 = dom.childAt(element5, [13]);
        var element13 = dom.childAt(element5, [15]);
        var element14 = dom.childAt(element5, [17]);
        var morphs = new Array(15);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createAttrMorph(element1, 'class');
        morphs[3] = dom.createMorphAt(element2,1,1);
        morphs[4] = dom.createAttrMorph(element4, 'fill');
        morphs[5] = dom.createAttrMorph(element5, 'fill');
        morphs[6] = dom.createAttrMorph(element6, 'fill');
        morphs[7] = dom.createAttrMorph(element7, 'fill');
        morphs[8] = dom.createAttrMorph(element8, 'fill');
        morphs[9] = dom.createAttrMorph(element9, 'fill');
        morphs[10] = dom.createAttrMorph(element10, 'fill');
        morphs[11] = dom.createAttrMorph(element11, 'fill');
        morphs[12] = dom.createAttrMorph(element12, 'fill');
        morphs[13] = dom.createAttrMorph(element13, 'fill');
        morphs[14] = dom.createAttrMorph(element14, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","class",["concat",["inventory-status-button laboklin-status-",["get","cssClasses",["loc",[null,[1,144],[1,154]]]]," ",["get","cssClass",["loc",[null,[1,159],[1,167]]]]," state-button cursor"]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,8],[3,18]]]]],[],0,null,["loc",[null,[3,2],[5,9]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[7,63],[7,68]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[23,63],[23,68]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[24,22],[24,27]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[25,22],[25,27]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[26,22],[26,27]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[27,22],[27,27]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[28,22],[28,27]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[29,22],[29,27]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[30,22],[30,27]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[31,22],[31,27]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[32,22],[32,27]]]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});