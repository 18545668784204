define('jason-frontend/components/editor-preview-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    url: null,
    showLoading: true,
    actions: {
      reset: function reset() {
        this.set('showLoading', true);
        this.set('url', null);
      },
      load: function load(url) {
        this.set('showLoading', false);
        this.set('url', url);
        var success = new PDFObject({
          url: this.get('url'),
          width: '100%',
          height: '800px'
        }).embed("editor-pdf-preview");
      }
    }
  });

});