define('jason-frontend/helpers/tax-sum', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.taxSum = taxSum;

  function taxSum(params) {

    var param0 = String(params[0]).replace(',', '.') || 0;
    var param1 = String(params[1]).replace(',', '.') || 0;
    var param2 = String(params[2]).replace(',', '.') || 0;
    var param3 = String(params[3]).replace(',', '.') || 0;
    var param4 = params[4] ? String(params[4]).replace(',', '.') : 0;
    var param5 = params[5] ? String(params[5]).replace(',', '.') : 0;
    var param6 = params[6] ? String(params[6]).replace(',', '.') : 0;

    return Math.round((parseFloat(param0) + parseFloat(param1) + parseFloat(param2) + parseFloat(param3) + parseFloat(param4) + parseFloat(param5) + parseFloat(param6)) * 100) / 100;
  }

  exports['default'] = Ember['default'].Helper.helper(taxSum);

});