define('jason-frontend/templates/components/icon-bubble', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/icon-bubble.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 19 19");
        var el2 = dom.createElement("path");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"d","M9.5,16.8888889 C8.59130435,16.8888889 7.6826087,16.7622222 6.81521739,16.5511111 L3.7173913,18.7044444 L3.7173913,15.1155556 C1.44565217,13.5533333 0,11.1466667 0,8.44444444 C0,3.8 4.25434783,0 9.5,0 C14.7456522,0 19,3.8 19,8.44444444 C19,13.0888889 14.7456522,16.8888889 9.5,16.8888889 L9.5,16.8888889 Z M9.5,0.844444444 C4.70869565,0.844444444 0.826086957,4.26444444 0.826086957,8.44444444 C0.826086957,11.02 2.31304348,13.3 4.54347826,14.6933333 L4.54347826,15.4955556 L4.54347826,17.1844444 L5.86521739,16.2555556 L5.82391304,16.2555556 L6.5673913,15.6222222 C7.47608696,15.9177778 8.4673913,16.0866667 9.5,16.0866667 C14.2913043,16.0866667 18.173913,12.6666667 18.173913,8.48666667 C18.173913,4.26444444 14.2913043,0.844444444 9.5,0.844444444 L9.5,0.844444444 Z");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element1, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","width",["loc",[null,[1,50],[1,55]]]]]]],
        ["attribute","height",["concat",[["get","width",["loc",[null,[1,69],[1,74]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[1,112],[1,117]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});