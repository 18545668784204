define('jason-frontend/helpers/is-deposit-entry', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isDepositEntry = isDepositEntry;

  function isDepositEntry(params) {
    var entry = params[0];

    if (entry && entry.get('article') && entry.get('article').get('name') === 'Guthaben') {
      return true;
    }
    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(isDepositEntry);

});