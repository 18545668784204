define('jason-frontend/components/button-auto', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'top',
    timer: 0,
    showStroke: false,
    content: 'Rechnung wird automatisch per Email verschickt',
    theme: 'tooltipster-punk'
  });

});