define('jason-frontend/routes/users/new', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return this.store.createRecord('user');
    },
    actions: {
      create: function create() {
        var self = this;
        this.controller.get('model').save().then(function () {
          self.transitionTo('users.index');
        });
      }
    }
  });

});