define('jason-frontend/components/toggle-favorite-button', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    cssClass: 'btn-default',
    selected: "false",
    fireAction: false,
    actions: {
      toggle: function toggle() {
        var el = this.get('element'),
            $btn = $('.btn', el);

        if ($btn.hasClass('btn-default')) {

          this.set('cssClass', 'btn-primary');
          this.set('selected', 'true');
          if (this.get('fireAction')) {
            this.sendAction('toggle', true);
          }
        } else {
          this.set('cssClass', 'btn-default');
          this.set('selected', 'false');
          if (this.get('fireAction')) {
            this.sendAction('toggle', false);
          }
        }
      }
    },
    becomeFocused: (function () {
      if (this.selected === "true" || this.selected === true) {
        this.set('cssClass', 'btn-primary');
        this.set('selected', 'true');
      }
    }).on('didInsertElement')
  });

});