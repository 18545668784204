define('jason-frontend/templates/practicemgmt/treatment-future-action', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 7,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/treatment-future-action.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context primary");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Vorlage hinzufügen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element1);
          return morphs;
        },
        statements: [
          ["element","action",["addTemplate",["get","selectedFutureId",["loc",[null,[4,32],[4,48]]]]],[],["loc",[null,[4,9],[4,50]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 4
            },
            "end": {
              "line": 12,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/treatment-future-action.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context primary");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Position hinzufügen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [
          ["element","action",["addArticle",["get","selectedFutureId",["loc",[null,[9,31],[9,47]]]]],[],["loc",[null,[9,9],[9,49]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 46,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/practicemgmt/treatment-future-action.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","context-bar grid");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","buttons-box col l-24/24");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","card animated box edit-future-action with-context-bar");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element2,1,1);
        morphs[1] = dom.createMorphAt(element2,2,2);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","eq",[["get","selectedFuture",["loc",[null,[3,14],[3,28]]]],"add_template"],[],["loc",[null,[3,10],[3,44]]]]],[],0,null,["loc",[null,[3,4],[7,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectedFuture",["loc",[null,[8,14],[8,28]]]],"add_article"],[],["loc",[null,[8,10],[8,43]]]]],[],1,null,["loc",[null,[8,4],[12,11]]]],
        ["inline","treatment-future-action-form",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[18,12],[18,17]]]]],[],[]],"users",["subexpr","@mut",[["get","users",["loc",[null,[19,12],[19,17]]]]],[],[]],"nextExecution",["subexpr","@mut",[["get","nextExecution",["loc",[null,[20,20],[20,33]]]]],[],[]],"nextExecutionTime",["subexpr","@mut",[["get","nextExecutionTime",["loc",[null,[21,24],[21,41]]]]],[],[]],"refererId",["subexpr","@mut",[["get","refererId",["loc",[null,[22,16],[22,25]]]]],[],[]],"future",["subexpr","@mut",[["get","future",["loc",[null,[23,13],[23,19]]]]],[],[]],"timeUnits",["subexpr","@mut",[["get","timeUnits",["loc",[null,[24,16],[24,25]]]]],[],[]],"apiType",["subexpr","@mut",[["get","apiType",["loc",[null,[25,14],[25,21]]]]],[],[]],"patientAppointmentCategories",["subexpr","@mut",[["get","patientAppointmentCategories",["loc",[null,[26,35],[26,63]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[27,12],[27,17]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[28,15],[28,23]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[29,14],[29,21]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[30,17],[30,27]]]]],[],[]],"futureTypes",["subexpr","@mut",[["get","futureTypes",["loc",[null,[31,18],[31,29]]]]],[],[]],"futureTriggerTypes",["subexpr","@mut",[["get","futureTriggerTypes",["loc",[null,[32,25],[32,43]]]]],[],[]],"setFutureType","setFutureType","addArticle","addArticle","addTemplate","addTemplate","clickBack","clickBack","deleteProduct","deleteProduct","changeQuantity","changeQuantity","changeApplied","changeApplied","changeGrossPrice","changeGrossPrice","save","save","actionReceiver",["subexpr","@mut",[["get","treatmentFutureActionForm",["loc",[null,[42,21],[42,46]]]]],[],[]]],["loc",[null,[17,4],[43,6]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});