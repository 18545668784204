define('jason-frontend/helpers/is-user-in', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isUserIn = isUserIn;

  function isUserIn(params) {
    var list = params[0],
        idToCheck = params[1];

    if (list) {
      return list.split(',').indexOf(idToCheck) >= 0;
    }
    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(isUserIn);

});