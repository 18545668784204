define('jason-frontend/routes/inventory/delivery-note-details', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {

      var id = params.params['inventory.delivery-note-details'].id;
      controller.set('id', id);
      controller.set('page', 1);
      this.applyFilter();

      this.store.find('serviceunit', { paged: false }).then(function (items) {
        controller.set('serviceunits', items.sortBy('name'));
      });

      controller.set('warehouses', this.store.find('inventory-warehouse'));
      controller.set("model", model);
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller');
      $.ajax({
        url: "/api/deliveryNotes/" + controller.id + "?page=" + controller.page,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('model', data.deliveryNote);
        controller.set('content.page', controller.page);
        controller.set('content.totalPages', data.meta.total_pages);
      });
    },
    actions: {
      updatePage: function updatePage() {
        this.applyFilter();
      },
      ignoreItem: function ignoreItem(entryId, requestId) {
        var self = this,
            controller = this.get('controller');
        $.ajax({
          url: "/api/inventoryRequests/ignoreDeliveryNoteEntry/" + requestId + "/" + entryId,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Eintrag', 'als nicht Lager-relevant markiert');

          $.ajax({
            url: "/api/deliveryNotes/" + requestId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.applyFilter();
          });
        }, function () {});
      },
      unignoreItem: function unignoreItem(entryId, requestId) {
        var self = this,
            controller = this.get('controller');
        $.ajax({
          url: "/api/inventoryRequests/unignoreDeliveryNoteEntry/" + requestId + "/" + entryId,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Eintrag', 'wurde als relevant markiert');
          self.applyFilter();
        }, function () {});
      },
      assignArticle: function assignArticle(id, entryId) {
        this.transitionTo('cash.add_article', 'deliveryNote', id, entryId);
      },
      bookinItem: function bookinItem(data, supplierRow, requestId) {
        var self = this,
            controller = this.get('controller');

        var row = $('div#entry_' + data.id),
            qty = row.find('input[name="packageQuantity"]').val();

        Ember['default'].set(data, 'packageQuantity', qty);

        $.each(data.positions, function (index, value) {
          Ember['default'].set(value, 'qty', 0);
        });

        $.ajax({
          url: "/api/inventoryRequests/bookinDeliveryNote/" + requestId + "?page=" + controller.get('page'),
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (result) {
          jason.notifiction.notifiy('Eingebucht', 'Lagerbestand eingebucht und Etikett erzeugt');
          self.applyFilter();
          // controller.set('model', result.deliveryNote);
          // controller.set('content.page', controller.page);
          // controller.set('content.totalPages', data.meta.total_pages);
        }, function () {});
      },
      printBarcode: function printBarcode(id) {
        var viewUrl = '/api/inventoryRequests/barcodes/' + id + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken();

        var myWindow = window.open(viewUrl, 'Drucken', 'width=600,height=600');

        var is_chrome = Boolean(window.chrome);
        if (is_chrome) {
          myWindow.onload = function () {

            myWindow.moveTo(0, 0);
            myWindow.resizeTo(640, 480);
            myWindow.print();
          };
        } else {
          myWindow.print();
          myWindow.close();
        }
      },
      printAllBarcodes: function printAllBarcodes(id, entryId) {

        var viewUrl = '/api/inventoryRequests/barcodes/all/' + id + '/' + entryId + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken() + '&referer=deliverynote';

        var myWindow = window.open(viewUrl, 'Drucken', 'width=600,height=600');

        var is_chrome = Boolean(window.chrome);
        if (is_chrome) {
          myWindow.onload = function () {

            myWindow.moveTo(0, 0);
            myWindow.resizeTo(640, 480);
            myWindow.print();
          };
        } else {
          myWindow.print();
          myWindow.close();
        }
      }
    }
  });

});