define('jason-frontend/controllers/purchases/add-product', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Produkt hinzufügen",
    breadCrumbPath: "purchases.add_product",
    queryParams: ["page", "perPage", "campaignId"],
    invoice: null,
    products: null,
    sidebarActive: true,
    filterName: null,
    filterSupplier: 'all',
    showFilters: true,
    filterManufacturer: null,
    partnerCategoriesThere: false,
    filterFavorites: false,
    sortBy: null,
    sortDir: null,
    categoryFilterClass: 'hidden',
    selectedCategoryFilter: null,
    promotionActiveFilter: null,
    selectedPartnerCategoryFilter: null,
    categories: null,
    filterAll: true,
    filterFavs: false,
    list: true,
    myStickyOptions: {
      topSpacing: 80,
      zIndex: 9
    },
    actions: {
      toCreateProduct: function toCreateProduct(pzn, name) {
        var self = this;
        setTimeout(function () {

          var newRecord = self.store.createRecord('purchaseProduct');
          newRecord.foreignId = pzn;
          newRecord.name = name;

          self.store.fetch('serviceunit', 1).then(function (unit) {
            newRecord.set('serviceunit', unit);
          });
          self.store.fetch('tax', 'tax20').then(function (tax) {
            newRecord.set('tax', tax);
          });

          self.get('editPurchaseProductPanel').send('loadData', newRecord, self.store.find('tax'), self.store.find('serviceunit'), self.store.find('productCategory'));

          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            alignTop: true,
            items: {
              src: '#modal-edit-purchase-product-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 500);
      },
      openPznCheckPanel: function openPznCheckPanel(pzn) {
        var self = this;

        self.get('checkPzn').send('reset');
        self.get('checkPzn').send('load', self.store);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          alignTop: true,
          items: {
            src: '#modal-create-product-pzn-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      checkPzn: function checkPzn(id) {
        this.transitionTo('purchases.detail', id, 0);
      },
      checkLaboklinPanel: function checkLaboklinPanel(product, orderId, infoId, supplierId, modelName) {
        if (supplierId === 'laboklin') {

          $('form#laboklinForm').attr('price-info-id', infoId);
          $('form#laboklinForm').attr('product-id', product.id);

          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-form-laboklin-chooser'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        } else {
          var qty = $('input[name=' + product.id + '_return_qty_spinner]').val();
          this.send('addToCart', product.id, orderId, infoId, modelName, 0, qty);
        }
      },
      newLaboklinForm: function newLaboklinForm() {
        setTimeout(function () {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-form-laboklin-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 700);
      }
    },
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    perPage: 16,
    watchName: (function () {
      this.set('page', 1);
    }).observes('filterName'),
    watchPartnerCategory: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('selectedPartnerCategoryFilter'),
    watchCategory: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('selectedCategoryFilter'),
    watchFavs: (function () {
      if (this.get('filterFavs')) {
        this.set('filterAll', false);
      } else {
        this.set('filterAll', true);
      }
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterFavs'),
    watchfilterAll: (function () {
      if (this.get('filterAll')) {
        this.set('filterFavs', false);
      } else {
        this.set('filterFavs', true);
      }
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterAll'),
    watchSupplier: (function () {
      this.set('page', 1);
      this.send('applyFilter');
      this.set('selectedPartnerCategoryFilter', null);
      this.set('partnerCategories', null);
      this.send('updatePartnerCategories');
    }).observes('filterSupplier')
  });

});