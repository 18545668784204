define('jason-frontend/helpers/format-number-german-dots', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.formatNumberGermanDots = formatNumberGermanDots;

  function formatNumberGermanDots(params) {
    return new String(params[0]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  exports['default'] = Ember['default'].Helper.helper(formatNumberGermanDots);

});