define('jason-frontend/models/inventory-check', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    date: DS['default'].attr('string'),
    time: DS['default'].attr('string'),
    result: DS['default'].attr('string'),
    user: DS['default'].belongsTo('user'),
    entries: DS['default'].hasMany('inventory-check-entry')
  });

});