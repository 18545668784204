define('jason-frontend/components/customer-consents', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    tagName: 'div',
    load: function load(id) {
      var self = this;
      this.set('customerId', id);
      $.ajax({
        url: "/api/customers/" + id + "/consents",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('items', data.consentLog);
      });
    },
    actions: {

      load: function load(id) {
        this.load(id);
      },
      downloadWarning: function downloadWarning(id, invoiceId) {
        window.open('/api/customers/downloadWarning/' + id);

        var deferred = Ember['default'].$.ajax({
          url: "/api/invoices/" + invoiceId + "/preview",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          window.location.href = window.location.origin + '/api/invoices/show/' + data.responseText;
        }, function (data) {
          window.location.href = window.location.origin + '/api/invoices/show/' + data.responseText;
        });
        return Ember['default'].RSVP.resolve(deferred);
      },
      deleteConsentLog: function deleteConsentLog(id) {
        var self = this;
        $.ajax({
          url: "/api/customers/consentLog/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.load(self.get('customerId'));
        }, function (data) {
          self.load(self.get('customerId'));
        });
      },
      // downloadConsentLogDocument: function (id, filename) {
      //   $.ajax({
      //     url: "/api/customers/consentLog/file/" + id,
      //     method: "GET",
      //     contentType: "application/octet-stream",
      //     dataType: "arraybuffer",
      //     headers: {
      //       'X-AUTH-TOKEN': API.getToken()
      //     }
      //   }).then(
      //     function (data) {
      //       var blob = new Blob([data], { type: "application/octet-stream" });
      //       var link = document.createElement('a');
      //       var url = window.URL.createObjectURL(blob);
      //
      //       link.href = url;
      //       link.download = filename;
      //
      //       // Append the link to the body (required for Firefox)
      //       document.body.appendChild(link);
      //       link.click();
      //       document.body.removeChild(link);
      //       window.URL.revokeObjectURL(url);
      //     }
      //   );
      // }

      downloadConsentLogDocument: function downloadConsentLogDocument(id, filename) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          var token = data.responseText;

          window.open('/api/customers/consentLog/file?token=' + data.responseText);
        });
      }
    }
  });

});