define('jason-frontend/helpers/get-latest-patient-info', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getLatestPatientInfo = getLatestPatientInfo;

  function getLatestPatientInfo(params) {

    var entries = params[0].entries,
        patientId = params[1].id;

    var returnData = null;

    $.each(entries, function (e, data) {
      if (e == patientId) {
        returnData = data.latestTreatment;
      }
    });
    return returnData;
  }

  exports['default'] = Ember['default'].Helper.helper(getLatestPatientInfo);

});