define('jason-frontend/routes/labprofiles/index', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find('xray-profile');
    },
    actions: {}

  });

});