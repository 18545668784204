define('jason-frontend/controllers/services/create', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Anlegen",
    breadCrumbPath: "services.create",
    actions: {
      triggerSubmit: function triggerSubmit() {
        $('form#serviceEdit').submit();
      },
      create: function create() {

        var self = this,
            refLine = $('.table__body');

        setTimeout(function () {
          var model = self.get('model');
          var price = model.get('priceExVat');
          if (price) {
            model.set('priceExVat', (price + '').replace(",", "."));
          }

          var patientCategory = new Array();
          $('form#serviceEdit input[type=checkbox]:checked').each(function () {
            patientCategory.push({
              id: $(this).val()
            });
          });

          if (!model.get('unit') || model.get('unit') && !model.get('unit').get('id')) {
            jason.notifiction.error('Fehler', 'Bitte wählen Sie eine Verrechnungseinheit');
            return;
          }

          var price = $('input.product-price', refLine).val();

          if (!price) {
            price = "0";
          }

          var product = new Object();
          product.name = model.get('name');
          product.priceExVat = price.replace(",", ".");
          product.description = model.get('description');
          product.referenceNumber = model.get('referenceNumber');
          product.gotNumber = model.get('gotNumber');
          if (model.get('category')) {
            product.category = { id: model.get('category').id };
          }
          product.tax = { id: model.get('tax').id, name: model.get('tax').get('name') };
          product.unit = { id: model.get('unit').get('id'), name: model.get('unit').get('name') };
          if (model.get('distributor')) {
            product.distributor = { id: model.get('distributor').get('id'), name: model.get('distributor').get('name') };
          }
          product.patientCategories = patientCategory;

          // if ($('a.fav-button')) {
          //   product.favorite = $('a.fav-button').data('selected') == true;
          // }

          if (model.get('labProfile')) {
            product.labProfile = { id: model.get('labProfile').id };
          }

          product.favorite = model.get('favorite');
          product.gotSmall = model.get('gotSmall');
          product.gotHorse = model.get('gotHorse');
          product.gotUse = model.get('gotUse');
          product.gotPara8 = model.get('gotPara8');
          product.tags = model.get('tags');

          $.ajax({
            url: "/api/services",
            method: "POST",
            data: JSON.stringify(product),
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.transitionTo('services.index');
          }, function (error) {});
        }, 1000);
      }
    }
  });

});