define('jason-frontend/components/digital-helper-qr-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      copyToClipboard: function copyToClipboard(text) {
        if (window.clipboardData && window.clipboardData.setData) {
          // IE specific code path to prevent textarea being shown while dialog is visible.
          return clipboardData.setData("Text", text);
        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
          var textarea = document.createElement("textarea");
          textarea.textContent = text;
          textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
          $('#modal-digital-helper-qr-panel').append(textarea);
          textarea.select();
          try {
            return document.execCommand("copy"); // Security exception may be thrown by some browsers.
          } catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
          } finally {
            $(textarea).remove();
          }
        }
      },
      load: function load(id) {
        var self = this;
        $.ajax({
          url: "/api/treatments/digitalHelper/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          $('#result').html('<img src="data:image/png;base64,' + data.digitalHelperQr.payload + '" />');
          self.set('url', data.digitalHelperQr.url);
        }, function (responseData) {});
      }
    }
  });

});