define('jason-frontend/components/datacare-delivery-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    actions: {
      load: function load(order) {
        this.set('data', order);
      },
      confirm: function confirm(order) {
        this.sendAction('confirm', order);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      toggleCheckbox: function toggleCheckbox(entry) {

        var self = this,
            form = $('#modal-form-supplier-panel');

        if (entry.get('confirmOrder')) {
          entry.set('confirmOrder', false);
        } else {
          entry.set('confirmOrder', true);
        }

        setTimeout(function () {
          var oneChecked = false;
          $('input[type=checkbox]:checked', form).each(function (e) {
            oneChecked = true;
          });
          if (oneChecked) {
            self.set('toggleVisible', true);
          } else {
            self.set('toggleVisible', false);
          }
        }, 150);
      }
    },
    becomeFocused: (function () {
      var self = this,
          form = $('#modal-form-supplier-panel'),
          oneChecked = false;

      $.each(this.get('data').get('subOrders').currentState, function (index, value) {
        oneChecked = true;
      });

      if (oneChecked) {
        self.set('toggleVisible', true);
      } else {
        self.set('toggleVisible', false);
      }
    }).observes('data')
  });

});