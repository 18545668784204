define('jason-frontend/controllers/welcome/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Dashboard",
    selectedTabPractice: "upcoming",
    selectedTabReminders: "past",
    selectedTabInvoices: "open",
    hideRevenueChart: false,
    hideCustomerChart: false,
    actions: {}
  });

});