define('jason-frontend/components/laboklin-labs', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      load: function load(labItems) {
        this.set('labItems', labItems);
        if (labItems.length > 0) {
          this.set('detailsShown', true);
        }
      },
      toggleLaboklinLabs: function toggleLaboklinLabs(value) {
        this.set('detailsShown', value);
      },
      edit: function edit(treatmentPatientId, id) {
        this.sendAction('editLab', treatmentPatientId, id);
      },
      unlink: function unlink(entryId, formId) {
        this.sendAction('unlink', entryId, formId);
      },
      submitLaboklin: function submitLaboklin(treatmentPatientId, formId) {
        this.sendAction('submitLaboklin', treatmentPatientId, formId);
      },
      downloadBarcode: function downloadBarcode(treatmentPatientId, formId) {
        this.sendAction('downloadBarcode', treatmentPatientId, formId);
      },
      downloadPdf: function downloadPdf(treatmentPatientId, formId) {
        this.sendAction('downloadPdf', formId);
      },
      "delete": function _delete(formId) {
        this.sendAction('delete', formId);
      }
    }
  });

});