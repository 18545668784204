define('jason-frontend/models/emergency-service', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    start: DS['default'].attr('date'),
    startString: DS['default'].attr(''),
    startStringTime: DS['default'].attr(''),
    end: DS['default'].attr('date'),
    endString: DS['default'].attr(''),
    endStringTime: DS['default'].attr(''),
    comment: DS['default'].attr('string')
  });

});