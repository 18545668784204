define('jason-frontend/models/article-import-history', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    created: DS['default'].attr('date'),
    oldPurchasePrice: DS['default'].attr('number'),
    newPurchasePrice: DS['default'].attr('number')
  });

});