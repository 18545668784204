define('jason-frontend/controllers/transactions/cashdesk', ['exports', 'ember', 'moment'], function (exports, Ember, moment) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Kassenjournal",
    breadCrumbPath: "transactions.cashdesk",
    filterType: "all",
    selectedRegister: null,
    sortDir: "DESC",
    classFilterTypeAll: "primary",
    classFilterTypeIn: "default",
    classFilterTypeOut: "default",
    filterPaymentAll: true,
    filterPaymentCash: false,
    filterPaymentCashcard: false,
    filterPaymentCreditcard: false,
    filterPaymentVoucher: false,
    filterPaymentDeposit: false,
    filterPaymentBanktransfer: false,
    classFilterPaymentAll: "primary",
    classFilterPaymentCash: "default",
    classFilterPaymentCashcard: "default",
    classFilterPaymentCreditcard: "default",
    classFilterPaymentVoucher: "default",
    classFilterPaymentBanktransfer: "default",
    classFilterPaymentDeposit: "default",
    pickDate: moment['default']().format("DD. MM. YYYY") + " - " + moment['default']().format("DD. MM. YYYY"),

    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    perPage: 20,
    to: null,
    from: null,

    watchCashRegister: (function () {
      this.send('applyFilter');
    }).observes('selectedRegister'),
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('pickDate')
  });

});