define('jason-frontend/controllers/mandants/editadmin', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Meine Mandanten",
    breadCrumbPath: "mandants.editadmin",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),
    page: 1,
    perPage: 20,
    editFee: null,
    selectTab: 'licences',
    actions: {
      changePurchaseEmail: function changePurchaseEmail(model) {

        var data = {
          id: model.id,
          purchaseEmail: model.get('purchaseEmail')
        };

        Ember['default'].$.ajax({
          url: "/api/mandants/changePurchaseEmail",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function () {
          jason.notifiction.systemError('Fehler', 'Speichern nicht möglich');
        });
      },
      openFeePanel: function openFeePanel(mandantId, feeId, type) {

        $('#mandantFeeForm').attr('data-fee-id', feeId);
        $('#mandantFeeForm').attr('data-type-id', type);

        var self = this;
        if (feeId > 0) {
          $.ajax({
            url: "/api/mandantFees/" + feeId,
            method: "GET",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.set('editFee', data.mandantFee);
          });
        } else {
          self.set('editFee', null);
          $('#mandantFeeForm').attr('data-fee-id', 0);
        }

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-form-mandant-fee-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openInvoiceTextPanel: function openInvoiceTextPanel(mandantId, month, year) {

        var self = this;

        $.ajax({
          url: "/api/mandantInvoiceTexts/" + mandantId + "/" + month + "/" + year,
          method: "GET",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('invoiceTextPanel').send('load', data.mandantInvoiceText, month, year, mandantId);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-form-mandant-invoice-text-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openCashbackPanel: function openCashbackPanel(mandantId, cashbackId) {

        $('#cashbackForm').attr('data-cashback-id', cashbackId);

        var self = this;
        if (cashbackId > 0) {
          $.ajax({
            url: "/api/mandants/cashbackSetting/" + cashbackId,
            method: "GET",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.set('editCashback', data.mandantSupplierSetting);
          });
        } else {
          self.set('editCashback', null);
          $('#cashbackForm').attr('data-cashback-id', 0);
        }

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-form-cashback-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openCustomerGroupsPanel: function openCustomerGroupsPanel(mandantId, customerGroupId) {

        $('#customerGroupForm').attr('data-customerGroup-id', customerGroupId);

        var self = this;
        if (customerGroupId > 0) {
          $.ajax({
            url: "/api/mandants/customerGroupSetting/" + customerGroupId,
            method: "GET",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.set('editCustomerGroup', data.mandantSupplierSetting);
          });
        } else {
          self.set('editCustomerGroup', null);
          $('#customerGroupForm').attr('data-customerGroup-id', 0);
        }

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-form-customerGroup-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    }
  });

});