define('jason-frontend/controllers/inventory/agg-stock', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Lagerbestand",
    breadCrumbPath: "inventory.agg-stock",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    showLoading: false,
    perPage: 20,
    filterStatus: 'created,used',
    myStickyOptionsTableHead: {
      topSpacing: 160,
      zIndex: 9
    },
    watchWarehouse: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterWarehouse'),
    actions: {
      openOutgoing: function openOutgoing(id) {
        var src = '#modal-inventory-outgoing-panel';
        var self = this;

        $.ajax({
          url: "/api/inventoryStocks/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('inventoryOutgoingPanel').send('load', data.inventoryStock);
        }, function () {});

        setTimeout(function () {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: src
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 1000);
      },
      openCorrectionPanel: function openCorrectionPanel(id) {

        var self = this;

        $.ajax({
          url: "/api/inventoryStocks/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('inventoryCorrectionPanel').send('load', data.inventoryStock);
        }, function () {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-inventory-correction-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      scan: function scan(nr) {

        this.get('quickScanProductPanel').send('loadWithoutDirect');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-quick-scan-product-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      transfer: function transfer(id) {
        var self = this;
        setTimeout(function () {

          self.get('changeWarehousePanel').send('load', self.store.find('inventory-warehouse'), id);

          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-change-warehouse-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 1000);
      },
      showStock: function showStock(productId, productName) {
        var self = this;

        $.ajax({
          url: "/api/inventoryStocks/inventoryProduct/" + productId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          var first = null;
          if (data.inventoryStock) {
            first = data.inventoryStock[0];
          }
          self.get('stockStockDetailPanel').send('load', data.inventoryStock, productName, productId, first);
        }, function () {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-show-stock-detail-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    }
  });

});