define('jason-frontend/helpers/show-treatment-tile', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.showTreatmentTile = showTreatmentTile;

  function showTreatmentTile(params) {
    var treatment = params[0],
        selectedUsers = params[1];

    var showUser = true;
    if (treatment.treatmentPatients) {
      $.each(treatment.treatmentPatients, function (index, value) {
        if (selectedUsers && selectedUsers.length) {
          var doctor = selectedUsers.some(function (u) {
            return value.doctor && parseInt(u) === parseInt(value.doctor.id);
          });
          var assistent = selectedUsers.some(function (u) {
            return value.assistant && parseInt(u) === parseInt(value.assistant.id);
          });
          showUser = assistent || doctor;
        }
      });
    }
    return showUser;
  }

  exports['default'] = Ember['default'].Helper.helper(showTreatmentTile);

});