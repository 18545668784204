define('jason-frontend/models/document', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    internalId: DS['default'].attr('number'),
    created: DS['default'].attr('date'),
    name: DS['default'].attr('string'),
    containerObject: DS['default'].attr(),
    type: DS['default'].attr('string'),
    mime: DS['default'].attr('string'),
    mandantId: DS['default'].attr(),
    appEditable: DS['default'].attr(),
    description: DS['default'].attr('string'),
    flatPath: DS['default'].attr(''),
    path: DS['default'].attr(''),
    tags: DS['default'].attr(''),
    shared: DS['default'].attr('boolean'),
    hideApp: DS['default'].attr(''),
    items: DS['default'].attr(''),
    patientId: DS['default'].attr(),
    fileCount: DS['default'].attr(),
    customerId: DS['default'].attr(),
    rootFolderId: DS['default'].attr(),
    etiga: DS['default'].attr(),
    year: DS['default'].attr(),
    month: DS['default'].attr(),
    internalType: DS['default'].attr(),
    user: DS['default'].attr()
  });

});