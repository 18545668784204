define('jason-frontend/components/documents-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    externalContext: null,
    patientId: null,
    year: null,
    month: null,
    loadPanels: true,
    customerId: null,
    useHover: true,
    sortAttribute: 'name',
    sortDir: 'ASC',
    sortBy: 'name',
    filterFileType: null,
    selectOnly: false,
    showActionButtons: true,
    smallView: false,
    store: Ember['default'].inject.service(),
    rootFolder: null,
    previousFolder: null,
    showBreadcrumbs: true,
    perPage: 10,
    prefix: 'empty',
    ctx: null,
    showSearch: true,
    showUpload: true,
    applyFilter: function applyFilter() {
      var filterName = this.get('filterName'),
          sortDir = this.get('sortDir'),
          sortBy = this.get('sortBy'),
          tags = this.get('tags');

      var self = this;
      this.externalContext.findPaged('document', {
        filterName: filterName,
        rootFolder: this.rootFolder,
        patientId: this.patientId,
        customerId: this.customerId,
        filterFileType: this.filterFileType,
        year: this.year,
        month: this.month,
        ctx: this.ctx,
        filterTags: tags,
        sortDir: sortDir,
        sortBy: sortBy,
        agent: 'desktop',
        perPage: 10
      }).then(function (entries) {
        self.set('model', entries);
        self.set('content', entries.meta);
        self.set('breads', entries.meta.breads);
        if (entries.meta.upFolder !== 'empty') {
          self.set('upFolder', entries.meta.upFolder);
        } else {
          self.set('upFolder', null);
        }
      });
    },
    actions: {
      hideLabForApp: function hideLabForApp(id) {
        var self = this;
        $.ajax({
          url: "/api/labs/" + id + "/hideLabDocument",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'ausgeblendet');
          self.applyFilter();
        }, function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'ausgeblendet');
          self.applyFilter();
        });
      },
      showLabForApp: function showLabForApp(id) {
        var self = this;
        $.ajax({
          url: "/api/labs/" + id + "/showLabDocument",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'eingeblendet');
          self.applyFilter();
        }, function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'eingeblendet');
          self.applyFilter();
        });
      },
      applyFilterWithTags: function applyFilterWithTags(tags) {
        this.set('tags', tags);
        this.applyFilter();
      },
      saveTags: function saveTags(document) {
        if (document) {
          var data = {
            internalId: document.internalId,
            tags: document.tags
          };

          var self = this;
          $.ajax({
            url: "/api/documents/" + document.internalId + "/tags",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(data),
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.applyFilter();
          }, function () {
            self.applyFilter();
          });
        }
      },
      downloadFolder: function downloadFolder() {
        window.open('/api/documents/downloadFolder/' + this.get('rootFolder') + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken() + '&patientId=' + this.get('patientId') + '&customerId=' + this.get('customerId') + '&year=' + this.get('year') + '&month=' + this.get('month'));
      },
      resetSearch: function resetSearch() {
        this.set('filterName', null);
        this.sendAction('updateSearch', this.get('showSearch'), this.get('filterName'), this.get('showUpload'), this.get('rootFolder'), this.get('patientId') || this.get('customerId'));
        this.applyFilter();
      },
      setSort: function setSort(attribute) {
        this.set('sortBy', attribute);
        if (this.get('sortDir') === 'ASC') {
          this.set('sortDir', 'DESC');
        } else {
          this.set('sortDir', 'ASC');
        }
        this.applyFilter();
      },
      externalSearch: function externalSearch(term) {
        this.set('filterName', term);
        this.applyFilter();
      },
      openDocumentForwardPanel: function openDocumentForwardPanel(id) {
        if (this.customerId) {
          this.get('forwardDocumentPanel').send('loadWithEmails', id, this.customerEmail);
        }

        var self = this;

        $.ajax({
          url: "/api/textModules?filterType=email&per_page=50",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('forwardDocumentPanel').send('loadTextmodules', data.textModule);
        }, function () {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-document-forward-panel' + this.get('prefix')
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      sendDocumentMail: function sendDocumentMail(id, data) {
        jason.notifiction.notifiy('Erfolgreich', 'Dokument wurde übermittelt');
        Ember['default'].$.ajax({
          url: "/api/patients/mailtodocument/" + id,
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {}, function () {});
      },
      downloadLaboklinRequest: function downloadLaboklinRequest(id) {

        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/treatmentPatients/downloadLaboklinPdf/' + API['default'].getMandant() + '/' + data.responseText);
        });
      },
      downloadLabResultIntern: function downloadLabResultIntern(id) {
        window.open('/api/labResults/download/' + id + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      downloadLabResultLaboklin: function downloadLabResultLaboklin(id) {

        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/treatmentPatients/downloadLaboklin/' + API['default'].getMandant() + '/pdf/' + data.responseText);
        });
      },
      downloadVetconnect: function downloadVetconnect(id) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/labResults/downloadOriginal/' + API['default'].getMandant() + '/' + data.responseText);
        });
      },
      downloadInvoice: function downloadInvoice(invoiceId) {
        var deferred = Ember['default'].$.ajax({
          url: "/api/invoices/" + invoiceId + "/preview",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          window.open('/api/invoices/show/' + data.responseText);
        }, function (data) {
          window.open('/api/invoices/show/' + data.responseText);
        });
        return Ember['default'].RSVP.resolve(deferred);
      },
      downloadDeliveryNote: function downloadDeliveryNote(orderId) {
        this.sendAction('gotoDeliveryNote', orderId);
      },
      forwardLab: function forwardLab(orderId) {
        this.sendAction('forwardLab', orderId);
      },
      downloadOrder: function downloadOrder(orderId) {
        var deferred = $.ajax({
          url: "/api/purchaseOrders/" + orderId + "/preview",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/purchaseOrders/show/' + orderId);
        });
      },
      share: function share(id, shared) {
        var self = this;
        $.ajax({
          url: "/api/media/" + id + "/share/" + shared,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Freigabe', 'erfolgreich geändert');
          self.applyFilter();
        }, function (error) {});
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      "delete": function _delete(id) {
        var self = this;
        $.ajax({
          url: "/api/media/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Dokument', 'erfolgreich gelöscht');
          self.applyFilter();
        }, function (error) {});
      },
      openContainerPreview: function openContainerPreview(container) {

        this.get('documentArchivePreviewPanel').send('load', container);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-document-archive-preview-panel' + this.get('prefix')
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      downloadFile: function downloadFile(id) {
        window.open('/api/media/' + id + '/download?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      downloadFileContainer: function downloadFileContainer(mediaSubItem) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: mediaSubItem
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/media/item/' + mediaSubItem + '/download?rat=' + data.responseText);
        });
      },
      preview: function preview(id) {
        if (this.selectOnly) {
          this.sendAction('select', id);
        } else {
          this.get('documentPreviewPanel').send('load', id);

          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-document-preview-panel' + this.get('prefix')
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }
      },
      saveDocument: function saveDocument(data) {

        Ember['default'].set(data, 'created', moment(data.created + ' ' + data.createdTime, 'DD. MM. YYYY HH:mm').unix() * 1000);
        var self = this;
        $.ajax({
          url: "/api/documents/" + data.internalId,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          self.applyFilter();
        }, function () {});
      },
      editDocument: function editDocument(id) {

        var self = this;

        var deferred = $.ajax({
          url: "/api/documents/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('documentEditPanel').send('load', data.documentItem, self.get('externalContext'), self.get('prefix'));
        }, function (data) {
          self.get('documentEditPanel').send('load', data.documentItem, self.get('externalContext'), self.get('prefix'));
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-document-edit-panel' + this.get('prefix')
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      reloadDocumentsPanel: function reloadDocumentsPanel() {
        this.applyFilter();
      },
      openTagPanel: function openTagPanel(document) {
        var self = this;
        var deferred = $.ajax({
          url: "/api/documentTags",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          var deferred = $.ajax({
            url: "/api/documents/" + document.get('internalId'),
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (documentData) {
            self.get('documentTagPanel').send('load', documentData.documentItem, data.documentTag);
          }, function (data) {});
        }, function (data) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-document-tag-panel' + this.get('prefix')
          },
          callbacks: {
            close: function close() {
              self.get('documentTagPanel').send('save');
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openUploadPanel: function openUploadPanel(ctx, ctxId) {
        if (this.customerId) {
          ctx = 'customers';
          ctxId = this.customerId;
        }
        if (this.patientId) {
          ctx = 'patients';
          ctxId = this.patientId;
        }

        this.get('documentUploadPanel').send('load', ctx, ctxId, this.rootFolder, this.get('breads'));

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-document-upload-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      gotoFolderNonModel: function gotoFolderNonModel(folder) {

        this.set('showSearch', false);

        this.set('showUpload', false);
        var folderId = folder.internalId;

        if (folder.rootFolderId) {
          folderId = folder.rootFolderId;
        }

        if (folder.month !== 'null' && folder.month > 0) {
          this.month = folder.month;
        } else {
          this.month = null;
        }
        if (folder.year !== 'null' && folder.year > 0) {
          this.year = folder.year;
        } else {
          this.year = null;
        }

        if (folder.customerId !== 'null') {
          this.customerId = folder.customerId;
        } else {
          this.customerId = null;
        }
        if (folder.patientId !== 'null') {
          this.patientId = folder.patientId;
        } else {
          this.patientId = null;
        }

        if (folderId !== 'null') {
          this.previousFolder = this.rootFolder;
          this.rootFolder = folderId;
        } else {
          this.set('showSearch', false);
          this.rootFolder = null;
        }

        if (this.patientId != null || this.customerId != null) {
          this.set('showUpload', true);
          this.set('showSearch', true);
        }

        if (folder.id === 'folder3' || folder.id === '3') {
          this.set('showSearch', true);
          this.set('showUpload', false);
        }

        this.set('filterName', null);
        this.sendAction('updateSearch', this.get('showSearch'), this.get('filterName'), this.get('showUpload'), folder.id, this.get('patientId') || this.get('customerId'));

        if (this.rootFolder !== null && String(this.rootFolder).indexOf('3333') === 0) {
          this.set('sortDir', 'DESC');
          this.set('sortBy', 'created');
        }
        if (this.previousFolder != null && String(this.previousFolder).indexOf('3333') === 0) {
          this.set('sortDir', 'ASC');
          this.set('sortBy', 'created');
        }
        this.applyFilter();
      },
      gotoFolder: function gotoFolder(folder) {

        try {
          folder.get('internalId');
        } catch (e) {
          folder = this.get('model').get('firstObject');
        }

        this.set('showSearch', false);

        this.set('showUpload', false);
        var folderId = folder.get('internalId');

        if (folder.get('rootFolderId')) {
          folderId = folder.get('rootFolderId');
        }

        if (folder.get('month') !== 'null' && folder.get('month') > 0) {
          this.month = folder.get('month');
        } else {
          this.month = null;
        }
        if (folder.get('year') !== 'null' && folder.get('year') > 0) {
          this.year = folder.get('year');
        } else {
          this.year = null;
        }

        if (folder.get('customerId') !== 'null') {
          this.customerId = folder.get('customerId');
        } else {
          this.customerId = null;
        }
        if (folder.get('patientId') !== 'null') {
          this.patientId = folder.get('patientId');
        } else {
          this.patientId = null;
        }

        if (folderId !== 'null') {
          this.previousFolder = this.rootFolder;
          this.rootFolder = folderId;
        } else {
          this.set('showSearch', false);
          this.rootFolder = null;
        }

        if (this.patientId != null || this.customerId != null) {
          this.set('showUpload', true);
          this.set('showSearch', true);
        }

        if (folder.id === 'folder3' || folder.id === '3') {
          this.set('showSearch', true);
          this.set('showUpload', false);
        }

        this.set('filterName', null);
        this.sendAction('updateSearch', this.get('showSearch'), this.get('filterName'), this.get('showUpload'), folder.id, this.get('patientId') || this.get('customerId'));

        if (this.rootFolder !== null && String(this.rootFolder).indexOf('3333') === 0) {
          this.set('sortDir', 'DESC');
          this.set('sortBy', 'created');
        }
        if (this.previousFolder != null && String(this.previousFolder).indexOf('3333') === 0) {
          this.set('sortDir', 'ASC');
          this.set('sortBy', 'created');
        }
        this.applyFilter();
      },
      setFilterType: function setFilterType(filterFileType) {
        this.set('filterFileType', filterFileType);
      },
      load: function load(externalContext) {
        this.externalContext = externalContext;
        this.ctx = null;
        this.set('showSearch', false);
        this.set('showUpload', false);
        this.sendAction('updateSearch', this.get('showSearch'), this.get('filterName'), this.get('showUpload'));
        this.applyFilter();
      },
      loadForPatient: function loadForPatient(externalContext, patientId, customerId, customerEmail, folderId) {
        this.externalContext = externalContext;
        this.patientId = patientId;
        this.customerId = customerId;
        this.customerEmail = customerEmail;
        if (folderId) {
          this.rootFolder = folderId;
        } else {
          this.rootFolder = '2222' + patientId;
        }
        this.ctx = 'patient';
        this.applyFilter();
      },
      loadForCustomer: function loadForCustomer(externalContext, customerId, customerEmail, folderId) {
        this.externalContext = externalContext;
        this.customerId = customerId;
        this.customerEmail = customerEmail;
        this.ctx = 'customer';

        if (folderId) {
          this.rootFolder = folderId;
        } else {
          this.rootFolder = '1111' + customerId;
        }

        this.applyFilter();
      }
    }
  });

});