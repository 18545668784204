define('jason-frontend/components/cashback-panel', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    toggleVisible: false,
    actions: {
      confirm: function confirm() {

        var form = $('#cashbackForm'),
            id = form.attr('data-cashback-id');

        var value = form.find('input[name=cashback]').val();
        value = value.replace(',', '.');

        if (isNaN(value) || value < 0) {
          jason.notifiction.systemError('Fehler', 'Bitte einen Wert >= 0 eingeben');
          return;
        }

        var fee = {
          id: id,
          mandantId: form.data('mandant-id'),
          cashback: value
        };

        if (form.find('#supplier')) {
          fee.supplier = {
            id: form.find('#cashback-supplier').val()
          };
        }

        this.sendAction('confirm', fee);

        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    },
    becomeFocused: (function () {
      var self = this,
          form = $('#customerGroupForm'),
          id = form.attr('data-mandant-id');
    }).on('didInsertElement')
  });

});