define('jason-frontend/models/xray-logbook-entry', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    created: DS['default'].attr('date'),
    kv: DS['default'].attr('string'),
    mAmpSec: DS['default'].attr('string')
  });

});