define('jason-frontend/helpers/css-bool-evaluator', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.cssBoolEvaluator = cssBoolEvaluator;

  function cssBoolEvaluator(params) {
    var evaluation = params[0],
        right = params[1],
        wrong = params[2];

    return evaluation ? right : wrong;
  }

  exports['default'] = Ember['default'].Helper.helper(cssBoolEvaluator);

});