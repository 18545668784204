define('jason-frontend/models/customer-deposit', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    sum: DS['default'].attr('number'),
    invoiceId: DS['default'].attr('number'),
    invoiceCode: DS['default'].attr('string'),
    invoiceSum: DS['default'].attr('number'),
    invoicePaid: DS['default'].attr('number'),
    invoiceOpen: DS['default'].attr('number'),
    invoiceDeposit: DS['default'].attr('number'),
    invoiceBalance: DS['default'].attr('number'),
    created: DS['default'].attr('date'),
    references: DS['default'].hasMany('invoice-reference'),
    transactions: DS['default'].hasMany('customerDepositTransaction')
  });

});