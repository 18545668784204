define('jason-frontend/models/payment-action', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    amount: DS['default'].attr('number'),
    created: DS['default'].attr('date'),
    paymentType: DS['default'].belongsTo('paymentType'),
    transactionGroupId: DS['default'].attr('string'),
    transactionGroupNumber: DS['default'].attr('string'),
    banktransferId: DS['default'].attr('number'),
    externalPaymentId: DS['default'].attr(''),
    cancellation: DS['default'].attr('boolean'),
    cashdeskProcessed: DS['default'].attr('boolean')
  });

});