define('jason-frontend/components/button-copy-permissions', ['exports', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'top',
    timer: 0,
    active: false,
    color: '#4A4A4A',
    content: 'Alle verrechneten Positionen ein/ausblenden',
    theme: 'tooltipster-punk',
    actions: {
      toggle: function toggle(ref, type) {
        this.sendAction('copyPermissions', ref, type);
      }
    }
  });

});