define('jason-frontend/templates/components/button-quickaction-admission', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 16,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-quickaction-admission.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 37 42");
        dom.setAttribute(el1,"class","quickaction-icon");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(1 1)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("circle");
        dom.setAttribute(el3,"class","circle");
        dom.setAttribute(el3,"cx","16");
        dom.setAttribute(el3,"cy","16");
        dom.setAttribute(el3,"r","16");
        dom.setAttribute(el3,"stroke","#404040");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"fill","#404040");
        dom.setAttribute(el3,"fill-rule","evenodd");
        dom.setAttribute(el3,"transform","translate(7 7) scale(0.14, 0.14)");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"class","icon");
        dom.setAttribute(el4,"d","M90-4H40.9c-2.3,0-4.1,1.8-4.1,4.1v5.7H18.7c-2.3,0-4.1,1.8-4.1,4.1v108c0,2.3,1.8,4.1,4.1,4.1h91.6    c2.3,0,4.1-1.8,4.1-4.1V9.9c0-2.3-1.8-4.1-4.1-4.1H94.1V0.1C94.1-2.2,92.3-4,90-4z M45,9.9V4.2h40.9v5.7v7.2H45V9.9z M106.2,14    v99.8H22.8V14h14v7.2c0,2.3,1.8,4.1,4.1,4.1H90c2.3,0,4.1-1.8,4.1-4.1V14H106.2z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"class","icon");
        dom.setAttribute(el4,"d","M95,67H35.9c-2.3,0-4.1,1.8-4.1,4.1c0,2.3,1.8,4.1,4.1,4.1H95c2.3,0,4.1-1.8,4.1-4.1C99.1,68.8,97.3,67,95,67z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"class","icon");
        dom.setAttribute(el4,"d","m65.5,86.8h-29.6c-2.3,0-4.1,1.8-4.1,4.1s1.8,4.1 4.1,4.1h29.5c2.3,0 4.1-1.8 4.1-4.1s-1.8-4.1-4-4.1z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"class","icon");
        dom.setAttribute(el4,"d","m31.8,51.2c0,2.3 1.8,4.1 4.1,4.1h59.1c2.3,0 4.1-1.8 4.1-4.1s-1.8-4.1-4.1-4.1h-59.1c-2.3,0-4.1,1.9-4.1,4.1z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("    <g transform=\"translate(19 24)\">");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("      <circle class=\"circle\" cx=\"8\" cy=\"8\" r=\"8\" fill=\"#FFF\" stroke=\"#404040\"/>");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("      <path class=\"icon\" fill=\"#404040\" d=\"M8.444 7.65V3.49c0-.22-.178-.395-.396-.395-.22 0-.397.177-.397.395v4.16H3.49c-.22 0-.395.18-.395.398 0 .22.177.396.395.396h4.16v4.162c0 .218.18.394.398.394.22 0 .396-.177.396-.394V8.444h4.162c.218 0 .394-.178.394-.396 0-.22-.177-.397-.394-.397H8.444z\"/>");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("    </g>");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});