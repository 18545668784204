define('jason-frontend/helpers/is-deliverynote-active', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isDeliverynoteActive = isDeliverynoteActive;

  function isDeliverynoteActive(params) {
    return API['default'].isDeliveryNoteActive();
  }

  exports['default'] = Ember['default'].Helper.helper(isDeliverynoteActive);

});