define('jason-frontend/helpers/is-parent-selected', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isParentSelected = isParentSelected;

  function isParentSelected(params) {
    var item = params[0],
        allItems = params[1];

    if (item.parentId) {
      return 'hidden';
    } else {
      return '';
    }
  }

  exports['default'] = Ember['default'].Helper.helper(isParentSelected);

});