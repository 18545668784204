define('jason-frontend/models/cashbook', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    created: DS['default'].attr('date'),
    formattedCreated: DS['default'].attr('string'),
    slip: DS['default'].attr('number'),
    slipId: DS['default'].attr('number'),
    invoiceId: DS['default'].attr('number'),
    invoiceCode: DS['default'].attr('string'),
    invoiceTotal: DS['default'].attr('string'),
    customer: DS['default'].attr('string'),
    customerId: DS['default'].attr('number'),
    banktransfer: DS['default'].attr('boolean'),
    'in': DS['default'].attr('number'),
    inFormatted: DS['default'].attr(''),
    out: DS['default'].attr('number'),
    outFormatted: DS['default'].attr(''),
    taxCode: DS['default'].attr('string'),
    cashRegister: DS['default'].attr('string'),
    text: DS['default'].attr('string')
  });

});