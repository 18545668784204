define('jason-frontend/components/treatment-future', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    crud: true,
    actions: {
      edit: function edit(treatmentPatientId, id) {
        this.sendAction('editFuture', treatmentPatientId, id);
      },
      move: function move(treatmentPatientId, id) {
        this.sendAction('moveFuture', treatmentPatientId, id);
      },
      deleteFutureEntry: function deleteFutureEntry(id, entryId) {
        this.sendAction('deleteFutureEntry', id, entryId);
      },
      addFutureAction: function addFutureAction(id) {
        this.sendAction('addFutureAction', id);
      },
      addDirectNotification: function addDirectNotification(id) {
        this.sendAction('addDirectNotification', id);
      },
      addDirectAppNotification: function addDirectAppNotification(id) {
        this.sendAction('addDirectAppNotification', id);
      },
      saveReminder: function saveReminder(model) {
        var self = this;

        var ne = model.nextExecution;

        var calcNextExecution = moment();

        if (ne === '1week') {
          calcNextExecution = moment().add(7, 'days');
        } else if (ne === '2weeks') {
          calcNextExecution = moment().add(14, 'days');
        } else if (ne === '3weeks') {
          calcNextExecution = moment().add(21, 'days');
        } else if (ne === '1month') {
          calcNextExecution = moment().add(1, 'month');
        } else if (ne === '2months') {
          calcNextExecution = moment().add(2, 'month');
        } else if (ne === '3months') {
          calcNextExecution = moment().add(3, 'month');
        } else if (ne === '1year') {
          calcNextExecution = moment().add(1, 'year');
        } else if (ne === 'misc') {
          calcNextExecution = moment(model.nextExecutionCustom, 'DD.MM.YYYY');
        }

        var data = {
          type: {
            id: 'add_reminder'
          },
          triggerType: {
            id: 'onetime'
          },
          timeUnitInterval: 0,
          timeUnitIterations: 0,
          reminderText: model.reminderText,
          reminderSubject: model.reminderSubject,
          reminderHeadline: model.reminderHeadline,
          reminderSalutation: model.reminderSalutation,
          reminderClosing: model.reminderClosing,
          nextExecution: calcNextExecution
        };

        var data2 = {
          referer: model.referer
        };
        $.ajax({
          url: "/api/treatmentFutureActions/" + this.get('model').id,
          method: "PUT",
          data: JSON.stringify(data2),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (responseData) {

          data.id = responseData;

          $.ajax({
            url: "/api/treatmentFutureActions/" + responseData,
            method: "POST",
            data: JSON.stringify(data),
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (resp) {
            self.sendAction('reload', 'future');
            jason.notifiction.notifiy('Erfolgreich gespeichert', '');
          });
        });
      },
      saveAppointment: function saveAppointment(model) {

        var self = this;

        var data = {
          type: {
            id: 'add_appointment'
          },
          triggerType: {
            id: 'onetime'
          },
          appTitle: model.title,
          appDateFrom: model.dateFrom,
          appDateTo: model.dateTo,
          appWholeDay: model.wholeDay,
          timeUnitInterval: 0,
          timeUnitIterations: 0,
          nextExecution: moment(model.date, 'DD. MM. YYYY')
        };

        if (model.categoryId) {
          data.appCategory = {
            id: model.categoryId
          };
        }
        if (model.etigaCategoryId) {
          data.etigaCategory = {
            id: model.etigaCategoryId
          };
        }

        var data2 = {
          referer: model.referer
        };
        $.ajax({
          url: "/api/treatmentFutureActions/" + this.get('model').id,
          method: "PUT",
          data: JSON.stringify(data2),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (responseData) {

          data.id = responseData;

          $.ajax({
            url: "/api/treatmentFutureActions/" + responseData,
            method: "POST",
            data: JSON.stringify(data),
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (resp) {
            self.sendAction('reload', 'future');
            jason.notifiction.notifiy('Erfolgreich gespeichert', '');
          });
        });
      },
      openAppointmentPanel: function openAppointmentPanel(referer) {

        var self = this;

        $.ajax({
          url: "/api/patientAppointmentCategories?filterActive=true&etiga=false",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('futureAddAppointmentPanel').send('load', data.patientAppointmentCategory, referer);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-future-add-appointment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openReminderPanel: function openReminderPanel(referer) {

        var self = this;

        $.ajax({
          url: "/api/treatmentPatients/" + this.get('model').id + "/reminderTexts",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('futureAddReminderPanel').send('load', data.reminderEmail, referer);
        });

        var data = {
          reminderHeadline: 'test'
        };

        self.get('futureAddReminderPanel').send('load', data);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-future-add-reminder-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    }
  });

});