define('jason-frontend/templates/components/button-copy-list', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 6,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/button-copy-list.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","14");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[5,24],[5,29]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-copy-list.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("title");
        var el3 = dom.createTextNode("edit");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","scale(0.03,0.03) translate(270,230)");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M503.633,117.628c-5.332-5.327-11.8-7.993-19.41-7.993H365.446c-11.417,0-23.603,3.806-36.542,11.42V27.412   c0-7.616-2.662-14.092-7.994-19.417C315.578,2.666,309.11,0,301.492,0H182.725c-7.614,0-15.99,1.903-25.125,5.708   c-9.136,3.806-16.368,8.376-21.7,13.706L19.414,135.901c-5.33,5.329-9.9,12.563-13.706,21.698C1.903,166.738,0,175.108,0,182.725   v191.858c0,7.618,2.663,14.093,7.992,19.417c5.33,5.332,11.803,7.994,19.414,7.994h155.318v82.229c0,7.61,2.662,14.085,7.992,19.41   c5.327,5.332,11.8,7.994,19.414,7.994h274.091c7.61,0,14.085-2.662,19.41-7.994c5.332-5.325,7.994-11.8,7.994-19.41V137.046   C511.627,129.432,508.965,122.958,503.633,117.628z M328.904,170.449v85.364h-85.366L328.904,170.449z M146.178,60.813v85.364   H60.814L146.178,60.813z M202.139,245.535c-5.33,5.33-9.9,12.564-13.706,21.701c-3.805,9.141-5.708,17.508-5.708,25.126v73.083   H36.547V182.725h118.766c7.616,0,14.087-2.664,19.417-7.994c5.327-5.33,7.994-11.801,7.994-19.412V36.547h109.637v118.771   L202.139,245.535z M475.078,475.085H219.263V292.355h118.775c7.614,0,14.082-2.662,19.41-7.994   c5.328-5.325,7.994-11.797,7.994-19.41V146.178h109.629v328.907H475.078z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createMorphAt(element2,1,1);
        morphs[3] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,14],[1,18]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,32],[1,36]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[4,10],[4,20]]]]],[],0,null,["loc",[null,[4,4],[6,11]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[7,15],[7,20]]]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});