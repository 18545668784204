define('jason-frontend/controllers/practicemgmt/treatment-journal', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Behandlungsjournal",
    breadCrumbPath: "practicemgmt.treatment-journal",
    treatmentSortDir: 'asc',
    pickDate: moment().format("DD. MM. YYYY") + " - " + moment().format("DD. MM. YYYY"),
    page: 1,
    showLoading: false,
    perPage: 20,
    filterDoctor: null,
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    filterLab: false,
    filterProducts: false,
    filterServices: false,
    filterFood: false,
    filterHide: false,
    searchType: 'products',
    productOrServices: [{ name: "Alle Produkte", id: "products" }, { name: "Alle Leistungen", id: "services" }],
    actions: {
      downloadTemplate: function downloadTemplate(id, patient) {

        window.open('/api/templates/' + id + '/download?patientId=' + patient.id + '&mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      reloadTemplate: function reloadTemplate(instanceId, readOnly, patientId) {
        var self = this;
        $.ajax({
          url: "/api/templateInstances/" + instanceId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('templateEditPanel').send('load', data.templateInstance, "templateInstances", readOnly, patientId);
        });
      },
      openTemplateEdit: function openTemplateEdit(tpId, instanceId, readOnly, patient) {
        this.transitionTo('editor.edit', instanceId, 'templateinstance', tpId);
      },
      openSlopingInfos: function openSlopingInfos(entry) {
        var self = this;

        var articleId = undefined;
        var article = undefined;
        try {
          articleId = entry.get('article').id;
          article = entry.get('article');
        } catch (e) {
          articleId = entry.article.id;
          article = entry.article;
        }

        $.ajax({
          url: "/api/products/" + articleId + "/slopingInfos",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('slopingInfoPanel').send('load', data.productSlopingInfo, article, entry);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-sloping-info-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    },
    watchDateChange: (function () {
      this.send('applyFilter');
    }).observes('pickDate'),
    watchDoctor: (function () {
      this.send('applyFilter');
    }).observes('filterDoctor'),
    watchAssistant: (function () {
      this.send('applyFilter');
    }).observes('filterAssistant'),
    watchRoom: (function () {
      this.send('applyFilter');
    }).observes('filterRoom'),
    watchCustomerSelection: (function () {
      var self = this;
      this.set('page', 1);

      if (this.get('filterCustomer')) {

        $.ajax({
          url: "/api/patients/forcustomer/" + this.get('filterCustomer').id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('patients', data.patient);
        });
      } else {
        this.set('filterPatient', null);
        this.set('patients', null);
      }
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterCustomer'),
    watchPatientSelection: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterPatient'),
    watchSearchType: (function () {
      var self = this;
      this.set('page', 1);

      this.set('filterServiceCategory', null);
      this.set('filterProduct', null);
      this.set('filterService', null);
      this.set('filterProductCategory', null);
      this.set('filterSupplier', null);
      this.set('filterPartnerCategory', null);

      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('searchType'),
    watchProduct: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterProduct'),
    watchService: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterService'),
    watchServiceCategory: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterServiceCategory'),
    watchProductCategory: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterProductCategory'),
    watchPatientCategory: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterPatientCategory'),
    watchSupplier: (function () {
      var self = this;
      this.set('page', 1);

      if (this.get('filterSupplier') == null) {
        this.set('partnerCategories', null);
        this.set('filterPartnerCategory', null);
      } else {
        this.send('updatePartnerCategories');
      }

      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterSupplier'),
    watchPartnerCategory: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterPartnerCategory')
  });

});