define('jason-frontend/templates/components/button-quick-articles', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-quick-articles.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"class","cursor state-button");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill-rule","evenodd");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M16,31 C24.2842713,31 31,24.2842713 31,16 C31,7.71572875 24.2842713,1 16,1 C7.71572875,1 1,7.71572875 1,16 C1,24.2842713 7.71572875,31 16,31 L16,31 L16,31 Z M16,29.5714286 C8.50470696,29.5714286 2.42857143,23.495293 2.42857143,16 C2.42857143,8.50470696 8.50470696,2.42857143 16,2.42857143 C23.495293,2.42857143 29.5714286,8.50470696 29.5714286,16 C29.5714286,23.495293 23.495293,29.5714286 16,29.5714286 L16,29.5714286 L16,29.5714286 Z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","translate(10 8)");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M10.0344706 3.36248889L1.96552941 3.36248889C1.40223529 3.36248889.943941176 2.9008.943941176 2.33351111L.943941176 1.02915556C.943941176.461688889 1.40205882 0 1.96552941 0L10.0346471 0C10.5979412 0 11.0562353.461688889 11.0562353 1.02915556L11.0562353 2.33351111C11.0560588 2.9008 10.5979412 3.36248889 10.0344706 3.36248889zM1.96552941.680888889C1.77494118.680888889 1.61982353.837155556 1.61982353 1.02915556L1.61982353 2.33351111C1.61982353 2.52533333 1.77494118 2.6816 1.96552941 2.6816L10.0346471 2.6816C10.2252353 2.6816 10.3803529 2.52533333 10.3803529 2.33351111L10.3803529 1.02915556C10.3803529.837155556 10.2252353.680888889 10.0346471.680888889L1.96552941.680888889 1.96552941.680888889zM11.6617059 13.4024889L.338294118 13.4024889C.151588235 13.4024889.000352941176 13.2501333.000352941176 13.0620444L.000352941176 7.73368889C.000352941176 7.5456.151588235 7.39324444.338294118 7.39324444L11.6617059 7.39324444C11.8484118 7.39324444 11.9996471 7.5456 11.9996471 7.73368889L11.9996471 13.0620444C11.9996471 13.2499556 11.8484118 13.4024889 11.6617059 13.4024889zM.676235294 12.7216L11.3237647 12.7216 11.3237647 8.07413333.676235294 8.07413333.676235294 12.7216z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M6.64129412,12.3978667 L5.35905882,12.3978667 C5.21911765,12.3978667 5.10564706,12.2833778 5.10564706,12.1424 L5.10564706,11.2986667 L4.26794118,11.2986667 C4.128,11.2986667 4.01435294,11.1843556 4.01435294,11.0433778 L4.01435294,9.75164444 C4.01435294,9.61066667 4.128,9.49617778 4.26794118,9.49617778 L5.10564706,9.49617778 L5.10564706,8.6528 C5.10564706,8.51182222 5.21911765,8.39733333 5.35905882,8.39733333 L6.64129412,8.39733333 C6.78123529,8.39733333 6.89470588,8.51182222 6.89470588,8.6528 L6.89470588,9.49617778 L7.73205882,9.49617778 C7.872,9.49617778 7.98564706,9.61066667 7.98564706,9.75164444 L7.98564706,11.0433778 C7.98564706,11.1843556 7.872,11.2986667 7.73205882,11.2986667 L6.89470588,11.2986667 L6.89470588,12.1424 C6.89452941,12.2835556 6.78123529,12.3978667 6.64129412,12.3978667 Z M5.61264706,11.8872889 L6.38788235,11.8872889 L6.38788235,11.0572444 C6.38788235,10.9088 6.50752941,10.7880889 6.65470588,10.7880889 L7.47882353,10.7880889 L7.47882353,10.0071111 L6.65470588,10.0071111 C6.50752941,10.0071111 6.38788235,9.88657778 6.38788235,9.73831111 L6.38788235,8.90826667 L5.61264706,8.90826667 L5.61264706,9.73831111 C5.61264706,9.88657778 5.49282353,10.0071111 5.34564706,10.0071111 L4.52135294,10.0071111 L4.52135294,10.7882667 L5.34564706,10.7882667 C5.49282353,10.7882667 5.61264706,10.9089778 5.61264706,11.0574222 L5.61264706,11.8872889 Z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M6,16 L1.70558824,16 C0.765352941,16 0.000352941176,15.2293333 0.000352941176,14.2823111 L0.000352941176,7.71928889 C0.000352941176,6.81742222 0.666352941,6.13333333 1.25417647,5.52977778 C1.64682353,5.12675556 2.05252941,4.71004444 2.05252941,4.39271111 L2.05252941,3.02222222 C2.05252941,2.83413333 2.20376471,2.68177778 2.39047059,2.68177778 C2.57717647,2.68177778 2.72841176,2.83413333 2.72841176,3.02222222 L2.72841176,4.39271111 C2.72841176,4.98844444 2.24664706,5.48302222 1.73647059,6.00675556 C1.21517647,6.54222222 0.676058824,7.09582222 0.676058824,7.71946667 L0.676058824,14.2824889 C0.676058824,14.8542222 1.13770588,15.3192889 1.70541176,15.3192889 L6,15.3192889 C6.18670588,15.3192889 6.33794118,15.4716444 6.33794118,15.6597333 C6.33794118,15.8478222 6.18670588,16 6,16 Z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M10.2944118,16 L6,16 C5.81329412,16 5.66205882,15.8476444 5.66205882,15.6595556 C5.66205882,15.4714667 5.81329412,15.3191111 6,15.3191111 L10.2944118,15.3191111 C10.8621176,15.3191111 11.3237647,14.8540444 11.3237647,14.2823111 L11.3237647,7.71928889 C11.3237647,7.09564444 10.7846471,6.54204444 10.2633529,6.00657778 C9.75317647,5.48284444 9.27141176,4.98808889 9.27141176,4.39253333 L9.27141176,3.02204444 C9.27141176,2.83395556 9.42264706,2.6816 9.60935294,2.6816 C9.79605882,2.6816 9.94729412,2.83395556 9.94729412,3.02204444 L9.94729412,4.39253333 C9.94729412,4.70986667 10.3531765,5.12675556 10.7456471,5.5296 C11.3334706,6.13333333 11.9994706,6.81724444 11.9994706,7.71911111 L11.9994706,14.2821333 C11.9996471,15.2293333 11.2346471,16 10.2944118,16 Z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element1, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[2,15],[2,20]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});