define('jason-frontend/routes/purchases/savings', ['exports', 'ember', 'moment', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, moment, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {
      params.filterMandant = "all";
      params.filterFrom = moment['default']().startOf('month').format('YYYY-MM-DD');
      params.filterTo = moment['default']().endOf('month').format('YYYY-MM-DD');
      return this.findPaged('purchaseSaving', params);
    },
    perPage: 20,
    queryParams: {},
    setupController: function setupController(controller) {

      if (API['default'].getMandant() != 4) {
        if (!API['default'].hasPermission('r_purchases_dashboards')) {
          this.transitionTo('home');
        }
      }

      this.applyFilter();
      controller.set('downloadToken', API['default'].getDownloadToken());
      controller.set('mandantId', API['default'].getMandant());
    },
    actions: {
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-');

      controller.set('showLoading', true);

      var from = moment['default'](dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment['default'](dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          filterIn = 'day',
          filterOut = 'day';

      if (controller.get('classFilterTypeInSingle') === 'primary') {
        filterIn = 'single';
      }
      if (controller.get('classFilterTypeInAll') === 'primary') {
        filterIn = 'all';
      }

      if (controller.get('classFilterTypeOutSingle') === 'primary') {
        filterOut = 'single';
      }
      if (controller.get('classFilterTypeOutAll') === 'primary') {
        filterOut = 'all';
      }

      controller.set('to', to);
      controller.set('from', from);
      controller.set('filterIn', filterIn);
      controller.set('filterOut', filterOut);

      this.findPaged('purchaseSaving', {
        filterFrom: from,
        filterTo: to,
        filterIn: filterIn,
        filterOut: filterOut
      }).then(function (transactions) {
        var meta = transactions.meta;
        controller.set('sumSaving', meta.sumSaving);
        controller.set('sumMemberFee', meta.sumMemberFee);
        controller.set('sumCashback', meta.sumCashback);
        controller.set('model', transactions);
        controller.set('showLoading', false);
      });
    }
  });

});