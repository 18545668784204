define('jason-frontend/models/purchase-tax-entry', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    amount: DS['default'].attr('number'),
    percentage: DS['default'].attr('number'),
    totalExclusive: DS['default'].attr('number'),
    totalInclusive: DS['default'].attr('number')
  });

});