define('jason-frontend/controllers/cash/logbook-detailed', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Eingabeprotokoll",
    breadCrumbPath: "cash.logbook",
    queryParams: ["page", "perPage"],
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    pickDate: moment().format("DD. MM. YYYY") + " - " + moment().format("DD. MM. YYYY"),
    page: 1,
    perPage: 100,
    to: null,
    from: null,
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('pickDate')
  });

});