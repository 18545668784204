define('jason-frontend/components/inventory-outgoing-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    type: null,
    warningSend: false,
    outgoingTypes: [{ name: "Abgabe", id: "removal" }, { name: "Verkauf", id: "sale" }, { name: "Lagerwechsel", id: "change" }],
    actions: {
      load: function load(item, stocktakingId, warehouses) {
        Ember['default'].set(item, 'subType', {
          id: 'removal',
          name: 'Abgabe'
        });
        Ember['default'].set(item, 'outgoingAmount', item.flatStock);
        this.set('item', item);
        this.set('stocktakingId', stocktakingId);
        this.set('warningSend', false);
        this.set('reopen', false);
        this.set('product', null);
      },
      loadReopen: function loadReopen(item, product) {
        Ember['default'].set(item, 'subType', {
          id: 'removal',
          name: 'Abgabe'
        });
        Ember['default'].set(item, 'outgoingAmount', item.flatStock);
        this.set('item', item);
        this.set('product', product);
        this.set('warningSend', false);
        this.set('reopen', true);
      },
      confirm: function confirm() {

        if (this.get('item').outgoingAmount < 0) {
          jason.notifiction.error('Fehler', 'Die Ausbuchungsmenge muss größer/gleich 0 sein');
          return;
        }

        if (this.get('item').outgoingAmount > this.get('item').flatStock) {
          jason.notifiction.error('Fehler', 'Die Ausbuchungsmenge darf nicht größer als der Lagerbestand sein');
          return;
        }

        if (this.get('item').subType.id === 'change') {
          if (!this.get('item').warehouse) {
            jason.notifiction.error('Fehler', 'Bitte ein Ziel-Lager wählen');
            return;
          }

          Ember['default'].set(this.get('item'), 'warehouseId', this.get('item').warehouse.id);

          if (this.get('item').warehouse.get('name') === this.get('item').warehouseName) {
            jason.notifiction.error('Fehler', 'Ziel-Lager darf nicht das aktuelle Lager sein');
            return;
          }
        }

        Ember['default'].set(this.get('item'), 'outgoingAmount', String(this.get('item').outgoingAmount).replace(',', '.'));

        Ember['default'].set(this.get('item'), 'subType', this.get('item').subType.id);

        Ember['default'].set(this.get('item'), 'stocktakingId', this.get('stocktakingId'));
        if (this.get('reopen') == true) {
          this.sendAction('save', this.get('item'), this.get('product'), true);
        } else {
          this.sendAction('save', this.get('item'), this.get('stocktakingId'));
        }
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      } }
  });

});