define('jason-frontend/helpers/get-avatar', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getAvatar = getAvatar;

  function getAvatar(params) {
    var picture = params[0];
    if (picture) {
      if (picture._internalModel) {
        return 'https://res.cloudinary.com/' + picture.get('cdnAcc') + '/image/upload/w_400,h_230,c_fill,g_auto/' + picture.get('cdnId');
      } else {
        return 'https://res.cloudinary.com/' + picture.cdnAcc + '/image/upload/w_400,h_230,c_fill,g_auto/' + picture.cdnId;
      }
    }
    return 'https://res.cloudinary.com/myjason2/image/upload/w_500,h_500,c_scale/x_70,y_0,w_400,h_400,c_crop/v1613225007/missing_large_ajacu3.png';
    // return 'https://res.cloudinary.com/poimmo/image/upload/w_400,h_200,c_fill/missing-avatar3_v4znqf.jpg';
  }

  exports['default'] = Ember['default'].Helper.helper(getAvatar);

});