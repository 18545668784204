define('jason-frontend/helpers/get-permission-title', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getPermissionTitle = getPermissionTitle;

  function getPermissionTitle(params) {
    var type = params[0];
    if (type === 'masterData') {
      return "Patient Stammdaten";
    }
    if (type === 'customerData') {
      return "Kunde Stammdaten";
    }
    if (type === 'addData') {
      return "Patient Zusatzinformationen";
    }
    if (type === 'addWeight') {
      return "Patient Gewicht";
    }
    if (type === 'addAnam') {
      return "Patient Behandlungshistorie - Anamnese";
    }
    if (type === 'addSymp') {
      return "Patient Behandlungshistorie - Symptome";
    }
    if (type === 'addDiag') {
      return "Patient Behandlungshistorie - Diagnose";
    }
    if (type === 'addThera') {
      return "Patient Behandlungshistorie - Therapie";
    }
    if (type === 'addLabor') {
      return "Patient Labor";
    }
    if (type === 'addDocs') {
      return "Patient Dokumente";
    }
    if (type === 'addCorr') {
      return "Patient Korrespondenz";
    }
    return;
  }

  exports['default'] = Ember['default'].Helper.helper(getPermissionTitle);

});