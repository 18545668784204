define('jason-frontend/templates/components/button-copy', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-copy.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"class","button--copy");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el2 = dom.createElement("svg");
        dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el2,"viewBox","0 0 32 32");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"fill","none");
        dom.setAttribute(el3,"fill-rule","evenodd");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("circle");
        dom.setAttribute(el4,"cx","16");
        dom.setAttribute(el4,"cy","16");
        dom.setAttribute(el4,"r","14");
        dom.setAttribute(el4,"stroke","#3B6182");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"fill","#3B6182");
        dom.setAttribute(el4,"d","M22.854 12.146l-4-4C18.76 8.053 18.634 8 18.5 8h-4c-.827 0-1.5.673-1.5 1.5V12h-1.5c-.827 0-1.5.673-1.5 1.5v9c0 .827.673 1.5 1.5 1.5h7c.827 0 1.5-.673 1.5-1.5V20h1.5c.827 0 1.5-.673 1.5-1.5v-6c0-.133-.053-.26-.146-.354zM19 9.706L21.293 12H19V9.707zM19 22.5c0 .275-.225.5-.5.5h-7c-.276 0-.5-.225-.5-.5v-9c0-.276.224-.5.5-.5H15v3c0 .55.45 1 1 1h3v5.5zM16 16v-2.293L18.293 16H16zm6 2.5c0 .275-.225.5-.5.5H20v-2.5c0-.133-.053-.26-.146-.354l-4-4c-.094-.093-.22-.146-.354-.146H14V9.5c0-.276.224-.5.5-.5H18v3c0 .55.448 1 1 1h3v5.5z");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        dom.setNamespace(null);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","delete-confirm");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","footer mt10");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","btn btn-default");
        var el4 = dom.createTextNode("Nein");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","btn btn-success");
        var el4 = dom.createTextNode("Ja");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element0, 'data-id');
        morphs[1] = dom.createAttrMorph(element0, 'title');
        morphs[2] = dom.createElementMorph(element0);
        morphs[3] = dom.createAttrMorph(element1, 'width');
        morphs[4] = dom.createAttrMorph(element1, 'height');
        morphs[5] = dom.createMorphAt(element2,1,1);
        morphs[6] = dom.createElementMorph(element4);
        morphs[7] = dom.createElementMorph(element5);
        return morphs;
      },
      statements: [
        ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[1,56],[1,63]]]]]]],
        ["attribute","title",["concat",[["get","tooltip",["loc",[null,[1,76],[1,83]]]]]]],
        ["element","action",["confirm"],[],["loc",[null,[1,3],[1,23]]]],
        ["attribute","width",["concat",[["get","size",["loc",[null,[2,53],[2,57]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[2,71],[2,75]]]]]]],
        ["content","text",["loc",[null,[8,2],[8,10]]]],
        ["element","action",["cancel"],[],["loc",[null,[10,40],[10,59]]]],
        ["element","action",["delete"],[],["loc",[null,[11,40],[11,59]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});