define('jason-frontend/helpers/get-order-type', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getPaymentMoral = getPaymentMoral;

  function getPaymentMoral(params) {
    var order = params[0];
    if (order) {
      try {
        if (order.get('privateOrder')) {
          return 'private';
        }
      } catch (e) {
        if (order.privateOrder) {
          return 'private';
        }
      }
    }
    return 'default';
  }

  exports['default'] = Ember['default'].Helper.helper(getPaymentMoral);

});