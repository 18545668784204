define('jason-frontend/helpers/get-css-row-class', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getCssRowClass = getCssRowClass;

  function getCssRowClass(params) {
    return params[0] % 2 != 0 ? 'first' : 'second';
  }

  exports['default'] = Ember['default'].Helper.helper(getCssRowClass);

});