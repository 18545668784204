define('jason-frontend/routes/purchases/masterdata', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  var modelHookRun;

  exports['default'] = Ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      modelHookRun = false;
    },
    model: function model() {
      return this.store.find('product-supplier', API['default'].getSupplierId());
    },
    afterModel: function afterModel(model) {
      if (!modelHookRun) {
        return model.reload();
      }
      return model;
    },
    actions: {
      save: function save() {
        var self = this;

        var model = self.get('controller').get('model');
        Ember['default'].set(model, 'shippingCostsAbsolute', new String(model.get('shippingCostsAbsolute')).replace(',', '.'));
        Ember['default'].set(model, 'freeHouseOrderValue', new String(model.get('freeHouseOrderValue')).replace(',', '.'));
        try {
          self.get('controller').get('model').save().then(function () {
            jason.notifiction.notifiy('Stammdaten', 'erfolgreich gespeichert');
          });
        } catch (err) {}
      },
      selectTab: function selectTab(selected) {
        var controller = this.get('controller');
        controller.set('selectTab', selected);
      }
    }
  });

});