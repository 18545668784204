define('jason-frontend/templates/products/create', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/products/create.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","card legacy animated edit-product");
        dom.setAttribute(el1,"id","productEditPage");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        dom.setAttribute(el2,"id","productEdit");
        dom.setAttribute(el2,"class","admin-form");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","buttons action-icons clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button icon-button--small");
        dom.setAttribute(el2,"onclick","history.back()");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button icon-button--success input");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(element1, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createMorphAt(element0,1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
        morphs[3] = dom.createElementMorph(element2);
        morphs[4] = dom.createMorphAt(element2,1,1);
        return morphs;
      },
      statements: [
        ["element","action",["create",["get","referer",["loc",[null,[2,64],[2,71]]]]],["on","submit"],["loc",[null,[2,46],[2,85]]]],
        ["inline","products-edit-form",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[3,33],[3,38]]]]],[],[]],"mode","create","editable",true,"taxes",["subexpr","@mut",[["get","taxes",["loc",[null,[3,73],[3,78]]]]],[],[]],"labProfiles",["subexpr","@mut",[["get","labProfiles",["loc",[null,[3,91],[3,102]]]]],[],[]],"productCategories",["subexpr","@mut",[["get","productCategories",["loc",[null,[3,121],[3,138]]]]],[],[]],"distributors",["subexpr","@mut",[["get","distributors",["loc",[null,[3,152],[3,164]]]]],[],[]],"allUnits",["subexpr","@mut",[["get","allUnits",["loc",[null,[3,174],[3,182]]]]],[],[]],"categories",["subexpr","@mut",[["get","categories",["loc",[null,[3,194],[3,204]]]]],[],[]],"manufacturers",["subexpr","@mut",[["get","manufacturers",["loc",[null,[3,219],[3,232]]]]],[],[]],"patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[3,251],[3,268]]]]],[],[]]],["loc",[null,[3,6],[3,270]]]],
        ["content","button-prev",["loc",[null,[8,69],[8,84]]]],
        ["element","action",["triggerSubmit"],[],["loc",[null,[9,5],[9,31]]]],
        ["inline","button-save",[],["color","#ffffff","size","52"],["loc",[null,[10,4],[10,45]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});