define('jason-frontend/controllers/cash/add-product', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Produkt hinzufügen",
    breadCrumbPath: "cash.add_product",
    queryParams: ["page", "perPage"],
    invoice: null,
    products: null,
    filterName: null,
    filterSupplier: 'all',
    filterManufacturer: null,
    filterFavorites: false,
    sortBy: null,
    sortDir: null,
    categoryFilterClass: 'hidden',
    selectedCategoryFilters: null,
    showLoading: true,

    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    perPage: 10,
    watchName: (function () {
      this.set('page', 1);
    }).observes('filterName'),
    watchCategory: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('selectedCategoryFilters'),
    watchSupplier: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterSupplier'),
    watchManufacturer: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterManufacturer')
  });

});