define('jason-frontend/controllers/cash/composite', ['exports', 'ember', 'moment'], function (exports, Ember, moment) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Sammelbeleg",
    breadCrumbPath: "cash.composite",
    selectedPaymentType: "cash",
    showInvoicePanel: true,
    showBankTransferPanel: false,
    selectedType: "in",
    selectedInvoiceId: null,
    selectedInvoice: null,
    maxPaymentAmount: null,
    selectedCashRegister: null,
    transaction: null,
    bankingAccount: null,
    showLoading: false,
    documentDate: moment['default']().format("DD. MM. YYYY"),
    notice: null,
    styleStepStart: "",
    styleStepConfirm: "hidden",
    tax20: 0,
    tax19: 0,
    tax13: 0,
    tax10: 0,
    tax7: 0,
    tax0: 0,
    actions: {
      toggleReference: function toggleReference(show) {
        this.set('showRefOutgoing', show);
      },
      setPaymentType: function setPaymentType(paymentType) {
        this.set('selectedPaymentType', paymentType);
        this.set('showBankTransferPanel', paymentType === 'banktransfer');
      },
      setType: function setType(type) {
        this.set('selectedType', type);
        this.set('showInvoicePanel', type === 'in');

        if (type === 'inlay') {
          this.set('selectedPaymentType', 'cash');
        }
      }
    }
  });

});