define('jason-frontend/routes/compositeproducts/create', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      var product = this.store.createRecord('compositeArticle');
      product.editable = true;
      return product;
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);
      controller.set('type', params.queryParams.type);
    }
  });

});