define('jason-frontend/helpers/current-user-picture', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.currentUserPicture = currentUserPicture;

  function currentUserPicture() {
    if (API['default'].getPictureCdnId() == undefined) {
      return 'tfe2s63guocjijuqco3j';
    }
    return API['default'].getPictureCdnId();
  }

  exports['default'] = Ember['default'].Helper.helper(currentUserPicture);

});