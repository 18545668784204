define('jason-frontend/models/delivery-note', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    date: DS['default'].attr('date'),
    creationDate: DS['default'].attr('date'),
    deliveryNoteNumber: DS['default'].attr('string'),
    orderNumber: DS['default'].attr('string'),
    purchaseOrderCode: DS['default'].attr('string'),
    purchaseOrderId: DS['default'].attr('number'),
    orderNumberSupplier: DS['default'].attr('string'),
    productSupplier: DS['default'].attr(),
    positions: DS['default'].attr(),
    status: DS['default'].attr(),
    allPositions: DS['default'].attr()
  });

});