define('jason-frontend/components/all-lab-result-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      load: function load(data, patientId) {
        this.set('items', data);
        this.set('patientId', patientId);
      },
      downloadResult: function downloadResult(id) {
        this.sendAction('downloadResult', id);
      },
      selectBarcode: function selectBarcode(id) {
        this.sendAction('selectBarcode', id);
      },
      update: function update(data) {
        this.set('item', data);
      },
      changeDate: function changeDate(data) {
        this.sendAction('dateChanged', data);
      },
      changeParamValue: function changeParamValue(id, param) {
        var value = $('input#param_' + param.id).val();
        this.sendAction('changeParamValue', id, param, value);
      },
      save: function save(id) {
        this.sendAction('save', id, this.get('item').comment);
      },
      openChart: function openChart(patientId, paramId) {
        this.sendAction('openChart', patientId, paramId);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});