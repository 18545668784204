define('jason-frontend/controllers/purchases/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Bestellungen",
    breadCrumbPath: "purchases.edit",
    currentOrderId: null,
    amount: 0,
    styleStepPositions: null,
    sidebarActive: true,
    styleStepSummary: "hidden",
    styleStepConfirmation: "hidden",
    privateOrder: false,
    privateOrderDifferentAddress: false,
    queryParams: ['redirectToListing'],
    reductionSum: 0,
    actions: {
      statsLoaded: function statsLoaded() {

        var self = this;

        setTimeout(function (e) {

          var sumTotal = 0;

          $('div.cashbackBar').each(function () {
            var sum = parseFloat($(this).attr('data-reduction-sum'));
            sumTotal += sum;
          });

          self.set('reductionSum', sumTotal);
        }, 100);
      },
      openLaboklin: function openLaboklin(order, laboklinFormId) {

        var self = this;

        $.ajax({
          url: "/api/purchaseOrders/" + order.id + "/laboklinForm/" + laboklinFormId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('laboklinTreatmentPanel').send('load', self.get('model').id, 0, data, null, null, self.store.find('patient-category'), null, null);
        });

        Ember['default'].run.later(function () {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-form-laboklin-treatment-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 1000);
      },
      changeLabel: function changeLabel(orderId) {
        var txt = $('#orderLabel').val();

        var data = {};
        data.label = txt;

        $.ajax({
          url: "/api/purchaseOrders/" + orderId + "/changeLabel",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {});
      }
    }
  });

});