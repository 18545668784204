define('jason-frontend/components/button-history', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'top',
    timer: 0,
    active: false,
    showStroke: true,
    color: '#4A4A4A',
    content: 'Freigabehistorie',
    theme: 'tooltipster-punk'
  });

});