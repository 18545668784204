define('jason-frontend/controllers/sale/dashboard', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Umsätze",
    breadCrumbPath: "sale.dashboard",
    mandant: null,
    supplier: null,
    showLoading: false,
    filterCustomer: null,
    filterUser: null,
    customerId: null,
    userRole: null,
    lastId: null,
    productCategories: null,
    filterPartnerCategory: null,
    filterSupplier: null,
    product: null,
    userId: null,
    searchType: 'products',
    sortBy: 'created',
    queryParams: ["lastId"],
    sortDir: 'asc',
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),
    roles: [{ name: "Tierärzte", id: "doctor" }, { name: "Assistent/innen", id: "assistant" }],
    productOrServices: [{ name: "Alle Produkte", id: "products" }, { name: "Alle Leistungen", id: "services" }],
    actions: {
      sortBy: function sortBy(_sortBy) {
        if (_sortBy === this.get('sortBy')) {
          if (this.get('sortDir') === 'desc') {
            this.set('sortDir', 'asc');
          } else {
            this.set('sortDir', 'desc');
          }
        }
        this.set('sortBy', _sortBy);
        this.send('applyFilter');
      },
      openSaleCharts: function openSaleCharts() {

        this.get('saleChartsPanel').send('load', this.get('filterCustomer'), this.get('filterUser'), this.get('filterUserRole'));

        Ember['default'].run.later(function () {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-sale-charts-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 500);
      },
      openSaleBarCharts: function openSaleBarCharts() {

        this.get('saleBarChartsPanel').send('load', this.get('filterCustomer'), this.get('filterUser'), this.get('filterUserRole'));

        Ember['default'].run.later(function () {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-sale-bar-charts-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 500);
      }
    },
    watchCustomer: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterCustomer'),
    watchUser: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterUser'),
    watchUserRole: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterUserRole'),
    watchProductCategory: (function () {
      var self = this;
      this.set('page', 1);

      if (this.get('filterProductOrService') == null) {
        this.set('filterProduct', null);
      }

      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterProductCategory'),
    watchServiceCategory: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterServiceCategory'),
    watchFilterProductOrService: (function () {
      var self = this;
      this.set('page', 1);

      if (this.get('filterProductOrService') == null) {
        this.set('filterServiceCategory', null);
        this.set('filterProduct', null);
        this.set('filterProductCategory', null);
      }

      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterProductOrService'),
    watchSearchType: (function () {
      var self = this;
      this.set('page', 1);

      this.set('filterServiceCategory', null);
      this.set('filterProduct', null);
      this.set('filterService', null);
      this.set('filterProductCategory', null);
      this.set('filterSupplier', null);
      this.set('filterPartnerCategory', null);

      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('searchType'),
    watchProduct: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterProduct'),
    watchService: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterService'),
    watchSupplier: (function () {
      var self = this;
      this.set('page', 1);

      if (this.get('filterSupplier') == null) {
        this.set('partnerCategories', null);
        this.set('filterPartnerCategory', null);
      } else {
        this.send('updatePartnerCategories');
      }

      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterSupplier'),
    watchPartnerCategory: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterPartnerCategory'),
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('pickDate')
  });

});