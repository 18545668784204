define('jason-frontend/components/delete-button', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'span',
    tooltip: 'Löschen',
    cssClass: 'danger',
    actions: {
      confirm: function confirm() {
        var el = this.get('element'),
            $btn = $('.btn-delete', el),
            offset = $btn.position(),
            $confirm = $('.delete-confirm', el),
            height = $confirm.outerHeight(),
            width = $confirm.outerWidth();

        $confirm.css('top', offset.top - height - 20);
        $confirm.css('left', offset.left - width / 2 - 20);

        $confirm.fadeIn();
      },
      cancel: function cancel() {
        var el = this.get('element'),
            $confirm = $('.delete-confirm', el);
        $confirm.fadeOut();
      },
      'delete': function _delete() {
        var el = this.get('element'),
            $btn = $('.btn-delete', el),
            $confirm = $('.delete-confirm', el);
        $confirm.fadeOut();
        this.sendAction('confirmed', $btn.data('id'));
      }
    }
  });

});