define('jason-frontend/components/inventory-correction-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    type: null,
    warningSend: false,
    correctionTypes: [{ name: "Bestand", id: "existing" }, { name: "Schwund", id: "fade" }, { name: "Diebstahl", id: "theft" }, { name: "Ordinationsverbrauch", id: "practiceusage" }, { name: "Zugang", id: "add" }, { name: "Abgelaufen", id: "expired" }, { name: "Ausgelaufen", id: "notavailable" }, { name: "Ausschuss", id: "waste" }, { name: "Abschreiben", id: "deprecate" }],
    actions: {
      load: function load(item, stocktakingId, keepOpen, reopen) {
        Ember['default'].set(item, 'subType', {
          id: 'existing',
          name: 'Bestand'
        });
        Ember['default'].set(item, 'shouldStand', item.flatStock);
        this.set('item', item);
        this.set('keepOpen', keepOpen);
        this.set('product', null);
        this.set('reopen', reopen);
        this.set('stocktakingId', stocktakingId);
        this.set('warningSend', false);
      },
      loadReopen: function loadReopen(item, product) {
        Ember['default'].set(item, 'subType', {
          id: 'existing',
          name: 'Bestand'
        });
        Ember['default'].set(item, 'shouldStand', item.flatStock);
        this.set('item', item);
        this.set('product', product);
        this.set('keepOpen', false);
        this.set('reopen', true);
        this.set('stocktakingId', null);
        this.set('warningSend', false);
      },
      confirm: function confirm() {

        if (this.get('item').shouldStand < 0) {
          jason.notifiction.error('Fehler', 'Der IST-Bestand muss größer/gleich 0 sein');
          return;
        }
        if (this.get('item').shouldStand > this.get('item').packageQuantity) {
          //!this.get('warningSend') &&
          jason.notifiction.errorLong('Achtung', 'Der IST-Bestand darf nicht größer als die Verpackungsmenge sein');
          return;
        }

        Ember['default'].set(this.get('item'), 'subType', this.get('item').subType.id);
        Ember['default'].set(this.get('item'), 'stocktakingId', this.get('stocktakingId'));

        if (this.get('reopen')) {
          this.sendAction('save', this.get('item'), this.get('product'), this.get('keepOpen'), true);
        } else {
          this.sendAction('save', this.get('item'), this.get('stocktakingId'), this.get('keepOpen'), false);
        }

        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      } }
  });

});