define('jason-frontend/helpers/get-age', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getAge = getAge;

  function getAge(params) {
    var dob = params[0],
        deathday = params[1];

    dob = new Date(dob);

    var now = new Date();

    if (deathday != null) {
      now = new Date(deathday);
    }

    var today = new Date(now.getYear(), now.getMonth(), now.getDate());

    var yearNow = now.getYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();

    var yearDob = dob.getYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var age = {};
    var ageString = "";
    var yearString = "";
    var monthString = "";
    var dayString = "";

    var yearAge = yearNow - yearDob;

    if (monthNow >= monthDob) var monthAge = monthNow - monthDob;else {
      yearAge--;
      var monthAge = 12 + monthNow - monthDob;
    }

    if (dateNow >= dateDob) var dateAge = dateNow - dateDob;else {
      monthAge--;
      var dateAge = 31 + dateNow - dateDob;

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }

    age = {
      years: yearAge,
      months: monthAge,
      days: dateAge
    };

    if (age.years > 1) yearString = " Jahre";else yearString = " Jahr";
    if (age.months > 1) monthString = " Monate";else monthString = " Monat";
    if (age.days > 1) dayString = " Tage";else dayString = " Tag";

    if (age.years > 0 && age.months > 0 && age.days > 0) ageString = age.years + yearString + ", " + age.months + monthString + ",  " + age.days + dayString + " alt";else if (age.years == 0 && age.months == 0 && age.days > 0) ageString = age.days + dayString;else if (age.years > 0 && age.months == 0 && age.days == 0) ageString = age.years + yearString;else if (age.years > 0 && age.months > 0 && age.days == 0) ageString = age.years + yearString + " " + age.months + monthString + " alt";else if (age.years == 0 && age.months > 0 && age.days > 0) ageString = age.months + monthString + " " + age.days + dayString + " alt";else if (age.years > 0 && age.months == 0 && age.days > 0) ageString = age.years + yearString + " " + age.days + dayString + " alt";else if (age.years == 0 && age.months > 0 && age.days == 0) ageString = age.months + monthString + " alt";else ageString = "";

    return ageString;
  }

  exports['default'] = Ember['default'].Helper.helper(getAge);

});