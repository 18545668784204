define('jason-frontend/routes/purchases/supplierdetails', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var modelHookRun;

  exports['default'] = Ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      modelHookRun = false;
    },
    model: function model(params) {
      return this.store.find('productSupplier', params.id);
    },
    afterModel: function afterModel(model) {
      if (!modelHookRun) {
        return model.reload();
      }
      return model;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    }
  });

});