define('jason-frontend/components/invoice-add-text', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ['table__row'],
    actions: {
      toggleCompositeFavorite: function toggleCompositeFavorite(product, add) {
        Ember['default'].set(product, 'favorite', add);
        this.sendAction('toggleCompositeFavorite', product.id, add);
      },
      addText: function addText(model, invoiceId, productName) {
        this.sendAction('addText', 1, model.id, invoiceId, productName);
      }
    }
  });

});