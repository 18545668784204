define('jason-frontend/routes/products/edit', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  var modelHookRun;

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    beforeModel: function beforeModel() {
      modelHookRun = false;
    },
    model: function model(params) {
      return this.store.findRecord('product', params.id, { reload: true });
    },
    afterModel: function afterModel(model) {
      if (!modelHookRun) {
        return model.reload();
      }
      return model;
    },
    selectTab: function selectTab(selected) {
      var controller = this.get('controller');
      controller.set('selectTab', selected);
      controller.set('sidebarActive', false);

      var self = this;

      var productId = controller.get('model').get('id');
      if (selected === 'future') {
        controller.set('futureItems', this.store.find('future-action-blueprint', { articleId: controller.get('model').get('id') }));
      } else if (selected === 'treatment') {
        controller.set('sidebarActive', true);
        $.ajax({
          url: "/api/products/" + controller.get('model').get('id') + "/treatmentvalues",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').set('selectedTreatmentValues', data.articleTreatmentValues);
        });

        $('.sortable div.sortable-item').each(function (index, val) {
          $(this).remove();
        });

        $('form.search-box input[type=text]').focus();

        setTimeout(function () {
          $.ajax({
            url: "/api/products/" + self.get('controller').get('model').id + "/textModules",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.get('controller').set('selectedTextModules', data.textModuleArticle);
          }, function () {});
          self.searchTextmodules();
        }, 500);
      } else if (selected === 'sloping') {
        $.ajax({
          url: "/api/products/" + controller.get('model').get('id') + "/slopingInfos",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('dosings', data.productSlopingInfo);
        });
      }
      var self = this;

      if (selected === 'inventory') {

        $.ajax({
          url: "/api/inventoryStocks/product/" + productId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          var first = null;
          if (data.inventoryStock) {
            first = data.inventoryStock[0];
          }
          controller.get('stockDetailPanel').send('load', data.inventoryStock, '', productId, first);
        }, function () {});
      }
    },
    saveTextModules: function saveTextModules() {

      var medicalHistoryEntries = new Array();
      var symptomsEntries = new Array();
      var diagnosesEntries = new Array();
      var therapiesEntries = new Array();
      var reportEntries = new Array();

      $('#sortableMedicalHistory div.sortable-item').each(function (index, val) {
        var id = $(this).attr('data-module-id');
        var entry = {
          position: index,
          textModuleId: id
        };
        medicalHistoryEntries.push(entry);
      });
      $('#sortableSymptoms div.sortable-item').each(function (index, val) {
        var id = $(this).attr('data-module-id');
        var entry = {
          position: index,
          textModuleId: id
        };
        symptomsEntries.push(entry);
      });
      $('#sortableDiagnoses div.sortable-item').each(function (index, val) {
        var id = $(this).attr('data-module-id');
        var entry = {
          position: index,
          textModuleId: id
        };
        diagnosesEntries.push(entry);
      });
      $('#sortableTherapies div.sortable-item').each(function (index, val) {
        var id = $(this).attr('data-module-id');
        var entry = {
          position: index,
          textModuleId: id
        };
        therapiesEntries.push(entry);
      });
      $('#sortableReports div.sortable-item').each(function (index, val) {
        var id = $(this).attr('data-module-id');
        var entry = {
          position: index,
          textModuleId: id
        };
        reportEntries.push(entry);
      });

      var data = {
        medicalHistoryEntries: medicalHistoryEntries,
        diagnosesEntries: diagnosesEntries,
        therapiesEntries: therapiesEntries,
        symptomsEntries: symptomsEntries,
        reportEntries: reportEntries
      };

      $.ajax({
        url: "/api/products/" + this.get('controller').get('model').id + "/textModules",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
      }, function () {});
    },
    searchTextmodules: function searchTextmodules() {
      var self = this;
      $.ajax({
        url: "/api/textModules?type=def&filterName=" + this.get('controller').get('filterName'),
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.get('controller').set('allItems', data.textModule);
        setTimeout(function () {
          $(".draggable").draggable({
            connectToSortable: ".sortable",
            helper: "clone",
            revert: "invalid"
          });
        }, 500);
      });

      $(".sortable").sortable({
        revert: true
      });

      $(".sortable").droppable({
        drop: function drop(event, ui) {
          setTimeout(function () {
            ui.draggable.addClass('fixed');
          }, 500);
        }
      });
    },
    setupController: function setupController(controller, model, params) {

      this._super(controller, model);
      var self = this;
      controller.set('labProfiles', this.store.find('labProfile'));

      controller.get('model').set('additionalPercentage', controller.get('model').get('additionPercentage'));
      controller.set('taxes', this.store.find('tax', { paged: false, onlyValid: true }));
      controller.set('purchaseProduct', this.store.find('purchase-product', model.id));

      this.store.find('serviceunit', { paged: false }).then(function (items) {
        controller.set('allUnits', items.sortBy('name'));
      });

      $.ajax({
        url: "/api/serviceunits?productId=" + model.id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('matchedUnits', data.serviceunit);
      });

      this.store.find('productCategory').then(function (items) {
        controller.set('categories', items.sortBy('fullName'));
      });

      $.ajax({
        url: "/api/productCategories?suggest=false",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('productCategories', data.productCategory);
      });

      this.store.find('productManufacturer').then(function (items) {
        controller.set('manufacturers', items.sortBy('name'));
      });

      this.store.find('productDistributor').then(function (items) {
        controller.set('distributors', items.sortBy('name'));
      });

      this.store.find('patientCategory', { convertDe: false }).then(function (items) {
        controller.set('patientCategories', items.sortBy('name'));
      });

      this.store.find('articleImportHistory', { productId: model.id }).then(function (items) {
        controller.set('history', items.sortBy('created'));
        controller.set('showHistory', true);
      });

      this.store.find('productSupplier', { service: true }).then(function (items) {
        controller.set('suppliers', items.sortBy('name'));
      });

      this.store.find('article-tag', { type: 'service' }).then(function (items) {
        items.forEach(function (item) {
          model.get('tags').forEach(function (foundTag) {
            if (foundTag.id == item.id) {
              Ember['default'].set(item, 'selected', true);
            }
          });
        });
        controller.set('articleTags', items);
      });

      var selectedTab = params.queryParams.selectTab;
      if (selectedTab) {
        setTimeout(function () {
          self.selectTab(selectedTab);
        }, 1000);
      }
    },
    actions: {

      updateLocation: function updateLocation(item) {
        var data = {
          locationInfo: item.locationInfo
        };
        $.ajax({
          url: "/api/inventoryStocks/" + item.id + "/updateLocation",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {});
      },
      updateExpiry: function updateExpiry(item) {
        var data = {
          expiryDate: item.expiryDate
        };

        if (new String(data.expiryDate).indexOf(".") == -1) {
          data.expiryDate = moment(item.expiryDate).format('DD. MM. YYYY');
        }

        $.ajax({
          url: "/api/inventoryStocks/" + item.id + "/updateExpiryDate",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {});
      },
      updateBatchNumber: function updateBatchNumber(item) {
        var data = {
          batchNumber: item.batchNumber
        };
        $.ajax({
          url: "/api/inventoryStocks/" + item.id + "/updateBatchNumber",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {});
      },
      searchTextModules: function searchTextModules() {
        this.searchTextmodules();
      },
      toggleSidebar: function toggleSidebar() {
        var controller = this.get('controller');
        if (controller.get('sidebarActive') === true) {
          controller.set('sidebarActive', false);
        } else {
          controller.set('sidebarActive', true);
          setTimeout(function () {
            $('aside.side-bar div.scrollable').css('max-height', $(window).height() - 375);
          }, 100);
        }
      },
      addServiceCategory: function addServiceCategory(category, title, selectedValues) {

        var self = this;

        $.ajax({
          url: "/api/services?filterMandant=all&page=1&per_page=10&selectedCategoryFilters=" + category + "&sortBy=&sortDir=",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('treatmentSelectServicePanel').send('load', data.service, selectedValues, title);
        }, function (error) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-select-service-panel'
          },
          callbacks: {
            close: function close() {
              var values = Array();

              $('#treatmentSelectServiceFormSelectedValues > div').each(function (index, item) {
                values.push($(item).attr('data-id'));
              });

              var data = {
                id: category,
                value: values.join(',')
              };

              $.ajax({
                url: "/api/products/" + self.get('controller').get('model').id + "/treatmentvalues",
                method: "POST",
                contentType: "application/json",
                dataType: "json",
                data: JSON.stringify(data),
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {
                self.selectTab('treatment');
              }, function (error) {
                self.selectTab('treatment');
              });
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectMultistageTreatmentDetail: function selectMultistageTreatmentDetail(provider, selected, title) {
        this.saveTextModules();
        var self = this;
        this.get('controller').get('treatmentMultiStageSelectPanel').send('load', provider, selected, self, title);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-multistage-select-panel'
          },
          callbacks: {
            close: function close() {
              var data = {
                id: provider,
                value: $('form#treatmentMultistageSelectForm').attr('data-ids')
              };

              $.ajax({
                url: "/api/products/" + self.get('controller').get('model').id + "/treatmentvalues",
                method: "POST",
                contentType: "application/json",
                dataType: "json",
                data: JSON.stringify(data),
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {
                self.selectTab('treatment');
              }, function (error) {
                self.selectTab('treatment');
              });
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectTreatmentDetailInfoMulti: function selectTreatmentDetailInfoMulti(provider, selected, title) {
        this.saveTextModules();
        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/lookups?type=" + provider,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('treatmentSelectPanel').send('loadMultiselect', data.treatmentLookup, selected, title);
        }, function (error) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-select-panel'
          },
          callbacks: {
            close: function close() {

              var value = null;
              $('#treatmentSelectFormSelectedValues > div').each(function (index, item) {
                value += $(item).attr('data-id') + ',';
              });

              var data = {
                id: provider,
                value: value
              };

              $.ajax({
                url: "/api/products/" + self.get('controller').get('model').id + "/treatmentvalues",
                method: "POST",
                contentType: "application/json",
                dataType: "json",
                data: JSON.stringify(data),
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {
                self.selectTab('treatment');
              }, function (error) {
                self.selectTab('treatment');
              });
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      saveTextModules: function saveTextModules() {
        this.saveTextModules();
      },
      setReorderActive: function setReorderActive(reorder) {
        if (reorder.active) {
          Ember['default'].set(reorder, "active", false);
        } else {
          Ember['default'].set(reorder, "active", true);
        }
      },
      saveReorder: function saveReorder(model) {
        var product = {};
        var self = this;

        product.reorderSettings = this.get('controller').get('purchaseProduct').get('reorderSettings');
        product.id = model.id;
        product.ehapoActive = model.get('ehapoActive');
        product.orderNotice = this.get('controller').get('purchaseProduct').get('orderNotice');
        product.autosendReorder = this.get('controller').get('purchaseProduct').get('autosendReorder');

        $.ajax({
          url: "/api/purchaseProducts/reorderInfos",
          method: "POST",
          data: JSON.stringify(product),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        }, function (error) {});
      },
      queryManufacturer: function queryManufacturer(query, deferred) {
        this.store.find('productManufacturer', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      toPurchase: function toPurchase(model) {
        var self = this;
        Ember['default'].$.ajax({
          url: "/api/purchaseOrders/practiceOrder",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (data && data > 0) {
            self.transitionTo('purchases.detail', model.id, data, { queryParams: { selectTab: "purchase" } });
          }
        });
      },
      showInvoice: function showInvoice(id) {
        this.transitionTo('invoices.show', id);
      },
      showPatient: function showPatient(id) {
        this.transitionTo('patients.edit', id, { queryParams: { selectTab: "history" } });
      },
      printBarcode: function printBarcode(id) {
        window.open('/api/inventoryRequests/barcodes/' + id + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      printQr: function printQr(id) {
        window.open('/api/products/' + id + '/qr');
      },
      showStock: function showStock(id) {
        this.transitionTo('inventory.stock-details', id);
      },
      selectTab: function selectTab(selected) {
        this.selectTab(selected);
      },
      triggerSubmit: function triggerSubmit() {
        $('form#productEdit').submit();
      },
      triggerSubmitSloping: function triggerSubmitSloping() {
        $('form#productSlopginEdit').submit();
      },
      addFutureAction: function addFutureAction(id) {
        var self = this,
            data = {};
        $.ajax({
          url: "/api/futureActionBlueprints/" + id,
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (responseData) {
          self.transitionTo('practicemgmt.treatment-future-action', 'products', id, responseData);
        });
      },
      saveSlopingInfo: function saveSlopingInfo(data) {
        var self = this,
            id = this.get('controller').get('model').id;
        $.ajax({
          url: "/api/products/" + id + "/slopingInfo",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (responseData) {
          self.selectTab('sloping');
        });
      },
      deleteEntry: function deleteEntry(modelId, itemId) {
        var self = this;
        var deferred = $.ajax({
          url: "/api/futureActionBlueprints/" + itemId,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Maßnahme entfernt', '');
          self.get('controller').set('futureItems', self.store.find('future-action-blueprint', { articleId: self.get('controller').get('model').get('id') }));
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      deleteSlopping: function deleteSlopping(id) {
        var self = this;
        var deferred = $.ajax({
          url: "/api/products/" + this.get('controller').get('model').id + "/slopingInfo/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Dosierungsinformation entfernt', '');
          self.selectTab('sloping');
        }, function (error) {
          jason.notifiction.notifiy('Dosierungsinformation entfernt', '');
          self.selectTab('sloping');
        });
        return Ember['default'].RSVP.resolve(deferred);
      },
      editEntry: function editEntry(itemId, modelId) {
        this.transitionTo('practicemgmt.treatment-future-action', 'products', itemId, modelId);
      }
    }
  });

});