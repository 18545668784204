define('jason-frontend/serializers/invoice', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  exports['default'] = DS['default'].ActiveModelSerializer.extend(DS['default'].EmbeddedRecordsMixin, {
    attrs: {
      entries: { embedded: 'always' },
      payments: { embedded: 'always' },
      taxEntries: { embedded: 'always' },
      patients: { embedded: 'always' },
      invoicePatients: { embedded: 'always' },
      customer: { embedded: 'always' },
      user: { embedded: 'always' },
      doctor: { embedded: 'always' },
      statusChangeUser: { embedded: 'always' },
      assistant: { embedded: 'always' },
      referenceInvoice: { embedded: 'always' },
      references: { embedded: 'always' }
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      var serialized = this.serialize(record, options);
      serialized.id = record.id ? record.id : 0;
      Ember['default'].merge(hash, serialized);
    }
  });

});