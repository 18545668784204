define('jason-frontend/components/button-add-action', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'top',
    cllClass: 'cls-1',
    timer: 0,
    showStroke: false,
    content: '',
    theme: 'tooltipster-punk',
    color: '#3B6182'
  });

});