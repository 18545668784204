define('jason-frontend/components/patient-medias', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      'delete': function _delete(id) {
        this.sendAction('deleteMedia', id);
      },
      forwardEmail: function forwardEmail(id) {
        this.sendAction('forwardEmail', id);
      },
      downloadFile: function downloadFile(id) {
        window.open('/api/media/' + id + '/download?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      }
    }
  });

});