define('jason-frontend/helpers/is-cat-selected2', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isCatSelected2 = isCatSelected2;

  function isCatSelected2(params /*, hash*/) {
    var selectedList = params[0],
        categoryId = params[1],
        returnValue = false;

    if (selectedList) {
      $.each(selectedList, function (index, value) {
        if (value === categoryId) {
          returnValue = true;
          return;
        }
      });
    }
    return returnValue;
  }

  exports['default'] = Ember['default'].Helper.helper(isCatSelected2);

});