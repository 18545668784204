define('jason-frontend/components/icon-medical-incompatibility', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';


  exports['default'] = TooltipsterComponent['default'].extend({
    size: 50,
    value: false,
    side: 'bottom',
    timer: 0,
    content: 'Chronische Erkrankungen / Medikamentenunverträglichkeit',
    theme: 'tooltipster-punk'
  });

});