define('jason-frontend/helpers/is-patient-selected', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isPatientSelected = isPatientSelected;

  function isPatientSelected(params) {
    var selectedList = params[0],
        patient = params[1],
        returnValue = false;
    if (patient && selectedList) {
      $.each(selectedList, function (index, value) {
        if (value == patient.id) {
          returnValue = true;
          return;
        }
      });
    }
    return returnValue;
  }

  exports['default'] = Ember['default'].Helper.helper(isPatientSelected);

});