define('jason-frontend/models/purchase-order-entry', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    quantity: DS['default'].attr('number'),
    returnQuantity: DS['default'].attr('number'),
    alreadyReturnedQuantity: DS['default'].attr('number'),
    preFixedQuantity: DS['default'].attr('number'),
    inventoryStatus: DS['default'].attr('string'),
    fixedQuantity: DS['default'].attr('number'),
    unitQuantity: DS['default'].attr('number'),
    price: DS['default'].attr('number'),
    retailPrice: DS['default'].attr('number'),
    commission: DS['default'].attr('number'),
    comment: DS['default'].attr('string'),
    transactionFee: DS['default'].attr('number'),
    saving: DS['default'].attr('number'),
    article: DS['default'].belongsTo('article'),
    total: DS['default'].attr('number'),
    tax: DS['default'].belongsTo('tax'),
    unit: DS['default'].belongsTo('serviceunit'),
    sellingAmount: DS['default'].attr('number'),
    jasonPriceDivided: DS['default'].attr('number'),
    retailPriceDivided: DS['default'].attr('number'),
    laboklinQuickInfos: DS['default'].attr('string'),
    laboklinFormId: DS['default'].attr('number'),
    laboklinFormStatusId: DS['default'].attr('string'),
    deliveryStatus: DS['default'].attr(''),
    deliveryStatusText: DS['default'].attr(''),
    confirmOrder: DS['default'].attr('')
  });

});