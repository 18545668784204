define('jason-frontend/routes/practicemgmt/emergency-service', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {
      this.applyFilter();
    },
    actions: {
      "delete": function _delete(id) {
        var self = this;
        $.ajax({
          url: "/api/emergencyServices/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          jason.notifiction.notifiy('Erfolgreich', 'entfernt');
          self.applyFilter();
        }, function (error) {});
      },
      edit: function edit(data, id) {
        var self = this;
        var pay = {
          id: data.id,
          endString: data.get('endString'),
          startString: data.get('startString'),
          comment: data.get('comment'),
          endStringTime: data.get('endStringTime'),
          startStringTime: data.get('startStringTime')
        };

        $.ajax({
          url: "/api/emergencyServices",
          method: "POST",
          data: JSON.stringify(pay),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          self.applyFilter();
        }, function (error) {});
      }
    },
    applyFilter: function applyFilter() {

      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      controller.set('showLoading', true);

      this.findPaged('emergency-service', {
        filterFrom: from,
        filterTo: to,
        per_page: 50
      }).then(function (transactions) {
        controller.set('meta', transactions.meta);
        controller.set('model', transactions);
        controller.set('showLoading', false);
      });
    }
  });

});