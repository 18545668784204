define('jason-frontend/controllers/purchases/cancel', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Retoure / Storno",
    breadCrumbPath: "purchases.cancel",
    reason: null,
    accept: false
  });

});