define('jason-frontend/components/patient-vaccinations', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',
    actions: {
      gotoArticle: function gotoArticle(id, type) {
        if (type === 'service') {
          this.sendAction('gotoService', id);
        }
        if (type === 'product') {
          this.sendAction('gotoProduct', id);
        }
      }
    }
  });

});