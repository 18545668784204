define('jason-frontend/components/email-etiga-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    months: null,
    type: null,
    qr: null,
    selectedEmailFromDropdown: null,
    actions: {
      openAddAppDirectNotification: function openAddAppDirectNotification() {
        this.sendAction('openAddAppDirectNotification');
      },
      qrDownload: function qrDownload() {
        this.sendAction('qrDownload');
        $.magnificPopup.close();
      },
      send: function send(invoice) {

        var form = $('#emailEtigaForm');

        var value = form.find('input[name=email]').val(),
            persist = form.find('#persist-email-etiga').is(':checked');

        if (value.length < 3) {
          jason.notifiction.systemError('Fehler', 'Bitte geben Sie eine gültige Email-Adresse ein');
          return;
        }

        var data = {
          email: value,
          persist: persist
        };

        this.sendAction('confirm', this.get('id'), data);

        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(id, email, qr) {
        this.set('id', id);
        this.set('email', email);
        this.set('qr', qr);
      },
      loadWithEmails: function loadWithEmails(id, emails, qr) {
        this.set('id', id);
        this.set('qr', qr);
        this.set('emails', emails);
        if (emails && emails != null && emails.length > 0) {
          this.set('selectedEmailFromDropdown', emails[0]);
          this.set('email', emails[0].value);
        }
      }
    },
    watchEmails: (function () {
      this.set('email', this.get('selectedEmailFromDropdown').value);
    }).observes('selectedEmailFromDropdown')
  });

});