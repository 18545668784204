define('jason-frontend/helpers/is-debug-user', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isDebugUser = isDebugUser;

  function isDebugUser() {
    return API['default'].isDebugUser();
  }

  exports['default'] = Ember['default'].Helper.helper(isDebugUser);

});