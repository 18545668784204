define('jason-frontend/controllers/practicemgmt/payment', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Fertig zum Bezahlen",
    breadCrumbPath: "practicemgmt.payment",
    filterCustomer: null,
    customerId: null,
    watchCustomer: (function () {
      var self = this;
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterCustomer')
  });

});