define('jason-frontend/templates/components/select-treatment-doctors', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 30,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/select-treatment-doctors.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","field--narrow");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2,"for","treatment-doctor");
        dom.setAttribute(el2,"class","select--inline input-element");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createTextNode("Arzt/Ärztin");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","field--narrow");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2,"for","treatment-assistant");
        dom.setAttribute(el2,"class","select--inline input-element");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createTextNode("Assistent/in");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]),3,3);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2, 1]),3,3);
        return morphs;
      },
      statements: [
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","doctors",["loc",[null,[5,12],[5,19]]]]],[],[]],"value",["subexpr","@mut",[["get","doctor",["loc",[null,[6,10],[6,16]]]]],[],[]],"cssClass","input--dropdown doctor","optionLabelPath","shortName","allowClear",true,"name","treatment-doctor","placeholder","Arzt/Ärztin wählen"],["loc",[null,[4,2],[12,4]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","assistants",["loc",[null,[20,14],[20,24]]]]],[],[]],"value",["subexpr","@mut",[["get","assistant",["loc",[null,[21,12],[21,21]]]]],[],[]],"cssClass","input--dropdown assistant","optionLabelPath","shortName","allowClear",true,"name","treatment-assistant","placeholder","Assistent/in wählen"],["loc",[null,[19,4],[27,6]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});