define('jason-frontend/controllers/mandants/dashboard', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Meine Umsätze",
    breadCrumbPath: "mandants.dashboard",
    mandant: null,
    supplier: null,
    showLoading: false,
    sortBy: 'sumPurchases',
    sortDir: 'asc',
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),
    actions: {
      sortBy: function sortBy(_sortBy) {

        if (_sortBy === this.get('sortBy')) {
          if (this.get('sortDir') === 'desc') {
            this.set('sortDir', 'asc');
          } else {
            this.set('sortDir', 'desc');
          }
        }
        this.set('sortBy', _sortBy);
        this.send('applyFilter');
      }
    },
    watchMandants: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('mandant'),
    watchSuppliers: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('supplier'),
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('pickDate')
  });

});