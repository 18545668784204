define('jason-frontend/routes/purchases/dashboard', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('suppliers', this.store.find('product-supplier', { filterPurchase: true }));
      controller.set('mandants', this.store.find('mandant'));
      this.applyFilter();
    },
    actions: {
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      },
      sortable: function sortable(attr) {
        if (this.get('controller').get('sortBy') === attr) {
          if (this.get('controller').get('sortDir') === 'asc') {
            this.get('controller').set('sortDir', 'desc');
          } else {
            this.get('controller').set('sortDir', 'asc');
          }
        } else {
          this.get('controller').set('sortBy', attr);
        }
        this.applyFilter();
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterType = controller.get('filterType'),
          filterSupplier = controller.get('filterSupplier'),
          filterMandant = controller.get('filterMandant'),
          self = this;

      if (filterSupplier && isNaN(filterSupplier)) {
        filterSupplier = filterSupplier.id;
      }
      if (filterMandant && isNaN(filterMandant)) {
        filterMandant = filterMandant.id;
      }

      controller.set('showLoading', true);

      this.findPaged('purchaseDashboard', {
        filterType: filterType,
        filterSupplier: filterSupplier,
        filterMandant: filterMandant,
        per_page: 10
      }).then(function (data) {
        var meta = data.meta;
        controller.set('showLoading', false);
        controller.set('model', data);
        controller.set('headers', meta.headers);
        controller.set('totalRevenue', meta.totalRevenue);
        controller.set('totalSaving', meta.totalSaving);
        controller.set('totalCashback', meta.totalCashback);
        controller.set('totalAdvantage', meta.totalAdvantage);
      });
    }
  });

});