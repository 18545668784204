define('jason-frontend/helpers/is-jason-network', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isJasonNetwork = isJasonNetwork;

  function isJasonNetwork() {
    return API['default'].getMandant() === '4';
  }

  exports['default'] = Ember['default'].Helper.helper(isJasonNetwork);

});