define('jason-frontend/routes/xrayprofiles/show', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('xray-profile', params.id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    }
  });

});