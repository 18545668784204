define('jason-frontend/templates/components/button-add', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-add.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("<span>+</span>");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"width","37");
        dom.setAttribute(el1,"height","42");
        dom.setAttribute(el1,"viewBox","0 0 37 42");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(1 1)");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("<circle cx=\"16\" cy=\"16\" r=\"16\" stroke=\"{{color}}\"/>");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","translate(19 24)");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("circle");
        dom.setAttribute(el4,"class","circle");
        dom.setAttribute(el4,"cx","8");
        dom.setAttribute(el4,"cy","8");
        dom.setAttribute(el4,"r","8");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"class","plus");
        dom.setAttribute(el4,"d","M8.444 7.65V3.49c0-.22-.178-.395-.396-.395-.22 0-.397.177-.397.395v4.16H3.49c-.22 0-.395.18-.395.398 0 .22.177.396.395.396h4.16v4.162c0 .218.18.394.398.394.22 0 .396-.177.396-.394V8.444h4.162c.218 0 .394-.178.394-.396 0-.22-.177-.397-.394-.397H8.444z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("<circle cx=\"16\" cy=\"16\" r=\"16\" stroke=\"{{color}}\" class=\"circle\"/>");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","icon");
        dom.setAttribute(el3,"d","M23.862 11.77l-.66-.04-9.774-.712-4.706-.328-.208-1.06c-.076-.346-.302-.654-.605-.808L6.68 8.4c-.19-.078-.415.037-.472.23-.076.192.038.423.227.48l1.153.406c.094.057.17.154.19.27l1.776 9.417c.018.077 0 .154-.02.21l-.472 1.253c-.132.347-.094.75.132 1.06.227.307.567.5.927.5H23.2c.21 0 .38-.173.38-.385 0-.21-.17-.384-.38-.384H10.12c-.132 0-.245-.058-.302-.174-.075-.115-.094-.23-.038-.365l.454-1.214h12.4c.51 0 .964-.346 1.096-.847l1.172-5.7c.075-.328 0-.675-.208-.925-.19-.27-.49-.423-.832-.46zm-.87 6.913c-.037.154-.188.27-.358.27H10.29l-.7-3.68-.055-.288-.662-3.524 3.856.27 1 .077 8.715.636 1 .077.38.02c.113 0 .207.057.283.153.057.077.076.193.057.308l-1.172 5.683zM11.86 22.226c-.832 0-1.513.693-1.513 1.54 0 .848.68 1.54 1.513 1.54.83 0 1.512-.692 1.512-1.54 0-.847-.68-1.54-1.512-1.54zm0 2.31c-.416 0-.756-.346-.756-.77 0-.423.34-.77.756-.77.415 0 .756.347.756.77 0 .424-.34.77-.756.77zm9.45-2.31c-.83 0-1.512.693-1.512 1.54 0 .848.68 1.54 1.513 1.54.832 0 1.513-.692 1.513-1.54 0-.847-.68-1.54-1.512-1.54zm0 2.31c-.415 0-.756-.346-.756-.77 0-.423.34-.77.757-.77.416 0 .757.347.757.77 0 .424-.34.77-.756.77z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 1]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(element0, [7]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element2, 'stroke');
        morphs[1] = dom.createAttrMorph(element3, 'fill');
        morphs[2] = dom.createAttrMorph(element4, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","stroke",["concat",[["get","color",["loc",[null,[7,65],[7,70]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[8,39],[8,44]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[11,1152],[11,1157]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});