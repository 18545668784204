define('jason-frontend/components/data-outdated-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      load: function load(user) {
        this.set('user', user);
      },
      close: function close() {
        $.magnificPopup.close();
      },
      reload: function reload() {
        this.sendAction('reload');
        jason.notifiction.notifiy('Daten', 'neu geladen');
        $.magnificPopup.close();
      }
    }
  });

});