define('jason-frontend/controllers/inventory/warehouses', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Meine Lager",
    breadCrumbPath: "inventory.warehouses",
    showLoading: false
  });

});