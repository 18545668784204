define('jason-frontend/routes/purchases/promotions', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",

    model: function model(params) {},
    setupController: function setupController(controller, model, params) {
      var self = this;
      controller.set("model", model);
      controller.set("showLoading", true);

      Ember['default'].$.ajax({
        url: "/api/purchaseOrders/practiceOrder",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        if (data && data > 0) {
          controller.set("orderId", data);
        }
      });

      this.store.unloadAll('productSupplier');

      controller.set('suppliers', this.store.find('product-supplier', { filterPurchase: true }));
      controller.set('promotionCategories', this.store.find('promotion-category'));

      Ember['default'].$.ajax({
        url: "/api/purchaseProducts/topPromotions",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set("topPromotions", data.topPromotion);
      });

      setTimeout(function () {
        controller.set("filterSupplier", "all");
        controller.set("selectedCategoryFilter", null);
        controller.set("filterPromotionCategory", null);
        controller.set("selectedPartnerCategoryFilter", null);
        controller.set("partnerCategories", null);
        controller.set("promotionActiveFilter", null);

        self.applyFilter();
      }, 200);
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          promotionActive = true,
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy'),
          filterSupplier = controller.get('filterSupplier'),
          supplierId = null,
          randomSearch = true,
          filterFavorites = controller.get('filterFavorites'),
          promotionCategoryId = controller.get('filterPromotionCategory');

      controller.set("showLoading", true);

      sortBy = "name.sort";
      sortDir = "asc";

      if (promotionCategoryId) {
        promotionCategoryId = promotionCategoryId.id;
      }

      if (filterSupplier) {
        supplierId = filterSupplier;
      }

      if (supplierId === 'all') {
        supplierId = null;
      }

      if (API['default'].isSupplier()) {
        randomSearch = false;
        supplierId = API['default'].getSupplierId();
      }

      this.findPaged('purchaseProduct', {
        filterName: filterName,
        filterPurchase: true,
        showInvisible: false,
        filterFavorites: filterFavorites,
        promotionCategoryId: promotionCategoryId,
        promotionActive: promotionActive,
        filterMandant: "all",
        randomSearch: randomSearch,
        filterSupplier: supplierId,
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 16
      }).then(function (entries) {
        var meta = entries.meta;
        controller.set('model', entries); //entries

        controller.set('totalEntries', meta.total_records);
        controller.set("showLoading", false);
      });
    },
    actions: {
      clickPromotion: function clickPromotion(promo) {
        var self = this;
        var controller = this.get('controller');
        if (promo.promotionId) {
          var data = {
            promotionId: promo.promotionId,
            mandantId: promo.mandantId
          };
          $.ajax({
            url: "/api/purchaseProducts/trackTopPromotion",
            method: "POST",
            contentType: "application/json",
            data: JSON.stringify(data),
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {
            self.transitionTo('purchases.add_product', controller.get('orderId'), { queryParams: { campaignId: promo.promotionId } });
          }, function (error) {
            self.transitionTo('purchases.add_product', controller.get('orderId'), { queryParams: { campaignId: promo.promotionId } });
          });
        } else {

          var data = {
            productId: promo.productId,
            mandantId: promo.mandantId
          };
          $.ajax({
            url: "/api/purchaseProducts/trackTopPromotion",
            method: "POST",
            contentType: "application/json",
            data: JSON.stringify(data),
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {
            self.transitionTo('purchases.detail', promo.productId, controller.get('orderId'));
          }, function (error) {
            self.transitionTo('purchases.detail', promo.productId, controller.get('orderId'));
          });
        }
      },
      toggleFavorite: function toggleFavorite(id, add) {
        var controller = this.get('controller');
        if (add) {
          $.ajax({
            url: "/api/purchaseProducts/" + id + "/addToFavs",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        } else {
          $.ajax({
            url: "/api/purchaseProducts/" + id + "/removeFromFavs",
            method: "DELETE",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        }
      },
      changeView: function changeView(value) {
        this.controller.set('list', value);
      },
      gotoDetail: function gotoDetail(product, order) {
        this.transitionTo('purchases.detail', product, order);
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      setSort: function setSort(attr) {
        var controller = this.get('controller');
        controller.set('sortBy', attr);
        if (controller.get('sortDir') === 'asc') {
          controller.set('sortDir', 'desc');
        } else {
          controller.set('sortDir', 'asc');
        }
        this.applyFilter();
      }
    }
  });

});