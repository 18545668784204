define('jason-frontend/routes/purchases/labresults', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {
      params.paged = true;
      return this.findPaged('purchase-order-laboklin-form', params);
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterId = controller.get('filterId'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      this.findPaged('purchase-order-laboklin-form', {
        filterId: filterId,
        sortDir: sortDir,
        paged: true,
        sortBy: sortBy,
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    actions: {
      saveResult: function saveResult(item) {

        var self = this;

        var msg = {
          url: item.url
        };

        $.ajax({
          url: "/api/labResults/" + item.id,
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(msg),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          self.applyFilter();
        }, function (error) {
          self.applyFilter();
        });
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      openUploadPanel: function openUploadPanel(item) {

        this.get('controller').get('labresultUploadPanel').send('load', item);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-labresult-upload-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      'delete': function _delete(id) {}
    }
  });

});