define('jason-frontend/routes/documents/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {

    model: function model(params) {
      return null;
    },
    setupController: function setupController(controller, model, params) {
      var self = this;
      setTimeout(function () {
        controller.get('documentsPanel').send('load', self);
      }, 500);

      var deferred = $.ajax({
        url: "/api/documentTags",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('tags', data.documentTag);
      }, function (data) {
        controller.set('tags', data.documentTag);
      });
    },
    actions: {
      selectSearchTag: function selectSearchTag(tag) {
        if (tag.selected === true) {
          Ember['default'].set(tag, 'selected', false);
        } else {
          Ember['default'].set(tag, 'selected', true);
        }
        var self = this;
        setTimeout(function () {
          var tags = new Array();
          $('#tagFilters label.info-badge.selected').each(function () {
            tags.push($(this).attr('data-id'));
          });
          self.get('controller').get('documentsPanel').send('applyFilterWithTags', tags.join(","));
        }, 300);
      },
      updateSearch: function updateSearch(enabled, term, uploadEnabled, folderId, patientOrCustomer) {
        if (term === null) {
          term = "";
        }
        this.get('controller').set('filterName', term);
        this.get('controller').set('searchEnabled', enabled);
        this.get('controller').set('uploadEnabled', uploadEnabled);
        this.get('controller').set('downloadFolderEnabled', patientOrCustomer);

        if (folderId === 'folder26' || folderId === 26) {
          this.get('controller').set('downloadFolderEnabled', true);
        }
        if (patientOrCustomer || folderId === 'folder3333' || folderId === 3333) {
          this.get('controller').set('tagsEnabled', true);
          this.get('controller').set('searchEnabled', true);
        } else {
          this.get('controller').set('tagsEnabled', false);
        }

        if (folderId === 'folder3' || folderId === 3) {
          this.get('controller').set('searchPlaceholder', 'Kunde suchen');
        } else {
          this.get('controller').set('searchPlaceholder', 'Dokumente suchen');
        }
      },
      searchDocument: function searchDocument() {
        this.get('controller').get('documentsPanel').send('externalSearch', this.get('controller').get('filterName'));
      },
      downloadFolder: function downloadFolder() {
        this.get('controller').get('documentsPanel').send('downloadFolder');
      },
      gotoDeliveryNote: function gotoDeliveryNote(id) {
        this.transitionTo('inventory.delivery-note-details', id);
      },
      openDocumentUpload: function openDocumentUpload() {
        this.get('controller').get('documentsPanel').send('openUploadPanel');
      }
    }
  });

});