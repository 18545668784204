define('jason-frontend/helpers/show-treatment-offer-filtered', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.showTreatmentOfferEntries = showTreatmentOfferEntries;

  function showTreatmentOfferEntries(params) {
    var entries = params[0],
        showServices = params[1],
        showArticles = params[2],
        showFeeding = params[3],
        showLabor = params[4],
        filterUser = params[5],
        treatmentPatient = params[6],
        serviceCategories = ['be', 'div', 'fre', 'im', 'lab', 'op'];

    if (!showServices && !showArticles && !showFeeding && !showLabor && !filterUser) {
      return true;
    }

    var doctorId = treatmentPatient.get('doctor') ? treatmentPatient.get('doctor').id : null,
        assistantId = treatmentPatient.get('assistant') ? treatmentPatient.get('assistant').id : null;

    var atLeastOne = false;
    if (filterUser) {
      atLeastOne = true;
      if (!(doctorId == filterUser || assistantId == filterUser)) {
        return false;
      }
    }

    $.each(entries.currentState, function (index, value) {

      if (value) {

        var serviceCategory = value.record.get('serviceCategory'),
            productCategory = value.record.get('productCategory'),
            articleType = value.record.get('articleType');

        if (showServices && articleType === 'service') {
          // $.inArray(serviceCategory, serviceCategories) > 0
          atLeastOne = true;
          return;
        }

        if (showArticles && productCategory !== 'ern' && articleType === 'product') {
          // productCategory
          atLeastOne = true;
          return;
        }

        if (showFeeding && productCategory === 'ern') {
          atLeastOne = true;
          return;
        }
        if (showLabor && (productCategory === 'labor' || serviceCategory === 'lab')) {
          atLeastOne = true;
          return;
        }
      }
    });
    return atLeastOne;
  }

  exports['default'] = Ember['default'].Helper.helper(showTreatmentOfferEntries);

});