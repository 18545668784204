define('jason-frontend/components/product-upload', ['exports', 'ember', 'ember-uploader', 'jason-frontend/api'], function (exports, Ember, EmberUploader, API) {

  'use strict';

  exports['default'] = EmberUploader['default'].FileField.extend({
    filesDidChange: function filesDidChange(files) {
      var _this = this;

      var data = {
        mandantId: API['default'].getMandant(),
        downloadToken: API['default'].getDownloadToken()
      };

      var uploader = EmberUploader['default'].Uploader.create({
        url: '/api/mediaUploads/product'
      });

      if (!Ember['default'].isEmpty(files)) {
        uploader.upload(files[0], data);
      }

      uploader.on('didUpload', function (e) {
        _this.sendAction('uploadDone', e);
      });

      uploader.on('progress', function (e) {
        _this.sendAction('showLoading2');
      });

      uploader.on('didError', function (jqXHR, textStatus, errorThrown) {
        jason.notifiction.error('Dokument konnte nicht hochgeladen werden', 'max. 10 Mbyte erlaubt');
      });
    }
  });

});