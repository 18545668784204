define('jason-frontend/helpers/has-booking-app', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.hasBookingApp = hasBookingApp;

  function hasBookingApp(params) {
    return API['default'].hasBookingApp();
  }

  exports['default'] = Ember['default'].Helper.helper(hasBookingApp);

});