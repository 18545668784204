define('jason-frontend/helpers/get-max-deposit-value', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getMaxDepositValue = getMaxDepositValue;

  function getMaxDepositValue(params /*, hash*/) {
    var invoiceTotal = params[0],
        depositAvailable = params[1],
        value = depositAvailable;

    if (depositAvailable > invoiceTotal) {
      value = invoiceTotal;
    }

    return (value + '').replace('.', ',');
  }

  exports['default'] = Ember['default'].Helper.helper(getMaxDepositValue);

});