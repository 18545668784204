define('jason-frontend/helpers/count-active-payments', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.countActivePayments = countActivePayments;

  function countActivePayments(params) {
    var paymentSums = params[0];
    var breakpoint = params[1];
    var type = params[2];

    if (!paymentSums) {
      return 0;
    }
    var count = 1;
    if (paymentSums.cash) {
      count++;
    }
    if (paymentSums.cashcard) {
      count++;
    }
    if (paymentSums.creditcard) {
      count++;
    }
    if (paymentSums.voucher) {
      count++;
    }
    if (paymentSums.deposit) {
      count++;
    }

    if (count == 5 && type === 'total' && breakpoint === 'desk') {
      return 8;
    }

    if (breakpoint === 'desk') {
      return Math.floor(24 / count);
    } else if (breakpoint === 'tablet') {
      if (count <= 2) {
        return 12;
      }
      return 8;
    } else if (breakpoint === 'phone') {
      if (type === 'total' && count % 2 > 0) {
        return 24;
      }
      return 12;
    }
  }

  exports['default'] = Ember['default'].Helper.helper(countActivePayments);

});