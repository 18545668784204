define('jason-frontend/components/aniveri-treatment-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    data: null,
    form: null,
    acceptData: false,
    treatment: true,
    allowUnlinking: true,
    showLoading: false,
    enforceReadOnline: false,
    animalAllergies: [{ name: "Milben", id: "milben" }, { name: "Floh", id: "floh" }, { name: "Fisch", id: "fisch" }, { name: "Rind", id: "rind" }, { name: "Huhn", id: "huhn" }, { name: "Ente", id: "ente" }, { name: "Pferd", id: "pferd" }, { name: "Ei", id: "ei" }, { name: "Weizen", id: "weizen" }, { name: "Soja", id: "soja" }, { name: "Reis", id: "reis" }, { name: "Kartoffel", id: "kartoffel" }, { name: "Gräser", id: "graeser" }, { name: "Sonstige", id: "sonstige" }],
    animalOrigins: [{ name: "Züchter", id: "breeder" }, { name: "Tierheim", id: "shelter" }, { name: "Privat", id: "private" }, { name: "Ausland", id: "foreign" }, { name: "Sonstiges", id: "misc" }],
    animalParasists: [{ name: "Ja", id: "ja" }, { name: "Nein", id: "nein" }, { name: "Ich weiß es nicht", id: "ich_weiss_es_nicht" }],
    animalFeedingsPerDay: [{ name: "1", id: "1" }, { name: "2", id: "2" }, { name: "3", id: "3" }, { name: "4", id: "4" }, { name: "5", id: "5" }, { name: "mehr als 5", id: "mehr_als_5" }],
    animalDrinkings: [{ name: "sehr wenig", id: "sehr_wenig" }, { name: "wenig", id: "wenig" }, { name: "normal", id: "normal" }, { name: "viel", id: "viel" }, { name: "sehr viel", id: "sehr_viel" }],
    animalActiveness: [{ name: "sehr faul", id: "sehr_faul" }, { name: "faul", id: "faul" }, { name: "normal", id: "normal" }, { name: "aktiv", id: "aktiv" }, { name: "sehr aktiv", id: "sehr_aktiv" }],
    animalShapes: [{ name: "1 - untergewichtig", id: "1_untergewichtig" }, { name: "2 - leicht untergewichtig", id: "2_leicht_untergewichtig" }, { name: "3 - normal", id: "3_normal" }, { name: "4 - leicht übergewichtig", id: "4_leicht_uebergewichtig" }, { name: "5 - übergewichtig", id: "5_uebergewichtig" }],
    animalCurrentDiseases: [{ name: "Durchfall", id: "durchfall" }, { name: "Verstopfung", id: "verstopfung" }, { name: "Sonstige", id: "sonstige" }, { name: "Keine", id: "keine" }],
    animalOutdoorAreas: [{ name: "Stadt, Industriegebiet", id: "stadt_industriegebiet" }, { name: "Ländlich, Wiesen, Äcker", id: "laendlich_wiesen_aecker" }, { name: "Wald, Wanderwege, Alm, Berge", id: "wald_wanderwege_alm_berge" }],
    animals: [{ name: "Hund - Sporthund", id: "hund_sporthund" }, { name: "Hund - Normaler Hund", id: "hund_normaler_hund" }, { name: "Katze - Hauskatze", id: "katze_hauskatze" }, { name: "Katze - Freigänger", id: "katze_freigaenger" }],
    animalFeedings: [{ name: "Trockenfutter", id: "trockenfutter" }, { name: "Nassfutter", id: "nassfutter" }, { name: "BARF", id: "barf" }, { name: "Kochen", id: "kochen" }, { name: "Sonstige", id: "sonstige" }],
    simpleBooleans: [{ name: "Ja", id: "ja" }, { name: "Nein", id: "nein" }],
    animalDeworming: [{ name: "Keine", id: "keine" }, { name: "Tabletten (chemisch)", id: "tabletten_chemisch" }, { name: "Tabletten (pflanzlich)", id: "tabletten_pflanzlich" }, { name: "Paste (chemisch)", id: "paste_chemisch" }, { name: "Paste (pflanzlich)", id: "paste_pflanzlich" }],
    animalAntiparasits: [{ name: "Keine", id: "keine" }, { name: "Tabletten (chemisch)", id: "tabletten_chemisch" }, { name: "Tabletten (pflanzlich)", id: "tabletten_pflanzlich" }, { name: "Tropfen am Fell", id: "tropfen_am_fell" }, { name: "Halsband", id: "halsband" }],
    animalDiseases: [{ name: "Niere", id: "niere" }, { name: "Herz", id: "herz" }, { name: "Lunge", id: "lunge" }, { name: "Gelenke", id: "gelenke" }, { name: "Inkontinenz", id: "inkontinenz" }, { name: "Bauchspeicheldrüße", id: "bauchspeicheldruesse" }, { name: "Borreliose", id: "borreliose" }, { name: "Leishmaniose", id: "leishmaniose" }, { name: "Sonstiges", id: "sonstiges" }],
    aniveriUsages: [{ name: "Als Vorsorge zur Erhebung der Nährstoffversorgung", id: "vorsorge" }, { name: "Als Vorsorge zur Erhebung der Nährstoffversorgung + individueller Futtermittelplan", id: "vorsorge_und_futtermittelplan" }],
    confirm: function confirm(treatmentPatientId, entryId) {
      var self = this;

      var form = $('#aniveriTreatmentForm');

      var id = this.get('form').id;

      if (!this.get('form').aniveriId || isNaN(this.get('form').aniveriId) || !this.get('form').aniveriId.length || this.get('form').aniveriId.length != 9) {
        jason.notifiction.error('Fehler', 'Bitte geben Sie eine gültige Haaranalysekit ID an');
        return false;
      }

      var data = {
        id: id,
        firstname: form.find('input[name=laboklin-owner-firstname]').val(),
        lastname: form.find('input[name=laboklin-owner-lastname]').val(),
        email: form.find('input[name=laboklin-owner-email]').val(),
        animalName: form.find('input[name=laboklin-animal-name]').val(),
        animalRace: form.find('input[name=laboklin-animal-race]').val(),
        animalGender: form.find('input[name=patientGender]:checked').val(),
        animalCategory: self.get('form').animalCategory,
        animalTypeMisc: form.find('input[name=patientTypeMisc]').val(),
        animalBirthdayPlain: this.get('form').animalBirthdayPlain,
        animalGenderPlain: this.get('form').animalGenderPlain,
        animalCastrated: this.get('form').animalCastrated,
        animalMixling: this.get('form').animalMixling,
        animalOrigin: this.get('form').animalOrigin,
        animalHeight: this.get('form').animalHeight,
        animalWeight: this.get('form').animalWeight,
        animalVaccinations: this.get('form').animalVaccinations,
        animalLastMeds: this.get('form').animalLastMeds,
        animalAllergies: this.get('form').animalAllergies,
        animalDiseases: this.get('form').animalDiseases,
        animalParasits: this.get('form').animalParasits,
        animalWorms: this.get('form').animalWorms,
        animalAntiParasits: this.get('form').animalAntiParasits,
        animalAntiParasitsUsage: this.get('form').animalAntiParasitsUsage,
        animalFeedingProcess: this.get('form').animalFeedingProcess,
        aniveriUsage: this.get('form').aniveriUsage,
        animalFeeding: this.get('form').animalFeeding,
        animalSweets: this.get('form').animalSweets,
        animalDrinking: this.get('form').animalDrinking,
        animalFeedingsPerDay: this.get('form').animalFeedingsPerDay,
        animalActiveness: this.get('form').animalActiveness,
        animalType: this.get('form').animalType,
        animalOutdoor: this.get('form').animalOutdoor,
        aniveriId: this.get('form').aniveriId,
        animalOutdoorArea: this.get('form').animalOutdoorArea,
        animalCurrentDisease: this.get('form').animalCurrentDisease,
        animalShape: this.get('form').animalShape
      };

      if (!data.animalCategory) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte wählen Sie eine Tierart aus');
        return false;
      }
      if (!data.animalName) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte geben Sie einen Tiernamen an');
        return false;
      }
      if (!data.firstname) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte geben Sie einen Tierbesitzer-Vornamen an');
        return false;
      }
      if (!data.lastname) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte geben Sie einen Tierbesitzer-Nachname an');
        return false;
      }
      if (!data.email) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte geben Sie eine Tierbesitzer-Email-Adresse an');
        return false;
      }
      if (!data.aniveriUsage) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte geben Sie an wie die Analyse verwendet wird');
        return false;
      }
      if (!data.animalHeight) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte geben Sie die Schulterhöhe an');
        return false;
      }
      if (!data.animalWeight) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte geben Sie das Gewicht an');
        return false;
      }
      if (!data.animalParasits) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte geben Sie an ob das Tier derzeit unter Parasiten- oder Wurmbefall leidet');
        return false;
      }
      if (!data.animalShape) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte geben Sie Körperform des Tieres an');
        return false;
      }
      if (!data.animalRace) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte geben Sie die Rasse des Tieres an');
        return false;
      }
      if (!data.animalFeedingsPerDay) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte geben Sie die Häufigkeit der Fütterung pro Tag an');
        return false;
      }
      if (!data.animalDrinking) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte geben Sie an wie viel das Tier am Tag trinkt');
        return false;
      }
      if (!data.animalActiveness) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte geben Sie an wie aktiv das Tier ist');
        return false;
      }
      if (!data.animalType) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte ordnen Sie das Tier zu');
        return false;
      }
      if (!data.animalOutdoorArea) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte ordnen Sie an wo sich das Tier am Häufigsten bewegt');
        return false;
      }
      if (!data.animalAntiParasitsUsage) {
        jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte geben Sie an ob Parasitenmittel für einen Wirkungszeitraum von mehr als 2 Wochen innerhalb eines Jahres verabreicht werden');
        return false;
      }

      if (this.get('treatment')) {
        var deferred = $.ajax({
          url: "/api/treatmentPatients/" + treatmentPatientId + "/laboklinForm",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (formId) {
          self.sendAction('confirm', treatmentPatientId, entryId, formId, id > 0);
        }, function (error) {});
      } else {
        var overrideProduct = form.attr('product-id');

        if (overrideProduct) {
          this.set('productId', overrideProduct);
        }

        var deferred = $.ajax({
          url: "/api/purchaseOrders/" + this.get('orderId') + "/laboklinForm",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (formId) {
          self.sendAction('confirm', self.get('productId'), self.get('orderId'), self.get('priceInfoId'), '', formId, 1);
        }, function (error) {});
      }
      return true;
    },
    actions: {
      load: function load(treatmentPatientId, entryId, data, patient, customer, patientCategories, orderId, product, priceInfoId) {
        this.set('treatmentPatientId', treatmentPatientId);
        this.set('entryId', entryId);
        this.set('patientCategories', patientCategories);
        this.set('showLoading', false);

        var self = this;

        if (data) {

          var needsFallback = false;

          var formData = data.purchaseOrderLaboklinForm;

          if (formData.probeTaking) {
            formData.probeTaking = moment(formData.probeTaking).format('DD. MM. YYYY');
          }

          this.set('form', formData);

          if (this.get('enforceReadOnline') || formData.readOnly) {
            formData.readOnly = true;
            $('#laboklinTreatmentForm input, #laboklinTreatmentForm textarea').each(function () {
              $(this).prop('disabled', true);
            });
          } else {
            $('#laboklinTreatmentForm input, #laboklinTreatmentForm textarea').each(function () {
              $(this).prop('disabled', false);
            });
          }
        } else {

          $('#laboklinTreatmentForm input, #laboklinTreatmentForm textarea').each(function () {
            $(this).prop('disabled', false);
          });

          var formData = {};
          if (patient) {
            formData.animalName = patient.name;
            formData.animalAge = self.getAge(patient.birthday);
            formData.animalRace = patient.race;

            if (patient.category) {
              formData.animalCategory = patient.category;
            }

            if (patient.gender) {

              var genderId = patient.gender.id;

              if (genderId === 'male') {
                if (patient.castrated) {
                  formData.animalGender = 'mk';
                } else {
                  formData.animalGender = 'm';
                }
              }
              if (genderId === 'female') {
                if (patient.castrated) {
                  formData.animalGender = 'wk';
                } else {
                  formData.animalGender = 'w';
                }
              }
            }
          }
          if (customer) {
            formData.firstname = customer.firstname;
            formData.lastname = customer.lastname;
            formData.street = customer.street;
            formData.town = customer.postalCode + ' ' + customer.town;
            formData.email = customer.email;
          }
          formData.invoiceTo = 'E';
          formData.reorder = false;

          try {
            if (product && product.get('labCategory')) {
              formData.orderType = product.get('labCategory');
            } else {
              needsFallback = true;
            }
          } catch (e) {
            try {
              if (product && product.labCategory) {
                formData.orderType = product.labCategory;
              } else {
                needsFallback = true;
              }
            } catch (e) {
              needsFallback = true;
            }
          }

          formData.probeTaking = moment().format('DD. MM. YYYY');
          formData.interpretation = "true";

          this.set('form', formData);
          this.set('orderId', orderId);
          if (product) {
            this.set('productId', product.id);
          }
          this.set('priceInfoId', priceInfoId);
        }

        $.ajax({
          url: "/api/laboklin/orderTypes",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('types', data.laboklinOrderType);

          if (needsFallback) {
            $.each(data.laboklinOrderType, function () {
              if ($(this)[0].name === 'Allgemein') {
                formData.orderType = $(this)[0].id;
              }
            });
          }
        });

        $('#laboklinTreatmentForm input[name=orderType]').each(function () {
          $(this).prop('disabled', true);
        });

        $('#orderTypeContainer').addClass('disabled');
      },
      downloadBarcode: function downloadBarcode(formId) {
        this.sendAction('downloadBarcode', formId);
      },
      downloadPdf: function downloadPdf(formId) {
        this.sendAction('downloadPdf', formId);
      },
      downloadMedia: function downloadMedia(formId, media) {
        this.sendAction('downloadMedia', formId, media);
      },
      submitAniveri: function submitAniveri(treatmentPatientId, formId) {
        var self = this;
        this.set('showLoading', true);
        if (this.confirm(treatmentPatientId, formId)) {
          setTimeout(function () {
            self.sendAction('submitAniveri', treatmentPatientId, formId);
          }, 1000);
        }
        this.set('showLoading', false);
      },
      unlink: function unlink(id, formId) {
        this.sendAction('removeEntry', id, formId);
        $('#' + id + '_entry').hide();
      },
      confirm: function confirm(treatmentPatientId, entryId) {
        this.confirm(treatmentPatientId, entryId);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    },
    getAge: function getAge(dob) {

      dob = new Date(dob);

      var now = new Date();
      var today = new Date(now.getYear(), now.getMonth(), now.getDate());

      var yearNow = now.getYear();
      var monthNow = now.getMonth();
      var dateNow = now.getDate();

      var yearDob = dob.getYear();
      var monthDob = dob.getMonth();
      var dateDob = dob.getDate();
      var age = {};
      var ageString = "";
      var yearString = "";
      var monthString = "";
      var dayString = "";

      var yearAge = yearNow - yearDob;

      if (monthNow >= monthDob) {
        var monthAge = monthNow - monthDob;
      } else {
        yearAge--;
        var monthAge = 12 + monthNow - monthDob;
      }

      if (dateNow >= dateDob) {
        var dateAge = dateNow - dateDob;
      } else {
        monthAge--;
        var dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }

      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
      };

      if (age.years > 1) {
        yearString = " Jahre";
      } else {
        yearString = " Jahr";
      }
      if (age.months > 1) {
        monthString = " Monate";
      } else {
        monthString = " Monat";
      }
      if (age.days > 1) {
        dayString = " Tage";
      } else {
        dayString = " Tag";
      }

      if (age.years > 0 && age.months > 0 && age.days > 0) {
        ageString = age.years + yearString + ", " + age.months + monthString + ",  " + age.days + dayString;
      } else if (age.years == 0 && age.months == 0 && age.days > 0) {
        ageString = age.days + dayString;
      } else if (age.years > 0 && age.months == 0 && age.days == 0) {
        ageString = age.years + yearString;
      } else if (age.years > 0 && age.months > 0 && age.days == 0) {
        ageString = age.years + yearString + " " + age.months + monthString;
      } else if (age.years == 0 && age.months > 0 && age.days > 0) {
        ageString = age.months + monthString + " " + age.days + dayString;
      } else if (age.years > 0 && age.months == 0 && age.days > 0) {
        ageString = age.years + yearString + " " + age.days + dayString;
      } else if (age.years == 0 && age.months > 0 && age.days == 0) {
        ageString = age.months + monthString;
      } else {
        ageString = "";
      }

      return ageString;
    }
  });

});