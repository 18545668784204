define('jason-frontend/components/edit-resource-day-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    saveAction: function saveAction() {
      this.sendAction('save');
      $.magnificPopup.close();
    },
    actions: {
      load: function load(row) {
        this.set('model', row);
      },
      'delete': function _delete(row) {
        Ember['default'].set(row, 'deleted', true);
      },
      add: function add(row) {
        this.get('model').pushObject({
          from: '9:00',
          to: '13:00'
        });
      },
      save: function save() {
        this.saveAction();
      },

      confirm: function confirm(id) {
        this.saveAction();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});