define('jason-frontend/models/purchase-product', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    description: DS['default'].attr('string'),
    rpaId: DS['default'].attr(''),
    pzn: DS['default'].attr(''),
    foreignId: DS['default'].attr('string'),
    atcCode: DS['default'].attr('string'),
    atcCodeDescription: DS['default'].attr('string'),
    effectivePrice: DS['default'].attr('number'),
    priceExVat: DS['default'].attr('number'),
    reduction: DS['default'].attr('number'),
    savingInPercent: DS['default'].attr('number'),
    priceInfoId: DS['default'].attr('number'),
    free: DS['default'].attr('boolean'),
    topPromotion: DS['default'].attr('boolean'),
    cashback: DS['default'].attr('number'),
    systemTags: DS['default'].attr(''),
    tags: DS['default'].attr(''),
    retailPrice: DS['default'].attr('number'),
    retailPriceDivided: DS['default'].attr('number'),
    jasonPrice: DS['default'].attr('number'),
    quantity: DS['default'].attr('number'),
    packageQuantity: DS['default'].attr('string'),
    picture: DS['default'].belongsTo('media'),
    pictureUrl: DS['default'].attr('string'),
    category: DS['default'].belongsTo('product-category'),
    supplier: DS['default'].belongsTo('product-supplier'),
    supplierFlat: DS['default'].attr('string'),
    detailUrl: DS['default'].attr('string'),
    activeRowSize: DS['default'].attr('number'),
    labCategory: DS['default'].attr('string'),
    manufacturer: DS['default'].belongsTo('product-manufacturer'),
    purchaseSupplierInfos: DS['default'].hasMany('purchase-product-supplier-info'),
    purchaseSupplierEntries: DS['default'].attr(''),
    reorderPurchaseSupplierInfos: DS['default'].attr(''),
    tax: DS['default'].belongsTo('tax'),
    unit: DS['default'].belongsTo('serviceunit'),
    serviceunit: DS['default'].belongsTo('serviceunit'),
    altServiceunit: DS['default'].attr(''),
    ingredients: DS['default'].attr('string'),
    slopingInfo: DS['default'].attr('string'),
    domainInfo: DS['default'].attr('string'),
    licenceNumber: DS['default'].attr('string'),
    licenceNumberEu: DS['default'].attr(''),
    licenceNumberAges: DS['default'].attr(''),
    licenceNumberApo: DS['default'].attr(''),
    gtin: DS['default'].attr('string'),
    targetRace: DS['default'].attr('string'),
    bestPrice: DS['default'].attr('boolean'),
    elordCredentialsRequired: DS['default'].attr('boolean'),
    iekAvailable: DS['default'].attr('boolean'),
    salePriceGross: DS['default'].attr('number'),
    jasonPriceDivided: DS['default'].attr('number'),
    sellingAmount: DS['default'].attr('number'),
    narcotic: DS['default'].attr('boolean'),
    promotionActive: DS['default'].attr('boolean'),
    promotionFrom: DS['default'].attr('string'),
    promotionCreated: DS['default'].attr('date'),
    promotionTo: DS['default'].attr('string'),
    promotionInfo: DS['default'].attr('string'),
    promotionLabel: DS['default'].attr('string'),
    active: DS['default'].attr('boolean'),
    deliverableSoon: DS['default'].attr('boolean'),
    partnerForeignId: DS['default'].attr('string'),
    partnerId: DS['default'].attr('string'),
    partnerNumber: DS['default'].attr('string'),
    supplierForeignId: DS['default'].attr('string'),
    imageSource: DS['default'].attr('string'),
    infodocSource: DS['default'].attr('string'),
    favorite: DS['default'].attr(),
    partnerCategory: DS['default'].attr('string'),
    partnerSubcategory: DS['default'].attr('string'),
    historyNotice: DS['default'].attr('string'),
    hasPromotion: DS['default'].attr(),
    stockLevels: DS['default'].attr(),
    infoMinimalAmount: DS['default'].attr(''),
    infoMinimalServiceunit: DS['default'].attr(''),
    reorderMinimalAmount: DS['default'].attr(''),
    reorderMinimalServiceunit: DS['default'].attr(''),
    reorderSupplier: DS['default'].attr(''),
    reorderSellingAmount: DS['default'].attr(''),
    reorderAmount: DS['default'].attr(''),
    orderNotice: DS['default'].attr(''),
    autosendReorder: DS['default'].attr(''),
    reorderPurchaseSupplierInfo: DS['default'].attr(),
    reorderSettings: DS['default'].attr()
  });

});