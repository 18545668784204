define('jason-frontend/routes/reminders/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.applyFilter();
    },
    actions: {
      clearLastTreatmentDate: function clearLastTreatmentDate() {
        this.get('controller').set('pickDateLastTreatment', null);
      },
      gotoTreatments: function gotoTreatments(id) {
        this.transitionTo('patients.edit', id, { queryParams: { selectTab: 'history' } });
      },
      gotoAppointment: function gotoAppointment(id) {
        this.transitionTo('patients.edit', id, { queryParams: { selectTab: 'appointments' } });
      },
      gotoReminders: function gotoReminders(id) {
        this.transitionTo('patients.edit', id, { queryParams: { selectTab: 'future' } });
      },
      gotoLabs: function gotoLabs(id) {
        this.transitionTo('patients.edit', id, { queryParams: { selectTab: 'lab' } });
      },
      selectTab: function selectTab(tab) {
        this.get('controller').set('selectedTab', tab);
        this.applyFilter();
      },
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-'),
          type = controller.get('selectedTab'),
          pickDateLastTreatment = controller.get('pickDateLastTreatment'),
          self = this;

      controller.set('showLoading', true);

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      if (type === 'past') {
        var dateRangePast = controller.get('pickDatePast').split('-');

        from = moment(dateRangePast[0], 'DD. MM. YYYY').format('YYYY-MM-DD');
        to = moment(dateRangePast[1], 'DD. MM. YYYY').format('YYYY-MM-DD');
      }

      controller.set('to', to);
      controller.set('from', from);

      this.findPaged('reminder', {
        filterFrom: from,
        filterTo: to,
        pickDateLastTreatment: pickDateLastTreatment,
        type: type,
        per_page: 10
      }).then(function (data) {
        var meta = data.meta;
        controller.set('showLoading', false);
        controller.set('items', data);
      });
    }
  });

});