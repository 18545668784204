define('jason-frontend/templates/components/select-inventory-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 6
              },
              "end": {
                "line": 50,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("form");
            dom.setAttribute(el1,"id","checkNumberForm");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","box");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-12/24 gutter pr40");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","sum-table u-mb");
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","sum-table__col col l-16/24");
            var el6 = dom.createTextNode("\n                    ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","");
            var el7 = dom.createTextNode("Auszubuchende Menge");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","u-text--right sum-table__col col l-8/24");
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(" ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","sum-table__col col l-16/24");
            var el6 = dom.createTextNode("\n                    ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","");
            var el7 = dom.createTextNode("- Bereits ausgebucht");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","u-text--right sum-table__col col l-8/24");
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","");
            var el7 = dom.createTextNode("- ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(" ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","sum-table__col col l-16/24 border-top");
            var el6 = dom.createTextNode("\n                    ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","");
            var el7 = dom.createElement("b");
            var el8 = dom.createTextNode("Noch auszubuchen");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","u-text--right sum-table__col col l-8/24 border-top");
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","");
            var el7 = dom.createElement("b");
            var el8 = dom.createComment("");
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode(" ");
            dom.appendChild(el7, el8);
            var el8 = dom.createComment("");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-12/24 gutter pt20");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-18/24");
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("label");
            dom.setAttribute(el5,"for","stockIdInput");
            dom.setAttribute(el5,"class","field-label title required");
            var el6 = dom.createTextNode("QR-Code scannen");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-6/24 u-text--right mt20");
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("button");
            dom.setAttribute(el5,"type","submit");
            dom.setAttribute(el5,"class","icon-button icon-button--success");
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element20 = dom.childAt(fragment, [1]);
            var element21 = dom.childAt(element20, [1]);
            var element22 = dom.childAt(element21, [1, 1]);
            var element23 = dom.childAt(element22, [3, 1]);
            var element24 = dom.childAt(element22, [7, 1]);
            var element25 = dom.childAt(element22, [11, 1, 0]);
            var element26 = dom.childAt(element21, [3]);
            var morphs = new Array(9);
            morphs[0] = dom.createElementMorph(element20);
            morphs[1] = dom.createMorphAt(element23,0,0);
            morphs[2] = dom.createMorphAt(element23,2,2);
            morphs[3] = dom.createMorphAt(element24,1,1);
            morphs[4] = dom.createMorphAt(element24,3,3);
            morphs[5] = dom.createMorphAt(element25,0,0);
            morphs[6] = dom.createMorphAt(element25,2,2);
            morphs[7] = dom.createMorphAt(dom.childAt(element26, [1]),3,3);
            morphs[8] = dom.createMorphAt(dom.childAt(element26, [3, 1]),1,1);
            return morphs;
          },
          statements: [
            ["element","action",["confirm",["get","id",["loc",[null,[12,33],[12,35]]]]],["on","submit"],["loc",[null,[12,14],[12,49]]]],
            ["inline","format-number-german",[["get","necessary",["loc",[null,[20,56],[20,65]]]]],[],["loc",[null,[20,33],[20,67]]]],
            ["content","baseUnit",["loc",[null,[20,68],[20,80]]]],
            ["inline","format-number-german",[["get","alreadyBooked",["loc",[null,[26,58],[26,71]]]]],[],["loc",[null,[26,35],[26,73]]]],
            ["content","baseUnit",["loc",[null,[26,74],[26,86]]]],
            ["inline","format-number-german",[["subexpr","minus",[["get","necessary",["loc",[null,[32,66],[32,75]]]],["get","alreadyBooked",["loc",[null,[32,76],[32,89]]]]],[],["loc",[null,[32,59],[32,90]]]]],[],["loc",[null,[32,36],[32,92]]]],
            ["content","baseUnit",["loc",[null,[32,93],[32,105]]]],
            ["inline","input",[],["value",["subexpr","@mut",[["get","stockIdInput",["loc",[null,[39,30],[39,42]]]]],[],[]],"autofocus",true,"class","gui-input","id","stockIdInput","name","stockIdInput"],["loc",[null,[39,16],[39,115]]]],
            ["inline","button-next",[],["size","38","color","#ffffff","content","Erfassen"],["loc",[null,[43,18],[43,78]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 70,
                    "column": 142
                  },
                  "end": {
                    "line": 70,
                    "column": 480
                  }
                },
                "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small green");
                var el2 = dom.createTextNode("insgesamt ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small");
                var el2 = dom.createTextNode("1 ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" = ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element15 = dom.childAt(fragment, [1]);
                var element16 = dom.childAt(fragment, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(element15,1,1);
                morphs[1] = dom.createMorphAt(element15,3,3);
                morphs[2] = dom.createMorphAt(element16,1,1);
                morphs[3] = dom.createMorphAt(element16,3,3);
                morphs[4] = dom.createMorphAt(element16,5,5);
                return morphs;
              },
              statements: [
                ["inline","format-number-german",[["subexpr","multiply",[["get","item.conversionFactor",["loc",[null,[70,299],[70,320]]]],["subexpr","positive",[["get","item.amount",["loc",[null,[70,331],[70,342]]]]],[],["loc",[null,[70,321],[70,343]]]]],[],["loc",[null,[70,289],[70,344]]]]],[],["loc",[null,[70,266],[70,346]]]],
                ["content","baseUnit",["loc",[null,[70,347],[70,359]]]],
                ["content","item.unit.name",["loc",[null,[70,393],[70,411]]]],
                ["inline","format-number-german",[["get","item.conversionFactor",["loc",[null,[70,437],[70,458]]]]],[],["loc",[null,[70,414],[70,460]]]],
                ["content","baseUnit",["loc",[null,[70,461],[70,473]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 71,
                    "column": 85
                  },
                  "end": {
                    "line": 71,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" / ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","item.type",["loc",[null,[71,102],[71,115]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 71,
                    "column": 125
                  },
                  "end": {
                    "line": 71,
                    "column": 161
                  }
                },
                "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.subType",["loc",[null,[71,145],[71,161]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 77,
                    "column": 18
                  },
                  "end": {
                    "line": 81,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"item",["subexpr","@mut",[["get","item",["loc",[null,[79,84],[79,88]]]]],[],[]],"content","Warenausgabe stornieren","confirmed","removeMovement","text","Wollen Sie diesen Warenausgang wirklich stornieren?"],["loc",[null,[79,20],[79,210]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 66,
                  "column": 12
                },
                "end": {
                  "line": 84,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-2/24");
              dom.setAttribute(el2,"data-label","Inventar-Nr");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-1/24");
              dom.setAttribute(el2,"data-label","");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--center l-5/24");
              dom.setAttribute(el2,"data-label","Menge");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--center l-5/24");
              dom.setAttribute(el2,"data-label","typ");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-5/24");
              dom.setAttribute(el2,"data-label","Ausgebucht");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-5/24");
              dom.setAttribute(el2,"data-label","Lager");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-1/24 table__cell--action");
              dom.setAttribute(el2,"data-label","");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element17 = dom.childAt(fragment, [1]);
              var element18 = dom.childAt(element17, [5]);
              var element19 = dom.childAt(element17, [7]);
              var morphs = new Array(10);
              morphs[0] = dom.createMorphAt(dom.childAt(element17, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element17, [3]),0,0);
              morphs[2] = dom.createMorphAt(element18,0,0);
              morphs[3] = dom.createMorphAt(element18,2,2);
              morphs[4] = dom.createMorphAt(element18,3,3);
              morphs[5] = dom.createMorphAt(element19,0,0);
              morphs[6] = dom.createMorphAt(element19,1,1);
              morphs[7] = dom.createMorphAt(dom.childAt(element17, [9]),1,1);
              morphs[8] = dom.createMorphAt(dom.childAt(element17, [11]),0,0);
              morphs[9] = dom.createMorphAt(dom.childAt(element17, [13]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.stockId",["loc",[null,[68,73],[68,89]]]],
              ["inline","show-movement-type",[],["value",["subexpr","@mut",[["get","item.amount",["loc",[null,[69,89],[69,100]]]]],[],[]],"classNames","movement--type","size",26],["loc",[null,[69,62],[69,138]]]],
              ["inline","format-number-german",[["get","item.amount",["loc",[null,[70,110],[70,121]]]]],[],["loc",[null,[70,87],[70,123]]]],
              ["content","item.unit.name",["loc",[null,[70,124],[70,142]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","baseUnit",["loc",[null,[70,162],[70,170]]]],["get","item.unit.name",["loc",[null,[70,171],[70,185]]]]],[],["loc",[null,[70,158],[70,186]]]]],[],["loc",[null,[70,153],[70,187]]]],["subexpr","not",[["subexpr","eq",[["get","item.conversionFactor",["loc",[null,[70,197],[70,218]]]],1],[],["loc",[null,[70,193],[70,221]]]]],[],["loc",[null,[70,188],[70,222]]]]],[],["loc",[null,[70,148],[70,223]]]]],[],0,null,["loc",[null,[70,142],[70,487]]]],
              ["block","if",[["get","item.type",["loc",[null,[71,91],[71,100]]]]],[],1,null,["loc",[null,[71,85],[71,125]]]],
              ["block","if",[["get","item.subType",["loc",[null,[71,131],[71,143]]]]],[],2,null,["loc",[null,[71,125],[71,168]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[73,32],[73,44]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[73,18],[73,67]]]],
              ["content","item.warehouse",["loc",[null,[75,67],[75,85]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["get","item.wasCancelled",["loc",[null,[77,34],[77,51]]]]],[],["loc",[null,[77,29],[77,52]]]],["subexpr","not",[["subexpr","eq",[["get","item.subType",["loc",[null,[77,62],[77,74]]]],"Storno"],[],["loc",[null,[77,58],[77,84]]]]],[],["loc",[null,[77,53],[77,85]]]]],[],["loc",[null,[77,24],[77,86]]]]],[],3,null,["loc",[null,[77,18],[81,25]]]]
            ],
            locals: ["item"],
            templates: [child0, child1, child2, child3]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 6
              },
              "end": {
                "line": 87,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","like-h2 box pt5 pb5");
            var el2 = dom.createTextNode("Verbuchte Ausgaben");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table table--large box no-box@tablet no-box@phone");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__head");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-2/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Nr");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-1/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell table__cell--center l-5/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Menge");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell table__cell--center l-5/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Typ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-5/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Datum");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-5/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Lager");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-1/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__body");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","connectedMovements",["loc",[null,[66,20],[66,38]]]]],[],0,null,["loc",[null,[66,12],[84,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 107,
                      "column": 130
                    },
                    "end": {
                      "line": 107,
                      "column": 246
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" = ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.unit.name",["loc",[null,[107,166],[107,184]]]],
                  ["inline","format-number-german",[["get","item.conversionFactor",["loc",[null,[107,210],[107,231]]]]],[],["loc",[null,[107,187],[107,233]]]],
                  ["content","baseUnit",["loc",[null,[107,234],[107,246]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 107,
                      "column": 246
                    },
                    "end": {
                      "line": 107,
                      "column": 414
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" = ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("1 ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" = ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(6);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
                  morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
                  morphs[4] = dom.createMorphAt(fragment,9,9,contextualElement);
                  morphs[5] = dom.createMorphAt(fragment,11,11,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","baseUnit",["loc",[null,[107,254],[107,266]]]],
                  ["inline","multiply",[["get","item.conversionFactor",["loc",[null,[107,280],[107,301]]]],-1],[],["loc",[null,[107,269],[107,306]]]],
                  ["content","item.unit.name",["loc",[null,[107,307],[107,325]]]],
                  ["content","item.unit.name",["loc",[null,[107,332],[107,350]]]],
                  ["inline","divide",[1,["subexpr","multiply",[["get","item.conversionFactor",["loc",[null,[107,374],[107,395]]]],-1],[],["loc",[null,[107,364],[107,399]]]]],[],["loc",[null,[107,353],[107,401]]]],
                  ["content","baseUnit",["loc",[null,[107,402],[107,414]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 107,
                    "column": 20
                  },
                  "end": {
                    "line": 107,
                    "column": 428
                  }
                },
                "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small");
                var el2 = dom.createTextNode("1 ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","gt",[["get","item.conversionFactor",["loc",[null,[107,140],[107,161]]]],0],[],["loc",[null,[107,136],[107,164]]]]],[],0,1,["loc",[null,[107,130],[107,421]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 103,
                  "column": 14
                },
                "end": {
                  "line": 126,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-2/24");
              dom.setAttribute(el2,"data-label","Nr");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-5/24");
              dom.setAttribute(el2,"data-label","Verfügbar");
              var el3 = dom.createElement("b");
              var el4 = dom.createElement("span");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("/");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-3/24");
              dom.setAttribute(el2,"data-label","Lager");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-5/24 break-word text-center");
              dom.setAttribute(el2,"data-label","Chargen-Nr");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-4/24");
              dom.setAttribute(el2,"data-label","Ablaufdatum");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-4/24");
              dom.setAttribute(el2,"data-label","Lagerplatz");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--action l-1/24");
              dom.setAttribute(el2,"data-label","");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","icon-button list-action-square");
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("img");
              dom.setAttribute(el4,"src","assets/images/icons/icon-next.svg");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [1]);
              var element12 = dom.childAt(element11, [3]);
              var element13 = dom.childAt(element12, [0, 0]);
              var element14 = dom.childAt(element11, [13, 1]);
              var morphs = new Array(11);
              morphs[0] = dom.createMorphAt(dom.childAt(element11, [1]),0,0);
              morphs[1] = dom.createAttrMorph(element13, 'class');
              morphs[2] = dom.createMorphAt(element13,0,0);
              morphs[3] = dom.createMorphAt(element13,2,2);
              morphs[4] = dom.createMorphAt(element12,2,2);
              morphs[5] = dom.createMorphAt(element12,4,4);
              morphs[6] = dom.createMorphAt(dom.childAt(element11, [5]),1,1);
              morphs[7] = dom.createMorphAt(dom.childAt(element11, [7]),1,1);
              morphs[8] = dom.createMorphAt(dom.childAt(element11, [9]),1,1);
              morphs[9] = dom.createMorphAt(dom.childAt(element11, [11]),1,1);
              morphs[10] = dom.createElementMorph(element14);
              return morphs;
            },
            statements: [
              ["content","item.id",["loc",[null,[105,66],[105,77]]]],
              ["attribute","class",["concat",[["subexpr","get-stock-color",[["get","item.flatStock",["loc",[null,[106,107],[106,121]]]],["get","item.packageQuantity",["loc",[null,[106,122],[106,142]]]]],[],["loc",[null,[106,89],[106,144]]]]]]],
              ["inline","format-number-german",[["get","item.flatStock",["loc",[null,[106,169],[106,183]]]]],[],["loc",[null,[106,146],[106,185]]]],
              ["inline","format-number-german",[["get","item.packageQuantity",["loc",[null,[106,209],[106,229]]]]],[],["loc",[null,[106,186],[106,231]]]],
              ["content","item.unit.name",["loc",[null,[106,243],[106,261]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","baseUnit",["loc",[null,[107,40],[107,48]]]],["get","item.unit.name",["loc",[null,[107,49],[107,63]]]]],[],["loc",[null,[107,36],[107,64]]]]],[],["loc",[null,[107,31],[107,65]]]],["subexpr","not",[["subexpr","eq",[["get","item.conversionFactor",["loc",[null,[107,75],[107,96]]]],1],[],["loc",[null,[107,71],[107,99]]]]],[],["loc",[null,[107,66],[107,100]]]]],[],["loc",[null,[107,26],[107,101]]]]],[],0,null,["loc",[null,[107,20],[107,435]]]],
              ["content","item.warehouseName",["loc",[null,[109,20],[109,42]]]],
              ["content","item.batchNumber",["loc",[null,[112,20],[112,40]]]],
              ["inline","time-format",[["get","item.expiryDate",["loc",[null,[115,34],[115,49]]]],"DD. MM. YYYY"],[],["loc",[null,[115,20],[115,66]]]],
              ["content","item.locationInfo",["loc",[null,[118,20],[118,41]]]],
              ["element","action",["copy",["get","item.id",["loc",[null,[121,78],[121,85]]]]],[],["loc",[null,[121,62],[121,87]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 89,
                "column": 8
              },
              "end": {
                "line": 129,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","like-h2 box pt5 pb5");
            var el2 = dom.createTextNode("Verfügbare Bestände");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table table--large box no-box@tablet no-box@phone");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__head");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-2/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Nr");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-5/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Verfügbar");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-3/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Lager");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-5/24 text-center");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Chargen-Nr");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-4/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Ablaufdatum");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-4/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Lagerplatz");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__body");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","items",["loc",[null,[103,22],[103,27]]]]],[],0,null,["loc",[null,[103,14],[126,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 6
            },
            "end": {
              "line": 130,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","not",[["get","fullyBooked",["loc",[null,[10,17],[10,28]]]]],[],["loc",[null,[10,12],[10,29]]]]],[],0,null,["loc",[null,[10,6],[50,13]]]],
          ["block","if",[["subexpr","gt",[["subexpr","get-size",[["get","connectedMovements",["loc",[null,[52,26],[52,44]]]]],[],["loc",[null,[52,16],[52,45]]]],0],[],["loc",[null,[52,12],[52,48]]]]],[],1,null,["loc",[null,[52,6],[87,13]]]],
          ["block","if",[["subexpr","and",[["subexpr","gt",[["subexpr","get-size",[["get","items",["loc",[null,[89,33],[89,38]]]]],[],["loc",[null,[89,23],[89,39]]]],0],[],["loc",[null,[89,19],[89,42]]]],["subexpr","not",[["get","fullyBooked",["loc",[null,[89,48],[89,59]]]]],[],["loc",[null,[89,43],[89,60]]]]],[],["loc",[null,[89,14],[89,61]]]]],[],2,null,["loc",[null,[89,8],[129,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 189,
                  "column": 20
                },
                "end": {
                  "line": 191,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      1 ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" = ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              return morphs;
            },
            statements: [
              ["content","selectedStock.unit.name",["loc",[null,[190,24],[190,51]]]],
              ["inline","format-number-german",[["get","selectedStock.conversionFactor",["loc",[null,[190,77],[190,107]]]]],[],["loc",[null,[190,54],[190,109]]]],
              ["content","baseUnit",["loc",[null,[190,110],[190,122]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 191,
                  "column": 20
                },
                "end": {
                  "line": 195,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      1 ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" = ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("1  =  <br/>");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","green");
              var el2 = dom.createTextNode("insgesamt ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [10]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              morphs[3] = dom.createMorphAt(element0,1,1);
              morphs[4] = dom.createMorphAt(element0,3,3);
              return morphs;
            },
            statements: [
              ["content","baseUnit",["loc",[null,[192,24],[192,36]]]],
              ["inline","multiply",[-1,["get","selectedStock.conversionFactor",["loc",[null,[192,53],[192,83]]]]],[],["loc",[null,[192,39],[192,85]]]],
              ["content","selectedStock.unit.name",["loc",[null,[192,86],[192,113]]]],
              ["inline","multiply",[["subexpr","divide",[1,["subexpr","multiply",[["get","selectedStock.conversionFactor",["loc",[null,[194,83],[194,113]]]],-1],[],["loc",[null,[194,73],[194,117]]]]],[],["loc",[null,[194,63],[194,118]]]],["get","outgoing",["loc",[null,[194,119],[194,127]]]]],[],["loc",[null,[194,52],[194,129]]]],
              ["content","baseUnit",["loc",[null,[194,130],[194,142]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 186,
                "column": 16
              },
              "end": {
                "line": 197,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","small");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","gt",[["get","selectedStock.conversionFactor",["loc",[null,[189,30],[189,60]]]],0],[],["loc",[null,[189,26],[189,63]]]]],[],0,1,["loc",[null,[189,20],[195,27]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 131,
              "column": 6
            },
            "end": {
              "line": 213,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("form");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","box mb0");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","box");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-12/24");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","field");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","new-not-title");
          dom.setAttribute(el6,"class","field-label title");
          var el7 = dom.createTextNode("Inventar-Nr");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-12/24");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","field");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","new-not-title");
          dom.setAttribute(el6,"class","field-label title");
          var el7 = dom.createTextNode("Produktname");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-12/24");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","field");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","new-not-title");
          dom.setAttribute(el6,"class","field-label title");
          var el7 = dom.createTextNode("Lager");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n               ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-12/24");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","field");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","new-not-title");
          dom.setAttribute(el6,"class","field-label title");
          var el7 = dom.createTextNode("Lagerplatz");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n               ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-12/24");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","field");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","new-not-title");
          dom.setAttribute(el6,"class","field-label title");
          var el7 = dom.createTextNode("VPE");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-12/24");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","field");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","new-not-title");
          dom.setAttribute(el6,"class","field-label title");
          var el7 = dom.createTextNode("Verfügbar");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-12/24");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","field mb0");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","new-not-title");
          dom.setAttribute(el6,"class","field-label title");
          var el7 = dom.createTextNode("Offene Menge");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-12/24");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","field mb0");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","outgoing");
          dom.setAttribute(el6,"class","field-label title");
          var el7 = dom.createTextNode("Auszubuchende Menge");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","col l-8/24");
          var el7 = dom.createTextNode("\n                ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","col l-16/24 pt10 pl10");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("              ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","bar");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--small");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--success");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1, 1]);
          var element3 = dom.childAt(element2, [9, 1]);
          var element4 = dom.childAt(element2, [11, 1]);
          var element5 = dom.childAt(element2, [13, 1]);
          var element6 = dom.childAt(element2, [15, 1]);
          var element7 = dom.childAt(element6, [5]);
          var element8 = dom.childAt(element1, [3]);
          var element9 = dom.childAt(element8, [1]);
          var element10 = dom.childAt(element8, [3]);
          var morphs = new Array(18);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [1, 1]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [3, 1]),3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [5, 1]),3,3);
          morphs[4] = dom.createMorphAt(dom.childAt(element2, [7, 1]),3,3);
          morphs[5] = dom.createMorphAt(element3,3,3);
          morphs[6] = dom.createMorphAt(element3,5,5);
          morphs[7] = dom.createMorphAt(element4,3,3);
          morphs[8] = dom.createMorphAt(element4,5,5);
          morphs[9] = dom.createMorphAt(element5,3,3);
          morphs[10] = dom.createMorphAt(element5,5,5);
          morphs[11] = dom.createMorphAt(dom.childAt(element6, [3]),1,1);
          morphs[12] = dom.createMorphAt(element7,1,1);
          morphs[13] = dom.createMorphAt(element7,3,3);
          morphs[14] = dom.createElementMorph(element9);
          morphs[15] = dom.createMorphAt(element9,1,1);
          morphs[16] = dom.createElementMorph(element10);
          morphs[17] = dom.createMorphAt(element10,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["confirmOut"],["on","submit"],["loc",[null,[133,12],[133,47]]]],
          ["content","selectedStock.id",["loc",[null,[139,14],[139,34]]]],
          ["content","selectedStock.product.name",["loc",[null,[145,14],[145,44]]]],
          ["content","selectedStock.warehouseName",["loc",[null,[151,15],[151,46]]]],
          ["content","selectedStock.locationInfo",["loc",[null,[157,15],[157,45]]]],
          ["inline","format-number-german",[["get","selectedStock.packageQuantity",["loc",[null,[163,37],[163,66]]]]],[],["loc",[null,[163,14],[163,68]]]],
          ["content","selectedStock.unit.name",["loc",[null,[163,69],[163,96]]]],
          ["inline","format-number-german",[["get","selectedStock.flatStock",["loc",[null,[169,37],[169,60]]]]],[],["loc",[null,[169,14],[169,62]]]],
          ["content","selectedStock.unit.name",["loc",[null,[169,63],[169,90]]]],
          ["inline","format-number-german",[["subexpr","minus",[["get","necessary",["loc",[null,[175,44],[175,53]]]],["get","alreadyBooked",["loc",[null,[175,54],[175,67]]]]],[],["loc",[null,[175,37],[175,68]]]]],[],["loc",[null,[175,14],[175,70]]]],
          ["content","baseUnit",["loc",[null,[175,71],[175,83]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","outgoing",["loc",[null,[182,30],[182,38]]]]],[],[]],"autofocus",true,"class","gui-input","id","outgoing","name","outgoing"],["loc",[null,[182,16],[182,103]]]],
          ["content","selectedStock.unit.name",["loc",[null,[185,14],[185,41]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","baseUnit",["loc",[null,[186,36],[186,44]]]],["get","selectedStock.unit.name",["loc",[null,[186,45],[186,68]]]]],[],["loc",[null,[186,32],[186,69]]]]],[],["loc",[null,[186,27],[186,70]]]],["subexpr","not",[["subexpr","eq",[["get","selectedStock.conversionFactor",["loc",[null,[186,80],[186,110]]]],1],[],["loc",[null,[186,76],[186,113]]]]],[],["loc",[null,[186,71],[186,114]]]]],[],["loc",[null,[186,22],[186,115]]]]],[],0,null,["loc",[null,[186,16],[197,23]]]],
          ["element","action",["showLoad"],[],["loc",[null,[205,52],[205,73]]]],
          ["content","button-prev",["loc",[null,[206,12],[206,27]]]],
          ["element","action",["confirmOut"],[],["loc",[null,[208,13],[208,36]]]],
          ["inline","button-next",[],["color","#ffffff","size","52","content","Gewählte Menge ausbuchen"],["loc",[null,[209,12],[209,88]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 226,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-select-inventory-panel");
        dom.setAttribute(el1,"class","popup-basic popup-very-large admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n              ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n      $(document).on(\"keyup\", \"#stockIdInput\", function() {\n          var value = $(this).val();\n          if(value.endsWith(\"QR\")) {\n              $('#checkNumberForm').submit();\n          }\n      });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element27 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element27, [1, 1]),1,1);
        morphs[1] = dom.createMorphAt(element27,3,3);
        morphs[2] = dom.createMorphAt(element27,4,4);
        return morphs;
      },
      statements: [
        ["inline","truncate",[["get","productName",["loc",[null,[5,27],[5,38]]]],100],[],["loc",[null,[5,16],[5,44]]]],
        ["block","if",[["get","showInput",["loc",[null,[8,12],[8,21]]]]],[],0,null,["loc",[null,[8,6],[130,13]]]],
        ["block","if",[["get","showDetails",["loc",[null,[131,12],[131,23]]]]],[],1,null,["loc",[null,[131,6],[213,13]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});