define('jason-frontend/routes/invoices/preview', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model, params) {
      var invoiceId = params.params['invoices.preview'].id;
      var hostAppendix = '';
      if (location.hostname === "localhost") {
        hostAppendix = 'http://localhost:4200';
      }

      controller.set('invoiceId', invoiceId);

      var deferred = $.ajax({
        url: "/api/invoices/" + invoiceId + "/preview?operatingCountry=" + API['default'].getOperatingCountry(),
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {}, function (data) {
        controller.set('viewUrl', hostAppendix + '/api/invoices/show/' + data.responseText);
        setTimeout(function () {
          controller.get('pdfPreview').send('showUrl', controller.get('viewUrl'));
        }, 3000);
      });

      this.store.find('textModule', { filterType: 'invoice' }).then(function (data) {
        controller.set('textModules', data);
        if (data && data.length > 0) {
          controller.set('textModule', data.content[0].id);
        }
      });

      $.ajax({
        url: "/api/editor/invoice/printTemplates",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('printTemplates', data.templateStyle);
        if (data.templateStyle.length > 0) {
          controller.set('selectedPrintTemplate', data.templateStyle[0]);
          controller.set('hasSidebar', true);
        }
      }, function () {});
    },
    actions: {
      reload: function reload() {
        var controller = this.get('controller');
        var style = '';
        if (controller.get('selectedPrintTemplate')) {
          style = controller.get('selectedPrintTemplate').id;
        }
        var deferred = $.ajax({
          url: "/api/invoices/" + this.get('controller').get('invoiceId') + "/preview?operatingCountry=" + API['default'].getOperatingCountry() + '&style=' + style,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          controller.set('viewUrl', '/api/invoices/show/' + data.responseText);
          setTimeout(function () {
            controller.get('pdfPreview').send('showUrl', controller.get('viewUrl'));
          }, 3000);
        });
      },
      toggleSidebar: function toggleSidebar() {
        var controller = this.get('controller');
        if (controller.get('sidebarActive') === true) {
          controller.set('sidebarActive', false);
        } else {
          controller.set('sidebarActive', true);
          setTimeout(function () {
            $('aside.side-bar div.scrollable').css('max-height', $(window).height() - 375);
          }, 100);
        }
      },
      download: function download(url) {
        url = url.replace("4200", "8080");
        window.open(url);
      },
      show: function show() {
        var self = this;
        setTimeout(function () {
          self.get('controller').get('pdfPreview').send('show');
        }, 1000);
      }
    }
  });

});