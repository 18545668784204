define('jason-frontend/components/edit-patient-memo-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    save: function save() {
      var data = undefined;
      if (this.get('model') && this.get('model').id) {
        try {
          data = {
            id: this.get('model').id,
            notice: this.get('model').get('notice'),
            createdFormatted: this.get('model').get('createdFormatted'),
            time: this.get('model').get('time')
          };
        } catch (e) {
          data = {
            id: this.get('model').id,
            notice: this.get('model').notice,
            createdFormatted: this.get('model').createdFormatted,
            time: this.get('model').time
          };
        }
      } else {
        data = {
          notice: this.get('model').notice,
          createdFormatted: this.get('model').createdFormatted,
          time: this.get('model').time
        };
      }

      var self = this;

      $.ajax({
        url: "/api/patientMemos/" + this.get('patientId'),
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (id) {
        self.sendAction('reload');
      }, function (error) {
        self.sendAction('reload');
      });
      $.magnificPopup.close();
    },
    actions: {
      'delete': function _delete() {
        var self = this;
        $.ajax({
          url: "/api/patientMemos/" + this.get('model').id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          self.sendAction('reload');
        }, function (error) {
          self.sendAction('reload');
        });
        $.magnificPopup.close();
      },
      save: function save() {
        this.save();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(entry, patientId) {
        this.set('model', entry);
        this.set('patientId', patientId);
      },
      loadNew: function loadNew(patientId) {
        this.set('model', {
          createdFormatted: moment().format('DD. MM. YYYY'),
          time: moment().format('HH:mm')
        });
        this.set('patientId', patientId);
      }
    }
  });

});