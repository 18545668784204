define('jason-frontend/helpers/get-value-or-empty-sign', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getValueOrEmptySign = getValueOrEmptySign;

  function getValueOrEmptySign(params) {
    var value = params[0],
        sign = params[1];

    if (value) {
      return value;
    }
    if (sign) {
      return sign;
    }
    return "-";
  }

  exports['default'] = Ember['default'].Helper.helper(getValueOrEmptySign);

});