define('jason-frontend/helpers/is-laboklin-treatment', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isLaboklinTreatment = isLaboklinTreatment;

  function isLaboklinTreatment() {
    return API['default'].isLaboklinTreatmentAllowed();
  }

  exports['default'] = Ember['default'].Helper.helper(isLaboklinTreatment);

});