define('jason-frontend/models/product-supplier-cashback-threshold', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    cashback: DS['default'].attr('number'),
    threshold: DS['default'].attr('number'),
    validFrom: DS['default'].attr(''),
    validTo: DS['default'].attr(''),
    validFromMonth: DS['default'].attr(''),
    validFromYear: DS['default'].attr(''),
    percentageWarning: DS['default'].attr('number'),
    supplier: DS['default'].belongsTo('product-supplier')
  });

});