define('jason-frontend/components/purchase-order-supplier-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    data: null,
    richterStatusPending: true,
    burstable: false,
    captionText: 'Überprüfe elOrd Warenkorb',
    updateRichter: function updateRichter(data) {
      this.set('result', data);
      this.set('burstable', false);
      this.set('richterStatusPending', false);
      this.set('richterChoice', 'delete');

      if (data.status === 'NOT_NEEDED') {
        console.log('proceed - no check');

        this.get('data').get('subOrders').forEach(function (subOrder) {
          if (subOrder.get('richterApi')) {
            Ember['default'].set(subOrder, 'confirmOrder', true);
            Ember['default'].set(subOrder, 'authority', true);
          }
        });
      } else {
        this.get('data').get('subOrders').forEach(function (subOrder) {
          if (subOrder.get('richterApi')) {
            Ember['default'].set(subOrder, 'confirmOrder', false);
          }
        });

        this.checkGlobalCheckbox();
      }
    },
    checkGlobalCheckbox: function checkGlobalCheckbox() {
      var self = this,
          oneChecked = false;
      this.set('toggleVisible', true);

      this.get('data').get('subOrders').forEach(function (subOrder) {
        if (subOrder.get('confirmOrder') && !subOrder.get('missingCredentials')) {
          oneChecked = true;
        }
      });

      if (oneChecked) {
        self.set('toggleVisible', true);
      } else {
        self.set('toggleVisible', false);
      }
    },
    actions: {
      init: function init() {
        this.set('richterStatusPending', true);
        this.set('captionText', 'Überprüfe elOrd Warenkorb');
        this.set('toggleVisible', false);
        this.set('burstable', false);
        this.checkGlobalCheckbox();
      },
      toMandant: function toMandant() {
        this.sendAction('toMandant');
        $.magnificPopup.close();
      },
      confirmRichterChoice: function confirmRichterChoice() {

        var self = this;
        this.set('showLoading', true);
        var choice = this.get('richterChoice');

        if (choice === 'cancel') {
          Ember['default'].set(this.get('result'), 'status', 'WITHOUT');
        }
        if (choice === 'delete') {
          $.ajax({
            url: "/api/purchaseOrders/richterClearCart",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            Ember['default'].set(self.get('result'), 'status', 'OK');
            self.set('showLoading', false);
          }, function () {});
        }
        if (choice === 'execute') {
          $.ajax({
            url: "/api/purchaseOrders/executeRichterCart",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            $.ajax({
              url: "/api/purchaseOrders/richterApiCheck?orderId=" + self.get('data').id + "&async=false&invalid=true",
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (checkData) {
              self.updateRichter(checkData);
              self.set('showLoading', false);
            });
          }, function () {});
        }
      },
      updateRichter: function updateRichter(data) {
        this.updateRichter(data);
      },
      deleteProduct: function deleteProduct(item) {
        this.sendAction('deleteProduct', item.orderEntryId, item.orderId, item.name);
      },
      updateRichterReaction: function updateRichterReaction(status, dataReal) {

        var burstable = true;
        dataReal.notokEntries.forEach(function (subOrder) {
          if (burstable && subOrder.burstable == false) {
            burstable = false;
          }
        });

        if (!dataReal.blocked) {
          this.set('burstable', burstable);
          console.log('override burst:' + burstable);
        }

        var data = {
          status: status,
          notokEntries: dataReal.notokEntries,
          blocked: dataReal.blocked
        };
        this.set('result', data);
        this.set('richterStatusPending', false);
        this.checkGlobalCheckbox();
      },
      confirm: function confirm(order) {
        var self = this;
        var needsRichterAvailabilityCheck = false;
        this.get('data').get('subOrders').forEach(function (subOrder) {
          if (subOrder.get('confirmOrder') && subOrder.get('richterApi')) {
            needsRichterAvailabilityCheck = true;
          }
        });

        if (self.get('burstable') == true) {
          needsRichterAvailabilityCheck = false;
        }

        this.sendAction('confirm', order);
        this.set('toggleVisible', false);
        this.set('captionText', 'Überprüfe Lieferstatus');
        this.set('richterStatusPending', true);
      },
      closePanel: function closePanel() {
        this.set('richterStatusPending', false);
        $.magnificPopup.close();
      },
      openLaboklin: function openLaboklin(order) {
        this.sendAction('openLaboklin', order);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      toggleCheckbox: function toggleCheckbox(order) {
        if (order.get('confirmOrder')) {
          order.set('confirmOrder', false);
        } else {
          order.set('confirmOrder', true);
        }
        this.checkGlobalCheckbox();
      }
    },
    becomeFocused: (function () {
      this.checkGlobalCheckbox();
    }).observes('data'),
    watchAuthorityChanged: (function () {
      var self = this;
      this.get('data').get('subOrders').forEach(function (subOrder) {
        if (subOrder.get('richterApi')) {
          if (subOrder.get('authority')) {
            Ember['default'].set(subOrder, 'confirmOrder', true);
          } else {
            Ember['default'].set(subOrder, 'confirmOrder', false);
          }
        }
      });

      setTimeout(function () {
        self.checkGlobalCheckbox();
      }, 100);
    }).observes('data.subOrders.@each.authority')
  });

});