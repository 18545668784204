define('jason-frontend/routes/confirm', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model, params) {
      var id = params.queryParams.id;

      $.ajax({
        url: "/api/register/confirm/" + id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function () {}, function (data) {});
    },
    actions: {},

    beforeModel: function beforeModel() {}
  });

});