define('jason-frontend/controllers/users/create', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Anlegen",
    breadCrumbPath: "users.create",
    actions: {}
  });

});