define('jason-frontend/routes/purchases/cashback-thresholds', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {},
    setupController: function setupController(controller) {
      this.applyFilter();
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller');

      this.findPaged('product-supplier', {
        perPage: 10,
        hideAll: true,
        addInvoiceDetails: true
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    actions: {
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      downloadInvoice: function downloadInvoice(mandantId, month, year) {
        var deferred = $.ajax({
          url: "/api/productSuppliers/invoices/" + mandantId + "/" + month + "/" + year,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/productSuppliers/invoices/show/' + mandantId + "/" + month + "/" + year);
        });
      },
      sendInvoice: function sendInvoice(mandantId, month, year) {
        var self = this,
            controller = this.get('controller');

        controller.set('showLoading', true);
        var deferred = $.ajax({
          url: "/api/productSuppliers/invoices/email/" + mandantId + "/" + month + "/" + year,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Email', 'erfolgreich verschickt');
          self.applyFilter();
          controller.set('showLoading', false);
        }, function (data) {
          jason.notifiction.notifiy('Email', 'erfolgreich verschickt');
          self.applyFilter();
          controller.set('showLoading', false);
        });
      }
    }
  });

});