define('jason-frontend/templates/components/button-reductions', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 5,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/button-reductions.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"class","circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","16");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[4,60],[4,65]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-reductions.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 34 34");
        dom.setAttribute(el1,"class","cursor button-reduction");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(1 1)");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"class","icon in__icon");
        dom.setAttribute(el3,"transform","scale(0.044, 0.044) translate(210,200)");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M139.219,124.494c12.369-13.416,19.184-31.412,19.184-50.678C158.403,37.134,132.202,0,82.116,0     C58.807,0,39.143,8.006,25.247,23.152C12.938,36.57,6.161,54.562,6.161,73.816c0,36.686,26.09,73.818,75.955,73.818     C105.512,147.635,125.262,139.635,139.219,124.494z M82.116,34.322c27.443,0,39.941,20.486,39.941,39.52     c0,10.354-3.484,19.939-9.816,26.986c-7.064,7.871-17.33,12.033-29.68,12.033c-28.137,0-40.955-20.484-40.955-39.516     C41.606,53.924,54.134,34.322,82.116,34.322z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M264.274,28.476c-4.539,0.082-10.736,2.912-13.772,6.287L25.186,285.275c-3.035,3.377-0.885,8.111,3.654,8.111h29.49     c4.539,0,10.73-2.768,13.76-6.148L296.013,36.777c3.029-3.383,2.828-8.887-4.672-8.885L264.274,28.476z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M239.647,175.822c-22.859,0-42.15,7.858-55.783,22.715c-12.074,13.158-18.726,30.811-18.726,49.699     c0,35.984,25.594,72.412,74.51,72.412c22.957,0,42.326-7.85,56.02-22.701c12.135-13.162,18.818-30.816,18.818-49.711     C314.485,212.252,288.78,175.822,239.647,175.822z M240.089,287.418c-27.375,0-39.848-20.557-39.848-39.648     c0-10.397,3.482-20.018,9.809-27.092c7.053-7.894,17.287-12.066,29.598-12.066c27.377,0,39.844,20.553,39.844,39.65     c0,10.395-3.483,20.018-9.805,27.092C262.632,283.246,252.397,287.418,240.089,287.418z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("/g>\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createMorphAt(element2,1,1);
        morphs[3] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,10],[3,20]]]]],[],0,null,["loc",[null,[3,4],[5,13]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[6,37],[6,42]]]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});