define('jason-frontend/components/download-invoice-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      downloadInvoice: function downloadInvoice(invoice) {
        if (this.get('selectedPrintTemplate')) {
          invoice.printTemplateId = this.get('selectedPrintTemplate').id;
        }
        this.sendAction('downloadInvoice', invoice);
      },
      loadPrintTemplates: function loadPrintTemplates(data) {
        this.set('printTemplates', data);
        if (data && data != null && data.length && data.length > 0) {
          this.set('selectedPrintTemplate', data[0]);
        }
      },
      downloadWarning: function downloadWarning(invoice, number) {
        if (this.get('selectedPrintTemplate')) {
          invoice.printTemplateId = this.get('selectedPrintTemplate').id;
        }
        this.sendAction('downloadWarningInvoice', invoice, number);
      },
      downloadWarningText: function downloadWarningText(invoice, number) {

        if (this.get('selectedPrintTemplate')) {
          invoice.printTemplateId = this.get('selectedPrintTemplate').id;
        }
        this.sendAction('downloadWarningText', invoice, number);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(invoice) {
        this.set('invoice', invoice);
      }
    }
  });

});