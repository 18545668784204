define('jason-frontend/components/invoice-add-product', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ['table__row'],
    showDosingIcon: false,
    inclusiveVpes: true,
    showCartButton: true,
    newStyle: false,
    actions: {
      toggleProductFavorite: function toggleProductFavorite(product, add) {
        Ember['default'].set(product, 'favorite', add);
        this.sendAction('toggleProductFavorite', product.id, add);
      },
      togglePriceFavorite: function togglePriceFavorite(product, price, add) {
        Ember['default'].set(price, 'favorite', add);
        this.sendAction('togglePriceFavorite', product.id, price.id, add);
      },
      addProduct: function addProduct(model, invoiceId, priceId, productName) {

        var id = priceId > 0 ? priceId : model.id;

        var qty = $(".spinn_" + id + " > input").val();

        qty = String(qty).replace(',', '.');

        this.sendAction('addProduct', qty, model.id, invoiceId, priceId, productName);
      }
    }
  });

});