define('jason-frontend/templates/components/button-labor', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-labor.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 34 34");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(1 1)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("circle");
        dom.setAttribute(el3,"class","circle");
        dom.setAttribute(el3,"cx","16");
        dom.setAttribute(el3,"cy","16");
        dom.setAttribute(el3,"r","16");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","translate(4,4) scale(0.07,0.07)");
        dom.setAttribute(el3,"fill-rule","evenodd");
        dom.setAttribute(el3,"class","icon-wrapper");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"class","icon");
        dom.setAttribute(el4,"d","M261.673,65.562l29.441-29.441L254.993,0l-29.441,29.441l-15.508-15.508L77.749,146.228l67.138,67.138l87.326-87.326\n	c28.395,34.831,26.369,86.358-6.079,118.805c-29.412,29.413-74.494,33.813-108.616,13.232h26.284v-15H54.189v15h38.538\n	c19.858,18.474,45.247,27.718,70.64,27.718c11.251,0,22.5-1.821,33.27-5.447l15.347,49.957h-85.118l13.802-46.463l-14.379-4.271\n	l-19.527,65.734h125.521l-21.749-70.799c9.449-4.813,18.312-11.16,26.206-19.054c38.3-38.3,40.335-99.334,6.121-140.061l34.32-34.32\n	L261.673,65.562z M236.158,40.047l18.834-18.834l14.909,14.908l-18.834,18.835l-7.454-7.454L236.158,40.047z M233.006,58.108\n	l18.061,18.061l0,0l4.902,4.902l-82.623,82.623l-45.925-45.925l82.623-82.623L233.006,58.108z M98.961,146.228l17.853-17.853\n	l45.925,45.925l-17.853,17.853L98.961,146.228z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element0, 'class');
        morphs[3] = dom.createElementMorph(element0);
        morphs[4] = dom.createAttrMorph(element2, 'stroke');
        morphs[5] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","class",["concat",["state-button cursor ",["subexpr","css-bool-evaluator",[["get","active",["loc",[null,[1,143],[1,149]]]],"active",""],[],["loc",[null,[1,122],[1,163]]]]]]],
        ["element","action",["toggle"],[],["loc",[null,[1,165],[1,184]]]],
        ["attribute","stroke",["concat",[["get","color",["loc",[null,[3,60],[3,65]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[4,15],[4,20]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});