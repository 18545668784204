define('jason-frontend/routes/layerplans/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {
      return this.store.find('appointment-layerplan');
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);
      this.applyFilter();
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller');
      this.findPaged('appointment-layerplan', {
        perPage: 10
      }).then(function (msgs) {
        controller.set('model', msgs);
      });
    },
    actions: {
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      "delete": function _delete(id) {
        var self = this;
        $.ajax({
          url: "/api/appointmentLayerplans/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy("Erfolgreich", "Terminvorlage entfernt");
          self.applyFilter();
        }, function (data) {
          jason.notifiction.notifiy("Erfolgreich", "Terminvorlage entfernt");
          self.applyFilter();
        });
      }
    }
  });

});