define('jason-frontend/routes/mandants/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    queryParams: {
      filterName: {
        refreshModel: true
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('taxes', this.store.find('tax'));
      this.applyFilter();
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller');

      this.findPaged('mandant', {
        perPage: 10,
        filterMandant: true,
        addInvoices: true,
        includeTestMandants: false
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    model: function model(params) {},
    actions: {
      editText: function editText(data) {

        var controller = this.get('controller');

        var deferred = $.ajax({
          url: "/api/mandantInvoiceTexts",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Rechnungstext', 'gespeichert');
        }, function (error) {
          jason.notifiction.notifiy('Rechnungstext', 'gespeichert');
        });
      },
      downloadInvoice: function downloadInvoice(mandantId, month, year) {
        var deferred = $.ajax({
          url: "/api/mandants/invoices/" + mandantId + "/" + month + "/" + year,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/mandants/invoices/show/' + mandantId + "/" + month + "/" + year);
        });
      },
      sendInvoice: function sendInvoice(mandantId, month, year) {
        var self = this,
            controller = this.get('controller');

        controller.set('showLoading', true);
        var deferred = $.ajax({
          url: "/api/mandants/invoices/email/" + mandantId + "/" + month + "/" + year,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Email', 'erfolgreich verschickt');
          self.applyFilter();
          controller.set('showLoading', false);
        }, function (data) {
          jason.notifiction.notifiy('Email', 'erfolgreich verschickt');
          self.applyFilter();
          controller.set('showLoading', false);
        });
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      toggleInactive: function toggleInactive(show) {
        this.get('controller').set('showInactive', show);
      },
      deactivateMandant: function deactivateMandant(id) {
        var controller = this.get('controller'),
            self = this;

        Ember['default'].$.ajax({
          url: "/api/mandants/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {}, function () {
          jason.notifiction.notifiy('Mandant', 'deaktiviert');
          self.refresh();
        });
      },
      reactivateMandant: function reactivateMandant(id) {
        var controller = this.get('controller'),
            self = this;

        Ember['default'].$.ajax({
          url: "/api/mandants/reactivateMandant/" + id,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {}, function () {
          jason.notifiction.notifiy('Mandant', 'aktiviert');
          self.refresh();
        });
      }
    }
  });

});