define('jason-frontend/models/customer', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    foreignId: DS['default'].attr('string'),
    firstname: DS['default'].attr('string'),
    lastname: DS['default'].attr('string'),
    fullName: DS['default'].attr('string'),
    street: DS['default'].attr('string'),
    postalCode: DS['default'].attr('string'),
    reminderSetting: DS['default'].attr('string'),
    defaultReminderSetting: DS['default'].attr('string'),
    town: DS['default'].attr('string'),
    email: DS['default'].attr('string'),
    email2: DS['default'].attr('string'),
    email3: DS['default'].attr('string'),
    email4: DS['default'].attr('string'),
    uid: DS['default'].attr('string'),
    emailDescription: DS['default'].attr('string'),
    emailDescription2: DS['default'].attr('string'),
    emailDescription3: DS['default'].attr('string'),
    emailDescription4: DS['default'].attr('string'),
    phone: DS['default'].attr('string'),
    phone1: DS['default'].attr('string'),
    phone2: DS['default'].attr('string'),
    phone3: DS['default'].attr('string'),
    phone4: DS['default'].attr('string'),
    phoneDescription: DS['default'].attr('string'),
    phoneDescription1: DS['default'].attr('string'),
    phoneDescription2: DS['default'].attr('string'),
    phoneDescription3: DS['default'].attr('string'),
    phoneDescription4: DS['default'].attr('string'),
    fax: DS['default'].attr('string'),
    fax2: DS['default'].attr('string'),
    fax3: DS['default'].attr('string'),
    fax4: DS['default'].attr('string'),
    faxDescription: DS['default'].attr('string'),
    faxDescription2: DS['default'].attr('string'),
    faxDescription3: DS['default'].attr('string'),
    faxDescription4: DS['default'].attr('string'),
    reductionToPurchasePrice: DS['default'].attr('boolean'),
    gender: DS['default'].belongsTo('gender', { async: true }),
    title: DS['default'].belongsTo('title', { async: true }),
    genderId: DS['default'].attr('string'),
    patientNames: DS['default'].attr('string'),
    deposit: DS['default'].attr('number'),
    version: DS['default'].attr('number'),
    patients: DS['default'].hasMany('patient'),
    emails: DS['default'].attr(''),
    phones: DS['default'].attr(''),
    reductions: DS['default'].hasMany('customer-reduction'),
    patientSorting: ['name'],
    sortedPatients: Ember.computed.sort('patients', 'patientSorting'),
    driveAddressAvailable: DS['default'].attr('boolean'),
    distanceKm: DS['default'].attr('number'),
    distanceMinutes: DS['default'].attr('number'),
    driveStreet: DS['default'].attr('string'),
    driveTown: DS['default'].attr('string'),
    drivePostalcode: DS['default'].attr('string'),
    driveNotice: DS['default'].attr('string'),
    sued: DS['default'].attr(''),
    company: DS['default'].attr('string'),
    profession: DS['default'].attr('string'),
    birthday: DS['default'].attr('date'),
    notice: DS['default'].attr('string'),
    tags: DS['default'].attr('string'),
    homepage: DS['default'].attr('string'),
    paymentMoral: DS['default'].belongsTo('payment-moral'),
    invoiceConditions: DS['default'].attr('string'),
    iban: DS['default'].attr('string'),
    deleted: DS['default'].attr('boolean'),
    etigaAppConnected: DS['default'].attr(''),
    etigaCodeRequested: DS['default'].attr(''),
    bic: DS['default'].attr('string'),
    invoiceFirstname: DS['default'].attr('string'),
    invoiceLastname: DS['default'].attr('string'),
    invoiceStreet: DS['default'].attr('string'),
    invoicePostalcode: DS['default'].attr('string'),
    invoiceTown: DS['default'].attr('string'),
    invoiceUid: DS['default'].attr('string'),
    invoiceGender: DS['default'].belongsTo('gender', { async: true }),
    invoiceTitle: DS['default'].belongsTo('title', { async: true }),
    country: DS['default'].belongsTo('country', { async: true }),
    salutation: DS['default'].belongsTo('customer-salutation'),
    differentInvoice: DS['default'].attr('boolean'),
    daysTillWarning1: DS['default'].attr('number'),
    daysTillWarning2: DS['default'].attr('number'),
    daysTillNopayer: DS['default'].attr('number'),
    defaultDaysTillWarning1: DS['default'].attr('number'),
    defaultDaysTillWarning2: DS['default'].attr('number'),
    defaultDaysTillNopayer: DS['default'].attr('number'),
    validations: {
      firstname: {
        presence: true
      },
      lastname: {
        presence: true
      }
    }
  });

});