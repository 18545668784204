define('jason-frontend/controllers/mandants/index', ['exports', 'jason-frontend/api'], function (exports, API) {

  'use strict';

  exports['default'] = Ember.ArrayController.extend({
    queryParams: ["page", "perPage", "filterName"],
    filterName: "",

    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    showInactive: false,
    showLoading: false,

    page: 1,
    perPage: 10,
    actions: {
      openInvoiceTextPanel: function openInvoiceTextPanel(mandantId, month, year) {

        var self = this;

        $.ajax({
          url: "/api/mandantInvoiceTexts/" + mandantId + "/" + month + "/" + year,
          method: "GET",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('invoiceTextPanel').send('load', data.mandantInvoiceText, month, year, mandantId);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-form-mandant-invoice-text-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    }
  });

});