define('jason-frontend/routes/expired', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model, params) {
      controller.set('showConfirm', false);
      controller.set('password', null);
      controller.set('passwordConfirmation', null);
      var self = this;

      if (params.queryParams.token) {
        var data = {
          token: params.queryParams.token
        };

        $.ajax({
          url: "/api/users/executeChangeRequest",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.error('Fehler', 'Bestätigung konnte nicht durchgeführt werden');
          self.transitionTo('login');
        }, function (data) {
          jason.notifiction.notifiy('Erfolg', 'Passwortänderung erfolgreich durchgeführt');
          self.transitionTo('login');
        });
      } else {
        if (!API['default'].getExpiringToken() || API['default'].getExpiringToken() === 'null') {
          this.transitionTo('login');
        }
      }
    },
    actions: {
      toLogin: function toLogin() {
        API['default'].resetToken();
        this.transitionTo('login');
      },
      changeExpired: function changeExpired() {
        var controller = this.get('controller');

        var data = {
          password: controller.get('password'),
          passwordConfirmation: controller.get('passwordConfirmation'),
          token: API['default'].getExpiringToken()
        };

        controller.set('inProgress', true);

        $.ajax({
          url: "/api/users/changeRequest",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolg', 'Email zur Bestätigung wurde verschickt');
        }, function (data) {
          controller.set('inProgress', false);
          if (data.responseJSON) {
            var error = data.responseJSON.message;

            if (error === 'same') {
              jason.notifiction.error('Fehler', 'Das neue Passwort darf nicht mit dem alten übereinstimmen');
            }
          } else {
            jason.notifiction.notifiy('Erfolg', 'Email zur Bestätigung wurde verschickt');
            controller.set('inProgress', false);
            controller.set('email', data.responseText);
            controller.set('showConfirm', true);
            API['default'].resetToken();
          }
        });
      }
    }
  });

});