define('jason-frontend/components/purchase-product-cell', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: '',
    qty: 1,
    promotionCell: false,
    actions: {
      checkLaboklinPanel: function checkLaboklinPanel(product, orderId, priceInfo, supplierId, productName) {
        this.sendAction('checkLaboklinPanel', product, orderId, priceInfo, supplierId, productName);
      },
      gotoDetail: function gotoDetail(productId, orderId) {
        this.sendAction('click', productId, orderId);
      },
      addToCart: function addToCart(productId, priceInfoId, orderId, productName) {
        this.sendAction('add', productId, orderId, priceInfoId, productName, 0, this.get('qty'));
      },
      increaseQuantity: function increaseQuantity() {
        this.set('qty', this.get('qty') + 1);
      },
      toggleFavorite: function toggleFavorite(product, add) {
        Ember['default'].set(product, 'favorite', add);
        this.sendAction('toggleFavorite', product.id, add);
      },
      decreaseQuantity: function decreaseQuantity() {
        if (this.get('qty') > 1) {
          this.set('qty', this.get('qty') - 1);
        }
      }
    }
  });

});