define('jason-frontend/models/patient-treatment-info', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    created: DS['default'].attr('date'),
    medicalHistory: DS['default'].attr('string'),
    symptom: DS['default'].attr('string'),
    therapy: DS['default'].attr('string'),
    report: DS['default'].attr('string'),
    diagnose: DS['default'].attr('string'),
    introductionTalk: DS['default'].attr('string'),
    digitalDiagnose: DS['default'].attr('string'),
    digitalTopDiagnose: DS['default'].attr('string'),
    patientId: DS['default'].attr('number'),
    invoiceId: DS['default'].attr('number'),
    shitConsistency: DS['default'].attr(),
    virtualTreatment: DS['default'].attr(),
    luBehaviour: DS['default'].attr(),
    shitBehaviour: DS['default'].attr(),
    diagnosticTests: DS['default'].attr(),
    adminTasks: DS['default'].attr(),
    luColor: DS['default'].attr(),
    activity: DS['default'].attr(),
    diagnoseInvoiceText: DS['default'].attr(),
    waterBehaviour: DS['default'].attr(),
    feedingBehaviour: DS['default'].attr(),
    introductionReasons: DS['default'].attr(),
    symptoms: DS['default'].attr(),
    digitalDiagnoses: DS['default'].attr(),
    excludedDigitalDiagnoses: DS['default'].attr(),
    excludedFinalDigitalDiagnoses: DS['default'].attr(),
    manualTreatments: DS['default'].attr(),
    finalDigitalDiagnoses: DS['default'].attr(),
    digitalTherapies: DS['default'].attr(),
    invoiceCode: DS['default'].attr('string'),
    invoiceDate: DS['default'].attr('date'),
    invoiceCustomer: DS['default'].attr('string'),
    invoiceCustomerId: DS['default'].attr('number'),
    dummy: DS['default'].attr('boolean')
  });

});