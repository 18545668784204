define('jason-frontend/components/treatment-info-form-clone', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    id: null,
    patientId: null,
    invoiceId: null,
    actions: {
      save: function save() {
        var data = {
          medicalHistory: $('#overlay_medicalHistory-clone').val(),
          symptom: $('#overlay_symptom-clone').val(),
          therapy: $('#overlay_therapy-clone').val(),
          diagnose: $('#overlay_diagnose-clone').val(),
          invoiceId: this.get('invoiceId'),
          patientId: this.get('patientId'),
          id: this.get('id')
        };

        this.sendAction('treatmentSaved', data);
        $.magnificPopup.close();
      },
      load: function load(id, patient, invoiceId, data, permissions, taskData) {
        this.set('data', data);
        this.set('id', id);
        this.set('invoiceId', invoiceId);
        this.set('patientId', patient.id);
        this.set('patient', patient);
        this.set('taskData', taskData);
        this.set('etigaPermissions', permissions);
      }
    }
  });

});