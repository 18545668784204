define('jason-frontend/components/toggle-favorite-button2', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    cssClass: 'btn-default',
    selected: "false",
    fireAction: false,
    actions: {
      toggle: function toggle() {
        var el = this.get('element'),
            $btn = $('.btn-blub', el);

        if (this.get('selected') === true) {
          this.set('selected', false);
          if (this.get('fireAction')) {
            this.sendAction('toggle', false);
          }
        } else {
          this.set('selected', true);
          if (this.get('fireAction')) {
            this.sendAction('toggle', true);
          }
        }
      }
    },
    becomeFocused: (function () {
      if (this.selected === "true" || this.selected === true) {
        this.set('selected', 'true');
      }
    }).on('didInsertElement')
  });

});