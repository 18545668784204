define('jason-frontend/helpers/get-days-between-improved', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getDaysBetweenImproved = getDaysBetweenImproved;

  function getDaysBetweenImproved(params) {
    var from = params[0],
        prefixPhrase = params[1],
        to = new Date();

    to.setHours(0, 0, 0, 0);

    var difference = moment(from).diff(moment(to), "days");

    if (difference == 1) {
      return 'morgen';
    }

    if (difference == -1) {
      return 'gestern';
    }

    if (difference < 0) {
      difference = difference * -1;
    }

    if (difference == 0) {
      return 'heute';
    }

    return prefixPhrase + ' ' + difference + ' Tagen';
  }

  exports['default'] = Ember['default'].Helper.helper(getDaysBetweenImproved);

});