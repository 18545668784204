define('jason-frontend/components/button-quick-feeding', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'top',
    timer: 0,
    color: '#4A4A4A',
    active: false,
    content: 'Erhaltene Ernährung anzeigen',
    theme: 'tooltipster-punk'
  });

});