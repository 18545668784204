define('jason-frontend/components/choose-appointment-edit-type-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    action: 'task',
    showTitle: true,
    actions: {
      load: function load(id) {
        this.set('id', id);
        this.set('action', 'task');
      },
      confirm: function confirm() {
        if (this.get('action') === 'task') {
          this.sendAction('editEvent', this.get('id'), false);
        } else {
          this.sendAction('editEvent', this.get('id'), true);
        }
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      reset: function reset() {}
    }
  });

});