define('jason-frontend/components/ical-link-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    link: null,
    actions: {
      load: function load(link) {
        this.set('title', 'https://portal.myjason.' + API['default'].getTopLevelDomain() + '/api/ical?token=' + link);
      },
      ok: function ok(app, msg) {
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});