define('jason-frontend/routes/basicinfos/index', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model() {},
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);
      var self = this;
      var selectedTab = params.queryParams.selectTab;

      if (selectedTab) {
        this.selectTab(selectedTab);
      } else {
        setTimeout(function () {
          if (API['default'].hasPermission("crud_products")) {
            self.selectTab('productcategories');
          } else if (API['default'].hasPermission("crud_services")) {
            self.selectTab('servicecategories');
          }
        }, 500);
      }
    },
    applyFilter: function applyFilter() {
      if (this.get('controller').get('selectTab') === 'serviceunits') {
        if (API['default'].hasPermission("crud_services")) {
          this.applyFilterServicecategories();
        }
      }
      if (this.get('controller').get('selectTab') === 'appointmentcategories') {
        this.applyAppointmentCategories();
      }
      if (this.get('controller').get('selectTab') === 'tags') {
        this.applyTags();
      }
      if (this.get('controller').get('selectTab') === 'etigaAppointmentcategories') {
        this.applyEtigaAppointmentCategories();
      }
      if (this.get('controller').get('selectTab') === 'distributors') {
        if (API['default'].hasPermission("crud_distributor")) {
          this.applyDistributors();
        }
      }
      if (this.get('controller').get('selectTab') === 'manufacturers') {
        if (API['default'].hasPermission("crud_manufacturer")) {
          this.applyManufacturers();
        }
      }
    },
    applyAppointmentCategories: function applyAppointmentCategories() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName');
      this.findPaged('patient-appointment-category', {
        filterName: filterName
      }).then(function (data) {
        controller.set('model', data);
      });
    },
    applyEtigaAppointmentCategories: function applyEtigaAppointmentCategories() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName');
      this.findPaged('patient-appointment-category', {
        filterName: filterName,
        etiga: true
      }).then(function (data) {
        controller.set('model', data);
      });
    },
    applyDistributors: function applyDistributors() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          filterSource = controller.get('filterSource'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      this.findPaged('productDistributor', {
        filterName: filterName,
        filterMandant: filterSource,
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10,
        paged: true
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    applyManufacturers: function applyManufacturers() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          filterSource = controller.get('filterSource'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      this.findPaged('productManufacturer', {
        filterName: filterName,
        filterMandant: filterSource,
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10,
        paged: true
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    applyTags: function applyTags() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          filterSource = controller.get('filterSource'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      this.findPaged('document-tag', {
        filterName: filterName,
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10,
        paged: true
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    applyFilterServicecategories: function applyFilterServicecategories() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          filterSource = controller.get('filterSource'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      if (!filterSource) {
        filterSource = "all";
      }

      this.findPaged('serviceunit', {
        filterName: filterName,
        filterMandant: filterSource,
        sortDir: sortDir,
        paged: true,
        sortBy: sortBy,
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    selectTab: function selectTab(selected) {
      var controller = this.get('controller');
      controller.set('selectTab', selected);

      if (selected === 'productcategories') {
        if (API['default'].hasPermission("crud_categories")) {
          controller.set('model', this.store.find('productCategory'));
        }
        controller.set('placeholder', 'Produkt suchen');
      } else if (selected === 'servicecategories') {
        if (API['default'].hasPermission("crud_categories")) {
          controller.set('model', this.store.find('serviceCategory'));
        }
        controller.set('placeholder', 'Leistung suchen');
      } else if (selected === 'serviceunits') {
        this.applyFilterServicecategories();
        controller.set('placeholder', 'Einheit suchen');
      } else if (selected === 'tags') {
        this.applyTags();
        controller.set('placeholder', 'Tag suchen');
      } else if (selected === 'appointmentcategories') {
        this.applyAppointmentCategories();
        controller.set('placeholder', 'Terminkategorie suchen');
      } else if (selected === 'etigaAppointmentcategories') {
        this.applyEtigaAppointmentCategories();
        controller.set('placeholder', 'App Terminbezeichnungen suchen');
      } else if (selected === 'distributors') {
        this.applyDistributors();
        controller.set('placeholder', 'Lieferant suchen');
      } else if (selected === 'manufacturers') {
        this.applyManufacturers();
        controller.set('placeholder', 'Hersteller suchen');
      }
    },
    actions: {
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      selectTab: function selectTab(id) {
        this.selectTab(id);
      },
      deleteManufacturer: function deleteManufacturer(id) {
        var self = this;
        this.get('store').find('productManufacturer', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.applyFilter();
        }, 1000);
      },
      deleteDistributor: function deleteDistributor(id) {
        var controller = this.get('controller'),
            self = this;
        this.get('store').find('productDistributor', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.applyFilter();
        }, 1000);
      },
      deleteEtigaAppointmentCategory: function deleteEtigaAppointmentCategory(id) {
        var self = this;
        $.ajax({
          url: "/api/patientAppointmentCategories/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
        }, function (error) {
          self.applyFilter();
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
        });
      },
      deleteAppointmentCategory: function deleteAppointmentCategory(id) {
        var self = this;
        $.ajax({
          url: "/api/patientAppointmentCategories/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
        }, function (error) {
          self.applyFilter();
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
        });
      },
      deleteTag: function deleteTag(id) {
        var self = this;
        $.ajax({
          url: "/api/documentTags/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
        }, function (error) {
          self.applyFilter();
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
        });
      },
      deleteServiceunits: function deleteServiceunits(id) {
        var controller = this.get('controller'),
            self = this;
        this.get('store').find('serviceunit', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.applyFilter();
        }, 500);
      },
      deleteServiceCategory: function deleteServiceCategory(id) {
        var controller = this.get('controller'),
            self = this;
        this.get('store').find('serviceCategory', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.selectTab('servicecategories');
        }, 1000);
      },
      deleteProductCategory: function deleteProductCategory(id) {
        var controller = this.get('controller'),
            self = this;
        this.get('store').find('productCategory', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.selectTab('productcategories');
        }, 1000);
      }
    }
  });

});