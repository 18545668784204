define('jason-frontend/helpers/get-orphand-patients', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getOrphandPatients = getOrphandPatients;

  function getOrphandPatients(params) {
    var patients = params[0],
        shown = params[1],
        patientList = new Array();

    if (patients) {
      var cleanIndex = 0;
      $.each(patients.currentState, function (index, value) {
        var found = false;
        if (shown) {
          $.each(shown, function (shownIndex, shownValue) {
            if (shownValue.id == value.id) {
              found = true;
            }
          });
        }
        if (!found) {
          cleanIndex++;
          patientList[cleanIndex] = {
            id: value.id,
            name: value._data.name
          };
        }
      });
    }
    return patientList;
  }

  exports['default'] = Ember['default'].Helper.helper(getOrphandPatients);

});