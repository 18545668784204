define('jason-frontend/templates/practicemgmt/station', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 6
            },
            "end": {
              "line": 9,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0,0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","station.selected",["loc",[null,[8,61],[8,77]]]],"selected",""],[],["loc",[null,[8,40],[8,93]]]]]]],
          ["element","action",["toggleStation",["get","station",["loc",[null,[8,120],[8,127]]]]],[],["loc",[null,[8,95],[8,129]]]],
          ["content","station.name",["loc",[null,[8,130],[8,146]]]]
        ],
        locals: ["station"],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 28,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","site-content card transaction-filter");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper mb30 mt20 grid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter mb0 col l-12/24");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Stationen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("Alle");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1, 1]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element3, 'class');
        morphs[1] = dom.createElementMorph(element3);
        morphs[2] = dom.createMorphAt(element2,5,5);
        morphs[3] = dom.createMorphAt(element1,3,3);
        morphs[4] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","allStationsSelected",["loc",[null,[6,59],[6,78]]]],"selected",""],[],["loc",[null,[6,38],[6,94]]]]]]],
        ["element","action",["toggleAllStations"],[],["loc",[null,[6,96],[6,126]]]],
        ["block","each",[["get","stations",["loc",[null,[7,14],[7,22]]]]],[],0,null,["loc",[null,[7,6],[9,15]]]],
        ["inline","treatment-patient-list",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[14,10],[14,15]]]]],[],[]],"showStation",true,"changeTrigger",["subexpr","@mut",[["get","changeTrigger",["loc",[null,[16,19],[16,32]]]]],[],[]],"filteredStations",["subexpr","@mut",[["get","stations",["loc",[null,[17,22],[17,30]]]]],[],[]],"movePatientToTreatmentRoom","movePatientToTreatmentRoom","movePatientToWaitingRoom","movePatientToWaitingRoom","abort","abort","exportInvoice","exportInvoice","editTreatmentInfo","editTreatmentInfo"],["loc",[null,[13,2],[23,4]]]],
        ["inline","edit-treatment-infos-panel",[],["confirm","saveEditTreatmentInfo","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[26,67],[26,72]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[26,84],[26,94]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[26,103],[26,110]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","infoTreatmentPanel",["loc",[null,[27,44],[27,62]]]]],[],[]]],["loc",[null,[26,0],[27,64]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});