define('jason-frontend/helpers/is', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.is = is;

  function is(params) {
    return params[0] === params[1];
  }

  exports['default'] = Ember['default'].Helper.helper(is);

});