define('jason-frontend/routes/appointments/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {
      var self = this;
      controller.set('doctors', this.store.find('user', { role: 'doctor', per_page: 100, addAll: true }));
      controller.set('assistants', this.store.find('user', { role: 'assistant', per_page: 100, addAll: true }));
      controller.set('users', this.store.find('user', { per_page: 100 }));
      controller.set('rooms', this.store.find('treatment-room'));
      controller.set('stations', this.store.find('treatment-station'));
      controller.set('resources', this.store.find('appointment-resource'));
      controller.set('filterDoctor', null);

      $.ajax({
        url: "/api/patientAppointmentCategories/forlist",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('patientAppointmentCategories', data.patientAppointmentCategory);
      });

      $.ajax({
        url: "/api/patientAppointmentCategories?filterActive=true&etiga=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('etigaCategories', data.patientAppointmentCategory);
      });

      $.ajax({
        url: "/api/productCategories?suggest=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('productCategories', data.productCategory.sortBy('text'));
        controller.set('filterProductCategory', null);
      });

      $.ajax({
        url: "/api/serviceCategories?suggest=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('serviceCategories', data.serviceCategory.sortBy('text'));
        controller.set('filterServiceCategory', null);
      });

      controller.set('suppliers', this.store.find('product-supplier', { filterPurchase: true }));

      if (controller.get('filterMe') === 'true') {
        controller.set('filterDoctor', API['default'].getUserId());
        self.applyFilter();
      } else {
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      }

      if (controller.get('openNew')) {
        setTimeout(function () {
          controller.send('openNewAppointment');
        }, 500);
      }
    },
    actions: {
      downloadAppointmentCsv: function downloadAppointmentCsv() {

        var controller = this.get('controller'),
            dateRange = controller.get('pickDate'),
            dateFromTo = dateRange.split('-'),
            filterDoctor = controller.get('filterDoctor'),
            filterAssistant = controller.get('filterAssistant'),
            filterCustomer = controller.get('filterCustomer'),
            filterPatient = controller.get('filterPatient'),
            filterCategory = controller.get('filterCategory'),
            filterRoom = controller.get('filterRoom'),
            filterResource = controller.get('filterResource'),
            filterStation = controller.get('filterStation');

        var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
            to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

        if (filterPatient) {
          filterPatient = filterPatient.id;
        }
        if (filterCustomer) {
          filterCustomer = filterCustomer.id;
        }

        var appx = "?filterFrom=" + from + "&filterTo=" + to;

        if (filterCustomer) {
          appx += "&filterCustomer=" + filterCustomer;
        }
        if (filterPatient) {
          appx += "&filterPatient=" + filterPatient;
        }
        if (filterDoctor) {
          appx += "&filterDoctor=" + filterDoctor;
        }
        if (filterResource) {
          appx += "&filterResource=" + filterResource;
        }
        if (filterAssistant) {
          appx += "&filterAssistant=" + filterAssistant;
        }
        if (filterRoom) {
          appx += "&filterRoom=" + filterRoom;
        }
        if (filterStation) {
          appx += "&filterStation=" + filterStation;
        }
        if (filterCategory) {
          appx += "&filterCategory=" + filterCategory;
        }

        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: API['default'].getMandant()
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/patientAppointments/download/' + API['default'].getMandant() + '/' + data.responseText + appx);
        });
      },
      reloadPatients: function reloadPatients(customerId) {
        var self = this;
        $.ajax({
          url: "/api/patients/forcustomer/" + customerId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('addAppointmentPanel').send('loadPatients', data.patient);
        });
      },
      selectCustomerConfirm: function selectCustomerConfirm(customerId, data, appointment, time, directTreatment) {
        var self = this,
            treatment = {
          customer: {
            id: customerId
          }
        };

        treatment.selectedPatients = data;
        treatment.appointment = appointment;
        treatment.time = time;

        $.ajax({
          url: "/api/treatments",
          method: "PUT",
          data: JSON.stringify(treatment),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          if (directTreatment) {
            self.transitionTo('practicemgmt.treatment-details', id, { queryParams: { entry: "true" } });
          } else {
            self.transitionTo('practicemgmt.admission');
          }
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      deleteAppointment: function deleteAppointment(id) {
        var controller = this.get('controller'),
            self = this;

        var deferred = $.ajax({
          url: "/api/patientAppointments/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          self.applyFilter();
        }, function (error) {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          self.applyFilter();
        });
        return Ember['default'].RSVP.resolve(deferred);
      },
      createAppointment: function createAppointment(data) {
        var self = this;
        $.ajax({
          url: "/api/patientAppointments",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          self.applyFilter();
        }, function () {});
      },
      updatePartnerCategories: function updatePartnerCategories() {
        var controller = this.controller;
        var selectedSupplier = controller.get('filterSupplier');

        if (selectedSupplier) {
          $.ajax({
            url: "/api/purchaseProducts/partnerCategories/" + selectedSupplier.id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set('partnerCategories', data.partnerCategory);
          });
        }
      },
      queryProducts: function queryProducts(query, deferred) {
        this.store.find('product', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      queryProductsPurchase: function queryProductsPurchase(query, deferred) {
        this.store.find('product', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      queryServices: function queryServices(query, deferred) {
        this.store.find('service', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      queryCustomer: function queryCustomer(query, deferred) {
        this.get('store').find('customer', { filterName: query.term }).then(deferred.resolve, deferred.reject);
      }
    },
    applyFilter: function applyFilter() {

      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-'),
          filterDoctor = controller.get('filterDoctor'),
          filterAssistant = controller.get('filterAssistant'),
          filterCustomer = controller.get('filterCustomer'),
          filterPatient = controller.get('filterPatient'),
          filterCategory = controller.get('filterCategory'),
          filterRoom = controller.get('filterRoom'),
          filterResource = controller.get('filterResource'),
          filterStation = controller.get('filterStation');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      if (filterPatient) {
        filterPatient = filterPatient.id;
      }
      if (filterCustomer) {
        filterCustomer = filterCustomer.id;
      }

      controller.set('to', to);
      controller.set('from', from);
      controller.set('showLoading', true);

      this.findPaged('patient-appointment', {
        filterFrom: from,
        filterTo: to,
        sort: 'asc',
        fromNow: controller.get('fromNow'),
        per_page: 10,
        filterPatient: filterPatient,
        filterCustomer: filterCustomer,
        filterDoctor: filterDoctor,
        filterResource: filterResource,
        filterAssistant: filterAssistant,
        filterCategory: filterCategory,
        filterStation: filterStation,
        filterRoom: filterRoom
      }).then(function (transactions) {
        controller.set('model', transactions);
        controller.set('showLoading', false);
      });
    }
  });

});