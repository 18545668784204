define('jason-frontend/components/treatment-history', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    showservice: false,
    showarticle: false,
    showfeeding: false,
    showlabor: false,
    showTotal: false,
    showHide: false,
    showHideApp: false,
    showPastActions: false,
    showBillingInfo: true,
    ctx: 'treatment',
    showFilter: true,
    showToggleAll: false,
    showToggleAllButton: true,
    loadOverlay: true,
    showChangeUser: true,
    showCopy: false,
    showSlopingDownload: true,
    showEdit: true,
    showAdvancedFilter: false,
    actions: {
      deleteReport: function deleteReport(id, tpId) {
        var self = this;

        $.ajax({
          url: "/api/treatmentReports/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data2) {
          jason.notifiction.notifiy('Behandlungsbericht', 'erfolgreich gelöscht');
          $.ajax({
            url: "/api/treatmentReports/" + tpId + "/all",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.get('treatmentHistoryReportChooser').send('load', data.treatmentReports, tpId);
          });
        });
      },
      undelete: function undelete(id) {
        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/" + id + "/reactivate",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'wiederhergestellt');
          self.sendAction('reload', self.get('showlabor'), self.get('showarticle'), self.get('showservice'), self.get('showfeeding'));
        }, function (error) {});
      },
      toggleQuickfilter: function toggleQuickfilter(filter) {
        if (this.get(filter) === true) {
          this.set(filter, false);
        } else {
          this.set(filter, true);
        }
        this.sendAction('reload', this.get('showlabor'), this.get('showarticle'), this.get('showservice'), this.get('showfeeding'));
      },
      openDigitalForm: function openDigitalForm(id) {
        this.sendAction('openDigitalForm', id);
      },
      gotoReport: function gotoReport(tpid, id) {
        this.sendAction('gotoReport', tpid, id);
      },
      gotoTemplate: function gotoTemplate(tpid, id) {
        this.sendAction('gotoTemplate', tpid, id);
      },
      openReportWizard: function openReportWizard(tpId) {
        var self = this;
        $.ajax({
          url: "/api/treatmentReports/" + tpId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (data.treatmentReport.empty == true) {
            self.get('controller').get('treatmentReportWizzard2').send('load', data.treatmentReport, tpId);

            $.magnificPopup.open({
              removalDelay: 500,
              closeOnBgClick: false,
              items: {
                src: '#modal-treatment-report-wizzard2'
              },
              callbacks: {
                close: function close() {},
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: true
            });
          } else {
            self.transitionTo('editor.edit', tpId, 'treatmentreport', 0);
          }
        });
      },
      openReportPreview: function openReportPreview(tpId, reportId) {
        this.sendAction('gotoReport', tpId, reportId);
      },
      openReportChooser: function openReportChooser(tpId) {

        var self = this;

        $.ajax({
          url: "/api/treatmentReports/" + tpId + "/all",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('treatmentHistoryReportChooser').send('load', data.treatmentReports, tpId);
        });
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-history-report-chooser'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openPast: function openPast(patientId) {

        var self = this;

        $.ajax({
          url: "/api/treatmentFutureActions?patientId=" + patientId + "&filterTime=past",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('pastActionsPanel').send('load', data.treatmentFutureAction);
        }, function (error) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-past-actions-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      downloadBarcode: function downloadBarcode(formId) {
        this.sendAction('downloadBarcode', formId);
      },
      downloadPdf: function downloadPdf(formId) {
        this.sendAction('downloadPdf', formId);
      },
      employeesSaved: function employeesSaved(data) {
        this.sendAction('employeesSaved', data);
      },
      roomSaved: function roomSaved(data) {
        this.sendAction('roomSaved', data);
      },
      forwardToBilling: function forwardToBilling(data) {
        this.sendAction('forwardToBilling', data);
      },
      downloadMedia: function downloadMedia(formId, media) {
        this.sendAction('downloadMedia', formId, media);
      },
      downloadLaboklinBarcode: function downloadLaboklinBarcode(entry) {
        this.sendAction('downloadLaboklinBarcode', entry);
      },
      downloadSlopingBarcode: function downloadSlopingBarcode(entry) {
        this.sendAction('downloadSlopingBarcode', entry);
      },
      openLaboklinChoosePanel: function openLaboklinChoosePanel(entryId, laboklinFormId) {
        this.sendAction('openLaboklinChoosePanel', entryId, laboklinFormId);
      },
      editTreatmentEmployees: function editTreatmentEmployees(item) {
        var self = this;

        this.get('treatmentHistoryEmployees').send('load', item);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#treatment-history-employees-form'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      editTreatmentRoom: function editTreatmentRoom(item) {
        var self = this;

        this.get('treatmentHistoryRooms').send('load', item);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#treatment-history-room-form'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      hide: function hide(id, hidden) {

        var target = hidden ? 'unhide' : 'hide';

        id = id.replace("hide", "");
        id = id.replace("unhide", "");

        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/" + target + "/" + id,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'ausgeblendet');
          self.sendAction('reload');
        });
      },
      hideForApp: function hideForApp(id, hidden) {

        var target = hidden ? 'unhideForApp' : 'hideForApp';

        id = id.replace('appHide', '');

        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/" + target + "/" + id,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'ausgeblendet');
          self.sendAction('reload');
        });
      },
      hideForAppOverride: function hideForAppOverride(id, hidden) {

        var target = hidden ? 'unhideForAppOverride' : 'hideForAppOverride';

        id = id.replace('appHide', '');

        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/" + target + "/" + id,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'ausgeblendet');
          self.sendAction('reload');
        });
      },
      open: function open(id, patient, invoiceId, permissions, owner) {

        var self = this;
        if (id && id > 0) {
          $.ajax({
            url: "/api/patientTreatmentInfos/" + id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.get('getTreatmentInfoForm').send('load', id, patient, 0, data.patientTreatmentInfo, permissions, null, owner);
          }, function (error) {});
        } else {
          $.ajax({
            url: "/api/patientTreatmentInfos/" + patient.id + "/" + invoiceId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.get('getTreatmentInfoForm').send('load', data.id, patient, invoiceId, data.patientTreatmentInfo, permissions, null, true);
          }, function (error) {});
        }

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#treatment-edit-form'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      saveTreatment: function saveTreatment(data) {
        this.sendAction('treatmentSaved', data);
      },
      openSlopingInfos: function openSlopingInfos(data) {
        this.sendAction('openSlopingInfos', data);
      },
      openTemplateEdit: function openTemplateEdit(id, instanceId, readOnly, patientId) {
        this.sendAction('openTemplateEdit', id, instanceId, readOnly, patientId);
      },
      deleteTreatment: function deleteTreatment(id) {
        this.sendAction('deleteTreatment', id);
      },
      filterHistory: function filterHistory(type, value) {
        this.set('show' + type, value);
        this.sendAction('reload', this.get('showlabor'), this.get('showarticle'), this.get('showservice'), this.get('showfeeding'));
      },
      toggleAll: function toggleAll(show) {
        if (show) {
          this.set('showToggleAll', true);
          $("div.table__second-row").removeClass('hidden');
        } else {
          this.set('showToggleAll', false);
          $("div.table__second-row").addClass('hidden');
        }
      },
      toggle: function toggle(entry) {
        var secondRow = $("div.table__cell[data-id=" + entry.id + "]");
        if (secondRow.hasClass('hidden')) {
          secondRow.removeClass('hidden');
        } else {
          secondRow.addClass('hidden');
        }
      },
      copy: function copy(treatmentPatientId, offerId) {
        this.sendAction('copy', offerId);
      }
    },
    watchUsers: (function () {}).observes('filterUser'),
    becomeFocused: (function () {
      this.set('showservice', false);
      this.set('showarticle', false);
      this.set('showfeeding', false);
      this.set('showlabor', false);
    }).on('didInsertElement')
  });

});