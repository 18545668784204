define('jason-frontend/components/edit-warning-add-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    months: null,
    type: null,
    actions: {
      confirm: function confirm(id) {
        var warningInfo = this.get('invoice').get('warningAddText');

        var data = {
          id: id,
          warningAddText: warningInfo
        };

        this.sendAction('confirm', data);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(invoice) {
        this.set('invoice', invoice);
      }
    }
  });

});