define('jason-frontend/controllers/compositeproducts/create', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Anlegen",
    breadCrumbPath: "compositeproducts.create",
    queryParams: ['referer', "type"],
    referer: null,
    createProduct: function createProduct(self, referer) {

      var model = self.get('model');
      var price = model.get('priceExVat');
      if (price) {
        model.set('priceExVat', (price + '').replace(",", "."));
      }

      var product = new Object();
      product.name = model.get('name');
      product.description = model.get('description');
      product.type = this.get('type');

      if (!product.name) {
        product.name = ' ';
      }

      product.favorite = model.get('favorite');

      return $.ajax({
        url: "/api/compositeArticles",
        method: "POST",
        data: JSON.stringify(product),
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        if (referer === 'stay') {
          return data.long;
        } else if (referer === 'sale') {
          self.transitionTo('sale.compositeproducts');
        } else {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          self.transitionTo('compositeproducts.edit', data.long);
        }
      }, function (error) {});
    },
    actions: {
      triggerSubmit: function triggerSubmit() {
        $('form#productEdit').submit();
      },
      addProduct: function addProduct(id) {
        var self = this;
        this.createProduct(this, 'stay').then(function (id) {
          self.transitionTo('cash.add_article', 'compositeAddProduct', id, 0);
        });
      },
      addDoc: function addDoc(id) {
        var self = this;
        this.createProduct(this, 'stay').then(function (id) {});
      },
      addTemplate: function addTemplate(id) {
        var self = this;
        this.createProduct(this, 'stay').then(function (id) {
          self.transitionTo('cash.add_article', 'compositeAddTemplate', id, 0);
        });
      },
      addService: function addService(id) {
        var self = this;
        this.createProduct(this, 'stay').then(function (id) {
          self.transitionTo('cash.add_article', 'compositeAddService', id, 0);
        });
      },
      create: function create(referer) {
        this.createProduct(this, referer);
      }
    }
  });

});