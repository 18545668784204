define('jason-frontend/components/button-future', ['exports', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'top',
    showStroke: false,
    content: '',
    theme: 'tooltipster-punk',
    active: false,
    color: '#3B6182'
  });

});