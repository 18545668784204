define('jason-frontend/models/closing-export', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    code: DS['default'].attr('string'),
    codeId: DS['default'].attr('number'),
    cashRegister: DS['default'].attr('string'),
    from: DS['default'].attr('date'),
    to: DS['default'].attr('date'),
    status: DS['default'].attr('string'),
    details: DS['default'].attr(''),
    request: DS['default'].attr('')
  });

});