define('jason-frontend/templates/components/invoice-discount-input', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/invoice-discount-input.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("<div class=\"input-group discount-spinner\">");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("<input type=\"number\" class=\"input input--editable\"");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n           ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("name=\"global-disocunt\" id=\"global-discount\"");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n           ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("value=\"\" />");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("<button class=\"discount__choice input percentage \"");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n      ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("<span class=\"discount__separator\">/</span>");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("<button class=\"discount__choice input absolute \"");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n      ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("</div>");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","input-group discount-spinner-legacy");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("input");
        dom.setAttribute(el2,"type","number");
        dom.setAttribute(el2,"class","form-control ui-spinner-input");
        dom.setAttribute(el2,"name","global-disocunt");
        dom.setAttribute(el2,"id","global-discount");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [20]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(element0, [5]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element1, 'value');
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createAttrMorph(element2, 'class');
        morphs[3] = dom.createElementMorph(element2);
        morphs[4] = dom.createAttrMorph(element3, 'class');
        morphs[5] = dom.createElementMorph(element3);
        return morphs;
      },
      statements: [
        ["attribute","value",["concat",[["get","invoice.discount",["loc",[null,[15,20],[15,36]]]]]]],
        ["element","action",["changeInvoiceDiscount",["get","invoice.id",["loc",[null,[15,73],[15,83]]]],["get","invoice.absoluteDiscount",["loc",[null,[15,84],[15,108]]]],["get","invoice.discount",["loc",[null,[15,109],[15,125]]]]],["on","focusOut"],["loc",[null,[15,40],[15,141]]]],
        ["attribute","class",["concat",["btn percentage ",["subexpr","get-relative-discount-class",[["get","invoice.absoluteDiscount",["loc",[null,[16,59],[16,83]]]]],[],["loc",[null,[16,29],[16,85]]]]]]],
        ["element","action",["setRelativeDiscountInvoice",["get","invoice.id",["loc",[null,[17,44],[17,54]]]],["get","invoice.absoluteDiscount",["loc",[null,[17,55],[17,79]]]]],[],["loc",[null,[17,6],[17,81]]]],
        ["attribute","class",["concat",["btn absolute ",["subexpr","get-absolute-discount-class",[["get","invoice.absoluteDiscount",["loc",[null,[18,57],[18,81]]]]],[],["loc",[null,[18,27],[18,83]]]]]]],
        ["element","action",["setAbsoluteDiscountInvoice",["get","invoice.id",["loc",[null,[19,44],[19,54]]]],["get","invoice.absoluteDiscount",["loc",[null,[19,55],[19,79]]]]],[],["loc",[null,[19,6],[19,81]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});