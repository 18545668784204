define('jason-frontend/components/icon-terms-of-payment', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'bottom',
    color: '#3B6182',
    strokeColor: '#3B6182',
    showStroke: true,
    timer: 0,
    content: 'Zahlungsvereinbarung',
    theme: 'tooltipster-punk'
  });

});