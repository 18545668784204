define('jason-frontend/components/invoice-add-service', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ['table__row'],
    actions: {
      toggleServiceFavorite: function toggleServiceFavorite(product, add) {
        Ember['default'].set(product, 'favorite', add);
        this.sendAction('toggleServiceFavorite', product.id, add);
      },
      addService: function addService(model, invoiceId, serviceName) {
        var qty = this.$().find("input.spinner-" + model.id).val();

        if (!qty) {
          qty = this.$().find("span.spinn_" + model.id + " > input").val();
        }
        qty = String(qty).replace(',', '.');

        this.sendAction('addService', qty, model.id, invoiceId, serviceName);
      }
    }
  });

});