define('jason-frontend/components/mandant-select-barcode-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    openPrint: function openPrint(id) {
      var viewUrl = '/api/mandants/downloadBarcode/' + id + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken();

      var myWindow = window.open(viewUrl, 'Drucken', 'width=600,height=600');

      var is_chrome = Boolean(window.chrome);
      if (is_chrome) {
        myWindow.onload = function () {

          myWindow.moveTo(0, 0);
          myWindow.resizeTo(640, 480);
          myWindow.print();
          // myWindow.close();
        };
      } else {
          myWindow.print();
          myWindow.close();
        }
    },
    actions: {
      downloadVer: function downloadVer() {
        this.openPrint('ver');
      },
      downloadHor: function downloadHor() {
        this.openPrint('hor');
      },
      downloadVerWithLogo: function downloadVerWithLogo() {
        this.openPrint('ver_logo');
      },
      downloadHorWithLogo: function downloadHorWithLogo() {
        this.openPrint('hor_logo');
      },
      downloadHorWithLogoWithoutDate: function downloadHorWithLogoWithoutDate() {
        this.openPrint('hor_logo_nodate');
      }
    }
  });

});