define('jason-frontend/components/news-toggle', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    becomeFocused: (function () {

      var self = this;

      Ember['default'].$.ajax({
        url: "/api/news",
        method: "GET",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        console.log(data);
        self.set('newsFeed', data);
      });
    }).on('didInsertElement')

  });

});