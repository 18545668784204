define('jason-frontend/routes/register', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model, params) {
      controller.set('showConfirm', false);

      if (params.queryParams.code) {
        controller.set('refCode', params.queryParams.code);
      }

      var hostname = window.location.hostname;

      if (hostname === 'portal.myjason.eu') {
        controller.set('country', 'DE');
      }
    },
    actions: {
      register: function register() {
        var controller = this.get('controller');

        var data = {
          name: controller.get('name'),
          email: controller.get('email'),
          town: controller.get('town'),
          postalCode: controller.get('postalCode'),
          address: controller.get('address'),
          refCode: controller.get('refCode'),
          firstname: controller.get('firstname'),
          lastname: controller.get('lastname'),
          userLastname: controller.get('userLastname'),
          userFirstname: controller.get('userFirstname'),
          hapoNr: controller.get('hapoNr'),
          phone: controller.get('phone')
        };

        if (controller.get('agb') !== true) {
          jason.notifiction.error('Fehler', 'Bitte stimmen Sie den ABGs zu');
          return false;
        }
        if (controller.get('dsgvo') !== true) {
          jason.notifiction.error('Fehler', 'Bitte stimmen Sie der Datenschutzerklärung zu');
          return false;
        }

        var hapoNr = controller.get('hapoNr');

        if (hapoNr && hapoNr.length != 10 && controller.get('country') === 'AT') {
          jason.notifiction.error('Fehler', 'Die HapoNr muss aus 10 Zahlen bestehen, OHNE führendes HA');
          return false;
        }

        controller.set('inProgress', true);

        $.ajax({
          url: "/api/register",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolg', 'Eine Bestätigungs-Email wurde an Sie verschickt');
        }, function (data) {
          controller.set('inProgress', false);

          if (data.responseJSON) {
            var error = data.responseJSON.message;

            if (error === 'invalid_refcode') {
              jason.notifiction.error('Fehler', 'Der eingegebene Referenz Code ist unbekannt');
            }
            if (error === 'email_taken') {
              jason.notifiction.error('Fehler', 'Die angegebene Email-Adresse ist bereits in Verwendung');
            }
            if (error === 'invalid_todate' || error === 'invalid_fromdate') {
              jason.notifiction.error('Fehler', 'Der Referenz Code ist nicht mehr gültig');
            }
          } else {
            jason.notifiction.notifiy('Erfolg', 'Email wurde verschickt');
            controller.set('inProgress', false);
            controller.set('showConfirm', true);
          }
        });
      }
    },

    beforeModel: function beforeModel() {},

    resetController: function resetController(controller) {
      controller.setProperties({
        firstname: null,
        lastname: null,
        hapoNr: null,
        message: null,
        transition: null
      });
    }
  });

});