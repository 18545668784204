define('jason-frontend/controllers/practicemgmt/admission-select-customer', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Kunde auswählen",
    breadCrumbPath: "practicemgmt.admission-select-customer",
    queryParams: ["page", "perPage", "filterName"],

    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    showFilters: true,

    page: 1,
    perPage: 10,

    chooseEtigaPanel2: function chooseEtigaPanel2(id) {

      var self = this;

      $.ajax({
        url: "/api/patients/" + id + "/activateEtiga",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {

        if (data.connected && data.patientId > 0) {
          self.transitionTo('patients.edit', data.patientId);
        } else if (!data.connected && data.patientId == 0 && data.matchCount == 0) {

          self.get('chooseEtigaCustomerPanel').send('load', null, data.master.id, data.customer);

          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-choose-etiga-customer-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        } else {
          self.get('chooseEtigaPanel').send('load', data.matches, data.master.id);

          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-choose-etiga-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }
      }, function (error) {});
    },
    actions: {
      scannedEtigaNumber: function scannedEtigaNumber(id) {

        var self = this;

        $.ajax({
          url: "/api/patients/" + id + "/getClientFromEtiga",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          if (data.found) {

            jason.notifiction.notifiy('Erfolg', 'eltiga Patient erfolgreich erkannt');

            self.get('startTreatmentPanel').send('load', data.customerId, data.patientIds);

            setTimeout(function () {
              $.magnificPopup.open({
                removalDelay: 500,
                closeOnBgClick: false,
                items: {
                  src: '#modal-start-treatment-panel'
                },
                callbacks: {
                  beforeOpen: function beforeOpen() {
                    var Animation = "mfp-with-fade";
                    this.st.mainClass = Animation;
                  }
                },
                midClick: true
              });
            }, 500);
          } else {

            if (data.notShared) {
              setTimeout(function (e) {
                self.chooseEtigaPanel2(id);
              }, 500);
            } else {
              jason.notifiction.error('Nicht erkannt', 'eltiga Patient konnte nicht gefunden werden');
            }
          }
        }, function (error) {});
      },
      quickScan: function quickScan() {
        this.get('quickScanEtiga').send('load');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-etiga-quick-scan-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectCustomer: function selectCustomer(customerId, patients) {

        var self = this;

        if (patients.length == 0) {
          jason.notifiction.error('Fehler', 'Bitte wählen Sie zumindest einen Patienten aus');
          return;
        }

        this.get('startTreatmentPanel').send('load', customerId, patients);

        Ember['default'].$.ajax({
          url: "/api/patients/latestInfos?patientIds=" + patients,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('startTreatmentPanel').send('loadTreatment', data);
        }, function (data) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-start-treatment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openCreateCustomer: function openCreateCustomer(invoiceId) {
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-create-customer-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });

        this.get('createCustomerPanel').send('load');
      },
      addPatient: function addPatient(customerId) {
        $('#createPatientForm').attr('data-customer-id', customerId);

        this.get('createPatientPanel').send('reset');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-create-patient-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    }
  });

});