define('jason-frontend/routes/etigafeed/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.applyFilter();
    },
    actions: {
      setFilterType: function setFilterType(type) {
        var controller = this.get('controller');
        controller.set('filterType', type);
        this.applyFilter();
      },
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      },
      submitFeed: function submitFeed(id) {
        var self = this;
        var controller = this.get('controller');
        $.ajax({
          url: "/api/feed/" + id + "/submit",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('eitrag', 'erfolgreich gespeichert');
          self.applyFilter();
        }, function (error) {});
      },
      approve: function approve(item) {
        var self = this;
        var controller = this.get('controller');
        $.ajax({
          url: "/api/etigaFeeds/" + item.id + "/approve",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {}, function (error) {
          jason.notifiction.notifiy('Beitrag', 'erfolgreich veröffentlicht');
          self.applyFilter();
        });
      },
      'delete': function _delete(id) {
        var self = this;
        $.ajax({
          url: "/api/etigaFeeds/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eintrag', 'erfolgreich gelöscht');
          self.applyFilter();
        }, function (error) {
          jason.notifiction.notifiy('Eintrag', 'erfolgreich gelöscht');
          self.applyFilter();
        });
      },
      save: function save(item) {
        var self = this;
        var controller = this.get('controller');
        $.ajax({
          url: "/api/etiga",
          method: "POST",
          data: JSON.stringify(item),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Feed', 'erfolgreich gespeichert');
          controller.get('editEtigaFeedPanel').send('updateId', data.etigaCampaign.id);
          self.applyFilter();
        }, function (error) {});
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterPatientCategory = controller.get('filterPatientCategory'),
          filterPatientGender = controller.get('filterGender'),
          filterAge = controller.get('filterAge'),
          filterAgeTo = controller.get('filterAgeTo'),
          filterName = controller.get('filterName'),
          filterType = controller.get('filterType'),
          self = this;

      controller.set('showLoading', true);

      this.findPaged('etigaFeed', {
        filterName: filterName,
        filterType: filterType,
        per_page: 10
      }).then(function (data) {
        var meta = data.meta;
        controller.set('model', data);
        controller.set('showLoading', false);
      });
    }
  });

});