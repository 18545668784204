define('jason-frontend/templates/components/show-task-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 40
            },
            "end": {
              "line": 33,
              "column": 142
            }
          },
          "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" seit ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.startedAt",["loc",[null,[33,105],[33,119]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[33,91],[33,142]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 36,
              "column": 12
            },
            "end": {
              "line": 43,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class"," col l-12/24");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field col l-12/24");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","taskCustomer");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Kunde/in");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","cursor is-highlighted");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element24 = dom.childAt(fragment, [1, 1, 3]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element24);
          morphs[1] = dom.createMorphAt(element24,0,0);
          morphs[2] = dom.createMorphAt(element24,2,2);
          return morphs;
        },
        statements: [
          ["element","action",["toCustomer",["get","item.customer.id",["loc",[null,[40,71],[40,87]]]]],[],["loc",[null,[40,49],[40,89]]]],
          ["content","item.customer.firstname",["loc",[null,[40,90],[40,117]]]],
          ["content","item.customer.lastname",["loc",[null,[40,118],[40,144]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 44,
              "column": 15
            },
            "end": {
              "line": 49,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-11/24");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field col l-12/24");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","taskPatient");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Patient");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","cursor is-highlighted");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element23 = dom.childAt(fragment, [0, 0, 3]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element23);
          morphs[1] = dom.createMorphAt(element23,0,0);
          return morphs;
        },
        statements: [
          ["element","action",["toPatient",["get","item.patient.id",["loc",[null,[46,66],[46,81]]]]],[],["loc",[null,[46,45],[46,83]]]],
          ["content","item.patient.name",["loc",[null,[46,84],[46,105]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 57,
              "column": 22
            },
            "end": {
              "line": 62,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class"," col l-12/24");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field col l-12/24 field-datepicker");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","nextExecution");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Erledigt am");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]),3,3);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.doneAt",["loc",[null,[60,28],[60,39]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[60,14],[60,62]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 66,
              "column": 14
            },
            "end": {
              "line": 68,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["content","item.user.firstname",["loc",[null,[67,16],[67,39]]]],
          ["content","item.user.lastname",["loc",[null,[67,40],[67,62]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 68,
              "column": 14
            },
            "end": {
              "line": 70,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                Alle\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 75,
              "column": 16
            },
            "end": {
              "line": 77,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["content","item.delegatedBy.firstname",["loc",[null,[76,18],[76,48]]]],
          ["content","item.delegatedBy.lastname",["loc",[null,[76,49],[76,78]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 103,
                "column": 22
              },
              "end": {
                "line": 103,
                "column": 155
              }
            },
            "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","is-highlighted cursor");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element21 = dom.childAt(fragment, [0]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element21);
            morphs[1] = dom.createMorphAt(element21,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["selectTab","chat"],[],["loc",[null,[103,78],[103,107]]]],
            ["content","item.text",["loc",[null,[103,138],[103,151]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 103,
                  "column": 155
                },
                "end": {
                  "line": 103,
                  "column": 356
                }
              },
              "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","is-highlighted cursor");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element20 = dom.childAt(fragment, [0]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element20);
              morphs[1] = dom.createMorphAt(element20,0,0);
              return morphs;
            },
            statements: [
              ["element","action",["goto","patienttreatments"],[],["loc",[null,[103,271],[103,308]]]],
              ["content","item.text",["loc",[null,[103,339],[103,352]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 103,
                    "column": 356
                  },
                  "end": {
                    "line": 103,
                    "column": 502
                  }
                },
                "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","is-highlighted cursor");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element19 = dom.childAt(fragment, [0]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element19);
                morphs[1] = dom.createMorphAt(element19,0,0);
                return morphs;
              },
              statements: [
                ["element","action",["goto","admission"],[],["loc",[null,[103,425],[103,454]]]],
                ["content","item.text",["loc",[null,[103,485],[103,498]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 103,
                    "column": 502
                  },
                  "end": {
                    "line": 103,
                    "column": 523
                  }
                },
                "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.text",["loc",[null,[103,510],[103,523]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 103,
                  "column": 356
                },
                "end": {
                  "line": 103,
                  "column": 523
                }
              },
              "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.text",["loc",[null,[103,370],[103,379]]]],"Kostenpflichtige Behandlung gestartet"],[],["loc",[null,[103,366],[103,420]]]]],[],0,1,["loc",[null,[103,356],[103,523]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 103,
                "column": 155
              },
              "end": {
                "line": 103,
                "column": 523
              }
            },
            "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","or",[["subexpr","eq",[["get","item.text",["loc",[null,[103,173],[103,182]]]],"Kostenlose Behandlung gestartet"],[],["loc",[null,[103,169],[103,217]]]],["subexpr","eq",[["get","item.text",["loc",[null,[103,222],[103,231]]]],"Kostenlose Behandlung angelegt"],[],["loc",[null,[103,218],[103,265]]]]],[],["loc",[null,[103,165],[103,266]]]]],[],0,1,["loc",[null,[103,155],[103,523]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 95,
              "column": 14
            },
            "end": {
              "line": 105,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-16/24 l-10/24@tablet l-4/24@desk overflow");
          dom.setAttribute(el1,"data-label","Datum");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-8/24 l-4/24@tablet l-6/24@desk overflow");
          dom.setAttribute(el1,"data-label","MitarbeiterIn");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-8/24 l-4/24@tablet l-14/24@desk overflow ");
          dom.setAttribute(el1,"data-label","Aktion");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element22 = dom.childAt(fragment, [3, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
          morphs[1] = dom.createMorphAt(element22,0,0);
          morphs[2] = dom.createMorphAt(element22,2,2);
          morphs[3] = dom.createMorphAt(dom.childAt(fragment, [5, 1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.created",["loc",[null,[97,43],[97,55]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[97,29],[97,78]]]],
          ["content","item.user.firstname",["loc",[null,[100,28],[100,51]]]],
          ["content","item.user.lastname",["loc",[null,[100,52],[100,74]]]],
          ["block","if",[["subexpr","eq",[["get","item.text",["loc",[null,[103,32],[103,41]]]],"Hat einen Kommentar verfasst"],[],["loc",[null,[103,28],[103,73]]]]],[],0,1,["loc",[null,[103,22],[103,530]]]]
        ],
        locals: ["item"],
        templates: [child0, child1]
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 110,
              "column": 10
            },
            "end": {
              "line": 112,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[111,12],[111,33]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 116,
                  "column": 38
                },
                "end": {
                  "line": 116,
                  "column": 169
                }
              },
              "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-myjason",[],["content","","color","#008EC6","cssClass","inverted","classNames","inline-icon"],["loc",[null,[116,78],[116,169]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 116,
                  "column": 176
                },
                "end": {
                  "line": 116,
                  "column": 282
                }
              },
              "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-lab",[],["content","","cssClass","inverted","classNames","inline-icon"],["loc",[null,[116,211],[116,282]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 116,
                  "column": 289
                },
                "end": {
                  "line": 116,
                  "column": 404
                }
              },
              "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-invoice",[],["content","","cssClass","inverted","classNames","inline-icon"],["loc",[null,[116,328],[116,404]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 116,
                  "column": 411
                },
                "end": {
                  "line": 116,
                  "column": 525
                }
              },
              "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-patient",[],["content","","classNames","inline-icon","cssClass","inverted"],["loc",[null,[116,451],[116,525]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 123,
                  "column": 22
                },
                "end": {
                  "line": 125,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","cloudinary-tag",[["get","message.sender.picture.cdnId",["loc",[null,[124,41],[124,69]]]]],["crop","fit","class","img-responsive mw50 ib"],["loc",[null,[124,24],[124,113]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 125,
                  "column": 22
                },
                "end": {
                  "line": 127,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"class","img-responsive mw50 ib");
              dom.setAttribute(el1,"title","user");
              dom.setAttribute(el1,"src","assets/images/users/default.png");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child6 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 131,
                  "column": 24
                },
                "end": {
                  "line": 132,
                  "column": 98
                }
              },
              "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("von: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element11,1,1);
              morphs[1] = dom.createMorphAt(element11,3,3);
              return morphs;
            },
            statements: [
              ["content","message.sender.firstname",["loc",[null,[132,36],[132,64]]]],
              ["content","message.sender.lastname",["loc",[null,[132,65],[132,92]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child7 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 135,
                    "column": 35
                  },
                  "end": {
                    "line": 135,
                    "column": 129
                  }
                },
                "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","message.patient.name",["loc",[null,[135,105],[135,129]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 136,
                    "column": 38
                  },
                  "end": {
                    "line": 136,
                    "column": 170
                  }
                },
                "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","message.customer.firstname",["loc",[null,[136,110],[136,140]]]],
                ["content","message.customer.lastname",["loc",[null,[136,141],[136,170]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 133,
                  "column": 22
                },
                "end": {
                  "line": 137,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                          Patient: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(",\n                          BesitzerIn: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element10 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element10,1,1);
              morphs[1] = dom.createMorphAt(element10,3,3);
              return morphs;
            },
            statements: [
              ["block","link-to",["patients.edit",["get","message.patient.id",["loc",[null,[135,62],[135,80]]]]],["class","is-highlighted"],0,null,["loc",[null,[135,35],[135,141]]]],
              ["block","link-to",["customers.edit",["get","message.customer.id",["loc",[null,[136,66],[136,85]]]]],["class","is-highlighted"],1,null,["loc",[null,[136,38],[136,182]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child8 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 143,
                      "column": 66
                    },
                    "end": {
                      "line": 143,
                      "column": 104
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("von ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.readBy",["loc",[null,[143,89],[143,104]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 143,
                      "column": 112
                    },
                    "end": {
                      "line": 143,
                      "column": 182
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("am ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","time-format",[["get","item.readOn",["loc",[null,[143,148],[143,159]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[143,134],[143,182]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 143,
                    "column": 22
                  },
                  "end": {
                    "line": 143,
                    "column": 189
                  }
                },
                "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("gelesen ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.readby",["loc",[null,[143,72],[143,83]]]]],[],0,null,["loc",[null,[143,66],[143,111]]]],
                ["block","if",[["get","item.readOn",["loc",[null,[143,118],[143,129]]]]],[],1,null,["loc",[null,[143,112],[143,189]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 141,
                  "column": 18
                },
                "end": {
                  "line": 145,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","readInfo small italic");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","or",[["get","item.readby",["loc",[null,[143,32],[143,43]]]],["get","item.readOn",["loc",[null,[143,44],[143,55]]]]],[],["loc",[null,[143,28],[143,56]]]]],[],0,null,["loc",[null,[143,22],[143,196]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child9 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 152,
                      "column": 24
                    },
                    "end": {
                      "line": 155,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("zum\n                          Untersuchungsauftrag");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[153,26],[153,108]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 151,
                    "column": 22
                  },
                  "end": {
                    "line": 156,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["patients.edit",["get","message.referenceId",["loc",[null,[152,51],[152,70]]]],["subexpr","query-params",[],["selectTab","lab"],["loc",[null,[152,71],[152,101]]]]],["title","Patient anzeigen"],0,null,["loc",[null,[152,24],[155,36]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 158,
                      "column": 24
                    },
                    "end": {
                      "line": 161,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("zum\n                          Patienten");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[159,26],[159,108]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 157,
                    "column": 22
                  },
                  "end": {
                    "line": 162,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["patients.edit",["get","message.referenceId",["loc",[null,[158,51],[158,70]]]],["subexpr","query-params",[],["selectTab","history"],["loc",[null,[158,71],[158,105]]]]],["title","Patient anzeigen"],0,null,["loc",[null,[158,24],[161,36]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 164,
                      "column": 24
                    },
                    "end": {
                      "line": 167,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("zur\n                          Rechnung");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[165,26],[165,108]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 163,
                    "column": 22
                  },
                  "end": {
                    "line": 168,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["invoices.show",["get","message.referenceId",["loc",[null,[164,51],[164,70]]]]],["title","Rechnung anzeigen"],0,null,["loc",[null,[164,24],[167,36]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 170,
                      "column": 24
                    },
                    "end": {
                      "line": 173,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("zur\n                          Bestellung");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[171,26],[171,108]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 169,
                    "column": 22
                  },
                  "end": {
                    "line": 174,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["purchases.show",["get","message.referenceId",["loc",[null,[170,52],[170,71]]]]],["title","Bestellung anzeigen"],0,null,["loc",[null,[170,24],[173,36]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 149,
                  "column": 20
                },
                "end": {
                  "line": 175,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(fragment,4,4,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,5,5,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,6,6,contextualElement);
              morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","message.referenceType",["loc",[null,[151,32],[151,53]]]],"treatment"],[],["loc",[null,[151,28],[151,66]]]]],[],0,null,["loc",[null,[151,22],[156,29]]]],
              ["block","if",[["subexpr","eq",[["get","message.referenceType",["loc",[null,[157,32],[157,53]]]],"patient"],[],["loc",[null,[157,28],[157,64]]]]],[],1,null,["loc",[null,[157,22],[162,29]]]],
              ["block","if",[["subexpr","eq",[["get","message.referenceType",["loc",[null,[163,32],[163,53]]]],"invoice"],[],["loc",[null,[163,28],[163,64]]]]],[],2,null,["loc",[null,[163,22],[168,29]]]],
              ["block","if",[["subexpr","eq",[["get","message.referenceType",["loc",[null,[169,32],[169,53]]]],"purchase"],[],["loc",[null,[169,28],[169,65]]]]],[],3,null,["loc",[null,[169,22],[174,29]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }());
        var child10 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 186,
                    "column": 24
                  },
                  "end": {
                    "line": 188,
                    "column": 24
                  }
                },
                "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","cloudinary-tag",[["get","reply.sender.picture.cdnId",["loc",[null,[187,43],[187,69]]]]],["crop","fit","class","img-responsive mw50 ib"],["loc",[null,[187,26],[187,113]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 188,
                    "column": 24
                  },
                  "end": {
                    "line": 190,
                    "column": 24
                  }
                },
                "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"class","img-responsive mw50 ib");
                dom.setAttribute(el1,"title","user");
                dom.setAttribute(el1,"src","assets/images/users/default.png");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 180,
                  "column": 12
                },
                "end": {
                  "line": 201,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","messageContainer reply");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","message");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","innerWrapper");
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","col l-24/24 mt10 mb10");
              var el5 = dom.createTextNode("\n                      ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","image");
              var el6 = dom.createTextNode("\n");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("                      ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                      ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","headline");
              var el6 = dom.createTextNode("\n                        ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("div");
              dom.setAttribute(el6,"class","like-h3");
              var el7 = dom.createTextNode("Antwort von ");
              dom.appendChild(el6, el7);
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                        ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                      ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","date");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1, 1, 1]);
              var element8 = dom.childAt(element7, [1]);
              var element9 = dom.childAt(element8, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]),1,1);
              morphs[1] = dom.createMorphAt(dom.childAt(element9, [1]),1,1);
              morphs[2] = dom.createUnsafeMorphAt(element9,3,3);
              morphs[3] = dom.createMorphAt(dom.childAt(element7, [3]),0,0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","reply.sender.picture",["loc",[null,[186,30],[186,50]]]]],[],0,1,["loc",[null,[186,24],[190,31]]]],
              ["content","reply.sender.shortName",["loc",[null,[193,57],[193,83]]]],
              ["content","reply.comment",["loc",[null,[194,24],[194,43]]]],
              ["inline","time-format",[["get","reply.created",["loc",[null,[197,52],[197,65]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[197,38],[197,88]]]]
            ],
            locals: ["reply"],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 113,
                "column": 10
              },
              "end": {
                "line": 212,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","messageContainer");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","message");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","category");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","innerWrapper");
            var el4 = dom.createTextNode("\n\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-24/24 mt10 mb10");
            var el5 = dom.createTextNode("\n\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","image");
            var el6 = dom.createTextNode("\n");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("                    ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","headline");
            var el6 = dom.createTextNode("\n                      ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("div");
            dom.setAttribute(el6,"class","like-h2");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("                    ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","date");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("p");
            dom.setAttribute(el4,"class","mt30 mb0");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("form");
            var el2 = dom.createTextNode("\n\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","panel-body p25");
            var el3 = dom.createTextNode("\n\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","field");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","comment");
            dom.setAttribute(el4,"class","field-label title");
            var el5 = dom.createTextNode("Antwort verfassen");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1, 1]);
            var element13 = dom.childAt(element12, [1]);
            var element14 = dom.childAt(element12, [3]);
            var element15 = dom.childAt(element14, [1]);
            var element16 = dom.childAt(element15, [3]);
            var element17 = dom.childAt(element14, [7]);
            var element18 = dom.childAt(fragment, [5]);
            var morphs = new Array(16);
            morphs[0] = dom.createMorphAt(element13,0,0);
            morphs[1] = dom.createMorphAt(element13,1,1);
            morphs[2] = dom.createMorphAt(element13,2,2);
            morphs[3] = dom.createMorphAt(element13,3,3);
            morphs[4] = dom.createMorphAt(dom.childAt(element13, [5]),0,0);
            morphs[5] = dom.createMorphAt(dom.childAt(element15, [1]),1,1);
            morphs[6] = dom.createMorphAt(dom.childAt(element16, [1]),0,0);
            morphs[7] = dom.createMorphAt(element16,3,3);
            morphs[8] = dom.createMorphAt(element16,5,5);
            morphs[9] = dom.createMorphAt(element14,3,3);
            morphs[10] = dom.createMorphAt(dom.childAt(element14, [5]),0,0);
            morphs[11] = dom.createUnsafeMorphAt(element17,1,1);
            morphs[12] = dom.createMorphAt(element17,3,3);
            morphs[13] = dom.createMorphAt(fragment,3,3,contextualElement);
            morphs[14] = dom.createElementMorph(element18);
            morphs[15] = dom.createMorphAt(dom.childAt(element18, [1, 1]),3,3);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","message.category.id",["loc",[null,[116,48],[116,67]]]],"jason"],[],["loc",[null,[116,44],[116,76]]]]],[],0,null,["loc",[null,[116,38],[116,176]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[116,186],[116,202]]]],"lab"],[],["loc",[null,[116,182],[116,209]]]]],[],1,null,["loc",[null,[116,176],[116,289]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[116,299],[116,315]]]],"invoice"],[],["loc",[null,[116,295],[116,326]]]]],[],2,null,["loc",[null,[116,289],[116,411]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[116,421],[116,437]]]],"practice"],[],["loc",[null,[116,417],[116,449]]]]],[],3,null,["loc",[null,[116,411],[116,532]]]],
            ["content","message.category.name",["loc",[null,[117,24],[117,49]]]],
            ["block","if",[["get","message.sender.picture",["loc",[null,[123,28],[123,50]]]]],[],4,5,["loc",[null,[123,22],[127,29]]]],
            ["content","message.title",["loc",[null,[130,43],[130,60]]]],
            ["block","if",[["get","message.sender",["loc",[null,[131,30],[131,44]]]]],[],6,null,["loc",[null,[131,24],[132,105]]]],
            ["block","if",[["get","item.patient",["loc",[null,[133,28],[133,40]]]]],[],7,null,["loc",[null,[133,22],[137,29]]]],
            ["block","if",[["subexpr","not",[["get","message.unread",["loc",[null,[141,29],[141,43]]]]],[],["loc",[null,[141,24],[141,44]]]]],[],8,null,["loc",[null,[141,18],[145,25]]]],
            ["inline","time-format",[["get","message.created",["loc",[null,[146,50],[146,65]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[146,36],[146,88]]]],
            ["content","message.message",["loc",[null,[148,20],[148,41]]]],
            ["block","if",[["get","message.referenceId",["loc",[null,[149,26],[149,45]]]]],[],9,null,["loc",[null,[149,20],[175,27]]]],
            ["block","each",[["get","message.replies",["loc",[null,[180,20],[180,35]]]]],[],10,null,["loc",[null,[180,12],[201,21]]]],
            ["element","action",["postReply",["get","message",["loc",[null,[202,37],[202,44]]]]],["on","submit"],["loc",[null,[202,16],[202,58]]]],
            ["inline","textarea",[],["rows",5,"value",["subexpr","@mut",[["get","comment",["loc",[null,[208,40],[208,47]]]]],[],[]],"class","input textarea__input full","name","comment","autocomplete","off"],["loc",[null,[208,16],[208,118]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 220,
                    "column": 60
                  },
                  "end": {
                    "line": 220,
                    "column": 146
                  }
                },
                "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(", ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.delegatedBy.firstname",["loc",[null,[220,86],[220,116]]]],
                ["content","item.delegatedBy.lastname",["loc",[null,[220,117],[220,146]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 219,
                  "column": 12
                },
                "end": {
                  "line": 221,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
              return morphs;
            },
            statements: [
              ["content","item.user.firstname",["loc",[null,[220,14],[220,37]]]],
              ["content","item.user.lastname",["loc",[null,[220,38],[220,60]]]],
              ["block","if",[["get","item.delegatedBy",["loc",[null,[220,66],[220,82]]]]],[],0,null,["loc",[null,[220,60],[220,153]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 221,
                  "column": 12
                },
                "end": {
                  "line": 223,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              Alle\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 212,
                "column": 10
              },
              "end": {
                "line": 232,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("form");
            var el2 = dom.createTextNode("\n\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","panel-body p25");
            var el3 = dom.createTextNode("\n\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","field col l-24/24");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","mail-to");
            dom.setAttribute(el4,"class","field-label title");
            var el5 = dom.createTextNode("Nachricht an");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","field");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","comment");
            dom.setAttribute(el4,"class","field-label title");
            var el5 = dom.createTextNode("Nachricht verfassen");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var element6 = dom.childAt(element5, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element5);
            morphs[1] = dom.createMorphAt(dom.childAt(element6, [1]),3,3);
            morphs[2] = dom.createMorphAt(dom.childAt(element6, [3]),3,3);
            return morphs;
          },
          statements: [
            ["element","action",["addMessage",["get","message",["loc",[null,[213,40],[213,47]]]]],["on","submit"],["loc",[null,[213,18],[213,61]]]],
            ["block","if",[["get","item.user",["loc",[null,[219,18],[219,27]]]]],[],0,1,["loc",[null,[219,12],[223,19]]]],
            ["inline","textarea",[],["rows",5,"value",["subexpr","@mut",[["get","comment",["loc",[null,[228,42],[228,49]]]]],[],[]],"class","input textarea__input full","name","comment","autocomplete","off"],["loc",[null,[228,18],[228,120]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 112,
              "column": 10
            },
            "end": {
              "line": 233,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","message",["loc",[null,[113,16],[113,23]]]]],[],0,1,["loc",[null,[113,10],[232,17]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 241,
              "column": 6
            },
            "end": {
              "line": 245,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--small");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element4);
          morphs[1] = dom.createMorphAt(element4,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["changeOwner",["get","item",["loc",[null,[242,71],[242,75]]]]],[],["loc",[null,[242,48],[242,77]]]],
          ["inline","button-change-user",[],["color","#fff","size",40,"showStroke",false,"content","Verantwortliche(n) wechseln"],["loc",[null,[243,8],[243,106]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 247,
              "column": 6
            },
            "end": {
              "line": 251,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--small");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element3);
          morphs[1] = dom.createMorphAt(element3,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["startTask",["get","item",["loc",[null,[248,71],[248,75]]]]],[],["loc",[null,[248,50],[248,77]]]],
          ["inline","button-play",[],["size",40,"color","#fff","content","Mit Arbeit beginnen","showStroke",false,"classNames","ml5"],["loc",[null,[249,10],[249,110]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 253,
              "column": 6
            },
            "end": {
              "line": 257,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--small");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createMorphAt(element2,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["finishTask",["get","item",["loc",[null,[254,72],[254,76]]]]],[],["loc",[null,[254,50],[254,78]]]],
          ["inline","button-checked",[],["color","#fff","size",40,"showStroke",false,"content","Aufgabe abschließen","classNames","ml10 mb10"],["loc",[null,[255,10],[255,120]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 260,
                "column": 8
              },
              "end": {
                "line": 264,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button-small icon-button--success");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element1);
            morphs[1] = dom.createMorphAt(element1,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["postReply",["get","item",["loc",[null,[261,93],[261,97]]]]],[],["loc",[null,[261,72],[261,99]]]],
            ["inline","button-send-mail",[],["size","52","color","#ffffff","content","Antwort senden"],["loc",[null,[262,12],[262,83]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 264,
                "column": 8
              },
              "end": {
                "line": 268,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button-small icon-button--success");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["addMessage",["get","item",["loc",[null,[265,94],[265,98]]]]],[],["loc",[null,[265,72],[265,100]]]],
            ["inline","button-send-mail",[],["size","52","color","#ffffff","content","Nachricht senden"],["loc",[null,[266,12],[266,85]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 259,
              "column": 6
            },
            "end": {
              "line": 269,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","message",["loc",[null,[260,14],[260,21]]]]],[],0,1,["loc",[null,[260,8],[268,15]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 274,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/show-task-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-show-task-panel");
        dom.setAttribute(el1,"class","popup-basic  popup-very-large  admin-form mfp-with-anim mfp-hide small--heading");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n              ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("\n                Aufgabendetails");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","js-tabs tabs mr20 ml20 pt20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tabs__head");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5,"class","u-mb0 clearfix");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"href","#tab-show");
        var el8 = dom.createTextNode("Aufgabendetails");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"href","#tab-chat");
        var el8 = dom.createTextNode("Konversation");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"class","tabs__body mt20");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-show");
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","panel-body p25");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class"," col l-12/24");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","new-not-title");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Aufgabe");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class"," col l-12/24");
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field col l-12/24");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","new-not-title");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Status");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class"," col l-12/24");
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field col l-12/24 field-datepicker");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","nextExecution");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Fällig am");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class"," col l-12/24");
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field col l-12/24 field-datepicker");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","nextExecution");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Dauer");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n             ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" Minuten\n          ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class"," col l-12/24");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","taskComment");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Verantwortlich");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class"," col l-12/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","taskComment");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Delegiert von");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class"," col l-24/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field col l-24/24");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","taskComment");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Notiz");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table table--small box no-box@tablet no-box@phone table--treatment-history");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__head");
        var el8 = dom.createComment("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-4/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Datum");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-6/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("MitarbeiterIn");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-14/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Aktion");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__body");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-chat");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-footer text-right");
        var el4 = dom.createTextNode("\n\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element25 = dom.childAt(fragment, [0, 1]);
        var element26 = dom.childAt(element25, [3]);
        var element27 = dom.childAt(element26, [1, 1]);
        var element28 = dom.childAt(element27, [1, 1]);
        var element29 = dom.childAt(element27, [3, 1]);
        var element30 = dom.childAt(element26, [3]);
        var element31 = dom.childAt(element30, [1]);
        var element32 = dom.childAt(element31, [1]);
        var element33 = dom.childAt(element32, [3, 0]);
        var element34 = dom.childAt(element30, [3]);
        var element35 = dom.childAt(element25, [5]);
        var morphs = new Array(23);
        morphs[0] = dom.createAttrMorph(element28, 'class');
        morphs[1] = dom.createElementMorph(element28);
        morphs[2] = dom.createAttrMorph(element29, 'class');
        morphs[3] = dom.createElementMorph(element29);
        morphs[4] = dom.createAttrMorph(element31, 'class');
        morphs[5] = dom.createMorphAt(dom.childAt(element32, [1, 1]),3,3);
        morphs[6] = dom.createMorphAt(element33,3,3);
        morphs[7] = dom.createMorphAt(element33,4,4);
        morphs[8] = dom.createMorphAt(element32,5,5);
        morphs[9] = dom.createMorphAt(element32,7,7);
        morphs[10] = dom.createMorphAt(dom.childAt(element32, [9, 0]),3,3);
        morphs[11] = dom.createMorphAt(dom.childAt(element32, [11, 0]),3,3);
        morphs[12] = dom.createMorphAt(element32,12,12);
        morphs[13] = dom.createMorphAt(dom.childAt(element32, [14, 1]),3,3);
        morphs[14] = dom.createMorphAt(dom.childAt(element32, [16, 1]),3,3);
        morphs[15] = dom.createMorphAt(dom.childAt(element32, [18, 1]),3,3);
        morphs[16] = dom.createMorphAt(dom.childAt(element31, [3, 3]),1,1);
        morphs[17] = dom.createAttrMorph(element34, 'class');
        morphs[18] = dom.createMorphAt(element34,1,1);
        morphs[19] = dom.createMorphAt(element35,1,1);
        morphs[20] = dom.createMorphAt(element35,3,3);
        morphs[21] = dom.createMorphAt(element35,5,5);
        morphs[22] = dom.createMorphAt(element35,7,7);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[13,80],[13,89]]]],"show"],[],["loc",[null,[13,76],[13,97]]]],"is-active",""],[],["loc",[null,[13,55],[13,114]]]]]]],
        ["element","action",["selectTab","show"],[],["loc",[null,[13,133],[13,162]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[16,80],[16,89]]]],"chat"],[],["loc",[null,[16,76],[16,97]]]],"is-active",""],[],["loc",[null,[16,55],[16,114]]]]]]],
        ["element","action",["selectTab","chat"],[],["loc",[null,[16,133],[16,162]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[22,58],[22,67]]]],"show"],[],["loc",[null,[22,54],[22,75]]]],"is-active",""],[],["loc",[null,[22,33],[22,92]]]]]]],
        ["content","item.title",["loc",[null,[28,14],[28,28]]]],
        ["content","item.taskStatus.name",["loc",[null,[33,16],[33,40]]]],
        ["block","if",[["subexpr","eq",[["get","item.taskStatus.id",["loc",[null,[33,50],[33,68]]]],"in_progress"],[],["loc",[null,[33,46],[33,83]]]]],[],0,null,["loc",[null,[33,40],[33,149]]]],
        ["block","if",[["get","item.customer",["loc",[null,[36,18],[36,31]]]]],[],1,null,["loc",[null,[36,12],[43,19]]]],
        ["block","if",[["get","item.patient",["loc",[null,[44,21],[44,33]]]]],[],2,null,["loc",[null,[44,15],[49,17]]]],
        ["inline","time-format",[["get","item.due",["loc",[null,[52,28],[52,36]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[52,14],[52,59]]]],
        ["content","item.duration",["loc",[null,[56,13],[56,30]]]],
        ["block","if",[["get","item.doneAt",["loc",[null,[57,28],[57,39]]]]],[],3,null,["loc",[null,[57,22],[62,25]]]],
        ["block","if",[["get","item.user",["loc",[null,[66,20],[66,29]]]]],[],4,5,["loc",[null,[66,14],[70,21]]]],
        ["block","if",[["get","item.delegatedBy",["loc",[null,[75,22],[75,38]]]]],[],6,null,["loc",[null,[75,16],[77,23]]]],
        ["content","item.notice",["loc",[null,[83,16],[83,31]]]],
        ["block","each",[["get","history",["loc",[null,[95,22],[95,29]]]]],[],7,null,["loc",[null,[95,14],[105,23]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[109,58],[109,67]]]],"chat"],[],["loc",[null,[109,54],[109,75]]]],"is-active",""],[],["loc",[null,[109,33],[109,92]]]]]]],
        ["block","if",[["get","showLoading",["loc",[null,[110,16],[110,27]]]]],[],8,9,["loc",[null,[110,10],[233,17]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[241,16],[241,25]]]],"show"],[],["loc",[null,[241,12],[241,33]]]]],[],10,null,["loc",[null,[241,6],[245,13]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","selectTab",["loc",[null,[247,21],[247,30]]]],"show"],[],["loc",[null,[247,17],[247,38]]]],["subexpr","eq",[["get","item.taskStatus.id",["loc",[null,[247,43],[247,61]]]],"open"],[],["loc",[null,[247,39],[247,69]]]]],[],["loc",[null,[247,12],[247,70]]]]],[],11,null,["loc",[null,[247,6],[251,13]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","selectTab",["loc",[null,[253,21],[253,30]]]],"show"],[],["loc",[null,[253,17],[253,38]]]],["get","item.editable",["loc",[null,[253,39],[253,52]]]],["subexpr","eq",[["get","item.taskStatus.id",["loc",[null,[253,57],[253,75]]]],"in_progress"],[],["loc",[null,[253,53],[253,90]]]]],[],["loc",[null,[253,12],[253,91]]]]],[],12,null,["loc",[null,[253,6],[257,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[259,16],[259,25]]]],"chat"],[],["loc",[null,[259,12],[259,33]]]]],[],13,null,["loc",[null,[259,6],[269,13]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13]
    };
  }()));

});