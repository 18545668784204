define('jason-frontend/helpers/positive', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getPositive = getPositive;

  function getPositive(params) {
    var value = params[0];
    if (value >= 0) {
      return value;
    } else {
      return -1 * value;
    }
  }

  exports['default'] = Ember['default'].Helper.helper(getPositive);

});