define('jason-frontend/components/button-private', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    cssClass: 'icon-button--unselected',
    tagName: 'a',
    size: 32,
    side: 'top',
    timer: 0,
    content: 'Privatbestellung ein/aus',
    theme: 'tooltipster-punk',
    fireAction: true,
    actions: {
      toggle: function toggle() {
        var el = this.get('element'),
            $btn = $('.btn', el);

        if (this.get('cssClass') === 'icon-button--unselected') {
          this.set('cssClass', '');
          this.set('selected', 'true');
          if (this.get('fireAction')) {
            this.sendAction('toggle', true);
          }
        } else {
          this.set('cssClass', 'icon-button--unselected');
          this.set('selected', 'false');
          if (this.get('fireAction')) {
            this.sendAction('toggle', false);
          }
        }
      }
    }
  });

});