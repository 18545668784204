define('jason-frontend/templates/components/choose-existing-etiga-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 26
                },
                "end": {
                  "line": 34,
                  "column": 211
                }
              },
              "moduleName": "jason-frontend/templates/components/choose-existing-etiga-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","animal-icon",[],["evil",["subexpr","@mut",[["get","patient.evil",["loc",[null,[34,69],[34,81]]]]],[],[]],"classNames","inline-icon","animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[34,117],[34,136]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[34,145],[34,169]]]]],[],[]],"size","32","content",["subexpr","@mut",[["get","patient.category.name",["loc",[null,[34,188],[34,209]]]]],[],[]]],["loc",[null,[34,50],[34,211]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 43
                },
                "end": {
                  "line": 37,
                  "column": 165
                }
              },
              "moduleName": "jason-frontend/templates/components/choose-existing-etiga-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-etiga-small",[],["content","","classNames","ml5 vertical-top inline-block","size","18","width",34],["loc",[null,[37,70],[37,165]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 16
              },
              "end": {
                "line": 56,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/choose-existing-etiga-panel.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24@desk");
            dom.setAttribute(el2,"data-label","Geschlecht");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                 ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24@desk");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24@desk pt25");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24@desk pt25");
            dom.setAttribute(el2,"data-label","Besitzer");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24@desk pt25");
            dom.setAttribute(el2,"data-label","Farbe");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24@desk pt25");
            dom.setAttribute(el2,"data-label","Rasse");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                 ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24@desk table__cell--action buttons-2");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","exp icon-button icon-button--success list-action-square");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            var el5 = dom.createTextNode("Auswählen");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    -->");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [5, 1]);
            var element3 = dom.childAt(element1, [7, 1]);
            var element4 = dom.childAt(element1, [13, 2]);
            var morphs = new Array(10);
            morphs[0] = dom.createMorphAt(dom.childAt(element1, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element1, [3, 1]),0,0);
            morphs[2] = dom.createMorphAt(element2,0,0);
            morphs[3] = dom.createMorphAt(element2,2,2);
            morphs[4] = dom.createMorphAt(element3,0,0);
            morphs[5] = dom.createMorphAt(element3,2,2);
            morphs[6] = dom.createMorphAt(dom.childAt(element1, [9, 1]),0,0);
            morphs[7] = dom.createMorphAt(dom.childAt(element1, [11, 1]),0,0);
            morphs[8] = dom.createElementMorph(element4);
            morphs[9] = dom.createMorphAt(element4,1,1);
            return morphs;
          },
          statements: [
            ["inline","icon-gender",[],["gender",["subexpr","@mut",[["get","patient.gender.id",["loc",[null,[31,49],[31,66]]]]],[],[]],"castrated",["subexpr","@mut",[["get","patient.castrated",["loc",[null,[31,77],[31,94]]]]],[],[]],"content",["subexpr","@mut",[["get","patient.genderDetails",["loc",[null,[31,103],[31,124]]]]],[],[]],"size","32","color","#4A4A4A"],["loc",[null,[31,28],[31,152]]]],
            ["block","if",[["get","patient.category",["loc",[null,[34,32],[34,48]]]]],[],0,null,["loc",[null,[34,26],[34,218]]]],
            ["content","patient.name",["loc",[null,[37,26],[37,42]]]],
            ["block","if",[["get","patient.etigaActive",["loc",[null,[37,49],[37,68]]]]],[],1,null,["loc",[null,[37,43],[37,172]]]],
            ["content","patient.customer.firstname",["loc",[null,[40,26],[40,56]]]],
            ["content","patient.customer.lastname",["loc",[null,[40,57],[40,86]]]],
            ["content","patient.color",["loc",[null,[43,26],[43,43]]]],
            ["content","patient.race",["loc",[null,[46,26],[46,42]]]],
            ["element","action",["select",["get","patient.customer.id",["loc",[null,[50,107],[50,126]]]],["get","patient.id",["loc",[null,[50,127],[50,137]]]]],[],["loc",[null,[50,89],[50,139]]]],
            ["inline","button-select",[],["color","#fff","showStroke",false,"content","Auswählen"],["loc",[null,[51,24],[51,91]]]]
          ],
          locals: ["patient"],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 10
            },
            "end": {
              "line": 59,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/choose-existing-etiga-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table no-box@phone grid table--large");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__head");
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-2/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-2/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-6/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Patient");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-6/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Besitzer");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-3/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Farbe");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-3/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Rasse");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-2/24");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__body");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","patients",["loc",[null,[28,24],[28,32]]]]],[],0,null,["loc",[null,[28,16],[56,25]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 10
            },
            "end": {
              "line": 63,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/choose-existing-etiga-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","ml10 mb30 label inline-block button verticaltop");
          var el2 = dom.createTextNode("Neue eltiga Akte anlegen\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [
          ["element","action",["createNew"],[],["loc",[null,[61,71],[61,93]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 67,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/choose-existing-etiga-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-choose-existing-etiga-panel");
        dom.setAttribute(el1,"class","popup-basic popup-extremely-large admin-form mfp-with-anim mfp-hide small--heading");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("eltiga Patient für die Verknüpfung mit eigenen Stammdaten auswählen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","box");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-24/24");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","inline-block pt2 col l-12/24");
        var el6 = dom.createTextNode("Noch nicht verknüpfte Freigaben");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 u-text--right");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","button context");
        var el7 = dom.createTextNode("Neue eltiga Akte anlegen\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0, 1, 3]);
        var element6 = dom.childAt(element5, [1, 3, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createElementMorph(element6);
        morphs[1] = dom.createMorphAt(element5,3,3);
        morphs[2] = dom.createMorphAt(element5,4,4);
        return morphs;
      },
      statements: [
        ["element","action",["createNew"],[],["loc",[null,[10,38],[10,60]]]],
        ["block","if",[["subexpr","eq",[["get","etigaActionType",["loc",[null,[16,20],[16,35]]]],"use"],[],["loc",[null,[16,16],[16,42]]]]],[],0,null,["loc",[null,[16,10],[59,17]]]],
        ["block","if",[["subexpr","eq",[["get","etigaActionType",["loc",[null,[60,20],[60,35]]]],"new"],[],["loc",[null,[60,16],[60,42]]]]],[],1,null,["loc",[null,[60,10],[63,17]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});