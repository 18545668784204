define('jason-frontend/routes/patients/etiga', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  var modelHookRun;

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);

      var id = params.params['patients.etiga'].masterId,
          self = this;

      controller.set("genders", this.store.find('gender'));

      var deferred = $.ajax({
        url: "/api/patients/" + id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('model', data.patient);
      }, function (error) {});

      $.ajax({
        url: "/api/patients/" + id + "/myEtigaPermissionsMatrix",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.get('etigaPanel').send('loadPermissions', data);
      });
    },
    actions: {
      selectPatientForEtigaActivation: function selectPatientForEtigaActivation(clientId, masterId) {
        var self = this;
        $.ajax({
          url: "/api/patients/" + clientId + "/connectToEtigaMaster/" + masterId + "?referer=" + this.get('controller').get('referer'),
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('eltiga aktiviert', '');
          self.transitionTo('patients.edit', clientId);
        }, function () {
          jason.notifiction.notifiy('eltiga aktiviert', '');
          self.transitionTo('patients.edit', clientId);
        });
      },
      selectCustomerForEtigaActivation: function selectCustomerForEtigaActivation(customerId, patientId, masterId) {

        var self = this;
        $.ajax({
          url: "/api/patients/connectCustomerToEtigaMaster/" + masterId + "/" + customerId + "/" + patientId + "?referer=" + this.get('controller').get('referer'),
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('eltiga aktiviert', '');
          self.transitionTo('patients.edit', data);
        }, function (data) {
          jason.notifiction.notifiy('eltiga aktiviert', '');
          self.transitionTo('patients.edit', data);
        });
      },
      createCustomerForEtigaActivation: function createCustomerForEtigaActivation(customer, masterId) {
        var self = this;
        $.ajax({
          url: "/api/patients/createCustomerAndConnectToEtigaMaster/" + masterId + "?referer=" + this.get('controller').get('referer'),
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(customer),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Kunde erstellt', 'eltiga für Patient aktiviert');
          self.transitionTo('patients.edit', data);
        }, function (data) {
          jason.notifiction.notifiy('Kunde erstellt', 'eltiga für Patient aktiviert');
          self.transitionTo('patients.edit', data);
        });
      }
    }
  });

});