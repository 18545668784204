define('jason-frontend/components/choose-existing-etiga-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    etigaActionType: 'use',
    actions: {
      load: function load(data, masterId) {
        this.set('patients', data);
        this.set('masterId', masterId);
      },
      select: function select(customerId, patientId) {
        this.sendAction('select', customerId, patientId);
        $.magnificPopup.close();
      },
      selectNewPatient: function selectNewPatient(customerId) {
        this.sendAction('select', customerId, null, this.get('masterId'));
        $.magnificPopup.close();
      },
      createNew: function createNew() {
        this.sendAction('createNew', this.get('masterId'));
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});