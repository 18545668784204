define('jason-frontend/controllers/document-tags/index', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Übersicht",
    breadCrumbPath: "etigafeed",
    filterPatientCategory: null,
    filterGender: null,
    showFilters: true,
    myStickyOptionsTableHead: {
      topSpacing: 160,
      zIndex: 9
    },
    showPanel: function showPanel(id) {

      var self = this;
      $.ajax({
        url: "/api/etigaCampaigns/" + id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.get('showEtigaCampaignPanel').send('load', data.etigaCampaign);
      }, function (error) {});

      $.magnificPopup.open({
        removalDelay: 500,
        closeOnBgClick: false,
        items: {
          src: '#modal-show-etiga-campaign-panel'
        },
        callbacks: {
          beforeOpen: function beforeOpen() {
            var Animation = "mfp-with-fade";
            this.st.mainClass = Animation;
          }
        },
        midClick: true
      });
    },
    actions: {
      showPanel: function showPanel(id) {
        this.showPanel(id);
      },
      openPanel: function openPanel() {

        this.get('editEtigaCampaignPanel').send('load');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-etiga-campaign-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      editPanel: function editPanel(item) {

        var self = this;

        $.ajax({
          url: "/api/etigaCampaigns/" + item.id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('editEtigaCampaignPanel').send('loadRecord', data.etigaCampaign);
        }, function (error) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-etiga-campaign-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      reload: function reload(id) {
        this.showPanel(id);
      }
    },
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('filterPatientCategory'),
    watchGender: (function () {
      this.send('applyFilter');
    }).observes('filterGender'),
    watchAge: (function () {
      this.send('applyFilter');
    }).observes('filterAge'),
    watchAgeTo: (function () {
      this.send('applyFilter');
    }).observes('filterAgeTo')
  });

});