define('jason-frontend/components/select-treatment-doctors', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    doctor: null,
    assistant: null,
    doctorObserve: (function () {
      this.sendAction('changed');
    }).observes('doctor'),
    assistantObserve: (function () {
      this.sendAction('changed');
    }).observes('assistant')
  });

});