define('jason-frontend/helpers/filter-favorite', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.filterFavorite = filterFavorite;

  function filterFavorite(params) {

    var favoriteEnabled = params[0],
        favoritesToggled = params[1];

    if (favoritesToggled === false) {
      return true;
    }

    if (favoriteEnabled === true || favoriteEnabled === 'true') {
      return true;
    }

    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(filterFavorite);

});