define('jason-frontend/components/edit-invoice-next-status-change-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      confirm: function confirm(invoice, id) {
        var nextChange = moment(invoice.get('nextStatusChangeInput'), 'DD. MM. YYYY');

        if (nextChange.isBefore(moment())) {
          jason.notifiction.error('Fehler', 'Das Fälligkeitsdatum darf nicht in der Vergangenheit liegen');
          return;
        }

        var data = {
          id: invoice.id,
          nextChange: nextChange.toDate()
        };

        this.sendAction('confirm', data);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(invoice) {
        this.set('invoice', invoice);

        if (invoice.get('nextStatusChange')) {
          invoice.set('nextStatusChangeInput', moment(invoice.get('nextStatusChange')).format('DD. MM. YYYY'));
        }
      }
    }
  });

});