define('jason-frontend/components/purchase-order-richter-async-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    showLoading: true,
    id: null,
    authority: false,
    actions: {
      confirmRichter: function confirmRichter() {
        this.sendAction('confirmRichter', this.get('parentOrderId'));
        $.magnificPopup.close();
      },
      load: function load(id, parentOrderId) {
        this.set('id', id);
        this.set('parentOrderId', parentOrderId);
        this.set('authority', false);
        this.set('showLoading', true);
      },
      checkFinished: function checkFinished(data) {
        this.set('result', data);
        this.set('showLoading', false);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});