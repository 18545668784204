define('jason-frontend/templates/components/button-mail', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 5,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/button-mail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"class","circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","14");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[4,62],[4,67]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-mail.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(0,0)");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"fill-rule","evenodd");
        dom.setAttribute(el3,"d","M1.6,0.210431141 C0.7205171,0.210431141 9.99999656e-08,0.96853374 9.99999656e-08,1.8938971 L9.99999656e-08,10.3112332 C9.99999656e-08,11.2365924 0.7205171,11.9946992 1.6,11.9946992 L16.4,11.9946992 C17.279482,11.9946992 18,11.2365924 18,10.3112332 L18,1.8938971 C18,0.96853374 17.279482,0.210431141 16.4,0.210431141 L1.6,0.210431141 Z M1.59375,1.05216412 C1.595896,1.05214729 1.597848,1.05216412 1.6,1.05216412 L16.4,1.05216412 C16.4043,1.05216412 16.40822,1.05209678 16.4125,1.05216412 L9.575,7.95701128 C9.243258,8.29183162 8.7698118,8.29183162 8.4375,7.95701128 L1.59375,1.05216412 Z M0.8875001,1.51248684 L5.3375,5.99076513 L0.8812501,10.6794914 C0.8307175,10.5681722 0.8000001,10.4454686 0.8000001,10.3112332 L0.8000001,1.8938971 C0.8000001,1.75437986 0.8331563,1.62698357 0.8875001,1.51248684 L0.8875001,1.51248684 Z M17.1125,1.51248684 C17.1668438,1.62698357 17.2,1.75437986 17.2,1.8938971 L17.2,10.3112332 C17.2,10.4454686 17.1692818,10.5681722 17.11875,10.6794914 L12.66875,5.99735169 L17.1125,1.51248684 Z M5.9125,6.57604311 L7.8875,8.56200686 C8.5126698,9.19191774 9.50004,9.19278051 10.125,8.56200686 L12.0875,6.57604311 L16.43125,11.1463796 C16.42045,11.1463796 16.4109238,11.1529031 16.4,11.1529031 L1.6,11.1529031 C1.589076,11.1529031 1.5795528,11.1468005 1.56875,11.1463796 L5.9125,6.57604311 Z");
        dom.setAttribute(el3,"transform","translate(7 10)");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createMorphAt(element2,1,1);
        morphs[3] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,10],[3,20]]]]],[],0,null,["loc",[null,[3,4],[5,11]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[6,18],[6,23]]]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});