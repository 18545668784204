define('jason-frontend/components/task-center', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    _onReady: (function () {
      this.fetchTasks();
    }).on('didInsertElement'),
    fetchTasks: function fetchTasks() {
      var self = this;

      $.ajax({
        url: "/api/tasks/undone",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('undoneCount', data);
      });
      Ember['default'].run.later(this, function () {
        self.fetchTasks();
      }, 60000);
    }
  });

});