define('jason-frontend/controllers/patients/index', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    queryParams: ["page", "perPage", "filterName"],
    filterName: "",

    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    sortBy: null,
    sortDir: null,

    showEtiga: false,
    filterAll: true,
    page: 1,
    perPage: 10,
    actions: {
      selectBarcode: function selectBarcode(patientId) {
        this.get('patientSelectBarcodePanel').send('load', patientId);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-patient-select-barcode-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      downloadBarcode: function downloadBarcode(patientId, format) {
        window.open('/api/patients/downloadBarcode/' + patientId + '/' + format + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      admissionPatient: function admissionPatient(customerId, patientId) {

        var self = this;
        var patients = [patientId];

        this.get('startTreatmentPanel').send('load', customerId, patients);

        Ember['default'].$.ajax({
          url: "/api/patients/latestInfos?patientIds=" + patients,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('startTreatmentPanel').send('loadTreatment', data);
        }, function (data) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-start-treatment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    },
    watchEtiga: (function () {
      this.set('page', 1);
      var self = this;
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);

      if (this.get('showEtiga')) {
        this.set('filterAll', false);
      } else {
        this.set('filterAll', true);
      }
    }).observes('showEtiga'),
    watchfilterAll: (function () {
      var self = this;
      if (this.get('filterAll')) {
        this.set('showEtiga', false);
      } else {
        this.set('showEtiga', true);
      }
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterAll')
  });

});