define('jason-frontend/helpers/format-number-german-3', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.formatNumberGerman = formatNumberGerman;

  function formatNumberGerman(params) {
    var value = (params[0] + '').replace(',', '.');
    value = Math.round(value * 1000) / 1000;
    return ('' + value).replace('.', ',');
  }

  exports['default'] = Ember['default'].Helper.helper(formatNumberGerman);

});