define('jason-frontend/components/treatment-multistage-select-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    selectedValues: new Array(),
    filterCategory: null,
    loadingLeft: true,
    diagnosesVersion: API['default'].getOperatingCountry() === 'AT' ? '1' : '2',
    selectedCategoryId: null,
    timerId: {},
    syncToDom: function syncToDom() {
      var ids = '';
      this.get('selectedValues').forEach(function (elem) {
        ids += elem.id + ',';
      });
      $('#treatmentMultistageSelectForm').attr('data-ids', ids);
    },
    searchDiagnose: function searchDiagnose(parent, categoryId) {
      return this.externalContext.findPaged('digital-diagnose', {
        filterCategory: this.get('filterCategory'),
        filterVersion: this.get('diagnosesVersion'),
        filterCategoryId: categoryId,
        filterPatientId: this.get('patientId'),
        per_page: 10
      });
    },
    searchProducts: function searchProducts(parent, categoryId) {
      return this.externalContext.findPaged('product', {
        filterMandant: 'all',
        filterFavorites: false,
        filterSupplier: 'richterpharma',
        per_page: 10
      });
    },
    searchSpecies: function searchSpecies(parent, categoryId) {
      return this.externalContext.findPaged('patientCategory', {
        filterMandant: 'all',
        filterFavorites: false,
        filterName: this.get('filterCategory'),
        per_page: 10
      });
    },
    searchTherapy: function searchTherapy(parent, categoryId) {
      return this.externalContext.findPaged('digital-therapy', {
        filterCategory: this.get('filterCategory'),
        filterCategoryId: categoryId,
        per_page: 10
      });
    },
    actions: {
      doSearch: function doSearch() {
        var self = this;

        if (this.get('subSearchActive') == true) {} else {
          if (this.get('type') === 'diagnoses' || this.get('type') === 'finalDiagnoses') {
            this.set('loadingLeft', false);
            this.set('loadingRight', true);
            this.searchDiagnose(false, this.get('selectedCategoryId')).then(function (entries) {
              self.set('rightValues', entries);
              self.set('loadingRight', false);
            });
          } else if (this.get('type') === 'therapies') {
            this.set('loadingLeft', false);
            this.set('loadingRight', true);
            this.searchTherapy(false, this.get('selectedCategoryId')).then(function (entries) {
              self.set('rightValues', entries);
              self.set('loadingRight', false);
            });
          } else if (this.get('type') === 'products') {
            this.set('loadingLeft', false);
            this.set('loadingRight', true);
            this.searchProducts(false, this.get('selectedCategoryId')).then(function (entries) {
              self.set('rightValues', entries);
              self.set('loadingRight', false);
            });
          } else if (this.get('type') === 'patientCategories') {
            this.set('loadingLeft', false);
            this.set('loadingRight', true);
            this.searchSpecies(false, this.get('selectedCategoryId')).then(function (entries) {
              self.set('rightValues', entries);
              self.set('loadingRight', false);
            });
          }

          if (this.get('filterCategory') && this.get('filterCategory').length <= 2) {
            if (this.get('type') === 'diagnoses' || this.get('type') === 'finalDiagnoses') {
              this.searchDiagnose(true).then(function (entries) {
                self.set('values', entries);
                self.set('leftContent', entries.meta);
                self.set('rightValues', null);
              });
            } else if (this.get('type') === 'products') {
              this.searchProducts(true).then(function (entries) {
                self.set('values', entries);
                self.set('leftContent', entries.meta);
                self.set('rightValues', null);
              });
            } else if (this.get('type') === 'patientCategories') {
              this.searchSpecies(true).then(function (entries) {
                self.set('values', entries);
                self.set('leftContent', entries.meta);
                self.set('rightValues', null);
              });
            } else {
              this.searchTherapy(true).then(function (entries) {
                self.set('values', entries);
                self.set('leftContent', entries.meta);
                self.set('rightValues', null);
              });
            }
            return;
          }

          if (this.get('type') === 'diagnoses' || this.get('type') === 'finalDiagnoses') {
            this.set('loadingLeft', true);
            this.set('loadingRight', true);
            this.searchDiagnose(true).then(function (entries) {
              self.set('values', entries);
              self.set('leftContent', entries.meta);
              self.set('loadingLeft', false);
            });
            this.searchDiagnose(false).then(function (entries) {
              self.set('rightValues', entries);
              self.set('loadingRight', false);
            });
          } else if (this.get('type') === 'products') {
            this.searchProducts(false).then(function (entries) {
              self.set('rightValues', entries);
              self.set('loadingRight', false);
            });
          } else if (this.get('type') === 'patientCategories') {
            this.searchSpecies(false).then(function (entries) {
              self.set('values', entries);-self.set('leftContent', entries.meta);
              self.set('loadingLeft', false);
              self.set('loadingRight', false);
            });
          } else {
            this.set('loadingLeft', true);
            this.set('loadingRight', true);
            this.searchTherapy(true).then(function (entries) {
              self.set('values', entries);
              self.set('leftContent', entries.meta);
              self.set('loadingLeft', false);
            });
            this.searchTherapy(false).then(function (entries) {
              self.set('rightValues', entries);
              self.set('loadingRight', false);
            });
          }
        }
      },
      updateReference: function updateReference(field, model) {
        Ember['default'].set(model, field, this.get('selectedValues'));
      },
      select: function select(item) {
        var selected = !item.selected;

        this.get('values').forEach(function (elem) {
          Ember['default'].set(elem, 'selected', false);
        });
        if (selected) {
          Ember['default'].set(item, 'selected', true);
        }

        var found = false;

        this.get('selectedValues').forEach(function (elem) {
          if (elem.id == item.id) {
            found = true;
          }
        });

        if (!found) {
          this.get('selectedValues').pushObject(item);
          this.syncToDom();
        }
      },
      deleteItem: function deleteItem(id) {
        var itemToDelete = null;
        this.get('selectedValues').forEach(function (elem, idx) {
          if (elem.id == id) {
            itemToDelete = elem;
          }
        });
        if (itemToDelete) {
          this.get('selectedValues').removeObject(itemToDelete);
          this.syncToDom();
        }
      },
      cancel: function cancel() {
        this.set('loading', true);
        $.magnificPopup.close();
      },
      load: function load(type, selected, ctx, title, patientId) {

        var self = this;

        this.set('title', title);
        this.set('patientId', patientId);
        this.set('type', type);
        this.set('selectedValues', new Array());
        this.set('rightValues', null);
        this.set('filterCategory', null);
        this.set('externalContext', ctx);
        this.set('selectedCategoryId', null);

        if (selected) {
          selected.forEach(function (elem) {
            self.get('selectedValues').pushObject(elem);
          });
        }

        this.syncToDom();

        if (type === 'diagnoses' || type === 'finalDiagnoses') {
          this.searchDiagnose(true).then(function (entries) {
            self.set('values', entries);
            self.set('leftContent', entries.meta);
            self.set('loadingLeft', false);
            self.set('loadingRight', false);
          });
        } else if (type === 'therapies') {
          this.searchTherapy(true).then(function (entries) {
            self.set('values', entries);
            self.set('leftContent', entries.meta);
            self.set('loadingLeft', false);
            self.set('loadingRight', false);
          });
        } else if (type === 'products') {
          this.searchProducts(true).then(function (entries) {
            self.set('values', entries);
            self.set('leftContent', entries.meta);
            self.set('loadingLeft', false);
            self.set('loadingRight', false);
          });
        } else if (type === 'patientCategories') {
          this.searchSpecies(true).then(function (entries) {
            self.set('values', entries);
            self.set('leftContent', entries.meta);
            self.set('loadingLeft', false);
            self.set('loadingRight', false);
          });
        }

        setTimeout(function () {
          $('#filterNameMultistageSelect').focus();
        }, 500);
      }
    },
    watchSearch: (function () {
      var self = this;
      var type = 'defaultTimer';
      if (this.timerId[type]) {
        window.clearTimeout(this.timerId[type]);
      }
      this.timerId[type] = window.setTimeout(function () {
        self.send('doSearch');
      }, 500);
    }).observes('filterCategory')
  });

});