define('jason-frontend/routes/patients/create', ['exports', 'ember', 'jason-frontend/api', 'moment'], function (exports, Ember, API, moment) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      var patient = new Object();
      patient.birthdayInput = null;
      return patient;
    },
    actions: {
      selectTab: function selectTab(selected) {
        var controller = this.get('controller');
        controller.set('selectTab', selected);
      },
      create: function create() {
        var self = this;
        var model = this.controller.get('model'),
            customerId = this.controller.get('selectedCustomerId'),
            patient = new Object();

        if (model.birthdayInput) {
          patient.birthday = moment['default'](model.birthdayInput, 'DD. MM. YYYY').toDate();
        }
        if (model.deathdayInput) {
          patient.deathday = moment['default'](model.deathdayInput, 'DD. MM. YYYY').toDate();
        }

        if (model.chipDateInput) {
          patient.chipDate = moment['default'](model.chipDateInput, 'DD. MM. YYYY').toDate();
        } else {
          patient.chipDate = null;
        }

        patient.name = model.name;
        patient.color = model.color;
        patient.race = model.race;
        patient.chipId = model.chipId;
        patient.foreignId = model.foreignId;
        patient.castrated = model.castrated;

        if (model.category && model.category.id) {
          patient.category = {
            id: model.category.id
          };
        }
        patient.customerId = customerId;
        if (model.gender && model.gender.id) {
          patient.gender = {
            id: model.gender.id
          };
        }

        var image = self.controller.get('uploadedAssets');

        if (image) {
          patient.uploadedPicture = image;
        }

        patient.bornCountry = model.bornCountry;
        patient.chipRegistered = model.chipRegistered;
        patient.evil = model.evil;
        patient.slaughter = model.slaughter;
        patient.handoverDocument = model.handoverDocument;
        patient.chipLocation = model.chipLocation;
        patient.notice = model.notice;
        patient.breedNumber = model.breedNumber;
        patient.horsePass = model.horsePass;
        patient.horseBurningMark = model.horseBurningMark;
        patient.horseTatoo = model.horseTatoo;
        patient.insuranceProtection = model.insuranceProtection;
        patient.horseLivenumber = model.horseLivenumber;
        patient.horseBurningMarkLocation = model.horseBurningMarkLocation;
        patient.horseTatooLocation = model.horseTatooLocation;
        patient.medicalIncompatibility = model.medicalIncompatibility;
        patient.medicalIncompatibilityNotice = model.medicalIncompatibilityNotice;

        $.ajax({
          url: "/api/patients",
          method: "POST",
          data: JSON.stringify(patient),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.transitionTo('customers.edit', customerId);
        }, function (error) {});
      }
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);
      var customerId = params.params['patients.create'].customerId;
      controller.set('genders', this.store.find('patient-gender'));
      controller.set('categories', this.store.find('patient-category'));
      this.store.find('customer', customerId).then(function (data) {
        controller.set('customer', data.get('lastname') + ' ' + data.get('firstname'));
      });

      controller.set('selectedCustomerId', customerId);
    }
  });

});