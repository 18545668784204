define('jason-frontend/models/invoice-row', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    value: DS['default'].attr('number'),
    month: DS['default'].attr('number'),
    year: DS['default'].attr('number'),
    mandantId: DS['default'].attr('number'),
    comment: DS['default'].attr('string')
  });

});