define('jason-frontend/serializers/product', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  exports['default'] = DS['default'].ActiveModelSerializer.extend(DS['default'].EmbeddedRecordsMixin, {
    attrs: {
      picture: { embedded: 'always' },
      tax: { embedded: 'always' },
      unit: { embedded: 'always' },
      category: { embedded: 'always' },
      supplier: { embedded: 'always' },
      manufacturer: { embedded: 'always' },
      distributor: { embedded: 'always' },
      prices: { embedded: 'always' },
      patientCategories: { embedded: 'always' }
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      var serialized = this.serialize(record, options);
      serialized.id = record.id ? record.id : 0;
      serialized.priceExVat = serialized.priceExVat + ''.replace(",", ".");
      Ember['default'].merge(hash, serialized);
    },
    keyForAttribute: function keyForAttribute(attr, method) {
      return Ember['default'].String.camelize(attr);
    }
  });

});