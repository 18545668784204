define('jason-frontend/models/service', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    type: DS['default'].attr(''),
    tags: DS['default'].attr(''),
    individualName: DS['default'].attr('string'),
    description: DS['default'].attr('string'),
    priceExVat: DS['default'].attr(),
    additionPercentage: DS['default'].attr('number'),
    additionalPercentage: DS['default'].attr('number'),
    jasonRecommendedPrice: DS['default'].attr('number'),
    jasonRecommendedAddition: DS['default'].attr('number'),
    jasonPrice: DS['default'].attr('boolean'),
    jason: DS['default'].attr('boolean'),
    editable: DS['default'].attr('boolean'),
    priceIncVat: DS['default'].attr('number'),
    referenceNumber: DS['default'].attr('string'),
    category: DS['default'].attr(''),
    distributor: DS['default'].belongsTo('product-distributor'),
    tax: DS['default'].belongsTo('tax'),
    unit: DS['default'].belongsTo('serviceunit', { async: true }),
    supplier: DS['default'].belongsTo('product-supplier'),
    patientCategories: DS['default'].attr(''),
    labProfile: DS['default'].attr(''),
    vetconnectTest: DS['default'].attr(''),
    xrayProfile: DS['default'].attr(''),
    factor: DS['default'].attr(''),
    favorite: DS['default'].attr(''),
    gotNumber: DS['default'].attr(''),
    gotNumberPrefix: DS['default'].attr(''),
    gotSmall: DS['default'].attr(''),
    gotHorse: DS['default'].attr(''),
    gotUse: DS['default'].attr(''),
    gotDeprecated: DS['default'].attr(''),
    gotPara8: DS['default'].attr(''),
    singleGotPrice: DS['default'].attr('')
  });

});