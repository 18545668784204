define('jason-frontend/controllers/invoices/banktransfer', ['exports', 'ember', 'moment'], function (exports, Ember, moment) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Überweisung",
    breadCrumbPath: "invoices.banktransfer",
    queryParams: ["page", "perPage", "filterCode"],
    filterStatus: "",
    filterCode: "",
    filterPaymentStatus: "",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    perPage: 10,
    selectedPaymentType: "cash",
    showInvoicePanel: true,
    showBankTransferPanel: false,
    selectedType: "in",
    selectedInvoiceId: null,
    selectedInvoice: null,
    selectedBankingAccount: null,
    notice: null,
    maxPaymentAmount: null,
    withdrawalDate: moment['default']().format("DD. MM. YYYY"),
    transaction: null,
    bankingAccount: null,
    documentDate: moment['default']().format("DD. MM. YYYY"),
    reason: null,
    styleStepOne: "",
    styleStepTwo: "hidden",
    styleStepThree: "hidden",
    styleStepFour: "hidden",
    styleStepFive: "hidden",
    actions: {
      toggleReference: function toggleReference(show) {
        this.set('showRefOutgoing', show);
      },
      setPaymentType: function setPaymentType(paymentType) {
        this.set('selectedPaymentType', paymentType);
        this.set('showBankTransferPanel', paymentType === 'banktransfer');
      },
      setType: function setType(type) {
        this.set('selectedType', type);
        this.set('showInvoicePanel', type === 'in');

        if (type === 'inlay') {
          this.set('selectedPaymentType', 'cash');
        }
      }
    },
    watchGiven: (function () {
      var currentGiven = parseFloat(String(this.get('given')).replace(',', '.'));
      var deposit = parseFloat(String(this.get('deposit')).replace(',', '.'));
      if (isNaN(deposit)) {
        deposit = 0;
      }
      if (currentGiven > this.get('toPay')) {
        var given = this.get('toPay') - deposit;
        this.set('given', String(Math.round(given * 100) / 100).replace('.', ','));
      }
    }).observes('given'),
    watchDeposit: (function () {
      var deposit = parseFloat(String(this.get('deposit')).replace(',', '.'));
      if (isNaN(deposit)) {
        deposit = 0;
      }
      if (deposit < 0) {
        deposit = deposit * -1;
        this.set('deposit', deposit);
      }
      if (deposit > this.get('toPay')) {
        deposit = this.get('toPay');
        this.set('deposit', String(deposit).replace('.', ','));
      }
      if (deposit > this.get('depositSum')) {
        deposit = this.get('depositSum');
        this.set('deposit', String(this.get('depositSum')).replace('.', ','));
      }
      var given = this.get('toPay') - deposit;
      this.set('given', String(Math.round(given * 100) / 100).replace('.', ','));
    }).observes('deposit'),
    watchType: (function () {
      var self = this;
      var invoice = this.store.fetch('invoice', this.selectedInvoiceId).then(function (data) {
        self.set("selectedInvoice", data);
        var open = data.get('total') - data.get('payedTotal');
        self.set("maxPaymentAmount", open);
      });
    }).observes('selectedInvoiceId')
  });

});