define('jason-frontend/routes/invoices/select-customer', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {
      return this.findPaged('customer', params);
    },
    setupController: function setupController(controller, model, params) {
      var invoiceId = params.params['invoices.select_customer'].id;
      controller.set("invoiceId", invoiceId);
      controller.set("gendersAll", this.store.find('gender'));

      controller.set('patientGenders', this.store.find('patient-gender'));
      controller.set('patientCategories', this.store.find('patient-category'));

      controller.set("model", model);
    },
    applyFilter: function applyFilter(searchString) {
      var controller = this.get('controller'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      this.findPaged('customer', {
        filterName: searchString,
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    actions: {
      toWaitingRoom: function toWaitingRoom() {
        this.transitionTo('practicemgmt.admission');
      },
      toTreatment: function toTreatment(id) {
        this.transitionTo('practicemgmt.treatment-details', id);
      },
      gotoDetails: function gotoDetails(id) {
        this.transitionTo('customers.edit', id);
      },
      gotoPatient: function gotoPatient(id) {
        this.transitionTo('patients.edit', id);
      },
      applyFilter: function applyFilter(searchString) {
        this.applyFilter(searchString);
      },
      createPatient: function createPatient(customerId, patientData) {
        var controller = this.get('controller'),
            self = this;

        var newPatient = {
          name: patientData.name,
          birthdayFormatted: patientData.birthdayFormatted,
          customerId: customerId,
          castrated: patientData.castrated,
          chipId: patientData.chipId
        };

        if (patientData.patientCategory) {
          newPatient.category = {
            id: patientData.patientCategory
          };
        }
        if (patientData.patientGender) {
          newPatient.gender = {
            id: patientData.patientGender
          };
        }

        $.ajax({
          url: "/api/patients",
          method: "POST",
          data: JSON.stringify(newPatient),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.applyFilter();
        }, function (error) {});
      },
      setCustomer: function setCustomer(customerId, patients) {

        var invoice = new Object();
        var customer = new Object();

        var invoiceId = this.get('controller').get('invoiceId');

        customer.id = customerId;
        invoice.customer = customer;
        invoice.selectedPatientIds = patients;

        var self = this;
        var deferred;
        if (invoiceId.length && invoiceId > 0) {

          invoice.id = invoiceId;

          deferred = $.ajax({
            url: "/api/invoices/" + invoiceId + "/changecustomer",
            method: "PUT",
            data: JSON.stringify(invoice),
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.store.find('invoice', invoiceId).then(function (invoice) {
              self.transitionTo('invoices.create', invoiceId, { queryParams: { entry: "true" } });
              jason.notifiction.notifiy('Kunde wurde geändert', '');
              return invoice;
            });
            return data;
          }, function (error) {
            return { status: error.statusText, message: error.responseText };
          });
        } else {
          deferred = $.ajax({
            url: "/api/invoices",
            method: "POST",
            data: JSON.stringify(invoice),
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.transitionToRoute('invoices.edit', invoiceId);
            jason.notifiction.notifiy('Neue Rechnung erstellt', '');
            return data;
          }, function (error) {
            return { status: error.statusText, message: error.responseText };
          });
        }
        return Ember['default'].RSVP.resolve(deferred);
      },
      setSort: function setSort(attr) {
        var controller = this.get('controller');
        controller.set('sortBy', attr);
        if (controller.get('sortDir') === 'asc') {
          controller.set('sortDir', 'desc');
        } else {
          controller.set('sortDir', 'asc');
        }
      },
      setCashsale: function setCashsale() {
        var self = this;

        var invoiceId = this.get('controller').get('invoiceId');

        var invoice = {
          id: invoiceId,
          customer: null
        };

        $.ajax({
          url: "/api/invoices/" + invoiceId + "/changecustomer",
          method: "PUT",
          data: JSON.stringify(invoice),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('invoices.create', invoiceId);
          return data;
        });
      },
      create: function create(invoiceId, customerData, patientData) {
        var controller = this.get('controller');

        var newCustomer = this.store.createRecord('customer', {
          firstname: customerData.firstname,
          lastname: customerData.lastname,
          genderId: customerData.genderId,
          email: customerData.email,
          street: customerData.street,
          postalCode: customerData.postalCode,
          town: customerData.town,
          phone1: customerData.phone
        });

        newCustomer.save().then(function (storedCustomer) {

          if (patientData) {

            var newPatient = {
              name: patientData.name,
              customerId: storedCustomer.id
            };

            if (patientData.patientCategory) {
              newPatient.category = {
                id: patientData.patientCategory
              };
            }
            if (patientData.patientGender) {
              newPatient.gender = {
                id: patientData.patientGender
              };
            }

            $.ajax({
              url: "/api/patients",
              method: "POST",
              data: JSON.stringify(newPatient),
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              var patients = Array();
              patients.push(data.patient.id);
              controller.send('setCustomer', storedCustomer, invoiceId, storedCustomer.firstname + '' + storedCustomer.lastname, patients);
            }, function (error) {});
          } else {
            controller.send('setCustomer', storedCustomer, invoiceId, storedCustomer.firstname + '' + storedCustomer.lastname);
          }
        });
      }
    }

  });

});