define('jason-frontend/helpers/show-prices', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.showPrices = showPrices;

  function showPrices(params) {
    return !API['default'].isHidePrices();
  }

  exports['default'] = Ember['default'].Helper.helper(showPrices);

});