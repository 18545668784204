define('jason-frontend/serializers/message-category', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  exports['default'] = DS['default'].ActiveModelSerializer.extend(DS['default'].EmbeddedRecordsMixin, {
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      var serialized = this.serialize(record, options);
      serialized.id = record.id ? record.id : 0;
      Ember['default'].merge(hash, serialized);
    },
    keyForAttribute: function keyForAttribute(attr, method) {
      return Ember['default'].String.camelize(attr);
    }
  });

});