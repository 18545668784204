define('jason-frontend/components/laboklin-treatment-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    data: null,
    form: null,
    acceptData: false,
    treatment: true,
    allowUnlinking: true,
    showLoading: false,
    enforceReadOnline: false,
    actions: {
      load: function load(treatmentPatientId, entryId, data, patient, customer, patientCategories, orderId, product, priceInfoId) {
        this.set('treatmentPatientId', treatmentPatientId);
        this.set('entryId', entryId);
        this.set('patientCategories', patientCategories);
        this.set('showLoading', false);

        var self = this;

        if (data) {

          var needsFallback = false;

          var formData = data.purchaseOrderLaboklinForm;

          if (formData.probeTaking) {
            formData.probeTaking = moment(formData.probeTaking).format('DD. MM. YYYY');
          }

          this.set('form', formData);

          if (this.get('enforceReadOnline') || formData.readOnly) {
            formData.readOnly = true;
            $('#laboklinTreatmentForm input, #laboklinTreatmentForm textarea').each(function () {
              $(this).prop('disabled', true);
            });
          } else {
            $('#laboklinTreatmentForm input, #laboklinTreatmentForm textarea').each(function () {
              $(this).prop('disabled', false);
            });
          }
        } else {

          $('#laboklinTreatmentForm input, #laboklinTreatmentForm textarea').each(function () {
            $(this).prop('disabled', false);
          });

          var formData = {};
          if (patient) {
            formData.animalName = patient.name;
            formData.animalAge = self.getAge(patient.birthday);
            formData.animalRace = patient.race;

            if (patient.category) {
              formData.animalCategory = patient.category;
            }

            if (patient.gender) {

              var genderId = patient.gender.id;

              if (genderId === 'male') {
                if (patient.castrated) {
                  formData.animalGender = 'mk';
                } else {
                  formData.animalGender = 'm';
                }
              }
              if (genderId === 'female') {
                if (patient.castrated) {
                  formData.animalGender = 'wk';
                } else {
                  formData.animalGender = 'w';
                }
              }
            }
          }
          if (customer) {
            formData.firstname = customer.firstname;
            formData.lastname = customer.lastname;
            formData.street = customer.street;
            formData.town = customer.postalCode + ' ' + customer.town;
            formData.email = customer.email;
          }
          formData.invoiceTo = 'E';
          formData.reorder = false;

          try {
            if (product && product.get('labCategory')) {
              formData.orderType = product.get('labCategory');
            } else {
              needsFallback = true;
            }
          } catch (e) {
            try {
              if (product && product.labCategory) {
                formData.orderType = product.labCategory;
              } else {
                needsFallback = true;
              }
            } catch (e) {
              needsFallback = true;
            }
          }

          formData.probeTaking = moment().format('DD. MM. YYYY');
          formData.interpretation = "true";

          this.set('form', formData);
          this.set('orderId', orderId);
          if (product) {
            this.set('productId', product.id);
          }
          this.set('priceInfoId', priceInfoId);
        }

        $.ajax({
          url: "/api/laboklin/orderTypes",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('types', data.laboklinOrderType);

          if (needsFallback) {
            $.each(data.laboklinOrderType, function () {
              if ($(this)[0].name === 'Allgemein') {
                formData.orderType = $(this)[0].id;
              }
            });
          }
        });

        $('#laboklinTreatmentForm input[name=orderType]').each(function () {
          $(this).prop('disabled', true);
        });

        $('#orderTypeContainer').addClass('disabled');
      },
      downloadBarcode: function downloadBarcode(formId) {
        this.sendAction('downloadBarcode', formId);
      },
      downloadPdf: function downloadPdf(formId) {
        this.sendAction('downloadPdf', formId);
      },
      downloadMedia: function downloadMedia(formId, media) {
        this.sendAction('downloadMedia', formId, media);
      },
      submitLaboklin: function submitLaboklin(treatmentPatientId, formId) {
        this.set('showLoading', true);
        this.sendAction('submitLaboklin', treatmentPatientId, formId);
      },
      unlink: function unlink(id, formId) {
        this.sendAction('removeEntry', id, formId);
        $('#' + id + '_entry').hide();
      },
      confirm: function confirm(treatmentPatientId, entryId) {
        var self = this;

        var form = $('#laboklinTreatmentForm');

        var id = form.find('input[name=laboklin-treatment-id]').val();

        var data = {
          id: id,
          invoiceTo: form.find('input[name=invoiceTo]:checked').val(),
          interpretation: form.find('input#interpretation').is(':checked'),
          forwardToHolder: form.find('input#forwardToHolder').is(':checked'),
          reorder: form.find('input#reorder').is(':checked'),
          firstname: form.find('input[name=laboklin-owner-firstname]').val(),
          lastname: form.find('input[name=laboklin-owner-lastname]').val(),
          town: form.find('input[name=laboklin-owner-town]').val(),
          street: form.find('input[name=laboklin-owner-street]').val(),
          email: form.find('input[name=laboklin-owner-email]').val(),
          probeTaking: moment(form.find('input[name=probeTaking]').val(), 'DD. MM. YYYY').format('YYYY-MM-DD'),
          probeReport: form.find('input[name=probeReport]').val(),
          probe: form.find('input[name=checkType]:checked').val(),
          animalName: form.find('input[name=laboklin-animal-name]').val(),
          animalRace: form.find('input[name=laboklin-animal-race]').val(),
          animalAge: form.find('input[name=laboklin-animal-age]').val(),
          animalGender: self.get('form').animalGender,
          animalCategory: self.get('form').animalCategory,
          animalTypeMisc: form.find('input[name=patientTypeMisc]').val(),
          orderType: form.find('input[name=orderType]:checked').val(),
          edvNumber: form.find('input[name=patientEdv]').val(),
          comment: form.find('textarea[name=laboklin-comment]').val(),
          wish: form.find('input[name=laboklin-wish]').val()
        };

        if (!data.animalCategory) {
          jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte wählen Sie eine Tierart aus');
          return;
        }
        if (data.invoiceTo === 'X' && (!data.email || data.email && data.email.length <= 3)) {
          jason.notifiction.error('Fehlerhafte Eingabe', 'Bitte geben Sie eine Email-Adresse an wenn die Rechnung an die Tierhalterin geht');
          return;
        }

        if (this.get('treatment')) {
          var deferred = $.ajax({
            url: "/api/treatmentPatients/" + treatmentPatientId + "/laboklinForm",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(data),
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (formId) {
            self.sendAction('confirm', treatmentPatientId, entryId, formId, id > 0);
          }, function (error) {});
        } else {

          var overrideProduct = form.attr('product-id');

          if (overrideProduct) {
            this.set('productId', overrideProduct);
          }

          var deferred = $.ajax({
            url: "/api/purchaseOrders/" + this.get('orderId') + "/laboklinForm",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(data),
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (formId) {
            self.sendAction('confirm', self.get('productId'), self.get('orderId'), self.get('priceInfoId'), '', formId, 1);
          }, function (error) {});
        }

        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    },
    getAge: function getAge(dob) {

      dob = new Date(dob);

      var now = new Date();
      var today = new Date(now.getYear(), now.getMonth(), now.getDate());

      var yearNow = now.getYear();
      var monthNow = now.getMonth();
      var dateNow = now.getDate();

      var yearDob = dob.getYear();
      var monthDob = dob.getMonth();
      var dateDob = dob.getDate();
      var age = {};
      var ageString = "";
      var yearString = "";
      var monthString = "";
      var dayString = "";

      var yearAge = yearNow - yearDob;

      if (monthNow >= monthDob) {
        var monthAge = monthNow - monthDob;
      } else {
        yearAge--;
        var monthAge = 12 + monthNow - monthDob;
      }

      if (dateNow >= dateDob) {
        var dateAge = dateNow - dateDob;
      } else {
        monthAge--;
        var dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }

      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
      };

      if (age.years > 1) {
        yearString = " Jahre";
      } else {
        yearString = " Jahr";
      }
      if (age.months > 1) {
        monthString = " Monate";
      } else {
        monthString = " Monat";
      }
      if (age.days > 1) {
        dayString = " Tage";
      } else {
        dayString = " Tag";
      }

      if (age.years > 0 && age.months > 0 && age.days > 0) {
        ageString = age.years + yearString + ", " + age.months + monthString + ",  " + age.days + dayString;
      } else if (age.years == 0 && age.months == 0 && age.days > 0) {
        ageString = age.days + dayString;
      } else if (age.years > 0 && age.months == 0 && age.days == 0) {
        ageString = age.years + yearString;
      } else if (age.years > 0 && age.months > 0 && age.days == 0) {
        ageString = age.years + yearString + " " + age.months + monthString;
      } else if (age.years == 0 && age.months > 0 && age.days > 0) {
        ageString = age.months + monthString + " " + age.days + dayString;
      } else if (age.years > 0 && age.months == 0 && age.days > 0) {
        ageString = age.years + yearString + " " + age.days + dayString;
      } else if (age.years == 0 && age.months > 0 && age.days == 0) {
        ageString = age.months + monthString;
      } else {
        ageString = "";
      }

      return ageString;
    }
  });

});