define('jason-frontend/components/document-archive-preview-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    url: null,
    prefix: null,
    items: 0,
    currentItem: null,
    currentIndex: null,
    hasPrevious: false,
    hasNext: true,
    updateNavigation: function updateNavigation() {
      if (this.get('currentIndex') + 1 < this.get('items')) {
        this.set('hasNext', true);
        this.set('hasPrevious', true);
      } else {
        this.set('hasNext', false);
        this.set('hasPrevious', true);
      }
      if (this.get('currentIndex') === 0) {
        this.set('hasPrevious', false);
      }

      this.set('url', '/api/media/item/' + this.get('currentItem').id + '/download?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
    },
    actions: {
      downloadCurrent: function downloadCurrent() {
        this.sendAction('download', this.get('currentItem').id);
      },
      load: function load(document) {
        this.set('id', document.get('internalId'));
        this.set('currentIndex', 0);
        this.set('currentItem', document.get('items')[this.get('currentIndex')]);
        this.set('document', document);
        this.set('items', document.get('items').length);

        this.updateNavigation();
      },
      next: function next() {

        this.set('currentIndex', this.get('currentIndex') + 1);
        this.set('currentItem', this.get('document').get('items')[this.get('currentIndex')]);
        this.updateNavigation();
      },
      back: function back() {
        if (this.get('currentIndex') > 1) {
          this.set('currentIndex', this.get('currentIndex') - 1);
        } else {
          this.set('currentIndex', 0);
        }
        this.set('currentItem', this.get('document').get('items')[this.get('currentIndex')]);
        this.updateNavigation();
      }

    }
  });

});