define('jason-frontend/routes/manufacturers/create', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return new Object();
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
    },
    actions: {
      create: function create() {
        var self = this;

        var controller = this.get('controller'),
            model = controller.get('model'),
            manufacturer = new Object();

        manufacturer.name = model.name;
        manufacturer.address = model.address;
        manufacturer.town = model.town;
        manufacturer.postalCode = model.postalCode;

        $.ajax({
          url: "/api/productManufacturers",
          method: "POST",
          data: JSON.stringify(manufacturer),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.transitionTo('basicinfos', { queryParams: { selectTab: "manufacturers" } });
          controller.set('name', '');
        }, function () {
          self.transitionTo('basicinfos', { queryParams: { selectTab: "manufacturers" } });
          controller.set('name', '');
        });
      }
    }
  });

});