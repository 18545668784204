define('jason-frontend/components/template-edit-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    readOnly: false,
    forwardActive: false,
    showUpload: true,
    doSave: function doSave() {
      var id = this.get('id');
      var self = this;

      var data = {
        defaultText: this.get('text'),
        defaultTitle: this.get('title')
      };

      $.ajax({
        url: "/api/" + this.get('type') + "/" + id,
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        jason.notifiction.notifiy('Gespeichert', '');
        setTimeout(function () {
          self.sendAction('reloadParent');
        }, 1000);
      }, function (error) {});
    },
    actions: {
      activateForward: function activateForward(id) {
        var self = this;
        this.set('forwardingActive', false);
        this.sendAction('forward', id, this.get('forwardEmail'), this.get('persistEmail'));
        setTimeout(function () {
          self.set('forwardingActive', true);
        }, 5000);
      },
      preview: function preview(id) {
        window.open('/api/media/' + id + '/download?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      uploadInProgress: function uploadInProgress(e) {
        this.set('uploading', true);
      },
      uploadDone2: function uploadDone2(data) {
        this.set('uploading', false);
        this.sendAction('reloadTemplate', this.get('id'), this.get('readOnly'), this.get('patient'));
      },
      unlink: function unlink(id) {
        var self = this;
        $.ajax({
          url: "/api/templateInstances/" + id + "/unlink",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Dokument', 'erfolgreich entknüpft');
          self.sendAction('reloadTemplate', self.get('id'), self.get('readOnly'), self.get('patient'));
        }, function (error) {});
      },
      deleteDoc: function deleteDoc(id) {
        var self = this;
        $.ajax({
          url: "/api/templateInstances/" + id + "/deleteMedia",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Dokument', 'erfolgreich gelöscht');
          self.sendAction('reloadTemplate', self.get('id'), self.get('readOnly'), self.get('patient'));
        }, function (error) {});
      },
      confirm: function confirm() {
        this.doSave();
        $.magnificPopup.close();
      },
      load: function load(instance, type, readOnly, patient) {
        this.set('id', instance.id);
        this.set('instance', instance);
        this.set('forwardingActive', true);

        if (patient) {
          this.set('patient', patient.id);
          this.set('forwardEmail', patient.customer.email);
        }
        this.set('text', instance.defaultText);
        this.set('title', instance.defaultTitle);
        this.set('type', type);
        this.set('readOnly', readOnly);

        if (type === 'templates') {
          this.set('showUpload', false);
        }

        this.set('ctx', 'templateInstance');
        this.set('ctxId', instance.id);
        this.set('ctxId2', patient);
        this.set('userId', API['default'].getUserId());
      },
      download: function download() {
        var self = this;
        if (this.get('readOnly') == false) {
          this.doSave();
        }
        $.magnificPopup.close();
        setTimeout(function () {
          self.sendAction('download', self.get('id'), self.get('patient'));
        }, 500);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});