define('jason-frontend/components/patients-edit-additional-form', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    model: null,
    store: Ember['default'].inject.service(),
    watchSlaughter: (function () {
      if (this.get('model') != null && this.get('model').get('slaughter') === true) {
        this.get('model').set('handoverDocument', true);
      }
    }).observes('model.slaughter')
  });

});