define('jason-frontend/templates/components/button-station', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 6
            },
            "end": {
              "line": 5,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/button-station.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("path");
          dom.setAttribute(el1,"d","M15,30 C23.2842713,30 30,23.2842713 30,15 C30,6.71572875 23.2842713,0 15,0 C6.71572875,0 0,6.71572875 0,15 C0,23.2842713 6.71572875,30 15,30 L15,30 L15,30 Z M15,28.5714286 C7.50470696,28.5714286 1.42857143,22.495293 1.42857143,15 C1.42857143,7.50470696 7.50470696,1.42857143 15,1.42857143 C22.495293,1.42857143 28.5714286,7.50470696 28.5714286,15 C28.5714286,22.495293 22.495293,28.5714286 15,28.5714286 L15,28.5714286 L15,28.5714286 Z");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'fill');
          return morphs;
        },
        statements: [
          ["attribute","fill",["concat",[["get","color",["loc",[null,[4,24],[4,29]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-station.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"fill-rule","evenodd");
        dom.setAttribute(el3,"transform","translate(6 6) scale(0.035, 0.035)");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M502.325,275.984c-4.375-67.815-61.12-125.902-125.62-125.902H225.623v125.902H74.541V57.754H-1v386.098h75.541v-92.328     h352.525v92.328h75.541V292.77v-8.393v-8.393H502.325z M242.41,166.869h134.295c55.397,0,104.918,50.361,109.115,109.115H242.41     V166.869z M485.82,427.066h-41.967v-92.328H57.754v92.328H15.787V74.541h41.967V292.77h167.869H485.82V427.066z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M150.082,259.197c32.734,0,58.754-26.02,58.754-58.754s-26.02-58.754-58.754-58.754s-58.754,26.02-58.754,58.754     S117.348,259.197,150.082,259.197z M150.082,158.475c23.502,0,41.967,18.466,41.967,41.967s-18.466,41.967-41.967,41.967     s-41.967-18.466-41.967-41.967S126.58,158.475,150.082,158.475z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createMorphAt(element2,1,1);
        morphs[3] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,12],[3,22]]]]],[],0,null,["loc",[null,[3,6],[5,13]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[6,19],[6,24]]]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});