define('jason-frontend/templates/components/button-toggle-dead', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-toggle-dead.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill-rule","evenodd");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("circle");
        dom.setAttribute(el3,"fill","none");
        dom.setAttribute(el3,"stroke-width","2");
        dom.setAttribute(el3,"class","circle");
        dom.setAttribute(el3,"cx","15");
        dom.setAttribute(el3,"cy","15");
        dom.setAttribute(el3,"r","14");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"fill-rule","evenodd");
        dom.setAttribute(el3,"transform","translate(7,7) scale(0.03,0.03)");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"class","icon");
        dom.setAttribute(el4,"d","M389.565,100.174h-83.072V16.696C306.493,7.479,298.608,0,289.391,0h-66.783c-9.217,0-16.289,7.479-16.289,16.696v83.478    h-83.885c-9.217,0-16.289,7.479-16.289,16.696v66.783c0,9.217,7.072,16.696,16.289,16.696h83.884v138.224    c11.13-2.935,32.833-4.659,50.087-4.659s38.957,1.724,50.087,4.659V200.348h83.072c9.217,0,17.102-7.479,17.102-16.696V116.87    C406.667,107.652,398.782,100.174,389.565,100.174z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"class","icon");
        dom.setAttribute(el4,"d","M439.207,494.174C434.381,423.032,353.913,367.304,256,367.304S77.619,423.033,72.793,494.173    c-0.315,4.609,1.293,9.153,4.457,12.533c3.152,3.38,7.576,5.294,12.196,5.294h333.109c4.619,0,9.044-1.913,12.196-5.294    C437.914,503.326,439.522,498.783,439.207,494.174z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element0, 'class');
        morphs[3] = dom.createElementMorph(element0);
        morphs[4] = dom.createAttrMorph(element2, 'stroke');
        morphs[5] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","class",["concat",["cursor state-button ",["subexpr","css-bool-evaluator",[["get","active",["loc",[null,[1,123],[1,129]]]],"active",""],[],["loc",[null,[1,102],[1,143]]]]]]],
        ["element","action",["toggle"],[],["loc",[null,[1,165],[1,184]]]],
        ["attribute","stroke",["concat",[["get","color",["loc",[null,[3,34],[3,39]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[4,15],[4,20]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});