define('jason-frontend/routes/serviceunits/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {
      params.filterMandant = "all";
      params.paged = true;
      return this.findPaged('serviceunit', params);
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          filterSource = controller.get('filterSource'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      if (!filterSource) {
        filterSource = "all";
      }

      this.findPaged('serviceunit', {
        filterName: filterName,
        filterMandant: filterSource,
        sortDir: sortDir,
        paged: true,
        sortBy: sortBy,
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    actions: {
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      'delete': function _delete(id) {
        var controller = this.get('controller'),
            self = this;
        this.get('store').find('serviceunit', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.applyFilter();
        }, 1000);
      }
    }
  });

});