define('jason-frontend/helpers/get-active-sort-class', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getActiveSortClass = getActiveSortClass;

  function getActiveSortClass(params) {

    return params[0] === params[1] ? 'activeSort' : '';
  }

  exports['default'] = Ember['default'].Helper.helper(getActiveSortClass);

});