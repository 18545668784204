define('jason-frontend/models/future-article-entry-blueprint', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    total: DS['default'].attr('number'),
    price: DS['default'].attr('number'),
    quantity: DS['default'].attr('number'),
    articleText: DS['default'].attr('string'),
    productCategory: DS['default'].attr('string'),
    serviceCategory: DS['default'].attr('string'),
    applied: DS['default'].attr('boolean'),
    article: DS['default'].belongsTo('article'),
    unitQuantity: DS['default'].attr('number'),
    tax: DS['default'].belongsTo('tax')
  });

});