define('jason-frontend/templates/components/button-ehapo', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-ehapo.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 34 34");
        dom.setAttribute(el1,"style","margin: 2px auto");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"transform","translate(3,3) scale(0.7,0.7)");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"class","icon-wrapper");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("circle");
        dom.setAttribute(el3,"cx","16");
        dom.setAttribute(el3,"cy","16");
        dom.setAttribute(el3,"r","16");
        dom.setAttribute(el3,"class","circle");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"transform","translate(3,4) scale(0.35,0.35)");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"class","icon-wrapper");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("rect");
        dom.setAttribute(el3,"x","37.631");
        dom.setAttribute(el3,"y","8.657");
        dom.setAttribute(el3,"width","6.236");
        dom.setAttribute(el3,"height","35.994");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M38.842,8.657");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("line");
        dom.setAttribute(el3,"x1","38.842");
        dom.setAttribute(el3,"y1","13.667");
        dom.setAttribute(el3,"x2","38.842");
        dom.setAttribute(el3,"y2","8.657");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("polyline");
        dom.setAttribute(el3,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el3,"points","9.537,44.651 37.651,8.669 42.879,12.754 17.942,44.675 ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M38.842,13.667");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M9.745,45.208");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M10.479,44.651");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("rect");
        dom.setAttribute(el3,"x","31.704");
        dom.setAttribute(el3,"y","31.622");
        dom.setAttribute(el3,"width","18.092");
        dom.setAttribute(el3,"height","6.236");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("line");
        dom.setAttribute(el3,"x1","2.612");
        dom.setAttribute(el3,"y1","44.319");
        dom.setAttribute(el3,"x2","9.072");
        dom.setAttribute(el3,"y2","44.2");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"transform","scale(0.72,0.72) translate(21 26)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("circle");
        dom.setAttribute(el3,"class","circle");
        dom.setAttribute(el3,"cx","8");
        dom.setAttribute(el3,"cy","8");
        dom.setAttribute(el3,"r","8");
        dom.setAttribute(el3,"fill","#FFF");
        dom.setAttribute(el3,"stroke","#ADCCD4");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","icon");
        dom.setAttribute(el3,"fill","#ADCCD4");
        dom.setAttribute(el3,"d","M8.444 7.65V3.49c0-.22-.178-.395-.396-.395-.22 0-.397.177-.397.395v4.16H3.49c-.22 0-.395.18-.395.398 0 .22.177.396.395.396h4.16v4.162c0 .218.18.394.398.394.22 0 .396-.177.396-.394V8.444h4.162c.218 0 .394-.178.394-.396 0-.22-.177-.397-.394-.397H8.444z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element0, [3]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element3, [5]);
        var element7 = dom.childAt(element3, [7]);
        var element8 = dom.childAt(element3, [9]);
        var element9 = dom.childAt(element3, [11]);
        var element10 = dom.childAt(element3, [13]);
        var element11 = dom.childAt(element3, [15]);
        var element12 = dom.childAt(element3, [17]);
        var morphs = new Array(15);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element0, 'class');
        morphs[3] = dom.createAttrMorph(element1, 'stroke');
        morphs[4] = dom.createAttrMorph(element2, 'stroke');
        morphs[5] = dom.createAttrMorph(element3, 'fill');
        morphs[6] = dom.createAttrMorph(element4, 'fill');
        morphs[7] = dom.createAttrMorph(element5, 'fill');
        morphs[8] = dom.createAttrMorph(element6, 'fill');
        morphs[9] = dom.createAttrMorph(element7, 'fill');
        morphs[10] = dom.createAttrMorph(element8, 'fill');
        morphs[11] = dom.createAttrMorph(element9, 'fill');
        morphs[12] = dom.createAttrMorph(element10, 'fill');
        morphs[13] = dom.createAttrMorph(element11, 'fill');
        morphs[14] = dom.createAttrMorph(element12, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","class",["concat",["inventory-status-button laboklin-status-",["get","cssClasses",["loc",[null,[1,144],[1,154]]]]," ",["get","cssClass",["loc",[null,[1,159],[1,167]]]]," state-button cursor"]]],
        ["attribute","stroke",["concat",[["get","color",["loc",[null,[2,15],[2,20]]]]]]],
        ["attribute","stroke",["concat",[["get","color",["loc",[null,[3,45],[3,50]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[5,15],[5,20]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[6,39],[6,44]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[7,18],[7,23]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[8,18],[8,23]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[9,57],[9,62]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[10,18],[10,23]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[11,18],[11,23]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[12,18],[12,23]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[13,40],[13,45]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[14,18],[14,23]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});