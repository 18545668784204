define('jason-frontend/routes/transactions/closings', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {
      params.type = 'z';
      return null;
    },
    setupController: function setupController(controller, model) {

      if (!API['default'].hasPermission('rc_closing')) {
        this.transitionTo('home');
      }

      controller.set('showClosingButton', true);
      controller.set('showCashRegisters', true);

      this.store.find('cashRegister').then(function (data) {
        controller.set('cashRegisters', data);
        var register = data.get('firstObject');
        if (API['default'].getPrimaryCashRegisterId() && API['default'].getPrimaryCashRegisterId() > 0) {
          data.forEach(function (item) {
            if (item.id === API['default'].getPrimaryCashRegisterId()) {
              register = item;
            }
          });
        } else if (register.get('active') == false) {
          data.forEach(function (item) {
            if (item.get('active') == true) {
              register = item;
            }
          });
        }
        controller.set('selectedRegister', register);
      });

      var type = controller.get('type');

      if (type) {
        controller.set('model', this.findPaged('closing', { type: type }));
      } else {
        controller.set('model', model);
      }
    },
    perPage: 10,
    queryParams: {
      filterRegisterId: {
        refreshModel: true
      }
    },
    retrieve: function retrieve(type) {
      var controller = this.get('controller'),
          selectedRegister = controller.get('selectedRegister');

      controller.set('showCashRegisters', true);

      if (type === 'zs' || type === 'ts' || type === 'ms' || type === 'js') {
        controller.set('showCashRegisters', false);
      }

      if (type === 'z' || type === 'zs') {
        controller.set('hideClosingButton', false);
      } else {
        controller.set('hideClosingButton', true);
      }

      if (selectedRegister) {
        selectedRegister = selectedRegister.id;
      }

      controller.set('type', type);
      controller.set('model', this.findPaged('closing', {
        type: type,
        selectedRegister: selectedRegister
      }));
    },
    actions: {
      dsfExport: function dsfExport(item) {
        $.ajax({
          url: "/api/closings/dsfExport/" + item.id,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('DSFinV-K', 'erstellt');
        }, function (data) {
          jason.notifiction.notifiy('DSFinV-K', 'erstellt');
        });
      },
      retrieve: function retrieve(type) {
        this.retrieve(type);
      },
      createClosing: function createClosing() {
        var controller = this.get('controller'),
            selectedRegister = controller.get('selectedRegister'),
            data = new Object(),
            self = this,
            type = controller.get('type');

        controller.set('showClosingButton', false);

        if (selectedRegister) {
          selectedRegister = selectedRegister.id;
        }

        data.cashRegisterId = selectedRegister;

        $.ajax({
          url: "/api/closings/create/" + type,
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Abschluss', 'erstellt');
        }, function (data) {
          if (data.status == 500) {
            jason.notifiction.error('Achtung', 'Es wird gerade eine Transaktion durchgeführt, Abschluss derzeit nicht möglich');
          } else {
            jason.notifiction.notifiy('Abschluss', 'erstellt');
            controller.set('model', self.findPaged('closing', {
              type: type,
              selectedRegister: selectedRegister
            }));
          }
          controller.set('showClosingButton', true);
        });
      }
    }
  });

});