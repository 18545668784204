define('jason-frontend/routes/inventory/warehouses', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    perPage: 10,
    queryParams: {},
    setupController: function setupController(controller, model) {
      controller.set("model", model);

      this.findPaged('inventory-warehouse', {}).then(function (data) {
        controller.set('model', data);
      });
    },
    actions: {}
  });

});