define('jason-frontend/models/purchase-sub-order', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    supplier: DS['default'].belongsTo('product-supplier'),
    entries: DS['default'].hasMany('purchaseOrderEntry'),
    subOrderId: DS['default'].attr('string'),
    count: DS['default'].attr(''),
    supplierTotal: DS['default'].attr('number'),
    confirmOrder: DS['default'].attr('boolean'),
    formMissing: DS['default'].attr('boolean'),
    missingCredentials: DS['default'].attr(''),
    richterApi: DS['default'].attr('boolean'),
    authorityId: DS['default'].attr('string'),
    authority: DS['default'].attr('boolean'),
    estimatedDeliveryCosts: DS['default'].attr('number'),
    freeHouseShippingNotReached: DS['default'].attr('boolean')
  });

});