define('jason-frontend/templates/components/edit-medical-problems-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 54,
              "column": 16
            },
            "end": {
              "line": 80,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-medical-problems-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 l-12/24@tablet l-4/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-6/24 l-12/24@tablet l-4/24@desk");
          dom.setAttribute(el2,"data-label","Produkt");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-6/24 l-14/24@tablet l-5/24@desk");
          dom.setAttribute(el2,"data-label","Dosierung");
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-6/24 l-12/24@tablet l-4/24@desk u-text--center");
          dom.setAttribute(el2,"data-label","Dauermedikation");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 l-14/24@tablet l-3/24@desk u-text--center");
          dom.setAttribute(el2,"data-label","Verabreicht");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-6/24 l-14/24@tablet l-2/24@desk u-text--center");
          dom.setAttribute(el2,"data-label","Abgesetzt");
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-2/24 l-2/24@tablet l-2/24@desk table__cell--action ");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","cursor ml10");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [13, 1]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [7, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element0, [9]),1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element0, [11]),1,1);
          morphs[6] = dom.createElementMorph(element1);
          morphs[7] = dom.createMorphAt(element1,1,1);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.created",["loc",[null,[57,38],[57,50]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[57,24],[57,73]]]],
          ["content","item.article",["loc",[null,[60,28],[60,44]]]],
          ["content","item.dosing",["loc",[null,[63,18],[63,33]]]],
          ["inline","css-bool-evaluator",[["get","item.infinitySloping",["loc",[null,[66,47],[66,67]]]],"Ja","Nein"],[],["loc",[null,[66,26],[66,81]]]],
          ["inline","css-bool-evaluator",[["get","item.applied",["loc",[null,[69,41],[69,53]]]],"Ja","Nein"],[],["loc",[null,[69,20],[69,67]]]],
          ["inline","css-bool-evaluator",[["get","item.discontinuedSloping",["loc",[null,[72,39],[72,63]]]],"Ja","Nein"],[],["loc",[null,[72,18],[72,77]]]],
          ["element","action",["editDosing",["get","item",["loc",[null,[75,63],[75,67]]]]],[],["loc",[null,[75,41],[75,69]]]],
          ["inline","button-edit",[],["color","#3B6182","size","32","content","bearbeiten"],["loc",[null,[76,20],[76,82]]]]
        ],
        locals: ["item"],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 92,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/edit-medical-problems-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-edit-medical-problems-panel");
        dom.setAttribute(el1,"class","popup-basic popup-extremely-large admin-form mfp-with-anim mfp-hide small--heading");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"id","editMedicalProblemsForm");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-heading");
        var el5 = dom.createTextNode("\n             \n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","box");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb0");
        var el6 = dom.createTextNode("Allergien / Unverträglichkeiten / Chronische Krankheiten ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","small");
        dom.setAttribute(el6,"style","display: block;margin-bottom: 15px;margin-top: -5px;");
        var el7 = dom.createTextNode("für eltiga App Benutzer sichtbar");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-24/24@desk");
        dom.setAttribute(el5,"id","allergies");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","slopingNotice");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb0");
        var el6 = dom.createTextNode("Notizen");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","small");
        dom.setAttribute(el6,"style","display: block;margin-bottom: 15px;margin-top: -5px;");
        var el7 = dom.createTextNode("nur in elovet sichtbar");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-24/24@desk");
        dom.setAttribute(el5,"id","medicalIncompatibilityNotice");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","slopingNotice");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb0 mt10 col l-8/24");
        var el6 = dom.createTextNode("Vergangene Dosierungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col mt30 l-6/24 text-right");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class"," checkbox-custom checkbox-primary field-checkbox ");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","filterInfinityValue");
        var el8 = dom.createTextNode("Nur Dauermedikationen anzeigen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col mt30 l-5/24 text-right");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class"," checkbox-custom checkbox-primary field-checkbox ");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","filterApplied");
        var el8 = dom.createTextNode("Verabreicht anzeigen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col mt30 l-5/24 text-right");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class"," checkbox-custom checkbox-primary field-checkbox ");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","filterDiscontinued");
        var el8 = dom.createTextNode("Abgesetzte anzeigen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table table--small box no-box@phone");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createComment("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Datum");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Produkt");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-5/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Dosierung");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24 u-text--center");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Dauermedikation");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-3/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Verarbreicht");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-2/24 u-text--center");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Abgesetzt");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-footer text-right");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","submit");
        dom.setAttribute(el5,"class","icon-button icon-button--success");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 1, 1]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element3, [11, 1]);
        var element5 = dom.childAt(element3, [13, 1]);
        var element6 = dom.childAt(element3, [15, 1]);
        var morphs = new Array(12);
        morphs[0] = dom.createElementMorph(element2);
        morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element3, [3, 1]),1,1);
        morphs[3] = dom.createMorphAt(dom.childAt(element3, [7, 1]),1,1);
        morphs[4] = dom.createElementMorph(element4);
        morphs[5] = dom.createMorphAt(element4,1,1);
        morphs[6] = dom.createElementMorph(element5);
        morphs[7] = dom.createMorphAt(element5,1,1);
        morphs[8] = dom.createElementMorph(element6);
        morphs[9] = dom.createMorphAt(element6,1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element3, [17, 3]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element2, [5, 1]),1,1);
        return morphs;
      },
      statements: [
        ["element","action",["confirm",["get","patient.id",["loc",[null,[3,62],[3,72]]]]],["on","submit"],["loc",[null,[3,43],[3,86]]]],
        ["inline","button-etiga-small",[],["content","","classNames","inline-icon vertical-middle","size","26","width",50],["loc",[null,[8,85],[8,178]]]],
        ["inline","textarea",[],["name","allergies","rows",2,"value",["subexpr","@mut",[["get","patient.allergies",["loc",[null,[12,57],[12,74]]]]],[],[]],"class","gui-textarea"],["loc",[null,[12,16],[12,97]]]],
        ["inline","textarea",[],["name","slopingNotice","rows",2,"value",["subexpr","@mut",[["get","patient.medicalIncompatibilityNotice",["loc",[null,[20,61],[20,97]]]]],[],[]],"class","gui-textarea"],["loc",[null,[20,16],[20,120]]]],
        ["element","action",["toggle"],[],["loc",[null,[26,77],[26,96]]]],
        ["inline","input",[],["type","checkbox","id","filterInfinityValue","name","filterInfinityValue","checked",["subexpr","@mut",[["get","filterInfinityValue",["loc",[null,[27,100],[27,119]]]]],[],[]]],["loc",[null,[27,16],[27,121]]]],
        ["element","action",["toggleApplied"],[],["loc",[null,[32,77],[32,103]]]],
        ["inline","input",[],["type","checkbox","id","filterApplied","name","filterAppliedValue","checked",["subexpr","@mut",[["get","filterAppliedValue",["loc",[null,[33,93],[33,111]]]]],[],[]]],["loc",[null,[33,16],[33,113]]]],
        ["element","action",["toggleDis"],[],["loc",[null,[38,77],[38,99]]]],
        ["inline","input",[],["type","checkbox","id","filterDiscontinued","name","filterDiscontinuedValue","checked",["subexpr","@mut",[["get","filterDiscontinuedValue",["loc",[null,[39,103],[39,126]]]]],[],[]]],["loc",[null,[39,16],[39,128]]]],
        ["block","each",[["get","items",["loc",[null,[54,24],[54,29]]]]],[],0,null,["loc",[null,[54,16],[80,23]]]],
        ["inline","button-save",[],["size","52","color","#ffffff","content","Speichern"],["loc",[null,[86,14],[86,75]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});