define('jason-frontend/routes/transactions/showclosing', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model, params) {
      var closingId = params.params['transactions.showclosing'].id;

      this.store.fetch('closing', closingId).then(function (data) {
        controller.set('model', data);

        var hostAppendix = '';
        if (location.hostname === "localhost") {
          hostAppendix = 'http://localhost:8080/';
        }

        controller.set('viewUrl', hostAppendix + '/api/closings/preview/' + data.get('token'));
        controller.set('id', closingId);

        // controller.set('viewUrl', hostAppendix + '/api/salesSlip/' + data.get('token'));
      });
    },
    actions: {
      download: function download(url) {
        window.open(url);
      },
      show: function show() {
        var self = this;
        setTimeout(function () {
          self.get('controller').get('pdfPreview').send('show');
        }, 1000);
      },
      print: function print(closing) {
        var deferred = $.ajax({
          url: "/api/closings/print/" + closing,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Belegdruck durchgeführt', '');
        }, function () {
          jason.notifiction.systemError('Fehlercode 101', 'Belegdruck konnte nicht durchgeführt werden');
        });
      }
    }
  });

});