define('jason-frontend/components/edit-todo-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    store: Ember['default'].inject.service(),
    type: null,
    repeatFilter: [{ name: "Einmalig", id: "single" }, { name: "Wiederholung", id: "repeat" }],
    actions: {
      loadInstance: function loadInstance(item) {
        this.set('mode', 'edit');
        this.set('type', 'instance');
        this.set('item', item);
      },
      load: function load(item) {
        this.set('mode', 'edit');
        this.set('type', 'template');
        this.set('item', item);
      },
      loadNew: function loadNew() {
        this.set('mode', 'create');
        this.set('type', 'template');
        var item = {
          name: null,
          repeat: 'single',
          dueTimeUnitIterations: 1,
          dueTimeUnitInterval: 1,
          time: moment().format('HH:mm'),
          dueTimeUnit: {
            id: 'day',
            name: 'Tage'
          },
          articleId: this.get('article').id
        };
        this.set('item', item);
      },
      confirm: function confirm(id) {

        if (!this.get('item').name) {
          jason.notifiction.error('Fehler', 'Bitte vergeben Sie einen Titel');
          return false;
        }

        if (this.get('type') === 'template') {
          if (!this.get('item').dueTimeUnit) {
            jason.notifiction.error('Fehler', 'Bitte wählen Sie eine Zeiteinheit');
            return false;
          }

          if (this.get('item').dueTimeUnitIterations && isNaN(this.get('item').dueTimeUnitIterations)) {
            jason.notifiction.error('Fehler', 'Bitte verwenden Sie für die Wiederholungen nur Zahlen');
            return false;
          }
          if (this.get('item').dueTimeUnitIterations && !isNaN(this.get('item').dueTimeUnitIterations) && this.get('item').dueTimeUnitIterations > 25) {
            jason.notifiction.error('Fehler', 'Es sind nur max. 50 Wiederholungen erlaubt');
            return false;
          }

          Ember['default'].set(this.get('item'), 'dueTimeUnit', {
            id: this.get('item').dueTimeUnit.id
          });
        }

        this.sendAction('confirm', this.get('item'));
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});