define('jason-frontend/components/add-reminder-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    store: Ember['default'].inject.service(),
    type: null,
    actions: {
      loadPatients: function loadPatients(patients) {
        this.set('patients', patients);
      },
      queryCustomer: function queryCustomer(query, deferred) {
        this.get('store').find('customer', { filterName: query.term }).then(deferred.resolve, deferred.reject);
      },
      reloadPatients: function reloadPatients(id) {
        this.sendAction('reloadPatients', id);
      },
      load: function load(data) {
        console.log(data);
        this.set('reminderHeadline', data.reminderHeadline);
        this.set('reminderText', data.reminderText);
        this.set('reminderClosing', data.reminderClosing);
        this.set('customerId', data.customerId);
      },
      confirm: function confirm(id) {
        var data = {
          reminderText: this.get('reminderText'),
          reminderSubject: this.get('reminderSubject'),
          reminderClosing: this.get('reminderClosing'),
          reminderHeadline: this.get('reminderHeadline'),
          reminderSalutation: this.get('reminderSalutation'),
          customerId: this.get('customerId')
        };
        this.sendAction('create', data);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      reset: function reset() {
        this.set('msgId', null);
        this.set('taskTitle', null);
        this.set('user', null);
        this.set('taskComment', null);
        this.set('taskNotice', null);
        this.set('patients', null);
        this.set('taskDueTime', moment().format("HH:mm"));
        this.set('taskDue', moment().format('DD. MM. YYYY'));
      },
      setMsgReference: function setMsgReference(msg) {
        this.set('msgId', msg);
        this.set('patient', msg.get('patient'));
        this.set('customer', msg.get('customer'));
      },
      setPatient: function setPatient(patient, customer) {
        this.set('patient', patient);
        this.set('customer', customer);
      }
    },
    watchCustomer: (function () {
      if (this.get('customer')) {
        this.sendAction('reloadPatients', this.get('customer').id);
      } else {
        this.set('patient', null);
        this.set('patients', null);
      }
    }).observes('customer')
  });

});