define('jason-frontend/components/stocktaking-check-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    externalContext: null,
    store: Ember['default'].inject.service(),
    rootFolder: null,
    previousFolder: null,
    perPage: 10,
    applyFilter: function applyFilter() {
      var stocktackingId = this.get('stocktakingId'),
          sortDir = this.get('sortDir'),
          sortBy = this.get('sortBy');

      var self = this;
      this.externalContext.findPaged('stocktakingDiffEntry', {
        stocktackingId: stocktackingId,
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10
      }).then(function (entries) {
        self.set('model', entries);
        self.set('content', entries.meta);
      });
    },
    actions: {
      gotoProduct: function gotoProduct(id) {
        this.sendAction('gotoProduct', id);
        $.magnificPopup.close();
      },
      openCorrectionPanel: function openCorrectionPanel(id) {
        this.sendAction('openCorrectionPanel', id, this.get('stocktakingId'), true);
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      reload: function reload() {
        this.applyFilter();
      },
      load: function load(stocktakingId, externalContext) {
        this.set('stocktakingId', stocktakingId);
        this.set('externalContext', externalContext);
        this.applyFilter();
      }
    }
  });

});