define('jason-frontend/routes/cash/composite', ['exports', 'ember', 'jason-frontend/api', 'moment'], function (exports, Ember, API, moment) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller) {

      if (!API['default'].hasPermission('c_payments')) {
        this.transitionTo('home');
      }

      controller.set('styleStepStart', '');
      controller.set('styleStepConfirmation', 'hidden');
      controller.set('transaction', null);
      controller.set('notice', null);
      controller.set('amount', null);
      controller.set('reason', null);
      controller.set('tax20', 0);
      controller.set('tax19', 0);
      controller.set('tax13', 0);
      controller.set('tax10', 0);
      controller.set('tax7', 0);
      controller.set('tax0', 0);
      controller.set('recieptNotice', null);
      controller.set('submitButtonEnabled', true);
      controller.set('selectedPaymentType', 'cash');

      this.store.find('cashRegister', { hideInactive: true }).then(function (data) {
        controller.set('cashRegisterList', data);
        controller.set('selectedCashRegister', data.get('firstObject'));
        if (API['default'].getPrimaryCashRegisterId() && API['default'].getPrimaryCashRegisterId() > 0) {
          data.forEach(function (item) {
            if (item.id === API['default'].getPrimaryCashRegisterId()) {
              controller.set('selectedCashRegister', item);
            }
          });
        }
      });
    },
    toggleFavoriteIntern: function toggleFavoriteIntern(id, add) {
      if (add) {
        $.ajax({
          url: "/api/compositeArticles/" + id + "/addToFavs",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {}, function (error) {});
      } else {
        $.ajax({
          url: "/api/compositeArticles/" + id + "/removeFromFavs",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {}, function (error) {});
      }
    },
    actions: {
      toggleFavorite: function toggleFavorite(product, add) {
        Ember['default'].set(product, 'favorite', add);
        this.toggleFavoriteIntern(product.id, add);
      },
      download: function download(url) {
        window.open(url);
      },
      doPayment: function doPayment() {
        var controller = this.get('controller'),
            self = this,
            selectedCashRegister = controller.get('selectedCashRegister'),
            notice = controller.get('notice'),
            recieptNotice = controller.get('recieptNotice');

        controller.set('submitButtonEnabled', false);

        if (selectedCashRegister == null) {
          jason.notifiction.error('Keine Kassa gefunden', 'Für die Buchung ist die Auswahl einer Kassa notwendig');
          return;
        }

        if (selectedCashRegister) {
          selectedCashRegister = selectedCashRegister.id;
        }

        var taxMap = Object();
        taxMap.tax20 = String(controller.get('tax20')).replace(',', '.');
        taxMap.tax19 = String(controller.get('tax19')).replace(',', '.');
        taxMap.tax13 = String(controller.get('tax13')).replace(',', '.');
        taxMap.tax10 = String(controller.get('tax10')).replace(',', '.');
        taxMap.tax7 = String(controller.get('tax7')).replace(',', '.');
        taxMap.tax0 = String(controller.get('tax0')).replace(',', '.');

        var amount = parseFloat(taxMap.tax20) + parseFloat(taxMap.tax19) + parseFloat(taxMap.tax13) + parseFloat(taxMap.tax10) + parseFloat(taxMap.tax7) + parseFloat(taxMap.tax0);

        var paymentItems = new Array();

        paymentItems.push({
          given: amount,
          change: 0,
          paymentType: 'cash'
        });

        var payload = {
          amount: amount,
          taxInfos: taxMap,
          selectedCashRegister: selectedCashRegister,
          recieptNotice: recieptNotice,
          type: 'composite',
          payments: paymentItems
        };

        if (payload.amount < 0.01) {
          jason.notifiction.error('Betrag fehlerhaft', 'Mindestbetrag von € 0,01 erforderlich');
          return;
        }

        controller.set('styleStepStart', 'hidden');
        controller.set('showLoading', true);
        controller.set('styleStepConfirmation', '');

        $.ajax({
          url: "/api/cashdesk/composite",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(payload),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('showLoading', false);
          controller.set('currentTransactionId', data);
          self.store.fetch('transaction-group', controller.get('currentTransactionId')).then(function (data2) {
            controller.set('transactionGroup', data2);
            controller.set('transactionDownloadUrl', '/api/salesSlip/' + data2.get('token'));
          });

          jason.notifiction.notifiy('Buchung erfolgreich', '');
          controller.set('submitButtonEnabled', true);
          return data;
        }, function (error) {
          controller.set('showLoading', false);
          jason.notifiction.error('Fehler', 'Es ist ein Fehler aufgetreten');
          controller.set('submitButtonEnabled', true);
        });
      },
      print: function print(transactionGroup) {
        var deferred = $.ajax({
          url: "/api/cashdesk/print/" + transactionGroup.get('id'),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Belegdruck durchgeführt', '');
        }, function () {
          jason.notifiction.systemError('Fehlercode 101', 'Belegdruck konnte nicht durchgeführt werden');
        });
      },
      showSlip: function showSlip() {
        this.get('controller').get('pdfPreview').send('show');
      },
      reset: function reset() {
        var controller = this.get('controller');
      }
    }
  });

});