define('jason-frontend/tests/pages/aupac-typeahead', ['exports', 'jason-frontend/tests/page-object'], function (exports, PageObject) {

  'use strict';



  exports['default'] = aupacTypeahead;
  var value = PageObject['default'].value;
  var customHelper = PageObject['default'].customHelper;
  var collection = PageObject['default'].collection;
  var clickable = PageObject['default'].clickable;

  function aupacTypeahead(selector, options) {
    return {
      search: function search(_search) {
        $(selector).val(_search).trigger('input');
      },
      suggestions: collection({
        scope: '', //Reset to global scope
        itemScope: '.tt-suggestion',
        item: {
          select: clickable()
        }
      }),
      value: value(selector),
      isDisabled: function isDisabled() {
        return $(selector).prop('disabled');
      },
      isEnabled: function isEnabled() {
        return !$(selector).prop('disabled');
      }
    };
  }

});