define('jason-frontend/helpers/is-professional', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isProfessionalMandant = isProfessionalMandant;

  function isProfessionalMandant() {
    return API['default'].getMandantType() === 'professional';
  }

  exports['default'] = Ember['default'].Helper.helper(isProfessionalMandant);

});