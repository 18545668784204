define('jason-frontend/components/quick-scan-charge-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      confirm: function confirm() {

        var entry = this.get('entry');
        var value = $('#qrProductInput').val();
        this.sendAction('confirm', value, entry);
        $.magnificPopup.close();
      },
      load: function load(entry) {
        var self = this;
        this.set('entry', entry);
        $('#qrProductInput').val('');

        setTimeout(function () {
          $('#qrProductInput').prop('disabled', false);
          $('#qrProductInput').focus();
          $('#qrProductInput').click();
          $('#qrProductInput').focus();
        }, 500);
      }
    },
    autoSubmitForm: function autoSubmitForm() {
      var self = this;
      Ember['default'].run.later(this, function () {
        var form = $('#productScanForm');
        if (form && !$('#qrProductInput').prop('disabled')) {
          form.submit();
        }
        if ($(document).find('#modal-quick-scan-product-panel').length != 0 && !$(document).find('#modal-quick-scan-product-panel').hasClass('mfp-hide')) {
          self.autoSubmitForm();
        }
      }, 500);
    }
  });

});