define('jason-frontend/components/treatment-info-history', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      load: function load(data, patientId) {
        this.set('historicItems', data);
        this.set('patientId', patientId);
      },
      reload: function reload() {
        this.sendAction('reload', this.get('patientId'));
      },
      saveTreatment: function saveTreatment(data) {
        this.sendAction('saveTreatment', data);
      },
      openEditPanel: function openEditPanel(patient, invoiceId) {
        this.get('getTreatmentHistoryPanel').send('open', 0, patient, invoiceId);
      }
    }
  });

});