define('jason-frontend/routes/inventory/agg-stock', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    perPage: 10,
    queryParams: {},
    setupController: function setupController(controller, model) {
      controller.set("model", model);
      controller.set('warehouses', this.store.find('inventory-warehouse'));
      this.applyFilter();
    },
    actions: {
      saveOutgoing: function saveOutgoing(data) {
        var self = this;
        $.ajax({
          url: "/api/inventoryStocks/" + data.id + "/outgoing",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Ausgangsbuchung', 'gespeichert');
          self.applyFilter();
        }, function (data) {
          jason.notifiction.notifiy('Ausgangsbuchung', 'gespeichert');
          self.applyFilter();
        });
      },
      updateLocation: function updateLocation(item) {
        var data = {
          locationInfo: item.locationInfo
        };
        $.ajax({
          url: "/api/inventoryStocks/" + item.id + "/updateLocation",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {});
      },
      updateExpiry: function updateExpiry(item) {
        var data = {
          expiryDate: item.expiryDate
        };
        if (new String(data.expiryDate).indexOf(".") == -1) {
          data.expiryDate = moment(item.expiryDate).format('DD. MM. YYYY');
        }

        $.ajax({
          url: "/api/inventoryStocks/" + item.id + "/updateExpiryDate",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {});
      },
      updateBatchNumber: function updateBatchNumber(item) {
        var data = {
          batchNumber: item.batchNumber
        };
        $.ajax({
          url: "/api/inventoryStocks/" + item.id + "/updateBatchNumber",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {});
      },
      showInvoice: function showInvoice(id) {
        this.transitionTo('invoices.show', id);
      },
      showPatient: function showPatient(id) {
        this.transitionTo('patients.edit', id, { queryParams: { selectTab: "history" } });
      },
      saveCorrection: function saveCorrection(data) {
        var self = this;
        Ember['default'].set(data, 'shouldStand', data.shouldStand.replace(',', '.'));
        $.ajax({
          url: "/api/inventoryStocks/" + data.id + "/correction",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Korrekturbuchung', 'gespeichert');
          self.applyFilter();
        }, function (data) {
          jason.notifiction.notifiy('Korrekturbuchung', 'gespeichert');
          self.applyFilter();
        });
      },
      scanProduct: function scanProduct(id) {
        var controller = this.get('controller');

        controller.set('filterCode', id);
        this.applyFilter();
      },
      scanProductId: function scanProductId(id) {
        var self = this;
        var controller = this.get('controller');

        $.ajax({
          url: "/api/products/" + id.replace('product', ''),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('filterCode', data.product.name);
          self.applyFilter();
        }, function (data) {});
      },
      changeWarehouse: function changeWarehouse(warehouse, stockId) {
        var self = this;
        var data = {
          warehouseId: warehouse.id,
          stockId: stockId
        };

        $.ajax({
          url: "/api/inventoryStocks/" + stockId + "/changeWarehouse",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.applyFilter();
        }, function (data) {
          self.applyFilter();
        });
      },
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      },
      bookout: function bookout(id, productId, productName) {
        var controller = this.get('controller');

        $.ajax({
          url: "/api/inventoryStocks/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.send('showStock', productId, productName);
        });
      },
      printBarcode: function printBarcode(id) {
        window.open('/api/inventoryRequests/barcodes/' + id + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      bookoutMovement: function bookoutMovement(id, productId, productName) {
        var controller = this.get('controller');

        $.ajax({
          url: "/api/inventoryStockMovements/simple/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.send('showStock', productId, productName);
        }, function (data) {
          controller.send('showStock', productId, productName);
        });
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          code = controller.get('filterCode'),
          status = controller.get('filterStatus'),
          warehouse = controller.get('filterWarehouse');

      if (warehouse) {
        warehouse = warehouse.id;
      }

      controller.set('showLoading', true);

      this.findPaged('aggInventoryStock', {
        filterCode: code,
        filterStatus: status,
        filterWarehouse: warehouse,
        pageSize: this.get('perPage')
      }).then(function (data) {
        var meta = data.meta;
        controller.set('model', data);
        controller.set('showLoading', false);
      });
    }
  });

});