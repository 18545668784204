define('jason-frontend/adapters/application', ['exports', 'ember-data', 'jason-frontend/api'], function (exports, DS, API) {

  'use strict';

  exports['default'] = DS['default'].RESTAdapter.extend({
    namespace: 'api',
    headers: {
      'X-AUTH-TOKEN': API['default'].getToken()
    }

  });

});