define('jason-frontend/routes/cash/tar-export', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      this.findPaged('tar-export', {
        filterFrom: from,
        filterTo: to
      }).then(function (entries) {
        controller.set('items', entries);
      });
    },
    setupController: function setupController(controller) {

      if (!API['default'].hasPermission('r_logbook')) {
        this.transitionTo('home');
      }

      controller.set('downloadToken', API['default'].getDownloadToken());
      controller.set('mandantId', API['default'].getMandant());

      this.applyFilter();
    },
    perPage: 100,
    queryParams: {
      filterRegisterId: {
        refreshModel: true
      }
    },
    actions: {
      updateProgress: function updateProgress(inProgress) {
        this.get('controller').set('inProgress', inProgress);
      },
      triggerExport: function triggerExport(data) {

        var self = this;

        var from = data.split("-")[0];
        var to = data.split("-")[1];

        var dat = {
          startDate: from,
          endDate: to
        };

        $.ajax({
          url: "/api/tarExports/triggerExport",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(dat),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Export ', 'erfolgreich angefordert');
          self.applyFilter();
          self.get('controller').set('inProgress', false);
        }, function (data) {
          jason.notifiction.notifiy('Export ', 'erfolgreich angefordert');
          self.applyFilter();
          self.get('controller').set('inProgress', false);
        });
      },
      openDownloadWizzard: function openDownloadWizzard() {
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-dsf-export-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      download: function download(item) {
        $.ajax({
          url: "/api/tarExports/downloadToken",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          window.open('/api/tarExports/' + item.id + '/download?token=' + data.responseText);
        }, function (data) {
          window.open('/api/tarExports/' + item.id + '/download?token=' + data.responseText);
        });
      }
    }
  });

});