define('jason-frontend/helpers/reset-header-class', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.resetHeaderClass = resetHeaderClass;

  function resetHeaderClass(params) {
    return params;
  }

  exports['default'] = Ember['default'].Helper.helper(resetHeaderClass);

});