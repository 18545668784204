define('jason-frontend/controllers/purchases/detail', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Produktdetails",
    breadCrumbPath: "purchases.detail",
    queryParams: ['selectTab'],
    selectTab: null,
    sidebarActive: true,
    openEditOverlay: function openEditOverlay(priceId, product, state) {
      var self = this;

      if (API['default'].isSupplier()) {

        $.ajax({
          url: "/api/mandants/" + API['default'].getMandant() + "/availableTopPromotions",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('editPurchasePricePanel').send('loadPromotionContingent', data);
        });
      }

      if (priceId) {
        this.store.fetch('purchaseProductSupplierAdminInfo', priceId).then(function (priceInfo) {
          self.get('editPurchasePricePanel').send('loadData', priceInfo, self.store.find('purchaseDeliveryStatus'), self.store.find('product-supplier', { filterPurchase: true }), product.id, state, self.store.find('promotion-category'));
        });
      } else {

        var newRecord = self.store.createRecord('purchaseProductSupplierInfo');
        newRecord.set('active', true);
        newRecord.set('activeFrom', '');
        newRecord.set('activeTo', '');
        newRecord.set('deliverableFrom', '');
        newRecord.set('deliverableTo', '');
        newRecord.set('promotionFrom', '');
        newRecord.set('promotionTo', '');
        newRecord.set('minimalOrderAmount', 0);
        newRecord.set('promotionPriceGroup1', 0);
        newRecord.set('promotionPriceGroup2', 0);
        newRecord.set('promotionPriceGroup3', 0);
        newRecord.set('promotionPriceGroup4', 0);
        newRecord.set('customerGroupPrice1', 0);
        newRecord.set('customerGroupPrice2', 0);
        newRecord.set('customerGroupPrice3', 0);
        newRecord.set('customerGroupPrice4', 0);
        newRecord.set('minimalOrderAmount', 1);
        newRecord.set('sellingAmount', 1);

        if (state === 'promotion') {
          newRecord.set('promotionRow', true);
        }

        self.store.find('purchaseDeliveryStatus', 'deliverable').then(function (status) {
          newRecord.set('deliveryStatus', status);
        });

        self.store.find('promotionCategory', 'starting').then(function (status) {
          newRecord.set('promotionCategory', status);
        });

        self.get('editPurchasePricePanel').send('loadData', newRecord, self.store.find('purchaseDeliveryStatus'), self.store.find('product-supplier', { filterPurchase: true }), product.id, state, self.store.find('promotion-category'));
      }

      $.magnificPopup.open({
        removalDelay: 500,
        closeOnBgClick: false,
        alignTop: true,
        items: {
          src: '#modal-edit-purchase-price-panel'
        },
        callbacks: {
          beforeOpen: function beforeOpen() {
            var Animation = "mfp-with-fade";
            this.st.mainClass = Animation;
          }
        },
        midClick: true
      });
    },
    actions: {
      toCreateProduct: function toCreateProduct(pzn) {
        var self = this;

        setTimeout(function () {

          var newRecord = self.get('store').createRecord('purchaseProduct');
          newRecord.foreignId = self.get('pzn');

          self.store.fetch('serviceunit', 1).then(function (unit) {
            newRecord.set('serviceunit', unit);
          });
          self.store.fetch('tax', 'tax20').then(function (tax) {
            newRecord.set('tax', tax);
          });

          self.get('editPurchaseProductPanel').send('loadData', newRecord, self.store.find('tax'), self.store.find('serviceunit'), self.store.find('productCategory'));

          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            alignTop: true,
            items: {
              src: '#modal-edit-purchase-product-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 500);
      },
      openActionInfos: function openActionInfos(info) {
        var self = this;

        self.get('promotionInfoPanel').send('loadData', info);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          alignTop: true,
          items: {
            src: '#modal-promotion-info-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openDeliveryInfos: function openDeliveryInfos(info) {
        var self = this;

        self.get('deliveryInfoPanel').send('loadData', info);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          alignTop: true,
          items: {
            src: '#modal-delivery-info-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      editPromotion: function editPromotion(product, priceId, orderId) {
        this.openEditOverlay(priceId, product, 'promotion');
      },
      edit: function edit(product, priceId, orderId) {
        this.openEditOverlay(priceId, product, 'default');
      },
      editProduct: function editProduct(product, orderId) {
        var self = this;

        var product2 = this.store.find('purchase-product', product.id);

        self.get('editPurchaseProductPanel').send('loadData', product2, self.store.find('tax'), self.store.find('serviceunit'), self.store.find('productCategory'));

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          alignTop: true,
          items: {
            src: '#modal-edit-purchase-product-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      checkLaboklinPanel: function checkLaboklinPanel(product, orderId, info, supplierId, modelName) {

        if (API['default'].isSupplier() || API['default'].getMandant() == 4) {
          return;
        }

        if (supplierId === 'laboklin') {
          var datas = this.get('order').get('laboklinDatas');

          if (datas && datas.currentState.length == 0) {

            this.get('laboklinTreatmentPanel').send('load', product.id, orderId, null, null, null, this.store.find('patient-category'), orderId, product, info.id);
            Ember['default'].run.later(function () {
              $.magnificPopup.open({
                removalDelay: 500,
                closeOnBgClick: false,
                items: {
                  src: '#modal-form-laboklin-treatment-panel'
                },
                callbacks: {
                  beforeOpen: function beforeOpen() {
                    var Animation = "mfp-with-fade";
                    this.st.mainClass = Animation;
                  }
                },
                midClick: true
              });
            }, 500);
          } else {
            this.get('laboklinChooser').send('load', info.id);

            $.magnificPopup.open({
              removalDelay: 500,
              closeOnBgClick: false,
              items: {
                src: '#modal-form-laboklin-chooser'
              },
              callbacks: {
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: true
            });
          }
        } else {

          var deliveryStatus = info.deliveryStatus.id;

          if (deliveryStatus === 'notdeliverable' && supplierId === 'jac') {

            $.magnificPopup.open({
              removalDelay: 500,
              closeOnBgClick: false,
              items: {
                src: '#modal-notdeliverable-confirm-panel'
              },
              callbacks: {
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: true
            });

            this.get('notDeliverableConfirm').send('load', product, info, orderId, modelName);
          } else {
            this.send('addProduct', product, orderId, info.id, modelName);
          }
        }
      },
      newLaboklinForm: function newLaboklinForm(product, orderId, priceInfoId, productName) {
        var self = this;

        self.store.find('patient-category').then(function (data) {
          self.get('laboklinTreatmentPanel').send('load', product.id, orderId, null, null, null, data, orderId, product, priceInfoId);
        });

        setTimeout(function () {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-form-laboklin-treatment-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 700);
      },
      addProduct: function addProduct(model, orderId, infoId, productName, formId) {
        var quantity = $("span[data-qty-entry-id=" + infoId + "] > input").val(),
            productId = this.get('model').id;

        quantity = String(quantity).replace(',', '.');

        var self = this;

        if (quantity <= 0) {
          jason.notifiction.error('Fehler', 'Menge muss größer 0 sein');
          return;
        }

        var deferred = jQuery.ajax({
          url: "/api/purchaseOrders/" + orderId + "/add/product/" + productId + "/" + infoId + "/" + quantity,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (entryId) {
          self.set("order", self.store.fetch('purchaseOrder', orderId));
          jason.notifiction.notifiy('Produkt(e) hinzugefügt', productName);

          if (formId) {
            jQuery.ajax({
              url: "/api/purchaseOrders/" + orderId + "/linkLaboklinForm/" + entryId + "/" + formId,
              method: "POST",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then();
          }
        }, function (error) {
          jason.notifiction.errorLong('Achtung', error.responseText);
          return { status: error.statusText, message: error.responseText };
        });
        return Ember['default'].RSVP.resolve(deferred);
      }
    }
  });

});