define('jason-frontend/templates/components/cashback-bar', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 4,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/cashback-bar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          var el2 = dom.createTextNode("Cashback Status ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("b");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [0]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
          morphs[1] = dom.createMorphAt(element4,3,3);
          morphs[2] = dom.createMorphAt(element4,5,5);
          return morphs;
        },
        statements: [
          ["content","supplier.acronym",["loc",[null,[3,25],[3,45]]]],
          ["content","stats.month",["loc",[null,[3,50],[3,65]]]],
          ["content","stats.year",["loc",[null,[3,66],[3,80]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 2
            },
            "end": {
              "line": 10,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/cashback-bar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","cashbackLabel");
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]),0,0);
          return morphs;
        },
        statements: [
          ["inline","format-currency",[],["onlyFull",true,"value",["subexpr","@mut",[["get","threshold.cashback",["loc",[null,[9,75],[9,93]]]]],[],[]]],["loc",[null,[9,37],[9,95]]]]
        ],
        locals: ["threshold"],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 0
            },
            "end": {
              "line": 15,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/cashback-bar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","thresholdBar ");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","bar");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1, 0]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element3, 'style');
          return morphs;
        },
        statements: [
          ["attribute","style",["concat",["width: ",["get","threshold.percentageSum",["loc",[null,[14,62],[14,85]]]],"%"]]]
        ],
        locals: ["threshold"],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 2
            },
            "end": {
              "line": 18,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/cashback-bar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","currentThresholdBar");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","bar");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element2, 'style');
          return morphs;
        },
        statements: [
          ["attribute","style",["concat",["left: ",["get","currentRevenueStart",["loc",[null,[17,53],[17,72]]]],"px; width: ",["get","currentRevenueWidth",["loc",[null,[17,87],[17,106]]]],"px;"]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 2
            },
            "end": {
              "line": 23,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/cashback-bar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["thresholdBarBorder ",["subexpr","css-bool-evaluator",[["subexpr","gte",[["get","threshold.percentageSum",["loc",[null,[22,61],[22,84]]]],99],[],["loc",[null,[22,56],[22,88]]]],"full"],[],["loc",[null,[22,35],[22,97]]]]]]]
        ],
        locals: ["threshold"],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 0
            },
            "end": {
              "line": 29,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/cashback-bar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","thresholdLabel");
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]),0,0);
          return morphs;
        },
        statements: [
          ["inline","format-currency",[],["onlyFull",true,"value",["subexpr","@mut",[["get","threshold.threshold",["loc",[null,[28,74],[28,93]]]]],[],[]]],["loc",[null,[28,36],[28,95]]]]
        ],
        locals: ["threshold"],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 0
            },
            "end": {
              "line": 39,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/cashback-bar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","legendWrapper");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-2/24");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","legend");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-22/24");
          var el3 = dom.createTextNode("  Aktuelles Monat: ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-2/24");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","legend current");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-22/24");
          var el3 = dom.createTextNode("Aktuelle Bestellung: ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [3]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [7]),1,1);
          return morphs;
        },
        statements: [
          ["inline","format-currency",[],["value",["subexpr","minus",[["get","currentRevenue",["loc",[null,[35,82],[35,96]]]],["get","revenueOrder",["loc",[null,[35,97],[35,109]]]]],[],["loc",[null,[35,75],[35,110]]]]],["loc",[null,[35,51],[35,112]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","revenueOrder",["loc",[null,[37,77],[37,89]]]]],[],[]]],["loc",[null,[37,53],[37,91]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 41,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/cashback-bar.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","thresholdCashbackBar");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","firstLabel");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("Cashback");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","thresholdBorderBar");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","thresholdLabelBar");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","firstLabel");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" Bestellungen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var element6 = dom.childAt(element5, [3]);
        var element7 = dom.childAt(element5, [5]);
        var element8 = dom.childAt(element5, [9]);
        var morphs = new Array(12);
        morphs[0] = dom.createAttrMorph(element5, 'class');
        morphs[1] = dom.createMorphAt(element5,1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
        morphs[3] = dom.createMorphAt(element6,3,3);
        morphs[4] = dom.createAttrMorph(element7, 'class');
        morphs[5] = dom.createAttrMorph(element7, 'data-reduction-sum');
        morphs[6] = dom.createMorphAt(element7,1,1);
        morphs[7] = dom.createMorphAt(element7,2,2);
        morphs[8] = dom.createMorphAt(dom.childAt(element5, [7]),1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
        morphs[10] = dom.createMorphAt(element8,3,3);
        morphs[11] = dom.createMorphAt(element5,11,11);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",[["get","hidden",["loc",[null,[1,14],[1,20]]]]]]],
        ["block","if",[["get","showLabel",["loc",[null,[2,6],[2,15]]]]],[],0,null,["loc",[null,[2,0],[4,7]]]],
        ["inline","button-cashback",[],["showStroke",false,"content","","color","#008EC6"],["loc",[null,[7,26],[7,89]]]],
        ["block","each",[["get","thresholds",["loc",[null,[8,10],[8,20]]]]],[],1,null,["loc",[null,[8,2],[10,11]]]],
        ["attribute","class",["concat",["cashbackBar ",["get","cssClass",["loc",[null,[12,26],[12,34]]]]]]],
        ["attribute","data-reduction-sum",["concat",[["get","reductionSum",["loc",[null,[12,60],[12,72]]]]]]],
        ["block","each",[["get","thresholds",["loc",[null,[13,8],[13,18]]]]],[],2,null,["loc",[null,[13,0],[15,9]]]],
        ["block","if",[["subexpr","and",[["subexpr","gte",[["get","currentRevenueStart",["loc",[null,[16,18],[16,37]]]],0],[],["loc",[null,[16,13],[16,40]]]],["subexpr","gte",[["get","currentRevenueWidth",["loc",[null,[16,46],[16,65]]]],0],[],["loc",[null,[16,41],[16,68]]]]],[],["loc",[null,[16,8],[16,69]]]]],[],3,null,["loc",[null,[16,2],[18,9]]]],
        ["block","each",[["get","thresholds",["loc",[null,[21,10],[21,20]]]]],[],4,null,["loc",[null,[21,2],[23,11]]]],
        ["inline","button-add-cart",[],["showStroke",false,"content",""],["loc",[null,[26,26],[26,73]]]],
        ["block","each",[["get","thresholds",["loc",[null,[27,8],[27,18]]]]],[],5,null,["loc",[null,[27,0],[29,9]]]],
        ["block","if",[["get","showLegend",["loc",[null,[32,6],[32,16]]]]],[],6,null,["loc",[null,[32,0],[39,7]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  }()));

});