define('jason-frontend/routes/users/edit', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('user', params.id);
    },
    actions: {
      changeImage: function changeImage(id) {
        this.controller.set('uploadedAssets', id);
      },
      save: function save() {
        var self = this;

        var image = self.controller.get('uploadedAssets');
        var model = self.controller.get('model');

        if (image) {
          model.uploadedPicture = image;
        }

        model.save().then(function () {
          self.transitionTo('users');
        }, function (error) {
          var error = error.errors[0];
          jason.notifiction.error(error.title, error.body);
        });
      },
      'delete': function _delete() {
        var self = this;
        var model = self.controller.get('model');
        model.destroyRecord().then(function () {
          self.transitionTo('users');
        }, function (error) {
          Ember['default'].Logger.debug(error);
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('genders', this.store.find('gender'));
      controller.set('titles', this.store.find('title'));
      controller.set('userRoles', this.store.find('userRole'));
      controller.set('cashRegisters', this.store.find('cashRegister'));
      controller.set('inventoryWarehouses', this.store.find('inventoryWarehouse'));
      if (model.get('picture')) {
        controller.set('userImage', model.get('picture').get('cdnId'));
      } else {
        controller.set('userImage', null);
      }
      controller.set('uploadedAssets', null);
    }
  });

});