define('jason-frontend/templates/components/document-tag-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 8
            },
            "end": {
              "line": 18,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/document-tag-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createAttrMorph(element1, 'style');
          morphs[2] = dom.createElementMorph(element1);
          morphs[3] = dom.createMorphAt(element1,0,0);
          morphs[4] = dom.createMorphAt(element1,2,2);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[17,45],[17,57]]]],"hidden",""],[],["loc",[null,[17,24],[17,72]]]]," info-badge cursor with-delete-button"]]],
          ["attribute","style",["concat",["background-color: ",["get","tag.color",["loc",[null,[17,138],[17,147]]]]]]],
          ["element","action",["addTag",["get","tag",["loc",[null,[17,169],[17,172]]]]],[],["loc",[null,[17,151],[17,174]]]],
          ["content","tag.name",["loc",[null,[17,175],[17,187]]]],
          ["inline","icon-plus",[],["fill",true,"size",22,"color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[17,188],[17,277]]]]
        ],
        locals: ["tag"],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 10
            },
            "end": {
              "line": 24,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/document-tag-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","info-badge with-delete-button cursor");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element0, 'style');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0,0,0);
          morphs[3] = dom.createMorphAt(element0,2,2);
          return morphs;
        },
        statements: [
          ["attribute","style",["concat",["background-color: ",["get","tag.color",["loc",[null,[23,118],[23,127]]]]]]],
          ["element","action",["removeTag",["get","tag",["loc",[null,[23,85],[23,88]]]]],[],["loc",[null,[23,64],[23,90]]]],
          ["content","tag.name",["loc",[null,[23,131],[23,143]]]],
          ["inline","icon-delete2",[],["fill",true,"color","#EF5F4E","content","","size",22,"classNames","inline-block vertical-middle"],["loc",[null,[23,144],[23,247]]]]
        ],
        locals: ["tag"],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 30,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/document-tag-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","document-tag-panel popup-basic popup-large admin-form mfp-with-anim mfp-hide small--heading");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("Tags zuweisen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"class","form-grid new-style");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-body");
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-24/24@desk l-24/24@phone gutter");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","name");
        dom.setAttribute(el6,"class","field-label");
        var el7 = dom.createTextNode("Datei");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        dom.setAttribute(el5,"class","mb10 mt20");
        var el6 = dom.createTextNode("Verfügbar:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        dom.setAttribute(el5,"class","mb10 mt20");
        var el6 = dom.createTextNode("Ausgewählt:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card pl10");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [1, 3]);
        var element4 = dom.childAt(element3, [1]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element2, 'id');
        morphs[1] = dom.createAttrMorph(element3, 'id');
        morphs[2] = dom.createElementMorph(element3);
        morphs[3] = dom.createMorphAt(dom.childAt(element4, [1]),3,3);
        morphs[4] = dom.createMorphAt(element4,5,5);
        morphs[5] = dom.createMorphAt(dom.childAt(element4, [9]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","id",["concat",["modal-document-tag-panel",["get","prefix",["loc",[null,[1,35],[1,41]]]]]]],
        ["attribute","id",["concat",["documentTagPanel",["get","prefix",["loc",[null,[7,32],[7,38]]]]]]],
        ["element","action",["save"],["on","submit"],["loc",[null,[7,70],[7,99]]]],
        ["inline","media-item",[],["showPath",false,"smallView",false,"document",["subexpr","@mut",[["get","document",["loc",[null,[12,63],[12,71]]]]],[],[]]],["loc",[null,[12,10],[12,73]]]],
        ["block","each",[["get","tags",["loc",[null,[16,16],[16,20]]]]],[],0,null,["loc",[null,[16,8],[18,17]]]],
        ["block","each",[["get","document.tags",["loc",[null,[22,18],[22,31]]]]],[],1,null,["loc",[null,[22,10],[24,19]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});