define('jason-frontend/models/logbook-entry-detailed', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    userFlat: DS['default'].attr('string'),
    cashRegisterFlat: DS['default'].attr('string'),
    transactionId: DS['default'].attr('number'),
    transactionCode: DS['default'].attr('number'),
    created: DS['default'].attr('date'),
    info: DS['default'].attr('string'),
    article: DS['default'].attr('string'),
    quantity: DS['default'].attr('string'),
    action: DS['default'].attr('string'),
    subAction: DS['default'].attr('string'),
    paymentTypes: DS['default'].attr('string'),
    grandTotalRksv: DS['default'].attr('number'),
    tax20: DS['default'].attr('number'),
    tax13: DS['default'].attr('number'),
    tax10: DS['default'].attr('number'),
    tax0: DS['default'].attr('number')
  });

});