define('jason-frontend/components/finish-task-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    type: null,
    selectTab: 'msg',
    actions: {
      confirm: function confirm() {

        var taskData = {
          doneAtInput: moment(this.get('doneAt'), 'DD. MM. YYYY').format('YYYY-MM-DD') + ' ' + this.get('doneAtTime'),
          finishTaskAction: this.get('finishTaskAction'),
          id: this.get('id'),
          task: this.get('task')
        };

        try {
          if (this.get('task').get('patient')) {
            taskData.patient = {
              id: this.get('task').get('patient').id
            };
          }
        } catch (e) {
          if (this.get('task').patient) {
            taskData.patient = {
              id: this.get('task').patient.id
            };
          }
        }

        this.sendAction('confirm', taskData);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      reset: function reset(task) {
        this.set('id', task.id);
        this.set('task', task);
        this.set('finishTaskAction', 'nothing');
        this.set('doneAtTime', moment().format("HH:mm"));
        this.set('doneAt', moment().format('DD. MM. YYYY'));
      }
    }
  });

});