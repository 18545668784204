define('jason-frontend/components/edit-past-actions-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      confirm: function confirm() {
        this.sendAction('save', this.get('item'));
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(patient) {
        this.set('item', patient);
      }
    }
  });

});