define('jason-frontend/templates/components/toggle-favorite-button2', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/toggle-favorite-button2.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element0, 'data-selected');
        morphs[1] = dom.createAttrMorph(element0, 'data-id');
        morphs[2] = dom.createAttrMorph(element0, 'class');
        morphs[3] = dom.createElementMorph(element0);
        morphs[4] = dom.createMorphAt(element0,1,1);
        return morphs;
      },
      statements: [
        ["attribute","data-selected",["concat",[["get","selected",["loc",[null,[1,40],[1,48]]]]]]],
        ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[1,63],[1,70]]]]]]],
        ["attribute","class",["concat",["verticaltop btn-blub btn-default selectable-item favs ",["subexpr","css-bool-evaluator",[["get","selected",["loc",[null,[1,156],[1,164]]]],"selected",""],[],["loc",[null,[1,135],[1,180]]]]]]],
        ["element","action",["toggle"],[],["loc",[null,[1,3],[1,22]]]],
        ["inline","button-favorite",[],["size","22","color","#999999","showStroke",false,"content",""],["loc",[null,[2,2],[2,76]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});