define('jason-frontend/templates/components/button-in-treatment', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 2
            },
            "end": {
              "line": 7,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/button-in-treatment.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("path");
          dom.setAttribute(el1,"d","M15,30 C23.2842713,30 30,23.2842713 30,15 C30,6.71572875 23.2842713,0 15,0 C6.71572875,0 0,6.71572875 0,15 C0,23.2842713 6.71572875,30 15,30 L15,30 L15,30 Z M15,28.5714286 C7.50470696,28.5714286 1.42857143,22.495293 1.42857143,15 C1.42857143,7.50470696 7.50470696,1.42857143 15,1.42857143 C22.495293,1.42857143 28.5714286,7.50470696 28.5714286,15 C28.5714286,22.495293 22.495293,28.5714286 15,28.5714286 L15,28.5714286 L15,28.5714286 Z");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'fill');
          return morphs;
        },
        statements: [
          ["attribute","fill",["concat",[["get","color",["loc",[null,[6,18],[6,23]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-in-treatment.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(-1 -2) scale(1.1, 1.1)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","icon");
        dom.setAttribute(el3,"d","M19.538 8.005c.07.012.132.047.18.1l3.605 3.605c.015.012.028.026.04.04l.877.857c.083.08.116.2.086.314-.03.113-.118.2-.23.23-.113.03-.233-.002-.314-.085l-.678-.677-1.793 1.793.18.18.5.498c.023.023.043.05.058.08l.837.837c.093.078.133.203.103.322-.03.118-.125.208-.244.233-.12.025-.243-.02-.317-.117l-.658-.677-7.91 7.89c-.087.09-.22.122-.34.08l-2.967-1.276L8.9 23.884c-.08.093-.205.132-.323.102-.118-.03-.21-.124-.234-.244-.024-.12.02-.242.118-.317l1.654-1.653-1.275-2.95c-.058-.12-.034-.262.06-.358l7.908-7.89-.697-.677c-.102-.1-.125-.257-.055-.383s.215-.19.355-.155c.062.016.117.05.16.1l.896.895.697.678 1.793-1.793-.677-.678c-.11-.092-.145-.247-.084-.377.06-.13.2-.205.342-.18zm.857 1.673l-1.793 1.813 2.25 2.252 1.794-1.793-2.25-2.272zm-3.148 1.355l-.917.916 1.136 1.115-.438.438-1.136-1.115-.916.916 1.135 1.116-.437.438-1.136-1.116-.896.897 1.116 1.135-.438.438-1.136-1.116-.896.897 1.135 1.116-.457.458-1.135-1.116-.877.877 1.117 1.134-.46.46-1.134-1.117-.976.976 1.215 2.81 2.87 1.235 7.73-7.73-.6-.618-.038-.02-2.75-2.75c-.033-.027-.06-.06-.08-.1l-.597-.577z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1, 1]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createAttrMorph(element2, 'fill');
        morphs[3] = dom.createMorphAt(element1,3,3);
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[3,31],[3,36]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[5,8],[5,18]]]]],[],0,null,["loc",[null,[5,2],[7,9]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});