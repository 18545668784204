define('jason-frontend/components/edit-thresholds-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    item: null,
    supplier: null,
    actions: {
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(line) {

        if (line) {
          this.set('item', line);
        } else {
          var line = {
            percentageWarning: 85
          };
          this.set('item', line);
        }
      },
      confirm: function confirm() {
        //TODO comma werte

        var item = this.get('item');

        var data = {
          id: item.id,
          percentageWarning: item.percentageWarning,
          cashback: item.cashback,
          threshold: item.threshold
        };

        if (item.validFromYear) {
          data.validFromYear = item.validFromYear;
        }
        if (item.validFromMonth) {
          data.validFromMonth = item.validFromMonth;
        }
        if (item.validToYear) {
          data.validToYear = item.validToYear;
        }
        if (item.validToMonth) {
          data.validToMonth = item.validToMonth;
        }

        this.sendAction('saveThreshold', data);
        $.magnificPopup.close();
      }
    }
  });

});