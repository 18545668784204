define('jason-frontend/models/task', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    title: DS['default'].attr('string'),
    due: DS['default'].attr('date'),
    notice: DS['default'].attr('string'),
    editable: DS['default'].attr('boolean'),
    doneAt: DS['default'].attr('date'),
    startedAt: DS['default'].attr('date'),
    user: DS['default'].belongsTo('user'),
    originalUser: DS['default'].belongsTo('user'),
    delegatedBy: DS['default'].belongsTo('user'),
    patient: DS['default'].belongsTo('patient'),
    customer: DS['default'].belongsTo('customer'),
    message: DS['default'].belongsTo('msg'),
    taskStatus: DS['default'].belongsTo('task-status')
  });

});