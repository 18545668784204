define('jason-frontend/components/app-denial-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    type: null,
    showTitle: true,
    actions: {
      load: function load(app) {
        Ember['default'].set(app, 'notificationOption', true);
        this.set('app', app);
      },
      gotoCustomer: function gotoCustomer(id) {
        this.sendAction('gotoCustomer', id);
        $.magnificPopup.close();
      },
      gotoPatient: function gotoPatient(id) {
        this.sendAction('gotoPatient', id);
        $.magnificPopup.close();
      },
      gotoPatientEtiga: function gotoPatientEtiga(id) {
        this.sendAction('gotoPatientEtiga', id);
        $.magnificPopup.close();
      },
      confirm: function confirm() {
        var inform = this.get('app').notificationOption;
        this.sendAction('confirm', this.get('app'), false, this.get('app').denialText, inform);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});