define('jason-frontend/components/change-task-owner-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    type: null,
    actions: {
      confirm: function confirm() {

        var taskData = {
          id: this.get('id')
        };

        if (this.get('user')) {
          taskData.user = {
            id: this.get('user').id
          };
        } else {
          jason.notifiction.error('Fehler', 'Bitte wählen Sie eine(n) Verantwortliche(n) für die Aufgabe');
          return false;
        }

        this.sendAction('confirm', taskData);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      reset: function reset(item, userId) {
        this.set('id', item.id);
        var self = this;
        if (this.get('users')) {
          this.get('users').forEach(function (item) {
            if (item.id === userId) {
              self.set('user', item);
            }
          });
        }
      }
    }
  });

});