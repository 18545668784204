define('jason-frontend/components/top-promotion-label', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    side: 'top',
    timer: 0,
    distance: 50,
    theme: 'tooltipster-punk'
  });

});