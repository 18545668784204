define('jason-frontend/routes/tasks', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    perPage: 10,
    queryParams: {},
    setupController: function setupController(controller, model) {
      controller.set("model", model);
      var self = this;
      this.store.find('user', { per_page: 100, addAll: true }).then(function (data) {
        controller.set('users', data);
        var selectedUser = false;
        data.forEach(function (item) {
          if (item.id === API['default'].getUserId()) {
            setTimeout(function () {
              controller.set('filterUser', item.id);
              self.selectTab('open');
              selectedUser = true;
            }, 50);
          }
        });
        if (!selectedUser) {
          self.selectTab('open');
          controller.set('filterUser', 9999);
        }
      });
    },

    selectTab: function selectTab(selected) {

      var controller = this.get('controller');

      if (!selected) {
        selected = controller.get('selectTab');
      }

      controller.set('selectTab', selected);

      var customerId = null;

      if (controller.get('filterCustomer')) {
        customerId = controller.get('filterCustomer').id;
      }
      var patientId = null;

      if (controller.get('filterPatient')) {
        patientId = controller.get('filterPatient').id;
      }

      if (selected === 'open') {
        controller.set('showLoading', true);
        this.findPaged('task', {
          perPage: 10,
          type: "open",
          userId: controller.get('filterUser'),
          customerId: customerId,
          page: controller.get('page'),
          patientId: patientId,
          sortDir: controller.get('sortDir')
        }).then(function (msgs) {
          controller.set('model', msgs);
          controller.set('showLoading', false);
        });
      }
      if (selected === 'done') {
        controller.set('showLoading', true);
        this.findPaged('task', {
          perPage: 10,
          type: "done",
          userId: controller.get('filterUser'),
          page: controller.get('page'),
          customerId: customerId,
          patientId: patientId,
          sortDir: controller.get('sortDir')
        }).then(function (msgs) {
          controller.set('model', msgs);
          controller.set('showLoading', false);
        });
      }
    },
    actions: {
      deleteTask: function deleteTask(id) {
        var self = this;
        var controller = this.get('controller');

        $.ajax({
          url: "/api/tasks/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Aufgabe', 'gelöscht');
          self.selectTab(controller.get('selectTab'));
        }, function (data) {
          jason.notifiction.notifiy('Aufgabe', 'gelöscht');
          self.selectTab(controller.get('selectTab'));
        });
      },
      queryCustomer: function queryCustomer(query, deferred) {
        this.get('store').find('customer', { filterName: query.term }).then(deferred.resolve, deferred.reject);
      },
      linkTo: function linkTo(to, id) {
        this.transitionTo(to, id);
      },
      reloadPatients: function reloadPatients(customerId) {
        var self = this;
        $.ajax({
          url: "/api/patients/forcustomer/" + customerId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('addTaskPanel').send('loadPatients', data.patient);
        });
      },
      createNotification: function createNotification(data) {
        var self = this,
            controller = this.get('controller');
        $.ajax({
          url: "/api/msgs",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.selectTab(controller.get('selectTab'));
          jason.notifiction.notifiy('Nachricht', 'verschickt');
        });
      },
      setSortDir: function setSortDir() {
        var controller = this.get('controller');
        controller.set('sortDir', controller.get('sortDir') === "asc" ? "desc" : "asc");
        this.selectTab(null);
      },
      startTask: function startTask(taskData) {
        var self = this,
            controller = this.get('controller');

        var task = {
          id: taskData.id
        };

        $.ajax({
          url: "/api/tasks/start",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(task),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Aufgabe', 'gestartet');
          self.selectTab(controller.get('selectTab'));
        });
      },
      goto: function goto(to, task) {
        if (to === 'admission') {
          this.transitionTo('practicemgmt.admission');
        } else if (to === 'patienttreatments') {
          try {
            this.transitionTo('patients.edit', task.get('patient').id, {
              queryParams: {
                selectTab: 'history'
              }
            });
          } catch (e) {
            this.transitionTo('patients.edit', task.patient.id, {
              queryParams: {
                selectTab: 'history'
              }
            });
          }
        }
      },
      finishTaskSend: function finishTaskSend(taskData) {
        var self = this,
            controller = this.get('controller');

        var action = taskData.finishTaskAction;

        $.ajax({
          url: "/api/tasks/finish",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(taskData),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Aufgabe', 'erledigt');
          self.selectTab(controller.get('selectTab'));
          if (action === 'notbillable') {
            setTimeout(function () {
              controller.send('editTreatment', data, taskData.patient, taskData.task);
            }, 500);
          }
          if (action === 'billable') {
            self.transitionTo('practicemgmt.treatment-details', data, { queryParams: { entry: "true" } });
          }
        }, function (data) {});
      },
      saveTreatment: function saveTreatment(data) {
        $.ajax({
          url: "/api/patientTreatmentInfos",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
        }, function () {
          jason.notifiction.systemError('Fehlercode 109', 'Eingaben konnte nicht gespeichert werden');
        });
      },
      changedTaskOwner: function changedTaskOwner(taskData) {
        var self = this,
            controller = this.get('controller');

        $.ajax({
          url: "/api/tasks/changedTaskOwner",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(taskData),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Aufgabe', 'Verantwortliche(n) geändert');
          self.selectTab(controller.get('selectTab'));
        });
      },
      addReply: function addReply(data) {
        var controller = this.get('controller');
        var self = this;
        $.ajax({
          url: "/api/messageReplies",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Antwort', 'verschickt');
        }, function () {
          jason.notifiction.notifiy('Antwort', 'verschickt');
          $.ajax({
            url: "/api/tasks/" + data.taskId + "/history",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.get('showTaskPanel').send('loadHistory', data.taskHistory);
          });
        });
      },
      openTask: function openTask(taskData) {
        var self = this,
            controller = this.get('controller');

        var task = {
          id: taskData.id
        };

        $.ajax({
          url: "/api/tasks/open",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(task),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Aufgabe', 'geöffnet');
          self.selectTab(controller.get('selectTab'));
        });
      },
      selectTab: function selectTab(selected) {
        this.selectTab(selected);
      },
      create: function create(data) {
        var controller = this.get('controller');
        var self = this;
        $.ajax({
          url: "/api/tasks",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.selectTab(controller.get('selectTab'));
          jason.notifiction.notifiy('Aufgabe', 'erstellt');
        });
      }
    }
  });

});