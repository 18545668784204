define('jason-frontend/components/services-edit-form', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      addTag: function addTag(tag) {
        if (!this.get('model').tags) {
          this.get('model').tags = [];
        }
        Ember['default'].set(tag, 'selected', true);
        this.get('model').tags.addObject(tag);
      },
      removeTag: function removeTag(tag) {
        try {
          if (tag.global === true) {
            return;
          }
        } catch (e) {
          if (tag.get('global') === true) {
            return;
          }
        }
        try {
          this.get('model').tags.removeObject(tag);
        } catch (e) {}
        this.get('articleTags').forEach(function (item) {
          var id = item.id;
          if (id == tag.id) {
            Ember['default'].set(item, 'selected', false);
          }
        });
      },
      toggleFavorite: function toggleFavorite(model) {
        try {
          if (model.get('favorite')) {
            Ember['default'].set(model, 'favorite', false);
          } else {
            Ember['default'].set(model, 'favorite', true);
          }
        } catch (e) {
          if (model.favorite) {
            Ember['default'].set(model, 'favorite', false);
          } else {
            Ember['default'].set(model, 'favorite', true);
          }
        }
      }
    }

  });

});