define('jason-frontend/routes/bankingaccounts/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('bankingAccount', params.id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },
    actions: {
      save: function save(id) {
        var self = this;

        var controller = this.get('controller'),
            model = controller.get('model'),
            bankingAccount = new Object();

        bankingAccount.id = id;
        bankingAccount.number = model.get('number');
        bankingAccount.iban = model.get('iban');
        bankingAccount.bankName = model.get('bankName');

        $.ajax({
          url: "/api/bankingAccounts/" + id,
          method: "PUT",
          data: JSON.stringify(bankingAccount),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.transitionTo('mandants.edit', { queryParams: { selectTab: "accounts" } });
        }, function () {
          self.transitionTo('mandants.edit', { queryParams: { selectTab: "accounts" } });
        });
      }
    }
  });

});