define('jason-frontend/routes/resourcemgmt/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('appointment-layerplan', params.id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.store.find('user', { per_page: 100, addAll: true }).then(function (data) {
        controller.set('users', data);
      });
    },
    actions: {

      save: function save(id) {
        var self = this;

        var controller = this.get('controller'),
            model = controller.get('model'),
            bankingAccount = new Object();

        bankingAccount.id = model.get('id');
        bankingAccount.name = model.get('name');
        bankingAccount.start = model.get('start');
        bankingAccount.duration = new String(model.get('duration')).replace(',', '.');

        if (!bankingAccount.start || new String(bankingAccount.start).indexOf(':') == -1) {
          jason.notifiction.error("Fehler", "Startzeit bitte im Format 08:00");
          return;
        }
        if (!bankingAccount.duration || bankingAccount.duration <= 0) {
          jason.notifiction.error("Fehler", "Bitte geben Sie eine Dauer > 0 Stunden ein");
          return;
        }

        $.ajax({
          url: "/api/appointmentLayerplans",
          method: "POST",
          data: JSON.stringify(bankingAccount),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.transitionTo('layerplans');
        }, function () {
          self.transitionTo('layerplans');
        });
      }
    }
  });

});