define('jason-frontend/controllers/purchases/show', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Details",
    breadCrumbPath: "purchases.show",
    sidebarActive: true,
    actions: {
      openLaboklin: function openLaboklin(order, laboklinFormId) {

        var self = this;

        $.ajax({
          url: "/api/purchaseOrders/" + order.id + "/laboklinForm/" + laboklinFormId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('laboklinTreatmentPanel').send('load', self.get('model').id, 0, data, null, null, self.store.find('patient-category'), null, null);
        });

        Ember['default'].run.later(function () {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-form-laboklin-treatment-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 1000);
      }
    }
  });

});