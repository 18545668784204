define('jason-frontend/components/consent-upload-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api', 'ember-uploader'], function (exports, Ember, InboundActions, API, EmberUploader) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    currentFile: null,
    uploading: false,
    active: false,
    loaded: false,
    selectedTab: null,
    type: 'default',
    uploadedFiles: null,
    statusAvailable: false,
    actions: {
      uploadInProgress: function uploadInProgress(e) {
        this.set('uploading', true);
      },
      load: function load(customerId) {
        this.set('customerId', customerId);
        this.set('created', moment().format('DD. MM. YYYY'));
        this.set('createdTime', moment().format('HH:mm'));
      },
      uploadError: function uploadError() {
        this.set('uploading', false);
        this.set('active', false);
      },
      uploadDone: function uploadDone(data) {
        this.set('uploading', false);
        this.set('active', false);
        this.sendAction('uploadDone');

        $.magnificPopup.close();
      }
    }
  });

});