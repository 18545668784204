define('jason-frontend/models/product-manufacturer', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    address: DS['default'].attr('string'),
    postalCode: DS['default'].attr('string'),
    town: DS['default'].attr('string'),
    editable: DS['default'].attr('boolean')
  });

});