define('jason-frontend/controllers/inventory/select-order', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    filterOrderStatus: "exported",
    breadCrumb: "Lagerbestand buchen",
    breadCrumbPath: "inventory.select-order",
    pageBinding: "content.page",
    selectTab: "default",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),
    page: 1,
    showLoading: false,
    perPage: 20,
    queryParams: ['selectTab', 'filterCode'],
    filterStockStatus: [{ name: "Angebraucht", id: "used" }, { name: "Aufgebraucht", id: "empty" }, { name: "Ausgebucht", id: "bookedout" }, { name: "Vollständig", id: "created" }],
    myStickyOptionsTableHead: {
      topSpacing: 160,
      zIndex: 9
    },
    showFilters: true,
    actions: {
      openCorrection: function openCorrection(id) {

        var self = this;

        $.ajax({
          url: "/api/inventoryStocks/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('inventoryCorrectionPanel').send('load', data.inventoryStock);
        }, function () {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-inventory-correction-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openOutgoing: function openOutgoing(id) {
        var src = '#modal-inventory-outgoing-panel';
        var self = this;

        $.ajax({
          url: "/api/inventoryStocks/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('inventoryOutgoingPanel').send('load', data.inventoryStock);
        }, function () {});

        setTimeout(function () {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: src
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 1000);
      },
      scan: function scan(context) {
        this.get('quickScanProductPanel').send('loadWithoutDirect', context);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-quick-scan-product-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      scanedProduct: function scanedProduct(id, patientId, ctx) {
        var _this = this;

        var self = this;
        if (ctx && ctx === 'default') {
          var self;

          (function () {
            var productId = id.replace("product", "");
            self = _this;

            $.ajax({
              url: "/api/inventoryRequests?referer=corrections",
              method: "PUT",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {

              var reqId = data.inventoryRequestSupplier.id;

              $.ajax({
                url: "/api/inventoryRequests/" + reqId + "/add/product/" + productId + "/1/0/0",
                method: "GET",
                contentType: "application/json",
                dataType: "json",
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {
                self.transitionTo('inventory.bookin-request', reqId);
              }, function () {});
            }, function () {});
          })();
        } else {

          if (id.startsWith('product')) {

            $.ajax({
              url: "/api/products/" + id.replace('product', '').replace('qr', ''),
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (product) {
              $.ajax({
                url: "/api/inventoryStocks/product/" + product.product.id,
                method: "GET",
                contentType: "application/json",
                dataType: "json",
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {
                self.get('stockSelectPanel').send('load', data, product.product);
              }, function () {});

              setTimeout(function () {
                $.magnificPopup.open({
                  removalDelay: 500,
                  closeOnBgClick: false,
                  items: {
                    src: '#modal-select-stock-panel'
                  },
                  callbacks: {
                    beforeOpen: function beforeOpen() {
                      var Animation = "mfp-with-fade";
                      this.st.mainClass = Animation;
                    }
                  },
                  midClick: true
                });
              }, 1000);
            }, function () {});
          } else {
            $.ajax({
              url: "/api/inventoryStocks/" + id,
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              if (self.get('selectedType') === 'correction') {
                self.get('inventoryCorrectionPanel').send('load', data.inventoryStock);
              } else if (self.get('selectedType') === 'outgoing') {
                self.get('inventoryOutgoingPanel').send('load', data.inventoryStock);
              }
            }, function () {});

            var src = '#modal-inventory-correction-panel';

            if (self.get('selectedType') === 'outgoing') {
              src = '#modal-inventory-outgoing-panel';
            }

            setTimeout(function () {
              $.magnificPopup.open({
                removalDelay: 500,
                closeOnBgClick: false,
                items: {
                  src: src
                },
                callbacks: {
                  beforeOpen: function beforeOpen() {
                    var Animation = "mfp-with-fade";
                    this.st.mainClass = Animation;
                  }
                },
                midClick: true
              });
            }, 1000);
          }
        }
      }
    },
    filterOrderStatusList: [{ value: "An Lieferant übermittelt", id: "exported" }, { value: "Abgeschlossen", id: "finished" }, { value: "Teil storniert/retourniert", id: "partly_canceled" }, { value: "Storniert/Retourniert", id: "canceled" }, { value: "Übermittlungsfehler", id: "sending_error" }],
    watchFilterOrderStatus: (function () {
      this.send('applyFilter');
    }).observes('filterOrderStatus'),
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('pickDate'),
    watchFilterStatus: (function () {
      this.send('applyFilter');
    }).observes('filterStatus')
  });

});