define('jason-frontend/models/purchase-order-laboklin-form', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';


  exports['default'] = DS['default'].Model.extend({
    shared: DS['default'].attr('boolean'),
    digitalAvailable: DS['default'].attr('boolean'),
    editable: DS['default'].attr('boolean'),
    created: DS['default'].attr('date'),
    reportType: DS['default'].attr('string'),
    type: DS['default'].attr(''),
    edvNumber: DS['default'].attr(''),
    laborNr: DS['default'].attr(''),
    firstname: DS['default'].attr(''),
    lastname: DS['default'].attr(''),
    animalName: DS['default'].attr(''),
    positions: DS['default'].attr('string'),
    articles: DS['default'].hasMany('purchase-product'),
    laboklinStatus: DS['default'].belongsTo('laboklin-form-status'),
    quickInfos: DS['default'].attr('string'),
    submitted: DS['default'].attr('string'),
    orderTypeString: DS['default'].attr('string'),
    orderType: DS['default'].attr('string'),
    reportDate: DS['default'].attr('string'),
    vetconnectConfirmUrl: DS['default'].attr(''),
    inhouse: DS['default'].attr(''),
    status: DS['default'].attr(''),
    hideApp: DS['default'].attr(''),
    treatmentHidden: DS['default'].attr(''),
    vetconnectRequisitionUrl: DS['default'].attr(''),
    vetconnectStatus: DS['default'].attr('')
  });

});