define('jason-frontend/helpers/is-below', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isBelowMandant = isBelowMandant;

  function isBelowMandant(params) {
    var desired = params[0];
    var currentState = API['default'].getMandantType();

    if (!currentState) {
      return false;
    }
    if (desired === 'essential' && (currentState === 'essential' || currentState === 'basic')) {
      return true;
    }
    if (desired === 'basic' && currentState === 'basic') {
      return true;
    }
    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(isBelowMandant);

});