define('jason-frontend/views/cloudinary', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].View.extend({
    tagName: 'input',
    name: 'file',
    attributeBindings: ['name', 'type', 'data-form-data'],
    type: 'file',

    didInsertElement: function didInsertElement() {
      var _this = this,
          controller = this.get('controller');

      var deferred = jQuery.ajax({
        url: "/api/media/cloudinaryconfig",
        method: "GET",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).done(function (response) {
        Ember['default'].run(function () {
          _this.set('data-form-data', JSON.stringify(response));
        });

        _this.$().cloudinary_fileupload({
          disableImageResize: false,
          imageMaxWidth: 10000000,
          imageMaxHeight: 10000000,
          acceptFileTypes: /(\.|\/)(gif|jpe?g|png|bmp|ico)$/i,
          maxFileSize: 20000000,
          loadImageMaxFileSize: 50000000
        });

        _this.$().bind("fileuploadstart", function (e) {
          controller.set('uploading', true);
        });

        _this.$().bind("fileuploadstop", function (e) {
          controller.set('uploading', false);
        });

        _this.$().bind('fileuploaddone', function (e, data) {
          controller.set('uploadedAssets', data.result.public_id);
          controller.set('uploading', false);
          controller.send('imageChanged', data.result.public_id);
        });
      });
    }
  });

});