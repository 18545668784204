define('jason-frontend/helpers/get-html', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getHtml = getHtml;

  function getHtml(params) {
    var param = params[0];
    if (param) {
      return param.replace(/\n/g, "<br />");
    }
    return param;
  }

  exports['default'] = Ember['default'].Helper.helper(getHtml);

});