define('jason-frontend/components/create-customer-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    months: null,
    type: null,
    showMore: false,
    addPatient: false,
    validate: function validate() {
      if (!this.get('firstname')) {
        jason.notifiction.error('Fehler', 'Bitte geben Sie einen Vornamen an');
        return false;
      }
      if (!this.get('lastname')) {
        jason.notifiction.error('Fehler', 'Bitte geben Sie einen Nachnamen an');
        return false;
      }
      if (!this.get('patientName') && this.get('addPatient')) {
        jason.notifiction.error('Fehler', 'Bitte geben Sie einen Patienten an');
        return false;
      }
      if (this.get('email') && this.get('email').length > 0) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(this.get('email')).toLowerCase())) {
          jason.notifiction.error('Fehler', 'Bitte geben Sie eine gültige Email-Adresse an');
          return false;
        }
      }
      return true;
    },
    startTreatment: function startTreatment(customerId, patientId, directTreatment) {
      var self = this,
          treatment = {
        customer: {
          id: customerId
        }
      };

      var patientTreatmentData = [];

      var patientData = {
        patientId: patientId,
        putDirectlyInTreatment: directTreatment
      };

      patientTreatmentData.push(patientData);
      treatment.selectedPatients = patientTreatmentData;

      $.ajax({
        url: "/api/treatments",
        method: "PUT",
        data: JSON.stringify(treatment),
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (id) {
        if (directTreatment) {
          self.sendAction('toTreatment', id);
        } else {
          self.sendAction('toWaitingRoom');
        }
      }, function (error) {
        return { status: error.statusText, message: error.responseText };
      });
    },
    savedAction: function savedAction(id) {
      if (this.get('ctx') === 'customers') {
        this.sendAction('gotoDetails', id);
      } else {
        this.sendAction('refresh');
      }
    },
    createCustomer: function createCustomer(target) {
      var form = $('#createCustomerForm'),
          self = this;

      var customer = {
        firstname: this.get('firstname'),
        lastname: this.get('lastname'),
        street: this.get('street'),
        postalCode: this.get('postalCode'),
        town: this.get('town'),
        email: this.get('email'),
        phone1: this.get('phone1')
      };

      if (this.get('selectedGender')) {
        customer.genderId = this.get('selectedGender').id;
      }

      var patientData = null;
      if (this.get('addPatient')) {
        patientData = {
          name: this.get('patientName'),
          race: this.get('patientRace'),
          color: this.get('patientColor'),
          chipId: this.get('patientChipId'),
          castrated: this.get('patientCastrated'),
          birthdayFormatted: this.get('birthdayInput')
        };

        if (this.get('patientGender')) {
          patientData.gender = {
            id: this.get('patientGender').id
          };
        }
        if (this.get('patientCategory')) {
          patientData.category = {
            id: this.get('patientCategory').id
          };
        }
        if (this.get('digitalRace')) {
          patientData.digitalRace = {
            id: this.get('digitalRace').id
          };
        }
      }

      $.ajax({
        url: "/api/customers",
        method: "POST",
        data: JSON.stringify(customer),
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {

        if (patientData) {

          patientData.customerId = data.customer.id;

          $.ajax({
            url: "/api/patients",
            method: "POST",
            data: JSON.stringify(patientData),
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (patientdata) {
            if (target === 'treatment') {
              self.startTreatment(data.customer.id, patientdata.patient.id, true);
            } else if (target === 'waitingroom') {
              self.startTreatment(data.customer.id, patientdata.patient.id, false);
            } else if (target === 'patient') {
              self.sendAction('gotoPatient', patientdata.patient.id);
            } else if (self.get('ctx') === 'invoice') {
              var patients = new Array();
              patients.push(patientdata.patient.id);
              self.sendAction('setCustomer', data.customer.id, patients);
            } else {
              self.savedAction(data.customer.id);
            }
          });
        } else {
          if (self.get('ctx') === 'invoice') {
            self.sendAction('setCustomer', data.customer.id);
          } else {
            self.savedAction(data.customer.id);
          }
        }
      }, function (error) {});
    },
    actions: {
      load: function load(ctx) {
        this.set('selectedGender', null);
        this.set('firstname', null);
        this.set('lastname', null);
        this.set('town', null);
        this.set('postalCode', null);
        this.set('street', null);
        this.set('email', null);
        this.set('phone1', null);
        this.set('patientName', null);
        this.set('patientRace', null);
        this.set('patientColor', null);
        this.set('patientChipId', null);
        this.set('patientCastrated', null);
        this.set('patientGender', null);
        this.set('patientCategory', null);
        this.set('digitalRace', null);
        this.set('birthdayInput', null);
        this.set('ctx', ctx);
      },
      toggleFirst: function toggleFirst() {
        if (this.get('showMore')) {
          this.set('showMore', false);
        } else {
          this.set('showMore', true);
        }
      },
      togglePatient: function togglePatient() {
        if (this.get('addPatient')) {
          this.set('addPatient', false);
        } else {
          this.set('addPatient', true);
        }
      },
      saveAndToTreatment: function saveAndToTreatment() {
        if (this.validate()) {
          this.createCustomer('treatment');
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          $.magnificPopup.close();
        }
      },
      saveAndToWaitingRoom: function saveAndToWaitingRoom() {
        if (this.validate()) {
          this.createCustomer('waitingroom');
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          $.magnificPopup.close();
        }
      },
      save: function save() {
        if (this.validate()) {
          this.createCustomer();
          this.sendAction('saved');
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          $.magnificPopup.close();
        }
      },
      saveAndGotoPatient: function saveAndGotoPatient() {
        if (this.validate()) {
          this.createCustomer('patient');
          this.sendAction('saved');
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          $.magnificPopup.close();
        }
      },
      saveAndGotoCustomer: function saveAndGotoCustomer() {
        if (this.validate()) {
          this.set('ctx', 'customers');
          this.createCustomer();
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          $.magnificPopup.close();
        }
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    },
    becomeFocused: (function () {
      var self = this,
          form = $('#createCustomerForm'),
          id = form.attr('data-invoice-id');
    }).on('didInsertElement'),
    watchCategory: (function () {

      var self = this;

      try {
        $.ajax({
          url: "/api/digitalRaces/" + self.get('patientCategory').id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('digitalRace', null);
          self.set('digitalRaces', data.digitalRace);
        });
      } catch (e) {}
    }).observes('patientCategory')
  });

});