define('jason-frontend/helpers/is-discount-available', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isDiscountAvailable = isDiscountAvailable;

  function isDiscountAvailable(params) {
    var article = params[0],
        type = params[1];

    try {
      if (article && article.get('category')) {
        var category = article.get('category').id;
        if (category === 'anz') {
          return false;
        }
      }
    } catch (e) {
      if (article && article.category) {
        var category = article.category.id;
        if (category === 'anz') {
          return false;
        }
      }
    }
    return true;
  }

  exports['default'] = Ember['default'].Helper.helper(isDiscountAvailable);

});