define('jason-frontend/templates/appointments/calendar', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 0
              },
              "end": {
                "line": 11,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  background-color: ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(";\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","user.color",["loc",[null,[10,20],[10,34]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 0
              },
              "end": {
                "line": 13,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  background-color: #b5b5c3;\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 2
              },
              "end": {
                "line": 36,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    background-color: ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(";\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","user.color",["loc",[null,[35,22],[35,36]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 2
              },
              "end": {
                "line": 38,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    background-color: #b5b5c3;\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 48,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("th.fc-resource-cell[data-resource-id=\"");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\"]::after {\n  content: '");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("';\n  transition: all 0.3s ease;\n  display: inline-block;\n  border-radius: 50%;\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  color: white;\n  width: 25px;\n  height: 25px;\n  font-size: 12px;\n  line-height: 25px;\n  text-align: center;\n  font-weight: bold;\n  position: absolute;\n  left:50%;\n  top:50%;\n  transform: translate(-50%, -50%);\n}\n.showUserBadges .fc-event.user-");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("::after {\n  content: '");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("';\n  bottom: -4px;\n  right: -4px;\n  position: absolute;\n  margin-left: -10px;\n  display: inline-block;\n  border-radius: 50%;\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  color: white;\n  width: 22px;\n  height: 22px;\n  font-size: 10px;\n  text-align: center;\n  font-weight: bold;\n  line-height: 22px;\n  z-index: 99;\n}\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
          morphs[4] = dom.createMorphAt(fragment,9,9,contextualElement);
          morphs[5] = dom.createMorphAt(fragment,11,11,contextualElement);
          return morphs;
        },
        statements: [
          ["content","user.id",["loc",[null,[4,38],[4,49]]]],
          ["content","user.capitals",["loc",[null,[5,12],[5,29]]]],
          ["block","if",[["get","user.colorHex",["loc",[null,[9,6],[9,19]]]]],[],0,1,["loc",[null,[9,0],[13,7]]]],
          ["content","user.id",["loc",[null,[26,31],[26,42]]]],
          ["content","user.capitals",["loc",[null,[27,12],[27,29]]]],
          ["block","if",[["get","user.colorHex",["loc",[null,[34,8],[34,21]]]]],[],2,3,["loc",[null,[34,2],[38,9]]]]
        ],
        locals: ["user"],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 102,
                "column": 16
              },
              "end": {
                "line": 110,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","symbol-label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element24 = dom.childAt(fragment, [1]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element24, 'title');
            morphs[1] = dom.createAttrMorph(element24, 'class');
            morphs[2] = dom.createAttrMorph(element24, 'style');
            morphs[3] = dom.createElementMorph(element24);
            morphs[4] = dom.createMorphAt(dom.childAt(element24, [1]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[103,32],[103,46]]]]]]],
            ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[104,65],[104,78]]]],"selected",""],[],["loc",[null,[104,44],[105,69]]]]," symbol symbol-circle symbol-40px"]]],
            ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[106,50],[106,60]]]]," !important;"]]],
            ["element","action",["toggleResource",["get","user",["loc",[null,[103,76],[103,80]]]]],[],["loc",[null,[103,50],[103,82]]]],
            ["content","user.capitals",["loc",[null,[107,46],[107,63]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 110,
                "column": 16
              },
              "end": {
                "line": 117,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","symbol-label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element23 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element23, 'title');
            morphs[1] = dom.createAttrMorph(element23, 'class');
            morphs[2] = dom.createElementMorph(element23);
            morphs[3] = dom.createMorphAt(dom.childAt(element23, [1]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[111,32],[111,46]]]]]]],
            ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[112,65],[112,78]]]],"selected",""],[],["loc",[null,[112,44],[113,69]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[113,105],[113,115]]]]]]],
            ["element","action",["toggleResource",["get","user",["loc",[null,[111,76],[111,80]]]]],[],["loc",[null,[111,50],[111,82]]]],
            ["content","user.capitals",["loc",[null,[114,46],[114,63]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 100,
              "column": 12
            },
            "end": {
              "line": 119,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","user.colorHex",["loc",[null,[102,22],[102,35]]]]],[],0,1,["loc",[null,[102,16],[117,23]]]]
        ],
        locals: ["user"],
        templates: [child0, child1]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 146,
              "column": 8
            },
            "end": {
              "line": 150,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","app");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-app");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 150,
              "column": 8
            },
            "end": {
              "line": 154,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","app");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-app");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 158,
              "column": 8
            },
            "end": {
              "line": 162,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","user");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-appUser");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 162,
              "column": 8
            },
            "end": {
              "line": 166,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","user");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-appUser");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 171,
              "column": 10
            },
            "end": {
              "line": 175,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","etiga");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-appEtiga");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 175,
              "column": 10
            },
            "end": {
              "line": 179,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","etiga");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-appEtiga");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 184,
              "column": 8
            },
            "end": {
              "line": 188,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","task");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-task");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 188,
              "column": 8
            },
            "end": {
              "line": 192,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","task");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-task");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 204,
                "column": 10
              },
              "end": {
                "line": 210,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("style");
            var el2 = dom.createTextNode("\n              label[for=cat-");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("]::before {\n                background-color: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(";\n             }\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element20 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element20,1,1);
            morphs[1] = dom.createMorphAt(element20,3,3);
            return morphs;
          },
          statements: [
            ["content","cat.id",["loc",[null,[206,28],[206,38]]]],
            ["content","cat.color",["loc",[null,[207,34],[207,47]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 212,
                "column": 12
              },
              "end": {
                "line": 216,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxCat");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element19 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element19,[],true); }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element19, 'value');
            morphs[1] = dom.createAttrMorph(element19, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","cat.id",["loc",[null,[213,30],[213,36]]]]]]],
            ["attribute","id",["concat",["cat-",["get","cat.id",["loc",[null,[214,31],[214,37]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 216,
                "column": 12
              },
              "end": {
                "line": 220,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxCat");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element18, 'value');
            morphs[1] = dom.createAttrMorph(element18, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","cat.id",["loc",[null,[217,30],[217,36]]]]]]],
            ["attribute","id",["concat",["cat-",["get","cat.id",["loc",[null,[218,31],[218,37]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 221,
                "column": 41
              },
              "end": {
                "line": 221,
                "column": 173
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" App Termin");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["inline","button-etiga-small",[],["content","","classNames","pt2 inline-icon","size","16","width",28],["loc",[null,[221,81],[221,162]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 221,
                "column": 173
              },
              "end": {
                "line": 221,
                "column": 193
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","cat.name",["loc",[null,[221,181],[221,193]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 203,
              "column": 8
            },
            "end": {
              "line": 223,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [2]);
          var element22 = dom.childAt(element21, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createAttrMorph(element21, 'class');
          morphs[2] = dom.createElementMorph(element21);
          morphs[3] = dom.createMorphAt(element21,1,1);
          morphs[4] = dom.createAttrMorph(element22, 'for');
          morphs[5] = dom.createMorphAt(element22,1,1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["get","cat.colorHex",["loc",[null,[204,16],[204,28]]]]],[],0,null,["loc",[null,[204,10],[210,17]]]],
          ["attribute","class",["concat",["upper checkbox-custom checkbox-primary mb5 color-pick ",["get","cat.color",["loc",[null,[211,78],[211,87]]]]]]],
          ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[211,91],[211,151]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","profile.cats",["loc",[null,[212,36],[212,48]]]],["get","cat.fullId",["loc",[null,[212,49],[212,59]]]]],[],["loc",[null,[212,18],[212,60]]]]],[],1,2,["loc",[null,[212,12],[220,19]]]],
          ["attribute","for",["concat",["cat-",["get","cat.id",["loc",[null,[221,30],[221,36]]]]]]],
          ["block","if",[["subexpr","eq",[["get","cat.name",["loc",[null,[221,51],[221,59]]]],"etiga App Termin"],[],["loc",[null,[221,47],[221,79]]]]],[],3,4,["loc",[null,[221,41],[221,200]]]]
        ],
        locals: ["cat"],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 234,
              "column": 10
            },
            "end": {
              "line": 238,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxUser");
          dom.setAttribute(el1,"id","user-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 238,
              "column": 10
            },
            "end": {
              "line": 242,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxUser");
          dom.setAttribute(el1,"id","user-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 246,
                "column": 10
              },
              "end": {
                "line": 252,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("style");
            var el2 = dom.createTextNode("\n                label[for=user-");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("]::before {\n                background-color: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(";\n              }\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element15 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element15,1,1);
            morphs[1] = dom.createMorphAt(element15,3,3);
            return morphs;
          },
          statements: [
            ["content","user.id",["loc",[null,[248,31],[248,42]]]],
            ["content","user.color",["loc",[null,[249,34],[249,48]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 254,
                "column": 12
              },
              "end": {
                "line": 258,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxUser");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element14,[],true); }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element14, 'value');
            morphs[1] = dom.createAttrMorph(element14, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","user.id",["loc",[null,[255,30],[255,37]]]]]]],
            ["attribute","id",["concat",["user-",["get","user.id",["loc",[null,[256,32],[256,39]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 258,
                "column": 12
              },
              "end": {
                "line": 262,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxUser");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element13, 'value');
            morphs[1] = dom.createAttrMorph(element13, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","user.id",["loc",[null,[259,30],[259,37]]]]]]],
            ["attribute","id",["concat",["user-",["get","user.id",["loc",[null,[260,32],[260,39]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 245,
              "column": 8
            },
            "end": {
              "line": 265,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element16 = dom.childAt(fragment, [2]);
          var element17 = dom.childAt(element16, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createAttrMorph(element16, 'class');
          morphs[2] = dom.createElementMorph(element16);
          morphs[3] = dom.createMorphAt(element16,1,1);
          morphs[4] = dom.createAttrMorph(element17, 'for');
          morphs[5] = dom.createMorphAt(element17,0,0);
          morphs[6] = dom.createMorphAt(element17,2,2);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["get","user.colorHex",["loc",[null,[246,16],[246,29]]]]],[],0,null,["loc",[null,[246,10],[252,17]]]],
          ["attribute","class",["concat",["upper checkbox-custom checkbox-primary mb5 color-pick ",["get","user.color",["loc",[null,[253,78],[253,88]]]]]]],
          ["element","action",["triggerRefreshUser",""],["on","change","preventDefault",false],["loc",[null,[253,92],[253,159]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","profile.users",["loc",[null,[254,36],[254,49]]]],["get","user.fullId",["loc",[null,[254,50],[254,61]]]]],[],["loc",[null,[254,18],[254,62]]]]],[],1,2,["loc",[null,[254,12],[262,19]]]],
          ["attribute","for",["concat",["user-",["get","user.id",["loc",[null,[263,31],[263,38]]]]]]],
          ["content","user.firstname",["loc",[null,[263,43],[263,61]]]],
          ["content","user.lastname",["loc",[null,[263,62],[263,79]]]]
        ],
        locals: ["user"],
        templates: [child0, child1, child2]
      };
    }());
    var child14 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 281,
                "column": 10
              },
              "end": {
                "line": 288,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","upper checkbox-custom checkbox-primary mb5");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"class","checkResource");
            dom.setAttribute(el2,"type","checkbox");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var element9 = dom.childAt(element8, [1]);
            var element10 = dom.childAt(element8, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createElementMorph(element8);
            morphs[1] = dom.createAttrMorph(element9, 'value');
            morphs[2] = dom.createAttrMorph(element9, 'id');
            morphs[3] = dom.createAttrMorph(element10, 'for');
            morphs[4] = dom.createMorphAt(element10,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["triggerRefreshResource",""],["on","change","preventDefault",false],["loc",[null,[282,68],[282,139]]]],
            ["attribute","value",["concat",[["get","resource.id",["loc",[null,[283,30],[283,41]]]]]]],
            ["attribute","id",["concat",["resource-",["get","resource.id",["loc",[null,[284,36],[284,47]]]]]]],
            ["attribute","for",["concat",["resource-",["get","resource.id",["loc",[null,[286,37],[286,48]]]]]]],
            ["content","resource.name",["loc",[null,[286,52],[286,69]]]]
          ],
          locals: ["resource"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 268,
              "column": 6
            },
            "end": {
              "line": 290,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","header");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h3");
          dom.setAttribute(el2,"class","mb20 float-left mt20 ml20");
          var el3 = dom.createTextNode("Resourcen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","upper checkbox-custom checkbox-primary mb5");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("input");
          dom.setAttribute(el3,"value","9999");
          dom.setAttribute(el3,"class","checkResource");
          dom.setAttribute(el3,"id","resource-9999");
          dom.setAttribute(el3,"type","checkbox");
          dom.setAttribute(el3,"checked","");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","resource-9999");
          var el4 = dom.createTextNode("Alle");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [3]);
          var element12 = dom.childAt(element11, [1]);
          if (this.cachedFragment) { dom.repairClonedNode(dom.childAt(element12, [1]),[],true); }
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          morphs[1] = dom.createAttrMorph(element11, 'class');
          morphs[2] = dom.createElementMorph(element12);
          morphs[3] = dom.createMorphAt(element11,3,3);
          return morphs;
        },
        statements: [
          ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames"," mt20 float-right topToggle","active",["subexpr","@mut",[["get","resourcesExpanded",["loc",[null,[272,107],[272,124]]]]],[],[]],"toggleAll","toggleResources","content","ein/ausblenden"],["loc",[null,[272,10],[272,179]]]],
          ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","resourcesExpanded",["loc",[null,[274,58],[274,75]]]],"","hidden"],[],["loc",[null,[274,37],[274,89]]]]]]],
          ["element","action",["triggerRefreshResource","all"],["on","change","preventDefault",false],["loc",[null,[275,66],[275,140]]]],
          ["block","each",[["get","resources",["loc",[null,[281,18],[281,27]]]]],[],0,null,["loc",[null,[281,10],[288,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 297,
              "column": 10
            },
            "end": {
              "line": 301,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxLocation");
          dom.setAttribute(el1,"id","room-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 301,
              "column": 10
            },
            "end": {
              "line": 305,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxLocation");
          dom.setAttribute(el1,"id","room-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child17 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 310,
                "column": 12
              },
              "end": {
                "line": 314,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxLocation");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element5,[],true); }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element5, 'value');
            morphs[1] = dom.createAttrMorph(element5, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","room.id",["loc",[null,[311,30],[311,37]]]]]]],
            ["attribute","id",["concat",["room-",["get","room.id",["loc",[null,[312,32],[312,39]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 314,
                "column": 12
              },
              "end": {
                "line": 318,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxLocation");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element4, 'value');
            morphs[1] = dom.createAttrMorph(element4, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","room.id",["loc",[null,[315,30],[315,37]]]]]]],
            ["attribute","id",["concat",["room-",["get","room.id",["loc",[null,[316,32],[316,39]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 308,
              "column": 8
            },
            "end": {
              "line": 321,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","upper checkbox-custom checkbox-primary mb5");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(element6, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createMorphAt(element6,1,1);
          morphs[2] = dom.createAttrMorph(element7, 'for');
          morphs[3] = dom.createMorphAt(element7,0,0);
          return morphs;
        },
        statements: [
          ["element","action",["triggerRefreshLocation",""],["on","change","preventDefault",false],["loc",[null,[309,66],[309,137]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","profile.rooms",["loc",[null,[310,36],[310,49]]]],["get","room.fullId",["loc",[null,[310,50],[310,61]]]]],[],["loc",[null,[310,18],[310,62]]]]],[],0,1,["loc",[null,[310,12],[318,19]]]],
          ["attribute","for",["concat",["room-",["get","room.id",["loc",[null,[319,31],[319,38]]]]]]],
          ["content","room.name",["loc",[null,[319,42],[319,55]]]]
        ],
        locals: ["room"],
        templates: [child0, child1]
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 330,
              "column": 10
            },
            "end": {
              "line": 334,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxStation");
          dom.setAttribute(el1,"id","station-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 334,
              "column": 10
            },
            "end": {
              "line": 338,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxStation");
          dom.setAttribute(el1,"id","station-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child20 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 344,
                "column": 12
              },
              "end": {
                "line": 348,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxStation");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element1,[],true); }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element1, 'value');
            morphs[1] = dom.createAttrMorph(element1, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","station.id",["loc",[null,[345,30],[345,40]]]]]]],
            ["attribute","id",["concat",["station-",["get","station.id",["loc",[null,[346,35],[346,45]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 348,
                "column": 12
              },
              "end": {
                "line": 352,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxStation");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'value');
            morphs[1] = dom.createAttrMorph(element0, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","station.id",["loc",[null,[349,30],[349,40]]]]]]],
            ["attribute","id",["concat",["station-",["get","station.id",["loc",[null,[350,35],[350,45]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 342,
              "column": 8
            },
            "end": {
              "line": 355,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","upper checkbox-custom checkbox-primary mb5");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createMorphAt(element2,1,1);
          morphs[2] = dom.createAttrMorph(element3, 'for');
          morphs[3] = dom.createMorphAt(element3,0,0);
          return morphs;
        },
        statements: [
          ["element","action",["triggerRefreshStation",""],["on","change","preventDefault",false],["loc",[null,[343,66],[343,136]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","profile.stations",["loc",[null,[344,36],[344,52]]]],["get","station.fullId",["loc",[null,[344,53],[344,67]]]]],[],["loc",[null,[344,18],[344,68]]]]],[],0,1,["loc",[null,[344,12],[352,19]]]],
          ["attribute","for",["concat",["station-",["get","station.id",["loc",[null,[353,34],[353,44]]]]]]],
          ["content","station.name",["loc",[null,[353,48],[353,64]]]]
        ],
        locals: ["station"],
        templates: [child0, child1]
      };
    }());
    var child21 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 360,
              "column": 2
            },
            "end": {
              "line": 363,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar-hidden");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"id","showSidebar");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[361,61],[361,196]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 432,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","calendarWrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","fc fc-ltr fc-unthemed");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","calendarStickyArea");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","d-flex align-items-center mb20");
        dom.setAttribute(el4,"id","calendarDateToolbar");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"type","button");
        dom.setAttribute(el6,"class","mr10 btn btn-light text-center");
        var el7 = dom.createTextNode("Heute\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","btn-group");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"type","button");
        dom.setAttribute(el7,"class","btn btn-icon btn-light text-center");
        var el8 = dom.createElement("img");
        dom.setAttribute(el8,"src","assets/images/icons/icon-prev-dark.svg");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"type","button");
        dom.setAttribute(el7,"class","btn btn-icon ml5 btn-light text-center");
        var el8 = dom.createElement("img");
        dom.setAttribute(el8,"src","assets/images/icons/icon-next-dark.svg");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","text-center");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","current-date fs-2 fw-bold");
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ml5 fs-7 text-gray-500 fw-bold");
        var el8 = dom.createTextNode("KW ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","d-flex justify-content-end");
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","flex-center mr20");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","mr5 text-center");
        dom.setAttribute(el7,"style","font-size: 13px;line-height: 13px");
        var el8 = dom.createTextNode("Farbe");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Terminart");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","header-toggle");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","colorMode");
        dom.setAttribute(el8,"class","switch neutral");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ml5 text-center");
        dom.setAttribute(el7,"style","font-size: 13px;line-height: 13px");
        var el8 = dom.createTextNode("Farbe");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Mitarbeiter:in");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","u-mb0 field select mr10");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"type","button");
        dom.setAttribute(el6,"class","btn btn-icon btn-light text-center");
        var el7 = dom.createTextNode("Ansicht\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","calendarFilterToolbar");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","headline");
        var el7 = dom.createTextNode("Geteilte Ansicht je Mitarbeiter:in");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","symbol-group symbol-hover flex-shrink-0 me-2");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","calendar");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"id","tooltipTest");
        dom.setAttribute(el2,"class","tooltip hidden");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","head");
        var el4 = dom.createTextNode("Details");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line");
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/clock.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","time");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line category");
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/empty.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","category");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line customer");
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/customer-info.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line");
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/info.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","info");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line appStatus");
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/empty.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","appStatus");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("aside");
        dom.setAttribute(el1,"id","calendarSidebar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2,"type","button");
        dom.setAttribute(el2,"class","mfp-close");
        var el3 = dom.createTextNode("X");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","box scrollable");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 mt20");
        var el5 = dom.createTextNode("Typ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","upper checkbox-custom checkbox-primary mb5 type-pick calApp");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","type-app");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","calApp");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Kund:innen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","upper checkbox-custom checkbox-primary mb5 type-pick calAppMa");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","type-appUser");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","calAppMa");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Mitarbeiter:innen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","upper checkbox-custom checkbox-primary mb5 type-pick calAppEtiga");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","type-appEtiga");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","calAppEtiga mr0");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Terminanfragen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","upper checkbox-custom checkbox-primary mb5 type-pick calTask");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","type-task");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","calTask");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Aufgaben");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 float-left ml20 mt20");
        var el5 = dom.createTextNode("Terminarten");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 float-left mt20 ml20");
        var el5 = dom.createTextNode("Personen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","upper checkbox-custom checkbox-primary mb5");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","user-9999");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 float-left mt20 ml20");
        var el5 = dom.createTextNode("Lokationen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","upper checkbox-custom checkbox-primary mb5");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","room-9999");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 float-left mt20 ml20");
        var el5 = dom.createTextNode("Stationen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","upper checkbox-custom checkbox-primary mb5");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","station-9999");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $( window ).resize(function() {\n      $('aside.side-bar ').css('min-height', $(window).height()-100);\n      $('aside.side-bar div.scrollable').css('max-height', $(window).height()-100);\n      $('#calendarWrapper thead.fc-head').css('width', $('#calendar').width());\n      $('#calendarWrapper .fc-widget-content .fc-day-grid').css('width', $('#calendar').width()-3);\n    });\n    $('aside.side-bar').css('min-height', $(window).height() - 150);\n    $('aside.side-bar div.scrollable').css('max-height', $(window).height() - 150);\n    $('#calendarWrapper .fc-widget-content .fc-day-grid').css('width', $('#calendar').width()-3);\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element25 = dom.childAt(fragment, [2]);
        var element26 = dom.childAt(element25, [1, 1]);
        var element27 = dom.childAt(element26, [1]);
        var element28 = dom.childAt(element27, [1]);
        var element29 = dom.childAt(element28, [1]);
        var element30 = dom.childAt(element28, [3]);
        var element31 = dom.childAt(element30, [1]);
        var element32 = dom.childAt(element30, [3]);
        var element33 = dom.childAt(element27, [3, 1]);
        var element34 = dom.childAt(element27, [5]);
        var element35 = dom.childAt(element34, [1, 3]);
        var element36 = dom.childAt(element34, [5]);
        var element37 = dom.childAt(element26, [3]);
        var element38 = dom.childAt(fragment, [4]);
        var element39 = dom.childAt(element38, [1]);
        var element40 = dom.childAt(element38, [3]);
        var element41 = dom.childAt(element40, [1]);
        var element42 = dom.childAt(element40, [3]);
        var element43 = dom.childAt(element40, [7]);
        var element44 = dom.childAt(element40, [9]);
        var element45 = dom.childAt(element40, [12]);
        var element46 = dom.childAt(element40, [15]);
        var element47 = dom.childAt(element40, [19]);
        var element48 = dom.childAt(element40, [23]);
        var element49 = dom.childAt(element48, [1]);
        var element50 = dom.childAt(element40, [29]);
        var element51 = dom.childAt(element50, [1]);
        var element52 = dom.childAt(element40, [33]);
        var element53 = dom.childAt(element52, [1]);
        var morphs = new Array(56);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
        morphs[1] = dom.createAttrMorph(element25, 'class');
        morphs[2] = dom.createAttrMorph(element26, 'class');
        morphs[3] = dom.createElementMorph(element29);
        morphs[4] = dom.createElementMorph(element31);
        morphs[5] = dom.createElementMorph(element32);
        morphs[6] = dom.createMorphAt(element33,1,1);
        morphs[7] = dom.createMorphAt(dom.childAt(element33, [2]),1,1);
        morphs[8] = dom.createElementMorph(element35);
        morphs[9] = dom.createMorphAt(element35,1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element34, [3]),1,1);
        morphs[11] = dom.createElementMorph(element36);
        morphs[12] = dom.createAttrMorph(element37, 'class');
        morphs[13] = dom.createMorphAt(dom.childAt(element37, [1, 3]),1,1);
        morphs[14] = dom.createAttrMorph(element38, 'class');
        morphs[15] = dom.createElementMorph(element39);
        morphs[16] = dom.createElementMorph(element41);
        morphs[17] = dom.createMorphAt(element41,0,0);
        morphs[18] = dom.createElementMorph(element42);
        morphs[19] = dom.createMorphAt(element42,0,0);
        morphs[20] = dom.createElementMorph(element43);
        morphs[21] = dom.createMorphAt(element43,1,1);
        morphs[22] = dom.createElementMorph(element44);
        morphs[23] = dom.createMorphAt(element44,1,1);
        morphs[24] = dom.createElementMorph(element45);
        morphs[25] = dom.createMorphAt(element45,1,1);
        morphs[26] = dom.createMorphAt(dom.childAt(element45, [3]),2,2);
        morphs[27] = dom.createElementMorph(element46);
        morphs[28] = dom.createMorphAt(element46,1,1);
        morphs[29] = dom.createMorphAt(dom.childAt(element40, [17]),3,3);
        morphs[30] = dom.createAttrMorph(element47, 'class');
        morphs[31] = dom.createMorphAt(element47,1,1);
        morphs[32] = dom.createMorphAt(dom.childAt(element40, [21]),3,3);
        morphs[33] = dom.createAttrMorph(element48, 'class');
        morphs[34] = dom.createElementMorph(element49);
        morphs[35] = dom.createMorphAt(element49,1,1);
        morphs[36] = dom.createMorphAt(element48,3,3);
        morphs[37] = dom.createMorphAt(element40,25,25);
        morphs[38] = dom.createMorphAt(dom.childAt(element40, [27]),3,3);
        morphs[39] = dom.createAttrMorph(element50, 'class');
        morphs[40] = dom.createElementMorph(element51);
        morphs[41] = dom.createMorphAt(element51,1,1);
        morphs[42] = dom.createMorphAt(element50,3,3);
        morphs[43] = dom.createMorphAt(dom.childAt(element40, [31]),3,3);
        morphs[44] = dom.createAttrMorph(element52, 'class');
        morphs[45] = dom.createElementMorph(element53);
        morphs[46] = dom.createMorphAt(element53,1,1);
        morphs[47] = dom.createMorphAt(element52,3,3);
        morphs[48] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[49] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[50] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[51] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[52] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[53] = dom.createMorphAt(fragment,16,16,contextualElement);
        morphs[54] = dom.createMorphAt(fragment,18,18,contextualElement);
        morphs[55] = dom.createMorphAt(fragment,20,20,contextualElement);
        return morphs;
      },
      statements: [
        ["block","each",[["get","users",["loc",[null,[3,8],[3,13]]]]],[],0,null,["loc",[null,[3,0],[48,9]]]],
        ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","hideBgEvents",["loc",[null,[51,58],[51,70]]]],"hideBgEvents",""],[],["loc",[null,[51,37],[51,90]]]]," ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","showUserBadges",["loc",[null,[51,116],[51,130]]]],"true"],[],["loc",[null,[51,112],[51,138]]]],"showUserBadges",""],[],["loc",[null,[51,91],[51,160]]]]," ",["subexpr","css-bool-evaluator",[["get","showFilter",["loc",[null,[51,182],[51,192]]]],"withFilter",""],[],["loc",[null,[51,161],[51,210]]]]," col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[51,243],[51,256]]]]],[],["loc",[null,[51,238],[51,257]]]],"24","19"],[],["loc",[null,[51,217],[51,269]]]],"/24@desk l-24/24@phone l-24/24@tablet ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[51,328],[51,341]]]],"sidebar-active",""],[],["loc",[null,[51,307],[51,363]]]]," card site-content fadeIn"]]],
        ["attribute","class",["concat",["col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[53,72],[53,85]]]]],[],["loc",[null,[53,67],[53,86]]]],"24","20"],[],["loc",[null,[53,46],[53,98]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["element","action",["today"],[],["loc",[null,[56,18],[56,36]]]],
        ["element","action",["prev"],[],["loc",[null,[59,34],[59,51]]]],
        ["element","action",["next"],[],["loc",[null,[61,34],[61,51]]]],
        ["content","currentDateFormatted",["loc",[null,[66,51],[66,75]]]],
        ["content","weekNumber",["loc",[null,[67,54],[67,68]]]],
        ["element","action",["toggleColorMode"],[],["loc",[null,[73,39],[73,67]]]],
        ["inline","input",[],["type","checkbox","id","colorMode","classNames","switch","name","colorMode","checked",["subexpr","@mut",[["get","colorModeUserEnabled",["loc",[null,[74,98],[74,118]]]]],[],[]]],["loc",[null,[74,14],[74,120]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","views",["loc",[null,[82,22],[82,27]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedView",["loc",[null,[83,20],[83,32]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","searchEnabled",false,"allowClear",false,"cssClass","input--dropdown custom-calendar"],["loc",[null,[81,12],[89,14]]]],
        ["element","action",["toggleFilter"],[],["loc",[null,[92,32],[92,57]]]],
        ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","showFilter",["loc",[null,[96,39],[96,49]]]],"","hidden"],[],["loc",[null,[96,18],[96,63]]]]," mb10"]]],
        ["block","each",[["get","users",["loc",[null,[100,20],[100,25]]]]],[],1,null,["loc",[null,[100,12],[119,21]]]],
        ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[136,38],[136,51]]]],"","hidden"],[],["loc",[null,[136,17],[136,65]]]]," side-bar card col l-5/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["element","action",["toggleSidebar",true],[],["loc",[null,[137,42],[137,73]]]],
        ["element","action",["toggleMe"],[],["loc",[null,[139,18],[139,39]]]],
        ["inline","button-toggle-me",[],["size",40,"strokeWidth",1,"classNames","toggleMe","active",["subexpr","@mut",[["get","onlyMine",["loc",[null,[139,111],[139,119]]]]],[],[]],"content",["subexpr","css-bool-evaluator",[["get","onlyMine",["loc",[null,[139,148],[139,156]]]],"Persönliche Ansicht","Praxis Ansicht"],[],["loc",[null,[139,128],[139,196]]]]],["loc",[null,[139,41],[139,198]]]],
        ["element","action",["ical"],[],["loc",[null,[140,18],[140,35]]]],
        ["inline","button-ical",[],["size",32,"classNames","ical"],["loc",[null,[140,37],[140,78]]]],
        ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[145,79],[145,139]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.types",["loc",[null,[146,32],[146,45]]]],"type-app"],[],["loc",[null,[146,14],[146,57]]]]],[],2,3,["loc",[null,[146,8],[154,15]]]],
        ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[157,81],[157,141]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.types",["loc",[null,[158,32],[158,45]]]],"type-appUser"],[],["loc",[null,[158,14],[158,61]]]]],[],4,5,["loc",[null,[158,8],[166,15]]]],
        ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[170,86],[170,146]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.types",["loc",[null,[171,34],[171,47]]]],"type-appEtiga"],[],["loc",[null,[171,16],[171,64]]]]],[],6,7,["loc",[null,[171,10],[179,17]]]],
        ["inline","button-etiga-small",[],["content","","classNames","inline-icon","size","16","width",28],["loc",[null,[180,75],[180,152]]]],
        ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[183,80],[183,140]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.types",["loc",[null,[184,32],[184,45]]]],"type-task"],[],["loc",[null,[184,14],[184,58]]]]],[],8,9,["loc",[null,[184,8],[192,15]]]],
        ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames","mt20 float-right topToggle","active",["subexpr","@mut",[["get","catsExpanded",["loc",[null,[199,104],[199,116]]]]],[],[]],"toggleAll","toggleCats","content","ein/ausblenden"],["loc",[null,[199,8],[199,166]]]],
        ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","catsExpanded",["loc",[null,[202,56],[202,68]]]],"","hidden"],[],["loc",[null,[202,35],[202,82]]]]]]],
        ["block","each",[["get","categories",["loc",[null,[203,16],[203,26]]]]],[],10,null,["loc",[null,[203,8],[223,17]]]],
        ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames","mt20 float-right topToggle","active",["subexpr","@mut",[["get","usersExpanded",["loc",[null,[228,104],[228,117]]]]],[],[]],"toggleAll","toggleUsers","content","ein/ausblenden"],["loc",[null,[228,8],[228,168]]]],
        ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","usersExpanded",["loc",[null,[231,56],[231,69]]]],"","hidden"],[],["loc",[null,[231,35],[231,83]]]]]]],
        ["element","action",["triggerRefreshUser","all"],["on","change","preventDefault",false],["loc",[null,[233,64],[233,134]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.users",["loc",[null,[234,34],[234,47]]]],"user-9999"],[],["loc",[null,[234,16],[234,60]]]]],[],11,12,["loc",[null,[234,10],[242,17]]]],
        ["block","each",[["get","users",["loc",[null,[245,16],[245,21]]]]],[],13,null,["loc",[null,[245,8],[265,17]]]],
        ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[268,12],[268,20]]]]],[],14,null,["loc",[null,[268,6],[290,13]]]],
        ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames"," mt20 float-right topToggle","active",["subexpr","@mut",[["get","roomsExpanded",["loc",[null,[293,105],[293,118]]]]],[],[]],"toggleAll","toggleRooms","content","ein/ausblenden"],["loc",[null,[293,8],[293,169]]]],
        ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","roomsExpanded",["loc",[null,[295,56],[295,69]]]],"","hidden"],[],["loc",[null,[295,35],[295,83]]]]]]],
        ["element","action",["triggerRefreshLocation","all"],["on","change","preventDefault",false],["loc",[null,[296,64],[296,138]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.rooms",["loc",[null,[297,34],[297,47]]]],"room-9999"],[],["loc",[null,[297,16],[297,60]]]]],[],15,16,["loc",[null,[297,10],[305,17]]]],
        ["block","each",[["get","rooms",["loc",[null,[308,16],[308,21]]]]],[],17,null,["loc",[null,[308,8],[321,17]]]],
        ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames"," mt20 float-right topToggle","active",["subexpr","@mut",[["get","stationsExpanded",["loc",[null,[326,105],[326,121]]]]],[],[]],"toggleAll","toggleStations","content","ein/ausblenden"],["loc",[null,[326,8],[326,175]]]],
        ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","stationsExpanded",["loc",[null,[328,56],[328,72]]]],"","hidden"],[],["loc",[null,[328,35],[328,86]]]]]]],
        ["element","action",["triggerRefreshStation","all"],["on","change","preventDefault",false],["loc",[null,[329,64],[329,137]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.stations",["loc",[null,[330,34],[330,50]]]],"station-9999"],[],["loc",[null,[330,16],[330,66]]]]],[],18,19,["loc",[null,[330,10],[338,17]]]],
        ["block","each",[["get","stations",["loc",[null,[342,16],[342,24]]]]],[],20,null,["loc",[null,[342,8],[355,17]]]],
        ["block","unless",[["get","sidebarActive",["loc",[null,[360,12],[360,25]]]]],[],21,null,["loc",[null,[360,2],[363,13]]]],
        ["inline","show-task-panel",[],["actionReceiver",["subexpr","@mut",[["get","showTaskPanel",["loc",[null,[365,33],[365,46]]]]],[],[]],"goto","goto","startTask","startTask","linkTo","linkTo","create","createNotification","addReply","addReply","finishTask","finishTaskSend","addMessage","addMessage","changeOwner","changeOwner"],["loc",[null,[365,0],[373,45]]]],
        ["inline","add-appointment-panel",[],["categories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[375,35],[375,52]]]]],[],[]],"etigaCategories",["subexpr","@mut",[["get","etigaCategories",["loc",[null,[376,40],[376,55]]]]],[],[]],"users",["subexpr","@mut",[["get","users",["loc",[null,[377,30],[377,35]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[378,32],[378,39]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[379,35],[379,45]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[380,34],[380,43]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[381,30],[381,35]]]]],[],[]],"users",["subexpr","@mut",[["get","users",["loc",[null,[382,30],[382,35]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[383,33],[383,41]]]]],[],[]],"showDelete",true,"showSeries",true,"supportUserApps",true,"showAdmission",true,"gotoPatient","gotoPatient","gotoEtigaMaster","gotoPatientEtiga","reloadPatients","reloadPatients","startAdmission","admissionPatient","gotoTreatment","gotoTreatment","create","createAppointment","delete","deleteAppointmentCheckEtiga","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[395,39],[395,58]]]]],[],[]]],["loc",[null,[375,0],[395,60]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[401,8],[401,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[402,10],[402,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[403,13],[403,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[404,17],[404,36]]]]],[],[]]],["loc",[null,[397,0],[404,38]]]],
        ["inline","app-request-confirm-panel",[],["confirm","confirmAppRequest","gotoPatient","gotoPatient","gotoPatientEtiga","gotoPatientEtiga","gotoCustomer","gotoCustomer","abort","abortAppRequest","actionReceiver",["subexpr","@mut",[["get","appRequestConfirmPanel",["loc",[null,[409,95],[409,117]]]]],[],[]]],["loc",[null,[406,0],[409,119]]]],
        ["inline","app-denial-panel",[],["confirm","deleteAppointment","gotoPatient","gotoPatient","gotoPatientEtiga","gotoPatientEtiga","gotoCustomer","gotoCustomer","actionReceiver",["subexpr","@mut",[["get","appDenialPanel",["loc",[null,[415,43],[415,57]]]]],[],[]]],["loc",[null,[411,0],[415,59]]]],
        ["inline","ical-link-panel",[],["actionReceiver",["subexpr","@mut",[["get","icalLinkPanel",["loc",[null,[417,33],[417,46]]]]],[],[]]],["loc",[null,[417,0],[417,48]]]],
        ["inline","choose-appointment-edit-type-panel",[],["editEvent","editEvent","actionReceiver",["subexpr","@mut",[["get","chooseAppointmentEditTypePanel",["loc",[null,[418,74],[418,104]]]]],[],[]]],["loc",[null,[418,0],[418,106]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21]
    };
  }()));

});