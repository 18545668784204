define('jason-frontend/helpers/to-lower-case', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.toLowerCase = toLowerCase;

  function toLowerCase(params) {
    return params[0].toLowerCase();
  }

  exports['default'] = Ember['default'].Helper.helper(toLowerCase);

});