define('jason-frontend/helpers/get-default-picture', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.getDefaultPicture = getDefaultPicture;

  function getDefaultPicture(params) {
    var product = params[0],
        supplier = params[1];

    if (API['default'].isSupplier()) {
      return 'nopicture_' + API['default'].getSupplierId() + '.png';
    }

    try {
      if (product && product.get('supplierOverride')) {
        return 'nopicture_' + product.get('supplierOverride') + '.png';
      }
    } catch (e) {}

    if (supplier) {
      try {
        return 'nopicture_' + supplier.get('id') + '.png';
      } catch (e) {
        return 'nopicture_' + supplier.id + '.png';
      }
    }

    try {
      if (product && product.get('supplier')) {
        return 'nopicture_' + product.get('supplier').get('id') + '.png';
      }
    } catch (e) {
      if (product && product.supplier) {
        return 'nopicture_' + product.supplier.id + '.png';
      }
    }

    return 'nopicture.png';
  }

  exports['default'] = Ember['default'].Helper.helper(getDefaultPicture);

});