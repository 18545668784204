define('jason-frontend/routes/purchases/summary', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  var modelHookRun;

  exports['default'] = Ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      modelHookRun = false;
    },
    model: function model(params) {
      return this.store.find('purchaseOrder', params.id, { test: true });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var self = this;
      $.each(model.get('subOrders').currentState, function (index, value) {
        self.store.findRecord('purchaseSubOrder', value.id).then(function (subOrder) {
          if (subOrder.get('missingCredentials') === false) {
            subOrder.set('confirmOrder', true);
          }
        });
      });
      controller.set('acceptAgbs', false);
    },
    afterModel: function afterModel(model) {
      if (!modelHookRun) {
        return model.reload();
      }
      return model;
    },
    placeOrder: function placeOrder(order, data) {
      var self = this;
      this.get('controller').get('supplierPanel').send('closePanel');
      $.ajax({
        url: "/api/purchaseOrders/" + order.id + "/place",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.transitionTo('purchases.confirm', order.id);
        jason.notifiction.notifiy('Bestellung', 'aufgegeben');
      }, function (error) {
        jason.notifiction.errorLong('Achtung', 'Bestellung ist bereits abgeschlossen, bitte steigen Sie erneut über Einkauf/Bestellungen ein');
      });
    },
    actions: {
      toggleSidebar: function toggleSidebar() {
        var controller = this.get('controller');
        if (controller.get('sidebarActive') === true) {
          controller.set('sidebarActive', false);
        } else {
          controller.set('sidebarActive', true);
          setTimeout(function () {
            $('aside.side-bar .scrollable').css('max-height', $(window).height() - 100);
            $('aside.side-bar').css('min-height', $(window).height() - 100);
          }, 100);

          if ($(window).width() <= 767) {
            $("html, body").animate({ scrollTop: 0 }, 200);
          }
        }
      },
      toMandant: function toMandant() {
        this.transitionTo('mandants.edit', { queryParams: { selectTab: "purchases" } });
      },
      deleteProduct: function deleteProduct(entryId, orderId, productName) {
        var self = this;

        var model = this.get('controller').get('model');

        var deferred = $.ajax({
          url: "/api/purchaseOrders/" + orderId + "/changeQuantity/" + entryId + "/0",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          model.reload();
          jason.notifiction.notifiy('Produkt entfernt', productName);
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      toSelectOrder: function toSelectOrder(orderId) {
        history.back();
      },
      confirmLaboklin: function confirmLaboklin(order, data) {
        this.send('confirm', order, data);
      },
      confirmRichter: function confirmRichter(orderId) {
        this.transitionTo('purchases.confirm', orderId);
      },
      confirm: function confirm(order, laboklinForm) {
        var controller = this.get('controller'),
            self = this;

        var data = {};
        data.subOrders = [];

        order.get('subOrders').forEach(function (subOrder) {

          var ignoredEntries = '';
          if (subOrder.get('supplier').id === 'jac') {
            subOrder.get('entries').forEach(function (entry) {
              if (entry.get('confirmOrder') === false) {
                ignoredEntries += entry.id + ',';
              }
            });
          }

          data.subOrders.push({
            supplier: {
              id: subOrder.get('supplier').id
            },
            richterApi: subOrder.get('richterApi'),
            authority: subOrder.get('authority'),
            confirmOrder: subOrder.get('confirmOrder'),
            ignoredEntries: ignoredEntries
          });
        });

        if (laboklinForm) {
          data.laboklinData = laboklinForm;
        }
        this.placeOrder(order, data);
      }
    }
  });

});