define('jason-frontend/templates/components/patient-activities', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 110,
              "column": 6
            },
            "end": {
              "line": 113,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element60 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element60, 'class');
          morphs[1] = dom.createElementMorph(element60);
          morphs[2] = dom.createMorphAt(element60,0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[111,61],[111,73]]]],"selected",""],[],["loc",[null,[111,40],[111,89]]]]]]],
          ["element","action",["toggleTag",["get","tag",["loc",[null,[112,100],[112,103]]]]],[],["loc",[null,[111,91],[112,105]]]],
          ["content","tag.name",["loc",[null,[112,106],[112,118]]]]
        ],
        locals: ["tag"],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 196,
                  "column": 8
                },
                "end": {
                  "line": 212,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-12/24 l-6/24@desk gutter mt20");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2,"class","u-mb0 field select full-width");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","title");
              var el4 = dom.createTextNode("Tier");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
              return morphs;
            },
            statements: [
              ["inline","select-2",[],["content",["subexpr","@mut",[["get","patients",["loc",[null,[201,24],[201,32]]]]],[],[]],"value",["subexpr","@mut",[["get","filterPatient",["loc",[null,[202,22],[202,35]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","name","optionValuePath","id","allowClear",true,"name","task-patient","placeholder","Bitte wählen"],["loc",[null,[200,14],[209,16]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 177,
                "column": 6
              },
              "end": {
                "line": 227,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-12/24 l-6/24@desk gutter mt20");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","select--inline input-element");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","title");
            var el4 = dom.createTextNode("Kund:in");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-12/24 l-6/24@desk gutter mt20");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","u-mb0 field select full-width");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","title");
            var el4 = dom.createTextNode("Spezies");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5, 1]),3,3);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["value",["subexpr","@mut",[["get","filterCustomer",["loc",[null,[182,20],[182,34]]]]],[],[]],"optionLabelPath","fullName","placeholder","Alle Kunden","allowClear",true,"typeaheadSearchingText","Suche Kunde","typeaheadNoMatchesText","Kein Kunden gefunden für '%@'","typeaheadErrorText","Ladevorgang fehlerhaft: %@","minimumInputLength",3,"maximumInputLength",15,"cssClass","custom-select2 newStyle","query","queryCustomer"],["loc",[null,[181,12],[193,14]]]],
            ["block","if",[["subexpr","and",[["get","filterCustomer",["loc",[null,[196,19],[196,33]]]],["get","patients",["loc",[null,[196,34],[196,42]]]]],[],["loc",[null,[196,14],[196,43]]]]],[],0,null,["loc",[null,[196,8],[212,15]]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","patientCategories",["loc",[null,[217,22],[217,39]]]]],[],[]],"value",["subexpr","@mut",[["get","filterPatientCategory",["loc",[null,[218,20],[218,41]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","placeholder","Alle","allowClear",true],["loc",[null,[216,12],[224,14]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 116,
              "column": 4
            },
            "end": {
              "line": 228,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24@desk gutter mt20");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"class","u-mb0 field select full-width");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","title");
          var el4 = dom.createTextNode("Ärzt:in");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24@desk gutter mt20");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"class","u-mb0 field select full-width");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","title");
          var el4 = dom.createTextNode("Assistent:in");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24@desk gutter mt20");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"class","u-mb0 field select full-width");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","title");
          var el4 = dom.createTextNode("Behandlungs-Lokation");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24@desk gutter mt20");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"class","u-mb0 field select full-width");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","title");
          var el4 = dom.createTextNode("Produkt/Leistung");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5, 1]),3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(fragment, [7, 1]),3,3);
          morphs[4] = dom.createMorphAt(fragment,9,9,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","doctors",["loc",[null,[121,16],[121,23]]]]],[],[]],"value",["subexpr","@mut",[["get","filterDoctor",["loc",[null,[122,14],[122,26]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","shortName","optionValuePath","id","placeholder","Alle","allowClear",true],["loc",[null,[120,6],[128,8]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","assistants",["loc",[null,[135,16],[135,26]]]]],[],[]],"value",["subexpr","@mut",[["get","filterAssistant",["loc",[null,[136,14],[136,29]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","shortName","optionValuePath","id","placeholder","Alle","allowClear",true],["loc",[null,[134,6],[142,8]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","rooms",["loc",[null,[149,20],[149,25]]]]],[],[]],"value",["subexpr","@mut",[["get","filterRoom",["loc",[null,[150,18],[150,28]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","placeholder","Alle","allowClear",true],["loc",[null,[148,10],[156,12]]]],
          ["inline","select-2",[],["value",["subexpr","@mut",[["get","filterArticle",["loc",[null,[163,18],[163,31]]]]],[],[]],"optionLabelPath","name","placeholder","Alle Produkte/Leistungen","allowClear",true,"typeaheadSearchingText","Suche ","typeaheadNoMatchesText","Kein Treffer für '%@'","typeaheadErrorText","Ladevorgang fehlerhaft: %@","minimumInputLength",3,"maximumInputLength",15,"cssClass","custom-select2 newStyle","query","queryArticle"],["loc",[null,[162,10],[174,12]]]],
          ["block","if",[["get","showPatientInfo",["loc",[null,[177,12],[177,27]]]]],[],0,null,["loc",[null,[177,6],[227,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 233,
              "column": 4
            },
            "end": {
              "line": 235,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[234,5],[234,26]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 253,
                  "column": 16
                },
                "end": {
                  "line": 255,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element51 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element51, 'class');
              morphs[1] = dom.createMorphAt(element51,0,0);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["info-badge info-badge-very-small mr5 tag-",["get","tag.name",["loc",[null,[254,75],[254,83]]]]]]],
              ["content","tag.name",["loc",[null,[254,87],[254,99]]]]
            ],
            locals: ["tag"],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 257,
                    "column": 18
                  },
                  "end": {
                    "line": 259,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","info-badge info-badge-very-small lab-idexx");
                var el2 = dom.createTextNode("IDEXX");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 259,
                      "column": 18
                    },
                    "end": {
                      "line": 261,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","info-badge info-badge-very-small lab-laboklin");
                  var el2 = dom.createTextNode("Laboklin");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 261,
                        "column": 18
                      },
                      "end": {
                        "line": 263,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("label");
                    dom.setAttribute(el1,"class","info-badge info-badge-very-small lab-intern");
                    var el2 = dom.createTextNode("Labor Intern");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                  ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 261,
                      "column": 18
                    },
                    "end": {
                      "line": 263,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","item.payload.privateLab",["loc",[null,[261,28],[261,51]]]]],[],0,null,["loc",[null,[261,18],[263,18]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 259,
                    "column": 18
                  },
                  "end": {
                    "line": 263,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[259,32],[259,49]]]],"laboklin"],[],["loc",[null,[259,28],[259,61]]]]],[],0,1,["loc",[null,[259,18],[263,18]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 256,
                  "column": 16
                },
                "end": {
                  "line": 264,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[257,28],[257,45]]]],"vetconnect"],[],["loc",[null,[257,24],[257,59]]]]],[],0,1,["loc",[null,[257,18],[263,25]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 275,
                      "column": 20
                    },
                    "end": {
                      "line": 279,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","is-highlighted");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" \n                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","ml0 tooltipstered symbol symbol-circle symbol-40px");
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","symbol-label");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element49 = dom.childAt(fragment, [1]);
                  var element50 = dom.childAt(fragment, [3]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createMorphAt(element49,0,0);
                  morphs[1] = dom.createMorphAt(element49,2,2);
                  morphs[2] = dom.createAttrMorph(element50, 'title');
                  morphs[3] = dom.createAttrMorph(element50, 'style');
                  morphs[4] = dom.createMorphAt(dom.childAt(element50, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","item.payload.doctor.lastname",["loc",[null,[276,51],[276,83]]]],
                  ["content","item.payload.doctor.firstname",["loc",[null,[276,84],[276,117]]]],
                  ["attribute","title",["concat",[["get","item.payload.doctor.shortName",["loc",[null,[277,36],[277,65]]]]]]],
                  ["attribute","style",["concat",["background-color: ",["get","item.payload.doctor.color",["loc",[null,[277,155],[277,180]]]]," !important;"]]],
                  ["content","item.payload.doctor.capitals",["loc",[null,[278,48],[278,80]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 281,
                      "column": 18
                    },
                    "end": {
                      "line": 281,
                      "column": 77
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("und");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 283,
                      "column": 24
                    },
                    "end": {
                      "line": 288,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","is-highlighted");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","ml0 tooltipstered symbol symbol-circle symbol-40px");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","symbol-label");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                          ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element47 = dom.childAt(fragment, [1]);
                  var element48 = dom.childAt(fragment, [3]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createMorphAt(element47,0,0);
                  morphs[1] = dom.createMorphAt(element47,2,2);
                  morphs[2] = dom.createAttrMorph(element48, 'title');
                  morphs[3] = dom.createAttrMorph(element48, 'style');
                  morphs[4] = dom.createMorphAt(dom.childAt(element48, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","item.payload.assistent.lastname",["loc",[null,[284,55],[284,90]]]],
                  ["content","item.payload.assistent.firstname",["loc",[null,[284,91],[284,127]]]],
                  ["attribute","title",["concat",[["get","item.payload.assistent.shortName",["loc",[null,[284,155],[284,187]]]]]]],
                  ["attribute","style",["concat",["background-color: ",["get","item.payload.assistent.color",["loc",[null,[284,277],[284,305]]]]," !important;"]]],
                  ["content","item.payload.assistent.capitals",["loc",[null,[285,52],[285,87]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 272,
                    "column": 16
                  },
                  "end": {
                    "line": 290,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  durch\n                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","symbol-group flex-center ml0 inline-block mb0 mt0 mr0");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","symbol-group flex-center inline-block ml0 mb0 mt0 mr0");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),1,1);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.payload.doctor",["loc",[null,[275,26],[275,45]]]]],[],0,null,["loc",[null,[275,20],[279,27]]]],
                ["block","if",[["subexpr","and",[["get","item.payload.assistent",["loc",[null,[281,29],[281,51]]]],["get","item.payload.doctor",["loc",[null,[281,52],[281,71]]]]],[],["loc",[null,[281,24],[281,72]]]]],[],1,null,["loc",[null,[281,18],[281,84]]]],
                ["block","if",[["get","item.payload.assistent",["loc",[null,[283,30],[283,52]]]]],[],2,null,["loc",[null,[283,24],[288,31]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 291,
                    "column": 16
                  },
                  "end": {
                    "line": 291,
                    "column": 111
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" durch ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","ml5 is-highlighted");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["content","item.eltigaMandant",["loc",[null,[291,82],[291,104]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 270,
                  "column": 14
                },
                "end": {
                  "line": 292,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                Behandlung\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","or",[["get","item.payload.assistent",["loc",[null,[272,26],[272,48]]]],["get","item.payload.doctor",["loc",[null,[272,49],[272,68]]]]],[],["loc",[null,[272,22],[272,69]]]]],[],0,null,["loc",[null,[272,16],[290,23]]]],
              ["block","if",[["get","item.eltigaMandant",["loc",[null,[291,22],[291,40]]]]],[],1,null,["loc",[null,[291,16],[291,118]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 292,
                    "column": 14
                  },
                  "end": {
                    "line": 294,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                Erinnerung\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 294,
                      "column": 14
                    },
                    "end": {
                      "line": 296,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                Notiz\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 296,
                        "column": 14
                      },
                      "end": {
                        "line": 298,
                        "column": 14
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                Labor\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 298,
                          "column": 14
                        },
                        "end": {
                          "line": 300,
                          "column": 14
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                Bildgebung\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 300,
                            "column": 14
                          },
                          "end": {
                            "line": 302,
                            "column": 14
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                Rechnung\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 302,
                              "column": 14
                            },
                            "end": {
                              "line": 304,
                              "column": 14
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                Impfung\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes() { return []; },
                        statements: [

                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 304,
                                "column": 14
                              },
                              "end": {
                                "line": 306,
                                "column": 14
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                E-Mail Weiterleitung\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes() { return []; },
                          statements: [

                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        var child0 = (function() {
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 306,
                                  "column": 14
                                },
                                "end": {
                                  "line": 308,
                                  "column": 14
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createTextNode("                eltiga Nachricht\n");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes() { return []; },
                            statements: [

                            ],
                            locals: [],
                            templates: []
                          };
                        }());
                        var child1 = (function() {
                          var child0 = (function() {
                            return {
                              meta: {
                                "revision": "Ember@1.13.5",
                                "loc": {
                                  "source": null,
                                  "start": {
                                    "line": 308,
                                    "column": 14
                                  },
                                  "end": {
                                    "line": 310,
                                    "column": 14
                                  }
                                },
                                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                              },
                              arity: 0,
                              cachedFragment: null,
                              hasRendered: false,
                              buildFragment: function buildFragment(dom) {
                                var el0 = dom.createDocumentFragment();
                                var el1 = dom.createTextNode("                Dokument\n");
                                dom.appendChild(el0, el1);
                                return el0;
                              },
                              buildRenderNodes: function buildRenderNodes() { return []; },
                              statements: [

                              ],
                              locals: [],
                              templates: []
                            };
                          }());
                          var child1 = (function() {
                            var child0 = (function() {
                              return {
                                meta: {
                                  "revision": "Ember@1.13.5",
                                  "loc": {
                                    "source": null,
                                    "start": {
                                      "line": 310,
                                      "column": 14
                                    },
                                    "end": {
                                      "line": 312,
                                      "column": 14
                                    }
                                  },
                                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                                },
                                arity: 0,
                                cachedFragment: null,
                                hasRendered: false,
                                buildFragment: function buildFragment(dom) {
                                  var el0 = dom.createDocumentFragment();
                                  var el1 = dom.createTextNode("                Termin\n              ");
                                  dom.appendChild(el0, el1);
                                  return el0;
                                },
                                buildRenderNodes: function buildRenderNodes() { return []; },
                                statements: [

                                ],
                                locals: [],
                                templates: []
                              };
                            }());
                            return {
                              meta: {
                                "revision": "Ember@1.13.5",
                                "loc": {
                                  "source": null,
                                  "start": {
                                    "line": 310,
                                    "column": 14
                                  },
                                  "end": {
                                    "line": 312,
                                    "column": 14
                                  }
                                },
                                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                              },
                              arity: 0,
                              cachedFragment: null,
                              hasRendered: false,
                              buildFragment: function buildFragment(dom) {
                                var el0 = dom.createDocumentFragment();
                                var el1 = dom.createComment("");
                                dom.appendChild(el0, el1);
                                return el0;
                              },
                              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                var morphs = new Array(1);
                                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                                dom.insertBoundary(fragment, 0);
                                dom.insertBoundary(fragment, null);
                                return morphs;
                              },
                              statements: [
                                ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[310,28],[310,37]]]],"app"],[],["loc",[null,[310,24],[310,44]]]]],[],0,null,["loc",[null,[310,14],[312,14]]]]
                              ],
                              locals: [],
                              templates: [child0]
                            };
                          }());
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 308,
                                  "column": 14
                                },
                                "end": {
                                  "line": 312,
                                  "column": 14
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createComment("");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                              var morphs = new Array(1);
                              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                              dom.insertBoundary(fragment, 0);
                              dom.insertBoundary(fragment, null);
                              return morphs;
                            },
                            statements: [
                              ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[308,28],[308,37]]]],"doc"],[],["loc",[null,[308,24],[308,44]]]]],[],0,1,["loc",[null,[308,14],[312,14]]]]
                            ],
                            locals: [],
                            templates: [child0, child1]
                          };
                        }());
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 306,
                                "column": 14
                              },
                              "end": {
                                "line": 312,
                                "column": 14
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(1);
                            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                            dom.insertBoundary(fragment, 0);
                            dom.insertBoundary(fragment, null);
                            return morphs;
                          },
                          statements: [
                            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[306,28],[306,37]]]],"com"],[],["loc",[null,[306,24],[306,44]]]]],[],0,1,["loc",[null,[306,14],[312,14]]]]
                          ],
                          locals: [],
                          templates: [child0, child1]
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 304,
                              "column": 14
                            },
                            "end": {
                              "line": 312,
                              "column": 14
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[304,28],[304,37]]]],"mail"],[],["loc",[null,[304,24],[304,45]]]]],[],0,1,["loc",[null,[304,14],[312,14]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 302,
                            "column": 14
                          },
                          "end": {
                            "line": 312,
                            "column": 14
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[302,28],[302,37]]]],"vacc"],[],["loc",[null,[302,24],[302,45]]]]],[],0,1,["loc",[null,[302,14],[312,14]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 300,
                          "column": 14
                        },
                        "end": {
                          "line": 312,
                          "column": 14
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[300,28],[300,37]]]],"invoice"],[],["loc",[null,[300,24],[300,48]]]]],[],0,1,["loc",[null,[300,14],[312,14]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 298,
                        "column": 14
                      },
                      "end": {
                        "line": 312,
                        "column": 14
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[298,28],[298,37]]]],"img"],[],["loc",[null,[298,24],[298,44]]]]],[],0,1,["loc",[null,[298,14],[312,14]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 296,
                      "column": 14
                    },
                    "end": {
                      "line": 312,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[296,28],[296,37]]]],"lab"],[],["loc",[null,[296,24],[296,44]]]]],[],0,1,["loc",[null,[296,14],[312,14]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 294,
                    "column": 14
                  },
                  "end": {
                    "line": 312,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[294,28],[294,37]]]],"not"],[],["loc",[null,[294,24],[294,44]]]]],[],0,1,["loc",[null,[294,14],[312,14]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 292,
                  "column": 14
                },
                "end": {
                  "line": 312,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[292,28],[292,37]]]],"rem"],[],["loc",[null,[292,24],[292,44]]]]],[],0,1,["loc",[null,[292,14],[312,14]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 315,
                    "column": 84
                  },
                  "end": {
                    "line": 315,
                    "column": 236
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.patient.customer.firstname",["loc",[null,[315,166],[315,201]]]],
                ["content","item.patient.customer.lastname",["loc",[null,[315,202],[315,236]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 315,
                    "column": 253
                  },
                  "end": {
                    "line": 315,
                    "column": 346
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.patient.name",["loc",[null,[315,325],[315,346]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 315,
                    "column": 359
                  },
                  "end": {
                    "line": 315,
                    "column": 457
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"src","assets/images/eltiga-poe.png");
                dom.setAttribute(el1,"style","max-height: 18px");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 314,
                  "column": 12
                },
                "end": {
                  "line": 316,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","mtm10 mb10 d-flex align-items-center flex-gap-5");
              var el2 = dom.createTextNode("Kund:in: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" mit ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element46 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element46,1,1);
              morphs[1] = dom.createMorphAt(element46,3,3);
              morphs[2] = dom.createMorphAt(element46,5,5);
              return morphs;
            },
            statements: [
              ["block","link-to",["customers.edit",["get","item.patient.customer.id",["loc",[null,[315,112],[315,136]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[315,84],[315,248]]]],
              ["block","link-to",["patients.edit",["get","item.patient.id",["loc",[null,[315,280],[315,295]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[315,253],[315,358]]]],
              ["block","if",[["get","item.patient.etigaActive",["loc",[null,[315,365],[315,389]]]]],[],2,null,["loc",[null,[315,359],[315,464]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child5 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 321,
                    "column": 14
                  },
                  "end": {
                    "line": 323,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                Von Kund:in\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 324,
                    "column": 14
                  },
                  "end": {
                    "line": 326,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                Von Praxis\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 327,
                    "column": 14
                  },
                  "end": {
                    "line": 329,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                Von Praxis\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 319,
                  "column": 10
                },
                "end": {
                  "line": 334,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element44 = dom.childAt(fragment, [1]);
              var element45 = dom.childAt(fragment, [3]);
              var morphs = new Array(6);
              morphs[0] = dom.createAttrMorph(element44, 'class');
              morphs[1] = dom.createMorphAt(element44,1,1);
              morphs[2] = dom.createMorphAt(element44,2,2);
              morphs[3] = dom.createMorphAt(element44,3,3);
              morphs[4] = dom.createAttrMorph(element45, 'class');
              morphs[5] = dom.createUnsafeMorphAt(element45,1,1);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["message-sender mt20 ",["get","item.title",["loc",[null,[320,46],[320,56]]]]]]],
              ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[321,24],[321,34]]]],"msg_incoming"],[],["loc",[null,[321,20],[321,50]]]]],[],0,null,["loc",[null,[321,14],[323,21]]]],
              ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[324,24],[324,34]]]],"reminder"],[],["loc",[null,[324,20],[324,46]]]]],[],1,null,["loc",[null,[324,14],[326,21]]]],
              ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[327,24],[327,34]]]],"msg_outgoing"],[],["loc",[null,[327,20],[327,50]]]]],[],2,null,["loc",[null,[327,14],[329,21]]]],
              ["attribute","class",["concat",["message ",["get","item.title",["loc",[null,[331,34],[331,44]]]]]]],
              ["content","item.payload.text",["loc",[null,[332,14],[332,37]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child6 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 335,
                  "column": 10
                },
                "end": {
                  "line": 345,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element42 = dom.childAt(fragment, [1]);
              var element43 = dom.childAt(element42, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element42, [1]),1,1);
              morphs[1] = dom.createElementMorph(element43);
              morphs[2] = dom.createMorphAt(element43,1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[338,16],[338,39]]]],
              ["element","action",["openApp",["get","item.referenceId",["loc",[null,[340,36],[340,52]]]]],[],["loc",[null,[340,17],[340,54]]]],
              ["inline","button-edit",[],["size","32","color","#fff","showStroke",false],["loc",[null,[342,16],[342,71]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child7 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 346,
                  "column": 10
                },
                "end": {
                  "line": 356,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element40 = dom.childAt(fragment, [1]);
              var element41 = dom.childAt(element40, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element40, [1]),1,1);
              morphs[1] = dom.createElementMorph(element41);
              morphs[2] = dom.createMorphAt(element41,1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[349,16],[349,39]]]],
              ["element","action",["editNote",["get","item.referenceId",["loc",[null,[351,37],[351,53]]]],["get","item.patient.id",["loc",[null,[351,54],[351,69]]]]],[],["loc",[null,[351,17],[351,71]]]],
              ["inline","button-edit",[],["size","32","color","#fff","showStroke",false],["loc",[null,[353,16],[353,71]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child8 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 357,
                  "column": 10
                },
                "end": {
                  "line": 363,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1, 1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[360,16],[360,39]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child9 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 364,
                  "column": 10
                },
                "end": {
                  "line": 370,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1, 1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[367,16],[367,39]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child10 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 377,
                    "column": 18
                  },
                  "end": {
                    "line": 379,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","label label-danger");
                var el2 = dom.createTextNode("Storniert");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 379,
                    "column": 18
                  },
                  "end": {
                    "line": 381,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","invoice-paymentstatus",[],["paymentStatus",["subexpr","@mut",[["get","item.payload.paymentStatus",["loc",[null,[380,58],[380,84]]]]],[],[]]],["loc",[null,[380,20],[380,86]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 386,
                      "column": 20
                    },
                    "end": {
                      "line": 388,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","entry.patient",["loc",[null,[387,25],[387,42]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 393,
                          "column": 51
                        },
                        "end": {
                          "line": 394,
                          "column": 107
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[394,91],[394,107]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 392,
                        "column": 26
                      },
                      "end": {
                        "line": 396,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" x ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("   ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("br");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(3);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                    morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["content","article.quantity",["loc",[null,[393,28],[393,48]]]],
                    ["block","link-to",["services.edit",["get","article.id",["loc",[null,[393,78],[393,88]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[393,51],[394,119]]]],
                    ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[395,54],[395,67]]]]],[],[]]],["loc",[null,[394,137],[395,69]]]]
                  ],
                  locals: ["article"],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 390,
                      "column": 24
                    },
                    "end": {
                      "line": 397,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Verrechnete Leistungen:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","entry.services",["loc",[null,[392,34],[392,48]]]]],[],0,null,["loc",[null,[392,26],[396,35]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 401,
                          "column": 51
                        },
                        "end": {
                          "line": 402,
                          "column": 107
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[402,91],[402,107]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 400,
                        "column": 26
                      },
                      "end": {
                        "line": 404,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" x ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("   ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("br");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(3);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                    morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["content","article.quantity",["loc",[null,[401,28],[401,48]]]],
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[401,78],[401,88]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[401,51],[402,119]]]],
                    ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[403,54],[403,67]]]]],[],[]]],["loc",[null,[402,137],[403,69]]]]
                  ],
                  locals: ["article"],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 398,
                      "column": 24
                    },
                    "end": {
                      "line": 405,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Verrechnete Produkte:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","entry.products",["loc",[null,[400,34],[400,48]]]]],[],0,null,["loc",[null,[400,26],[404,35]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child3 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 409,
                          "column": 51
                        },
                        "end": {
                          "line": 410,
                          "column": 107
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[410,91],[410,107]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 408,
                        "column": 26
                      },
                      "end": {
                        "line": 412,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" x ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("   ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("br");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(3);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                    morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["content","article.quantity",["loc",[null,[409,28],[409,48]]]],
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[409,78],[409,88]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[409,51],[410,119]]]],
                    ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[411,54],[411,67]]]]],[],[]]],["loc",[null,[410,137],[411,69]]]]
                  ],
                  locals: ["article"],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 406,
                      "column": 24
                    },
                    "end": {
                      "line": 413,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Verrechnete Medikamente:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","entry.meds",["loc",[null,[408,34],[408,44]]]]],[],0,null,["loc",[null,[408,26],[412,35]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child4 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 417,
                          "column": 51
                        },
                        "end": {
                          "line": 418,
                          "column": 107
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[418,91],[418,107]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 416,
                        "column": 26
                      },
                      "end": {
                        "line": 420,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" x ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("   ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("br");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(3);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                    morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["content","article.quantity",["loc",[null,[417,28],[417,48]]]],
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[417,78],[417,88]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[417,51],[418,119]]]],
                    ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[419,54],[419,67]]]]],[],[]]],["loc",[null,[418,137],[419,69]]]]
                  ],
                  locals: ["article"],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 414,
                      "column": 24
                    },
                    "end": {
                      "line": 421,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Verrechnete Ernährung:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","entry.feeding",["loc",[null,[416,34],[416,47]]]]],[],0,null,["loc",[null,[416,26],[420,35]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child5 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 425,
                          "column": 28
                        },
                        "end": {
                          "line": 430,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","align-items-center mb5 mt5");
                      var el2 = dom.createTextNode("\n                                ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("img");
                      dom.setAttribute(el2,"src","assets/images/icons/filetypes/pdf.svg");
                      dom.setAttribute(el2,"class","mr5");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("a");
                      dom.setAttribute(el2,"class","is-highlighted cursor");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                              ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element35 = dom.childAt(fragment, [1, 3]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createElementMorph(element35);
                      morphs[1] = dom.createMorphAt(element35,0,0);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["gotoTemplate",["get","item.referenceId",["loc",[null,[428,89],[428,105]]]],["get","template.id",["loc",[null,[428,106],[428,117]]]]],[],["loc",[null,[428,65],[428,119]]]],
                      ["content","template.name",["loc",[null,[428,120],[428,137]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 424,
                        "column": 26
                      },
                      "end": {
                        "line": 431,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","template.name",["loc",[null,[425,34],[425,47]]]]],[],0,null,["loc",[null,[425,28],[430,35]]]]
                  ],
                  locals: ["template"],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 422,
                      "column": 24
                    },
                    "end": {
                      "line": 432,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Vorlagen:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","entry.templates",["loc",[null,[424,34],[424,49]]]]],[],0,null,["loc",[null,[424,26],[431,35]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 385,
                    "column": 18
                  },
                  "end": {
                    "line": 434,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","pl5 pt5 pb10");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element36 = dom.childAt(fragment, [2]);
                var morphs = new Array(6);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(element36,1,1);
                morphs[2] = dom.createMorphAt(element36,2,2);
                morphs[3] = dom.createMorphAt(element36,3,3);
                morphs[4] = dom.createMorphAt(element36,4,4);
                morphs[5] = dom.createMorphAt(element36,5,5);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["block","if",[["get","entry.patientId",["loc",[null,[386,26],[386,41]]]]],[],0,null,["loc",[null,[386,20],[388,27]]]],
                ["block","if",[["get","entry.services",["loc",[null,[390,30],[390,44]]]]],[],1,null,["loc",[null,[390,24],[397,31]]]],
                ["block","if",[["get","entry.products",["loc",[null,[398,30],[398,44]]]]],[],2,null,["loc",[null,[398,24],[405,31]]]],
                ["block","if",[["get","entry.meds",["loc",[null,[406,30],[406,40]]]]],[],3,null,["loc",[null,[406,24],[413,31]]]],
                ["block","if",[["get","entry.feeding",["loc",[null,[414,30],[414,43]]]]],[],4,null,["loc",[null,[414,24],[421,31]]]],
                ["block","if",[["get","entry.templates",["loc",[null,[422,30],[422,45]]]]],[],5,null,["loc",[null,[422,24],[432,31]]]]
              ],
              locals: ["entry"],
              templates: [child0, child1, child2, child3, child4, child5]
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 438,
                    "column": 14
                  },
                  "end": {
                    "line": 441,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[440,16],[440,71]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 371,
                  "column": 10
                },
                "end": {
                  "line": 443,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ms-1");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","d-flex align-items-center");
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","mr10");
              var el5 = dom.createElement("b");
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode(",");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","mr10");
              var el5 = dom.createTextNode("Gesamtsumme: ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode(" EUR");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element37 = dom.childAt(fragment, [1]);
              var element38 = dom.childAt(element37, [1]);
              var element39 = dom.childAt(element38, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element39, [1, 0]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element39, [3]),1,1);
              morphs[2] = dom.createMorphAt(element39,5,5);
              morphs[3] = dom.createMorphAt(dom.childAt(element38, [3]),1,1);
              morphs[4] = dom.createMorphAt(element37,3,3);
              return morphs;
            },
            statements: [
              ["content","item.payload.code",["loc",[null,[375,40],[375,61]]]],
              ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","item.payload.total",["loc",[null,[376,94],[376,112]]]]],[],[]]],["loc",[null,[376,50],[376,114]]]],
              ["block","if",[["subexpr","eq",[["get","item.payload.status",["loc",[null,[377,28],[377,47]]]],"canceled"],[],["loc",[null,[377,24],[377,59]]]]],[],0,1,["loc",[null,[377,18],[381,25]]]],
              ["block","each",[["get","item.payload.entries",["loc",[null,[385,26],[385,46]]]]],[],2,null,["loc",[null,[385,18],[434,27]]]],
              ["block","link-to",["invoices.show",["get","item.referenceId",["loc",[null,[438,41],[438,57]]]]],["classNames","icon-button icon-button--default list-action-square exp line-action align-items-center"],3,null,["loc",[null,[438,14],[441,26]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }());
        var child11 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 448,
                      "column": 18
                    },
                    "end": {
                      "line": 450,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    Eine eltiga App Benutzer:in hat das Dokument ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","is-highlighted cursor");
                  var el2 = dom.createElement("b");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" geteilt.\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element32 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element32);
                  morphs[1] = dom.createMorphAt(dom.childAt(element32, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["openDocument",["get","item.referenceId",["loc",[null,[449,122],[449,138]]]]],[],["loc",[null,[449,98],[449,140]]]],
                  ["content","item.payload.filename",["loc",[null,[449,144],[449,169]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 450,
                      "column": 18
                    },
                    "end": {
                      "line": 452,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    Die Praxis hat das Dokument ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","is-highlighted cursor");
                  var el2 = dom.createElement("b");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" hinzugefügt.\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element31 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element31);
                  morphs[1] = dom.createMorphAt(dom.childAt(element31, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["openDocument",["get","item.referenceId",["loc",[null,[451,105],[451,121]]]]],[],["loc",[null,[451,81],[451,123]]]],
                  ["content","item.payload.filename",["loc",[null,[451,127],[451,152]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 447,
                    "column": 16
                  },
                  "end": {
                    "line": 453,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.payload.ctx",["loc",[null,[448,28],[448,44]]]],"eltiga"],[],["loc",[null,[448,24],[448,54]]]]],[],0,1,["loc",[null,[448,18],[452,25]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 453,
                    "column": 16
                  },
                  "end": {
                    "line": 455,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","item.payload.text",["loc",[null,[454,18],[454,41]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 444,
                  "column": 10
                },
                "end": {
                  "line": 462,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ms-1 fw-semibold");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action align-items-center");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element33 = dom.childAt(fragment, [1]);
              var element34 = dom.childAt(element33, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element33, [1]),1,1);
              morphs[1] = dom.createElementMorph(element34);
              morphs[2] = dom.createMorphAt(element34,1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.payload.filename",["loc",[null,[447,22],[447,43]]]]],[],0,1,["loc",[null,[447,16],[455,23]]]],
              ["element","action",["openDocument",["get","item.referenceId",["loc",[null,[457,41],[457,57]]]],["get","item.patient.id",["loc",[null,[457,58],[457,73]]]]],[],["loc",[null,[457,17],[457,75]]]],
              ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[459,16],[459,71]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child12 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 463,
                  "column": 10
                },
                "end": {
                  "line": 471,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"class","mr10");
              dom.setAttribute(el2,"alt","");
              dom.setAttribute(el2,"style","width: 22px;");
              dom.setAttribute(el2,"src","assets/images/icons/history/vacc.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ms-1 fw-semibold");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[468,16],[468,37]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child13 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 478,
                    "column": 16
                  },
                  "end": {
                    "line": 479,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 479,
                      "column": 16
                    },
                    "end": {
                      "line": 480,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 480,
                        "column": 16
                      },
                      "end": {
                        "line": 481,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 481,
                        "column": 16
                      },
                      "end": {
                        "line": 483,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["content","item.payload.orderTypeString",["loc",[null,[482,18],[482,50]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 480,
                      "column": 16
                    },
                    "end": {
                      "line": 483,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","item.payload.privateLab",["loc",[null,[480,26],[480,49]]]]],[],0,1,["loc",[null,[480,16],[483,16]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 479,
                    "column": 16
                  },
                  "end": {
                    "line": 483,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[479,30],[479,47]]]],"laboklin"],[],["loc",[null,[479,26],[479,59]]]]],[],0,1,["loc",[null,[479,16],[483,16]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 483,
                    "column": 24
                  },
                  "end": {
                    "line": 483,
                    "column": 132
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","block small");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                return morphs;
              },
              statements: [
                ["inline","laboklin-reporttype",[],["type",["subexpr","@mut",[["get","form.reportType",["loc",[null,[483,108],[483,123]]]]],[],[]]],["loc",[null,[483,81],[483,125]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 472,
                  "column": 10
                },
                "end": {
                  "line": 493,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"class","mr10");
              dom.setAttribute(el2,"alt","");
              dom.setAttribute(el2,"style","width: 30px;");
              dom.setAttribute(el2,"src","assets/images/icons/history/lab.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ms-1 fw-semibold");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element29 = dom.childAt(fragment, [1]);
              var element30 = dom.childAt(element29, [9]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(element29,3,3);
              morphs[1] = dom.createMorphAt(element29,5,5);
              morphs[2] = dom.createUnsafeMorphAt(dom.childAt(element29, [7]),1,1);
              morphs[3] = dom.createElementMorph(element30);
              morphs[4] = dom.createMorphAt(element30,1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[478,26],[478,43]]]],"vetconnect"],[],["loc",[null,[478,22],[478,57]]]]],[],0,1,["loc",[null,[478,16],[483,23]]]],
              ["block","if",[["get","item.payload.reportType",["loc",[null,[483,30],[483,53]]]]],[],2,null,["loc",[null,[483,24],[483,139]]]],
              ["content","item.payload.text",["loc",[null,[486,16],[486,39]]]],
              ["element","action",["openLab",["get","item.referenceId",["loc",[null,[488,36],[488,52]]]],["get","item.patient.id",["loc",[null,[488,53],[488,68]]]]],[],["loc",[null,[488,17],[488,70]]]],
              ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[490,16],[490,71]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child14 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 494,
                  "column": 10
                },
                "end": {
                  "line": 506,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center img detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"class","mr10");
              dom.setAttribute(el2,"alt","");
              dom.setAttribute(el2,"style","width: 30px;");
              dom.setAttribute(el2,"src","assets/images/icons/history/img.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ms-1 fw-semibold");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element27 = dom.childAt(fragment, [1]);
              var element28 = dom.childAt(element27, [5]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element27, [3]),1,1);
              morphs[1] = dom.createElementMorph(element28);
              morphs[2] = dom.createMorphAt(element28,1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[499,16],[499,37]]]],
              ["element","action",["openLab",["get","item.referenceId",["loc",[null,[501,38],[501,54]]]]],[],["loc",[null,[501,19],[501,56]]]],
              ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[503,18],[503,73]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child15 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 510,
                    "column": 16
                  },
                  "end": {
                    "line": 512,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Anamnese:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.medicalHistory",["loc",[null,[511,65],[511,98]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 513,
                    "column": 16
                  },
                  "end": {
                    "line": 515,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Symptome:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.sypmtoms",["loc",[null,[514,65],[514,92]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 516,
                    "column": 16
                  },
                  "end": {
                    "line": 518,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Diagnose:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.diagnosis",["loc",[null,[517,65],[517,93]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 519,
                    "column": 16
                  },
                  "end": {
                    "line": 521,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Therapie:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.therapy",["loc",[null,[520,65],[520,91]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 522,
                    "column": 16
                  },
                  "end": {
                    "line": 524,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Beratung:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.report",["loc",[null,[523,65],[523,90]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child5 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 527,
                      "column": 20
                    },
                    "end": {
                      "line": 527,
                      "column": 145
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"style","font-style: italic;font-weight:normal");
                  var el2 = dom.createTextNode("\"");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\"");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
                  return morphs;
                },
                statements: [
                  ["content","item.payload.ratingComment",["loc",[null,[527,107],[527,137]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 525,
                    "column": 16
                  },
                  "end": {
                    "line": 529,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Feedback durch Kund:in:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element24 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element24,1,1);
                morphs[1] = dom.createMorphAt(element24,3,3);
                return morphs;
              },
              statements: [
                ["inline","rating-status",[],["rating",["subexpr","@mut",[["get","item.payload.rating",["loc",[null,[526,102],[526,121]]]]],[],[]],"classNames","inline-block"],["loc",[null,[526,79],[526,149]]]],
                ["block","if",[["get","item.payload.ratingComment",["loc",[null,[527,26],[527,52]]]]],[],0,null,["loc",[null,[527,20],[527,152]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child6 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 530,
                    "column": 16
                  },
                  "end": {
                    "line": 532,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Tierärztliche Leistungen:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.manualTreatment",["loc",[null,[531,81],[531,115]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child7 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 537,
                        "column": 49
                      },
                      "end": {
                        "line": 537,
                        "column": 90
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[537,74],[537,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 537,
                          "column": 98
                        },
                        "end": {
                          "line": 538,
                          "column": 154
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[538,138],[538,154]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 537,
                        "column": 90
                      },
                      "end": {
                        "line": 538,
                        "column": 166
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["services.edit",["get","article.id",["loc",[null,[537,125],[537,135]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[537,98],[538,166]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 539,
                        "column": 24
                      },
                      "end": {
                        "line": 540,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","mr5");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                        ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element21 = dom.childAt(fragment, [3]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element21);
                    morphs[1] = dom.createMorphAt(element21,0,0);
                    morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openSlopingInfos",["get","article.lineId",["loc",[null,[539,121],[539,135]]]],["get","article.id",["loc",[null,[539,136],[539,146]]]]],[],["loc",[null,[539,93],[539,148]]]],
                    ["inline","button-sloping",[],["color","#3B6182","size","28","content","Dosierungsinformation bearbeiten","selected",true,"cssClasses","selected"],["loc",[null,[539,149],[539,272]]]],
                    ["content","article.dosingInfo",["loc",[null,[539,283],[539,305]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 535,
                      "column": 18
                    },
                    "end": {
                      "line": 541,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" x ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element22 = dom.childAt(fragment, [1]);
                  var element23 = dom.childAt(element22, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(element23,0,0);
                  morphs[1] = dom.createMorphAt(element23,2,2);
                  morphs[2] = dom.createMorphAt(element22,3,3);
                  return morphs;
                },
                statements: [
                  ["content","article.quantity",["loc",[null,[537,26],[537,46]]]],
                  ["block","if",[["get","item.eltigaRecord",["loc",[null,[537,55],[537,72]]]]],[],0,1,["loc",[null,[537,49],[538,173]]]],
                  ["block","if",[["get","article.dosing",["loc",[null,[539,30],[539,44]]]]],[],2,null,["loc",[null,[539,24],[540,31]]]]
                ],
                locals: ["article"],
                templates: [child0, child1, child2]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 533,
                    "column": 16
                  },
                  "end": {
                    "line": 542,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createTextNode("Erhaltene Leistungen:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.services",["loc",[null,[535,26],[535,47]]]]],[],0,null,["loc",[null,[535,18],[541,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child8 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 547,
                        "column": 49
                      },
                      "end": {
                        "line": 547,
                        "column": 90
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[547,74],[547,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 547,
                          "column": 98
                        },
                        "end": {
                          "line": 548,
                          "column": 154
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[548,138],[548,154]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 547,
                        "column": 90
                      },
                      "end": {
                        "line": 548,
                        "column": 166
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[547,125],[547,135]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[547,98],[548,166]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 549,
                        "column": 22
                      },
                      "end": {
                        "line": 550,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","mr5");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                  ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element18 = dom.childAt(fragment, [3]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element18);
                    morphs[1] = dom.createMorphAt(element18,0,0);
                    morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openSlopingInfos",["get","article.lineId",["loc",[null,[549,119],[549,133]]]],["get","article.id",["loc",[null,[549,134],[549,144]]]]],[],["loc",[null,[549,91],[549,146]]]],
                    ["inline","button-sloping",[],["color","#3B6182","size","28","content","Dosierungsinformation bearbeiten","selected",true,"cssClasses","selected"],["loc",[null,[549,147],[549,270]]]],
                    ["content","article.dosingInfo",["loc",[null,[549,281],[549,303]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 545,
                      "column": 18
                    },
                    "end": {
                      "line": 551,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" x ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element19 = dom.childAt(fragment, [1]);
                  var element20 = dom.childAt(element19, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(element20,0,0);
                  morphs[1] = dom.createMorphAt(element20,2,2);
                  morphs[2] = dom.createMorphAt(element19,3,3);
                  return morphs;
                },
                statements: [
                  ["content","article.quantity",["loc",[null,[547,26],[547,46]]]],
                  ["block","if",[["get","item.eltigaRecord",["loc",[null,[547,55],[547,72]]]]],[],0,1,["loc",[null,[547,49],[548,173]]]],
                  ["block","if",[["get","article.dosing",["loc",[null,[549,28],[549,42]]]]],[],2,null,["loc",[null,[549,22],[550,25]]]]
                ],
                locals: ["article"],
                templates: [child0, child1, child2]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 543,
                    "column": 16
                  },
                  "end": {
                    "line": 552,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createTextNode("Erhaltene Produkte:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.products",["loc",[null,[545,26],[545,47]]]]],[],0,null,["loc",[null,[545,18],[551,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child9 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 557,
                        "column": 49
                      },
                      "end": {
                        "line": 557,
                        "column": 90
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[557,74],[557,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 557,
                          "column": 98
                        },
                        "end": {
                          "line": 558,
                          "column": 154
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[558,138],[558,154]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 557,
                        "column": 90
                      },
                      "end": {
                        "line": 558,
                        "column": 166
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[557,125],[557,135]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[557,98],[558,166]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 559,
                        "column": 22
                      },
                      "end": {
                        "line": 560,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","mr5");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                      ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element15 = dom.childAt(fragment, [3]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element15);
                    morphs[1] = dom.createMorphAt(element15,0,0);
                    morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openSlopingInfos",["get","article.lineId",["loc",[null,[559,119],[559,133]]]],["get","article.id",["loc",[null,[559,134],[559,144]]]]],[],["loc",[null,[559,91],[559,146]]]],
                    ["inline","button-sloping",[],["color","#3B6182","size","28","content","Dosierungsinformation bearbeiten","selected",true,"cssClasses","selected"],["loc",[null,[559,147],[559,270]]]],
                    ["content","article.dosingInfo",["loc",[null,[559,281],[559,303]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 555,
                      "column": 18
                    },
                    "end": {
                      "line": 561,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" x ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element16 = dom.childAt(fragment, [1]);
                  var element17 = dom.childAt(element16, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(element17,0,0);
                  morphs[1] = dom.createMorphAt(element17,2,2);
                  morphs[2] = dom.createMorphAt(element16,3,3);
                  return morphs;
                },
                statements: [
                  ["content","article.quantity",["loc",[null,[557,26],[557,46]]]],
                  ["block","if",[["get","item.eltigaRecord",["loc",[null,[557,55],[557,72]]]]],[],0,1,["loc",[null,[557,49],[558,173]]]],
                  ["block","if",[["get","article.dosing",["loc",[null,[559,28],[559,42]]]]],[],2,null,["loc",[null,[559,22],[560,29]]]]
                ],
                locals: ["article"],
                templates: [child0, child1, child2]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 553,
                    "column": 16
                  },
                  "end": {
                    "line": 562,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createTextNode("Erhaltene Medikamente:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.meds",["loc",[null,[555,26],[555,43]]]]],[],0,null,["loc",[null,[555,18],[561,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child10 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 567,
                        "column": 49
                      },
                      "end": {
                        "line": 567,
                        "column": 90
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[567,74],[567,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 567,
                          "column": 98
                        },
                        "end": {
                          "line": 568,
                          "column": 154
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[568,138],[568,154]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 567,
                        "column": 90
                      },
                      "end": {
                        "line": 568,
                        "column": 166
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[567,125],[567,135]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[567,98],[568,166]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 569,
                        "column": 24
                      },
                      "end": {
                        "line": 572,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","mr5");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n\n\n                        ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element12 = dom.childAt(fragment, [3]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element12);
                    morphs[1] = dom.createMorphAt(element12,0,0);
                    morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openSlopingInfos",["get","article.lineId",["loc",[null,[569,121],[569,135]]]],["get","article.id",["loc",[null,[569,136],[569,146]]]]],[],["loc",[null,[569,93],[569,148]]]],
                    ["inline","button-sloping",[],["color","#3B6182","size","28","content","Dosierungsinformation bearbeiten","selected",true,"cssClasses","selected"],["loc",[null,[569,149],[569,272]]]],
                    ["content","article.dosingInfo",["loc",[null,[569,283],[569,305]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 565,
                      "column": 18
                    },
                    "end": {
                      "line": 573,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" x ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element13 = dom.childAt(fragment, [1]);
                  var element14 = dom.childAt(element13, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(element14,0,0);
                  morphs[1] = dom.createMorphAt(element14,2,2);
                  morphs[2] = dom.createMorphAt(element13,3,3);
                  return morphs;
                },
                statements: [
                  ["content","article.quantity",["loc",[null,[567,26],[567,46]]]],
                  ["block","if",[["get","item.eltigaRecord",["loc",[null,[567,55],[567,72]]]]],[],0,1,["loc",[null,[567,49],[568,173]]]],
                  ["block","if",[["get","article.dosing",["loc",[null,[569,30],[569,44]]]]],[],2,null,["loc",[null,[569,24],[572,31]]]]
                ],
                locals: ["article"],
                templates: [child0, child1, child2]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 563,
                    "column": 16
                  },
                  "end": {
                    "line": 574,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createTextNode("Erhaltene Ernährung:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.feeding",["loc",[null,[565,26],[565,46]]]]],[],0,null,["loc",[null,[565,18],[573,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child11 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 579,
                          "column": 22
                        },
                        "end": {
                          "line": 585,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","align-items-center mb5 mt5");
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("img");
                      dom.setAttribute(el2,"src","assets/images/icons/filetypes/pdf.svg");
                      dom.setAttribute(el2,"class","mr5");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("a");
                      dom.setAttribute(el2,"class","is-highlighted cursor");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element11 = dom.childAt(fragment, [1, 3]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createElementMorph(element11);
                      morphs[1] = dom.createMorphAt(element11,0,0);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["gotoTemplate",["get","item.referenceId",["loc",[null,[582,83],[582,99]]]],["get","template.id",["loc",[null,[583,68],[583,79]]]]],[],["loc",[null,[582,59],[583,81]]]],
                      ["content","template.name",["loc",[null,[583,82],[583,99]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 578,
                        "column": 20
                      },
                      "end": {
                        "line": 586,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","template.name",["loc",[null,[579,28],[579,41]]]]],[],0,null,["loc",[null,[579,22],[585,29]]]]
                  ],
                  locals: ["template"],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 576,
                      "column": 18
                    },
                    "end": {
                      "line": 587,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("Vorlagen:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","item.payload.templates",["loc",[null,[578,28],[578,50]]]]],[],0,null,["loc",[null,[578,20],[586,29]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 591,
                          "column": 22
                        },
                        "end": {
                          "line": 597,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","align-items-center mb5 mt5");
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("img");
                      dom.setAttribute(el2,"src","assets/images/icons/filetypes/pdf.svg");
                      dom.setAttribute(el2,"class","mr5");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("a");
                      dom.setAttribute(el2,"class","is-highlighted cursor");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element10 = dom.childAt(fragment, [1, 3]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createElementMorph(element10);
                      morphs[1] = dom.createMorphAt(element10,0,0);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["gotoReport",["get","item.referenceId",["loc",[null,[594,81],[594,97]]]],["get","report.id",["loc",[null,[595,68],[595,77]]]]],[],["loc",[null,[594,59],[595,79]]]],
                      ["content","report.name",["loc",[null,[595,80],[595,95]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 590,
                        "column": 20
                      },
                      "end": {
                        "line": 598,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","report.name",["loc",[null,[591,28],[591,39]]]]],[],0,null,["loc",[null,[591,22],[597,29]]]]
                  ],
                  locals: ["report"],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 588,
                      "column": 18
                    },
                    "end": {
                      "line": 599,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("Berichte:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","item.payload.reports",["loc",[null,[590,28],[590,48]]]]],[],0,null,["loc",[null,[590,20],[598,29]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 575,
                    "column": 16
                  },
                  "end": {
                    "line": 600,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.payload.templates",["loc",[null,[576,24],[576,46]]]]],[],0,null,["loc",[null,[576,18],[587,25]]]],
                ["block","if",[["get","item.payload.reports",["loc",[null,[588,24],[588,44]]]]],[],1,null,["loc",[null,[588,18],[599,25]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child12 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 605,
                        "column": 18
                      },
                      "end": {
                        "line": 612,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","exp icon-button list-action-square line-action");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element8 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element8, 'style');
                    morphs[1] = dom.createMorphAt(element8,1,1);
                    return morphs;
                  },
                  statements: [
                    ["attribute","style",["concat",["right: ",["subexpr","css-bool-evaluator",[["get","showCopy",["loc",[null,[606,58],[606,66]]]],"145px","100px"],[],["loc",[null,[606,37],[606,84]]]]]]],
                    ["inline","button-hide-app-with-confirm",[],["confirmed","hideTreatment","size","28","id",["subexpr","@mut",[["get","item.referenceId",["loc",[null,[608,56],[608,72]]]]],[],[]],"classNames","","color","#3B6182","content","In eltiga App sichtbar","text","Wollen Sie diese Behandlung für eltiga App Benutzer:innen wirklich ausblenden?"],["loc",[null,[607,22],[610,140]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 613,
                          "column": 20
                        },
                        "end": {
                          "line": 619,
                          "column": 20
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square line-action");
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element7 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element7, 'style');
                      morphs[1] = dom.createElementMorph(element7);
                      morphs[2] = dom.createMorphAt(element7,1,1);
                      return morphs;
                    },
                    statements: [
                      ["attribute","style",["concat",["right: ",["subexpr","css-bool-evaluator",[["get","showCopy",["loc",[null,[614,60],[614,68]]]],"145px","100px"],[],["loc",[null,[614,39],[614,86]]]]]]],
                      ["element","action",["unhideTreatment",["get","item.referenceId",["loc",[null,[614,115],[614,131]]]]],[],["loc",[null,[614,88],[614,133]]]],
                      ["inline","button-unhide-app",[],["size",28,"showStroke",false,"innerColor","#fff","color","#fff","content","In eltiga App ausgeblendet"],["loc",[null,[616,24],[617,82]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 619,
                          "column": 20
                        },
                        "end": {
                          "line": 625,
                          "column": 20
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square line-action");
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element6 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element6, 'style');
                      morphs[1] = dom.createElementMorph(element6);
                      morphs[2] = dom.createMorphAt(element6,1,1);
                      return morphs;
                    },
                    statements: [
                      ["attribute","style",["concat",["right: ",["subexpr","css-bool-evaluator",[["get","showCopy",["loc",[null,[620,60],[620,68]]]],"145px","100px"],[],["loc",[null,[620,39],[620,86]]]]]]],
                      ["element","action",["unhideTreatmentNotAllowed",["get","item.referenceId",["loc",[null,[620,125],[620,141]]]]],[],["loc",[null,[620,88],[620,143]]]],
                      ["inline","button-unhide-app",[],["size",28,"showStroke",false,"innerColor","#fff","color","#fff","content","In eltiga App ausgeblendet"],["loc",[null,[622,24],[623,82]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 612,
                        "column": 18
                      },
                      "end": {
                        "line": 627,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","item.payload.eltigaShareable",["loc",[null,[613,26],[613,54]]]]],[],0,1,["loc",[null,[613,20],[625,27]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 629,
                        "column": 18
                      },
                      "end": {
                        "line": 634,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square line-action exp");
                    dom.setAttribute(el1,"style","right: 100px;");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element5 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element5);
                    morphs[1] = dom.createMorphAt(element5,1,1);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["copy",["get","item.referenceId",["loc",[null,[630,39],[630,55]]]]],[],["loc",[null,[630,23],[630,57]]]],
                    ["inline","button-copy-list",[],["showStroke",false,"size","42","color","#fff","classNames",""],["loc",[null,[632,22],[632,96]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 604,
                      "column": 16
                    },
                    "end": {
                      "line": 646,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square exp line-action");
                  dom.setAttribute(el1,"style","right: 55px;");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n\n                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square exp line-action");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element9 = dom.childAt(fragment, [4]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  morphs[2] = dom.createElementMorph(element9);
                  morphs[3] = dom.createMorphAt(element9,1,1);
                  morphs[4] = dom.createMorphAt(dom.childAt(fragment, [6]),1,1);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","item.payload.eltigaShared",["loc",[null,[605,24],[605,49]]]]],[],0,1,["loc",[null,[605,18],[627,25]]]],
                  ["block","if",[["get","showCopy",["loc",[null,[629,24],[629,32]]]]],[],2,null,["loc",[null,[629,18],[634,25]]]],
                  ["element","action",["editTreatment",["get","item.referenceId",["loc",[null,[636,46],[636,62]]]]],[],["loc",[null,[636,21],[636,64]]]],
                  ["inline","button-edit",[],["size","32","color","#fff","showStroke",false],["loc",[null,[638,20],[638,75]]]],
                  ["inline","button-delete-with-confirm-full",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","item",["loc",[null,[642,103],[642,107]]]]],[],[]],"confirmed","deleteTreatment","text","Wollen Sie diese Behandlung wirklich löschen?"],["loc",[null,[642,20],[644,103]]]]
                ],
                locals: [],
                templates: [child0, child1, child2]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 603,
                    "column": 14
                  },
                  "end": {
                    "line": 647,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","unless",[["get","item.payload.eltigaRecord",["loc",[null,[604,26],[604,51]]]]],[],0,null,["loc",[null,[604,16],[646,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child13 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 659,
                      "column": 20
                    },
                    "end": {
                      "line": 663,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","dropdown-item");
                  var el2 = dom.createTextNode("\n                        Bericht erstellen\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element1);
                  return morphs;
                },
                statements: [
                  ["element","action",["openReportChooser",["get","item.referenceId",["loc",[null,[660,54],[660,70]]]]],[],["loc",[null,[660,25],[660,72]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 663,
                        "column": 20
                      },
                      "end": {
                        "line": 667,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","dropdown-item");
                    var el2 = dom.createTextNode("\n                        Bericht(e) bearbeiten\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                    ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openReportChooser",["get","item.referenceId",["loc",[null,[664,54],[664,70]]]]],[],["loc",[null,[664,25],[664,72]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 663,
                      "column": 20
                    },
                    "end": {
                      "line": 667,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","gt",[["get","item.payload.reportCount",["loc",[null,[663,34],[663,58]]]],0],[],["loc",[null,[663,30],[663,61]]]]],[],0,null,["loc",[null,[663,20],[667,20]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 649,
                    "column": 14
                  },
                  "end": {
                    "line": 670,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","dropdown");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","ml5 icon-button icon-button--default list-action-square dropdown");
                dom.setAttribute(el2,"data-toggle","dropdown");
                dom.setAttribute(el2,"aria-haspopup","true");
                dom.setAttribute(el2,"aria-expanded","false");
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createTextNode("Mehr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","dropdown-menu");
                dom.setAttribute(el2,"aria-labelledby","dropdownMenuButton");
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","dropdown-item");
                var el4 = dom.createTextNode("\n                      Bearbeiten\n                    ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                  ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var element3 = dom.childAt(element2, [3]);
                var element4 = dom.childAt(element3, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]),1,1);
                morphs[1] = dom.createElementMorph(element4);
                morphs[2] = dom.createMorphAt(element3,3,3);
                return morphs;
              },
              statements: [
                ["inline","button-more-hor",[],["showStroke",false,"color","#fff","size",40,"content",""],["loc",[null,[653,20],[653,88]]]],
                ["element","action",["editTreatment",["get","item.referenceId",["loc",[null,[656,48],[656,64]]]]],[],["loc",[null,[656,23],[656,66]]]],
                ["block","if",[["subexpr","eq",[["get","item.payload.reportCount",["loc",[null,[659,30],[659,54]]]],0],[],["loc",[null,[659,26],[659,57]]]]],[],0,1,["loc",[null,[659,20],[667,27]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 507,
                  "column": 10
                },
                "end": {
                  "line": 675,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box treatment");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element25 = dom.childAt(fragment, [1]);
              var element26 = dom.childAt(element25, [1]);
              var morphs = new Array(14);
              morphs[0] = dom.createMorphAt(element26,1,1);
              morphs[1] = dom.createMorphAt(element26,2,2);
              morphs[2] = dom.createMorphAt(element26,3,3);
              morphs[3] = dom.createMorphAt(element26,4,4);
              morphs[4] = dom.createMorphAt(element26,5,5);
              morphs[5] = dom.createMorphAt(element26,6,6);
              morphs[6] = dom.createMorphAt(element26,7,7);
              morphs[7] = dom.createMorphAt(element26,8,8);
              morphs[8] = dom.createMorphAt(element26,9,9);
              morphs[9] = dom.createMorphAt(element26,10,10);
              morphs[10] = dom.createMorphAt(element26,11,11);
              morphs[11] = dom.createMorphAt(element26,12,12);
              morphs[12] = dom.createMorphAt(element25,3,3);
              morphs[13] = dom.createMorphAt(element25,5,5);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.payload.medicalHistory",["loc",[null,[510,22],[510,49]]]]],[],0,null,["loc",[null,[510,16],[512,23]]]],
              ["block","if",[["get","item.payload.sypmtoms",["loc",[null,[513,22],[513,43]]]]],[],1,null,["loc",[null,[513,16],[515,23]]]],
              ["block","if",[["get","item.payload.diagnosis",["loc",[null,[516,22],[516,44]]]]],[],2,null,["loc",[null,[516,16],[518,23]]]],
              ["block","if",[["get","item.payload.therapy",["loc",[null,[519,22],[519,42]]]]],[],3,null,["loc",[null,[519,16],[521,23]]]],
              ["block","if",[["get","item.payload.report",["loc",[null,[522,22],[522,41]]]]],[],4,null,["loc",[null,[522,16],[524,23]]]],
              ["block","if",[["get","item.payload.rating",["loc",[null,[525,22],[525,41]]]]],[],5,null,["loc",[null,[525,16],[529,23]]]],
              ["block","if",[["get","item.payload.manualTreatment",["loc",[null,[530,22],[530,50]]]]],[],6,null,["loc",[null,[530,16],[532,23]]]],
              ["block","if",[["get","item.payload.services",["loc",[null,[533,22],[533,43]]]]],[],7,null,["loc",[null,[533,16],[542,23]]]],
              ["block","if",[["get","item.payload.products",["loc",[null,[543,22],[543,43]]]]],[],8,null,["loc",[null,[543,16],[552,23]]]],
              ["block","if",[["get","item.payload.meds",["loc",[null,[553,22],[553,39]]]]],[],9,null,["loc",[null,[553,16],[562,23]]]],
              ["block","if",[["get","item.payload.feeding",["loc",[null,[563,22],[563,42]]]]],[],10,null,["loc",[null,[563,16],[574,23]]]],
              ["block","unless",[["get","item.eltigaRecord",["loc",[null,[575,26],[575,43]]]]],[],11,null,["loc",[null,[575,16],[600,27]]]],
              ["block","unless",[["get","item.eltigaRecord",["loc",[null,[603,24],[603,41]]]]],[],12,null,["loc",[null,[603,14],[647,25]]]],
              ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[649,20],[649,28]]]]],[],13,null,["loc",[null,[649,14],[670,21]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 237,
                "column": 4
              },
              "end": {
                "line": 679,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","timeline-item");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","timeline-line");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("img");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","pe-3 mb10");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","d-flex align-items-center mt-1 fs-6");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","me-2 fs-7");
            var el6 = dom.createElement("b");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(", ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                um ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                Uhr");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","tags ml20");
            var el6 = dom.createTextNode("\n");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","headline mt5 flex-center flex-gap-5");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element52 = dom.childAt(fragment, [1]);
            var element53 = dom.childAt(element52, [3]);
            var element54 = dom.childAt(element53, [1, 1]);
            var element55 = dom.childAt(element52, [5]);
            var element56 = dom.childAt(element55, [1]);
            var element57 = dom.childAt(element56, [1]);
            var element58 = dom.childAt(element57, [1, 0]);
            var element59 = dom.childAt(element57, [3]);
            var morphs = new Array(21);
            morphs[0] = dom.createAttrMorph(element53, 'class');
            morphs[1] = dom.createAttrMorph(element54, 'src');
            morphs[2] = dom.createAttrMorph(element55, 'class');
            morphs[3] = dom.createMorphAt(element58,0,0);
            morphs[4] = dom.createMorphAt(element58,2,2);
            morphs[5] = dom.createMorphAt(element58,4,4);
            morphs[6] = dom.createMorphAt(element59,1,1);
            morphs[7] = dom.createMorphAt(element59,2,2);
            morphs[8] = dom.createMorphAt(dom.childAt(element56, [3]),1,1);
            morphs[9] = dom.createMorphAt(element56,5,5);
            morphs[10] = dom.createMorphAt(element55,3,3);
            morphs[11] = dom.createMorphAt(element55,4,4);
            morphs[12] = dom.createMorphAt(element55,5,5);
            morphs[13] = dom.createMorphAt(element55,6,6);
            morphs[14] = dom.createMorphAt(element55,7,7);
            morphs[15] = dom.createMorphAt(element55,8,8);
            morphs[16] = dom.createMorphAt(element55,9,9);
            morphs[17] = dom.createMorphAt(element55,10,10);
            morphs[18] = dom.createMorphAt(element55,11,11);
            morphs[19] = dom.createMorphAt(element55,12,12);
            morphs[20] = dom.createMorphAt(element55,13,13);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["timeline-icon color-",["get","item.type",["loc",[null,[241,42],[241,51]]]]]]],
            ["attribute","src",["concat",["/assets/images/icons/history/",["get","item.type",["loc",[null,[243,53],[243,62]]]],".svg"]]],
            ["attribute","class",["concat",["timeline-content ",["get","item.type",["loc",[null,[246,39],[246,48]]]]," mb-10 mt-n1"]]],
            ["inline","get-weekday",[["get","item.date",["loc",[null,[249,54],[249,63]]]]],[],["loc",[null,[249,40],[249,65]]]],
            ["inline","time-format",[["get","item.date",["loc",[null,[249,81],[249,90]]]],"DD. MM. YYYY"],[],["loc",[null,[249,67],[249,107]]]],
            ["inline","time-format",[["get","item.date",["loc",[null,[250,33],[250,42]]]],"HH:mm"],[],["loc",[null,[250,19],[250,52]]]],
            ["block","each",[["get","item.tags",["loc",[null,[253,24],[253,33]]]]],[],0,null,["loc",[null,[253,16],[255,25]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[256,26],[256,35]]]],"lab"],[],["loc",[null,[256,22],[256,42]]]]],[],1,null,["loc",[null,[256,16],[264,23]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[270,24],[270,33]]]],"treat"],[],["loc",[null,[270,20],[270,42]]]]],[],2,3,["loc",[null,[270,14],[312,21]]]],
            ["block","if",[["subexpr","and",[["get","item.patient",["loc",[null,[314,23],[314,35]]]],["get","showPatientInfo",["loc",[null,[314,36],[314,51]]]]],[],["loc",[null,[314,18],[314,52]]]]],[],4,null,["loc",[null,[314,12],[316,19]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[319,20],[319,29]]]],"com"],[],["loc",[null,[319,16],[319,36]]]]],[],5,null,["loc",[null,[319,10],[334,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[335,20],[335,29]]]],"app"],[],["loc",[null,[335,16],[335,36]]]]],[],6,null,["loc",[null,[335,10],[345,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[346,20],[346,29]]]],"not"],[],["loc",[null,[346,16],[346,36]]]]],[],7,null,["loc",[null,[346,10],[356,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[357,20],[357,29]]]],"rem"],[],["loc",[null,[357,16],[357,36]]]]],[],8,null,["loc",[null,[357,10],[363,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[364,20],[364,29]]]],"mail"],[],["loc",[null,[364,16],[364,37]]]]],[],9,null,["loc",[null,[364,10],[370,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[371,20],[371,29]]]],"invoice"],[],["loc",[null,[371,16],[371,40]]]]],[],10,null,["loc",[null,[371,10],[443,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[444,20],[444,29]]]],"doc"],[],["loc",[null,[444,16],[444,36]]]]],[],11,null,["loc",[null,[444,10],[462,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[463,20],[463,29]]]],"vacc"],[],["loc",[null,[463,16],[463,37]]]]],[],12,null,["loc",[null,[463,10],[471,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[472,20],[472,29]]]],"lab"],[],["loc",[null,[472,16],[472,36]]]]],[],13,null,["loc",[null,[472,10],[493,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[494,20],[494,29]]]],"img"],[],["loc",[null,[494,16],[494,36]]]]],[],14,null,["loc",[null,[494,10],[506,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[507,20],[507,29]]]],"treat"],[],["loc",[null,[507,16],[507,38]]]]],[],15,null,["loc",[null,[507,10],[675,17]]]]
          ],
          locals: ["item"],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 235,
              "column": 4
            },
            "end": {
              "line": 683,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","pagination-wrapper is-fixed");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","items",["loc",[null,[237,12],[237,17]]]]],[],0,null,["loc",[null,[237,4],[679,13]]]],
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","items",["loc",[null,[681,29],[681,34]]]]],[],[]],"numPagesToShow",7],["loc",[null,[681,6],[681,53]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 747,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","patientActivityWrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","mb30 mt20 grid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter standalone mb10");
        dom.setAttribute(el3,"style","margin-right: -10px");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"style","padding-right: 9px");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","");
        dom.setAttribute(el6,"style","line-height: 30px");
        var el7 = dom.createTextNode("Alle");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small  l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/treat.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Behandlungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/invoice.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Rechnungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/app.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Termine");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/rem.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Erinnerungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/not.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Notizen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/doc.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Dokumente");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/lab.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Labor");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/img.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Bildgebung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/mail.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        E-Mail");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/com.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        eltiga Chat");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-8/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/history/vacc.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Impfungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-7/24@desk-wide l-8/24@desk l-24/24 gutter");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","pickDate");
        dom.setAttribute(el4,"class","date-picker input-element");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title mt0");
        var el6 = dom.createTextNode("Zeitraum");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"style","display: inline-block;width: 100%;padding-right: 55px;");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","field-icon");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("i");
        dom.setAttribute(el7,"class","fa fa-calendar");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","u-mb0 field select");
        dom.setAttribute(el5,"style","position: absolute;right: 0");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","switchFiltersJournal");
        dom.setAttribute(el6,"class","button filter-toggle");
        dom.setAttribute(el6,"style","border:none;background-color: #cccccc;color: #fff;display: block;height: 46px;width: 46px;padding:7px 10px 10px;");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"style","height: 30px;width: 30px;");
        dom.setAttribute(el7,"src","assets/images/icons/icon-filter.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter text-right col l-17/24@desk-wide l-16/24@desk l-24/24 pl5");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"style","font-weight: normal");
        dom.setAttribute(el4,"class","block title mb10");
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","timeline");
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function () {\n    $('#pickDateJournal').daterangepicker({\n      \"autoApply\": true,\n      \"ranges\": {\n        \"Alle\": [moment('2016-01-01T00:00:00.000'), moment()],\n        \"Letzten 30 Tage\": [moment().subtract(30, 'days'), moment()],\n        \"Letzten 6 Monate\": [moment().subtract(6, 'months'), moment()],\n        \"Dieses Jahr\": [moment().startOf('year'), moment()],\n      },\n      \"locale\": {\n        \"format\": \"DD. MM. YYYY\",\n        \"separator\": \" - \",\n        \"applyLabel\": \"Anwenden\",\n        \"cancelLabel\": \"Abbrechen\",\n        \"fromLabel\": \"Von\",\n        \"toLabel\": \"Bis\",\n        \"customRangeLabel\": \"Benutzerdefiniert\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n      \"linkedCalendars\": false\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element61 = dom.childAt(fragment, [0]);
        var element62 = dom.childAt(element61, [1]);
        var element63 = dom.childAt(element62, [1]);
        var element64 = dom.childAt(element63, [1, 1]);
        var element65 = dom.childAt(element63, [3, 1]);
        var element66 = dom.childAt(element63, [5, 1]);
        var element67 = dom.childAt(element63, [7, 1]);
        var element68 = dom.childAt(element63, [9, 1]);
        var element69 = dom.childAt(element63, [11, 1]);
        var element70 = dom.childAt(element63, [13, 1]);
        var element71 = dom.childAt(element63, [15, 1]);
        var element72 = dom.childAt(element63, [17, 1]);
        var element73 = dom.childAt(element63, [19, 1]);
        var element74 = dom.childAt(element63, [21, 1]);
        var element75 = dom.childAt(element63, [23, 1]);
        var element76 = dom.childAt(element62, [3, 1]);
        var morphs = new Array(32);
        morphs[0] = dom.createAttrMorph(element64, 'class');
        morphs[1] = dom.createElementMorph(element64);
        morphs[2] = dom.createAttrMorph(element65, 'class');
        morphs[3] = dom.createElementMorph(element65);
        morphs[4] = dom.createAttrMorph(element66, 'class');
        morphs[5] = dom.createElementMorph(element66);
        morphs[6] = dom.createAttrMorph(element67, 'class');
        morphs[7] = dom.createElementMorph(element67);
        morphs[8] = dom.createAttrMorph(element68, 'class');
        morphs[9] = dom.createElementMorph(element68);
        morphs[10] = dom.createAttrMorph(element69, 'class');
        morphs[11] = dom.createElementMorph(element69);
        morphs[12] = dom.createAttrMorph(element70, 'class');
        morphs[13] = dom.createElementMorph(element70);
        morphs[14] = dom.createAttrMorph(element71, 'class');
        morphs[15] = dom.createElementMorph(element71);
        morphs[16] = dom.createAttrMorph(element72, 'class');
        morphs[17] = dom.createElementMorph(element72);
        morphs[18] = dom.createAttrMorph(element73, 'class');
        morphs[19] = dom.createElementMorph(element73);
        morphs[20] = dom.createAttrMorph(element74, 'class');
        morphs[21] = dom.createElementMorph(element74);
        morphs[22] = dom.createAttrMorph(element75, 'class');
        morphs[23] = dom.createElementMorph(element75);
        morphs[24] = dom.createMorphAt(dom.childAt(element76, [3]),1,1);
        morphs[25] = dom.createMorphAt(dom.childAt(element76, [5]),1,1);
        morphs[26] = dom.createMorphAt(dom.childAt(element62, [5]),3,3);
        morphs[27] = dom.createMorphAt(element62,7,7);
        morphs[28] = dom.createMorphAt(dom.childAt(element61, [3]),1,1);
        morphs[29] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[30] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[31] = dom.createMorphAt(fragment,6,6,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["selectable-item text-center align-items-center color-all ",["subexpr","css-bool-evaluator",[["get","typeAllSelected",["loc",[null,[6,98],[6,113]]]],"selected-style",""],[],["loc",[null,[6,77],[7,91]]]]]]],
        ["element","action",["toggleTypeFilter","all"],[],["loc",[null,[7,93],[8,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-treat ",["subexpr","css-bool-evaluator",[["get","typeTreatmentSelected",["loc",[null,[11,88],[11,109]]]],"selected-style",""],[],["loc",[null,[11,67],[12,110]]]]]]],
        ["element","action",["toggleTypeFilter","treat"],[],["loc",[null,[12,112],[13,36]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-invoice ",["subexpr","css-bool-evaluator",[["get","typeInvoiceSelected",["loc",[null,[18,90],[18,109]]]],"selected-style",""],[],["loc",[null,[18,69],[20,95]]]]]]],
        ["element","action",["toggleTypeFilter","invoice"],[],["loc",[null,[20,97],[21,38]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-app ",["subexpr","css-bool-evaluator",[["get","typeAppSelected",["loc",[null,[26,86],[26,101]]]],"selected-style",""],[],["loc",[null,[26,65],[27,91]]]]]]],
        ["element","action",["toggleTypeFilter","app"],[],["loc",[null,[27,93],[28,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-rem ",["subexpr","css-bool-evaluator",[["get","typeRemSelected",["loc",[null,[33,86],[33,101]]]],"selected-style",""],[],["loc",[null,[33,65],[34,91]]]]]]],
        ["element","action",["toggleTypeFilter","rem"],[],["loc",[null,[34,93],[35,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-not ",["subexpr","css-bool-evaluator",[["get","typeNotSelected",["loc",[null,[40,86],[40,101]]]],"selected-style",""],[],["loc",[null,[40,65],[41,91]]]]]]],
        ["element","action",["toggleTypeFilter","not"],[],["loc",[null,[41,93],[42,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-doc ",["subexpr","css-bool-evaluator",[["get","typeDocSelected",["loc",[null,[47,86],[47,101]]]],"selected-style",""],[],["loc",[null,[47,65],[48,91]]]]]]],
        ["element","action",["toggleTypeFilter","doc"],[],["loc",[null,[48,93],[49,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-lab ",["subexpr","css-bool-evaluator",[["get","typeLabSelected",["loc",[null,[54,86],[54,101]]]],"selected-style",""],[],["loc",[null,[54,65],[55,91]]]]]]],
        ["element","action",["toggleTypeFilter","lab"],[],["loc",[null,[55,93],[56,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-img ",["subexpr","css-bool-evaluator",[["get","typeImgSelected",["loc",[null,[61,86],[61,101]]]],"selected-style",""],[],["loc",[null,[61,65],[62,91]]]]]]],
        ["element","action",["toggleTypeFilter","img"],[],["loc",[null,[62,93],[63,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-mail ",["subexpr","css-bool-evaluator",[["get","typeMailSelected",["loc",[null,[68,87],[68,103]]]],"selected-style",""],[],["loc",[null,[68,66],[69,92]]]]]]],
        ["element","action",["toggleTypeFilter","mail"],[],["loc",[null,[69,94],[70,35]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-com ",["subexpr","css-bool-evaluator",[["get","typeComSelected",["loc",[null,[75,86],[75,101]]]],"selected-style",""],[],["loc",[null,[75,65],[76,91]]]]]]],
        ["element","action",["toggleTypeFilter","com"],[],["loc",[null,[76,93],[77,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-vacc ",["subexpr","css-bool-evaluator",[["get","typeVaccSelected",["loc",[null,[82,87],[82,103]]]],"selected-style",""],[],["loc",[null,[82,66],[83,92]]]]]]],
        ["element","action",["toggleTypeFilter","vacc"],[],["loc",[null,[83,94],[84,35]]]],
        ["inline","input",[],["id","pickDateJournal","value",["subexpr","@mut",[["get","pickDateJournal",["loc",[null,[94,45],[94,60]]]]],[],[]],"class","new-style input input--date-picker ember-view ember-text-field","name","pickDateJournal"],["loc",[null,[94,10],[95,114]]]],
        ["inline","input",[],["type","checkbox","id","switchFiltersJournal","classNames","switch","name","switchFiltersJournal","checked",["subexpr","@mut",[["get","showFilters",["loc",[null,[101,116],[101,127]]]]],[],[]]],["loc",[null,[101,10],[101,129]]]],
        ["block","each",[["get","availableTags",["loc",[null,[110,14],[110,27]]]]],[],0,null,["loc",[null,[110,6],[113,15]]]],
        ["block","if",[["get","showFilters",["loc",[null,[116,10],[116,21]]]]],[],1,null,["loc",[null,[116,4],[228,11]]]],
        ["block","if",[["get","loading",["loc",[null,[233,10],[233,17]]]]],[],2,3,["loc",[null,[233,4],[683,11]]]],
        ["inline","treatment-history-report-chooser",[],["chooseReport","gotoReport","openWizard","openReportWizard","delete","deleteReport","prefix","-journal","actionReceiver",["subexpr","@mut",[["get","treatmentHistoryReportChooserActivities",["loc",[null,[692,17],[692,56]]]]],[],[]]],["loc",[null,[687,0],[692,58]]]],
        ["inline","treatment-report-wizzard-journal",[],["saveFinished","openReportPreview","actionReceiver",["subexpr","@mut",[["get","treatmentReportWizzardJournal",["loc",[null,[696,17],[696,46]]]]],[],[]]],["loc",[null,[694,0],[696,48]]]],
        ["content","eltiga-sharenotallowed-panel",["loc",[null,[698,0],[698,32]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});