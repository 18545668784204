define('jason-frontend/models/customer-deposit-transaction', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    created: DS['default'].attr('date'),
    slipId: DS['default'].attr('number'),
    slipCode: DS['default'].attr('string'),
    total: DS['default'].attr('number')
  });

});