define('jason-frontend/components/quick-scan-select-price-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      addProduct: function addProduct(qty, productId, referenceId, priceId, productName) {
        this.sendAction('add', qty, productId, referenceId, priceId, productName, this.get('patientId'), this.get('directEhapo'), this.get('stockId'));
        $.magnificPopup.close();
      },
      toggleProductFavorite: function toggleProductFavorite(product, add) {
        this.sendAction('toggleProductFavorite', product, add);
      },
      togglePriceFavorite: function togglePriceFavorite(product, price, add) {
        this.sendAction('togglePriceFavorite', product, price, add);
      },
      load: function load(product, patientId, directEhapo, stockId) {
        this.set('product', product.product[0]);
        this.set('patientId', patientId);
        this.set('directEhapo', directEhapo);
        this.set('stockId', stockId);
      },
      loadSingle: function loadSingle(product, patientId) {
        this.set('product', product.product);
        this.set('patientId', patientId);
        this.set('stockId', null);
      }
    }
  });

});