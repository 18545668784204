define('jason-frontend/components/patient-invoice-panel', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    toggleVisible: false,
    months: null,
    type: null,
    actions: {
      changePatient: function changePatient(invoiceId, patientId, add) {
        this.sendAction('changePatient', invoiceId, patientId, add);
      },
      refresh: function refresh() {
        $.magnificPopup.close();
        this.sendAction('refresh');
      },
      send: function send(invoice) {
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      addPatient: function addPatient(customerId, invoiceId) {
        this.sendAction('addPatient', customerId, invoiceId);
      }
    }
  });

});