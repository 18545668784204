define('jason-frontend/controllers/patients/create', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Anlegen",
    breadCrumbPath: "patients.create",
    selectedCustomerId: 0,
    selectTab: 'masterdata',
    actions: {
      triggerSave: function triggerSave() {
        $('form#patientEdit').submit();
      }
    }
  });

});