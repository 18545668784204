define('jason-frontend/controllers/inventory/stock-details', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    filterOrderStatus: "exported",
    breadCrumb: "Details",
    breadCrumbPath: "inventory.select-order",
    qrProductInput: '',
    actions: {
      updateLocation: function updateLocation(item) {
        var data = {
          locationInfo: item.locationInfo
        };

        $.ajax({
          url: "/api/inventoryStocks/" + item.id + "/updateLocation",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {});
      },
      openCorrectionPanel: function openCorrectionPanel(id) {
        var self = this;

        $.ajax({
          url: "/api/inventoryStocks/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('inventoryCorrectionPanel').send('load', data.inventoryStock);
        }, function () {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-inventory-correction-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    }
  });

});