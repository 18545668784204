define('jason-frontend/routes/etigaconfirm', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model, params) {
      controller.set('status', params.queryParams.status);
      controller.set('uid', decodeURIComponent(atob(params.queryParams.uid)));
    }
  });

});