define('jason-frontend/components/simple-select', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    selectedValues: new Array(),
    loading: true,
    timerId: {},
    mode: 'single',
    prefix: '',
    actions: {
      select: function select(item) {
        this.sendAction('select', item, this.get('reference'));
        this.set('values', null);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        this.sendAction('confirm', this.get('selectedValues'), this.get('reference'), this.get('provider'));
        $.magnificPopup.close();
      },
      load: function load(values, title, reference) {
        this.set('values', values);
        this.set('title', title);
        this.set('reference', reference);
      }
    }
  });

});