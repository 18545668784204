define('jason-frontend/controllers/cash/choose', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Ausgangsrechnungen",
    breadCrumbPath: "cash.choose",
    actions: {
      addPatient: function addPatient(customerId) {
        $('#createPatientForm').attr('data-customer-id', customerId);

        this.get('createPatientPanel').send('reset');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-create-patient-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      cancelDeselect: function cancelDeselect(el) {
        var $confirm = $('.delete-confirm', el);

        el.element.prop('checked', true);
      }
    }
  });

});