define('jason-frontend/components/button-sign', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'top',
    timer: 0,
    showStroke: true,
    color: '#4A4A4A',
    content: 'Sicherheitsvorrichtung aktivieren',
    theme: 'tooltipster-punk',
    text: "Hiermit aktivieren Sie die elektronische Sicherheitsvorrichtung Ihrer Registrierkassa",
    useConfirm: true,
    actions: {
      confirm: function confirm() {
        if (this.get('useConfirm')) {
          var el = this.get('element'),
              $btn = $('.btn-delete', el),
              offset = $btn.position(),
              $confirm = $('.delete-confirm', el),
              height = $confirm.outerHeight(),
              width = $confirm.outerWidth();

          $confirm.css('top', offset.top - height - 20);
          $confirm.css('left', offset.left - width / 2 - 20);

          $confirm.fadeIn();
        }
      },
      cancel: function cancel() {
        var el = this.get('element'),
            $confirm = $('.delete-confirm', el);
        $confirm.fadeOut();
      },
      'delete': function _delete() {
        var el = this.get('element'),
            $btn = $('.btn-delete', el),
            $confirm = $('.delete-confirm', el);
        $confirm.fadeOut();
        this.sendAction('confirmed', $btn.data('id'));
      }
    }
  });

});