define('jason-frontend/models/appointment-resource', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    labEquipment: DS['default'].attr(''),
    treatmentRoom: DS['default'].attr(''),
    treatmentStation: DS['default'].attr(''),
    categories: DS['default'].attr('')
  });

});