define('jason-frontend/helpers/get-host-path', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.getHostPath = getHostPath;

  function getHostPath(path) {
    var host = API['default'].getRemoteAssetPath();
    var pathString = path[0];
    if (host && pathString.indexOf('http') === -1) {
      return host + pathString;
    }
    return pathString;
  }

  exports['default'] = Ember['default'].Helper.helper(getHostPath);

});