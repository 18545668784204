define('jason-frontend/templates/components/invoice-deposit-chooser', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 6
            },
            "end": {
              "line": 21,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-deposit-chooser.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","cell-deposit-payment");
          dom.setAttribute(el1,"class","table__cell l-8/24@tablet l-3/24@desk payment payment-label");
          dom.setAttribute(el1,"data-label","Auszahlung");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","text");
          dom.setAttribute(el2,"id","deposit-payment");
          dom.setAttribute(el2,"class","input input--editable given-input");
          dom.setAttribute(el2,"name","payment");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","cell-deposit-deposit");
          dom.setAttribute(el1,"class","table__cell l-8/24@tablet l-3/24@desk deposit payment-label ");
          dom.setAttribute(el1,"data-label","Guthaben");
          dom.setAttribute(el1,"data-deposit","0");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","text");
          dom.setAttribute(el2,"id","deposit-deposit");
          dom.setAttribute(el2,"value","0");
          dom.setAttribute(el2,"class","input input--editable given-input");
          dom.setAttribute(el2,"name","deposit");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element2, 'data-payment');
          morphs[1] = dom.createAttrMorph(element3, 'value');
          return morphs;
        },
        statements: [
          ["attribute","data-payment",["concat",[["get","deposit",["loc",[null,[15,147],[15,154]]]]]]],
          ["attribute","value",["concat",[["subexpr","format-number-german",[["subexpr","invert-number",[["get","deposit",["loc",[null,[16,95],[16,102]]]]],[],["loc",[null,[16,80],[16,103]]]]],[],["loc",[null,[16,57],[16,105]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 6
            },
            "end": {
              "line": 28,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-deposit-chooser.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","cell-deposit-payment");
          dom.setAttribute(el1,"class","table__cell l-8/24@tablet l-3/24@desk payment open");
          dom.setAttribute(el1,"data-label","Auszahlung");
          var el2 = dom.createTextNode("\n         ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","cell-deposit-deposit");
          dom.setAttribute(el1,"class","table__cell l-8/24@tablet l-3/24@desk deposit open");
          dom.setAttribute(el1,"data-label","Guthaben");
          dom.setAttribute(el1,"data-deposit","0");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element1, 'data-payment');
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),0,0);
          return morphs;
        },
        statements: [
          ["attribute","data-payment",["concat",[["subexpr","invert-number",[["get","deposit",["loc",[null,[22,152],[22,159]]]]],[],["loc",[null,[22,136],[22,161]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","invert-number",[["get","deposit",["loc",[null,[23,54],[23,61]]]]],[],["loc",[null,[23,39],[23,62]]]]],["loc",[null,[23,15],[23,64]]]],
          ["inline","format-currency",[],["value",0],["loc",[null,[26,16],[26,43]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 8
            },
            "end": {
              "line": 32,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-deposit-chooser.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          Guthaben-Buchung nur bei Stammkunden-Verrechnung möglich\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 45,
              "column": 4
            },
            "end": {
              "line": 49,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-deposit-chooser.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"type","submit");
          dom.setAttribute(el1,"class","icon-button icon-button--success");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["registerDepositPayments"],[],["loc",[null,[46,69],[46,105]]]],
          ["inline","button-next",[],["size","52","color","#ffffff"],["loc",[null,[47,8],[47,49]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 51,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/invoice-deposit-chooser.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table table--payment box no-box@phone");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table__head");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell l-3/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Gutschrift");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell l-3/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Auszahlung");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell l-3/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Guthaben");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell l-15/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Hinweis");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table__body");
        dom.setAttribute(el2,"id","transaction-input");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__row rows");
        dom.setAttribute(el3,"id","row1");
        dom.setAttribute(el3,"data-id","1");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell l-8/24@tablet l-3/24@desk open payment-label");
        dom.setAttribute(el4,"id","cell-deposit-open");
        dom.setAttribute(el4,"data-label","Offen");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell l-15/24@desk");
        dom.setAttribute(el4,"data-label","Hinweis");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons ");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button icon-button--small");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0, 3, 1]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(fragment, [2]);
        var element7 = dom.childAt(element6, [1]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element5, 'data-open');
        morphs[1] = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
        morphs[2] = dom.createMorphAt(element4,3,3);
        morphs[3] = dom.createMorphAt(dom.childAt(element4, [5]),1,1);
        morphs[4] = dom.createElementMorph(element7);
        morphs[5] = dom.createMorphAt(element7,1,1);
        morphs[6] = dom.createMorphAt(element6,3,3);
        return morphs;
      },
      statements: [
        ["attribute","data-open",["concat",[["get","deposit",["loc",[null,[10,112],[10,119]]]]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","deposit",["loc",[null,[11,38],[11,45]]]]],[],[]]],["loc",[null,[11,14],[11,47]]]],
        ["block","if",[["subexpr","is-bankadvice-available",[["get","invoice",["loc",[null,[14,37],[14,44]]]]],[],["loc",[null,[14,12],[14,45]]]]],[],0,1,["loc",[null,[14,6],[28,13]]]],
        ["block","if",[["subexpr","not",[["subexpr","is-bankadvice-available",[["get","invoice",["loc",[null,[30,44],[30,51]]]]],[],["loc",[null,[30,19],[30,52]]]]],[],["loc",[null,[30,14],[30,53]]]]],[],2,null,["loc",[null,[30,8],[32,15]]]],
        ["element","action",["toSelectPositions"],[],["loc",[null,[42,7],[42,37]]]],
        ["inline","button-prev",[],["size","44","color","#ffffff"],["loc",[null,[43,6],[43,47]]]],
        ["block","if",[["get","submitButtonEnabled",["loc",[null,[45,10],[45,29]]]]],[],3,null,["loc",[null,[45,4],[49,11]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});