define('jason-frontend/components/show-stock-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      updateLocation: function updateLocation(item) {
        this.sendAction('updateLocation', item);
      },
      updateExpiry: function updateExpiry(item) {
        this.sendAction('updateExpiry', item);
      },
      updateBatchNumber: function updateBatchNumber(item) {
        this.sendAction('updateBatchNumber', item);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(items, productName, productStockId, data) {
        this.set('productName', productName);
        this.get('stockStockDetailPanel').send('load', items, productName, productStockId, data);
      },
      bookout: function bookout(id) {
        this.sendAction('bookout', id, this.get('productStockId'), this.get('productName'));
      },
      openOutgoing: function openOutgoing(id) {
        this.sendAction('openOutgoing', id);
      },
      transfer: function transfer(id) {
        this.sendAction('transfer', id);
        $.magnificPopup.close();
      },
      bookoutMovement: function bookoutMovement(id, stockId, productName) {
        this.sendAction('bookoutMovement', id, stockId, productName);
      },
      printBarcode: function printBarcode(id) {
        this.sendAction('printBarcode', id);
      },
      showInvoice: function showInvoice(id) {
        this.sendAction('showInvoice', id);
        $.magnificPopup.close();
      },
      openCorrection: function openCorrection(id) {
        var self = this;
        setTimeout(function () {
          self.sendAction('openCorrection', id);
        }, 500);
      },
      showPatient: function showPatient(id) {
        this.sendAction('showPatient', id);
        $.magnificPopup.close();
      }
    }
  });

});