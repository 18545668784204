define('jason-frontend/controllers/invoices/preview', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Anzeigen",
    breadCrumbPath: "invoices",
    sidebarActive: true,
    selectedPrintTemplate: null,
    hasSidebar: false,
    invoiceId: null,
    watchTemplate: (function () {
      this.send('reload');
    }).observes('selectedPrintTemplate')
  });

});