define('jason-frontend/routes/services/edit', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    // beforeModel() {
    //   modelHookRun = false;
    // },
    model: function model(params) {},
    // afterModel(model) {
    //   if (!modelHookRun) {
    //     return model.reload();
    //   }
    // },
    saveTextModules: function saveTextModules() {

      var medicalHistoryEntries = new Array();
      var symptomsEntries = new Array();
      var diagnosesEntries = new Array();
      var therapiesEntries = new Array();
      var reportEntries = new Array();

      $('#sortableMedicalHistory div.sortable-item').each(function (index, val) {
        var id = $(this).attr('data-module-id');
        var entry = {
          position: index,
          textModuleId: id
        };
        medicalHistoryEntries.push(entry);
      });
      $('#sortableSymptoms div.sortable-item').each(function (index, val) {
        var id = $(this).attr('data-module-id');
        var entry = {
          position: index,
          textModuleId: id
        };
        symptomsEntries.push(entry);
      });
      $('#sortableDiagnoses div.sortable-item').each(function (index, val) {
        var id = $(this).attr('data-module-id');
        var entry = {
          position: index,
          textModuleId: id
        };
        diagnosesEntries.push(entry);
      });
      $('#sortableTherapies div.sortable-item').each(function (index, val) {
        var id = $(this).attr('data-module-id');
        var entry = {
          position: index,
          textModuleId: id
        };
        therapiesEntries.push(entry);
      });
      $('#sortableReports div.sortable-item').each(function (index, val) {
        var id = $(this).attr('data-module-id');
        var entry = {
          position: index,
          textModuleId: id
        };
        reportEntries.push(entry);
      });

      var data = {
        medicalHistoryEntries: medicalHistoryEntries,
        diagnosesEntries: diagnosesEntries,
        therapiesEntries: therapiesEntries,
        symptomsEntries: symptomsEntries,
        reportEntries: reportEntries
      };

      $.ajax({
        url: "/api/products/" + this.get('controller').get('model').id + "/textModules",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
      }, function () {});
    },
    selectTab: function selectTab(selected) {
      var controller = this.get('controller');
      controller.set('selectTab', selected);
      controller.set('sidebarActive', false);
      var self = this;
      if (selected === 'future') {
        controller.set('futureItems', this.store.find('future-action-blueprint', { articleId: controller.get('model').id }));
      } else if (selected === 'treatment') {
        controller.set('sidebarActive', true);
        $.ajax({
          url: "/api/products/" + controller.get('model').id + "/treatmentvalues",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').set('selectedTreatmentValues', data.articleTreatmentValues);
        });

        $('.sortable div.sortable-item').each(function (index, val) {
          $(this).remove();
        });

        setTimeout(function () {
          self.searchTextmodules();
          $.ajax({
            url: "/api/products/" + self.get('controller').get('model').id + "/textModules",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.get('controller').set('selectedTextModules', data.textModuleArticle);
          }, function () {});
        }, 500);
      }
    },
    searchTextmodules: function searchTextmodules() {
      var self = this;
      $.ajax({
        url: "/api/textModules?type=def&filterName=" + this.get('controller').get('filterName'),
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.get('controller').set('allItems', data.textModule);
        setTimeout(function () {
          $(".draggable").draggable({
            connectToSortable: ".sortable",
            helper: "clone",
            revert: "invalid"
          });
        }, 500);
      });

      $(".sortable").sortable({
        revert: true
      });

      $(".sortable").droppable({
        drop: function drop(event, ui) {
          setTimeout(function () {
            ui.draggable.addClass('fixed');
          }, 500);
        }
      });
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);
      var self = this;
      $.ajax({
        url: "/api/services/" + params.params['services.edit'].id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        model = data.service;
        controller.set('model', data.service);

        self.store.find('article-tag', { type: 'service' }).then(function (items) {
          items.forEach(function (item) {
            model.tags.forEach(function (foundTag) {
              if (foundTag.id === item.id) {
                Ember['default'].set(item, 'selected', true);
              }
            });
          });
          controller.set('articleTags', items);
        });
      }, function (error) {});

      controller.set('taxes', this.store.find('tax', { paged: false, onlyValid: true }));

      this.store.find('serviceunit', { paged: false }).then(function (items) {
        controller.set('allUnits', items.sortBy('name'));
      });

      controller.set('labProfiles', this.store.find('labProfile'));
      controller.set('xrayProfiles', this.store.find('xrayProfile'));

      $.ajax({
        url: "/api/serviceCategories?suggest=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('categories', data.serviceCategory);
      });

      this.store.find('productDistributor', { onlyMy: true }).then(function (items) {
        controller.set('distributors', items.sortBy('name'));
      });

      controller.set('patientCategories', this.store.find('patientCategory'));

      var selectedTab = params.queryParams.selectTab;
      if (selectedTab) {
        setTimeout(function () {
          self.selectTab(selectedTab);
        }, 1000);
      }
    },
    actions: {
      searchTextModules: function searchTextModules() {
        this.searchTextmodules();
      },
      toggleSidebar: function toggleSidebar() {
        var controller = this.get('controller');
        if (controller.get('sidebarActive') === true) {
          controller.set('sidebarActive', false);
        } else {
          controller.set('sidebarActive', true);
          setTimeout(function () {
            $('aside.side-bar div.scrollable').css('max-height', $(window).height() - 375);
          }, 100);
        }
      },
      addServiceCategory: function addServiceCategory(category, title, selectedValues) {

        var self = this;

        $.ajax({
          url: "/api/services?filterMandant=all&page=1&per_page=10&selectedCategoryFilters=" + category + "&sortBy=&sortDir=",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('treatmentSelectServicePanel').send('load', data.service, selectedValues, title);
        }, function (error) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-select-service-panel'
          },
          callbacks: {
            close: function close() {
              var values = Array();

              $('#treatmentSelectServiceFormSelectedValues > div').each(function (index, item) {
                values.push($(item).attr('data-id'));
              });

              var data = {
                id: category,
                value: values.join(',')
              };

              $.ajax({
                url: "/api/products/" + self.get('controller').get('model').id + "/treatmentvalues",
                method: "POST",
                contentType: "application/json",
                dataType: "json",
                data: JSON.stringify(data),
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {
                self.selectTab('treatment');
              }, function (error) {
                self.selectTab('treatment');
              });
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectMultistageTreatmentDetail: function selectMultistageTreatmentDetail(provider, selected, title) {
        this.saveTextModules();
        var self = this;
        this.get('controller').get('treatmentMultiStageSelectPanel').send('load', provider, selected, self, title);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-multistage-select-panel'
          },
          callbacks: {
            close: function close() {
              var data = {
                id: provider,
                value: $('form#treatmentMultistageSelectForm').attr('data-ids')
              };

              $.ajax({
                url: "/api/products/" + self.get('controller').get('model').id + "/treatmentvalues",
                method: "POST",
                contentType: "application/json",
                dataType: "json",
                data: JSON.stringify(data),
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {
                self.selectTab('treatment');
              }, function (error) {
                self.selectTab('treatment');
              });
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectTreatmentDetailInfoMulti: function selectTreatmentDetailInfoMulti(provider, selected, title) {
        this.saveTextModules();
        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/lookups?type=" + provider,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('treatmentSelectPanel').send('loadMultiselect', data.treatmentLookup, selected, title);
        }, function (error) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-select-panel'
          },
          callbacks: {
            close: function close() {

              var value = null;
              $('#treatmentSelectFormSelectedValues > div').each(function (index, item) {
                value += $(item).attr('data-id') + ',';
              });

              var data = {
                id: provider,
                value: value
              };

              $.ajax({
                url: "/api/products/" + self.get('controller').get('model').id + "/treatmentvalues",
                method: "POST",
                contentType: "application/json",
                dataType: "json",
                data: JSON.stringify(data),
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {
                self.selectTab('treatment');
              }, function (error) {
                self.selectTab('treatment');
              });
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      saveTextModules: function saveTextModules() {
        this.saveTextModules();
      },
      printQr: function printQr(id) {
        window.open('/api/services/' + id + '/qr');
      },
      deleteEntry: function deleteEntry(modelId, itemId) {
        var self = this;
        var deferred = $.ajax({
          url: "/api/futureActionBlueprints/" + itemId,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Maßnahme entfernt', '');
          self.get('controller').set('futureItems', self.store.find('future-action-blueprint', { articleId: self.get('controller').get('model').id }));
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      editEntry: function editEntry(itemId, modelId) {
        this.transitionTo('practicemgmt.treatment-future-action', 'services', itemId, modelId);
      },
      selectTab: function selectTab(selected) {
        this.selectTab(selected);
      },
      addFutureAction: function addFutureAction(id) {
        var self = this,
            data = {};
        $.ajax({
          url: "/api/futureActionBlueprints/" + id,
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (responseData) {
          self.transitionTo('practicemgmt.treatment-future-action', 'services', id, responseData);
        });
      }
    }
  });

});