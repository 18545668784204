define('jason-frontend/components/add-direct-app-notification-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    type: null,
    showTitle: true,
    actions: {
      load: function load(id, showTitle) {
        this.set('id', id);
        this.set('showTitle', showTitle);
        this.set('message', null);
        this.set('type', 'default');
      },
      loadWithPreset: function loadWithPreset(id, message) {
        this.set('id', id);
        this.set('message', message);
        this.set('showTitle', false);
        this.set('type', 'reorderConfirm');
      },
      confirm: function confirm() {
        var data = {
          title: this.get('title'),
          message: this.get('message'),
          app: true,
          type: this.get('type'),
          messageId: this.get('id')
        };
        this.sendAction('create', data);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      reset: function reset() {
        this.set('message', null);
      }
    }
  });

});