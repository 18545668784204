define('jason-frontend/helpers/get-invoice-class', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getInvoiceClass = getInvoiceClass;

  function getInvoiceClass(params /*, hash*/) {
    if (params[0].id == params[1]) {
      return "primary active";
    }
    return "default";
  }

  exports['default'] = Ember['default'].Helper.helper(getInvoiceClass);

});