define('jason-frontend/helpers/get-inventory-status', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getInventoryStatus = getInventoryStatus;

  function getInventoryStatus(params) {
    var entry = params[0];

    if (entry.get('fullyBooked')) {
      return "fullybooked";
    }
    if (entry.get('partlyBooked')) {
      return "partlybooked";
    }

    return "notbooked";
  }

  exports['default'] = Ember['default'].Helper.helper(getInventoryStatus);

});