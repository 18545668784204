define('jason-frontend/helpers/get-article-name-override', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getArticleName = getArticleName;

  function getArticleName(params) {
    var entry = params[0];

    if (entry.get('articleText')) {
      return entry.get('articleText');
    }
    if (entry.get('article')) {
      return entry.get('article').get('name');
    }
    return "";
  }

  exports['default'] = Ember['default'].Helper.helper(getArticleName);

});