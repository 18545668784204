define('jason-frontend/components/purchase-order-sum-new', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    buttonLabel: 'Bestellung überprüfen',
    showButton: true,
    actions: {
      gotoCart: function gotoCart(id, currentId) {
        this.sendAction('toCart', id, currentId);
      }
    }
  });

});