define('jason-frontend/components/cashback-bar', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    supplier: null,
    orderSum: null,
    cashbackData: null,
    showLegend: false,
    cssClass: '',
    showLabel: false,
    hidden: 'hidden',
    _onReady: (function () {
      this.loadStats();
    }).on('didInsertElement'),
    _onDestroy: (function () {}).on('didDestroyElement'),
    loadStats: function loadStats() {

      var self = this;

      if (this.get('cashbackData')) {
        self.set('thresholds', this.get('cashbackData').thresholds);
        self.set('currentRevenue', this.get('cashbackData').currentRevenue);
        self.set('revenueOrder', this.get('cashbackData').revenueOrder);
        self.set('reached', this.get('cashbackData').cashbackReached);
        if (this.get('cashbackData').thresholds.length > 0) {
          this.set('hidden', '');
        }
        return;
      }
      var orderSum = this.get('orderSum');

      if (!orderSum) {
        orderSum = 0;
      }

      $.ajax({
        url: "/api/productSupplierCashbackThresholds?supplierId=" + this.get('supplier').id + '&toAdd=' + orderSum,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('currentRevenue', data.cashbackStatus.currentRevenue);
        self.set('reductionSum', data.cashbackStatus.reductionSum);
        self.set('revenueOrder', data.cashbackStatus.revenueOrder);
        self.set('thresholds', data.cashbackStatus.thresholds);
        self.set('reached', data.cashbackStatus.cashbackReached);
        self.set('currentRevenueWidth', data.cashbackStatus.currentRevenueWidth);
        self.set('currentRevenueStart', data.cashbackStatus.currentRevenueStart);
        if (data.cashbackStatus.thresholds.length > 0) {
          self.set('hidden', '');
        }
        self.sendAction('loaded');
      });
    }
  });

});