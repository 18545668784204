define('jason-frontend/controllers/services/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Leistungen",
    breadCrumbPath: "services.index",
    showFilters: true,
    filterAll: true,
    filterDistributor: null,
    filterFavs: false,
    filterTiny: true,
    filterHorse: true,
    articleTags: null,
    filterUse: true,
    myStickyOptionsTableHead: {
      topSpacing: 160,
      zIndex: 9
    },
    watchSmall: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterTiny'),
    watchHorse: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterHorse'),
    watchUse: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterUse'),
    watchFavs: (function () {
      if (this.get('filterFavs')) {
        this.set('filterAll', false);
      } else {
        this.set('filterAll', true);
      }
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterFavs'),
    watchfilterAll: (function () {
      if (this.get('filterAll')) {
        this.set('filterFavs', false);
      } else {
        this.set('filterFavs', true);
      }
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterAll'),
    watchDistributor: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterDistributor'),
    watchCategory: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('selectedCategoryFilters')
  });

});