define('jason-frontend/helpers/contains-string', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.containsString = containsString;

  function containsString(params) {
    if (params[0] && params[1]) {

      return params[0].indexOf(params[1]) >= 0;
    }
    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(containsString);

});