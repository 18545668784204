define('jason-frontend/helpers/is-operating-country', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isOperatingCountry = isOperatingCountry;

  function isOperatingCountry(params) {
    var country = params[0];
    return country.toLowerCase() === API['default'].getOperatingCountry().toLowerCase();
  }

  exports['default'] = Ember['default'].Helper.helper(isOperatingCountry);

});