define('jason-frontend/components/patient-memos', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    tagName: 'div',
    actions: {
      reload: function reload() {
        this.sendAction('reload');
      },
      openNewMemo: function openNewMemo(patientId) {
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-patient-memo-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });

        this.get('editPatientMemoPanel').send('loadNew', patientId);
      },
      openEditMemo: function openEditMemo(model, patientId) {

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-patient-memo-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });

        this.get('editPatientMemoPanel').send('load', model, patientId);
      }
    }
  });

});