define('jason-frontend/templates/components/document-edit-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 20,
                    "column": 30
                  },
                  "end": {
                    "line": 20,
                    "column": 185
                  }
                },
                "moduleName": "jason-frontend/templates/components/document-edit-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","cursor");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [0]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element6);
                morphs[1] = dom.createMorphAt(element6,0,0);
                return morphs;
              },
              statements: [
                ["element","action",["gotoFolder",["get","folder",["loc",[null,[20,103],[20,109]]]]],[],["loc",[null,[20,81],[20,111]]]],
                ["inline","button-prev",[],["color","000","classNames","vertical-middle inline-block"],["loc",[null,[20,112],[20,181]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 20,
                    "column": 185
                  },
                  "end": {
                    "line": 20,
                    "column": 265
                  }
                },
                "moduleName": "jason-frontend/templates/components/document-edit-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"src","assets/images/icons/folder.svg");
                dom.setAttribute(el1,"class","mr10 vertical-bottom");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 22
                },
                "end": {
                  "line": 21,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/components/document-edit-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","is-highlighted cursor");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var element8 = dom.childAt(element7, [2]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element7,0,0);
              morphs[1] = dom.createElementMorph(element8);
              morphs[2] = dom.createMorphAt(element8,0,0);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","folder.name",["loc",[null,[20,40],[20,51]]]],"zurück"],[],["loc",[null,[20,36],[20,61]]]]],[],0,1,["loc",[null,[20,30],[20,272]]]],
              ["element","action",["gotoFolder",["get","folder",["loc",[null,[20,328],[20,334]]]]],[],["loc",[null,[20,306],[20,336]]]],
              ["content","folder.name",["loc",[null,[20,337],[20,352]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 25,
                    "column": 24
                  },
                  "end": {
                    "line": 29,
                    "column": 24
                  }
                },
                "moduleName": "jason-frontend/templates/components/document-edit-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square");
                var el2 = dom.createTextNode("\n                              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element5);
                morphs[1] = dom.createMorphAt(element5,1,1);
                return morphs;
              },
              statements: [
                ["element","action",["selectFolder",["get","folder.internalId",["loc",[null,[26,117],[26,134]]]]],[],["loc",[null,[26,93],[26,136]]]],
                ["inline","button-select",[],["color","white","showStroke",false,"content",""],["loc",[null,[27,30],[27,89]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 22
                },
                "end": {
                  "line": 30,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/components/document-edit-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","folder.name",["loc",[null,[25,44],[25,55]]]],"zurück"],[],["loc",[null,[25,40],[25,65]]]]],[],["loc",[null,[25,35],[25,66]]]],["subexpr","eq",[["get","folder.type",["loc",[null,[25,71],[25,82]]]],"folder"],[],["loc",[null,[25,67],[25,92]]]]],[],["loc",[null,[25,30],[25,93]]]]],[],0,null,["loc",[null,[25,24],[29,31]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 16
              },
              "end": {
                "line": 33,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/document-edit-panel.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-24/24@phone l-24/24@tablet l-20/24@desk");
            dom.setAttribute(el2,"data-label","Ordner");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24@phone l-6/24@tablet l-4/24@desk");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element9, [3]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","or",[["subexpr","eq",[["get","folder.type",["loc",[null,[19,36],[19,47]]]],"up"],[],["loc",[null,[19,32],[19,53]]]],["subexpr","eq",[["get","folder.type",["loc",[null,[19,58],[19,69]]]],"folder"],[],["loc",[null,[19,54],[19,79]]]]],[],["loc",[null,[19,28],[19,80]]]]],[],0,null,["loc",[null,[19,22],[21,29]]]],
            ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[24,28],[24,36]]]]],[],1,null,["loc",[null,[24,22],[30,29]]]]
          ],
          locals: ["folder"],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 14
              },
              "end": {
                "line": 41,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/document-edit-panel.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1,"class","crumb-trail");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
            return morphs;
          },
          statements: [
            ["content","bread.name",["loc",[null,[39,21],[39,35]]]]
          ],
          locals: ["bread"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 54,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/document-edit-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","panel");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","panel-heading");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","panel-title");
          var el4 = dom.createTextNode("Speicherort ändern");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","panel-body p25");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","mb0 table-wrapper box no-box@phone");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table grid table--large");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__head");
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","table__cell l-24/24");
          var el7 = dom.createTextNode("\n                  ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","table__title title");
          var el8 = dom.createTextNode("Name");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__body");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("              ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4,"class","mt20 mb0 documentBreadcrumbs");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","panel-footer text-right");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--small");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--success");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [1]);
          var element11 = dom.childAt(element10, [3, 1]);
          var element12 = dom.childAt(element10, [5]);
          var element13 = dom.childAt(element12, [1]);
          var element14 = dom.childAt(element12, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(dom.childAt(element11, [1, 3]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element11, [3]),1,1);
          morphs[2] = dom.createElementMorph(element13);
          morphs[3] = dom.createMorphAt(element13,1,1);
          morphs[4] = dom.createElementMorph(element14);
          morphs[5] = dom.createMorphAt(element14,1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","folders",["loc",[null,[16,24],[16,31]]]]],[],0,null,["loc",[null,[16,16],[33,25]]]],
          ["block","each",[["get","breads",["loc",[null,[37,22],[37,28]]]]],[],1,null,["loc",[null,[37,14],[41,23]]]],
          ["element","action",["back"],[],["loc",[null,[46,13],[46,30]]]],
          ["content","button-prev",["loc",[null,[47,12],[47,27]]]],
          ["element","action",["selectFolder",["get","rootFolder",["loc",[null,[49,37],[49,47]]]]],[],["loc",[null,[49,13],[49,49]]]],
          ["inline","button-select",[],["color","#ffffff","size","60","content",""],["loc",[null,[50,12],[50,66]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 54,
              "column": 2
            },
            "end": {
              "line": 96,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/document-edit-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","panel");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","panel-heading");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","panel-title");
          var el4 = dom.createTextNode("Dokument bearbeiten");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("form");
          dom.setAttribute(el2,"class","form-grid new-style");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","panel-body p25");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-24/24@desk l-24/24@phone gutter");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","name");
          dom.setAttribute(el5,"class","field-label");
          var el6 = dom.createTextNode("Name");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-24/24 gutter");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","description");
          dom.setAttribute(el5,"class","field-label");
          var el6 = dom.createTextNode("Beschreibung");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-24/24 gutter");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","pickDate");
          dom.setAttribute(el5,"class","date-picker input-element");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","field-label");
          var el7 = dom.createTextNode("Datum");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"class","field-icon");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("i");
          dom.setAttribute(el7,"class","fa fa-calendar");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            Uhr\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-24/24@desk l-24/24@phone gutter");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","name");
          dom.setAttribute(el5,"class","field-label");
          var el6 = dom.createTextNode("Speicherort");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("img");
          dom.setAttribute(el5,"src","assets/images/icons/folder.svg");
          dom.setAttribute(el5,"class","mr10");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("a");
          dom.setAttribute(el5,"class","cursor is-highlighted");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","panel-footer text-right");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","icon-button--cancel icon-button");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"type","submit");
          dom.setAttribute(el4,"class","icon-button icon-button--success");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 3]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [5, 1]);
          var element3 = dom.childAt(element1, [7, 5]);
          var element4 = dom.childAt(element0, [3]);
          var morphs = new Array(10);
          morphs[0] = dom.createAttrMorph(element0, 'id');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]),3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(element1, [3]),3,3);
          morphs[4] = dom.createMorphAt(element2,3,3);
          morphs[5] = dom.createMorphAt(element2,7,7);
          morphs[6] = dom.createElementMorph(element3);
          morphs[7] = dom.createMorphAt(element3,0,0);
          morphs[8] = dom.createMorphAt(dom.childAt(element4, [1]),1,1);
          morphs[9] = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
          return morphs;
        },
        statements: [
          ["attribute","id",["concat",["documentEditPanel",["get","prefix",["loc",[null,[60,33],[60,39]]]]]]],
          ["element","action",["save"],["on","submit"],["loc",[null,[60,71],[60,100]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","document.name",["loc",[null,[64,24],[64,37]]]]],[],[]],"autofocus",true,"class","gui-input new-style","title","Bitte geben Sie einen Namen an","name","name"],["loc",[null,[64,10],[64,133]]]],
          ["inline","textarea",[],["rows","3","value",["subexpr","@mut",[["get","document.description",["loc",[null,[68,36],[68,56]]]]],[],[]],"class","full input textarea__input","name","description","autocomplete","off"],["loc",[null,[68,10],[68,131]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","document.created",["loc",[null,[73,26],[73,42]]]]],[],[]],"class","input--date-picker small-style input datetimepicker2 ember-view ember-text-field","name","created"],["loc",[null,[73,12],[73,149]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","document.createdTime",["loc",[null,[77,26],[77,46]]]]],[],[]],"class","input date-picker__input new-style time fixed-timepicker-width","name","createdTime","placeholder","12:00"],["loc",[null,[77,12],[77,158]]]],
          ["element","action",["enableFolderChooser",["get","document",["loc",[null,[83,101],[83,109]]]]],[],["loc",[null,[83,70],[83,111]]]],
          ["content","document.flatPath",["loc",[null,[83,142],[83,163]]]],
          ["inline","button-delete-with-confirm",[],["color","#fff","item",["subexpr","@mut",[["get","document",["loc",[null,[88,57],[88,65]]]]],[],[]],"confirmed","delete","size","40","showStroke",false,"content","","text","Wollen Sie dieses Dokument wirklich löschen?"],["loc",[null,[88,10],[88,176]]]],
          ["inline","button-save",[],["color","#ffffff","size","60","content",""],["loc",[null,[91,10],[91,62]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 134,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/document-edit-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","popup-basic popup-large admin-form mfp-with-anim mfp-hide small--heading");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $('.datetimepicker2').daterangepicker({\n      singleDatePicker: true,\n      autoUpdateInput: true,\n      \"locale\": {\n        \"format\": \"DD. MM. YYYY\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element15 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element15, 'id');
        morphs[1] = dom.createMorphAt(element15,1,1);
        return morphs;
      },
      statements: [
        ["attribute","id",["concat",["modal-document-edit-panel",["get","prefix",["loc",[null,[1,36],[1,42]]]]]]],
        ["block","if",[["get","showFolderChooser",["loc",[null,[2,8],[2,25]]]]],[],0,1,["loc",[null,[2,2],[96,9]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});