define('jason-frontend/templates/components/button-admission', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-admission.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(6 6) scale(0.16, 0.16)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M90-4H40.9c-2.3,0-4.1,1.8-4.1,4.1v5.7H18.7c-2.3,0-4.1,1.8-4.1,4.1v108c0,2.3,1.8,4.1,4.1,4.1h91.6    c2.3,0,4.1-1.8,4.1-4.1V9.9c0-2.3-1.8-4.1-4.1-4.1H94.1V0.1C94.1-2.2,92.3-4,90-4z M45,9.9V4.2h40.9v5.7v7.2H45V9.9z M106.2,14    v99.8H22.8V14h14v7.2c0,2.3,1.8,4.1,4.1,4.1H90c2.3,0,4.1-1.8,4.1-4.1V14H106.2z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M95,67H35.9c-2.3,0-4.1,1.8-4.1,4.1c0,2.3,1.8,4.1,4.1,4.1H95c2.3,0,4.1-1.8,4.1-4.1C99.1,68.8,97.3,67,95,67z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","m65.5,86.8h-29.6c-2.3,0-4.1,1.8-4.1,4.1s1.8,4.1 4.1,4.1h29.5c2.3,0 4.1-1.8 4.1-4.1s-1.8-4.1-4-4.1z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","m31.8,51.2c0,2.3 1.8,4.1 4.1,4.1h59.1c2.3,0 4.1-1.8 4.1-4.1s-1.8-4.1-4.1-4.1h-59.1c-2.3,0-4.1,1.9-4.1,4.1z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element1, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[2,13],[2,18]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});