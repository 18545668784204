define('jason-frontend/helpers/get-formatted-timespan', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getFormattedTimespan = getFormattedTimespan;

  function getFormattedTimespan(params) {
    var minutes = params[0],
        hours = Math.floor(minutes / 60);

    if (minutes < 1) {
      return '< 1 Minute';
    }

    if (minutes <= 60) {
      return minutes + ' Min';
    }

    minutes = minutes - Math.floor(minutes / 60) * 60;

    return hours + ' Std. ' + minutes + ' Min.';
  }

  exports['default'] = Ember['default'].Helper.helper(getFormattedTimespan);

});