define('jason-frontend/components/edit-purchase-product-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    selectTab: 'general',
    item: null,
    deliveryStatus: null,
    productId: null,
    showDocUploadButton: true,
    actions: {
      refresh: function refresh() {
        $.magnificPopup.close();
        this.sendAction('refresh');
      },
      selectTab: function selectTab(selected) {
        this.set('selectTab', selected);
      },
      loadData: function loadData(product, taxes, serviceunits, categories) {
        this.set('item', product);
        this.set('taxes', taxes);
        this.set('categories', categories);
        this.set('serviceunits', serviceunits);
        this.set('selectTab', 'general');
        this.set('showDocUploadButton', true);
      },
      imageChanged: function imageChanged(id) {
        this.get('item').set('pictureUrl', "https://res.cloudinary.com/myjason/image/upload/w_400,c_scale/" + id);
      },
      docUploadDone: function docUploadDone(data) {
        this.set('showDocUploadButton', false);
        this.get('item').set('detailUrl', data);
        this.get('item').set('infodocSource', 'url');
      },
      confirm: function confirm() {

        var product = {};
        product.id = this.get('item').get('id');
        product.foreignId = this.get('item').get('foreignId');
        product.name = this.get('item').get('name');
        product.gtin = this.get('item').get('gtin');
        product.description = this.get('item').get('description');
        product.atcCode = this.get('item').get('atcCode');
        product.atcCodeDescription = this.get('item').get('atcCodeDescription');

        product.licenceNumber = this.get('item').get('licenceNumber');
        product.domainInfo = this.get('item').get('domainInfo');
        product.ingredients = this.get('item').get('ingredients');
        product.narcotic = this.get('item').get('narcotic');
        product.referenceNumber = this.get('item').get('referenceNumber');
        product.slopingInfo = this.get('item').get('slopingInfo');
        product.targetRace = this.get('item').get('targetRace');
        product.pictureUrl = this.get('item').get('pictureUrl');
        product.detailUrl = this.get('item').get('detailUrl');
        product.packageQuantity = this.get('item').get('packageQuantity');

        if (this.get('item').get('salePriceGross')) {
          product.salePriceGross = String(this.get('item').get('salePriceGross')).replace(",", ".");
        }
        if (this.get('item').get('packageQuantity')) {
          var qty = String(this.get('item').get('packageQuantity')).replace(",", ".");
          product.quantity = parseFloat(qty);
        }

        if (this.get('item').get('imageSource') === 'supplier') {
          product.pictureUrl = null;
        }

        product.partnerForeignId = this.get('item').get('partnerForeignId');
        product.supplierForeignId = this.get('item').get('supplierForeignId');

        product.partnerCategory = this.get('item').get('partnerCategory');
        product.partnerSubcategory = this.get('item').get('partnerSubcategory');

        product.unit = {
          id: this.get('item').get('serviceunit').id
        };
        product.tax = {
          id: this.get('item').get('tax').id
        };

        if (this.get('item').get('category')) {
          product.category = {
            id: this.get('item').get('category').id
          };
        }

        if (!product.name || product.name.length < 2) {
          jason.notifiction.error('Fehler', 'Bitte geben Sie einen Produktnamen an');
          return;
        }

        this.sendAction('save', product);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      addPatient: function addPatient(customerId, invoiceId) {
        this.sendAction('addPatient', customerId, invoiceId);
      },
      load: function load(patient) {
        this.set('patientInfo', patient);
      }
    }
  });

});