define('jason-frontend/routes/inventory/delivery-note', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    perPage: 25,
    queryParams: {},
    setupController: function setupController(controller, model) {
      var self = this;
      controller.set("model", model);
      this.store.find('productSupplier', { service: true, hideAll: true }).then(function (items) {
        controller.set('suppliers', items.sortBy('name'));
      });

      if (this.get('controller').get('selectedType') === 'deliverynotes') {
        controller.set('placeholderSearch', 'Lieferschein-Nr, Bestell-Nr Lieferant');
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      } else if (this.get('controller').get('selectedType') === 'orders') {
        controller.set('placeholderSearch', 'Bestell-Nr');
        setTimeout(function () {
          self.applyFilterOrders();
        }, 500);
      }
    },
    setType: function setType(type) {
      var self = this;
      var controller = this.get('controller');
      this.get('controller').set('selectedType', type);
      this.get('controller').set('model', null);
      this.get('controller').set('selectTab', type);

      if (this.get('controller').get('selectedType') === 'deliverynotes') {
        controller.set('placeholderSearch', 'Lieferschein-Nr, Bestell-Nr Lieferant');
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      } else if (this.get('controller').get('selectedType') === 'orders') {
        controller.set('placeholderSearch', 'Bestell-Nr');
        setTimeout(function () {
          self.applyFilterOrders();
        }, 500);
      }
    },
    actions: {
      download: function download(orderId) {
        var deferred = $.ajax({
          url: "/api/purchaseOrders/" + orderId + "/preview",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/purchaseOrders/show/' + orderId);
        });
      },
      setType: function setType(to) {
        this.setType(to);
      },
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          if (self.get('controller').get('selectedType') == 'orders') {
            self.applyFilterOrders();
          } else {
            self.applyFilter();
          }
        }, 500);
      },
      showDeliveryNote: function showDeliveryNote(id) {
        this.transitionTo('inventory.delivery-note-details', id);
      },
      showDeliveryView: function showDeliveryView(id) {
        this.transitionTo('inventory.delivery-note-view', id);
      }
    },
    applyFilterOrders: function applyFilterOrders() {
      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          code = controller.get('filterCode'),
          dateFromTo = dateRange.split('-');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      controller.set('showLoading', true);

      this.findPaged('purchaseOrder', {
        filterCode: code,
        filterFrom: from,
        filterTo: to,
        filterStatus: controller.get('filterOrderStatus')
      }).then(function (transactions) {
        var meta = transactions.meta;
        controller.set('sumIndividualCashback', meta.sumIndividualCashback);
        controller.set('sumJasonCashback', meta.sumJasonCashback);
        controller.set('model', transactions);
        controller.set('showLoading', false);
      });
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          code = controller.get('filterCode'),
          filterSupplier = controller.get('filterSupplier'),
          dateFromTo = dateRange.split('-');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('showLoading', true);

      this.findPaged('deliveryNote', {
        filterCode: code,
        filterSupplier: filterSupplier,
        filterFrom: from,
        filterTo: to,
        pageSize: this.get('perPage')
      }).then(function (data) {
        var meta = data.meta;
        controller.set('model', data);
        controller.set('showLoading', false);
      });
    }
  });

});