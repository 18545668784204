define('jason-frontend/components/patient-appointments', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    stickyHeader: true,
    myStickyOptionsTableHead: {
      topSpacing: 160,
      zIndex: 9
    },
    filtersShown: false,
    actions: {
      "delete": function _delete(id) {
        this.sendAction('delete', id);
      },
      edit: function edit(item) {
        this.sendAction('edit', item.id);
      },
      admissionPatient: function admissionPatient(customerId, patientId) {
        this.sendAction('startTreatment', customerId, patientId);
      },
      showAppointment: function showAppointment(id) {
        this.sendAction('showAppointment', id);
      }
    }
  });

});