define('jason-frontend/routes/labprofiles/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('lab-profile', params.id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('patientCategories', this.store.find('patientCategory'));
    },
    actions: {
      saveReference: function saveReference(data) {
        var self = this;
        $.ajax({
          url: "/api/labProfiles/customReference",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          self.get('controller').get('model').reload();
        }, function () {});
      },
      deleteReference: function deleteReference(id) {
        var self = this;
        $.ajax({
          url: "/api/labProfiles/customReference/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'entfernt');
          self.get('controller').get('model').reload();
        }, function () {
          self.get('controller').get('model').reload();
        });
      },
      save: function save() {
        var self = this,
            model = this.get('controller').get('model');

        var data = {
          id: model.get('id'),
          name: model.get('name'),
          customParams: model.get('customParams')
        };

        $.ajax({
          url: "/api/labProfiles/" + data.id,
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          self.transitionTo('practice', { queryParams: { selectTab: "labprofiles" } });
        }, function () {});
      }
    }
  });

});