define('jason-frontend/components/button-include', ['exports', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'top',
    color: '#EF5F4E',
    showStroke: true,
    fill: false,
    timer: 0,
    content: 'Einschließen',
    theme: 'tooltipster-punk'
  });

});