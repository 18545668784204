define('jason-frontend/routes/document-tags/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({

    model: function model(params) {
      if (params.id > 0) {
        return this.store.find('document-tag', params.id);
      } else {
        var model = this.store.createRecord('document-tag');
        return model;
      }
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);

      var self = this;
    },
    actions: {
      save: function save() {

        var self = this;
        var data = {
          id: this.get('controller').get('model').id,
          name: this.get('controller').get('model').get('name'),
          color: this.get('controller').get('model').get('color')
        };

        var id = this.get('controller').get('model').id;

        if (id === null) {
          id = 0;
        }
        $.ajax({
          url: "/api/documentTags",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('basicinfos', { queryParams: { selectTab: "tags" } });
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        }, function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        });
      }
    }
  });

});