define('jason-frontend/helpers/get-current-mandant-id', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.getCurrentMandantId = getCurrentMandantId;

  function getCurrentMandantId() {
    return API['default'].getMandant();
  }

  exports['default'] = Ember['default'].Helper.helper(getCurrentMandantId);

});