define('jason-frontend/routes/purchases/commissions', ['exports', 'ember', 'moment', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, moment, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model() {
      return null;
    },
    perPage: 20,
    queryParams: {},
    setupController: function setupController(controller) {

      if (API['default'].getMandant() != '4') {
        this.transitionTo('home');
      }

      controller.set('mandants', this.store.find('mandant'));
      controller.set('suppliers', this.store.find('product-supplier', { filterPurchase: true, hideAll: true }));

      this.applyFilter();
    },
    actions: {
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      }
    },
    applyFilter: function applyFilter() {

      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-');

      controller.set('showLoading', true);

      var from = moment['default'](dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment['default'](dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      this.findPaged('purchaseCommission', {
        filterFrom: from,
        filterTo: to,
        mandantId: controller.get('mandant'),
        supplierId: controller.get('supplier'),
        sortBy: controller.get('sortBy'),
        sortDir: controller.get('sortDir')
      }).then(function (transactions) {

        var meta = transactions.meta;
        controller.set('sumCommission', meta.sumCommission);
        controller.set('sumSaving', meta.sumSaving);
        controller.set('sumCashback', meta.sumCashback);
        controller.set('model', transactions);
        controller.set('showLoading', false);
      });
    }
  });

});