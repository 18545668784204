define('jason-frontend/helpers/multiply-german', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.multiplyGerman = multiplyGerman;

  function multiplyGerman(params) {
    var first = String(params[0]).replace(',', '.'),
        second = String(params[1]).replace(',', '.');

    return Math.round(first * second * 100) / 100;
  }

  exports['default'] = Ember['default'].Helper.helper(multiplyGerman);

});