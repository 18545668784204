define('jason-frontend/templates/components/button-add-promotion', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-add-promotion.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"transform","translate(-1,-1),scale(0.1,0.1)");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M141.328,150.089v-24.106c0-14.451-9.221-23.079-24.673-23.079c-15.441,0-24.66,8.628-24.66,23.079v24.109\n				c0.001,14.455,9.221,23.085,24.663,23.085C132.105,173.177,141.328,164.546,141.328,150.089z M123.399,150.091\n				c0,3.669-1.128,5.943-6.74,5.944c-5.503-0.001-6.579-2.182-6.579-5.946v-24.106c0-3.765,1.076-5.945,6.579-5.945\n				c5.612,0,6.74,2.275,6.74,5.945V150.091z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M204.812,106.158c0-5.556-4.879-9.288-9.437-9.288c-3.428,0-6.285,1.761-7.676,4.72l-60.414,118.455\n				c-0.659,1.337-0.994,2.722-0.994,4.119c0,4.856,4.178,8.962,9.122,8.967c0.004,0,0.008,0,0.011,0\n				c3.577-0.004,6.67-1.93,8.248-5.102l60.141-117.573C204.486,109.111,204.812,107.706,204.812,106.158z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M213.341,159.049c-15.446,0-24.668,8.629-24.668,23.082v24.112c0,14.452,9.222,23.08,24.669,23.08\n				c15.443,0,24.663-8.628,24.663-23.08v-24.112C238.005,167.678,228.785,159.049,213.341,159.049z M220.075,206.242\n				c0,3.667-1.127,5.939-6.734,5.939c-1.665,0-3.912-0.238-5.16-1.374c-0.94-0.853-1.416-2.388-1.416-4.562V182.13\n				c0-2.175,0.477-3.712,1.418-4.569c1.249-1.137,3.495-1.376,5.159-1.376c5.607,0,6.733,2.275,6.733,5.947V206.242z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element1, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[2,17],[2,22]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});