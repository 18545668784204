define('jason-frontend/routes/compositeproducts/select-product', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",

    model: function model(params) {
      params.filterMandant = "all";
      return this.findPaged('product', params);
    },
    setupController: function setupController(controller, model, params) {
      controller.set("model", model);

      var productId = params.params['compositeproducts.select-product'].id;

      this.store.find('productCategory').then(function (items) {
        controller.set('categories', items.sortBy('fullName'));
      });

      controller.set('productId', productId);

      this.store.unloadAll('productSupplier');

      this.store.find('productSupplier').then(function (items) {
        controller.set('suppliers', items.sortBy('name'));
      });

      controller.set("linkToEditEnabled", API['default'].hasPermission('crud_products'));

      $.ajax({
        url: "/api/productCategories?suggest=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('categories', data.productCategory);
        controller.set('selectedCategoryFilters', null);
      });
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          selectedCategoryFilters = controller.get('selectedCategoryFilters'),
          filterSupplier = controller.get('filterSupplier'),
          filterManufacturer = controller.get('filterManufacturer'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy'),
          manufacturerId = null,
          filterFavorites = controller.get('filterFavorites'),
          filterId = null;

      if (filterManufacturer) {
        manufacturerId = filterManufacturer.id;
      }

      if (filterSupplier === 'all') {
        filterSupplier = null;
      }

      if (selectedCategoryFilters) {
        filterId = selectedCategoryFilters.id;
      }

      this.findPaged('product', {
        filterName: filterName,
        selectedCategoryFilters: filterId,
        filterSupplier: filterSupplier,
        filterManufacturer: manufacturerId,
        filterFavorites: filterFavorites,
        filterMandant: "all",
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    actions: {
      toggleFavorites: function toggleFavorites(enabled) {
        var controller = this.get('controller');
        controller.set('filterFavorites', enabled);
        this.applyFilter();
      },
      queryManufacturer: function queryManufacturer(query, deferred) {
        this.store.find('productManufacturer', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      setSort: function setSort(attr) {
        var controller = this.get('controller');
        controller.set('sortBy', attr);
        if (controller.get('sortDir') === 'asc') {
          controller.set('sortDir', 'desc');
        } else {
          controller.set('sortDir', 'asc');
        }
        this.applyFilter();
      },
      addProduct: function addProduct(quantity, productId, invoiceId, priceId, productName) {

        var compositeProductId = this.get('controller').get('productId');

        var deferred = jQuery.ajax({
          url: "/api/compositeArticles/add/" + compositeProductId + "/product/" + productId + "/" + quantity + "/" + priceId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Produkt(e) hinzugefügt', productName);
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
        return Ember['default'].RSVP.resolve(deferred);
      }
    }
  });

});