define('jason-frontend/templates/components/button-upload', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 5,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/button-upload.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("path");
          dom.setAttribute(el1,"d","M16,31 C24.2842713,31 31,24.2842713 31,16 C31,7.71572875 24.2842713,1 16,1 C7.71572875,1 1,7.71572875 1,16 C1,24.2842713 7.71572875,31 16,31 L16,31 L16,31 Z M16,29.5714286 C8.50470696,29.5714286 2.42857143,23.495293 2.42857143,16 C2.42857143,8.50470696 8.50470696,2.42857143 16,2.42857143 C23.495293,2.42857143 29.5714286,8.50470696 29.5714286,16 C29.5714286,23.495293 23.495293,29.5714286 16,29.5714286 L16,29.5714286 L16,29.5714286 Z");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-upload.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"fill-rule","evenodd");
        dom.setAttribute(el3,"transform","translate(7,6),scale(0.6,0.6)");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M16,0c-4.411,0-8,3.589-8,8v18c0,3.309,2.691,6,6,6c3.309,0,6-2.691,6-6V8c0-2.206-1.794-4-4-4s-4,1.794-4,4v15     c0,0.552,0.448,1,1,1c0.553,0,1-0.448,1-1V8c0-1.104,0.897-2,2-2c1.104,0,2,0.896,2,2v18c0,2.206-1.794,4-4,4     c-2.205,0-4-1.794-4-4V8c0-3.309,2.691-6,6-6c3.309,0,6,2.691,6,6v15c0,0.552,0.448,1,1,1s1-0.448,1-1V8C24,3.589,20.411,0,16,0z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element1, 'fill');
        morphs[3] = dom.createMorphAt(element1,1,1);
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[2,13],[2,18]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,10],[3,20]]]]],[],0,null,["loc",[null,[3,4],[5,11]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});