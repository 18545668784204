define('jason-frontend/templates/notifications', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 7,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/notifications.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context align-items-center");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/chat.svg");
          dom.setAttribute(el2,"style","width: 20px;padding-top: 3px;");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Chat");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element8);
          return morphs;
        },
        statements: [
          ["element","action",["openGen2Chat"],[],["loc",[null,[4,11],[4,36]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 4
            },
            "end": {
              "line": 12,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/notifications.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neue Nachricht");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element7);
          morphs[1] = dom.createMorphAt(element7,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openNew"],[],["loc",[null,[9,11],[9,31]]]],
          ["inline","button-mail",[],["content","Neue Benachrichtigung","color","#fff","size","32","showStroke",false],["loc",[null,[10,10],[10,97]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 67,
              "column": 0
            },
            "end": {
              "line": 69,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/notifications.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[68,2],[68,23]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 145,
                  "column": 22
                },
                "end": {
                  "line": 145,
                  "column": 175
                }
              },
              "moduleName": "jason-frontend/templates/notifications.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-myjason",[],["content","","color","#008EC6","classNames","inline-icon","cssClass","inverted","classNames","inline-icon"],["loc",[null,[145,59],[145,175]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 145,
                  "column": 182
                },
                "end": {
                  "line": 145,
                  "column": 287
                }
              },
              "moduleName": "jason-frontend/templates/notifications.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-lab",[],["content","","classNames","inline-icon","cssClass","inverted"],["loc",[null,[145,217],[145,287]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 145,
                  "column": 294
                },
                "end": {
                  "line": 145,
                  "column": 407
                }
              },
              "moduleName": "jason-frontend/templates/notifications.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-invoice",[],["classNames","inline-icon","content","","cssClass","inverted"],["loc",[null,[145,333],[145,407]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 145,
                  "column": 414
                },
                "end": {
                  "line": 145,
                  "column": 528
                }
              },
              "moduleName": "jason-frontend/templates/notifications.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-patient",[],["classNames","inline-icon","content","","cssClass","inverted"],["loc",[null,[145,454],[145,528]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 159,
                      "column": 20
                    },
                    "end": {
                      "line": 161,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/notifications.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","32","color","#3B6182","showStroke",true],["loc",[null,[160,22],[160,79]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 158,
                    "column": 18
                  },
                  "end": {
                    "line": 162,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/notifications.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["patients.edit",["get","item.referenceId",["loc",[null,[159,47],[159,63]]]],["subexpr","query-params",[],["selectTab","lab"],["loc",[null,[159,64],[159,94]]]]],["title","Patient anzeigen"],0,null,["loc",[null,[159,20],[161,32]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 164,
                      "column": 20
                    },
                    "end": {
                      "line": 166,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/notifications.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","32","color","#3B6182","showStroke",true],["loc",[null,[165,22],[165,79]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 163,
                    "column": 18
                  },
                  "end": {
                    "line": 167,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/notifications.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["patients.edit",["get","item.referenceId",["loc",[null,[164,47],[164,63]]]],["subexpr","query-params",[],["selectTab","history"],["loc",[null,[164,64],[164,98]]]]],["title","Patient anzeigen"],0,null,["loc",[null,[164,20],[166,32]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 169,
                      "column": 20
                    },
                    "end": {
                      "line": 171,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/notifications.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","32","color","#3B6182","showStroke",true],["loc",[null,[170,22],[170,79]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 168,
                    "column": 18
                  },
                  "end": {
                    "line": 172,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/notifications.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["invoices.show",["get","item.referenceId",["loc",[null,[169,47],[169,63]]]]],["title","Rechnung anzeigen"],0,null,["loc",[null,[169,20],[171,32]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 174,
                      "column": 20
                    },
                    "end": {
                      "line": 176,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/notifications.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","32","color","#3B6182","showStroke",true],["loc",[null,[175,22],[175,79]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 173,
                    "column": 18
                  },
                  "end": {
                    "line": 177,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/notifications.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["purchases.show",["get","item.referenceId",["loc",[null,[174,48],[174,64]]]]],["title","Bestellung anzeigen"],0,null,["loc",[null,[174,20],[176,32]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 157,
                  "column": 16
                },
                "end": {
                  "line": 178,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/notifications.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
              morphs[3] = dom.createMorphAt(fragment,3,3,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[158,28],[158,46]]]],"treatment"],[],["loc",[null,[158,24],[158,59]]]]],[],0,null,["loc",[null,[158,18],[162,25]]]],
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[163,28],[163,46]]]],"patient"],[],["loc",[null,[163,24],[163,57]]]]],[],1,null,["loc",[null,[163,18],[167,25]]]],
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[168,28],[168,46]]]],"invoice"],[],["loc",[null,[168,24],[168,57]]]]],[],2,null,["loc",[null,[168,18],[172,25]]]],
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[173,28],[173,46]]]],"purchase"],[],["loc",[null,[173,24],[173,58]]]]],[],3,null,["loc",[null,[173,18],[177,25]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 136,
                "column": 12
              },
              "end": {
                "line": 181,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/notifications.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell  l-23/24@phone l-23/24@tablet l-3/24@desk");
            dom.setAttribute(el2,"data-label","Wird verschickt am");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell  l-1/24@phone l-1/24@tablet l-3/24@desk");
            dom.setAttribute(el2,"data-label","Tierarztpraxis");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","heading--inline");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell  l-1/24@phone l-1/24@tablet l-3/24@desk");
            dom.setAttribute(el2,"data-label","Kategorie");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell  l-1/24@phone l-1/24@tablet l-3/24@desk");
            dom.setAttribute(el2,"data-label","Empfänger");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","heading--inline");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24@phone l-8/24@tablet l-5/24@desk");
            dom.setAttribute(el2,"data-label","Titel");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24@phone l-8/24@tablet l-5/24@desk");
            dom.setAttribute(el2,"data-label","Nachricht");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--action l-2/24@desk");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [5, 1]);
            var morphs = new Array(11);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1, 0]),0,0);
            morphs[2] = dom.createMorphAt(element1,0,0);
            morphs[3] = dom.createMorphAt(element1,1,1);
            morphs[4] = dom.createMorphAt(element1,2,2);
            morphs[5] = dom.createMorphAt(element1,3,3);
            morphs[6] = dom.createMorphAt(element1,5,5);
            morphs[7] = dom.createMorphAt(dom.childAt(element0, [7, 1, 0]),0,0);
            morphs[8] = dom.createMorphAt(dom.childAt(element0, [9, 1]),0,0);
            morphs[9] = dom.createUnsafeMorphAt(dom.childAt(element0, [11, 1]),0,0);
            morphs[10] = dom.createMorphAt(dom.childAt(element0, [13]),1,1);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","item.created",["loc",[null,[139,38],[139,50]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[139,24],[139,73]]]],
            ["content","item.mandantString",["loc",[null,[142,52],[142,74]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[145,32],[145,48]]]],"jason"],[],["loc",[null,[145,28],[145,57]]]]],[],0,null,["loc",[null,[145,22],[145,182]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[145,192],[145,208]]]],"lab"],[],["loc",[null,[145,188],[145,215]]]]],[],1,null,["loc",[null,[145,182],[145,294]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[145,304],[145,320]]]],"invoice"],[],["loc",[null,[145,300],[145,331]]]]],[],2,null,["loc",[null,[145,294],[145,414]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[145,424],[145,440]]]],"practice"],[],["loc",[null,[145,420],[145,452]]]]],[],3,null,["loc",[null,[145,414],[145,535]]]],
            ["content","item.category.name",["loc",[null,[145,536],[145,558]]]],
            ["inline","button-text",[],["text",["subexpr","truncate",[["get","item.receiversString",["loc",[null,[148,81],[148,101]]]],100],[],["loc",[null,[148,71],[148,106]]]],"content",["subexpr","@mut",[["get","item.receiversString",["loc",[null,[148,115],[148,135]]]]],[],[]]],["loc",[null,[148,52],[148,137]]]],
            ["content","item.title",["loc",[null,[151,22],[151,36]]]],
            ["content","item.message",["loc",[null,[154,22],[154,40]]]],
            ["block","if",[["get","item.referenceId",["loc",[null,[157,22],[157,38]]]]],[],4,null,["loc",[null,[157,16],[178,23]]]]
          ],
          locals: ["item"],
          templates: [child0, child1, child2, child3, child4]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 0
            },
            "end": {
              "line": 186,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/notifications.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1,"class","tabs__body mt20");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2,"data-for","#tab-inbox");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2,"data-for","#tab-unread");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2,"data-for","#tab-outbox");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2,"data-for","#tab-future");
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table no-box@phone");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__head");
          var el5 = dom.createComment("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-3/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title");
          var el7 = dom.createTextNode("Wird verschickt am");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-3/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title");
          var el7 = dom.createTextNode("Tierarztpraxis");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-3/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title");
          var el7 = dom.createTextNode("Kategorie");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-3/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title");
          var el7 = dom.createTextNode("Empfänger");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-5/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title");
          var el7 = dom.createTextNode("Titel");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-5/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title");
          var el7 = dom.createTextNode("Nachricht");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-2/24");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__body");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element2, [3]);
          var element5 = dom.childAt(element2, [5]);
          var element6 = dom.childAt(element2, [7]);
          var morphs = new Array(11);
          morphs[0] = dom.createAttrMorph(element3, 'class');
          morphs[1] = dom.createMorphAt(element3,1,1);
          morphs[2] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
          morphs[3] = dom.createAttrMorph(element4, 'class');
          morphs[4] = dom.createMorphAt(element4,1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
          morphs[6] = dom.createAttrMorph(element5, 'class');
          morphs[7] = dom.createMorphAt(element5,1,1);
          morphs[8] = dom.createMorphAt(dom.childAt(element5, [3]),1,1);
          morphs[9] = dom.createAttrMorph(element6, 'class');
          morphs[10] = dom.createMorphAt(dom.childAt(element6, [1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[72,56],[72,65]]]],"inbox"],[],["loc",[null,[72,52],[72,74]]]],"is-active",""],[],["loc",[null,[72,31],[72,91]]]]]]],
          ["inline","notifications-timeline",[],["items",["subexpr","@mut",[["get","model",["loc",[null,[73,39],[73,44]]]]],[],[]],"delete","delete","reply","reply","activateEtiga","chooseEtigaPanel","markAsReadForAll","markAsReadForAll","showTask","showTask","startAdmission","startAdmission","markAsRead","markAsRead","gotoPracticeCart","gotoPracticeCart","gotoPurchaseProduct","gotoPurchaseProduct","addTask","addTask","openDirectMessage","openDirectMessage","gotoPatient","gotoPatient","startAdmissionFromReorder","startAdmissionFromReorder","startAdmissionFromMessage","startAdmissionFromMessage","openAppRequestConfirmation","openAppRequestConfirmation","forward","forward","type","in"],["loc",[null,[73,8],[90,44]]]],
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[92,33],[92,40]]]]],[],[]],"numPagesToShow",7,"currentPage",["subexpr","@mut",[["get","page",["loc",[null,[92,70],[92,74]]]]],[],[]]],["loc",[null,[92,10],[92,76]]]],
          ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[95,56],[95,65]]]],"unread"],[],["loc",[null,[95,52],[95,75]]]],"is-active",""],[],["loc",[null,[95,31],[95,92]]]]]]],
          ["inline","notifications-timeline",[],["items",["subexpr","@mut",[["get","model",["loc",[null,[96,39],[96,44]]]]],[],[]],"delete","delete","reply","reply","activateEtiga","chooseEtigaPanel","markAsReadForAll","markAsReadForAll","showTask","showTask","markAsRead","markAsRead","startAdmission","startAdmission","openDirectMessage","openDirectMessage","gotoPracticeCart","gotoPracticeCart","gotoPurchaseProduct","gotoPurchaseProduct","gotoPatient","gotoPatient","startAdmissionFromReorder","startAdmissionFromReorder","openAppRequestConfirmation","openAppRequestConfirmation","addTask","addTask","forward","forward","type","unread"],["loc",[null,[96,8],[112,48]]]],
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[114,33],[114,40]]]]],[],[]],"numPagesToShow",7],["loc",[null,[114,10],[114,59]]]],
          ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[117,56],[117,65]]]],"outbox"],[],["loc",[null,[117,52],[117,75]]]],"is-active",""],[],["loc",[null,[117,31],[117,92]]]]]]],
          ["inline","notifications-timeline",[],["items",["subexpr","@mut",[["get","model",["loc",[null,[118,39],[118,44]]]]],[],[]],"delete","delete","reply","reply","addTask","addTask","showTask","showTask","forward","forward","type","out"],["loc",[null,[118,8],[118,143]]]],
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[120,33],[120,40]]]]],[],[]],"numPagesToShow",7],["loc",[null,[120,10],[120,59]]]],
          ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[123,56],[123,65]]]],"future"],[],["loc",[null,[123,52],[123,75]]]],"is-active",""],[],["loc",[null,[123,31],[123,92]]]]]]],
          ["block","each",[["get","futureItems",["loc",[null,[136,20],[136,31]]]]],[],0,null,["loc",[null,[136,12],[181,21]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 220,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/notifications.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","context-bar grid");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","buttons-box col l-24/24");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","card animated edit-product with-context-bar");
        dom.setAttribute(el1,"id","notificationsPanel");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","box");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        dom.setAttribute(el3,"class","u-mb0");
        var el4 = dom.createTextNode("Benachrichtigungen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tabs__head mb10");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"class","u-mb0 clearfix");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-inbox");
        var el7 = dom.createTextNode("Alle");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-unread");
        var el7 = dom.createTextNode("Ungelesene");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-outbox");
        var el7 = dom.createTextNode("Gesendet");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-future");
        var el7 = dom.createTextNode("Geplant");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-6/24@desk box");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","filterCategory");
        dom.setAttribute(el4,"class","input-element");
        dom.setAttribute(el4,"style","width: 100%");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Kategorie");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-6/24@desk box");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","filterStatus");
        dom.setAttribute(el4,"class","input-element");
        dom.setAttribute(el4,"style","width: 100%");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Status");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0, 1]);
        var element10 = dom.childAt(fragment, [2, 3]);
        var element11 = dom.childAt(element10, [1, 1]);
        var element12 = dom.childAt(element11, [1, 1]);
        var element13 = dom.childAt(element11, [3, 1]);
        var element14 = dom.childAt(element11, [5, 1]);
        var element15 = dom.childAt(element11, [7, 1]);
        var morphs = new Array(25);
        morphs[0] = dom.createMorphAt(element9,1,1);
        morphs[1] = dom.createMorphAt(element9,2,2);
        morphs[2] = dom.createAttrMorph(element12, 'class');
        morphs[3] = dom.createElementMorph(element12);
        morphs[4] = dom.createAttrMorph(element13, 'class');
        morphs[5] = dom.createElementMorph(element13);
        morphs[6] = dom.createAttrMorph(element14, 'class');
        morphs[7] = dom.createElementMorph(element14);
        morphs[8] = dom.createAttrMorph(element15, 'class');
        morphs[9] = dom.createElementMorph(element15);
        morphs[10] = dom.createMorphAt(dom.childAt(element10, [3, 1]),3,3);
        morphs[11] = dom.createMorphAt(dom.childAt(element10, [5, 1]),3,3);
        morphs[12] = dom.createMorphAt(element10,7,7);
        morphs[13] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[14] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[15] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[16] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[17] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[18] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[19] = dom.createMorphAt(fragment,16,16,contextualElement);
        morphs[20] = dom.createMorphAt(fragment,18,18,contextualElement);
        morphs[21] = dom.createMorphAt(fragment,20,20,contextualElement);
        morphs[22] = dom.createMorphAt(fragment,22,22,contextualElement);
        morphs[23] = dom.createMorphAt(fragment,24,24,contextualElement);
        morphs[24] = dom.createMorphAt(fragment,26,26,contextualElement);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","has-booking-app",[],[],["loc",[null,[3,10],[3,27]]]]],[],0,null,["loc",[null,[3,4],[7,11]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-jason-network",[],[],["loc",[null,[8,14],[8,32]]]],["subexpr","is-at-least",["essential"],[],["loc",[null,[8,33],[8,58]]]]],[],["loc",[null,[8,10],[8,59]]]]],[],1,null,["loc",[null,[8,4],[12,11]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[25,78],[25,87]]]],"inbox"],[],["loc",[null,[25,74],[25,96]]]],"is-active",""],[],["loc",[null,[25,53],[25,113]]]]]]],
        ["element","action",["selectTab","inbox",1],[],["loc",[null,[25,133],[25,165]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[28,78],[28,87]]]],"unread"],[],["loc",[null,[28,74],[28,97]]]],"is-active",""],[],["loc",[null,[28,53],[28,114]]]]]]],
        ["element","action",["selectTab","unread",1],[],["loc",[null,[28,135],[28,168]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[31,78],[31,87]]]],"outbox"],[],["loc",[null,[31,74],[31,97]]]],"is-active",""],[],["loc",[null,[31,53],[31,114]]]]]]],
        ["element","action",["selectTab","outbox",1],[],["loc",[null,[31,135],[31,168]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[34,78],[34,87]]]],"future"],[],["loc",[null,[34,74],[34,97]]]],"is-active",""],[],["loc",[null,[34,53],[34,114]]]]]]],
        ["element","action",["selectTab","future",1],[],["loc",[null,[34,135],[34,168]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","categories",["loc",[null,[43,18],[43,28]]]]],[],[]],"value",["subexpr","@mut",[["get","filterCategory",["loc",[null,[44,16],[44,30]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","allowClear",false],["loc",[null,[42,8],[49,10]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","status",["loc",[null,[56,18],[56,24]]]]],[],[]],"value",["subexpr","@mut",[["get","filterStatus",["loc",[null,[57,16],[57,28]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","placeholder","Alle","allowClear",true],["loc",[null,[55,8],[63,10]]]],
        ["block","if",[["subexpr","or",[["get","showLoadingOverride",["loc",[null,[67,10],[67,29]]]],["get","showLoading",["loc",[null,[67,30],[67,41]]]]],[],["loc",[null,[67,6],[67,42]]]]],[],2,3,["loc",[null,[67,0],[186,7]]]],
        ["inline","add-notification-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[192,31],[192,36]]]]],[],[]],"mandants",["subexpr","@mut",[["get","mandants",["loc",[null,[192,46],[192,54]]]]],[],[]],"mandant",["subexpr","@mut",[["get","mandant",["loc",[null,[192,63],[192,70]]]]],[],[]],"create","create","forward","sendForward","actionReceiver",["subexpr","@mut",[["get","addNotificationPanel",["loc",[null,[192,125],[192,145]]]]],[],[]]],["loc",[null,[192,0],[192,147]]]],
        ["inline","add-task-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[193,23],[193,28]]]]],[],[]],"create","connectTask","reloadPatients","reloadPatients","actionReceiver",["subexpr","@mut",[["get","addTaskPanel",["loc",[null,[193,97],[193,109]]]]],[],[]]],["loc",[null,[193,0],[193,111]]]],
        ["inline","add-reply-panel",[],["create","addReply","actionReceiver",["subexpr","@mut",[["get","addReplyPanel",["loc",[null,[194,51],[194,64]]]]],[],[]]],["loc",[null,[194,0],[194,66]]]],
        ["inline","choose-etiga-panel",[],["select","selectPatientForEtigaActivation","actionReceiver",["subexpr","@mut",[["get","chooseEtigaPanel",["loc",[null,[195,77],[195,93]]]]],[],[]]],["loc",[null,[195,0],[195,95]]]],
        ["inline","choose-etiga-customer-panel",[],["select","selectCustomerForEtigaActivation","createCustomerForEtigaActivation","createCustomerForEtigaActivation","genders",["subexpr","@mut",[["get","genders",["loc",[null,[196,148],[196,155]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","chooseEtigaCustomerPanel",["loc",[null,[196,171],[196,195]]]]],[],[]]],["loc",[null,[196,0],[196,197]]]],
        ["inline","add-direct-app-notification-panel",[],["reload","reload","create","addDirectAppNotification","actionReceiver",["subexpr","@mut",[["get","addDirectAppNotification",["loc",[null,[198,101],[198,125]]]]],[],[]]],["loc",[null,[198,0],[198,127]]]],
        ["inline","app-request-confirm-panel",[],["confirm","confirmAppRequest","gotoPatient","gotoPatient","gotoPatientEtiga","gotoPatient","gotoCustomer","gotoCustomer","abort","abortAppRequest","actionReceiver",["subexpr","@mut",[["get","appRequestConfirmPanel",["loc",[null,[202,95],[202,117]]]]],[],[]]],["loc",[null,[199,0],[202,119]]]],
        ["inline","finish-task-panel",[],["confirm","finishTaskSend","actionReceiver",["subexpr","@mut",[["get","finishTaskPanel",["loc",[null,[205,60],[205,75]]]]],[],[]]],["loc",[null,[205,0],[205,77]]]],
        ["inline","change-task-owner-panel",[],["confirm","changedTaskOwner","users",["subexpr","@mut",[["get","users",["loc",[null,[206,59],[206,64]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","changeTaskOwnerPanel",["loc",[null,[206,80],[206,100]]]]],[],[]]],["loc",[null,[206,0],[206,102]]]],
        ["inline","show-task-panel",[],["actionReceiver",["subexpr","@mut",[["get","showTaskPanel",["loc",[null,[208,33],[208,46]]]]],[],[]],"linkTo","linkTo","finishTask","finishTask","changeOwner","changeOwner"],["loc",[null,[208,0],[208,114]]]],
        ["inline","treatment-info-form",[],["treatmentSaved","saveTreatment","actionReceiver",["subexpr","@mut",[["get","getTreatmentInfoForm",["loc",[null,[210,68],[210,88]]]]],[],[]]],["loc",[null,[210,0],[210,90]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[216,14],[216,19]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[217,16],[217,23]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[218,19],[218,29]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[219,23],[219,42]]]]],[],[]]],["loc",[null,[212,0],[219,44]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});