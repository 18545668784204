define('jason-frontend/helpers/value-bool-evaluator', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.cssBoolEvaluator = cssBoolEvaluator;

  function cssBoolEvaluator(params) {
    var evaluation = params[0],
        first = params[1],
        second = params[2];

    return evaluation ? first : second;
  }

  exports['default'] = Ember['default'].Helper.helper(cssBoolEvaluator);

});