define('jason-frontend/routes/mandants/show', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('mandant', API['default'].getMandant());
    }
  });

});