define('jason-frontend/routes/mandants/dashboard', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('mandants', this.store.find('mandant'));
      controller.set('suppliers', this.store.find('product-supplier', { filterPurchase: true, hideAll: true }));

      this.applyFilter();
    },
    actions: {
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-'),
          self = this;

      controller.set('showLoading', true);

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      this.findPaged('mandantDashboard', {
        filterFrom: from,
        filterTo: to,
        mandantId: controller.get('mandant'),
        supplierId: controller.get('supplier'),
        sortBy: controller.get('sortBy'),
        sortDir: controller.get('sortDir')
      }).then(function (data) {
        var meta = data.meta;
        controller.set('sumCash', meta.sum_cash);
        controller.set('sumTotal', meta.sum_total);
        controller.set('sumBanktransfer', meta.sum_banktransfer);
        controller.set('sumPurchases', meta.sum_purchases);
        controller.set('sumCommissions', meta.sum_comissions);
        controller.set('sumTransactionFee', meta.sum_transaction_fee);
        controller.set('sumFee', meta.sum_fee);
        controller.set('sumCashback', meta.sum_cashback);
        controller.set('showLoading', false);

        controller.set('model', data);
      });
    }
  });

});