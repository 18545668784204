define('jason-frontend/initializers/bread-crumbs', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {
    name: "ember-breadcrumbs",
    initialize: function initialize(container, app) {
      app.inject("component:bread-crumbs", "router", "router:main");
      app.inject("component:bread-crumbs", "applicationController", "controller:application");
    }
  };

});