define('jason-frontend/templates/components/select-inventory-panel-simple', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 239
                },
                "end": {
                  "line": 24,
                  "column": 355
                }
              },
              "moduleName": "jason-frontend/templates/components/select-inventory-panel-simple.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" = ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.unit.name",["loc",[null,[24,275],[24,293]]]],
              ["inline","format-number-german",[["get","item.conversionFactor",["loc",[null,[24,319],[24,340]]]]],[],["loc",[null,[24,296],[24,342]]]],
              ["content","baseUnit",["loc",[null,[24,343],[24,355]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 355
                },
                "end": {
                  "line": 24,
                  "column": 523
                }
              },
              "moduleName": "jason-frontend/templates/components/select-inventory-panel-simple.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" = ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("1 ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" = ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(6);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
              morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
              morphs[4] = dom.createMorphAt(fragment,9,9,contextualElement);
              morphs[5] = dom.createMorphAt(fragment,11,11,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","baseUnit",["loc",[null,[24,363],[24,375]]]],
              ["inline","multiply",[["get","item.conversionFactor",["loc",[null,[24,389],[24,410]]]],-1],[],["loc",[null,[24,378],[24,415]]]],
              ["content","item.unit.name",["loc",[null,[24,416],[24,434]]]],
              ["content","item.unit.name",["loc",[null,[24,441],[24,459]]]],
              ["inline","divide",[1,["subexpr","multiply",[["get","item.conversionFactor",["loc",[null,[24,483],[24,504]]]],-1],[],["loc",[null,[24,473],[24,508]]]]],[],["loc",[null,[24,462],[24,510]]]],
              ["content","baseUnit",["loc",[null,[24,511],[24,523]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 129
              },
              "end": {
                "line": 24,
                "column": 537
              }
            },
            "moduleName": "jason-frontend/templates/components/select-inventory-panel-simple.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","small");
            var el2 = dom.createTextNode("1 ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","gt",[["get","item.conversionFactor",["loc",[null,[24,249],[24,270]]]],0],[],["loc",[null,[24,245],[24,273]]]]],[],0,1,["loc",[null,[24,239],[24,530]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 14
            },
            "end": {
              "line": 38,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/components/select-inventory-panel-simple.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-2/24");
          dom.setAttribute(el2,"data-label","Nr");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-7/24");
          dom.setAttribute(el2,"data-label","VPE");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-5/24");
          dom.setAttribute(el2,"data-label","Verfügbare Menge");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-5/24");
          dom.setAttribute(el2,"data-label","Lager");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-4/24");
          dom.setAttribute(el2,"data-label","Lagerplatz");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-1/24");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","input input--action");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var element2 = dom.childAt(element0, [5]);
          var element3 = dom.childAt(element0, [11, 1]);
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
          morphs[1] = dom.createMorphAt(element1,0,0);
          morphs[2] = dom.createMorphAt(element1,2,2);
          morphs[3] = dom.createMorphAt(element1,3,3);
          morphs[4] = dom.createMorphAt(element2,0,0);
          morphs[5] = dom.createMorphAt(element2,2,2);
          morphs[6] = dom.createMorphAt(dom.childAt(element0, [7]),1,1);
          morphs[7] = dom.createMorphAt(dom.childAt(element0, [9]),1,1);
          morphs[8] = dom.createElementMorph(element3);
          morphs[9] = dom.createMorphAt(element3,1,1);
          return morphs;
        },
        statements: [
          ["content","item.id",["loc",[null,[23,66],[23,77]]]],
          ["inline","format-number-german",[["get","item.packageQuantity",["loc",[null,[24,88],[24,108]]]]],[],["loc",[null,[24,65],[24,110]]]],
          ["content","item.unit.name",["loc",[null,[24,111],[24,129]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","baseUnit",["loc",[null,[24,149],[24,157]]]],["get","item.unit.name",["loc",[null,[24,158],[24,172]]]]],[],["loc",[null,[24,145],[24,173]]]]],[],["loc",[null,[24,140],[24,174]]]],["subexpr","not",[["subexpr","eq",[["get","item.conversionFactor",["loc",[null,[24,184],[24,205]]]],1],[],["loc",[null,[24,180],[24,208]]]]],[],["loc",[null,[24,175],[24,209]]]]],[],["loc",[null,[24,135],[24,210]]]]],[],0,null,["loc",[null,[24,129],[24,544]]]],
          ["inline","format-number-german",[["get","item.flatStock",["loc",[null,[25,103],[25,117]]]]],[],["loc",[null,[25,80],[25,119]]]],
          ["content","item.unit.name",["loc",[null,[25,120],[25,138]]]],
          ["content","item.warehouseName",["loc",[null,[27,20],[27,42]]]],
          ["content","item.locationInfo",["loc",[null,[30,20],[30,41]]]],
          ["element","action",["use",["get","item.id",["loc",[null,[33,66],[33,73]]]]],[],["loc",[null,[33,51],[33,75]]]],
          ["inline","button-select",[],["color","#3B6182","classNames","ml10","showStroke",true,"content","Lagerstand verwenden"],["loc",[null,[34,22],[34,120]]]]
        ],
        locals: ["item"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 44,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/select-inventory-panel-simple.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-select-inventory-panel-simple");
        dom.setAttribute(el1,"class","popup-basic popup-very-large admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n              ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","like-h2 box pt5 pb5");
        var el4 = dom.createTextNode("Verfügbare Bestände");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table table--small box no-box@tablet no-box@phone");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__head");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-2/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Nr");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-7/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("VPE");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-5/24");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Verfügbar");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-5/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Lager");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-5/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Lagerplatz");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__body");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 1]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element4, [5, 3]),1,1);
        return morphs;
      },
      statements: [
        ["inline","truncate",[["get","productName",["loc",[null,[5,27],[5,38]]]],100],[],["loc",[null,[5,16],[5,44]]]],
        ["block","each",[["get","items",["loc",[null,[21,22],[21,27]]]]],[],0,null,["loc",[null,[21,14],[38,23]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});