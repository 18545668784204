define('jason-frontend/templates/components/quick-scan-product-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 10
              },
              "end": {
                "line": 50,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/quick-scan-product-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-24/24");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","field mb0");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"for","useFavorites");
            dom.setAttribute(el3,"class","field-label title required");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","checkbox-custom checkbox-primary field-checkbox");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","useFavorites");
            var el5 = dom.createTextNode("Gemäß Favoriten direkt ausbuchen");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-24/24");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","field mb0");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"for","useDosing");
            dom.setAttribute(el3,"class","field-label title required");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","checkbox-custom checkbox-primary field-checkbox");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","useDosing");
            var el5 = dom.createTextNode("Gemäß Dosierungsinformationen direkt ausbuchen");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","input",[],["type","checkbox","name","useFavorites","id","useFavorites","checked",["subexpr","@mut",[["get","useFavorites",["loc",[null,[36,88],[36,100]]]]],[],[]]],["loc",[null,[36,18],[36,102]]]],
            ["inline","input",[],["type","checkbox","name","useDosing","id","useDosing","checked",["subexpr","@mut",[["get","useDosing",["loc",[null,[45,82],[45,91]]]]],[],[]]],["loc",[null,[45,18],[45,93]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 8
            },
            "end": {
              "line": 51,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/quick-scan-product-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","mb20 l-24/24");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title field-label");
          dom.setAttribute(el2,"style","color: #626262");
          var el3 = dom.createTextNode("Lageroptionen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","patient-category");
          dom.setAttribute(el2,"class","field select");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","arrow");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","view",[["get","Ember.Select",["loc",[null,[21,23],[21,35]]]]],["content",["subexpr","@mut",[["get","ehapoOptions",["loc",[null,[22,31],[22,43]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","name","ehapoOption","selection",["subexpr","@mut",[["get","directEhapo",["loc",[null,[26,33],[26,44]]]]],[],[]]],["loc",[null,[21,16],[27,18]]]],
          ["block","if",[["subexpr","eq",[["get","directEhapo.id",["loc",[null,[31,20],[31,34]]]],"ehapo"],[],["loc",[null,[31,16],[31,43]]]]],[],0,null,["loc",[null,[31,10],[50,17]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 100,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/quick-scan-product-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-quick-scan-product-panel");
        dom.setAttribute(el1,"class","popup-basic admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n              ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("\n                Produkt scannen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"id","productScanForm");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","box pb0");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-24/24");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field mb10");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","qrProductInput");
        dom.setAttribute(el7,"class","field-label title required");
        var el8 = dom.createTextNode("QR-Code scannen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","bar");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","submit");
        dom.setAttribute(el5,"class","icon-button icon-button--success");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n\n    $('#qrProductInput').scannerDetection({\n      timeBeforeScanTest: 200, // wait for the next character for upto 200ms\n      onComplete: function(barcode, qty) {\n        if (!$('#qrProductInput').prop('disabled')) {\n          $('#qrProductInput').prop('disabled', true);\n          $('#productScanForm').submit();\n        }\n      },\n      scanButtonKeyCode: 116, // the hardware scan button acts as key 116 (F5)\n      scanButtonLongPressThreshold: 5, // assume a long press if 5 or more events come in sequence\n      onError: function(string){ console.log('error scanning');}\n    });\n\n    $(document).off(\"change paste keyup select\", \"#qrProductInput\");\n    $(document).on(\"change paste keyup select\", \"#qrProductInput\", function() {\n      var value = $(this).val();\n      if(value.toLowerCase().endsWith(\"qr\") && !$(this).prop('disabled')) {\n        $(this).prop('disabled', true);\n        setTimeout(function() {\n          if(!$(this).prop('disabled')) {\n            $('#productScanForm').submit();\n          }\n        }, 300);\n      }\n    });\n\n    $(document).off('change', '#directEhapo');\n    $(document).on('change', '#directEhapo', function() {\n      setTimeout(function() {\n        $('#qrProductInput').focus();\n      }, 500);\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 3]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1, 1]),3,3);
        morphs[2] = dom.createMorphAt(element1,3,3);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [3, 1]),1,1);
        return morphs;
      },
      statements: [
        ["element","action",["confirm"],["on","submit"],["loc",[null,[8,10],[8,42]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","qrProductInput",["loc",[null,[14,26],[14,40]]]]],[],[]],"class","gui-input","id","qrProductInput","name","qrProductInput","autocomplete","off"],["loc",[null,[14,12],[14,121]]]],
        ["block","if",[["get","showDirectEhapo",["loc",[null,[17,14],[17,29]]]]],[],0,null,["loc",[null,[17,8],[51,15]]]],
        ["inline","button-next",[],["color","#ffffff","size","52","content","Position hinzufügen"],["loc",[null,[56,10],[56,81]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});