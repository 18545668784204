define('jason-frontend/controllers/purchases/ws-logs', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Datacare Übermittlungsfehler",
    breadCrumbPath: "purchases.ws-logs",
    sortBy: null,
    sortDir: null,
    filterMandant: null,
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),
    page: 1,
    perPage: 10,
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('pickDate'),
    watchMandant: (function () {
      this.send('applyFilter');
    }).observes('filterMandant')
  });

});