define('jason-frontend/controllers/textmodules', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Vorlagen",
    breadCrumbPath: "textmodules.index",
    filterFavorites: false
  });

});