define('jason-frontend/components/labprofile-edit-form', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      save: function save() {
        this.sendAction('save');
      },
      saveReference: function saveReference(data) {
        this.sendAction('saveReference', data);
      },
      deleteReference: function deleteReference(data) {
        this.sendAction('deleteReference', data);
      },
      editReference: function editReference(id, paramId) {
        var self = this;

        $.ajax({
          url: "/api/labProfiles/customReference/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('editLabReferencePanel').send('load', data.labProfilePatientCategoryReferenceValue, paramId);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-labreference-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      addReference: function addReference(paramId) {
        var self = this;

        self.get('editLabReferencePanel').send('load', null, paramId);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-labreference-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    }
  });

});