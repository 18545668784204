define('jason-frontend/routes/cash/inlay', ['exports', 'ember', 'jason-frontend/api', 'moment'], function (exports, Ember, API, moment) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller) {

      if (!API['default'].hasPermission('c_payments')) {
        this.transitionTo('home');
      }

      controller.set('styleStepPayment', '');
      controller.set('styleStepConfirmation', 'hidden');
      controller.set('transaction', null);
      controller.set('notice', null);
      controller.set('amount', null);
      controller.set('reason', null);
      controller.set('submitButtonEnabled', true);
      controller.set('selectedPaymentType', 'cash');

      this.store.find('cashRegister', { hideInactive: true }).then(function (data) {
        controller.set('cashRegisterList', data);
        controller.set('selectedCashRegister', data.get('firstObject'));
        if (API['default'].getPrimaryCashRegisterId() && API['default'].getPrimaryCashRegisterId() > 0) {
          data.forEach(function (item) {
            if (item.id === API['default'].getPrimaryCashRegisterId()) {
              controller.set('selectedCashRegister', item);
            }
          });
        }
      });
    },
    actions: {
      download: function download(url) {
        window.open(url);
      },
      doPayment: function doPayment() {
        var controller = this.get('controller');
        controller.set('submitButtonEnabled', false);

        var selectedCashRegister = controller.get('selectedCashRegister'),
            selectedType = controller.get('selectedType'),
            reason = controller.get('reason'),
            amount = controller.get('amount'),
            documentDate = controller.get('documentDate');

        if (selectedCashRegister) {
          selectedCashRegister = selectedCashRegister.id;
        }

        var payments = new Array();

        payments[0] = {
          given: amount.replace(",", "."),
          paymentType: 'cash'
        };

        var payload = {
          invoiceId: controller.get('selectedInvoiceId'),
          selectedCashRegister: selectedCashRegister,
          reason: reason,
          documentDate: moment['default'](documentDate, 'DD. MM. YYYY').format('YYYY-MM-DD'),
          type: 'inlay',
          payments: payments
        };

        var self = this;

        controller.set('styleStepPayment', 'hidden');
        controller.set('showLoading', true);
        controller.set('styleStepConfirmation', '');

        $.ajax({
          url: "/api/cashdesk/in",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(payload),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('showLoading', false);
          controller.set('currentTransactionId', data);

          self.store.fetch('transaction-group', controller.get('currentTransactionId')).then(function (data2) {
            controller.set('transactionGroup', data2);
            controller.set('transactionDownloadUrl', '/api/salesSlip/' + data2.get('token'));
          });

          jason.notifiction.notifiy('Buchung erfolgreich', '');
          controller.set('submitButtonEnabled', true);
          return data;
        }, function (error) {
          controller.set('showLoading', false);
          jason.notifiction.error('Fehler', 'Es ist ein Fehler aufgetreten');
          controller.set('submitButtonEnabled', true);
        });
      },
      print: function print(transactionGroup) {
        var deferred = $.ajax({
          url: "/api/cashdesk/print/" + transactionGroup.get('id'),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Belegdruck durchgeführt', '');
        }, function () {
          jason.notifiction.systemError('Fehlercode 101', 'Belegdruck konnte nicht durchgeführt werden');
        });
      },
      showSlip: function showSlip() {
        this.get('controller').get('pdfPreview').send('show');
      },
      reset: function reset() {
        var controller = this.get('controller');
      }
    }
  });

});