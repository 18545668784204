define('jason-frontend/routes/sale/dashboard', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('users', this.store.find('user', { per_page: 100, addNotAssigned: true }));

      $.ajax({
        url: "/api/productCategories?suggest=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('productCategories', data.productCategory.sortBy('text'));
        controller.set('filterProductCategory', null);
      });
      $.ajax({
        url: "/api/serviceCategories?suggest=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('serviceCategories', data.serviceCategory.sortBy('text'));
        controller.set('filterServiceCategory', null);
      });

      controller.set('suppliers', this.store.find('product-supplier', { filterPurchase: true }));

      this.applyFilter();
    },
    actions: {
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      },
      updatePartnerCategories: function updatePartnerCategories() {
        var controller = this.controller;
        var selectedSupplier = controller.get('filterSupplier');

        if (selectedSupplier) {
          $.ajax({
            url: "/api/purchaseProducts/partnerCategories/" + selectedSupplier.id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set('partnerCategories', data.partnerCategory);
          });
        }
      },
      queryProducts: function queryProducts(query, deferred) {
        this.store.find('product', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      queryProductsPurchase: function queryProductsPurchase(query, deferred) {
        this.store.find('product', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      queryServices: function queryServices(query, deferred) {
        this.store.find('service', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      queryCustomer: function queryCustomer(query, deferred) {
        this.store.find('customer', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      sortable: function sortable(attr) {
        if (this.get('controller').get('sortBy') === attr) {
          if (this.get('controller').get('sortDir') === 'asc') {
            this.get('controller').set('sortDir', 'desc');
          } else {
            this.get('controller').set('sortDir', 'asc');
          }
        } else {
          this.get('controller').set('sortBy', attr);
        }
        this.applyFilter();
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-'),
          filterCustomer = controller.get('filterCustomer'),
          filterUser = controller.get('filterUser'),
          filterUserRole = controller.get('filterUserRole'),
          filterProductCategory = controller.get('filterProductCategory'),
          filterServiceCategory = controller.get('filterServiceCategory'),
          filterProduct = controller.get('filterProduct'),
          filterService = controller.get('filterService'),
          filterSupplier = controller.get('filterSupplier'),
          filterPartnerCategory = controller.get('filterPartnerCategory'),
          self = this;

      controller.set('showLoading', true);

      var from = moment(dateFromTo[0], 'DD. MM. YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY HH:mm').format('YYYY-MM-DD HH:mm');

      controller.set('to', to);
      controller.set('from', from);

      if (filterCustomer && isNaN(filterCustomer)) {
        filterCustomer = filterCustomer.id;
      }
      if (filterUser && isNaN(filterUser)) {
        filterUser = filterUser.id;
      }
      if (filterProductCategory && isNaN(filterProductCategory)) {
        filterProductCategory = filterProductCategory.id;
      }
      if (filterServiceCategory && isNaN(filterServiceCategory)) {
        filterServiceCategory = filterServiceCategory.id;
      }
      if (filterProduct && isNaN(filterProduct)) {
        filterProduct = filterProduct.id;
      }
      if (filterService && isNaN(filterService)) {
        filterProduct = filterService.id;
        filterService = null;
      }
      if (filterSupplier && isNaN(filterSupplier)) {
        filterSupplier = filterSupplier.id;
      }
      if (filterPartnerCategory && isNaN(filterPartnerCategory)) {
        filterPartnerCategory = filterPartnerCategory.name;
      }

      this.findPaged('saleDashboard', {
        filterFrom: from,
        filterTo: to,
        userId: filterUser,
        customerId: filterCustomer,
        userRole: filterUserRole,
        productCategory: filterProductCategory,
        serviceCategory: filterServiceCategory,
        filterProduct: filterProduct,
        filterService: filterService,
        filterSupplier: filterSupplier,
        filterPartnerCategory: filterPartnerCategory,
        sortBy: controller.get('sortBy'),
        sortDir: controller.get('sortDir'),
        lastId: controller.get('lastId'),
        per_page: 10
      }).then(function (data) {
        var meta = data.meta;
        controller.set('totalRevenue', meta.totalRevenue);
        controller.set('totalRevenueGross', meta.totalRevenueGross);
        controller.set('totalOpen', meta.totalOpen);
        controller.set('totalTreatments', meta.totalTreatments);
        controller.set('totalRevenueTreatmentOnly', meta.totalRevenueTreatmentOnly);
        controller.set('totalTreatmentsWithoutInvoice', meta.totalTreatmentsWithoutInvoice);
        controller.set('totalTreatments', meta.totalTreatments);
        controller.set('totalRevenue20', meta.totalRevenue20);
        controller.set('totalRevenue19', meta.totalRevenue19);
        controller.set('totalRevenue13', meta.totalRevenue13);
        controller.set('totalRevenue10', meta.totalRevenue10);
        controller.set('totalRevenue7', meta.totalRevenue7);
        controller.set('totalRevenue0', meta.totalRevenue0);
        controller.set('showLoading', false);
        controller.set('lastId', meta.lastId);

        controller.set('model', data);
      });
    }
  });

});