define('jason-frontend/models/transaction-group', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    transactions: DS['default'].hasMany('transaction'),
    created: DS['default'].attr('date'),
    transactionId: DS['default'].attr('number'),
    invoiceId: DS['default'].attr('number'),
    invoiceCode: DS['default'].attr('string'),
    customer: DS['default'].attr('string'),
    customerId: DS['default'].attr('number'),
    banktransferId: DS['default'].attr('number'),
    banktransfer: DS['default'].attr('boolean'),
    cancellation: DS['default'].attr('boolean'),
    canceledBy: DS['default'].attr('number'),
    cashdeskProcessed: DS['default'].attr('boolean'),
    verificationDate: DS['default'].attr('date'),
    verificationStatus: DS['default'].belongsTo('verificationStatus'),
    info: DS['default'].attr('string'),
    total: DS['default'].attr('number'),
    token: DS['default'].attr(''),
    transactionType: DS['default'].belongsTo('transactionType'),
    transactionSubType: DS['default'].belongsTo('transactionSubType')
  });

});