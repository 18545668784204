define('jason-frontend/controllers/notifications', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    filterOrderStatus: "exported",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    filterCategory: null,
    perPage: 10,
    showLoading: false,
    showLoadingOverride: true,
    queryParams: ['filterType'],
    selectTab: 'inbox',
    breadCrumb: "Meine Nachrichten",
    breadCrumbPath: "notifications",
    status: [{
      id: "open", name: 'Offen'
    }, {
      id: "done", name: 'Erledigt'
    }],
    actions: {
      editTreatment: function editTreatment(item, patient) {

        this.get('getTreatmentInfoForm').send('load', item, patient);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#treatment-edit-form'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openAppRequestConfirmation: function openAppRequestConfirmation(item) {

        var self = this;

        $.ajax({
          url: "/api/patientAppointments/" + item.get('referenceData').id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('appRequestConfirmPanel').send('load', data.patientAppointment, item);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-app-request-confirm-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      reply: function reply(item) {

        this.get('addReplyPanel').send('reset', item);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-reply-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openDirectMessage: function openDirectMessage(item, type) {
        var self = this;
        if (type === 'finished') {

          $.ajax({
            url: "/api/mandants/emailText/" + item.id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.get('addDirectAppNotification').send('loadWithPreset', item.id, data.mandantEmailText.appReorderConfirm);
          });
        } else {
          this.get('addDirectAppNotification').send('load', item, false);
        }

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-direct-app-notification-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      changeOwner: function changeOwner(item) {

        this.get('changeTaskOwnerPanel').send('reset', item, API['default'].getUserId());

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-change-task-owner-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      finishTask: function finishTask(item) {
        var self = this;

        this.get('finishTaskPanel').send('reset', item);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-finish-task-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      showTask: function showTask(item) {

        this.get('showTaskPanel').send('load', item);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-show-task-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      addTask: function addTask(item) {
        this.get('addTaskPanel').send('reset');
        this.get('addTaskPanel').send('setMsgReference', item);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-task-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      chooseEtigaPanel: function chooseEtigaPanel(id, msg) {

        var self = this;

        var message = {
          id: msg.id
        };

        $.ajax({
          url: "/api/patients/" + id + "/activateEtiga",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          if (data.connected && data.patientId > 0) {
            self.transitionTo('patients.edit', data.patientId);
          } else if (!data.connected && data.patientId == 0 && data.matchCount == 0) {

            self.get('chooseEtigaCustomerPanel').send('load', null, data.master.id, data.customer);

            $.magnificPopup.open({
              removalDelay: 500,
              closeOnBgClick: false,
              items: {
                src: '#modal-choose-etiga-customer-panel'
              },
              callbacks: {
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: true
            });
          } else {
            self.get('chooseEtigaPanel').send('load', data.matches, data.master.id);

            $.magnificPopup.open({
              removalDelay: 500,
              closeOnBgClick: false,
              items: {
                src: '#modal-choose-etiga-panel'
              },
              callbacks: {
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: true
            });
          }
          if (message.unread) {
            self.send('markAsRead', message);
          }
        }, function (error) {});
      },
      openNew: function openNew() {
        var self = this;
        this.store.find('mandant').then(function (data) {
          self.set('mandants', data);

          data.forEach(function (item) {
            if (item.id === API['default'].getMandant()) {
              self.set('mandant', item);
            }
          });
        });

        this.get('addNotificationPanel').send('reset');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-notification-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      forward: function forward(msg) {
        var self = this;
        this.store.find('mandant').then(function (data) {
          self.set('mandants', data);

          data.forEach(function (item) {
            if (item.id === API['default'].getMandant()) {
              self.set('mandant', item);
            }
          });
        });

        this.get('addNotificationPanel').send('reset');
        this.get('addNotificationPanel').send('forward', msg);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-notification-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    },
    watchCategoryFilter: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('selectTab');
      }, 500);
    }).observes('filterCategory'),
    watchStatus: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('selectTab');
      }, 500);
    }).observes('filterStatus')
  });

});