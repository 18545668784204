define('jason-frontend/components/purchase-order-laboklin-chooser-treatment', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    actions: {
      load: function load(treatmentPatientId, entryId, data) {
        this.set('treatmentPatientId', treatmentPatientId);
        this.set('entryId', entryId);
        this.set('items', data.purchaseOrderLaboklinForm);
      },
      addNew: function addNew(treatmentPatientId, entryId) {
        this.sendAction('addNewLaboklin', treatmentPatientId, entryId);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      assign: function assign(treatmentPatientId, entryId, formId) {
        this.sendAction('assignLaboklin', treatmentPatientId, entryId, formId);
        $.magnificPopup.close();
      }
    },
    becomeFocused: (function () {}).observes('data')
  });

});