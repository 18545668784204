define('jason-frontend/templates/digitalhelper', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 4
            },
            "end": {
              "line": 22,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/digitalhelper.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","error");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"class","context button default");
          var el2 = dom.createTextNode("Schließen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element34 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),0,0);
          morphs[1] = dom.createElementMorph(element34);
          return morphs;
        },
        statements: [
          ["content","errorMsg",["loc",[null,[20,23],[20,37]]]],
          ["element","action",["close"],[],["loc",[null,[21,45],[21,63]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 14
              },
              "end": {
                "line": 37,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/digitalhelper.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","animal-icon",[],["useFilter",false,"classNames","inline-block vertical-middle","color","#fff","animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[36,40],[36,59]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[36,68],[36,92]]]]],[],[]],"size","60","content",""],["loc",[null,[35,16],[36,115]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 14
              },
              "end": {
                "line": 39,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/digitalhelper.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/jason-poe-white.svg");
            dom.setAttribute(el1,"style","width: 55px;padding-top: 11px;padding-left: 6px;");
            dom.setAttribute(el1,"class","inline-block vertical-middle");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 14
              },
              "end": {
                "line": 42,
                "column": 92
              }
            },
            "moduleName": "jason-frontend/templates/digitalhelper.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"class","dead-icon");
            dom.setAttribute(el1,"src","assets/icons/died-black.svg");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 55
              },
              "end": {
                "line": 45,
                "column": 42
              }
            },
            "moduleName": "jason-frontend/templates/digitalhelper.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n            - ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","patient.digitalRace.name",["loc",[null,[45,14],[45,42]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 42
                },
                "end": {
                  "line": 45,
                  "column": 84
                }
              },
              "moduleName": "jason-frontend/templates/digitalhelper.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("- ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","patient.race",["loc",[null,[45,68],[45,84]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 42
              },
              "end": {
                "line": 45,
                "column": 84
              }
            },
            "moduleName": "jason-frontend/templates/digitalhelper.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","patient.race",["loc",[null,[45,52],[45,64]]]]],[],0,null,["loc",[null,[45,42],[45,84]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 6
              },
              "end": {
                "line": 62,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/digitalhelper.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","chooser");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            dom.setAttribute(el2,"style","margin-left: 0");
            var el3 = dom.createTextNode("Funktionen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"class","context button");
            var el3 = dom.createTextNode("Profilbild ändern");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"class","context button");
            var el3 = dom.createTextNode("Bilder und Dokumente hinzufügen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"class","context button default mt50");
            var el3 = dom.createTextNode("Schließen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element23 = dom.childAt(fragment, [1]);
            var element24 = dom.childAt(element23, [3]);
            var element25 = dom.childAt(element23, [5]);
            var element26 = dom.childAt(element23, [7]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element24);
            morphs[1] = dom.createElementMorph(element25);
            morphs[2] = dom.createElementMorph(element26);
            return morphs;
          },
          statements: [
            ["element","action",["toChangeProfilePic"],[],["loc",[null,[58,41],[58,72]]]],
            ["element","action",["toUpload"],[],["loc",[null,[59,41],[59,62]]]],
            ["element","action",["close"],[],["loc",[null,[60,54],[60,72]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 72,
                      "column": 16
                    },
                    "end": {
                      "line": 75,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"id","img");
                  dom.setAttribute(el1,"src","#");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"class","mt20 button context");
                  var el2 = dom.createTextNode("Foto verwerfen");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element19 = dom.childAt(fragment, [3]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element19);
                  return morphs;
                },
                statements: [
                  ["element","action",["retakeMobilePicture"],[],["loc",[null,[74,54],[74,86]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 75,
                      "column": 16
                    },
                    "end": {
                      "line": 77,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"id","camerapic");
                  dom.setAttribute(el1,"class","cursor");
                  dom.setAttribute(el1,"src","assets/images/icons/camera.svg");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element18 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element18);
                  return morphs;
                },
                statements: [
                  ["element","action",["uploadFile"],[],["loc",[null,[76,90],[76,113]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 68,
                    "column": 14
                  },
                  "end": {
                    "line": 78,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/digitalhelper.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("form");
                dom.setAttribute(el1,"style","");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("input");
                dom.setAttribute(el2,"type","file");
                dom.setAttribute(el2,"id","fileUpload");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element20 = dom.childAt(fragment, [1, 1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element20);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["element","action",["renderPreview"],["on","change"],["loc",[null,[70,53],[70,91]]]],
                ["block","if",[["get","previewImage",["loc",[null,[72,22],[72,34]]]]],[],0,1,["loc",[null,[72,16],[77,23]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 87,
                      "column": 18
                    },
                    "end": {
                      "line": 90,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"class","gutter col l-5/24 button context");
                  var el2 = dom.createElement("img");
                  dom.setAttribute(el2,"src","assets/images/icons/reversecamera.svg");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","col l-1/24");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element12 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element12);
                  return morphs;
                },
                statements: [
                  ["element","action",["changeCamera"],[],["loc",[null,[88,69],[88,94]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 91,
                      "column": 18
                    },
                    "end": {
                      "line": 93,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"id","screenshot-button");
                  var el2 = dom.createTextNode("Foto aufnehmen");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element11 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element11, 'class');
                  return morphs;
                },
                statements: [
                  ["attribute","class",["concat",["gutter col l-",["subexpr","css-bool-evaluator",[["get","showSwitchCamera",["loc",[null,[92,64],[92,80]]]],"18","24"],[],["loc",[null,[92,43],[92,92]]]],"/24 button context"]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 97,
                      "column": 18
                    },
                    "end": {
                      "line": 100,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"class","gutter col l-5/24 button context");
                  var el2 = dom.createElement("img");
                  dom.setAttribute(el2,"src","assets/images/icons/reversecamera.svg");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","col l-1/24");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element10 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element10);
                  return morphs;
                },
                statements: [
                  ["element","action",["changeCamera"],[],["loc",[null,[98,69],[98,94]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 78,
                    "column": 14
                  },
                  "end": {
                    "line": 103,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/digitalhelper.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("b");
                dom.setAttribute(el2,"class","is-error");
                var el3 = dom.createTextNode("Keine Kamera gefunden");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","activatePicture");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/camera.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","takePicture hidden grid");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("video");
                dom.setAttribute(el2,"autoplay","");
                dom.setAttribute(el2,"id","video");
                dom.setAttribute(el2,"class","");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","pictureTaken hidden grid");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","");
                dom.setAttribute(el2,"id","img");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                var el3 = dom.createTextNode("Foto verwerfen");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element13 = dom.childAt(fragment, [1]);
                var element14 = dom.childAt(fragment, [3, 1]);
                var element15 = dom.childAt(fragment, [5]);
                var element16 = dom.childAt(fragment, [7]);
                var element17 = dom.childAt(element16, [5]);
                var morphs = new Array(7);
                morphs[0] = dom.createAttrMorph(element13, 'class');
                morphs[1] = dom.createElementMorph(element14);
                morphs[2] = dom.createMorphAt(element15,3,3);
                morphs[3] = dom.createMorphAt(element15,4,4);
                morphs[4] = dom.createMorphAt(element16,3,3);
                morphs[5] = dom.createAttrMorph(element17, 'class');
                morphs[6] = dom.createElementMorph(element17);
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["text-center error ",["subexpr","css-bool-evaluator",[["get","cameraError",["loc",[null,[79,67],[79,78]]]],"","hidden"],[],["loc",[null,[79,46],[79,92]]]]]]],
                ["element","action",["activateVideoStream"],[],["loc",[null,[83,60],[83,92]]]],
                ["block","if",[["get","showSwitchCamera",["loc",[null,[87,24],[87,40]]]]],[],0,null,["loc",[null,[87,18],[90,25]]]],
                ["block","if",[["subexpr","not",[["get","cameraError",["loc",[null,[91,29],[91,40]]]]],[],["loc",[null,[91,24],[91,41]]]]],[],1,null,["loc",[null,[91,18],[93,25]]]],
                ["block","if",[["get","showSwitchCamera",["loc",[null,[97,24],[97,40]]]]],[],2,null,["loc",[null,[97,18],[100,25]]]],
                ["attribute","class",["concat",["gutter col l-",["subexpr","css-bool-evaluator",[["get","showSwitchCamera",["loc",[null,[101,62],[101,78]]]],"18","24"],[],["loc",[null,[101,41],[101,90]]]],"/24 button context"]]],
                ["element","action",["retakePicture"],[],["loc",[null,[101,110],[101,136]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 109,
                    "column": 10
                  },
                  "end": {
                    "line": 113,
                    "column": 10
                  }
                },
                "moduleName": "jason-frontend/templates/digitalhelper.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","text-center mb10");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","loading-animation",["loc",[null,[111,14],[111,35]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 114,
                      "column": 12
                    },
                    "end": {
                      "line": 116,
                      "column": 12
                    }
                  },
                  "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"type","submit");
                  dom.setAttribute(el1,"class","button context primary");
                  var el2 = dom.createTextNode("Speichern");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element9 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element9);
                  return morphs;
                },
                statements: [
                  ["element","action",["changeProfilePic"],[],["loc",[null,[115,67],[115,96]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 113,
                    "column": 10
                  },
                  "end": {
                    "line": 117,
                    "column": 10
                  }
                },
                "moduleName": "jason-frontend/templates/digitalhelper.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","not",[["get","cameraError",["loc",[null,[114,23],[114,34]]]]],[],["loc",[null,[114,18],[114,35]]]]],[],0,null,["loc",[null,[114,12],[116,19]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 6
                },
                "end": {
                  "line": 121,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/digitalhelper.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"id","changeProfilePic");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h2");
              var el3 = dom.createElement("img");
              dom.setAttribute(el3,"class","cursor");
              dom.setAttribute(el3,"src","assets/images/icons/back.svg");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" Profilbild ändern");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","col l-24/24");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("b");
              var el4 = dom.createTextNode("Bildquelle wählen");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","imageFrame");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","button context hidden");
              dom.setAttribute(el3,"id","capture-video-button");
              var el4 = dom.createTextNode("Video aufnehmen");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("select");
              dom.setAttribute(el2,"id","video-source");
              dom.setAttribute(el2,"class","hidden");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element21 = dom.childAt(fragment, [1]);
              var element22 = dom.childAt(element21, [1, 0]);
              var morphs = new Array(3);
              morphs[0] = dom.createElementMorph(element22);
              morphs[1] = dom.createMorphAt(dom.childAt(element21, [3, 3]),1,1);
              morphs[2] = dom.createMorphAt(element21,5,5);
              return morphs;
            },
            statements: [
              ["element","action",["toChoose"],[],["loc",[null,[64,69],[64,90]]]],
              ["block","if",[["get","mobile",["loc",[null,[68,20],[68,26]]]]],[],0,1,["loc",[null,[68,14],[103,21]]]],
              ["block","if",[["get","fileuploadInProgress",["loc",[null,[109,16],[109,36]]]]],[],2,3,["loc",[null,[109,10],[117,17]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 129,
                        "column": 16
                      },
                      "end": {
                        "line": 131,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","cursor");
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2,"src","assets/images/icons/go-back.svg");
                    dom.setAttribute(el2,"class","mr10");
                    dom.setAttribute(el2,"style","width: 20px");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element4 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element4);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["gotoFolder",["get","upFolder",["loc",[null,[130,58],[130,66]]]]],[],["loc",[null,[130,36],[130,68]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 134,
                        "column": 16
                      },
                      "end": {
                        "line": 138,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("li");
                    dom.setAttribute(el1,"class","crumb-trail folder");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","is-highlighted cursor");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element3 = dom.childAt(fragment, [1, 1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element3);
                    morphs[1] = dom.createMorphAt(element3,0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["gotoFolder",["get","bread",["loc",[null,[136,75],[136,80]]]]],[],["loc",[null,[136,53],[136,82]]]],
                    ["content","bread.name",["loc",[null,[136,83],[136,97]]]]
                  ],
                  locals: ["bread"],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 126,
                      "column": 10
                    },
                    "end": {
                      "line": 141,
                      "column": 10
                    }
                  },
                  "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"id","breads");
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","nav inline-block");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("ul");
                  dom.setAttribute(el2,"class","inline-block");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]),1,1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]),1,1);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","upFolder",["loc",[null,[129,22],[129,30]]]]],[],0,null,["loc",[null,[129,16],[131,23]]]],
                  ["block","each",[["get","breads",["loc",[null,[134,24],[134,30]]]]],[],1,null,["loc",[null,[134,16],[138,25]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 144,
                      "column": 12
                    },
                    "end": {
                      "line": 146,
                      "column": 12
                    }
                  },
                  "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","col l-24/24");
                  var el2 = dom.createElement("img");
                  dom.setAttribute(el2,"src","assets/images/icons/folder.svg");
                  dom.setAttribute(el2,"class","mr10 verticaltop");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("a");
                  dom.setAttribute(el2,"class","is-highlighted");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element2 = dom.childAt(fragment, [1, 2]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element2);
                  morphs[1] = dom.createMorphAt(element2,0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["gotoFolder",["get","folder",["loc",[null,[145,156],[145,162]]]]],[],["loc",[null,[145,134],[145,164]]]],
                  ["content","folder.name",["loc",[null,[145,165],[145,180]]]]
                ],
                locals: ["folder"],
                templates: []
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 151,
                        "column": 14
                      },
                      "end": {
                        "line": 158,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","ok");
                    var el2 = dom.createTextNode("\n                ");
                    dom.appendChild(el1, el2);
                    dom.setNamespace("http://www.w3.org/2000/svg");
                    var el2 = dom.createElement("svg");
                    dom.setAttribute(el2,"enable-background","new 0 0 24 24");
                    dom.setAttribute(el2,"height","512");
                    dom.setAttribute(el2,"viewBox","0 0 24 24");
                    dom.setAttribute(el2,"width","512");
                    dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
                    var el3 = dom.createTextNode("\n                  ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("path");
                    dom.setAttribute(el3,"fill","white");
                    dom.setAttribute(el3,"d","m.828 13.336c-.261.304-.388.691-.357 1.091s.215.764.52 1.024l7.403 6.346c.275.235.616.361.974.361.044 0 .089-.002.134-.006.405-.036.77-.229 1.028-.542l12.662-15.411c.254-.31.373-.7.334-1.099-.04-.399-.231-.759-.541-1.014l-2.318-1.904c-.639-.524-1.585-.432-2.111.207l-9.745 11.861-3.916-3.355c-.628-.536-1.576-.465-2.115.163z");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n              ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 158,
                        "column": 16
                      },
                      "end": {
                        "line": 164,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","failure");
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.setNamespace("http://www.w3.org/2000/svg");
                    var el2 = dom.createElement("svg");
                    dom.setAttribute(el2,"width","528px");
                    dom.setAttribute(el2,"height","528px");
                    dom.setAttribute(el2,"viewBox","0 -8 528 528");
                    dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
                    var el3 = dom.createTextNode("\n                    ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("path");
                    dom.setAttribute(el3,"d","M264 456Q210 456 164 429 118 402 91 356 64 310 64 256 64 202 91 156 118 110 164 83 210 56 264 56 318 56 364 83 410 110 437 156 464 202 464 256 464 310 437 356 410 402 364 429 318 456 264 456ZM264 288L328 352 360 320 296 256 360 192 328 160 264 224 200 160 168 192 232 256 168 320 200 352 264 288Z");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                  ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 166,
                        "column": 20
                      },
                      "end": {
                        "line": 166,
                        "column": 74
                      }
                    },
                    "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("erfolgreich hochgeladen");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child3 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 166,
                        "column": 74
                      },
                      "end": {
                        "line": 166,
                        "column": 113
                      }
                    },
                    "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("konnte nicht hochgeladen werden");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 149,
                      "column": 10
                    },
                    "end": {
                      "line": 168,
                      "column": 10
                    }
                  },
                  "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","upload-status");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(element1,1,1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]),0,0);
                  morphs[2] = dom.createMorphAt(dom.childAt(element1, [5]),0,0);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","upload.status",["loc",[null,[151,24],[151,37]]]],"ok"],[],["loc",[null,[151,20],[151,43]]]]],[],0,1,["loc",[null,[151,14],[164,23]]]],
                  ["content","upload.name",["loc",[null,[165,28],[165,43]]]],
                  ["block","if",[["subexpr","eq",[["get","upload.status",["loc",[null,[166,30],[166,43]]]],"ok"],[],["loc",[null,[166,26],[166,49]]]]],[],2,3,["loc",[null,[166,20],[166,120]]]]
                ],
                locals: ["upload"],
                templates: [child0, child1, child2, child3]
              };
            }());
            var child3 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 174,
                      "column": 10
                    },
                    "end": {
                      "line": 178,
                      "column": 10
                    }
                  },
                  "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","text-center mb10");
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["content","loading-animation",["loc",[null,[176,14],[176,35]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child4 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 180,
                      "column": 10
                    },
                    "end": {
                      "line": 182,
                      "column": 10
                    }
                  },
                  "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"type","submit");
                  dom.setAttribute(el1,"class","button context default mb50");
                  var el2 = dom.createTextNode("Hinzufügen");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child5 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 182,
                      "column": 10
                    },
                    "end": {
                      "line": 184,
                      "column": 10
                    }
                  },
                  "moduleName": "jason-frontend/templates/digitalhelper.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"type","submit");
                  dom.setAttribute(el1,"class","button context primary mb50");
                  var el2 = dom.createTextNode("Hinzufügen");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element0);
                  return morphs;
                },
                statements: [
                  ["element","action",["uploadFile"],[],["loc",[null,[183,70],[183,93]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 121,
                    "column": 6
                  },
                  "end": {
                    "line": 187,
                    "column": 6
                  }
                },
                "moduleName": "jason-frontend/templates/digitalhelper.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"id","uploadView");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("h2");
                var el3 = dom.createElement("img");
                dom.setAttribute(el3,"class","cursor");
                dom.setAttribute(el3,"src","assets/images/icons/back.svg");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" Bilder und Dokumente");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Ordner auswählen");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"id","folderList");
                dom.setAttribute(el2,"class","grid");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("form");
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("input");
                dom.setAttribute(el3,"type","file");
                dom.setAttribute(el3,"id","fileUpload");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n      ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1]);
                var element7 = dom.childAt(element6, [1, 0]);
                var element8 = dom.childAt(element6, [11, 1]);
                var morphs = new Array(7);
                morphs[0] = dom.createElementMorph(element7);
                morphs[1] = dom.createMorphAt(element6,5,5);
                morphs[2] = dom.createMorphAt(dom.childAt(element6, [7]),1,1);
                morphs[3] = dom.createMorphAt(element6,9,9);
                morphs[4] = dom.createElementMorph(element8);
                morphs[5] = dom.createMorphAt(element6,13,13);
                morphs[6] = dom.createMorphAt(element6,15,15);
                return morphs;
              },
              statements: [
                ["element","action",["toChoose"],[],["loc",[null,[123,69],[123,90]]]],
                ["block","if",[["subexpr","list-not-empty",[["get","breads",["loc",[null,[126,32],[126,38]]]]],[],["loc",[null,[126,16],[126,39]]]]],[],0,null,["loc",[null,[126,10],[141,17]]]],
                ["block","each",[["get","folders",["loc",[null,[144,20],[144,27]]]]],[],1,null,["loc",[null,[144,12],[146,21]]]],
                ["block","each",[["get","uploads",["loc",[null,[149,18],[149,25]]]]],[],2,null,["loc",[null,[149,10],[168,19]]]],
                ["element","action",["doUpload"],["on","change"],["loc",[null,[171,47],[171,80]]]],
                ["block","if",[["get","fileuploadInProgress",["loc",[null,[174,16],[174,36]]]]],[],3,null,["loc",[null,[174,10],[178,17]]]],
                ["block","if",[["get","fileuploadInProgress",["loc",[null,[180,16],[180,36]]]]],[],4,5,["loc",[null,[180,10],[184,17]]]]
              ],
              locals: [],
              templates: [child0, child1, child2, child3, child4, child5]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 121,
                  "column": 6
                },
                "end": {
                  "line": 187,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/digitalhelper.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","view",["loc",[null,[121,20],[121,24]]]],"upload"],[],["loc",[null,[121,16],[121,34]]]]],[],0,null,["loc",[null,[121,6],[187,6]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 62,
                "column": 6
              },
              "end": {
                "line": 187,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/digitalhelper.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","view",["loc",[null,[62,20],[62,24]]]],"profilePic"],[],["loc",[null,[62,16],[62,38]]]]],[],0,1,["loc",[null,[62,6],[187,6]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 4
            },
            "end": {
              "line": 189,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/digitalhelper.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","progressBar");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","bar");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","patientDetails");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","picture");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("figure");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","sidebar-card");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h1");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","subName");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"id","customerLabel");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element27 = dom.childAt(fragment, [3]);
          var element28 = dom.childAt(element27, [1, 1]);
          var element29 = dom.childAt(element27, [3]);
          var element30 = dom.childAt(element29, [1]);
          var element31 = dom.childAt(element29, [3]);
          var element32 = dom.childAt(element29, [5]);
          var element33 = dom.childAt(element29, [7, 1]);
          var morphs = new Array(11);
          morphs[0] = dom.createAttrMorph(element28, 'class');
          morphs[1] = dom.createAttrMorph(element28, 'style');
          morphs[2] = dom.createAttrMorph(element30, 'class');
          morphs[3] = dom.createMorphAt(dom.childAt(element30, [1]),1,1);
          morphs[4] = dom.createMorphAt(element31,0,0);
          morphs[5] = dom.createMorphAt(element31,2,2);
          morphs[6] = dom.createMorphAt(element32,0,0);
          morphs[7] = dom.createMorphAt(element32,2,2);
          morphs[8] = dom.createMorphAt(element33,1,1);
          morphs[9] = dom.createMorphAt(element33,3,3);
          morphs[10] = dom.createMorphAt(fragment,5,5,contextualElement);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["cursor frame ",["subexpr","css-bool-evaluator",[["subexpr","or",[["get","patient.sold",["loc",[null,[28,63],[28,75]]]],["get","patient.dead",["loc",[null,[28,76],[28,88]]]]],[],["loc",[null,[28,59],[28,89]]]],"dead",""],[],["loc",[null,[28,38],[28,101]]]]]]],
          ["attribute","style",["concat",["background-image: url(",["subexpr","get-avatar",[["get","patient.picture",["loc",[null,[28,145],[28,160]]]]],[],["loc",[null,[28,132],[28,162]]]],");"]]],
          ["attribute","class",["concat",["avatar cursor ",["get","patient.gender.id",["loc",[null,[32,38],[32,55]]]]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","patient.category.id",["loc",[null,[34,34],[34,53]]]],"misc"],[],["loc",[null,[34,30],[34,61]]]]],[],["loc",[null,[34,25],[34,62]]]],["get","patient.category",["loc",[null,[34,63],[34,79]]]]],[],["loc",[null,[34,20],[34,80]]]]],[],0,1,["loc",[null,[34,14],[39,21]]]],
          ["block","if",[["get","patient.dead",["loc",[null,[42,20],[42,32]]]]],[],2,null,["loc",[null,[42,14],[42,99]]]],
          ["content","patient.name",["loc",[null,[42,100],[42,116]]]],
          ["content","patient.category.name",["loc",[null,[44,29],[44,54]]]],
          ["block","if",[["get","patient.digitalRace",["loc",[null,[44,61],[44,80]]]]],[],3,4,["loc",[null,[44,55],[45,91]]]],
          ["content","patient.customer.firstname",["loc",[null,[49,14],[49,44]]]],
          ["content","patient.customer.lastname",["loc",[null,[49,45],[49,74]]]],
          ["block","if",[["subexpr","eq",[["get","view",["loc",[null,[55,16],[55,20]]]],"choose"],[],["loc",[null,[55,12],[55,30]]]]],[],5,6,["loc",[null,[55,6],[187,13]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 195,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/digitalhelper.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n  html, body {\n    height: 100%;\n  }\n  html, body > .ember-view {\n    height: 100%;\n  }\n  body {\n    background-position: center center;\n    background-size: cover;\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","digitalHelper");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"id","innerWrapper");
        dom.setAttribute(el2,"class","card clearfix");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/assistant.svg");
        dom.setAttribute(el5,"style","margin-right: 7px");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Digitaler Assistant");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2, 1]),3,3);
        return morphs;
      },
      statements: [
        ["content","style",["loc",[null,[11,4],[11,13]]]],
        ["block","if",[["get","error",["loc",[null,[19,10],[19,15]]]]],[],0,1,["loc",[null,[19,4],[189,11]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});