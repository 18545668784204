define('jason-frontend/templates/components/button-lab-top', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 5,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/button-lab-top.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("path");
          dom.setAttribute(el1,"class","outerCircle");
          dom.setAttribute(el1,"d","M16,31 C24.2842713,31 31,24.2842713 31,16 C31,7.71572875 24.2842713,1 16,1 C7.71572875,1 1,7.71572875 1,16 C1,24.2842713 7.71572875,31 16,31 L16,31 L16,31 Z M16,29.5714286 C8.50470696,29.5714286 2.42857143,23.495293 2.42857143,16 C2.42857143,8.50470696 8.50470696,2.42857143 16,2.42857143 C23.495293,2.42857143 29.5714286,8.50470696 29.5714286,16 C29.5714286,23.495293 23.495293,29.5714286 16,29.5714286 L16,29.5714286 L16,29.5714286 Z");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-lab-top.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 34 34");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(1 1)");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","translate(6,6) scale(0.06,0.06)");
        dom.setAttribute(el3,"fill-rule","evenodd");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"class","icon");
        dom.setAttribute(el4,"d","M261.673,65.562l29.441-29.441L254.993,0l-29.441,29.441l-15.508-15.508L77.749,146.228l67.138,67.138l87.326-87.326\n	c28.395,34.831,26.369,86.358-6.079,118.805c-29.412,29.413-74.494,33.813-108.616,13.232h26.284v-15H54.189v15h38.538\n	c19.858,18.474,45.247,27.718,70.64,27.718c11.251,0,22.5-1.821,33.27-5.447l15.347,49.957h-85.118l13.802-46.463l-14.379-4.271\n	l-19.527,65.734h125.521l-21.749-70.799c9.449-4.813,18.312-11.16,26.206-19.054c38.3-38.3,40.335-99.334,6.121-140.061l34.32-34.32\n	L261.673,65.562z M236.158,40.047l18.834-18.834l14.909,14.908l-18.834,18.835l-7.454-7.454L236.158,40.047z M233.006,58.108\n	l18.061,18.061l0,0l4.902,4.902l-82.623,82.623l-45.925-45.925l82.623-82.623L233.006,58.108z M98.961,146.228l17.853-17.853\n	l45.925,45.925l-17.853,17.853L98.961,146.228z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element0, 'class');
        morphs[3] = dom.createAttrMorph(element1, 'fill');
        morphs[4] = dom.createMorphAt(element1,1,1);
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","class",["concat",["state-button cursor ",["subexpr","css-bool-evaluator",[["get","selected",["loc",[null,[1,143],[1,151]]]],"active",""],[],["loc",[null,[1,122],[1,165]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[2,13],[2,18]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,10],[3,20]]]]],[],0,null,["loc",[null,[3,4],[5,11]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});