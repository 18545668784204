define('jason-frontend/helpers/get-absolute-discount-class', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getAbsoluteDiscountClass = getAbsoluteDiscountClass;

  function getAbsoluteDiscountClass(params) {
    if (params[0] == true) {
      return "hover";
    }
    return "";
  }

  exports['default'] = Ember['default'].Helper.helper(getAbsoluteDiscountClass);

});