define('jason-frontend/helpers/truncate', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.truncate = truncate;

  function truncate(params) {
    var limit = params[1] || 46;
    var text = params[0];
    if (text && text.length > limit) {
      if (limit > 1) {
        text = text.substr(0, limit - 3) + "...";
      } else {
        text = text.substr(0, 1);
      }
    }
    return text;
  }

  exports['default'] = Ember['default'].Helper.helper(truncate);

});