define('jason-frontend/templates/components/button-ehapo2', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 5,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/button-ehapo2.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"class","circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","16");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'fill');
          morphs[1] = dom.createAttrMorph(element0, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","fill",["concat",[["get","color",["loc",[null,[4,60],[4,65]]]]]]],
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[4,79],[4,84]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-ehapo2.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 34 34");
        dom.setAttribute(el1,"style","margin-top: 2px");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(1 1)");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","translate(3,3) scale(0.42,0.44)");
        dom.setAttribute(el3,"fill-rule","evenodd");
        dom.setAttribute(el3,"class","icon-wrapper");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("rect");
        dom.setAttribute(el4,"x","37.631");
        dom.setAttribute(el4,"y","8.657");
        dom.setAttribute(el4,"fill","#fff");
        dom.setAttribute(el4,"width","6.236");
        dom.setAttribute(el4,"height","35.994");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"fill","#fff");
        dom.setAttribute(el4,"d","M38.842,8.657");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("line");
        dom.setAttribute(el4,"fill","#fff");
        dom.setAttribute(el4,"x1","38.842");
        dom.setAttribute(el4,"y1","13.667");
        dom.setAttribute(el4,"x2","38.842");
        dom.setAttribute(el4,"y2","8.657");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("polyline");
        dom.setAttribute(el4,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el4,"fill","#fff");
        dom.setAttribute(el4,"points","9.537,44.651 37.651,8.669 42.879,12.754 17.942,44.675 ");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"fill","#fff");
        dom.setAttribute(el4,"d","M38.842,13.667");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"fill","#fff");
        dom.setAttribute(el4,"d","M9.745,45.208");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"fill","#fff");
        dom.setAttribute(el4,"d","M10.479,44.651");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("rect");
        dom.setAttribute(el4,"x","31.704");
        dom.setAttribute(el4,"y","31.622");
        dom.setAttribute(el4,"fill","#fff");
        dom.setAttribute(el4,"width","18.092");
        dom.setAttribute(el4,"height","6.236");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("line");
        dom.setAttribute(el4,"fill","#fff");
        dom.setAttribute(el4,"x1","2.612");
        dom.setAttribute(el4,"y1","44.319");
        dom.setAttribute(el4,"x2","9.072");
        dom.setAttribute(el4,"y2","44.2");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createAttrMorph(element1, 'class');
        morphs[3] = dom.createMorphAt(element2,1,1);
        morphs[4] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","class",["concat",["laboklin-status-",["get","cssClasses",["loc",[null,[1,120],[1,130]]]]," ",["get","cssClass",["loc",[null,[1,135],[1,143]]]]," state-button cursor jason ",["subexpr","css-bool-evaluator",[["get","selected",["loc",[null,[1,193],[1,201]]]],"active",""],[],["loc",[null,[1,172],[1,215]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,10],[3,20]]]]],[],0,null,["loc",[null,[3,4],[5,11]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[6,15],[6,20]]]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});