define('jason-frontend/components/button-toggle', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    'class': '',
    size: 32,
    side: 'top',
    entry: null,
    timer: 0,
    content: 'erweitern',
    theme: 'tooltipster-punk',
    actions: {
      toggle: function toggle(entry) {
        if (this.get('class') === '') {
          this.set('class', 'reduce');
        } else {
          this.set('class', '');
        }
        this.sendAction('toggle', entry);
      }
    }
  });

});