define('jason-frontend/models/mandant-dashboard', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    mandant: DS['default'].attr(),
    sumCash: DS['default'].attr('number'),
    sumPurchases: DS['default'].attr('number'),
    sumCommission: DS['default'].attr('number'),
    sumJasonNetworkFee: DS['default'].attr('number'),
    sumCashback: DS['default'].attr('number'),
    sumFee: DS['default'].attr('number')
  });

});