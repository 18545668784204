define('jason-frontend/helpers/all-sub-orders-confirmed', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.allSubOrdersConfirmed = allSubOrdersConfirmed;

  function allSubOrdersConfirmed(params) {
    var order = params[0],
        oneConfirmed = false;

    return oneConfirmed;
  }

  exports['default'] = Ember['default'].Helper.helper(allSubOrdersConfirmed);

});