define('jason-frontend/components/layerplan-edit-form', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      save: function save(id) {
        this.sendAction('save', id);
      }
    }
  });

});