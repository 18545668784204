define('jason-frontend/routes/inventory/bookin-request', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model() {},
    setupController: function setupController(controller, model, params) {
      var id = params.params['inventory.bookin-request'].id,
          type = params.params['inventory.bookin-request'].type,
          stocktakingId = params.queryParams.stocktakingId;

      var self = this;
      $.ajax({
        url: "/api/inventoryRequests/" + id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('model', data.inventoryRequestSupplier);

        if (data.inventoryRequestSupplier.entries == null) {} else {
          setTimeout(function () {

            $('html, body').animate({
              scrollTop: $('#entry_' + data.inventoryRequestSupplier.latestAdded).offset().top
            }, 250);
          }, 500);
        }
      }, function () {});

      this.store.find('serviceunit', { paged: false }).then(function (items) {
        controller.set('serviceunits', items.sortBy('name'));
      });

      controller.set('warehouses', this.store.find('inventory-warehouse'));
    },
    actions: {
      copy: function copy(field, entry) {
        if (field === 'charge') {
          (function () {
            var firstValue = undefined;
            entry.packageEntries.forEach(function (elem) {
              if (!firstValue) {
                firstValue = elem.chargeNumber;
              } else {
                Ember['default'].set(elem, 'chargeNumber', firstValue);
              }
            });
          })();
        } else if (field === 'date') {
          (function () {
            var firstValue = undefined;
            entry.packageEntries.forEach(function (elem) {
              if (!firstValue) {
                firstValue = elem.expiryDate;
              } else {
                Ember['default'].set(elem, 'expiryDate', firstValue);
              }
            });
          })();
        }
      },
      changeWarehouse: function changeWarehouse(wh) {
        var controller = this.get('controller');

        var data = {
          warehouseId: wh.id
        };

        $.ajax({
          url: "/api/inventoryRequests/changeWarehouse/" + this.get('controller').get('model').id,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('model', data.inventoryRequestSupplier);
        }, function () {});
      },
      backToStocktaking: function backToStocktaking(id) {
        this.transitionTo('inventory.stocktaking-detail', id);
      },
      back: function back() {
        this.transitionTo('inventory.select-order');
      },
      toAddProduct: function toAddProduct(requestId) {
        var self = this,
            timeout = 0;

        setTimeout(function () {
          self.transitionTo('cash.add_article', 'inventoryRequests', requestId, 0);
        }, timeout);
      },
      removeItem: function removeItem(requestId, entryId) {

        var self = this,
            controller = this.get('controller');

        var data = {
          id: entryId
        };

        $.ajax({
          url: "/api/inventoryRequests/removeProduct/" + requestId,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('model', data.inventoryRequestSupplier);

          if (data.inventoryRequestSupplier.referer === 'corrections' && data.inventoryRequestSupplier.entries.length == 0) {
            self.transitionTo('inventory.select-order');
          }

          if (data.inventoryRequestSupplier.entries == null) {
            self.transitionTo('cash.add_article', 'inventoryRequests', id, 0);
          }
        }, function () {});
      },
      bookinItem: function bookinItem(data, supplierRow, requestId) {
        var self = this,
            controller = this.get('controller');
        data.deliveryDay = supplierRow.deliveryDay;
        data.deliveryTime = supplierRow.deliveryTime;

        var row = $('div#entry_' + data.id),
            qty = row.find('input[name="packageQuantity"]').val();

        Ember['default'].set(data, 'packageQuantity', qty);

        $.each(data.packageEntries, function (index, value) {
          Ember['default'].set(value, 'shouldStand', 0); //new String(qty).replace(',', '.')
        });

        $.ajax({
          url: "/api/inventoryRequests/bookin/" + requestId,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Eingebucht', 'Lagerbestand eingebucht und Etikett erzeugt');
          controller.set('model', data.inventoryRequestSupplier);
        }, function () {});
      },
      printBarcode: function printBarcode(id) {
        var viewUrl = '/api/inventoryRequests/barcodes/' + id + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken();

        var myWindow = window.open(viewUrl, 'Drucken', 'width=600,height=600');

        var is_chrome = Boolean(window.chrome);
        if (is_chrome) {
          myWindow.onload = function () {

            myWindow.moveTo(0, 0);
            myWindow.resizeTo(640, 480);
            myWindow.print();
          };
        } else {
          myWindow.print();
          myWindow.close();
        }
      },
      printAllBarcodes: function printAllBarcodes(id, entryId) {

        var viewUrl = '/api/inventoryRequests/barcodes/all/' + id + '/' + entryId + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken();

        var myWindow = window.open(viewUrl, 'Drucken', 'width=600,height=600');

        var is_chrome = Boolean(window.chrome);
        if (is_chrome) {
          myWindow.onload = function () {

            myWindow.moveTo(0, 0);
            myWindow.resizeTo(640, 480);
            myWindow.print();
          };
        } else {
          myWindow.print();
          myWindow.close();
        }
      }
    }
  });

});