define('jason-frontend/routes/patients/appointment-categories', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    perPage: 10,
    queryParams: {},
    setupController: function setupController(controller, model) {
      controller.set("model", model);

      this.search();
    },
    search: function search() {
      var controller = this.get('controller');
      this.findPaged('patient-appointment-category', {}).then(function (data) {
        controller.set('model', data);
      });
    },
    actions: {
      "delete": function _delete(id) {
        var self = this;
        $.ajax({
          url: "/api/patientAppointmentCategories/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.refresh();
          self.search();
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
        }, function (error) {
          self.refresh();
          self.search();
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
        });
      }
    }
  });

});