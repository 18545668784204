define('jason-frontend/controllers/practicemgmt/admission', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Übersicht",
    breadCrumbPath: "practicemgmt.admission",
    type: 'all',
    showAll: true,
    showAdmission: false,
    showWaiting: false,
    showTreatment: false,
    showPayment: false,
    showStation: false,
    showTopTiles: false,
    waitingroomItems: null,
    treatmentItems: null,
    upcomingItems: null,
    stationItems: null,
    mainView: 'splitted',
    selectedUsers: Array(),
    changeTrigger: Date(),
    actions: {
      openTime: function openTime(treatment) {
        this.get('selectAppTime').send('load', treatment);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-select-app-time'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openExportInvoicePanel: function openExportInvoicePanel(tpId) {

        var self = this;

        $.ajax({
          url: "/api/treatmentPatients/" + tpId + "/checkInvoices",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          if (data.treatmentPatientInvoice && data.treatmentPatientInvoice.invoices.length > 0) {
            $.magnificPopup.open({
              removalDelay: 500,
              closeOnBgClick: false,
              items: {
                src: '#modal-export-invoice-panel-panel'
              },
              callbacks: {
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: true
            });

            self.get('exportInvoicePanel').send('load', tpId, data.treatmentPatientInvoice, data.treatmentPatientInvoice.mode);
          } else {
            self.send('exportInvoice', tpId);
          }
        });
      },
      quickScan: function quickScan() {

        this.get('quickScanEtiga').send('load');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-etiga-quick-scan-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      editTreatmentInfo: function editTreatmentInfo(info) {
        this.get('infoTreatmentPanel').send('load', info);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-treatment-infos-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    }
  });

});