define('jason-frontend/helpers/compare', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.compare = compare;

  function compare(params) {
    return params[0] == params[1];
  }

  exports['default'] = Ember['default'].Helper.helper(compare);

});