define('jason-frontend/templates/components/notifications-timeline', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 8,
                  "column": 12
                },
                "end": {
                  "line": 8,
                  "column": 140
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-myjason",[],["content","","color","#008EC6","cssClass","inverted","classNames","inline-icon"],["loc",[null,[8,49],[8,140]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 12
                },
                "end": {
                  "line": 9,
                  "column": 103
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-ehapo2",[],["content","","classNames","inline-icon"],["loc",[null,[9,49],[9,103]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 11,
                    "column": 14
                  },
                  "end": {
                    "line": 13,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-lab",[],["content","","fill","#fff","cssClass","invertedZoetis","classNames","invertedZoetis inline-icon"],["loc",[null,[12,16],[12,120]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 14,
                      "column": 16
                    },
                    "end": {
                      "line": 16,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-lab",[],["content","","cssClass","inverted","classNames","inline-icon"],["loc",[null,[15,18],[15,89]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 16,
                      "column": 16
                    },
                    "end": {
                      "line": 18,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-lab",[],["content","","fill","#fff","cssClass","invertedLaboklin","classNames","invertedLaboklin inline-icon"],["loc",[null,[17,18],[17,126]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 13,
                    "column": 14
                  },
                  "end": {
                    "line": 19,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[14,26],[14,36]]]],"Interner Untersuchungsauftrag verfügbar"],[],["loc",[null,[14,22],[14,79]]]]],[],0,1,["loc",[null,[14,16],[18,23]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 12
                },
                "end": {
                  "line": 20,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.cssClass",["loc",[null,[11,24],[11,37]]]],"zoetis"],[],["loc",[null,[11,20],[11,47]]]]],[],0,1,["loc",[null,[11,14],[19,21]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 12
                },
                "end": {
                  "line": 21,
                  "column": 127
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-invoice",[],["content","","cssClass","inverted","classNames","inline-icon"],["loc",[null,[21,51],[21,127]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 12
                },
                "end": {
                  "line": 22,
                  "column": 126
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-patient",[],["content","","classNames","inline-icon","cssClass","inverted"],["loc",[null,[22,52],[22,126]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 12
                },
                "end": {
                  "line": 23,
                  "column": 126
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-patient",[],["content","","classNames","inline-icon","cssClass","inverted"],["loc",[null,[23,52],[23,126]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child6 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 12
                },
                "end": {
                  "line": 24,
                  "column": 251
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-etiga-small",[],["content","","classNames","inline-icon","cssClass",""],["loc",[null,[24,181],[24,251]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child7 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 16
                },
                "end": {
                  "line": 31,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"class","img-responsive mw50 ib");
              dom.setAttribute(el1,"title","user");
              dom.setAttribute(el1,"src","assets/images/vetconnect.png");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child8 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 31,
                    "column": 16
                  },
                  "end": {
                    "line": 33,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"class","img-responsive mw50 ib");
                dom.setAttribute(el1,"title","user");
                dom.setAttribute(el1,"src","assets/images/scilvip.png");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 33,
                      "column": 16
                    },
                    "end": {
                      "line": 35,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"class","img-responsive mw50 ib");
                  dom.setAttribute(el1,"title","user");
                  dom.setAttribute(el1,"src","assets/images/laboklin.png");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 35,
                        "column": 16
                      },
                      "end": {
                        "line": 37,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","cloudinary-tag",[["get","item.sender.picture.cdnId",["loc",[null,[36,35],[36,60]]]]],["crop","fit","class","img-responsive mw50 ib"],["loc",[null,[36,18],[36,104]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 37,
                        "column": 16
                      },
                      "end": {
                        "line": 39,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1,"class","img-responsive mw50 ib");
                    dom.setAttribute(el1,"title","user");
                    dom.setAttribute(el1,"src","assets/images/users/default.png");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 35,
                      "column": 16
                    },
                    "end": {
                      "line": 39,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","item.sender.picture",["loc",[null,[35,26],[35,45]]]]],[],0,1,["loc",[null,[35,16],[39,16]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 33,
                    "column": 16
                  },
                  "end": {
                    "line": 39,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[33,30],[33,40]]]],"Untersuchungsauftrag verfügbar"],[],["loc",[null,[33,26],[33,74]]]]],[],0,1,["loc",[null,[33,16],[39,16]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 16
                },
                "end": {
                  "line": 39,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[31,30],[31,40]]]],"Interner Untersuchungsauftrag verfügbar"],[],["loc",[null,[31,26],[31,83]]]]],[],0,1,["loc",[null,[31,16],[39,16]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child9 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 44,
                    "column": 18
                  },
                  "end": {
                    "line": 45,
                    "column": 86
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("von: ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element22 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element22,1,1);
                morphs[1] = dom.createMorphAt(element22,3,3);
                return morphs;
              },
              statements: [
                ["content","item.sender.firstname",["loc",[null,[45,30],[45,55]]]],
                ["content","item.sender.lastname",["loc",[null,[45,56],[45,80]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 16
                },
                "end": {
                  "line": 46,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.sender",["loc",[null,[44,24],[44,35]]]]],[],0,null,["loc",[null,[44,18],[45,93]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child10 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 48,
                    "column": 18
                  },
                  "end": {
                    "line": 50,
                    "column": 117
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                      an: ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","button-text",[],["text",["subexpr","truncate",[["get","item.receiversString",["loc",[null,[50,55],[50,75]]]],200],[],["loc",[null,[50,45],[50,80]]]],"content",["subexpr","@mut",[["get","item.receiversString",["loc",[null,[50,89],[50,109]]]]],[],[]]],["loc",[null,[50,26],[50,111]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 16
                },
                "end": {
                  "line": 51,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.receiversString",["loc",[null,[48,24],[48,44]]]]],[],0,null,["loc",[null,[48,18],[50,124]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child11 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 56,
                        "column": 33
                      },
                      "end": {
                        "line": 56,
                        "column": 122
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","item.patient.name",["loc",[null,[56,101],[56,122]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 54,
                      "column": 20
                    },
                    "end": {
                      "line": 59,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createTextNode("\n                        Patient: ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ,\n                        BesitzerIn: ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element21 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(element21,1,1);
                  morphs[1] = dom.createMorphAt(element21,3,3);
                  morphs[2] = dom.createMorphAt(element21,5,5);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["patients.etiga",["get","item.patient.id",["loc",[null,[56,61],[56,76]]]]],["class","is-highlighted"],0,null,["loc",[null,[56,33],[56,134]]]],
                  ["content","item.customer.firstname",["loc",[null,[57,36],[57,63]]]],
                  ["content","item.customer.lastname",["loc",[null,[57,64],[57,90]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 61,
                        "column": 31
                      },
                      "end": {
                        "line": 61,
                        "column": 119
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","item.patient.name",["loc",[null,[61,98],[61,119]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 63,
                        "column": 34
                      },
                      "end": {
                        "line": 63,
                        "column": 157
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","item.customer.firstname",["loc",[null,[63,103],[63,130]]]],
                    ["content","item.customer.lastname",["loc",[null,[63,131],[63,157]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 59,
                      "column": 20
                    },
                    "end": {
                      "line": 65,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createTextNode("\n                      Patient: ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ,\n                      BesitzerIn: ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element20 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(element20,1,1);
                  morphs[1] = dom.createMorphAt(element20,3,3);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["patients.edit",["get","item.patient.id",["loc",[null,[61,58],[61,73]]]]],["class","is-highlighted"],0,null,["loc",[null,[61,31],[61,131]]]],
                  ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[63,62],[63,78]]]]],["class","is-highlighted"],1,null,["loc",[null,[63,34],[63,169]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 53,
                    "column": 18
                  },
                  "end": {
                    "line": 66,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","and",[["get","item.patient.etigaActive",["loc",[null,[54,31],[54,55]]]],["get","item.patient.etigaMaster",["loc",[null,[54,56],[54,80]]]]],[],["loc",[null,[54,26],[54,81]]]]],[],0,1,["loc",[null,[54,20],[65,27]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 52,
                  "column": 16
                },
                "end": {
                  "line": 67,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.patient",["loc",[null,[53,24],[53,36]]]]],[],0,null,["loc",[null,[53,18],[66,25]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child12 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 77,
                  "column": 14
                },
                "end": {
                  "line": 79,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child13 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 80,
                    "column": 16
                  },
                  "end": {
                    "line": 80,
                    "column": 205
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","reply-button pt2");
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","cursor");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element18 = dom.childAt(fragment, [0, 0]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element18);
                morphs[1] = dom.createMorphAt(element18,0,0);
                return morphs;
              },
              statements: [
                ["element","action",["reply",["get","item",["loc",[null,[80,133],[80,137]]]]],[],["loc",[null,[80,116],[80,139]]]],
                ["inline","button-reply",[],["color","#3B6182","size",30],["loc",[null,[80,155],[80,195]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 79,
                  "column": 14
                },
                "end": {
                  "line": 86,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","forward pt2");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","cursor");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element19 = dom.childAt(fragment, [3, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createElementMorph(element19);
              morphs[2] = dom.createMorphAt(element19,1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","and",[["get","item.withinPractice",["loc",[null,[80,27],[80,46]]]],["subexpr","not",[["subexpr","eq",[["get","item.category.id",["loc",[null,[80,56],[80,72]]]],"app"],[],["loc",[null,[80,52],[80,79]]]]],[],["loc",[null,[80,47],[80,80]]]]],[],["loc",[null,[80,22],[80,81]]]]],[],0,null,["loc",[null,[80,16],[80,212]]]],
              ["element","action",["forward",["get","item",["loc",[null,[82,40],[82,44]]]]],[],["loc",[null,[82,21],[82,46]]]],
              ["inline","button-forward",[],["color","#3B6182","size",30],["loc",[null,[83,20],[83,62]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child14 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 87,
                  "column": 14
                },
                "end": {
                  "line": 98,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","unreadIcon");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","cursor");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","unreadForAllIcon");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","cursor");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element16 = dom.childAt(fragment, [1, 1]);
              var element17 = dom.childAt(fragment, [3, 1]);
              var morphs = new Array(4);
              morphs[0] = dom.createElementMorph(element16);
              morphs[1] = dom.createMorphAt(element16,1,1);
              morphs[2] = dom.createElementMorph(element17);
              morphs[3] = dom.createMorphAt(element17,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["markAsRead",["get","item",["loc",[null,[89,43],[89,47]]]]],[],["loc",[null,[89,21],[89,49]]]],
              ["inline","button-checked",[],["cssClasses","checked-button","size",30,"content","Als gelesen markieren"],["loc",[null,[90,20],[90,106]]]],
              ["element","action",["markAsReadForAll",["get","item",["loc",[null,[94,49],[94,53]]]]],[],["loc",[null,[94,21],[94,55]]]],
              ["inline","button-all-checked",[],["classNames","checked-button inline-icon","color","#3B6182","size",30,"content","Für alle (anderen MitarbeiterInnen) als gelesen markieren"],["loc",[null,[95,20],[95,174]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child15 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 99,
                    "column": 16
                  },
                  "end": {
                    "line": 105,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","delete pt2");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","cursor");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","button-delete-with-confirm-full",[],["item",["subexpr","@mut",[["get","item",["loc",[null,[102,61],[102,65]]]]],[],[]],"confirmed","delete","size",30,"text","Wollen Sie diese Benachrichtigung wirklich löschen?"],["loc",[null,[102,22],[102,153]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 98,
                  "column": 14
                },
                "end": {
                  "line": 106,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","or",[["subexpr","eq",[["get","type",["loc",[null,[99,30],[99,34]]]],"in"],[],["loc",[null,[99,26],[99,40]]]],["subexpr","eq",[["get","type",["loc",[null,[99,45],[99,49]]]],"unread"],[],["loc",[null,[99,41],[99,59]]]]],[],["loc",[null,[99,22],[99,60]]]]],[],0,null,["loc",[null,[99,16],[105,23]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child16 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 110,
                      "column": 58
                    },
                    "end": {
                      "line": 110,
                      "column": 96
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("von ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.readBy",["loc",[null,[110,81],[110,96]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 110,
                      "column": 104
                    },
                    "end": {
                      "line": 110,
                      "column": 174
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("am ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","time-format",[["get","item.readOn",["loc",[null,[110,140],[110,151]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[110,126],[110,174]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 110,
                    "column": 14
                  },
                  "end": {
                    "line": 110,
                    "column": 181
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("gelesen ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.readby",["loc",[null,[110,64],[110,75]]]]],[],0,null,["loc",[null,[110,58],[110,103]]]],
                ["block","if",[["get","item.readOn",["loc",[null,[110,110],[110,121]]]]],[],1,null,["loc",[null,[110,104],[110,181]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 108,
                  "column": 12
                },
                "end": {
                  "line": 112,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","readInfo small italic");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","or",[["get","item.readby",["loc",[null,[110,24],[110,35]]]],["get","item.readOn",["loc",[null,[110,36],[110,47]]]]],[],["loc",[null,[110,20],[110,48]]]]],[],0,null,["loc",[null,[110,14],[110,188]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child17 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 119,
                      "column": 16
                    },
                    "end": {
                      "line": 120,
                      "column": 39
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  Mit eltiga verknüpfen");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 117,
                    "column": 14
                  },
                  "end": {
                    "line": 121,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                return morphs;
              },
              statements: [
                ["block","link-to",["patients.etiga",["get","item.patient.id",["loc",[null,[119,44],[119,59]]]]],["class","mt10 mb10 label inline-block button purple-style p5"],0,null,["loc",[null,[119,16],[120,51]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 116,
                  "column": 12
                },
                "end": {
                  "line": 122,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","not",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[117,29],[117,47]]]],"patientetiga"],[],["loc",[null,[117,25],[117,63]]]]],[],["loc",[null,[117,20],[117,64]]]]],[],0,null,["loc",[null,[117,14],[121,21]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child18 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 126,
                      "column": 35
                    },
                    "end": {
                      "line": 126,
                      "column": 210
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.referenceData.reactedUser.firstname",["loc",[null,[126,122],[126,166]]]],
                  ["content","item.referenceData.reactedUser.lastname",["loc",[null,[126,167],[126,210]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 125,
                    "column": 16
                  },
                  "end": {
                    "line": 127,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  Termin wurde von ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" am ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                dom.setAttribute(el1,"class","is-success");
                var el2 = dom.createTextNode("bestätigt");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                return morphs;
              },
              statements: [
                ["block","link-to",["users.edit",["get","item.referenceData.reactedUser.id",["loc",[null,[126,59],[126,92]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[126,35],[126,222]]]],
                ["inline","time-format",[["get","item.referenceData.reactedDate",["loc",[null,[126,240],[126,270]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[126,226],[126,293]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 128,
                        "column": 35
                      },
                      "end": {
                        "line": 128,
                        "column": 210
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","item.referenceData.reactedUser.firstname",["loc",[null,[128,122],[128,166]]]],
                    ["content","item.referenceData.reactedUser.lastname",["loc",[null,[128,167],[128,210]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 127,
                      "column": 16
                    },
                    "end": {
                      "line": 129,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  Termin wurde von ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" am ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  dom.setAttribute(el1,"class","is-warning");
                  var el2 = dom.createTextNode("abgelehnt");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["users.edit",["get","item.referenceData.reactedUser.id",["loc",[null,[128,59],[128,92]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[128,35],[128,222]]]],
                  ["inline","time-format",[["get","item.referenceData.reactedDate",["loc",[null,[128,240],[128,270]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[128,226],[128,293]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 129,
                        "column": 16
                      },
                      "end": {
                        "line": 135,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","addAdmission");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","icon-button icon-button--small icon-button--success");
                    var el3 = dom.createTextNode("\n                      ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                    ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element15 = dom.childAt(fragment, [1, 1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element15);
                    morphs[1] = dom.createMorphAt(element15,1,1);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openAppRequestConfirmation",["get","item",["loc",[null,[131,61],[131,65]]]]],[],["loc",[null,[131,23],[131,67]]]],
                    ["inline","button-calendar",[],["showStroke",false,"content","Terminanfrage beantworten","color","#FFFFFF","size","48"],["loc",[null,[132,22],[132,120]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 129,
                      "column": 16
                    },
                    "end": {
                      "line": 135,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","item.referenceData.status",["loc",[null,[129,30],[129,55]]]],"requested"],[],["loc",[null,[129,26],[129,68]]]]],[],0,null,["loc",[null,[129,16],[135,16]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 127,
                    "column": 16
                  },
                  "end": {
                    "line": 135,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.referenceData.status",["loc",[null,[127,30],[127,55]]]],"aborted"],[],["loc",[null,[127,26],[127,66]]]]],[],0,1,["loc",[null,[127,16],[135,16]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 123,
                  "column": 14
                },
                "end": {
                  "line": 137,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.referenceData.status",["loc",[null,[125,26],[125,51]]]],"booked"],[],["loc",[null,[125,22],[125,61]]]]],[],0,1,["loc",[null,[125,16],[135,23]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child19 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 142,
                    "column": 36
                  },
                  "end": {
                    "line": 142,
                    "column": 157
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.referenceData.patient.name",["loc",[null,[142,122],[142,157]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 146,
                    "column": 35
                  },
                  "end": {
                    "line": 146,
                    "column": 205
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.referenceData.customer.firstname",["loc",[null,[146,123],[146,164]]]],
                ["content","item.referenceData.customer.lastname",["loc",[null,[146,165],[146,205]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 153,
                    "column": 16
                  },
                  "end": {
                    "line": 153,
                    "column": 123
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("VPE: ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.referenceData.unitQuantity",["loc",[null,[153,60],[153,95]]]],
                ["content","item.referenceData.unit",["loc",[null,[153,96],[153,123]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 154,
                    "column": 16
                  },
                  "end": {
                    "line": 159,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  Lieferant: ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createTextNode("Diese Menge wurde automatisch in den ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","is-highlighted cursor");
                var el3 = dom.createTextNode("Praxis Warenkorb");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" gelegt.");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element12 = dom.childAt(fragment, [7, 1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[1] = dom.createElementMorph(element12);
                return morphs;
              },
              statements: [
                ["content","item.referenceData.reorderSupplier",["loc",[null,[156,29],[156,67]]]],
                ["element","action",["gotoPracticeCart"],[],["loc",[null,[158,91],[158,120]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 159,
                    "column": 16
                  },
                  "end": {
                    "line": 161,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("<p>Für das Produkt konnte keine eindeutige Nachbestellungsinformation gefunden werden. Bitte führen Sie eine manuelle Bestellung durch.</p>");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child5 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 162,
                    "column": 20
                  },
                  "end": {
                    "line": 162,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Anmerkung: ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),2,2);
                return morphs;
              },
              statements: [
                ["content","item.referenceData.notice",["loc",[null,[162,85],[162,114]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 139,
                  "column": 14
                },
                "end": {
                  "line": 164,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","p10 mt10");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("b");
              var el3 = dom.createTextNode("Patient:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    Spezies: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    Geschlecht: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("b");
              var el3 = dom.createTextNode("BesitzerIn:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    Name: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    Adresse: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(", ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    Telefon: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                 Produkt: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","is-highlighted cursor");
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                 Menge: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element13 = dom.childAt(fragment, [1]);
              var element14 = dom.childAt(element13, [37]);
              var morphs = new Array(16);
              morphs[0] = dom.createMorphAt(element13,3,3);
              morphs[1] = dom.createMorphAt(element13,6,6);
              morphs[2] = dom.createMorphAt(element13,9,9);
              morphs[3] = dom.createMorphAt(element13,16,16);
              morphs[4] = dom.createMorphAt(element13,21,21);
              morphs[5] = dom.createMorphAt(element13,23,23);
              morphs[6] = dom.createMorphAt(element13,26,26);
              morphs[7] = dom.createMorphAt(element13,28,28);
              morphs[8] = dom.createMorphAt(element13,30,30);
              morphs[9] = dom.createMorphAt(element13,33,33);
              morphs[10] = dom.createElementMorph(element14);
              morphs[11] = dom.createMorphAt(element14,1,1);
              morphs[12] = dom.createMorphAt(element13,40,40);
              morphs[13] = dom.createMorphAt(element13,43,43);
              morphs[14] = dom.createMorphAt(element13,45,45);
              morphs[15] = dom.createMorphAt(element13,47,47);
              return morphs;
            },
            statements: [
              ["block","link-to",["patients.edit",["get","item.referenceData.patient.id",["loc",[null,[142,63],[142,92]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[142,36],[142,169]]]],
              ["content","item.referenceData.patient.category.name",["loc",[null,[143,29],[143,73]]]],
              ["content","item.referenceData.patient.gender.name",["loc",[null,[144,32],[144,74]]]],
              ["block","link-to",["customers.edit",["get","item.referenceData.customer.id",["loc",[null,[146,63],[146,93]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[146,35],[146,217]]]],
              ["content","item.referenceData.customer.firstname",["loc",[null,[148,26],[148,67]]]],
              ["content","item.referenceData.customer.lastname",["loc",[null,[148,68],[148,108]]]],
              ["content","item.referenceData.customer.street",["loc",[null,[149,29],[149,67]]]],
              ["content","item.referenceData.customer.postalCode",["loc",[null,[149,69],[149,111]]]],
              ["content","item.referenceData.customer.town",["loc",[null,[149,112],[149,148]]]],
              ["content","item.referenceData.customer.phone",["loc",[null,[150,29],[150,66]]]],
              ["element","action",["gotoPurchaseProduct",["get","item.referenceData.articleId",["loc",[null,[151,90],[151,118]]]]],[],["loc",[null,[151,59],[151,120]]]],
              ["content","item.referenceData.articleName",["loc",[null,[151,122],[151,156]]]],
              ["content","item.referenceData.amount",["loc",[null,[152,24],[152,53]]]],
              ["block","if",[["get","item.referenceData.unitQuantity",["loc",[null,[153,22],[153,53]]]]],[],2,null,["loc",[null,[153,16],[153,130]]]],
              ["block","if",[["get","item.referenceData.added",["loc",[null,[154,22],[154,46]]]]],[],3,4,["loc",[null,[154,16],[161,23]]]],
              ["block","if",[["get","item.referenceData.notice",["loc",[null,[162,26],[162,51]]]]],[],5,null,["loc",[null,[162,20],[162,125]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5]
          };
        }());
        var child20 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 166,
                  "column": 14
                },
                "end": {
                  "line": 171,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","inline-block button purple-style");
              var el2 = dom.createTextNode("\n                    zur ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [4]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element11);
              morphs[1] = dom.createMorphAt(element11,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["gotoPatient",["get","item.patient.id",["loc",[null,[168,42],[168,57]]]]],[],["loc",[null,[168,19],[168,59]]]],
              ["inline","icon-etiga",[],["color","ffffff","content","","classNames","inline-icon middle","size","22","width",50],["loc",[null,[169,24],[169,115]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child21 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 175,
                    "column": 18
                  },
                  "end": {
                    "line": 177,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createTextNode("Zum Dokument");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[176,20],[176,102]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 173,
                  "column": 16
                },
                "end": {
                  "line": 179,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","reference");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","link-to",["patients.edit",["get","item.patient.id",["loc",[null,[175,45],[175,60]]]],["subexpr","query-params",[],["selectTab","documents","rootFolderId",["get","item.referenceId",["loc",[null,[175,110],[175,126]]]]],["loc",[null,[175,61],[175,127]]]]],[],0,null,["loc",[null,[175,18],[177,30]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child22 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 183,
                      "column": 18
                    },
                    "end": {
                      "line": 186,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("zum\n                    Untersuchungsauftrag");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[184,20],[184,102]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 181,
                    "column": 16
                  },
                  "end": {
                    "line": 188,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","reference");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","link-to",["patients.edit",["get","item.referenceId",["loc",[null,[183,45],[183,61]]]],["subexpr","query-params",[],["selectTab","lab"],["loc",[null,[183,62],[183,92]]]]],["title","Patient anzeigen"],0,null,["loc",[null,[183,18],[186,30]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 191,
                      "column": 18
                    },
                    "end": {
                      "line": 194,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("\n                    Zur Behandlung");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[192,20],[192,102]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 189,
                    "column": 16
                  },
                  "end": {
                    "line": 196,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","reference");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","link-to",["practicemgmt.treatment-details",["get","item.referenceId",["loc",[null,[191,62],[191,78]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[191,79],[191,106]]]]],[],0,null,["loc",[null,[191,18],[194,30]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 199,
                      "column": 18
                    },
                    "end": {
                      "line": 202,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("zum\n                    Lieferschein");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[200,20],[200,102]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 197,
                    "column": 16
                  },
                  "end": {
                    "line": 204,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","reference");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","link-to",["inventory.delivery-note-details",["get","item.referenceId",["loc",[null,[199,63],[199,79]]]]],["title","Lieferschein anzeigen"],0,null,["loc",[null,[199,18],[202,30]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 207,
                      "column": 18
                    },
                    "end": {
                      "line": 210,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("zum\n                    Patient");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[208,20],[208,102]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 205,
                    "column": 16
                  },
                  "end": {
                    "line": 212,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","reference");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","link-to",["patients.edit",["get","item.referenceId",["loc",[null,[207,45],[207,61]]]],["subexpr","query-params",[],["selectTab","history"],["loc",[null,[207,62],[207,96]]]]],["title","Patient anzeigen"],0,null,["loc",[null,[207,18],[210,30]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child4 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 215,
                      "column": 18
                    },
                    "end": {
                      "line": 217,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    zur eltiga Akte\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 213,
                    "column": 16
                  },
                  "end": {
                    "line": 219,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","reference");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","link-to",["patients.edit",["get","item.referenceId",["loc",[null,[215,45],[215,61]]]],["subexpr","query-params",[],["selectTab","etiga"],["loc",[null,[215,62],[215,94]]]]],["title","Patient anzeigen","class","button purple-style"],0,null,["loc",[null,[215,18],[217,30]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child5 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 222,
                      "column": 18
                    },
                    "end": {
                      "line": 225,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("zur\n                    Rechnung");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[223,20],[223,102]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 220,
                    "column": 16
                  },
                  "end": {
                    "line": 227,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","reference");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","link-to",["invoices.show",["get","item.referenceId",["loc",[null,[222,45],[222,61]]]]],["title","Rechnung anzeigen"],0,null,["loc",[null,[222,18],[225,30]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child6 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 230,
                      "column": 18
                    },
                    "end": {
                      "line": 233,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("zur\n                    Bestellung");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[231,20],[231,102]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 228,
                    "column": 16
                  },
                  "end": {
                    "line": 235,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","reference");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","link-to",["purchases.show",["get","item.referenceId",["loc",[null,[230,46],[230,62]]]]],["title","Bestellung anzeigen"],0,null,["loc",[null,[230,18],[233,30]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 180,
                  "column": 12
                },
                "end": {
                  "line": 236,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
              morphs[3] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[4] = dom.createMorphAt(fragment,4,4,contextualElement);
              morphs[5] = dom.createMorphAt(fragment,5,5,contextualElement);
              morphs[6] = dom.createMorphAt(fragment,6,6,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[181,26],[181,44]]]],"treatment"],[],["loc",[null,[181,22],[181,57]]]]],[],0,null,["loc",[null,[181,16],[188,23]]]],
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[189,26],[189,44]]]],"treatmentPatient"],[],["loc",[null,[189,22],[189,64]]]]],[],1,null,["loc",[null,[189,16],[196,23]]]],
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[197,26],[197,44]]]],"deliveryNote"],[],["loc",[null,[197,22],[197,60]]]]],[],2,null,["loc",[null,[197,16],[204,23]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","item.category.id",["loc",[null,[205,36],[205,52]]]],"app"],[],["loc",[null,[205,32],[205,59]]]]],[],["loc",[null,[205,27],[205,60]]]],["subexpr","eq",[["get","item.referenceType",["loc",[null,[205,65],[205,83]]]],"patient"],[],["loc",[null,[205,61],[205,94]]]]],[],["loc",[null,[205,22],[205,95]]]]],[],3,null,["loc",[null,[205,16],[212,23]]]],
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[213,26],[213,44]]]],"patientetiga"],[],["loc",[null,[213,22],[213,60]]]]],[],4,null,["loc",[null,[213,16],[219,23]]]],
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[220,26],[220,44]]]],"invoice"],[],["loc",[null,[220,22],[220,55]]]]],[],5,null,["loc",[null,[220,16],[227,23]]]],
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[228,26],[228,44]]]],"purchase"],[],["loc",[null,[228,22],[228,56]]]]],[],6,null,["loc",[null,[228,16],[235,23]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6]
          };
        }());
        var child23 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 240,
                      "column": 18
                    },
                    "end": {
                      "line": 240,
                      "column": 180
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon vertical-middle"],["loc",[null,[240,58],[240,156]]]],
                  ["content","patient.name",["loc",[null,[240,160],[240,176]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 239,
                    "column": 16
                  },
                  "end": {
                    "line": 241,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["block","link-to",["patients.edit",["get","patient.id",["loc",[null,[240,45],[240,55]]]]],[],0,null,["loc",[null,[240,18],[240,192]]]]
              ],
              locals: ["patient"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 237,
                  "column": 12
                },
                "end": {
                  "line": 243,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","reference");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.referenceData.patients",["loc",[null,[239,24],[239,51]]]]],[],0,null,["loc",[null,[239,16],[241,25]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child24 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 246,
                    "column": 16
                  },
                  "end": {
                    "line": 248,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createTextNode("Termin im Kalender anzeigen");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[247,18],[247,100]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 244,
                  "column": 12
                },
                "end": {
                  "line": 250,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","reference mb0");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","link-to",["appointments.calendar"],["title","Termin im Kalender anzeigen"],0,null,["loc",[null,[246,16],[248,28]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child25 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 253,
                    "column": 16
                  },
                  "end": {
                    "line": 256,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createTextNode("zur\n                  Korrespondenz");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[254,18],[254,100]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 251,
                  "column": 14
                },
                "end": {
                  "line": 258,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","reference");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","link-to",["patients.edit",["get","item.patient.id",["loc",[null,[253,43],[253,58]]]],["subexpr","query-params",[],["selectTab","correspondance"],["loc",[null,[253,59],[253,100]]]]],["title","Korrespondenz anzeigen"],0,null,["loc",[null,[253,16],[256,28]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child26 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 262,
                      "column": 263
                    },
                    "end": {
                      "line": 262,
                      "column": 353
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode(" am ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","time-format",[["get","task.doneAt",["loc",[null,[262,319],[262,330]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[262,305],[262,353]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 262,
                        "column": 443
                      },
                      "end": {
                        "line": 262,
                        "column": 522
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("seit ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(",");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","time-format",[["get","task.startedAt",["loc",[null,[262,484],[262,498]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[262,470],[262,521]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 262,
                      "column": 360
                    },
                    "end": {
                      "line": 262,
                      "column": 585
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode(", ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" fällig am ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","task.startedAt",["loc",[null,[262,449],[262,463]]]]],[],0,null,["loc",[null,[262,443],[262,529]]]],
                  ["inline","time-format",[["get","task.due",["loc",[null,[262,554],[262,562]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[262,540],[262,585]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 262,
                        "column": 627
                      },
                      "end": {
                        "line": 262,
                        "column": 734
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","task.user.firstname",["loc",[null,[262,688],[262,711]]]],
                    ["content","task.user.lastname",["loc",[null,[262,712],[262,734]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 262,
                      "column": 610
                    },
                    "end": {
                      "line": 262,
                      "column": 746
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["users.edit",["get","task.user.id",["loc",[null,[262,651],[262,663]]]]],["class","is-highlighted"],0,null,["loc",[null,[262,627],[262,746]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child3 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 262,
                      "column": 746
                    },
                    "end": {
                      "line": 262,
                      "column": 758
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("Alle");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 261,
                    "column": 16
                  },
                  "end": {
                    "line": 263,
                    "column": 17
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createTextNode("Aufgabe:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","cursor is-highlighted");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" (");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(", Verantwortlich: ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(")\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element10 = dom.childAt(fragment, [7]);
                var morphs = new Array(7);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[1] = dom.createElementMorph(element10);
                morphs[2] = dom.createMorphAt(element10,0,0);
                morphs[3] = dom.createMorphAt(dom.childAt(fragment, [9]),0,0);
                morphs[4] = dom.createMorphAt(fragment,10,10,contextualElement);
                morphs[5] = dom.createMorphAt(fragment,11,11,contextualElement);
                morphs[6] = dom.createMorphAt(fragment,13,13,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon","content","Aufgabe öffnen"],["loc",[null,[262,28],[262,135]]]],
                ["element","action",["showTask",["get","task",["loc",[null,[262,205],[262,209]]]]],[],["loc",[null,[262,185],[262,211]]]],
                ["content","task.title",["loc",[null,[262,212],[262,226]]]],
                ["content","task.taskStatus.name",["loc",[null,[262,235],[262,259]]]],
                ["block","if",[["subexpr","eq",[["get","task.taskStatus.id",["loc",[null,[262,273],[262,291]]]],"done"],[],["loc",[null,[262,269],[262,299]]]]],[],0,null,["loc",[null,[262,263],[262,360]]]],
                ["block","if",[["subexpr","or",[["subexpr","eq",[["get","task.taskStatus.id",["loc",[null,[262,374],[262,392]]]],"in_progress"],[],["loc",[null,[262,370],[262,407]]]],["subexpr","eq",[["get","task.taskStatus.id",["loc",[null,[262,412],[262,430]]]],"open"],[],["loc",[null,[262,408],[262,438]]]]],[],["loc",[null,[262,366],[262,439]]]]],[],1,null,["loc",[null,[262,360],[262,592]]]],
                ["block","if",[["get","task.user",["loc",[null,[262,616],[262,625]]]]],[],2,3,["loc",[null,[262,610],[262,765]]]]
              ],
              locals: ["task"],
              templates: [child0, child1, child2, child3]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 259,
                  "column": 14
                },
                "end": {
                  "line": 265,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","reference");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.tasks",["loc",[null,[261,24],[261,34]]]]],[],0,null,["loc",[null,[261,16],[263,26]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child27 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 268,
                  "column": 10
                },
                "end": {
                  "line": 275,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","cursor etiga-send-msg");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","icon-button icon-button--small");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var element9 = dom.childAt(element8, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element8, 'class');
              morphs[1] = dom.createElementMorph(element9);
              morphs[2] = dom.createMorphAt(dom.childAt(element9, [1]),1,1);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["directEtigaMessage ",["subexpr","css-bool-evaluator",[["subexpr","or",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","item.referenceData.status",["loc",[null,[269,82],[269,107]]]],"requested"],[],["loc",[null,[269,78],[269,120]]]]],[],["loc",[null,[269,73],[269,121]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[269,126],[269,142]]]],"appapp"],[],["loc",[null,[269,122],[269,152]]]]],[],["loc",[null,[269,68],[269,153]]]],["subexpr","and",[["get","item.app",["loc",[null,[269,159],[269,167]]]],["subexpr","or",[["subexpr","eq",[["get","item.category.id",["loc",[null,[269,176],[269,192]]]],"app"],[],["loc",[null,[269,172],[269,199]]]],["subexpr","and",[["subexpr","eq",[["get","item.status",["loc",[null,[269,209],[269,220]]]],"done"],[],["loc",[null,[269,205],[269,228]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[269,233],[269,249]]]],"apporder"],[],["loc",[null,[269,229],[269,261]]]]],[],["loc",[null,[269,200],[269,262]]]]],[],["loc",[null,[269,168],[269,263]]]]],[],["loc",[null,[269,154],[269,264]]]]],[],["loc",[null,[269,64],[269,265]]]],"right",""],[],["loc",[null,[269,43],[269,279]]]]]]],
              ["element","action",["openDirectMessage",["get","item.id",["loc",[null,[270,46],[270,53]]]]],[],["loc",[null,[270,17],[270,55]]]],
              ["inline","button-chat",[],["content","Nachricht an eltiga app BenutzerIn verfassen","color","#ffffff","size","40","showStroke",false],["loc",[null,[272,24],[272,137]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child28 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 276,
                  "column": 10
                },
                "end": {
                  "line": 282,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","addAdmission");
              dom.setAttribute(el1,"style","right: 58px;");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--small icon-button--success");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element7);
              morphs[1] = dom.createMorphAt(element7,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["startAdmissionFromMessage",["get","item",["loc",[null,[278,56],[278,60]]]]],[],["loc",[null,[278,19],[278,62]]]],
              ["inline","button-add-admission",[],["content","Nachricht in Behandlung übernehmen","color","#FFFFFF","size","40"],["loc",[null,[279,18],[279,113]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child29 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 283,
                  "column": 10
                },
                "end": {
                  "line": 289,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","addAdmission");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--small icon-button--success");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element6);
              morphs[1] = dom.createMorphAt(element6,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["startAdmissionFromReorder",["get","item",["loc",[null,[285,54],[285,58]]]]],[],["loc",[null,[285,17],[285,60]]]],
              ["inline","button-add-admission",[],["content","Bestellung in Behandlung übernehmen","color","#FFFFFF","size","40"],["loc",[null,[286,16],[286,112]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child30 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 290,
                  "column": 10
                },
                "end": {
                  "line": 296,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","addAdmission");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--small icon-button--success");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element5);
              morphs[1] = dom.createMorphAt(element5,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["openDirectMessage",["get","item",["loc",[null,[292,50],[292,54]]]],"finished"],[],["loc",[null,[292,21],[292,67]]]],
              ["inline","button-pickup",[],["content","eltiga App BenutzerIn benachrichtigen: Bestellung abholbereit","color","#FFFFFF","size","40"],["loc",[null,[293,20],[293,135]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child31 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 306,
                    "column": 18
                  },
                  "end": {
                    "line": 308,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","cloudinary-tag",[["get","reply.sender.picture.cdnId",["loc",[null,[307,37],[307,63]]]]],["crop","fit","class","img-responsive mw50 ib"],["loc",[null,[307,20],[307,107]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 308,
                    "column": 18
                  },
                  "end": {
                    "line": 310,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"class","img-responsive mw50 ib");
                dom.setAttribute(el1,"title","user");
                dom.setAttribute(el1,"src","assets/images/users/default.png");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 313,
                    "column": 41
                  },
                  "end": {
                    "line": 313,
                    "column": 87
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","reply.sender.shortName",["loc",[null,[313,61],[313,87]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 313,
                    "column": 87
                  },
                  "end": {
                    "line": 313,
                    "column": 109
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("App-Benutzerin");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 317,
                      "column": 22
                    },
                    "end": {
                      "line": 319,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("zur Behandlung");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-view",[],["size","24","color","#3B6182","showStroke",true,"classNames","inline-icon"],["loc",[null,[318,24],[318,106]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 315,
                    "column": 18
                  },
                  "end": {
                    "line": 321,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","reference");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","link-to",["practicemgmt.treatment-details",["get","item.referenceData.treatmentPatientId",["loc",[null,[317,66],[317,103]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[317,104],[317,131]]]]],["title","Zur Behandlung"],0,null,["loc",[null,[317,22],[319,34]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child5 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 325,
                    "column": 40
                  },
                  "end": {
                    "line": 325,
                    "column": 148
                  }
                },
                "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","cursor");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [0]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element0);
                morphs[1] = dom.createMorphAt(element0,0,0);
                return morphs;
              },
              statements: [
                ["element","action",["reply",["get","item",["loc",[null,[325,82],[325,86]]]]],[],["loc",[null,[325,65],[325,88]]]],
                ["inline","button-reply",[],["color","#3B6182","size",31],["loc",[null,[325,104],[325,144]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 300,
                  "column": 6
                },
                "end": {
                  "line": 329,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","message");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","innerWrapper");
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","col l-24/24 mt10 mb10");
              var el5 = dom.createTextNode("\n                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","image");
              var el6 = dom.createTextNode("\n");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("                ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","headline");
              var el6 = dom.createTextNode("\n        ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("div");
              dom.setAttribute(el6,"class","like-h3");
              var el7 = dom.createTextNode("Antwort von ");
              dom.appendChild(el6, el7);
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                  ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("                ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","date");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","reply-button");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [1, 1]);
              var element3 = dom.childAt(element2, [1]);
              var element4 = dom.childAt(element3, [3]);
              var morphs = new Array(7);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]),1,1);
              morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]),1,1);
              morphs[3] = dom.createUnsafeMorphAt(element4,3,3);
              morphs[4] = dom.createMorphAt(element4,5,5);
              morphs[5] = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
              morphs[6] = dom.createMorphAt(dom.childAt(element2, [5]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["messageContainer reply ",["subexpr","css-bool-evaluator",[["get","item.tmpHide",["loc",[null,[301,64],[301,76]]]],"hidden",""],[],["loc",[null,[301,43],[301,90]]]]]]],
              ["block","if",[["get","reply.sender.picture",["loc",[null,[306,24],[306,44]]]]],[],0,1,["loc",[null,[306,18],[310,25]]]],
              ["block","if",[["get","reply.sender",["loc",[null,[313,47],[313,59]]]]],[],2,3,["loc",[null,[313,41],[313,116]]]],
              ["content","reply.comment",["loc",[null,[314,18],[314,37]]]],
              ["block","if",[["subexpr","and",[["get","item.referenceData",["loc",[null,[315,29],[315,47]]]],["get","item.referenceData.treatmentPatientId",["loc",[null,[315,48],[315,85]]]]],[],["loc",[null,[315,24],[315,86]]]]],[],4,null,["loc",[null,[315,18],[321,25]]]],
              ["inline","time-format",[["get","reply.created",["loc",[null,[324,46],[324,59]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[324,32],[324,82]]]],
              ["block","if",[["subexpr","not",[["get","item.app",["loc",[null,[325,51],[325,59]]]]],[],["loc",[null,[325,46],[325,60]]]]],[],5,null,["loc",[null,[325,40],[325,155]]]]
            ],
            locals: ["reply"],
            templates: [child0, child1, child2, child3, child4, child5]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 4
              },
              "end": {
                "line": 331,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","category flex-center");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","ml10");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","innerWrapper");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-24/24 mt20 mb10");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","image");
            var el6 = dom.createTextNode("\n");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","headline");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("div");
            dom.setAttribute(el6,"class","like-h2 mt0 mb5");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","addTask pt2");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("a");
            dom.setAttribute(el6,"class","cursor");
            var el7 = dom.createTextNode("\n                  ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","date");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("p");
            dom.setAttribute(el4,"class","mt30 mb0");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element23 = dom.childAt(fragment, [1]);
            var element24 = dom.childAt(element23, [1]);
            var element25 = dom.childAt(element24, [1]);
            var element26 = dom.childAt(element24, [3]);
            var element27 = dom.childAt(element26, [1]);
            var element28 = dom.childAt(element27, [3]);
            var element29 = dom.childAt(element26, [3]);
            var element30 = dom.childAt(element29, [1, 1]);
            var element31 = dom.childAt(element26, [9]);
            var morphs = new Array(38);
            morphs[0] = dom.createAttrMorph(element23, 'class');
            morphs[1] = dom.createAttrMorph(element24, 'class');
            morphs[2] = dom.createMorphAt(element25,1,1);
            morphs[3] = dom.createMorphAt(element25,3,3);
            morphs[4] = dom.createMorphAt(element25,5,5);
            morphs[5] = dom.createMorphAt(element25,7,7);
            morphs[6] = dom.createMorphAt(element25,9,9);
            morphs[7] = dom.createMorphAt(element25,11,11);
            morphs[8] = dom.createMorphAt(element25,13,13);
            morphs[9] = dom.createMorphAt(dom.childAt(element25, [15]),0,0);
            morphs[10] = dom.createMorphAt(dom.childAt(element27, [1]),1,1);
            morphs[11] = dom.createMorphAt(dom.childAt(element28, [1]),0,0);
            morphs[12] = dom.createMorphAt(element28,3,3);
            morphs[13] = dom.createMorphAt(element28,4,4);
            morphs[14] = dom.createMorphAt(element28,5,5);
            morphs[15] = dom.createAttrMorph(element29, 'class');
            morphs[16] = dom.createElementMorph(element30);
            morphs[17] = dom.createMorphAt(element30,1,1);
            morphs[18] = dom.createMorphAt(element29,3,3);
            morphs[19] = dom.createMorphAt(element29,4,4);
            morphs[20] = dom.createMorphAt(element26,5,5);
            morphs[21] = dom.createMorphAt(dom.childAt(element26, [7]),0,0);
            morphs[22] = dom.createUnsafeMorphAt(element31,1,1);
            morphs[23] = dom.createMorphAt(element31,3,3);
            morphs[24] = dom.createMorphAt(element31,4,4);
            morphs[25] = dom.createMorphAt(element31,6,6);
            morphs[26] = dom.createMorphAt(element31,8,8);
            morphs[27] = dom.createMorphAt(element31,10,10);
            morphs[28] = dom.createMorphAt(element31,11,11);
            morphs[29] = dom.createMorphAt(element31,12,12);
            morphs[30] = dom.createMorphAt(element31,13,13);
            morphs[31] = dom.createMorphAt(element31,14,14);
            morphs[32] = dom.createMorphAt(element31,15,15);
            morphs[33] = dom.createMorphAt(element26,11,11);
            morphs[34] = dom.createMorphAt(element26,12,12);
            morphs[35] = dom.createMorphAt(element26,13,13);
            morphs[36] = dom.createMorphAt(element26,14,14);
            morphs[37] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["messageContainer ",["subexpr","css-bool-evaluator",[["get","item.tmpHide",["loc",[null,[5,56],[5,68]]]],"hidden",""],[],["loc",[null,[5,35],[5,82]]]]]]],
            ["attribute","class",["concat",["message ",["subexpr","css-bool-evaluator",[["subexpr","and",[["subexpr","or",[["subexpr","eq",[["get","type",["loc",[null,[6,62],[6,66]]]],"in"],[],["loc",[null,[6,58],[6,72]]]],["subexpr","eq",[["get","type",["loc",[null,[6,77],[6,81]]]],"unread"],[],["loc",[null,[6,73],[6,91]]]]],[],["loc",[null,[6,54],[6,92]]]],["get","item.unread",["loc",[null,[6,93],[6,104]]]]],[],["loc",[null,[6,49],[6,105]]]],"unread",""],[],["loc",[null,[6,28],[6,119]]]]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[8,22],[8,38]]]],"jason"],[],["loc",[null,[8,18],[8,47]]]]],[],0,null,["loc",[null,[8,12],[8,147]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[9,22],[9,38]]]],"ehapo"],[],["loc",[null,[9,18],[9,47]]]]],[],1,null,["loc",[null,[9,12],[9,110]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[10,22],[10,38]]]],"lab"],[],["loc",[null,[10,18],[10,45]]]]],[],2,null,["loc",[null,[10,12],[20,19]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[21,22],[21,38]]]],"invoice"],[],["loc",[null,[21,18],[21,49]]]]],[],3,null,["loc",[null,[21,12],[21,134]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[22,22],[22,38]]]],"practice"],[],["loc",[null,[22,18],[22,50]]]]],[],4,null,["loc",[null,[22,12],[22,133]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[23,22],[23,38]]]],"calendar"],[],["loc",[null,[23,18],[23,50]]]]],[],5,null,["loc",[null,[23,12],[23,133]]]],
            ["block","if",[["subexpr","or",[["subexpr","eq",[["get","item.category.id",["loc",[null,[24,26],[24,42]]]],"etigashare"],[],["loc",[null,[24,22],[24,56]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[24,61],[24,77]]]],"app"],[],["loc",[null,[24,57],[24,84]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[24,89],[24,105]]]],"apporder"],[],["loc",[null,[24,85],[24,117]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[24,122],[24,138]]]],"appapp"],[],["loc",[null,[24,118],[24,148]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[24,153],[24,169]]]],"etiga"],[],["loc",[null,[24,149],[24,178]]]]],[],["loc",[null,[24,18],[24,179]]]]],[],6,null,["loc",[null,[24,12],[24,258]]]],
            ["content","item.category.name",["loc",[null,[25,31],[25,53]]]],
            ["block","if",[["subexpr","eq",[["get","item.cssClass",["loc",[null,[29,26],[29,39]]]],"vetconnect"],[],["loc",[null,[29,22],[29,53]]]]],[],7,8,["loc",[null,[29,16],[39,23]]]],
            ["content","item.title",["loc",[null,[42,45],[42,59]]]],
            ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[43,26],[43,30]]]],"in"],[],["loc",[null,[43,22],[43,36]]]]],[],9,null,["loc",[null,[43,16],[46,23]]]],
            ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[47,26],[47,30]]]],"out"],[],["loc",[null,[47,22],[47,37]]]]],[],10,null,["loc",[null,[47,16],[51,23]]]],
            ["block","if",[["subexpr","not",[["subexpr","eq",[["get","item.category.id",["loc",[null,[52,31],[52,47]]]],"etiga"],[],["loc",[null,[52,27],[52,56]]]]],[],["loc",[null,[52,22],[52,57]]]]],[],11,null,["loc",[null,[52,16],[67,23]]]],
            ["attribute","class",["concat",["iconLine ",["subexpr","css-bool-evaluator",[["get","item.unread",["loc",[null,[71,54],[71,65]]]],"","read"],[],["loc",[null,[71,33],[71,77]]]]]]],
            ["element","action",["addTask",["get","item",["loc",[null,[73,38],[73,42]]]]],[],["loc",[null,[73,19],[73,44]]]],
            ["inline","button-add-task",[],["content","Neue verknüpfte Aufgabe","color","#3B6182","size","30","showStroke",true],["loc",[null,[74,18],[74,113]]]],
            ["block","if",[["subexpr","or",[["subexpr","eq",[["get","item.category.id",["loc",[null,[77,28],[77,44]]]],"app"],[],["loc",[null,[77,24],[77,51]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[77,56],[77,72]]]],"apporder"],[],["loc",[null,[77,52],[77,84]]]]],[],["loc",[null,[77,20],[77,85]]]]],[],12,13,["loc",[null,[77,14],[86,21]]]],
            ["block","if",[["subexpr","and",[["subexpr","or",[["subexpr","eq",[["get","type",["loc",[null,[87,33],[87,37]]]],"in"],[],["loc",[null,[87,29],[87,43]]]],["subexpr","eq",[["get","type",["loc",[null,[87,48],[87,52]]]],"unread"],[],["loc",[null,[87,44],[87,62]]]]],[],["loc",[null,[87,25],[87,63]]]],["get","item.unread",["loc",[null,[87,64],[87,75]]]]],[],["loc",[null,[87,20],[87,76]]]]],[],14,15,["loc",[null,[87,14],[106,21]]]],
            ["block","if",[["subexpr","not",[["get","item.unread",["loc",[null,[108,23],[108,34]]]]],[],["loc",[null,[108,18],[108,35]]]]],[],16,null,["loc",[null,[108,12],[112,19]]]],
            ["inline","time-format",[["get","item.created",["loc",[null,[113,44],[113,56]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[113,30],[113,79]]]],
            ["content","item.message",["loc",[null,[115,14],[115,32]]]],
            ["block","if",[["subexpr","and",[["get","item.patient",["loc",[null,[116,23],[116,35]]]],["get","item.patient.etigaActive",["loc",[null,[116,36],[116,60]]]],["get","item.patient.etigaMaster",["loc",[null,[116,61],[116,85]]]],["subexpr","not",[["subexpr","eq",[["get","item.category.id",["loc",[null,[116,95],[116,111]]]],"etiga"],[],["loc",[null,[116,91],[116,120]]]]],[],["loc",[null,[116,86],[116,121]]]]],[],["loc",[null,[116,18],[116,122]]]]],[],17,null,["loc",[null,[116,12],[122,19]]]],
            ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[123,24],[123,42]]]],"appAppointmentRequest"],[],["loc",[null,[123,20],[123,67]]]]],[],18,null,["loc",[null,[123,14],[137,21]]]],
            ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[139,24],[139,42]]]],"reorderApp"],[],["loc",[null,[139,20],[139,56]]]]],[],19,null,["loc",[null,[139,14],[164,21]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[166,24],[166,40]]]],"etiga"],[],["loc",[null,[166,20],[166,49]]]]],[],20,null,["loc",[null,[166,14],[171,23]]]],
            ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[173,26],[173,44]]]],"document"],[],["loc",[null,[173,22],[173,56]]]]],[],21,null,["loc",[null,[173,16],[179,23]]]],
            ["block","if",[["get","item.referenceId",["loc",[null,[180,18],[180,34]]]]],[],22,null,["loc",[null,[180,12],[236,21]]]],
            ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[237,22],[237,40]]]],"patients"],[],["loc",[null,[237,18],[237,52]]]]],[],23,null,["loc",[null,[237,12],[243,19]]]],
            ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[244,22],[244,40]]]],"appAppointmentRequest"],[],["loc",[null,[244,18],[244,65]]]]],[],24,null,["loc",[null,[244,12],[250,19]]]],
            ["block","if",[["subexpr","and",[["get","item.patient.id",["loc",[null,[251,25],[251,40]]]],["subexpr","not",[["get","item.patient.etigaMaster",["loc",[null,[251,46],[251,70]]]]],[],["loc",[null,[251,41],[251,71]]]]],[],["loc",[null,[251,20],[251,72]]]]],[],25,null,["loc",[null,[251,14],[258,21]]]],
            ["block","if",[["get","item.tasks",["loc",[null,[259,20],[259,30]]]]],[],26,null,["loc",[null,[259,14],[265,21]]]],
            ["block","if",[["subexpr","or",[["subexpr","eq",[["get","item.category.id",["loc",[null,[268,24],[268,40]]]],"appapp"],[],["loc",[null,[268,20],[268,50]]]],["subexpr","and",[["get","item.app",["loc",[null,[268,56],[268,64]]]],["subexpr","or",[["subexpr","eq",[["get","item.category.id",["loc",[null,[268,73],[268,89]]]],"app"],[],["loc",[null,[268,69],[268,96]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[268,101],[268,117]]]],"apporder"],[],["loc",[null,[268,97],[268,129]]]]],[],["loc",[null,[268,65],[268,130]]]]],[],["loc",[null,[268,51],[268,131]]]]],[],["loc",[null,[268,16],[268,132]]]]],[],27,null,["loc",[null,[268,10],[275,17]]]],
            ["block","if",[["subexpr","and",[["get","item.patient.id",["loc",[null,[276,21],[276,36]]]],["subexpr","not",[["get","item.patient.etigaMaster",["loc",[null,[276,42],[276,66]]]]],[],["loc",[null,[276,37],[276,67]]]],["subexpr","not",[["get","item.referenceType",["loc",[null,[276,73],[276,91]]]]],[],["loc",[null,[276,68],[276,92]]]]],[],["loc",[null,[276,16],[276,93]]]]],[],28,null,["loc",[null,[276,10],[282,17]]]],
            ["block","if",[["subexpr","and",[["subexpr","eq",[["get","item.category.id",["loc",[null,[283,25],[283,41]]]],"apporder"],[],["loc",[null,[283,21],[283,53]]]],["subexpr","not",[["get","item.referenceData.admissionExecuted",["loc",[null,[283,59],[283,95]]]]],[],["loc",[null,[283,54],[283,96]]]]],[],["loc",[null,[283,16],[283,97]]]]],[],29,null,["loc",[null,[283,10],[289,17]]]],
            ["block","if",[["subexpr","and",[["get","item.referenceData.admissionExecuted",["loc",[null,[290,21],[290,57]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[290,62],[290,78]]]],"apporder"],[],["loc",[null,[290,58],[290,90]]]],["subexpr","eq",[["get","item.status",["loc",[null,[290,95],[290,106]]]],"open"],[],["loc",[null,[290,91],[290,114]]]]],[],["loc",[null,[290,16],[290,115]]]]],[],30,null,["loc",[null,[290,10],[296,17]]]],
            ["block","each",[["get","item.replies",["loc",[null,[300,14],[300,26]]]]],[],31,null,["loc",[null,[300,6],[329,15]]]]
          ],
          locals: ["item"],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24, child25, child26, child27, child28, child29, child30, child31]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 332,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","items",["loc",[null,[4,12],[4,17]]]]],[],0,null,["loc",[null,[4,4],[331,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 332,
              "column": 2
            },
            "end": {
              "line": 334,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    Zurzeit nichts Neues\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 336,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/notifications-timeline.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table no-box@phone");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
        return morphs;
      },
      statements: [
        ["block","if",[["get","items",["loc",[null,[3,8],[3,13]]]]],[],0,1,["loc",[null,[3,2],[334,9]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});