define('jason-frontend/routes/invoices/showbanktransfer', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model, params) {
      var transactionId = params.params['invoices.showbanktransfer'].id;
      this.store.fetch('transaction-group', transactionId).then(function (data) {
        controller.set('model', data);
      });
    }
  });

});