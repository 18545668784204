define('jason-frontend/components/promotion-info-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      refresh: function refresh() {
        $.magnificPopup.close();
      },
      loadData: function loadData(info) {
        this.set('info', info);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});