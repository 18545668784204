define('jason-frontend/controllers/reminders/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Übersicht",
    selectedTab: "past",
    pickDate: moment().format("DD. MM. YYYY") + " - " + moment().endOf('month').endOf('day').format("DD. MM. YYYY"),
    pickDatePast: moment().subtract(1, 'month').format("DD. MM. YYYY") + " - " + moment().format("DD. MM. YYYY"),
    pickDateLastTreatment: null,
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('pickDate'),
    watchDatePast: (function () {
      this.send('applyFilter');
    }).observes('pickDatePast'),
    watchLastTreatment: (function () {
      this.send('applyFilter');
    }).observes('pickDateLastTreatment')
  });

});