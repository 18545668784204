define('jason-frontend/routes/purchases/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.findRecord('purchaseOrder', params.id, { reload: true });
    },
    afterModel: function afterModel() {
      this.transitionTo({ queryParams: { redirectToListing: null } });
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);
      var orderId = params.params['purchases.edit'].id;

      if (!API['default'].hasPermission('crud_purchases')) {
        this.transitionTo('home');
      }

      if (orderId > 0) {
        controller.set('currentOrderId', orderId);

        var redirectToListing = params.queryParams.redirectToListing;
        var referer = params.queryParams.referer;

        controller.set('referer', referer);

        if (referer !== 'product') {
          if (model && (model.get('entryCount') == 0 && redirectToListing === "true" || redirectToListing === "ever") && orderId && orderId > 0) {
            this.transitionTo('purchases.add_product', orderId);
          }
        }
      }
    },
    actions: {
      gotoCarts: function gotoCarts() {
        this.transitionTo('purchases.choose');
      },
      toggleSidebar: function toggleSidebar() {
        var controller = this.get('controller');
        if (controller.get('sidebarActive') === true) {
          controller.set('sidebarActive', false);
        } else {
          controller.set('sidebarActive', true);
          setTimeout(function () {
            $('aside.side-bar .scrollable').css('max-height', $(window).height() - 100);
            $('aside.side-bar').css('min-height', $(window).height() - 100);
          }, 100);

          if ($(window).width() <= 767) {
            $("html, body").animate({ scrollTop: 0 }, 200);
          }
        }
      },
      unlinkLaboklin: function unlinkLaboklin(entryId, formId) {
        var controller = this.get('controller'),
            self = this;

        $.ajax({
          url: "/api/purchaseOrders/" + controller.get('model').id + "/unlinkLaboklin/" + entryId + "/" + formId,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {}, function () {
          jason.notifiction.systemError('Fehlercode 111', 'Eingaben konnte nicht gespeichert werden');
        });
      },
      downloadBarcode: function downloadBarcode(formId) {
        window.open('/api/treatmentPatients/downloadBarcode/' + formId + '/hor?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      downloadPdf: function downloadPdf(id) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/treatmentPatients/downloadLaboklinPdf/' + API['default'].getMandant() + '/' + data.responseText);
        });
      },
      downloadMedia: function downloadMedia(id, media) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/treatmentPatients/downloadLaboklin/' + API['default'].getMandant() + '/' + media + '/' + data.responseText);
        });
      },
      toShowCart: function toShowCart() {
        var controller = this.get('controller');

        controller.set('styleStepPositions', null);
        controller.set('styleStepSummary', 'hidden');
        controller.set('styleStepConfirmation', 'hidden');
      },
      deleteProduct: function deleteProduct(entryId, orderId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/purchaseOrders/" + orderId + "/changeQuantity/" + entryId + "/0",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.get('controller').set("invoice", self.store.fetch('purchaseOrder', orderId));
          jason.notifiction.notifiy('Produkt entfernt', productName);
        }, function (error) {
          jason.notifiction.errorLong('Achtung', error.responseText);
        });

        return Ember['default'].RSVP.resolve(deferred);
      },
      changeEntryLabel: function changeEntryLabel(entryId, txt) {
        var data = {};
        data.label = txt;

        $.ajax({
          url: "/api/purchaseOrders/" + entryId + "/changeEntryLabel",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {});
      },
      changeQuantity: function changeQuantity(quantity, entryId, orderId, productName) {
        var self = this,
            controller = this.get('controller');

        var deferred = $.ajax({
          url: "/api/purchaseOrders/" + orderId + "/changeQuantity/" + entryId + "/" + quantity,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          controller.set('model', self.store.findRecord('purchaseOrder', orderId, { reload: true }));
        }, function (error) {
          jason.notifiction.errorLong('Achtung', error.responseText);
          controller.set('model', self.store.findRecord('purchaseOrder', orderId, { reload: true }));
        });

        return Ember['default'].RSVP.resolve(deferred);
      },
      toAddProduct: function toAddProduct(orderId) {
        this.transitionTo('purchases.add_product', orderId);
      },
      toSelectSummary: function toSelectSummary(order, orderId) {
        this.transitionTo('purchases.summary', orderId);
      }
    }
  });

});