define('jason-frontend/serializers/payment-action', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].ActiveModelSerializer.extend(DS['default'].EmbeddedRecordsMixin, {
    attrs: {
      paymentType: { embedded: 'always' }
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      var serialized = this.serialize(record, options);
      serialized.id = record.id ? record.id : 0;
      Ember.merge(hash, serialized);
    }
  });

});