define('jason-frontend/routes/purchases/add-product', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",

    model: function model(params) {},
    setupController: function setupController(controller, model, params) {
      var self = this;
      controller.set("model", model);
      controller.set("showLoading", true);

      if (API['default'].isSupplier()) {
        controller.set('sidebarActive', false);
      }

      setTimeout(function () {
        controller.set("filterSupplier", "b");

        if (API['default'].isSupplier()) {
          controller.set("filterSupplier", API['default'].getSupplierId());
        } else {
          controller.set("filterSupplier", "all");
        }

        controller.set("selectedCategoryFilter", null);
        controller.set("selectedPartnerCategoryFilter", null);
        controller.set("partnerCategories", null);
        controller.set("promotionActiveFilter", null);
        self.applyFilter();
      }, 200);

      var orderId = params.params['purchases.add_product'].id;
      if (orderId > 0) {
        controller.set("order", this.store.fetch('purchaseOrder', orderId));
      }

      var campaignId = params.queryParams.campaignId;
      controller.set('showFilters', true);
      if (campaignId && campaignId.length) {
        controller.set('showFilters', false);
        controller.set('filterName', null);
        controller.set('filterCampaignId', campaignId);
      } else {
        controller.set('filterCampaignId', null);
      }

      controller.set("orderId", orderId);
      controller.set("linkToEditEnabled", API['default'].hasPermission('crud_products'));

      this.store.unloadAll('productSupplier');

      controller.set('suppliers', this.store.find('product-supplier', { filterPurchase: true }));

      $.ajax({
        url: "/api/productCategories?suggest=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('categories', data.productCategory.sortBy('text'));
        controller.set('selectedCategoryFilter', null);
      });

      var mandant = this.store.find('mandant', API['default'].getMandant()).then(function (mandant) {
        controller.set('laboklinForm', self.store.createRecord('purchaseOrderLaboklinForm', {
          buyer: mandant.get('name') + ' ' + mandant.get('additionalName') + '\n' + mandant.get('operators') + '\n' + mandant.get('street') + '\n' + mandant.get('postalCode') + ' ' + mandant.get('town')
        }));
      });
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          selectedPartnerCategoryFilter = controller.get('selectedPartnerCategoryFilter'),
          selectedCategoryFilter = controller.get('selectedCategoryFilter'),
          filterSupplier = controller.get('filterSupplier'),
          promotionActive = false,
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy'),
          filterFavs = controller.get('filterFavs'),
          supplierId = null,
          filterFavorites = controller.get('filterFavorites'),
          campaignId = controller.get('filterCampaignId'),
          partnerCategoryId = null,
          categoryId = null;

      controller.set("showLoading", true);

      sortBy = "name.sort";
      sortDir = "asc";

      if (filterSupplier) {
        supplierId = filterSupplier;
      }

      if (supplierId === 'all') {
        supplierId = null;
      }

      if (API['default'].isSupplier()) {
        supplierId = API['default'].getSupplierId();
      }

      if (selectedPartnerCategoryFilter) {
        partnerCategoryId = selectedPartnerCategoryFilter.id;
      }

      if (selectedCategoryFilter) {
        categoryId = selectedCategoryFilter.id;
      }

      this.findPaged('purchaseProduct', {
        filterName: filterName,
        filterPurchase: true,
        showInvisible: API['default'].isSupplier() || API['default'].getMandant() == 4 ? true : false,
        selectedPartnerCategoryFilter: partnerCategoryId,
        selectedCategoryFilter: categoryId,
        filterSupplier: supplierId,
        filterFavorites: filterFavorites,
        promotionActive: promotionActive,
        filterMandant: "all",
        campaignId: campaignId,
        sortDir: sortDir,
        filterFavs: filterFavs,
        sortBy: sortBy,
        perPage: 16
      }).then(function (entries) {
        var meta = entries.meta;
        controller.set('model', entries);
        controller.set('totalEntries', meta.total_records);
        controller.set("showLoading", false);
      });
    },
    actions: {
      gotoCart: function gotoCart(id) {
        this.transitionTo('purchases.edit', id, { queryParams: { referer: "product" } });
      },
      gotoCarts: function gotoCarts() {
        this.transitionTo('purchases.choose');
      },
      toProduct: function toProduct(id, orderId) {
        this.transitionTo('purchases.detail', id, orderId);
      },
      deleteCartEntry: function deleteCartEntry(id, orderId) {
        var self = this;
        var deferred = $.ajax({
          url: "/api/purchaseOrders/" + orderId + "/changeQuantity/" + id + "/0",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.set("order", self.store.fetch('purchaseOrder', orderId));
        }, function (error) {
          self.set("order", self.store.fetch('purchaseOrder', orderId));
        });
      },
      toggleSidebar: function toggleSidebar() {
        var controller = this.get('controller');
        if (controller.get('sidebarActive') === true) {
          controller.set('sidebarActive', false);
        } else {
          controller.set('sidebarActive', true);
          setTimeout(function () {
            $('aside.side-bar .scrollable').css('max-height', $(window).height() - 100);
            $('aside.side-bar').css('min-height', $(window).height() - 100);
          }, 100);

          if ($(window).width() <= 767) {
            $("html, body").animate({ scrollTop: 0 }, 200);
          }
        }
      },
      saveProduct: function saveProduct(product) {
        var self = this;

        $.ajax({
          url: "/api/purchaseProducts",
          method: "POST",
          data: JSON.stringify(product),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          self.transitionTo('purchases.detail', id, 0);
        }, function (error) {});
      },
      updatePartnerCategories: function updatePartnerCategories() {
        var controller = this.controller;
        var selectedSupplier = controller.get('filterSupplier');
        $.ajax({
          url: "/api/purchaseProducts/partnerCategories/" + selectedSupplier,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('partnerCategoriesThere', data.partnerCategory.length > 0);
          controller.set('partnerCategories', data.partnerCategory);
        });
      },
      changeView: function changeView(value) {
        this.controller.set('list', value);
      },
      filterFavs: function filterFavs() {
        if (this.controller.get('filterFavs')) {
          this.controller.set('filterFavs', false);
        } else {
          this.controller.set('filterFavs', true);
        }
        this.applyFilter();
      },
      gotoDetail: function gotoDetail(product, order) {
        this.transitionTo('purchases.detail', product, order);
      },
      toggleFavorites: function toggleFavorites(enabled) {
        var controller = this.get('controller');
        controller.set('filterFavorites', enabled);
        this.applyFilter();
      },
      toggleFavorite: function toggleFavorite(id, add) {
        var controller = this.get('controller');

        if (add) {
          $.ajax({
            url: "/api/purchaseProducts/" + id + "/addToFavs",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        } else {
          $.ajax({
            url: "/api/purchaseProducts/" + id + "/removeFromFavs",
            method: "DELETE",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        }
      },
      queryManufacturer: function queryManufacturer(query, deferred) {
        this.store.find('productManufacturer', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      setSort: function setSort(attr) {
        var controller = this.get('controller');
        controller.set('sortBy', attr);
        if (controller.get('sortDir') === 'asc') {
          controller.set('sortDir', 'desc');
        } else {
          controller.set('sortDir', 'asc');
        }
        this.applyFilter();
      }
    }
  });

});