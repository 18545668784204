define('jason-frontend/routes/cash/logbook', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      this.findPaged('logbookEntry', {
        filterFrom: from,
        filterTo: to
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    setupController: function setupController(controller) {

      if (!API['default'].hasPermission('r_logbook')) {
        this.transitionTo('home');
      }

      controller.set('downloadToken', API['default'].getDownloadToken());
      controller.set('mandantId', API['default'].getMandant());

      this.applyFilter();
    },
    perPage: 100,
    queryParams: {
      filterRegisterId: {
        refreshModel: true
      }
    },
    actions: {
      checkForm: function checkForm(form) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: API['default'].getMandant()
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          $('#' + form + ' input[name=token]').val(data.responseText);
          $('#' + form).submit();
        });
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      }
    }
  });

});