define('jason-frontend/components/simple-number-spinner', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ['input-group', 'spinner2'],
    allowZero: false,
    readOnly: false,
    actions: {
      decreaseQuantitySpinner: function decreaseQuantitySpinner(id) {
        var qty = parseFloat(this.$().find("span[data-qty-entry-id='" + id + "'] input").val());
        if (this.get('allowZero')) {
          if (qty > 0) {
            this.set('value', qty - 1);
            this.sendAction('changeQuantity', this.get('entry'), this.get('orderId'), '', 1000, this.get('value'));
          }
        } else {
          if (qty > 1) {
            this.set('value', qty - 1);
            this.sendAction('changeQuantity', this.get('entry'), this.get('orderId'), '', 1000, this.get('value'));
          }
        }
      },
      increaseQuantitySpinner: function increaseQuantitySpinner(id) {
        var qty = parseFloat(this.$().find("span[data-qty-entry-id='" + id + "'] input").val());
        this.set('value', qty + 1);
        this.sendAction('changeQuantity', this.get('entry'), this.get('orderId'), '', 1000, this.get('value'));
      }
    }
  });

});