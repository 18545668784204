define('jason-frontend/templates/components/invoice-entries', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 38
            },
            "end": {
              "line": 10,
              "column": 84
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Verabreicht");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 84
            },
            "end": {
              "line": 10,
              "column": 101
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Angewandt");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 14
            },
            "end": {
              "line": 19,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("th");
          dom.setAttribute(el1,"class","text-right");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 10
            },
            "end": {
              "line": 23,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","get-invoice-heading-class",[["get","entry.patient",["loc",[null,[22,40],[22,53]]]],["get","shownHeads",["loc",[null,[22,54],[22,64]]]]],[],["loc",[null,[22,12],[22,66]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 37
              },
              "end": {
                "line": 28,
                "column": 153
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("h3");
            var el2 = dom.createTextNode("Rechnungsempfänger: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element26 = dom.childAt(fragment, [0]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element26,1,1);
            morphs[1] = dom.createMorphAt(element26,3,3);
            return morphs;
          },
          statements: [
            ["content","invoice.customer.lastname",["loc",[null,[28,86],[28,115]]]],
            ["content","invoice.customer.firstname",["loc",[null,[28,116],[28,146]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 28,
                  "column": 376
                },
                "end": {
                  "line": 28,
                  "column": 460
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","entry.patient.name",["loc",[null,[28,438],[28,460]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 153
              },
              "end": {
                "line": 28,
                "column": 478
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h3");
            dom.setAttribute(el1,"class","heading--inline");
            var el2 = dom.createTextNode("Leistungsempfänger: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element25 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element25,1,1);
            morphs[1] = dom.createMorphAt(element25,3,3);
            return morphs;
          },
          statements: [
            ["inline","animal-icon",[],["animal-id",["subexpr","@mut",[["get","entry.patient.category.id",["loc",[null,[28,234],[28,259]]]]],[],[]],"race-id",["subexpr","@mut",[["get","entry.patient.digitalRace.icon",["loc",[null,[28,268],[28,298]]]]],[],[]],"content",["subexpr","@mut",[["get","entry.patient.category.name",["loc",[null,[28,307],[28,334]]]]],[],[]],"size","35","classNames","vertical-middle"],["loc",[null,[28,210],[28,375]]]],
            ["block","link-to",["patients.edit",["get","entry.patient.id",["loc",[null,[28,403],[28,419]]]]],["classNames","pt7"],0,null,["loc",[null,[28,376],[28,472]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 42
              },
              "end": {
                "line": 35,
                "column": 88
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Verabreicht");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 88
              },
              "end": {
                "line": 35,
                "column": 105
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Angewandt");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 18
              },
              "end": {
                "line": 44,
                "column": 18
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("th");
            dom.setAttribute(el1,"class","text-right");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 20
              },
              "end": {
                "line": 52,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"type","text");
            dom.setAttribute(el1,"class","datepicker gui-input form-control day-input");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element24 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element24, 'value');
            morphs[1] = dom.createAttrMorph(element24, 'data-day-entry-id');
            morphs[2] = dom.createElementMorph(element24);
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","entry.day",["loc",[null,[51,50],[51,59]]]]]]],
            ["attribute","data-day-entry-id",["concat",[["get","entry.id",["loc",[null,[51,136],[51,144]]]]]]],
            ["element","action",["changeDay",["get","entry",["loc",[null,[51,169],[51,174]]]],["get","invoice.id",["loc",[null,[51,175],[51,185]]]]],["on","change"],["loc",[null,[51,148],[51,199]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 20
              },
              "end": {
                "line": 54,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","entry.day",["loc",[null,[53,22],[53,35]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child7 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 58,
                  "column": 24
                },
                "end": {
                  "line": 68,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"style","margin: auto");
              dom.setAttribute(el1,"class","input-group spinner");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("input");
              dom.setAttribute(el2,"type","text");
              dom.setAttribute(el2,"class","form-control ui-spinner-input");
              dom.setAttribute(el2,"name","spinner");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","quantityspinn btn up");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","quantityspinn btn down");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element20 = dom.childAt(fragment, [1]);
              var element21 = dom.childAt(element20, [1]);
              var element22 = dom.childAt(element20, [3]);
              var element23 = dom.childAt(element20, [5]);
              var morphs = new Array(7);
              morphs[0] = dom.createAttrMorph(element21, 'data-qty-entry-id');
              morphs[1] = dom.createAttrMorph(element21, 'value');
              morphs[2] = dom.createElementMorph(element21);
              morphs[3] = dom.createAttrMorph(element22, 'data-qty-entry-id');
              morphs[4] = dom.createElementMorph(element22);
              morphs[5] = dom.createAttrMorph(element23, 'data-qty-entry-id');
              morphs[6] = dom.createElementMorph(element23);
              return morphs;
            },
            statements: [
              ["attribute","data-qty-entry-id",["concat",[["get","entry.id",["loc",[null,[61,60],[61,68]]]]]]],
              ["attribute","value",["concat",[["get","entry.quantity",["loc",[null,[62,48],[62,62]]]]]]],
              ["element","action",["changeQuantity",["get","entry",["loc",[null,[62,92],[62,97]]]],["get","invoice.id",["loc",[null,[62,98],[62,108]]]],["get","entry.article.name",["loc",[null,[62,109],[62,127]]]],["get","entry.quantity",["loc",[null,[62,128],[62,142]]]]],["on","focusOut"],["loc",[null,[62,66],[62,158]]]],
              ["attribute","data-qty-entry-id",["concat",[["get","entry.id",["loc",[null,[64,56],[64,64]]]]]]],
              ["element","action",["increaseQuantity",["get","entry",["loc",[null,[64,96],[64,101]]]],["get","invoice.id",["loc",[null,[64,102],[64,112]]]],["get","entry.article.name",["loc",[null,[64,113],[64,131]]]]],[],["loc",[null,[64,68],[64,133]]]],
              ["attribute","data-qty-entry-id",["concat",[["get","entry.id",["loc",[null,[66,56],[66,64]]]]]]],
              ["element","action",["decreaseQuantity",["get","entry",["loc",[null,[66,96],[66,101]]]],["get","invoice.id",["loc",[null,[66,102],[66,112]]]],["get","entry.article.name",["loc",[null,[66,113],[66,131]]]]],[],["loc",[null,[66,68],[66,133]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 68,
                  "column": 24
                },
                "end": {
                  "line": 70,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","entry.quantity",["loc",[null,[69,26],[69,44]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 57,
                "column": 22
              },
              "end": {
                "line": 71,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","edit",["loc",[null,[58,30],[58,34]]]]],[],0,1,["loc",[null,[58,24],[70,31]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child8 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 71,
                "column": 22
              },
              "end": {
                "line": 73,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","entry.quantity",["loc",[null,[72,24],[72,42]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child9 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 77,
                  "column": 24
                },
                "end": {
                  "line": 80,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"type","text");
              dom.setAttribute(el1,"class","form-control");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element19 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element19, 'data-article-text-entry-id');
              morphs[1] = dom.createAttrMorph(element19, 'value');
              morphs[2] = dom.createElementMorph(element19);
              return morphs;
            },
            statements: [
              ["attribute","data-article-text-entry-id",["concat",[["get","entry.id",["loc",[null,[78,98],[78,106]]]]]]],
              ["attribute","value",["concat",[["get","entry.article.name",["loc",[null,[79,44],[79,62]]]]]]],
              ["element","action",["changeText",["get","entry",["loc",[null,[79,88],[79,93]]]],["get","invoice.id",["loc",[null,[79,94],[79,104]]]],["get","entry.article.name",["loc",[null,[79,105],[79,123]]]],["get","entry.article.name",["loc",[null,[79,124],[79,142]]]]],["on","focusOut"],["loc",[null,[79,66],[79,158]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 80,
                  "column": 24
                },
                "end": {
                  "line": 82,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","entry.articleName",["loc",[null,[81,26],[81,47]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 76,
                "column": 22
              },
              "end": {
                "line": 83,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","is-name-editable",[["get","entry.article",["loc",[null,[77,48],[77,61]]]]],[],["loc",[null,[77,30],[77,62]]]]],[],0,1,["loc",[null,[77,24],[82,31]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child10 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 83,
                "column": 22
              },
              "end": {
                "line": 85,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","entry.articleName",["loc",[null,[84,24],[84,45]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child11 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 88,
                "column": 24
              },
              "end": {
                "line": 90,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","entry.article.unit.name",["loc",[null,[89,26],[89,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child12 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 90,
                "column": 24
              },
              "end": {
                "line": 92,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["content","entry.unitQuantity",["loc",[null,[91,26],[91,48]]]],
            ["content","entry.unit.name",["loc",[null,[91,49],[91,68]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child13 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 98,
                    "column": 30
                  },
                  "end": {
                    "line": 102,
                    "column": 30
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("input");
                dom.setAttribute(el1,"checked","");
                dom.setAttribute(el1,"type","checkbox");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element17 = dom.childAt(fragment, [1]);
                if (this.cachedFragment) { dom.repairClonedNode(element17,[],true); }
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element17, 'value');
                morphs[1] = dom.createAttrMorph(element17, 'data-applied-entry-id');
                morphs[2] = dom.createAttrMorph(element17, 'id');
                morphs[3] = dom.createElementMorph(element17);
                return morphs;
              },
              statements: [
                ["attribute","value",["concat",[["get","entry.applied",["loc",[null,[99,50],[99,63]]]]]]],
                ["attribute","data-applied-entry-id",["concat",[["get","entry.id",["loc",[null,[99,92],[99,100]]]]]]],
                ["attribute","id",["concat",["checkboxExample",["get","entry.id",["loc",[null,[100,62],[100,70]]]]]]],
                ["element","action",["changeApplied",["get","entry",["loc",[null,[101,82],[101,87]]]],["get","invoice.id",["loc",[null,[101,88],[101,98]]]],["get","entry.article.name",["loc",[null,[101,99],[101,117]]]]],[],["loc",[null,[101,57],[101,119]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 102,
                    "column": 30
                  },
                  "end": {
                    "line": 106,
                    "column": 30
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("input");
                dom.setAttribute(el1,"type","checkbox");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element16 = dom.childAt(fragment, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element16, 'value');
                morphs[1] = dom.createAttrMorph(element16, 'data-applied-entry-id');
                morphs[2] = dom.createAttrMorph(element16, 'id');
                morphs[3] = dom.createElementMorph(element16);
                return morphs;
              },
              statements: [
                ["attribute","value",["concat",[["get","entry.applied",["loc",[null,[103,50],[103,63]]]]]]],
                ["attribute","data-applied-entry-id",["concat",[["get","entry.id",["loc",[null,[103,92],[103,100]]]]]]],
                ["attribute","id",["concat",["checkboxExample",["get","entry.id",["loc",[null,[104,62],[104,70]]]]]]],
                ["element","action",["changeApplied",["get","entry",["loc",[null,[105,82],[105,87]]]],["get","invoice.id",["loc",[null,[105,88],[105,98]]]],["get","entry.article.name",["loc",[null,[105,99],[105,117]]]]],[],["loc",[null,[105,57],[105,119]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 97,
                  "column": 28
                },
                "end": {
                  "line": 108,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element18 = dom.childAt(fragment, [2]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createAttrMorph(element18, 'for');
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","entry.applied",["loc",[null,[98,36],[98,49]]]]],[],0,1,["loc",[null,[98,30],[106,37]]]],
              ["attribute","for",["concat",["checkboxExample",["get","entry.id",["loc",[null,[107,61],[107,69]]]]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 95,
                "column": 22
              },
              "end": {
                "line": 110,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","checkbox-custom checkbox-primary mb5");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","is-applied-available",[["get","entry",["loc",[null,[97,56],[97,61]]]],["get","type",["loc",[null,[97,62],[97,66]]]]],[],["loc",[null,[97,34],[97,67]]]]],[],0,null,["loc",[null,[97,28],[108,35]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child14 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 112,
                  "column": 28
                },
                "end": {
                  "line": 114,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"checked","true");
              dom.setAttribute(el1,"disabled","true");
              dom.setAttribute(el1,"type","checkbox");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              if (this.cachedFragment) { dom.repairClonedNode(dom.childAt(fragment, [1]),[],true); }
              var element15 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element15, 'id');
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",["checkboxExample",["get","entry.id",["loc",[null,[113,60],[113,68]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 114,
                  "column": 28
                },
                "end": {
                  "line": 116,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"disabled","true");
              dom.setAttribute(el1,"type","checkbox");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element14 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element14, 'id');
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",["checkboxExample",["get","entry.id",["loc",[null,[115,60],[115,68]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 110,
                "column": 22
              },
              "end": {
                "line": 119,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","checkbox-custom checkbox-disabled mb10");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"for","checkboxDefault1");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","entry.applied",["loc",[null,[112,34],[112,47]]]]],[],0,1,["loc",[null,[112,28],[116,35]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child15 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 122,
                "column": 22
              },
              "end": {
                "line": 130,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","input-group");
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("\n                                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("input");
            dom.setAttribute(el3,"type","text");
            dom.setAttribute(el3,"class","form-control price-input");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1, 1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element13, 'data-price-entry-id');
            morphs[1] = dom.createAttrMorph(element13, 'value');
            morphs[2] = dom.createElementMorph(element13);
            return morphs;
          },
          statements: [
            ["attribute","data-price-entry-id",["concat",[["get","entry.id",["loc",[null,[126,66],[126,74]]]]]]],
            ["attribute","value",["concat",[["subexpr","format-number-german-3",[["get","entry.price",["loc",[null,[127,75],[127,86]]]]],[],["loc",[null,[127,50],[127,88]]]]]]],
            ["element","action",["changePrice",["get","entry",["loc",[null,[127,113],[127,118]]]],["get","invoice.id",["loc",[null,[127,119],[127,129]]]],["get","entry.article.name",["loc",[null,[127,130],[127,148]]]]],["on","focusOut"],["loc",[null,[127,90],[127,164]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child16 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 130,
                "column": 22
              },
              "end": {
                "line": 132,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","format-currency",[],["value",["subexpr","@mut",[["get","entry.price",["loc",[null,[131,48],[131,59]]]]],[],[]]],["loc",[null,[131,24],[131,61]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child17 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 138,
                "column": 22
              },
              "end": {
                "line": 146,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","input-group");
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("\n                                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("input");
            dom.setAttribute(el3,"type","text");
            dom.setAttribute(el3,"class","form-control price-input");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1, 1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element12, 'data-gross-price-entry-id');
            morphs[1] = dom.createAttrMorph(element12, 'value');
            morphs[2] = dom.createElementMorph(element12);
            return morphs;
          },
          statements: [
            ["attribute","data-gross-price-entry-id",["concat",[["get","entry.id",["loc",[null,[142,72],[142,80]]]]]]],
            ["attribute","value",["concat",[["subexpr","format-number-german",[["subexpr","get-inclusive-price",[["get","entry.price",["loc",[null,[143,94],[143,105]]]],["get","entry.tax.percentage",["loc",[null,[143,106],[143,126]]]]],[],["loc",[null,[143,73],[143,127]]]]],[],["loc",[null,[143,50],[143,129]]]]]]],
            ["element","action",["changeGrossPrice",["get","entry",["loc",[null,[143,159],[143,164]]]],["get","entry.tax.percentage",["loc",[null,[143,165],[143,185]]]],["get","invoice.id",["loc",[null,[143,186],[143,196]]]],["get","entry.article.name",["loc",[null,[143,197],[143,215]]]]],["on","focusOut"],["loc",[null,[143,131],[143,231]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child18 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 146,
                "column": 22
              },
              "end": {
                "line": 148,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","format-currency",[],["value",["subexpr","get-inclusive-price",[["get","entry.price",["loc",[null,[147,69],[147,80]]]],["get","entry.tax.percentage",["loc",[null,[147,81],[147,101]]]]],[],["loc",[null,[147,48],[147,102]]]]],["loc",[null,[147,24],[147,104]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child19 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 152,
                  "column": 24
                },
                "end": {
                  "line": 162,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","input-group discount-spinner");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("input");
              dom.setAttribute(el2,"type","number");
              dom.setAttribute(el2,"class","form-control ui-spinner-input");
              dom.setAttribute(el2,"name","spinner");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var element9 = dom.childAt(element8, [1]);
              var element10 = dom.childAt(element8, [3]);
              var element11 = dom.childAt(element8, [5]);
              var morphs = new Array(9);
              morphs[0] = dom.createAttrMorph(element9, 'data-discount-entry-id');
              morphs[1] = dom.createAttrMorph(element9, 'value');
              morphs[2] = dom.createElementMorph(element9);
              morphs[3] = dom.createAttrMorph(element10, 'class');
              morphs[4] = dom.createAttrMorph(element10, 'data-qty-entry-id');
              morphs[5] = dom.createElementMorph(element10);
              morphs[6] = dom.createAttrMorph(element11, 'class');
              morphs[7] = dom.createAttrMorph(element11, 'data-qty-entry-id');
              morphs[8] = dom.createElementMorph(element11);
              return morphs;
            },
            statements: [
              ["attribute","data-discount-entry-id",["concat",[["get","entry.id",["loc",[null,[155,65],[155,73]]]]]]],
              ["attribute","value",["concat",[["get","entry.discount",["loc",[null,[156,48],[156,62]]]]]]],
              ["element","action",["changeDiscount",["get","entry",["loc",[null,[156,92],[156,97]]]],["get","invoice.id",["loc",[null,[156,98],[156,108]]]],["get","entry.absoluteDiscount",["loc",[null,[156,109],[156,131]]]],["get","entry.article.name",["loc",[null,[156,132],[156,150]]]],["get","entry.discount",["loc",[null,[156,151],[156,165]]]]],["on","focusOut"],["loc",[null,[156,66],[156,181]]]],
              ["attribute","class",["concat",["quantityspinn btn percentage ",["subexpr","get-relative-discount-class",[["get","entry.absoluteDiscount",["loc",[null,[157,101],[157,123]]]]],[],["loc",[null,[157,71],[157,125]]]]]]],
              ["attribute","data-qty-entry-id",["concat",[["get","entry.id",["loc",[null,[158,56],[158,64]]]]]]],
              ["element","action",["setRelativeDiscount",["get","entry",["loc",[null,[158,99],[158,104]]]],["get","invoice.id",["loc",[null,[158,105],[158,115]]]],["get","entry.discount",["loc",[null,[158,116],[158,130]]]],["get","entry.absoluteDiscount",["loc",[null,[158,131],[158,153]]]],["get","entry.article.name",["loc",[null,[158,154],[158,172]]]]],[],["loc",[null,[158,68],[158,174]]]],
              ["attribute","class",["concat",["quantityspinn btn absolute ",["subexpr","get-absolute-discount-class",[["get","entry.absoluteDiscount",["loc",[null,[159,99],[159,121]]]]],[],["loc",[null,[159,69],[159,123]]]]]]],
              ["attribute","data-qty-entry-id",["concat",[["get","entry.id",["loc",[null,[160,56],[160,64]]]]]]],
              ["element","action",["setAbsoluteDiscount",["get","entry",["loc",[null,[160,99],[160,104]]]],["get","invoice.id",["loc",[null,[160,105],[160,115]]]],["get","entry.discount",["loc",[null,[160,116],[160,130]]]],["get","entry.absoluteDiscount",["loc",[null,[160,131],[160,153]]]],["get","entry.article.name",["loc",[null,[160,154],[160,172]]]]],[],["loc",[null,[160,68],[160,174]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 163,
                    "column": 26
                  },
                  "end": {
                    "line": 165,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","format-currency",[],["value",["subexpr","@mut",[["get","entry.discount",["loc",[null,[164,52],[164,66]]]]],[],[]]],["loc",[null,[164,28],[164,68]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 165,
                    "column": 26
                  },
                  "end": {
                    "line": 167,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" %\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","entry.discount",["loc",[null,[166,28],[166,46]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 162,
                  "column": 24
                },
                "end": {
                  "line": 168,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","entry.absoluteDiscount",["loc",[null,[163,32],[163,54]]]]],[],0,1,["loc",[null,[163,26],[167,33]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 151,
                "column": 22
              },
              "end": {
                "line": 169,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","and",[["get","edit",["loc",[null,[152,35],[152,39]]]],["subexpr","not",[["subexpr","is-tgd",[["get","entry",["loc",[null,[152,53],[152,58]]]]],[],["loc",[null,[152,45],[152,59]]]]],[],["loc",[null,[152,40],[152,60]]]]],[],["loc",[null,[152,30],[152,61]]]]],[],0,1,["loc",[null,[152,24],[168,31]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child20 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 175,
                    "column": 30
                  },
                  "end": {
                    "line": 179,
                    "column": 30
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("input");
                dom.setAttribute(el1,"checked","");
                dom.setAttribute(el1,"type","checkbox");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1]);
                if (this.cachedFragment) { dom.repairClonedNode(element6,[],true); }
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element6, 'value');
                morphs[1] = dom.createAttrMorph(element6, 'data-bankadvice-entry-id');
                morphs[2] = dom.createAttrMorph(element6, 'id');
                morphs[3] = dom.createElementMorph(element6);
                return morphs;
              },
              statements: [
                ["attribute","value",["concat",[["get","entry.bankadvice",["loc",[null,[176,50],[176,66]]]]]]],
                ["attribute","data-bankadvice-entry-id",["concat",[["get","entry.id",["loc",[null,[176,98],[176,106]]]]]]],
                ["attribute","id",["concat",["bankAdvice",["get","entry.id",["loc",[null,[177,57],[177,65]]]]]]],
                ["element","action",["changeBankadvice",["get","entry",["loc",[null,[178,85],[178,90]]]],["get","invoice.id",["loc",[null,[178,91],[178,101]]]],["get","entry.article.name",["loc",[null,[178,102],[178,120]]]]],[],["loc",[null,[178,57],[178,122]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 179,
                    "column": 30
                  },
                  "end": {
                    "line": 183,
                    "column": 30
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("input");
                dom.setAttribute(el1,"type","checkbox");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element5, 'value');
                morphs[1] = dom.createAttrMorph(element5, 'data-bankadvice-entry-id');
                morphs[2] = dom.createAttrMorph(element5, 'id');
                morphs[3] = dom.createElementMorph(element5);
                return morphs;
              },
              statements: [
                ["attribute","value",["concat",[["get","entry.bankadvice",["loc",[null,[180,50],[180,66]]]]]]],
                ["attribute","data-bankadvice-entry-id",["concat",[["get","entry.id",["loc",[null,[180,98],[180,106]]]]]]],
                ["attribute","id",["concat",["bankAdvice",["get","entry.id",["loc",[null,[181,57],[181,65]]]]]]],
                ["element","action",["changeBankadvice",["get","entry",["loc",[null,[182,85],[182,90]]]],["get","invoice.id",["loc",[null,[182,91],[182,101]]]],["get","entry.article.name",["loc",[null,[182,102],[182,120]]]]],[],["loc",[null,[182,57],[182,122]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 174,
                  "column": 28
                },
                "end": {
                  "line": 185,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [2]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createAttrMorph(element7, 'for');
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","entry.bankadvice",["loc",[null,[175,36],[175,52]]]]],[],0,1,["loc",[null,[175,30],[183,37]]]],
              ["attribute","for",["concat",["bankAdvice",["get","entry.id",["loc",[null,[184,56],[184,64]]]]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 172,
                "column": 22
              },
              "end": {
                "line": 187,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","checkbox-custom checkbox-primary mb5");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","not",[["subexpr","is-deposit-invoice",[["get","invoice",["loc",[null,[174,59],[174,66]]]]],[],["loc",[null,[174,39],[174,67]]]]],[],["loc",[null,[174,34],[174,68]]]]],[],0,null,["loc",[null,[174,28],[185,35]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child21 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 189,
                  "column": 28
                },
                "end": {
                  "line": 191,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"checked","true");
              dom.setAttribute(el1,"disabled","true");
              dom.setAttribute(el1,"type","checkbox");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              if (this.cachedFragment) { dom.repairClonedNode(dom.childAt(fragment, [1]),[],true); }
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element2, 'id');
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",["checkBoxAdvice",["get","entry.id",["loc",[null,[190,59],[190,67]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 191,
                  "column": 28
                },
                "end": {
                  "line": 193,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("input");
              dom.setAttribute(el1,"disabled","true");
              dom.setAttribute(el1,"type","checkbox");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element1, 'id');
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",["checkBoxAdvice",["get","entry.id",["loc",[null,[192,59],[192,67]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 187,
                "column": 22
              },
              "end": {
                "line": 196,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","checkbox-custom checkbox-disabled mb10");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(element3, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element3,1,1);
            morphs[1] = dom.createAttrMorph(element4, 'for');
            return morphs;
          },
          statements: [
            ["block","if",[["get","entry.bankadvice",["loc",[null,[189,34],[189,50]]]]],[],0,1,["loc",[null,[189,28],[193,35]]]],
            ["attribute","for",["concat",["checkBoxAdvice",["get","entry.id",["loc",[null,[194,58],[194,66]]]]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child22 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 199,
                "column": 18
              },
              "end": {
                "line": 203,
                "column": 18
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1,"class","text-center");
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"class","btn-delete btn-danger btn icon-btn");
            dom.setAttribute(el2,"title","Löschen");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","jason-icon");
            var el4 = dom.createTextNode("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [
            ["element","action",["delete",["get","entry.id",["loc",[null,[201,47],[201,55]]]],["get","invoice.id",["loc",[null,[201,56],[201,66]]]],["get","entry.article.name",["loc",[null,[201,67],[201,85]]]]],[],["loc",[null,[201,29],[201,87]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 12
            },
            "end": {
              "line": 207,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("thead");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tr");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3,"colspan","12");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tr");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3,"class","");
          var el4 = dom.createTextNode("Datum");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3,"class","text-center");
          dom.setAttribute(el3,"style","width: 10%");
          var el4 = dom.createTextNode("Menge");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3,"style","width: 20%");
          var el4 = dom.createTextNode("Artikel/Leistung");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3,"style","");
          var el4 = dom.createTextNode("Menge / Einheit");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3,"style","width: 5%");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3,"class","text-right hide-1300");
          dom.setAttribute(el3,"style","width: 5%");
          var el4 = dom.createTextNode("Netto");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3,"class","text-right hide-1300");
          dom.setAttribute(el3,"style","width: 5%");
          var el4 = dom.createTextNode("MWST");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3,"class","pr20 text-right");
          dom.setAttribute(el3,"style","width: 8%");
          var el4 = dom.createTextNode("Brutto");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3,"class","text-center");
          dom.setAttribute(el3,"style","");
          var el4 = dom.createTextNode("Rabatt");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3,"style","width: 5%");
          var el4 = dom.createTextNode("Gutschrift");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3,"style","width: 10%");
          dom.setAttribute(el3,"class","text-right pr10");
          var el4 = dom.createTextNode("Gesamtpreis");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tbody");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tr");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3,"class","");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3,"class","text-center");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3,"class","text-center");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3,"class","text-right net hide-1300");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3,"class","text-right hide-1300");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" %\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3,"class","pr20 text-right gross");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3,"class","discount text-center");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3,"class","text-center");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3,"class","text-right pr10");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element27 = dom.childAt(fragment, [1]);
          var element28 = dom.childAt(element27, [1]);
          var element29 = dom.childAt(element27, [3]);
          var element30 = dom.childAt(fragment, [3, 1]);
          var morphs = new Array(18);
          morphs[0] = dom.createAttrMorph(element28, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element28, [1]),0,0);
          morphs[2] = dom.createAttrMorph(element29, 'class');
          morphs[3] = dom.createMorphAt(dom.childAt(element29, [9]),0,0);
          morphs[4] = dom.createMorphAt(element29,23,23);
          morphs[5] = dom.createAttrMorph(element30, 'class');
          morphs[6] = dom.createMorphAt(dom.childAt(element30, [1]),1,1);
          morphs[7] = dom.createMorphAt(dom.childAt(element30, [3]),1,1);
          morphs[8] = dom.createMorphAt(dom.childAt(element30, [5]),1,1);
          morphs[9] = dom.createMorphAt(dom.childAt(element30, [7]),1,1);
          morphs[10] = dom.createMorphAt(dom.childAt(element30, [9]),1,1);
          morphs[11] = dom.createMorphAt(dom.childAt(element30, [11]),1,1);
          morphs[12] = dom.createMorphAt(dom.childAt(element30, [13]),1,1);
          morphs[13] = dom.createMorphAt(dom.childAt(element30, [15]),1,1);
          morphs[14] = dom.createMorphAt(dom.childAt(element30, [17]),1,1);
          morphs[15] = dom.createMorphAt(dom.childAt(element30, [19]),1,1);
          morphs[16] = dom.createMorphAt(dom.childAt(element30, [21]),0,0);
          morphs[17] = dom.createMorphAt(element30,23,23);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["head ",["subexpr","get-invoice-heading-selected-class",[["get","entry.patient",["loc",[null,[27,70],[27,83]]]],["get","invoice.patients",["loc",[null,[27,84],[27,100]]]]],[],["loc",[null,[27,33],[27,102]]]]," ",["subexpr","get-invoice-heading-class",[["get","entry.patient",["loc",[null,[27,131],[27,144]]]],["get","shownHeads",["loc",[null,[27,145],[27,155]]]]],[],["loc",[null,[27,103],[27,157]]]]]]],
          ["block","unless",[["get","entry.patient",["loc",[null,[28,47],[28,60]]]]],[],0,1,["loc",[null,[28,37],[28,489]]]],
          ["attribute","class",["concat",["head ",["subexpr","get-invoice-heading-class",[["get","entry.patient",["loc",[null,[30,60],[30,73]]]],["get","shownHeads",["loc",[null,[30,74],[30,84]]]]],[],["loc",[null,[30,32],[30,86]]]]]]],
          ["block","if",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[35,48],[35,75]]]]],[],2,3,["loc",[null,[35,42],[35,112]]]],
          ["block","if",[["get","edit",["loc",[null,[42,24],[42,28]]]]],[],4,null,["loc",[null,[42,18],[44,25]]]],
          ["attribute","class",["concat",["above ",["subexpr","get-css-row-class",[["get","entry.position",["loc",[null,[48,54],[48,68]]]]],[],["loc",[null,[48,34],[48,70]]]]]]],
          ["block","if",[["get","edit",["loc",[null,[50,26],[50,30]]]]],[],5,6,["loc",[null,[50,20],[54,27]]]],
          ["block","if",[["subexpr","is-quantity-editable",[["get","entry.article",["loc",[null,[57,50],[57,63]]]]],[],["loc",[null,[57,28],[57,64]]]]],[],7,8,["loc",[null,[57,22],[73,29]]]],
          ["block","if",[["get","edit",["loc",[null,[76,28],[76,32]]]]],[],9,10,["loc",[null,[76,22],[85,29]]]],
          ["block","unless",[["get","entry.unit",["loc",[null,[88,34],[88,44]]]]],[],11,12,["loc",[null,[88,24],[92,35]]]],
          ["block","if",[["get","edit",["loc",[null,[95,28],[95,32]]]]],[],13,14,["loc",[null,[95,22],[119,29]]]],
          ["block","if",[["subexpr","and",[["get","edit",["loc",[null,[122,33],[122,37]]]],["subexpr","not",[["subexpr","is-tgd",[["get","entry",["loc",[null,[122,51],[122,56]]]]],[],["loc",[null,[122,43],[122,57]]]]],[],["loc",[null,[122,38],[122,58]]]]],[],["loc",[null,[122,28],[122,59]]]]],[],15,16,["loc",[null,[122,22],[132,29]]]],
          ["content","entry.tax.percentage",["loc",[null,[135,22],[135,46]]]],
          ["block","if",[["subexpr","and",[["get","edit",["loc",[null,[138,33],[138,37]]]],["subexpr","not",[["subexpr","is-tgd",[["get","entry",["loc",[null,[138,51],[138,56]]]]],[],["loc",[null,[138,43],[138,57]]]]],[],["loc",[null,[138,38],[138,58]]]]],[],["loc",[null,[138,28],[138,59]]]]],[],17,18,["loc",[null,[138,22],[148,29]]]],
          ["block","if",[["subexpr","is-discount-available",[["get","entry.article",["loc",[null,[151,51],[151,64]]]],["get","type",["loc",[null,[151,65],[151,69]]]]],[],["loc",[null,[151,28],[151,70]]]]],[],19,null,["loc",[null,[151,22],[169,29]]]],
          ["block","if",[["get","edit",["loc",[null,[172,28],[172,32]]]]],[],20,21,["loc",[null,[172,22],[196,29]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","entry.total",["loc",[null,[198,72],[198,83]]]]],[],[]]],["loc",[null,[198,48],[198,85]]]],
          ["block","if",[["get","edit",["loc",[null,[199,24],[199,28]]]]],[],22,null,["loc",[null,[199,18],[203,25]]]]
        ],
        locals: ["entry"],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 212,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/invoice-entries.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        dom.setAttribute(el1,"id","invoice-table");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-md-12");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("table");
        dom.setAttribute(el3,"class","table table-striped");
        dom.setAttribute(el3,"id","invoices");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("thead");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("tr");
        dom.setAttribute(el5,"class","head");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"class","");
        var el7 = dom.createTextNode("Datum");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"class","text-center");
        dom.setAttribute(el6,"style","width: 10%");
        var el7 = dom.createTextNode("Menge");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"style","width: 20%");
        var el7 = dom.createTextNode("Artikel/Leistung");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"style","");
        var el7 = dom.createTextNode("VPE");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"style","width: 5%");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"class","text-right hide-1300");
        dom.setAttribute(el6,"style","width: 5%");
        var el7 = dom.createTextNode("Netto");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"class","text-right hide-1300");
        dom.setAttribute(el6,"style","width: 5%");
        var el7 = dom.createTextNode("MWST");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"class","pr20 text-right");
        dom.setAttribute(el6,"style","width: 8%");
        var el7 = dom.createTextNode("Brutto");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"class","text-center");
        dom.setAttribute(el6,"style","");
        var el7 = dom.createTextNode("Rabatt");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"style","width: 5%");
        var el7 = dom.createTextNode("Gutschrift");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"style","width: 10%");
        dom.setAttribute(el6,"class","text-right pr10");
        var el7 = dom.createTextNode("Gesamtpreis");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element31 = dom.childAt(fragment, [0, 1, 1]);
        var element32 = dom.childAt(element31, [1]);
        var element33 = dom.childAt(element32, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element31, 'data-invoice-id');
        morphs[1] = dom.createMorphAt(dom.childAt(element33, [9]),0,0);
        morphs[2] = dom.createMorphAt(element33,23,23);
        morphs[3] = dom.createMorphAt(element32,3,3);
        morphs[4] = dom.createMorphAt(element31,3,3);
        return morphs;
      },
      statements: [
        ["attribute","data-invoice-id",["concat",[["get","invoice.id",["loc",[null,[3,76],[3,86]]]]]]],
        ["block","if",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[10,44],[10,71]]]]],[],0,1,["loc",[null,[10,38],[10,108]]]],
        ["block","if",[["get","edit",["loc",[null,[17,20],[17,24]]]]],[],2,null,["loc",[null,[17,14],[19,21]]]],
        ["block","unless",[["get","invoice.customer",["loc",[null,[21,20],[21,36]]]]],[],3,null,["loc",[null,[21,10],[23,21]]]],
        ["block","each",[["get","invoice.entries",["loc",[null,[25,20],[25,35]]]]],[],4,null,["loc",[null,[25,12],[207,21]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }()));

});