define('jason-frontend/components/media-upload-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    currentFile: null,
    actions: {
      load: function load(data) {
        this.set('showUploadButton', false);
        this.set('mediaCategory', null);
        this.set('currentFile', null);
      },
      uploadDone: function uploadDone(data) {
        this.set('data', data);
        this.sendAction('reloadMedias');
        $.magnificPopup.close();
      }
    },
    watchMediaCategory: (function () {
      if (this.get('mediaCategory')) {
        this.set('showUploadButton', true);
      }
    }).observes('mediaCategory')
  });

});