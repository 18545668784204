define('jason-frontend/routes/inventory/corrections', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    perPage: 10,
    queryParams: {},
    setupController: function setupController(controller, model) {
      controller.set("model", model);
      this.applyFilter();
    },
    actions: {
      saveCorrection: function saveCorrection(data) {
        var self = this;
        $.ajax({
          url: "/api/inventoryStocks/" + data.id + "/correction",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Korrekturbuchung', 'gespeichert');
          self.applyFilter();
          setTimeout(function () {
            self.get('controller').send('scan');
          }, 500);
        }, function (data) {
          jason.notifiction.notifiy('Korrekturbuchung', 'gespeichert');
          self.applyFilter();
          setTimeout(function () {
            self.get('controller').send('scan');
          }, 500);
        });
      },
      showStock: function showStock(id) {
        this.transitionTo('inventory.stock-details', id);
      },
      scanProduct: function scanProduct(id) {
        var controller = this.get('controller');

        controller.set('filterCode', id);
        this.applyFilter();
      },
      createRequest: function createRequest() {
        var self = this;
        $.ajax({
          url: "/api/inventoryRequests?referer=corrections",
          method: "PUT",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('inventory.bookin-request', data.inventoryRequestSupplier.id, { queryParams: { type: 'inventoryCorrections' } });
        }, function () {});
      },
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          code = controller.get('filterCode'),
          status = controller.get('filterStatus'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      controller.set('showLoading', true);

      this.findPaged('inventory-stock-movement', {
        filterCode: code,
        filterStatus: status,
        filterType: 'correction',
        to: to,
        from: from,
        pageSize: this.get('perPage')
      }).then(function (data) {
        var meta = data.meta;
        controller.set('model', data);
        controller.set('showLoading', false);
        controller.set('totalPlus', meta.total_plus);
        controller.set('totalMinus', meta.total_minus);
      });
    }
  });

});