define('jason-frontend/components/customer-revenue-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    show: function show(id) {

      var charts = $('div#customerRevenueChart');

      $.ajax({
        url: "/api/customerSaleDashboards/csvBar/" + id,
        method: "GET",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {

        var csv = data.saleBar.data;

        var lines = csv.split('\n').length;

        if (lines <= 2) {
          charts.hide();
          return;
        }

        charts.show();

        Highcharts.setOptions({
          lang: {
            decimalPoint: ',',
            thousandsSep: '.',
            months: ['Jänner', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
          }
        });

        charts.highcharts({
          colors: ["#7cb5ec", "#f7a35c", "#90ee7e", "#7798BF", "#aaeeee", "#ff0066", "#eeaaee", "#55BF3B", "#DF5353", "#7798BF", "#aaeeee"],

          series: [{
            name: 'Umsatz Brutto je Monat'
          }],
          data: {
            csv: csv
          },
          chart: {
            type: 'column'
          },
          title: {
            text: ''
          },
          xAxis: {
            title: {
              text: 'Monat'
            }
          },
          yAxis: [{
            title: {
              text: 'Umsatz Brutto in EUR'
            },
            labels: {
              align: 'left',
              x: 3,
              y: 16,
              format: '{value:.,0f}'
            },
            showFirstLabel: false
          }, {
            linkedTo: 0,
            gridLineWidth: 0,
            opposite: true,
            title: {
              text: 'EUR'
            },
            labels: {
              align: 'right',
              x: -3,
              y: 16,
              format: '{value:.,0f}'
            },
            showFirstLabel: false
          }]

        });
      });
    },
    actions: {
      load: function load(id) {

        var self = this;

        $.ajax({
          url: "/api/customerSaleDashboards/" + id,
          method: "GET",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('total', data.customerSaleDashboard.total);
          self.set('totalYear', data.customerSaleDashboard.totalYear);
          self.set('totalAverage', data.customerSaleDashboard.totalAverage);
          self.show(id);
        });
      }
    }
  });

});