define('jason-frontend/controllers/cash/dsf-export', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "DSFinV-K Export",
    breadCrumbPath: "cash.dsf",
    queryParams: ["page", "perPage"],
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),
    page: 1,
    perPage: 100,
    inProgress: false,
    to: null,
    from: null,
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('pickDate')
  });

});