define('jason-frontend/components/sale-bar-charts', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    type: null,
    actions: {
      confirm: function confirm(data) {
        this.sendAction('create', data);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(customerId, userId, userRole) {
        var self = this;
        setTimeout(function () {
          self.show(customerId, userId, userRole);
        }, 500);
      }
    },
    show: function show(customerId, userId, userRole) {

      var charts = $('div#revenueBarChart');

      $.ajax({
        url: "/api/saleDashboards/csvBar?userId=" + userId + "&customerId=" + customerId + "&userRole=" + userRole,
        method: "GET",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {

        var csv = data.saleBar.data,
            label = data.saleBar.label;

        if (!csv) {
          return;
        }

        charts.show();

        Highcharts.setOptions({
          lang: {
            numericSymbols: null,
            decimalPoint: ',',
            thousandsSep: '.',
            months: ['Jänner', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            months: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sept', 'Okt', 'Nov', 'Dez'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
          }
        });

        charts.highcharts({
          colors: ["#7cb5ec", "#f7a35c", "#90ee7e", "#7798BF", "#aaeeee", "#ff0066", "#eeaaee", "#55BF3B", "#DF5353", "#7798BF", "#aaeeee"],
          chart: {
            type: 'column'
          },
          data: {
            csv: csv
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['Umsatz in EUR netto']
          },
          colors: ["#4A4A4A", "#999999", "#ADCCD4"],
          labels: {
            items: [{
              html: label,
              style: {
                color: '#ADCCD4',
                top: '3px',
                left: '200px',
                fontSize: '18px'
              }
            }]
          },
          yAxis: {
            title: {
              text: 'EUR'
            }
          }
        });
      });
    }
  });

});