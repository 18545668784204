define('jason-frontend/components/resource-edit-form', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      save: function save() {
        this.sendAction('save');
      }
    }
  });

});