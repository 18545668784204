define('jason-frontend/helpers/get-article-name', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getArticleName = getArticleName;

  function getArticleName(params) {
    var type = params[0];
    if (type === 'product') {
      return "Produkt";
    }
    if (type === 'service') {
      return "Leistung";
    }
    if (type === 'composite') {
      return "Paket";
    }
    if (type === 'text') {
      return "Vorlagen";
    }
    return;
  }

  exports['default'] = Ember['default'].Helper.helper(getArticleName);

});