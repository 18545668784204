define('jason-frontend/components/button-hide-app-with-confirm', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'top',
    timer: 0,
    content: '',
    showStroke: false,
    color: '#fff',
    innerStroke: '#fff',
    theme: 'tooltipster-punk',
    actions: {
      confirm: function confirm() {
        var el = this.get('element'),
            $btn = $('.btn-delete', el),
            offset = $btn.position(),
            $confirm = $('.delete-confirm', el),
            height = $confirm.outerHeight(),
            width = $confirm.outerWidth();

        $confirm.css('top', offset.top - height - 20);
        $confirm.css('left', offset.left - width / 2 - 20);

        $confirm.fadeIn();
      },
      cancel: function cancel() {
        var el = this.get('element'),
            $confirm = $('.delete-confirm', el);
        $confirm.fadeOut();
      },
      'delete': function _delete() {
        var el = this.get('element'),
            $btn = $('.btn-delete', el),
            $confirm = $('.delete-confirm', el);
        $confirm.fadeOut();

        this.sendAction('confirmed', this.get('id'), this.get('hiddenForApp'));
      }
    }
  });

});