define('jason-frontend/templates/components/icon-ehapo', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 63,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/icon-ehapo.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"version","1.0");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"width","80");
        dom.setAttribute(el1,"viewBox","0 0 857.000000 242.000000");
        dom.setAttribute(el1,"preserveAspectRatio","xMidYMid meet");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"transform","translate(0.000000,206.000000) scale(0.0250000,-0.0250000)");
        dom.setAttribute(el2,"stroke","none");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M9726 8988 c-3 -40 -19 -669 -36 -1398 -29 -1241 -35 -1399 -91\n-2485 -49 -965 -139 -2363 -175 -2722 l-6 -61 716 0 716 0 0 66 c0 85 17 668\n30 1052 34 955 94 1513 204 1898 89 309 213 533 381 690 222 208 479 314 833\n344 404 34 780 -90 965 -318 67 -82 101 -145 137 -251 55 -167 63 -253 57\n-663 -6 -366 -15 -559 -62 -1265 -41 -604 -111 -1430 -131 -1527 l-5 -26 735\n0 736 0 0 92 c2 286 26 841 85 1896 89 1599 91 1735 35 2012 -83 404 -278 674\n-639 882 -303 174 -730 267 -1231 268 -488 1 -877 -73 -1195 -227 -275 -133\n-480 -314 -692 -611 -83 -116 -83 -116 -83 -77 0 51 57 1068 70 1258 6 83 29\n384 50 669 22 286 40 532 40 548 l0 28 -719 0 -719 0 -6 -72z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M3720 8399 c-716 -54 -1463 -283 -2023 -621 -691 -417 -1208 -1071\n-1473 -1864 -95 -284 -172 -639 -206 -949 -21 -190 -18 -813 5 -990 68 -524\n240 -1054 472 -1452 520 -888 1380 -1438 2560 -1637 325 -54 419 -61 870 -60\n373 0 441 2 603 22 902 111 1611 391 2157 852 109 93 334 325 426 440 347 436\n591 933 738 1501 105 405 145 725 145 1154 -1 363 -28 619 -101 935 -156 684\n-440 1212 -884 1645 -74 72 -172 161 -219 198 -591 472 -1404 759 -2335 827\n-163 12 -569 11 -735 -1z m2140 -2654 l0 -1595 410 0 410 0 0 -430 0 -430\n-410 0 -410 0 0 -470 0 -470 -430 0 -430 0 0 470 0 470 -410 0 -410 0 0 430 0\n430 410 0 410 0 0 846 c0 495 -4 844 -9 842 -5 -1 -622 -788 -1372 -1748 -749\n-960 -1363 -1746 -1363 -1746 -1 -1 -258 1 -573 5 l-572 6 1947 2492 1947\n2493 428 0 427 0 0 -1595z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M18080 7409 c-435 -29 -890 -96 -1419 -209 -255 -55 -260 -57 -266\n-83 -2 -12 -16 -105 -30 -207 -39 -280 -54 -379 -85 -544 -16 -83 -27 -153\n-25 -155 2 -2 77 24 167 57 406 150 769 248 1128 306 265 43 364 51 640 51\n249 -1 273 -2 390 -27 92 -20 149 -39 217 -73 268 -133 370 -394 251 -644 -45\n-95 -175 -223 -283 -278 -152 -78 -402 -153 -810 -243 -588 -130 -1005 -252\n-1330 -390 -258 -110 -438 -227 -624 -406 -226 -217 -347 -439 -397 -724 -20\n-116 -14 -373 11 -490 60 -279 199 -555 361 -716 310 -311 849 -449 1611 -414\n271 12 703 65 724 88 4 4 17 50 29 102 36 149 124 463 169 599 23 68 39 126\n37 128 -2 2 -64 -14 -137 -37 -280 -84 -434 -105 -713 -97 -206 5 -324 27\n-439 80 -166 77 -297 236 -333 406 -55 259 61 500 326 676 197 132 363 195\n950 365 518 149 607 184 826 325 l84 55 -5 -68 c-3 -37 -21 -371 -40 -742 -19\n-371 -40 -747 -45 -835 -21 -315 -63 -814 -75 -895 l-6 -35 695 0 695 0 5 80\nc3 44 8 233 11 420 9 556 35 1129 85 1905 65 991 70 1092 71 1339 0 108 -5\n230 -11 271 -39 260 -124 438 -281 591 -262 257 -672 408 -1258 464 -168 16\n-651 18 -871 4z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M23978 7399 c-488 -21 -975 -81 -1758 -215 -146 -25 -273 -48 -282\n-50 -17 -5 -18 -31 -18 -357 0 -1288 -127 -4278 -255 -6007 -24 -319 -54 -691\n-60 -733 l-5 -37 719 0 719 0 6 337 c8 412 75 2535 82 2581 l5 34 142 -144\nc78 -78 180 -173 227 -209 298 -233 679 -384 1110 -441 52 -7 189 -12 305 -12\n244 1 414 23 618 79 357 97 618 253 877 521 307 318 533 740 653 1220 66 262\n95 476 104 769 25 828 -237 1578 -720 2057 -250 248 -583 413 -1037 512 -421\n92 -848 121 -1432 95z m743 -1018 c357 -73 610 -236 798 -515 454 -672 313\n-1922 -275 -2438 -220 -192 -484 -291 -779 -291 -324 1 -606 117 -846 350\n-251 243 -367 508 -410 938 -15 152 -7 575 20 1045 20 341 56 728 70 742 12\n13 317 97 463 127 123 26 282 49 433 65 22 2 128 2 235 1 144 -3 220 -9 291\n-24z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M30380 7404 c-320 -23 -607 -82 -906 -185 -405 -140 -717 -329 -979\n-594 -384 -388 -611 -861 -707 -1475 -30 -198 -33 -689 -5 -870 126 -797 503\n-1383 1122 -1746 775 -453 1988 -507 2892 -128 787 329 1315 1068 1464 2049\n26 170 36 543 20 721 -42 442 -180 858 -392 1180 -188 286 -452 524 -775 700\n-294 160 -702 281 -1109 329 -126 15 -514 27 -625 19z m455 -1039 c185 -30\n349 -92 495 -186 103 -67 275 -247 337 -353 86 -145 146 -321 186 -541 30\n-163 32 -597 4 -773 -99 -620 -353 -1024 -771 -1227 -367 -178 -850 -182\n-1202 -9 -440 215 -681 634 -713 1239 -33 628 150 1208 480 1519 170 161 417\n281 669 326 133 23 387 26 515 5z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'height');
        morphs[1] = dom.createAttrMorph(element1, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","height",["concat",[["get","size",["loc",[null,[2,26],[2,30]]]]]]],
        ["attribute","fill",["concat",["#",["get","color",["loc",[null,[5,14],[5,19]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});