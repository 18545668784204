define('jason-frontend/models/reference-code', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    code: DS['default'].attr('string'),
    owner: DS['default'].attr('string'),
    validFrom: DS['default'].attr('date'),
    validTo: DS['default'].attr('date'),
    email: DS['default'].attr(''),
    mandants: DS['default'].attr('')
  });

});