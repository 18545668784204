define('jason-frontend/models/closing', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    number: DS['default'].attr('number'),
    rksvSubmitted: DS['default'].attr('boolean'),
    type: DS['default'].attr('string'),
    from: DS['default'].attr('date'),
    to: DS['default'].attr('date'),
    exportStatus: DS['default'].attr(''),
    token: DS['default'].attr(''),
    transactionId: DS['default'].attr('number'),
    cashRegister: DS['default'].belongsTo('cashRegister')
  });

});