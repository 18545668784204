define('jason-frontend/models/inventory-stock-movement', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    amount: DS['default'].attr('number'),
    stockId: DS['default'].attr('number'),
    referenceType: DS['default'].attr('string'),
    created: DS['default'].attr('date'),
    stockBefore: DS['default'].attr(''),
    stockAfter: DS['default'].attr(''),
    referenceId: DS['default'].attr('number'),
    conversionFactor: DS['default'].attr('number'),
    product: DS['default'].attr(),
    unit: DS['default'].belongsTo('serviceunit'),
    warehouse: DS['default'].attr('string'),
    type: DS['default'].attr('string'),
    status: DS['default'].attr('string'),
    subType: DS['default'].attr('string'),
    notice: DS['default'].attr('string'),
    batchNumber: DS['default'].attr('string'),
    locationInfo: DS['default'].attr('string'),
    user: DS['default'].belongsTo('user'),
    referenceText: DS['default'].attr('string'),
    cancelled: DS['default'].attr('boolean'),
    wasCancelled: DS['default'].attr('boolean')
  });

});