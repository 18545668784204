define('jason-frontend/components/add-notification-partlet', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      confirm: function confirm(id) {
        var userIds = new Array();
        var addedAtLeastOne = false;
        $('input.messageUser:checked').each(function (index, field) {
          userIds.push($(this).val());
          addedAtLeastOne = true;
        });

        if (!addedAtLeastOne) {
          jason.notifiction.error('Fehler', 'Bitte wählen Sie zumindest einen Empfänger aus');
          return;
        }

        var messageData = {
          title: this.get('title'),
          message: this.get('message'),
          receivers: userIds
        };

        if (this.get('delay') === 'at') {
          messageData.activeFrom = moment(this.get('activeFrom'), 'DD. MM. YYYY').format('YYYY-MM-DD') + ' ' + this.get('activeFromTime');
        }

        this.sendAction('confirm', messageData);
      },
      reset: function reset() {
        this.set('title', null);
        this.set('message', null);
        this.set('activeFromTime', moment().format("HH:mm"));
        this.set('activeFrom', moment().format('DD. MM. YYYY'));
        this.set('delay', 'now');

        this.set('messageUsers', ',' + API['default'].getUserId());
      }
    }
  });

});