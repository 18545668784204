define('jason-frontend/components/treatment-info-form', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    id: null,
    patientId: null,
    invoiceId: null,
    editDate: false,
    actions: {
      save: function save() {
        var data = {
          medicalHistory: $('#overlay_medicalHistory').val(),
          report: $('#overlay_report').val(),
          symptom: $('#overlay_symptom').val(),
          therapy: $('#overlay_therapy').val(),
          diagnose: $('#overlay_diagnose').val(),
          invoiceId: this.get('invoiceId'),
          patientId: this.get('patientId'),
          id: this.get('id'),
          editDate: this.get('editDate')
        };

        if (this.get('editDate')) {
          data.date = $('#treatmentDate').val();
          data.time = $('#treatmentTime').val();
        }

        this.sendAction('treatmentSaved', data);
        $.magnificPopup.close();
      },
      load: function load(id, patient, invoiceId, data, permissions, taskData, owner) {
        this.set('data', data);
        this.set('id', id);
        this.set('invoiceId', invoiceId);
        this.set('patientId', patient.id);
        this.set('patient', patient);
        this.set('taskData', taskData);
        this.set('owner', owner);
        console.log(owner);
        this.set('etigaPermissions', permissions);
      }
    }
  });

});