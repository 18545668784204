define('jason-frontend/templates/components/add-reminder-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 104,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/add-reminder-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-add-reminder-panel");
        dom.setAttribute(el1,"class","popup-basic  popup-very-large  admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"id","addReminderForm");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-heading");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","panel-title");
        var el6 = dom.createTextNode("\n                Neue Erinnerung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-body p25");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","field col l-24/24");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","new-not-title");
        dom.setAttribute(el6,"class","field-label title");
        var el7 = dom.createTextNode("Überschrift");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","field");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","reminderText");
        dom.setAttribute(el6,"class","field-label title");
        var el7 = dom.createTextNode("Erinnerungstext");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","field col l-24/24");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","reminderClosing");
        dom.setAttribute(el6,"class","field-label title");
        var el7 = dom.createTextNode("Abschlusssatz");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-footer text-right");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","submit");
        dom.setAttribute(el5,"class","icon-button icon-button--success");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function () {\n\n    $(\"#addTaskForm\").validate({\n      errorClass: \"state-error\",\n      validClass: \"state-success\",\n      errorElement: \"em\",\n      rules: {\n        'new-not-title': {\n          required: true\n        },\n        'taskComment': {\n          required: true\n        }\n      },\n      highlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n      },\n      unhighlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n      },\n      errorPlacement: function(error, element) {\n        if (element.is(\":radio\") || element.is(\":checkbox\")) {\n          element.closest('.option-group').after(error);\n        } else {\n          error.insertAfter(element.parent());\n        }\n      }\n    });\n\n    $(\"body\").delegate(\".js-date-picker\", \"focusin\", function(){\n      $(this).daterangepicker({\n        singleDatePicker: true,\n        showDropdowns: true,\n        \"locale\": {\n          \"format\": \"DD. MM. YYYY\",\n          \"daysOfWeek\": [\n            \"So\",\n            \"Mo\",\n            \"Di\",\n            \"Mi\",\n            \"Do\",\n            \"Fr\",\n            \"Sa\"\n          ],\n          \"monthNames\": [\n            \"Jänner\",\n            \"Februar\",\n            \"März\",\n            \"April\",\n            \"Mai\",\n            \"Juni\",\n            \"Juli\",\n            \"August\",\n            \"September\",\n            \"Oktober\",\n            \"November\",\n            \"Dezember\"\n          ],\n          \"firstDay\": 1\n        },\n      });\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 1]);
        var element1 = dom.childAt(element0, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]),3,3);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [3]),3,3);
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [5]),3,3);
        morphs[4] = dom.createMorphAt(dom.childAt(element0, [5, 1]),1,1);
        return morphs;
      },
      statements: [
        ["element","action",["confirm"],["on","submit"],["loc",[null,[3,31],[3,63]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","reminderHeadline",["loc",[null,[14,24],[14,40]]]]],[],[]],"class","gui-input","name","new-not-title","autocomplete","off"],["loc",[null,[14,10],[14,100]]]],
        ["inline","textarea",[],["rows",5,"value",["subexpr","@mut",[["get","reminderText",["loc",[null,[19,34],[19,46]]]]],[],[]],"class","input textarea__input full","name","reminderText","autocomplete","off"],["loc",[null,[19,10],[19,122]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","reminderClosing",["loc",[null,[24,24],[24,39]]]]],[],[]],"class","gui-input","name","reminderClosing","autocomplete","off"],["loc",[null,[24,10],[24,101]]]],
        ["inline","button-send-mail",[],["size","52","color","#ffffff","content","Senden"],["loc",[null,[31,10],[31,73]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});