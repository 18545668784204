define('jason-frontend/serializers/logbook-entry', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  exports['default'] = DS['default'].ActiveModelSerializer.extend(DS['default'].EmbeddedRecordsMixin, {
    attrs: {
      user: { embedded: 'always' },
      cashRegister: { embedded: 'always' },
      verificationStatus: { embedded: 'always' }
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      var serialized = this.serialize(record, options);
      serialized.id = record.id ? record.id : 0;
      Ember['default'].merge(hash, serialized);
    }
  });

});