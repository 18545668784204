define('jason-frontend/helpers/get-even', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getEven = getEven;

  function getEven(params) {
    var id = params[0];

    if (id % 2 == 0) {
      return 'even';
    }

    return 'uneven';
  }

  exports['default'] = Ember['default'].Helper.helper(getEven);

});