define('jason-frontend/controllers/digitalhelper', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  var dateObj = new Date();
  var day = dateObj.getDate() - 1;
  var set;

  switch (dateObj.getMonth() + 1) {
    case 1:
    case 4:
    case 7:
    case 10:
      set = "1";
      break;
    case 2:
    case 5:
    case 8:
    case 11:
      set = "2";
      break;
    default:
      set = "0";
  }
  ;

  var infos = [[//start set 0
  'Navagio Beach or Shipwreck Beach, on the coast of Zakynthos, in Greece', //belongs to picture with _0 ... and so on //maximum 110 characters
  'Hallstatt, Gmunden, Upper Austria. World Heritage Site.', 'Bahá' + "'" + 'í gardens, The Terraces seen from above, Haifa Bay in the Background. Haifa, Israel', 'Manarola - one of the oldest towns in Cingue Terre, Italian Riviera', 'City View of London', 'Central Park, Manhatten, New York City', 'Coconut Palm Trees Beside Calm Lake Silhouette, Sri Lanka', 'Dog on a frozen lake while a train is passing, Switzerland', 'Horses in a magnificent, colorful, hilly meadows - place unknown', 'Black and orange striped tabby cat', //we are at number 9 here
  'Cliffs of Moher, Liscannor, Ireland', 'Prince Edward Island, Canada', 'Dolomites, Monte Piana, Italy', 'Jackson Street Bridge, Atlanta, United States', 'The summer night sky in Tuscany (Italy) is something like this.', 'O‘ahu, United States', 'Shot from the top of the Hotel Monteleone atbower  Sunset in New Orleans.', 'Every weekend in Bangkok this large night market takes place outside of the world mall.', 'Tasiilaq, Greenland', 'Beautiful snowy meadows in Hirzel, Switzerland', 'Burj Khalifa, Dubai, United Arab Emirates', 'Cairngorm Reindeer Centre in Aviemore, Scotland, and Britain’s only free-ranging reindeer herd.', 'Singapore', 'Spiral Jetty, United States', 'Gold Coast, Australia', 'Each phase of the lunar eclipse', 'Valley of Fire State Park, United States', '"Sleeping Beauty"', 'Nothern Lights and Huskeys in Norway', 'Mesa Arch Trail, Moab, United States', 'Raising Air Balloons in Cappadocia, Turkey' //number 30
  ], [//start set 1
  '"Mensroom"', 'Aurora in Kirkjufell, Iceland', 'Haiku Stairs, Kaneohe, United States', 'Hollandsche Rading, Netherlands', 'Even dogs like playing ball at the beach.', 'Skyline of Shanghai, China', 'Areal drone shot of Tegal Island, Indonesia', 'Caravan on Silk Road in Jiuquan, DunHuang, China', 'Flamingo of Edingburgh Zoo, Scotland, United Kingdom', 'Astronaut Space Kitty', 'Shot from the Burj Khalifa in Dubai, the highest building on earth', 'Beautiful Sunset in an awesome green landscape in Skye, United Kingdom', 'Enjoing a good book from time to time', 'Rainbow lighting on a bridge in Osijek, Croatia', 'Sometimes the very best breakfast eats are the simplest. Salt Lake City, United States', 'The city, that never sleeps. New York, United States', 'Vivid Night at Sidney Opera House, Australia', 'Plage du Petit Bec, Les Portes-en-Ré, France', 'Red Panda. ZSL Whipsnade Zoo, Whipsnade, United Kingdom', 'Highway Crossing in Shanghai, China', 'Aguas Calientes, Peru', 'myJason is available everywhere: even under stunning night skies in the Atacama Desert in Chile!', 'The world is the limit. Or is it?', 'Pink toned thoughts on a hike. Altmünster am Traunsee, Österreich', 'Sunset over Tokyo, Japan', 'Regal Architecture in Cong, Ireland', 'Temple Of Poseidon, Sounio', 'An unknown white city in Greece', 'Metropolitan City of Venice, Italy', 'Cats hide and seek', 'Puppies in Marrakech, Marocco'], [//start set 2
  'Thunderstorm over New York, United States', 'Olkhon Island, Russia', 'Jabel Jais Mountain, United Arab Emirates', 'Dog enyoing the sunset in Swasey Mountain, United States', 'Singapore', 'Flamingos having a bath', 'Amazing Sunset in Avalon, NJ, USA', 'The locals call him Cyrus. Kruger National Park, South Africa', 'Blyde River Canyon, South Africa', 'Globe Trotter enyjoing the magnificant view in Lutry, Switzerland', 'Mowing the lawn in Croke Park, Dublin, Ireland', 'The famous Mam Tor winding road in Castleton, United Kingdom', 'Amazing drone shot of Siquijor, Philippines', 'Bixby Creek Bridge, Monterey, United States', 'Fight or Love? Kruger Park, South Africa', 'Canyonlands National Park, United States', 'Kite surfing under the Golden Gate Bridge, San Francisco, United States', 'California Street, San Francisco, United States', 'New York, United States', 'Driving a herd of young cattle back to their paddock in the Australian Outback.', 'Light Show in London, United Kingdom', 'Sunflowers of Colby Farm, Newbury, United States', 'Goðafoss Waterfall, Iceland', 'Swimming in Tulum, Mexico', 'A mix of salt and bacteria makes the unique colour of Laguna Rosa, Spain', 'Oia, Greece', 'This sunset in Iceland looks like it was from a different world.', 'New York through a rainy window', 'CN Tower, Toronto, Canada', 'Summer is coming on this unknown beach.', 'RED Arrow Display Team with a special manoeuver over Bournemouth, United Kingdom']];
  var credits = [[//start set 0
  '© Nikolay Draganov', //credits, belongs to picture with index 0...and so on //max 40 characters
  '© Daniel Frank', '© Konstantin Stupak', '© Pixaby, pixaby.com', '© Dominika Gregušová', '© Unknown Photographer', '© Recal Media', '© Riccardo Bresciani', '© Brian Musial', '© Shashank Kumawat', //number 9
  '© Vincent Guth', '© Scott Walsh', '© Michal Kmeť', '© Joey Kyber', '© Luca Baggio', '© Jeremy Bishop', '© Scott Webb', '© Sam Beasley', '© Filip Gielda', '© Ricardo Gomez Angel', '© Roman Logov', '© Joe Green', '© Duy Nguyen', '© Greg Rakozy', '© Jordan Gellie', '© Linda Xu', '© Jake Blucker', '© Stef Versoza', '© Sam Ekpil', '© Omer Salom', '© Daniela Cuevas' //number 30
  ], [//start set 1
  '© Mike Marquez', '© Joshua Earle', '© Kalen Emsley', '© Vincent van Zalinge', '© Andrew Pons', '© Zhang Kaiyv', '© Hanson Lu', '© Steven Kang', '© Sean Paul Kinnear', '© Becca Howard', '© Rohan Makhecha', '© Robert Lukeman', '© Ksenia Makagonova', '© Dominik Lalic', '© Brooke Lark', '© Alex Jodoin', '© Louis Amal', '© Sweet Ice Cream Photography', '© Ritchie Valens', '© Denys Nevozhai', '© Abraham Osorio', '© Johnson Wang', '© Christine Roy', '© Simon Matzinger', '© Arto Marttinnen', '© Shanna Beasley', '© Cristina Gottardi', '© Harshil Gudka', '© Federico Beccari', '© Mikhail Vasilyev', '© Anoir Chafik'], [//start set 2
  '© Ian Froome', '© Sergey Pesterev', '© Mirza Babar Baig', '© Patrick Hendry', '© Mike Enerio', '© Matthew Cabret', '© Barth Bailey', '© Geran de Klerk', '© Ashim D’Silva', '© Olivier Fahrni', '© Rémi Müller', '© Rob Bates', '© James Connolly', '© Casey Horner', '© Bas van Brandwijk', '© Caroline Dinouard', '© Sam Goodgame', '© Ragnar Vorel', '© Matteo Catanese', '© Tobias Keller', '© Christopher Burns', '© Justin Casey', '© Trevor Cole', '© Andy McCune', '© Willian Justen de Vasconcellos', '© Matt Artz', '© Luca Micheli', '© Tim Queng', '© Tim Gouw', '© Annie Gray', '© Nick Fewings']];

  var timeout;

  exports['default'] = Ember['default'].Controller.extend({
    country: 'AT',
    code: null,
    image: 'assets/images/login-backgrounds/background_' + set + '_' + day + '.jpeg',
    successMessage: true,
    uploads: new Array(),
    style: (function () {
      return 'background-image:url(%@%@)'.fmt(this.get('image'));
    }).property('image')
  });

});