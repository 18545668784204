define('jason-frontend/models/inventory-check-entry', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    result: DS['default'].attr('string'),
    productName: DS['default'].attr('string'),
    locationInfo: DS['default'].attr('string')
  });

});