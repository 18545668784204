define('jason-frontend/models/ws-log', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    created: DS['default'].attr('date'),
    text: DS['default'].attr('string'),
    mandantName: DS['default'].attr('string'),
    purchaseOrder: DS['default'].attr(''),
    title: DS['default'].attr('string')
  });

});