define('jason-frontend/helpers/get-outstanding-payment-class', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getOutstandingPaymentClass = getOutstandingPaymentClass;

  function getOutstandingPaymentClass(params) {
    return params[0] > params[1] ? 'danger' : 'success';
  }

  exports['default'] = Ember['default'].Helper.helper(getOutstandingPaymentClass);

});