define('jason-frontend/controllers/inventory/inventory-print', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Lagerwert",
    breadCrumbPath: "inventory.inventory-print",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    pickDate: moment().format("DD. MM. YYYY"),
    showFilters: true,
    page: 1,
    showLoading: false,
    perPage: 20,
    filterStatus: 'created,used',

    expiredList: [{ name: "Ja", id: "ja" }, { name: "Nein", id: "Nein" }],
    actions: {
      toggle: function toggle(entry) {
        var secondRow = $("div.table__cell[data-id=" + entry.id + "]");
        if (secondRow.hasClass('hidden')) {
          secondRow.removeClass('hidden');
          self = this;

          var controller = this,
              date = controller.get('pickDate'),
              warehouse = controller.get('filterWarehouse'),
              expired = controller.get('filterExpired');

          if (warehouse) {
            warehouse = warehouse.id;
          }
          if (expired) {
            expired = expired.id;
          }

          var filterString = '';

          if (expired) {
            filterString += '&filterExpired=' + expired;
          }
          if (warehouse) {
            filterString += '&filterWarehouse=' + warehouse;
          }

          date = moment(date, 'DD. MM. YYYY').format('YYYY-MM-DD');

          $.ajax({
            url: "/api/inventoryStocks/inventoryProduct/" + entry.get('productId') + "?filterDate=" + date + filterString,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            Ember['default'].set(entry, 'subItems', data.inventoryStock);
          }, function () {});
        } else {
          secondRow.addClass('hidden');
        }
      }
    },
    myStickyOptionsTableHead: {
      topSpacing: 160,
      zIndex: 9
    },
    watchWarehouse: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterWarehouse'),
    watchTax: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterTax'),
    watchCategory: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterProductCategory'),
    watchPickDate: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('pickDate'),
    watchExpired: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterExpired')
  });

});