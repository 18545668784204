define('jason-frontend/routes/mandants/editadmin', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  var modelHookRun;

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    beforeModel: function beforeModel() {
      modelHookRun = false;
    },
    model: function model(params) {
      return this.store.find('mandant', params.id, { reload: true });
    },
    afterModel: function afterModel(model) {
      if (!modelHookRun) {
        return model.reload();
      }
      return model;
    },
    selectTab: function selectTab(selected, mandantId) {
      var controller = this.get('controller');
      controller.set('selectTab', selected);

      if (!mandantId) {
        mandantId = controller.get('model').id;
      }

      if (selected === 'system') {
        Ember['default'].$.ajax({
          url: "/api/mandantUsers/" + mandantId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set("users", data.mandantUser);
        });
      }
      if (selected === 'invoices') {
        Ember['default'].$.ajax({
          url: "/api/mandants/invoices/" + mandantId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set("invoices", data.mandantInvoice);
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('taxes', this.store.find('tax'));

      Ember['default'].$.ajax({
        url: "/api/mandantFees/all/" + model.id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set("fees", data.mandantFee);
      });

      Ember['default'].$.ajax({
        url: "/api/mandantSimFees/all/" + model.id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set("simFees", data.mandantSimFee);
      });

      Ember['default'].$.ajax({
        url: "/api/mandantJasonNetworkFees/all/" + model.id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set("jasonNetworkFees", data.mandantJasonNetworkFee);
      });

      Ember['default'].$.ajax({
        url: "/api/mandantInvoiceRows/all/" + model.id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set("invoiceRows", data.mandantInvoiceRow);
      });

      Ember['default'].$.ajax({
        url: "/api/mandants/cashbackSettings/" + model.id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set("cashbacks", data.mandantSupplierSetting);
      });

      Ember['default'].$.ajax({
        url: "/api/mandants/customerGroupSettings/" + model.id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set("customerGroups", data.mandantSupplierSetting);
      });

      controller.set('suppliers', this.store.find('productSupplier', { filterPurchase: true, hideAll: true }));
    },
    actions: {
      editText: function editText(data) {

        var controller = this.get('controller');

        var deferred = $.ajax({
          url: "/api/mandantInvoiceTexts",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Rechnungstext', 'gespeichert');
        }, function (error) {
          jason.notifiction.notifiy('Rechnungstext', 'gespeichert');
        });
      },
      saveInvoiceSettings: function saveInvoiceSettings() {
        var self = this,
            controller = this.get('controller');

        var data = {
          invoiceCashback: controller.get('model').get('invoiceCashback'),
          invoiceCashbackReduction: controller.get('model').get('invoiceCashbackReduction'),
          invoiceTransactionFee: controller.get('model').get('invoiceTransactionFee'),
          autosendInvoice: controller.get('model').get('autosendInvoice'),
          showUid: controller.get('model').get('showUid'),
          invoiceSimFee: controller.get('model').get('invoiceSimFee'),
          invoiceCashbackDetails: controller.get('model').get('invoiceCashbackDetails'),
          invoiceEmail: controller.get('model').get('invoiceEmail'),
          taxCode: controller.get('model').get('taxCode'),
          taxNumber: controller.get('model').get('taxNumber')
        };

        var deferred = $.ajax({
          url: "/api/mandants/" + controller.get('model').get('id') + "/saveInvoiceSettings",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (error) {
          jason.notifiction.notifiy('Freigabeeinstellungen', 'gespeichert');
        });
      },
      saveUsers: function saveUsers() {
        var self = this,
            controller = this.get('controller');

        var data = {
          maxUsers: controller.get('model').get('maxUsers')
        };

        var deferred = $.ajax({
          url: "/api/mandants/" + controller.get('model').get('id') + "/userSettings",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (error) {
          jason.notifiction.notifiy('Benutzer-Einstellungen', 'gespeichert');
        });
      },
      saveSaleSettings: function saveSaleSettings() {
        var self = this,
            controller = this.get('controller');

        var data = {
          barsoi: controller.get('model').get('barsoi')
        };

        var deferred = $.ajax({
          url: "/api/mandants/" + controller.get('model').get('id') + "/saveSaleSettings",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (error) {
          jason.notifiction.notifiy('Verkaufseinstellungen', 'gespeichert');
        });
      },
      savePurchaseSettings: function savePurchaseSettings() {
        var self = this,
            controller = this.get('controller');

        var data = {
          idfNumber: controller.get('model').get('idfNumber'),
          idfNumberPrivate: controller.get('model').get('idfNumberPrivate'),
          idfNumberDrug: controller.get('model').get('idfNumberDrug')
        };

        var deferred = $.ajax({
          url: "/api/mandants/" + controller.get('model').get('id') + "/savePurchaseSettings",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (error) {
          jason.notifiction.notifiy('Einkaufeinstellungen', 'gespeichert');
        });
      },
      sendInvoice: function sendInvoice(mandantId, month, year) {
        var self = this,
            controller = this.get('controller');

        controller.set('showLoading', true);
        var deferred = $.ajax({
          url: "/api/mandants/invoices/email/" + mandantId + "/" + month + "/" + year,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Email', 'erfolgreich verschickt');
          self.selectTab('invoices', controller.get('model').id);
          controller.set('showLoading', false);
        }, function (data) {
          jason.notifiction.notifiy('Email', 'erfolgreich verschickt');
          self.selectTab('invoices', controller.get('model').id);
          controller.set('showLoading', false);
        });
      },
      downloadInvoice: function downloadInvoice(mandantId, month, year) {
        var deferred = $.ajax({
          url: "/api/mandants/invoices/" + mandantId + "/" + month + "/" + year,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/mandants/invoices/show/' + mandantId + "/" + month + "/" + year);
        });
      },
      selectTab: function selectTab(selected, mandantId) {
        this.selectTab(selected, mandantId);
      },
      addCashback: function addCashback(cashback) {
        var controller = this.get('controller'),
            model = this.get('model');

        Ember['default'].$.ajax({
          url: "/api/mandants/cashbackSetting",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(cashback),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          Ember['default'].$.ajax({
            url: "/api/mandants/cashbackSettings/" + cashback.mandantId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set("cashbacks", data.mandantSupplierSetting);
          });
        }, function () {});
      },
      deleteInvoiceRow: function deleteInvoiceRow(id) {
        var self = this;
        var mandantId = this.get('controller').get('model').id;
        var controller = this.get('controller');
        $.ajax({
          url: "/api/mandantInvoiceRows/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {}, function (error) {
          jason.notifiction.notifiy('Eintrag', 'erfolgreich gelöscht');
          Ember['default'].$.ajax({
            url: "/api/mandantInvoiceRows/all/" + mandantId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set("invoiceRows", data.mandantInvoiceRow);
          });
        });
      },
      addCustomerGroup: function addCustomerGroup(cashback) {
        var controller = this.get('controller'),
            model = this.get('model');

        Ember['default'].$.ajax({
          url: "/api/mandants/customerGroupSetting",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(cashback),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          Ember['default'].$.ajax({
            url: "/api/mandants/customerGroupSettings/" + cashback.mandantId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set("customerGroups", data.mandantSupplierSetting);
          });
        }, function () {});
      },
      addFee: function addFee(fee) {
        var controller = this.get('controller'),
            model = this.get('model');

        if (fee.type === 'fee') {
          Ember['default'].$.ajax({
            url: "/api/mandantFees",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(fee),
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            Ember['default'].$.ajax({
              url: "/api/mandantFees/all/" + fee.mandantId,
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              controller.set("fees", data.mandantFee);
            });
          }, function () {
            jason.notifiction.systemError('Fehler', 'Für diesen Monat gibt es bereits einen Eintrag');
          });
        } else if (fee.type === 'simFee') {
          Ember['default'].$.ajax({
            url: "/api/mandantSimFees",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(fee),
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            Ember['default'].$.ajax({
              url: "/api/mandantSimFees/all/" + fee.mandantId,
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              controller.set("simFees", data.mandantSimFee);
            });
          }, function () {
            jason.notifiction.systemError('Fehler', 'Für diesen Monat gibt es bereits einen Eintrag');
          });
        } else if (fee.type === 'jasonNetworkFee') {
          Ember['default'].$.ajax({
            url: "/api/mandantJasonNetworkFees",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(fee),
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            Ember['default'].$.ajax({
              url: "/api/mandantJasonNetworkFees/all/" + fee.mandantId,
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              controller.set("jasonNetworkFees", data.mandantJasonNetworkFee);
            });
          }, function () {
            jason.notifiction.systemError('Fehler', 'Für diesen Monat gibt es bereits einen Eintrag');
          });
        } else if (fee.type === 'invoicerows') {
          Ember['default'].$.ajax({
            url: "/api/mandantInvoiceRows",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(fee),
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            Ember['default'].$.ajax({
              url: "/api/mandantInvoiceRows/all/" + fee.mandantId,
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              controller.set("invoiceRows", data.mandantInvoiceRow);
            });
          }, function () {
            jason.notifiction.systemError('Fehler', 'Für diesen Monat gibt es bereits einen Eintrag');
          });
        }
      }
    }
  });

});