define('jason-frontend/models/logbook-entry', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    user: DS['default'].belongsTo('user'),
    cashRegister: DS['default'].belongsTo('cashRegister'),
    transactionId: DS['default'].attr('number'),
    created: DS['default'].attr('date'),
    info: DS['default'].attr('string'),
    userFlat: DS['default'].attr('string'),
    action: DS['default'].attr('string'),
    subAction: DS['default'].attr('string'),
    foreignType: DS['default'].attr('string'),
    foreignId: DS['default'].attr('number'),
    foreignText: DS['default'].attr('string'),
    paymentTypes: DS['default'].attr('string'),
    grandTotal: DS['default'].attr('number'),
    grandTotalRksv: DS['default'].attr('number'),
    verificationStatus: DS['default'].belongsTo('verification-status'),
    tax20: DS['default'].attr('number'),
    tax13: DS['default'].attr('number'),
    tax10: DS['default'].attr('number'),
    tax0: DS['default'].attr('number')
  });

});