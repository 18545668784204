define('jason-frontend/components/email-invoice-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    months: null,
    type: null,
    selectedEmailFromDropdown: null,
    actions: {
      send: function send(invoice) {

        var form = $('#emailInvoiceForm');

        var value = form.find('input[name=email]').val(),
            persist = form.find('#persist-email').is(':checked');

        if (value.length < 3) {
          jason.notifiction.systemError('Fehler', 'Bitte geben Sie eine gültige Email-Adresse ein');
          return;
        }

        var data = {
          email: value,
          persist: persist
        };

        if (this.get('selectedTemplate')) {
          data.templateId = this.get('selectedTemplate').id;
        }
        if (this.get('selectedPrintTemplate')) {
          data.printTemplateId = this.get('selectedPrintTemplate').id;
        }

        this.sendAction('confirm', this.get('id'), data);

        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      loadTextmodules: function loadTextmodules(data) {
        this.set('templates', data);
        this.set('selectedTemplate', null);
      },
      loadPrintTemplates: function loadPrintTemplates(data) {
        this.set('printTemplates', data);
        if (data && data != null && data.length && data.length > 0) {
          this.set('selectedPrintTemplate', data[0]);
        }
      },
      loadWithEmails: function loadWithEmails(id, emails) {
        this.set('id', id);
        this.set('emails', emails);
        if (emails && emails != null && emails.length && emails.length > 0) {
          this.set('selectedEmailFromDropdown', emails[0]);
          this.set('email', emails[0].value);
        }
      },
      load: function load(id, email) {
        this.set('id', id);
        this.set('email', email);
      }
    },
    watchEmails: (function () {
      this.set('email', this.get('selectedEmailFromDropdown').value);
    }).observes('selectedEmailFromDropdown')
  });

});