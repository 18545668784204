define('jason-frontend/templates/components/button-add-entry2', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 3,
              "column": 73
            }
          },
          "moduleName": "jason-frontend/templates/components/button-add-entry2.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","14");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[3,40],[3,45]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-add-entry2.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","in__icon");
        dom.setAttribute(el3,"fill-rule","evenodd");
        dom.setAttribute(el3,"d","M13.72125,1.18207143 L0.59625,1.18207143 C0.268125,1.18207143 0,0.917428571 0,0.590571429 C0,0.265571429 0.2671875,0 0.59625,0 L13.72125,0 C14.05125,0 14.3184375,0.264642857 14.3184375,0.590571429 C14.3184375,0.917428571 14.05125,1.18207143 13.72125,1.18207143 L13.72125,1.18207143 Z M13.72125,4.13678571 L0.59625,4.13678571 C0.268125,4.13678571 0,3.87214286 0,3.54528571 C0,3.22028571 0.2671875,2.95471429 0.59625,2.95471429 L13.72125,2.95471429 C14.05125,2.95471429 14.3184375,3.21935714 14.3184375,3.54528571 C14.3184375,3.87028571 14.05125,4.13678571 13.72125,4.13678571 Z M7.7559375,7.08964286 L0.59625,7.08964286 C0.268125,7.09057143 0,6.82685714 0,6.5 C0,6.175 0.2671875,5.90942857 0.59625,5.90942857 L7.7559375,5.90942857 C8.0859375,5.90942857 8.3521875,6.17407143 8.3521875,6.5 C8.3521875,6.825 8.085,7.09057143 7.755,7.09057143 L7.7559375,7.08964286 Z M11.3353125,10.0471429 L13.72125,10.0471429 C14.05125,10.0471429 14.3184375,9.78342857 14.3184375,9.45657143 C14.3184375,9.12971429 14.05125,8.86507143 13.72125,8.86507143 L11.3353125,8.86507143 L11.3353125,6.5 C11.3353125,6.17407143 11.0671875,5.90942857 10.7390625,5.90942857 C10.4090625,5.90942857 10.141875,6.175 10.141875,6.5 L10.141875,8.86507143 L7.7559375,8.86507143 C7.4259375,8.86507143 7.15875,9.12971429 7.15875,9.45657143 C7.15875,9.78342857 7.4259375,10.0471429 7.7559375,10.0471429 L10.141875,10.0471429 L10.141875,12.4094286 C10.141875,12.7362857 10.4090625,13 10.74,13 C11.0690625,13 11.3353125,12.7362857 11.3353125,12.4094286 L11.3353125,10.0471429 Z M4.771875,10.0471429 L0.5971875,10.0471429 C0.2690625,10.0471429 0.0009375,9.78342857 0.0009375,9.45657143 C0.0009375,9.12971429 0.268125,8.86507143 0.5971875,8.86507143 L4.771875,8.86507143 C5.101875,8.86507143 5.3690625,9.12971429 5.3690625,9.45657143 C5.3690625,9.78342857 5.101875,10.0471429 4.7728125,10.0471429 L4.771875,10.0471429 Z");
        dom.setAttribute(el3,"transform","translate(9 10)");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createMorphAt(element2,1,1);
        morphs[3] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,10],[3,20]]]]],[],0,null,["loc",[null,[3,4],[3,80]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[4,35],[4,40]]]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});