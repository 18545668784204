define('jason-frontend/components/button-labor', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, Ember, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'top',
    timer: 0,
    color: '#4A4A4A',
    active: false,
    content: 'Verrechnete Laborleistungen anzeigen',
    theme: 'tooltipster-punk',
    actions: {
      toggle: function toggle() {
        this.sendAction('toggleAll', true);
        if (this.get('active')) {
          this.set('active', false);
          this.sendAction('toggle', 'labor', false);
        } else {
          this.set('active', true);
          this.sendAction('toggle', 'labor', true);
        }
      }
    }
  });

});