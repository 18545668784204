define('jason-frontend/templates/components/button-quickaction-etiga', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-quickaction-etiga.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"version","1.1");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"xmlns:xlink","http://www.w3.org/1999/xlink");
        dom.setAttribute(el1,"x","0px");
        dom.setAttribute(el1,"y","0px");
        dom.setAttribute(el1,"viewBox","0 0 37 42");
        dom.setAttributeNS(el1,"http://www.w3.org/XML/1998/namespace","xml:space","preserve");
        dom.setAttribute(el1,"class","quickaction-icon");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(1 1)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("circle");
        dom.setAttribute(el3,"class","circle");
        dom.setAttribute(el3,"cx","16");
        dom.setAttribute(el3,"cy","16");
        dom.setAttribute(el3,"r","16");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","scale(0.015,-0.015) translate(550,-1600)");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el4,"d","M391 1064 c-95 -21 -159 -54 -227 -117 -112 -104 -164 -238 -164 -428 0 -130 12 -197 47 -268 56 -110 143 -182 268 -223 64 -21 88 -23 270 -23 162 0 211 4 258 18 67 21 75 34 84 138 7 79 2 83 -83 63 -32 -7 -100 -20 -151 -29 -89 -15 -95 -15 -185 6 -87 20 -96 25 -144 73 -53 52 -77 106 -90 202 -11 76 -21 74 380 74 298 0 355 2 366 15 8 10 11 47 8 117 -2 86 -7 112 -30 160 -34 74 -107 151 -172 183 -107 52 -296 69 -435 39z m272 -182 c37 -16 49 -29 70 -73 36 -75 42 -113 22 -133 -14 -14 -45 -16 -220 -16 -112 0 -210 3 -219 6 -34 13 -2 114 54 169 75 73 192 92 293 47z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n   ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element2, 'stroke');
        morphs[3] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[2,30],[2,34]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[2,48],[2,52]]]]]]],
        ["attribute","stroke",["concat",[["get","color",["loc",[null,[4,60],[4,65]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[6,17],[6,22]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});