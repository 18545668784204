define('jason-frontend/helpers/divide', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.divide = divide;

  function divide(params) {
    return params[0] / params[1];
  }

  exports['default'] = Ember['default'].Helper.helper(divide);

});