define('jason-frontend/routes/treatmentrooms/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",

    perPage: 10,
    page: 1,
    model: function model(params) {
      return this.findPaged('treatmentRoom', params);
    },
    setupController: function setupController(controller, model) {
      controller.set("model", model);
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      this.findPaged('treatmentRoom', {
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10,
        paged: true
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    actions: {
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      'delete': function _delete(id) {
        var controller = this.get('controller'),
            self = this;
        this.get('store').find('treatmentRoom', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.applyFilter();
        }, 1000);
      }
    }
  });

});