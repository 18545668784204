define('jason-frontend/controllers/profile', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Mein Profil",
    breadCrumbPath: "profile",
    themes: [{
      id: "elovet", name: 'elovet (grau)'
    }, {
      id: "eltiga", name: 'eltiga (blau)'
    }, {
      id: "elord", name: 'elord (rot)'
    }, {
      id: "barbie", name: 'bonbon (pink)'
    }, {
      id: "pride", name: 'pride (regenbogen)'
    }],
    defaultSlotDurations: [{
      id: "15", name: 'Große Darstellung'
    }, {
      id: "30", name: 'Kleine Darstellung'
    }],
    landingPages: [{
      id: "dashboard", name: 'Dashboard'
    }, {
      id: "overview", name: 'Praxis / Überblick'
    }, {
      id: "calendar", name: 'Kalender'
    }, {
      id: "promotions", name: 'Angebote'
    }]
  });

});