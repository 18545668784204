define('jason-frontend/helpers/is-global-discount-available', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isGlobalDiscountAvailable = isGlobalDiscountAvailable;

  function isGlobalDiscountAvailable(params) {
    var invoice = params[0],
        type = params[1];

    if (invoice && invoice.get('deposit')) {
      return false;
    }
    return true;
  }

  exports['default'] = Ember['default'].Helper.helper(isGlobalDiscountAvailable);

});