define('jason-frontend/templates/users/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 6
              },
              "end": {
                "line": 9,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/users/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/add-entry.svg");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("Neue Mitarbeiter:in");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 2
            },
            "end": {
              "line": 11,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/users/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element6, 'class');
          morphs[1] = dom.createMorphAt(element6,1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["buttons-box col l-",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[6,55],[6,68]]]],"4","10"],[],["loc",[null,[6,34],[6,79]]]],"/24"]]],
          ["block","link-to",["users.create"],["class","with-icon button context primary"],0,null,["loc",[null,[7,6],[9,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 8
            },
            "end": {
              "line": 40,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/users/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-1/24");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","table__title title");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-8/24");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","table__title title");
          var el3 = dom.createTextNode("Name");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-8/24");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","table__title title");
          var el3 = dom.createTextNode("Email");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-1/24");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","table__title title");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell table__cell--center l-2/24");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","table__title title");
          var el3 = dom.createTextNode("Status");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-4/24");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","table__title title");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 18
              },
              "end": {
                "line": 49,
                "column": 18
              }
            },
            "moduleName": "jason-frontend/templates/users/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","cloudinary-tag",[["get","user.picture.cdnId",["loc",[null,[48,37],[48,55]]]]],["crop","fit","class","img-responsive ib"],["loc",[null,[48,20],[48,94]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 18
              },
              "end": {
                "line": 51,
                "column": 18
              }
            },
            "moduleName": "jason-frontend/templates/users/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"class","img-responsive ib");
            dom.setAttribute(el1,"src","assets/images/users/default.png");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 22
              },
              "end": {
                "line": 55,
                "column": 111
              }
            },
            "moduleName": "jason-frontend/templates/users/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","user.title.name",["loc",[null,[55,55],[55,74]]]],
            ["content","user.firstname",["loc",[null,[55,75],[55,93]]]],
            ["content","user.lastname",["loc",[null,[55,94],[55,111]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 34
                },
                "end": {
                  "line": 62,
                  "column": 127
                }
              },
              "moduleName": "jason-frontend/templates/users/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","colorTile");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [0]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element1, 'style');
              return morphs;
            },
            statements: [
              ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[62,106],[62,116]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 127
                },
                "end": {
                  "line": 62,
                  "column": 181
                }
              },
              "moduleName": "jason-frontend/templates/users/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [0]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element0, 'class');
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["colorTile ",["get","user.color",["loc",[null,[62,160],[62,170]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 62,
                "column": 16
              },
              "end": {
                "line": 62,
                "column": 188
              }
            },
            "moduleName": "jason-frontend/templates/users/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","user.colorHex",["loc",[null,[62,40],[62,53]]]]],[],0,1,["loc",[null,[62,34],[62,188]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 65,
                "column": 22
              },
              "end": {
                "line": 65,
                "column": 46
              }
            },
            "moduleName": "jason-frontend/templates/users/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("aktiv");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 65,
                "column": 46
              },
              "end": {
                "line": 65,
                "column": 61
              }
            },
            "moduleName": "jason-frontend/templates/users/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("inaktiv");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 72,
                "column": 16
              },
              "end": {
                "line": 74,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/users/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("Bearbeiten");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[73,18],[73,84]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child7 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 76,
                  "column": 18
                },
                "end": {
                  "line": 78,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/users/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              var el2 = dom.createTextNode("Rechte");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","button-permission",[],["size","32","content","Berechtigungen ändern","color","#fff","showStroke",false],["loc",[null,[77,20],[77,113]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 75,
                "column": 16
              },
              "end": {
                "line": 79,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/users/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["users.permission",["get","user",["loc",[null,[76,48],[76,52]]]]],["class","icon-button icon-button--default list-action-square exp"],0,null,["loc",[null,[76,18],[78,30]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 10
            },
            "end": {
              "line": 82,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/users/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center table__cell--image l-6/24 l-1/24@desk");
          dom.setAttribute(el2,"data-label","Bild");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--name l-18/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Name");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","sub");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-16/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Email");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-4/24 table__cell--center l-1/24@desk");
          dom.setAttribute(el2,"data-label","Farbe");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-3/24 table__cell--center l-2/24@desk");
          dom.setAttribute(el2,"data-label","Aktiv");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-24/24 l-4/24@desk buttons-3");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--delete list-action-square exp");
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [3]);
          var element4 = dom.childAt(element2, [11]);
          var element5 = dom.childAt(element4, [1]);
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(dom.childAt(element2, [1, 1]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [5, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element2, [7]),1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element2, [9, 1]),0,0);
          morphs[6] = dom.createMorphAt(element5,1,1);
          morphs[7] = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
          morphs[8] = dom.createMorphAt(element4,3,3);
          morphs[9] = dom.createMorphAt(element4,4,4);
          return morphs;
        },
        statements: [
          ["block","if",[["get","user.picture",["loc",[null,[47,24],[47,36]]]]],[],0,1,["loc",[null,[47,18],[51,25]]]],
          ["block","link-to",["users.edit",["get","user.id",["loc",[null,[55,46],[55,53]]]]],[],2,null,["loc",[null,[55,22],[55,123]]]],
          ["content","user.userRole.name",["loc",[null,[56,34],[56,56]]]],
          ["content","user.username",["loc",[null,[59,20],[59,37]]]],
          ["block","if",[["get","user.color",["loc",[null,[62,22],[62,32]]]]],[],3,null,["loc",[null,[62,16],[62,195]]]],
          ["block","if",[["get","user.active",["loc",[null,[65,28],[65,39]]]]],[],4,5,["loc",[null,[65,22],[65,68]]]],
          ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","user",["loc",[null,[69,96],[69,100]]]]],[],[]],"confirmed","delete","text","Wollen Sie diesen Mitarbeiter wirklich löschen?"],["loc",[null,[69,18],[69,176]]]],
          ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","user",["loc",[null,[70,49],[70,53]]]]],[],[]],"confirmed","delete","textToShow","Löschen","text","Wollen Sie diesen Mitarbeiter wirklich löschen?"],["loc",[null,[70,24],[70,150]]]],
          ["block","link-to",["users.edit",["get","user.id",["loc",[null,[72,40],[72,47]]]]],["class","icon-button icon-button--default list-action-square exp"],6,null,["loc",[null,[72,16],[74,28]]]],
          ["block","if",[["subexpr","has-permission",["u_permissions"],[],["loc",[null,[75,22],[75,54]]]]],[],7,null,["loc",[null,[75,16],[79,23]]]]
        ],
        locals: ["user"],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 96,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/users/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-14/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","site-content card with-context-bar");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-4/24@desk l-24/24 box@tablet no-box@phone");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","u-text--center");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title");
        var el5 = dom.createElement("span");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" / ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Aktive Benutzer");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-24/24 box@phone upgrade-panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","clearfix");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/jason-plus.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Add-On: elovet");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("br");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("Named-User Lizenzen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"class","button context");
        var el6 = dom.createTextNode("Upgrade");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper box no-box@phone");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table grid table--large");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__head");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__body");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button icon-button--small");
        dom.setAttribute(el2,"onclick","history.back()");
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [0]);
        var element8 = dom.childAt(fragment, [2]);
        var element9 = dom.childAt(element8, [1, 1, 1]);
        var element10 = dom.childAt(element9, [0]);
        var element11 = dom.childAt(element8, [3, 1]);
        var element12 = dom.childAt(element11, [3, 3]);
        var element13 = dom.childAt(element8, [5]);
        var element14 = dom.childAt(element13, [1]);
        var morphs = new Array(13);
        morphs[0] = dom.createAttrMorph(element7, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element7, [1]),1,1);
        morphs[2] = dom.createMorphAt(element7,3,3);
        morphs[3] = dom.createAttrMorph(element10, 'class');
        morphs[4] = dom.createMorphAt(element10,0,0);
        morphs[5] = dom.createMorphAt(element9,2,2);
        morphs[6] = dom.createElementMorph(element11);
        morphs[7] = dom.createElementMorph(element12);
        morphs[8] = dom.createMorphAt(dom.childAt(element14, [1]),1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element14, [3]),1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element13, [3]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(fragment, [4, 1]),1,1);
        morphs[12] = dom.createMorphAt(fragment,6,6,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["context-bar grid ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,50],[1,63]]]],"sidebar",""],[],["loc",[null,[1,29],[1,78]]]]]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[3,28],[3,38]]]]],[],[]],"placeHolder","Mitarbeiterin suchen","search","applyFilter"],["loc",[null,[3,4],[3,96]]]],
        ["block","if",[["get","contingent.createAllowed",["loc",[null,[5,8],[5,32]]]]],[],0,null,["loc",[null,[5,2],[11,9]]]],
        ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","contingent.createAllowed",["loc",[null,[17,66],[17,90]]]],"is-success","is-error"],[],["loc",[null,[17,45],[17,116]]]]]]],
        ["content","contingent.activeUsers",["loc",[null,[17,118],[17,144]]]],
        ["content","contingent.maxUsers",["loc",[null,[17,154],[17,177]]]],
        ["element","action",["openUpgradePanel"],[],["loc",[null,[22,26],[22,55]]]],
        ["element","action",["openUpgradePanel"],[],["loc",[null,[26,39],[26,68]]]],
        ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[33,36],[33,60]]]]],[],[]]],1,null,["loc",[null,[33,8],[40,29]]]],
        ["block","each",[["get","model",["loc",[null,[43,18],[43,23]]]]],[],2,null,["loc",[null,[43,10],[82,19]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[86,31],[86,38]]]]],[],[]],"numPagesToShow",7],["loc",[null,[86,8],[86,57]]]],
        ["inline","button-prev",[],["color","#ffffff","size","44"],["loc",[null,[92,70],[92,111]]]],
        ["inline","upgrade-panel",[],["type","users","refresh","applyFilter","actionReceiver",["subexpr","@mut",[["get","upgradePanel",["loc",[null,[95,66],[95,78]]]]],[],[]]],["loc",[null,[95,0],[95,80]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});