define('jason-frontend/helpers/get-stock-color', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getStockColor = getStockColor;

  function getStockColor(params) {
    var qty = params[0];
    var total = params[1];

    if (qty < 1) {
      return 'stock-out';
    } else if (qty < total) {
      return 'stock-low';
    }
    return 'stock-full';
  }

  exports['default'] = Ember['default'].Helper.helper(getStockColor);

});