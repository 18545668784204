define('jason-frontend/routes/practicemgmt/treatment-journal', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {
      controller.set('doctors', this.store.find('user', { role: 'doctor', per_page: 100 }));
      controller.set('assistants', this.store.find('user', { role: 'assistant', per_page: 100 }));
      controller.set('rooms', this.store.find('treatment-room'));
      controller.set('patientCategories', this.store.find('patient-category'));

      $.ajax({
        url: "/api/productCategories?suggest=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('productCategories', data.productCategory.sortBy('text'));
        controller.set('filterProductCategory', null);
      });
      $.ajax({
        url: "/api/serviceCategories?suggest=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('serviceCategories', data.serviceCategory.sortBy('text'));
        controller.set('filterServiceCategory', null);
      });
      controller.set('suppliers', this.store.find('product-supplier', { filterPurchase: true }));
      this.applyFilter();
    },
    actions: {
      selectSlopingBarcode: function selectSlopingBarcode(entryId, format) {
        window.open('/api/treatmentOffers/downloadSloping/' + entryId + '/_logo?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      gotoReport: function gotoReport(tpid, id) {
        this.transitionTo('editor.edit', tpid, 'treatmentreport', id);
      },
      toggleHide: function toggleHide(value) {
        var controller = this.get('controller');
        controller.set('filterHide', value);
        this.applyFilter();
      },
      toggleQuickfilter: function toggleQuickfilter(filter) {
        var controller = this.get('controller');
        if (controller.get(filter) === true) {
          controller.set(filter, false);
        } else {
          controller.set(filter, true);
        }
        this.applyFilter();
      },
      reloadHistory: function reloadHistory(showLab, showProduct, showService, showFood) {
        var controller = this.get('controller');
        controller.set('filterLab', showLab);
        controller.set('filterProducts', showProduct);
        controller.set('filterServices', showService);
        controller.set('filterFood', showFood);
        this.applyFilter();
      },
      employeesSaved: function employeesSaved(data) {
        $.ajax({
          url: "/api/treatmentPatients/changeEmployees",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (result) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      roomSaved: function roomSaved(data) {
        $.ajax({
          url: "/api/treatmentPatients/updateRoom",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (result) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      deleteTreatment: function deleteTreatment(id) {
        var controller = this.get('controller'),
            self = this;

        var deferred = $.ajax({
          url: "/api/treatmentPatients/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Behandlung entfernt', '');
          self.applyFilter();
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      download: function download() {

        var controller = this.get('controller'),
            dateRange = controller.get('pickDate'),
            dateFromTo = dateRange.split('-'),
            filterDoctor = controller.get('filterDoctor'),
            filterAssistant = controller.get('filterAssistant'),
            filterCustomer = controller.get('filterCustomer'),
            filterPatient = controller.get('filterPatient'),
            filterProduct = controller.get('filterProduct'),
            filterService = controller.get('filterService'),
            filterHide = controller.get('filterHide'),
            filterRoom = controller.get('filterRoom');

        var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
            to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

        if (filterPatient) {
          filterPatient = filterPatient.id;
        }
        if (filterCustomer) {
          filterCustomer = filterCustomer.id;
        }
        if (filterProduct) {
          filterProduct = filterProduct.id;
        }
        if (filterService) {
          filterService = filterService.id;
        }
        if (filterRoom) {
          filterRoom = filterRoom.id;
        }

        var appx = "&per_page=100000&filterFrom=" + from + "&filterTo=" + to;

        if (filterCustomer) {
          appx += "&filterCustomer=" + filterCustomer;
        }
        if (filterPatient) {
          appx += "&filterPatient=" + filterPatient;
        }
        if (filterDoctor) {
          appx += "&filterDoctor=" + filterDoctor;
        }
        if (filterRoom) {
          appx += "&filterRoom=" + filterRoom;
        }
        if (filterAssistant) {
          appx += "&filterAssistant=" + filterAssistant;
        }
        if (filterService) {
          appx += "&filterService=" + filterService;
        }
        if (filterProduct) {
          appx += "&filterProduct=" + filterProduct;
        }
        if (filterHide) {
          appx += "&filterHide=" + filterHide;
        }

        window.open('/api/treatmentPatients/downloadHistoryData?mandantId=' + API['default'].getMandant() + appx + '&downloadToken=' + API['default'].getDownloadToken());
      },
      saveTreatment: function saveTreatment(data) {
        var self = this;
        $.ajax({
          url: "/api/patientTreatmentInfos",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          self.applyFilter();
        }, function () {
          jason.notifiction.systemError('Fehlercode 109', 'Eingaben konnte nicht gespeichert werden!');
        });
      },
      updatePartnerCategories: function updatePartnerCategories() {
        var controller = this.controller;
        var selectedSupplier = controller.get('filterSupplier');

        if (selectedSupplier) {
          $.ajax({
            url: "/api/purchaseProducts/partnerCategories/" + selectedSupplier.id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set('partnerCategories', data.partnerCategory);
          });
        }
      },
      queryProducts: function queryProducts(query, deferred) {
        this.store.find('product', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      queryProductsPurchase: function queryProductsPurchase(query, deferred) {
        this.store.find('product', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      queryServices: function queryServices(query, deferred) {
        this.store.find('service', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      gotoProduct: function gotoProduct(id) {
        this.transitionTo('products.edit', id, { queryParams: { selectTab: "sloping" } });
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      queryCustomer: function queryCustomer(query, deferred) {
        this.get('store').find('customer', { filterName: query.term }).then(deferred.resolve, deferred.reject);
      },
      reloadPatients: function reloadPatients(customerId) {
        var self = this;
        $.ajax({
          url: "/api/patients/forcustomer/" + customerId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('addTaskPanel').send('loadPatients', data.patient);
        });
      }
    },
    applyFilter: function applyFilter() {

      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-'),
          filterDoctor = controller.get('filterDoctor'),
          filterAssistant = controller.get('filterAssistant'),
          filterCustomer = controller.get('filterCustomer'),
          filterPatient = controller.get('filterPatient'),
          filterProduct = controller.get('filterProduct'),
          filterService = controller.get('filterService'),
          filterHide = controller.get('filterHide'),
          filterServiceCategory = controller.get('filterServiceCategory'),
          filterProductCategory = controller.get('filterProductCategory'),
          filterPatientCategory = controller.get('filterPatientCategory'),
          filterRoom = controller.get('filterRoom');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      if (filterPatient) {
        filterPatient = filterPatient.id;
      }
      if (filterCustomer) {
        filterCustomer = filterCustomer.id;
      }
      if (filterService) {
        filterService = filterService.id;
      }
      if (filterServiceCategory) {
        filterServiceCategory = filterServiceCategory.id;
      }
      if (filterProductCategory) {
        filterProductCategory = filterProductCategory.id;
      }
      if (filterPatientCategory) {
        filterPatientCategory = filterPatientCategory.id;
      }
      if (filterProduct) {
        filterProduct = filterProduct.id;
      }
      if (filterRoom) {
        filterRoom = filterRoom.id;
      }

      controller.set('to', to);
      controller.set('from', from);

      controller.set('showLoading', true);

      this.findPaged('treatment-patient', {
        filterFrom: from,
        filterTo: to,
        cached: true,
        per_page: 50,
        filterPatient: filterPatient,
        filterCustomer: filterCustomer,
        filterDoctor: filterDoctor,
        filterAssistant: filterAssistant,
        filterService: filterService,
        filterServiceCategory: filterServiceCategory,
        filterProductCategory: filterProductCategory,
        filterPatientCategory: filterPatientCategory,
        filterProduct: filterProduct,
        filterRoom: filterRoom,
        filterHide: filterHide,
        filterLab: controller.get('filterLab'),
        filterProducts: controller.get('filterProducts'),
        filterServices: controller.get('filterServices'),
        filterFood: controller.get('filterFood')
      }).then(function (transactions) {
        controller.set('meta', transactions.meta);
        controller.set('model', transactions);
        controller.set('showLoading', false);
      });
    }
  });

});