define('jason-frontend/helpers/multiply', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.multiply = multiply;

  function multiply(params) {
    return Math.round(params[0] * params[1] * 100) / 100;
  }

  exports['default'] = Ember['default'].Helper.helper(multiply);

});