define('jason-frontend/templates/components/button-lawyer', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 6
            },
            "end": {
              "line": 6,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/button-lawyer.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"class","circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","16");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element4, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[5,64],[5,69]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 0
            },
            "end": {
              "line": 21,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/button-lawyer.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","delete-confirm medium-confirm");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","footer");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","button");
          dom.setAttribute(el3,"href","#");
          var el4 = dom.createTextNode("Nein");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","button");
          dom.setAttribute(el3,"href","#");
          var el4 = dom.createTextNode("Ja");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element0,1,1);
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [
          ["content","text",["loc",[null,[15,4],[15,12]]]],
          ["element","action",["cancel"],[],["loc",[null,[17,9],[17,28]]]],
          ["element","action",["delete"],[],["loc",[null,[18,9],[18,28]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-lawyer.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"class","btn-delete");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el2 = dom.createElement("svg");
        dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el2,"class","cursor");
        dom.setAttribute(el2,"viewBox","-1 -1 34 34");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"fill","none");
        dom.setAttribute(el3,"fill-rule","evenodd");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("g");
        dom.setAttribute(el4,"fill-rule","evenodd");
        dom.setAttribute(el4,"transform","translate(6,6) scale(0.04,0.04)");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"d","M369.378,233.765l1.18-2.247c0-0.004,0.034-0.091,0.034-0.091l77.88-148.26c0.104-0.16,5.378-10.214,10.773,0.026    l77.637,147.8c1.596,3.179,6.506,14.469-5.014,14.469l-155.048-0.026c-0.018,0-0.018,0-0.018,0    C365.509,245.427,367.539,237.733,369.378,233.765L369.378,233.765z M197.229,231.926l0.008,0.009l1.206,2.32    c1.865,3.852,4.68,11.19-1.206,11.19l-167.066-0.018c-5.543,0-4.441-5.07-2.676-9.168l2.65-5.027l0.026-0.043l79.641-151.643    c0,0,4.068-6.289,7.377,0h0.009L197.229,231.926L197.229,231.926z M566.462,237.204l-0.243-0.555l0.139-0.104l-1.752-3.357    L470.262,53.57c-2.567-4.688-11.537-18.459-28.782-18.459H125.464c-19.24,0-28.869,18.958-29.081,19.401L3.735,230.915    l-0.035,0.078l-0.659,1.245c-1.553,3.071-5,11.42-1.579,19.665c23.538,38.207,65.501,63.848,113.67,63.848    c47.094,0,88.18-24.565,111.987-61.363c3.643-8.358-1.431-18.828-1.431-18.828L138.038,68.637l-0.854-1.618    c-1.184-2.719-2.233-7.898,7.165-7.898h114.928c3.253,0.108,12.344,1.722,12.344,16.941v395.443    c-0.217,3.903-1.7,11.85-9.846,16.863l-57.529,35.583c0,0-15.094,9.524,0,9.524h163.258c11.121,0,0-7.721,0-7.721l-59.269-36.276    c-0.034-0.018-0.034-0.035-0.052-0.052c-9.75-5.969-11.572-13.48-11.832-17.28V74.375c0-13.753,9.854-15.171,13.324-15.254    h112.373c2.933,0.056,11.901,1.032,6.263,11.823l-87.993,167.508c0,0-2.759,6.593,0.347,13.823    c23.56,37.999,65.354,63.475,113.375,63.475c48.109,0,90.059-25.528,113.602-63.653    C569.983,245.952,567.347,239.13,566.462,237.204L566.462,237.204z");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element7, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element5, 'data-id');
        morphs[1] = dom.createAttrMorph(element5, 'title');
        morphs[2] = dom.createElementMorph(element5);
        morphs[3] = dom.createAttrMorph(element6, 'width');
        morphs[4] = dom.createAttrMorph(element6, 'height');
        morphs[5] = dom.createMorphAt(element7,1,1);
        morphs[6] = dom.createAttrMorph(element8, 'fill');
        morphs[7] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[1,54],[1,61]]]]]]],
        ["attribute","title",["concat",[["get","tooltip",["loc",[null,[1,74],[1,81]]]]]]],
        ["element","action",["confirm"],[],["loc",[null,[1,3],[1,23]]]],
        ["attribute","width",["concat",[["get","size",["loc",[null,[2,51],[2,55]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[2,69],[2,73]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[4,12],[4,22]]]]],[],0,null,["loc",[null,[4,6],[6,13]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[7,17],[7,22]]]]]]],
        ["block","if",[["get","useConfirm",["loc",[null,[13,6],[13,16]]]]],[],1,null,["loc",[null,[13,0],[21,7]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});