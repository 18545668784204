define('jason-frontend/components/customers-edit-form', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      addEmail: function addEmail() {
        this.sendAction('addEmail');
      },
      removeEmail: function removeEmail(model) {
        this.sendAction('removeEmail', model);
      },
      addPhone: function addPhone() {
        this.sendAction('addPhone');
      },
      removePhone: function removePhone(model) {
        this.sendAction('removePhone', model);
      },
      addFax: function addFax() {
        this.sendAction('addFax');
      },
      removeFax: function removeFax(model) {
        this.sendAction('removeFax', model);
      }
    }
  });

});