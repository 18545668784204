define('jason-frontend/components/public-admission-pin-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    selectedTab: 'admission',
    directInput: false,
    toggleVisible: false,
    months: null,
    type: null,
    showMore: false,
    addPatient: false,
    validate: function validate() {
      if (!this.get('firstname')) {
        jason.notifiction.error('Fehler', 'Bitte geben Sie einen Vornamen an');
        return false;
      }
      if (!this.get('lastname')) {
        jason.notifiction.error('Fehler', 'Bitte geben Sie einen Nachnamen an');
        return false;
      }
      if (!this.get('patientName') && this.get('addPatient')) {
        jason.notifiction.error('Fehler', 'Bitte geben Sie einen Patienten an');
        return false;
      }
      if (this.get('email') && this.get('email').length > 0) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(this.get('email')).toLowerCase())) {
          jason.notifiction.error('Fehler', 'Bitte geben Sie eine gültige Email-Adresse an');
          return false;
        }
      }
      return true;
    },
    startTreatment: function startTreatment(customerId, patientId, directTreatment) {
      var self = this,
          treatment = {
        customer: {
          id: customerId
        }
      };

      var patientTreatmentData = [];

      var patientData = {
        patientId: patientId,
        putDirectlyInTreatment: directTreatment
      };

      patientTreatmentData.push(patientData);
      treatment.selectedPatients = patientTreatmentData;

      $.ajax({
        url: "/api/treatments",
        method: "PUT",
        data: JSON.stringify(treatment),
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (id) {
        if (directTreatment) {
          self.sendAction('toTreatment', id);
        } else {
          self.sendAction('toWaitingRoom');
        }
      }, function (error) {
        return { status: error.statusText, message: error.responseText };
      });
    },
    savedAction: function savedAction(id) {
      if (this.get('ctx') === 'customers') {
        this.sendAction('gotoDetails', id);
      } else {
        this.sendAction('refresh');
      }
    },
    createCustomer: function createCustomer(target) {
      var form = $('#createCustomerForm'),
          self = this;

      var customer = {
        firstname: this.get('firstname'),
        lastname: this.get('lastname'),
        street: this.get('street'),
        postalCode: this.get('postalCode'),
        town: this.get('town'),
        email: this.get('email'),
        phone1: this.get('phone1')
      };

      if (this.get('selectedGender')) {
        customer.genderId = this.get('selectedGender').id;
      }

      var patientData = null;
      if (this.get('addPatient')) {
        patientData = {
          name: this.get('patientName'),
          race: this.get('patientRace'),
          color: this.get('patientColor'),
          chipId: this.get('patientChipId'),
          castrated: this.get('patientCastrated'),
          birthdayFormatted: this.get('birthdayInput')
        };

        if (this.get('patientGender')) {
          patientData.gender = {
            id: this.get('patientGender').id
          };
        }
        if (this.get('patientCategory')) {
          patientData.category = {
            id: this.get('patientCategory').id
          };
        }
        if (this.get('digitalRace')) {
          patientData.digitalRace = {
            id: this.get('digitalRace').id
          };
        }
      }

      $.ajax({
        url: "/api/customers",
        method: "POST",
        data: JSON.stringify(customer),
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {

        if (patientData) {

          patientData.customerId = data.customer.id;

          $.ajax({
            url: "/api/patients",
            method: "POST",
            data: JSON.stringify(patientData),
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (patientdata) {
            if (target === 'treatment') {
              self.startTreatment(data.customer.id, patientdata.patient.id, true);
            } else if (target === 'waitingroom') {
              self.startTreatment(data.customer.id, patientdata.patient.id, false);
            } else if (target === 'patient') {
              self.sendAction('gotoPatient', patientdata.patient.id);
            } else if (self.get('ctx') === 'invoice') {
              var patients = new Array();
              patients.push(patientdata.patient.id);
              self.sendAction('setCustomer', data.customer.id, patients);
            } else {
              self.savedAction(data.customer.id);
            }
          });
        } else {
          if (self.get('ctx') === 'invoice') {
            self.sendAction('setCustomer', data.customer.id);
          } else {
            self.savedAction(data.customer.id);
          }
        }
      }, function (error) {});
    },
    actions: {
      toggleFirst: function toggleFirst() {
        if (this.get('showMore')) {
          this.set('showMore', false);
        } else {
          this.set('showMore', true);
        }
      },
      togglePatient: function togglePatient() {
        if (this.get('addPatient')) {
          this.set('addPatient', false);
        } else {
          this.set('addPatient', true);
        }
      },
      saveAndToTreatment: function saveAndToTreatment() {
        if (this.validate()) {
          this.createCustomer('treatment');
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          $.magnificPopup.close();
        }
      },
      saveAndToWaitingRoom: function saveAndToWaitingRoom() {
        if (this.validate()) {
          this.createCustomer('waitingroom');
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          $.magnificPopup.close();
        }
      },
      save: function save() {
        if (this.validate()) {
          this.createCustomer();
          this.sendAction('saved');
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          $.magnificPopup.close();
        }
      },
      saveAndGotoPatient: function saveAndGotoPatient() {
        if (this.validate()) {
          this.createCustomer('patient');
          this.sendAction('saved');
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          $.magnificPopup.close();
        }
      },
      saveAndGotoCustomer: function saveAndGotoCustomer() {
        if (this.validate()) {
          this.set('ctx', 'customers');
          this.createCustomer();
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          $.magnificPopup.close();
        }
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      activateDirectInput: function activateDirectInput() {
        this.set('directInput', true);
        setTimeout(function () {
          $('#etigaNumber').focus();
        }, 300);
      },
      deactivateDirectInput: function deactivateDirectInput() {
        this.set('directInput', false);
        setTimeout(function () {
          $('#qrInput2').focus();
        }, 300);
      },
      reset: function reset() {
        $('#qrInput2').prop('disabled', false);
        $('#qrInput2').focus();
      },
      confirm: function confirm() {

        if (this.get('directInput') === false) {
          var input = new String(this.get('qrInput'));
          input = input.replace('QR', '');

          var all = atob(input);

          var id = all.split('#')[0].replace('qr', '');

          if (id.length == 0) {
            jason.notifiction.error('Eingabe nicht korrekt', '');
            this.set('qrInput', '');
            $('#qrInput2').prop('disabled', false);
            $('#qrInput2').focus();
            return;
          }

          this.sendAction('confirm', id);

          $('#qrInput2').prop('disabled', false);
        } else {
          var input = new String(this.get('etigaNumber'));
          this.sendAction('confirm', input);
        }
        $.magnificPopup.close();
      },

      selectMode: function selectMode(mode) {
        this.set('selectedTab', mode);
        if (mode === 'etiga') {
          setTimeout(function () {
            $('#qrInput2').focus();
          }, 300);
        }
      },
      copyToClipboard: function copyToClipboard(text) {
        if (window.clipboardData && window.clipboardData.setData) {
          // IE specific code path to prevent textarea being shown while dialog is visible.
          return clipboardData.setData("Text", text);
        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
          var textarea = document.createElement("textarea");
          textarea.textContent = text;
          textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
          $('#modal-public-admission-pin-panel').append(textarea);
          textarea.select();
          try {
            return document.execCommand("copy"); // Security exception may be thrown by some browsers.
          } catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
          } finally {
            $(textarea).remove();
          }
        }
      },
      load: function load(ctx) {
        var self = this;
        this.set('qrInput', '');
        this.set('directInput', false);
        this.set('selectedGender', null);
        this.set('firstname', null);
        this.set('lastname', null);
        this.set('town', null);
        this.set('postalCode', null);
        this.set('street', null);
        this.set('email', null);
        this.set('phone1', null);
        this.set('patientName', null);
        this.set('patientRace', null);
        this.set('patientColor', null);
        this.set('patientChipId', null);
        this.set('patientCastrated', null);
        this.set('patientGender', null);
        this.set('patientCategory', null);
        this.set('digitalRace', null);
        this.set('birthdayInput', null);
        this.set('ctx', ctx);

        $.ajax({
          url: "/api/treatments/digitalHelper/admissionQr",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          $('#result').html('<img src="data:image/png;base64,' + data.digitalHelperQr.payload + '" />');
          self.set('url', data.digitalHelperQr.url);
        }, function (responseData) {});
      }
    },
    autoSubmitForm: function autoSubmitForm() {
      var self = this;
      Ember['default'].run.later(this, function () {
        var form = $('#etigaScanForm2');
        if (form && !$('#qrInput2').prop('disabled')) {
          form.submit();
        }
        if ($(document).find('#modal-etiga-quick-scan-panel').length != 0 && !$(document).find('#modal-etiga-quick-scan-panel').hasClass('mfp-hide')) {
          self.autoSubmitForm();
        }
      }, 500);
    },
    watchCategory: (function () {
      var self = this;
      try {
        $.ajax({
          url: "/api/digitalRaces/" + self.get('patientCategory').id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('digitalRace', null);
          self.set('digitalRaces', data.digitalRace);
        });
      } catch (e) {}
    }).observes('patientCategory')
  });

});