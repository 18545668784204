define('jason-frontend/routes/users/create', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return this.store.createRecord('user');
    },
    actions: {
      create: function create() {
        var self = this;
        var model = this.controller.get('model');
        model.save().then(function () {
          self.transitionTo('users.index');
        }, function (error) {
          var error = error.errors[0];
          jason.notifiction.error(error.title, error.body);
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('genders', this.store.find('gender'));
      controller.set('titles', this.store.find('title'));
      controller.set('userRoles', this.store.find('userRole'));
      controller.set('cashRegisters', this.store.find('cashRegister'));
    }
  });

});