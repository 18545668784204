define('jason-frontend/templates/components/button-add-list2', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 5,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/button-add-list2.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"fill","none");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","14");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[4,34],[4,39]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-add-list2.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"class","add-button");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("title");
        var el3 = dom.createTextNode("view");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","scale(0.08,0.08) translate(50,50)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","m120.353 120.353h-20.059v40.118h-40.118v20.059h40.118v40.118h20.059v-40.118h40.118v-20.059h-40.118z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","m250.735 0h-160.47c-16.592 0-30.089 13.497-30.089 30.088v30.088h-30.088c-16.591 0-30.088 13.497-30.088 30.089v160.471c0 16.592 13.497 30.088 30.088 30.088h160.471c16.592 0 30.088-13.497 30.088-30.088v-30.088h30.088c16.592 0 30.088-13.497 30.088-30.088v-160.472c0-16.591-13.496-30.088-30.088-30.088zm-50.147 250.735c0 5.534-4.496 10.029-10.029 10.029h-160.471c-5.534 0-10.029-4.496-10.029-10.029v-160.47c0-5.534 4.496-10.029 10.029-10.029h160.471c5.534 0 10.029 4.496 10.029 10.029zm60.177-60.176c0 5.534-4.496 10.029-10.029 10.029h-30.088v-110.323c0-16.592-13.497-30.088-30.088-30.088h-110.325v-30.089c0-5.534 4.496-10.029 10.029-10.029h160.471c5.534 0 10.029 4.496 10.029 10.029v160.471z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [5]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createMorphAt(element1,3,3);
        morphs[3] = dom.createAttrMorph(element2, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,33],[1,37]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,51],[1,55]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,8],[3,18]]]]],[],0,null,["loc",[null,[3,2],[5,9]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[6,13],[6,18]]]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});