define('jason-frontend/components/button-ehapo', ['exports', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'top',
    timer: 0,
    active: false,
    showStroke: true,
    color: '#3B6182',
    content: 'Produkt einbuchen',
    theme: 'tooltipster-punk'
  });

});