define('jason-frontend/models/msg', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    title: DS['default'].attr('string'),
    app: DS['default'].attr('boolean'),
    message: DS['default'].attr('string'),
    mandantString: DS['default'].attr('string'),
    receiversString: DS['default'].attr('string'),
    sender: DS['default'].belongsTo('user'),
    readByReceiver: DS['default'].attr('boolean'),
    created: DS['default'].attr('date'),
    activeFrom: DS['default'].attr('string'),
    read: DS['default'].attr('date'),
    referenceId: DS['default'].attr('number'),
    referenceType: DS['default'].attr('string'),
    readBy: DS['default'].attr('string'),
    status: DS['default'].attr('string'),
    withinPractice: DS['default'].attr('boolean'),
    readOn: DS['default'].attr('date'),
    patient: DS['default'].belongsTo('patient'),
    customer: DS['default'].attr(''),
    cssClass: DS['default'].attr(''),
    tasks: DS['default'].hasMany('task'),
    collapsed: DS['default'].attr('boolean'),
    unread: DS['default'].attr('boolean'),
    referenceData: DS['default'].attr(''),
    replies: DS['default'].hasMany('message-reply'),
    category: DS['default'].belongsTo('messageCategory')
  });

});