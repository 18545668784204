define('jason-frontend/helpers/in-future', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.inFuture = inFuture;

  function inFuture(params) {
    var date = params[0];

    if (!date) {
      return false;
    }

    var now = moment();
    var testDate = moment(date, 'DD.MM.YYYY');

    return testDate > now;
  }

  exports['default'] = Ember['default'].Helper.helper(inFuture);

});