define('jason-frontend/controllers/transactions/showclosing', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Anzeigen",
    breadCrumbPath: "transactions.showclosing",
    queryParams: ['type'],
    type: null
  });

});