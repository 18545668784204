define('jason-frontend/components/choose-etiga-customer-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    etigaActionType: 'use',
    masterId: null,
    applyFilter: function applyFilter(first) {
      var self = this;

      var term = this.get('filterName');
      var splitted;
      if (term) {
        splitted = term.split(' ');
      }

      var url = "/api/customers?searchTerm=" + term;

      if (splitted && splitted.length > 1) {
        url = "/api/customers?searchFirstname=" + splitted[1] + "&searchLastname=" + splitted[0];
      }
      $.ajax({
        url: url,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('customers', data.customer);
        if (first) {
          if (data.customer.length == 0) {
            self.set('filterName', '');
            self.applyFilter(false);
          }
        }
      });
    },
    actions: {
      load: function load(matches, masterId, etigaCustomer) {

        this.set('masterId', masterId);
        this.set('showSelect', true);
        this.set('showSelectPatient', false);
        console.log(etigaCustomer);
        if (etigaCustomer) {
          this.set('firstname', etigaCustomer.firstname);
          this.set('lastname', etigaCustomer.lastname);

          this.set('filterName', etigaCustomer.lastname + ' ' + etigaCustomer.firstname);
        }
        this.applyFilter(true);
      },
      applyFilter: function applyFilter(first) {
        this.applyFilter(first);
      },
      save: function save() {

        var customer = {
          firstname: this.get('firstname'),
          lastname: this.get('lastname'),
          postalCode: this.get('postalCode'),
          town: this.get('town'),
          street: this.get('address')
        };

        if (this.get('gender')) {
          customer.gender = {
            id: this.get('gender').id
          };
        }

        this.sendAction('createCustomerForEtigaActivation', customer, this.get('masterId'));
        $.magnificPopup.close();
      },
      showForm: function showForm() {
        this.set('showSelect', false);
        this.set('showSelectPatient', false);
        this.set('customer', null);
      },
      toSelect: function toSelect() {
        this.set('showSelect', true);
        this.set('showSelectPatient', false);
        this.set('customer', null);
      },
      selectCustomer: function selectCustomer(customer) {
        this.set('showSelect', false);
        this.set('showSelectPatient', true);
        this.set('customer', customer);
      },
      select: function select(customerId, patientId) {
        this.sendAction('select', customerId, patientId, this.get('masterId'));
        $.magnificPopup.close();
      },
      selectNewPatient: function selectNewPatient(customerId) {
        this.sendAction('select', customerId, null, this.get('masterId'));
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});