define('jason-frontend/routes/inventory/massmove-detail', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {

      var id = params.params['inventory.massmove-detail'].id;
      this.set('id', id);
      this._super(controller);

      controller.set('warehouses', this.store.find('inventory-warehouse'));

      this.loadData(id);
    },
    loadData: function loadData(id) {
      var self = this,
          controller = this.get('controller');

      if (!id) {
        id = controller.get('model').id;
      }

      $.ajax({
        url: "/api/inventoryMassMove/" + id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('model', data.inventoryMassMove);
      }, function () {
        controller.set('model', data.inventoryMassMove);
      });
    },
    save: function save() {
      var data = this.get('controller').get('model'),
          controller = this.get('controller');

      var user = {
        id: data.user.id
      };

      Ember['default'].set(data, "user", user);

      $.ajax({
        url: "/api/inventoryStocktakings/" + this.get('controller').get('model').id,
        method: "POST",
        contentType: "application/json",
        data: JSON.stringify(data),
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('model', data.inventoryStocktaking);
        jason.notifiction.notifiy('Inventur', 'gespeichert');
      }, function () {
        jason.notifiction.notifiy('Inventur', 'gespeichert');
      });
    },
    actions: {
      executeChange: function executeChange() {

        var self = this;

        var controller = this.get('controller');

        var warehouse = controller.get('warehouse');

        if (!warehouse) {
          jason.notifiction.error('Fehler', 'Bitte wählen Sie ein Ziellager aus');
          return;
        }

        var data = {
          warehouseId: warehouse.id
        };

        $.ajax({
          url: "/api/inventoryMassMove/" + this.get('id') + "/execute",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('inventory.select-order');
        }, function () {
          self.transitionTo('inventory.select-order');
        });
      },
      scanProduct: function scanProduct(id) {
        var self = this;
        $.ajax({
          url: "/api/inventoryMassMove/" + this.get('id') + "/" + id,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.loadData();
          setTimeout(function () {
            self.get('controller').send('scan');
          }, 500);
        }, function () {});
      },
      'delete': function _delete(id) {
        var self = this;
        $.ajax({
          url: "/api/inventoryMassMove/" + this.get('id') + "/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.loadData();
        }, function () {});
      },
      save: function save() {
        this.save();
      },
      scan: function scan() {
        this.save();
        this.get('controller').send('openScan');
      }
    }
  });

});