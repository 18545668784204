define('jason-frontend/helpers/get-order-type-name', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getOrderTypeName = getOrderTypeName;

  function getOrderTypeName(params) {
    var order = params[0];
    if (order) {
      try {
        if (order.get('privateOrder')) {
          return 'Privat';
        }
      } catch (e) {
        if (order.privateOrder) {
          return 'Privat';
        }
      }
    }
    return 'Praxis';
  }

  exports['default'] = Ember['default'].Helper.helper(getOrderTypeName);

});