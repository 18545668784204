define('jason-frontend/helpers/is-invoice-patient-selected', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isInvoicePatientSelected = isInvoicePatientSelected;

  function isInvoicePatientSelected(params) {
    var globalInvoice = params[0],
        patient = params[1];

    var value = globalInvoice.get('patients').contains(patient);
    return value;
  }

  exports['default'] = Ember['default'].Helper.helper(isInvoicePatientSelected);

});