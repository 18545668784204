define('jason-frontend/components/quick-scan-etiga-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    directInput: false,
    actions: {
      activateDirectInput: function activateDirectInput() {
        this.set('directInput', true);
      },
      deactivateDirectInput: function deactivateDirectInput() {
        this.set('directInput', false);
      },
      reset: function reset() {
        $('#qrInput').prop('disabled', false);
        $('#qrInput').focus();
      },
      confirm: function confirm() {

        if (this.get('directInput') === false) {
          var input = new String(this.get('qrInput'));
          input = input.replace('QR', '');

          var all = atob(input);

          var id = all.split('#')[0].replace('qr', '');

          if (id.length == 0) {
            jason.notifiction.error('Eingabe nicht korrekt', '');
            this.set('qrInput', '');
            $('#qrInput').prop('disabled', false);
            $('#qrInput').focus();
            return;
          }

          this.sendAction('confirm', id);

          $('#qrInput').prop('disabled', false);
        } else {
          var input = new String(this.get('etigaNumber'));
          this.sendAction('confirm', input);
        }
        $.magnificPopup.close();
      },
      load: function load() {
        var self = this;
        this.set('qrInput', '');
        this.set('directInput', false);
      }
    },
    autoSubmitForm: function autoSubmitForm() {

      var self = this;
      Ember['default'].run.later(this, function () {
        var form = $('#etigaScanForm');
        if (form && !$('#qrInput').prop('disabled')) {
          form.submit();
        }
        if ($(document).find('#modal-etiga-quick-scan-panel').length != 0 && !$(document).find('#modal-etiga-quick-scan-panel').hasClass('mfp-hide')) {
          self.autoSubmitForm();
        }
      }, 500);
    }
  });

});