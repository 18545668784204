define('jason-frontend/serializers/task', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  exports['default'] = DS['default'].ActiveModelSerializer.extend(DS['default'].EmbeddedRecordsMixin, {
    attrs: {
      taskStatus: { embedded: 'always' },
      user: { embedded: 'always' },
      originalUser: { embedded: 'always' },
      message: { embedded: 'always' },
      taskStatus: { embedded: 'always' },
      customer: { embedded: 'always' },
      patient: { embedded: 'always' },
      delegatedBy: { embedded: 'always' }
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      var serialized = this.serialize(record, options);
      serialized.id = record.id ? record.id : 0;
      Ember['default'].merge(hash, serialized);
    },
    keyForAttribute: function keyForAttribute(attr, method) {
      return Ember['default'].String.camelize(attr);
    }
  });

});