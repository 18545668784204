define('jason-frontend/templates/components/button-view', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 5,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/button-view.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","14");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[4,24],[4,29]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-view.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"fill-rule","evenodd");
        dom.setAttribute(el3,"d","M10.25,0.0841665263 C6.09814684,0.0841665263 2.41307208,2.21422011 0.128125,5.48720296 C-0.0395460803,5.72867554 -0.0395460803,6.05182856 0.128125,6.29330114 C2.41307163,9.5663328 6.0981473,11.7035997 10.25,11.7035997 C14.4018525,11.7035997 18.0869281,9.5663328 20.371875,6.29330114 C20.5395461,6.05182856 20.5395461,5.72867554 20.371875,5.48720296 C18.0869277,2.21422011 14.4018529,0.0841665263 10.25,0.0841665263 Z M10.25,1.47849851 C13.7666247,1.47849851 16.8835135,3.20147222 18.9411458,5.89388313 C16.8834631,8.58375867 13.7646806,10.3092677 10.25,10.3092677 C6.73531919,10.3092677 3.61653666,8.58375867 1.55885417,5.89388313 C3.61648632,3.20147222 6.73337511,1.47849851 10.25,1.47849851 Z M10.25,2.40805317 C8.37112079,2.40805317 6.83333333,3.97696481 6.83333333,5.89388313 C6.83333333,7.8107875 8.37112079,9.37971309 10.25,9.37971309 C12.1288792,9.37971309 13.6666667,7.8107875 13.6666667,5.89388313 C13.6666667,3.97696481 12.1288792,2.40805317 10.25,2.40805317 Z M10.25,3.80238515 C11.3902777,3.80238515 12.3,4.73051759 12.3,5.89388313 C12.3,7.05724402 11.3902777,7.9853811 10.25,7.9853811 C9.10972235,7.9853811 8.2,7.05724402 8.2,5.89388313 C8.2,4.73051759 9.10972235,3.80238515 10.25,3.80238515 Z");
        dom.setAttribute(el3,"transform","translate(5.75 10)");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createMorphAt(element2,1,1);
        morphs[3] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,10],[3,20]]]]],[],0,null,["loc",[null,[3,4],[5,11]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[6,18],[6,23]]]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});