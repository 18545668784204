define('jason-frontend/templates/components/patient-activities', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 81,
              "column": 6
            },
            "end": {
              "line": 84,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element42 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element42, 'class');
          morphs[1] = dom.createElementMorph(element42);
          morphs[2] = dom.createMorphAt(element42,0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[82,61],[82,73]]]],"selected",""],[],["loc",[null,[82,40],[82,89]]]]]]],
          ["element","action",["toggleTag",["get","tag",["loc",[null,[83,100],[83,103]]]]],[],["loc",[null,[82,91],[83,105]]]],
          ["content","tag.name",["loc",[null,[83,106],[83,118]]]]
        ],
        locals: ["tag"],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 106,
                "column": 16
              },
              "end": {
                "line": 108,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element33 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element33, 'class');
            morphs[1] = dom.createMorphAt(element33,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["info-badge info-badge-very-small mr5 tag-",["get","tag.name",["loc",[null,[107,75],[107,83]]]]]]],
            ["content","tag.name",["loc",[null,[107,87],[107,99]]]]
          ],
          locals: ["tag"],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 110,
                  "column": 18
                },
                "end": {
                  "line": 112,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge info-badge-very-small lab-idexx");
              var el2 = dom.createTextNode("IDEXX");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 112,
                    "column": 18
                  },
                  "end": {
                    "line": 114,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","info-badge info-badge-very-small lab-laboklin");
                var el2 = dom.createTextNode("Laboklin");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 114,
                      "column": 18
                    },
                    "end": {
                      "line": 116,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","info-badge info-badge-very-small lab-intern");
                  var el2 = dom.createTextNode("Labor Intern");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                  ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 114,
                    "column": 18
                  },
                  "end": {
                    "line": 116,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.payload.privateLab",["loc",[null,[114,28],[114,51]]]]],[],0,null,["loc",[null,[114,18],[116,18]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 112,
                  "column": 18
                },
                "end": {
                  "line": 116,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[112,32],[112,49]]]],"laboklin"],[],["loc",[null,[112,28],[112,61]]]]],[],0,1,["loc",[null,[112,18],[116,18]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 109,
                "column": 16
              },
              "end": {
                "line": 117,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[110,28],[110,45]]]],"vetconnect"],[],["loc",[null,[110,24],[110,59]]]]],[],0,1,["loc",[null,[110,18],[116,25]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 119,
                  "column": 20
                },
                "end": {
                  "line": 121,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge info-badge-very-small tag-eltiga");
              var el2 = dom.createTextNode("Eltiga");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 118,
                "column": 16
              },
              "end": {
                "line": 122,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","item.eltigaMandant",["loc",[null,[119,26],[119,44]]]]],[],0,null,["loc",[null,[119,20],[121,27]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 131,
                    "column": 20
                  },
                  "end": {
                    "line": 133,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("spann");
                dom.setAttribute(el1,"class","is-highlighted");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" (TA)\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element32 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element32,0,0);
                morphs[1] = dom.createMorphAt(element32,2,2);
                return morphs;
              },
              statements: [
                ["content","item.payload.doctor.lastname",["loc",[null,[132,52],[132,84]]]],
                ["content","item.payload.doctor.firstname",["loc",[null,[132,85],[132,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 134,
                    "column": 18
                  },
                  "end": {
                    "line": 134,
                    "column": 79
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" und ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 135,
                    "column": 20
                  },
                  "end": {
                    "line": 137,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("spann");
                dom.setAttribute(el1,"class","is-highlighted");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" (AS)\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element31 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element31,0,0);
                morphs[1] = dom.createMorphAt(element31,2,2);
                return morphs;
              },
              statements: [
                ["content","item.payload.assistent.lastname",["loc",[null,[136,52],[136,87]]]],
                ["content","item.payload.assistent.firstname",["loc",[null,[136,88],[136,124]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 129,
                  "column": 16
                },
                "end": {
                  "line": 138,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  durch\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.payload.doctor",["loc",[null,[131,26],[131,45]]]]],[],0,null,["loc",[null,[131,20],[133,27]]]],
              ["block","if",[["subexpr","and",[["get","item.payload.assistent",["loc",[null,[134,29],[134,51]]]],["get","item.payload.doctor",["loc",[null,[134,52],[134,71]]]]],[],["loc",[null,[134,24],[134,72]]]]],[],1,null,["loc",[null,[134,18],[134,86]]]],
              ["block","if",[["get","item.payload.assistent",["loc",[null,[135,26],[135,48]]]]],[],2,null,["loc",[null,[135,20],[137,27]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 139,
                  "column": 16
                },
                "end": {
                  "line": 139,
                  "column": 102
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(", ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","is-highlighted");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              return morphs;
            },
            statements: [
              ["content","item.eltigaMandant",["loc",[null,[139,73],[139,95]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 127,
                "column": 14
              },
              "end": {
                "line": 140,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                Behandlung\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","or",[["get","item.payload.assistent",["loc",[null,[129,26],[129,48]]]],["get","item.payload.doctor",["loc",[null,[129,49],[129,68]]]]],[],["loc",[null,[129,22],[129,69]]]]],[],0,null,["loc",[null,[129,16],[138,23]]]],
            ["block","if",[["get","item.eltigaMandant",["loc",[null,[139,22],[139,40]]]]],[],1,null,["loc",[null,[139,16],[139,109]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child4 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 140,
                  "column": 14
                },
                "end": {
                  "line": 142,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                Erinnerung\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 142,
                    "column": 14
                  },
                  "end": {
                    "line": 144,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                Notiz\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 144,
                      "column": 14
                    },
                    "end": {
                      "line": 146,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                Labor\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 146,
                        "column": 14
                      },
                      "end": {
                        "line": 148,
                        "column": 14
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                Bildgebung\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 148,
                          "column": 14
                        },
                        "end": {
                          "line": 150,
                          "column": 14
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                Rechnung\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 150,
                            "column": 14
                          },
                          "end": {
                            "line": 152,
                            "column": 14
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                Impfung\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 152,
                              "column": 14
                            },
                            "end": {
                              "line": 154,
                              "column": 14
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                E-Mail Weiterleitung\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes() { return []; },
                        statements: [

                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 154,
                                "column": 14
                              },
                              "end": {
                                "line": 156,
                                "column": 14
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                eltiga Nachricht\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes() { return []; },
                          statements: [

                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        var child0 = (function() {
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 156,
                                  "column": 14
                                },
                                "end": {
                                  "line": 158,
                                  "column": 14
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createTextNode("                Dokument\n");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes() { return []; },
                            statements: [

                            ],
                            locals: [],
                            templates: []
                          };
                        }());
                        var child1 = (function() {
                          var child0 = (function() {
                            return {
                              meta: {
                                "revision": "Ember@1.13.5",
                                "loc": {
                                  "source": null,
                                  "start": {
                                    "line": 158,
                                    "column": 14
                                  },
                                  "end": {
                                    "line": 160,
                                    "column": 14
                                  }
                                },
                                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                              },
                              arity: 0,
                              cachedFragment: null,
                              hasRendered: false,
                              buildFragment: function buildFragment(dom) {
                                var el0 = dom.createDocumentFragment();
                                var el1 = dom.createTextNode("                Termin\n              ");
                                dom.appendChild(el0, el1);
                                return el0;
                              },
                              buildRenderNodes: function buildRenderNodes() { return []; },
                              statements: [

                              ],
                              locals: [],
                              templates: []
                            };
                          }());
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 158,
                                  "column": 14
                                },
                                "end": {
                                  "line": 160,
                                  "column": 14
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createComment("");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                              var morphs = new Array(1);
                              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                              dom.insertBoundary(fragment, 0);
                              dom.insertBoundary(fragment, null);
                              return morphs;
                            },
                            statements: [
                              ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[158,28],[158,37]]]],"app"],[],["loc",[null,[158,24],[158,44]]]]],[],0,null,["loc",[null,[158,14],[160,14]]]]
                            ],
                            locals: [],
                            templates: [child0]
                          };
                        }());
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 156,
                                "column": 14
                              },
                              "end": {
                                "line": 160,
                                "column": 14
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(1);
                            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                            dom.insertBoundary(fragment, 0);
                            dom.insertBoundary(fragment, null);
                            return morphs;
                          },
                          statements: [
                            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[156,28],[156,37]]]],"doc"],[],["loc",[null,[156,24],[156,44]]]]],[],0,1,["loc",[null,[156,14],[160,14]]]]
                          ],
                          locals: [],
                          templates: [child0, child1]
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 154,
                              "column": 14
                            },
                            "end": {
                              "line": 160,
                              "column": 14
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[154,28],[154,37]]]],"com"],[],["loc",[null,[154,24],[154,44]]]]],[],0,1,["loc",[null,[154,14],[160,14]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 152,
                            "column": 14
                          },
                          "end": {
                            "line": 160,
                            "column": 14
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[152,28],[152,37]]]],"mail"],[],["loc",[null,[152,24],[152,45]]]]],[],0,1,["loc",[null,[152,14],[160,14]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 150,
                          "column": 14
                        },
                        "end": {
                          "line": 160,
                          "column": 14
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[150,28],[150,37]]]],"vacc"],[],["loc",[null,[150,24],[150,45]]]]],[],0,1,["loc",[null,[150,14],[160,14]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 148,
                        "column": 14
                      },
                      "end": {
                        "line": 160,
                        "column": 14
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[148,28],[148,37]]]],"invoice"],[],["loc",[null,[148,24],[148,48]]]]],[],0,1,["loc",[null,[148,14],[160,14]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 146,
                      "column": 14
                    },
                    "end": {
                      "line": 160,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[146,28],[146,37]]]],"img"],[],["loc",[null,[146,24],[146,44]]]]],[],0,1,["loc",[null,[146,14],[160,14]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 144,
                    "column": 14
                  },
                  "end": {
                    "line": 160,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[144,28],[144,37]]]],"lab"],[],["loc",[null,[144,24],[144,44]]]]],[],0,1,["loc",[null,[144,14],[160,14]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 142,
                  "column": 14
                },
                "end": {
                  "line": 160,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[142,28],[142,37]]]],"not"],[],["loc",[null,[142,24],[142,44]]]]],[],0,1,["loc",[null,[142,14],[160,14]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 140,
                "column": 14
              },
              "end": {
                "line": 160,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[140,28],[140,37]]]],"rem"],[],["loc",[null,[140,24],[140,44]]]]],[],0,1,["loc",[null,[140,14],[160,14]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child5 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 166,
                  "column": 14
                },
                "end": {
                  "line": 168,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                Von Kund:in\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 169,
                  "column": 14
                },
                "end": {
                  "line": 171,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                Von Praxis\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 172,
                  "column": 14
                },
                "end": {
                  "line": 174,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                Von Praxis\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 164,
                "column": 10
              },
              "end": {
                "line": 179,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element29 = dom.childAt(fragment, [1]);
            var element30 = dom.childAt(fragment, [3]);
            var morphs = new Array(6);
            morphs[0] = dom.createAttrMorph(element29, 'class');
            morphs[1] = dom.createMorphAt(element29,1,1);
            morphs[2] = dom.createMorphAt(element29,2,2);
            morphs[3] = dom.createMorphAt(element29,3,3);
            morphs[4] = dom.createAttrMorph(element30, 'class');
            morphs[5] = dom.createUnsafeMorphAt(element30,1,1);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["message-sender mt20 ",["get","item.title",["loc",[null,[165,46],[165,56]]]]]]],
            ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[166,24],[166,34]]]],"msg_incoming"],[],["loc",[null,[166,20],[166,50]]]]],[],0,null,["loc",[null,[166,14],[168,21]]]],
            ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[169,24],[169,34]]]],"reminder"],[],["loc",[null,[169,20],[169,46]]]]],[],1,null,["loc",[null,[169,14],[171,21]]]],
            ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[172,24],[172,34]]]],"msg_outgoing"],[],["loc",[null,[172,20],[172,50]]]]],[],2,null,["loc",[null,[172,14],[174,21]]]],
            ["attribute","class",["concat",["message ",["get","item.title",["loc",[null,[176,34],[176,44]]]]]]],
            ["content","item.payload.text",["loc",[null,[177,14],[177,37]]]]
          ],
          locals: [],
          templates: [child0, child1, child2]
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 180,
                "column": 10
              },
              "end": {
                "line": 190,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element27 = dom.childAt(fragment, [1]);
            var element28 = dom.childAt(element27, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element27, [1]),1,1);
            morphs[1] = dom.createElementMorph(element28);
            morphs[2] = dom.createMorphAt(element28,1,1);
            return morphs;
          },
          statements: [
            ["content","item.payload.text",["loc",[null,[183,16],[183,39]]]],
            ["element","action",["openApp",["get","item.referenceId",["loc",[null,[185,36],[185,52]]]]],[],["loc",[null,[185,17],[185,54]]]],
            ["inline","button-edit",[],["size","32","color","#fff","showStroke",false],["loc",[null,[187,16],[187,71]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 191,
                "column": 10
              },
              "end": {
                "line": 201,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element25 = dom.childAt(fragment, [1]);
            var element26 = dom.childAt(element25, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element25, [1]),1,1);
            morphs[1] = dom.createElementMorph(element26);
            morphs[2] = dom.createMorphAt(element26,1,1);
            return morphs;
          },
          statements: [
            ["content","item.payload.text",["loc",[null,[194,16],[194,39]]]],
            ["element","action",["editNote",["get","item.referenceId",["loc",[null,[196,37],[196,53]]]]],[],["loc",[null,[196,17],[196,55]]]],
            ["inline","button-edit",[],["size","32","color","#fff","showStroke",false],["loc",[null,[198,16],[198,71]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child8 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 202,
                "column": 10
              },
              "end": {
                "line": 208,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1, 1]),1,1);
            return morphs;
          },
          statements: [
            ["content","item.payload.text",["loc",[null,[205,16],[205,39]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child9 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 209,
                "column": 10
              },
              "end": {
                "line": 215,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1, 1]),1,1);
            return morphs;
          },
          statements: [
            ["content","item.payload.text",["loc",[null,[212,16],[212,39]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child10 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 229,
                      "column": 45
                    },
                    "end": {
                      "line": 230,
                      "column": 101
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","article.name",["loc",[null,[230,85],[230,101]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 228,
                    "column": 20
                  },
                  "end": {
                    "line": 232,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" x ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("   ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                return morphs;
              },
              statements: [
                ["content","article.quantity",["loc",[null,[229,22],[229,42]]]],
                ["block","link-to",["services.edit",["get","article.id",["loc",[null,[229,72],[229,82]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[229,45],[230,113]]]],
                ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[231,48],[231,61]]]]],[],[]]],["loc",[null,[230,131],[231,63]]]]
              ],
              locals: ["article"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 226,
                  "column": 18
                },
                "end": {
                  "line": 233,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Verrechnete Leistungen:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.services",["loc",[null,[228,28],[228,49]]]]],[],0,null,["loc",[null,[228,20],[232,29]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 237,
                      "column": 45
                    },
                    "end": {
                      "line": 238,
                      "column": 101
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","article.name",["loc",[null,[238,85],[238,101]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 236,
                    "column": 20
                  },
                  "end": {
                    "line": 240,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" x ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("   ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                return morphs;
              },
              statements: [
                ["content","article.quantity",["loc",[null,[237,22],[237,42]]]],
                ["block","link-to",["products.edit",["get","article.id",["loc",[null,[237,72],[237,82]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[237,45],[238,113]]]],
                ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[239,48],[239,61]]]]],[],[]]],["loc",[null,[238,131],[239,63]]]]
              ],
              locals: ["article"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 234,
                  "column": 18
                },
                "end": {
                  "line": 241,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Verrechnete Produkte:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.products",["loc",[null,[236,28],[236,49]]]]],[],0,null,["loc",[null,[236,20],[240,29]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 245,
                      "column": 45
                    },
                    "end": {
                      "line": 246,
                      "column": 101
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","article.name",["loc",[null,[246,85],[246,101]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 244,
                    "column": 20
                  },
                  "end": {
                    "line": 248,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" x ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("   ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                return morphs;
              },
              statements: [
                ["content","article.quantity",["loc",[null,[245,22],[245,42]]]],
                ["block","link-to",["products.edit",["get","article.id",["loc",[null,[245,72],[245,82]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[245,45],[246,113]]]],
                ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[247,48],[247,61]]]]],[],[]]],["loc",[null,[246,131],[247,63]]]]
              ],
              locals: ["article"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 242,
                  "column": 18
                },
                "end": {
                  "line": 249,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Verrechnete Medikamente:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.meds",["loc",[null,[244,28],[244,45]]]]],[],0,null,["loc",[null,[244,20],[248,29]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 253,
                      "column": 45
                    },
                    "end": {
                      "line": 254,
                      "column": 101
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","article.name",["loc",[null,[254,85],[254,101]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 252,
                    "column": 20
                  },
                  "end": {
                    "line": 256,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" x ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("   ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                return morphs;
              },
              statements: [
                ["content","article.quantity",["loc",[null,[253,22],[253,42]]]],
                ["block","link-to",["products.edit",["get","article.id",["loc",[null,[253,72],[253,82]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[253,45],[254,113]]]],
                ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[255,48],[255,61]]]]],[],[]]],["loc",[null,[254,131],[255,63]]]]
              ],
              locals: ["article"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 250,
                  "column": 18
                },
                "end": {
                  "line": 257,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Verrechnete Ernährung:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.feeding",["loc",[null,[252,28],[252,48]]]]],[],0,null,["loc",[null,[252,20],[256,29]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 261,
                      "column": 22
                    },
                    "end": {
                      "line": 266,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","align-items-center mb5 mt5");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("img");
                  dom.setAttribute(el2,"src","assets/images/icons/filetypes/pdf.svg");
                  dom.setAttribute(el2,"class","mr5");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("a");
                  dom.setAttribute(el2,"class","is-highlighted cursor");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element20 = dom.childAt(fragment, [1, 3]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element20);
                  morphs[1] = dom.createMorphAt(element20,0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["gotoTemplate",["get","item.referenceId",["loc",[null,[264,83],[264,99]]]],["get","template.id",["loc",[null,[264,100],[264,111]]]]],[],["loc",[null,[264,59],[264,113]]]],
                  ["content","template.name",["loc",[null,[264,114],[264,131]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 260,
                    "column": 20
                  },
                  "end": {
                    "line": 267,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","template.name",["loc",[null,[261,28],[261,41]]]]],[],0,null,["loc",[null,[261,22],[266,29]]]]
              ],
              locals: ["template"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 258,
                  "column": 18
                },
                "end": {
                  "line": 268,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Vorlagen:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.templates",["loc",[null,[260,28],[260,50]]]]],[],0,null,["loc",[null,[260,20],[267,29]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 272,
                  "column": 14
                },
                "end": {
                  "line": 275,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[274,16],[274,71]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 216,
                "column": 10
              },
              "end": {
                "line": 277,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ms-1");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","mr10");
            var el5 = dom.createElement("b");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(",");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","mr10");
            var el5 = dom.createTextNode("Gesamtsumme: ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(" EUR");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element21 = dom.childAt(fragment, [1]);
            var element22 = dom.childAt(element21, [1]);
            var element23 = dom.childAt(element22, [1]);
            var element24 = dom.childAt(element22, [3]);
            var morphs = new Array(9);
            morphs[0] = dom.createMorphAt(dom.childAt(element23, [1, 0]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element23, [3]),1,1);
            morphs[2] = dom.createMorphAt(element23,5,5);
            morphs[3] = dom.createMorphAt(element24,1,1);
            morphs[4] = dom.createMorphAt(element24,2,2);
            morphs[5] = dom.createMorphAt(element24,3,3);
            morphs[6] = dom.createMorphAt(element24,4,4);
            morphs[7] = dom.createMorphAt(element24,5,5);
            morphs[8] = dom.createMorphAt(element21,3,3);
            return morphs;
          },
          statements: [
            ["content","item.payload.code",["loc",[null,[220,40],[220,61]]]],
            ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","item.payload.total",["loc",[null,[221,94],[221,112]]]]],[],[]]],["loc",[null,[221,50],[221,114]]]],
            ["inline","invoice-paymentstatus",[],["paymentStatus",["subexpr","@mut",[["get","item.payload.paymentStatus",["loc",[null,[222,56],[222,82]]]]],[],[]]],["loc",[null,[222,18],[222,84]]]],
            ["block","if",[["get","item.payload.services",["loc",[null,[226,24],[226,45]]]]],[],0,null,["loc",[null,[226,18],[233,25]]]],
            ["block","if",[["get","item.payload.products",["loc",[null,[234,24],[234,45]]]]],[],1,null,["loc",[null,[234,18],[241,25]]]],
            ["block","if",[["get","item.payload.meds",["loc",[null,[242,24],[242,41]]]]],[],2,null,["loc",[null,[242,18],[249,25]]]],
            ["block","if",[["get","item.payload.feeding",["loc",[null,[250,24],[250,44]]]]],[],3,null,["loc",[null,[250,18],[257,25]]]],
            ["block","if",[["get","item.payload.templates",["loc",[null,[258,24],[258,46]]]]],[],4,null,["loc",[null,[258,18],[268,25]]]],
            ["block","link-to",["invoices.show",["get","item.referenceId",["loc",[null,[272,41],[272,57]]]]],["classNames","icon-button icon-button--default list-action-square exp line-action align-items-center"],5,null,["loc",[null,[272,14],[275,26]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5]
        };
      }());
      var child11 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 282,
                    "column": 18
                  },
                  "end": {
                    "line": 284,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    Eine eltiga App Benutzer:in hat das Dokument ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","is-highlighted cursor");
                var el2 = dom.createElement("b");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" geteilt.\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element17 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element17);
                morphs[1] = dom.createMorphAt(dom.childAt(element17, [0]),0,0);
                return morphs;
              },
              statements: [
                ["element","action",["openDocument",["get","item.referenceId",["loc",[null,[283,122],[283,138]]]]],[],["loc",[null,[283,98],[283,140]]]],
                ["content","item.payload.filename",["loc",[null,[283,144],[283,169]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 284,
                    "column": 18
                  },
                  "end": {
                    "line": 286,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    Die Praxis hat das Dokument ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","is-highlighted cursor");
                var el2 = dom.createElement("b");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" hinzugefügt.\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element16 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element16);
                morphs[1] = dom.createMorphAt(dom.childAt(element16, [0]),0,0);
                return morphs;
              },
              statements: [
                ["element","action",["openDocument",["get","item.referenceId",["loc",[null,[285,105],[285,121]]]]],[],["loc",[null,[285,81],[285,123]]]],
                ["content","item.payload.filename",["loc",[null,[285,127],[285,152]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 281,
                  "column": 16
                },
                "end": {
                  "line": 287,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.payload.ctx",["loc",[null,[282,28],[282,44]]]],"eltiga"],[],["loc",[null,[282,24],[282,54]]]]],[],0,1,["loc",[null,[282,18],[286,25]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 287,
                  "column": 16
                },
                "end": {
                  "line": 289,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[288,18],[288,41]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 278,
                "column": 10
              },
              "end": {
                "line": 296,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ms-1 fw-semibold");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action align-items-center");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var element19 = dom.childAt(element18, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element18, [1]),1,1);
            morphs[1] = dom.createElementMorph(element19);
            morphs[2] = dom.createMorphAt(element19,1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","item.payload.filename",["loc",[null,[281,22],[281,43]]]]],[],0,1,["loc",[null,[281,16],[289,23]]]],
            ["element","action",["openDocument",["get","item.referenceId",["loc",[null,[291,41],[291,57]]]]],[],["loc",[null,[291,17],[291,59]]]],
            ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[293,16],[293,71]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child12 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 297,
                "column": 10
              },
              "end": {
                "line": 305,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"class","mr10");
            dom.setAttribute(el2,"alt","");
            dom.setAttribute(el2,"style","width: 22px;");
            dom.setAttribute(el2,"src","assets/images/icons/history/vacc.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ms-1 fw-semibold");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["content","item.payload.text",["loc",[null,[302,16],[302,37]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child13 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 312,
                  "column": 16
                },
                "end": {
                  "line": 313,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 313,
                    "column": 16
                  },
                  "end": {
                    "line": 314,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 314,
                      "column": 16
                    },
                    "end": {
                      "line": 315,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 315,
                      "column": 16
                    },
                    "end": {
                      "line": 317,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","item.payload.orderTypeString",["loc",[null,[316,18],[316,50]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 314,
                    "column": 16
                  },
                  "end": {
                    "line": 317,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.payload.privateLab",["loc",[null,[314,26],[314,49]]]]],[],0,1,["loc",[null,[314,16],[317,16]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 313,
                  "column": 16
                },
                "end": {
                  "line": 317,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[313,30],[313,47]]]],"laboklin"],[],["loc",[null,[313,26],[313,59]]]]],[],0,1,["loc",[null,[313,16],[317,16]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 317,
                  "column": 24
                },
                "end": {
                  "line": 317,
                  "column": 132
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","block small");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
              return morphs;
            },
            statements: [
              ["inline","laboklin-reporttype",[],["type",["subexpr","@mut",[["get","form.reportType",["loc",[null,[317,108],[317,123]]]]],[],[]]],["loc",[null,[317,81],[317,125]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 306,
                "column": 10
              },
              "end": {
                "line": 327,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"class","mr10");
            dom.setAttribute(el2,"alt","");
            dom.setAttribute(el2,"style","width: 30px;");
            dom.setAttribute(el2,"src","assets/images/icons/history/lab.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ms-1 fw-semibold");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            var element15 = dom.childAt(element14, [9]);
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(element14,3,3);
            morphs[1] = dom.createMorphAt(element14,5,5);
            morphs[2] = dom.createUnsafeMorphAt(dom.childAt(element14, [7]),1,1);
            morphs[3] = dom.createElementMorph(element15);
            morphs[4] = dom.createMorphAt(element15,1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[312,26],[312,43]]]],"vetconnect"],[],["loc",[null,[312,22],[312,57]]]]],[],0,1,["loc",[null,[312,16],[317,23]]]],
            ["block","if",[["get","item.payload.reportType",["loc",[null,[317,30],[317,53]]]]],[],2,null,["loc",[null,[317,24],[317,139]]]],
            ["content","item.payload.text",["loc",[null,[320,16],[320,39]]]],
            ["element","action",["openLab",["get","item.referenceId",["loc",[null,[322,36],[322,52]]]]],[],["loc",[null,[322,17],[322,54]]]],
            ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[324,16],[324,71]]]]
          ],
          locals: [],
          templates: [child0, child1, child2]
        };
      }());
      var child14 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 328,
                "column": 10
              },
              "end": {
                "line": 340,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center img detail-box");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"class","mr10");
            dom.setAttribute(el2,"alt","");
            dom.setAttribute(el2,"style","width: 30px;");
            dom.setAttribute(el2,"src","assets/images/icons/history/img.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ms-1 fw-semibold");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square exp line-action");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1]);
            var element13 = dom.childAt(element12, [5]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element12, [3]),1,1);
            morphs[1] = dom.createElementMorph(element13);
            morphs[2] = dom.createMorphAt(element13,1,1);
            return morphs;
          },
          statements: [
            ["content","item.payload.text",["loc",[null,[333,16],[333,37]]]],
            ["element","action",["openLab",["get","item.referenceId",["loc",[null,[335,38],[335,54]]]]],[],["loc",[null,[335,19],[335,56]]]],
            ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[337,18],[337,73]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child15 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 344,
                  "column": 16
                },
                "end": {
                  "line": 346,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","textDetails");
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Anamnese:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.medicalHistory",["loc",[null,[345,65],[345,98]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 347,
                  "column": 16
                },
                "end": {
                  "line": 349,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","textDetails");
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Symptome:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.sypmtoms",["loc",[null,[348,65],[348,92]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 350,
                  "column": 16
                },
                "end": {
                  "line": 352,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","textDetails");
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Diagnose:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.diagnosis",["loc",[null,[351,65],[351,93]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 353,
                  "column": 16
                },
                "end": {
                  "line": 355,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","textDetails");
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Therapie:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.therapy",["loc",[null,[354,65],[354,91]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 356,
                  "column": 16
                },
                "end": {
                  "line": 358,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","textDetails");
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Beratung:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.report",["loc",[null,[357,65],[357,90]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 359,
                  "column": 16
                },
                "end": {
                  "line": 361,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","textDetails");
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Tierärztliche Leistungen:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.manualTreatment",["loc",[null,[360,81],[360,115]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child6 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 365,
                      "column": 43
                    },
                    "end": {
                      "line": 365,
                      "column": 84
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","article.name",["loc",[null,[365,68],[365,84]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 365,
                        "column": 92
                      },
                      "end": {
                        "line": 366,
                        "column": 148
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[366,132],[366,148]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 365,
                      "column": 84
                    },
                    "end": {
                      "line": 366,
                      "column": 160
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["services.edit",["get","article.id",["loc",[null,[365,119],[365,129]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[365,92],[366,160]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 364,
                    "column": 18
                  },
                  "end": {
                    "line": 367,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" x ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                return morphs;
              },
              statements: [
                ["content","article.quantity",["loc",[null,[365,20],[365,40]]]],
                ["block","if",[["get","item.eltigaRecord",["loc",[null,[365,49],[365,66]]]]],[],0,1,["loc",[null,[365,43],[366,167]]]]
              ],
              locals: ["article"],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 362,
                  "column": 16
                },
                "end": {
                  "line": 368,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Erhaltene Leistungen:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.services",["loc",[null,[364,26],[364,47]]]]],[],0,null,["loc",[null,[364,18],[367,27]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child7 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 372,
                      "column": 43
                    },
                    "end": {
                      "line": 372,
                      "column": 84
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","article.name",["loc",[null,[372,68],[372,84]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 372,
                        "column": 92
                      },
                      "end": {
                        "line": 373,
                        "column": 148
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[373,132],[373,148]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 372,
                      "column": 84
                    },
                    "end": {
                      "line": 373,
                      "column": 160
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["products.edit",["get","article.id",["loc",[null,[372,119],[372,129]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[372,92],[373,160]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 371,
                    "column": 18
                  },
                  "end": {
                    "line": 374,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" x ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                return morphs;
              },
              statements: [
                ["content","article.quantity",["loc",[null,[372,20],[372,40]]]],
                ["block","if",[["get","item.eltigaRecord",["loc",[null,[372,49],[372,66]]]]],[],0,1,["loc",[null,[372,43],[373,167]]]]
              ],
              locals: ["article"],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 369,
                  "column": 16
                },
                "end": {
                  "line": 375,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Erhaltene Produkte:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.products",["loc",[null,[371,26],[371,47]]]]],[],0,null,["loc",[null,[371,18],[374,27]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child8 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 379,
                      "column": 43
                    },
                    "end": {
                      "line": 379,
                      "column": 84
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","article.name",["loc",[null,[379,68],[379,84]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 379,
                        "column": 92
                      },
                      "end": {
                        "line": 380,
                        "column": 133
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[380,117],[380,133]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 379,
                      "column": 84
                    },
                    "end": {
                      "line": 380,
                      "column": 145
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["products.edit",["get","article.id",["loc",[null,[379,119],[379,129]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[379,92],[380,145]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 378,
                    "column": 18
                  },
                  "end": {
                    "line": 381,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" x ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                return morphs;
              },
              statements: [
                ["content","article.quantity",["loc",[null,[379,20],[379,40]]]],
                ["block","if",[["get","item.eltigaRecord",["loc",[null,[379,49],[379,66]]]]],[],0,1,["loc",[null,[379,43],[380,152]]]]
              ],
              locals: ["article"],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 376,
                  "column": 16
                },
                "end": {
                  "line": 382,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Erhaltene Medikamente:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.meds",["loc",[null,[378,26],[378,43]]]]],[],0,null,["loc",[null,[378,18],[381,27]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child9 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 386,
                      "column": 43
                    },
                    "end": {
                      "line": 386,
                      "column": 84
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","article.name",["loc",[null,[386,68],[386,84]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 386,
                        "column": 92
                      },
                      "end": {
                        "line": 387,
                        "column": 148
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[387,132],[387,148]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 386,
                      "column": 84
                    },
                    "end": {
                      "line": 387,
                      "column": 160
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["products.edit",["get","article.id",["loc",[null,[386,119],[386,129]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[386,92],[387,160]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 385,
                    "column": 18
                  },
                  "end": {
                    "line": 388,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" x ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                return morphs;
              },
              statements: [
                ["content","article.quantity",["loc",[null,[386,20],[386,40]]]],
                ["block","if",[["get","item.eltigaRecord",["loc",[null,[386,49],[386,66]]]]],[],0,1,["loc",[null,[386,43],[387,167]]]]
              ],
              locals: ["article"],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 383,
                  "column": 16
                },
                "end": {
                  "line": 389,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("b");
              var el2 = dom.createTextNode("Erhaltene Ernährung:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","item.payload.feeding",["loc",[null,[385,26],[385,46]]]]],[],0,null,["loc",[null,[385,18],[388,27]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child10 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 394,
                        "column": 22
                      },
                      "end": {
                        "line": 400,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","align-items-center mb5 mt5");
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2,"src","assets/images/icons/filetypes/pdf.svg");
                    dom.setAttribute(el2,"class","mr5");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","is-highlighted cursor");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element9 = dom.childAt(fragment, [1, 3]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element9);
                    morphs[1] = dom.createMorphAt(element9,0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["gotoTemplate",["get","item.referenceId",["loc",[null,[397,83],[397,99]]]],["get","template.id",["loc",[null,[398,68],[398,79]]]]],[],["loc",[null,[397,59],[398,81]]]],
                    ["content","template.name",["loc",[null,[398,82],[398,99]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 393,
                      "column": 20
                    },
                    "end": {
                      "line": 401,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","template.name",["loc",[null,[394,28],[394,41]]]]],[],0,null,["loc",[null,[394,22],[400,29]]]]
                ],
                locals: ["template"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 391,
                    "column": 18
                  },
                  "end": {
                    "line": 402,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createTextNode("Vorlagen:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.templates",["loc",[null,[393,28],[393,50]]]]],[],0,null,["loc",[null,[393,20],[401,29]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 406,
                        "column": 22
                      },
                      "end": {
                        "line": 412,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","align-items-center mb5 mt5");
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2,"src","assets/images/icons/filetypes/pdf.svg");
                    dom.setAttribute(el2,"class","mr5");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","is-highlighted cursor");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element8 = dom.childAt(fragment, [1, 3]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element8);
                    morphs[1] = dom.createMorphAt(element8,0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["gotoReport",["get","item.referenceId",["loc",[null,[409,81],[409,97]]]],["get","report.id",["loc",[null,[410,68],[410,77]]]]],[],["loc",[null,[409,59],[410,79]]]],
                    ["content","report.name",["loc",[null,[410,80],[410,95]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 405,
                      "column": 20
                    },
                    "end": {
                      "line": 413,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","report.name",["loc",[null,[406,28],[406,39]]]]],[],0,null,["loc",[null,[406,22],[412,29]]]]
                ],
                locals: ["report"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 403,
                    "column": 18
                  },
                  "end": {
                    "line": 414,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createTextNode("Berichte:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,5,5,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.reports",["loc",[null,[405,28],[405,48]]]]],[],0,null,["loc",[null,[405,20],[413,29]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 390,
                  "column": 16
                },
                "end": {
                  "line": 415,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.payload.templates",["loc",[null,[391,24],[391,46]]]]],[],0,null,["loc",[null,[391,18],[402,25]]]],
              ["block","if",[["get","item.payload.reports",["loc",[null,[403,24],[403,44]]]]],[],1,null,["loc",[null,[403,18],[414,25]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child11 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 420,
                      "column": 18
                    },
                    "end": {
                      "line": 427,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"style","margin-right: 45px");
                  dom.setAttribute(el1,"class","icon-button list-action-square line-action");
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","button-hide-app-with-confirm",[],["confirmed","hideTreatment","size","28","id",["subexpr","@mut",[["get","item.referenceId",["loc",[null,[423,56],[423,72]]]]],[],[]],"classNames","","color","#3B6182","content","In eltiga App sichtbar","text","Wollen Sie diese Behandlung für eltiga App Benutzer:innen wirklich ausblenden?"],["loc",[null,[422,22],[425,140]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 427,
                      "column": 18
                    },
                    "end": {
                      "line": 433,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"style","margin-right: 45px");
                  dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square line-action");
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element6 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element6);
                  morphs[1] = dom.createMorphAt(element6,1,1);
                  return morphs;
                },
                statements: [
                  ["element","action",["unhideTreatment",["get","item.referenceId",["loc",[null,[428,77],[428,93]]]]],[],["loc",[null,[428,50],[428,95]]]],
                  ["inline","button-unhide-app",[],["size",28,"showStroke",false,"innerColor","#fff","color","#fff","content",["subexpr","css-bool-evaluator",[["get","item.billed",["loc",[null,[431,70],[431,81]]]],"In eltiga App ausgeblendet","Ausgeblendet weil nicht bezahlt"],[],["loc",[null,[431,50],[431,145]]]]],["loc",[null,[430,22],[431,147]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 435,
                      "column": 18
                    },
                    "end": {
                      "line": 440,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square line-action exp");
                  dom.setAttribute(el1,"style","right: 100px;");
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element5);
                  morphs[1] = dom.createMorphAt(element5,1,1);
                  return morphs;
                },
                statements: [
                  ["element","action",["copy",["get","item.referenceId",["loc",[null,[436,39],[436,55]]]]],[],["loc",[null,[436,23],[436,57]]]],
                  ["inline","button-copy-list",[],["showStroke",false,"size","42","color","#fff","classNames",""],["loc",[null,[438,22],[438,96]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 419,
                    "column": 16
                  },
                  "end": {
                    "line": 446,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square exp line-action");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element7 = dom.childAt(fragment, [4]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                morphs[2] = dom.createElementMorph(element7);
                morphs[3] = dom.createMorphAt(element7,1,1);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.payload.eltigaShared",["loc",[null,[420,24],[420,49]]]]],[],0,1,["loc",[null,[420,18],[433,25]]]],
                ["block","if",[["get","showCopy",["loc",[null,[435,24],[435,32]]]]],[],2,null,["loc",[null,[435,18],[440,25]]]],
                ["element","action",["editTreatment",["get","item.referenceId",["loc",[null,[442,46],[442,62]]]]],[],["loc",[null,[442,21],[442,64]]]],
                ["inline","button-edit",[],["size","32","color","#fff","showStroke",false],["loc",[null,[444,20],[444,75]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 418,
                  "column": 14
                },
                "end": {
                  "line": 447,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","item.payload.eltigaRecord",["loc",[null,[419,26],[419,51]]]]],[],0,null,["loc",[null,[419,16],[446,27]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child12 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 459,
                    "column": 20
                  },
                  "end": {
                    "line": 463,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","dropdown-item");
                var el2 = dom.createTextNode("\n                        Bericht erstellen\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element1);
                return morphs;
              },
              statements: [
                ["element","action",["openReportChooser",["get","item.referenceId",["loc",[null,[460,54],[460,70]]]]],[],["loc",[null,[460,25],[460,72]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 463,
                      "column": 20
                    },
                    "end": {
                      "line": 467,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","dropdown-item");
                  var el2 = dom.createTextNode("\n                        Bericht(e) bearbeiten\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                    ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element0);
                  return morphs;
                },
                statements: [
                  ["element","action",["openReportChooser",["get","item.referenceId",["loc",[null,[464,54],[464,70]]]]],[],["loc",[null,[464,25],[464,72]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 463,
                    "column": 20
                  },
                  "end": {
                    "line": 467,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","gt",[["get","item.payload.reportCount",["loc",[null,[463,34],[463,58]]]],0],[],["loc",[null,[463,30],[463,61]]]]],[],0,null,["loc",[null,[463,20],[467,20]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 449,
                  "column": 14
                },
                "end": {
                  "line": 470,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","dropdown");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","ml5 icon-button icon-button--default list-action-square dropdown");
              dom.setAttribute(el2,"data-toggle","dropdown");
              dom.setAttribute(el2,"aria-haspopup","true");
              dom.setAttribute(el2,"aria-expanded","false");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createTextNode("Mehr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","dropdown-menu");
              dom.setAttribute(el2,"aria-labelledby","dropdownMenuButton");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","dropdown-item");
              var el4 = dom.createTextNode("\n                      Bearbeiten\n                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(element2, [3]);
              var element4 = dom.childAt(element3, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]),1,1);
              morphs[1] = dom.createElementMorph(element4);
              morphs[2] = dom.createMorphAt(element3,3,3);
              return morphs;
            },
            statements: [
              ["inline","button-more-hor",[],["showStroke",false,"color","#fff","size",40,"content",""],["loc",[null,[453,20],[453,88]]]],
              ["element","action",["editTreatment",["get","item.referenceId",["loc",[null,[456,48],[456,64]]]]],[],["loc",[null,[456,23],[456,66]]]],
              ["block","if",[["subexpr","eq",[["get","item.payload.reportCount",["loc",[null,[459,30],[459,54]]]],0],[],["loc",[null,[459,26],[459,57]]]]],[],0,1,["loc",[null,[459,20],[467,27]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 341,
                "column": 10
              },
              "end": {
                "line": 475,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","d-flex align-items-center detail-box treatment");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1]);
            var element11 = dom.childAt(element10, [1]);
            var morphs = new Array(13);
            morphs[0] = dom.createMorphAt(element11,1,1);
            morphs[1] = dom.createMorphAt(element11,2,2);
            morphs[2] = dom.createMorphAt(element11,3,3);
            morphs[3] = dom.createMorphAt(element11,4,4);
            morphs[4] = dom.createMorphAt(element11,5,5);
            morphs[5] = dom.createMorphAt(element11,6,6);
            morphs[6] = dom.createMorphAt(element11,7,7);
            morphs[7] = dom.createMorphAt(element11,8,8);
            morphs[8] = dom.createMorphAt(element11,9,9);
            morphs[9] = dom.createMorphAt(element11,10,10);
            morphs[10] = dom.createMorphAt(element11,11,11);
            morphs[11] = dom.createMorphAt(element10,3,3);
            morphs[12] = dom.createMorphAt(element10,5,5);
            return morphs;
          },
          statements: [
            ["block","if",[["get","item.payload.medicalHistory",["loc",[null,[344,22],[344,49]]]]],[],0,null,["loc",[null,[344,16],[346,23]]]],
            ["block","if",[["get","item.payload.sypmtoms",["loc",[null,[347,22],[347,43]]]]],[],1,null,["loc",[null,[347,16],[349,23]]]],
            ["block","if",[["get","item.payload.diagnosis",["loc",[null,[350,22],[350,44]]]]],[],2,null,["loc",[null,[350,16],[352,23]]]],
            ["block","if",[["get","item.payload.therapy",["loc",[null,[353,22],[353,42]]]]],[],3,null,["loc",[null,[353,16],[355,23]]]],
            ["block","if",[["get","item.payload.report",["loc",[null,[356,22],[356,41]]]]],[],4,null,["loc",[null,[356,16],[358,23]]]],
            ["block","if",[["get","item.payload.manualTreatment",["loc",[null,[359,22],[359,50]]]]],[],5,null,["loc",[null,[359,16],[361,23]]]],
            ["block","if",[["get","item.payload.services",["loc",[null,[362,22],[362,43]]]]],[],6,null,["loc",[null,[362,16],[368,23]]]],
            ["block","if",[["get","item.payload.products",["loc",[null,[369,22],[369,43]]]]],[],7,null,["loc",[null,[369,16],[375,23]]]],
            ["block","if",[["get","item.payload.meds",["loc",[null,[376,22],[376,39]]]]],[],8,null,["loc",[null,[376,16],[382,23]]]],
            ["block","if",[["get","item.payload.feeding",["loc",[null,[383,22],[383,42]]]]],[],9,null,["loc",[null,[383,16],[389,23]]]],
            ["block","unless",[["get","item.eltigaRecord",["loc",[null,[390,26],[390,43]]]]],[],10,null,["loc",[null,[390,16],[415,27]]]],
            ["block","unless",[["get","item.eltigaRecord",["loc",[null,[418,24],[418,41]]]]],[],11,null,["loc",[null,[418,14],[447,25]]]],
            ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[449,20],[449,28]]]]],[],12,null,["loc",[null,[449,14],[470,21]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 90,
              "column": 4
            },
            "end": {
              "line": 479,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","timeline-item");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","timeline-line");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pe-3 mb10");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","d-flex align-items-center mt-1 fs-6");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","me-2 fs-7");
          var el6 = dom.createElement("b");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(", ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                um ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                Uhr");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","tags ml20");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("              ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","headline");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element34 = dom.childAt(fragment, [1]);
          var element35 = dom.childAt(element34, [3]);
          var element36 = dom.childAt(element35, [1, 1]);
          var element37 = dom.childAt(element34, [5]);
          var element38 = dom.childAt(element37, [1]);
          var element39 = dom.childAt(element38, [1]);
          var element40 = dom.childAt(element39, [1, 0]);
          var element41 = dom.childAt(element39, [3]);
          var morphs = new Array(21);
          morphs[0] = dom.createAttrMorph(element35, 'class');
          morphs[1] = dom.createAttrMorph(element36, 'src');
          morphs[2] = dom.createAttrMorph(element37, 'class');
          morphs[3] = dom.createMorphAt(element40,0,0);
          morphs[4] = dom.createMorphAt(element40,2,2);
          morphs[5] = dom.createMorphAt(element40,4,4);
          morphs[6] = dom.createMorphAt(element41,1,1);
          morphs[7] = dom.createMorphAt(element41,2,2);
          morphs[8] = dom.createMorphAt(element41,3,3);
          morphs[9] = dom.createMorphAt(dom.childAt(element38, [3]),1,1);
          morphs[10] = dom.createMorphAt(element37,3,3);
          morphs[11] = dom.createMorphAt(element37,4,4);
          morphs[12] = dom.createMorphAt(element37,5,5);
          morphs[13] = dom.createMorphAt(element37,6,6);
          morphs[14] = dom.createMorphAt(element37,7,7);
          morphs[15] = dom.createMorphAt(element37,8,8);
          morphs[16] = dom.createMorphAt(element37,9,9);
          morphs[17] = dom.createMorphAt(element37,10,10);
          morphs[18] = dom.createMorphAt(element37,11,11);
          morphs[19] = dom.createMorphAt(element37,12,12);
          morphs[20] = dom.createMorphAt(element37,13,13);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["timeline-icon color-",["get","item.type",["loc",[null,[94,42],[94,51]]]]]]],
          ["attribute","src",["concat",["/assets/images/icons/history/",["get","item.type",["loc",[null,[96,53],[96,62]]]],".svg"]]],
          ["attribute","class",["concat",["timeline-content ",["get","item.type",["loc",[null,[99,39],[99,48]]]]," mb-10 mt-n1"]]],
          ["inline","get-weekday",[["get","item.date",["loc",[null,[102,54],[102,63]]]]],[],["loc",[null,[102,40],[102,65]]]],
          ["inline","time-format",[["get","item.date",["loc",[null,[102,81],[102,90]]]],"DD. MM. YYYY"],[],["loc",[null,[102,67],[102,107]]]],
          ["inline","time-format",[["get","item.date",["loc",[null,[103,33],[103,42]]]],"HH:mm"],[],["loc",[null,[103,19],[103,52]]]],
          ["block","each",[["get","item.tags",["loc",[null,[106,24],[106,33]]]]],[],0,null,["loc",[null,[106,16],[108,25]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[109,26],[109,35]]]],"lab"],[],["loc",[null,[109,22],[109,42]]]]],[],1,null,["loc",[null,[109,16],[117,23]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[118,26],[118,35]]]],"treat"],[],["loc",[null,[118,22],[118,44]]]]],[],2,null,["loc",[null,[118,16],[122,23]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[127,24],[127,33]]]],"treat"],[],["loc",[null,[127,20],[127,42]]]]],[],3,4,["loc",[null,[127,14],[160,21]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[164,20],[164,29]]]],"com"],[],["loc",[null,[164,16],[164,36]]]]],[],5,null,["loc",[null,[164,10],[179,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[180,20],[180,29]]]],"app"],[],["loc",[null,[180,16],[180,36]]]]],[],6,null,["loc",[null,[180,10],[190,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[191,20],[191,29]]]],"not"],[],["loc",[null,[191,16],[191,36]]]]],[],7,null,["loc",[null,[191,10],[201,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[202,20],[202,29]]]],"rem"],[],["loc",[null,[202,16],[202,36]]]]],[],8,null,["loc",[null,[202,10],[208,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[209,20],[209,29]]]],"mail"],[],["loc",[null,[209,16],[209,37]]]]],[],9,null,["loc",[null,[209,10],[215,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[216,20],[216,29]]]],"invoice"],[],["loc",[null,[216,16],[216,40]]]]],[],10,null,["loc",[null,[216,10],[277,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[278,20],[278,29]]]],"doc"],[],["loc",[null,[278,16],[278,36]]]]],[],11,null,["loc",[null,[278,10],[296,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[297,20],[297,29]]]],"vacc"],[],["loc",[null,[297,16],[297,37]]]]],[],12,null,["loc",[null,[297,10],[305,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[306,20],[306,29]]]],"lab"],[],["loc",[null,[306,16],[306,36]]]]],[],13,null,["loc",[null,[306,10],[327,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[328,20],[328,29]]]],"img"],[],["loc",[null,[328,16],[328,36]]]]],[],14,null,["loc",[null,[328,10],[340,17]]]],
          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[341,20],[341,29]]]],"treat"],[],["loc",[null,[341,16],[341,38]]]]],[],15,null,["loc",[null,[341,10],[475,17]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 544,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","patientActivityWrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","mb30 mt20 grid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter standalone col l-24/24");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Aktivitäten");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"style","padding-right: 9px");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"style","line-height: 30px");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/treat.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Behandlungen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/vacc.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Impfungen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/img.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Bildgebung");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/lab.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Labor");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/app.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Termine");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/com.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        eltiga Nachrichten");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/mail.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        E-Mail");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/invoice.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Rechnungen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/doc.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Dokumente");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/not.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Notizen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","/assets/images/icons/history/rem.svg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Erinnerungen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-5/24@desk l-24/24 mt10");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","pickDate");
        dom.setAttribute(el4,"class","date-picker input-element");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title mt0");
        var el6 = dom.createTextNode("Zeitraum");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","field-icon");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("i");
        dom.setAttribute(el6,"class","fa fa-calendar");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-1/24@desk u-hide@phone u-hide@tablet");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter mt10 col l-18/24@desk  l-24/24");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title mb10");
        var el5 = dom.createTextNode("Filter für Behandlungen und Rechnungen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","timeline");
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function () {\n    $('#pickDateJournal').daterangepicker({\n      \"autoApply\": true,\n      \"ranges\": {\n        \"Alle\": [moment('2016-01-01T00:00:00.000'), moment()],\n        \"Letzten 30 Tage\": [moment().subtract(30, 'days'), moment()],\n        \"Letzten 6 Monate\": [moment().subtract(6, 'months'), moment()],\n        \"Dieses Jahr\": [moment().startOf('year'), moment()],\n      },\n      \"locale\": {\n        \"format\": \"DD. MM. YYYY\",\n        \"separator\": \" - \",\n        \"applyLabel\": \"Anwenden\",\n        \"cancelLabel\": \"Abbrechen\",\n        \"fromLabel\": \"Von\",\n        \"toLabel\": \"Bis\",\n        \"customRangeLabel\": \"Benutzerdefiniert\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n      \"linkedCalendars\": false\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element43 = dom.childAt(fragment, [0]);
        var element44 = dom.childAt(element43, [1]);
        var element45 = dom.childAt(element44, [1]);
        var element46 = dom.childAt(element45, [3]);
        var element47 = dom.childAt(element45, [5]);
        var element48 = dom.childAt(element45, [7]);
        var element49 = dom.childAt(element45, [9]);
        var element50 = dom.childAt(element45, [11]);
        var element51 = dom.childAt(element45, [13]);
        var element52 = dom.childAt(element45, [15]);
        var element53 = dom.childAt(element45, [17]);
        var element54 = dom.childAt(element45, [19]);
        var element55 = dom.childAt(element45, [21]);
        var element56 = dom.childAt(element45, [23]);
        var element57 = dom.childAt(element45, [25]);
        var element58 = dom.childAt(element43, [3]);
        var morphs = new Array(30);
        morphs[0] = dom.createAttrMorph(element46, 'class');
        morphs[1] = dom.createElementMorph(element46);
        morphs[2] = dom.createAttrMorph(element47, 'class');
        morphs[3] = dom.createElementMorph(element47);
        morphs[4] = dom.createAttrMorph(element48, 'class');
        morphs[5] = dom.createElementMorph(element48);
        morphs[6] = dom.createAttrMorph(element49, 'class');
        morphs[7] = dom.createElementMorph(element49);
        morphs[8] = dom.createAttrMorph(element50, 'class');
        morphs[9] = dom.createElementMorph(element50);
        morphs[10] = dom.createAttrMorph(element51, 'class');
        morphs[11] = dom.createElementMorph(element51);
        morphs[12] = dom.createAttrMorph(element52, 'class');
        morphs[13] = dom.createElementMorph(element52);
        morphs[14] = dom.createAttrMorph(element53, 'class');
        morphs[15] = dom.createElementMorph(element53);
        morphs[16] = dom.createAttrMorph(element54, 'class');
        morphs[17] = dom.createElementMorph(element54);
        morphs[18] = dom.createAttrMorph(element55, 'class');
        morphs[19] = dom.createElementMorph(element55);
        morphs[20] = dom.createAttrMorph(element56, 'class');
        morphs[21] = dom.createElementMorph(element56);
        morphs[22] = dom.createAttrMorph(element57, 'class');
        morphs[23] = dom.createElementMorph(element57);
        morphs[24] = dom.createMorphAt(dom.childAt(element44, [3, 1]),3,3);
        morphs[25] = dom.createMorphAt(dom.childAt(element44, [7]),3,3);
        morphs[26] = dom.createMorphAt(element58,1,1);
        morphs[27] = dom.createMorphAt(dom.childAt(element58, [3]),1,1);
        morphs[28] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[29] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["selectable-item align-items-center color-all ",["subexpr","css-bool-evaluator",[["get","typeAllSelected",["loc",[null,[6,86],[6,101]]]],"selected-style",""],[],["loc",[null,[6,65],[7,91]]]]]]],
        ["element","action",["toggleTypeFilter","all"],[],["loc",[null,[7,93],[8,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-treat ",["subexpr","css-bool-evaluator",[["get","typeTreatmentSelected",["loc",[null,[9,88],[9,109]]]],"selected-style",""],[],["loc",[null,[9,67],[10,110]]]]]]],
        ["element","action",["toggleTypeFilter","treat"],[],["loc",[null,[10,112],[11,36]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-vacc ",["subexpr","css-bool-evaluator",[["get","typeVaccSelected",["loc",[null,[14,87],[14,103]]]],"selected-style",""],[],["loc",[null,[14,66],[15,92]]]]]]],
        ["element","action",["toggleTypeFilter","vacc"],[],["loc",[null,[15,94],[16,35]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-img ",["subexpr","css-bool-evaluator",[["get","typeImgSelected",["loc",[null,[19,86],[19,101]]]],"selected-style",""],[],["loc",[null,[19,65],[20,91]]]]]]],
        ["element","action",["toggleTypeFilter","img"],[],["loc",[null,[20,93],[21,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-lab ",["subexpr","css-bool-evaluator",[["get","typeLabSelected",["loc",[null,[24,86],[24,101]]]],"selected-style",""],[],["loc",[null,[24,65],[25,91]]]]]]],
        ["element","action",["toggleTypeFilter","lab"],[],["loc",[null,[25,93],[26,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-app ",["subexpr","css-bool-evaluator",[["get","typeAppSelected",["loc",[null,[29,86],[29,101]]]],"selected-style",""],[],["loc",[null,[29,65],[30,91]]]]]]],
        ["element","action",["toggleTypeFilter","app"],[],["loc",[null,[30,93],[31,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-com ",["subexpr","css-bool-evaluator",[["get","typeComSelected",["loc",[null,[34,86],[34,101]]]],"selected-style",""],[],["loc",[null,[34,65],[35,91]]]]]]],
        ["element","action",["toggleTypeFilter","com"],[],["loc",[null,[35,93],[36,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-mail ",["subexpr","css-bool-evaluator",[["get","typeMailSelected",["loc",[null,[39,87],[39,103]]]],"selected-style",""],[],["loc",[null,[39,66],[40,92]]]]]]],
        ["element","action",["toggleTypeFilter","mail"],[],["loc",[null,[40,94],[41,35]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-invoice ",["subexpr","css-bool-evaluator",[["get","typeInvoiceSelected",["loc",[null,[44,90],[44,109]]]],"selected-style",""],[],["loc",[null,[44,69],[46,95]]]]]]],
        ["element","action",["toggleTypeFilter","invoice"],[],["loc",[null,[46,97],[47,38]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-doc ",["subexpr","css-bool-evaluator",[["get","typeDocSelected",["loc",[null,[50,86],[50,101]]]],"selected-style",""],[],["loc",[null,[50,65],[51,91]]]]]]],
        ["element","action",["toggleTypeFilter","doc"],[],["loc",[null,[51,93],[52,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-not ",["subexpr","css-bool-evaluator",[["get","typeNotSelected",["loc",[null,[55,86],[55,101]]]],"selected-style",""],[],["loc",[null,[55,65],[56,91]]]]]]],
        ["element","action",["toggleTypeFilter","not"],[],["loc",[null,[56,93],[57,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-rem ",["subexpr","css-bool-evaluator",[["get","typeRemSelected",["loc",[null,[60,86],[60,101]]]],"selected-style",""],[],["loc",[null,[60,65],[61,91]]]]]]],
        ["element","action",["toggleTypeFilter","rem"],[],["loc",[null,[61,93],[62,34]]]],
        ["inline","input",[],["id","pickDateJournal","value",["subexpr","@mut",[["get","pickDateJournal",["loc",[null,[69,43],[69,58]]]]],[],[]],"class","new-style input input--date-picker ember-view ember-text-field","name","pickDateJournal"],["loc",[null,[69,8],[70,112]]]],
        ["block","each",[["get","availableTags",["loc",[null,[81,14],[81,27]]]]],[],0,null,["loc",[null,[81,6],[84,15]]]],
        ["block","each",[["get","items",["loc",[null,[90,12],[90,17]]]]],[],1,null,["loc",[null,[90,4],[479,13]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","items",["loc",[null,[481,29],[481,34]]]]],[],[]],"numPagesToShow",7],["loc",[null,[481,6],[481,53]]]],
        ["inline","treatment-history-report-chooser",[],["chooseReport","gotoReport","openWizard","openReportWizard","delete","deleteReport","prefix","-journal","actionReceiver",["subexpr","@mut",[["get","treatmentHistoryReportChooserActivities",["loc",[null,[491,17],[491,56]]]]],[],[]]],["loc",[null,[486,0],[491,58]]]],
        ["inline","treatment-report-wizzard-journal",[],["saveFinished","openReportPreview","actionReceiver",["subexpr","@mut",[["get","treatmentReportWizzardJournal",["loc",[null,[495,17],[495,46]]]]],[],[]]],["loc",[null,[493,0],[495,48]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});