define('jason-frontend/components/confirm-panel', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    text: "Wollen Sie wirklich löschen?",
    actions: {
      confirm: function confirm() {
        var el = this.get('element'),
            $confirm = $('.confirm-panel', el);
        $confirm.fadeOut();
        this.sendAction('confirm');
      },
      cancel: function cancel() {
        var el = this.get('element'),
            $confirm = $('.confirm-panel', el);
        $confirm.fadeOut();
      }
    }
  });

});