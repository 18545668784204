define('jason-frontend/routes/inventorywarehouses/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('inventoryWarehouse', params.id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.store.find('user', { per_page: 100, addAll: true }).then(function (data) {
        controller.set('users', data);
      });
    },
    actions: {
      save: function save(id) {
        var self = this;

        var controller = this.get('controller'),
            model = controller.get('model'),
            bankingAccount = new Object();

        bankingAccount.id = id;
        bankingAccount.name = model.get('name');
        bankingAccount.showStock = model.get('showStock');

        var userIds = new Array();
        $('#inventoryWarehouseEdit input.messageUser:checked').each(function (index, field) {
          userIds.push($(this).val());
        });

        bankingAccount.visibleFor = userIds;

        $.ajax({
          url: "/api/inventoryWarehouses/" + id,
          method: "PUT",
          data: JSON.stringify(bankingAccount),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.transitionTo('practice', { queryParams: { selectTab: "warehouses" } });
        }, function () {
          self.transitionTo('practice', { queryParams: { selectTab: "warehouses" } });
        });
      }
    }
  });

});