define('jason-frontend/templates/components/button-waiting-room', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 6
            },
            "end": {
              "line": 5,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/button-waiting-room.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("path");
          dom.setAttribute(el1,"d","M15,30 C23.2842713,30 30,23.2842713 30,15 C30,6.71572875 23.2842713,0 15,0 C6.71572875,0 0,6.71572875 0,15 C0,23.2842713 6.71572875,30 15,30 L15,30 L15,30 Z M15,28.5714286 C7.50470696,28.5714286 1.42857143,22.495293 1.42857143,15 C1.42857143,7.50470696 7.50470696,1.42857143 15,1.42857143 C22.495293,1.42857143 28.5714286,7.50470696 28.5714286,15 C28.5714286,22.495293 22.495293,28.5714286 15,28.5714286 L15,28.5714286 L15,28.5714286 Z");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'fill');
          return morphs;
        },
        statements: [
          ["attribute","fill",["concat",[["get","color",["loc",[null,[4,24],[4,29]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-waiting-room.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"fill-rule","evenodd");
        dom.setAttribute(el3,"transform","translate(9 0) scale(0.05, 0.05)");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M106.668,192c23.531,0,42.667-19.136,42.667-42.667c0-23.531-19.136-42.667-42.667-42.667     c-23.531,0-42.667,19.136-42.667,42.667C64.001,172.865,83.137,192,106.668,192z M106.668,128     c11.755,0,21.333,9.579,21.333,21.333c0,11.755-9.579,21.333-21.333,21.333c-11.755,0-21.333-9.579-21.333-21.333     C85.334,137.579,94.913,128,106.668,128z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M160.001,426.667H61.91L21.1,243.03c-1.28-5.76-6.976-9.301-12.736-8.107c-5.76,1.28-9.387,6.976-8.107,12.736     l42.411,190.848v62.827c0,5.888,4.779,10.667,10.667,10.667s10.667-4.779,10.688-10.667V448h63.979v53.333     c0,5.888,4.779,10.667,10.667,10.667s10.667-4.779,10.667-10.667V448h10.667c5.888,0,10.667-4.779,10.667-10.667     C170.668,431.446,165.889,426.667,160.001,426.667z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M224.001,341.334h-74.667V256c0-23.531-19.136-42.667-42.667-42.667c-23.531,0-42.667,19.136-42.667,42.667v117.333     c0,17.643,14.357,32,32,32h96V480c0,17.643,14.357,32,32,32s32-14.357,32-32V373.334     C256.001,355.691,241.644,341.334,224.001,341.334z M234.668,480c0,5.888-4.779,10.667-10.667,10.667     s-10.667-4.779-10.667-10.667v-85.333c0-5.888-4.779-10.667-10.667-10.667H96.001c-5.888,0-10.667-4.779-10.667-10.667V256     c0-11.755,9.579-21.333,21.333-21.333c11.755,0,21.333,9.579,21.333,21.333v96c0,5.888,4.779,10.667,10.667,10.667h85.333     c5.888,0,10.667,4.779,10.667,10.667V480z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element3, [5]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createMorphAt(element2,1,1);
        morphs[3] = dom.createAttrMorph(element3, 'fill');
        morphs[4] = dom.createAttrMorph(element4, 'fill');
        morphs[5] = dom.createAttrMorph(element5, 'fill');
        morphs[6] = dom.createAttrMorph(element6, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,12],[3,22]]]]],[],0,null,["loc",[null,[3,6],[5,13]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[6,19],[6,24]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[7,370],[7,375]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[8,411],[8,416]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[9,620],[9,625]]]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});