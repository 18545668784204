define('jason-frontend/helpers/in-list', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.inList = inList;

  function inList(params) {
    var idToCheck = params[0],
        list = params[1];

    if (list) {
      return list.indexOf(idToCheck) >= 0;
    }
    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(inList);

});