define('jason-frontend/templates/components/icon-module-purchase', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/icon-module-purchase.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"class","licenceIcon");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        var el2 = dom.createElement("title");
        var el3 = dom.createTextNode("Einkauf");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","#ADCCD4");
        dom.setAttribute(el2,"fill-rule","evenodd");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M60.683 33.608l-1.247-.07-18.428-1.302-8.875-.598-.392-1.935c-.142-.633-.57-1.196-1.14-1.478l-2.317-.774c-.356-.14-.784.072-.89.424-.143.35.07.774.427.88l2.174.738c.178.106.32.28.356.493l3.35 17.203c.037.14 0 .28-.035.387l-.89 2.287c-.25.633-.18 1.372.25 1.935.426.563 1.068.914 1.745.914h24.666c.392 0 .713-.315.713-.702 0-.387-.322-.704-.714-.704H34.77c-.25 0-.463-.105-.57-.316-.143-.212-.178-.423-.07-.67l.854-2.215h23.382c.963 0 1.818-.634 2.068-1.548l2.21-10.414c.142-.597 0-1.23-.393-1.688-.355-.492-.925-.773-1.567-.844zm.57 2.252l-2.21 10.378c-.07.282-.356.493-.677.493H35.09l-1.318-6.72-.107-.527-1.247-6.438 7.27.493 1.89.14 16.432 1.16 1.89.142.712.035c.214 0 .392.106.535.282.106.14.142.352.106.563z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M38.05 52.71c-1.57 0-2.852 1.268-2.852 2.816 0 1.548 1.283 2.814 2.852 2.814 1.568 0 2.85-1.266 2.85-2.814s-1.282-2.815-2.85-2.815zm0 4.223c-.785 0-1.426-.633-1.426-1.407s.64-1.407 1.426-1.407c.784 0 1.425.632 1.425 1.406 0 .774-.64 1.407-1.425 1.407zM55.87 52.71c-1.567 0-2.85 1.268-2.85 2.816 0 1.548 1.283 2.814 2.85 2.814 1.57 0 2.853-1.266 2.853-2.814S57.44 52.71 55.87 52.71zm0 4.223c-.783 0-1.424-.633-1.424-1.407s.64-1.407 1.425-1.407 1.427.632 1.427 1.406c0 .774-.642 1.407-1.426 1.407z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element0, 'viewBox');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","viewport",["loc",[null,[1,34],[1,42]]]]]]],
        ["attribute","height",["concat",[["get","viewport",["loc",[null,[1,56],[1,64]]]]]]],
        ["attribute","viewBox",["concat",["10 10 ",["get","size",["loc",[null,[1,85],[1,89]]]]," ",["get","size",["loc",[null,[1,94],[1,98]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});