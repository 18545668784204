define('jason-frontend/components/treatment-diagnose-select-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    selectedValues: new Array(),
    excludedValues: new Array(),
    filterCategory: null,
    loadingLeft: true,
    diagnosesVersion: '2',
    selectedCategoryId: null,
    timerId: {},
    syncToDom: function syncToDom() {
      var ids = '';
      this.get('selectedValues').forEach(function (elem) {
        ids += elem.id + ',';
      });
      $('#treatmentMultistageSelectForm').attr('data-ids', ids);
    },
    searchDiagnose: function searchDiagnose(parent, categoryId) {
      return this.externalContext.findPaged('digital-diagnose', {
        filterCategory: this.get('filterCategory'),
        filterVersion: this.get('diagnosesVersion'),
        filterCategoryId: categoryId,
        filterPatientId: this.get('patientId'),
        per_page: 5
      });
    },
    actions: {
      close: function close() {
        $.magnificPopup.close();
      },
      save: function save(treatmentPatientId) {

        var data = {
          values: this.get('selectedValues'),
          excludedValues: this.get('excludedValues')
        };

        $.ajax({
          url: "/api/treatmentPatients/" + treatmentPatientId + "/" + this.get('type'),
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (error) {});
      },
      doSearch: function doSearch() {
        var self = this;

        if (this.get('type') === 'diagnoses' || this.get('type') === 'finalDiagnoses') {
          this.set('loadingLeft', false);
          this.set('loadingRight', true);
          this.searchDiagnose(false, this.get('selectedCategoryId')).then(function (entries) {
            self.set('values', entries);
            self.set('totalRecords', entries.meta.total_records);
            self.set('categories', entries.meta.categories);
          });
        }
      },
      updateReference: function updateReference(field, model) {
        Ember['default'].set(model, field, this.get('selectedValues'));
      },
      select: function select(item) {
        var selected = !item.selected;

        this.get('values').forEach(function (elem) {
          Ember['default'].set(elem, 'selected', false);
        });
        if (selected) {
          Ember['default'].set(item, 'selected', true);
        }

        var found = false;

        this.get('selectedValues').forEach(function (elem) {
          if (elem.id == item.id) {
            found = true;
          }
        });

        if (!found) {
          this.get('selectedValues').pushObject(item);
          this.syncToDom();
        }
      },
      exclude: function exclude(item) {
        var found = false;

        this.get('excludedValues').forEach(function (elem) {
          if (elem.id == item.id) {
            found = true;
          }
        });

        if (!found) {
          this.get('excludedValues').pushObject(item);
          this.syncToDom();
        }
      },
      deleteItem: function deleteItem(id) {
        var itemToDelete = null;
        this.get('selectedValues').forEach(function (elem, idx) {
          if (elem.id == id) {
            itemToDelete = elem;
          }
        });
        if (itemToDelete) {
          this.get('selectedValues').removeObject(itemToDelete);
          this.syncToDom();
        }
      },
      excludeItem: function excludeItem(id) {
        var itemToMove = null;
        this.get('selectedValues').forEach(function (elem, idx) {
          if (elem.id == id) {
            itemToMove = elem;
          }
        });

        this.get('selectedValues').removeObject(itemToMove);

        if (itemToMove) {
          this.get('excludedValues').addObject(itemToMove);
          this.syncToDom();
        }
      },
      includeItem: function includeItem(id) {
        var itemToMove = null;
        this.get('excludedValues').forEach(function (elem, idx) {
          if (elem.id == id) {
            itemToMove = elem;
          }
        });

        this.get('excludedValues').removeObject(itemToMove);

        if (itemToMove) {
          this.get('selectedValues').addObject(itemToMove);
          this.syncToDom();
        }
      },
      deleteExcludedItem: function deleteExcludedItem(id) {
        var itemToDelete = null;
        this.get('excludedValues').forEach(function (elem, idx) {
          if (elem.id == id) {
            itemToDelete = elem;
          }
        });
        if (itemToDelete) {
          this.get('excludedValues').removeObject(itemToDelete);
          this.syncToDom();
        }
      },
      cancel: function cancel() {
        this.set('loading', true);
        $.magnificPopup.close();
      },
      load: function load(type, selected, excluded, ctx, title, patientId) {

        var self = this;

        this.set('title', title);
        this.set('patientId', patientId);
        this.set('type', type);
        this.set('selectedValues', new Array());
        this.set('excludedValues', new Array());
        this.set('rightValues', null);
        this.set('filterCategory', null);
        this.set('externalContext', ctx);
        this.set('selectedCategoryId', null);

        if (selected) {
          selected.forEach(function (elem) {
            self.get('selectedValues').pushObject(elem);
          });
        }
        if (excluded) {
          excluded.forEach(function (elem) {
            self.get('excludedValues').pushObject(elem);
          });
        }

        this.syncToDom();

        self.set('values', null);
        self.set('totalRecords', null);
        self.set('leftContent', null);

        setTimeout(function () {
          $('#filterNameDiagnoseSelect').focus();
        }, 500);
      }
    },
    watchSearch: (function () {
      var self = this;
      var type = 'defaultTimer';
      if (this.timerId[type]) {
        window.clearTimeout(this.timerId[type]);
      }
      this.timerId[type] = window.setTimeout(function () {
        self.send('doSearch');
      }, 500);
    }).observes('filterCategory')
  });

});