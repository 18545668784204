define('jason-frontend/models/agent', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    version: DS['default'].attr('string'),
    lastOnline: DS['default'].attr('date'),
    firstOnline: DS['default'].attr('date'),
    lastBackup: DS['default'].attr('date'),
    onlineStatus: DS['default'].attr('string'),
    localIp: DS['default'].attr('string'),
    printers: DS['default'].hasMany('printer')
  });

});