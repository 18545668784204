define('jason-frontend/helpers/is-test-mandant', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isTestMandant = isTestMandant;

  function isTestMandant() {
    return API['default'].getMandant() === '3' || API['default'].getMandant() === '10000';
  }

  exports['default'] = Ember['default'].Helper.helper(isTestMandant);

});