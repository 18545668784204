define('jason-frontend/tests/helpers/aupac-typeahead', ['exports', 'ember'], function (exports, Ember) {

        'use strict';

        exports['default'] = function () {

                /**
                 * Allows you to easily select an item from an ajax-search component.
                 *
                 * @param selector : jquery selector of the typeahead input element
                 * @param searchString : The search text
                 * @param suggestionIndex : The item to select from the drop down (starting at 1 for the first item)
                 */
                Ember['default'].Test.registerHelper('aupacTypeaheadSearch', function (app, selector, searchString, suggestionIndex) {

                        $(selector).eq(0).val(searchString).trigger("input");

                        Ember['default'].run(function () {
                                click('.tt-suggestion:nth-child(' + suggestionIndex + ')');
                        });

                        return app.testHelpers.wait();
                });
        }

});