define('jason-frontend/models/treatment', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    created: DS['default'].attr('date'),
    appointment: DS['default'].attr('boolean'),
    state: DS['default'].belongsTo('treatment-state'),
    customer: DS['default'].belongsTo('customer'),
    time: DS['default'].attr('string'),
    treatmentPatients: DS['default'].hasMany('treatment-patient')
  });

});