define('jason-frontend/templates/practicemgmt/treatment', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 4
            },
            "end": {
              "line": 23,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/treatment.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","button-station",[],["content","Stationen anzeigen","color","#FFFFFF","size","40"],["loc",[null,[22,6],[22,79]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 2
              },
              "end": {
                "line": 27,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/treatment.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-ready4payment",[],["content","Fertig zum Bezahlen","color","#FFFFFF","size","40"],["loc",[null,[26,4],[26,84]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 0
            },
            "end": {
              "line": 28,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/treatment.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["practicemgmt.payment"],["class","icon-button icon-button--mid"],0,null,["loc",[null,[25,2],[27,14]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 36,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/practicemgmt/treatment.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","site-content card transaction-filter");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","box");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        dom.setAttribute(el3,"class","u-mb0");
        var el4 = dom.createTextNode("Patienten aus Wartezimmer übernehmen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons ");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button icon-button--success");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(element1, [4]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(element0,1,1);
        morphs[1] = dom.createMorphAt(element0,5,5);
        morphs[2] = dom.createMorphAt(element1,1,1);
        morphs[3] = dom.createMorphAt(element1,2,2);
        morphs[4] = dom.createElementMorph(element2);
        morphs[5] = dom.createMorphAt(element2,1,1);
        morphs[6] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","treatment-patient-list",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[2,33],[2,38]]]]],[],[]],"type","in_treatment","clickPatient","clickPatient","movePatientToWaitingRoom","movePatientToWaitingRoom","movePatientToStation","movePatientToStation","abort","abort","exportInvoice","exportInvoice","editTreatmentInfo","editTreatmentInfo"],["loc",[null,[2,2],[8,4]]]],
        ["inline","treatment-patient-list",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[13,33],[13,38]]]]],[],[]],"type","in_waitingroom","movePatientToTreatmentRoom","movePatientToTreatmentRoom","abort","abort","editTreatmentInfo","editTreatmentInfo"],["loc",[null,[13,2],[17,4]]]],
        ["block","link-to",["practicemgmt.station"],["class","icon-button icon-button--mid"],0,null,["loc",[null,[21,4],[23,16]]]],
        ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[24,6],[24,19]]]]],[],1,null,["loc",[null,[24,0],[28,7]]]],
        ["element","action",["selectCustomerForTreatment"],[],["loc",[null,[29,7],[29,46]]]],
        ["inline","button-add-admission",[],["color","#FFFFFF","size","56"],["loc",[null,[30,6],[30,56]]]],
        ["inline","edit-treatment-infos-panel",[],["confirm","saveEditTreatmentInfo","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[34,67],[34,72]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[34,84],[34,94]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[34,103],[34,110]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","infoTreatmentPanel",["loc",[null,[34,126],[34,144]]]]],[],[]]],["loc",[null,[34,0],[34,146]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});