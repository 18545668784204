define('jason-frontend/templates/components/treatment-history', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 32,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","mb30 grid");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","quick-filter col l-24/24 l-6/24@desk");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","title");
          var el4 = dom.createTextNode("Mitarbeiter/In");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","filterOrderStatus");
          dom.setAttribute(el3,"class","input-element");
          dom.setAttribute(el3,"style","width: 100%");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","quick-filter u-hide@tablet col l-6/24");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","quick-filter col l-24/24  l-12/24");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","title");
          var el4 = dom.createTextNode("Tags");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("Ernährung");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("Leistungen");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("Produkte");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("Labor");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [1]);
          var element22 = dom.childAt(element21, [5]);
          var element23 = dom.childAt(element22, [3]);
          var element24 = dom.childAt(element22, [5]);
          var element25 = dom.childAt(element22, [7]);
          var element26 = dom.childAt(element22, [9]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(dom.childAt(element21, [1, 3]),1,1);
          morphs[1] = dom.createAttrMorph(element23, 'class');
          morphs[2] = dom.createElementMorph(element23);
          morphs[3] = dom.createAttrMorph(element24, 'class');
          morphs[4] = dom.createElementMorph(element24);
          morphs[5] = dom.createAttrMorph(element25, 'class');
          morphs[6] = dom.createElementMorph(element25);
          morphs[7] = dom.createAttrMorph(element26, 'class');
          morphs[8] = dom.createElementMorph(element26);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","users",["loc",[null,[7,18],[7,23]]]]],[],[]],"value",["subexpr","@mut",[["get","filterUser",["loc",[null,[8,16],[8,26]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","shortName","optionValuePath","id","placeholder","Alle","allowClear",true],["loc",[null,[6,8],[14,10]]]],
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showfeeding",["loc",[null,[21,59],[21,70]]]],"selected",""],[],["loc",[null,[21,38],[21,86]]]]]]],
          ["element","action",["toggleQuickfilter","showfeeding"],[],["loc",[null,[21,88],[22,112]]]],
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showservice",["loc",[null,[23,59],[23,70]]]],"selected",""],[],["loc",[null,[23,38],[23,86]]]]]]],
          ["element","action",["toggleQuickfilter","showservice"],[],["loc",[null,[23,88],[24,112]]]],
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showarticle",["loc",[null,[25,59],[25,70]]]],"selected",""],[],["loc",[null,[25,38],[25,86]]]]]]],
          ["element","action",["toggleQuickfilter","showarticle"],[],["loc",[null,[25,88],[26,112]]]],
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showlabor",["loc",[null,[27,59],[27,68]]]],"selected",""],[],["loc",[null,[27,38],[27,84]]]]]]],
          ["element","action",["toggleQuickfilter","showlabor"],[],["loc",[null,[27,86],[28,108]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 0
            },
            "end": {
              "line": 40,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col u-text--right l-24/24@desk  buttons-in-a-row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element20 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element20,1,1);
          morphs[1] = dom.createMorphAt(element20,3,3);
          morphs[2] = dom.createMorphAt(element20,5,5);
          morphs[3] = dom.createMorphAt(element20,7,7);
          return morphs;
        },
        statements: [
          ["inline","button-feeding",[],["size",50,"toggle","filterHistory","toggleAll","toggleAll"],["loc",[null,[35,4],[35,75]]]],
          ["inline","button-service",[],["size",50,"toggle","filterHistory","toggleAll","toggleAll"],["loc",[null,[36,4],[36,75]]]],
          ["inline","button-articles",[],["size",50,"toggle","filterHistory","toggleAll","toggleAll"],["loc",[null,[37,4],[37,76]]]],
          ["inline","button-labor",[],["size",50,"toggle","filterHistory","toggleAll","toggleAll"],["loc",[null,[38,4],[38,73]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 44,
              "column": 50
            },
            "end": {
              "line": 44,
              "column": 191
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","button-toggle-all",[],["size",30,"showStroke",false,"classNames","auto-center","active",["subexpr","@mut",[["get","showToggleAll",["loc",[null,[44,154],[44,167]]]]],[],[]],"toggleAll","toggleAll"],["loc",[null,[44,77],[44,191]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 13
            },
            "end": {
              "line": 47,
              "column": 118
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-4/24");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","table__title title");
          var el3 = dom.createTextNode("Patient");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 128
            },
            "end": {
              "line": 50,
              "column": 196
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" Ergebnisse in ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" Behandlungen");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["content","total",["loc",[null,[50,145],[50,154]]]],
          ["content","totalGroup",["loc",[null,[50,169],[50,183]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 58,
                  "column": 16
                },
                "end": {
                  "line": 60,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","cursor");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              return morphs;
            },
            statements: [
              ["inline","button-toggle",[],["entry",["subexpr","@mut",[["get","item",["loc",[null,[59,58],[59,62]]]]],[],[]],"toggle","toggle","content","Zeige erhaltene Produkte und Leistungen an"],["loc",[null,[59,36],[59,133]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 68,
                          "column": 22
                        },
                        "end": {
                          "line": 68,
                          "column": 169
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","icon-open-payment",[],["content","Offene Ausgangsrechnung","size","32","showStroke",true,"color","#EF5F4E"],["loc",[null,[68,72],[68,169]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 67,
                        "column": 20
                      },
                      "end": {
                        "line": 69,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["invoices.create",["get","item.info.invoiceId",["loc",[null,[68,51],[68,70]]]]],[],0,null,["loc",[null,[68,22],[68,181]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 69,
                        "column": 20
                      },
                      "end": {
                        "line": 71,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","icon-open-payment",[],["content","Noch nicht verrechnet","size","32","showStroke",true,"color","#4A4A4A"],["loc",[null,[70,22],[70,117]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 66,
                      "column": 18
                    },
                    "end": {
                      "line": 72,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","item.info.invoiceId",["loc",[null,[67,26],[67,45]]]]],[],0,1,["loc",[null,[67,20],[71,27]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 73,
                        "column": 20
                      },
                      "end": {
                        "line": 73,
                        "column": 168
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","icon-open-payment",[],["size","32","content","Bezahlte Ausgangsrechnung","showStroke",true,"color","#429321"],["loc",[null,[73,68],[73,168]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 72,
                      "column": 18
                    },
                    "end": {
                      "line": 74,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["invoices.show",["get","item.info.invoiceId",["loc",[null,[73,47],[73,66]]]]],[],0,null,["loc",[null,[73,20],[73,180]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 65,
                    "column": 16
                  },
                  "end": {
                    "line": 75,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","not",[["get","item.billed",["loc",[null,[66,29],[66,40]]]]],[],["loc",[null,[66,24],[66,41]]]]],[],0,1,["loc",[null,[66,18],[74,25]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 14
                },
                "end": {
                  "line": 76,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","and",[["get","showBillingInfo",["loc",[null,[65,27],[65,42]]]],["subexpr","show-prices",[],[],["loc",[null,[65,43],[65,56]]]]],[],["loc",[null,[65,22],[65,57]]]]],[],0,null,["loc",[null,[65,16],[75,23]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 76,
                  "column": 12
                },
                "end": {
                  "line": 78,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","button-etiga-small",[],["content","","classNames","inline-icon","size","24","width",50],["loc",[null,[77,14],[77,91]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 82,
                  "column": 67
                },
                "end": {
                  "line": 82,
                  "column": 111
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.dayTime",["loc",[null,[82,95],[82,111]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 83,
                  "column": 18
                },
                "end": {
                  "line": 83,
                  "column": 220
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","block small mb5");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("Std. ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("Min.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element14 = dom.childAt(fragment, [0]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element14,0,0);
              morphs[1] = dom.createMorphAt(element14,2,2);
              morphs[2] = dom.createMorphAt(element14,4,4);
              return morphs;
            },
            statements: [
              ["inline","button-duration",[],["color","#4A4A4A","classNames","inline-block verticaltop","size",16],["loc",[null,[83,76],[83,157]]]],
              ["content","item.durationHours",["loc",[null,[83,158],[83,180]]]],
              ["content","item.durationMinutes",["loc",[null,[83,185],[83,209]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 86,
                    "column": 22
                  },
                  "end": {
                    "line": 86,
                    "column": 84
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Lokation auswählen");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 86,
                    "column": 91
                  },
                  "end": {
                    "line": 86,
                    "column": 126
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.room.name",["loc",[null,[86,108],[86,126]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 84,
                  "column": 18
                },
                "end": {
                  "line": 88,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","mt5 clickable is-highlighted input--editable");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element13 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createElementMorph(element13);
              morphs[1] = dom.createMorphAt(element13,1,1);
              morphs[2] = dom.createMorphAt(element13,2,2);
              return morphs;
            },
            statements: [
              ["element","action",["editTreatmentRoom",["get","item",["loc",[null,[85,105],[85,109]]]]],[],["loc",[null,[85,76],[85,111]]]],
              ["block","if",[["subexpr","and",[["get","showChangeUser",["loc",[null,[86,33],[86,47]]]],["subexpr","not",[["get","item.room",["loc",[null,[86,53],[86,62]]]]],[],["loc",[null,[86,48],[86,63]]]]],[],["loc",[null,[86,28],[86,64]]]]],[],0,null,["loc",[null,[86,22],[86,91]]]],
              ["block","if",[["get","item.room",["loc",[null,[86,97],[86,106]]]]],[],1,null,["loc",[null,[86,91],[86,133]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child6 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 89,
                    "column": 20
                  },
                  "end": {
                    "line": 89,
                    "column": 55
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.room.name",["loc",[null,[89,37],[89,55]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 88,
                  "column": 18
                },
                "end": {
                  "line": 90,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.room",["loc",[null,[89,26],[89,35]]]]],[],0,null,["loc",[null,[89,20],[89,62]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child7 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 93,
                      "column": 101
                    },
                    "end": {
                      "line": 93,
                      "column": 334
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","animal-icon",[],["color","#3B6182","classNames","inline-icon middle","evil",["subexpr","@mut",[["get","item.patient.evil",["loc",[null,[93,197],[93,214]]]]],[],[]],"animal-id",["subexpr","@mut",[["get","item.patient.category.id",["loc",[null,[93,225],[93,249]]]]],[],[]],"race-id",["subexpr","@mut",[["get","item.patient.digitalRace.icon",["loc",[null,[93,258],[93,287]]]]],[],[]],"size","24","content",["subexpr","@mut",[["get","item.patient.category.name",["loc",[null,[93,306],[93,332]]]]],[],[]]],["loc",[null,[93,130],[93,334]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 93,
                    "column": 34
                  },
                  "end": {
                    "line": 93,
                    "column": 363
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.patient.category",["loc",[null,[93,107],[93,128]]]]],[],0,null,["loc",[null,[93,101],[93,341]]]],
                ["content","item.patient.name",["loc",[null,[93,342],[93,363]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 93,
                      "column": 454
                    },
                    "end": {
                      "line": 93,
                      "column": 605
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","gender-icon",[],["size","26","classNames","middle inline-icon","color","#3B6182","gender",["subexpr","@mut",[["get","item.patientOwner.gender.id",["loc",[null,[93,565],[93,592]]]]],[],[]],"content",""],["loc",[null,[93,486],[93,605]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 93,
                    "column": 380
                  },
                  "end": {
                    "line": 93,
                    "column": 642
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.patientOwner.gender",["loc",[null,[93,460],[93,484]]]]],[],0,null,["loc",[null,[93,454],[93,612]]]],
                ["content","item.patientOwner.fullName",["loc",[null,[93,612],[93,642]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 93,
                    "column": 655
                  },
                  "end": {
                    "line": 93,
                    "column": 807
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-ical",[],["size",24,"classNames","inline-block verticaltop","color","#00AAC6","content","eltiga App BenutzerIn"],["loc",[null,[93,698],[93,807]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 93,
                      "column": 807
                    },
                    "end": {
                      "line": 93,
                      "column": 973
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-ical",[],["size",24,"classNames","inline-block verticaltop","color","#797979","content","eltiga Tier QR Code verschickt"],["loc",[null,[93,855],[93,973]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 93,
                    "column": 807
                  },
                  "end": {
                    "line": 93,
                    "column": 973
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.patientOwner.etigaCodeRequested",["loc",[null,[93,817],[93,853]]]]],[],0,null,["loc",[null,[93,807],[93,973]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 92,
                  "column": 17
                },
                "end": {
                  "line": 94,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__cell l-12/24 l-6/24@tablet l-4/24@desk");
              dom.setAttribute(el1,"data-label","Patient");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2,"class","like-h3");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("br");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element12 = dom.childAt(fragment, [0, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element12,0,0);
              morphs[1] = dom.createMorphAt(element12,2,2);
              morphs[2] = dom.createMorphAt(element12,4,4);
              return morphs;
            },
            statements: [
              ["block","link-to",["patients.edit",["get","item.patient.id",["loc",[null,[93,61],[93,76]]]]],["class","is-highlighted"],0,null,["loc",[null,[93,34],[93,375]]]],
              ["block","link-to",["customers.edit",["get","item.patientOwner.id",["loc",[null,[93,408],[93,428]]]]],["class","is-highlighted"],1,null,["loc",[null,[93,380],[93,654]]]],
              ["block","if",[["get","item.patientOwner.etigaAppConnected",["loc",[null,[93,661],[93,696]]]]],[],2,3,["loc",[null,[93,655],[93,980]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }());
        var child8 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 100,
                      "column": 16
                    },
                    "end": {
                      "line": 100,
                      "column": 92
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("auswählen");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 100,
                      "column": 99
                    },
                    "end": {
                      "line": 100,
                      "column": 173
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [
                  ["content","item.doctor.firstname",["loc",[null,[100,118],[100,143]]]],
                  ["content","item.doctor.lastname",["loc",[null,[100,144],[100,168]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 100,
                      "column": 180
                    },
                    "end": {
                      "line": 100,
                      "column": 258
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.assistant.firstname",["loc",[null,[100,202],[100,230]]]],
                  ["content","item.assistant.lastname",["loc",[null,[100,231],[100,258]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 98,
                    "column": 18
                  },
                  "end": {
                    "line": 102,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","clickable is-highlighted input--editable");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element11 = dom.childAt(fragment, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createElementMorph(element11);
                morphs[1] = dom.createMorphAt(element11,1,1);
                morphs[2] = dom.createMorphAt(element11,2,2);
                morphs[3] = dom.createMorphAt(element11,3,3);
                return morphs;
              },
              statements: [
                ["element","action",["editTreatmentEmployees",["get","item",["loc",[null,[99,106],[99,110]]]]],[],["loc",[null,[99,72],[99,112]]]],
                ["block","if",[["subexpr","and",[["get","showChangeUser",["loc",[null,[100,27],[100,41]]]],["subexpr","not",[["get","item.assistant",["loc",[null,[100,47],[100,61]]]]],[],["loc",[null,[100,42],[100,62]]]],["subexpr","not",[["get","item.doctor",["loc",[null,[100,68],[100,79]]]]],[],["loc",[null,[100,63],[100,80]]]]],[],["loc",[null,[100,22],[100,81]]]]],[],0,null,["loc",[null,[100,16],[100,99]]]],
                ["block","if",[["get","item.doctor",["loc",[null,[100,105],[100,116]]]]],[],1,null,["loc",[null,[100,99],[100,180]]]],
                ["block","if",[["get","item.assistant",["loc",[null,[100,186],[100,200]]]]],[],2,null,["loc",[null,[100,180],[100,265]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 103,
                      "column": 18
                    },
                    "end": {
                      "line": 103,
                      "column": 92
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [
                  ["content","item.doctor.firstname",["loc",[null,[103,37],[103,62]]]],
                  ["content","item.doctor.lastname",["loc",[null,[103,63],[103,87]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 103,
                      "column": 99
                    },
                    "end": {
                      "line": 103,
                      "column": 177
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.assistant.firstname",["loc",[null,[103,121],[103,149]]]],
                  ["content","item.assistant.lastname",["loc",[null,[103,150],[103,177]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 102,
                    "column": 16
                  },
                  "end": {
                    "line": 104,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.doctor",["loc",[null,[103,24],[103,35]]]]],[],0,null,["loc",[null,[103,18],[103,99]]]],
                ["block","if",[["get","item.assistant",["loc",[null,[103,105],[103,119]]]]],[],1,null,["loc",[null,[103,99],[103,184]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 97,
                  "column": 14
                },
                "end": {
                  "line": 105,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","has-permission",["u_treatment_history"],[],["loc",[null,[98,24],[98,62]]]]],[],0,1,["loc",[null,[98,18],[104,23]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child9 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 105,
                  "column": 14
                },
                "end": {
                  "line": 107,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","item.mandantName",["loc",[null,[106,16],[106,36]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child10 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 111,
                  "column": 10
                },
                "end": {
                  "line": 113,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            Abgabe Produkte/Leistungen\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child11 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 114,
                      "column": 95
                    },
                    "end": {
                      "line": 114,
                      "column": 338
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" > ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","small");
                  var el2 = dom.createElement("b");
                  var el3 = dom.createTextNode("Anmerkung:");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),2,2);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [
                  ["inline","get-value-or-emptySign",[["get","item.info.digitalTopDiagnose",["loc",[null,[114,153],[114,181]]]]],[],["loc",[null,[114,128],[114,183]]]],
                  ["inline","get-value-or-emptySign",[["get","item.info.digitalDiagnose",["loc",[null,[114,211],[114,236]]]]],[],["loc",[null,[114,186],[114,238]]]],
                  ["inline","get-value-or-emptySign",[["get","item.info.diagnose",["loc",[null,[114,311],[114,329]]]]],[],["loc",[null,[114,286],[114,331]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 114,
                      "column": 338
                    },
                    "end": {
                      "line": 114,
                      "column": 393
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createUnsafeMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","get-value-or-emptySign",[["get","item.info.diagnose",["loc",[null,[114,372],[114,390]]]]],[],["loc",[null,[114,346],[114,393]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 114,
                    "column": 12
                  },
                  "end": {
                    "line": 115,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.info.digitalDiagnose",["loc",[null,[114,101],[114,126]]]]],[],0,1,["loc",[null,[114,95],[114,400]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 113,
                  "column": 10
                },
                "end": {
                  "line": 116,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","or",[["get","item.owner",["loc",[null,[114,22],[114,32]]]],["subexpr","is-etiga-readable",["addDiag",["get","etigaPermissions",["loc",[null,[114,62],[114,78]]]],["get","item.patient",["loc",[null,[114,79],[114,91]]]]],[],["loc",[null,[114,33],[114,92]]]]],[],["loc",[null,[114,18],[114,93]]]]],[],0,null,["loc",[null,[114,12],[115,19]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child12 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 120,
                  "column": 12
                },
                "end": {
                  "line": 124,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--success list-action-square");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element10 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element10);
              morphs[1] = dom.createMorphAt(element10,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["undelete",["get","item.id",["loc",[null,[121,97],[121,104]]]]],[],["loc",[null,[121,77],[121,106]]]],
              ["inline","button-checked",[],["content","Gelöschte Behandlung wiederherstellen","color","#fff","showStroke",false],["loc",[null,[122,16],[122,112]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child13 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 125,
                    "column": 14
                  },
                  "end": {
                    "line": 129,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element9 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element9);
                morphs[1] = dom.createMorphAt(element9,1,1);
                return morphs;
              },
              statements: [
                ["element","action",["copy",["get","model.id",["loc",[null,[126,35],[126,43]]]],["get","item.id",["loc",[null,[126,44],[126,51]]]]],[],["loc",[null,[126,19],[126,53]]]],
                ["inline","button-copy-list",[],["showStroke",false,"size","30","color","#fff","classNames",""],["loc",[null,[127,18],[127,92]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 130,
                    "column": 14
                  },
                  "end": {
                    "line": 135,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element8 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element8);
                morphs[1] = dom.createMorphAt(element8,1,1);
                return morphs;
              },
              statements: [
                ["element","action",["forwardToBilling",["get","item.id",["loc",[null,[131,107],[131,114]]]]],[],["loc",[null,[131,79],[131,116]]]],
                ["inline","button-ready4payment",[],["showStroke",false,"color","#fff","item",["subexpr","@mut",[["get","item",["loc",[null,[132,76],[132,80]]]]],[],[]],"size",30,"content","Übergabe an Ausgangsrechnung"],["loc",[null,[132,18],[133,81]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 137,
                      "column": 16
                    },
                    "end": {
                      "line": 143,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","item",["loc",[null,[139,98],[139,102]]]]],[],[]],"confirmed","deleteTreatment","text","Wollen Sie diese Behandlungsdetails wirklich löschen?"],["loc",[null,[139,20],[141,111]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 145,
                        "column": 18
                      },
                      "end": {
                        "line": 150,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element7 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element7);
                    morphs[1] = dom.createMorphAt(element7,1,1);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["open",["get","item.info.id",["loc",[null,[146,39],[146,51]]]],["get","item.patient",["loc",[null,[146,52],[146,64]]]],0,["get","etigaPermissions",["loc",[null,[146,67],[146,83]]]],["get","item.owner",["loc",[null,[146,84],[146,94]]]]],[],["loc",[null,[146,23],[146,96]]]],
                    ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[148,22],[148,88]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 144,
                      "column": 16
                    },
                    "end": {
                      "line": 151,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","not",[["get","item.appEditable",["loc",[null,[145,29],[145,45]]]]],[],["loc",[null,[145,24],[145,46]]]]],[],0,null,["loc",[null,[145,18],[150,25]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 136,
                    "column": 14
                  },
                  "end": {
                    "line": 152,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","and",[["get","item.owner",["loc",[null,[137,27],[137,37]]]],["subexpr","not",[["get","item.hasEntries",["loc",[null,[137,43],[137,58]]]]],[],["loc",[null,[137,38],[137,59]]]]],[],["loc",[null,[137,22],[137,60]]]]],[],0,null,["loc",[null,[137,16],[143,23]]]],
                ["block","unless",[["get","item.info.virtualTreatment",["loc",[null,[144,26],[144,52]]]]],[],1,null,["loc",[null,[144,16],[151,27]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 154,
                      "column": 16
                    },
                    "end": {
                      "line": 158,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element6 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element6);
                  morphs[1] = dom.createMorphAt(element6,1,1);
                  return morphs;
                },
                statements: [
                  ["element","action",["hide",["get","item.id",["loc",[null,[155,97],[155,104]]]],["get","item.hidden",["loc",[null,[155,105],[155,116]]]]],[],["loc",[null,[155,81],[155,118]]]],
                  ["inline","button-unhide",[],["content","einblenden","color","#fff","showStroke",false,"classNames","ml10"],["loc",[null,[156,20],[156,106]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 158,
                      "column": 16
                    },
                    "end": {
                      "line": 165,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","button-hide-with-confirm",[],["content","ausblenden","color","#fff","id",["subexpr","concat",["hide",["get","item.id",["loc",[null,[160,99],[160,106]]]]],[],["loc",[null,[160,84],[160,107]]]],"hidden",["subexpr","@mut",[["get","item.hidden",["loc",[null,[161,54],[161,65]]]]],[],[]],"showStroke",false,"classNames","","item",["subexpr","@mut",[["get","item",["loc",[null,[161,102],[161,106]]]]],[],[]],"confirmed","hide","text","Wollen Sie diese Behandlungsdetails wirklich ausblenden?"],["loc",[null,[160,20],[163,112]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 153,
                    "column": 14
                  },
                  "end": {
                    "line": 166,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.hidden",["loc",[null,[154,22],[154,33]]]]],[],0,1,["loc",[null,[154,16],[165,23]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child4 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 169,
                        "column": 18
                      },
                      "end": {
                        "line": 176,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","icon-button list-action-square");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-hide-app-with-confirm",[],["confirmed","hideForAppOverride","size","28","id",["subexpr","concat",["appHide",["get","item.id",["loc",[null,[172,74],[172,81]]]]],[],["loc",[null,[172,56],[172,82]]]],"hiddenForApp",["subexpr","@mut",[["get","item.hideAppOverride",["loc",[null,[172,96],[172,116]]]]],[],[]],"classNames","","color","#3B6182","content","In eltiga App sichtbar","text","Wollen Sie diese Behandlung für eltiga App BenutzerInnen wirklich ausblenden?"],["loc",[null,[171,22],[174,139]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 176,
                        "column": 18
                      },
                      "end": {
                        "line": 182,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","icon-button icon-button--default  list-action-square");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element5 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element5);
                    morphs[1] = dom.createMorphAt(element5,1,1);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["hideForAppOverride",["get","item.id",["loc",[null,[177,53],[177,60]]]],["get","item.hideAppOverride",["loc",[null,[177,61],[177,81]]]]],[],["loc",[null,[177,23],[177,83]]]],
                    ["inline","button-unhide-app",[],["size",28,"showStroke",false,"innerColor","#fff","color","#fff","content",["subexpr","css-bool-evaluator",[["get","item.billed",["loc",[null,[180,70],[180,81]]]],"In eltiga App ausgeblendet","Ausgeblendet weil nicht bezahlt"],[],["loc",[null,[180,50],[180,145]]]]],["loc",[null,[179,22],[180,147]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 168,
                      "column": 16
                    },
                    "end": {
                      "line": 183,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","item.hideAppOverride",["loc",[null,[169,24],[169,44]]]]],[],0,1,["loc",[null,[169,18],[182,25]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 184,
                        "column": 18
                      },
                      "end": {
                        "line": 190,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","icon-button icon-button--default  list-action-square");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element4 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element4);
                    morphs[1] = dom.createMorphAt(element4,1,1);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["hideForApp",["get","item.id",["loc",[null,[185,45],[185,52]]]],["get","item.hiddenForApp",["loc",[null,[185,53],[185,70]]]]],[],["loc",[null,[185,23],[185,72]]]],
                    ["inline","button-unhide-app",[],["size",28,"showStroke",false,"innerColor","#fff","color","#fff","content",["subexpr","css-bool-evaluator",[["get","item.billed",["loc",[null,[188,70],[188,81]]]],"In eltiga App ausgeblendet","Ausgeblendet weil nicht bezahlt"],[],["loc",[null,[188,50],[188,145]]]]],["loc",[null,[187,22],[188,147]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 190,
                        "column": 18
                      },
                      "end": {
                        "line": 197,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","icon-button list-action-square");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-hide-app-with-confirm",[],["confirmed","hideForApp","size","28","id",["subexpr","concat",["appHide",["get","item.id",["loc",[null,[192,107],[192,114]]]]],[],["loc",[null,[192,89],[192,115]]]],"hiddenForApp",["subexpr","@mut",[["get","item.hiddenForApp",["loc",[null,[193,66],[193,83]]]]],[],[]],"classNames","","color","#3B6182","content","In eltiga App sichtbar","text","Wollen Sie diese Behandlung für eltiga App BenutzerInnen wirklich ausblenden?"],["loc",[null,[192,22],[195,139]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 183,
                      "column": 16
                    },
                    "end": {
                      "line": 198,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","item.hiddenForApp",["loc",[null,[184,24],[184,41]]]]],[],0,1,["loc",[null,[184,18],[197,25]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 167,
                    "column": 14
                  },
                  "end": {
                    "line": 199,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","and",[["get","item.globalAppOverride",["loc",[null,[168,27],[168,49]]]],["subexpr","not",[["get","item.billed",["loc",[null,[168,55],[168,66]]]]],[],["loc",[null,[168,50],[168,67]]]]],[],["loc",[null,[168,22],[168,68]]]]],[],0,1,["loc",[null,[168,16],[198,23]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 124,
                  "column": 12
                },
                "end": {
                  "line": 200,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
              morphs[3] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[4] = dom.createMorphAt(fragment,4,4,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","and",[["get","item.hasEntries",["loc",[null,[125,25],[125,40]]]],["get","showCopy",["loc",[null,[125,41],[125,49]]]]],[],["loc",[null,[125,20],[125,50]]]]],[],0,null,["loc",[null,[125,14],[129,21]]]],
              ["block","if",[["subexpr","and",[["subexpr","show-prices",[],[],["loc",[null,[130,25],[130,38]]]],["subexpr","not",[["get","item.info.invoiceId",["loc",[null,[130,44],[130,63]]]]],[],["loc",[null,[130,39],[130,64]]]],["subexpr","not",[["get","item.billed",["loc",[null,[130,70],[130,81]]]]],[],["loc",[null,[130,65],[130,82]]]],["get","item.hasEntries",["loc",[null,[130,83],[130,98]]]]],[],["loc",[null,[130,20],[130,99]]]]],[],1,null,["loc",[null,[130,14],[135,21]]]],
              ["block","if",[["get","showEdit",["loc",[null,[136,20],[136,28]]]]],[],2,null,["loc",[null,[136,14],[152,21]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["get","item.appEditable",["loc",[null,[153,30],[153,46]]]]],[],["loc",[null,[153,25],[153,47]]]],["get","showHide",["loc",[null,[153,48],[153,56]]]]],[],["loc",[null,[153,20],[153,57]]]]],[],3,null,["loc",[null,[153,14],[166,21]]]],
              ["block","if",[["subexpr","and",[["get","showHideApp",["loc",[null,[167,25],[167,36]]]],["get","item.owner",["loc",[null,[167,37],[167,47]]]]],[],["loc",[null,[167,20],[167,48]]]]],[],4,null,["loc",[null,[167,14],[199,21]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4]
          };
        }());
        var child14 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 203,
                  "column": 14
                },
                "end": {
                  "line": 204,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child15 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 205,
                    "column": 16
                  },
                  "end": {
                    "line": 208,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("b");
                dom.setAttribute(el2,"class","is-error");
                var el3 = dom.createTextNode("Behandlung wurde gelöscht");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 210,
                    "column": 67
                  },
                  "end": {
                    "line": 210,
                    "column": 203
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","get-value-or-emptySign",[["get","item.info.medicalHistory",["loc",[null,[210,176],[210,200]]]]],[],["loc",[null,[210,150],[210,203]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 211,
                    "column": 16
                  },
                  "end": {
                    "line": 212,
                    "column": 139
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Vorstellungsgründe:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [2, 0]),0,0);
                return morphs;
              },
              statements: [
                ["inline","print-list-of-name",[["get","item.info.introductionReasons",["loc",[null,[212,89],[212,118]]]],", "],[],["loc",[null,[212,67],[212,126]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 213,
                    "column": 16
                  },
                  "end": {
                    "line": 214,
                    "column": 125
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Administrative Tätigkeiten:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [2, 0]),0,0);
                return morphs;
              },
              statements: [
                ["inline","print-list-of-name",[["get","item.info.adminTasks",["loc",[null,[214,89],[214,109]]]]],[],["loc",[null,[214,67],[214,112]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 216,
                    "column": 67
                  },
                  "end": {
                    "line": 216,
                    "column": 194
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","get-value-or-emptySign",[["get","item.info.symptom",["loc",[null,[216,175],[216,192]]]]],[],["loc",[null,[216,150],[216,194]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child5 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 217,
                    "column": 16
                  },
                  "end": {
                    "line": 218,
                    "column": 123
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Klinische Symptomatik:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [2, 0]),0,0);
                return morphs;
              },
              statements: [
                ["inline","print-list-of-name",[["get","item.info.symptoms",["loc",[null,[218,89],[218,107]]]]],[],["loc",[null,[218,67],[218,110]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child6 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 222,
                        "column": 34
                      },
                      "end": {
                        "line": 222,
                        "column": 71
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" (");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(")");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["content","dia.details",["loc",[null,[222,55],[222,70]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 222,
                        "column": 78
                      },
                      "end": {
                        "line": 222,
                        "column": 146
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(", ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 221,
                      "column": 18
                    },
                    "end": {
                      "line": 223,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(3);
                  morphs[0] = dom.createUnsafeMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","dia.name",["loc",[null,[222,20],[222,34]]]],
                  ["block","if",[["get","dia.details",["loc",[null,[222,40],[222,51]]]]],[],0,null,["loc",[null,[222,34],[222,78]]]],
                  ["block","unless",[["subexpr","eq",[["get","index",["loc",[null,[222,92],[222,97]]]],["subexpr","minus",[["get","item.info.digitalDiagnoses.length",["loc",[null,[222,105],[222,138]]]],1],[],["loc",[null,[222,98],[222,141]]]]],[],["loc",[null,[222,88],[222,142]]]]],[],1,null,["loc",[null,[222,78],[222,157]]]]
                ],
                locals: ["dia"],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 219,
                    "column": 16
                  },
                  "end": {
                    "line": 224,
                    "column": 29
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Vorläufige Diagnosen:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2, 0]),1,1);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.info.digitalDiagnoses",["loc",[null,[221,26],[221,52]]]]],[],0,null,["loc",[null,[221,18],[223,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child7 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 228,
                        "column": 35
                      },
                      "end": {
                        "line": 228,
                        "column": 72
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" (");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(")");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["content","dia.details",["loc",[null,[228,56],[228,71]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 228,
                        "column": 79
                      },
                      "end": {
                        "line": 228,
                        "column": 152
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(", ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 227,
                      "column": 19
                    },
                    "end": {
                      "line": 229,
                      "column": 19
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 2,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                     ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(3);
                  morphs[0] = dom.createUnsafeMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","dia.name",["loc",[null,[228,21],[228,35]]]],
                  ["block","if",[["get","dia.details",["loc",[null,[228,41],[228,52]]]]],[],0,null,["loc",[null,[228,35],[228,79]]]],
                  ["block","unless",[["subexpr","eq",[["get","index",["loc",[null,[228,93],[228,98]]]],["subexpr","minus",[["get","item.info.finalDigitalDiagnoses.length",["loc",[null,[228,106],[228,144]]]],1],[],["loc",[null,[228,99],[228,147]]]]],[],["loc",[null,[228,89],[228,148]]]]],[],1,null,["loc",[null,[228,79],[228,163]]]]
                ],
                locals: ["dia","index"],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 225,
                    "column": 16
                  },
                  "end": {
                    "line": 230,
                    "column": 29
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Finale Diagnosen:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2, 0]),1,1);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.info.finalDigitalDiagnoses",["loc",[null,[227,27],[227,58]]]]],[],0,null,["loc",[null,[227,19],[229,28]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child8 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 232,
                    "column": 67
                  },
                  "end": {
                    "line": 232,
                    "column": 195
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","get-value-or-emptySign",[["get","item.info.therapy",["loc",[null,[232,176],[232,193]]]]],[],["loc",[null,[232,151],[232,195]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child9 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 233,
                    "column": 16
                  },
                  "end": {
                    "line": 234,
                    "column": 131
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Therapeutische Behandlungen:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [2, 0]),0,0);
                return morphs;
              },
              statements: [
                ["inline","print-list-of-name",[["get","item.info.digitalTherapies",["loc",[null,[234,89],[234,115]]]]],[],["loc",[null,[234,67],[234,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child10 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 236,
                      "column": 67
                    },
                    "end": {
                      "line": 236,
                      "column": 195
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createUnsafeMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","get-value-or-emptySign",[["get","item.info.report",["loc",[null,[236,176],[236,192]]]]],[],["loc",[null,[236,150],[236,195]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 235,
                    "column": 16
                  },
                  "end": {
                    "line": 236,
                    "column": 215
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Beratung:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2, 0]),0,0);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","or",[["get","item.owner",["loc",[null,[236,77],[236,87]]]],["subexpr","is-etiga-readable",["addAnam",["get","etigaPermissions",["loc",[null,[236,117],[236,133]]]],["get","item.patient",["loc",[null,[236,134],[236,146]]]]],[],["loc",[null,[236,88],[236,147]]]]],[],["loc",[null,[236,73],[236,148]]]]],[],0,null,["loc",[null,[236,67],[236,202]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child11 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 238,
                      "column": 182
                    },
                    "end": {
                      "line": 238,
                      "column": 232
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\"");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\"");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","item.ratingComment",["loc",[null,[238,209],[238,231]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 237,
                    "column": 16
                  },
                  "end": {
                    "line": 238,
                    "column": 252
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@deskmt10");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Feedback:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk mt10");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","ml10 inline");
                dom.setAttribute(el2,"style","font-style: italic");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [2]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element3,0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element3, [2]),0,0);
                return morphs;
              },
              statements: [
                ["inline","rating-status",[],["rating",["subexpr","@mut",[["get","item.rating",["loc",[null,[238,89],[238,100]]]]],[],[]],"classNames","inline-block"],["loc",[null,[238,66],[238,128]]]],
                ["block","if",[["get","item.ratingComment",["loc",[null,[238,188],[238,206]]]]],[],0,null,["loc",[null,[238,182],[238,239]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child12 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 239,
                    "column": 16
                  },
                  "end": {
                    "line": 240,
                    "column": 112
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Tierärztliche Leistungen:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [2, 0]),0,0);
                return morphs;
              },
              statements: [
                ["content","item.info.manualTreatments",["loc",[null,[240,67],[240,99]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 204,
                  "column": 14
                },
                "end": {
                  "line": 241,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
              var el2 = dom.createElement("b");
              var el3 = dom.createTextNode("Anamnese:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
              var el2 = dom.createElement("b");
              var el3 = dom.createTextNode("Symptome:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
              var el2 = dom.createElement("b");
              var el3 = dom.createTextNode("Therapie:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(13);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [4, 0]),0,0);
              morphs[2] = dom.createMorphAt(fragment,6,6,contextualElement);
              morphs[3] = dom.createMorphAt(fragment,8,8,contextualElement);
              morphs[4] = dom.createMorphAt(dom.childAt(fragment, [12, 0]),0,0);
              morphs[5] = dom.createMorphAt(fragment,14,14,contextualElement);
              morphs[6] = dom.createMorphAt(fragment,16,16,contextualElement);
              morphs[7] = dom.createMorphAt(fragment,18,18,contextualElement);
              morphs[8] = dom.createMorphAt(dom.childAt(fragment, [22, 0]),0,0);
              morphs[9] = dom.createMorphAt(fragment,24,24,contextualElement);
              morphs[10] = dom.createMorphAt(fragment,26,26,contextualElement);
              morphs[11] = dom.createMorphAt(fragment,28,28,contextualElement);
              morphs[12] = dom.createMorphAt(fragment,30,30,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.deleted",["loc",[null,[205,22],[205,34]]]]],[],0,null,["loc",[null,[205,16],[208,23]]]],
              ["block","if",[["subexpr","or",[["get","item.owner",["loc",[null,[210,77],[210,87]]]],["subexpr","is-etiga-readable",["addAnam",["get","etigaPermissions",["loc",[null,[210,117],[210,133]]]],["get","item.patient",["loc",[null,[210,134],[210,146]]]]],[],["loc",[null,[210,88],[210,147]]]]],[],["loc",[null,[210,73],[210,148]]]]],[],1,null,["loc",[null,[210,67],[210,210]]]],
              ["block","if",[["subexpr","and",[["subexpr","or",[["get","item.owner",["loc",[null,[211,31],[211,41]]]],["subexpr","is-etiga-readable",["addAnam",["get","etigaPermissions",["loc",[null,[211,71],[211,87]]]],["get","item.patient",["loc",[null,[211,88],[211,100]]]]],[],["loc",[null,[211,42],[211,101]]]]],[],["loc",[null,[211,27],[211,102]]]],["get","item.info.introductionReasons",["loc",[null,[211,103],[211,132]]]]],[],["loc",[null,[211,22],[211,133]]]]],[],2,null,["loc",[null,[211,16],[212,146]]]],
              ["block","if",[["subexpr","and",[["subexpr","or",[["get","item.owner",["loc",[null,[213,31],[213,41]]]],["subexpr","is-etiga-readable",["addAnam",["get","etigaPermissions",["loc",[null,[213,71],[213,87]]]],["get","item.patient",["loc",[null,[213,88],[213,100]]]]],[],["loc",[null,[213,42],[213,101]]]]],[],["loc",[null,[213,27],[213,102]]]],["get","item.info.adminTasks",["loc",[null,[213,103],[213,123]]]]],[],["loc",[null,[213,22],[213,124]]]]],[],3,null,["loc",[null,[213,16],[214,132]]]],
              ["block","if",[["subexpr","or",[["get","item.owner",["loc",[null,[216,77],[216,87]]]],["subexpr","is-etiga-readable",["addSymp",["get","etigaPermissions",["loc",[null,[216,117],[216,133]]]],["get","item.patient",["loc",[null,[216,134],[216,146]]]]],[],["loc",[null,[216,88],[216,147]]]]],[],["loc",[null,[216,73],[216,148]]]]],[],4,null,["loc",[null,[216,67],[216,201]]]],
              ["block","if",[["subexpr","and",[["subexpr","or",[["get","item.owner",["loc",[null,[217,31],[217,41]]]],["subexpr","is-etiga-readable",["addSymp",["get","etigaPermissions",["loc",[null,[217,71],[217,87]]]],["get","item.patient",["loc",[null,[217,88],[217,100]]]]],[],["loc",[null,[217,42],[217,101]]]]],[],["loc",[null,[217,27],[217,102]]]],["get","item.info.symptoms",["loc",[null,[217,103],[217,121]]]]],[],["loc",[null,[217,22],[217,122]]]]],[],5,null,["loc",[null,[217,16],[218,130]]]],
              ["block","if",[["subexpr","and",[["subexpr","or",[["get","item.owner",["loc",[null,[219,31],[219,41]]]],["subexpr","is-etiga-readable",["addDiag",["get","etigaPermissions",["loc",[null,[219,71],[219,87]]]],["get","item.patient",["loc",[null,[219,88],[219,100]]]]],[],["loc",[null,[219,42],[219,101]]]]],[],["loc",[null,[219,27],[219,102]]]],["get","item.info.digitalDiagnoses",["loc",[null,[219,103],[219,129]]]]],[],["loc",[null,[219,22],[219,130]]]]],[],6,null,["loc",[null,[219,16],[224,36]]]],
              ["block","if",[["subexpr","and",[["subexpr","or",[["get","item.owner",["loc",[null,[225,31],[225,41]]]],["subexpr","is-etiga-readable",["addDiag",["get","etigaPermissions",["loc",[null,[225,71],[225,87]]]],["get","item.patient",["loc",[null,[225,88],[225,100]]]]],[],["loc",[null,[225,42],[225,101]]]]],[],["loc",[null,[225,27],[225,102]]]],["get","item.info.finalDigitalDiagnoses",["loc",[null,[225,103],[225,134]]]]],[],["loc",[null,[225,22],[225,135]]]]],[],7,null,["loc",[null,[225,16],[230,36]]]],
              ["block","if",[["subexpr","or",[["get","item.owner",["loc",[null,[232,77],[232,87]]]],["subexpr","is-etiga-readable",["addThera",["get","etigaPermissions",["loc",[null,[232,118],[232,134]]]],["get","item.patient",["loc",[null,[232,135],[232,147]]]]],[],["loc",[null,[232,88],[232,148]]]]],[],["loc",[null,[232,73],[232,149]]]]],[],8,null,["loc",[null,[232,67],[232,202]]]],
              ["block","if",[["subexpr","and",[["subexpr","or",[["get","item.owner",["loc",[null,[233,31],[233,41]]]],["subexpr","is-etiga-readable",["addThera",["get","etigaPermissions",["loc",[null,[233,72],[233,88]]]],["get","item.patient",["loc",[null,[233,89],[233,101]]]]],[],["loc",[null,[233,42],[233,102]]]]],[],["loc",[null,[233,27],[233,103]]]],["get","item.info.digitalTherapies",["loc",[null,[233,104],[233,130]]]]],[],["loc",[null,[233,22],[233,131]]]]],[],9,null,["loc",[null,[233,16],[234,138]]]],
              ["block","if",[["get","item.info.report",["loc",[null,[235,22],[235,38]]]]],[],10,null,["loc",[null,[235,16],[236,222]]]],
              ["block","if",[["get","item.rating",["loc",[null,[237,22],[237,33]]]]],[],11,null,["loc",[null,[237,16],[238,259]]]],
              ["block","if",[["get","item.info.manualTreatments",["loc",[null,[239,22],[239,48]]]]],[],12,null,["loc",[null,[239,16],[240,119]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12]
          };
        }());
        var child16 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 244,
                  "column": 12
                },
                "end": {
                  "line": 246,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","button context");
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/edit.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2,"class","pl5");
              var el3 = dom.createTextNode("Berichte");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element2);
              return morphs;
            },
            statements: [
              ["element","action",["openReportChooser",["get","item.id",["loc",[null,[245,46],[245,53]]]]],[],["loc",[null,[245,17],[245,55]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child17 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 255,
                    "column": 66
                  },
                  "end": {
                    "line": 255,
                    "column": 153
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","table__title title");
                var el2 = dom.createTextNode("VK Brutto");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 256,
                      "column": 138
                    },
                    "end": {
                      "line": 256,
                      "column": 179
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("Rabatt");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 256,
                      "column": 179
                    },
                    "end": {
                      "line": 256,
                      "column": 193
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("Faktor");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 256,
                    "column": 67
                  },
                  "end": {
                    "line": 256,
                    "column": 207
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","table__title title");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[256,144],[256,171]]]]],[],0,1,["loc",[null,[256,138],[256,200]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 257,
                    "column": 66
                  },
                  "end": {
                    "line": 257,
                    "column": 150
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","table__title title");
                var el2 = dom.createTextNode("Gesamt");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 262,
                    "column": 20
                  },
                  "end": {
                    "line": 279,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","treatment-now-entry",[],["classNames",["subexpr","@mut",[["get","entry.type",["loc",[null,[265,37],[265,47]]]]],[],[]],"openLaboklinChoosePanel","openLaboklinChoosePanel","downloadLaboklinBarcode","downloadBarcode","openSlopingInfos","openSlopingInfos","openTemplateEdit","openTemplateEdit","openDigitalForm","openDigitalForm","readOnly",true,"showPrice",["subexpr","@mut",[["get","item.owner",["loc",[null,[272,36],[272,46]]]]],[],[]],"patient",["subexpr","@mut",[["get","item.patient",["loc",[null,[273,34],[273,46]]]]],[],[]],"treatmentPatientId",["subexpr","@mut",[["get","item.id",["loc",[null,[274,45],[274,52]]]]],[],[]],"entry",["subexpr","@mut",[["get","entry",["loc",[null,[275,32],[275,37]]]]],[],[]],"offer",["subexpr","@mut",[["get","offer",["loc",[null,[276,32],[276,37]]]]],[],[]]],["loc",[null,[264,24],[277,26]]]]
              ],
              locals: ["entry"],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 250,
                  "column": 12
                },
                "end": {
                  "line": 282,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","pl50 box no-box@phone table table--invoice no-hover");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__head");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell table__cell--center l-3/24");
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","table__title title");
              var el5 = dom.createTextNode("Menge");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell l-5/24");
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","table__title title");
              var el5 = dom.createTextNode("Position");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell table__cell--right l-3/24");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell table__cell--center l-3/24");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell table__cell--right l-2/24");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell l-2/24");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell l-1/24");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__body");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element1, [5]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [7]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element1, [9]),0,0);
              morphs[3] = dom.createMorphAt(dom.childAt(element0, [3]),1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","and",[["get","item.owner",["loc",[null,[255,77],[255,87]]]],["subexpr","show-prices",[],[],["loc",[null,[255,88],[255,101]]]]],[],["loc",[null,[255,72],[255,102]]]]],[],0,null,["loc",[null,[255,66],[255,160]]]],
              ["block","if",[["subexpr","and",[["get","item.owner",["loc",[null,[256,78],[256,88]]]],["subexpr","show-prices",[],[],["loc",[null,[256,89],[256,102]]]]],[],["loc",[null,[256,73],[256,103]]]]],[],1,null,["loc",[null,[256,67],[256,214]]]],
              ["block","if",[["subexpr","and",[["get","item.owner",["loc",[null,[257,77],[257,87]]]],["subexpr","show-prices",[],[],["loc",[null,[257,88],[257,101]]]]],[],["loc",[null,[257,72],[257,102]]]]],[],2,null,["loc",[null,[257,66],[257,157]]]],
              ["block","each",[["get","item.entries",["loc",[null,[262,28],[262,40]]]]],[],3,null,["loc",[null,[262,20],[279,29]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 8
              },
              "end": {
                "line": 286,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row no-stripes");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--toggle l-3/24 l-2/24@tablet l-1/24@desk");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 l-2/24@tablet l-1/24@desk");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-12/24 l-6/24@tablet l-4/24@desk");
            dom.setAttribute(el2,"data-label","Datum");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","block mb5 like-h3");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"data-label","MitarbeiterIn");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-24/24 l-7/24@desk overflow");
            dom.setAttribute(el2,"data-label","Diagnose");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","like-h3");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n           ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell   table__cell--action buttons-4 l-6/24 l-4/24@desk");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-24/24 pl30 l-20/24@desk");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--right l-24/24 l-4/24@desk");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__second-row l-24/24 hidden");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element15 = dom.childAt(fragment, [1]);
            var element16 = dom.childAt(element15, [5]);
            var element17 = dom.childAt(element16, [1]);
            var element18 = dom.childAt(element15, [9]);
            var element19 = dom.childAt(element15, [19]);
            var morphs = new Array(15);
            morphs[0] = dom.createMorphAt(dom.childAt(element15, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element15, [3, 1]),1,1);
            morphs[2] = dom.createMorphAt(element17,0,0);
            morphs[3] = dom.createMorphAt(element17,2,2);
            morphs[4] = dom.createMorphAt(element16,3,3);
            morphs[5] = dom.createMorphAt(element16,5,5);
            morphs[6] = dom.createMorphAt(element15,7,7);
            morphs[7] = dom.createAttrMorph(element18, 'class');
            morphs[8] = dom.createMorphAt(dom.childAt(element18, [1]),1,1);
            morphs[9] = dom.createMorphAt(dom.childAt(element15, [11, 1]),1,1);
            morphs[10] = dom.createMorphAt(dom.childAt(element15, [13]),1,1);
            morphs[11] = dom.createMorphAt(dom.childAt(element15, [15]),1,1);
            morphs[12] = dom.createMorphAt(dom.childAt(element15, [17]),1,1);
            morphs[13] = dom.createAttrMorph(element19, 'data-id');
            morphs[14] = dom.createMorphAt(element19,1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","item.hasEntries",["loc",[null,[58,22],[58,37]]]]],[],0,null,["loc",[null,[58,16],[60,23]]]],
            ["block","if",[["get","item.owner",["loc",[null,[64,20],[64,30]]]]],[],1,2,["loc",[null,[64,14],[78,19]]]],
            ["content","item.dayDate",["loc",[null,[82,50],[82,66]]]],
            ["block","unless",[["get","item.appEditable",["loc",[null,[82,77],[82,93]]]]],[],3,null,["loc",[null,[82,67],[82,122]]]],
            ["block","if",[["get","item.durationMinutes",["loc",[null,[83,24],[83,44]]]]],[],4,null,["loc",[null,[83,18],[83,227]]]],
            ["block","if",[["subexpr","and",[["get","item.owner",["loc",[null,[84,29],[84,39]]]],["subexpr","has-permission",["u_treatment_history"],[],["loc",[null,[84,40],[84,78]]]]],[],["loc",[null,[84,24],[84,79]]]]],[],5,6,["loc",[null,[84,18],[90,25]]]],
            ["block","if",[["get","showPatients",["loc",[null,[92,23],[92,35]]]]],[],7,null,["loc",[null,[92,17],[94,27]]]],
            ["attribute","class",["concat",["table__cell l-12/24 l-12/24@tablet l-",["subexpr","css-bool-evaluator",[["get","showPatients",["loc",[null,[95,87],[95,99]]]],"3","7"],[],["loc",[null,[95,66],[95,109]]]],"/24@desk"]]],
            ["block","if",[["get","item.owner",["loc",[null,[97,20],[97,30]]]]],[],8,9,["loc",[null,[97,14],[107,21]]]],
            ["block","if",[["subexpr","and",[["get","item.virtualTreatment",["loc",[null,[111,21],[111,42]]]],["get","item.info.virtualTreatment",["loc",[null,[111,43],[111,69]]]]],[],["loc",[null,[111,16],[111,70]]]]],[],10,11,["loc",[null,[111,10],[116,17]]]],
            ["block","if",[["get","item.deleted",["loc",[null,[120,18],[120,30]]]]],[],12,13,["loc",[null,[120,12],[200,19]]]],
            ["block","if",[["subexpr","and",[["get","item.virtualTreatment",["loc",[null,[203,25],[203,46]]]],["get","item.info.virtualTreatment",["loc",[null,[203,47],[203,73]]]]],[],["loc",[null,[203,20],[203,74]]]]],[],14,15,["loc",[null,[203,14],[241,21]]]],
            ["block","if",[["subexpr","and",[["subexpr","not",[["get","item.deleted",["loc",[null,[244,28],[244,40]]]]],[],["loc",[null,[244,23],[244,41]]]],["get","item.owner",["loc",[null,[244,42],[244,52]]]]],[],["loc",[null,[244,18],[244,53]]]]],[],16,null,["loc",[null,[244,12],[246,19]]]],
            ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[249,83],[249,90]]]]]]],
            ["block","if",[["get","item.hasEntries",["loc",[null,[250,18],[250,33]]]]],[],17,null,["loc",[null,[250,12],[282,19]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 6
            },
            "end": {
              "line": 287,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","or",[["subexpr","eq",[["get","ctx",["loc",[null,[54,22],[54,25]]]],"treatment"],[],["loc",[null,[54,18],[54,38]]]],["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","invoiceId",["loc",[null,[54,53],[54,62]]]],["get","item.info.invoiceId",["loc",[null,[54,63],[54,82]]]]],[],["loc",[null,[54,49],[54,83]]]]],[],["loc",[null,[54,44],[54,84]]]],["subexpr","not",[["subexpr","eq",[["get","item.id",["loc",[null,[54,94],[54,101]]]],["get","model.id",["loc",[null,[54,102],[54,110]]]]],[],["loc",[null,[54,90],[54,111]]]]],[],["loc",[null,[54,85],[54,112]]]]],[],["loc",[null,[54,39],[54,113]]]]],[],["loc",[null,[54,14],[54,114]]]]],[],0,null,["loc",[null,[54,8],[286,15]]]]
        ],
        locals: ["item"],
        templates: [child0]
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 289,
              "column": 2
            },
            "end": {
              "line": 293,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","pagination-wrapper is-fixed");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","items",["loc",[null,[291,29],[291,34]]]]],[],[]],"numPagesToShow",7],["loc",[null,[291,6],[291,53]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 296,
              "column": 0
            },
            "end": {
              "line": 301,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","treatment-info-form",[],["rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[297,30],[297,35]]]]],[],[]],"treatmentSaved","saveTreatment","editDate",true,"actionReceiver",["subexpr","@mut",[["get","getTreatmentInfoForm",["loc",[null,[297,96],[297,116]]]]],[],[]]],["loc",[null,[297,2],[297,118]]]],
          ["inline","treatment-history-employees",[],["doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[298,40],[298,47]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[298,59],[298,69]]]]],[],[]],"employeesSaved","employeesSaved","actionReceiver",["subexpr","@mut",[["get","treatmentHistoryEmployees",["loc",[null,[298,117],[298,142]]]]],[],[]]],["loc",[null,[298,2],[298,144]]]],
          ["inline","treatment-history-rooms",[],["rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[299,34],[299,39]]]]],[],[]],"roomSaved","roomSaved","actionReceiver",["subexpr","@mut",[["get","treatmentHistoryRooms",["loc",[null,[299,77],[299,98]]]]],[],[]]],["loc",[null,[299,2],[299,100]]]],
          ["inline","past-actions-panel",[],["actionReceiver",["subexpr","@mut",[["get","pastActionsPanel",["loc",[null,[300,38],[300,54]]]]],[],[]]],["loc",[null,[300,2],[300,56]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 312,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table table--small box no-box@tablet no-box@phone table--treatment-history pl0 pr0 no-hover");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table__head");
        var el3 = dom.createComment("\n         ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell l-1/24");
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n         ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell l-1/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell l-4/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Datum");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("MitarbeiterIn");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Diagnose");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n        ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table__body");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element27 = dom.childAt(fragment, [3]);
        var element28 = dom.childAt(element27, [1]);
        var element29 = dom.childAt(element28, [9]);
        var element30 = dom.childAt(element28, [11]);
        var element31 = dom.childAt(element28, [13]);
        var morphs = new Array(13);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
        morphs[2] = dom.createMorphAt(dom.childAt(element28, [1, 0]),0,0);
        morphs[3] = dom.createMorphAt(element28,7,7);
        morphs[4] = dom.createAttrMorph(element29, 'class');
        morphs[5] = dom.createAttrMorph(element30, 'class');
        morphs[6] = dom.createAttrMorph(element31, 'class');
        morphs[7] = dom.createMorphAt(dom.childAt(element31, [0]),0,0);
        morphs[8] = dom.createMorphAt(dom.childAt(element27, [3]),1,1);
        morphs[9] = dom.createMorphAt(element27,5,5);
        morphs[10] = dom.createMorphAt(fragment,5,5,contextualElement);
        morphs[11] = dom.createMorphAt(fragment,7,7,contextualElement);
        morphs[12] = dom.createMorphAt(fragment,9,9,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","if",[["get","showFilter",["loc",[null,[1,6],[1,16]]]]],[],0,null,["loc",[null,[1,0],[32,7]]]],
        ["block","if",[["get","showAdvancedFilter",["loc",[null,[33,6],[33,24]]]]],[],1,null,["loc",[null,[33,0],[40,7]]]],
        ["block","if",[["get","showToggleAllButton",["loc",[null,[44,56],[44,75]]]]],[],2,null,["loc",[null,[44,50],[44,198]]]],
        ["block","if",[["get","showPatients",["loc",[null,[47,19],[47,31]]]]],[],3,null,["loc",[null,[47,13],[47,125]]]],
        ["attribute","class",["concat",["table__cell l-",["subexpr","css-bool-evaluator",[["get","showPatients",["loc",[null,[48,60],[48,72]]]],"3","7"],[],["loc",[null,[48,39],[48,82]]]],"/24"]]],
        ["attribute","class",["concat",["table__cell l-",["subexpr","css-bool-evaluator",[["get","showTotal",["loc",[null,[49,60],[49,69]]]],"6","7"],[],["loc",[null,[49,39],[49,79]]]],"/24"]]],
        ["attribute","class",["concat",["table__cell l-",["subexpr","css-bool-evaluator",[["get","showTotal",["loc",[null,[50,60],[50,69]]]],"5","4"],[],["loc",[null,[50,39],[50,79]]]],"/24 text-right"]]],
        ["block","if",[["get","showTotal",["loc",[null,[50,134],[50,143]]]]],[],4,null,["loc",[null,[50,128],[50,203]]]],
        ["block","each",[["get","items",["loc",[null,[53,14],[53,19]]]]],[],5,null,["loc",[null,[53,6],[287,17]]]],
        ["block","if",[["get","showPager",["loc",[null,[289,8],[289,17]]]]],[],6,null,["loc",[null,[289,2],[293,9]]]],
        ["block","if",[["get","loadOverlay",["loc",[null,[296,6],[296,17]]]]],[],7,null,["loc",[null,[296,0],[301,7]]]],
        ["inline","treatment-history-report-chooser",[],["chooseReport","gotoReport","delete","deleteReport","openWizard","openReportWizard","actionReceiver",["subexpr","@mut",[["get","treatmentHistoryReportChooser",["loc",[null,[307,17],[307,46]]]]],[],[]]],["loc",[null,[303,0],[307,48]]]],
        ["inline","treatment-report-wizzard2",[],["saveFinished","openReportPreview","actionReceiver",["subexpr","@mut",[["get","treatmentReportWizzard2",["loc",[null,[311,17],[311,40]]]]],[],[]]],["loc",[null,[309,0],[311,42]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  }()));

});