define('jason-frontend/components/merge-customer-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    customer: null,
    applyFilter: function applyFilter(searchString) {
      var self = this;

      var term = this.get('filterName');
      var splitted;
      if (term) {
        splitted = term.split(' ');
      }

      var url = "/api/customers";

      if (term && term.length > 1) {
        url = "/api/customers?filterName=" + term;
      }
      $.ajax({
        url: url,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('model', data.customer);
        self.set('content', data.customer);
      });
    },
    actions: {
      load: function load(customer) {
        this.set('customer', customer);
        this.applyFilter();
      },
      triggerSearch: function triggerSearch() {
        this.applyFilter();
      },
      selectCustomer: function selectCustomer(customer, patients) {
        var self = this;

        if (customer === this.customer.id) {
          jason.notifiction.errorLong('Fehler', 'Ziel und Quelle dürfen nicht übereinstimmen');
          return;
        }

        $.ajax({
          url: '/api/customers/' + this.customer.id + '/merge/' + customer,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.sendAction('gotoCustomer', data);
          jason.notifiction.notifiy('Übertragung', 'Tiere und Rechnungsinformationen erfolgreich übertragen');
          $.magnificPopup.close();
        });
      },
      select: function select(id) {
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }

    }
  });

});