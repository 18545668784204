define('jason-frontend/helpers/is-same-order-type', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isSameOrderType = isSameOrderType;

  function isSameOrderType(params) {
    var product = params[0],
        orderType = params[1];

    if (!product) {
      return false;
    }

    try {
      var category = product.get('labCategory');

      if (category && orderType === category) {
        return true;
      }
    } catch (e) {
      var category = product.labCategory;
      if (category && orderType === category) {
        return true;
      }
    }

    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(isSameOrderType);

});