define('jason-frontend/controllers/users/edit', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Bearbeiten",
    breadCrumbPath: "users.edit",
    uploadedAssets: null,
    editMode: true
  });

});