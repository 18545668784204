define('jason-frontend/models/customer-reduction', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    reduction: DS['default'].attr('number'),
    productCategory: DS['default'].belongsTo('productCategory'),
    serviceCategory: DS['default'].belongsTo('serviceCategory'),
    patient: DS['default'].belongsTo('patient'),
    type: DS['default'].belongsTo('customerReductionType')
  });

});