define('jason-frontend/helpers/is-etiga-feed-active', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isEtigaFeedActive = isEtigaFeedActive;

  function isEtigaFeedActive(params) {
    return API['default'].getMandant() == 3 || API['default'].getMandant() == 37 || API['default'].getMandant() == 141 || API['default'].getMandant() == 10006 || API['default'].getMandant() == 673 || API['default'].getMandant() == 674 || API['default'].getMandant() == 675;
  }

  exports['default'] = Ember['default'].Helper.helper(isEtigaFeedActive);

});