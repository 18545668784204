define('jason-frontend/helpers/get-invoice-heading-class', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getInvoiceHeadingClass = getInvoiceHeadingClass;

  function getInvoiceHeadingClass(params) {
    var patient = params[0],
        shownHeads = params[1];
    if (!patient) {
      patient = 'all';
    }
    if (shownHeads.contains(patient)) {
      return 'hidden';
    }
    shownHeads.push(patient);
    return '';
  }

  exports['default'] = Ember['default'].Helper.helper(getInvoiceHeadingClass);

});