define('jason-frontend/helpers/get-product-picture', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.getProductPicture = getProductPicture;

  function getProductPicture(params) {
    var product = params[0];

    if (API['default'].isSupplier()) {
      return 'suppliers/small_' + API['default'].getSupplierId() + '.png';
    }

    try {
      if (product && product.get('supplier') && product.get('supplier').purchaseRelevant === true) {
        return 'suppliers/small_' + product.get('supplier').get('id') + '.png';
      }
    } catch (e) {
      if (product && product.supplier.purchaseRelevant === true) {
        return 'suppliers/small_' + product.supplier.id + '.png';
      }
    }
    return 'products/noproduct.svg';
  }

  exports['default'] = Ember['default'].Helper.helper(getProductPicture);

});