define('jason-frontend/helpers/is-product', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isProduct = isProduct;

  function isProduct(params) {
    var entry = params[0],
        article = entry.get('article');

    if (article && article.get('type') === "service") {
      return false;
    }

    return true;
  }

  exports['default'] = Ember['default'].Helper.helper(isProduct);

});