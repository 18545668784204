define('jason-frontend/helpers/get-payment-name', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getPaymentName = getPaymentName;

  function getPaymentName(params) {
    var paymentType = params[0];

    switch (paymentType) {
      case 'cash':
        return "Bar";
        break;
      case 'cashcard':
        return "EC-Karte";
        break;
      case 'creditcard':
        return "Kreditkarte";
        break;
      case 'voucher':
        return "Gutschein";
        break;
      case 'banktransfer':
        return "Überweisung";
        break;
      default:
        return "Bar";
    }
    return "Bar";
  }

  exports['default'] = Ember['default'].Helper.helper(getPaymentName);

});