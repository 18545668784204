define('jason-frontend/routes/admission', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model, params) {
      var token = params.queryParams.context;
      controller.set('showConfirm', false);
      controller.set('successMessage', false);
      controller.set('etigaActivated', false);
      var self = this;
      if (!token) {
        controller.set('error', true);
        controller.set('token', null);
        controller.set('id', null);
        controller.set('errorMsg', 'Ungültiger QR-Code');
      } else {
        var decoded = atob(token).split('.');
        var validTill = atob(decoded[2]);
        controller.set('validTill', validTill);
        controller.set('token', token);
        try {
          var now = moment();
          var parsedTillTime = moment(validTill, 'YYYY-MM-DD HH:mm-ss');
          var diff = Math.round(moment.duration(parsedTillTime.diff(now)).asSeconds());
          controller.set('diff', diff);
        } catch (e) {}
      }

      controller.set('insurers', this.store.find('insurer'));
      controller.set('patientGenders', this.store.find('patient-gender'));
      controller.set('patientCategories', this.store.find('patient-category'));
      controller.set("genders", this.store.find('gender'));
      controller.set("titles", this.store.find('title'));

      $.ajax({
        url: "/api/public/admission/validate",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'TOKEN': token
        }
      }).then(function (data) {
        self.progress(controller.get('diff'), controller.get('diff'), $('#progressBar'));
        controller.set('mandant', data.mandant);
        controller.set('agbUrl', data.agbUrl);
        controller.set('dsgvoUrl', data.dsgvoUrl);
        self.initValidation();
      }, function (data) {
        controller.set('error', true);
        controller.set('token', null);
        controller.set('id', null);
        controller.set('errorMsg', 'QR-Code abgelaufen oder ungültig<br/><br/>Bitte neu scannen');
      });

      $.ajax({
        url: "/api/public/admission/consents?context=pims_self_register",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'TOKEN': token
        }
      }).then(function (data) {
        controller.set('consents', data);
      }, function (data) {});

      var hostname = window.location.hostname;

      if (hostname === 'portal.myjason.eu') {
        controller.set('country', 'DE');
      }

      var emptyModel = {
        customer: {
          activateEtiga: true
        },
        patients: [{
          uid: Math.random().toString(36).substring(2) + new Date().getTime().toString(36),
          pictureTaken: false
        }]
      };

      controller.set('model', emptyModel);
    },
    initValidation: function initValidation() {
      setTimeout(function () {
        $("#admissionForm").validate({
          errorClass: "state-error",
          validClass: "state-success",
          errorElement: "em",
          rules: {
            'firstname': {
              required: true
            },
            'lastname': {
              required: true
            },
            'patientName': {
              required: true
            },
            'email': {
              required: false,
              email: true
            }
          },
          highlight: function highlight(element, errorClass, validClass) {
            $(element).closest('.field').addClass(errorClass).remove < Class(validClass);
          },
          unhighlight: function unhighlight(element, errorClass, validClass) {
            $(element).closest('.field').removeClass(errorClass).addClass(validClass);
          },
          errorPlacement: function errorPlacement(error, element) {
            if (element.is(":radio") || element.is(":checkbox")) {
              element.closest('.option-group').after(error);
            } else {
              error.insertAfter(element.parent());
            }
          }
        });
      }, 1000);
    },
    progress: function progress(timeleft, timetotal, $element) {
      var self = this;
      var progressBarWidth = timeleft * $element.width() / timetotal;
      $element.find('div').animate({ width: progressBarWidth }, 500).html(Math.floor(timeleft / 60) + ":" + timeleft % 60);
      if (timeleft > 0) {
        setTimeout(function () {
          self.progress(timeleft - 1, timetotal, $element);
        }, 1000);
      } else {
        self.get('controller').set('error', true);
        self.get('controller').set('token', null);
        self.get('controller').set('errorMsg', 'QR-Code ist nicht mehr gültig - bitte neu scannen');
      }
    },
    actions: {
      selectIntroductionReasons: function selectIntroductionReasons(values, reference) {
        Ember['default'].set(reference, 'introductionReasons', values);
      },
      getConsentDocument: function getConsentDocument(consent) {
        var controller = this.get('controller');
        $.ajax({
          url: "/api/public/admission/consents/file?consentId=" + consent.id,
          method: "GET",
          contentType: "application/octet-stream",
          headers: {
            'TOKEN': controller.get('token')
          }
        }).then(function (data) {
          var blob = new Blob([data]);
          var filename = consent.uploadUrl;
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            (function () {
              var a = document.createElement('a');
              document.body.appendChild(a);
              var url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = filename;
              a.click();
              setTimeout(function () {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
              }, 0);
            })();
          }
        });
      },
      selectTreatmentDetailInfoMulti: function selectTreatmentDetailInfoMulti(provider, title, reference) {

        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/lookups?type=" + provider,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('treatmentSelectPanel').send('loadMultiselect', data.treatmentLookup, reference.introductionReasons, title, reference);
        }, function (error) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-select-panel'
          },
          callbacks: {
            close: function close() {
              self.get('controller').get('treatmentSelectPanel').send('cancel');
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      resetRace: function resetRace(patient) {
        Ember['default'].set(patient, 'digitalRace', null);
      },
      changeRace: function changeRace(item, patient) {
        Ember['default'].set(patient, 'digitalRace', item);
      },
      openSelectPicker: function openSelectPicker(patient, title) {

        if (patient.digitalRace) {
          return;
        }
        var self = this;

        if (patient.category.id) {
          $.ajax({
            url: "/api/digitalRaces/" + patient.category.id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.get('controller').get('simpleSelectPanel').send('load', data.digitalRace, title, patient);
          });
        }

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#simple-select-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      retakePicture: function retakePicture(patient) {
        Ember['default'].set(patient, 'pictureTaken', false);
      },
      renderPreview: function renderPreview(patient) {
        var fileInput = Ember['default'].$('#' + patient.uid + 'fileUpload')[0];
        var file = fileInput.files[0];
        Ember['default'].set(patient, 'pictureTaken', true);
        var src = URL.createObjectURL(file);

        var reader = new FileReader();
        reader.onloadend = function () {
          var base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
          Ember['default'].set(patient, 'imageSrc', base64String);
        };
        reader.readAsDataURL(Ember['default'].$('#' + patient.uid + 'fileUpload')[0].files[0]);

        setTimeout(function () {
          $('#' + patient.uid + ' img.img').attr('src', src);
        }, 300);
      },
      uploadFile: function uploadFile(patient) {
        $('#' + patient.uid + 'fileUpload').click();
      },
      close: function close() {
        window.close();
      },
      removePatient: function removePatient(patient) {
        this.get('controller').get('model').patients.removeObject(patient);
      },
      addPatient: function addPatient() {
        this.get('controller').get('model').patients.pushObject({
          uid: Math.random().toString(36).substring(2) + new Date().getTime().toString(36)
        });
      },
      reset: function reset() {
        window.close();
      },
      confirm: function confirm() {

        var controller = this.get('controller');

        var data = {
          code: controller.get('code'),
          customer: {
            firstname: controller.get('model').customer.firstname,
            lastname: controller.get('model').customer.lastname,
            street: controller.get('model').customer.street,
            postalCode: controller.get('model').customer.postalCode,
            town: controller.get('model').customer.town,
            phone: controller.get('model').customer.phone,
            email: controller.get('model').customer.email,
            dsgvo: controller.get('model').customer.dsgvo,
            activateEtiga: controller.get('model').customer.activateEtiga
          },
          patients: []
        };
        var consentData = [];
        var consentsConfirmed = true;
        $.each(controller.get('consents'), function (index, consent) {
          if (consent.mandatoryConsent && !consent.checked) {
            if (consent.renderAsCheck) {
              jason.notifiction.error("Hinweis", "Bitte akzeptieren Sie die " + consent.title);
              consentsConfirmed = false;
            }
          }
          consentData.push({
            consentId: consent.id,
            checked: consent.checked == null ? "true" : consent.checked,
            context: "pims_self_register"
          });
        });

        if (!consentsConfirmed) {
          return;
        }

        $.each(controller.get('model').patients, function (index, patient) {
          var tmpData = {
            name: patient.name,
            castrated: patient.castrated,
            slaughter: patient.slaughter,
            handoverDocument: patient.slaughter,
            stableAddress: patient.stableAddress,
            horsePass: patient.horsePass,
            horsePassNumber: patient.horsePassNumber,
            birthdayFormatted: patient.birthday,
            insured: patient.insured,
            color: patient.color,
            chipId: patient.chipId,
            insuranceNumber: patient.insuranceNumber,
            introductionReasons: patient.introductionReasons,
            imageSrc: patient.imageSrc ? patient.imageSrc.replace('data:image/webp;base64,', '') : null
          };
          if (patient.gender) {
            tmpData.gender = {
              id: patient.gender.id
            };
          }
          if (patient.category) {
            tmpData.category = {
              id: patient.category.id
            };
          }
          if (patient.insurer) {
            tmpData.insurer = {
              id: patient.insurer.id
            };
          }
          if (patient.digitalRace) {
            tmpData.digitalRace = {
              id: patient.digitalRace.id
            };
          }
          data.patients.pushObject(tmpData);
        });

        if (controller.get('model').customer.title) {
          data.customer.title = {
            id: controller.get('model').customer.title.id
          };
        }
        if (controller.get('model').customer.gender) {
          data.customer.gender = {
            id: controller.get('model').customer.gender.id
          };
        }

        data.consents = consentData;

        controller.set('loading', true);
        $.ajax({
          url: "/api/public/admission/record",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'TOKEN': controller.get('token')
          }
        }).then(function (dat) {
          controller.set('etigaActivated', data.customer.activateEtiga);
          controller.set('model', null);
          controller.set('successMessage', true);
          controller.set('loading', false);
        }, function (dat) {
          controller.set('loading', false);
          jason.notifiction.errorLong('Unbekannter Fehler', 'Bitte wenden Sie sich an eine/n MitarbeiterIn');
        });
      }
    }
  });

});