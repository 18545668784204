define('jason-frontend/routes/inventorywarehouses/create', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return new Object();
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
    },
    actions: {
      create: function create() {
        var self = this;

        var controller = this.get('controller'),
            model = controller.get('model'),
            account = new Object();

        account.name = model.name;
        account.showStock = model.showStock;

        $.ajax({
          url: "/api/inventoryWarehouses",
          method: "POST",
          data: JSON.stringify(account),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.transitionTo('practice', { queryParams: { selectTab: "warehouses" } });
          controller.set('number', '');
        }, function () {
          self.transitionTo('practice', { queryParams: { selectTab: "warehouses" } });
          controller.set('number', '');
        });
      }
    }
  });

});