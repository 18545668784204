define('jason-frontend/helpers/is-basic', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isBasicMandant = isBasicMandant;

  function isBasicMandant() {
    return API['default'].getMandantType() === 'basic';
  }

  exports['default'] = Ember['default'].Helper.helper(isBasicMandant);

});