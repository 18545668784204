define('jason-frontend/templates/mandants/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 6
            },
            "end": {
              "line": 11,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/mandants/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[10,8],[10,29]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 144
                },
                "end": {
                  "line": 32,
                  "column": 212
                }
              },
              "moduleName": "jason-frontend/templates/mandants/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","label label-success");
              var el2 = dom.createTextNode("Aktiv");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 212
                },
                "end": {
                  "line": 32,
                  "column": 271
                }
              },
              "moduleName": "jason-frontend/templates/mandants/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","label label-danger");
              var el2 = dom.createTextNode("deaktiviert");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 14
                },
                "end": {
                  "line": 42,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/mandants/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","mailButton cursor");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element1);
              morphs[1] = dom.createMorphAt(element1,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["sendInvoice",["get","mandant.id",["loc",[null,[39,68],[39,78]]]],["get","mandant.latestInvoice.month",["loc",[null,[39,79],[39,106]]]],["get","mandant.latestInvoice.year",["loc",[null,[39,107],[39,133]]]]],[],["loc",[null,[39,45],[39,135]]]],
              ["inline","button-mail",[],["size","32","color","#3B6182","showStroke",true,"content","Rechnung per Email an Mandanten verschicken"],["loc",[null,[40,18],[40,129]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 58,
                  "column": 16
                },
                "end": {
                  "line": 62,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/mandants/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","input input--action");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["inline","button-delete-with-confirm",[],["classNames","ml5","item",["subexpr","@mut",[["get","mandant",["loc",[null,[60,71],[60,78]]]]],[],[]],"confirmed","deactivateMandant","text","Wollen Sie diesen Mandanten wirklich deaktivieren?"],["loc",[null,[60,20],[60,168]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 16
                },
                "end": {
                  "line": 66,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/mandants/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","input input--action");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element0);
              morphs[1] = dom.createMorphAt(element0,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["reactivateMandant",["get","mandant.id",["loc",[null,[63,78],[63,88]]]]],[],["loc",[null,[63,49],[63,90]]]],
              ["inline","button-select",[],["color","#3B6182","classNames","ml5","showStroke",true,"content","Wollen Sie diesen Mandanten wirklich wieder aktivieren?"],["loc",[null,[64,20],[64,152]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 67,
                  "column": 14
                },
                "end": {
                  "line": 67,
                  "column": 118
                }
              },
              "moduleName": "jason-frontend/templates/mandants/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-edit",[],["size","32","color","#3B6182"],["loc",[null,[67,77],[67,118]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child6 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 69,
                  "column": 14
                },
                "end": {
                  "line": 69,
                  "column": 119
                }
              },
              "moduleName": "jason-frontend/templates/mandants/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-auto",[],["color","#008EC6","size","32","classNames","mr5","showStroke",true],["loc",[null,[69,45],[69,119]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 12
              },
              "end": {
                "line": 72,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/mandants/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-1/24@phone  l-1/24@tablet l-1/24@desk");
            dom.setAttribute(el2,"data-label","Id");
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-7/24@phone  l-5/24@tablet l-6/24@desk");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","label label-jason");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-24/24@phone  l-6/24@tablet  l-2/24@desk");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table-status-label");
            var el4 = dom.createTextNode("Status");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24@phone  l-8/24@tablet l-6/24@desk");
            dom.setAttribute(el2,"data-label","Letzte Rechnung");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","downloadButton cursor");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24@phone  l-8/24@tablet l-6/24@desk");
            dom.setAttribute(el2,"data-label","Laufende Rechnung");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","mailButton cursor");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","downloadButton cursor");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--action l-3/24@desk");
            var el3 = dom.createTextNode("\n\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [3]);
            var element4 = dom.childAt(element2, [7]);
            var element5 = dom.childAt(element4, [3]);
            var element6 = dom.childAt(element2, [9]);
            var element7 = dom.childAt(element6, [4]);
            var element8 = dom.childAt(element6, [7]);
            var element9 = dom.childAt(element2, [11]);
            var morphs = new Array(17);
            morphs[0] = dom.createMorphAt(dom.childAt(element2, [1, 0]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element3, [0]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element3, [2]),0,0);
            morphs[3] = dom.createMorphAt(dom.childAt(element3, [4]),0,0);
            morphs[4] = dom.createMorphAt(dom.childAt(element2, [5]),1,1);
            morphs[5] = dom.createMorphAt(element4,1,1);
            morphs[6] = dom.createElementMorph(element5);
            morphs[7] = dom.createMorphAt(element5,1,1);
            morphs[8] = dom.createMorphAt(element4,5,5);
            morphs[9] = dom.createMorphAt(element6,1,1);
            morphs[10] = dom.createElementMorph(element7);
            morphs[11] = dom.createMorphAt(element7,1,1);
            morphs[12] = dom.createElementMorph(element8);
            morphs[13] = dom.createMorphAt(element8,1,1);
            morphs[14] = dom.createMorphAt(element9,1,1);
            morphs[15] = dom.createMorphAt(element9,3,3);
            morphs[16] = dom.createMorphAt(element9,5,5);
            return morphs;
          },
          statements: [
            ["content","mandant.id",["loc",[null,[30,101],[30,115]]]],
            ["content","mandant.name",["loc",[null,[31,106],[31,122]]]],
            ["content","mandant.operators",["loc",[null,[31,140],[31,161]]]],
            ["content","mandant.mandantType",["loc",[null,[31,205],[31,228]]]],
            ["block","if",[["get","mandant.active",["loc",[null,[32,150],[32,164]]]]],[],0,1,["loc",[null,[32,144],[32,278]]]],
            ["inline","invoice-submission-status",[],["invoice",["subexpr","@mut",[["get","mandant.latestInvoice",["loc",[null,[34,50],[34,71]]]]],[],[]]],["loc",[null,[34,14],[34,73]]]],
            ["element","action",["downloadInvoice",["get","mandant.id",["loc",[null,[35,74],[35,84]]]],["get","mandant.latestInvoice.month",["loc",[null,[35,85],[35,112]]]],["get","mandant.latestInvoice.year",["loc",[null,[35,113],[35,139]]]]],[],["loc",[null,[35,47],[35,141]]]],
            ["inline","button-download-list",[],["size","32","color","#3B6182","content","Rechnung herunterladen"],["loc",[null,[36,16],[36,99]]]],
            ["block","if",[["subexpr","not",[["subexpr","or",[["get","showLoading",["loc",[null,[38,29],[38,40]]]],["get","mandant.latestInvoice.submitted",["loc",[null,[38,41],[38,72]]]]],[],["loc",[null,[38,25],[38,73]]]]],[],["loc",[null,[38,20],[38,74]]]]],[],2,null,["loc",[null,[38,14],[42,21]]]],
            ["inline","invoice-submission-status",[],["invoice",["subexpr","@mut",[["get","mandant.currentInvoice",["loc",[null,[45,50],[45,72]]]]],[],[]]],["loc",[null,[45,14],[45,74]]]],
            ["element","action",["downloadInvoice",["get","mandant.id",["loc",[null,[47,72],[47,82]]]],["get","mandant.currentInvoice.month",["loc",[null,[47,83],[47,111]]]],["get","mandant.currentInvoice.year",["loc",[null,[47,112],[47,139]]]]],[],["loc",[null,[47,45],[47,141]]]],
            ["inline","button-download-list",[],["size","32","color","#3B6182","content","Rechnung herunterladen"],["loc",[null,[48,18],[48,101]]]],
            ["element","action",["openInvoiceTextPanel",["get","mandant.id",["loc",[null,[51,81],[51,91]]]],["get","mandant.currentInvoice.month",["loc",[null,[51,92],[51,120]]]],["get","mandant.currentInvoice.year",["loc",[null,[51,121],[51,148]]]]],[],["loc",[null,[51,49],[51,150]]]],
            ["inline","button-edit",[],["size","32","color","#3B6182","content","Rechnungstext bearbeiten"],["loc",[null,[52,18],[52,94]]]],
            ["block","if",[["get","mandant.active",["loc",[null,[58,22],[58,36]]]]],[],3,4,["loc",[null,[58,16],[66,23]]]],
            ["block","link-to",["mandants.editadmin",["get","mandant.id",["loc",[null,[67,46],[67,56]]]]],["title","Bearbeiten"],5,null,["loc",[null,[67,14],[67,130]]]],
            ["block","if",[["get","mandant.autosendInvoice",["loc",[null,[69,20],[69,43]]]]],[],6,null,["loc",[null,[69,14],[69,126]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 10
            },
            "end": {
              "line": 73,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/mandants/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","or",[["get","mandant.active",["loc",[null,[28,22],[28,36]]]],["get","showInactive",["loc",[null,[28,37],[28,49]]]]],[],["loc",[null,[28,18],[28,50]]]]],[],0,null,["loc",[null,[28,12],[72,19]]]]
        ],
        locals: ["mandant"],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 83,
              "column": 2
            },
            "end": {
              "line": 85,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/mandants/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","button-user",[],["content","Neuen Mandant anlegen","color","#FFFFFF","size","56"],["loc",[null,[84,4],[84,77]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 88,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/mandants/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","site-content card transaction-filter");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","box");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","u-mb0");
        var el5 = dom.createTextNode("Mandanten");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"class","u-mb0");
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-24/24 mt20 toggle-button-with-icon u-text--right");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("deaktivierte einblenden");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table table--small box no-box@phone");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__head");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__cell l-1/24");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","table__title title");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__cell l-6/24");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","table__title title");
        var el8 = dom.createTextNode("Praxis");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__cell l-2/24");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","table__title title");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__cell l-6/24");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","table__title title");
        var el8 = dom.createTextNode("Letzte Rechnung");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__cell l-6/24");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","table__title title");
        var el8 = dom.createTextNode("Aktuelle Rechnung");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__cell l-3/24");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__body");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons ");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0, 1, 3]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element10,1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element10, [3]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element10, [5, 3]),1,1);
        morphs[3] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[4] = dom.createMorphAt(dom.childAt(fragment, [4]),1,1);
        return morphs;
      },
      statements: [
        ["block","if",[["get","showLoading",["loc",[null,[9,12],[9,23]]]]],[],0,null,["loc",[null,[9,6],[11,13]]]],
        ["inline","button-toggle-inactive",[],["size",40,"active",["subexpr","@mut",[["get","showInactive",["loc",[null,[14,46],[14,58]]]]],[],[]],"toggleAll","toggleInactive"],["loc",[null,[14,6],[14,87]]]],
        ["block","each",[["get","model",["loc",[null,[27,18],[27,23]]]]],[],1,null,["loc",[null,[27,10],[73,19]]]],
        ["inline","mandant-invoice-text-panel",[],["taxes",["subexpr","@mut",[["get","taxes",["loc",[null,[80,35],[80,40]]]]],[],[]],"mandant",["subexpr","@mut",[["get","mandant",["loc",[null,[80,49],[80,56]]]]],[],[]],"confirm","editText","actionReceiver",["subexpr","@mut",[["get","invoiceTextPanel",["loc",[null,[80,91],[80,107]]]]],[],[]]],["loc",[null,[80,0],[80,109]]]],
        ["block","link-to",["mandants.create"],["class","icon-button icon-button--success"],2,null,["loc",[null,[83,2],[85,14]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});