define('jason-frontend/components/laboklin-select-barcode-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    openPrint: function openPrint(id, format) {

      var viewUrl = '/api/treatmentPatients/downloadBarcode/' + id + '/' + format + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken();

      var myWindow = window.open(viewUrl, 'Drucken', 'width=600,height=600');

      var is_chrome = Boolean(window.chrome);
      if (is_chrome) {
        myWindow.onload = function () {

          myWindow.moveTo(0, 0);
          myWindow.resizeTo(640, 480);
          myWindow.print();
        };
      } else {
        myWindow.print();
        myWindow.close();
      }
    },
    actions: {
      downloadHor: function downloadHor() {
        this.openPrint(this.get('id'), 'hor');
      },
      downloadVer: function downloadVer() {
        this.openPrint(this.get('id'), 'hor');
      },
      load: function load(id) {
        this.set('id', id);
      }
    }
  });

});