define('jason-frontend/controllers/inventory/delivery-note', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Lieferscheine",
    breadCrumbPath: "inventory.delivery-note",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    placeholderSearch: "Lieferschein-Nr suchen, Bestell-Nr suchen",
    filterOrderStatus: "exported",
    totalPagesBinding: "content.totalPages",
    pickDate: moment().startOf('year').format("DD. MM. YYYY") + " - " + moment().endOf('year').format("DD. MM. YYYY"),
    page: 1,
    selectedType: 'deliverynotes',
    showLoading: false,
    perPage: 20,
    filterStatus: 'created,used',
    filterSupplier: null,
    showFilters: true,
    myStickyOptionsTableHead: {
      topSpacing: 160,
      zIndex: 9
    },
    myStickyOptionsTableHeadOrders: {
      topSpacing: 160,
      zIndex: 9
    },
    actions: {},
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('pickDate'),
    watchSupplier: (function () {
      this.send('applyFilter');
    }).observes('filterSupplier')
  });

});