define('jason-frontend/controllers/practicemgmt/emergency-service', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Notdienst",
    breadCrumbPath: "practicemgmt.emergency-service",
    treatmentSortDir: 'asc',
    pickDate: moment().format("DD. MM. YYYY") + " - " + moment().format("DD. MM. YYYY"),
    page: 1,
    showLoading: false,
    perPage: 20,
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    actions: {
      openEdit: function openEdit(item) {
        this.get('editEmergencyService').send('load', item);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-emergency-service-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openNew: function openNew(item) {
        var newOne = this.store.createRecord('emergencyService', { comment: '', startString: moment().format("DD. MM. YYYY"), endString: moment().add(1, 'day').format("DD. MM. YYYY"), startStringTime: '12:00', endStringTime: '12:00' });
        this.get('editEmergencyService').send('load', newOne);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-emergency-service-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    },
    watchDateChange: (function () {
      this.send('applyFilter');
    }).observes('pickDate')
  });

});