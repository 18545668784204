define('jason-frontend/controllers/transactions/cashbook', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Kassenbuch",
    breadCrumbPath: "transactions.cashbook",
    filterIn: "day",
    filterOut: "single",
    selectedRegister: null,
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),

    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    perPage: 20,
    to: null,
    from: null,
    myStickyOptionsTableHead: {
      topSpacing: 80,
      zIndex: 9
    },

    actions: {
      setFilterType: function setFilterType(type, setting) {

        if (type === 'in') {
          this.set('filterIn', setting);
        }
        if (type === 'out') {
          this.set('filterOut', setting);
        }
        this.send('applyFilter');
      }
    },
    watchCashRegister: (function () {
      this.send('applyFilter');
    }).observes('selectedRegister'),
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('pickDate')
  });

});