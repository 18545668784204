define('jason-frontend/routes/purchases/confirm', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('purchaseOrder', params.id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('renderPdf', false);

      var deferred = $.ajax({
        url: "/api/purchaseOrders/" + model.id + "/preview",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function () {}, function (data) {
        controller.set('viewUrl', '/api/purchaseOrders/show/' + model.id);
        controller.set('renderPdf', true);
      });
    },
    actions: {
      toggleSidebar: function toggleSidebar() {
        var controller = this.get('controller');
        if (controller.get('sidebarActive') === true) {
          controller.set('sidebarActive', false);
        } else {
          controller.set('sidebarActive', true);
          setTimeout(function () {
            $('aside.side-bar .scrollable').css('max-height', $(window).height() - 100);
            $('aside.side-bar').css('min-height', $(window).height() - 100);
          }, 100);

          if ($(window).width() <= 767) {
            $("html, body").animate({ scrollTop: 0 }, 200);
          }
        }
      },
      download: function download(orderId) {
        var deferred = $.ajax({
          url: "/api/purchaseOrders/" + orderId + "/preview",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/purchaseOrders/show/' + orderId);
        });
      }
    },
    show: function show() {
      var self = this;

      setTimeout(function () {
        self.get('controller').get('pdfPreview').send('show');
      }, 200);
    }
  });

});