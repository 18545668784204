define('jason-frontend/controllers/cash/reciept', ['exports', 'ember', 'moment', 'jason-frontend/api'], function (exports, Ember, moment, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Eingangsrechnung",
    breadCrumbPath: "cash.reciept",
    selectedPaymentType: API['default'].getMandant() != 10001 && API['default'].getMandant() != 10003 ? "banktransfer" : "cash",
    showInvoicePanel: true,
    showBankTransferPanel: false,
    transaction: null,
    selectedCashRegister: null,
    bankingAccount: null,
    isCashDeskaware: false,
    tax20: 0,
    tax19: 0,
    tax13: 0,
    tax10: 0,
    tax0: 0,
    tax7: 0,
    tax5: 0,
    //total: this.get('tax20'),
    recieptDate: moment['default']().format("DD. MM. YYYY"),
    documentDate: moment['default']().format("DD. MM. YYYY"),
    notice: null,
    paymentTypeList: [{ value: "Bar", id: "cash" }, { value: "EC-Karte", id: "cashcard" }, { value: "Kreditkarte", id: "creditcard" }, { value: "Überweisung", id: "banktransfer" }, { value: "Gutschein", id: "voucher" }],
    styleStepOne: "",
    styleStepTwo: "hidden",
    styleStepThree: "hidden",
    styleStepFour: "hidden",
    styleStepFive: "hidden",
    actions: {
      setPaymentType: function setPaymentType(paymentType) {
        this.set('selectedPaymentType', paymentType);
        this.set('showBankTransferPanel', paymentType === 'banktransfer');
      }
    }
  });

});