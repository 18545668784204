define('jason-frontend/components/discount-spinner', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ['input-group', 'discount-spinner2'],
    actions: {
      setRelativeDiscountDiscountSpinner: function setRelativeDiscountDiscountSpinner(entry, id, discount, absolute, name) {
        this.sendAction('setRelativeDiscount', entry, id, discount, absolute, name);
      },
      setAbsoluteDiscountDiscountSpinner: function setAbsoluteDiscountDiscountSpinner(entry, id, discount, absolute, name) {
        this.sendAction('setAbsoluteDiscount', entry, id, discount, absolute, name);
      },
      changeDiscountDiscountSpinner: function changeDiscountDiscountSpinner(entry, id, discount, absolute, name) {
        this.sendAction('changeDiscount', entry, id, discount, absolute, name);
      }
    }
  });

});