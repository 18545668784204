define('jason-frontend/templates/components/treatment-fever-chart', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/treatment-fever-chart.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","highchartsFever");
        dom.setAttribute(el1,"style","height: 200px; margin: 0 auto");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(1);
        morphs[0] = dom.createAttrMorph(element0, 'data-treatment-id');
        return morphs;
      },
      statements: [
        ["attribute","data-treatment-id",["concat",[["get","treatmentId",["loc",[null,[1,47],[1,58]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});