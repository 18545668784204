define('jason-frontend/models/patient-vaccination', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    articleName: DS['default'].attr(''),
    articleId: DS['default'].attr(''),
    articleType: DS['default'].attr(''),
    date: DS['default'].attr('')
  });

});