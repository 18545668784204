define('jason-frontend/helpers/is-ehapo-active', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isEhapoActive = isEhapoActive;

  function isEhapoActive(params) {
    return API['default'].isInventoryActive();
  }

  exports['default'] = Ember['default'].Helper.helper(isEhapoActive);

});