define('jason-frontend/components/banktransfer-payment-chooser', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      registerPayments: function registerPayments() {

        var given = $('table#transaction-input td.given input').val();
        given = given.replace(",", ".");

        this.sendAction('continue', given);
      },
      toSelectPositions: function toSelectPositions() {
        this.sendAction('toSelectPositions');
      }
    },
    reset: function reset() {
      var row1 = $('table#transaction-input tr.rows#row1');
      $('input.given-input', row1).val('');
      $('td.open').data('open', $('#init-open').html());
    },
    becomeFocused: (function () {

      function roundToTwo(value) {
        var format = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

        var value = Math.round(value * 100) / 100;
        if (format) {
          return ('' + value).replace('.', ',');
        }
        return value;
      }

      this.reset();

      $(document).off("blur", "tr.rows input");
      $(document).on("blur", "tr.rows input", function () {
        var currentRow = $(this).closest('tr'),
            open = parseFloat(currentRow.find('td.open').attr('data-open')),
            given = $(this).val().replace(",", ".");

        if (!$(this).val().length) {
          return;
        }

        if (given > open) {
          currentRow.find('td.given input').val(roundToTwo(open, true));
        }
      });
    }).on('didInsertElement').observes('invoice')
  });

});