define('jason-frontend/routes/transactions/financial', ['exports', 'ember', 'moment'], function (exports, Ember, moment) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController() {
      this.applyFilter();
    },
    actions: {
      applyFilter: function applyFilter() {
        this.applyFilter();
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-'),
          filterType = controller.get('filterType'),
          filterSumIn = controller.get('filterSumIn'),
          filterSumOut = controller.get('filterSumOut'),
          filterDayIn = controller.get('filterDayIn'),
          filterDayOut = controller.get('filterDayOut'),
          filterPaymentAll = controller.get('filterPaymentAll'),
          filterPaymentCash = controller.get('filterPaymentCash'),
          filterPaymentCreditcard = controller.get('filterPaymentCreditcard'),
          filterPaymentCashcard = controller.get('filterPaymentCashcard'),
          filterPaymentVoucher = controller.get('filterPaymentVoucher'),
          filterPaymentBanktransfer = controller.get('filterPaymentBanktransfer');

      var from = moment['default'](dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment['default'](dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      this.store.find('transaction', {
        filterFrom: from,
        filterTo: to,
        filterType: filterType,
        filterSumIn: filterSumIn,
        filterSumOut: filterSumOut,
        filterDayIn: filterDayIn,
        filterDayOut: filterDayOut,
        filterPaymentAll: filterPaymentAll,
        filterPaymentCash: filterPaymentCash,
        filterPaymentCreditcard: filterPaymentCreditcard,
        filterPaymentCashcard: filterPaymentCashcard,
        filterPaymentVoucher: filterPaymentVoucher,
        filterPaymentBanktransfer: filterPaymentBanktransfer
      }).then(function (transactions) {
        var meta = transactions.meta;
        controller.set('sum', meta.sum);
        controller.set('model', transactions);
      });
    }
  });

});