define('jason-frontend/components/treatment-patient-list', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',
    filterState: null,
    showPager: false,
    showSums: false,
    inProgress: false,
    actions: {
      openTime: function openTime(treatment) {
        this.sendAction('openTime', treatment);
      },
      editTreatmentInfo: function editTreatmentInfo(info) {
        this.sendAction('editTreatmentInfo', info);
      },
      openStationPanel: function openStationPanel(id) {

        this.get('setStationPanel').send('load', id);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-select-station-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      movePatientToStation: function movePatientToStation(id, data) {
        this.sendAction('movePatientToStation', id, data);
      },
      movePatientToWaitingRoom: function movePatientToWaitingRoom(id) {
        this.sendAction('movePatientToWaitingRoom', id);
      },
      movePatientToTreatmentRoom: function movePatientToTreatmentRoom(id) {
        this.sendAction('movePatientToTreatmentRoom', id);
      },
      abort: function abort(patientId) {
        this.sendAction('abort', patientId);
      },
      exportInvoice: function exportInvoice(patientId, invoiceId) {
        this.set('inProgress', true);
        this.sendAction('exportInvoice', patientId, invoiceId);
      },
      clickPatient: function clickPatient(id) {
        this.sendAction('clickPatient', id);
      },
      onInsert: (function () {
        this.set('inProgress', false);
      }).on('didInsertElement')
    }
  });

});