define('jason-frontend/components/image-upload-and-select-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    currentFile: null,
    uploading: false,
    withDocsGlobal: true,
    active: false,
    documentType: 'Bild',
    withDocuments: true,
    loaded: false,
    actions: {
      upload: function upload() {},
      uploadInProgress: function uploadInProgress(e) {
        this.set('uploading', true);
      },
      select: function select(media) {
        this.sendAction('selected', media.get('internalId'));
        $.magnificPopup.close();
      },
      load: function load(externalContext, type, patientId, customerId, customerEmail) {
        this.set('showUploadButton', false);
        this.set('currentFile', null);
        // this.set('ctx', ctx);
        // this.set('ctxId', sctxId);
        this.set('type', type);
        this.set('withDocuments', type === 'templateinstance' || type === 'treatmentreport');

        this.set('userId', API['default'].getUserId());
        // this.set('folderId', folderId);
        this.set('active', true);

        var self = this;

        if (this.get('withDocuments')) {
          this.get('documentsPanel').send('setFilterType', 'image');
          this.get('documentsPanel').send('loadForPatient', externalContext, patientId, customerId);
        }

        if (!this.get('loaded')) {
          this.set('loaded', true);
          Dropzone.autoDiscover = false;
          var myDropzone = new Dropzone("#uploadForm", {
            maxFiles: 1,
            createImageThumbnails: false,
            acceptedFiles: "image/*",
            url: '/api/mediaUploads?name=upload&category=editor&mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken()
          });

          myDropzone.on("addedfile", function (file) {
            self.set('uploading', true);
          });
          myDropzone.on("complete", function (file) {
            var status = file.xhr.status;
            if (status === 200) {
              var id = file.xhr.responseText;
              self.sendAction('insertImage', id, self.get('type'));
              $.magnificPopup.close();
            } else {
              jason.notifiction.error('Beim Hochladen ist ein Fehler aufgetreten', '');
            }
            self.set('uploading', false);
          });
        } else {
          Dropzone.forElement('#uploadForm').removeAllFiles(true);
        }
      },
      uploadDone: function uploadDone(data) {
        this.set('uploading', false);
        this.set('active', false);
        this.sendAction('uploadDone');

        $.magnificPopup.close();
      }
    }
  });

});