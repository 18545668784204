define('jason-frontend/models/etiga-feed', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    title: DS['default'].attr('string'),
    text: DS['default'].attr(''),
    titleSecondary: DS['default'].attr(''),
    expandedText: DS['default'].attr(''),
    externalLink: DS['default'].attr(''),
    imageUrl: DS['default'].attr(''),
    type: DS['default'].attr('string'),
    created: DS['default'].attr('date'),
    validFrom: DS['default'].attr('date'),
    validTo: DS['default'].attr('date'),
    validPeriod: DS['default'].attr(''),
    topic: DS['default'].attr('string'),
    owner: DS['default'].attr(''),
    patientCategory: DS['default'].attr(),
    patientCategories: DS['default'].attr(),
    digitalRace: DS['default'].attr(),
    weightFrom: DS['default'].attr('number'),
    weightTo: DS['default'].attr('number'),
    ageFrom: DS['default'].attr('number'),
    ageTo: DS['default'].attr('number'),
    diagnosis: DS['default'].attr(''),
    active: DS['default'].attr('boolean'),
    approved: DS['default'].attr('boolean'),
    products: DS['default'].attr(''),
    channelApp: DS['default'].attr('boolean'),
    channelWeb: DS['default'].attr('boolean'),
    channelPortal: DS['default'].attr('boolean')
  });

});