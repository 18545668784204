define('jason-frontend/components/etiga-change-status', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: 'inline-block',
    actions: {
      open: function open() {
        this.sendAction('open');
      }
    }
  });

});