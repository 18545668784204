define('jason-frontend/components/customer-deposit-value', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    deposit: 0,
    balance: 0,
    customer: null,
    tagName: 'span',
    formattedValue: (function () {
      if (this.get('deposit') > 0) {
        return accounting.formatMoney(this.get('deposit'), "€ ", 2, ".", ",");
      }
      return accounting.formatMoney(this.get('balance'), "€ ", 2, ".", ",");
    }).property('balance'),
    style: (function () {
      if (this.get('customer') && this.get('customer').get('sued')) {
        return "dark";
      }
      if (this.get('status') === 2) {
        return "danger";
      }
      if (this.get('status') === 1) {
        return "warning";
      }
      return "success";
    }).property('status'),
    text: (function () {

      if (this.get('customer') && this.get('customer').get('sued')) {
        return "Gesperrt";
      }

      if (this.get('deposit') > 0 && this.get('balance') == 0) {
        return "Guthaben";
      }
      return "Saldo";
    }).property('deposit')
  });

});