define('jason-frontend/controllers/patients/etiga', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Etiga",
    breadCrumbPath: "patients.etiga",
    queryParams: ['referer'],
    actions: {
      chooseEtigaPanel: function chooseEtigaPanel(id) {

        var self = this;

        $.ajax({
          url: "/api/patients/" + id + "/activateEtiga",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          if (data.connected && data.patientId > 0) {
            self.transitionTo('patients.edit', data.patientId);
          } else if (!data.connected && data.patientId == 0 && data.matchCount == 0) {

            self.get('chooseEtigaCustomerPanel').send('load', null, data.master.id, data.customer);

            $.magnificPopup.open({
              removalDelay: 500,
              closeOnBgClick: false,
              items: {
                src: '#modal-choose-etiga-customer-panel'
              },
              callbacks: {
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: true
            });
          } else {
            self.get('chooseEtigaPanel').send('load', data.matches, data.master.id);

            $.magnificPopup.open({
              removalDelay: 500,
              closeOnBgClick: false,
              items: {
                src: '#modal-choose-etiga-panel'
              },
              callbacks: {
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: true
            });
          }
        }, function (error) {});
      }
    }
  });

});