define('jason-frontend/controllers/cash/removal', ['exports', 'ember', 'moment'], function (exports, Ember, moment) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Auszahlung",
    breadCrumbPath: "cash.removal",
    selectedPaymentType: "cash",
    showInvoicePanel: true,
    showBankTransferPanel: false,
    selectedType: "removal",
    selectedInvoiceId: null,
    selectedInvoice: null,
    transaction: null,
    bankingAccount: null,
    isCashDeskaware: false,
    recieptDate: moment['default']().format("DD. MM. YYYY"),
    documentDate: moment['default']().format("DD. MM. YYYY"),
    notice: null,
    paymentTypeList: [{ value: "Bar", id: "cash" }, { value: "EC-Karte", id: "cashcard" }, { value: "Kreditkarte", id: "creditcard" }, { value: "Überweisung", id: "banktransfer" }, { value: "Gutschein", id: "voucher" }],
    bankingAccountList: [{ value: "Raika 0815", id: "1" }, { value: "Hypo 0817", id: "2" }],
    styleStepPayment: "",
    styleStepConfirmation: "hidden",
    actions: {
      toggleReference: function toggleReference(show) {
        this.set('showRefOutgoing', show);
      },
      setPaymentType: function setPaymentType(paymentType) {
        this.set('selectedPaymentType', paymentType);
        this.set('showBankTransferPanel', paymentType === 'banktransfer');
      },
      setType: function setType(type) {
        this.set('selectedType', type);
        //this.set('showInvoicePanel', type === 'in');

        if (type === 'banktransfer') {
          this.set('selectedPaymentType', 'banktransfer');
        }
      }
    },
    watchType: (function () {
      var invoice = this.store.fetch('invoice', this.selectedInvoiceId);
      this.set("selectedInvoice", invoice);
    }).observes('selectedInvoiceId')
  });

});