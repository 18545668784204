define('jason-frontend/templates/components/button-copy-permissions', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-copy-permissions.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill-rule","evenodd");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("circle");
        dom.setAttribute(el3,"fill","none");
        dom.setAttribute(el3,"stroke-width","2");
        dom.setAttribute(el3,"class","circle");
        dom.setAttribute(el3,"cx","15");
        dom.setAttribute(el3,"cy","15");
        dom.setAttribute(el3,"r","14");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","translate(-1,0)");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"class","icon");
        dom.setAttribute(el4,"d","M1.12401361,14.9642143 C0.841241497,14.9642143 0.558027211,14.8596429 0.342465986,14.6502857 C-0.0893197279,14.232 -0.0893197279,13.5535714 0.342465986,13.1352857 L6.15620748,7.5 L0.342465986,1.86492857 C-0.0893197279,1.44642857 -0.0893197279,0.768 0.342465986,0.349714286 C0.773809524,-0.0685714286 1.47421769,-0.0685714286 1.90556122,0.349714286 L9.28284014,7.5 L1.90556122,14.6502857 C1.69,14.8596429 1.40678571,14.9642143 1.12401361,14.9642143 L1.12401361,14.9642143 Z");
        dom.setAttribute(el4,"transform","rotate(90 6 17.5)");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element0, 'class');
        morphs[3] = dom.createElementMorph(element0);
        morphs[4] = dom.createAttrMorph(element2, 'stroke');
        morphs[5] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","class",["concat",["cursor state-button ",["subexpr","css-bool-evaluator",[["get","active",["loc",[null,[1,123],[1,129]]]],"active",""],[],["loc",[null,[1,102],[1,143]]]]]]],
        ["element","action",["toggle",["get","reference",["loc",[null,[1,184],[1,193]]]],["get","type",["loc",[null,[1,194],[1,198]]]]],[],["loc",[null,[1,165],[1,200]]]],
        ["attribute","stroke",["concat",[["get","color",["loc",[null,[3,34],[3,39]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[5,20],[5,25]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});