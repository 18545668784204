define('jason-frontend/templates/components/button-bankadvice', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-bankadvice.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        dom.setAttribute(el1,"class","cursor button-applied");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("circle");
        dom.setAttribute(el2,"class","circle");
        dom.setAttribute(el2,"cx","16");
        dom.setAttribute(el2,"cy","16");
        dom.setAttribute(el2,"r","13");
        dom.setAttribute(el2,"stroke","#3B6182");
        dom.setAttribute(el2,"fill","#fff");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","#3B6182");
        dom.setAttribute(el2,"fill-rule","evenodd");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M15.9150391,15.9013672 L19.3125,15.9013672 L19.3125,20.7617188 C18.7838515,20.9303394 18.2460965,21.0579423 17.6992188,21.1445312 C17.152341,21.2311202 16.5188838,21.2744141 15.7988281,21.2744141 C14.2857997,21.2744141 13.1077516,20.824386 12.2646484,19.9243164 C11.4215453,19.0242468 11,17.7630289 11,16.140625 C11,15.1015573 11.208494,14.1912474 11.6254883,13.409668 C12.0424826,12.6280885 12.6428997,12.0310893 13.4267578,11.6186523 C14.2106159,11.2062154 15.128901,11 16.1816406,11 C17.2480522,11 18.2415319,11.1959616 19.1621094,11.5878906 L18.7109375,12.6132812 C17.8085892,12.2304668 16.9404339,12.0390625 16.1064453,12.0390625 C14.8896424,12.0390625 13.9394565,12.4013636 13.2558594,13.1259766 C12.5722622,13.8505896 12.2304688,14.8554623 12.2304688,16.140625 C12.2304688,17.4895901 12.5597298,18.5126918 13.2182617,19.2099609 C13.8767937,19.90723 14.8440691,20.2558594 16.1201172,20.2558594 C16.812829,20.2558594 17.48958,20.1761076 18.1503906,20.0166016 L18.1503906,16.9404297 L15.9150391,16.9404297 L15.9150391,15.9013672 Z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});