define('jason-frontend/helpers/is-cashback-active', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isCashbackActive = isCashbackActive;

  function isCashbackActive(params) {
    return API['default'].isCashbackActive();
  }

  exports['default'] = Ember['default'].Helper.helper(isCashbackActive);

});