define('jason-frontend/controllers/mandants/create', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Erstellen",
    breadCrumbPath: "mandants.create",
    selectTab: 'masterdata',
    showSubmit: true,
    actions: {
      save: function save() {

        var mandant = {},
            self = this;

        mandant.name = $('input[name=mandant-name]').val();
        mandant.operators = $('input[name=mandant-operators]').val();
        mandant.firstname = $('input[name=mandant-firstname]').val();
        mandant.lastname = $('input[name=mandant-lastname]').val();
        mandant.email = $('input[name=mandant-email]').val();
        mandant.street = $('input[name=mandant-street]').val();
        mandant.postalCode = $('input[name=mandant-postalcode]').val();
        mandant.town = $('input[name=mandant-town]').val();
        mandant.homepage = $('input[name=mandant-homepage]').val();
        mandant.phone = $('input[name=mandant-phone]').val();

        var title = $('select[name=mandant-title]').val();
        var gender = $('select[name=mandant-gender]').val();

        if (gender) {
          mandant.gender = {
            id: gender
          };
        }
        if (title) {
          mandant.title = {
            id: title
          };
        }

        this.set('showSubmit', false);

        Ember['default'].$.ajax({
          url: "/api/mandants",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(mandant),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Mandant erfolgreich angelegt', ' - Die Zugangsdaten werden per Email an support@myjason.at verschickt');
          self.transitionTo('mandants');
          self.set('showSubmit', true);
        }, function (error) {
          var errors = $.parseJSON(error.responseText).errors;
          jason.notifiction.error('Fehler', errors[0].body);
          self.set('showSubmit', true);
        });
      }
    }
  });

});