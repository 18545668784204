define('jason-frontend/templates/components/button-hide-app-with-confirm', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 64,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-hide-app-with-confirm.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"class","btn-delete");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el2 = dom.createElement("svg");
        dom.setAttribute(el2,"id","uuid-a2ffa835-c978-41f1-83dc-1ad2da3bbde6");
        dom.setAttribute(el2,"viewBox","0 0 230 220");
        dom.setAttribute(el2,"version","1.1");
        dom.setAttribute(el2,"sodipodi:docname","Pfote_eltiga_Blau.svg");
        dom.setAttribute(el2,"inkscape:version","1.3 (0e150ed6c4, 2023-07-21)");
        dom.setAttribute(el2,"inkscape:export-filename","Pfote_eltiga_Blau");
        dom.setAttribute(el2,"inkscape:export-xdpi","414.85709");
        dom.setAttribute(el2,"inkscape:export-ydpi","414.85709");
        dom.setAttributeNS(el2,"http://www.w3.org/XML/1998/namespace","xml:space","preserve");
        dom.setAttribute(el2,"xmlns:inkscape","http://www.inkscape.org/namespaces/inkscape");
        dom.setAttribute(el2,"xmlns:sodipodi","http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd");
        dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el2,"xmlns:svg","http://www.w3.org/2000/svg");
        var el3 = dom.createElement("sodipodi:namedview");
        dom.setAttribute(el3,"id","namedview14");
        dom.setAttribute(el3,"pagecolor","#ffffff");
        dom.setAttribute(el3,"bordercolor","#000000");
        dom.setAttribute(el3,"borderopacity","0.25");
        dom.setAttribute(el3,"inkscape:showpageshadow","2");
        dom.setAttribute(el3,"inkscape:pageopacity","0.0");
        dom.setAttribute(el3,"inkscape:pagecheckerboard","0");
        dom.setAttribute(el3,"inkscape:deskcolor","#d1d1d1");
        dom.setAttribute(el3,"inkscape:zoom","1.0043408");
        dom.setAttribute(el3,"inkscape:cx","266.34385");
        dom.setAttribute(el3,"inkscape:cy","133.42085");
        dom.setAttribute(el3,"inkscape:window-width","1350");
        dom.setAttribute(el3,"inkscape:window-height","1212");
        dom.setAttribute(el3,"inkscape:window-x","4188");
        dom.setAttribute(el3,"inkscape:window-y","79");
        dom.setAttribute(el3,"inkscape:window-maximized","0");
        dom.setAttribute(el3,"inkscape:current-layer","uuid-a2ffa835-c978-41f1-83dc-1ad2da3bbde6");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("defs");
        dom.setAttribute(el3,"id","defs1");
        var el4 = dom.createElement("style");
        dom.setAttribute(el4,"id","style1");
        var el5 = dom.createTextNode(".uuid-1d62b353-73f9-4152-be0b-412c8a6b1f3d{fill:#fff;}.uuid-0dfc20d4-ae5a-4550-8530-ea7e5a207e48{fill:#404040;}");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("style");
        dom.setAttribute(el4,"id","style1-7");
        var el5 = dom.createTextNode(".uuid-151e98da-8fe0-4016-b29c-83cd75b7a948,.uuid-bce4389e-4a82-4896-952d-fa6c8f04dc38,.uuid-c4a6a0dc-cb3e-49e4-b226-14587a055751,.uuid-5a758300-1f85-4782-a636-8f4b71596e54{fill:none;}.uuid-f4d691d6-0bce-4df0-895b-30c5cec054ff{fill:");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(";}.uuid-e2878217-384b-418f-bba4-cfe65b19a658{fill:#fff;}.uuid-54b1ed39-c8d9-4a91-9360-0e5d6f53a1e2{fill:#9d9d9c;}.uuid-bce4389e-4a82-4896-952d-fa6c8f04dc38{clip-path:url(#uuid-16b1850c-6308-467d-93ad-895730708938);}.uuid-c4a6a0dc-cb3e-49e4-b226-14587a055751{clip-path:url(#uuid-df217ca4-196e-474f-8ac8-a92c26787a51);}.uuid-5a758300-1f85-4782-a636-8f4b71596e54{clip-path:url(#uuid-7e60c6a6-2e42-4dce-926d-fa7a12096171);}");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("clipPath");
        dom.setAttribute(el4,"id","uuid-df217ca4-196e-474f-8ac8-a92c26787a51");
        var el5 = dom.createElement("rect");
        dom.setAttribute(el5,"class","uuid-151e98da-8fe0-4016-b29c-83cd75b7a948");
        dom.setAttribute(el5,"width","195.59");
        dom.setAttribute(el5,"height","85.04");
        dom.setAttribute(el5,"id","rect1");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("clipPath");
        dom.setAttribute(el4,"id","uuid-7e60c6a6-2e42-4dce-926d-fa7a12096171");
        var el5 = dom.createElement("path");
        dom.setAttribute(el5,"class","uuid-151e98da-8fe0-4016-b29c-83cd75b7a948");
        dom.setAttribute(el5,"d","m12.76,42.52c0,16.2,13.13,29.34,29.34,29.34s29.34-13.14,29.34-29.34-13.13-29.34-29.34-29.34-29.34,13.14-29.34,29.34");
        dom.setAttribute(el5,"id","path1-9");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("clipPath");
        dom.setAttribute(el4,"id","uuid-16b1850c-6308-467d-93ad-895730708938");
        var el5 = dom.createElement("rect");
        dom.setAttribute(el5,"class","uuid-151e98da-8fe0-4016-b29c-83cd75b7a948");
        dom.setAttribute(el5,"width","195.59");
        dom.setAttribute(el5,"height","85.04");
        dom.setAttribute(el5,"id","rect2");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"id","path1");
        dom.setAttribute(el3,"class","uuid-0dfc20d4-ae5a-4550-8530-ea7e5a207e48");
        dom.setAttribute(el3,"d","m 116.35009,12.184008 c -56.882236,0 -103.020012,46.137769 -103.020012,103.020012 0,56.88222 46.137776,103.02001 103.020012,103.02001 56.88224,0 103.02001,-46.10268 103.02001,-103.02001 0,-56.882243 -46.13777,-103.020012 -103.02001,-103.020012 z m -0.98068,19.874316 c 16.57314,0 30.0172,13.450929 30.0172,30.02407 0,16.573147 -13.44406,30.017225 -30.0172,30.017225 -16.573147,0 -30.024076,-13.444078 -30.024076,-30.017225 0,-16.573141 13.450929,-30.02407 30.024076,-30.02407 z M 59.394879,61.972673 c 9.901762,-0.245783 18.119782,7.586817 18.365571,17.453472 0.24579,9.901747 -7.585993,18.119767 -17.487755,18.365557 -9.901761,0.2458 -18.112925,-7.5868 -18.358712,-17.453467 -0.24579,-9.901759 7.579135,-18.11978 17.480896,-18.365562 z M 163.96478,80.653704 c 13.20232,-0.140458 24.04835,10.497917 24.15369,23.735356 0.14046,13.20231 -10.49791,24.05519 -23.73535,24.16052 -13.20232,0.14045 -24.04833,-10.49792 -24.15367,-23.73534 -0.14044,-13.202327 10.49791,-24.055196 23.73533,-24.160536 z M 94.054919,102.49626 c 24.929871,0 45.118411,20.18853 45.118411,45.11839 0,24.92988 -20.18854,45.1184 -45.118411,45.1184 -24.929853,0 -45.118402,-20.18852 -45.118402,-45.1184 0,-24.92986 20.188549,-45.11839 45.118402,-45.11839 z m 75.629391,36.20307 c 9.90173,0.0351 17.90691,8.07378 17.87181,17.94038 0,9.90173 -8.07294,17.90691 -17.97467,17.87178 -9.90174,0 -17.90692,-8.07375 -17.87181,-17.94038 0,-9.9017 8.07294,-17.90689 17.97467,-17.87178 z");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        dom.setNamespace(null);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","delete-confirm medium-confirm");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","footer");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","button");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createTextNode("Nein");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","button");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createTextNode("Ja");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [2]);
        var element3 = dom.childAt(fragment, [2]);
        var element4 = dom.childAt(element3, [3]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element4, [3]);
        var morphs = new Array(10);
        morphs[0] = dom.createAttrMorph(element0, 'data-id');
        morphs[1] = dom.createAttrMorph(element0, 'title');
        morphs[2] = dom.createElementMorph(element0);
        morphs[3] = dom.createAttrMorph(element1, 'width');
        morphs[4] = dom.createAttrMorph(element1, 'height');
        morphs[5] = dom.createMorphAt(dom.childAt(element1, [1, 1]),1,1);
        morphs[6] = dom.createAttrMorph(element2, 'style');
        morphs[7] = dom.createMorphAt(element3,1,1);
        morphs[8] = dom.createElementMorph(element5);
        morphs[9] = dom.createElementMorph(element6);
        return morphs;
      },
      statements: [
        ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[1,54],[1,61]]]]]]],
        ["attribute","title",["concat",[["get","tooltip",["loc",[null,[1,74],[1,81]]]]]]],
        ["element","action",["confirm"],[],["loc",[null,[1,3],[1,23]]]],
        ["attribute","width",["concat",[["get","size",["loc",[null,[4,13],[4,17]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[5,14],[5,18]]]]]]],
        ["content","innerColor",["loc",[null,[37,249],[37,263]]]],
        ["attribute","style",["concat",["stroke-width:3.51127;fill:",["get","innerStroke",["loc",[null,[55,39],[55,50]]]],";fill-opacity:1"]]],
        ["content","text",["loc",[null,[58,2],[58,10]]]],
        ["element","action",["cancel"],[],["loc",[null,[60,7],[60,26]]]],
        ["element","action",["delete"],[],["loc",[null,[61,7],[61,26]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});