define('jason-frontend/components/forward-invoice-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      referenceInvoice: false,
      referenceInvoice1: false,
      referenceInvoice2: false,
      invoiceSelected: false,
      email: null,
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(invoice, emails) {
        this.set('invoice', invoice);
        this.set('referenceInvoice', false);
        this.set('referenceInvoice1', false);
        this.set('referenceInvoice2', false);
        this.set('invoiceSelected', false);
        this.set('email', null);

        this.set('emails', emails);
        if (emails && emails != null && emails.length > 0) {
          this.set('selectedEmailFromDropdown', emails[0]);
          this.set('email', emails[0].value);
        }
      },
      loadPrintTemplates: function loadPrintTemplates(data) {
        this.set('printTemplates', data);
        if (data && data != null && data.length && data.length > 0) {
          this.set('selectedPrintTemplate', data[0]);
        }
      },
      send: function send() {
        var data = {
          invoice: this.get('referenceInvoice'),
          referenceInvoice1: this.get('referenceInvoice1'),
          referenceInvoice2: this.get('referenceInvoice2'),
          warningInvoice: this.get('invoiceSelected'),
          baseInvoiceId: this.get('invoice').get('warning') ? this.get('invoice').get('referenceInvoice').id : this.get('invoice').id,
          warningInvoiceId: this.get('invoice').get('warning') ? this.get('invoice').id : null,
          email: this.get('email')
        };

        if (!data.invoice && !data.referenceInvoice1 && !data.referenceInvoice2 && !data.warningInvoice) {
          jason.notifiction.error("Fehler", "Bitte wählen Sie zumindest ein Dokument");
          return;
        }

        if (this.get('selectedPrintTemplate')) {
          data.printTemplateId = this.get('selectedPrintTemplate').id;
        }

        this.sendAction('forward', data);
        $.magnificPopup.close();
      }
    },
    watchEmails: (function () {
      this.set('email', this.get('selectedEmailFromDropdown').value);
    }).observes('selectedEmailFromDropdown')
  });

});