define('jason-frontend/routes/compositeproducts/select-service', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",

    page: 1,
    perPage: 10,
    model: function model(params) {
      params.filterMandant = "all";
      return this.findPaged('service', params);
    },
    setupController: function setupController(controller, model, params) {
      var productId = params.params['compositeproducts.select-service'].id;

      controller.set('productId', productId);

      this.store.unloadAll('productSupplier');

      this.store.find('productSupplier', { service: true }).then(function (items) {
        controller.set('suppliers', items.sortBy('name'));
      });

      controller.set("model", model);
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          selectedCategoryFilters = controller.get('selectedCategoryFilters'),
          filterSupplier = controller.get('filterSupplier'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy'),
          filterId = null;

      if (filterSupplier === 'all') {
        filterSupplier = null;
      }

      if (selectedCategoryFilters) {
        filterId = selectedCategoryFilters.id;
      }

      this.findPaged('service', {
        filterName: filterName,
        selectedCategoryFilters: filterId,
        filterSupplier: filterSupplier,
        filterMandant: "all",
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    actions: {
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      setSort: function setSort(attr) {
        var controller = this.get('controller');
        controller.set('sortBy', attr);
        if (controller.get('sortDir') === 'asc') {
          controller.set('sortDir', 'desc');
        } else {
          controller.set('sortDir', 'asc');
        }
        this.applyFilter();
      },

      addService: function addService(quantity, serviceId, invoiceId, serviceName) {

        var compositeProductId = this.get('controller').get('productId');

        var deferred = jQuery.ajax({
          url: "/api/compositeArticles/add/" + compositeProductId + "/service/" + serviceId + "/" + quantity,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Produkt(e) hinzugefügt', serviceName);
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
        return Ember['default'].RSVP.resolve(deferred);
      }
    }
  });

});