define('jason-frontend/controllers/purchases/commissions', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Meine Provisionen",
    breadCrumbPath: "purchases.savings",
    filterIn: "day",
    filterOut: "single",
    classFilterTypeInAll: "default",
    classFilterTypeInSingle: "default",
    classFilterTypeInDay: "primary",
    classFilterTypeOutAll: "default",
    classFilterTypeOutSingle: "primary",
    classFilterTypeOutDay: "default",
    selectedRegister: null,
    showLoading: true,
    sortBy: 'date',
    sortDir: 'asc',
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),

    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    mandant: null,
    supplier: null,
    page: 1,
    perPage: 20,
    to: null,
    from: null,

    actions: {
      sortBy: function sortBy(_sortBy) {
        if (_sortBy === this.get('sortBy')) {
          if (this.get('sortDir') === 'desc') {
            this.set('sortDir', 'asc');
          } else {
            this.set('sortDir', 'desc');
          }
        }
        this.set('sortBy', _sortBy);
        this.send('applyFilter');
      }
    },
    watchMandants: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('mandant'),
    watchSuppliers: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('supplier'),
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('pickDate')
  });

});