define('jason-frontend/controllers/purchases/confirmcancel', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Bestätigung",
    breadCrumbPath: "purchases.confirmcancel",
    renderPdf: false
  });

});