define('jason-frontend/components/button-merge', ['exports', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    tooltip: '',
    color: '3B6182',
    showStroke: true,
    size: 32,
    side: 'top',
    content: '',
    theme: 'tooltipster-punk'
  });

});