define('jason-frontend/components/etiga-app-permission-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    tagName: 'div',
    actions: {
      loadCredentials: function loadCredentials(data, email) {
        this.set('credentials', data);
        this.set('email', email);
      },
      submitCredentials: function submitCredentials() {
        this.sendAction('submitCredentials', this.get('email'));
      },
      downloadEtigaQr: function downloadEtigaQr() {
        this.sendAction('downloadEtigaQr');
      }
    }
  });

});