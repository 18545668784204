define('jason-frontend/routes/partner', ['exports', 'ember', 'jason-frontend/api', 'jason-frontend/config/environment'], function (exports, Ember, API, config) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model, params) {
      API['default'].logout();
      this.session.set('user', null);
      API['default'].resetToken();

      this.get('controller').set('idpEnabled', false);
      var token = params.queryParams.token;
      var requestData = {};
      requestData.resetToken = token;
      if (token) {
        $.ajax({
          url: "/api/users/resetPassword",
          method: "POST",
          data: JSON.stringify(requestData),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.error('Fehler', 'Es ist ein unbekannter Fehler aufgetreten');
          controller.set('token', null);
        }, function (data) {
          jason.notifiction.notifiy('Password erfolgreich zurückgesetzt', 'Sie erhalten in Kürze Ihr neues Passwort');
          controller.set('token', null);
        });
      }
    },
    actions: {
      reset: function reset(username) {

        var requestData = {};
        requestData.username = username;

        $.ajax({
          url: "/api/users/resetPasswordRequest",
          method: "POST",
          data: JSON.stringify(requestData),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.error('Fehler', 'Es ist ein unbekannter Fehler aufgetreten');
        }, function (data) {
          jason.notifiction.notifiy('Password zurücksetzen', 'Sie erhalten in Kürze eine Email an ' + username + ' mit weiteren Anweisungen');
        });
      },
      login: function login() {
        var route = this,
            controller = this.get('controller');

        var username = controller.get('username'),
            password = controller.get('password');

        controller.set('message', null);
        var self = this;
        API['default'].login(username, password).then(function (login) {
          if (login.passwordExpired) {
            self.transitionTo('expired');
          } else {
            route.session.set('user', login.user);
            window.location.replace("/dashboard");
          }
        }, function (error) {
          controller.set('message', 'Email-Adresse und/oder Passwort konnten nicht zugeordnet werden');
        });
      },

      cancel: function cancel() {
        this.transitionTo('index');
      }
    },

    beforeModel: function beforeModel() {},

    resetController: function resetController(controller) {
      controller.setProperties({
        username: null,
        password: null,
        message: null,
        transition: null
      });
    }
  });

});