define('jason-frontend/helpers/is-smart-assistant-enabled', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isSmartAssistantEnabled = isSmartAssistantEnabled;

  function isSmartAssistantEnabled() {
    return API['default'].getMandant() === '3' || API['default'].getMandant() === '10007' || API['default'].getMandant() === '10009' || API['default'].getMandant() === '10000' || API['default'].getMandant() === '1' || API['default'].getMandant() === '2';
  }

  exports['default'] = Ember['default'].Helper.helper(isSmartAssistantEnabled);

});