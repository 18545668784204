define('jason-frontend/routes/editor/edit', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {
      return null;
    },
    searchTextmodules: function searchTextmodules() {
      var self = this;

      var type = this.get('controller').get('type');

      var treatmentId = this.get('controller').get('typeId');

      if (type === 'treatmentreport') {
        treatmentId = this.get('controller').get('id');
      }

      $.ajax({
        url: "/api/textModules?type=def&filterName=" + this.get('controller').get('filterName') + "&ctx=" + type + "&patientTreatmentId=" + treatmentId,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.get('controller').set('allItems', data.textModule);
        setTimeout(function () {
          $(".dragable").draggable({ revert: true, helper: "clone", appendTo: 'body' });
        }, 500);
      });
    },
    setupController: function setupController(controller, model, params) {
      var id = params.params['editor.edit'].id;
      var referer = params.params['editor.edit'].referer;
      var refererId = params.params['editor.edit'].refererId;

      if (params.queryParams && params.queryParams.context && params.queryParams.context !== 'null' && params.queryParams.context !== 'undefined') {
        this.get('controller').set('context', params.queryParams.context);
      } else {
        console.log('set context to null');
        this.get('controller').set('context', null);
      }
      if (params.queryParams && params.queryParams.patientId && params.queryParams.patientId !== 'null' && params.queryParams.patientId !== 'undefined') {
        this.get('controller').set('patientId', params.queryParams.patientId);
      } else {
        console.log('set patientId to null');
        this.get('controller').set('patientId', null);
      }

      this.get('controller').set('id', id);
      this.get('controller').set('mandantId', API['default'].getMandant());
      this.get('controller').set('type', referer);
      this.get('controller').set('typeId', refererId);

      if (referer === 'template' || referer === 'templateinstance') {
        this.get('controller').set('selectedTab', 'animal');
      }
      if (API['default'].getMandant() == 10007) {
        this.get('controller').set('styles', this.get('controller').get('stylesAlternative'));
      }
      if (API['default'].getMandant() == 10009) {
        this.get('controller').set('styles', this.get('controller').get('styles10009'));
      }

      var self = this;

      $.ajax({
        url: "/api/templatecategories",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.get('controller').set('categories', data.templateCategory);
      });

      var ids = referer + "_" + id;
      if (referer === 'treatmentreport') {
        ids = referer;
      }

      $.ajax({
        url: "/api/mandants/defaultTemplateStyle/" + ids,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        if (data.responseText !== 'none') {
          controller.set('style', data.responseText);
        }
        self.loadContent(id, referer, refererId);
        $('body').removeClass('tiny-overlay-disabled');
        $('form.search-box input[type=text]').focus();
        self.searchTextmodules();
      }, function (data) {
        if (data.responseText !== 'none') {
          controller.set('style', data.responseText);
        }
        self.loadContent(id, referer, refererId);
        $('body').removeClass('tiny-overlay-disabled');
        $('form.search-box input[type=text]').focus();
        self.searchTextmodules();
      });
    },
    loadContent: function loadContent(id, referer, refererId) {

      var suffix = '';
      if (this.get('controller').get('context')) {
        suffix += '&context=' + this.get('controller').get('context');
      }
      if (this.get('controller').get('context')) {
        suffix += '&patientId=' + this.get('controller').get('patientId');
      }

      console.log(suffix);

      var self = this;
      $.ajax({
        url: "/api/editor/" + id + "/" + referer + '?refererId=' + refererId + '&style=' + this.get('controller').get('style') + suffix,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.get('controller').set('model', data.editor);
        self.initEditor();
      });
    },
    imageUploadHandler: function imageUploadHandler(blobInfo, success, failure, progress) {

      var formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());
      formData.append('mandantId', API['default'].getMandant());
      formData.append('downloadToken', API['default'].getDownloadToken());
      formData.append('name', 'uploadname');
      formData.append('category', 'editor');

      $.ajax({
        url: '/api/mediaUploads',
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false
      }).then(function (data) {
        return success('http://res.cloudinary.com/myjason/image/upload/w_800,h_800,c_limit/' + data);
      });
    },
    initEditor: function initEditor() {
      var self = this,
          controller = self.get('controller');
      tinymce.remove();
      var dfreeBodyConfig = {
        selector: '.mce-content-body',
        toolbar_sticky: true,
        menubar: false,
        language: 'de',
        inline: true,
        statusbar: false,
        quickbar: true,
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true,
        file_picker_types: 'image',
        automatic_uploads: true,
        block_unsupported_drop: false,
        paste_retain_style_properties: 'color',
        paste_webkit_styles: 'color',
        images_upload_handler: this.imageUploadHandler,
        plugins: ['autolink', 'noneditable', 'link', 'pagebreak', 'table', 'lists', 'paste', 'image'],
        setup: function setup(editor) {
          editor.ui.registry.addButton('addImage', {
            text: '',
            icon: 'image',
            onAction: function onAction(_) {
              $.magnificPopup.open({
                removalDelay: 500,
                closeOnBgClick: false,
                items: {
                  src: '#modal-image-upload-and-select-panel'
                },
                callbacks: {
                  beforeOpen: function beforeOpen() {
                    var Animation = "mfp-with-fade";
                    this.st.mainClass = Animation;
                  }
                },
                midClick: true
              });
              controller.get('imageUploadAndSelectPanel').send('load', self, controller.get('type'), controller.get('model').patient ? controller.get('model').patient.id : 0);
            }
          });
        },
        toolbar: 'formatselect | bold italic underline | forecolor | fontsizeselect lineheight | alignleft aligncenter alignright alignjustify | bullist numlist | table addImage | pagebreak',
        contextmenu: 'image imagetools table lists',
        noneditable_noneditable_class: 'placeholder',
        pagebreak_separator: '<!--pagebreak-->',
        block_formats: 'Paragraph=p;Kopfzeile 1=h1;Kopfzeile 2=h2;Kopfzeile 3=h3;Kopfzeile 4=h4;Preformatted=pre',
        fontsize_formats: '8px 10px 12px 14px 18px 24px 36px'
      };
      setTimeout(function () {
        tinymce.init(dfreeBodyConfig);
      }, 500);
    },
    savePdf: function savePdf(id) {
      if (this.get('controller').get('type') === 'templateinstance') {

        var suffix = '';
        if (this.get('controller').get('context')) {
          suffix += '&context=' + this.get('controller').get('context');
        }
        if (this.get('controller').get('context')) {
          suffix += '&patientId=' + this.get('controller').get('patientId');
        }

        $.ajax({
          url: "/api/templateInstances/" + id + "/save/" + this.get('controller').get('typeId') + '?style=' + this.get('controller').get('style') + suffix,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {});
      } else if (this.get('controller').get('type') === 'treatmentreport') {
        $.ajax({
          url: "/api/treatmentReports/" + id + "/save/" + this.get('controller').get('typeId') + '?style=' + this.get('controller').get('style'),
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {});
      }
    },
    save: function save() {
      var data = {
        id: this.get('controller').get('model').id,
        defaultText: tinymce.activeEditor.getContent(),
        name: this.get('controller').get('model').name,
        filename: this.get('controller').get('model').filename,
        title: this.get('controller').get('model').title,
        category: this.get('controller').get('model').category
      };

      var self = this;
      var saveUrl = this.get('controller').get('id') + "/" + this.get('controller').get('type');

      if (this.get('controller').get('type') === 'treatmentreport') {
        saveUrl += "/" + this.get('controller').get('typeId');
      }

      $.ajax({
        url: "/api/editor/" + saveUrl,
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.loadContent(data, self.get('controller').get('type'), self.get('controller').get('typeId'));
        self.savePdf(self.get('controller').get('id'));
      }, function (error) {});
    },
    getDownloadFilename: function getDownloadFilename(id, browser, patientId, style, token) {
      var hostAppendix = '';
      if (location.hostname === "localhost") {
        hostAppendix = 'http://localhost:8080/';
      }

      if (this.get('controller').get('type') === 'treatmentreport') {
        id = this.get('controller').get('typeId');
      }

      return hostAppendix + 'api/editor/' + this.get('controller').get('type') + '/' + id + '/pdf?mandantId=' + API['default'].getMandant() + '&token=' + token + '&filename=' + btoa(this.get('controller').get('model').filename) + '&inBrowser=' + browser + '&style=' + style + '&patientId=' + patientId;
    },
    reloadReport: function reloadReport(id) {
      var self = this;
      $.ajax({
        url: "/api/treatmentReports/" + self.get('controller').get('id') + "/recreate/" + id,
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function () {
        $.ajax({
          url: "/api/editor/" + self.get('controller').get('id') + "/" + self.get('controller').get('type') + '?refererId=' + id + '&style=' + self.get('controller').get('style'),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').set('model', data.editor);
          self.get('controller').set('typeId', id);

          window.history.replaceState({}, '', '/dashboard/editor/' + self.get('controller').get('id') + '/treatmentreport/' + id);

          tinymce.activeEditor.setContent(data.editor.content);
        });
      }, function () {});
    },
    actions: {
      insertProduct: function insertProduct(product) {
        tinymce.activeEditor.insertContent(product);
      },
      pickProduct: function pickProduct() {
        alert('here');
        // $('#productSelectItem').addClass('dragable');
      },
      unshare: function unshare(id) {
        var self = this;
        $.ajax({
          url: "/api/treatmentReports/" + id + "/unshare",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          Ember['default'].set(self.get('controller').get('model'), 'shared', false);
          jason.notifiction.notifiy('Erfolgreich', 'eltiga Freigabe aufgehoben');
        });
      },
      share: function share(id) {
        var self = this;
        this.get('controller').set('showShareAnimation', true);
        this.save();
        setTimeout(function () {
          $.ajax({
            url: "/api/treatmentReports/" + id + "/share",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            Ember['default'].set(self.get('controller').get('model'), 'shared', true);
            jason.notifiction.notifiy('Erfolgreich', 'in eltiga App freigegeben');
            self.get('controller').set('showShareAnimation', false);
          });
        }, 10000);
      },
      styleChanged: function styleChanged() {
        this.loadContent(this.get('controller').id, this.get('controller').get('type'), this.get('controller').get('typeId'));
      },
      searchTextModules: function searchTextModules() {
        this.searchTextmodules();
      },
      toTreatment: function toTreatment() {
        this.transitionTo('practicemgmt.treatment-details', this.get('controller').get('id'));
      },
      redirectToReport: function redirectToReport(treatmentPatientId, reportId) {
        this.reloadReport(reportId);
      },
      reloadReport: function reloadReport() {
        this.reloadReport(this.get('controller').get('typeId'));
      },
      openReportWizzard: function openReportWizzard() {
        var self = this;
        $.ajax({
          url: "/api/treatmentReports/" + this.get('controller').get('id'),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('treatmentReportWizzard').send('load', data.treatmentReport, self.get('controller').get('id'));
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-report-wizzard'
          },
          callbacks: {
            close: function close() {
              self.get('controller').get('treatmentReportWizzard').send('save');
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openWizard: function openWizard(id) {
        if (id === 0) {

          var self = this;
          $.ajax({
            url: "/api/treatmentReports/" + this.get('controller').get('id'),
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.get('controller').get('treatmentReportWizzard').send('load', data.treatmentReport, self.get('controller').get('id'));
          });
        } else {
          var self = this;
          $.ajax({
            url: "/api/treatmentReports/" + this.get('controller').get('id') + "/" + id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.get('controller').get('treatmentReportWizzard').send('load', data.treatmentReport, self.get('controller').get('id'));
          });
        }

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-report-wizzard'
          },
          callbacks: {
            close: function close() {},
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      sendEmail: function sendEmail(id, data) {

        data.patientId = this.get('controller').get('model').patient.id;
        jason.notifiction.notifiy('Erfolgreich', 'Dokument wurde übermittelt');

        if (this.get('controller').get('type') === 'templateinstance') {
          data.templateInstanceId = this.get('controller').get('id');
        }
        data.type = this.get('controller').get('type');
        var style = this.get('controller').get('style');
        if (style && style.length) {
          data.style = style;
        }

        Ember['default'].$.ajax({
          url: "/api/editor/" + id + "/forwardEmail?style=" + this.get('controller').get('style'),
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {}, function () {});
      },
      openMailPanel: function openMailPanel(id) {
        this.save();
        var self = this;

        var customer = this.get('controller').get('model').customer;

        if (customer) {
          this.get('controller').get('documentForwardPanel').send('loadWithEmails', id, customer.emails);
        }
        $.ajax({
          url: "/api/textModules?filterType=email",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('documentForwardPanel').send('loadTextmodules', data.textModule, self.get('controller').get('type'));
        }, function () {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-document-forward-panel-editor'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectTab: function selectTab(id) {
        this.get('controller').set('selectedTab', id);
      },
      insertImage: function insertImage(id) {
        tinymce.activeEditor.insertContent('<img src="http://res.cloudinary.com/myjason/image/upload/w_800,h_800,c_limit/' + id + '"/>');
      },
      selectMedia: function selectMedia(id) {
        $.ajax({
          url: "/api/media/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          var hostAppendix = 'https://praxis.vetnative.com';
          if (location.hostname === "localhost") {
            hostAppendix = 'http://localhost:8080';
          }
          if (location.hostname === 'portal.myjason.eu') {
            hostAppendix = 'https://portal.myjason.eu';
          }
          tinymce.activeEditor.insertContent('<img src="' + hostAppendix + '/api/media/' + data.media.id + '/download?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken() + '"/>');
        });
      },
      back: function back() {
        this.save();
        setTimeout(function () {
          window.history.back();
        }, 1000);
      },
      savePdf: function savePdf(id) {
        this.savePdf(id);
      },
      openPreview: function openPreview(id) {
        this.save();
        this.get('controller').get('editPreviewPanel').send('reset');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-preview-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });

        var self = this;
        setTimeout(function () {

          $.ajax({
            url: "/api/users/rat",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify({
              id: id
            }),
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {}, function (data) {
            self.get('controller').get('editPreviewPanel').send('load', self.getDownloadFilename(self.get('controller').get('model').id, true, self.get('controller').get('model').patient ? self.get('controller').get('model').patient.id : 0, self.get('controller').get('model').style, data.responseText));
          });
        }, 1500);
      },
      save: function save() {
        jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        this.save();
      },
      printPdf: function printPdf(id) {
        var self = this;
        this.save();
        setTimeout(function () {
          self.savePdf(self.get('controller').get('id'));
        }, 1000);
        setTimeout(function () {
          $.ajax({
            url: "/api/users/rat",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify({
              id: id
            }),
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {}, function (data) {
            window.open(self.getDownloadFilename(self.get('controller').get('model').id, false, self.get('controller').get('model').patient ? self.get('controller').get('model').patient.id : 0, self.get('controller').get('style'), data.responseText));
          });
        }, 2000);
      },
      toggleSidebar: function toggleSidebar() {
        var controller = this.get('controller');
        if (controller.get('sidebarActive') === true) {
          controller.set('sidebarActive', false);
        } else {
          controller.set('sidebarActive', true);
          setTimeout(function () {
            $('aside.side-bar .scrollable').css('max-height', $(window).height() - 100);
            $('aside.side-bar').css('min-height', $(window).height() - 100);
            $(".dragable").draggable({ revert: true, helper: "clone", appendTo: 'body' });
          }, 100);

          if ($(window).width() <= 767) {
            $("html, body").animate({ scrollTop: 0 }, 200);
          }
        }
      }
    }
  });

});