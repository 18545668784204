define('jason-frontend/templates/components/button-prev', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-prev.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"width","32");
        dom.setAttribute(el1,"height","32");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        var el2 = dom.createElement("title");
        var el3 = dom.createTextNode("prev");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2,"d","M20.177 23.964c.283 0 .566-.104.782-.314.43-.418.43-1.096 0-1.515L15.144 16.5l5.814-5.635c.43-.42.43-1.097 0-1.515-.432-.42-1.133-.42-1.564 0L12.02 16.5l7.376 7.15c.215.21.5.314.78.314z");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(-1 0)");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(1);
        morphs[0] = dom.createAttrMorph(element0, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","fill",["concat",[["get","color",["loc",[null,[2,306],[2,311]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});