define('jason-frontend/controllers/inventory/quick-scan', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    filterOrderStatus: "exported",
    breadCrumb: "Produkt überprüfen",
    breadCrumbPath: "inventory.select-order",
    qrProductInput: '',
    actions: {
      transfer: function transfer(id) {
        var self = this;
        setTimeout(function () {
          self.get('changeWarehousePanel').send('load', self.store.find('inventory-warehouse'), id);

          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-change-warehouse-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 1000);
      }
    }
  });

});