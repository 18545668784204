define('jason-frontend/initializers/inject-session', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = {

    name: 'inject-session',

    initialize: function initialize(container, application) {

      container.register('service:session', Ember['default'].Object);

      application.inject('route', 'session', 'service:session');
      application.inject('controller', 'session', 'service:session');
    }

  };

});