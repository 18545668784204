define('jason-frontend/models/article-tag', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    internalId: DS['default'].attr(''),
    global: DS['default'].attr(''),
    selected: DS['default'].attr('')
  });

});