define('jason-frontend/helpers/get-inactivity-time', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.getInactivityTime = getInactivityTime;

  function getInactivityTime() {
    return API['default'].getMaxInactivityTime();
  }

  exports['default'] = Ember['default'].Helper.helper(getInactivityTime);

});