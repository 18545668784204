define('jason-frontend/controllers/inventory/corrections', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Korrekturbuchungen",
    breadCrumbPath: "inventory.corrections",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    pickDate: moment().format("DD. MM. YYYY") + " - " + moment().format("DD. MM. YYYY"),
    page: 1,
    showLoading: false,
    perPage: 20,
    filterStatus: 'created,used',
    myStickyOptions: {
      topSpacing: 80,
      zIndex: 9
    },
    myStickyOptionsTableHead: {
      topSpacing: 180,
      zIndex: 9
    },
    watchTime: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('pickDate'),
    actions: {
      scan: function scan() {
        this.get('quickScanProductPanel').send('loadWithoutDirect');
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-quick-scan-product-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      scanedProduct: function scanedProduct(id) {

        var self = this;

        $.ajax({
          url: "/api/inventoryStocks/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('inventoryCorrectionPanel').send('load', data.inventoryStock);
        }, function () {});

        setTimeout(function () {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-inventory-correction-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 1000);
      }
    }
  });

});