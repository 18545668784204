define('jason-frontend/components/purchase-cancel-entries', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      changeReturnQuantity: function changeReturnQuantity(entry, orderId, cancellationId, productName, oldQty) {
        var returnQty = this.$().find("input[data-return-qty-entry-id='" + entry.id + "']").val(),
            qty = parseFloat(this.$().find("input[data-qty-entry-id='" + entry.id + "']").val());

        if (oldQty != returnQty) {
          returnQty = String(returnQty).replace(',', '.');
          if (returnQty >= 0 && returnQty <= qty) {
            this.sendAction('returnQuantityChanged', returnQty, entry.id, orderId, cancellationId, productName);
          } else {
            jason.notifiction.error('Fehleingabe', 'Retournierte Menge muss mindestens 0 sein');
            this.$().find("input[data-return-qty-entry-id='" + entry.id + "']").val(oldQty);
          }
        }
      },
      increaseReturnQuantity: function increaseReturnQuantity(entry, orderId, cancellationId, productName) {
        var returnQty = parseFloat(this.$().find("input[data-return-qty-entry-id='" + entry.id + "']").val());
        var qty = parseFloat(this.$().find("input[data-qty-entry-id='" + entry.id + "']").val());
        if (returnQty >= qty) {
          jason.notifiction.error('Fehleingabe', 'Retournierte Menge darf nicht größer als bestellte Menge sein');
        } else {
          this.sendAction('returnQuantityChanged', returnQty + 1, entry.id, orderId, cancellationId, productName);
        }
      },
      decreaseReturnQuantity: function decreaseReturnQuantity(entry, orderId, cancellationId, productName) {
        var returnQty = parseFloat(this.$().find("input[data-return-qty-entry-id='" + entry.id + "']").val()),
            qty = parseFloat(this.$().find("input[data-qty-entry-id='" + entry.id + "']").val());

        if (returnQty <= 0) {
          jason.notifiction.error('Fehleingabe', 'Retournierte Menge muss mindestens 0 sein');
        } else {
          this.sendAction('returnQuantityChanged', returnQty - 1, entry.id, orderId, cancellationId, productName);
        }
      }
    }
  });

});