define('jason-frontend/serializers/purchase-sub-order', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  exports['default'] = DS['default'].ActiveModelSerializer.extend(DS['default'].EmbeddedRecordsMixin, {
    attrs: {
      entries: { embedded: 'always' },
      supplier: { embedded: 'always' }
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      var serialized = this.serialize(record, options);
      serialized.id = record.id ? record.id : 0;
      Ember['default'].merge(hash, serialized);
    },
    normalize: function normalize(type, hash, property) {
      hash.id = hash.subOrderId;
      return this._super(type, hash, property);
    }
  });

});