define('jason-frontend/components/global-doctor-panel', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    invoice: null,
    doctor: null,
    assistant: null,
    doctorObserve: (function () {
      this.sendAction('doctorChanged', this.get('invoice').get('id'), this.get('doctor'), 'doctor');
    }).observes('doctor'),
    assistantObserve: (function () {
      this.sendAction('assistantChanged', this.get('invoice').get('id'), this.get('assistant'), 'assistant');
    }).observes('assistant'),
    invoiceChange: (function () {}).observes('invoice')
  });

});