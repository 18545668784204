define('jason-frontend/routes/transactions/cashbook', ['exports', 'ember', 'moment', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, moment, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {
      params.filterMandant = "all";
      params.filterFrom = moment['default']().startOf('month').format('YYYY-MM-DD HH:mm');
      params.filterTo = moment['default']().endOf('month').format('YYYY-MM-DD HH:mm');
      return this.findPaged('cashbook', params);
    },
    perPage: 20,
    queryParams: {},
    setupController: function setupController(controller) {

      if (!API['default'].hasPermission('r_cashbook')) {
        this.transitionTo('home');
      }

      this.applyFilter();
      controller.set('cashRegisters', this.store.find('cashRegister'));
      controller.set('downloadToken', API['default'].getDownloadToken());
      controller.set('mandantId', API['default'].getMandant());
    },
    actions: {
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-'),
          selectedRegister = controller.get('selectedRegister');

      var from = moment['default'](dateFromTo[0], 'DD. MM. YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
          to = moment['default'](dateFromTo[1], 'DD. MM. YYYY HH:mm').format('YYYY-MM-DD HH:mm');

      controller.set('to', to);
      controller.set('from', from);

      this.findPaged('cashbook', {
        filterFrom: from,
        filterTo: to,
        filterIn: controller.get('filterIn'),
        filterOut: controller.get('filterOut'),
        selectedRegister: selectedRegister
      }).then(function (transactions) {
        var meta = transactions.meta;
        controller.set('sumIn', meta.sumIn);
        controller.set('sumOut', meta.sumOut);
        controller.set('model', transactions);
      });
    }
  });

});