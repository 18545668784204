define('jason-frontend/controllers/purchases/transaction-fees', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Meine Jason Network Fee",
    breadCrumbPath: "purchases.transactionFees",
    filterIn: "day",
    filterOut: "single",
    classFilterTypeInAll: "default",
    classFilterTypeInSingle: "default",
    classFilterTypeInDay: "primary",
    classFilterTypeOutAll: "default",
    classFilterTypeOutSingle: "primary",
    classFilterTypeOutDay: "default",
    selectedRegister: null,
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),

    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    perPage: 20,
    to: null,
    from: null,

    actions: {
      setFilterType: function setFilterType(type, setting) {
        if (type === 'in') {
          if (setting === 'all') {
            this.set('classFilterTypeInAll', "primary");
            this.set('classFilterTypeInSingle', "default");
            this.set('classFilterTypeInDay', "default");
          }
          if (setting === 'single') {
            this.set('classFilterTypeInAll', "default");
            this.set('classFilterTypeInSingle', "primary");
            this.set('classFilterTypeInDay', "default");
          }
          if (setting === 'day') {
            this.set('classFilterTypeInAll', "default");
            this.set('classFilterTypeInSingle', "default");
            this.set('classFilterTypeInDay', "primary");
          }
        }
        this.send('applyFilter');
      }
    }
  });

});