define('jason-frontend/helpers/is-etiga-readable', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isEtigaReadable = isEtigaReadable;

  function isEtigaReadable(params) {
    var area = params[0],
        permissionMap = params[1],
        patient = params[2];

    var returnValue = true;

    try {
      if (patient.get('etigaOwner') == true) {
        return true;
      }
    } catch (e) {
      try {
        if (patient.etigaOwner == true) {
          return true;
        }
      } catch (e) {}
    }

    if (permissionMap) {
      $.each(permissionMap.items, function (index, permission) {
        if (permission.title === area) {
          returnValue = permission.permissionPractice === 'read' || permission.permissionPractice === 'write';
          return returnValue;
        }
      });
    }
    return returnValue;
  }

  exports['default'] = Ember['default'].Helper.helper(isEtigaReadable);

});