define('jason-frontend/components/patient-weight', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: '',
    actions: {
      edit: function edit(id, patientId) {
        this.sendAction('edit', id, patientId);
      },
      'delete': function _delete(id, patientId) {
        this.sendAction('delete', id, patientId);
      },
      sortable: function sortable(patientId) {
        this.sendAction('setSortDir', patientId);
      }
    }
  });

});