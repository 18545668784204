define('jason-frontend/templates/components/button-quick-labor', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-quick-labor.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"class","cursor");
        dom.setAttribute(el1,"viewBox","0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill-rule","evenodd");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M16,31 C24.2842713,31 31,24.2842713 31,16 C31,7.71572875 24.2842713,1 16,1 C7.71572875,1 1,7.71572875 1,16 C1,24.2842713 7.71572875,31 16,31 L16,31 L16,31 Z M16,29.5714286 C8.50470696,29.5714286 2.42857143,23.495293 2.42857143,16 C2.42857143,8.50470696 8.50470696,2.42857143 16,2.42857143 C23.495293,2.42857143 29.5714286,8.50470696 29.5714286,16 C29.5714286,23.495293 23.495293,29.5714286 16,29.5714286 L16,29.5714286 L16,29.5714286 Z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","translate(9 8)");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M9.31055098,0.270933015 L5.17020081,0.270933015 L5.17020081,7.34731379 L0.900105085,13.3806855 C-0.0543784621,14.7294367 0.326728373,16.0298231 1.78303371,16.0298231 L12.6968965,16.0298231 C14.1530383,16.0298231 14.5340411,14.7295745 13.5798052,13.3815629 L9.31055098,7.34868365 L9.31055098,0.270933015 Z M8.60193861,1.02383365 L8.60193861,7.60015706 L13.0134622,13.834077 C13.6517379,14.735744 13.4931602,15.2769225 12.6968965,15.2769225 L1.78303371,15.2769225 C0.986567903,15.2769225 0.827896971,14.7355165 1.46646582,13.8331746 L5.87881318,7.59881041 L5.87881318,1.02383365 L8.60193861,1.02383365 Z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("polygon");
        dom.setAttribute(el4,"points","4.273 1.024 9.794 1.024 9.794 .271 4.273 .271");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("polygon");
        dom.setAttribute(el4,"points","4.273 9.306 9.793 9.306 9.793 8.553 4.273 8.553");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element1, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,64],[1,68]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,82],[1,86]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[2,13],[2,18]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});