define('jason-frontend/components/labresult-upload-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    currentFile: null,
    uploading: false,
    active: false,
    loaded: false,
    selectedTab: null,
    selectedTags: null,
    tagsFlat: null,
    type: 'default',
    uploadedFiles: null,
    statusAvailable: false,
    actions: {
      uploadInProgress: function uploadInProgress(e) {
        this.set('uploading', true);
      },
      save: function save() {
        this.sendAction('save', this.get('item'));
        $.magnificPopup.close();
      },
      load: function load(item) {
        this.set('item', item);

        Ember['default'].set(this.get('item'), 'url', this.get('item').get('vetconnectConfirmUrl'));
        this.set('active', true);

        var self = this;

        if (!this.get('loaded')) {
          this.set('loaded', true);
          Dropzone.autoDiscover = false;
          var myDropzone = new Dropzone("#labResultUploadForm", {
            maxFiles: 1,
            createImageThumbnails: false,
            url: '/api/labResults/upload?id=' + item.id + '&mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken()
          });

          myDropzone.on("addedfile", function (file) {
            self.set('uploading', true);
          });
          myDropzone.on("complete", function (file) {
            var status = file.xhr.status;
            if (status === 200) {
              var id = file.xhr.responseText;
              $.magnificPopup.close();
            } else {
              jason.notifiction.error('Beim Hochladen ist ein Fehler aufgetreten', '');
            }
            self.sendAction('save', self.get('item'));
            self.set('uploading', false);
          });
        } else {
          Dropzone.forElement('#labResultUploadForm').removeAllFiles(true);
        }
      },
      uploadError: function uploadError() {
        this.set('uploading', false);
        this.set('active', false);
      },
      uploadDone: function uploadDone(data) {
        this.set('uploading', false);
        this.set('active', false);
        this.sendAction('uploadDone');

        $.magnificPopup.close();
      }
    }
  });

});