define('jason-frontend/controllers/purchases/summary', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Zusammenfassung",
    breadCrumbPath: "purchases.summary",
    acceptAgbs: false,
    sidebarActive: true,
    order: null,
    openSupplierPanel: function openSupplierPanel() {

      this.get('supplierPanel').send('init');

      var self = this;

      if (this.get('model').get('needsRichterWsCall')) {
        self.get('supplierPanel').send('updateRichter', { status: 'NOT_NEEDED' });
      } else if (this.get('model').get('invalidRichterCredentials')) {
        self.get('supplierPanel').send('updateRichter', { status: 'INVALID_CREDENTIALS' });
      } else {
        self.get('supplierPanel').send('updateRichter', { status: 'NOT_NEEDED' });
      }

      $.magnificPopup.open({
        removalDelay: 500, //delay removal by X to allow out-animation,
        closeOnBgClick: false,
        items: {
          src: '#modal-form-supplier-panel'
        },
        callbacks: {
          beforeOpen: function beforeOpen() {
            var Animation = "mfp-with-fade";
            this.st.mainClass = Animation;
          }
        },
        midClick: true
      });
    },
    actions: {
      checkRichter: function checkRichter(order) {
        var self = this;
        if (order.get('needsRichterWsCall')) {

          $.ajax({
            url: "/api/purchaseOrders/" + order.id + "/addRichter",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            if (data.readyToSubmit) {
              self.send('checkDatacare', order);
            } else {
              if (data.blocked) {
                self.get('supplierPanel').send('updateRichterReaction', 'INVALID_SUM', data);
                jason.notifiction.error('Abbruch', 'Es ist ein Fehler aufgetreten - bitte versuchen sie es später erneut');
              } else {
                self.get('supplierPanel').send('updateRichterReaction', 'NOT_AVAILABLE', data);
              }
            }
          }, function (error) {
            self.get('supplierPanel').send('updateRichterReaction', 'ERROR');
            jason.notifiction.error('Abbruch', 'Die Bestellung kann zurzeit nicht durchgeführt werden');
          });
        } else {
          self.send('checkDatacare', order);
        }
      },
      checkDatacare: function checkDatacare(order) {

        var jacobyThere = false;

        $.each(order.get('subOrders').currentState, function (index, value) {
          var id = value.id;

          if (id.indexOf("jac") > 0 && value._data.confirmOrder === true) {
            jacobyThere = true;
          }
        });

        if (jacobyThere) {
          setTimeout(function () {
            $.magnificPopup.open({
              removalDelay: 500, //delay removal by X to allow out-animation,
              closeOnBgClick: false,
              items: {
                src: '#modal-datacare-delivery-panel'
              },
              callbacks: {
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: true
            });
          }, 1000);

          this.get('datacareDeliveryPanel').send('load', order);
        } else {
          this.send('confirm', order);
        }
      },
      openSupplierPanel: function openSupplierPanel() {
        this.openSupplierPanel();
      },
      confirmLaboklin: function confirmLaboklin(order) {
        var self = this;

        setTimeout(function () {
          self.get('model').reload();
          self.openSupplierPanel();
        }, 1000);
      },
      openLaboklin: function openLaboklin(order) {
        var self = this;
        var deferred = $.ajax({
          url: "/api/purchaseOrders/" + order.id + "/laboklinForm",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (data.purchaseOrderLaboklinForm.birthday) {
            data.purchaseOrderLaboklinForm.birthday = moment(data.purchaseOrderLaboklinForm.birthday).format('DD. MM. YYYY');
          } else {
            data.purchaseOrderLaboklinForm.birthday = moment().format('DD. MM. YYYY');
          }
          if (data.purchaseOrderLaboklinForm.probeTaking) {
            data.purchaseOrderLaboklinForm.probeTaking = moment(data.purchaseOrderLaboklinForm.probeTaking).format('DD. MM. YYYY');
          } else {
            data.purchaseOrderLaboklinForm.probeTaking = moment().format('DD. MM. YYYY');
          }
          self.set('form', data.purchaseOrderLaboklinForm);
        }, function (error) {});
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-form-laboklin-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    }
  });

});