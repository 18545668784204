define('jason-frontend/components/email-lab-result-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    months: null,
    type: null,
    actions: {
      send: function send(invoice) {

        var form = $('#emailLabResultForm');

        var value = form.find('input[name=email]').val(),
            persist = form.find('#persist-email').is(':checked');

        if (value.length < 3) {
          jason.notifiction.systemError('Fehler', 'Bitte geben Sie eine gültige Email-Adresse ein');
          return;
        }

        var data = {
          email: value,
          persist: persist
        };

        this.sendAction('confirm', this.get('id'), data);

        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(id, email) {
        this.set('id', id);
        this.set('email', email);
      }
    }
  });

});