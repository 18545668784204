define('jason-frontend/routes/inventory/stocktaking-add', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    perPage: 10,
    queryParams: {},
    setupController: function setupController(controller, model, params) {
      controller.set("model", model);
      var id = params.params['inventory.stocktaking-add'].id;
      controller.set('id', id);
      this.applyFilter();
    },
    actions: {
      scanedProduct: function scanedProduct(id) {
        this.get('controller').set('filterCode', id);
        this.applyFilter();
      },
      addCorrection: function addCorrection(correctionId) {

        var id = this.get('controller').get('id');

        var self = this;
        $.ajax({
          url: "/api/inventoryStocktakings/" + id + "/addCorrection/" + correctionId,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          jason.notifiction.notifiy('Korrekturbuchung', 'hinzugefügt');
        }, function (data) {
          jason.notifiction.notifiy('Korrekturbuchung', 'hinzugefügt');
        });
      },
      showStock: function showStock(id) {
        this.transitionTo('inventory.stock-details', id);
      },
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          code = controller.get('filterCode'),
          status = controller.get('filterStatus'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      controller.set('showLoading', true);

      this.findPaged('inventory-stock-movement', {
        filterCode: code,
        filterStatus: status,
        filterType: 'correction',
        to: to,
        from: from,
        pageSize: this.get('perPage')
      }).then(function (data) {
        var meta = data.meta;
        controller.set('model', data);
        controller.set('showLoading', false);
        controller.set('totalPlus', meta.total_plus);
        controller.set('totalMinus', meta.total_minus);
      });
    }
  });

});