define('jason-frontend/components/export-invoice-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    selectedMode: 'new',
    actions: {
      confirm: function confirm() {
        this.sendAction('confirm', this.get('tpId'), this.get('selectedMode'), this.get('selectedInvoice'));
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      selectMode: function selectMode(mode) {
        this.set('selectedMode', mode);
      },
      load: function load(tpId, invoices, mode) {
        var self = this;
        this.set('tpId', tpId);
        this.set('selectedMode', mode);
        this.set('invoices', invoices);
        if (invoices && invoices.invoices) {
          self.set('selectedInvoice', invoices.invoices[0].id);
        }
      }
    }
  });

});