define('jason-frontend/routes/practicemgmt/payment', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {

      controller.set("gendersAll", this.store.find('gender'));
      controller.set('patientGenders', this.store.find('patient-gender'));
      controller.set('patientCategories', this.store.find('patient-category'));
      controller.set('doctors', this.store.find('user', { role: 'doctor' }));

      controller.set("model", model);

      controller.set('filterCustomer', null);
      this.applyFilter();
    },
    updateState: function updateState(_updateState) {
      var self = this;
      $.ajax({
        url: "/api/treatments/updateState",
        method: "POST",
        data: JSON.stringify(_updateState),
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.refresh();
      });
    },
    applyFilter: function applyFilter() {

      var controller = this.get('controller'),
          filterCustomer = controller.get('filterCustomer');
      this.findPaged('treatment', {
        filterCustomer: filterCustomer,
        type: 'ready4payment'
      }).then(function (entries) {
        controller.set('model', entries);
        controller.set('customers', entries.meta.customers);
        controller.set('sum', entries.meta.sum);
      });
    },
    actions: {
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      queryCustomer: function queryCustomer(query, deferred) {
        this.store.find('customer', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      exportInvoice: function exportInvoice(id) {
        var self = this,
            deferred = $.ajax({
          url: "/api/treatmentOffers/" + id + "/checkout",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('invoices.create', data, { queryParams: { entry: "true" } });
          jason.notifiction.notifiy('In Ausgangsrechnung übertragen', '');
        }, function (error) {});
      },
      close: function close(id) {
        var self = this,
            updateState = {
          treatmentPatientId: id,
          newState: 'closed',
          hardAbort: true
        };
        this.updateState(updateState);
      }
    }
  });

});