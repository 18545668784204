define('jason-frontend/helpers/show-treatment', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.showTreatment = showTreatment;

  function showTreatment(params) {
    var treatment = params[0],
        showAll = params[1],
        showAdmission = params[2],
        showWaiting = params[3],
        showTreatment = params[4],
        showPayment = params[5],
        showStation = params[6],
        stationsOverride = params[7],
        filteredStation = params[8],
        selectedUsers = params[9];

    var show = false;
    var showUser = true;
    if (treatment.get('treatmentPatients')) {
      $.each(treatment.get('treatmentPatients').currentState, function (index, value) {
        var stateId = value.record.get('state').get('id');
        if (showAll && (stationsOverride || stateId !== 'in_station')) {
          show = true;
        } else {
          if (showAdmission && stateId === 'in_admission' || showWaiting && stateId === 'in_waitingroom' || showTreatment && stateId === 'in_treatment' || showPayment && stateId === 'ready_for_payment' || showStation && stateId === 'in_station') {
            show = true;
            if (showStation) {
              if (filteredStation && filteredStation.length && filteredStation.some(function (s) {
                return s.get('selected') === true;
              })) {
                show = filteredStation.some(function (s) {
                  return s.get('selected') === true && value.record.get('station') && value.record.get('station').id === s.id;
                });
              }
            }
          }
        }

        if (selectedUsers && selectedUsers.length) {
          var doctor = selectedUsers.some(function (u) {
            return value.record.get('doctor') && u === value.record.get('doctor').id;
          });
          var assistent = selectedUsers.some(function (u) {
            return value.record.get('assistant') && u === value.record.get('assistant').id;
          });
          showUser = assistent || doctor;
        }
      });
    }
    return showUser && show;
  }

  exports['default'] = Ember['default'].Helper.helper(showTreatment);

});