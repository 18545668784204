define('jason-frontend/models/invoice-reference', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    code: DS['default'].attr('string'),
    text: DS['default'].attr('string'),
    type: DS['default'].attr('string'),
    msg: DS['default'].attr('string'),
    created: DS['default'].attr('date')
  });

});