define('jason-frontend/components/edit-etiga-campaign-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    item: null,
    selectedTab: 'edit',
    showLoading: true,
    campaignId: null,
    loadReceivers: function loadReceivers() {
      var self = this;
      $.ajax({
        url: "/api/etigaCampaigns/" + this.get('campaignId'),
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('showLoading', false);
        self.set('showItem', data.etigaCampaign);
      });
    },
    selectTab: function selectTab(target) {
      var self = this;
      if (target === 'receivers') {

        this.confirm();
        self.set('showLoading', true);
        setTimeout(function () {
          self.loadReceivers();
        }, 2000);
      }
      this.set('selectedTab', target);
    },
    confirm: function confirm() {

      var data = {};

      var item = this.get('item');

      if (item.patientCategory) {
        var cat = {
          id: item.patientCategory.id
        };
        data.patientCategory = cat;
      }

      if (this.get('campaignId')) {
        data.editId = this.get('campaignId');
        data.id = this.get('campaignId');
      }

      if (item.patientGender) {
        var cat = {
          id: item.patientGender.id
        };
        data.patientGender = cat;
      }

      data.patientAge = item.patientAge;
      data.patientAgeTo = item.patientAgeTo;
      data.content = item.content;
      data.title = item.title;

      this.sendAction('save', data);
    },
    actions: {
      remove: function remove(id) {
        var campaignId = this.get('campaignId');
        var self = this;
        $.ajax({
          url: "/api/etigaCampaigns/" + campaignId + "/patient/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('showLoading', true);
          self.loadReceivers();
        }, function () {
          self.set('showLoading', true);
          self.loadReceivers();
        });
      },
      selectTab: function selectTab(target) {
        this.selectTab(target);
      },
      confirm: function confirm() {
        this.confirm();

        // $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load() {
        var item = {};
        this.set('item', item);
        this.set('campaignId', null);
        this.selectTab('edit');
      },
      loadRecord: function loadRecord(item) {
        this.set('item', item);
        this.set('campaignId', item.id);
        this.selectTab('edit');
      },
      updateId: function updateId(id) {
        this.set('campaignId', id);
      },
      submit: function submit(id) {
        this.sendAction('submit', id);
        $.magnificPopup.close();
      }
    }
  });

});