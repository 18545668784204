define('jason-frontend/routes/inventory/check-detail', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {

      var id = params.params['inventory.check-detail'].id;
      this._super(controller);
      this.loadData(id);
      controller.set('users', this.store.find('user', { per_page: 100 }));
    },
    loadData: function loadData(id) {

      var self = this,
          controller = this.get('controller');

      $.ajax({
        url: "/api/inventoryChecks/" + id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('model', data.inventoryCheck);
      }, function () {
        controller.set('model', data.inventoryCheck);
      });
    },
    save: function save() {

      var data = this.get('controller').get('model'),
          controller = this.get('controller');

      var user = {
        id: data.user.id
      };

      Ember['default'].set(data, "user", user);

      $.ajax({
        url: "/api/inventoryChecks/" + this.get('controller').get('model').id,
        method: "POST",
        contentType: "application/json",
        data: JSON.stringify(data),
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('model', data.inventoryCheck);
      }, function () {});
    },
    actions: {
      manualStockSelected: function manualStockSelected(item, product) {
        var self = this;
        var controller = this.get('controller');
        var data = {
          id: item
        };

        $.ajax({
          url: "/api/inventoryChecks/" + this.get('controller').get('model').id + "/add",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('model', data.inventoryCheck);
          jason.notifiction.notifiy('Position', 'hinzugefügt');
        }, function () {
          controller.set('model', data.inventoryCheck);
          jason.notifiction.notifiy('Position', 'hinzugefügt');

          $.ajax({
            url: "/api/products/" + id.replace('product', '').replace('qr', ''),
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (product) {
            $.ajax({
              url: "/api/inventoryStocks/product/" + product.product.id,
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              self.get('controller').get('stockSelectPanel').send('load', data, product.product);
            }, function () {});

            setTimeout(function () {
              $.magnificPopup.open({
                removalDelay: 500,
                closeOnBgClick: false,
                items: {
                  src: '#modal-select-stock-panel'
                },
                callbacks: {
                  beforeOpen: function beforeOpen() {
                    var Animation = "mfp-with-fade";
                    this.st.mainClass = Animation;
                  }
                },
                midClick: true
              });
            }, 1000);
          }, function () {});
        });
      },
      print: function print(id) {
        window.open('/api/inventoryChecks/download/' + id);
      },
      save: function save() {
        this.save();
      },
      deleteEntry: function deleteEntry(id) {
        var self = this,
            controller = this.get('controller');

        $.ajax({
          url: "/api/inventoryChecks/" + this.get('controller').get('model').id + "/entry/" + id,
          method: "DELETE",
          contentType: "application/json",

          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('model', data.inventoryCheck);
        }, function () {
          controller.set('model', data.inventoryCheck);
        });
      },
      scan: function scan() {
        this.save();
        this.get('controller').send('openScan');
      },
      scanProduct: function scanProduct(id) {
        var self = this,
            controller = this.get('controller');

        if (id.startsWith('product')) {
          $.ajax({
            url: "/api/products/" + id.replace('product', '').replace('qr', ''),
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (product) {
            $.ajax({
              url: "/api/inventoryStocks/product/" + product.product.id,
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              self.get('controller').get('stockSelectPanel').send('load', data, product.product);
            }, function () {});

            setTimeout(function () {
              $.magnificPopup.open({
                removalDelay: 500,
                closeOnBgClick: false,
                items: {
                  src: '#modal-select-stock-panel'
                },
                callbacks: {
                  beforeOpen: function beforeOpen() {
                    var Animation = "mfp-with-fade";
                    this.st.mainClass = Animation;
                  }
                },
                midClick: true
              });
            }, 1000);
          }, function () {});
        } else {
          var data = {
            id: id
          };

          $.ajax({
            url: "/api/inventoryChecks/" + this.get('controller').get('model').id + "/add",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(data),
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set('model', data.inventoryCheck);
            jason.notifiction.notifiy('Kontrolle', 'hinzugefügt');
          }, function () {
            controller.set('model', data.inventoryCheck);
            jason.notifiction.notifiy('Kontrolle', 'umgebucht');
          });
        }
      }
    }
  });

});