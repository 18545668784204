define('jason-frontend/components/invoice-deposit-chooser', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    actions: {
      registerDepositPayments: function registerDepositPayments() {
        var payment = $('#cell-deposit-payment').data('payment'),
            deposit = $('#cell-deposit-deposit').data('deposit');

        if (payment < 0) {
          payment = payment * -1;
        }

        if (deposit < 0) {
          deposit = deposit * -1;
        }

        this.sendAction('continue', payment, deposit);
      },
      toSelectPositions: function toSelectPositions() {
        this.sendAction('toSelectPositions');
      }
    },
    becomeFocused: (function () {

      function roundToTwo(value) {
        return Math.round(value * 100) / 100;
      }

      $(document).on("blur", "#deposit-payment", function () {
        var deposit = $('#cell-deposit-deposit'),
            open = $('#cell-deposit-open').data('open'),
            payment = $('#cell-deposit-payment'),
            paymentValue = $(this).val().replace(',', '.');

        if (paymentValue < 0) {
          paymentValue = paymentValue * -1;
        }

        if (paymentValue > -1 * open) {
          paymentValue = open * -1;
        }

        var newAmount = roundToTwo(-1 * open - paymentValue);
        deposit.find('input').val(new String(newAmount).replace('.', ','));
        deposit.data('deposit', newAmount);
        payment.data('payment', paymentValue);
        payment.find('input').val(new String(paymentValue).replace('.', ','));
      });

      $(document).on("blur", "#deposit-deposit", function () {
        var payment = $('#cell-deposit-payment'),
            deposit = $('#cell-deposit-deposit'),
            open = $('#cell-deposit-open').data('open'),
            depositValue = $(this).val().replace(',', '.');

        if (depositValue < 0) {
          depositValue = depositValue * -1;
        }
        if (depositValue > -1 * open) {
          depositValue = -1 * open;
        }

        var newAmount = roundToTwo(-1 * open - depositValue);
        payment.find('input').val(new String(newAmount).replace('.', ','));
        payment.data('payment', newAmount);
        deposit.data('deposit', depositValue);
        deposit.find('input').val(new String(depositValue).replace('.', ','));
      });

      $.validator.addMethod("mynumber", function (value, element) {
        return this.optional(element) || /^\d+([,][0-9]{1,2})?$/g.test(value);
      }, "Bitte einen gültigen Betrag eingeben");

      $.validator.addMethod("notZero", function (value, element) {
        var valueSafe = value.length ? value.replace(',', '.') : 0;
        return this.optional(element) || valueSafe > 0;
      }, "Bitte einen Betrag > 0,01 Euro eingeben");

      $("#payment-Chooser-Form").validate({
        errorClass: "state-error",
        validClass: "state-success",
        errorElement: "em",
        rules: {
          'deposit-payment': {
            mynumber: true
          },
          'deposit-deposit': {
            mynumber: true
          }
        },
        highlight: function highlight(element, errorClass, validClass) {
          $(element).closest('.field').addClass(errorClass).removeClass(validClass);
        },
        unhighlight: function unhighlight(element, errorClass, validClass) {
          $(element).closest('.field').removeClass(errorClass).addClass(validClass);
        },
        errorPlacement: function errorPlacement(error, element) {
          if (element.is(":radio") || element.is(":checkbox")) {
            element.closest('.option-group').after(error);
          } else {
            error.insertAfter(element.parent());
          }
        }
      });
    }).on('didInsertElement')
  });

});