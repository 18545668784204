define('jason-frontend/helpers/calculate-saving', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.calculateSaving = calculateSaving;

  function calculateSaving(params) {
    return params[1] - params[0];
  }

  exports['default'] = Ember['default'].Helper.helper(calculateSaving);

});