define('jason-frontend/routes/purchases/suppliers', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find('productSupplier', { filterPurchase: true, hideAll: true });
    }
  });

});