define('jason-frontend/templates/welcome/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 61
            },
            "end": {
              "line": 16,
              "column": 113
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Kunden demnächst");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 61
            },
            "end": {
              "line": 21,
              "column": 113
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Tiere auf Station");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 81,
              "column": 4
            },
            "end": {
              "line": 110,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col  l-24/24 l-12/24@tablet  l-6/24@desk gutter kpi-tile");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","card u-text--center mb20");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card-header h-90px align-items-start border-0 pt-5");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","card-title d-flex flex-column");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","d-flex align-items-center cursor");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"style","font-size: 1rem;padding-right: 10px");
          var el7 = dom.createTextNode("€");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"style","font-size: 2.2rem !important;");
          dom.setAttribute(el6,"class","is-highlighted");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"style","padding-left: 20px;font-size: 1rem");
          var el7 = dom.createTextNode("Umsatz heute");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card-body");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","d-flex flex-stack cursor");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","text-gray-700 fw-semibold fs-6 me-2");
          var el6 = dom.createTextNode("Laufende Woche");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","d-flex align-items-senter");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","text-gray-900 fw-bolder fs-6");
          dom.setAttribute(el6,"style","font-size: 1rem !important;");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","separator separator-dashed my-3");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","d-flex flex-stack cursor");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","text-gray-700 fw-semibold fs-6 me-2");
          var el6 = dom.createTextNode("Laufendes Monat");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","d-flex align-items-senter");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","text-gray-900 fw-bolder fs-6");
          dom.setAttribute(el6,"style","font-size: 1rem !important;");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","hint");
          var el5 = dom.createTextNode("Umsätze Netto");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","updated");
          var el5 = dom.createTextNode("Stand: ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element18 = dom.childAt(fragment, [1, 1]);
          var element19 = dom.childAt(element18, [1, 1, 1]);
          var element20 = dom.childAt(element18, [3]);
          var element21 = dom.childAt(element20, [1]);
          var element22 = dom.childAt(element20, [5]);
          var morphs = new Array(7);
          morphs[0] = dom.createElementMorph(element19);
          morphs[1] = dom.createMorphAt(dom.childAt(element19, [3]),0,0);
          morphs[2] = dom.createElementMorph(element21);
          morphs[3] = dom.createMorphAt(dom.childAt(element21, [3, 0]),0,0);
          morphs[4] = dom.createElementMorph(element22);
          morphs[5] = dom.createMorphAt(dom.childAt(element22, [3, 0]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element20, [9]),1,1);
          return morphs;
        },
        statements: [
          ["element","action",["gotoSalesDashboard"],[],["loc",[null,[86,58],[86,89]]]],
          ["inline","format-number-german",[["get","revenueData.today",["loc",[null,[88,105],[88,122]]]]],[],["loc",[null,[88,82],[88,124]]]],
          ["element","action",["gotoSalesDashboard"],[],["loc",[null,[94,48],[94,79]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","revenueData.week",["loc",[null,[96,154],[96,170]]]]],[],[]],"class","inline"],["loc",[null,[96,130],[96,187]]]],
          ["element","action",["gotoSalesDashboard"],[],["loc",[null,[99,48],[99,79]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","revenueData.month",["loc",[null,[101,154],[101,171]]]]],[],[]],"class","inline"],["loc",[null,[101,130],[101,188]]]],
          ["inline","time-format",[["get","revenueData.lastUpdate",["loc",[null,[105,52],[105,74]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[105,38],[105,97]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 115,
              "column": 8
            },
            "end": {
              "line": 117,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","text-center mt20 mb20");
          var el2 = dom.createTextNode("Derzeit keine Daten verfügbar");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 126,
                "column": 8
              },
              "end": {
                "line": 128,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","text-center mt20 mb20");
            var el2 = dom.createTextNode("Derzeit keine Daten verfügbar");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 121,
              "column": 0
            },
            "end": {
              "line": 132,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-24/24 l-12/24@desk gutter ");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","card mb20");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","title");
          var el4 = dom.createTextNode("Umsätze");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","small mb10");
          var el4 = dom.createTextNode("Monatsvergleich, netto");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"id","revenueBarChart");
          dom.setAttribute(el3,"style","height: 200px; margin: 0 auto");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),5,5);
          return morphs;
        },
        statements: [
          ["block","if",[["get","hideRevenueChart",["loc",[null,[126,14],[126,30]]]]],[],0,null,["loc",[null,[126,8],[128,15]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 171,
                "column": 32
              },
              "end": {
                "line": 171,
                "column": 160
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.customer.firstname",["loc",[null,[171,106],[171,133]]]],
            ["content","item.customer.lastname",["loc",[null,[171,134],[171,160]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 176,
                  "column": 36
                },
                "end": {
                  "line": 176,
                  "column": 139
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","treatment.patient.name",["loc",[null,[176,113],[176,139]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 175,
                "column": 28
              },
              "end": {
                "line": 177,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["block","link-to",["patients.edit",["get","treatment.patient.id",["loc",[null,[176,63],[176,83]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[176,36],[176,151]]]]
          ],
          locals: ["treatment"],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 181,
                "column": 26
              },
              "end": {
                "line": 185,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element16);
            morphs[1] = dom.createMorphAt(element16,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["admissionPatient",["get","item.customer.id",["loc",[null,[182,126],[182,142]]]],["get","patientInfo.patient.id",["loc",[null,[182,143],[182,165]]]]],[],["loc",[null,[182,98],[182,167]]]],
            ["inline","button-admission",[],["size","32","color","#fff","showStroke",false,"content","Check-In"],["loc",[null,[183,32],[183,111]]]]
          ],
          locals: ["patientInfo"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 165,
              "column": 20
            },
            "end": {
              "line": 188,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Aufgenommen");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Tier");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-2/24@desk buttons-2");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element17, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element17, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element17, [5, 1]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element17, [7]),1,1);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.created",["loc",[null,[168,46],[168,58]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[168,32],[168,81]]]],
          ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[171,60],[171,76]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[171,32],[171,172]]]],
          ["block","each",[["get","item.treatmentPatients",["loc",[null,[175,36],[175,58]]]]],[],1,null,["loc",[null,[175,28],[177,37]]]],
          ["block","each",[["get","item.treatmentPatients",["loc",[null,[181,34],[181,56]]]]],[],2,null,["loc",[null,[181,26],[185,35]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2]
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 207,
                "column": 30
              },
              "end": {
                "line": 207,
                "column": 155
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.customer.firstname",["loc",[null,[207,101],[207,128]]]],
            ["content","item.customer.lastname",["loc",[null,[207,129],[207,155]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 212,
                  "column": 36
                },
                "end": {
                  "line": 212,
                  "column": 136
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","treatment.patient.name",["loc",[null,[212,110],[212,136]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 211,
                "column": 28
              },
              "end": {
                "line": 213,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["block","link-to",["patients.edit",["get","treatment.patient",["loc",[null,[212,63],[212,80]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[212,36],[212,148]]]]
          ],
          locals: ["treatment"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 201,
              "column": 18
            },
            "end": {
              "line": 219,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Aufgenommen");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Tier");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-2/24@desk buttons-2");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element15 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element15, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element15, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element15, [5, 1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.created",["loc",[null,[204,44],[204,56]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[204,30],[204,79]]]],
          ["block","link-to",["customers.edit",["get","item.customer",["loc",[null,[207,58],[207,71]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[207,30],[207,167]]]],
          ["block","each",[["get","item.treatmentPatients",["loc",[null,[211,36],[211,58]]]]],[],1,null,["loc",[null,[211,28],[213,37]]]]
        ],
        locals: ["item"],
        templates: [child0, child1]
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 238,
                "column": 32
              },
              "end": {
                "line": 238,
                "column": 160
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.customer.firstname",["loc",[null,[238,106],[238,133]]]],
            ["content","item.customer.lastname",["loc",[null,[238,134],[238,160]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 243,
                  "column": 36
                },
                "end": {
                  "line": 243,
                  "column": 139
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","treatment.patient.name",["loc",[null,[243,113],[243,139]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 242,
                "column": 28
              },
              "end": {
                "line": 244,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["block","link-to",["patients.edit",["get","treatment.patient.id",["loc",[null,[243,63],[243,83]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[243,36],[243,151]]]]
          ],
          locals: ["treatment"],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 249,
                  "column": 28
                },
                "end": {
                  "line": 251,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[250,30],[250,96]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 248,
                "column": 26
              },
              "end": {
                "line": 252,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["practicemgmt.treatment-details",["get","treatment.id",["loc",[null,[249,72],[249,84]]]],["subexpr","query-params",[],["scrollTo","positions"],["loc",[null,[249,85],[249,120]]]]],["class","icon-button icon-button--default list-action-square"],0,null,["loc",[null,[249,28],[251,40]]]]
          ],
          locals: ["treatment"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 232,
              "column": 20
            },
            "end": {
              "line": 255,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Aufgenommen");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Tier");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-2/24@desk buttons-2");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element14 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element14, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element14, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element14, [5, 1]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element14, [7]),1,1);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.created",["loc",[null,[235,46],[235,58]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[235,32],[235,81]]]],
          ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[238,60],[238,76]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[238,32],[238,172]]]],
          ["block","each",[["get","item.treatmentPatients",["loc",[null,[242,36],[242,58]]]]],[],1,null,["loc",[null,[242,28],[244,37]]]],
          ["block","each",[["get","item.treatmentPatients",["loc",[null,[248,34],[248,56]]]]],[],2,null,["loc",[null,[248,26],[252,35]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2]
      };
    }());
    var child8 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 274,
                "column": 30
              },
              "end": {
                "line": 274,
                "column": 158
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.customer.firstname",["loc",[null,[274,104],[274,131]]]],
            ["content","item.customer.lastname",["loc",[null,[274,132],[274,158]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 279,
                  "column": 36
                },
                "end": {
                  "line": 279,
                  "column": 139
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","treatment.patient.name",["loc",[null,[279,113],[279,139]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 278,
                "column": 28
              },
              "end": {
                "line": 280,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["block","link-to",["patients.edit",["get","treatment.patient.id",["loc",[null,[279,63],[279,83]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[279,36],[279,151]]]]
          ],
          locals: ["treatment"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 268,
              "column": 18
            },
            "end": {
              "line": 286,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Aufgenommen");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Tier");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-2/24@desk buttons-2");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element13 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element13, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element13, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element13, [5, 1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.created",["loc",[null,[271,44],[271,56]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[271,30],[271,79]]]],
          ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[274,58],[274,74]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[274,30],[274,170]]]],
          ["block","each",[["get","item.treatmentPatients",["loc",[null,[278,36],[278,58]]]]],[],1,null,["loc",[null,[278,28],[280,37]]]]
        ],
        locals: ["item"],
        templates: [child0, child1]
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 327,
                  "column": 55
                },
                "end": {
                  "line": 327,
                  "column": 195
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.customerData.firstname",["loc",[null,[327,133],[327,164]]]],
              ["content","item.customerData.lastname",["loc",[null,[327,165],[327,195]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 327,
                "column": 30
              },
              "end": {
                "line": 327,
                "column": 207
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["customers.edit",["get","item.customerData.id",["loc",[null,[327,83],[327,103]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[327,55],[327,207]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 327,
                "column": 207
              },
              "end": {
                "line": 327,
                "column": 225
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Barverkauf");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 333,
                "column": 24
              },
              "end": {
                "line": 336,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[335,26],[335,92]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 321,
              "column": 18
            },
            "end": {
              "line": 339,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right l-12/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Summe");
          var el3 = dom.createTextNode("\n                         ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-4/24@desk buttons-2");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element12 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element12, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element12, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element12, [5, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element12, [7]),1,1);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.created",["loc",[null,[324,44],[324,56]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[324,30],[324,79]]]],
          ["block","if",[["get","item.customerData",["loc",[null,[327,36],[327,53]]]]],[],0,1,["loc",[null,[327,30],[327,232]]]],
          ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","item.totalGross",["loc",[null,[330,75],[330,90]]]]],[],[]]],["loc",[null,[330,31],[330,92]]]],
          ["block","link-to",["invoices.create",["get","item.id",["loc",[null,[333,53],[333,60]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[333,61],[333,88]]]]],["class","icon-button icon-button--default list-action-square"],2,null,["loc",[null,[333,24],[336,36]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2]
      };
    }());
    var child10 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 362,
                  "column": 51
                },
                "end": {
                  "line": 362,
                  "column": 179
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.customer.firstname",["loc",[null,[362,125],[362,152]]]],
              ["content","item.customer.lastname",["loc",[null,[362,153],[362,179]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 362,
                "column": 30
              },
              "end": {
                "line": 362,
                "column": 191
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[362,79],[362,95]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[362,51],[362,191]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 362,
                "column": 191
              },
              "end": {
                "line": 362,
                "column": 209
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Barverkauf");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 368,
                "column": 24
              },
              "end": {
                "line": 371,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-view",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[370,26],[370,92]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 353,
              "column": 18
            },
            "end": {
              "line": 374,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-4/24@desk");
          dom.setAttribute(el2,"data-label","Re-NR");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-12/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right l-12/24 l-4/24@desk");
          dom.setAttribute(el2,"data-label","Summe");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-4/24@desk buttons-2");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element11, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element11, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element11, [5, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element11, [7, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element11, [9]),1,1);
          return morphs;
        },
        statements: [
          ["content","item.code",["loc",[null,[356,30],[356,43]]]],
          ["inline","time-format",[["get","item.created",["loc",[null,[359,44],[359,56]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[359,30],[359,79]]]],
          ["block","if",[["get","item.customer",["loc",[null,[362,36],[362,49]]]]],[],0,1,["loc",[null,[362,30],[362,216]]]],
          ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","item.total",["loc",[null,[365,74],[365,84]]]]],[],[]]],["loc",[null,[365,30],[365,86]]]],
          ["block","link-to",["invoices.show",["get","item.id",["loc",[null,[368,51],[368,58]]]]],["class","icon-button icon-button--default list-action-square"],2,null,["loc",[null,[368,24],[371,36]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2]
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 384,
              "column": 28
            },
            "end": {
              "line": 384,
              "column": 64
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Erinnerungen");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 408,
              "column": 18
            },
            "end": {
              "line": 414,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center 24/24");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Keine Erinnerungen gefunden");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 420,
                    "column": 71
                  },
                  "end": {
                    "line": 422,
                    "column": 34
                  }
                },
                "moduleName": "jason-frontend/templates/welcome/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small is-important");
                var el2 = dom.createTextNode("(");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                          )");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","get-days-between-improved",[["get","item.nextExecution",["loc",[null,[421,86],[421,104]]]],"seit"],[],["loc",[null,[421,58],[421,113]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 422,
                    "column": 34
                  },
                  "end": {
                    "line": 423,
                    "column": 32
                  }
                },
                "moduleName": "jason-frontend/templates/welcome/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small");
                var el2 = dom.createTextNode("(");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        )");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
                return morphs;
              },
              statements: [
                ["inline","get-days-between-improved",[["get","item.nextExecution",["loc",[null,[422,91],[422,109]]]],"in"],[],["loc",[null,[422,63],[422,116]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 420,
                  "column": 44
                },
                "end": {
                  "line": 423,
                  "column": 39
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.overdue",["loc",[null,[420,77],[420,89]]]]],[],0,1,["loc",[null,[420,71],[423,39]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 419,
                "column": 101
              },
              "end": {
                "line": 423,
                "column": 46
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","not",[["get","item.executed",["loc",[null,[420,55],[420,68]]]]],[],["loc",[null,[420,50],[420,69]]]]],[],0,null,["loc",[null,[420,44],[423,46]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 426,
                "column": 28
              },
              "end": {
                "line": 427,
                "column": 109
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","button-warning",[],["size","25","showStroke",true,"color","#EF5F4E","content","Fälligkeit bereits überschritten"],["loc",[null,[426,48],[427,109]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 428,
                "column": 30
              },
              "end": {
                "line": 430,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-small");
            var el2 = dom.createTextNode("Serie");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 430,
                "column": 24
              },
              "end": {
                "line": 432,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-small");
            var el2 = dom.createTextNode("Einmalig");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                        ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 437,
                    "column": 29
                  },
                  "end": {
                    "line": 439,
                    "column": 29
                  }
                },
                "moduleName": "jason-frontend/templates/welcome/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                               ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","info-badge info-badge-small");
                var el2 = dom.createTextNode("verschickt");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 439,
                    "column": 29
                  },
                  "end": {
                    "line": 441,
                    "column": 29
                  }
                },
                "moduleName": "jason-frontend/templates/welcome/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                               ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","info-badge info-badge-small");
                var el2 = dom.createTextNode("offen");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 436,
                  "column": 27
                },
                "end": {
                  "line": 443,
                  "column": 71
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                             ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","sub block small mt5");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                               / ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" mal");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [2]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(element6,0,0);
              morphs[2] = dom.createMorphAt(element6,2,2);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.finished",["loc",[null,[437,35],[437,48]]]]],[],0,1,["loc",[null,[437,29],[441,36]]]],
              ["content","item.timeUnitIterationsDone",["loc",[null,[442,63],[442,94]]]],
              ["content","item.timeUnitIterations",["loc",[null,[443,33],[443,60]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 443,
                  "column": 71
                },
                "end": {
                  "line": 445,
                  "column": 27
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                             ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge info-badge-small");
              var el2 = dom.createTextNode("offen");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 435,
                "column": 31
              },
              "end": {
                "line": 446,
                "column": 25
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("                         ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","item.triggerType",["loc",[null,[436,37],[436,53]]]],"multitime"],[],["loc",[null,[436,33],[436,66]]]]],[],0,1,["loc",[null,[436,27],[445,34]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 446,
                "column": 25
              },
              "end": {
                "line": 447,
                "column": 25
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-small");
            var el2 = dom.createTextNode("offen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 451,
                  "column": 51
                },
                "end": {
                  "line": 451,
                  "column": 179
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.customer.firstname",["loc",[null,[451,125],[451,152]]]],
              ["content","item.customer.lastname",["loc",[null,[451,153],[451,179]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 451,
                "column": 30
              },
              "end": {
                "line": 451,
                "column": 191
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[451,79],[451,95]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[451,51],[451,191]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child7 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 452,
                  "column": 64
                },
                "end": {
                  "line": 452,
                  "column": 157
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.patient.name",["loc",[null,[452,136],[452,157]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 452,
                "column": 44
              },
              "end": {
                "line": 452,
                "column": 169
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["patients.edit",["get","item.patient.id",["loc",[null,[452,91],[452,106]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[452,64],[452,169]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 415,
              "column": 18
            },
            "end": {
              "line": 455,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 table__cell--center l-5/24@desk");
          dom.setAttribute(el2,"data-label","Fällig");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","sub block");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Uhr");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-12/24 l-3/24@desk");
          dom.setAttribute(el2,"data-label","Typ");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 table__cell--center l-6/24@desk");
          dom.setAttribute(el2,"data-label","Status");
          var el3 = dom.createTextNode("\n                         ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                         ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24  l-9/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","block");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var element8 = dom.childAt(element7, [1]);
          var element9 = dom.childAt(element7, [3]);
          var element10 = dom.childAt(element7, [7]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element8, [3]),0,0);
          morphs[2] = dom.createMorphAt(element8,4,4);
          morphs[3] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element7, [5, 1]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
          morphs[7] = dom.createMorphAt(dom.childAt(element10, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.nextExecution",["loc",[null,[418,44],[418,62]]]],"DD. MM. YYYY"],[],["loc",[null,[418,30],[418,79]]]],
          ["inline","time-format",[["get","item.nextExecution",["loc",[null,[419,62],[419,80]]]],"HH:mm"],[],["loc",[null,[419,48],[419,90]]]],
          ["block","if",[["get","item.nextExecution",["loc",[null,[420,24],[420,42]]]]],[],0,null,["loc",[null,[419,101],[423,53]]]],
          ["block","if",[["get","item.overdue",["loc",[null,[426,34],[426,46]]]]],[],1,null,["loc",[null,[426,28],[427,116]]]],
          ["block","if",[["subexpr","eq",[["get","item.triggerType",["loc",[null,[428,40],[428,56]]]],"multitime"],[],["loc",[null,[428,36],[428,69]]]]],[],2,3,["loc",[null,[428,30],[432,31]]]],
          ["block","if",[["get","item.executed",["loc",[null,[435,37],[435,50]]]]],[],4,5,["loc",[null,[435,31],[447,32]]]],
          ["block","if",[["get","item.customer",["loc",[null,[451,36],[451,49]]]]],[],6,null,["loc",[null,[451,30],[451,198]]]],
          ["block","if",[["get","item.patient",["loc",[null,[452,50],[452,62]]]]],[],7,null,["loc",[null,[452,44],[452,176]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7]
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 468,
              "column": 18
            },
            "end": {
              "line": 474,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center 24/24");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Keine Erinnerungen gefunden");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 481,
                    "column": 71
                  },
                  "end": {
                    "line": 483,
                    "column": 34
                  }
                },
                "moduleName": "jason-frontend/templates/welcome/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small is-important");
                var el2 = dom.createTextNode("(");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                          )");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","get-days-between-improved",[["get","item.nextExecution",["loc",[null,[482,86],[482,104]]]],"seit"],[],["loc",[null,[482,58],[482,113]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 483,
                    "column": 34
                  },
                  "end": {
                    "line": 484,
                    "column": 32
                  }
                },
                "moduleName": "jason-frontend/templates/welcome/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small");
                var el2 = dom.createTextNode("(");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        )");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
                return morphs;
              },
              statements: [
                ["inline","get-days-between-improved",[["get","item.nextExecution",["loc",[null,[483,91],[483,109]]]],"in"],[],["loc",[null,[483,63],[483,116]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 481,
                  "column": 44
                },
                "end": {
                  "line": 484,
                  "column": 39
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.overdue",["loc",[null,[481,77],[481,89]]]]],[],0,1,["loc",[null,[481,71],[484,39]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 480,
                "column": 101
              },
              "end": {
                "line": 484,
                "column": 46
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","not",[["get","item.executed",["loc",[null,[481,55],[481,68]]]]],[],["loc",[null,[481,50],[481,69]]]]],[],0,null,["loc",[null,[481,44],[484,46]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 485,
                "column": 24
              },
              "end": {
                "line": 486,
                "column": 107
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","small");
            var el2 = dom.createTextNode("Serie, alle ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element1,1,1);
            morphs[1] = dom.createMorphAt(element1,3,3);
            return morphs;
          },
          statements: [
            ["content","item.timeUnitInterval",["loc",[null,[486,52],[486,77]]]],
            ["content","item.timeUnit.name",["loc",[null,[486,78],[486,100]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 489,
                "column": 28
              },
              "end": {
                "line": 490,
                "column": 109
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","button-warning",[],["size","25","showStroke",true,"color","#EF5F4E","content","Fälligkeit bereits überschritten"],["loc",[null,[489,48],[490,109]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 491,
                "column": 30
              },
              "end": {
                "line": 493,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-small");
            var el2 = dom.createTextNode("Serie");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 493,
                "column": 24
              },
              "end": {
                "line": 495,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-small");
            var el2 = dom.createTextNode("Einmalig");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                        ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 500,
                  "column": 27
                },
                "end": {
                  "line": 501,
                  "column": 69
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","sub block small mt5");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                             / ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" mal");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [0]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element0,0,0);
              morphs[1] = dom.createMorphAt(element0,2,2);
              return morphs;
            },
            statements: [
              ["content","item.timeUnitIterationsDone",["loc",[null,[500,102],[500,133]]]],
              ["content","item.timeUnitIterations",["loc",[null,[501,31],[501,58]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 498,
                "column": 31
              },
              "end": {
                "line": 502,
                "column": 25
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                           ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-small");
            var el2 = dom.createTextNode("verschickt");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                           ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                         ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","item.triggerType",["loc",[null,[500,37],[500,53]]]],"multitime"],[],["loc",[null,[500,33],[500,66]]]]],[],0,null,["loc",[null,[500,27],[501,76]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 502,
                "column": 25
              },
              "end": {
                "line": 503,
                "column": 25
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-small");
            var el2 = dom.createTextNode("offen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                         ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child7 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 506,
                  "column": 51
                },
                "end": {
                  "line": 506,
                  "column": 179
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.customer.firstname",["loc",[null,[506,125],[506,152]]]],
              ["content","item.customer.lastname",["loc",[null,[506,153],[506,179]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 506,
                "column": 30
              },
              "end": {
                "line": 506,
                "column": 191
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[506,79],[506,95]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[506,51],[506,191]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child8 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 507,
                  "column": 64
                },
                "end": {
                  "line": 507,
                  "column": 157
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.patient.name",["loc",[null,[507,136],[507,157]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 507,
                "column": 44
              },
              "end": {
                "line": 507,
                "column": 169
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["patients.edit",["get","item.patient.id",["loc",[null,[507,91],[507,106]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[507,64],[507,169]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 476,
              "column": 18
            },
            "end": {
              "line": 510,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 table__cell--center l-5/24@desk");
          dom.setAttribute(el2,"data-label","Versendet");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","sub block");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Uhr");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-12/24 l-3/24@desk");
          dom.setAttribute(el2,"data-label","Typ");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 table__cell--center l-6/24@desk");
          dom.setAttribute(el2,"data-label","Status");
          var el3 = dom.createTextNode("\n                         ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24  l-9/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","block");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element2, [3]);
          var element5 = dom.childAt(element2, [7]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
          morphs[2] = dom.createMorphAt(element3,4,4);
          morphs[3] = dom.createMorphAt(element3,6,6);
          morphs[4] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element2, [5, 1]),0,0);
          morphs[7] = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
          morphs[8] = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.lastExecution",["loc",[null,[479,44],[479,62]]]],"DD. MM. YYYY"],[],["loc",[null,[479,30],[479,79]]]],
          ["inline","time-format",[["get","item.lastExecution",["loc",[null,[480,62],[480,80]]]],"HH:mm"],[],["loc",[null,[480,48],[480,90]]]],
          ["block","if",[["get","item.nextExecution",["loc",[null,[481,24],[481,42]]]]],[],0,null,["loc",[null,[480,101],[484,53]]]],
          ["block","if",[["subexpr","eq",[["get","item.triggerType.id",["loc",[null,[485,34],[485,53]]]],"multitime"],[],["loc",[null,[485,30],[485,66]]]]],[],1,null,["loc",[null,[485,24],[486,114]]]],
          ["block","if",[["get","item.overdue",["loc",[null,[489,34],[489,46]]]]],[],2,null,["loc",[null,[489,28],[490,116]]]],
          ["block","if",[["subexpr","eq",[["get","item.triggerType",["loc",[null,[491,40],[491,56]]]],"multitime"],[],["loc",[null,[491,36],[491,69]]]]],[],3,4,["loc",[null,[491,30],[495,31]]]],
          ["block","if",[["get","item.executed",["loc",[null,[498,37],[498,50]]]]],[],5,6,["loc",[null,[498,31],[503,32]]]],
          ["block","if",[["get","item.customer",["loc",[null,[506,36],[506,49]]]]],[],7,null,["loc",[null,[506,30],[506,198]]]],
          ["block","if",[["get","item.patient",["loc",[null,[507,50],[507,62]]]]],[],8,null,["loc",[null,[507,44],[507,176]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 535,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/welcome/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","welcomeDashboard");
        dom.setAttribute(el1,"class","dashboard");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","grid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","card u-text--center mb20");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card-header h-90px align-items-start border-0 pt-5");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","card-title d-flex flex-column");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-center");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"style","font-size: 1rem;padding-right: 10px");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"style","font-size: 2.2rem !important;");
        dom.setAttribute(el8,"class","is-highlighted");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"style","padding-left: 20px;font-size: 1rem");
        var el9 = dom.createTextNode("in Praxis");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card-body");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex flex-stack");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","text-gray-700 fw-semibold fs-6 me-2");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-senter");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","text-gray-900 fw-bolder fs-6");
        dom.setAttribute(el8,"style","font-size: 1rem !important;");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","separator separator-dashed my-3");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex flex-stack");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","text-gray-700 fw-semibold fs-6 me-2");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-senter");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","text-gray-900 fw-bolder fs-6");
        dom.setAttribute(el8,"style","font-size: 1rem !important;");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","card u-text--center mb20");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card-header h-90px align-items-start border-0 pt-5");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","card-title d-flex flex-column");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-center cursor");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"style","font-size: 1rem;padding-right: 10px");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"style","font-size: 2.2rem !important;");
        dom.setAttribute(el8,"class","is-highlighted");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"style","padding-left: 20px;font-size: 1rem");
        var el9 = dom.createTextNode("Behandlungen heute");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card-body");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex flex-stack cursor");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","text-gray-700 fw-semibold fs-6 me-2");
        var el8 = dom.createTextNode("Laufende Woche");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-senter");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","text-gray-900 fw-bolder fs-6");
        dom.setAttribute(el8,"style","font-size: 1rem !important;");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","separator separator-dashed my-3");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex flex-stack cursor");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","text-gray-700 fw-semibold fs-6 me-2");
        var el8 = dom.createTextNode("Laufendes Monat");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-senter");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","text-gray-900 fw-bolder fs-6");
        dom.setAttribute(el8,"style","font-size: 1rem !important;");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","updated");
        var el7 = dom.createTextNode("Stand: ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","card u-text--center mb20");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card-header h-90px align-items-start border-0 pt-5");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","card-title d-flex flex-column");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-center cursor");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"style","font-size: 1rem;padding-right: 10px");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"style","font-size: 2.2rem !important;");
        dom.setAttribute(el8,"class","is-highlighted");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"style","padding-left: 20px;font-size: 1rem");
        var el9 = dom.createTextNode("Kundentermine heute");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card-body");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex flex-stack");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","text-gray-700 fw-semibold fs-6 me-2 cursor");
        var el8 = dom.createTextNode("Laufende Woche");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-senter");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","text-gray-900 fw-bolder fs-6");
        dom.setAttribute(el8,"style","font-size: 1rem !important;");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","separator separator-dashed my-3");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex flex-stack");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","text-gray-700 fw-semibold fs-6 me-2 cursor");
        var el8 = dom.createTextNode("Laufendes Monat");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-senter");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","text-gray-900 fw-bolder fs-6");
        dom.setAttribute(el8,"style","font-size: 1rem !important;");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","updated");
        var el7 = dom.createTextNode("Stand: ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","card mb20");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Kunden");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","small mb10");
        var el6 = dom.createTextNode("Monatsvergleich");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"id","customerBarChart");
        dom.setAttribute(el5,"style","height: 200px; margin: 0 auto");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-24/24 l-12/24@desk gutter ");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","card");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("In meiner Praxis");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","small mb10");
        var el6 = dom.createTextNode("Übersicht");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","js-tabs tabs");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","tabs__head");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ul");
        dom.setAttribute(el7,"class","u-mb0 clearfix");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9,"href","#tab-practice-upcoming");
        var el10 = dom.createTextNode("Demnächst");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9,"href","#tab-practice-waiting");
        var el10 = dom.createTextNode("Wartezimmer");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9,"href","#tab-practice-treatment");
        var el10 = dom.createTextNode("Behandlung");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9,"href","#tab-practice-payment");
        var el10 = dom.createTextNode("Bezahlen");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        dom.setAttribute(el6,"class","tabs__body mt20");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-practice-upcoming");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table grid");
        dom.setAttribute(el8,"style","overflow-y: auto;max-height: 450px;");
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__head");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-6/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Termin");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-8/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Kund:in");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-8/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Tier");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-2/24");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__body");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-practice-waiting");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table grid");
        dom.setAttribute(el8,"style","overflow-y: auto;max-height: 450px;");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__head");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-6/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Aufgenommen");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-8/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Kund:in");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-8/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Tier");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-2/24");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__body");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-practice-treatment");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table grid");
        dom.setAttribute(el8,"style","overflow-y: auto;max-height: 450px;");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__head");
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-6/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Aufgenommen");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-8/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Kund:in");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-8/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Tier");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-2/24");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__body");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-practice-payment");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table grid");
        dom.setAttribute(el8,"style","overflow-y: auto;max-height: 450px;");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__head");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-6/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Aufgenommen");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-8/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Kund:in");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-8/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Tier");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-2/24");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__body");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-24/24 l-12/24@desk gutter ");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","card");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Ausgangsrechnung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","small mb10");
        var el6 = dom.createTextNode("Übersicht");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","js-tabs tabs");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","tabs__head");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ul");
        dom.setAttribute(el7,"class","u-mb0 clearfix");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9,"href","#tab-invoice-open");
        var el10 = dom.createTextNode("Offen");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9,"href","#tab-invoice-recent");
        var el10 = dom.createTextNode("Kürzlich abgeschlossen");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        dom.setAttribute(el6,"class","tabs__body mt20");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-invoice-open");
        var el8 = dom.createTextNode("\n\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table grid");
        dom.setAttribute(el8,"style","overflow-y: auto;max-height: 450px;");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__head");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-6/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Datum");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-8/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Kund:in");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell table__cell--right l-6/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Summe");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-4/24");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__body");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-invoice-recent");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table grid");
        dom.setAttribute(el8,"style","overflow-y: auto;max-height: 450px;");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__head");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-4/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("RE-Nr");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-6/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Datum");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-6/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Kund:in");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell table__cell--right l-4/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Summe");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-4/24");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__body");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-24/24 l-12/24@desk gutter ");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","card");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","small mb10");
        var el6 = dom.createTextNode("Übersicht");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","js-tabs tabs");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","tabs__head");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ul");
        dom.setAttribute(el7,"class","u-mb0 clearfix");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9,"href","#tab-reminder-past");
        var el10 = dom.createTextNode("Kürzlich versendet");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9,"href","#tab-reminder-upcoming");
        var el10 = dom.createTextNode("Demnächst");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        dom.setAttribute(el6,"class","tabs__body mt20");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-reminder-upcoming");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table grid");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__head");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell table__cell--center l-5/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Fällig");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell table__cell--center l-3/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Typ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell table__cell--center l-6/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Status");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-9/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Kund:in");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__body");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-reminder-past");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table grid");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__head");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell table__cell--center l-5/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Versendet");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell table__cell--center  l-3/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Typ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell table__cell--center l-6/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Status");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-9/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Kund:in");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__body");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element23 = dom.childAt(fragment, [0, 1]);
        var element24 = dom.childAt(element23, [1]);
        var element25 = dom.childAt(element24, [1]);
        var element26 = dom.childAt(element25, [3]);
        var element27 = dom.childAt(element26, [1]);
        var element28 = dom.childAt(element26, [5]);
        var element29 = dom.childAt(element23, [3]);
        var element30 = dom.childAt(element29, [1]);
        var element31 = dom.childAt(element30, [1, 1, 1]);
        var element32 = dom.childAt(element30, [3]);
        var element33 = dom.childAt(element32, [1]);
        var element34 = dom.childAt(element32, [5]);
        var element35 = dom.childAt(element23, [5]);
        var element36 = dom.childAt(element35, [1]);
        var element37 = dom.childAt(element36, [1, 1, 1]);
        var element38 = dom.childAt(element36, [3]);
        var element39 = dom.childAt(element38, [1]);
        var element40 = dom.childAt(element38, [5]);
        var element41 = dom.childAt(element23, [9]);
        var element42 = dom.childAt(element23, [13, 1, 5]);
        var element43 = dom.childAt(element42, [1, 1]);
        var element44 = dom.childAt(element43, [1, 1]);
        var element45 = dom.childAt(element43, [3, 1]);
        var element46 = dom.childAt(element43, [5, 1]);
        var element47 = dom.childAt(element43, [7, 1]);
        var element48 = dom.childAt(element42, [3]);
        var element49 = dom.childAt(element48, [1]);
        var element50 = dom.childAt(element48, [3]);
        var element51 = dom.childAt(element48, [5]);
        var element52 = dom.childAt(element48, [7]);
        var element53 = dom.childAt(element23, [15, 1, 5]);
        var element54 = dom.childAt(element53, [1, 1]);
        var element55 = dom.childAt(element54, [1, 1]);
        var element56 = dom.childAt(element54, [3, 1]);
        var element57 = dom.childAt(element53, [3]);
        var element58 = dom.childAt(element57, [1]);
        var element59 = dom.childAt(element57, [3]);
        var element60 = dom.childAt(element23, [17, 1]);
        var element61 = dom.childAt(element60, [5]);
        var element62 = dom.childAt(element61, [1, 1]);
        var element63 = dom.childAt(element62, [1, 1]);
        var element64 = dom.childAt(element62, [3, 1]);
        var element65 = dom.childAt(element61, [3]);
        var element66 = dom.childAt(element65, [1]);
        var element67 = dom.childAt(element66, [1, 3]);
        var element68 = dom.childAt(element65, [3]);
        var element69 = dom.childAt(element68, [1, 3]);
        var morphs = new Array(62);
        morphs[0] = dom.createAttrMorph(element24, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element25, [1, 1, 1, 3]),0,0);
        morphs[2] = dom.createMorphAt(dom.childAt(element27, [1]),0,0);
        morphs[3] = dom.createMorphAt(dom.childAt(element27, [3, 0]),0,0);
        morphs[4] = dom.createMorphAt(dom.childAt(element28, [1]),0,0);
        morphs[5] = dom.createMorphAt(dom.childAt(element28, [3, 0]),0,0);
        morphs[6] = dom.createAttrMorph(element29, 'class');
        morphs[7] = dom.createElementMorph(element31);
        morphs[8] = dom.createMorphAt(dom.childAt(element31, [3]),0,0);
        morphs[9] = dom.createElementMorph(element33);
        morphs[10] = dom.createMorphAt(dom.childAt(element33, [3, 0]),0,0);
        morphs[11] = dom.createElementMorph(element34);
        morphs[12] = dom.createMorphAt(dom.childAt(element34, [3, 0]),0,0);
        morphs[13] = dom.createMorphAt(dom.childAt(element32, [7]),1,1);
        morphs[14] = dom.createAttrMorph(element35, 'class');
        morphs[15] = dom.createElementMorph(element37);
        morphs[16] = dom.createMorphAt(dom.childAt(element37, [3]),0,0);
        morphs[17] = dom.createElementMorph(element39);
        morphs[18] = dom.createMorphAt(dom.childAt(element39, [3, 0]),0,0);
        morphs[19] = dom.createElementMorph(element40);
        morphs[20] = dom.createMorphAt(dom.childAt(element40, [3, 0]),0,0);
        morphs[21] = dom.createMorphAt(dom.childAt(element38, [7]),1,1);
        morphs[22] = dom.createMorphAt(element23,7,7);
        morphs[23] = dom.createAttrMorph(element41, 'class');
        morphs[24] = dom.createMorphAt(dom.childAt(element41, [1]),5,5);
        morphs[25] = dom.createMorphAt(element23,11,11);
        morphs[26] = dom.createAttrMorph(element44, 'class');
        morphs[27] = dom.createElementMorph(element44);
        morphs[28] = dom.createAttrMorph(element45, 'class');
        morphs[29] = dom.createElementMorph(element45);
        morphs[30] = dom.createAttrMorph(element46, 'class');
        morphs[31] = dom.createElementMorph(element46);
        morphs[32] = dom.createAttrMorph(element47, 'class');
        morphs[33] = dom.createElementMorph(element47);
        morphs[34] = dom.createAttrMorph(element49, 'class');
        morphs[35] = dom.createMorphAt(dom.childAt(element49, [1, 3]),1,1);
        morphs[36] = dom.createAttrMorph(element50, 'class');
        morphs[37] = dom.createMorphAt(dom.childAt(element50, [1, 3]),1,1);
        morphs[38] = dom.createAttrMorph(element51, 'class');
        morphs[39] = dom.createMorphAt(dom.childAt(element51, [1, 3]),1,1);
        morphs[40] = dom.createAttrMorph(element52, 'class');
        morphs[41] = dom.createMorphAt(dom.childAt(element52, [1, 3]),1,1);
        morphs[42] = dom.createAttrMorph(element55, 'class');
        morphs[43] = dom.createElementMorph(element55);
        morphs[44] = dom.createAttrMorph(element56, 'class');
        morphs[45] = dom.createElementMorph(element56);
        morphs[46] = dom.createAttrMorph(element58, 'class');
        morphs[47] = dom.createMorphAt(dom.childAt(element58, [1, 3]),1,1);
        morphs[48] = dom.createAttrMorph(element59, 'class');
        morphs[49] = dom.createMorphAt(dom.childAt(element59, [1, 3]),1,1);
        morphs[50] = dom.createMorphAt(dom.childAt(element60, [1]),0,0);
        morphs[51] = dom.createAttrMorph(element63, 'class');
        morphs[52] = dom.createElementMorph(element63);
        morphs[53] = dom.createAttrMorph(element64, 'class');
        morphs[54] = dom.createElementMorph(element64);
        morphs[55] = dom.createAttrMorph(element66, 'class');
        morphs[56] = dom.createMorphAt(element67,1,1);
        morphs[57] = dom.createMorphAt(element67,2,2);
        morphs[58] = dom.createAttrMorph(element68, 'class');
        morphs[59] = dom.createMorphAt(element69,1,1);
        morphs[60] = dom.createMorphAt(element69,3,3);
        morphs[61] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["col l-24/24 l-12/24@tablet l-",["subexpr","css-bool-evaluator",[["subexpr","and",[["subexpr","show-prices",[],[],["loc",[null,[3,71],[3,84]]]],["subexpr","has-permission",["r_sale_dashboards"],[],["loc",[null,[3,85],[3,121]]]]],[],["loc",[null,[3,66],[3,122]]]],"6","8"],[],["loc",[null,[3,45],[3,132]]]],"/24@desk gutter kpi-tile"]]],
        ["content","liveData.inPractice",["loc",[null,[9,81],[9,104]]]],
        ["block","link-to",["appointments.calendar"],[],0,null,["loc",[null,[16,61],[16,125]]]],
        ["content","liveData.upcoming",["loc",[null,[17,130],[17,151]]]],
        ["block","link-to",["practicemgmt.station"],[],1,null,["loc",[null,[21,61],[21,125]]]],
        ["content","liveData.inStation",["loc",[null,[22,130],[22,152]]]],
        ["attribute","class",["concat",["col  l-24/24 l-12/24@tablet  l-",["subexpr","css-bool-evaluator",[["subexpr","and",[["subexpr","show-prices",[],[],["loc",[null,[27,73],[27,86]]]],["subexpr","has-permission",["r_sale_dashboards"],[],["loc",[null,[27,87],[27,123]]]]],[],["loc",[null,[27,68],[27,124]]]],"6","8"],[],["loc",[null,[27,47],[27,134]]]],"/24@desk gutter  kpi-tile"]]],
        ["element","action",["gotoTreatmentJournal"],[],["loc",[null,[31,58],[31,91]]]],
        ["content","treatmentData.today",["loc",[null,[33,81],[33,104]]]],
        ["element","action",["gotoTreatmentJournal"],[],["loc",[null,[39,48],[39,81]]]],
        ["content","treatmentData.week",["loc",[null,[41,130],[41,152]]]],
        ["element","action",["gotoTreatmentJournal"],[],["loc",[null,[44,48],[44,81]]]],
        ["content","treatmentData.month",["loc",[null,[46,130],[46,153]]]],
        ["inline","time-format",[["get","treatmentData.lastUpdate",["loc",[null,[49,52],[49,76]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[49,38],[49,99]]]],
        ["attribute","class",["concat",["col  l-24/24 l-12/24@tablet  l-",["subexpr","css-bool-evaluator",[["subexpr","and",[["subexpr","show-prices",[],[],["loc",[null,[54,73],[54,86]]]],["subexpr","has-permission",["r_sale_dashboards"],[],["loc",[null,[54,87],[54,123]]]]],[],["loc",[null,[54,68],[54,124]]]],"6","8"],[],["loc",[null,[54,47],[54,134]]]],"/24@desk gutter kpi-tile "]]],
        ["element","action",["gotoCalendar"],[],["loc",[null,[58,58],[58,83]]]],
        ["content","appointmentData.today",["loc",[null,[60,81],[60,106]]]],
        ["element","action",["gotoCalendar"],[],["loc",[null,[66,41],[66,66]]]],
        ["content","appointmentData.week",["loc",[null,[68,130],[68,154]]]],
        ["element","action",["gotoCalendar"],[],["loc",[null,[71,41],[71,66]]]],
        ["content","appointmentData.month",["loc",[null,[73,130],[73,155]]]],
        ["inline","time-format",[["get","appointmentData.lastUpdate",["loc",[null,[76,52],[76,78]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[76,38],[76,101]]]],
        ["block","if",[["subexpr","and",[["subexpr","show-prices",[],[],["loc",[null,[81,15],[81,28]]]],["subexpr","has-permission",["r_sale_dashboards"],[],["loc",[null,[81,29],[81,65]]]]],[],["loc",[null,[81,10],[81,66]]]]],[],2,null,["loc",[null,[81,4],[110,11]]]],
        ["attribute","class",["concat",["col l-24/24 l-",["subexpr","css-bool-evaluator",[["subexpr","and",[["subexpr","show-prices",[],[],["loc",[null,[111,56],[111,69]]]],["subexpr","has-permission",["r_sale_dashboards"],[],["loc",[null,[111,70],[111,106]]]]],[],["loc",[null,[111,51],[111,107]]]],"12","24"],[],["loc",[null,[111,30],[111,119]]]],"/24@desk gutter"]]],
        ["block","if",[["get","hideCustomerChart",["loc",[null,[115,14],[115,31]]]]],[],3,null,["loc",[null,[115,8],[117,15]]]],
        ["block","if",[["subexpr","and",[["subexpr","show-prices",[],[],["loc",[null,[121,11],[121,24]]]],["subexpr","has-permission",["r_sale_dashboards"],[],["loc",[null,[121,25],[121,61]]]]],[],["loc",[null,[121,6],[121,62]]]]],[],4,null,["loc",[null,[121,0],[132,7]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabPractice",["loc",[null,[142,84],[142,103]]]],"upcoming"],[],["loc",[null,[142,80],[142,115]]]],"is-active",""],[],["loc",[null,[142,59],[142,132]]]]]]],
        ["element","action",["selectTabPractice","upcoming"],[],["loc",[null,[142,164],[142,205]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabPractice",["loc",[null,[145,84],[145,103]]]],"in_waitingroom"],[],["loc",[null,[145,80],[145,121]]]],"is-active",""],[],["loc",[null,[145,59],[145,138]]]]]]],
        ["element","action",["selectTabPractice","in_waitingroom"],[],["loc",[null,[145,169],[145,216]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabPractice",["loc",[null,[148,84],[148,103]]]],"in_treatment"],[],["loc",[null,[148,80],[148,119]]]],"is-active",""],[],["loc",[null,[148,59],[148,136]]]]]]],
        ["element","action",["selectTabPractice","in_treatment"],[],["loc",[null,[148,169],[148,214]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabPractice",["loc",[null,[151,84],[151,103]]]],"ready_for_payment"],[],["loc",[null,[151,80],[151,124]]]],"is-active",""],[],["loc",[null,[151,59],[151,141]]]]]]],
        ["element","action",["selectTabPractice","ready_for_payment"],[],["loc",[null,[151,172],[151,222]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabPractice",["loc",[null,[156,72],[156,91]]]],"upcoming"],[],["loc",[null,[156,68],[156,103]]]],"is-active",""],[],["loc",[null,[156,47],[156,120]]]]]]],
        ["block","each",[["get","practiceItems",["loc",[null,[165,28],[165,41]]]]],[],5,null,["loc",[null,[165,20],[188,29]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabPractice",["loc",[null,[192,70],[192,89]]]],"in_waitingroom"],[],["loc",[null,[192,66],[192,107]]]],"is-active",""],[],["loc",[null,[192,45],[192,124]]]]]]],
        ["block","each",[["get","practiceItems",["loc",[null,[201,26],[201,39]]]]],[],6,null,["loc",[null,[201,18],[219,27]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabPractice",["loc",[null,[223,72],[223,91]]]],"in_treatment"],[],["loc",[null,[223,68],[223,107]]]],"is-active",""],[],["loc",[null,[223,47],[223,124]]]]]]],
        ["block","each",[["get","practiceItems",["loc",[null,[232,28],[232,41]]]]],[],7,null,["loc",[null,[232,20],[255,29]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabPractice",["loc",[null,[259,70],[259,89]]]],"ready4payment"],[],["loc",[null,[259,66],[259,106]]]],"is-active",""],[],["loc",[null,[259,45],[259,123]]]]]]],
        ["block","each",[["get","practiceItems",["loc",[null,[268,26],[268,39]]]]],[],8,null,["loc",[null,[268,18],[286,27]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabInvoices",["loc",[null,[303,84],[303,103]]]],"open"],[],["loc",[null,[303,80],[303,111]]]],"is-active",""],[],["loc",[null,[303,59],[303,128]]]]]]],
        ["element","action",["selectTabInvoices","open"],[],["loc",[null,[303,155],[303,192]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabInvoices",["loc",[null,[306,84],[306,103]]]],"recent"],[],["loc",[null,[306,80],[306,113]]]],"is-active",""],[],["loc",[null,[306,59],[306,130]]]]]]],
        ["element","action",["selectTabInvoices","recent"],[],["loc",[null,[306,159],[306,198]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabInvoices",["loc",[null,[311,70],[311,89]]]],"open"],[],["loc",[null,[311,66],[311,97]]]],"is-active",""],[],["loc",[null,[311,45],[311,114]]]]]]],
        ["block","each",[["get","invoiceItems",["loc",[null,[321,26],[321,38]]]]],[],9,null,["loc",[null,[321,18],[339,27]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabInvoices",["loc",[null,[343,70],[343,89]]]],"recent"],[],["loc",[null,[343,66],[343,99]]]],"is-active",""],[],["loc",[null,[343,45],[343,116]]]]]]],
        ["block","each",[["get","invoiceItems",["loc",[null,[353,26],[353,38]]]]],[],10,null,["loc",[null,[353,18],[374,27]]]],
        ["block","link-to",["reminders"],[],11,null,["loc",[null,[384,28],[384,76]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabReminders",["loc",[null,[391,84],[391,104]]]],"past"],[],["loc",[null,[391,80],[391,112]]]],"is-active",""],[],["loc",[null,[391,59],[391,129]]]]]]],
        ["element","action",["selectTabReminders","past"],[],["loc",[null,[391,157],[391,195]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabReminders",["loc",[null,[394,84],[394,104]]]],"upcoming"],[],["loc",[null,[394,80],[394,116]]]],"is-active",""],[],["loc",[null,[394,59],[394,133]]]]]]],
        ["element","action",["selectTabReminders","upcoming"],[],["loc",[null,[394,165],[394,207]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabReminders",["loc",[null,[399,70],[399,90]]]],"upcoming"],[],["loc",[null,[399,66],[399,102]]]],"is-active",""],[],["loc",[null,[399,45],[399,119]]]]]]],
        ["block","if",[["subexpr","eq",[["get","reminderItems.meta.total_records",["loc",[null,[408,28],[408,60]]]],0],[],["loc",[null,[408,24],[408,63]]]]],[],12,null,["loc",[null,[408,18],[414,25]]]],
        ["block","each",[["get","reminderItems",["loc",[null,[415,26],[415,39]]]]],[],13,null,["loc",[null,[415,18],[455,27]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabReminders",["loc",[null,[459,70],[459,90]]]],"past"],[],["loc",[null,[459,66],[459,98]]]],"is-active",""],[],["loc",[null,[459,45],[459,115]]]]]]],
        ["block","if",[["subexpr","eq",[["get","reminderItems.meta.total_records",["loc",[null,[468,28],[468,60]]]],0],[],["loc",[null,[468,24],[468,63]]]]],[],14,null,["loc",[null,[468,18],[474,25]]]],
        ["block","each",[["get","reminderItems",["loc",[null,[476,26],[476,39]]]]],[],15,null,["loc",[null,[476,18],[510,27]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[527,8],[527,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[528,10],[528,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[529,13],[529,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[530,17],[530,36]]]]],[],[]]],["loc",[null,[523,0],[530,38]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15]
    };
  }()));

});