define('jason-frontend/models/reminder', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    patient: DS['default'].attr(''),
    customer: DS['default'].attr(''),
    lastLab: DS['default'].attr(''),
    executed: DS['default'].attr(''),
    triggerType: DS['default'].attr(''),
    timeUnitIterationsDone: DS['default'].attr(''),
    timeUnitIterations: DS['default'].attr(''),
    finished: DS['default'].attr(''),
    lastReminder: DS['default'].attr(''),
    lastTreatment: DS['default'].attr(''),
    lastAppointment: DS['default'].attr(''),
    nextExecution: DS['default'].attr(''),
    lastExecution: DS['default'].attr('')
  });

});