define('jason-frontend/helpers/is-applied-available', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isAppliedAvailable = isAppliedAvailable;

  function isAppliedAvailable(params) {
    var entry = params[0],
        article = entry.get('article');

    if (!article) {
      return false;
    }
    if (article.get('type') === "service") {
      return false;
    }

    if (article) {
      if (entry.get('tgd') == true) {
        return false;
      }
      if (article.get('category')) {
        var category = article.get('category').get('id');
        if (category === 'gut' || category === 'anz') {
          return false;
        }
      }
    }
    return true;
  }

  exports['default'] = Ember['default'].Helper.helper(isAppliedAvailable);

});