define('jason-frontend/helpers/plus', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.plus = plus;

  function plus(params) {
    return Math.round((params[0] + params[1]) * 100) / 100;
  }

  exports['default'] = Ember['default'].Helper.helper(plus);

});