define('jason-frontend/routes/transactions/cashdesk', ['exports', 'ember', 'moment', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, moment, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {
      params.filterMandant = "all";
      params.filterFrom = moment['default']().startOf('month').format('YYYY-MM-DD HH:mm');
      params.filterTo = moment['default']().endOf('month').format('YYYY-MM-DD HH:mm');
      return this.findPaged('cashdesk', params);
    },
    perPage: 20,
    queryParams: {},
    setupController: function setupController(controller) {

      if (!API['default'].hasPermission('r_cashdesk')) {
        this.transitionTo('home');
      }

      controller.set('sortDir', 'DESC');

      this.applyFilter();

      this.store.find('cashRegister').then(function (data) {
        controller.set('cashRegisters', data);
        var register = data.get('firstObject');
        if (API['default'].getPrimaryCashRegisterId() && API['default'].getPrimaryCashRegisterId() > 0) {
          data.forEach(function (item) {
            if (item.id === API['default'].getPrimaryCashRegisterId()) {
              register = item;
            }
          });
        } else if (register.get('active') == false) {
          data.forEach(function (item) {
            if (item.get('active') == true) {
              register = item;
            }
          });
        }
        controller.set('selectedRegister', register);
      });
    },
    actions: {
      changeSortDir: function changeSortDir() {
        var controller = this.get('controller');
        if (controller.get('sortDir') === "ASC") {
          controller.set('sortDir', 'DESC');
        } else {
          controller.set('sortDir', 'ASC');
        }
        this.applyFilter();
      },
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      },
      setFilterType: function setFilterType(type) {
        var controller = this.get('controller');
        controller.set('filterType', type);
        if (type === 'in') {
          controller.set('classFilterTypeIn', "primary");
          controller.set('classFilterTypeAll', "default");
          controller.set('classFilterTypeOut', "default");
        }
        if (type === 'out') {
          controller.set('classFilterTypeIn', "default");
          controller.set('classFilterTypeAll', "default");
          controller.set('classFilterTypeOut', "primary");
        }
        if (type === 'all') {
          controller.set('classFilterTypeIn', "default");
          controller.set('classFilterTypeAll', "primary");
          controller.set('classFilterTypeOut', "default");
        }
        this.applyFilter();
      },
      setPaymentType: function setPaymentType(type) {
        var controller = this.get('controller');
        if (type === 'all') {
          if (controller.filterPaymentAll) {
            controller.set('classFilterPaymentAll', "default");
            controller.set('filterPaymentAll', false);
          } else {
            controller.set('classFilterPaymentAll', "primary");
            controller.set('classFilterPaymentCash', "default");
            controller.set('classFilterPaymentCashcard', "default");
            controller.set('classFilterPaymentCreditcard', "default");
            controller.set('classFilterPaymentVoucher', "default");
            controller.set('classFilterPaymentDeposit', "default");
            controller.set('filterPaymentAll', true);
            controller.set('filterPaymentCash', false);
            controller.set('filterPaymentCashcard', false);
            controller.set('filterPaymentCreditcard', false);
            controller.set('filterPaymentVoucher', false);
            controller.set('filterPaymentDeposit', false);
          }
        }
        if (type === 'cash') {
          if (controller.filterPaymentCash) {
            controller.set('classFilterPaymentCash', "default");
            controller.set('filterPaymentAll', false);
            controller.set('filterPaymentCash', false);
          } else {
            controller.set('classFilterPaymentCash', "primary");
            controller.set('classFilterPaymentAll', "default");
            controller.set('filterPaymentCash', true);
            controller.set('filterPaymentAll', false);
          }
        }
        if (type === 'cashcard') {
          if (controller.filterPaymentCashcard) {
            controller.set('classFilterPaymentCashcard', "default");
            controller.set('filterPaymentAll', false);
            controller.set('filterPaymentCashcard', false);
          } else {
            controller.set('classFilterPaymentAll', "default");
            controller.set('classFilterPaymentCashcard', "primary");
            controller.set('filterPaymentCashcard', true);
            controller.set('filterPaymentAll', false);
          }
        }
        if (type === 'creditcard') {
          if (controller.filterPaymentCreditcard) {
            controller.set('classFilterPaymentCreditcard', "default");
            controller.set('filterPaymentAll', false);
            controller.set('filterPaymentCreditcard', false);
          } else {
            controller.set('classFilterPaymentAll', "default");
            controller.set('classFilterPaymentCreditcard', "primary");
            controller.set('filterPaymentCreditcard', true);
            controller.set('filterPaymentAll', false);
          }
        }
        if (type === 'voucher') {
          if (controller.filterPaymentVoucher) {
            controller.set('classFilterPaymentVoucher', "default");
            controller.set('filterPaymentAll', false);
            controller.set('filterPaymentVoucher', false);
          } else {
            controller.set('classFilterPaymentAll', "default");
            controller.set('classFilterPaymentVoucher', "primary");
            controller.set('filterPaymentVoucher', true);
            controller.set('filterPaymentAll', false);
          }
        }
        if (type === 'deposit') {
          if (controller.filterPaymentDeposit) {
            controller.set('classFilterPaymentDeposit', "default");
            controller.set('filterPaymentAll', false);
            controller.set('filterPaymentDeposit', false);
          } else {
            controller.set('classFilterPaymentAll', "default");
            controller.set('classFilterPaymentDeposit', "primary");
            controller.set('filterPaymentDeposit', true);
            controller.set('filterPaymentAll', false);
          }
        }
        this.applyFilter();
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-'),
          filterType = controller.get('filterType'),
          filterPaymentAll = controller.get('filterPaymentAll'),
          filterPaymentCash = controller.get('filterPaymentCash'),
          filterPaymentCreditcard = controller.get('filterPaymentCreditcard'),
          filterPaymentCashcard = controller.get('filterPaymentCashcard'),
          filterPaymentVoucher = controller.get('filterPaymentVoucher'),
          filterPaymentDeposit = controller.get('filterPaymentDeposit'),
          sortDir = controller.get('sortDir'),
          selectedRegister = controller.get('selectedRegister');

      if (selectedRegister) {
        selectedRegister = selectedRegister.id;
      }

      var from = moment['default'](dateFromTo[0], 'DD. MM. YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
          to = moment['default'](dateFromTo[1], 'DD. MM. YYYY HH:mm').format('YYYY-MM-DD HH:mm');

      this.findPaged('cashdesk', {
        filterFrom: from,
        sortDir: sortDir,
        filterTo: to,
        filterType: filterType,
        filterCashDeskOnly: true,
        filterPaymentAll: filterPaymentAll,
        filterPaymentCash: filterPaymentCash,
        filterPaymentCreditcard: filterPaymentCreditcard,
        filterPaymentCashcard: filterPaymentCashcard,
        filterPaymentVoucher: filterPaymentVoucher,
        filterPaymentDeposit: filterPaymentDeposit,
        selectedRegister: selectedRegister
      }).then(function (transactions) {
        var meta = transactions.meta;
        controller.set('total', meta.total);
        controller.set('paymentSums', meta.paymentSums);
        controller.set('model', transactions);
      });
    }
  });

});