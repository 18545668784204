define('jason-frontend/components/product-import-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    showUploadForm: false,
    currentFile: null,
    showUploadButton: false,
    actions: {
      load: function load(data) {
        this.set('showUploadForm', true);
        this.set('showUploadButton', true);

        $('#modal-product-import-panel img.loading-animation').addClass('hidden');
      },
      uploadDone: function uploadDone(e) {
        this.set('showUploadButton', true);
        this.sendAction('uploadDone');
        $.magnificPopup.close();
      },
      showLoading: function showLoading() {
        this.set('showUploadButton', false);
      }
    }
  });

});