define('jason-frontend/helpers/is-show-ehapo-warning', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isShowEhapoWarning = isShowEhapoWarning;

  function isShowEhapoWarning(params) {
    var referer = params[0],
        fullyBooked = params[1];

    if (fullyBooked) {
      return false;
    }

    if (referer === 'treatment') {
      return API['default'].getEhapoCheckTreatment() === 'warning';
    }
    return API['default'].getEhapoCheckCashdesk() === 'warning';
  }

  exports['default'] = Ember['default'].Helper.helper(isShowEhapoWarning);

});