define('jason-frontend/helpers/get-invoice-heading-selected-class', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getInvoiceHeadingSelectedClass = getInvoiceHeadingSelectedClass;

  function getInvoiceHeadingSelectedClass(params /*, hash*/) {
    var patient = params[0],
        allPatients = params[1];

    if (patient) {
      return '';
    }
    return 'selected';
  }

  exports['default'] = Ember['default'].Helper.helper(getInvoiceHeadingSelectedClass);

});