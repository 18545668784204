define('jason-frontend/controllers/tasks', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    filterOrderStatus: "exported",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    queryParams: ["page", "filterUser"],
    page: 1,
    perPage: 10,
    showLoading: false,
    filterUser: null,
    filterCustomer: null,
    selectTab: 'inbox',
    breadCrumb: "Meine Aufgaben",
    breadCrumbPath: "tasks",
    sortDir: 'desc',
    actions: {
      openNew: function openNew() {
        this.get('addTaskPanel').send('reset');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-task-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      showTask: function showTask(item) {
        this.get('showTaskPanel').send('load', item);
        var self = this;

        $.ajax({
          url: "/api/tasks/" + item.id + "/history",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('showTaskPanel').send('loadHistory', data.taskHistory);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-show-task-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      addMessage: function addMessage(task) {
        var self = this;

        this.store.find('mandant').then(function (data) {
          self.set('mandants', data);

          data.forEach(function (item) {
            if (item.id === API['default'].getMandant()) {
              self.set('mandant', item);
            }
          });
        });

        this.get('addNotificationPanel').send('reset');
        this.get('addNotificationPanel').send('addTaskReference', task);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-notification-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      showNotification: function showNotification(item) {
        this.get('showNotificationPanel').send('load', item);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-show-notification-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      changeOwner: function changeOwner(item) {

        this.get('changeTaskOwnerPanel').send('reset', item, API['default'].getUserId());

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-change-task-owner-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      editTreatment: function editTreatment(item, patient, taskData) {

        this.get('getTreatmentInfoForm').send('load', item, patient, null, null, null, taskData);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#treatment-edit-form'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      finishTask: function finishTask(item) {
        var self = this;

        this.get('finishTaskPanel').send('reset', item);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-finish-task-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    },
    watchDateTask: (function () {
      this.send("applyFilter");
    }).observes('pickDate'),
    watchUserSelection: (function () {
      var self = this;
      this.set('page', 1);

      setTimeout(function () {
        self.send('selectTab');
      }, 500);
    }).observes('filterUser'),
    watchCustomerSelection: (function () {
      var self = this;
      this.set('page', 1);

      if (this.get('filterCustomer')) {

        $.ajax({
          url: "/api/patients/forcustomer/" + this.get('filterCustomer').id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('patients', data.patient);
        });
      } else {
        this.set('filterPatient', null);
        this.set('patients', null);
      }

      setTimeout(function () {
        self.send('selectTab');
      }, 500);
    }).observes('filterCustomer'),
    watchPatientSelection: (function () {
      var self = this;
      this.set('page', 1);

      setTimeout(function () {
        self.send('selectTab');
      }, 500);
    }).observes('filterPatient')
  });

});