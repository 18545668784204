define('jason-frontend/helpers/order-entries-available', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.orderEntriesAvailable = orderEntriesAvailable;

  function orderEntriesAvailable(params) {
    var invoice = params[0];
    if (invoice && invoice.get('subOrders')) {
      return invoice.get('subOrders').length > 0;
    }

    if (invoice) {
      return invoice.get('hasEntries');
    }
    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(orderEntriesAvailable);

});