define('jason-frontend/components/patient-category-filter-button', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    cssClass: 'default',
    actions: {
      selectCategoryFilterOption: function selectCategoryFilterOption(categoryId) {
        this.sendAction('categorySelected', categoryId);
        if (this.get('cssClass') === 'default') {
          this.set('cssClass', 'primary');
        } else {
          this.set('cssClass', 'default');
        }
      }
    }
  });

});