define('jason-frontend/components/sale-charts', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    type: null,
    actions: {
      confirm: function confirm(data) {
        this.sendAction('create', data);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(customerId, userId, userRole) {
        var self = this;
        setTimeout(function () {
          self.show(customerId, userId, userRole);
        }, 500);
      }
    },
    show: function show(customer, userId, userRole) {

      var charts = $('div#revenueChart');

      if (customer) {
        if (typeof customer === 'object') {
          customer = customer.id;
        }
      }
      if (userId) {
        if (typeof userId === 'object') {
          userId = userId.id;
        }
      }

      $.ajax({
        url: "/api/saleDashboards/csvLine?userId=" + userId + "&customerId=" + customer + "&userRole=" + userRole,
        method: "GET",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {

        var csv = data.saleLine.data;

        var lines = csv.split('\n').length;

        if (lines <= 2) {
          charts.hide();
          return;
        }

        charts.show();

        Highcharts.setOptions({
          lang: {
            decimalPoint: ',',
            thousandsSep: '.',
            months: ['Jänner', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
          }
        });

        charts.highcharts({
          colors: ["#7cb5ec", "#f7a35c", "#90ee7e", "#7798BF", "#aaeeee", "#ff0066", "#eeaaee", "#55BF3B", "#DF5353", "#7798BF", "#aaeeee"],

          data: {
            csv: csv
          },

          title: {
            text: ''
          },
          xAxis: {
            labels: {
              enabled: false,
              format: '{value:%m}',
              rotation: 45,
              align: 'left'
            }
          },
          yAxis: [{
            title: {
              text: null
            },
            labels: {
              align: 'left',
              x: 3,
              y: 16,
              format: '{value:.,0f}'
            },
            showFirstLabel: false
          }, {
            linkedTo: 0,
            gridLineWidth: 0,
            opposite: true,
            title: {
              text: 'EUR'
            },
            labels: {
              align: 'right',
              x: -3,
              y: 16,
              format: '{value:.,0f}'
            },
            showFirstLabel: false
          }],

          tooltip: {
            shared: true,
            crosshairs: true
          },

          plotOptions: {
            series: {
              cursor: 'pointer',
              marker: {
                lineWidth: 1
              }
            }
          }
        });
      });
    }
  });

});