define('jason-frontend/helpers/is-digital-admission-shown', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isDigitalAdmissionShown = isDigitalAdmissionShown;

  function isDigitalAdmissionShown() {
    return API['default'].getMandant() === '3' || API['default'].getMandant() === '10000' || API['default'].getMandant() === '10008' || API['default'].getMandant() === '10009' || API['default'].getMandant() === '1' || API['default'].getMandant() === '2';
  }

  exports['default'] = Ember['default'].Helper.helper(isDigitalAdmissionShown);

});