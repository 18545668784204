define('jason-frontend/templates/components/button-change-user', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 3
            },
            "end": {
              "line": 5,
              "column": 3
            }
          },
          "moduleName": "jason-frontend/templates/components/button-change-user.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"class","circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","16");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[4,62],[4,67]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 28,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-change-user.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 34 34");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(1 1)");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"class","icon in__icon ");
        dom.setAttribute(el3,"transform","scale(0.045,0.045) translate(110,90)");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M178.414,467.038c4.889-27.122,16.416-52.318,33.127-73.795c-3.246-3.229-6.609-6.334-10.211-9.164\n		c-4.908-3.884-9.164-8.431-12.795-13.421c-17.025,15.069-37.912,24.081-60.545,24.081c-22.631,0-43.518-9.012-60.539-24.081\n		c-3.631,4.982-7.875,9.522-12.789,13.421c-22,17.324-37.877,42.337-43.154,71.603l-2.231,12.456\n		c-0.973,5.427,0.508,11.017,4.051,15.247c3.541,4.24,8.779,6.682,14.301,6.682h152.525\n		C177.785,482.715,177.018,474.878,178.414,467.038z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M430.906,389.95c-4.061-3.195-7.611-6.874-10.92-10.75c-20.691,19.073-46.271,30.512-74.031,30.512\n		c-27.771,0-53.348-11.439-74.049-30.494c-3.297,3.858-6.84,7.53-10.9,10.732c-25.5,20.078-43.877,49.043-49.982,82.962\n		c-0.76,4.253,0.404,8.623,3.178,11.931c2.768,3.31,6.869,5.225,11.189,5.225h7.807h233.291c4.32,0,8.422-1.915,11.184-5.225\n		c2.779-3.308,3.941-7.678,3.184-11.931C474.725,438.993,456.377,410.028,430.906,389.95z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M255.035,256.895c0,66.111,40.736,119.685,90.92,119.685c50.178,0,90.873-53.573,90.873-119.685\n		c0-66.079-40.695-119.652-90.873-119.652C295.772,137.242,255.035,190.815,255.035,256.895z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M127.99,361.606c38.68,0,70.018-41.27,70.018-92.148c0-50.904-31.338-92.166-70.018-92.166\n		c-38.625,0-69.996,41.262-69.996,92.166C57.994,320.337,89.365,361.606,127.99,361.606z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M109.848,111.877l76.213,0.048c6.994,0.01,13.266-4.221,15.934-10.637c2.668-6.446,1.191-13.864-3.752-18.798\n		l-16.426-16.43c19.813-10.677,42.082-16.364,65.232-16.364c36.814,0,71.43,14.334,97.459,40.364\n		c4.853,4.854,11.209,7.28,17.568,7.28c6.355,0,12.717-2.427,17.57-7.28c9.707-9.698,9.707-25.431,0-35.139\n		C344.233,19.501,297.139,0,247.049,0c-36.545,0-71.439,10.49-101.434,29.862L122.02,6.268c-4.928-4.934-12.326-6.408-18.816-3.747\n		c-6.42,2.662-10.609,8.925-10.609,15.937v76.19C92.594,104.145,100.324,111.877,109.848,111.877z");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createMorphAt(element2,1,1);
        morphs[3] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[3,9],[3,19]]]]],[],0,null,["loc",[null,[3,3],[5,10]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[6,38],[6,43]]]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});