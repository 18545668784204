define('jason-frontend/components/purchase-order-laboklin-chooser', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    actions: {
      load: function load(priceInfoId) {
        this.set('priceInfoId', priceInfoId);
      },
      addNew: function addNew(product, orderId, modelName) {
        this.sendAction('addNew', product, orderId, this.get('priceInfoId'), modelName);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      assign: function assign(product, orderId, modelName, formId) {

        this.sendAction('addProduct', product, orderId, this.get('priceInfoId'), modelName, formId);

        jason.notifiction.notifiy('Untersuchungsauftrag', 'zugewiesen');

        $.magnificPopup.close();
      }
    },
    becomeFocused: (function () {}).observes('data')
  });

});