define('jason-frontend/models/patient-appointment-category', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    fullId: DS['default'].attr('string'),
    color: DS['default'].attr('string'),
    colorHex: DS['default'].attr(''),
    readOnly: DS['default'].attr(''),
    etiga: DS['default'].attr(''),
    active: DS['default'].attr('boolean'),
    editable: DS['default'].attr('boolean')
  });

});