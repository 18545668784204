define('jason-frontend/helpers/get-cancellation-info', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getCancellationInfo = getCancellationInfo;

  function getCancellationInfo(params) {
    var cancelledBy = params[0].get('canceledBy');
    return cancelledBy > 0 && params[0].get('transactionType').get('id') !== 'cancel_in' && params[0].get('transactionType').get('id') !== 'cancel_out' ? '(storniert)' : '';
  }

  exports['default'] = Ember['default'].Helper.helper(getCancellationInfo);

});