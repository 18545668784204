define('jason-frontend/components/treatment-select-service-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    selectedValues: new Array(),
    loading: true,
    timerId: {},
    actions: {
      confirm: function confirm() {
        var _this = this;

        this.set('loading', true);
        this.set('values', this.get('originalValues'));

        var searchList = new Array();

        this.get('originalValues').forEach(function (value) {
          Ember['default'].set(value, 'name', value.originalName);
          if (_this.get('filterName').length > 1) {
            var pos = value.name.toLowerCase().indexOf(_this.get('filterName').toLowerCase());
            if (pos >= 0) {
              var _name = value.name;
              _name = _name.substring(0, pos) + '<b>' + _name.substring(pos, pos + _this.get('filterName').length) + '</b>' + _name.substring(pos + _this.get('filterName').length);
              Ember['default'].set(value, 'name', _name);
              searchList.push(value);
            }
          } else if (_this.get('filterName').length == 1) {
            if (value.name.toLowerCase().indexOf(_this.get('filterName').toLowerCase()) == 0) {
              var _name2 = value.name;
              _name2 = '<b>' + _name2.substring(0, 1) + '</b>' + _name2.substring(1);
              Ember['default'].set(value, 'name', _name2);
              searchList.push(value);
            }
          } else {
            searchList.push(value);
            Ember['default'].set(value, 'name', value.originalName);
          }
        });

        this.set('loading', false);
        this.set('values', searchList);
      },
      deleteItem: function deleteItem(elem) {
        Ember['default'].set(elem, 'selected', false);
        this.get('selectedValues').removeObject(elem);
      },
      select: function select(item) {
        var selected = !item.selected;
        if (selected) {
          this.get('selectedValues').pushObject(item);
          Ember['default'].set(item, 'selected', true);
        } else {
          this.get('selectedValues').removeObject(item);
          Ember['default'].set(item, 'selected', false);
        }
      },
      cancel: function cancel() {
        this.sendAction('confirm', this.get('selectedValues'), this.get('reference'), this.get('provider'));
        $.magnificPopup.close();
      },
      load: function load(values, selected, title) {
        this.set('selectedValues', new Array());
        this.set('loading', false);
        this.set('values', values);
        this.set('originalValues', this.get('values'));
        this.set('title', title);
        var self = this;

        this.get('originalValues').forEach(function (elem) {
          Ember['default'].set(elem, 'originalName', elem.name);
        });

        if (selected) {
          this.get('values').forEach(function (elem) {
            selected.forEach(function (selec) {
              if (elem.id === selec.id) {
                Ember['default'].set(elem, 'selected', true);
                self.get('selectedValues').pushObject(elem);
              }
            });
          });
        }

        setTimeout(function () {
          $('#filterNameServiceSelect').focus();
        }, 500);
      }
    },
    watchSearch: (function () {
      var self = this;
      var type = 'defaultTimer';
      if (this.timerId[type]) {
        window.clearTimeout(this.timerId[type]);
      }
      this.timerId[type] = window.setTimeout(function () {
        self.send('confirm');
      }, 500);
    }).observes('filterName')
  });

});