define('jason-frontend/components/patient-select-barcode-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    openPrint: function openPrint(patientId, format) {
      var viewUrl = '/api/patients/downloadBarcode/' + patientId + '/' + format + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken();

      var myWindow = window.open(viewUrl, 'Drucken', 'width=600,height=600');

      var is_chrome = Boolean(window.chrome);
      if (is_chrome) {
        myWindow.onload = function () {

          myWindow.moveTo(0, 0);
          myWindow.resizeTo(640, 480);
          myWindow.print();
        };
      } else {
        myWindow.print();
        myWindow.close();
      }
    },
    actions: {
      downloadQr: function downloadQr() {
        var self = this;
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: self.get('patient').id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/patients/downloadEtigaQr/' + self.get('patient').id + '?mandantId=' + API['default'].getMandant() + '&token=' + data.responseText);
        });
      },
      downloadWithDate: function downloadWithDate() {
        this.openPrint(this.get('patient').id, 'withDate');
      },
      downloadWithoutDate: function downloadWithoutDate() {
        this.openPrint(this.get('patient').id, 'withoutDate');
      },
      downloadBirthday: function downloadBirthday() {
        this.openPrint(this.get('patient').id, 'birthday');
      },
      load: function load(patient) {
        this.set('patient', patient);
      }
    }
  });

});