define('jason-frontend/components/products-edit-form', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    store: Ember['default'].inject.service(),
    actions: {
      toggleFavorite: function toggleFavorite(toggle) {
        this.get('model').set('favorite', toggle);
      },
      addTag: function addTag(tag) {
        if (!this.get('model').get('tags')) {
          Ember['default'].set(this.get('model'), 'tags', new Array());
        }
        Ember['default'].set(tag, 'selected', true);
        this.get('model').get('tags').addObject(tag);
      },
      removeTag: function removeTag(tag) {
        this.get('model').get('tags').removeObject(tag);

        this.get('articleTags').forEach(function (item) {
          var id = item.id;
          if (id == tag.id) {
            Ember['default'].set(item, 'selected', false);
          }
        });
      },
      queryManufacturer: function queryManufacturer(query, deferred) {
        this.get('store').find('productManufacturer', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      }
    },
    becomeFocused: (function () {}).on('didInsertElement')
  });

});