define('jason-frontend/models/product-supplier', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    acronym: DS['default'].attr('string'),
    termsAndConditions: DS['default'].attr('string'),
    agbLink: DS['default'].attr('string'),
    street: DS['default'].attr('string'),
    streetNumber: DS['default'].attr('string'),
    postalCode: DS['default'].attr('string'),
    town: DS['default'].attr('string'),
    country: DS['default'].attr('string'),
    uid: DS['default'].attr('string'),
    phone: DS['default'].attr('string'),
    homepage: DS['default'].attr('string'),
    purchaseEmail: DS['default'].attr('string'),
    purchaseRelevant: DS['default'].attr(''),
    generalEmail: DS['default'].attr('string'),
    paymentTerms: DS['default'].attr('string'),
    returnTerms: DS['default'].attr('string'),
    deliveryTerms: DS['default'].attr('string'),
    specialDiscounts: DS['default'].attr('string'),
    debitNecessary: DS['default'].attr('boolean'),
    parcelDropping: DS['default'].attr('boolean'),
    dailyDelivery: DS['default'].attr('boolean'),
    deliveryDays: DS['default'].attr('string'),
    latestInvoice: DS['default'].attr(),
    currentInvoice: DS['default'].attr(),
    autosendInvoice: DS['default'].attr(),
    lastFileImport: DS['default'].attr('date'),
    minimalOrderValue: DS['default'].attr('number'),
    freeHouseOrderValue: DS['default'].attr('number'),
    shippingCostsAbsolute: DS['default'].attr('number'),
    shippingCostsPercentage: DS['default'].attr('number'),
    contactFirstname: DS['default'].attr('string'),
    contactLastname: DS['default'].attr('string'),
    availablePromotions: DS['default'].attr('number'),
    availableTopPromotions: DS['default'].attr('number'),
    contactEmail: DS['default'].attr('string')
  });

});