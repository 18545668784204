define('jason-frontend/components/edit-purchase-price-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    selectTab: 'general',
    item: null,
    deliveryStatus: null,
    productId: null,
    state: null,
    actions: {
      imageChanged: function imageChanged(id) {
        this.set('promotionBanner', id);
        this.get('item').set('topPromotionImage', id);
      },
      deleteImage: function deleteImage() {
        this.get('item').set('topPromotionImage', null);
      },
      refresh: function refresh() {
        $.magnificPopup.close();
        this.sendAction('refresh');
      },
      selectTab: function selectTab(selected) {
        this.set('selectTab', selected);
      },
      loadPromotionContingent: function loadPromotionContingent(data) {
        console.log(data);
        this.set('contingent', data);
      },
      loadData: function loadData(priceInfo, deliveryStatus, suppliers, productId, state, promotionCategories) {
        this.set('item', priceInfo);
        this.set('suppliers', suppliers);
        this.set('promotionCategories', promotionCategories);
        this.set('deliveryStatus', deliveryStatus);
        this.set('productId', productId);
        this.set('selectTab', 'general');
        this.set('state', 'default');
        this.set('selectTab', 'general');

        if (priceInfo.get('promotionRow')) {
          this.set('selectTab', 'promotions');
          this.set('state', 'promotion');
        }
      },
      resetPromotionFrom: function resetPromotionFrom() {
        this.get('item').set('promotionFrom', null);
      },
      resetPromotionTo: function resetPromotionTo() {
        this.get('item').set('promotionTo', null);
      },
      resetDeliverableFrom: function resetDeliverableFrom() {
        this.get('item').set('deliverableFrom', null);
      },
      confirm: function confirm(info, articleId) {

        var price = {};
        price.id = this.get('item').id;
        price.deliveryInfo = this.get('item').get('deliveryInfo');
        price.partnerText = this.get('item').get('partnerText');
        price.promotionLabel = this.get('item').get('promotionLabel');
        price.promotionInfo = this.get('item').get('promotionInfo');
        price.promotionActive = this.get('item').get('promotionActive');
        price.deliverableSoon = this.get('item').get('deliverableSoon');
        price.topPromotion = this.get('item').get('topPromotion');
        price.topPromotionText = this.get('item').get('topPromotionText');
        price.topPromotionImage = this.get('item').get('topPromotionImage');
        price.topPromotionTitle = this.get('item').get('topPromotionTitle');

        price.promotionPriceGroup4 = String(this.get('item').get('promotionPriceGroup4')).replace(",", ".");
        price.customerGroupPrice4 = String(this.get('item').get('customerGroupPrice4')).replace(",", ".");

        if (this.get('item').get('customerGroupPrice3Active')) {
          price.customerGroupPrice3 = String(this.get('item').get('customerGroupPrice3')).replace(",", ".");
        }
        if (this.get('item').get('customerGroupPrice2Active')) {
          price.customerGroupPrice2 = String(this.get('item').get('customerGroupPrice2')).replace(",", ".");
        }
        if (this.get('item').get('customerGroupPrice1Active')) {
          price.customerGroupPrice1 = String(this.get('item').get('customerGroupPrice1')).replace(",", ".");
        }
        if (this.get('item').get('promotionPriceGroup1')) {
          price.promotionPriceGroup1 = String(this.get('item').get('promotionPriceGroup1')).replace(",", ".");
        }
        if (this.get('item').get('promotionPriceGroup2')) {
          price.promotionPriceGroup2 = String(this.get('item').get('promotionPriceGroup2')).replace(",", ".");
        }
        if (this.get('item').get('promotionPriceGroup3')) {
          price.promotionPriceGroup3 = String(this.get('item').get('promotionPriceGroup3')).replace(",", ".");
        }

        price.minimalOrderAmount = String(this.get('item').get('minimalOrderAmount')).replace(",", ".");
        price.deliveryPhone = this.get('item').get('deliveryPhone');

        price.sellingAmount = this.get('item').get('sellingAmount');

        if (this.get('item').get('retailPrice')) {
          price.retailPrice = String(this.get('item').get('retailPrice')).replace(",", ".");
        }

        price.promotionRow = this.get('item').get('promotionRow');

        price.deliveryStatus = {
          id: this.get('item').get('deliveryStatus').id
        };

        price.active = this.get('item').get('active');

        if (this.get('item').get('deliverableFrom')) {
          price.deliverableFrom = moment($('#deliverable-from').val(), 'DD.MM.YYYY').format('YYYY-MM-DD');
        }
        if (this.get('item').get('deliverableTo')) {
          price.deliverableTo = moment($('#deliverable-to').val(), 'DD.MM.YYYY').format('YYYY-MM-DD');
        }

        if (this.get('item').get('promotionFrom')) {
          price.promotionFrom = moment($('#promotion-from').val(), 'DD.MM.YYYY').format('YYYY-MM-DD');
        }
        if (this.get('item').get('promotionTo')) {
          price.promotionTo = moment($('#promotion-to').val(), 'DD.MM.YYYY').format('YYYY-MM-DD');
        }

        if (this.get('item').get('activeFrom')) {
          price.activeFrom = moment($('#active-from').val(), 'DD.MM.YYYY').format('YYYY-MM-DD');
        }
        if (this.get('item').get('activeTo')) {
          price.activeTo = moment($('#active-to').val(), 'DD.MM.YYYY').format('YYYY-MM-DD');
        }

        if (this.get('item').get('supplier')) {
          price.supplier = {
            id: this.get('item').get('supplier').id
          };
        }
        if (this.get('item').get('promotionCategory')) {
          price.promotionCategory = {
            id: this.get('item').get('promotionCategory').id
          };
        }

        if (this.get('contingent').available <= 0 && price.topPromotion) {
          if ($.inArray(Number.parseInt(price.id), this.get('contingent').ids) == -1) {
            jason.notifiction.error('Kontingent verbraucht', 'Sie können derzeit keine neue Top-Aktion erstellen');
            return;
          }
        }

        price.articleId = this.get('productId');
        price.showOverlay = this.get('item').get('showOverlay');

        if (price.topPromotion) {

          if (price.promotionTo == null) {
            jason.notifiction.error('Fehler', 'Bitte geben Sie ein Aktionsende an');
            return;
          }

          var promotionTo = moment(price.promotionTo);

          if (promotionTo.subtract(1, 'months') > moment()) {
            jason.notifiction.error('Fehler', 'Aktionsende der Top-Aktion muss innerhalb eines Monats liegen');
            return;
          }
        }

        this.sendAction('save', price);
        // $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      addPatient: function addPatient(customerId, invoiceId) {
        this.sendAction('addPatient', customerId, invoiceId);
      },
      load: function load(patient) {
        this.set('patientInfo', patient);
      }
    }
  });

});