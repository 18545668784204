define('jason-frontend/components/select-inventory-panel-simple', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      confirm: function confirm() {
        this.set('showInput', true);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      use: function use(id) {
        this.sendAction('use', id);
        $.magnificPopup.close();
      },
      load: function load(entries, name) {
        this.set('items', entries);
        this.set('productName', name);
      }
    }
  });

});