define('jason-frontend/helpers/is-supplier', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isSupplier = isSupplier;

  function isSupplier() {
    return API['default'].isSupplier();
  }

  exports['default'] = Ember['default'].Helper.helper(isSupplier);

});