define('jason-frontend/helpers/is-selected-invoice', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isSelectedInvoice = isSelectedInvoice;

  function isSelectedInvoice(params /*, hash*/) {
    return params;
  }

  exports['default'] = Ember['default'].Helper.helper(isSelectedInvoice);

});