define('jason-frontend/components/treatment-history-rooms', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    patientInfo: null,
    actions: {
      save: function save() {
        var data = {
          treatmentPatientId: this.get('info').id
        };

        if (this.get('info').get('room')) {
          data.roomId = this.get('info').get('room').id;
        }

        this.sendAction('roomSaved', data);
        $.magnificPopup.close();
      },
      load: function load(info) {
        this.set('info', info);
      }
    }
  });

});