define('jason-frontend/models/dsf-export', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    cashRegister: DS['default'].attr('string'),
    from: DS['default'].attr('date'),
    timeExpiration: DS['default'].attr('date'),
    created: DS['default'].attr('date'),
    to: DS['default'].attr('date'),
    format: DS['default'].attr(''),
    status: DS['default'].attr('string')
  });

});