define('jason-frontend/helpers/cloudinary-tag', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Handlebars.makeBoundHelper(function (publicId, options) {
    if (Em.isBlank(publicId)) {
      return '';
    }

    var height = options.hash.height || 535,
        width = options.hash.width || 535,
        crop = options.hash.crop || 'fill',
        cssClass = options.hash['class'] || 'cloudinary-image';

    return new Ember['default'].Handlebars.SafeString($.cloudinary.image(publicId, {
      width: width,
      height: height,
      crop: crop,
      'class': cssClass
    }).prop('outerHTML'));
  });

});