define('jason-frontend/components/email-transaction-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    months: null,
    type: null,
    actions: {
      loadTextmodules: function loadTextmodules(data) {
        this.set('templates', data);
        this.set('selectedTemplate', null);
      },
      send: function send(invoice) {

        var form = $('#emailTransactionForm');

        var value = form.find('input[name=email]').val(),
            persist = form.find('#persist-email').is(':checked');;

        if (value.length < 3) {
          jason.notifiction.systemError('Fehler', 'Bitte geben Sie eine gültige Email-Adresse ein');
          return;
        }

        var data = {
          email: value,
          persist: persist,
          customerId: this.get('customerId')
        };

        if (this.get('selectedTemplate')) {
          data.templateId = this.get('selectedTemplate').id;
        }

        this.sendAction('confirm', this.get('id'), data);

        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(id, email, showPersist, customerId) {
        this.set('id', id);
        this.set('email', email);
        this.set('showPersist', showPersist);
        this.set('customerId', customerId);
      }
    }
  });

});