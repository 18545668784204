define('jason-frontend/helpers/is-add-deposit-available', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isAddDepositAvailable = isAddDepositAvailable;

  function isAddDepositAvailable(params) {
    var invoice = params[0],
        entry = params[2];
    return invoice.get('customer') && invoice.get('customer').get('id') > 0 && entry.get('patient') == null;
  }

  exports['default'] = Ember['default'].Helper.helper(isAddDepositAvailable);

});