define('jason-frontend/models/transaction', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    amount: DS['default'].attr('number'),
    invoiceId: DS['default'].attr('string'),
    invoiceCode: DS['default'].attr('string'),
    transactionId: DS['default'].attr('string'),
    banktransferId: DS['default'].attr('string'),
    externalPaymentId: DS['default'].attr(''),
    transactionGroupId: DS['default'].attr('string'),
    paymentType: DS['default'].belongsTo('paymentType'),
    transactionType: DS['default'].belongsTo('transactionType'),
    documentDate: DS['default'].attr('date'),
    withdrawalDate: DS['default'].attr('date'),
    recipientDate: DS['default'].attr('date'),
    withdrawalDateInput: DS['default'].attr('string'),
    withdrawalName: DS['default'].attr('string'),
    withdrawalBankname: DS['default'].attr('string'),
    withdrawalIban: DS['default'].attr('string'),
    referenceNumber: DS['default'].attr('string'),
    reason: DS['default'].attr('string'),
    bankAccount: DS['default'].belongsTo('banking-Account'),
    created: DS['default'].attr('date'),
    createdInput: DS['default'].attr('string'),
    from: DS['default'].attr('date'),
    to: DS['default'].attr('date'),
    summed: DS['default'].attr('boolean'),
    recipientReferenceNumber: DS['default'].attr('string'),
    taxInfos: DS['default'].hasMany('transaction-tax-info')
  });

});