define('jason-frontend/routes/cash/choose', ['exports', 'ember', 'jason-frontend/api', 'jason-frontend/helpers/is-journal-enabled'], function (exports, Ember, API, is_journal_enabled) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller) {
      if (!API['default'].hasPermission('c_payments')) {
        this.transitionTo('home');
      }

      controller.set('patientGenders', this.store.find('patient-gender'));
      controller.set('patientCategories', this.store.find('patient-category'));

      this.loadData();
    },
    loadData: function loadData() {
      var controller = this.get('controller');
      Ember['default'].$.ajax({
        url: "/api/invoices/open",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set("invoices", data);
        controller.set("count", data.invoice.length);
        controller.set('sum', data.meta.sum);
      });
    },
    actions: {
      createInvoice: function createInvoice() {
        var invoice = {},
            self = this;

        Ember['default'].$.ajax({
          url: "/api/invoices",
          method: "POST",
          data: JSON.stringify(invoice),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('invoices.select_customer', data);
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      gotoHistory: function gotoHistory(patient) {
        var tab = 'history';
        if (is_journal_enabled.isJournalEnabled()) {
          tab = 'activities';
        }
        this.sendAction('gotoPatient', patient.id, tab);
      },
      selectInvoice: function selectInvoice(id) {
        this.transitionTo('invoices.create', id, { queryParams: { entry: "true" } });
      },
      changePatient: function changePatient(invoiceId, patientId, add) {
        var self = this,
            controller = self.get('controller');
        Ember['default'].$.ajax({
          url: "/api/invoices/" + invoiceId + "/changePatient/" + patientId + "/" + add,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {

          Ember['default'].$.ajax({
            url: "/api/invoices/open",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {});
        });
      },
      closeInvoice: function closeInvoice(id) {
        var controller = this.get('controller');
        Ember['default'].$.ajax({
          url: "/api/invoices/" + id + "/close",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (data.status === 500) {
            jason.notifiction.errorLong('Fehler', 'Ausgangsrechnung konnte aufgrund einer offenen Lagerbuchung nicht geschlossen werden');
          } else {
            jason.notifiction.notifiy('Ausgangsrechnung', 'geschlossen');
            Ember['default'].$.ajax({
              url: "/api/invoices/open",
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              controller.set("invoices", data);
            });
          }
        }, function (data) {
          if (data.status === 500) {
            jason.notifiction.errorLong('Fehler', 'Ausgangsrechnung konnte aufgrund einer offenen Lagerbuchung nicht geschlossen werden');
          } else {
            jason.notifiction.notifiy('Ausgangsrechnung', 'geschlossen');
            Ember['default'].$.ajax({
              url: "/api/invoices/open",
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              controller.set("invoices", data);
            });
          }
        });
      },
      createPatient: function createPatient(customerId, patientData) {
        var controller = this.get('controller'),
            self = this;

        var newPatient = {
          name: patientData.name,
          birthdayFormatted: patientData.birthdayFormatted,
          customerId: customerId,
          castrated: patientData.castrated,
          chipId: patientData.chipId
        };

        if (patientData.patientCategory) {
          newPatient.category = {
            id: patientData.patientCategory
          };
        }
        if (patientData.patientGender) {
          newPatient.gender = {
            id: patientData.patientGender
          };
        }

        $.ajax({
          url: "/api/patients",
          method: "POST",
          data: JSON.stringify(newPatient),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.loadData();
        }, function (error) {});
      }
    }
  });

});